'use strict';

angular.module('uasApp').component('appraisalTemplateRows', {
  templateUrl: 'es6/assessment/appraisal/template/appraisal.template.rows.html',
  bindings: {
    template: '<'
  },
  controllerAs: 'rowController',
  controller: function ($uibModal, AppraisalTemplate, Message, Pageable) {
    const rowController = this;

    rowController.$onInit = function () {
      rowController.pageable = Pageable.of({
        order: 'code'
      });
    };

    rowController.create = function () {
      open({
        weighting: 0
      });
    };

    rowController.edit = function (row) {
      open(row);
    };

    function open(row) {
      $uibModal.open({
        templateUrl: 'es6/assessment/appraisal/template/appraisal.template.row.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance, CustomField) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.row = angular.copy(row);

            CustomField.query({
              rootType: 'module',
              entityType: 'appraisal'
            }).$promise.then((fields) => {
              modalController.fields = _.keyBy(fields, 'name');
            });
          };

          modalController.save = function () {
            if (angular.isDefined(row.id)) {
              _.remove(rowController.template.rows, { id: row.id });
            }

            rowController.template.rows.push(modalController.row);

            save().then(() => {
              Message.addSuccessLabel('Static.Message.DataAdjusted');
              $uibModalInstance.dismiss();
            });
          };
        }
      });
    }

    function save() {
      return AppraisalTemplate.save(rowController.template).$promise.then((template) => {
        rowController.template.rows = template.rows;
      });
    }

    rowController.remove = function (row) {
      _.remove(rowController.template.rows, { id: row.id });
      
      save().then(() => {
        Message.addSuccessLabel('Static.Message.DataRemoved');
      });
    };
  }
});
