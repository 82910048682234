'use strict';

angular.module('uasApp').component('categoryList', {
  templateUrl: 'es6/type/category.list.html',
  bindings: {
    rootType: '<',
    categoryId: '<?',
    onSelect: '&'
  },
  controllerAs: 'categoryListController',
  controller: function (Category, Message, $uibModal) {
    const categoryListController = this;

    categoryListController.$onInit = function() {
        loadData();
    };

    function loadData() {
        categoryListController.loading = true;
        Category.query({
            rootType: categoryListController.rootType
        }).$promise.then((categories) => {
            categoryListController.categories = categories;
        }).finally(() => {
            categoryListController.loading = false;
        });
    }

    categoryListController.select = function(category) {
        const categoryId = _.result(category, 'id');
        if (categoryId === categoryListController.categoryId) {
            categoryListController.onSelect();
        } else {
            categoryListController.onSelect({ categoryId });
        }
    };

    categoryListController.create = function() {
        open({
            rootType: categoryListController.rootType
        });
    };

    categoryListController.edit = function(category) {
        open(category);
    };

    function open(category) {
        $uibModal.open({
            template: `
                <category-edit 
                    category="categoryModalController.category"
                    on-cancel="categoryModalController.close()"
                    on-save="categoryModalController.save(category)">
                </category-edit>
            `,
            controllerAs: 'categoryModalController',
            controller: function($uibModalInstance) {
                const categoryModalController = this;
                categoryModalController.category = angular.copy(category);

                categoryModalController.close = function() {
                    $uibModalInstance.dismiss();
                };

                categoryModalController.save = function(data) {
                    Category.store(data).$promise.then(() => {
                        categoryModalController.close();
                        Message.onSaved();
                        loadData();
                    });
                };
            }
        });
    }

    categoryListController.remove = function(category) {
        Category.remove(category).$promise.then(() => {
            Message.onRemoved();
            loadData();
        });
    };
  }
});
