'use strict';

angular.module('uasApp').component('assessmentSelector', {
  bindings: {
    field: '<',
    parent: '<?',
    category: '<?',
    entity: '<?',
    createDisabled: '<?',
    includeTypes: '<?',
    operations: '<?',
    operationsToEdit: '<?',
    mode: '<?',
    onMode: '&'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/assessment/assessment.selector.html',
  controllerAs: 'assessmentSelectorController',
  controller: function (Assessment, EntityType, SecurityService) {
    const assessmentSelectorController = this;

    assessmentSelectorController.$onInit = function () {
      EntityType.canCreate('assessment').then((create) => {
        assessmentSelectorController.createAllowed = SecurityService.isAllowed('CREATE_ASSESSMENT', assessmentSelectorController.operations) && create;
        setDefaultMode();
      });

      assessmentSelectorController.ngModelCtrl.$render = function () {
        assessmentSelectorController.assessment = assessmentSelectorController.ngModelCtrl.$viewValue;
        assessmentSelectorController.assessmentId = _.get(assessmentSelectorController.assessment, 'id');
      };
    };

    function setDefaultMode() {
      if (assessmentSelectorController.mode) {
        return;
      }

      if (assessmentSelectorController.createAllowed) {
        assessmentSelectorController.setMode('create');
      } else {
        assessmentSelectorController.setMode('search');
      }
    }

    assessmentSelectorController.setMode = function (mode) {
      assessmentSelectorController.mode = mode;
      reset();

      assessmentSelectorController.onMode({
        mode: assessmentSelectorController.mode
      });
    };

    function reset() {
      delete assessmentSelectorController.assessment;
      delete assessmentSelectorController.assessmentId;
      setViewValue();
    }

    assessmentSelectorController.onCopy = function () {
      Assessment.find(assessmentSelectorController.assessmentId).$promise.then((assessment) => {
        assessmentSelectorController.setMode('create');

        assessmentSelectorController.assessment = _.omit(angular.copy(assessment), ['id', 'externalId', 'code', 'self', 'moduleId', 'uid']);
        setViewValue(assessmentSelectorController.assessment);
      });
    };

    assessmentSelectorController.onChange = function () {
      setViewValue(assessmentSelectorController.assessment);
    };

    function setViewValue(assessment) {
      const copy = angular.copy(assessment);
      assessmentSelectorController.ngModelCtrl.$setViewValue(copy);
    }

    assessmentSelectorController.onAssessment = function () {
      Assessment.find(assessmentSelectorController.assessmentId).$promise.then((assessment) => {
        setViewValue(assessment);
      });
    };
  }
});
