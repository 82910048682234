'use strict';

angular.module('uasApp').component('sectionSelect', {
  bindings: {
    typeId: '<',
    classes: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/custom/sections/section.select.html',
  controllerAs: 'sectionSelectController',
  controller: function(i18nFilter, Language, Section) {
    const sectionSelectController = this;

    sectionSelectController.$onInit = function () {
      Section.query({
        typeId: sectionSelectController.typeId
      }).$promise.then((sections) => {
        sectionSelectController.sections = sections;
        setDisplayName();
      });

      sectionSelectController.ngModelCtrl.$render = function () {
        sectionSelectController.sectionId = sectionSelectController.ngModelCtrl.$modelValue;
      };

      Language.onChange(setDisplayName);
    };

    function setDisplayName() {
      _.forEach(sectionSelectController.sections, (section) => {
        section.displayName = i18nFilter(section.names);
      });
      sectionSelectController.sections = _.sortBy(sectionSelectController.sections, 'displayName');
    }

    sectionSelectController.onChange = function () {
      sectionSelectController.ngModelCtrl.$setViewValue(sectionSelectController.sectionId);
    };
  }
});