'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:documentFields
 * @description
 */
angular.module('uasApp').component('documentFields', {
  bindings: {
    document: '<',
    entity: '<',
    typeIds: '<?',
    nameFormula: '<?',
    operations: '<',
    operationsToEdit: '<'
  },
  templateUrl: 'es6/document/document.fields.html',
  controllerAs: 'documentFieldsController',
  controller: function (CustomField, EntityService) {
    const documentFieldsController = this;

    const EXTENSIONS = [
      {
        // Only generate name for new documents
        name: 'name',
        formula: angular.isUndefined(documentFieldsController.document.id) ? documentFieldsController.nameFormula : undefined
      }
    ];

    documentFieldsController.$onInit = function () {
      loadData();
    };

    function loadData() {
      documentFieldsController.loading = true;

      getFields().then((fields) => {
        documentFieldsController.fields = _.sortBy(fields, 'sequence');

        // Set document type if there is only one allowed type
        if (_.size(documentFieldsController.typeIds) === 1) {
          documentFieldsController.document.typeId = documentFieldsController.typeIds[0];
        }

        setEvaluation();
      }).finally(() => {
        documentFieldsController.loading = false;
      });
    }

    function getFields() {
      return CustomField.query({
        rootType: EntityService.getType(documentFieldsController.document.entity),
        entityType: 'document'
      }).$promise.then((fields) => {
        return CustomField.extend(fields, EXTENSIONS);
      });
    }

    documentFieldsController.isIncluded = function (type) {
      if (_.isEmpty(documentFieldsController.typeIds)) {
        return true;
      }

      return _.includes(documentFieldsController.typeIds, type.id);
    };

    documentFieldsController.onValue = function () {
      setEvaluation();
    };

    function setEvaluation() {
      // Only root type is specified so custom field can be determined.
      // Root id is not specified because child documents are not used for evaluation.
      const document = {
        type: documentFieldsController.document.entity.type
      };
      const values = EntityService.extend(documentFieldsController.document, documentFieldsController.document.entity);
      values.entityCode = documentFieldsController.entity.code;
      values.entityExternalId = documentFieldsController.entity.externalId;

      documentFieldsController.evaluation = CustomField.getEvaluation(
        document,
        values,
        documentFieldsController.fields);
    }
  }
});