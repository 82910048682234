'use strict';

angular.module('uasApp').component('expandAllToggle', {
  templateUrl: 'es6/app/expand.all.toggle.html',
  bindings: {
    isOpen: '<?',
    onExpand: '&',
    classes: '@?'
  },
  controllerAs: 'expandController',
  controller: function ($q) {
    const expandController = this;

    expandController.$onInit = function () {
      expandController.expanded = expandController.isOpen === true;
      expandController.loading = false;
    };

    expandController.onToggle = function () {
      const expanded = !expandController.expanded;

      const promise = expandController.onExpand({ expanded });
      $q.resolve(promise).finally(() => {
        expandController.expanded = expanded;
      });
    };
  }
});
