'use strict';

angular.module('uasApp').filter('noHtml', function() {
    return function(text) {
        if (!text) {
            return text;
        }

        return text.replace(/<\/li\>/ig, '\n').replace(/<\/p\>/ig, '\n').replace(/<br\/\>/ig, '\n').replace(/(<([^>]+)>)/ig, '');
    };
});
