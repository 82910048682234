'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ReportTemplateService
 * @description
 * Factory to save report templates, or to get them by type.
 */
angular.module('uasApp').factory('ReportTemplateService', function ($uibModal, $q, Message, ReportTemplate) {
  /**
   * Save a report template with the provided parameters.
   * @param {Object} params - Required parameters for saving.
   * @param {string} params.type - The type of the report template.
   * @param {boolean} params.createNew - Whether the template is new, or should be saved as such.
   * @param {Object} params.template - The report template to be saved.
   * @param {Object} params.pageable - The pageable object containing sort order and direction.
   * @param {Function} params.headers - A function that returns the visible columns' headers.
   */
  function save({ type, createNew, template, pageable, headers }) {
    const deferred = $q.defer();

    $uibModal.open({
      controllerAs: 'reportTemplateModalController', controller: function ($uibModalInstance) {
        this.createNew = createNew;
        this.template = angular.copy(template) || {};
        this.instance = $uibModalInstance;
        this.onSave = function (savedTemplate) {
          store(type, savedTemplate, pageable, headers).then((result) => {
            deferred.resolve(result);
            $uibModalInstance.close();
          }).catch((error) => {
            deferred.reject(error);
          });
        };
      }, template: `
          <report-template-edit
            create-new="reportTemplateModalController.createNew"
            template="reportTemplateModalController.template"
            instance="reportTemplateModalController.instance"
            on-submit="reportTemplateModalController.onSave(template)">
          </report-template-edit>
        `
    });

    return deferred.promise;
  }

  function store(type, template, pageable, headers) {
    const body = {
      id: template.id,
      name: template.name,
      sequence: template.sequence,
      visible: template.visible,
      type: type,
      sort: pageable.order,
      direction: pageable.reverse ? 'DESC' : 'ASC',
      headers: headers()
    };

    return ReportTemplate.store(body).$promise.then((result) => {
      Message.onSaved();
      return result;
    });
  }

  function getByType(type) {
    return ReportTemplate.query({
      type: type
    }).$promise;
  }

  return { save, getByType };
});
