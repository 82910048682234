'use strict';

/**
* Slices an array from start to n.
* @param {Array} input The input that needs to be sliced.
* @param {Number} start The index from where the list should start.
* @return {Array}
*/
angular.module('uasApp').filter('startFrom', function() {
    return function(input, start) {
        if (!input) {
            return [];
        }
        if (!input.isArray) {
            input = _.toArray(input);
        }
        if (typeof start !== 'number') {
            start = parseInt(start);
        }
        return input.slice(start);
    };
});
