'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasVideoModal
 * @description Manages a video.
 */
angular.module('uasApp').component('uasVideoModal', {
  bindings: {
    video: '<',
    onSave: '&',
    onClose: '&'
  },
  templateUrl: 'es6/app/instruction/video.modal.html',
  controllerAs: 'videoModalController',
  controller: function ($uibModal, Video, Message, Language) {
    const videoModalController = this;

    videoModalController.$onInit = function () {
      videoModalController.languages = Language.supported;
    };

    videoModalController.addLanguage = function () {
      open({});
    };

    videoModalController.removeLanguage = function (language) {
      _.remove(videoModalController.video.languages, language);
      videoModalController.videoForm.$setDirty();
    };

    videoModalController.editLanguage = function (language) {
      open(language);
    };

    function open(language) {
      $uibModal.open({
        templateUrl: 'es6/app/instruction/video.modal.language.modal.html',
        controllerAs: 'languageModalController',
        controller: function ($uibModalInstance) {
          const languageModalController = this;

          languageModalController.$onInit = function () {
            languageModalController.language = angular.copy(language);
            languageModalController.languages = videoModalController.languages;
          };

          languageModalController.save = function () {
            _.remove(videoModalController.video.languages, (it) =>
              it.language === languageModalController.language.language || (!_.isEmpty(language.language) && it.language === language.language)
            );
            videoModalController.video.languages.push(languageModalController.language);

            videoModalController.videoForm.$setDirty();
            $uibModalInstance.close();
          };
        }
      });
    }

    videoModalController.save = function () {
      Video.store(videoModalController.video).$promise.then(() => {
        Message.onSaved();
        videoModalController.onSave();
      });
    };
  }
});
