'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ReferenceList
 * @description
 * The ReferenceList service.
 */
angular.module('uasApp').factory('ReferenceList', function (UasResource) {
  return UasResource('/reference-lists', {
    search: {
      method: 'GET',
      url: '/reference-lists/search',
      isArray: false
    }
  });
});
