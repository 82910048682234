'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uasWizardTabs
 * @description
 * The uasWizardTabs directive.
 */
angular.module('uasApp')
  .component('uasWizardTabs', {
    bindings: {
      tabs: '<',
      onTab: '&'
    },
    templateUrl: 'es6/app/tabs/wizard.tabs.html',
    controllerAs: 'wizardTabsController',
    controller: function () {
      const wizardTabsController = this;

      wizardTabsController.filter = function(tab) {
        return angular.isUndefined(tab.visible) || tab.visible === true;
      };
    }
  });
