'use strict';

angular.module('uasApp').component('processStatusPage', {
    templateUrl: 'es6/process/progress/process.status.page.html',
    bindings: {
        entity: '<',
        process: '<?',
        page: '<'
    },
    controllerAs: 'processStatusPageController'
});