'use strict';

angular.module('uasApp').component('assetList', {
  bindings: {
    module: '<',
    operations: '<',
    operationsToEdit: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/inventory/asset/asset.list.html',
  controllerAs: 'assetListController',
  controller: function($q, $uibModal, activeFilter, feedbackObserver, Asset, Delivery, Reference, CustomField, Message) {
    const assetListController = this;

    assetListController.$onInit = function() {
      assetListController.readOnly = assetListController.isReadOnly === true;

      loadData();
    };

    function loadData() {
        assetListController.loading = true;
        $q.all([
            Asset.query({
              entityType: 'module',
              entityId: assetListController.module.id
            }).$promise.then(activeFilter),
            Delivery.query({
              entityType: 'module',
              entityId: assetListController.module.id
            }).$promise,
            Reference.query({
              typeCode: 'RECIPIENT'
            }).$promise,
            CustomField.query({
              rootType: 'resource',
              entityType: 'resource-version'
            }).$promise
        ]).then(([assets, deliveries, recipients, versionFields]) => {
            assetListController.assets = _(assets).sortBy(['useWeek', 'version.externalId', 'id']).map((asset) => {
                asset.deliveries = _.filter(deliveries, { assetId: asset.id });
                return asset;
            }).value();
  
            assetListController.recipients = recipients;
            assetListController.versionFields = _(versionFields).sortBy('sequence').keyBy('name').value();

            search();
        }).finally(() => {
            assetListController.loading = false;
        });
    }

    assetListController.create = function() {
      open({});
    };

    assetListController.edit = function(asset) {
      open(asset);
    };

    function open(asset) {
        $uibModal.open({
          template: `
            <asset-modal
              asset="openAssetController.asset"
              module="openAssetController.module"
              operations="openAssetController.operations"
              operations-to-edit="openAssetController.operationsToEdit"
              on-change="openAssetController.onChange()"
              on-cancel="openAssetController.close()">
            </asset-modal>
          `,
          controllerAs: 'openAssetController',
          controller: function($uibModalInstance) {
            const openAssetController = this;
  
            openAssetController.$onInit = function() {
              openAssetController.asset = mapToForm(asset);
              openAssetController.module = angular.copy(assetListController.module);
              openAssetController.operations = angular.copy(assetListController.operations);
              openAssetController.operationsToEdit = angular.copy(assetListController.operationsToEdit);
            };
  
            openAssetController.close = function() {
              $uibModalInstance.dismiss();
            };
  
            openAssetController.onChange = function() {
              dataChanged();
              Message.addSuccessLabel('Static.Message.DataAdjusted');
              openAssetController.close();
            };
          }
        });
      }
  
    function mapToForm(view) {
        const form = angular.copy(view);
        form.versionId = _.result(view, 'version.id');
        form.startId = _.result(view, 'start.id');
        form.endId = _.result(view, 'end.id');
        return form;
    }
  
    function dataChanged() {
        feedbackObserver.dataChanged();
        loadData();
    }
  
    assetListController.remove = function (asset, comment) {
        return Asset.remove({
          id: asset.id,
          comment: {
            message: comment
          }
        }).$promise.then(() => {
          dataChanged();
          Message.addSuccessLabel('Static.Message.DataRemoved');
        });
    };

    assetListController.onRecipient = function(recipientId) {
      if (recipientId === assetListController.recipientId) {
        delete assetListController.recipientId;
      } else {
        assetListController.recipientId = recipientId;
      }

      search();
    };

    function search() {
      _.forEach(assetListController.assets, (asset) => {
        _.forEach(asset.deliveries, (delivery) => {
          const matches = angular.isUndefined(assetListController.recipientId) || delivery.recipientId === assetListController.recipientId;
          delivery.visible = matches && delivery.amount > 0;
        });

        asset.visible = angular.isUndefined(assetListController.recipientId) || _.some(asset.deliveries, { visible: true });
      });
    }
  }
});