'use strict';

/**
 * List based report of changes in the faculty.
 */
angular.module('uasApp').component('uasSettings', {
	templateUrl: 'es6/app/settings.html',
	controllerAs: 'settingsController',
	controller: function (AuthService, Debug) {
		const settingsController = this;

		settingsController.$onInit = function() {
			loadData();
			Debug.onChange(loadData);
		};

		function loadData() {
			settingsController.debug = Debug.isDebug();
		}

		settingsController.toggleDebug = function() {
			Debug.setDebug(settingsController.debug);
		};

		settingsController.generateToken = function() {
			AuthService.token().then((token) => {
				settingsController.token = token;
			});
		};

		settingsController.copyToken = function() {
			navigator.clipboard.writeText(settingsController.token);
		};
	}
});