'use strict';

angular.module('uasApp').filter('enum', function(translateFilter) {
    return function(name) {
        if (_.isEmpty(name)) {
            return '';
        }

        const key = 'Enum.' + name;
        const label = translateFilter(key);
        return key === label ? name : label;
    };
});
