'use strict';

angular.module('uasApp').component('previewButton', {
  bindings: {
    entity: '<',
    type: '<'
  },
  templateUrl: 'es6/app/preview/preview.button.html',
  controllerAs: 'previewButtonController',
  controller: function () {
    const previewButtonController = this;

    previewButtonController.$onChanges = function() {
        prepare(previewButtonController.entity);
    };

    function prepare(entity) {
        if (angular.isUndefined(entity.self)) {
            entity.self = {
                type: entity.type,
                id: entity.id
            };
        }
    }
  }
});