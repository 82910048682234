'use strict';

angular.module('uasApp').component('subjectCategoryEdit', {
    templateUrl: 'es6/subjects/category/subject.category.edit.html',
    bindings: {
        category: '<',
        onSaved: '&',
        onCancel: '&',
        showType: '<'
    },
    controllerAs: 'subjectCategoryEditController',
    controller: function (SubjectCategory, Scale) {
        const subjectCategoryEditController = this;

        subjectCategoryEditController.$onInit = function () {
            Scale.query().$promise.then((scales) => {
                subjectCategoryEditController.scales = scales;
            });
        };

        subjectCategoryEditController.$onChanges = function () {
            subjectCategoryEditController.category.scaleId = _.get(subjectCategoryEditController.category, 'scale.id');
        };

        subjectCategoryEditController.cancel = function () {
            subjectCategoryEditController.onCancel();
        };

        subjectCategoryEditController.save = function (category) {
            delete category.scale;
            SubjectCategory.store(category).$promise.then(() => {
                subjectCategoryEditController.onSaved();
            });
        };
    }
});
