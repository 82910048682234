'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowStudyStudyable
 * @description
 * uasWorkflowStudyStudyable Show studyability of a study.
 */
angular.module('uasApp')
  .component('uasWorkflowStudyStudyable', {
    bindings: {
      study: '<',
      page: '<'
    },
    templateUrl: 'es6/study/workflow/workflow.study.studyable.html',
    controllerAs: 'studyableWorkflowController'
});
