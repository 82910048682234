'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Period
 * @description
 * The Period service.
 */
angular.module('uasApp')
    .factory('Period', function(UasResource) {
        const instance = UasResource('/period');

        instance.getSelectable = function (academicYearId) {
            return instance.query({ academicYearId }).$promise.then((periods) =>
                _.filter(periods, (period) =>
                    period.selectable === true &&
                    angular.isUndefined(period.partOfDayId)
                )
            );
        };

        return instance;
    });
