'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:reorderableItem
 * @description
 * Creates a reorderable list item, for usage in elements with a dnd-list directive.
 * The directive becomes a li element to satisfy dnd-list's ul-li requirement.
 * Note: Avoid using ng-repeat with 'track by' in the parent dnd-list to ensure correct reordering.
 *
 * @param {Object} item - The item data to be reordered.
 * @param {Boolean} isFixed - If true, the item cannot be reordered.
 * @param {Function} onMove - Expression evaluated on item move, e.g. to update sequences in the parent scope.
 */
angular.module('uasApp').directive('reorderableItem', function() {
  return {
    scope: {
      item: '<',
      isFixed: '<',
      onMove: '&'
    },
    transclude: true,
    restrict: 'E',
    replace: true,
    require: '^ul',
    template: `
      <li
        class="d-f gap-10 vertical-align selectable"
        dnd-draggable="item"
        dnd-moved="onMove()"
        dnd-effect-allowed="move"
        dnd-disable-if="isFixed"
        draggable="true">
        <ng-transclude class="w-100p"></ng-transclude>
        <div class="actions" ng-if="!isFixed">
          <i class="fa fa-bars pull-right m-r-10 m-t-5"
             uib-tooltip="{{ 'Static.Label.ColumnOrder' | translate }}"
             tooltip-append-to-body="true"
             tooltip-placement="auto">
          </i>
        </div>
      </li>
    `
  };
});

