'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasGroupGeneral
 * @description
 * # uasGroupGeneral
 * Group general tab, used view and edit information about a group.
 */
angular.module('uasApp').component('uasGroupGeneral', {
  bindings: {
    group: '<',
    operations: '<'
  },
  templateUrl: 'es6/group/group.general.html',
  controllerAs: 'groupGeneralController',
  controller: function ($state, Faculty, Organisation, Study, Qualification, Process, CustomField, CustomPage) {
    const groupGeneralController = this;

    groupGeneralController.$onInit = () => {
      Process.query({
        academicYearId: groupGeneralController.group.academicYearId,
        rootType: 'MODULE_GROUP'
      }).$promise.then((processes) => {
        groupGeneralController.processes = processes;
      });

      CustomField.query({
        rootType: 'module-group'
      }).$promise.then((fields) => {
        groupGeneralController.fields = _.keyBy(fields, 'name');
      });

      CustomPage.query({
        basePath: 'group.general'
      }).$promise.then((pages) => {
        groupGeneralController.pages = pages;
      });

      loadData(groupGeneralController.group);
    };

    function loadData(group) {
      if (angular.isDefined(group.facultyId)) {
        Faculty.get({
          id: group.facultyId
        }).$promise.then((faculty) => {
          groupGeneralController.faculty = faculty;
        });
      }

      if (angular.isDefined(group.organisationId)) {
        Organisation.get({
          id: group.organisationId
        }).$promise.then((organisation) => {
          groupGeneralController.organisation = organisation;
        });
      }

      if (angular.isDefined(group.studyId)) {
        Study.get({
          id: group.studyId
        }).$promise.then((study) => {
          groupGeneralController.study = study;
        });
      }

      if (angular.isDefined(group.qualificationId)) {
        Qualification.get({
          id: group.qualificationId
        }).$promise.then((qualification) => {
          groupGeneralController.qualification = qualification;
        });
      }
    }

    groupGeneralController.onValidate = (newStatus) => {
      if (newStatus) {
        groupGeneralController.group.status = newStatus.type;
      }
    };

    groupGeneralController.onStatus = function () {
      $state.reload();
    };

    groupGeneralController.onStudies = function(studies) {
      groupGeneralController.hideUsedInStudies = _.isEmpty(studies);
    };
  }
});
