'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:specificationAdmin
 * @specificationAdmin
 * specificationAdmin Shows specification admin.
 */
angular.module('uasApp').component('specificationAdmin', {
  bindings: {
    specification: '<',
    operations: '<'
  },
  templateUrl: 'es6/specification/specification.admin.html',
  controllerAs: 'specificationAdminController',
  controller: function ($q, $state, CustomPage, FeedbackService, Message, Specification, EntityType) {
    const specificationAdminController = this;

    specificationAdminController.$onInit = function () {
      specificationAdminController.specification_ = angular.copy(specificationAdminController.specification);
      loadData();
    };

    function loadData() {
      specificationAdminController.loading = true;
      $q.all([
        CustomPage.query({
          basePath: 'specification.admin'
        }).$promise,
        EntityType.get({
          rootType: 'specification',
          entityType: 'specification'
        }).$promise
      ]).then(([pages, customType]) => {
        specificationAdminController.pages = pages;
        specificationAdminController.customType = customType;
      }).finally(() => {
        specificationAdminController.loading = false;
      });
    }

    specificationAdminController.save = function (specification, comment) {
      const body = {
        comment: comment,
        content: specification
      };

      return Specification.update(body).$promise.then((result) => {
        specificationAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(specification);
        return result;
      });
    };

    specificationAdminController.delete = function (specification, comment) {
      const body = {
        comment: comment,
        id: specification.id
      };

      Specification.remove(body).$promise.then(() => {
        Message.onRemoved();
        $state.reload();
      });
    };

    specificationAdminController.deleteFull = function (specification) {
      Specification.deleteFull({
        id: specification.id
      }).$promise.then(() => {
        Message.onRemoved();

        specificationAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };

    specificationAdminController.onPublish = function () {
      $state.reload();
    };
  }
});
