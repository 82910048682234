'use strict';

angular.module('uasApp').component('customFieldLabel', {
    bindings: {
        field: '<',
        labelKey: '@',
        isReadOnly: '<?',
        requiredModel: '<?'
    },
    templateUrl: 'es6/fields/custom.field.label.html',
    controllerAs: 'customFieldLabelController',
    controller: function(CustomField, Language) {
        const customFieldLabelController = this;

        customFieldLabelController.$onChanges = function() {
            setLabel();
        };

        customFieldLabelController.$onInit = function() {
            customFieldLabelController.onLanguage = Language.onChange(setLabel);
        };

        customFieldLabelController.$onDestroy = function() {
            customFieldLabelController.onLanguage();
        };

        function setLabel() {
            customFieldLabelController.label = CustomField.getLabel(customFieldLabelController.field, customFieldLabelController.labelKey);
        }
    }
});