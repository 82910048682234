'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('custom-type-list', {
        url: '/custom-types',
        template: '<uas-custom-type-list></uas-custom-type-list>',
        breadcrumb: {
            label: 'Static.Page.CustomTypes',
            root: true
        },
        admin: true
    });
    
    $stateProvider.state('custom-type', {
        url: '/custom-types/:id',
        template: '<uas-custom-type custom-type="customTypeStateController.customType"></uas-custom-type>',
        controllerAs: 'customTypeStateController',
        controller: function (customType) {
            this.customType = customType;
        },
        resolve: {
            customType: function (CustomType, $stateParams) {
                return CustomType.get({ 
                    id: $stateParams.id 
                }).$promise;
            }
        },
        admin: true
    }).state('custom-type.general', {
        url: '/general',
        views: {
            'customType': {
                resolve: {
                    customType: function (customType) {
                        return customType;
                    }
                },
                controllerAs: 'customTypeGeneralStateController',
                controller: function (customType) {
                    this.customType = customType;
                },
                template: '<uas-custom-type-general custom-type="customTypeGeneralStateController.customType"></uas-custom-type-general>'
            }
        },
        breadcrumb: {},
        admin: true
    }).state('custom-type.fields', {
        url: '/fields',
        views: {
            'customType': {
                resolve: {
                    customType: function (customType) {
                        return customType;
                    }
                },
                controllerAs: 'fieldsStateController',
                controller: function (customType) {
                    this.customType = customType;
                },
                template: '<uas-custom-field-list custom-type="fieldsStateController.customType"></uas-custom-field-list>'
            }
        },
        breadcrumb: {},
        admin: true
    }).state('custom-type.export', {
        url: '/exports',
        views: {
            'customType': {
                resolve: {
                    customType: function (customType) {
                        return customType;
                    }
                },
                controllerAs: 'exportTypeStateController',
                controller: function (customType) {
                    this.customType = customType;
                },
                template: '<uas-export-type custom-type="exportTypeStateController.customType"></uas-export-type>'
            }
        },
        breadcrumb: {},
        admin: true
    }).state('custom-type.sections', {
        url: '/sections',
        views: {
            'customType': {
                resolve: {
                    customType: function (customType) {
                        return customType;
                    }
                },
                controllerAs: 'sectionStateController',
                controller: function (customType) {
                    this.customType = customType;
                },
                template: '<section-list custom-type="sectionStateController.customType"></section-list>'
            }
        },
        breadcrumb: {},
        admin: true
    });
});
