'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('module', {
        url: '/module/{id:[0-9]{1,8}}',
        templateUrl: 'es6/module/module.html',
        controller: 'ModuleCtrl',
        controllerAs: 'moduleController',
        abstract: true,
        entityType: 'module',
        redirectTo: 'module.general',
        breadcrumb: {},
        resolve: {
            module: function(AcademicYearHolder, Module, $stateParams) {
                const instance = this;
                return Module.get({
                    id: $stateParams.id
                }).$promise.then(function(module) {
                    instance.self.data = module;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(module.academicYearId).then(() => {
                        return module;
                    });
                });
            },
            operations: function (AuthService, module) {
                return AuthService.operations('module', module.id);
            },
            historyMode: function($stateParams) {
                return $stateParams.historyMode === 'true';
            },
            periodId: function($stateParams) {
                return $stateParams.periodId;
            }
        },
        params: {
          historyMode: {
            value: 'false'
          },
          periodId: {
            value: ''
          }
        }
    });

    $stateProvider.state('module.general', {
        url: '/general?historyMode&periodId',
        views: {
            'module': {
                template: '<module-general module-id="moduleId" operations="operations"></module-general>'
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Module.General',
            label: 'Static.Help.Module.General'
        }
    });

    $stateProvider.state('module.description', {
        url: '/descriptions',
        views: {
            'module': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('module');
                    }
                },
                controller: function($scope, types) {
                    $scope.types = types;
                },
                template: `
                    <description
                        data-types="types"
                        data-entity-type="module"
                        data-entity-id="moduleId"
                        data-status="module.status"
                        data-operations="operations">
                    </description>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Module.Description',
            label: 'Static.Help.Module.Description'
        }
    });

    $stateProvider.state('module.additional', {
        url: '/additional',
        views: {
            'module': {
                template: `
                    <uas-additional
                        entity="module"
                        operations="operations">
                    </uas-additional>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ADDITIONAL'],
            title: 'Static.Tab.Module.Extra',
            label: 'Static.Help.Module.Extra'
        }
    });

    $stateProvider.state('module.items', {
        url: '/items',
        views: {
            'module': {
                template: `
                    <items
                        entity="{ type: \'module\', id: moduleId }"
                        operations="operations">
                    </items>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ITEMS'],
            title: 'Static.Tab.Module.Items',
            label: 'Static.Help.Module.Items'
        }
    });

    $stateProvider.state('module.capacity', {
        url: '/capacity',
        views: {
            'module': {
                template: `
                    <uas-capacity 
                        entity="module" 
                        operations="operations">
                    </uas-capacity>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CAPACITY'],
            title: 'Static.Tab.Module.Capacity',
            label: 'Static.Help.Module.Capacity'
        }
    });

    $stateProvider.state('module.documents', {
        url: '/documents',
        views: {
            'module': {
                template: `
                    <document-list
                        entity="module"
                        operations="operations"
                        operations-to-edit="['EDIT_DOCUMENT']">
                    </document-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_DOCUMENT'],
            title: 'Static.Tab.Module.Documents',
            label: 'Static.Help.Module.Documents'
        }
    });

    $stateProvider.state('module.credits', {
        url: '/credits',
        views: {
            'module': {
                template: `
                    <uas-credits 
                        entity="module" 
                        operations="operations">
                    </uas-credits>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CREDITS'],
            title: 'Static.Tab.Module.Credits',
            label: 'Static.Help.Module.Credits'
        }
    });

    $stateProvider.state('module.teaching-method', {
        url: '/methods',
        views: {
            'module': {
                template: `
                    <method-list 
                        module="module" 
                        operations="operations" 
                        show-budget="true">
                    </method-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_METHODS'],
            title: 'Static.Tab.Module.Methods',
            label: 'Static.Help.Module.Methods'
        }
    });

    $stateProvider.state('module.method-tree', {
        url: '/method-tree',
        views: {
            'module': {
                template: `
                    <method-tree 
                        module="module" 
                        operations="operations">
                    </method-tree>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_METHODS'],
            title: 'Static.Tab.Module.MethodSchema',
            label: 'Static.Help.Module.MethodSchema'
        }
    });

    $stateProvider.state('module.relations', {
        url: '/relations',
        views: {
            'module': {
                template: `
                    <relations
                        entity-type="module"
                        entity-id="moduleId"
                        operations="operations">
                    </relations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Module.Relations',
            label: 'Static.Help.Module.Relations'
        }
    });

    $stateProvider.state('module.groups', {
        url: '/groups',
        views: {
            'module': {
                template: `
                    <uas-module-groups 
                        module="module" 
                        operations="operations">
                    </uas-module-groups>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Module.Groups',
            label: 'Static.Help.Module.Groups'
        }
    });

    $stateProvider.state('module.studies', {
        url: '/studies',
        views: {
            'module': {
                template: `
                    <uas-module-studies 
                        module-id="moduleId">
                    </uas-module-studies>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Module.Studies',
            label: 'Static.Help.Module.Studies'
        }
    });

    $stateProvider.state('module.cost', {
        url: '/cost',
        views: {
            'module': {
                template: `
                    <module-cost
                        module-id="moduleId"
                        operations="operations">
                    </module-cost>
                `
            }
        },
        data: {
            secured: ['VIEW_COST'],
            title: 'Static.Tab.Module.Cost',
            label: 'Static.Help.Module.Cost'
        },
        breadcrumb: {},
        parent: 'module'
    });

    $stateProvider.state('module.cost-divisions', {
        url: '/cost-divisions',
        views: {
            'module': {
                template: `
                    <uas-cost-division 
                        module="module" 
                        operations="operations">
                    </uas-cost-division>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST_DIVISION'],
            title: 'Static.Tab.Module.CostDivisions',
            label: 'Static.Help.Module.CostDivisions'
        }
    });

    $stateProvider.state('module.offering', {
        url: '/offering',
        views: {
            'module': {
                template: `
                    <offering-list 
                        entity="module" 
                        operations="operations">
                    </offering-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_OFFERINGS'],
            title: 'Static.Tab.Module.Offering',
            label: 'Static.Help.Module.Offering'
        }
    });

    $stateProvider.state('module.worklogs', {
        url: '/worklogs',
        views: {
            'module': {
                template: `
                    <uas-worklog 
                        module="module"
                        operations="operations">
                    </uas-worklog>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_WORKLOG'],
            title: 'Static.Tab.Module.Worklogs',
            label: 'Static.Help.Module.Worklogs'
        }
    });

    $stateProvider.state('module.resources', {
        url: '/resources',
        views: {
            'module': {
                template: `
                    <uas-module-resources
                        module-id="moduleId"
                        operations="operations"
                        operations-to-edit="EDIT_RESOURCES">
                    </uas-module-resources>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RESOURCES'],
            title: 'Static.Tab.Module.Resources',
            label: 'Static.Help.Module.Resources'
        }
    });

    $stateProvider.state('module.changes', {
        url: '/changes',
        parent: 'module',
        views: {
            'module': {
                controllerAs: 'stateController',
                controller: function($stateParams) {
                    const stateController = this;
                    stateController.entity = { 
                        type: 'module', 
                        id: $stateParams.id 
                    };
                },
                template: `
                    <uas-changes-detail 
                        entity="stateController.entity">
                    </uas-changes-detail>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CHANGE'],
            title: 'Static.Tab.Module.Changes',
            label: 'Static.Help.Module.Changes'
        }
    });

    $stateProvider.state('module.admin', {
        url: '/admin',
        views: {
            'module': {
                template: `
                    <uas-module-admin 
                        module="module" 
                        operations="operations">
                    </uas-module-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Module.Admin',
            label: 'Static.Help.Module.Admin'
        }
    });

    $stateProvider.state('module.preview', {
        url: '/preview',
        views: {
            'module': {
                template: `
                    <module-preview 
                        module-id="moduleId">
                    </module-preview>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULE_PREVIEW'],
            title: 'Static.Tab.Module.Preview',
            label: 'Static.Help.Module.Preview'
        }
    });

    $stateProvider.state('module.appraisals', {
        url: '/appraisals',
        views: {
            'module': {
                template: `
                    <appraisal-list 
                        module="module" 
                        operations="operations">
                    </appraisal-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_APPRAISALS'],
            title: 'Static.Tab.Module.Appraisals',
            label: 'Static.Help.Module.Appraisals'
        }
    });

    $stateProvider.state('module.links', {
        url: '/links',
        views: {
            'module': {
                template: `
                    <link-list 
                        entity="module" 
                        operations="operations">
                    </link-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_LINKS'],
            title: 'Static.Tab.Module.Links',
            label: 'Static.Help.Module.Links'
        }
    });

    $stateProvider.state('module.assets', {
        url: '/assets',
        views: {
            'module': {
                template: `
                    <asset-list 
                        module="module" 
                        operations="operations">
                    </asset-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ASSETS'],
            title: 'Static.Tab.Module.Assets',
            label: 'Static.Help.Module.Assets'
        }
    });

    $stateProvider.state('module.deliveries', {
        url: '/deliveries',
        views: {
            'module': {
                template: `
                    <delivery-report 
                        module="module">
                    </delivery-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ASSETS'],
            title: 'Static.Tab.Module.Deliveries',
            label: 'Static.Help.Module.Deliveries'
        }
    });
    
    $stateProvider.state('module.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'module': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: '<page-loader page-id="pageId" entity="module" operations="operations"></page-loader>'
            }
        },
        breadcrumb: {}
    });

});
