'use strict';

angular.module('uasApp').component('availabilityReport', {
    templateUrl: 'es6/staffing/availability/availability.report.html',
    bindings: {
        entity: '<',
        operations: '<',
        operationsToEdit: '<',
        hideFilter: '<?'
    },
    controllerAs: 'availabilityReportController',
    controller: function (Availability, AvailabilityModal, EntityService, AuthService, Language, Report) {
        const availabilityReportController = this;

        availabilityReportController.$onInit = function () {
            availabilityReportController.operationsToEdit_ = AuthService.buildOperationsToEdit(availabilityReportController.operationsToEdit, 'EDIT_RELATIONS');

            availabilityReportController.name = 'availability';
            if (availabilityReportController.entity) {
                availabilityReportController.name = `${availabilityReportController.entity.code}-availability`;
            }

            loadData();
            Language.onChange(loadData);
        };

        function loadData() {
            availabilityReportController.loading = true;
            return Availability.report({
                definition: true
            }).$promise.then((report) => {
                availabilityReportController.columns = report.headers;
                availabilityReportController.groups = report.groups;
            }).finally(() => {
                availabilityReportController.loading = false;
            });
        }

        availabilityReportController.onSearch = function (params) {
            if (availabilityReportController.params) {
                availabilityReportController.reload = {};
            }
            availabilityReportController.params = angular.copy(params) || {};
        };

        availabilityReportController.getRows = function (pageable) {
            availabilityReportController.pageable = pageable;

            const params = getParameters(pageable);
            return Availability.report(params).$promise;
        };

        function getParameters(pageable) {
            const params = _.extend(pageable, {
                studyId: availabilityReportController.params.studyId,
                term: availabilityReportController.params.term,
                roleId: availabilityReportController.params.roleId,
                attention: availabilityReportController.params.attention,
                complete: availabilityReportController.params.complete,
                vacancy: availabilityReportController.params.vacancy,
                active: true
            });

            return EntityService.extend(params, availabilityReportController.entity);
        }

        availabilityReportController.decorate = function(row) {
            const percentage = Report.unwrap(row.data.allocatedPercentage) || 0;
            const complete = Report.unwrap(row.data.complete) === true;

            let decorations = {};
            if (complete) {
                decorations.allocatedPercentage = {
                    text: 'text-success'
                };
            } else if (percentage > 100) {
                decorations.allocatedPercentage = {
                    text: 'text-danger',
                    icon: 'exclamation-triangle'
                };
            }
      
            return decorations;
        };

        availabilityReportController.calculate = function () {
            const params = getParameters({});
            
            availabilityReportController.loading = true;
            Availability.calculate(params, {}).$promise.finally(() =>
                availabilityReportController.loading = false
            );
        };

        availabilityReportController.open = function (row) {
            Availability.get({ id: row.entity.id }).$promise.then((availability) => {
                AvailabilityModal.open({
                    assignmentId: _.result(availability, 'assignment.id'),
                    operationsToEdit: availabilityReportController.operationsToEdit,
                    onChange: () => availabilityReportController.onSearch(availabilityReportController.params)
                });
            });
        };
    }
});