'use strict';

angular.module('uasApp').component('subjectCategoryList', {
    bindings: {
        typeId: '<?',
        owner: '<?',
        onSelect: '&'
    },
    templateUrl: 'es6/subjects/category/subject.category.list.html',
    controllerAs: 'subjectCategoryListController',
    controller: function ($uibModal, EntityService, Pageable, SubjectCategory, Message) {
        const subjectCategoryListController = this;

        subjectCategoryListController.$onInit = function() {
            subjectCategoryListController.pageable = Pageable.of({
                order: ['sequence', 'code']
            });

            subjectCategoryListController.search();
        };

        subjectCategoryListController.search = function() {
            loadData().then(() => 
                autoSelect()
            );
        };

        function loadData() {
            const params = subjectCategoryListController.pageable.build({
                typeId: subjectCategoryListController.typeId
            });

            if (angular.isDefined(subjectCategoryListController.owner)) {
                params.entityType = EntityService.getType(subjectCategoryListController.owner);
                params.entityId = subjectCategoryListController.owner.id;
            }

            subjectCategoryListController.loading = true;
            return SubjectCategory.search(params).$promise.then((categories) => {
                subjectCategoryListController.categories = categories;
            }).finally(() => {
                subjectCategoryListController.loading = false;
            });
        }

        function autoSelect() {
            if (angular.isUndefined(subjectCategoryListController.selected)) {
                const category = _.first(subjectCategoryListController.categories.content);
                subjectCategoryListController.select(category);
            }
        }

        subjectCategoryListController.select = function(category) {
            subjectCategoryListController.selected = _.get(category, 'id');
            subjectCategoryListController.onSelect({ category });
        };

        subjectCategoryListController.create = function() {
            subjectCategoryListController.edit({
                owner: EntityService.toReference(subjectCategoryListController.owner),
                typeId: subjectCategoryListController.typeId
            });
        };

        subjectCategoryListController.edit = function(category) {
            $uibModal.open({
                template: `
                    <subject-category-edit 
                        category="modalController.category" 
                        on-saved="modalController.save()"
                        on-cancel="modalController.close()"
                        show-type="modalController.showType">
                    </subject-category-edit>
                `,
                controllerAs: 'modalController',
                controller: function ($uibModalInstance) {
                    const modalController = this;
                    modalController.category = angular.copy(category);
                    modalController.modal = $uibModalInstance;
                    modalController.showType = angular.isUndefined(subjectCategoryListController.typeId);

                    modalController.save = function() {
                        modalController.close();
                        subjectCategoryListController.search();
                        Message.onSaved();
                    };

                    modalController.close = function() {
                        modalController.modal.dismiss();
                    };
                }
            });
        };

        subjectCategoryListController.delete = function(category) {
            SubjectCategory.remove(category).$promise.then(() => {
                if (subjectCategoryListController.selected === category.id) {
                    delete subjectCategoryListController.selected;
                }
                subjectCategoryListController.search();
                Message.onRemoved();
            });
        };
    }
});
