'use strict';

angular.module('uasApp').component('createNoteButton', {
  bindings: {
    entity: '<',
    btnSize: '@?',
    onCreate: '&'
  },
  templateUrl: 'es6/note/create.note.button.html',
  controllerAs: 'createNoteButtonController',
  controller: function ($uibModal) {
    const createNoteButtonController = this;

    createNoteButtonController.$onInit = function () {
      createNoteButtonController.btnSize = createNoteButtonController.btnSize || '';
    };

    createNoteButtonController.openModal = function () {
      $uibModal.open({
        templateUrl: 'es6/note/create.note.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance, Message, Note) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.note = {
              entity: createNoteButtonController.entity
            };
          };

          modalController.create = function () {
            Note.save(
              modalController.note
            ).$promise.then((note) => {
              Message.onSaved();
              $uibModalInstance.close();
              createNoteButtonController.onCreate({ note });
            });
          };
        }
      });
    };
  }
});
