'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Organisation
 * @description
 * Organisation resource
 */
angular.module('uasApp').factory('Organisation', function(UasResource) {
    return UasResource('/organisations', {
        deleteFull: {
            method: 'DELETE',
            url: '/organisations/:id/full',
            isArray: false
        }
    });
});
