'use strict';

angular.module('uasApp').component('textareaAutoResize', {
  templateUrl: 'es6/app/textarea.auto.resize.html',
  bindings: {
    inputId: '@',
    name: '@',
    rows: '<?', // Default rows=1
    language: '@?',
    classes: '@?',
    isRequired: '<?',
    isReadOnly: '<?',
    isDisabled: '<?',
    isMentions: '<?',
    isFocus: '<?',
    modelOptions: '<?',
    maxLength: '<?',
    spellcheck: '<?',
    placeholder: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'textareaAutoResizeController',
  controller: function ($element) {
    const textareaAutoResizeController = this;

    textareaAutoResizeController.$onInit = function () {
      textareaAutoResizeController.divElement = $element.find('div')[0];

      textareaAutoResizeController.ngModelCtrl.$render = function () {
        textareaAutoResizeController.text = textareaAutoResizeController.ngModelCtrl.$modelValue;
        textareaAutoResizeController.divElement.dataset.replicatedValue = textareaAutoResizeController.text;
      };
    };

    textareaAutoResizeController.onChange = function () {
      textareaAutoResizeController.ngModelCtrl.$setViewValue(angular.copy(textareaAutoResizeController.text));
    };
  }
});
