'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:GeneratedValue
 * @description
 * The GeneratedValue service.
 */
angular.module('uasApp')
    .factory('GeneratedValue', function(UasResource) {
        return UasResource('/generated-values', {
            'available': {
                method: 'GET',
                url: '/generated-values/available',
                isArray: false
            }
        });
    });
