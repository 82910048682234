'use strict';

angular.module('uasApp').component('linkCategoryList', {
  bindings: {
    selected: '<',
    onSelect: '&'
  },
  templateUrl: 'es6/link/category/link.category.list.html',
  controllerAs: 'linkCategoryListController',
  controller: function ($uibModal, Pageable, Message, LinkCategory) {
    const linkCategoryListController = this;

    linkCategoryListController.$onInit = function () {
      linkCategoryListController.pageable = Pageable.of({
          order: 'sequence'
      });
        
      loadData();
    };

    function loadData() {
        const params = linkCategoryListController.pageable.build();

        linkCategoryListController.loading = true;
        return LinkCategory.page(params).$promise.then((categories) => {
          linkCategoryListController.categories = categories;

            if (angular.isUndefined(linkCategoryListController.category)) {
                const category = _.head(linkCategoryListController.categories.content);
                linkCategoryListController.select(category);
            }
        }).finally(() => {
          linkCategoryListController.loading = false;
        });
    }

    linkCategoryListController.search = function () {
      loadData();
    };

    linkCategoryListController.select = function (category) {
        if (angular.isDefined(category)) {
          linkCategoryListController.selected = category;
          linkCategoryListController.onSelect({ category });
        }
    };

    linkCategoryListController.create = function () {
      open({});
    };

    linkCategoryListController.edit = function (category) {
        open(category);
    };

    function open(category) {
      $uibModal.open({
        templateUrl: 'es6/link/category/link.category.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.category = angular.copy(category);

          modalController.save = function () {
            LinkCategory.store(modalController.category).$promise.then(() => {
                loadData();
                Message.onSaved();
            });

            $uibModalInstance.dismiss();
          };
        }
      });
    }

    linkCategoryListController.remove = function (category) {
        LinkCategory.remove(category).$promise.then(() => {
            loadData();
            Message.onRemoved();
        });
    };
  }
});
