'use strict';

angular.module('uasApp').component('methodTypeSelect', {
  bindings: {
    exam: '<',
    multipleValues: '<?',
    prefixProperty: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/methods/type/method.type.select.html',
  controllerAs: 'methodTypeSelectController',
  controller: function (i18nFilter, MethodType) {
    const methodTypeSelectController = this;

    methodTypeSelectController.$onInit = function () {
      methodTypeSelectController.ngModelCtrl.$render = () => {
        const value = methodTypeSelectController.ngModelCtrl.$modelValue;
        methodTypeSelectController.value = _.isString(value) ? parseInt(value) : value;
      };

      MethodType.query({
        academicYearId: sessionStorage.academicYear,
        exam: methodTypeSelectController.exam
      }).$promise.then((types) => {
        methodTypeSelectController.types = _(types).map((type) => {
          const name = i18nFilter(type.names) || type.code;
          return _.extend(type, { name });
        }).sortBy(['name', 'code', 'id']).value();
      });
    };

    methodTypeSelectController.onChange = function () {
      methodTypeSelectController.ngModelCtrl.$setViewValue(methodTypeSelectController.value);
    };
  }
});
