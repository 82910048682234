'use strict';

angular.module('uasApp').component('tasksReport', {
    templateUrl: 'es6/staffing/task/report/tasks.report.html',
    bindings: {
        entity: '<?',
        isReadOnly: '<?',
        workflowMode: '<?'
    },
    controllerAs: 'tasksReportController',
    controller: function (Task, TaskTemplate, TaskModal, Effort, EffortModal, Language, EntityService) {
        const tasksReportController = this;

        tasksReportController.$onInit = function() {
            TaskTemplate.query({
                academicYearId: sessionStorage.academicYear
            }).$promise.then((templates) => {
                tasksReportController.templates = templates;
                tasksReportController.tags = _(templates).map('tag').filter(_.isString).map(_.trim).uniq().sort().value();

                setTemplates();
            });

            const entityType = EntityService.getType(tasksReportController.entity);
            tasksReportController.entityType = entityType;
            tasksReportController.context = `${entityType}.tasks`;
            tasksReportController.editable = tasksReportController.isReadOnly !== true;
            
            tasksReportController.onLanguage = Language.onChange(tasksReportController.loadData);
            tasksReportController.loadData();
        };

        tasksReportController.$onDestroy = function() {
            tasksReportController.onLanguage();
        };

        tasksReportController.loadData = function() {
            tasksReportController.loading = true;
            Task.report({
                definition: true
            }).$promise.then((report) => {
                tasksReportController.columns = report.headers;
                tasksReportController.groups = report.groups;
            }).finally(() => {
                tasksReportController.loading = false;
            });
        };

        tasksReportController.onTag = function() {
            setTemplates();

            delete tasksReportController.templateId;
            tasksReportController.loadData();
        };

        function setTemplates() {
            if (tasksReportController.tag) {
                tasksReportController.templates_ = _.filter(tasksReportController.templates, { tag: tasksReportController.tag });
            } else {
                tasksReportController.templates_ = tasksReportController.templates;
            }
        }

        tasksReportController.getRows = function(pageable) {
            const parameters = _.extend(pageable, {
                academicYearId: sessionStorage.academicYear,
                templateId: tasksReportController.templateId,
                assignmentId: tasksReportController.assignmentId,
                tag: tasksReportController.tag
            });

            const extended = EntityService.extend(parameters, tasksReportController.entity);
            return Task.report(extended).$promise;
        };

        tasksReportController.create = function() {
            open({
                academicYearId: sessionStorage.academicYear,
                assignmentId: tasksReportController.assignmentId,
                templateId: tasksReportController.templateId
            });
        };

        tasksReportController.edit = function(row) {
            if (tasksReportController.editable) {
                Task.get({ id: row.entity.id }).$promise.then((task) => {
                    const form = angular.copy(task);
                    form.templateId = _.result(task, 'template.id');
                    return form;
                }).then(open);
            }
        };

        function open(task) {
            Effort.findByTask(task.id).then((effort) => {
                if (angular.isDefined(effort)) {
                    EffortModal.open({
                        effort,
                        isReadOnly: tasksReportController.isReadOnly,
                        workflowMode: tasksReportController.workflowMode,
                        onChange: tasksReportController.loadData
                    });
                } else {
                    TaskModal.open({
                        task,
                        isReadOnly: tasksReportController.isReadOnly,
                        workflowMode: tasksReportController.workflowMode,
                        onChange: tasksReportController.loadData
                    });
                }
            });
        }

    }
});