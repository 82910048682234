'use strict';

angular.module('uasApp')
    .component('uasDownloads', {
        templateUrl: 'es6/report/downloads.html',
        controllerAs: 'downloadsController',
        controller: function (Downloads, BackendService) {
            const downloadsController = this;

            downloadsController.$onInit = function () {
                downloadsController.downloads = [];
                loadData();
            };

            function loadData() {
                const downloadPath = BackendService.getBaseUrl() + '/downloads/contents';

                downloadsController.loading = true;
                Downloads.query().$promise.then((downloads) => {
                    downloadsController.downloads = _(downloads).map((download) => {
                        download.url = `${downloadPath}?name=${download.name}`;
                        return download;
                    }).sortBy('name').value();
                }).finally(() => {
                    downloadsController.loading = false;
                });
            }

            downloadsController.deleteAll = function() {
                Downloads.deleteAll().$promise.then(() => {
                    loadData();
                });
            };
        }
    });
