'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:booleanOptions
 * @description
 * Select options for a boolean
 */
angular.module('uasApp').component('enumOptions', {
    bindings: {
        selectId: '@?',
        size: '@?',
        type: '<?',
        constants: '<?',
        filter: '<?', // Option filter expression
        ordinalBased: '<?',
        autoSelect: '<?',
        displayType: '<?',
        dropdownAlign: '<?',
        classes: '@?',
        toggleAriaLabel: '@?',
        toggleLabel: '@?',
        isRequired: '<?',
        isDisabled: '<?',
        onFocus: '&?',
        onBlur: '&?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/app/forms/enum.options.html',
    controllerAs: 'enumOptionsController',
    controller: function(Enums, Language, enumFilter, $q) {
        const enumOptionsController = this;

        enumOptionsController.$onInit = function() {
            enumOptionsController.ngModelCtrl.$render = function() {
                enumOptionsController.value = enumOptionsController.ngModelCtrl.$modelValue || '';
            };

            enumOptionsController.order = enumOptionsController.ordinalBased ? 'ordinal' : 'name';
            enumOptionsController.onLanguage = Language.onChange(setOptions);
        };

        enumOptionsController.$onDestroy = function() {
            enumOptionsController.onLanguage();
        };

        enumOptionsController.$onChanges = function() {
            loadData();
        };

        function loadData() {
            getConstants().then(() => {
                setOptions();
                autoSelect();
            });
        }

        function getConstants() {
            if (angular.isDefined(enumOptionsController.constants)) {
                return $q.resolve(enumOptionsController.constants);
            }

            return Enums.constants(enumOptionsController.type).$promise.then((constants) => {
                enumOptionsController.constants = filterOptions(constants);
            });
        }

        function filterOptions(options) {
            if (_.isFunction(enumOptionsController.filter)) {
                return _.filter(options, enumOptionsController.filter);
            }

            return options;
        }

        function setOptions() {
            enumOptionsController.options = _(enumOptionsController.constants).map((constant, $index) => {
                return {
                    id: $index,
                    ordinal: $index,
                    value: constant,
                    name: enumFilter(constant)
                };
            }).sortBy(enumOptionsController.order).value();
        }

        function autoSelect() {
            if (enumOptionsController.autoSelect && _.isEmpty(enumOptionsController.value)) {
                const option = _.head(enumOptionsController.options);
                if (angular.isDefined(option)) {
                    enumOptionsController.value = option;
                    enumOptionsController.onChange();
                }
            }
        }

        enumOptionsController.onChange = function () {
            enumOptionsController.ngModelCtrl.$setViewValue(enumOptionsController.value);
        };
    }
});
