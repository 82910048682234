'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:Structure
 * @description Shows an interactive structure of an entity.
 */
angular.module('uasApp')
  .component('structure', {
    bindings: {
      entity: '<',
      operations: '<',
      moduleColumns: '<?',
      groupColumns: '<?',
      workflowMode: '<?',
      isReadOnly: '<?',
      isCompare: '<?',
      showPeriod: '<?',
      showInactive: '<?',
      showRedirect: '<?',
      markInactiveOfferings: '<?',
      showDescription: '<?',
      editDescription: '<?',
      hideEditOffering: '<?',
      expandAll: '<?',
      defaultSort: '<'
    },
    templateUrl: 'es6/planboard/structure.html',
    controllerAs: 'structureController',
    controller: function(Module, ModuleGroup, StudyModuleGroup, ModuleGroupModuleGroup, ModuleGroupModule, Structures, EntityType,
        EntityService, Events, SecurityService, Nodes, CalendarPeriod, Credits, Pageable, Parameter, pagedFilter) {
        const structureController = this;

        const GROUP_RESOURCES = {
            'study-module-group': StudyModuleGroup,
            'module-group-module-group': ModuleGroupModuleGroup
        };

        structureController.$onInit = function() {
            structureController.manager = Nodes.manager({
                build,
                load
            });

            const entityType = EntityService.getType(structureController.entity);
            if (entityType === 'study') {
                structureController.studyId = structureController.entity.id;
            } else {
                structureController.studyId = structureController.entity.studyId;
            }

            EntityType.get({
                rootType: 'module-group'
            }).$promise.then((groupType) => {
                structureController.groupType = groupType;
            });

            EntityType.get({
                rootType: 'module'
            }).$promise.then((moduleType) => {
                structureController.moduleType = moduleType;
            });

            structureController.operationsToEdit = structureController.workflowMode === true ?
                ['EDIT_MODULE_GROUP_MODULE_WORKFLOW', 'EDIT_MODULE_GROUP_MODULE_GROUP_WORKFLOW', 'EDIT_STUDY_MODULE_GROUP_WORKFLOW'] :
                ['EDIT_MODULE_GROUP_MODULE', 'EDIT_MODULE_GROUP_MODULE_GROUP', 'EDIT_STUDY_MODULE_GROUP'];

            structureController.permissions = structureController.workflowMode === true ? {
                editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_WORKFLOW', structureController.operations),
                editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP_WORKFLOW', structureController.operations),
                editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP_WORKFLOW', structureController.operations)
            } : {
                editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE', structureController.operations),
                editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP', structureController.operations),
                editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP', structureController.operations)
            };

            structureController.editable = structureController.isReadOnly !== true && _.some(structureController.permissions);
            structureController.active = structureController.showInactive !== true;
            structureController.redirect = structureController.showRedirect === true;
            structureController.groups = [];

            Parameter.load().then(() => {
                structureController.forceOwner = Parameter.getParameterAsBoolean('curriculum.force_owner');
                structureController.loadData();
            });
        };

        structureController.$onChanges = function() {
            const property = structureController.defaultSort || 'code';
            structureController.property = `module.${property}`;
        };

        structureController.loadData = function () {
            structureController.loading = true;
            structureController.manager.init({
                entity: structureController.entity,
                active: false,
                groupColumns: structureController.groupColumns
            }).then((groups) => {
                structureController.groups = groups;
                setAllActive();
            }).finally(() => {
                structureController.loading = false;
            });
        };

        function build(node, parent) {
            const result = Nodes.build(node, parent);
            const owned = isOwned(node.studyId);
            const allowed = owned || (angular.isUndefined(node.studyId) && !structureController.forceOwner);

            return _.extend(result, {
                owned,
                allowed,
                editable: hasPermissionToEdit(node) && allowed,
                open: _.some(structureController.open, node.entity),
                choiceRequired: result.required || isChoiceRequired(parent),
                pageable: Pageable.of({ order: 'index', pageSize: 999 }),
                target: {
                    type: 'module-group',
                    id: node.id
                }
            });
        }

        function hasPermissionToEdit(node) {
            const entity = node.entity || node;
            const entityType = EntityService.getType(entity);

            if (angular.isDefined(entityType)) {
                if (entityType === 'study-module-group') {
                    return structureController.permissions.editStudyModuleGroup;
                } else if (entityType === 'module-group-module-group') {
                    return structureController.permissions.editModuleGroupModuleGroup;
                }
            }
            return false;
        }

        function isOwned(studyId) {
            return studyId === structureController.studyId;
        }

        function isChoiceRequired(node) {
            return _.result(node, 'choiceType.required', false);
        }

        structureController.onPeriod = function() {
            reload();
        };

        structureController.isActive = function(node) {
            return node.changeType !== 'REMOVE';
        };

        structureController.onExpandAll = function (open) {
            return structureController.manager.expand(structureController.groups, open, (group) => group.owned === true || structureController.expandAll);
        };

        structureController.toggle = function(node, $event) {
            if (!Events.isClickableTarget($event)) {
                structureController.manager.toggle(node);
            }
        };

        structureController.onMode = function () {
            setAllActive();
        };

        function setAllActive() {
            structureController.activeGroups = _.filter(structureController.groups, isActive);
            _.forEach(structureController.activeGroups, setActive);
        }

        function setActive(group) {
            group.activeChildren = _.filter(group.children, isActive);
            group.activeModules = pagedFilter(_.filter(group.modules, isActive), group.pageable);
            _.forEach(group.activeChildren, setActive);
        }

        function isActive(node) {
            if (structureController.active) {
                return structureController.isActive(node);
            }

            return true;
        }

        function reload(groupId) {
            structureController.open = Nodes.getOpen(structureController.groups);
            if (angular.isDefined(groupId)) {
                const nodes = Nodes.getNodes(structureController.groups, {
                    target: {
                        type: 'module-group',
                        id: groupId
                    }
                });
                _.forEach(nodes, (node) => structureController.manager.reload(node));
            } else {
                structureController.loadData();
            }
        }

        structureController.onSort = function (group) {
            setActive(group);
        };

        structureController.addGroupEnabled = function (group) {
            return structureController.permissions.editModuleGroupModuleGroup === true &&
                _.get(group, 'groupType.groups') !== false &&
                _.get(group, 'choiceType.groups') !== false;
        };

        structureController.addModuleEnabled = function (group) {
            return structureController.permissions.editModuleGroupModule === true &&
                _.get(group, 'groupType.modules') !== false &&
                _.get(group, 'choiceType.modules') !== false;
        };

        structureController.addModule = function (group) {
            Structures.addModuleToGroup({
                studyId: structureController.studyId,
                facultyId: structureController.entity.facultyId,
                academicYearId: structureController.entity.academicYearId,
                groupId: group.target.id,
                showGroupAlert: true,
                operations: structureController.operations,
                operationsToEdit: structureController.operationsToEdit,
                onAddNewModule: function (module, message) {
                    addModule(module, group, message);
                },
                onAddExistingModule: function (module, message) {
                    addModule(module, group, message);
                },
                onDelete: function() {
                    reload(group.id);
                }
            });
        };

        structureController.addSelected = function (group) {
            const module = structureController.selected.module;
            const onChange = structureController.selected.onChange;

            if (angular.isDefined(module)) {
                addModule(module, group, '', onChange);
            }
        };

        function addModule(module, group, message, onChange) {
            return ModuleGroupModule.save({
                comment: {
                    message
                },
                content: {
                    moduleGroupId: group.id,
                    moduleId: module.id,
                    cluster: module.cluster,
                    required: module.required,
                    alternative: module.alternative,
                    values: module.values
                }
            }).$promise.then(() => {
                reload(group.id);

                if (_.isFunction(onChange)) {
                    onChange({ module, group });
                }
            });
        }

        structureController.editModuleInStructure = function (node) {
            Structures.editModuleInStructure({
                entity: {
                    id: node.id
                },
                module: node.module,
                operations: structureController.operations,
                operationsToEdit: structureController.operationsToEdit,
                onSave: () => reload(node.parentId),
                onCancel: () => reload(node.parentId)
            });
        };

        structureController.editModule = function (node) {
            Structures.editModule({
                id: node.module.id,
                onSave: () => reload(node.parentId),
                onCancel: () => reload(node.parentId)
            });
        };

        structureController.editOffering = function (module) {
            Structures.editOffering({
                module,
                hideEdit: structureController.hideEditOffering,
                operations: structureController.operations,
                workflowMode: structureController.workflowMode,
                onClose: () => reload(module.parentId)
            });
        };

        structureController.moveModule = function(module, group) {
            Structures.moveModule({
                entity: structureController.entity,
                moduleId: module.moduleId,
                studyId: structureController.studyId,
                groupId: group.id,
                open: Nodes.getOpen(structureController.groups),
                onSave: (targetId) => {
                    reload(targetId);
                    reload(group.id);
                }
            });
        };

        structureController.removeModule = function(module, group, node) {
            Structures.removeModule({
                studyId: structureController.studyId,
                module,
                group,
                entity: node,
                onChange: () => reload(node.parentId)
            });
        };

        structureController.restoreModule = function (node, message) {
            return ModuleGroupModule.restore({
                id: node.id
            }, {
                comment: {
                    message
                }
            }).$promise.then(() => {
                reload(node.moduleGroupId);
            });
        };

        structureController.restoreTerminatedModule = function (node, message) {
            Module.get({
                id: node.moduleId
            }).$promise.then((module) => {
                const content = _.extend(module, { terminated: false });
                Module.save({
                    comment: {
                        message: message
                    },
                    content
                }).$promise.then(() => {
                    reload(node.moduleGroupId);
                });
            });
        };

        structureController.addGroup = function (parent) {
            if (angular.isDefined(parent)) {
                addGroupToParent(parent);
            } else {
                const entityType = _.result(structureController.entity, 'self.type');
                if (entityType === 'study') {
                    addToStudy(structureController.entity);
                } else if (entityType === 'module-group') {
                    addGroupToParent(structureController.entity);
                }
            }
        };

        function addToStudy(study) {
            Structures.addGroupToStudy({
                study: study,
                operations: structureController.operations,
                operationsToEdit: structureController.operationsToEdit,
                workflowMode: structureController.workflowMode,
                onAdd: function (group, study_, message) {
                    return StudyModuleGroup.save({
                        comment: {
                            message
                        },
                        content: {
                            studyId: study_.id,
                            moduleGroupId: group.id,
                            phaseId: group.phaseId,
                            required: group.required,
                            description: group.description,
                            values: group.values
                        }
                    }).$promise.then(() => reload());
                }
            });
        }

        function addGroupToParent(parent) {
            Structures.addGroupToParent({
                parent: parent.id,
                studyId: structureController.studyId,
                operations: structureController.operations,
                operationsToEdit: structureController.operationsToEdit,
                workflowMode: structureController.workflowMode,
                onAdd: function (group, parent_, message) {
                    return ModuleGroupModuleGroup.save({
                        comment: {
                            message: message
                        },
                        content: {
                            parentId: parent_.id,
                            moduleGroupId: group.id,
                            phaseId: group.phaseId,
                            required: group.required,
                            description: group.description,
                            values: group.values
                        }
                    }).$promise.then(() => reload(parent_.id));
                }
            });
        }

        structureController.removeGroup = function(node) {
            const resource = GROUP_RESOURCES[node.entity.type];
            if (resource) {
                resource.save({
                    removes: [node.entity.id]
                }).$promise.then(() => {
                    reload(node.parentId);
                });
            }
        };

        structureController.restoreGroup = function (node, message) {
            const resource = GROUP_RESOURCES[node.entity.type];
            if (resource) {
                return resource.restore({
                    id: node.entity.id
                }, {
                    comment: {
                        message
                    }
                }).$promise.then(() => {
                    reload(node.parentId);
                });
            }
        };

        structureController.editGroupInStructure = function (node) {
            Structures.editGroupInStructure({
                entity: node.entity,
                group: {
                    id: node.id,
                    code: node.code,
                    localName: _.result(node, 'names.NL'),
                    englishName: _.result(node, 'names.EN')
                },
                operations: structureController.operations,
                operationsToEdit: structureController.operationsToEdit,
                onSave: () => reload(node.parentId),
                onCancel: () => reload(node.id)
            });
        };

        structureController.editGroup = function (node) {
            Structures.editGroup({
                id: node.id,
                entityType: node.entity.type,
                context: {
                    studyId: structureController.studyId,
                    groupId: node.parentId
                },
                workflowMode: structureController.workflowMode,
                onSave: () => reload(node.parentId),
                onCancel: () => reload(node.parentId)
            });
        };

        structureController.moveGroup = function(group) {
            Structures.moveGroup({
                entity: structureController.entity,
                studyId: structureController.studyId,
                groupId: group.id,
                parentId: group.parentId,
                open: Nodes.getOpen(structureController.groups),
                onSave: (targetId) => {
                    reload(targetId);
                    reload(group.parentId);
                }
            });
        };

        function load(node) {
            return ModuleGroup.modules({
                id: node.id,
                active: false,
                referenceDate: CalendarPeriod.getReferenceDate(structureController.period),
                fields: _.map(structureController.moduleColumns, 'id')
            }).$promise.then((modules) => {
                node.modules = _.map(modules, (module, index) => {
                    const moduleId = _.result(module, 'module.id');
                    const owned = isOwned(module.studyId);

                    module.source = {
                        type: 'module-group',
                        id: node.id
                    };

                    module.target = {
                        type: 'module',
                        id: moduleId
                    };

                    module.moduleId = moduleId;
                    module.moduleGroupId = node.id;
                    module.parentId = node.id;
                    module.owned = owned;
                    module.editable = (owned || (angular.isUndefined(module.studyId) && !structureController.forceOwner))
                      && structureController.permissions.editModuleGroupModule;
                    module.allowed = structureController.permissions.editModuleGroupModule;
                    module.index = index;
                    module.choiceRequired = module.required || isChoiceRequired(node);

                    const credits = _.result(module, 'credits', 0);
                    module.credits = credits;
                    module.difference = credits * getChangeFactor(module);

                    return module;
                });

                const moduleCredits = _(node.modules).filter(structureController.isActive).sumBy('credits');
                const childrenCredits = _(node.children).filter(structureController.isActive).sumBy((child) => Credits.getMinimum(child.credits));

                node.totalCredits = moduleCredits + childrenCredits;

                if (_.result(node, 'groupType.empty', false)) {
                    node.creditsValid = true;
                } else {
                    node.creditsValid = Credits.isValid(node.totalCredits, node.credits, isChoiceRequired(node));
                }

                setActive(node);
            });
        }

        function getChangeFactor(node) {
            const changeType = _.result(node, 'changeType');
            if (changeType === 'CREATE') {
                return 1;
            } else if (changeType === 'REMOVE') {
                return -1;
            } else {
                return 0;
            }
        }

        structureController.select = function (module, onChange) {
            structureController.selected = { module, onChange };
        };

        structureController.some = function(objects, property) {
            return _.some(objects, property);
        };
    }
});
