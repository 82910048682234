'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:teamFields
 * @description Shows the team fields in a form.
 */
angular.module('uasApp').component('teamFields', {
  templateUrl: 'es6/team/team.fields.html',
  bindings: {
    team: '<',
    hideOwner: '<?',
    operations: '<'
  },
  controllerAs: 'teamFieldsController',
  controller: function (CustomField) {
    const teamFieldsController = this;

    const FIELDS = ['externalId', 'email', 'code', 'localName', 'englishName', 'startDate', 'endDate', 'owner'];

    const EXTENSIONS = [
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' }
    ];

    teamFieldsController.$onInit = function () {
      teamFieldsController.facultyId = _.get(teamFieldsController.team, 'owner.id');

      CustomField.query({
        rootType: 'team'
      }).$promise.then((fields) => {
        teamFieldsController.fields = CustomField.show(CustomField.extend(fields, EXTENSIONS), FIELDS);
        if (teamFieldsController.hideOwner) {
          CustomField.hide(teamFieldsController.fields, 'owner');
        }

        teamFieldsController.onEvaluate();
      });
    };

    teamFieldsController.onFaculty = function () {
      delete teamFieldsController.team.owner;

      if (angular.isDefined(teamFieldsController.facultyId)) {
        teamFieldsController.team.owner = {
          id: teamFieldsController.facultyId,
          type: 'faculty'
        };
      }
    };

    teamFieldsController.onEvaluate = function () {
      const entity = {
        type: 'team',
        id: _.get(teamFieldsController.team, 'id')
      };
      teamFieldsController.evaluation = CustomField.getEvaluation(
        entity,
        teamFieldsController.team,
        teamFieldsController.fields);
    };
  }
});
