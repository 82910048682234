'use strict';

angular.module('uasApp')
    .directive('maxTextLength', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                var maxlength = Number(attrs.maxTextLength);

                function fromUser(text) {
                    if (text) {
                        ngModelCtrl.$setValidity('maxlength', text.length <= maxlength);
                    }
                    return text;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    })
    .directive('minTextLength', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                var minlength = Number(attrs.minTextLength);

                function fromUser(text) {
                    if (text) {
                        ngModelCtrl.$setValidity('minlength', text.length > minlength);
                    }
                    return text;
                }
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    });
