'use strict';

/**
 * @ngdoc component
 * @name uasApp.controller:exportList
 * @description Shows all entities and changes to export in a context.
 */
angular.module('uasApp')
  .component('exportList', {
    bindings: {
      facultyId: '<',
      studyId: '<?',
      processTypeId: '<',
      legend: '<?'
    },
    templateUrl: 'es6/export/export.list.html',
    controllerAs: 'exportListController',
    controller: function ($uibModal, $q, EntityService, Export, ExternalSystem, ProcessType, Study) {

      const exportListController = this;

      exportListController.$onInit = function () {
        exportListController.loading = true;
        exportListController.legend = exportListController.legend || [];

        $q.all([
          Study.query({
            facultyId: exportListController.facultyId
          }).$promise,
          ExternalSystem.query().$promise,
          ProcessType.query().$promise
        ]).then(([studies, systems, processTypes]) => {
          exportListController.filterStudies = _.sortBy(studies, ['code', 'id']);
          exportListController.processTypes = processTypes;

          exportListController.system = _.find(systems, { defaultSelected: true });
          exportListController.systemId = _.result(exportListController.system, 'id');

          exportListController.parameters = {
            exportable: true,
            academicYearId: sessionStorage.academicYear,
            facultyId: exportListController.facultyId,
            studyId: exportListController.studyId,
            processType: exportListController.processTypeId,
            systemId: exportListController.systemId
          };
        }).finally(() => {
          exportListController.loading = false;
        });
      };

      exportListController.toggle = function (entry) {
        const studyId = entry.entity.studyId;

        if (angular.isDefined(studyId)) {
          const group = _.find(exportListController.studies, (study) => study.study.id === studyId);
          group.toggled = _.every(group.entities, { selected: true });
          group.severalToggled = _.some(group.entities, { selected: true }) && !group.toggled;
        } else {
          exportListController.unlinked.toggled = _.every(exportListController.unlinked.entities, { selected: true });
          exportListController.unlinked.severalToggled = _.some(exportListController.unlinked.entities, { selected: true }) && !exportListController.unlinked.toggled;
        }

        setSelectionStatus();
      };

      exportListController.toggleAllContainers = function () {
        _.each(exportListController.studies, (study) => {
          study.toggled = !exportListController.allSelected;
          selectAllInContainer(study);
        });

        exportListController.unlinked.toggled = !exportListController.allSelected;
        selectAllInContainer(exportListController.unlinked);

        setSelectionStatus();
      };

      exportListController.selectAll = function (container) {
        selectAllInContainer(container);
        setSelectionStatus();
      };

      function selectAllInContainer(container) {
        const selected = container.toggled === true;

        _.each(container.entities, (entity) => {
          entity.selected = selected;
        });
      }

      function setSelectionStatus() {
        exportListController.anySelected = _.some(exportListController.entities, { selected: true });

        exportListController.allSelected =
          _.every(exportListController.studies, { toggled: true }) &&
          (exportListController.unlinked.length ? (exportListController.unlinked.toggled === true) : true) &&
          _.every(exportListController.entities, { selected: true });
      }

      function isDefined(value) {
        return !!value;
      }

      exportListController.loadChanges = function () {
        exportListController.searched = angular.copy(exportListController.parameters);
        exportListController.changes = {};
        exportListController.entries = [];

        if (isDefined(exportListController.systemId)) {
          const settings = getSettings();

          exportListController.loading = true;
          Export.roots(exportListController.searched).$promise.then((entities) => {
            exportListController.entities = entities;

            _.each(exportListController.entities, (entity) => {
              const previous = _.find(settings.entities, {
                type: entity.entity.self.type,
                id: entity.entity.id
              });

              _.extend(entity, previous || { selected: false });
            });

            setGroups();
          }).finally(() => {
            exportListController.loading = false;
          });
        } else {
          exportListController.studies = [];
          exportListController.numberOfChanges = 0;
        }
      };

      function setGroups() {
        const settings = getSettings();
        const studies = exportListController.filterStudies;

        _.remove(exportListController.entities, { numberOfChanges: 0 });

        exportListController.studies = _(studies).map((study) => {
          const result = {
            study: study,
            entities: _.filter(exportListController.entities, (entity) => entity.entity.studyId === study.id)
          };

          result.numberOfChanges = _.sumBy(result.entities, 'numberOfChanges');
          return _.extend(result, _.find(settings.studies, { id: study.id }));
        }).filter((study) => study.numberOfChanges > 0).value();

        exportListController.unlinked = buildUnlinked(settings, exportListController.entities, studies);

        if (exportListController.studies.length === 0) {
          exportListController.unlinked.open = true;
        }

        exportListController.numberOfChanges = _.sumBy(exportListController.entities, 'numberOfChanges');
      }

      function buildUnlinked(settings, entities, studies) {
        const unlinked = _.filter(entities, (entity) => {
          const studyId = entity.entity.studyId;
          return angular.isUndefined(studyId) || !_.some(studies, { id: studyId });
        });

        const result = angular.copy(settings.unlinked);
        return _.extend(result, {
          entities: unlinked,
          numberOfChanges: _.sumBy(unlinked, 'numberOfChanges')
        });
      }

      function getSettings() {
        const settings = {
          unlinked: {},
          studies: [],
          entities: []
        };

        if (angular.isDefined(exportListController.unlinked)) {
          settings.unlinked = {
            open: exportListController.unlinked.open,
            toggled: exportListController.unlinked.toggled
          };
        }

        if (angular.isDefined(exportListController.studies)) {
          const studies = exportListController.studies || [];
          settings.studies = _.map(studies, (entry) => {
            return {
              id: entry.study.id,
              open: entry.open,
              toggled: entry.toggled
            };
          });
        }

        const entities = exportListController.entities;
        if (angular.isDefined(entities)) {
          settings.entities = _.map(entities, (entry) => {
            return {
              type: entry.entity.self.type,
              id: entry.entity.id,
              selected: entry.selected
            };
          });
        }

        return settings;
      }

      exportListController.open = function (root) {
        openExport([root]);
      };

      function onExport(lines) {
        _.forEach(lines, (line) => {
          if (line.status === 'COMPLETED') {
            exportListController.loading = true;
            loadChangesByRoot({
              entity: line.root
            }).then((data) => {
              const entities = _.filter(exportListController.entities, (row) => _.isEqual(row.entity.self, line.root));
              _.forEach(entities, (entity) => entity.numberOfChanges = data.changes.length);

              setGroups();
            }).finally(() => {
              exportListController.loading = false;
            });
          }
        });
      }

      function openExport(roots) {
        $uibModal.open({
          templateUrl: 'es6/export/changes.export.html',
          size: 'lg',
          resolve: {
            systemId: function () {
              return exportListController.systemId;
            },
            entries: function () {
              return angular.copy(roots);
            },
            loadChangesByRoot: function () {
              return loadChangesByRoot;
            },
            onExport: function () {
              return onExport;
            }
          },
          controller: 'ChangeExportCtrl'
        });
      }

      exportListController.openExportAll = function () {
        const selected = _.filter(exportListController.entities, { selected: true });
        if (selected.length > 0) {
          openExport(selected);
        }
      };

      exportListController.openExportStudy = function (study, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        $uibModal.open({
          templateUrl: 'es6/export/changes.export.all.html',
          resolve: {
            systemId: function () {
              return exportListController.systemId;
            },
            study: function () {
              return study;
            },
            loadChangesByRoot: function () {
              return loadChangesByRoot;
            },
            onExport: function () {
              return onExport;
            }
          },
          controller: 'ChangeExportAllCtrl'
        });
      };

      function loadChangesByRoot(root) {
        const parameters = angular.copy(exportListController.parameters);
        parameters.rootType = root.entity.type;
        parameters.rootId = root.entity.id;

        return Export.changes(parameters).$promise;
      }

      exportListController.openInNewTab = function (entity, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        EntityService.redirectPlain(entity.self, {
          target: '_blank'
        });
      };
    }
  });
