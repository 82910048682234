'use strict';

/**
 * Creates the default form label
 */
angular.module('uasApp')
    .component('formLabel', {
        bindings: {
            label: '<',
            labelDefault: '@?',
            infoLabel: '@?',
            infoLabelHtml: '@?',
            infoLabelType: '@?',
            isReadOnly: '<?',
            isRequired: '<?',
            requiredModel: '<?'
        },
        templateUrl: 'es6/app/forms/form.label.html',
        controllerAs: 'formLabelController',
        controller: function(Changes) {
            const formLabelController = this;

            formLabelController.$onChanges = function (changes) {
                if (Changes.hasChanged(changes, 'isReadOnly')) {
                    formLabelController.readOnly = formLabelController.isReadOnly === true;
                }

                if (Changes.hasChanged(changes, ['label', 'labelDefault'])) {
                    formLabelController.isLabelKey = (formLabelController.label || '').match(/([A-Za-z0-0]+(\.))+/g);
                }
            };
        }
    });
