'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:EmailClipboard
 * @description
 * The EmailClipboard factory. Opens a modal with a textarea to copy text to the clipboard.
 * If the value is an array, the values are joined with the default delimiter (semicolon).
 * Users can switch between a comma and semicolon delimiter in the modal.
 */
angular.module('uasApp')
  .factory('EmailClipboard', function($uibModal) {
    return {
      copy: function(value) {
        $uibModal.open({
          templateUrl: 'views/modals/email-clipboard.html',
          windowClass: 'email-clipboard',
          controllerAs: 'emailClipboardController',
          controller: function() {
            const emailClipboardController = this;

            emailClipboardController.$onInit = function() {
              emailClipboardController.initialValue = angular.copy(value);
              emailClipboardController.delimiter = ';';

              if (angular.isArray(emailClipboardController.initialValue)) {
                if (emailClipboardController.initialValue.length > 0) {
                  emailClipboardController.value = _.join(emailClipboardController.initialValue, emailClipboardController.delimiter + ' ');
                } else {
                  emailClipboardController.value = '';
                }
              } else {
                emailClipboardController.value = emailClipboardController.initialValue;
              }
            };

            emailClipboardController.setDelimiter = function(delimiter) {
              if (delimiter !== emailClipboardController.delimiter) {
                emailClipboardController.value = _.join(emailClipboardController.initialValue, delimiter + ' ');
                emailClipboardController.delimiter = delimiter;
              }
            };
          }
        });
      }
    };
  });
