'use strict';

angular.module('uasApp').component('linkCreate', {
  bindings: {
    entity: '<',
    categoryId: '<',
    onChange: '&'
  },
  templateUrl: 'es6/link/link.create.html',
  controllerAs: 'linkCreateController',
  controller: function (Link, LinkType, EntityService) {
    const linkCreateController = this;

    linkCreateController.$onInit = function() {
      linkCreateController.form = {};
      setTypes();
    };

    function setTypes() {
      const entityType = EntityService.getType(linkCreateController.entity);
      const sourceType = EntityService.getRootType(entityType);

      LinkType.query().$promise.then((types) => {
        linkCreateController.types = _(types).filter((type) => {
          return angular.isUndefined(linkCreateController.categoryId) || type.categoryId === linkCreateController.categoryId;
        }).filter((type) => {
          return !type.sourceType || type.sourceType === sourceType;
        }).value();

        if (linkCreateController.types.length === 1) {
          linkCreateController.form.typeId = _.head(linkCreateController.types).id;
          linkCreateController.onType();
        }
      });
    }

    linkCreateController.onType = function() {
      delete linkCreateController.form.target; 

      LinkType.find(linkCreateController.form.typeId).$promise.then((type) => {
        linkCreateController.type = type;

        if (type.targetType) {
          // Restrict targets on the configured type
          const targetType = EntityService.getEntityType(type.targetType);
          linkCreateController.targetTypes = [targetType];
        } else {
          linkCreateController.targetTypes = [];
        }
      });
    };

    linkCreateController.create = function() {
      const { typeId, target } = linkCreateController.form;
      if (angular.isUndefined(typeId) || angular.isUndefined(target)) {
        return;
      }

      const link = {
        entity: EntityService.toReference(linkCreateController.entity),
        typeId,
        target
      };

      return Link.store({
        content: link,
        comment: { message: '' }
      }).$promise.then(() => {
        linkCreateController.onChange();
        linkCreateController.setVisible(false);
        delete linkCreateController.form.target; 
      });
    };


    linkCreateController.setVisible = function(visible) {
      linkCreateController.visible = visible;
    };
  }
});