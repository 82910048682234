'use strict';

angular.module('uasApp')
    .component('relations', {
        templateUrl: 'es6/staffing/relation/relations.html',
        bindings: {
            entityType: '@',
            entityId: '<',
            types: '<?',
            operations: '<?',
            operationsToEdit: '<?',
            isReadOnly: '<?',
            inModal: '<?', // True if relations is used in modal
            inactive: '<?'
        },
        controllerAs: 'relationsController',
        controller: function($uibModal, $q, $scope, Relation, RoleType, AuthService, SecurityService, Language, Column,
            WorkflowValidator, entityTranslateFilter, translateFilter, CustomPage, AcademicYear, CustomField, Message, feedbackObserver) {

            const relationsController = this;

            relationsController.$onInit = function() {
                WorkflowValidator.setValidity(() => relationsController.relationForm);

                relationsController.active = relationsController.inactive !== true;

                const operationsToEdit = AuthService.buildOperationsToEdit(relationsController.operationsToEdit, 'EDIT_RELATIONS');
                const allowed = SecurityService.isAllowed(operationsToEdit, relationsController.operations);
                relationsController.editable = relationsController.isReadOnly !== true && allowed;
                
                setPropertyName();
                loadData();

                relationsController.onLanguage = Language.onChange(() => onLanguage());

                $scope.$watch('relationsController.relationForm', () => verify());
            };

            function setPropertyName() {
                relationsController.targetName = Language.getPropertyName();
            }

            function onLanguage() {
                setPropertyName();
                setFiltered();
            }

            relationsController.$onDestroy = function () {
                relationsController.onLanguage();
                WorkflowValidator.reset();
            };

            function loadData() {
                relationsController.loading = true;
                $q.all([
                    getRoles(),
                    Relation.query({
                        entityType: relationsController.entityType,
                        entityId: relationsController.entityId,
                        active: false
                    }).$promise,
                    AcademicYear.get({
                        id: sessionStorage.academicYear
                    }).$promise,
                    CustomPage.query({
                        basePath: 'relations'
                    }).$promise,
                    CustomField.query({
                        rootType: relationsController.entityType,
                        entityType: 'relation'
                    }).$promise
                ]).then(([roles, relations, academicYear, pages, fields]) => {
                    relationsController.roles = _.sortBy(roles, ['typeSequence', 'sequence', 'externalId', 'code', 'startDate', 'endDate']);
                    relationsController.relations = relations;
                    relationsController.year = academicYear;
                    relationsController.pages = pages;

                    relationsController.fields = fields;
                    relationsController.columns = Column.fromFields(fields);

                    relationsController.startDateVisible = _(fields).filter({ name: 'startDate' }).map('visible').first() === true;
                    relationsController.endDateVisible = _(fields).filter({ name: 'endDate' }).map('visible').first() === true;
                    relationsController.datesVisible = relationsController.startDateVisible && relationsController.endDateVisible;

                    AuthService.resecureAll();
                    relationsController.operationsToEdit_ = AuthService.buildOperationsToEdit(relationsController.operationsToEdit, 'EDIT_RELATIONS');
                    relationsController.isAllowedToEdit = SecurityService.isAllowed(relationsController.operationsToEdit_, relationsController.operations)
                        && _.some(relationsController.fields, { visible: true });

                    relationsController.isAllowedToView = SecurityService.isAllowed(AuthService.buildOperationsToView(relationsController.operationsToEdit_, 'VIEW_RELATIONS'), relationsController.operations);

                    verify();
                    setFiltered();
                }).finally(() => {
                    relationsController.loading = false;
                });
            }

            function getRoles() {
                return RoleType.query({
                    entityType: relationsController.entityType,
                    entityId: relationsController.entityId,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((roles) => {
                    let filteredRoles = roles;
                    if (!_.isEmpty(relationsController.types)) {
                        filteredRoles = _.filter(filteredRoles, (role) => _.includes(relationsController.types, role.code));
                        _.forEach(filteredRoles, (role) => role.typeSequence = _.indexOf(relationsController.types, role.code));
                    }
                    return filteredRoles;
                });
            }

            relationsController.copy = function(role) {
                const names = _(relationsController.filtered)
                    .filter({ roleId: role.id })
                    .map((relation) => entityTranslateFilter(relation.target))
                    .filter((target) => !_.isEmpty(target))
                    .join('\n');
                
                navigator.clipboard.writeText(names);
            };

            relationsController.canEdit = function(relation) {
                return relationsController.isAllowedToEdit && isActive(relation) && !relationsController.isReadOnly;
            };

            relationsController.showDeleteButton = function(relation) {
                return isActive(relation) && (!relation.external || !relationsController.datesVisible); 
            };

            relationsController.showEndButton = function(relation) {
                return isActive(relation) && !relation.endDate && relation.external && relationsController.datesVisible;
            };

            function isActive(relation) {
                return relation.changeType !== 'REMOVE';
            }

            function verify() {
                _.forEach(relationsController.roles, verifyRole);
                const valid = _(relationsController.roles).filter({ enforceMaximum: true }).map('errors').flatten().value().length === 0;
                if (angular.isDefined(relationsController.relationForm)) {
                    relationsController.relationForm.$setValidity('roles', valid);
                }
            }

            function verifyRole(role) {
                const errors = [];

                const relations = _(relationsController.relations).filter(isActive).filter({ roleId: role.id }).value();
                const dates = getDates(relations);
                _.forEach(dates, (date) => {
                    role.count = _.filter(relations, (relation) => isActiveOn(relation, date)).length;

                    if (angular.isDefined(role.minimum) && role.count < role.minimum) {
                        errors.push(translateFilter('Static.Tab.Relations.MinimumFailed', { minimum: role.minimum }));
                    }

                    if (angular.isDefined(role.maximum) && role.count > role.maximum) {
                        errors.push(translateFilter('Static.Tab.Relations.MaximumFailed', { maximum: role.maximum }));
                    }
                });

                role.errors = _.uniq(errors);
                role.status = getStatus(role, errors);
            }

            function isDefined(v) {
                return angular.isDefined(v) && v !== null;
            }

            function getDates(relations) {
                const year = {
                    startDate: relationsController.year.startDate,
                    endDate: relationsController.year.endDate
                };

                const dates = _(relations).map((relation) => [relation.startDate, relation.endDate]).flatten()
                                          .filter(isDefined)
                                          .map(getSurrounding).flatten()
                                          .map(getTime).filter((date) => isActiveOn(year, date)).value();

                const range = _([year.startDate, year.endDate]).filter(isDefined).map(getTime).value();
                dates.push(range);

                return _(dates).flatten().sort().uniq().value();
            }

            function getSurrounding(date) {
                const yesterday = new Date(date);
                yesterday.setDate(yesterday.getDate() - 1);

                const tomorrow = new Date(date);
                tomorrow.setDate(tomorrow.getDate() + 1);

                return [yesterday, date, tomorrow];
            }

            function isActiveOn(range, time) {
                const started = !isDefined(range.startDate) || getTime(range.startDate) <= time;
                const alive = !isDefined(range.endDate) || getTime(range.endDate) >= time;
                return started && alive;
            }

            function getTime(date) {
                return date.getTime();
            }

            function getStatus(role, errors) {
                let status = 'success';
                if (errors.length > 0) {
                    status = role.enforceMaximum === true ? 'danger' : 'warning';
                }
                return status;
            }

            relationsController.onMode = function () {
                setFiltered();
            };

            function setFiltered() {
                relationsController.filtered = _(relationsController.relations)
                    .filter((relation) => !relationsController.active || isActive(relation))
                    .orderBy(['active', (relation) => relation.startDate || '', (relation) => relation.endDate || '', relationsController.targetName], ['desc', 'asc', 'asc', 'asc'])
                    .value();
            }

            relationsController.openEditModal = function(relation, role) {
                $uibModal.open({
                    template: `
                        <uas-relation-edit
                            relation="modalController.relation"
                            role="modalController.role"
                            fields="modalController.fields"
                            on-save="modalController.onSave(relation)"
                            on-cancel="modalController.onCancel()"
                            operations="modalController.operations"
                            operations-to-edit="modalController.operationsToEdit"
                            in-modal="true">
                        </uas-relation-edit>
                    `,
                    size: 'lg',
                    controllerAs: 'modalController',
                    controller: function($uibModalInstance) {
                        const modalController = this;
                        modalController.relation = angular.copy(relation);
                        modalController.role = role;
                        modalController.fields = relationsController.fields;
                        modalController.operations = relationsController.operations;
                        modalController.operationsToEdit = relationsController.operationsToEdit_;

                        modalController.onSave = function(result) {
                            Relation.save({
                                comment: {
                                    message: ''
                                },
                                content: result
                            }).$promise.then(() => {
                                Message.addSuccessLabel('Static.Message.DataAdjusted');
                                feedbackObserver.dataChanged();
                                loadData();
                            }).finally(() => $uibModalInstance.close());
                        };

                        modalController.onCancel = function() {
                            $uibModalInstance.close();
                        };
                    }
                });
            };

            relationsController.openAddModal = function(role) {
                const relation = {
                    entity: {
                        type: relationsController.entityType,
                        id: relationsController.entityId
                    },
                    startDate: getDefaultStartDate(role)
                };

                relationsController.openEditModal(relation, role);
            };

            function getDefaultStartDate(role) {
                const defaultStartDate = _.get(role, 'defaultStartDate') === true;
                return defaultStartDate ? relationsController.year.startDate : undefined;
            }

            relationsController.remove = function(relation, comment) {
                Relation.save({
                    comment: {
                        message: comment
                    },
                    removes: [relation.id]
                }).$promise.then(() => {
                    Message.addSuccessLabel('Static.Message.DataRemoved');
                    feedbackObserver.dataChanged();
                    loadData();
                });
            };
        }
    });
