'use strict';

angular.module('uasApp').factory('StudyType', function (UasResource) {
    return UasResource('/study-types', {
        search: {
            method: 'GET',
            url: '/study-types/search'
        }
    });
});
