'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:simulationEdit
 * @description
 * simulationEdit Edit a simulation.
 */
angular.module('uasApp')
  .component('simulationEdit', {
    bindings: {
      simulation: '<'
    },
    templateUrl: 'es6/simulation/simulation.edit.html',
    controllerAs: 'simulationController',
    controller: function ($uibModal, Simulation, SimulationPerson, Session, AuthService, Message, AcademicYear, AcademicYearHolder) {
      const simulationController = this;

      simulationController.$onInit = function () {
        simulationController.owner = false;
        loadPersons();
      };

      simulationController.addPerson = function () {
        $uibModal.open({
          templateUrl: 'es6/simulation/simulation.edit.add.person.modal.html',
          controllerAs: 'simulationPersonController',
          controller: function ($uibModalInstance) {
            const simulationPersonController = this;
            simulationPersonController.roles = ['OWNER', 'READER'];

            simulationPersonController.add = function () {
              SimulationPerson.save({
                simulationId: simulationController.simulation.id,
                personId: simulationPersonController.personId,
                role: simulationPersonController.role
              }).$promise.then(() => {
                Message.onSaved();
                loadPersons();
              }).finally(() => {
                $uibModalInstance.dismiss();
              });
            };
          }
        });
      };

      simulationController.removePerson = function (person) {
        SimulationPerson.delete({
          id: person.id
        }).$promise.then(() => {
          Message.onRemoved();
          loadPersons();
        });
      };

      function loadPersons() {
        return SimulationPerson.query({
          simulationId: simulationController.simulation.id
        }).$promise.then((persons) => {
          simulationController.persons = persons;

          const owners = _(persons).filter({ role: 'OWNER' }).map('person').value();
          simulationController.owner = AuthService.isAdmin() || _.some(owners, { id: Session.personId });
        });
      }

      simulationController.save = function () {
        Simulation.store({
          id: simulationController.simulation.id,
          externalId: simulationController.simulation.externalId,
          code: simulationController.simulation.code,
          persons: _.map(simulationController.simulation.persons, buildPerson)
        }).$promise.then(() => {
          Message.onSaved();
        });
      };

      function buildPerson(wrapper) {
        return {
          person: _.result(wrapper.person, 'id'),
          role: wrapper.role
        };
      }

      simulationController.remove = function () {
        simulationController.loading = true;
        Simulation.remove(simulationController.simulation).$promise.then(() => {
          Message.onRemoved();
          return AcademicYear.get({
            id: simulationController.simulation.originId
          }).$promise.then((origin) => {
            AcademicYearHolder.transition(origin);
          });
        }).finally(() => {
          simulationController.loading = false;
        });
      };

    }
  });
