'use strict';

angular.module('uasApp').factory('Availability', function(UasResource) {
    return UasResource('/availability', {
        calculate: {
            url: '/availability/calculate',
            method: 'POST'
        },
        report: {
            url: '/availability-report',
            method: 'GET',
            isArray: false
        },
        mine: {
            url: '/availability/mine',
            method: 'GET',
            isArray: true
        }
    });
});
