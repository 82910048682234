'use strict';

angular.module('uasApp').component('subjectTypePage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/subjects/type/subject.type.page.html',
  controllerAs: 'subjectTypePageController',
  controller: function(Page) {
    const subjectTypePageController = this;

    subjectTypePageController.$onInit = function() {
      subjectTypePageController.operationsToEdit = ['EDIT_SUBJECT_TYPES'];
      subjectTypePageController.typeId = Page.getParameterAsInt(subjectTypePageController.page, 'type');
    };
  }
});