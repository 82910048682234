'use strict';

angular.module('uasApp').component('exportButton', {
  templateUrl: 'es6/export/export.button.html',
  bindings: {
    entity: '<',
    operations: '<',
    onExport: '&'
  },
  controllerAs: 'exportButtonController',
  controller: function (Export, ExternalSystem, ExportDetails) {
    const exportButtonController = this;

    exportButtonController.$onInit = function() {
      ExternalSystem.query().$promise.then((systems) => {
        exportButtonController.systems = systems;
      });
    };

    exportButtonController.start = function (system) {
      const root = {
        type: exportButtonController.entity.self.type,
        id: exportButtonController.entity.id
      };

      const command = {
        entities: [{
          reference: root
        }],
        root: root,
        full: true,
        academicYearId: sessionStorage.academicYear,
        systemId: system.id
      };

      exportButtonController.loading = true;
      Export.submit({}, command).$promise.then((lines) => {
        ExportDetails.show(lines);
        exportButtonController.onExport();
      }).finally(() => {
        exportButtonController.loading = false;
      });
    };
  }
});
