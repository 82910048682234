'use strict';

angular.module('uasApp')
    .component('uasChangeReportTemplate', {
        templateUrl: 'es6/report/change.report.template.html',
        controllerAs: 'reportTemplateController',
        controller: function (ChangeReportTemplate, Message, $uibModal, entityTranslateFilter, i18nFilter) {
            const reportTemplateController = this;

            reportTemplateController.$onInit = function() {
                reportTemplateController.templates = ChangeReportTemplate.query();
            };

            reportTemplateController.create = function() {
                reportTemplateController.edit({});
            };

            reportTemplateController.edit = function(template) {
                $uibModal.open({
                    templateUrl: 'es6/report/change.report.template.edit.modal.html',
                    resolve: {
                        descriptions: function(Element) {
                            return Element.query().$promise.then((elements) =>
                                _(elements)
                                    .filter((element) => _.endsWith(element.type, '_DESCRIPTION'))
                                    .forEach((element) => element.translation = entityTranslateFilter(element))
                            );
                        },
                        roles: function(RoleType) {
                            return RoleType.query({
                                academicYearId: sessionStorage.academicYear
                            }).$promise.then((roles) =>
                                _.forEach(roles, (role) => role.translation = i18nFilter(role.names) + ' (' + role.code + ')')
                            );
                        }
                    },
                    controllerAs: 'editTemplateController',
                    controller: function($uibModalInstance, descriptions, roles) {
                        const editTemplateController = this;
                        editTemplateController.template = template;
                        editTemplateController.descriptions = descriptions;
                        editTemplateController.roles = roles;

                        editTemplateController.save = function() {
                            ChangeReportTemplate.store(editTemplateController.template).$promise.then(function(result) {
                                if (angular.isUndefined(template.id)) {
                                    reportTemplateController.templates.push(result);
                                } else {
                                    _.extend(template, result);
                                }
                                Message.addSuccessLabel('Static.Message.DataSaved');
                                $uibModalInstance.close();
                            });
                        };
                    }
                });
            };

            reportTemplateController.remove = function(template) {
                ChangeReportTemplate.remove({
                    id: template.id
                }).$promise.then(function() {
                    _.remove(reportTemplateController.templates, (t) => t.id === template.id);
                    Message.addSuccessLabel('Static.Message.DataRemoved');
                });
            };
        }
    });
