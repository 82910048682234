'use strict';

angular.module('uasApp').factory('CommentModal', function ($uibModal) {

    function openModal(content, onSaveCallback, commentType, form) {
        $uibModal.open({
            controllerAs: 'modalController',
            controller: function ($uibModalInstance) {
                const modalController = this;
                modalController.modal = $uibModalInstance;
                modalController.onSave = onSaveCallback;
                modalController.content = content;
                modalController.commentType = commentType;
                modalController.form = form;
            },
            template: `
                <uas-comment-modal
                    modal="modalController.modal"
                    comment-type="modalController.commentType"
                    content="modalController.content"
                    on-save="modalController.onSave(content, comment, form)"
                    form="modalController.form">
                </uas-comment-modal>
            `
        });
    }

    return {
        openModal
    };

});
