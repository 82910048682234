'use strict';

angular.module('uasApp').component('filterPanel', {
  templateUrl: 'es6/app/filter/filter.panel.html',
  transclude: true,
  bindings: {
    defaultOpen: '<?',
    viewClasses: '<?',
    onToggle: '&'
  },
  controllerAs: 'filterPanelController',
  controller: function () {
    const filterPanelController = this;

    filterPanelController.$onChanges = function() {
        filterPanelController.open = filterPanelController.defaultOpen === true;
    };

    filterPanelController.toggle = function() {
        filterPanelController.open = filterPanelController.open !== true;
        filterPanelController.onToggle({ open: filterPanelController.open });
    };
  }
});
