'use strict';

angular.module('uasApp')
    .directive('resecured', function() {
        return {
            restrict: 'A',
            require: '?^secured',
            link: function(scope, elem, attrs, securedCtrl) {
                function updateSecurity() {
                    if (securedCtrl !== null) {
                        securedCtrl.resecure(elem);
                    }
                }

                updateSecurity();
                scope.$on('secure', updateSecurity);
            }
        };
    });
