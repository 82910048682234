'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('scheduled-activities', {
    url: '/scheduled-activities?:facultyId&:methodTypeId&:periodId&:studyId&',
    breadcrumb: {
      root: true,
      label: 'Static.Page.ScheduledActivities'
    },
    resolve: {
      facultyId: function ($stateParams) {
        return parse($stateParams, 'facultyId');
      },
      methodTypeId: function ($stateParams) {
        return parse($stateParams, 'methodTypeId');
      },
      periodId: function ($stateParams) {
        return parse($stateParams, 'periodId');
      },
      studyId: function ($stateParams) {
        return parse($stateParams, 'studyId');
      }
    },
    controllerAs: 'activitiesStateController',
    controller: function ($stateParams, facultyId, methodTypeId, periodId, studyId) {
      this.facultyId = facultyId;
      this.methodTypeId = methodTypeId;
      this.periodId = periodId;
      this.studyId = studyId;
    },
    template: `
      <scheduled-activities 
        faculty-id="activitiesStateController.facultyId" 
        period-id="activitiesStateController.periodId"
        method-type-id="activitiesStateController.methodTypeId"
        study-id="activitiesStateController.studyId">
      </scheduled-activities>
    `
  });

  function parse($stateParams, name) {
    const value = $stateParams[name];
    if (_.isEmpty(value)) {
      return undefined;
    }

    return parseInt(value);
  }

});
