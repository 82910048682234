'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:specificationPage
 * @description
 * Select or create a specification on an entity.
 */
angular.module('uasApp').component('specificationPage', {
    bindings: {
        entity: '<',
        page: '<',
        process: '<?',
        workflow: '<?',
        operations: '<'
    },
    templateUrl: 'es6/specification/specification.page.html',
    controllerAs: 'specificationPageController',
    controller: function(Page, EntityService, SecurityService, Specification, WorkflowModal) {
        const specificationPageController = this;

        specificationPageController.$onInit = function() {
            const workflowMode = angular.isDefined(specificationPageController.workflow);
            const operationsToEdit = workflowMode ? 'EDIT_WORKFLOW' : 'EDIT';

            specificationPageController.readOnly = Page.getParameterAsBoolean(specificationPageController.page, 'readOnly', false);
            specificationPageController.entityType = EntityService.getType(specificationPageController.entity);
            specificationPageController.editable = !specificationPageController.readOnly && SecurityService.isAllowed(operationsToEdit, specificationPageController.operations);
            specificationPageController.items = _.result(specificationPageController.page, 'items', []);

            reset();
        };

        function reset() {
            delete specificationPageController.select;
            delete specificationPageController.selected;

            setDefaultValues();
            loadData();
        }

        function setDefaultValues() {
            const template = _.pick(specificationPageController.entity,
                ['code', 'abbreviation', 'localName', 'englishName']
            );

            _.forEach(specificationPageController.items, (item) => {
                item.values = template[item.field.name];
            });
        }

        function loadData() {
            const specificationId = _.result(specificationPageController.entity, 'specificationId');
            specificationPageController.loading = true;
            Specification.find(specificationId).$promise.then((specification) => {
                specificationPageController.specification = specification;
                setEvaluation();
            }).finally(() => {
                specificationPageController.loading = false;
            });
        }

        specificationPageController.onChange = function() {
            setEvaluation();
        };

        function setEvaluation() {
            const entity = { type: 'specification' };
            specificationPageController.evaluation = Page.getEvaluation(entity, specificationPageController.items);
        }

        specificationPageController.setSelect = function(select) {
            specificationPageController.select = select;
        };

        specificationPageController.submit = function() {
            if (specificationPageController.select === true) {
                const specificationId = _.result(specificationPageController.selected, 'id');
                setSpecificationId(specificationId);
            } else {
                const form = {
                    entityType: 'specification',
                    academicYearId: sessionStorage.academicYear,
                    pageId: _.result(specificationPageController.page, 'id'),
                    items: Page.getItems({
                        items: specificationPageController.items,
                        operations: specificationPageController.operations,
                        operationsToEdit: ['CREATE_SPECIFICATION']
                    }),
                    comment: {
                        message: ''
                    }
                };

                Page.generate(form).$promise.then((specification) => {
                    const specificationId = _.result(specification, 'id');
                    setSpecificationId(specificationId);
                });
            }
        };

        function setSpecificationId(specificationId) {
            return Specification.connect({
                entityType: specificationPageController.entityType,
                entityId: specificationPageController.entity.id,
                specificationId,
                comment: {
                    message: ''
                }
            }).$promise.then(() => {
                _.set(specificationPageController.entity, 'specificationId', specificationId);
                reset();
            });
        }

        specificationPageController.remove = function() {
            setSpecificationId();
        };

        specificationPageController.edit = function() {
            WorkflowModal.open({
                entityType: 'specification',
                entity: angular.copy(specificationPageController.specification),
                onClose: loadData
            });
        };
    }
});
