'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:changesDetailsPage
 * @description
 * changesDetailsPage Shows changes
 */
angular.module('uasApp')
  .component('changesDetailsPage', {
    bindings: {
      entity: '<',
      operations: '<',
      page: '<',
      process: '<'
    },
    templateUrl: 'es6/changes/changes.details.page.html',
    controllerAs: 'changesDetailsPageController'
});
