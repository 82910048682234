'use strict';

angular.module('uasApp').component('buttonSpacer', {
  bindings: {
    btnSize: '@',
    iconClasses: '@?'
  },
  templateUrl: 'es6/app/buttons/button.spacer.html',
  controllerAs: 'buttonSpacerController'
});
