'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:FacultyStatistics
 * @description
 * The FacultyStatistics service.
 */
angular.module('uasApp').factory('FacultyStatistics', function (UasResource) {
  return UasResource('/faculty-statistics');
});
