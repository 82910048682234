'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityCreate
 * @description
 */
angular.module('uasApp').component('activityCreate', {
    bindings: {
        activity: '<',
        entity: '<',
        module: '<',
        period: '<',
        weeks: '<',
        fields: '<',
        categoryId: '<?',
        showCategory: '<?',
        disableType: '<?',
        operations: '<',
        onSelect: '&',
        onCancel: '&',
        onSubmit: '&'
    },
    templateUrl: 'es6/schedule/activity/form/activity.create.html',
    controllerAs: 'activityCreateController',
    controller: function(Activity) {
        const activityCreateController = this;

        activityCreateController.onType = function(type, category) {
            activityCreateController.category = category;
            activityCreateController.assessments = angular.copy(_.result(type, 'assessments'));
            activityCreateController.methods = angular.copy(_.result(type, 'methods'));

            activityCreateController.activity.typeId = _.result(type, 'id');
            activityCreateController.onSelect({ type });
        };

        activityCreateController.submit = function() {
            const activity = Activity.build(activityCreateController.activity, activityCreateController.weeks);
            activityCreateController.onSubmit({ activity });
        };

        activityCreateController.cancel = function() {
            activityCreateController.onCancel();
        };
    }
});
