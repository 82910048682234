'use strict';

angular.module('uasApp').component('subjectTypeList', {
    bindings: {
        typeId: '<?',
        owner: '<?'
    },
    templateUrl: 'es6/subjects/type/subject.type.list.html',
    controllerAs: 'subjectTypeListController',
    controller: function ($uibModal, Pageable, SubjectVersion, CustomType, Message, Dates) {
        const subjectTypeListController = this;

        subjectTypeListController.$onInit = function () {
            subjectTypeListController.active = 'true';
            subjectTypeListController.pageable = Pageable.of({
                order: 'type.code'
            });
        };

        subjectTypeListController.search = function() {
            const params = subjectTypeListController.pageable.build({
                text: subjectTypeListController.text || '',
                categoryId: subjectTypeListController.categoryId,
                active: subjectTypeListController.active
            });

            subjectTypeListController.loading = true;
            return SubjectVersion.search(params).$promise.then((versions) => {
                subjectTypeListController.versions = versions;
                Dates.setActive(versions.content);
            }).finally(() => {
                subjectTypeListController.loading = false;
            });
        };

        subjectTypeListController.onCategory = function(category) {
            subjectTypeListController.categoryId = _.result(category, 'id');
            
            const customTypeId = _.result(category, 'definition');
            if (customTypeId) {
                CustomType.find(customTypeId).$promise.then((customType) => {
                    subjectTypeListController.customType = customType;
                });
            }

            subjectTypeListController.search();
        };

        subjectTypeListController.create = function() {
            subjectTypeListController.edit({
                type: {
                    category: subjectTypeListController.categoryId,
                    names: []
                },
                descriptions: []
            });
        };

        subjectTypeListController.edit = function(version) {
            $uibModal.open({
                template: `
                    <subject-type-edit 
                        version="modalController.version" 
                        fields="modalController.fields" 
                        on-change="modalController.refresh()"
                        on-cancel="modalController.close()">
                    </subject-type-edit>
                `,
                resolve: {
                    fields: function($q, CustomField) {
                        const customTypeId = _.result(subjectTypeListController.customType, 'id');
                        if (angular.isUndefined(customTypeId)) {
                            return $q.resolve([]);
                        }

                        return CustomField.query({
                            type: customTypeId
                        }).$promise;
                    }
                },
                controllerAs: 'modalController',
                controller: function (fields, $uibModalInstance) {
                    const modalController = this;
                    modalController.version = angular.copy(version);
                    modalController.fields = fields;
                    modalController.modal = $uibModalInstance;

                    modalController.refresh = function() {
                        modalController.close();
                        subjectTypeListController.search();
                    };

                    modalController.close = function() {
                        modalController.modal.dismiss();
                    };
                }
            });
        };

        subjectTypeListController.delete = function(version) {
            SubjectVersion.remove(version).$promise.then(() => {
                subjectTypeListController.search();
                Message.onRemoved();
            });
        };
    }
});
