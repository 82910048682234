'use strict';

angular.module('uasApp')
    .directive('elementCheck', function(Element) {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/element-check.html',
            scope: {
                ngModel: '=',
                byDefault: '@',
                type: '@',
                cardinality: '@',
                checkId: '@?'
            },
            link: function(scope) {
                scope.checkId = scope.checkId || 'element';
                Element.getActive(scope.type).then(function(elements) {
                    scope.elements = elements;
                    if ((!scope.ngModel) && (scope.byDefault)) {
                        scope.ngModel = _.find(scope.elements, (e) => e.externalId === scope.byDefault).id;
                    }
                });

                scope.data = {};
                if (angular.isArray(scope.ngModel)) {
                    _.each(scope.ngModel, function(id) {
                        scope.data[id] = true;
                    });
                }

                // Manipulation

                scope.updateCheckbox = function(id) {
                    var value = !scope.data[id];
                    if (!scope.ngModel) {
                        scope.ngModel = [];
                    }
                    if (value === true) {
                        scope.ngModel.push(id);
                    } else {
                        var index = scope.ngModel.indexOf(id);
                        scope.ngModel.splice(index, 1);
                    }
                };

                scope.updateRadio = function(newId) {
                    scope.ngModel = newId;
                };
            }
        };
    });
