'use strict';

/**
 * @ngdoc service
 * @name uasServices.factory:Compare
 * @description
 * The Compare service.
 */
angular.module('uasApp')
    .factory('Compare', function(UasResource) {
        return UasResource('/compare', {
            'modules': {
                method: 'GET', 
                url: '/compare/modules',  
                isArray: true
            }
        });
    });
