'use strict';

angular.module('uasApp').component('periodPicker', {
  bindings: {
    inputId: '@',
    academicYearId: '<?',
    minPeriod: '<?',
    maxPeriod: '<?',
    classes: '<?',
    dropdownClasses: '<?',
    idModel: '<?',
    inModal: '<?',
    date: '@',
    isDisabled: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/calendar/period/period.picker.html',
  controllerAs: 'periodPickerController',
  controller: function ($q, AcademicYear, CalendarPeriod, configDateFilter) {
    const periodPickerController = this;

    periodPickerController.$onInit = function () {
      periodPickerController.resetFilter = {};

      if (angular.isDefined(periodPickerController.academicYearId)) {
        periodPickerController.yearId = _.toNumber(periodPickerController.academicYearId);
      }

      periodPickerController.ngModelCtrl.$render = render;
      minPeriodValidator();
      maxPeriodValidator();

      loadData();
    };

    function render() {
      loadPeriods().then(() => {
        const modelValue = periodPickerController.ngModelCtrl.$modelValue;
        delete periodPickerController.period;

        if (periodPickerController.idModel) {
          periodPickerController.period = getPeriod(modelValue);
        } else {
          periodPickerController.period = modelValue;
        }
      });
    }

    function minPeriodValidator() {
      periodPickerController.ngModelCtrl.$asyncValidators.minPeriod = function (modelValue, viewValue) {
        return validateModel(modelValue, viewValue, validateMinPeriod);
      };
    }

    function maxPeriodValidator() {
      periodPickerController.ngModelCtrl.$asyncValidators.maxPeriod = function (modelValue, viewValue) {
        return validateModel(modelValue, viewValue, validateMaxPeriod);
      };
    }

    function validateModel(modelValue, viewValue, validateCallback) {
      return loadPeriods().then(() => {
        let period = modelValue || viewValue;
        if (periodPickerController.idModel) {
          period = getPeriod(period);
        }
        if (!validateCallback(period)) {
          return $q.reject(false);
        }
        return true;
      });
    }

    function loadData() {
      AcademicYear.query().$promise.then((years) => {
        periodPickerController.years = _.filter(years, { enabled: true, simulation: false });
      });
    }

    periodPickerController.$onChanges = function () {
      validate();
    };

    function validate() {
      loadPeriods().then(() => {
        periodPickerController.ngModelCtrl.$setValidity('minPeriod', validateMinPeriod(periodPickerController.period));
        periodPickerController.ngModelCtrl.$setValidity('maxPeriod', validateMaxPeriod(periodPickerController.period));
      });
    }

    function loadPeriods() {
      if (!periodPickerController.periods) {
        return CalendarPeriod.query().$promise.then((periods) => {
          periodPickerController.periods = buildPeriods(periods);
          filterPeriods();
        });
      } else {
        return $q.resolve(periodPickerController.periods);
      }
    }

    function validateMinPeriod(period) {
      const minPeriod = getPeriod(periodPickerController.minPeriod);
      return angular.isUndefined(period) || angular.isUndefined(minPeriod) || period.startDate >= minPeriod.startDate;
    }

    function validateMaxPeriod(period) {
      const maxPeriod = getPeriod(periodPickerController.maxPeriod);
      return angular.isUndefined(period) || angular.isUndefined(maxPeriod) || period.startDate <= maxPeriod.startDate;
    }

    function getPeriod(id) {
      if (angular.isDefined(id)) {
        return _.find(periodPickerController.periods, { id });
      }
    }

    function buildPeriods(periods) {
      return _(periods).map((period) => {
        const date = periodPickerController.date === 'endDate' ? period.endDate : period.startDate;

        return _.extend(period, {
          date: date,
          displayName: `${period.code || period.period} (${configDateFilter(date)})`
        });
      }).sortBy(['date', 'displayName', 'id']).value();
    }

    periodPickerController.selectYear = function () {
      filterPeriods();
    };

    periodPickerController.filterName = function (filter) {
      periodPickerController.filter = filter;
      filterPeriods();
    };

    function filterPeriods() {
      periodPickerController.filteredPeriods = _.filter(periodPickerController.periods, (period) =>
        _.includes(_.toLower(period.displayName), _.toLower(periodPickerController.filter))
        && (!periodPickerController.yearId || period.academicYearId === periodPickerController.yearId)
      );
    }

    periodPickerController.isValid = function () {
      return periodPickerController.ngModelCtrl.$valid;
    };

    periodPickerController.toggleDropdown = function () {
      periodPickerController.resetFilter.reset();
      delete periodPickerController.yearId;
      periodPickerController.selectYear();
    };

    periodPickerController.selectPeriod = function (period) {
      periodPickerController.toggleDropdown();
      periodPickerController.period = period;

      if (periodPickerController.idModel) {
        periodPickerController.ngModelCtrl.$setViewValue(_.get(period, 'id'));
      } else {
        periodPickerController.ngModelCtrl.$setViewValue(angular.copy(period));
      }
    };

    periodPickerController.stopPropagation = function (event) {
      event.stopPropagation();
    };
  }
});