'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:selectAllButton
 * @description Shows a button that shows a select all or deselect all label depending on the state.
 */
angular.module('uasApp').component('selectAllButton', {
  bindings: {
    onClick: '&',
    amountSelected: '<',
    amountTotal: '<'
  },
  templateUrl: 'es6/app/buttons/select.all.button.html',
  controllerAs: 'selectAllButtonController',
  controller: function ($timeout, Changes) {
    const selectAllButtonController = this;

    selectAllButtonController.$onInit = function () {
      update();
    };

    selectAllButtonController.handleClick = function () {
      selectAllButtonController.loading = true;
      update();

      $timeout(() => {
        selectAllButtonController.onClick();
        selectAllButtonController.loading = false;
        update();
      });
    };

    selectAllButtonController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'amountSelected') || Changes.hasChanged(changes, 'amountTotal')) {
        update();
      }
    };

    function update() {
      selectAllButtonController.allSelected = selectAllButtonController.amountSelected === selectAllButtonController.amountTotal;

      if (selectAllButtonController.loading) {
        selectAllButtonController.label = 'Static.Label.Loading';
      } else {
        selectAllButtonController.label = selectAllButtonController.allSelected ? 'Static.Label.DeselectAll' : 'Static.Label.SelectAll';
      }
    }
  }
});
