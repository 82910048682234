'use strict';

angular.module('uasApp').config(function ($stateProvider) {
    const PUBLIC = {
        public: true,
        root: true,
        background: true
    };

    $stateProvider.state('support', {
        url: '/support',
        template: '<uas-login redirect="false"></uas-login>',
        data: PUBLIC
    });

    $stateProvider.state('token', {
        url: '/token?action',
        template: `
            <authenticate-token
                action="authenticationTokenStateController.action">
            </authenticate-token>
        `,
        data: PUBLIC,
        controllerAs: 'authenticationTokenStateController',
        controller: function($stateParams) {
            const authenticationTokenStateController = this;
            authenticationTokenStateController.action = $stateParams.action;
        }
    });

    $stateProvider.state('forbidden', {
        url: '/forbidden',
        template: '<uas-forbidden></uas-forbidden>',
        data: PUBLIC
    });

    $stateProvider.state('expired', {
        url: '/expired',
        template: '<uas-expired><uas-expired>',
        data: PUBLIC
    });

    $stateProvider.state('unauthorized', {
        url: '/unauthorized',
        template: '<uas-unauthorized message="Static.Page.Unauthorized.Subtext"></uas-unauthorized>',
        data: PUBLIC
    });

    $stateProvider.state('access-rules', {
        url: '/access-rules',
        breadcrumb: {
            label: 'Static.Page.AccessRules',
            root: true
        },
        template: '<access-rule-table></access-rule-table>',
        admin: true
    });

    $stateProvider.state('role-type-list', {
        url: '/role-types',
        breadcrumb: {
            label: 'Static.Page.RoleTypes',
            root: true
        },
        template: '<uas-role-type-list types="types"></uas-role-type-list>',
        admin: true
    });

    $stateProvider.state('operations-report', {
        url: '/operations-report',
        breadcrumb: {
            label: 'Static.Page.OperationsReport',
            root: true
        },
        template: '<operations-report></operations-report>',
        admin: true
    });

});
