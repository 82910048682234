'use strict';

angular.module('uasApp').component('referenceTypeList', {
  templateUrl: 'es6/reference/reference.type.list.html',
  controllerAs: 'referenceTypeController',
  controller: function ($state, $uibModal, Pageable, Message, ReferenceType) {
    const referenceTypeController = this;

    referenceTypeController.$onInit = function () {
      referenceTypeController.pageable = Pageable.of({
        order: 'code'
      });

      referenceTypeController.search();
    };

    referenceTypeController.search = () => {
      const params = referenceTypeController.pageable.build({
        text: referenceTypeController.referenceTypeSearch || ''
      });

      referenceTypeController.loading = true;
      return ReferenceType.page(params).$promise.then((referenceTypes) => {
        referenceTypeController.referenceTypes = referenceTypes;
      }).finally(() => {
        referenceTypeController.loading = false;
      });
    };

    referenceTypeController.create = function () {
      $uibModal.open({
        templateUrl: 'es6/reference/reference.type.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.type = {};
          };

          modalController.close = function () {
            $uibModalInstance.close();
          };

          modalController.save = function () {
            ReferenceType.save(modalController.type).$promise.then(() => {
              Message.onSaved();
              $uibModalInstance.dismiss();
              referenceTypeController.search();
            });
          };
        }
      });
    };

    referenceTypeController.edit = function (referenceType) {
      $state.go('reference-type.type', { id: referenceType.id });
    };

    referenceTypeController.remove = function (referenceType) {
      ReferenceType.remove(referenceType).$promise.then(() => {
        referenceTypeController.search();
        Message.addSuccessLabel('Static.Message.DataRemoved');
      });
    };
  }
});
