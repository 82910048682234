'use strict';

angular.module('uasApp').component('subjectTypeTooltip', {
    templateUrl: 'es6/subjects/type/subject.type.tooltip.html',
    bindings: {
        version: '<'
    },
    controllerAs: 'subjectTypeTooltipController',
    controller: function (i18nFilter, Language) {
        const subjectTypeTooltipController = this;

        subjectTypeTooltipController.$onInit = function() {
            subjectTypeTooltipController.onLanguage = Language.onChange(setDescription);
        };

        subjectTypeTooltipController.$onDestroy = function() {
            subjectTypeTooltipController.onLanguage();
        };

        subjectTypeTooltipController.$onChanges = function() {
            setTooltips();
            setDescription();
        };

        function setTooltips() {
            let tooltips = {};

            const values = _.get(subjectTypeTooltipController.version, 'values');
            if (!_.isEmpty(values)) {
                _(values).keys().sort().forEach((key) => {
                    tooltips[key] = _.join(values[key], ', ');
                });
            }

            subjectTypeTooltipController.tooltips = tooltips;
        }

        function setDescription() {
            const descriptions = _.get(subjectTypeTooltipController.version, 'descriptions', []);
            subjectTypeTooltipController.description = i18nFilter(descriptions);
        }
    }
});