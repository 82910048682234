'use strict';

angular.module('uasApp').component('availabilityReportPage', {
    bindings: {
        entity: '<',
        page: '<',
        operations: '<'
    },
    templateUrl: 'es6/staffing/availability/availability.report.page.html',
    controllerAs: 'availabilityReportPageController',
    controller: function(Page) {
        const availabilityReportPageController = this;

        availabilityReportPageController.$onInit = function() {
            availabilityReportPageController.hideFilter = Page.getParameterAsBoolean(availabilityReportPageController.page, 'hideFilter', false);
        };
    }
});
