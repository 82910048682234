'use strict';

angular.module('uasApp')
    .directive('goToChange', function(EntityService) {
        return {
            restrict: 'A',
            link: function(scope, elem, attrs) {
                elem.bind('click', function() {
                    var change = scope.$apply(attrs.goToChange);
                    EntityService.redirect(change.entity, change.root, {
                        type: 'change',
                        id: change.id
                    });
                });
            }
        };
    });
