'use strict';

angular.module('uasApp').component('assessmentCreate', {
    bindings: {
        values: '<',
        operations: '<?',
        operationsToEdit: '<?',
        btnLabel: '@?',
        btnSize: '@?',
        onChange: '&'
    },
    templateUrl: 'es6/assessment/assessment.create.html',
    controllerAs: 'assessmentCreateController',
    controller: function (SecurityService, $uibModal) {

        const assessmentCreateController = this;

        assessmentCreateController.$onChanges = function() {
            assessmentCreateController.authorized = SecurityService.isAllowed(assessmentCreateController.operationsToEdit, assessmentCreateController.operations);
        };

        assessmentCreateController.open = function() {
            open({
                academicYearId: sessionStorage.academicYear,
                values: {}
            });
        };

        function open(assessment) {
            $uibModal.open({
                template: `
                    <assessment-modal
                        assessment="assessmentStateController.assessment"
                        operations="assessmentStateController.operations"
                        operations-to-edit="assessmentStateController.operationsToEdit"
                        on-submit="assessmentStateController.save(assessment, comment)"
                        on-cancel="assessmentStateController.close()">
                    </assessment-modal>
                `,
                controllerAs: 'assessmentStateController',
                controller: function ($uibModalInstance, feedbackObserver, Assessment, Message) {
                    const modalController = this;
                    modalController.assessment = _.extend(assessment, assessmentCreateController.values);
                    modalController.operations = assessmentCreateController.operations;
                    modalController.operationsToEdit = assessmentCreateController.operationsToEdit;
            
                    modalController.save = function (result, comment) {
                        delete result.type;
            
                        return Assessment.save({
                            content: result,
                            comment: comment
                        }).$promise.then(() => {
                            feedbackObserver.dataChanged();
                            Message.onSaved();
                            modalController.close();
                            assessmentCreateController.onChange();
                        });
                    };
            
                    modalController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

    }
});
