'use strict';

angular.module('uasApp').component('effortFilter', {
  bindings: {
    entity: '<',
    onSearch: '&'
  },
  templateUrl: 'es6/staffing/effort/report/effort.filter.html',
  controllerAs: 'effortFilterController',
  controller: function ($q, EntityService, ModuleGroup, Period, Study) {
    const effortFilterController = this;

    effortFilterController.$onInit = function () {
      const entityType = EntityService.getType(effortFilterController.entity);
      if (entityType === 'study') {
        effortFilterController.studyId = effortFilterController.entity.id;
      }

      loadData().then(() => {
        effortFilterController.search();
      });
    };

    function loadData() {
      return $q.all([
        Period.query().$promise,
        Study.query({
          academicYearId: sessionStorage.academicYear
        }).$promise,
        getGroups()
      ]).then(([periods, studies, groups]) => {
        effortFilterController.periods = periods;
        effortFilterController.studies = studies;
        effortFilterController.groups = groups;
      });
    }

    function getGroups() {
      if (angular.isUndefined(effortFilterController.studyId)) {
        return $q.resolve([]);
      }

      return ModuleGroup.query({
        studyId: effortFilterController.studyId
      }).$promise;
    }

    effortFilterController.onStudy = function () {
      loadData().then(() => {
        delete effortFilterController.moduleGroupId;
        return effortFilterController.search();
      });
    };

    effortFilterController.search = function () {
      effortFilterController.onSearch({
        params: {
          studyId: effortFilterController.studyId,
          moduleGroupId: effortFilterController.moduleGroupId,
          periodId: effortFilterController.periodId,
          term: effortFilterController.term,
          attention: effortFilterController.attention,
          complete: effortFilterController.complete
        }
      });
    };
  }
});
