'use strict';

/**
 * @ngdoc filter
 * @name uasApp.filter:emptyToUndefined
 * @function
 * @description
 * Replaces empty strings with undefined. Can be used to group empty and undefined values together when sorting.
 * @param {String} value A value that might be an empty string.
 * @return {String} Undefined if the input is defined and empty, otherwise the original value.
 */
angular.module('uasApp')
  .filter('emptyToUndefined', function() {
  return function(value) {
    if (angular.isDefined(value) && _.isEmpty(value)) {
      return undefined;
    }

    return value;
  };
});
