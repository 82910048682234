'use strict';

angular.module('uasApp')
    .directive('mentions', function(Person) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                function init() {
                    if (attrs.mentions !== 'true') {
                        return;
                    }

                    $(element).textcomplete([{
                        words: [],
                        match: /\@(\w{2,})$/, // /@\w{2,}/g
                        search: function(term, callback) {
                            element.attr('overlay', 'overlay');
                            Person.page({
                                text: term,
                                active: true,
                                size: 5,
                                page: 1,
                                sort: 'asc'
                            }).$promise.then((result) => {
                                return result.content;
                            }).then(callback);
                        },
                        index: 1,
                        template: function(person) {
                            var template = '';
                            if (person.fullName) {
                                template += '<span class="badge">';
                                template += person.externalId;
                                template += '</span>';
                                template += ' ' + person.fullName;
                            } else {
                                template += person.externalId;
                            }
                            return template;
                        },
                        replace: function(person) {
                            return `[${person.externalId}]`;
                        }
                    }], {
                        onKeydown: function(e) {
                            if (e.keyCode === 13) {
                                element.attr('overlay', '');
                            }
                        }
                    });
                }

                init();
                scope.$watch('mentions', init);
            }
        };
    });
