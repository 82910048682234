'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:ModuleTabs
 * @description
 * # ModuleTabs
 * Tabs for modules
 */
angular.module('uasApp').factory('ModuleTabs', function() {
  return {
    get: function() {
      // Tabs which uses ui-router states to generate content.
      return [{
        view: 'general',
        route: 'module.general',
        readOnly: true
      }, {
        view: 'description',
        route: 'module.description'
      }, {
        view: 'teaching-method',
        route: 'module.teaching-method'
      }, {
        view: 'method-tree',
        route: 'module.method-tree'
      }, {
        view: 'assessments',
        route: 'module.assessments'
      }, {
        view: 'appraisals',
        route: 'module.appraisals'
      }, {
        view: 'offering',
        route: 'module.offering'
      }, {
        view: 'relations',
        route: 'module.relations'
      }, {
        view: 'additional',
        route: 'module.additional'
      }, {
        view: 'items',
        route: 'module.items'
      }, {
        view: 'capacity',
        route: 'module.capacity'
      }, {
        view: 'credits',
        route: 'module.credits'
      }, {
        view: 'documents',
        route: 'module.documents'
      }, {
        view: 'groups',
        route: 'module.groups'
      }, {
        view: 'studies',
        route: 'module.studies',
        outline: false
      }, {
        view: 'cost',
        route: 'module.cost'
      }, {
        view: 'cost-divisions',
        route: 'module.cost-divisions'
      }, {
        view: 'worklogs',
        route: 'module.worklogs'
      }, {
        view: 'resources',
        route: 'module.resources',
        outline: false
      }, {
        view: 'changes',
        route: 'module.changes'
      }, {
        view: 'admin',
        route: 'module.admin'
      }, {
        view: 'general',
        route: 'module.preview',
        readOnly: true
      }, {
        view: 'links',
        route: 'module.links'
      }, {
        view: 'assets',
        route: 'module.assets'
      }, {
        view: 'deliveries',
        route: 'module.deliveries'
      }];
    }
  };
});
