'use strict';

angular.module('uasApp').component('yearRollover', {
  bindings: {
    year: '<'
  },
  templateUrl: 'es6/admin/year/year.rollover.html',
  controllerAs: 'yearRolloverController',
  controller: function (AcademicYear, Faculty) {
    const yearRolloverController = this;

    yearRolloverController.$onInit = function() {
      loadData();
    };

    function loadData() {
      yearRolloverController.loading = true;
      Faculty.query({
        academicYearId: yearRolloverController.year.id
      }).$promise.then((faculties) => {
        yearRolloverController.faculties = _.sortBy(faculties, ['code', 'id']);
      }).finally(() => {
        yearRolloverController.loading = false;
      });
    }

    yearRolloverController.rollover = function() {
      const facultyIds = _(yearRolloverController.faculties).filter({ selected: true }).map('id').value();

      yearRolloverController.saving = true;
      AcademicYear.rollover({
        id: yearRolloverController.year.id
      }, {
        facultyIds
      }).$promise.finally(() => {
        yearRolloverController.saving = false;
        loadData();
      });
    };
  }
});