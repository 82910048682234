'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:studyModulesReport
 * @description
 * Shows study module group modules.
 */
angular.module('uasApp')
  .component('studyModulesReport', {
    bindings: {
      study: '<',
      clicked: '&?',
      refresh: '<?',
      isReadOnly: '<?',
      reportTemplate: '@?',
      hideReportTemplates: '<?',
      defaultMode: '<?'
    },
    templateUrl: 'es6/module/report/study.modules.report.html',
    controllerAs: 'studyModulesReportController',
    controller: function(Language, ModuleTabs, Report, ReportLinks) {
        const studyModulesReportController = this;

        studyModulesReportController.$onInit = function() {
            studyModulesReportController.readOnly = studyModulesReportController.isReadOnly === true;
            studyModulesReportController.mode = studyModulesReportController.defaultMode || 'USED_STUDY';

            loadData();
            Language.onChange(loadData);

            if (_.isObject(studyModulesReportController.refresh)) {
                studyModulesReportController.refresh.loadData = () => loadData();
            }
        };

        function loadData() {
            studyModulesReportController.loading = true;
            Report.moduleGroupModules({
                definition: true
            }).$promise.then((report) => {
                studyModulesReportController.columns = report.headers;
                studyModulesReportController.groups = report.groups;
            }).finally(() => {
                studyModulesReportController.loading = false;
            });
        }

        studyModulesReportController.onMode = function(mode) {
            studyModulesReportController.mode = mode;
            loadData();
        };

        studyModulesReportController.getRows = function(pageable) {
            const params = _.extend({
                studyId: studyModulesReportController.study.id,
                filter: studyModulesReportController.mode,
                language: Language.get()
            }, pageable);

            return Report.moduleGroupModules(params).$promise;
        };

        const moduleGeneralState = 'module.general';
        const states = {
            general: moduleGeneralState,
            additional: 'module.additional',
            credits: 'module.credits',
            capacity: 'module.capacity',
            description: 'module.description',
            relation: 'module.relations',
            costs: 'module.cost'
        };

        studyModulesReportController.onClick = function(row, col) {
            if (!studyModulesReportController.readOnly) {
                onClick(row, col);
            }
        };

        function onClick (row, col) {
            if (angular.isDefined(studyModulesReportController.clicked)) {
                studyModulesReportController.clicked({ row, col });
            } else {
                const state = states[col.group];
                if (!_.isEmpty(state)) {
                    ReportLinks.navigateTo(state, moduleGeneralState, 'module', 'module', row.entity.id, ModuleTabs.get());
                }
            }
        }
    }
});
