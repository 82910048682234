'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:specificationDetails
 * @description
 * Displays specification details
 */
angular.module('uasApp').component('specificationDetails', {
    bindings: {
        specification: '<'
    },
    templateUrl: 'es6/specification/specification.details.html',
    controllerAs: 'specificationDetailsController',
    controller: function ($q, Credits, Faculty, Organisation, Study) {
        const specificationDetailsController = this;

        specificationDetailsController.$onInit = function () {
            loadData();
        };

        function loadData() {
            specificationDetailsController.loading = true;
            $q.all([
                Faculty.find(
                    specificationDetailsController.specification.facultyId
                ).$promise,
                Organisation.find(
                    specificationDetailsController.specification.organisationId
                ).$promise,
                Study.query({
                    specificationId: specificationDetailsController.specification.id,
                    academicYearId: sessionStorage.academicYear
                }).$promise,
                Credits.get({
                    entityType: 'specification',
                    entityId: specificationDetailsController.specification.id
                }).$promise
            ]).then(([faculty, organisation, studies, credits]) => {
                specificationDetailsController.faculty = faculty;
                specificationDetailsController.organisation = organisation;
                specificationDetailsController.studies = studies;
                specificationDetailsController.credits = credits;
            }).finally(() => {
                specificationDetailsController.loading = false;
            });
        }
    }
});
