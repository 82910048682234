'use strict';

angular.module('uasApp').controller('AssignmentCtrl', function (assignment, operations) {
  const assignmentController = this;

  assignmentController.assignment = assignment;
  assignmentController.operations = operations;

  assignmentController.tabs = [{
    title: 'Static.Tab.Assignment.General',
    view: 'general',
    route: 'assignment.general',
    label: 'Static.Help.Assignment.General',
    readOnly: true
  }, {
    title: 'Static.Tab.Assignment.Availability',
    view: 'availability',
    route: 'assignment.availability',
    label: 'Static.Help.Assignment.Availability'
  }, {
    title: 'Static.Tab.Assignment.Relations',
    view: 'relations',
    route: 'assignment.relations',
    label: 'Static.Help.Assignment.Relations'
  }, {
    title: 'Static.Tab.Assignment.Notes',
    view: 'notes',
    route: 'assignment.notes',
    label: 'Static.Help.Assignment.Notes'
  }, {
    title: 'Static.Tab.Assignment.Admin',
    view: 'admin',
    route: 'assignment.admin',
    label: 'Static.Help.Assignment.Admin'
  }];
});
