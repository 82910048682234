'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCustomFieldList
 * @description
 * uasCustomFieldList Manages the custom fields
 */
angular.module('uasApp')
    .component('uasCustomPageList', {
        bindings: {
            pages: '<'
        },
        templateUrl: 'es6/custom/pages/custom.page.list.html',
        controllerAs: 'pagesListController',
        controller: function (Pageable, CustomPage, $uibModal) {
            const pagesListController = this;

            pagesListController.$onInit = function() {
                pagesListController.pageable = Pageable.of({
                    order: 'route'
                });
            };

            pagesListController.create = function () {
                pagesListController.edit({});
            };

            pagesListController.edit = function (page) {
                $uibModal.open({
                    templateUrl: 'es6/custom/pages/custom.page.edit.modal.html',
                    controllerAs: 'pageEditController',
                    controller: function($uibModalInstance, Message) {
                        this.page = angular.copy(page);
                        this.save = function() {
                            this.page.tabGroup = _.get(this.page.tabGroup, 'id');

                            CustomPage.update(this.page).$promise.then((result) => {
                                const index = pagesListController.pages.indexOf(page);
                                pagesListController.pages[index] = result;

                                _.extend(page, result);
                                Message.addSuccessLabel('Static.Message.DataAdjusted');
                                $uibModalInstance.close();
                            });
                        };
                        this.delete = function() {
                            CustomPage.remove({ id: page.id })
                              .$promise.then(function () {
                                const index = pagesListController.pages.indexOf(page);
                                pagesListController.pages.splice(index, 1);

                                Message.addSuccessLabel('Static.Message.DataRemoved');
                                $uibModalInstance.close();
                            });
                        };
                        this.canPageBeDeleted = function () {
                           return (angular.isUndefined(page.state) || !page.state) && angular.isDefined(page.id);
                        };
                    }
                });
            };
        }
    });
