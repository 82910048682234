'use strict';

/**
 * @ngdoc service
 * @name uasServices.factory:Calendar
 * @description
 * The Calendar service.
 */
angular.module('uasApp')
    .factory('Calendar', function(UasResource) {
        return UasResource('/calendars');
    });
