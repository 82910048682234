'use strict';

angular.module('uasApp').component('changeProgress', {
  bindings: {
    parameters: '<',
    status: '<?',
    onChange: '&?'
  },
  templateUrl: 'es6/changes/change.progress.html',
  controllerAs: 'changeProgressController',
  controller: function (Change, CHANGE_STATUS) {
    const changeProgressController = this;

    changeProgressController.$onInit = function() {
      loadData();
    };

    function loadData() {
      const parameters = angular.copy(changeProgressController.parameters || {});
      delete parameters.status;

      changeProgressController.total = 0;
      changeProgressController.loading = true;
      Change.progress(parameters).$promise.then((progress) => {
        const total = _(progress).map('amount').sum();
        changeProgressController.rows = _.map(progress, (row) => {
          row.percentage = (row.amount / total) * 100;
          row.color = _.result(CHANGE_STATUS[row.status], 'color', 'default');
          row.sequence = _.result(CHANGE_STATUS[row.status], 'sequence');
          return row;
        });
      }).finally(() => {
          changeProgressController.loading = false;
      });
    }

    changeProgressController.onClick = function(row) {
      if (changeProgressController.onChange) {
        changeProgressController.onChange({
          status: row.status
        });
      }
    };
  }
});
