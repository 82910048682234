'use strict';

angular.module('uasApp').directive('dateUtc', function (uasDateService) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$parsers.push(uasDateService.convert);
      ngModel.$formatters.push(uasDateService.convert);
    }
  };
});
