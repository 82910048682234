'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:referenceOptions
 * @description Select options for a specific reference type.
 */
angular.module('uasApp').component('referenceOptions', {
  bindings: {
    inputId: '@?',
    classes: '@?',
    typeId: '<?',
    filter: '<?', // Filter expression
    onChange: '&?',
    uasDisabled: '<?',
    uasRequired: '<?',
    isReadOnly: '<?',
    viewClasses: '@?',
    displayType: '<?',
    excludeIds: '<?',
    multipleValues: '<?',
    arrayModel: '<?', // Indicates if the ng-model is an array or a single value. Default = false
    evaluation: '<?',
    minItems: '<?',
    maxItems: '<?',
    filterValues: '<?', // Option filter function
    truncate: '<?' // If true read-only values will be truncated with ellipsis and tooltip on hover when content is too large
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/reference/reference.options.html',
  controllerAs: 'optionsController',
  controller: function ($q, Reference, referenceFilter, ReferenceList, Changes) {
    const optionsController = this;

    optionsController.$onInit = function () {
      optionsController.ngModelCtrl.$render = function () {
        optionsController.values = optionsController.ngModelCtrl.$modelValue;
      };
    };

    optionsController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'typeId')) {
        loadData();
      }
    };

    function loadData() {
      if (!optionsController.isReadOnly) {
        delete optionsController.references;

        if (optionsController.typeId) {
          $q.all([
            Reference.getActive(optionsController.typeId, optionsController.filter),
            ReferenceList.query({
              referenceTypeId: optionsController.typeId
            }).$promise
          ]).then(([references, lists]) => {
            optionsController.referenceLists = lists;
            optionsController.references = filterValues(references);
          });
        }
      }
    }

    function filterValues(references) {
      if (_.isFunction(optionsController.filterValues)) {
        return _.filter(references, optionsController.filterValues);
      }

      return references;
    }

    optionsController.formatValue = function (value) {
      return referenceFilter(value, optionsController.references);
    };

    optionsController.setNames = function (elements) {
      _.each(elements, (reference) => {
        _.set(reference, 'name', Reference.getName(reference));
      });
    };

    optionsController.onChange_ = function (values) {
      if (_.isFunction(optionsController.onChange)) {
        optionsController.onChange(values);
      }
    };
  }
});
