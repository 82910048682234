'use strict';

angular.module('uasApp').component('uasPageableList', {
  bindings: {
    onSearch: '&',
    onChange: '&',
    column: '@?',
    parameters: '<?',
    placeholderLabel: '@'
  },
  templateUrl: 'es6/planboard/add/pageable.list.html',
  controllerAs: 'listController',
  controller: function (Pageable, Changes) {
    const listController = this;

    listController.$onInit = function () {
      listController.searchText = '';
      listController.selections = [];
      listController.pageable = Pageable.of({
        order: 'code'
      });
      listController.search();
    };

    listController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'parameters') && angular.isDefined(listController.pageable)) {
        listController.search();
      }
    };

    /**
     * Populates the list of modules with the user input.
     */
    listController.search = function () {
      const parameters = _.extend({
        text: listController.searchText || '',
        studyId: _.result(listController.study, 'id'),
        year: sessionStorage.academicYear
      }, listController.parameters || {});

      listController.onSearch({
        parameters: listController.pageable.build(parameters)
      }).then((page) => {
        listController.page = page;
        
        // Auto select first if only one match
        if (page.totalElements === 1) {
          const single = _.head(page.content);
          listController.setSelected(single, true);
        }

        setSelectedContent();
      });
    };

    function setSelectedContent() {
      _.forEach(listController.page.content, (element) => {
        element.selected = _.some(listController.selections, { id: element.id });
      });
    }

    listController.setSelected = function(element, selected) {
      if (angular.isDefined(element)) {
        element.selected = selected === true;

        _.remove(listController.selections, { id: element.id });
        if (element.selected) {
          listController.selections.push(element);
        }

        listController.onChange({
          selections: listController.selections
        });
      }

      setSelectedContent();
    };
  }
});
