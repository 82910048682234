'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Script
 * @description
 * The Script service.
 */
angular.module('uasApp').factory('ScheduledScript', function(UasResource) {
    return UasResource('/schedule-scripts');
});
