'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:structureLegend
 * @description Shows a legend of items that can be displayed on structure or planboard pages.
 */
angular.module('uasApp')
  .component('structureLegend', {
    bindings: {
      showOriginal: '<?',
      showCurrent: '<?',
      showCreated: '<?',
      showModified: '<?',
      showRemoved: '<?',
      showDetails: '<?',
      showRead: '<?',
      showInfo: '<?',
      showDetailsExternal: '<?',
      showRequired: '<?',
      showReadOnly: '<?',
      showMove: '<?',
      showNotOwned: '<?',
      showEditInStructure: '<?',
      showEdit: '<?',
      showAdd: '<?',
      showUndo: '<?',
      showRemoveTimes: '<?',
      showRemoveTrash: '<?'
    },
    templateUrl: 'es6/planboard/legend/structure-legend.html',
    controllerAs: 'structureLegendController'
});
