'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:selectGroupHeader
 * @description Shows a vertically aligned header with a chevron to toggle the group.
 */
angular.module('uasApp').component('selectGroupHeader', {
  bindings: {
    isExpandable: '<',
    isOpen: '<',
    onClick: '&'
  },
  templateUrl: 'es6/app/forms/dropdown/select.group.header.html',
  transclude: true,
  controllerAs: 'selectGroupHeaderController'
});
