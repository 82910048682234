'use strict';

angular.module('uasApp')
    .component('breadcrumbs', {
        templateUrl: 'es6/app/navigation/breadcrumbs.html',
        controllerAs: 'breadcrumbsController',
        controller: function($rootScope, $state, $timeout, entityTranslateFilter, BreadcrumbsService, Session, Language) {
            const breadcrumbsController = this;

            breadcrumbsController.$onInit = function() {
                breadcrumbsController.breadcrumbs = undefined;
                breadcrumbsController.Session = Session;

                breadcrumbsController.onState = $rootScope.$on('$stateChangeSuccess', (event, toState) => {
                    addBreadcrumb(toState);
                });
    
                breadcrumbsController.onLanguage = Language.onChange(() => {
                    _.each(breadcrumbsController.breadcrumbs, (breadcrumb) => {
                        breadcrumb.label = breadcrumb.originalLabel;
                        breadcrumb.labelSet = false;
                        getLabelForBreadcrumb(breadcrumb, 0);
                    });
                });
    
                if (!breadcrumbsController.breadcrumbs) {
                    addBreadcrumb($state.current);
                }
            };

            breadcrumbsController.$onDestroy = function() {
                breadcrumbsController.onState();
                breadcrumbsController.onLanguage();
            };

            function checkLabelSet (breadcrumb, originalLabel, count) {
                breadcrumb.labelSet = (breadcrumb.label !== originalLabel && breadcrumb.label !== '');
                if (!breadcrumb.labelSet && !breadcrumb.label) {
                    breadcrumb.label = originalLabel;
                    $timeout(() => {
                        getLabelForBreadcrumb(breadcrumb, count);
                    });
                }
            }

            function getLabelForBreadcrumb(breadcrumb, count) {
                if (!breadcrumb.labelSet && count < 10) {
                    const originalLabel = breadcrumb.label;
                    if (breadcrumb.label) {
                        breadcrumb.translate = true;
                        checkLabelSet(breadcrumb, originalLabel, count);
                    } else if (angular.isDefined(breadcrumb.data)) {
                        const data = breadcrumb.data;
                        const name = entityTranslateFilter(data);

                        var label = '';
                        if (!_.isEmpty(data.code)) {
                            label += data.code;
                        }
                        if (!_.isEmpty(name)) {
                            label += (' ' + name);
                        }

                        breadcrumb.label = label;

                        checkLabelSet(breadcrumb, originalLabel, ++count);
                    }
                }
            }

            function refreshBreadcrumbs () {
                breadcrumbsController.breadcrumbs = BreadcrumbsService.getBreadcrumbs();
                _.each(breadcrumbsController.breadcrumbs, (breadcrumb) => {
                    getLabelForBreadcrumb(breadcrumb, 0);
                });
            }

            function addBreadcrumb (state) {
                if (_.result(state.breadcrumb, 'hide') !== true) {
                    BreadcrumbsService.addBreadcrumb(state);
                    refreshBreadcrumbs();
                }
            }
        }
    });
