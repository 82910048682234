'use strict';
/**
 * @ngdoc function
 * @name uasApp.component:uasPage
 * @component
 * # component
 * Only shows content when custom page is visible.
 *  * Usage:
 * <uas-page route="faculty.general"><button>Button</button></uas-page>
 *
 * Note that a directive is used instead of a component because using component resulted
 * in Javascript errors ($compile:multidir) when nesting uas-page elements.
 */
angular.module('uasApp').directive('uasPage', function() {
    return {
        transclude: true,
        template: `
            <div>
                <div ng-if="pageController.visible" 
                     ng-transclude>
                </div>

                <debug-only>
                    <div ng-if="!pageController.visible" class="m-b-15">
                        <code>
                            <i class="fa fa-ban"></i> {{ pageController.route }}
                        </code>
                    </div>
                </debug-only>
            </div>
        `,
        bindToController: {
            route: '@'
        },
        controllerAs: 'pageController',
        controller: function(CustomPage) {
            const pageController = this;

            pageController.$onInit = function() {
                CustomPage.get({
                    route: pageController.route
                }).$promise.then(function(page) {
                    pageController.visible = _.get(page, 'visible', true);
                });
            };
        }
    };
});
