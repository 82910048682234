'use strict';

angular.module('uasApp').component('checkboxList', {
  templateUrl: 'es6/reference/checkbox.list.html',
  bindings: {
    items: '<',
    propertyName: '@',
    inputId: '@',
    showTotal: '<?',
    showSelected: '<?'
  },
  require: {
    formCtrl: '^form',
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'checkboxListController',
  controller: function (i18nFilter) {
    const checkboxListController = this;

    checkboxListController.$onInit = function () {
      checkboxListController.ngModelCtrl.$render = () => {
        _.forEach(checkboxListController.items, (item) => {
          item.selected = angular.isDefined(_.find(checkboxListController.ngModelCtrl.$modelValue, [checkboxListController.propertyName, item.id]));

          if (!item.displayName && item.names) {
            item.displayName = i18nFilter(item.names);
          }
        });
        checkboxListController.filterItems('');
      };
    };

    checkboxListController.filterItems = function (filter) {
      checkboxListController.filteredItems = _.filter(checkboxListController.items, (item) =>
        _.includes(_.toLower(item.displayName), _.toLower(filter))
      );
      if (checkboxListController.showTotal) {
        checkboxListController.total = checkboxListController.filteredItems.length;
      }
    };

    checkboxListController.selectItems = function (selected) {
      _.forEach(checkboxListController.filteredItems, (item) => {
        item.selected = selected;
      });

      checkboxListController.formCtrl.$setDirty();
      checkboxListController.onChange();
    };

    checkboxListController.onChange = function () {
      const ids = _(checkboxListController.items)
        .filter((item) => item.selected)
        .map((item) => ({
          [checkboxListController.propertyName]: item.id
        }))
        .value();
      checkboxListController.ngModelCtrl.$setViewValue(ids);
    };
  }
});