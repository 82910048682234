'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processParticipantsList
 * @description
 * Shows the participants in a process.
 */
angular.module('uasApp').component('processParticipantsList', {
    bindings: {
        entity: '<',
        page: '<',
        process: '<',
        status: '<?'
    },
    templateUrl: 'es6/process/participants/process.participants.list.html',
    controllerAs: 'processParticipantsListController',
    controller: function (Participant) {
        const processParticipantsListController = this;

        processParticipantsListController.$onInit = function() {
          loadData();
        };

        function loadData() {
          processParticipantsListController.loading = true;
          Participant.query({
            entityType: processParticipantsListController.entity.self.type,
            entityId: processParticipantsListController.entity.id,
            processId: processParticipantsListController.process.id,
            statusTypeId: _.get(processParticipantsListController.status, 'id')
          }).$promise.then((participants) => {
            processParticipantsListController.participants = _.orderBy(participants, ['lastSeen', 'person.fullName']);
          }).finally(() => {
            processParticipantsListController.loading = false;
          });
        }
    }
});
