'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasStudyStructure
 * @description
 * uasStudyStructure Shows study structure tree.
 */
angular.module('uasApp')
  .component('uasStudyable', {
    bindings: {
      studyId: '<'
    },
    templateUrl: 'es6/study/study.studyable.html',
    controllerAs: 'studyableController',
    controller: function(Studyable, AuthService, Report, Language) {

        const studyableController = this;

        studyableController.$onInit = function() {
            AuthService.operations('study', studyableController.studyId).then(function(operations) {
                studyableController.operations = operations;
            });

            loadData();
        };

        studyableController.calculateAmountOfModules = function(programme) {
            return programme.filteredPeriods
                .map((period) => {
                    return period.modules.length;
                }).reduce((a, b) => { return a + b;}, 0);
        };

        function loadData() {
            studyableController.loadingProgramme = true;
            studyableController.loadingModules = true;

            Studyable.programme({
                studyId: studyableController.studyId
            }).$promise.then(function(programmes) {
                studyableController.programmes = programmes;
                filterProgrammes();
            }).finally(function() {
                studyableController.loadingProgramme = false;
            });

            Studyable.query({
                studyId: studyableController.studyId,
                language: Language.get()
            }).$promise.then(function(modules) {
                studyableController.modules = modules;
            }).finally(function() {
                studyableController.loadingModules = false;
            });
        }

        studyableController.toggle = function(programme, value) {
            programme.all = value;
            filterProgramme(programme);
        };

        function filterProgrammes() {
            _.each(studyableController.programmes, filterProgramme);
        }

        function filterProgramme(programme) {
            programme.filteredPeriods = _.filter(programme.blocks, function(block) {
                return programme.all === true || (isInValidPeriod(block) && !block.studyableIgnore);
            });
        }

        function isInValidPeriod(period) {
            return (period.numberOfRequiredCourses > 0 && period.numberOfCourses > 1) ||
                   (period.requiredCredits > period.maximumCredits);
        }

        studyableController.getReport = function(programme) {
            return Report.studyableProgramme({
                studyId: studyableController.studyId,
                moduleGroupId: programme.entity.moduleGroupId,
                incorrectOnly: !programme.all,
                language: Language.get()
            }).$promise;
        };
        
    }
});
