'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessModal
 * @description
 * uasProcessModal Edit the process
 */
angular.module('uasApp')
  .component('uasProcessModal', {
    bindings: {
      process: '<',
      type: '<',
      instance: '<'
    },
    templateUrl: 'es6/process/process.edit.modal.html',
    controllerAs: 'processEditController',
    controller: function (Process, Period, Message) {

      const processEditController = this;
      processEditController.$onInit = loadData;

      function loadData() {
        processEditController.dateRequired = true;
        processEditController.periods = Period.query({
          academicYearId: processEditController.process.academicYearId
        });
      }

      processEditController.save = function() {
        Process.store(processEditController.process).$promise.then(() => {
            Message.onSaved();
            processEditController.close();
        });
      };

      processEditController.close = function() {
        processEditController.instance.dismiss();
      };

    }
});
