'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:offeringReportPage
 * @description
 * descriptionReportPage Shows description for all studies that use the entity
 */
angular.module('uasApp').component('offeringReportPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/offerings/offering.report.page.html',
  controllerAs: 'offeringReportPageController',
  controller: function (Offering, EntityService, Study, Language) {
    const offeringReportPageController = this;

    offeringReportPageController.$onInit = function () {
      offeringReportPageController.rootType = EntityService.getType(offeringReportPageController.entity);
      offeringReportPageController.context = `${offeringReportPageController.rootType}.offerings`;
      offeringReportPageController.parameters = {};

      offeringReportPageController.onLanguage = Language.onChange(loadData);

      Study.query({
        facultyId: offeringReportPageController.entity.id,
        academicYearId: sessionStorage.academicYear
      }).$promise.then((studies) => {
        offeringReportPageController.studies = studies;
      });

      loadData();
    };

    offeringReportPageController.$onDestroy = function () {
      offeringReportPageController.onLanguage();
    };

    offeringReportPageController.search = function() {
      loadData();
    };

    function loadData() {
      offeringReportPageController.loading = true;
      Offering.report({
        entityType: offeringReportPageController.entityType,
        definition: true
      }).$promise.then((report) => {
        offeringReportPageController.columns = report.headers;
        offeringReportPageController.groups = report.groups;
      }).finally(() => {
        offeringReportPageController.loading = false;
      });
    }

    offeringReportPageController.getRows = function (pageable) {
      const parameters = getParameters(pageable);
      return Offering.report(parameters).$promise;
    };

    function getParameters(pageable) {
      let parameters = _.extend(offeringReportPageController.parameters, {
        academicYearId: sessionStorage.academicYear,
        rootType: offeringReportPageController.rootType,
        rootId: offeringReportPageController.entity.id,
        entityType: EntityService.getEntityType(offeringReportPageController.entityType),
        studyId: offeringReportPageController.studyId
      });

      return _.extend(pageable, parameters);
    }
  }
});
