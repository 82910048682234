'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Video
 * @description
 * The Video service.
 */
angular.module('uasApp')
    .factory('Video', function(UasResource) {
        return UasResource('/videos');
    });
