'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Evaluation
 * @description
 * The Evaluation service.
 */
angular.module('uasApp').factory('Evaluation', function(UasResource) {
  return UasResource('/evaluation', {
    feedbacks: {
      url: '/evaluation/feedback',
      method: 'GET',
      isArray: true
    },
    saveFeedback: {
      url: '/evaluation/feedback',
      method: 'POST'
    },
    participant: {
      url: '/evaluation/participant',
      method: 'GET',
      isArray: false
    }
  });
});