'use strict';

angular.module('uasApp').component('reportTemplateSelect', {
  bindings: {
    type: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/report/template/report.template.select.html',
  controllerAs: 'templateSelectController',
  controller: function ($q, ReportTemplate) {
    const templateSelectController = this;

    templateSelectController.$onInit = function () {
      templateSelectController.ngModelCtrl.$render = () => {
        templateSelectController.template = templateSelectController.ngModelCtrl.$modelValue;
      };
      loadTemplates();
    };

    function loadTemplates() {
      if (!templateSelectController.templates) {
        return ReportTemplate.query({
          type: templateSelectController.type
        }).$promise.then((templates) => {
          templateSelectController.templates = _.sortBy(templates, ['sequence', 'name']);
        });
      }
      return $q.resolve(templateSelectController.templates);
    }

    templateSelectController.onChange = function () {
      templateSelectController.ngModelCtrl.$setViewValue(templateSelectController.template);
    };
  }
});
