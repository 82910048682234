'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:StudyTabs
 * @description
 * # StudyTabs
 * Tabs for studies
 */
angular.module('uasApp').factory('StudyTabs', function() {
  return {
    get: function() {
      // Tabs which uses ui-router states to generate content.
      return [{
        view: 'general',
        route: 'study.general',
        readOnly: true
      }, {
        view: 'description',
        route: 'study.description'
      }, {
        view: 'additional',
        route: 'study.additional'
      }, {
        view: 'credits',
        route: 'study.credits'
      }, {
        view: 'capacity',
        route: 'study.capacity'
      }, {
        view: 'relations',
        route: 'study.relations'
      }, {
        view: 'qualifications',
        route: 'study.qualifications'
      }, {
        view: 'subject-types',
        route: 'study.subject-types'
      }, {
        view: 'structure',
        route: 'study.structure'
      }, {
        view: 'planner',
        route: 'study.planner'
      }, {
        view: 'planboard',
        route: 'study.planboard'
      }, {
        view: 'studyable',
        route: 'study.studyable'
      }, {
        view: 'cost',
        route: 'study.cost'
      }, {
        view: 'changes',
        route: 'study.changes'
      }, {
        view: 'groups',
        route: 'study.groups'
      }, {
        view: 'modules',
        route: 'study.modules'
      }, {
        view: 'modules',
        route: 'study.module-group-modules'
      }, {
        view: 'hours',
        route: 'study.hours'
      }, {
        view: 'cost-divisions',
        route: 'study.cost-divisions'
      }, {
        view: 'export',
        route: 'study.export'
      }, {
        view: 'admin',
        route: 'study.admin'
      }, {
        view: 'links',
        route: 'study.links'
      }, {
        view: 'deliveries',
        route: 'study.deliveries'
      }];
    }
  };
});
