'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:changesListPage
 * @description
 * changesListPage Shows changes in a list
 */
angular.module('uasApp')
  .component('changesListPage', {
    bindings: {
      entity: '<',
      operations: '<',
      page: '<',
      workflow: '<?',
      process: '<'
    },
    templateUrl: 'es6/changes/changes.list.page.html',
    controllerAs: 'changesListPageController',
    controller: function(Page) {
      const changesListPageController = this;

      changesListPageController.$onInit = function() {
        changesListPageController.hideEmpty = Page.getParameterAsBoolean(changesListPageController.page, 'hideEmpty', false);
        changesListPageController.showFilters = Page.getParameterAsBoolean(changesListPageController.page, 'showFilters', false);

        const filterProcess = Page.getParameterAsBoolean(changesListPageController.page, 'filterProcess', false);
        let processTypeId = Page.getParameterAsInt(changesListPageController.page, 'processTypeId');

        if (angular.isUndefined(processTypeId) && filterProcess) {
          processTypeId = _.get(changesListPageController.process, 'type.id');
        }

        changesListPageController.processTypeId = processTypeId;
      };
    }
  });
