'use strict';

/**
 * Rounds values.
 */
angular.module('uasApp').filter('round', function() {
    return function(value, limit) {
        return _.round(value, limit);
    };
});
