'use strict';

/**
 * Shows debug information about the permissions.
 */
angular.module('uasApp').component('permissionButton', {
    bindings: {
        requiredOperations: '<',
        operations: '<',
        isReadOnly: '<?',
        viewClasses: '<?'
    },
    controllerAs: 'permissionButtonController',
    templateUrl: 'es6/security/permission.button.html',
    controller: function(SecurityService, $uibModal) {
        const permissionButtonController = this;

        permissionButtonController.$onInit = function() {
            permissionButtonController.readOnly = permissionButtonController.isReadOnly === true;
            permissionButtonController.allowed = !permissionButtonController.readOnly && SecurityService.isAllowed(permissionButtonController.requiredOperations, permissionButtonController.operations);
            permissionButtonController.visible = permissionButtonController.readOnly || !_.isEmpty(permissionButtonController.requiredOperations);
            permissionButtonController.label = join(permissionButtonController.requiredOperations);
        };

        function join(operations) {
            if (_.isArray(operations)) {
                return _(operations).sort().join(', ');
            }

            return operations;
        }

        permissionButtonController.open = function() {
            $uibModal.open({
                template: `
                    <div class="modal-header">
                        <button type="button" class="close" ng-click="permissionModalController.close()"></button>
                        <h2 class="modal-title" translate>Static.Page.Permissions</h2>
                    </div>
                    <div class="modal-body">
                        <dl class="dl-horizontal">
                            <dt translate>Static.Page.Permissions.RequiredOperations</dt>
                            <dd>{{ permissionModalController.requiredOperations }}</dd>
                            <dt translate>Static.Page.Permissions.Operations</dt>
                            <dd>{{ permissionModalController.operations }}</dd>
                        </dl>
                    </div>
                `,
                controllerAs: 'permissionModalController',
                controller: function($uibModalInstance) {
                    const permissionModalController = this;
                    permissionModalController.requiredOperations = join(permissionButtonController.requiredOperations);
                    permissionModalController.operations = join(permissionButtonController.operations);
                    permissionModalController.close = () => $uibModalInstance.dismiss();
                }
            });
        };
    }
});
