'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:changeMode
 * @description Shows two buttons to toggle between active and inactive states.
 * When the toggle changes, the ngModelCtrl is updated, and the parent controller can react by binding a ng-change.
 */
angular.module('uasApp').component('changeMode', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/changes/change.mode.html',
  controllerAs: 'changeModeController',
  controller: function () {
    const changeModeController = this;

    changeModeController.$onInit = function () {
      changeModeController.ngModelCtrl.$setDirty = _.noop;

      changeModeController.ngModelCtrl.$render = () => {
        changeModeController.active = changeModeController.ngModelCtrl.$viewValue;
      };
    };

    changeModeController.updateViewValue = function () {
      changeModeController.ngModelCtrl.$setViewValue(changeModeController.active);
    };
  }
});
