'use strict';

angular.module('uasApp').component('typeList', {
  templateUrl: 'es6/type/type.list.html',
  bindings: {
    validator: '@',
    headerLabel: '@',
    helpLabel: '@',
    icon: '@',
    resource: '<'
  },
  controllerAs: 'typeListController',
  controller: function ($uibModal, Pageable, Message) {
    const typeListController = this;

    typeListController.$onInit = () => {
      typeListController.pageable = Pageable.of({
        order: 'sequence'
      });
      typeListController.search();
    };

    typeListController.search = () => {
      const params = typeListController.pageable.build({
        text: typeListController.text || ''
      });

      typeListController.loading = true;
      typeListController.resource.search(params).$promise.then((types) => {
        typeListController.types = types;
      }).finally(() => {
        typeListController.loading = false;
      });
    };

    typeListController.create = function () {
      open({});
    };

    typeListController.edit = function (type) {
      typeListController.resource.get({ id: type.id }).$promise.then(open);
    };

    function open(type) {
      $uibModal.open({
        size: 'lg',
        template: `
          <type-edit
            type="typeModalController.type"
            on-save="typeModalController.save()"
            on-cancel="typeModalController.close()"
            header-label="{{ typeModalController.headerLabel }}"
            validator="{{ typeModalController.validator }}">
          </type-edit>`,
        controllerAs: 'typeModalController',
        controller: function ($uibModalInstance) {
          const typeModalController = this;
          typeModalController.type = type;
          typeModalController.validator = typeListController.validator;
          typeModalController.headerLabel = typeListController.headerLabel;

          typeModalController.save = function () {
            typeListController.resource.store(type).$promise.then(() => {
              typeModalController.close();
              typeListController.search();
              Message.onSaved();
            });
          };

          typeModalController.close = function () {
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    typeListController.delete = function (type) {
      typeListController.resource.delete(type).$promise.then(() => {
        typeListController.search();
        Message.onRemoved();
      });
    };
  }
});
