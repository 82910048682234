'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessList
 * @description
 * uasProcessList Shows the processes
 */
angular.module('uasApp')
  .component('uasProcessTypeList', {
    templateUrl: 'es6/process/process.type.list.html',
    controllerAs: 'processListController',
    controller: function (ProcessType, Message) {
      const processListController = this;

      processListController.$onInit = function() {
        loadData();
      };

      function loadData() {
        processListController.loading = true;
        ProcessType.query().$promise.then((processes) => {
          processListController.processes = processes;
        }).finally(() => {
          processListController.loading = false;
        });
      }

     processListController.remove = function(data) {
        ProcessType.remove({ id: data.id }).$promise.then(() => {
            _.remove(processListController.processes, { id: data.id });
            Message.onRemoved();
        });
      };
    }
});
