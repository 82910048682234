'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionPreview
 * @description
 * descriptionPreview Preview the description
 */
angular.module('uasApp').component('descriptionPreview', {
  bindings: {
    text: '<',
    editor: '<'
  },
  templateUrl: 'es6/i18n/descriptions/description.preview.html',
  controllerAs: 'descriptionPreviewController',
  controller: function ($sce, Changes) {
    const descriptionPreviewController = this;

    descriptionPreviewController.$onInit = function () {
      setTrustedHtml();
    };

    descriptionPreviewController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'text')) {
        setTrustedHtml();
      }
    };

    function setTrustedHtml() {
      descriptionPreviewController.trustedHtml = $sce.trustAsHtml(descriptionPreviewController.text);
    }
  }
});
