'use strict';

/**
 * @ngdoc function
 * @name uasApp.config:PersonStates
 * @description
 * # PersonStates
 * Configures all states of a person.
 */
angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('change-report-template', {
        url: '/change-report-template',
        template: '<uas-change-report-template></uas-change-report-template>',
        breadcrumb: {
            label: 'Static.Page.ChangeReportTemplate',
            root: true
        },
        admin: true
    });

    $stateProvider.state('change-report', {
        url: '/change-report',
        template: '<uas-change-report></uas-change-report>',
        breadcrumb: {
            label: 'Report',
            root: true
        }
    });

    $stateProvider.state('downloads', {
        url: '/downloads',
        template: '<uas-downloads></uas-downloads>',
        breadcrumb: {
            label: 'Static.Page.Downloads',
            root: true
        }
    });

});
