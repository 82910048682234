'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Institution
 * @description
 * Institution resource
 */
angular.module('uasApp').factory('Institution', function(UasResource) {
    return UasResource('/institution');
});
