'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:academicYearLabel
 * @description
 * # academicYearLabel
 * Displays an academic year
 */
angular.module('uasApp')
    .component('academicYearLabel', {
        bindings: {
            year: '<'
        },
        controllerAs: 'academicYearLabelController',
        templateUrl: 'es6/admin/year/year.label.html'
    });
