'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:commentsPage
 * @description
 * commentsPage Shows comments
 */
angular.module('uasApp')
  .component('commentsPage', {
    bindings: {
      entity: '<',
      page: '<',
      isReadOnly: '<?',
      process: '<'
    },
    templateUrl: 'es6/changes/comments.page.html',
    controllerAs: 'commentsPageController',
    controller: function (Page, Feedback, Language, Comment) {
      const commentsPageController = this;

      commentsPageController.$onInit = function () {
        commentsPageController.readOnly = commentsPageController.isReadOnly || Page.getParameterAsBoolean(commentsPageController.page, 'readOnly', false);
        commentsPageController.filterProcess = Page.getParameterAsBoolean(commentsPageController.page, 'filterProcess', false);
        loadData();
      };

      function loadData() {
        commentsPageController.loading = true;
        loadComments().then((comments) => {
          commentsPageController.comments = comments || [];
        }).finally(() => {
          commentsPageController.loading = false;
        });
      }

      function loadComments() {
        if (commentsPageController.filterProcess) {
          return loadProcessComments();
        } else {
          return loadFeedbackComments();
        }
      }

      function loadProcessComments() {
        return Comment.query({
          entityType: commentsPageController.entity.self.type,
          entityId: commentsPageController.entity.id,
          processId: _.get(commentsPageController.process, 'id')
        }).$promise;
      }

      function loadFeedbackComments() {
        return Feedback.get({
          entityType: commentsPageController.entity.self.type,
          entityId: commentsPageController.entity.id,
          language: Language.get()
        }).$promise.then((feedback) => {
          const comments = feedback.comments || [];

          _.each(feedback.statuses, (status) => {
            if (status.comment && status.comment.message) {
              const comment = status.comment;
              comment.status = status.status;
              comments.push(comment);
            }
          });
          return comments;
        });
      }
    }
  });
