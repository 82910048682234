'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCostDivision
 * @description
 * uasCostDivision shows cost division of a module
 */
angular.module('uasApp')
  .component('uasCostDivision', {
    bindings: {
      module: '<',
      operations: '<',
      isReadOnly: '<?',
      workflowMode: '<?',
      inactive: '<?',
      onChange: '&'
    },
    templateUrl: 'es6/cost/division/cost.division.html',
    controllerAs: 'costDivisionController',
    controller: function ($q, WorkflowValidator, Message, CostDivision, Organisation, SecurityService, Dates, feedbackObserver) {
      const costDivisionController = this;
      costDivisionController.errors = [];

      costDivisionController.$onInit = function () {
        WorkflowValidator.setValidity(() => costDivisionController.divisionForm);
        WorkflowValidator.onSave(costDivisionController.save, {
          rootType: 'module',
          entityType: 'cost-division'
        });

        const operationsToEdit = costDivisionController.workflowMode === true ? 'EDIT_COST_DIVISION_WORKFLOW' : 'EDIT_COST_DIVISION';
        costDivisionController.readOnly = costDivisionController.isReadOnly === true || !SecurityService.isAllowed(operationsToEdit, costDivisionController.operations);
        costDivisionController.active = costDivisionController.inactive !== true;

        loadData();
      };

      costDivisionController.$onDestroy = function () {
        WorkflowValidator.reset();
      };

      function getOrganisationsWithActive() {
        return Organisation.query({
          facultyId: costDivisionController.module.facultyId,
          active: true
        }).$promise
          .then((organisations) => Dates.setActive(organisations, sessionStorage.academicYear));
      }

      function loadData() {
        costDivisionController.loading = true;
        return $q.all([
          getOrganisationsWithActive(),
          CostDivision.query({
            moduleId: costDivisionController.module.id,
            active: false
          }).$promise
        ]).then(([organisations, divisions]) => {
          costDivisionController.empty = divisions.length === 0;

          costDivisionController.organisations = _(organisations)
            .filter({ active: true })
            .sortBy('code')
            .value();

          costDivisionController.divisions = _(divisions)
            .map((division) => {
              return _.extend(division, {
                moduleId: division.module.id,
                organisationId: division.organisation.id
              });
            })
            .sortBy(['percentage', 'organisation.code', 'id'])
            .reverse()
            .value();

          costDivisionController.onMode();
          costDivisionController.validate();
        }).finally(() => {
          costDivisionController.loading = false;
        });
      }

      costDivisionController.onMode = function () {
        costDivisionController.filtered = _.filter(costDivisionController.divisions,
          (division) => !costDivisionController.active || costDivisionController.isActive(division));
      };

      costDivisionController.isActive = function (division) {
        return division.changeType !== 'REMOVE';
      };

      costDivisionController.save = function () {
        const activeDivisions = _(costDivisionController.divisions)
          .filter((division) => costDivisionController.isActive(division))
          .forEach((division) => {
            division.ready = true;
          });

        return CostDivision.saveAll(activeDivisions).$promise.then(() => {
          costDivisionController.divisionForm.$setPristine(false);
          costDivisionController.onChange();
          feedbackObserver.dataChanged();
          Message.onSaved();
          loadData();
        });
      };

      costDivisionController.start = function () {
        costDivisionController.empty = false;
        costDivisionController.loading = true;
        CostDivision.generate({
          moduleId: costDivisionController.module.id
        }, {}).$promise.then(onGenerated).finally(() => {
          costDivisionController.loading = false;
        });
      };

      function onGenerated() {
        return loadData().then(() => {
          if (costDivisionController.empty) {
            costDivisionController.add();
          }
        });
      }

      costDivisionController.add = function () {
        const current = getTotalPercentage();
        const remainder = Math.max(100 - current, 0);

        costDivisionController.empty = false;
        costDivisionController.divisions.push({
          moduleId: costDivisionController.module.id,
          academicYearId: sessionStorage.academicYear,
          percentage: remainder
        });

        costDivisionController.validate();
        costDivisionController.onMode();
        costDivisionController.divisionForm.$setDirty(true);
      };

      costDivisionController.remove = function (division) {
        division.changeType = 'REMOVE';
        costDivisionController.validate();
        costDivisionController.onMode();
        costDivisionController.divisionForm.$setDirty(true);
      };

      function getTotalPercentage() {
        return _(costDivisionController.divisions)
          .filter((division) => costDivisionController.isActive(division))
          .map((division) => parseInt(division.percentage || '0'))
          .sum();
      }

      /**
       * Validates if the divided costs are equal to 100% of the costs.
       */
      function validateCostDivisionPercentage() {
        const percentage = getTotalPercentage();
        costDivisionController.percentage = percentage;

        let errorData = {
          type: 'PERCENTAGE',
          message: null
        };

        _.remove(costDivisionController.errors, (error) => error.type === 'PERCENTAGE');

        if (percentage > 100) {
          errorData.message = 'Static.Error.CostDivision.MoreThan100PercentOfCostsDivided';
          costDivisionController.errors.push(errorData);
        } else if (percentage < 100) {
          errorData.message = 'Static.Error.CostDivision.LessThan100PercentOfCostsDivided';
          costDivisionController.errors.push(errorData);
        }

        costDivisionController.hasPercentageError = !!errorData.message;
        costDivisionController.divisionForm.$setValidity('percentage', !errorData.message);
      }

      function validateOrganisation(organisation, index) {
        const organisationValid = organisation
          ? (organisation.active && !organisation.terminated)
          : false;

        if (!organisationValid) {
          const errorData = {
            type: 'ORGANISATION',
            message: 'Static.Error.CostDivision.InvalidOrganisationSelected'
          };
          costDivisionController.errors.push(errorData);
          costDivisionController.divisionForm.$setValidity(`organisation-${index}`, errorData.message);
        } else {
          _.remove(costDivisionController.errors, (error) => error.message === 'Static.Error.CostDivision.InvalidOrganisationSelected');
          costDivisionController.divisionForm.$setValidity(`organisation-${index}`, true);
        }
      }

      /**
       * Validate if all divisions refer to a valid, active organisation.
       */
      function validateOrganizationsActive() {
        _(costDivisionController.filtered)
          .filter(costDivisionController.isActive)
          .forEach((division) => {
            const organisation = _.find(costDivisionController.organisations, (org) => org.id === division.organisationId);

            const index = costDivisionController.filtered.indexOf(division);

            validateOrganisation(organisation, index);
          });
      }

      costDivisionController.validate = function () {
        validateCostDivisionPercentage();
        validateOrganizationsActive();
      };

      costDivisionController.restore = function (division) {
        delete division.changeType;

        costDivisionController.validate();
        costDivisionController.onMode();

        costDivisionController.divisionForm.$setDirty(true);
      };
    }
  });
