'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:periodList
 * @description
 * periodList shows the periods
 */
angular.module('uasApp').component('periodList', {
    bindings: {
        calendar: '<'
    },
    templateUrl: 'es6/calendar/ribbon/period.list.html',
    controllerAs: 'periodListController',
    controller: function(Pageable, CalendarPeriod, Period, Language, Message, $uibModal, $q) {

        const periodListController = this;

        periodListController.$onInit = function() {
            updateNameProperty();
            Language.onChange(updateNameProperty);

            loadData();
        };

        function updateNameProperty() {
            periodListController.name = Language.getPropertyName();
        }

        function loadData() {
            periodListController.loading = true;
            $q.all([
                CalendarPeriod.query({
                    calendarId: periodListController.calendar.id
                }).$promise,
                Period.query({
                    calendarId: periodListController.calendar.id
                }).$promise
            ]).then(([periods, ribbons]) => {
                periodListController.periods = _.map(periods, (period) => {
                    period.ribbons = _.filter(ribbons, { startId: period.id });
                    period.pageable = Pageable.of({
                        order: 'externalId'
                    });

                    return period;
                });
            }).finally(() => {
                periodListController.loading = false;
            });
        }

        periodListController.create = function(period) {
            periodListController.edit({
                academicYearId: periodListController.calendar.academicYearId,
                calendarId: periodListController.calendar.id,
                startId: period.id,
                selectable: true
            });
        };

        /**
         * Deletes the element
         * @param {Object} element The element to be removed.
         */
        periodListController.remove = function(period) {
            Period.remove({
                id: period.id
            }).$promise.then(function() {
                Message.onRemoved();
                loadData();
            });
        };

        periodListController.edit = function(ribbon) {
            $uibModal.open({
                size: 'lg',
                templateUrl: 'es6/calendar/ribbon/period.edit.modal.html',
                controllerAs: 'periodModalController',
                controller: function($uibModalInstance) {
                    const periodModalController = this;

                    periodModalController.$onInit = function() {
                        periodModalController.ribbon = angular.copy(ribbon);
                        periodModalController.periods = periodListController.periods;

                        setEnds();
                    };

                    periodModalController.onStart = function() {
                        setEnds();
                    };

                    function setEnds() {
                        const start = _.find(periodModalController.periods, {
                            id: periodModalController.ribbon.startId
                        });

                        periodModalController.ends = _.filter(periodModalController.periods, (end) => {
                            return end.period >= _.result(start, 'period', 0);
                        });
                    }

                    periodModalController.save = function() {
                        return Period.store(periodModalController.ribbon).$promise.then(() => {
                            Message.onSaved();
                            loadData();
                        }).finally(() => {
                            periodModalController.close();
                        });
                    };

                    periodModalController.close = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };
    }
});
