'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:additionalPage
 * @description
 * additionalPage Shows additional
 */
angular.module('uasApp')
    .component('additionalPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<',
            isReadOnly: '<?',
            onEvaluate: '&?'
        },
        templateUrl: 'es6/app/additional/additional.page.html',
        controllerAs: 'additionalPageController',
        controller: function(Page) {
            const additionalPageController = this;

            additionalPageController.$onInit = function () {
                additionalPageController.readOnly = additionalPageController.isReadOnly || Page.getParameter(additionalPageController.page, 'readOnly', false);
                additionalPageController.operationsToEdit = angular.isDefined(additionalPageController.workflow) ? 'EDIT_ADDITIONAL_WORKFLOW' : 'EDIT_ADDITIONAL';
            };

            additionalPageController.setEvaluation = function (evaluation) {
                if (_.isFunction(additionalPageController.onEvaluate)) {
                    additionalPageController.onEvaluate({ evaluation });
                }
            };
        }
    });
