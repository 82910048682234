'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:attributeSelector
 * @description
 * Shows an entity-options dropdown with display-type DROPDOWN_SELECT.
 * To select an attribute.
 */
angular.module('uasApp').component('attributeSelector', {
  bindings: {
    attributes: '<', // must have a numeric id and displayName
    classes: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/attribute.selector.html',
  controllerAs: 'attributeSelectorController',
  controller: function () {
    const attributeSelectorController = this;

    attributeSelectorController.$onInit = function() {
      attributeSelectorController.ngModelCtrl.$render = function () {
        attributeSelectorController.selectedAttributeIds = attributeSelectorController.ngModelCtrl.$modelValue;
      };
    };

    attributeSelectorController.$onChanges = function () {
      if (_.size(attributeSelectorController.attributes) === 1) {
        attributeSelectorController.selectedAttributeIds = _.map(attributeSelectorController.attributes, 'id');
        attributeSelectorController.onChange();
      }
    };

    attributeSelectorController.formatAttribute = function (entity) {
      return angular.isDefined(entity) ? entity.displayName : '';
    };

    attributeSelectorController.onChange = function () {
      attributeSelectorController.ngModelCtrl.$setViewValue(attributeSelectorController.selectedAttributeIds);
    };
  }
});
