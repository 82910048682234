'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessEdit
 * @description
 * uasProcessEdit Edits a process
 */
angular.module('uasApp')
  .component('uasProcessTypeEdit', {
    bindings: {
      process: '<'
    },
    templateUrl: 'es6/process/process.type.edit.html',
    controllerAs: 'processController',
    controller: function ($q, $state, translateFilter, ProcessType, ExternalSystem, Language, Message) {
      const processController = this;

      processController.$onInit = function() {
        processController.onLanguage = Language.onChange(setLabel);

        setLabel();
        loadData();
      };

      processController.$onDestroy = function() {
        processController.onLanguage();
      };

      function setLabel() {
        if (angular.isDefined(processController.process.id)) {
          processController.label = ProcessType.getLabel(processController.process);
        } else {
          processController.label = translateFilter('Static.Page.ProcessType.Create');
        }
      }

      function loadData() {
        processController.loading = true;
        $q.all([
          ProcessType.query().$promise,
          ExternalSystem.query().$promise
        ]).then(([parents, systems]) => {
          processController.parents = _(parents).filter((process) => {
            return angular.isUndefined(process.parentId) && process.id !== processController.process.id;
          }).map((process) => {
            const label = ProcessType.getLabel(process);
            return { id: process.id, label };
          }).value();

          processController.systems = systems;
        }).finally(() => {
          processController.loading = false;
        });
      }

      processController.save = function(form) {
        ProcessType.store(processController.process).$promise.then(function(result) {
          form.$setPristine();
          if (angular.isUndefined(processController.process.id)) {
            $state.go('process-type', { id: result.id });
          }
          Message.onSaved();
        });
       };
      }
});
