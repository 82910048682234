'use strict';

angular.module('uasApp').component('linkTypeList', {
  templateUrl: 'es6/link/type/link.type.list.html',
  controllerAs: 'linkTypeController',
  controller: function ($uibModal, Pageable, Message, LinkType) {
    const linkTypeController = this;

    linkTypeController.$onInit = function () {
      linkTypeController.pageable = Pageable.of({
        order: 'code'
      });

      loadData();
    };

    linkTypeController.search = function() {
      loadData();
    };

    function loadData() {
      if (angular.isUndefined(linkTypeController.category)) {
        return;
      }

      const params = linkTypeController.pageable.build({
        categoryId: _.result(linkTypeController.category, 'id'),
        text: linkTypeController.text || ''
      });

      linkTypeController.loading = true;
      return LinkType.page(params).$promise.then((types) => {
        linkTypeController.types = types;
      }).finally(() => {
        linkTypeController.loading = false;
      });
    }

    linkTypeController.onCategory = function (category) {
      linkTypeController.category = category;
      loadData();
    };

    linkTypeController.create = function () {
      openModal({
        categoryId: _.result(linkTypeController.category, 'id')
      });
    };

    linkTypeController.edit = function (type) {
      openModal(type);
    };

    function openModal(type) {
      $uibModal.open({
        templateUrl: 'es6/link/type/link.type.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.type = angular.copy(type);

          modalController.save = function () {
            LinkType.save(modalController.type).$promise.then(() => {
              loadData();
              Message.onSaved();
            });
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    linkTypeController.remove = function (type) {
      LinkType.remove(type).$promise.then(() => {
        loadData();
        Message.onRemoved();
      });
    };
  }
});
