'use strict';

angular.module('uasApp').component('linkCategorySelect', {
  templateUrl: 'es6/link/category/link.category.select.html',
  bindings: {
    selectId: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'linkCategorySelectController',
  controller: function (LinkCategory) {
    const linkCategorySelectController = this;

    linkCategorySelectController.$onInit = function () {
        LinkCategory.query().$promise.then((categories) => {
          linkCategorySelectController.categories = categories;
        });

        linkCategorySelectController.ngModelCtrl.$render = function () {
          linkCategorySelectController.categoryId = _.parseInt(linkCategorySelectController.ngModelCtrl.$modelValue);
        };
    };

    linkCategorySelectController.onSelect = function () {
      linkCategorySelectController.ngModelCtrl.$setViewValue(linkCategorySelectController.categoryId);
    };
  }
});