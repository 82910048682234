'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:studiesLabel
 * @description
 * studiesLabel Shows studies that use this entity.
 */
angular.module('uasApp').component('studiesLabel', {
  bindings: {
    entity: '<',
    onLoaded: '&'
  },
  templateUrl: 'es6/study/studies.label.html',
  controllerAs: 'studiesLabelController',
  controller: function (Module, ModuleGroup, EntityService) {
    const studiesLabelController = this;

    const DEFAULT_MAX = 5;
    const RESOURCES = {
      'module': Module.studies,
      'module-group': ModuleGroup.studies
    };

    studiesLabelController.$onInit = function () {
      studiesLabelController.max = DEFAULT_MAX;
    };

    studiesLabelController.$onChanges = function (changes) {
      if (changes.entity) {
        loadData();
      }
    };

    function loadData() {
      const entityId = _.result(studiesLabelController.entity, 'id');
      const entityType = EntityService.getType(studiesLabelController.entity);
      const getStudies = RESOURCES[entityType];

      if (getStudies && angular.isDefined(entityId)) {
        return getStudies({
          id: entityId
        }).$promise.then((studies) => {
          studiesLabelController.studies = _(studies)
            .sortBy('code')
            .map((study) => _.extend(study, { type: 'study' }))
            .value();

          studiesLabelController.onLoaded({ data: studiesLabelController.studies });
        });
      } else {
        studiesLabelController.studies = [];
      }
    }

    studiesLabelController.increase = function($event) {
      $event.preventDefault();
      $event.stopPropagation();

      studiesLabelController.max += DEFAULT_MAX;
    };
  }
});
