'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ModuleCtrl
 * @description
 * # ModuleCtrl
 * Module form controller. used to add a module
 */
angular.module('uasApp').controller('ModuleCtrl', function ($scope, $state, module, ModuleTabs, Tabs, operations, historyMode, periodId) {

    // Bind the id for the feedback directive
    $scope.moduleId = $state.params.id;
    $scope.module = module;
    $scope.historyMode = historyMode;
    $scope.periodId = periodId;
    $scope.operations = operations;

    Tabs.build(ModuleTabs.get(), operations, 'module', 'module', $scope.moduleId).then((builtTabs) => {
        $scope.tabs = builtTabs;
    });
});
