'use strict';

angular.module('uasApp').factory('GroupType', function (UasResource) {
    return UasResource('/group-types', {
        search: {
            method: 'GET',
            url: '/group-types/search'
        },
        excluded: {
            method: 'GET',
            url: '/group-types/excluded'
        }
    });
});
