'use strict';

angular.module('uasApp').factory('FteModal', function($uibModal) {
    
    function open(args) {
        const assignment = _.result(args, 'assignment');

        $uibModal.open({
            template: `
                <fte-edit
                    assignment="fteModalController.assignment"
                    fte="fteModalController.fte"
                    is-read-only="fteModalController.isReadOnly"
                    on-change="fteModalController.onChange()"
                    on-cancel="fteModalController.close()">
                </fte-edit>
            `,
            resolve: {
                fte: function(Fte) {
                    return Fte.get({
                        assignmentId: assignment.id,
                        academicYearId: sessionStorage.academicYearId
                    }).$promise;
                },
                operations: function (AuthService) {
                    return AuthService.operations('assignment', assignment.id);
                }
            },
            controllerAs: 'fteModalController',
            controller: function($uibModalInstance, fte, operations, SecurityService) {
                const fteModalController = this;
                fteModalController.assignment = assignment;
                fteModalController.fte = fte;
                fteModalController.isReadOnly = args.isReadOnly || !SecurityService.isAllowed(['EDIT_ASSIGNMENTS'], operations);

                fteModalController.onChange = function() {
                    fteModalController.close();
                    if (_.isFunction(args.onChange)) {
                        args.onChange();
                    }
                };

                fteModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    return { open };

});
