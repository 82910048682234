'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCalendarForm
 * @description
 * uasCalendarForm edit the details of a calendar
 */
angular.module('uasApp')
    .component('uasCalendarForm', {
        bindings: {
            calendar: '<'
        },
        templateUrl: 'es6/calendar/calendar.form.html',
        controllerAs: 'calendarController',
        controller: function (Calendar, Message, $state) {
            const calendarController = this;

            calendarController.save = function(calendar) {
                Calendar.store(calendar).$promise.then((result) => {
                    Message.addSuccessLabel('Static.Message.DataSaved');
                    if (angular.isUndefined(calendar.id)) {
                        $state.go('calendar.general', { id: result.id });
                    } else {
                        _.extend(calendar, result);
                    }
                });
            };
        }

    });
