'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:feedbackPage
 * @description
 * feedbackPage Shows evaluation feedback
 */
angular.module('uasApp').component('evaluationFeedbackPage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?',
    operations: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/evaluation/evaluation.feedback.page.html',
  controllerAs: 'feedbackPageController',
  controller: function ($uibModal, EntityService, Evaluation, i18nFilter, Language, Page, SecurityService) {
    const feedbackPageController = this;

    feedbackPageController.$onInit = function () {
      feedbackPageController.evaluationType = Page.getParameter(feedbackPageController.page, 'evaluationType');
      feedbackPageController.role = Page.getParameter(feedbackPageController.page, 'role');
      feedbackPageController.readOnly = feedbackPageController.isReadOnly ||
        Page.getParameterAsBoolean(feedbackPageController.page, 'readOnly', false);
      feedbackPageController.editorOptions = Page.getParameterAsArray(feedbackPageController.page, 'editorOptions', 'bold,italic,underline,list,bullet,link');

      feedbackPageController.editable = SecurityService.isAllowed(['EDIT_EVALUATION'], feedbackPageController.operations) && !feedbackPageController.readOnly;

      feedbackPageController.loading = true;
      getEvaluation().then((evaluation) => {
        feedbackPageController.evaluation = evaluation;
        return loadFeedback();
      }).finally(() => {
        feedbackPageController.loading = false;
      });

      Language.onChange(setRoleDisplayNames);
    };

    function getEvaluation() {
      const reference = EntityService.toReference(feedbackPageController.entity);
      const params = {
        evaluationType: feedbackPageController.evaluationType,
        entityType: _.get(reference, 'type'),
        entityId: _.get(reference, 'id')
      };

      return Evaluation.get(params).$promise;
    }

    function loadFeedback() {
      const evaluationId = _.get(feedbackPageController.evaluation, 'id');
      if (angular.isUndefined(evaluationId) || angular.isUndefined(feedbackPageController.role)) {
        feedbackPageController.feedbacks = [];
        return;
      }
        
      const reference = EntityService.toReference(feedbackPageController.entity);
      return Evaluation.feedbacks({
        evaluationId: feedbackPageController.evaluation.id,
        entityType: _.get(reference, 'type'),
        entityId: _.get(reference, 'id')
      }).$promise.then((feedbacks) => {
        feedbackPageController.feedbacks = _.filter(feedbacks, (feedback) =>
          _.isEmpty(feedbackPageController.role) || _.get(feedback.role, 'externalId') === feedbackPageController.role);

        const found = _.find(feedbackPageController.feedbacks, (feedback) => _.get(feedback.role, 'externalId') === feedbackPageController.role);
        if (!found) {
          feedbackPageController.feedbacks.push({
            role: {
              externalId: feedbackPageController.role
            }
          });
        }

        setRoleDisplayNames();
      });
    }

    function setRoleDisplayNames() {
      _.forEach(feedbackPageController.feedbacks, (feedback) => {
        if (angular.isDefined(feedback.role)) {
          feedback.role.displayName = i18nFilter(feedback.role.names) || _.get(feedback, 'role.externalId');
        }
      });
    }

    feedbackPageController.edit = function (feedback) {
      $uibModal.open({
        size: 'lg',
        templateUrl: 'es6/evaluation/evaluation.feedback.modal.html',
        controllerAs: 'feedbackModalController',
        controller: function ($uibModalInstance, Message) {
          const feedbackModalController = this;

          feedbackModalController.$onInit = function () {
            feedbackModalController.feedback = angular.copy(feedback);
            feedbackModalController.editorOptions = feedbackPageController.editorOptions;
          };

          feedbackModalController.save = function () {
            const entity = EntityService.toReference(feedbackPageController.entity);

            Evaluation.saveFeedback({
              entity,
              evaluationId: feedbackPageController.evaluation.id,
              message: feedbackModalController.feedback.message,
              role: _.get(feedbackModalController.feedback, 'role.externalId')
            }).$promise.then(() => {
              Message.onSaved();
              $uibModalInstance.dismiss();
            });
          };
        }
      }).result.then(loadFeedback, loadFeedback);
    };
  }
});
