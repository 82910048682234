'use strict';

angular.module('uasApp')
    .component('uasChangeReport', {
        bindings: {
            facultyId: '@'
        },
        templateUrl: 'es6/report/change.report.html',
        controllerAs: 'changeReportController',
        controller: function (Report, Language, ChangeReportTemplate, DiffHtml, entityTranslateFilter, i18nFilter, translateFilter, configDateFilter, $q) {
            const changeReportController = this;

            changeReportController.$onInit = function() {
                ChangeReportTemplate.query().$promise.then((results) => {
                    changeReportController.templateId = _(results).map('id').head();
                    changeReportController.templates = results;
                });
            };

            changeReportController.updateReport = function() {
                changeReportController.loading = true;
                getReport().then((report) => {                    
                    _.each(report.rows, (row) => {
                        addDiff(row.role);
                        addDiff(row.description);
                        row.lastModifiedDate = getLastModificationDate(row.role);
                    });

                    changeReportController.report = report;
                    changeReportController.headers = buildHeaders(report);
                }).finally(() => {
                    changeReportController.loading = false;
                });
            };

            function getLastModificationDate (role) {
                return _.head(_(role).map('date').sort().value());
            }

            function getReport() {
                return Report.changes({
                    academicYearId: sessionStorage.academicYear,
                    templateId: changeReportController.templateId,
                    language: Language.get()
                }).$promise;
            }

            function addDiff(values) {
                values.diff = DiffHtml.buildDiff(values.source || '', values.target || '');
            }

            /**
             * Converts a change report to a report suitable for excel and csv export
             */
            changeReportController.getRows = function(report) {
                const rows = buildRows(report);
                return $q.resolve(rows);
            };

            function buildHeaders(report) {
                let headers = [{
                    code: 'code',
                    name: translateFilter('Module.Code')
                }, {
                    code: 'module',
                    name: translateFilter('Module')
                }, {
                    code: 'lastModificationDate', 
                    name: translateFilter('Static.Label.Date')
                }, {
                    code: 'agreement', 
                    name: translateFilter('Static.Tab.Faculty.ChangeReport.Agreement')
                }];

                if (report.template.changeDescriptionType) {
                    headers.push({
                        code: 'descriptionSource',
                        name: entityTranslateFilter(report.template.changeDescriptionType) + ' (source)'
                    });
                    
                    headers.push({
                        code: 'descriptionTarget',
                        name: entityTranslateFilter(report.template.changeDescriptionType) + ' (target)'
                    });
                }

                if (report.template.changeRoleType) {
                    headers.push({
                        code: 'roleSource',
                        name: i18nFilter(report.template.changeRoleType.names) + ' (source)'
                    });
                    
                    headers.push({
                        code: 'roleTarget',
                        name: i18nFilter(report.template.changeRoleType.names) + ' (target)'
                    });
                }

                return headers;
            }

            function buildRows(report) {
                return report.rows.map((row) => {
                    const newRow = {};
                    newRow.code = row.entity.code;
                    newRow.module = entityTranslateFilter(row.entity);
                    newRow.lastModificationDate = configDateFilter(getLastModificationDate(row.role));

                    const description = row.description;
                    newRow.descriptionSource = _.result(description, 'source');
                    newRow.descriptionTarget = _.result(description, 'target');

                    const roles = row.role || [];
                    const sources = _(roles).filter({ created: false }).map('name').value();
                    const targets = _(roles).filter({ removed: false }).map((x) => {
                        var result = x.name;
                        if (angular.isDefined(x.date)) {
                            result += (' - ' + configDateFilter(x.date));
                        }
                        return result;
                    }).value();

                    newRow.roleSource = sources.join(', ');
                    newRow.roleTarget = targets.join(', ');

                    return newRow;
                });
            }
        }
    });
