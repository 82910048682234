'use strict';

angular.module('uasApp').component('uasAdditional', {
    bindings: {
        entity: '<',
        operations: '<?',
        operationsToEdit: '@?',
        onParentSave: '<?',  // save callback should have form callback(entity, comment)
        isReadOnly: '<?',
        onEvaluate: '&?'
    },
    templateUrl: 'es6/app/additional/additional.html',
    controllerAs: 'additionalController',
    controller: function ($q, Additional, AcademicYear, CustomField, Dates, Message, translateFilter, AuthService, feedbackObserver, Changes) {
        const additionalController = this;

        additionalController.$onInit = function () {
            additionalController.operationsToEdit_ = AuthService.buildOperationsToEdit(additionalController.operationsToEdit, 'EDIT_ADDITIONAL');
        };

        additionalController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'entity') && additionalController.entity) {
                loadData();
            }
        };

        function loadData() {
            if (angular.isUndefined(additionalController.academicYearId)) {
                additionalController.academicYearId = sessionStorage.academicYear;
            }

            additionalController.loading = true;
            $q.all([
                CustomField.query({
                    rootType: additionalController.entity.self.type,
                    entityType: 'additional'
                }).$promise,
                AcademicYear.get({
                    id: additionalController.academicYearId
                }).$promise,
                getAdditional()
            ]).then(([fields, year, additional]) => {
                additionalController.additional = additional;

                const values = _.result(additional, 'values', {});
                additionalController.items = _(fields).filter((field) => Dates.isActive(field, year)).sortBy(['sequence', 'name', 'id']).map((field, sequence) => {
                    return {
                        sequence,
                        field,
                        values: values[field.id] || []
                    };
                }).value();
            }).finally(() => {
                additionalController.loading = false;
                AuthService.resecureAll();
            });
        }

        function getAdditional() {
            if (angular.isDefined(additionalController.entity.id)) {
                return Additional.get({
                    entityType: additionalController.entity.self.type,
                    entityId: additionalController.entity.id,
                    academicYearId: additionalController.academicYearId
                }).$promise.then((additional) => {
                    if (!_.get(additional, 'entity')) {
                        additional = { entity: additionalController.entity };
                    }
                    return additional;
                });
            }

            return $q.resolve({ 
                entity: additionalController.entity 
            });
        }

        additionalController.setEvaluation = function (evaluation) {
            if (_.isFunction(additionalController.onEvaluate)) {
                additionalController.onEvaluate({ evaluation });
            }
        };

        additionalController.save = function (items, entity, comment) {
            const model = _.extend(additionalController.additional, {
                academicYearId: additionalController.academicYearId,
                entity: entity.self,
                values: getValues(items)
            });

            const body = {
                comment: comment,
                content: model
            };

            return Additional.update(body).$promise.then(function (result) {
                if (!additionalController.onParentSave) {
                    feedbackObserver.dataChanged();
                    Message.addSuccess(translateFilter('Static.Message.DataAdjusted'));
                }
                if (angular.isUndefined(additionalController.additional.id) && result.entities[0]) {
                    additionalController.additional.id = result.entities[0].entity.id;
                }
                return result;
            });
        };

        function getValues(items) {
            return _(items).map((item) => {
                return _.map(item.values, (value) => {
                    return {
                        field: item.field.id,
                        value: value
                    };
                });
            }).flatten().value();
        }
    }
});
