'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:rulesReport
 * @description
 * Displays rules for an entity.
 */
angular.module('uasApp').component('rulesReport', {
  templateUrl: 'es6/rule/report/rules.report.html',
  bindings: {
    entity: '<',
    operations: '<',
    isReadOnly: '<?',
    reportTemplate: '<?',
    hideReportTemplates: '<?'
  },
  controllerAs: 'rulesReportController',
  controller: function ($state, CreateManager, EntityService, Language, Rule, WorkflowModal) {
    const rulesReportController = this;

    rulesReportController.$onInit = function () {
      rulesReportController.entityType = EntityService.getType(rulesReportController.entity);

      setName();
      loadData();

      rulesReportController.onLanguage = Language.onChange(loadData);
    };

    rulesReportController.$onDestroy = function () {
      rulesReportController.onLanguage();
    };

    function loadData() {
      rulesReportController.loading = true;

      return Rule.report({
        definition: true
      }).$promise.then((report) => {
        rulesReportController.groups = report.groups;
        rulesReportController.columns = report.headers;
      }).finally(() => {
        rulesReportController.loading = false;
      });
    }

    function setName() {
      rulesReportController.name = 'rules';
      rulesReportController.context = 'rules';

      if (angular.isDefined(rulesReportController.entity)) {
        rulesReportController.name = `${rulesReportController.entity.code}-rules`;
        rulesReportController.context = `${rulesReportController.entityType}.rules`;
      }
    }

    function getFacultyId() {
      if (rulesReportController.entityType === 'faculty') {
        return rulesReportController.entity.id;
      }

      return _.get(rulesReportController.entity, 'facultyId');
    }

    rulesReportController.getRows = function (parameters) {
      parameters.facultyId = getFacultyId();
      return Rule.report(parameters).$promise;
    };

    rulesReportController.onClick = function (row) {
      if (rulesReportController.isReadOnly) {
        goToDetails(row);
      } else {
        edit(row);
      }
    };

    rulesReportController.create = function () {
      const qualification = {
        facultyId: getFacultyId()
      };

      CreateManager.open('rule', qualification, rulesReportController.operations, () => {
        loadData();
      });
    };

    function edit(row) {
      Rule.get({
        id: row.entity.id
      }).$promise.then((rule) => {
        WorkflowModal.open({
          entity: rule,
          onCancel: loadData,
          onEmpty: () => goToDetails(row),
          onSave: loadData
        });
      });
    }

    function goToDetails(row) {
      $state.go('rule.general', { id: row.entity.id });
    }
  }
});