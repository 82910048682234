'use strict';

/**
 * @ngdoc filter
 * @name uasApp.filter:truncate
 * @function
 * @description
 * # truncate
 * Truncates a string to a given number
 */
angular.module('uasApp')
  .filter('truncate', function () {
    return function (input, maxLength, showEllipsis) {

      if (input === undefined || input === null) {
        return;
      }

      if (input.length > maxLength) {
        const addEllipsis = angular.isUndefined(showEllipsis) ? true : showEllipsis;

        let truncatedString = input.substring(0, maxLength);

        if (addEllipsis) {
          truncatedString += '...';
          return truncatedString;
        }
      }

      return input;
    };
  });
