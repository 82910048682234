'use strict';

angular.module('uasApp').filter('ribonTranslate', function($filter) {
    return function(value) {
        if (!value) {
            return '';
        } else if (_.isArray(value)) {
            var total = [];
            _.each(value, function(item) {
                var result = $filter('entityTranslate')(item);
                if (result) {
                    total.push(result);
                }
            });
            return total.join('+');
        } else {
            return value;
        }
    };
});
