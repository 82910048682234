'use strict';

angular.module('uasApp').component('htmlText', {
  bindings: {
    inputId: '@?',
    language: '@',
    editor: '@?',
    maxCharacters: '<?',
    isRequired: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    editorOptions: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/html.text.html',
  transclude: true,
  controllerAs: 'htmlTextController',
  controller: function ($sce, Changes, Parameter, Clipboard, $element) {
    const htmlTextController = this;

    htmlTextController.$onInit = function () {
      Parameter.load().then(() => loadFormats());

      htmlTextController.active = false;
      htmlTextController.disabled = htmlTextController.isDisabled === true;
      htmlTextController.readOnly = htmlTextController.isReadOnly === true;

      htmlTextController.customOptions = [
        {
          import: 'formats/bold',
          toRegister: {
            key: 'tagName',
            value: 'b' // Quill uses <strong> by default
          }
        },
        {
          import: 'formats/italic',
          toRegister: {
            key: 'tagName',
            value: 'i' // Quill uses <em> by default
          }
        }
      ];

      htmlTextController.ngModelCtrl.$render = function () {
        htmlTextController.model = htmlTextController.ngModelCtrl.$modelValue;
        htmlTextController.trustedHtml = $sce.trustAsHtml(htmlTextController.model);
      };
    };

    htmlTextController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'maxCharacters')) {
        if (angular.isDefined(htmlTextController.maxCharacters)) {
          htmlTextController.ngModelCtrl.$validators.maxCharacters = function (modelValue, viewValue) {
            const value = htmlTextController.countPlainText ? htmlTextController.plainText : (modelValue || viewValue);
            return  _.isEmpty(value) || value.length <= htmlTextController.maxCharacters;
          };
        }
      }
    };

    function loadFormats() {
      htmlTextController.formats = [];
      htmlTextController.options = {};

      const name = htmlTextController.editor || 'html.editor';
      htmlTextController.stripHtml = Parameter.getParameterAsBoolean(name + '.copypaste.clean', false);
      htmlTextController.countPlainText = Parameter.getParameterAsBoolean(name +'.count.plain_text', true);

      getEditorOptions(name).forEach((format) => {
        htmlTextController.options[format] = true;
        htmlTextController.formats.push(format);
      });
    }

    function getEditorOptions(name) {
      if (_.isEmpty(htmlTextController.editorOptions)) {
        return Parameter.getParameterAsList(name);
      }

      return htmlTextController.editorOptions;
    }

    htmlTextController.onFocus = function () {
      htmlTextController.active = true;
    };

    htmlTextController.onBlur = function () {
      htmlTextController.active = false;
    };

    htmlTextController.onContentChanged = function (editor, html, text) {
      if (htmlTextController.active) {
        let value;
        if (!isEmpty(text)) {
          setSpellCheck();
          value = applyFormat(angular.copy(htmlTextController.model));
        } else {
          delete htmlTextController.model;
        }
        htmlTextController.ngModelCtrl.$setViewValue(value);
      }

      htmlTextController.plainText = text;
    };

    /**
     * Returns true if string is empty or only a new line.
     * Quill will add an empty line even if all text is removed.
     */
    function isEmpty(str) {
      return _.isEmpty(str) || str === '\n';
    }

    function setSpellCheck() {
      const language = _.lowerCase(htmlTextController.language);

      const paragraphs = $element.find('.ql-editor p');
      _.forEach(paragraphs, (paragraph) => {
        if (!htmlTextController.disabled) {
          paragraph.setAttribute('contenteditable', '');
        } else {
          paragraph.removeAttribute('contenteditable');
        }
        paragraph.setAttribute('spellcheck', 'true');
        paragraph.setAttribute('lang', language);
      });
    }

    function applyFormat(text) {
      if (_.isEmpty(text)) {
        return text;
      }

      const formatted = text.replace('&amp;', '&');

      // Keep paragraph structure for alignment
      if (htmlTextController.options.align) {
        return formatted
          .replace(/class="ql-align-center"/g, 'style="text-align: center;"')
          .replace(/class="ql-align-right"/g, 'style="text-align: right;"')
          .replace(/&nbsp;/g, ' ')
          .replace(/( )*(contenteditable|spellcheck|lang)="[a-zA-Z]*"/g, '');
      }

      // Replace all paragraphs with break rules
      return formatted
        .replace(/&nbsp;/g, ' ')
        .replace(/<p[^>]*><br><\/p>/g, '<br/>')
        .replace(/<p[^>]*>/g, '')
        .replace(/<\/p>$/g, '')
        .replace(/<\/p>/g, '<br/>')
        .replace(/<br>$/g, '');
    }

    htmlTextController.showHtml = function () {
      Clipboard.copy({
        value: htmlTextController.ngModelCtrl.$modelValue
      });
    };

    htmlTextController.onEditor = function (editor) {
      setSpellCheck();

      if (htmlTextController.stripHtml) {
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          delta.ops = _.transform(delta.ops, (ops, op) => {
            if (op.insert && angular.isString(op.insert)) {
              ops.push({
                insert: op.insert
              });
            }
          }, []);
          return delta;
        });
      }
    };
  }
});
