'use strict';

angular.module('uasApp').component('academicYearSimulations', {
  templateUrl: 'es6/admin/year/year.simulations.html',
  controllerAs: 'academicYearSimulationsCtrl',
  bindings: {
    year: '<'
  },
  controller: function (Simulation, Message, Pageable) {
    const academicYearSimulationsCtrl = this;

    academicYearSimulationsCtrl.$onInit = function () {
      academicYearSimulationsCtrl.pageable = Pageable.of({
        order: 'code'
      });

      loadData();
    };

    function loadData() {
      academicYearSimulationsCtrl.loading = true;
      const academicYearId = _.get(academicYearSimulationsCtrl.year, 'id');

      const params = academicYearSimulationsCtrl.pageable.build({
        originId: academicYearId
      });

      Simulation.page(params).$promise.then((simulations) => {
        academicYearSimulationsCtrl.simulations = simulations;
      }).finally(() => {
        academicYearSimulationsCtrl.loading = false;
      });
    }

    academicYearSimulationsCtrl.onPage = function() {
      loadData();
    };

    academicYearSimulationsCtrl.remove = function(simulation) {
      academicYearSimulationsCtrl.loading = true;
      Simulation.remove(simulation).$promise.then(() => {
        Message.onRemoved();
        loadData();
      }).finally(() => {
        academicYearSimulationsCtrl.loading = false;
      });
    };
  }
});
