'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityWeeks
 * @description
 */
angular.module('uasApp').component('activityWeeks', {
    bindings: {
        activity: '<',
        entity: '<',
        showAll: '<',
        beforePeriod: '<?',
        afterPeriod: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/schedule/activity/form/activity.weeks.html',
    controllerAs: 'activityWeeksController',
    controller: function ($q, CustomField, Element) {
        const activityWeeksController = this;

        activityWeeksController.$onInit = function () {
            loadData();

            activityWeeksController.ngModelCtrl.$render = () => {
                const weeks = activityWeeksController.ngModelCtrl.$viewValue || [];
                activityWeeksController.weeks = angular.copy(weeks);
                activityWeeksController.offeringWeeks = _.filter(activityWeeksController.weeks, (week) => !outsidePeriod(week));

                if (activityWeeksController.beforePeriod) {
                    activityWeeksController.beforeWeeks = _.filter(activityWeeksController.weeks, 'beforePeriod');
                }

                if (activityWeeksController.afterPeriod) {
                    activityWeeksController.afterWeeks = _.filter(activityWeeksController.weeks, 'afterPeriod');
                }

                update();
            };
        };

        function loadData() {
            activityWeeksController.loading = false;

            $q.all([
                CustomField.query({
                    rootType: 'module',
                    entityType: 'activity-planning'
                }).$promise,
                Element.getActive('CALENDAR_WEEK_TYPE')
            ]).then(([fields, weekTypes]) => {
                activityWeeksController.fields = _.keyBy(fields, 'name');
                activityWeeksController.isEditable = isEditable(activityWeeksController.fields.week);

                const educationType = _.find(weekTypes, { code: 'EDUCATION' });
                activityWeeksController.educationTypeId = _.get(educationType, 'id');
            }).finally(() => {
                activityWeeksController.loading = false;
            });
        }

        function isEditable(field) {
            return CustomField.isEditable(field, activityWeeksController.activity, activityWeeksController.entity);
        }

        function outsidePeriod(week) {
            return week.beforePeriod || week.afterPeriod;
        }

        function update() {
            if (!_.isEmpty(activityWeeksController.weeks)) {
                activityWeeksController.every = _.every(activityWeeksController.offeringWeeks, { active: true });
                activityWeeksController.some = _.some(activityWeeksController.weeks, { active: true }) ? true : undefined;
            } else {
                activityWeeksController.every = false;
                delete activityWeeksController.some;
            }

            activityWeeksController.ngModelCtrl.$setValidity('weeks', activityWeeksController.some === true);
        }

        activityWeeksController.onWeeks = function() {
            setViewValue();
        };

        function setViewValue() {
            update();

            const weeks = angular.copy(activityWeeksController.weeks);
            activityWeeksController.ngModelCtrl.$setViewValue(weeks);
        }
    }
});
