'use strict';

angular.module('uasApp').component('uasCapacity', {
  bindings: {
    entity: '<',
    operations: '<',
    operationsToEdit: '@?'
  },
  templateUrl: 'es6/app/capacity/capacity.html',
  controllerAs: 'capacityController',
  controller: function ($q, Capacity, CustomField, EntityService, Message, AuthService, feedbackObserver) {
    const capacityController = this;

    capacityController.$onInit = function () {
      capacityController.reference = EntityService.toReference(capacityController.entity);
      capacityController.operationsToEdit_ = AuthService.buildOperationsToEdit(capacityController.operationsToEdit, [
        'EDIT_CAPACITY'
      ]);

      loadData();
    };

    function loadData() {
      $q.all([
        getCapacity(),
        CustomField.query({
          rootType: capacityController.reference.type,
          entityType: 'capacity'
        }).$promise
      ]).then(([capacity, fields]) => {
          capacityController.capacity = capacity;
          capacityController.fields = fields;
          capacityController.setEvaluation();
      });
    }

    function getCapacity() {
      return Capacity.get({
        entityType: capacityController.reference.type,
        entityId: capacityController.reference.id
      }).$promise.then((capacity) => {
        return _.extend(capacity, {
          entity: capacityController.reference
        });
      });
    }

    capacityController.save = function (capacity, comment) {
      if (angular.isUndefined(capacity.academicYearId)) {
        capacity.academicYearId = sessionStorage.academicYear;
      }

      return Capacity.update({
        comment: comment,
        content: capacity
      }).$promise.then((result) => {
        feedbackObserver.dataChanged();
        Message.onSaved();
        if (angular.isUndefined(capacityController.capacity.id)) {
          capacityController.capacity.id = result.entities[0].entity.id;
        }
        return result;
      });
    };

    capacityController.setEvaluation = function () {
      capacityController.evaluation = CustomField.getEvaluation(
        capacityController.reference,
        capacityController.capacity,
        capacityController.fields
      );
    };
  }
});
