'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionsPage
 * @description
 * descriptionsPage Shows descriptions
 */
angular.module('uasApp')
    .component('descriptionsPage', {
        bindings: {
            entity: '<',
            page: '<',
            process: '<?',
            workflow: '<?',
            operations: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/i18n/descriptions.page.html',
        controllerAs: 'descriptionsPageController',
        controller: function (Description, Page) {
            const descriptionsPageController = this;

            descriptionsPageController.$onInit = function() {
                descriptionsPageController.operationsToEdit = angular.isDefined(descriptionsPageController.workflow) ? ['EDIT_DESCRIPTIONS_WORKFLOW'] : ['EDIT_DESCRIPTIONS'];
                descriptionsPageController.readOnly = descriptionsPageController.isReadOnly || Page.getParameterAsBoolean(descriptionsPageController.page, 'readOnly', false);
                descriptionsPageController.showHelp = Page.getParameterAsBoolean(descriptionsPageController.page, 'showHelp', false);
                descriptionsPageController.showIncompleteHelp = Page.getParameterAsBoolean(descriptionsPageController.page, 'showIncompleteHelp', false);
                descriptionsPageController.mode = Page.getParameter(descriptionsPageController.page, 'mode', 'edit');
                descriptionsPageController.viewMode = Page.getParameter(descriptionsPageController.page, 'viewMode', 'single');
                descriptionsPageController.editor = Page.getParameter(descriptionsPageController.page, 'editor');

                loadData();
            };

            function loadData() {
                descriptionsPageController.loading = true;
                Description.getTypes(descriptionsPageController.entity.self.type).then((types) => {
                    const codes = Page.getParameterAsArray(descriptionsPageController.page, 'types');
                    if (_.isEmpty(codes)) {
                        descriptionsPageController.types = types;
                    } else {
                        descriptionsPageController.types = _(codes)
                            .map((code) => findType(types, code))
                            .filter(angular.isDefined)
                            .map((type, $index) => {
                                type.pageSequence = $index;
                                return type;
                            })
                            .value();
                    }
                }).finally(() => {
                    descriptionsPageController.loading = false;
                });
            }

            function findType(types, code) {
                let found = _.find(types, { code: code });
                if (angular.isUndefined(found)) {
                  found = _.find(types, { externalId: code });
                }
                return found;
            }
        }
    });
