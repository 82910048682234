'use strict';

angular.module('uasApp')
    .component('uasPlanboardMoveOffering', {
        bindings: {
            modal: '<',
            moduleGroupCount: '<',
            origin: '<',
            groups: '<'
        },
        templateUrl: 'es6/planboard/move/planboard.move.offering.html',
        controllerAs: 'planboardMoveController',
        controller: function (entityTranslateFilter) {
            const planboardMoveController = this;

            planboardMoveController.$onInit = function () {
                const moduleName = entityTranslateFilter(planboardMoveController.origin.offering, 'moduleLocalName', 'moduleEnglishName');
                planboardMoveController.moduleName = { moduleName };
                planboardMoveController.offeringElsewhere = {
                    moduleName: moduleName,
                    offeringCount: planboardMoveController.moduleGroupCount
                };
            };

            planboardMoveController.cancel = function () {
              restore(planboardMoveController.origin.offering, planboardMoveController.origin.clone.offering);
              planboardMoveController.modal.close();
            };

            planboardMoveController.move = function () {
                planboardMoveController.origin.offering.askedMove = true;
                planboardMoveController.modal.close();
            };

            function restore(current, original) {
              current.period = original.period;
              current.dirty = original.dirty;
              current.changeType = original.changeType;
            }
        }
    });

