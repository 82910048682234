'use strict';

angular.module('uasApp').component('choiceTypeList', {
    templateUrl: 'es6/group/choice/choice.type.list.html',
    controllerAs: 'choiceTypeListController',
    controller: function ($uibModal, Pageable, ChoiceType, Message) {
        const choiceTypeListController = this;

        choiceTypeListController.$onInit = () => {
            choiceTypeListController.pageable = Pageable.of();
            loadData();
        };

        function loadData() {
            choiceTypeListController.loading = true;
            return ChoiceType.query().$promise.then((choiceTypes) => {
                choiceTypeListController.choiceTypes = choiceTypes;
            }).finally(() => {
                choiceTypeListController.loading = false;
            });
        }

        choiceTypeListController.create = function () {
            open({
                groups: true,
                modules: true
            });
        };

        choiceTypeListController.edit = function (choiceType) {
            const copy = angular.copy(choiceType);
            open(copy);
        };

        function open(choiceType) {
            $uibModal.open({
                size: 'lg',
                template: `
                    <choice-type-edit choice-type="choiceTypeModalController.choiceType"
                                      on-save="choiceTypeModalController.onSave()"
                                      on-cancel="choiceTypeModalController.close()">
                    </choice-type-edit>
                `,
                controllerAs: 'choiceTypeModalController',
                controller: function($uibModalInstance) {
                    const choiceTypeModalController = this;
                    choiceTypeModalController.choiceType = choiceType;

                    choiceTypeModalController.onSave = function() {
                        choiceTypeModalController.close();
                        loadData();
                        Message.onSaved();
                    };

                    choiceTypeModalController.close = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

        choiceTypeListController.delete = function (choiceType) {
            ChoiceType.delete({
                id: choiceType.id
            }).$promise.then(() => {
                loadData();
                Message.onRemoved();
            });
        };
    }
});
