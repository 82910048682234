'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:customerLogo
 * @description
 * Displays the customer logo set through a parameter with responsive but standardized dimensions and placement,
 * or (legacy) with more flexibility via CSS variables if customer.style is set.
 */
angular.module('uasApp').component('customerLogo', {
  templateUrl: 'es6/app/customer.logo.html',
  controllerAs: 'customerLogoController',
  controller: function(Parameter) {
    const customerLogoController = this;

    const STYLE_PARAMETER_NAME = 'customer.style';
    const LOGO_PARAMETER_NAME = 'customer.logo';
    const FALLBACK_LOGO_URL = 'https://themes.timeedit.net/timeedit-2024-logo-en.svg';

    customerLogoController.$onInit = function() {
      setCustomerLogo();
    };

    function setCustomerLogo() {
      const customerStyle = Parameter.getParameterValue(STYLE_PARAMETER_NAME);

      if (_.isEmpty(customerStyle)) {
        customerLogoController.customerLogoUrl = Parameter.getParameterValue(LOGO_PARAMETER_NAME, FALLBACK_LOGO_URL);
      }
    }
  }
});
