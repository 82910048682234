'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assessmentAdmin
 * @description
 * assessmentAdmin Shows assessment admin.
 */
angular.module('uasApp').component('assessmentAdmin', {
  bindings: {
    assessment: '<',
    operations: '<'
  },
  templateUrl: 'es6/assessment/assessment.admin.html',
  controllerAs: 'assessmentAdminController',
  controller: function ($state, Assessment, EntityType, feedbackObserver, Message, FeedbackService) {
    const assessmentAdminController = this;

    assessmentAdminController.$onInit = function () {
      assessmentAdminController.assessment_ = angular.copy(assessmentAdminController.assessment);
      loadData();
    };

    function loadData() {
      assessmentAdminController.loading = true;

      EntityType.get({
        rootType: 'assessment',
        entityType: 'assessment'
      }).$promise.then((customType) => {
        assessmentAdminController.customType = customType;
      }).finally(() => {
        assessmentAdminController.loading = false;
      });
    }

    assessmentAdminController.save = function (content, comment) {
      return Assessment.update({
        content,
        comment
      }).$promise.then(() => {
        assessmentAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(assessmentAdminController.assessment_);
      });
    };

    assessmentAdminController.delete = function (assessment, comment) {
      Assessment.remove({
        comment: comment,
        id: assessment.id
      }).$promise.then(() => {
        feedbackObserver.dataChanged();
        Message.onRemoved();
      });
    };

    assessmentAdminController.deleteFull = function (assessment) {
      Assessment.deleteFull({
        id: assessment.id
      }).$promise.then(() => {
        Message.onRemoved();

        assessmentAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});