'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodSchemaFields
 * @description
 * methodSchemaFields Manages a method schema.
 */
angular.module('uasApp').component('methodSchemaFields', {
    bindings: {
        schema: '<',
        method: '<?',
        fields: '<',
        operations: '<',
        operationsToEdit: '<'
    },
    templateUrl: 'es6/methods/schema/method.schema.fields.html',
    controllerAs: 'methodSchemaFieldsController',
    controller: function(CustomField) {
        const methodSchemaFieldsController = this;

        methodSchemaFieldsController.$onInit = function() {
            setEvaluation();
        };
        
        methodSchemaFieldsController.$onChanges = function() {
            methodSchemaFieldsController.fields_ = _(methodSchemaFieldsController.fields)
                .filter((field) => field.name !== 'methodId')
                .sortBy(['sequence', 'name'])
                .value();

            setEvaluation();
        };

        methodSchemaFieldsController.onValue = function() {
            setEvaluation();
        };

        function setEvaluation() {
            const method = { 
                type: 'method', 
                id: _.result(methodSchemaFieldsController.method, 'id')
            };

            let properties = angular.copy(methodSchemaFieldsController.method);
            properties = _.extend(properties, methodSchemaFieldsController.schema);

            methodSchemaFieldsController.evaluation = CustomField.getEvaluation(method, properties, methodSchemaFieldsController.fields);
        }

    }
});