'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Script
 * @description
 * The Script service.
 */
angular.module('uasApp').factory('Script', function(UasResource) {
    return UasResource('/scripts', {
      queue: {
        url: '/scripts/queue',
        method: 'GET',
        isArray: true
      },
      clearQueue: {
        url: '/scripts/queue',
        method: 'DELETE',
        isArray: true
      }
    });
});
