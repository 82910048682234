'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:description
 * @description
 * # description Displays descriptions for an entity.
 */
angular.module('uasApp').component('description', {
  bindings: {
    entityType: '@',
    entityId: '<',
    academicYearId: '<?',
    process: '<?',
    types: '<',
    status: '<?',
    defaultMode: '<?',
    defaultViewMode: '<?',
    defaultEditor: '<?',
    operations: '<?',
    operationsToEdit: '<?',
    isReadOnly: '<?',
    showHelp: '<?',
    showIncompleteHelp: '<?'
  },
  templateUrl: 'es6/i18n/descriptions/description.html',
  controllerAs: 'descriptionController',
  controller: function ($q, $scope, $timeout, AuthService, SecurityService, Description, Describe, Message, Language, translateFilter,
                        Feedback, $translate, EntityTextHolder, Parameter, ChangeModal, DiffHtml, feedbackObserver, Changes, WorkflowValidator) {

    const descriptionController = this;

    descriptionController.$onInit = function () {
      descriptionController.containers = [];
      descriptionController.allowed = false;

      // Page sequence is the sequence of the description types in the page configuration
      descriptionController.sort = ['element.pageSequence', 'element.sequence', 'element.externalId'];

      descriptionController.status = 'open';
      descriptionController.operationsToEdit_ = AuthService.buildOperationsToEdit(descriptionController.operationsToEdit, 'EDIT_DESCRIPTIONS');
      descriptionController.editable = SecurityService.isAllowed(descriptionController.operationsToEdit_, descriptionController.operations);

      setMode();

      if (!descriptionController.isReadOnly) {
        WorkflowValidator.setValidity(() => descriptionController.descriptionForm);
        WorkflowValidator.onSave((comment) => {
          const descriptions = descriptionController.getAllDirty(descriptionController.descriptionForm);
          return descriptionController.saveAll(descriptions, comment);
        }, {
          rootType: descriptionController.entityType,
          entityType: 'description'
        });
      }
    };

    descriptionController.$onChanges = function (changes) {
      if ((Changes.hasChanged(changes, 'entityId') || Changes.hasChanged(changes, 'types')) && descriptionController.entityId) {
        loadData();
      }
    };

    descriptionController.$onDestroy = function() {
      WorkflowValidator.reset();
    };

    function setMode() {
      let mode = descriptionController.defaultMode || 'edit';
      if (descriptionController.isReadOnly === true || (mode === 'edit' && descriptionController.editable !== true)) {
        mode = 'preview';
      }
      descriptionController.mode = mode;
    }

    function loadData() {
      if (angular.isUndefined(descriptionController.academicYearId)) {
        descriptionController.academicYearId = sessionStorage.academicYear;
      }

      descriptionController.loading = true;
      $q.all([
        Description.query({
          entityType: descriptionController.entityType,
          entityId: descriptionController.entityId,
          academicYearId: descriptionController.academicYearId
        }).$promise,
        Describe.get({
          language: Language.get(),
          entityType: descriptionController.entityType,
          entityId: descriptionController.entityId
        }).$promise,
        Language.query({
          entityType: descriptionController.entityType,
          entityId: descriptionController.entityId
        }).$promise,
        Parameter.load()
      ]).then(([descriptions, entity, languages]) => {
        setLanguages(languages);

        descriptionController.entity = entity;
        descriptionController.editor = descriptionController.defaultEditor || Parameter.getParameterValue('description.editor', 'text');

        _.each(descriptions, (description) => {
          description.hide = description.text === '';
        });

        const grouped = _.groupBy(descriptions, 'typeId');
        descriptionController.containers = _.map(descriptionController.types, (element) => {
          // Only show supported languages
          const content = _.filter(grouped[element.id] || [], (description) => {
            return _.includes(descriptionController.languages, description.language);
          });

          const container = {
            element,
            content
          };

          setDescriptions(container);
          return container;
        });

        updateDescriptions();
        updatePreviewLabels();
        setChanges();
      }).finally(() => {
        descriptionController.loading = false;
      });
    }

    function setChanges() {
      Feedback.get({
        entityType: descriptionController.entityType,
        entityId: descriptionController.entityId,
        status: descriptionController.status === 'open' ? 'OPEN' : undefined,
        filterType: 'description',
        language: Language.get()
      }).$promise.then((feedback) => {
        _.each(descriptionController.containers, (container) => {
          _.each(container.content, (description) => {
            setChange(description, feedback);
          });
        });
      });
    }

    descriptionController.onStatus = function () {
      setChanges();
    };

    function setLanguages(languages) {
      descriptionController.requiredLanguages = languages;
      descriptionController.mainLanguage = descriptionController.mainLanguage || _.head(descriptionController.requiredLanguages) || Language.get();
      descriptionController.languages = Language.descriptions;

      if (!_.includes(descriptionController.languages, descriptionController.mainLanguage)) {
        descriptionController.mainLanguage = _.head(descriptionController.languages);
      }

      descriptionController.sortedLanguages = getSortedLanguages();
      descriptionController.helpText = buildHelpTexts();

      configureViewMode();
    }

    function configureViewMode() {
      let viewMode = descriptionController.viewMode || descriptionController.defaultViewMode || 'sideways';

      if (_.size(descriptionController.languages) === 1) {
        viewMode = 'single';
      }

      descriptionController.setViewMode(viewMode);
    }

    function setChange(description, feedback) {
      const selector = {
        change: {
          entity: {
            type: 'description',
            id: description.id
          }
        }
      };

      description.change = _.find(feedback.changes, selector);
      addDiff(description.change);
    }

    function addDiff(change) {
      if (change) {
        change.diff = buildDiff(change);
      }
    }

    function buildDiff(change) {
      if (!change.properties || change.properties.length === 0) {
        return '';
      }

      const source = _.head(change.properties).value.source;
      const target = _.head(change.properties).value.target;
      return DiffHtml.buildDiff(source, target);
    }

    function updatePreviewLabels() {
      descriptionController.previewLabels = _(descriptionController.containers)
        .filter(function (description) {
          return description.element.code === 'LONG_NAME';
        })
        .map('content')
        .flatten()
        .map(function (description) {
          return {
            language: description.language,
            label: description.text || (descriptionController.entity ? descriptionController.entity.entity.name : '')
          };
        })
        .value();
    }

    function getDynamicName(description) {
      return `${descriptionController.entityType}-${description.typeId}-${description.language}`;
    }

    function isDescriptionDirty(form, description) {
      if (angular.isUndefined(form)) {
        return false;
      }

      const name = 'form-' + getDynamicName(description);
      let dirty = _.result(form[name], '$dirty', false);
      if (dirty) {
        dirty = !!description.text || angular.isDefined(description.id);
      }
      return dirty;
    }

    descriptionController.isFormDirty = function (form) {
      return _.some(descriptionController.containers, function (container) {
        return _.some(container.content, function (description) {
          return isDescriptionDirty(form, description);
        });
      });
    };

    descriptionController.getAllDirty = function (form) {
      const dirtyModel = [];

      _.each(descriptionController.containers, function (container) {
        _.each(container.content, function (description) {
          if (isDescriptionDirty(form, description)) {
            const model = angular.copy(description);
            model.typeId = container.element.id;
            model.entity = {
              type: descriptionController.entityType,
              id: descriptionController.entityId
            };
            model.academicYearId = descriptionController.academicYearId;
            dirtyModel.push(model);
          }
        });
      });

      return dirtyModel;
    };

    /**
     * Saves all the descriptions
     */
    descriptionController.saveAll = function (descriptions, comment, form) {
      const body = {
        contents: descriptions,
        comment
      };

      return Description.update(body).$promise.then((result) => {
        feedbackObserver.dataChanged();
        loadData();
        if (angular.isDefined(form)) {
          form.$setPristine();
        }
        $scope.$broadcast('show-errors-reset');
        Message.addSuccess(translateFilter('Static.Message.DescriptionsAdjusted'));
        EntityTextHolder.clear({
          type: descriptionController.entityType,
          id: descriptionController.entityId
        });
        return result;
      });
    };

    descriptionController.isDisabled = function (element) {
      return descriptionController.editable !== true || element.ignore === true ||
        !SecurityService.isAllowed(element.operation, descriptionController.operations);
    };

    descriptionController.hasAnyChanges = function (language) {
      return descriptionController.hasChangesInLang(descriptionController.viewMode === 'single' ? descriptionController.mainLanguage : language);
    };

    descriptionController.hasChangesInLang = function (language) {
      return _.some(descriptionController.containers, (description) => {
        return _.some(description.content, (content) => {
          return content.language === language && !_.isEmpty(content.change) && !_.isEmpty(content.change.diff);
        });
      });
    };

    descriptionController.hasTextInLang = function (language) {
      return _.some(descriptionController.containers, (description) => {
        return _.some(description.content, (content) => {
          return content.language === language && !_.isEmpty(content.text);
        });
      });
    };

    function setDescriptions(container) {
      // Add missing languages to container
      _.forEach(descriptionController.languages, (language) => 
        addDescription(container, language)
      );

      // Prevent unnecessary layout shifts by sorting the languages based on if they are the main language or not
      container.content = _.sortBy(container.content, (description) =>
        _.indexOf(descriptionController.sortedLanguages, description.language)
      );
    }

    function addDescription(container, language) {
      if (angular.isUndefined(language)) {
        return;
      }

      let description = _.find(container.content, { language });
      if (angular.isUndefined(description)) {
        description = {
          typeId: container.element.id,
          language,
          text: ''
        };

        container.content.push(description);
      }

      description.hide = false;
      description.name = getDynamicName(description);
    }

    function updateDescriptions() {
      descriptionController.requiredLanguages_ = _.concat(descriptionController.requiredLanguages, getRequiredLanguages());

      _.forEach(descriptionController.containers, (container) => {
        _.forEach(container.content, (description) => {
          description.required = isRequired(container, description.language);
        });
      });
    }

    function getRequiredLanguages() {
      return _(descriptionController.containers)
        .filter((container) => container.element.code !== 'LONG_NAME')
        .map('content')
        .flatten()
        .filter((description) => !_.isEmpty(description.text))
        .map('language')
        .uniq()
        .value();
    }

    function isRequired(container, language) {
      let required = false;
      if (container.element.required === true) {
        required = _.includes(descriptionController.requiredLanguages_, language);
      }
      return required;
    }

    descriptionController.onChange = function (container) {
      updateDescriptions();

      if (container.element.code === 'LONG_NAME') {
        updatePreviewLabels();
      }
    };

    descriptionController.openChangeModal = function (change) {
      ChangeModal.openRevisions(change.id);
    };

    descriptionController.setMainLanguage = function () {
      $timeout(() => {
        updatePreviewLabels();
        descriptionController.sortedLanguages = getSortedLanguages();
        _.each(descriptionController.containers, setDescriptions);
      });
    };

    function buildHelpTexts() {
      const teachingLanguages = descriptionController.requiredLanguages;
      const optionalLanguages = _.difference(descriptionController.languages, teachingLanguages);

      const teachingLanguagePart = buildHelpText(teachingLanguages, 'Teaching');
      const optionalLanguagePart = buildHelpText(optionalLanguages, 'Optional');
      return _([teachingLanguagePart, optionalLanguagePart]).filter(angular.isDefined).join(' ');
    }

    function buildHelpText(values, part) {
      const filtered = _.filter(values || [], (language) => !_.isEmpty(language));
      if (_.isEmpty(filtered)) {
        return undefined;
      }

      const label = filtered.length > 1 ?
        `Studyguide.Descriptions.HelpText.Plural${part}Language` :
        `Studyguide.Descriptions.HelpText.Singular${part}Language`;

      const languages = _(filtered)
        .sortBy((language) => language !== descriptionController.mainLanguage)
        .map((language) => $translate.instant('Enum.' + language))
        .value()
        .join(', ');

      return $translate.instant(label, { languages: languages });
    }

    function getSortedLanguages() {
      // Prevent unnecessary layout shifts by sorting the languages based on if they are the main language or not
      return _.sortBy(descriptionController.languages, (language) => {
        return (language === descriptionController.mainLanguage) ? -1 : 1;
      });
    }

    descriptionController.sectionLanguageFilter = function (language) {
      if (descriptionController.viewMode === 'single') {
        return language === descriptionController.mainLanguage;
      }

      return true;
    };

    descriptionController.languageFilter = function (description) {
      return descriptionController.viewMode === 'single' ? (description.language === descriptionController.mainLanguage) : true;
    };

    descriptionController.setViewMode = function (viewMode) {
      descriptionController.viewMode = viewMode;
      descriptionController.columnWidth = (viewMode === 'sideways') ? (12 / _.size(descriptionController.languages)) : 12;
    };
  }
});
