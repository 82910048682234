'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('hooks', {
        url: '/hooks',
        template: '<hook-list></hook-list>',
        breadcrumb: {
            label: 'Static.Page.Hooks',
            root: true
        }
    });

});
