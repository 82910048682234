'use strict';

angular.module('uasApp').component('uasPageableReport', {
  bindings: {
    name: '@',
    entity: '<?',
    operations: '<?',
    reportType: '@?',
    cacheKey: '@',
    context: '@?',
    order: '@?',
    reverse: '<?',
    groups: '<',
    columns: '<',
    getData: '&',
    decorator: '&?',
    defaultTemplate: '@?',
    hideTemplates: '<?',
    hideHeader: '<?',
    rowClasses: '@?',
    reload: '<?',
    selectable: '<?',
    onClick: '&?',
    onRowSelect: '&?' // Called each time a row is selected / deselected
  },
  transclude: {
    'buttons': '?reportButtons'
  },
  templateUrl: 'es6/report/pageable.report.html',
  controllerAs: 'reportController',
  controller: function ($q, $timeout, translateFilter, BulkModal, Changes, EntityService, Pageable, Report, ReportTemplateService, SecurityService, COLUMN_TYPES) {
    const reportController = this;
    const REPORT_PAGE_SIZE = 50;

    reportController.$onInit = function () {
      reportController.COLUMN_TYPES = COLUMN_TYPES;
      reportController.editable = SecurityService.isAllowed('BULK_CHANGE', reportController.operations);
      reportController.headerId = _.uniqueId('pageable-report-');

      if (_.isEmpty(reportController.context)) {
        reportController.context = reportController.cacheKey;
      }
    };

    reportController.$onChanges = function (changes) {
      if (!reportController.pageable) {
        reportController.pageable = Pageable.of({
          order: reportController.order,
          reverse: reportController.reverse
        });
        reportController.defaultPageable = angular.copy(reportController.pageable);
      }

      if (Changes.hasChanged(changes, 'columns') && reportController.columns) {
        reportController.defaultColumns = angular.copy(getDefaultColumns());
        setColumnIds();
        setColumnNames();
      }

      if (Changes.hasChanged(changes, 'reportType')) {
        reportController.rootType = EntityService.getRootType(reportController.reportType);  
      }

      if (Changes.hasChanged(changes, 'reload') && reportController.reload) {
        if (reportController.reload.reset) {
          reportController.loaded = false;
          reportController.pageable.currentPage = 1;
        }

        if (!reportController.loaded) {
          loadData();
          clearSelection();
        } else {
          loadAll();
        }
      }
    };

    function getDefaultColumns() {
      return _(reportController.columns)
        .filter({ visible: true })
        .map((column, index) => ({
          code: column.code,
          sequence: index
        }))
        .value();
    }

    function loadData(total) {
      const code = reportController.pageable.order;
      const column = _.find(reportController.columns, { code }) || {};
      if (!column.code) {
        // Unknown sort column so fallback to id
        reportController.pageable.order = 'id';
        reportController.pageable.reverse = false;
      }

      // If the column is not sortable through the api, we must sort it in the client
      if (column.sortable === false && code !== 'id') {
        return loadAll();
      }

      let pageable = angular.copy(reportController.pageable);
      if (column.sortPath) {
        pageable.order = column.sortPath;
      }

      reportController.loading = true;
      return getData({
        pageable: pageable.build(),
        total
      }).then((report) => {
        reportController.report = report;
      }).finally(() => {
        reportController.loading = false;
        updateColumns();
      });
    }

    function getData(parameters) {
      return reportController.getData(parameters).then((report) => {
        report.content = _.map(report.content, buildRow);
        decorate(report.content);
        return report;
      });
    }

    function buildRow(row) {
      const id = _.result(row, 'entity.id', row.id);
      const selected = _.some(reportController.selectedRows, { id });

      if (row.data) {
        return _.extend(row, { selected });
      }

      let data = {};
      _.forEach(reportController.columns, (column) => {
        data[column.code] = _.result(row, column.code);
      });

      return {
        entity: { id: row.id },
        selected,
        data
      };
    }

    function decorate(rows) {
      if (reportController.decorator) {
        _.each(rows, function (row) {
          row.decoration = reportController.decorator({ row });
        });
      }
    }

    reportController.onSort = function () {
      loadData();
    };

    reportController.onChange = function () {
      const total = getTotal();
      return loadData(total);
    };

    function getTotal() {
      return _.result(reportController.report, 'totalElements');
    }

    reportController.getRows = function () {
      return loadAll().then((report) => {
        return forEachColumn((column) => Report.ensure(report.rows, column)).then(() => {
          if (reportController.selectable_) {
            return _(report.rows).filter((row) => _.some(reportController.selectedRows, row.entity)).map('data').value();
          } else {
            return _.map(report.rows, 'data');
          }
        });
      });
    };

    reportController.onSelectAll = function () {
      loadAll();
    };

    reportController.onSearch = function () {
      loadAll();
      reportController.searchColumns = true;
    };

    function loadAll() {
      reportController.loading = true;
      return buildReport().then((report) => {
        reportController.report = report;
        reportController.loaded = true;
        return report;
      }).finally(() => {
        reportController.loading = false;
        delete reportController.progress;

        updateColumns();
      });
    }

    function buildReport() {
      const report = {
        groups: reportController.groups,
        headers: _.map(reportController.columns, (column) => {
          const provided = _.result(column, 'provided', true);
          return _.extend(column, { provided });
        }),
        defaultColumns: reportController.defaultColumns,
        rows: []
      };

      return addRows(report, 1);
    }

    function addRows(report, page) {
      const pageable = _.extend(reportController.pageable.build());
      pageable.page = page;
      pageable.size = REPORT_PAGE_SIZE;
      pageable.sort = 'id,ASC';

      const total = getTotal();

      return getData({
        pageable: pageable,
        total: total
      }).then((result) => {
        reportController.progress = {
          current: result.number,
          total: result.totalPages
        };

        _.forEach(result.content, (row) => {
          report.rows.push(row);
        });

        if (result.last) {
          return report;
        } else {
          return addRows(report, page + 1);
        }
      });
    }

    function setColumnIds() {
      // The column dropdown requires a unique numeric id for each column
      _.forEach(reportController.columns, (column) => {
        if (angular.isUndefined(column.id)) {
          column.id = _.parseInt(_.uniqueId());
        }
      });
    }

    function setColumnNames() {
      _.forEach(reportController.columns, (column) => {
        if (angular.isUndefined(column.name)) {
          column.name = translateFilter(column.label);
        }
      });
    }

    function updateColumns() {
      updateHeaderSequences();

      reportController.reportColumns = _(reportController.columns)
        .filter('visible')
        .map((column) => ({
          id: column.id,
          code: column.code,
          name: column.name,
          valueType: column.valueType || 'string',
          visible: column.visible,
          editable: column.editable,
          sequence: column.sequence
        }))
        .sortBy('sequence')
        .value();

      reportController.selectedColumnsIds = _.map(reportController.reportColumns, 'id');

      reportController.selectable_ = reportController.selectable === true || (reportController.editable && reportController.reportType);
      if (reportController.selectable_) {
        reportController.selectedRows = reportController.selectedRows || [];
      }
      ensureHeaders();
    }

    reportController.onColumn = function (selectedIds) {
      _.forEach(reportController.columns, (column) => {
        delete column.sequence;

        const index = _.findIndex(selectedIds, (id) => id === column.id);
        if (index !== -1) {
          column.sequence = index;
        }
      });

      updateColumns();
    };

    reportController.setTemplate = function (template) {
      reportController.template = template;

      if (angular.isDefined(template)) {
        reportController.pageable.order = template.sort;
        reportController.pageable.reverse = template.direction === 'DESC';

        _.forEach(reportController.columns, (column) => {
          const header = _.find(reportController.template.headers, { name: column.code });
          setColumn(column, header);
        });
      } else {
        loadPreference();
      }

      reportController.searchColumns = reportController.searchColumns || _.some(reportController.columns, (column) => !_.isEmpty(column.search));
      if (reportController.searchColumns) {
        loadAll();
      } else {
        loadData();
      }
    };

    function setColumn(column, header) {
      column.visible = false;
      delete column.sequence;

      if (header) {
        column.visible = true;
        column.sequence = header.sequence;
        column.search = header.value;
      }
    }

    function updateHeaderSequences() {
      $timeout(() => {
        _.forEach(reportController.selectedColumnsIds, (columnId, i) => {
          const found = _.find(reportController.columns, { id: columnId });
          if (found) {
            found.sequence = i;
          }
        });
      });
    }

    function loadPreference() {
      const preference = Report.getPreferences(reportController.template, {
        order: reportController.defaultPageable.order,
        reverse: reportController.defaultPageable.reverse,
        headers: reportController.defaultColumns
      });

      _.forEach(reportController.columns, (column) => {
        const header = _.find(preference.headers, { code: column.code });
        setColumn(column, header);
      });

      reportController.pageable.order = preference.order;
      reportController.pageable.reverse = preference.reverse;
    }

    reportController.edit = function (column, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      BulkModal.open({
        rows: reportController.selectedRows,
        column,
        onChange: loadData
      });
    };

    reportController.onEdit = function () {
      reportController.loaded = false;
      return loadAll();
    };

    // Redirects

    reportController.doOnClick = function (row, col) {
      if (reportController.onClick) {
        reportController.onClick({
          row,
          col
        });
      } else {
        routeToEntity(row);
      }
    };

    function routeToEntity(row) {
      EntityService.redirectPlain(row.entity, {
        target: '_blank'
      });
    }

    reportController.onSelectRows = function (rows) {
      setSelectedRows(rows);
    };

    reportController.onSelect = function (row) {
      let rows = reportController.selectedRows;
      if (angular.isUndefined(rows)) {
        rows = _(reportController.report.content).filter({ selected: true }).map('entity').value();
      } else if (row.entity) {
        _.remove(rows, row.entity);
        if (row.selected === true) {
          rows = _.concat(rows, row.entity);
        }
      }

      setSelectedRows(rows);
    };

    function setSelectedRows(rows) {
      reportController.selectedRows = angular.copy(rows);

      if (_.isFunction(reportController.onRowSelect)) {
        reportController.onRowSelect({ rows });
      }
    }

    function clearSelection() {
      setSelectedRows([]);
      reportController.selectAll = false;
    }

    // Decoration

    function ensureHeaders() {
      return forEachColumn(ensureHeader);
    }

    function forEachColumn(callback) {
      const promises = _(reportController.columns)
        .filter({ visible: true })
        .map(callback)
        .value();

      return $q.all(promises);
    }

    function ensureHeader(column) {
      const rows = _.result(reportController.report, 'content', []);
      return Report.ensure(rows, column);
    }

    // Report templates

    reportController.onSave = function (createNew) {
      updateHeaderSequences();

      ReportTemplateService.save({
        type: reportController.cacheKey,
        createNew: createNew,
        template: reportController.template,
        pageable: reportController.pageable,
        headers: getHeaders
      }).then((template) => {
        reportController.template = template;
        Report.savePreferences(reportController.cacheKey, reportController.template);
      });
    };

    function getHeaders() {
      return _(reportController.columns)
        .filter({ visible: true })
        .map((column) => ({
          name: column.code,
          sequence: column.sequence
        }))
        .value();
    }
  }
});
