'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:operationsPage
 * @description
 * operationsPage Shows granted operations
 */
angular.module('uasApp')
    .component('operationsPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<'
        },
        templateUrl: 'es6/entity-operations-overview/operations.page.html',
        controllerAs: 'operationsPageController',
        controller: function(EntityService) {
            const operationsPageController = this;

            operationsPageController.$onInit = function() {
                operationsPageController.reference = EntityService.toReference(operationsPageController.entity);
            };
        }
    });
