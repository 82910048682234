'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {
    $stateProvider.state('news', {
        url: '/news',
        template: '<uas-news></uas-news>',
        breadcrumb: {
            label: 'Static.Page.News',
            root: true
        },
        admin: true
    });
});
