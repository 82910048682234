'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Store
 * @description
 * The storage service.
 */
angular.module('uasApp')
    .factory('$store', function() {
        return function() {
            var data = [];
            var instance = {};

            /*
             * Filter all matching entities.
             */
            instance.query = function(args) {
                return _.filter(data, args);
            };

            /*
             * Find the first matching entity.
             */
            instance.get = function(args) {
                return _.find(data, args);
            };

            /*
             * Save an entity.
             */
            instance.save = function(args, entity) {
                var index = _.findIndex(data, args);
                entity = entity || args;
                if (index >= 0) {
                    data.splice(index, 1, entity);
                } else {
                    data.push(entity);
                }
            };

            instance.saveAll = function(entities) {
                _.each(entities, function(entity) {
                    instance.save(entity);
                });
            };

            /*
             * Remove all matching entities.
             */
            instance.remove = function(args) {
                _.remove(data, args);
            };

            return instance;
        };
    });
