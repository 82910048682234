'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('tabs', {
        url: '/tabs?rootType',
        controllerAs: 'tabsStateController',
        controller: function ($stateParams) {
            this.rootType = $stateParams.rootType;
        },
        template: '<tabs-list root-type="tabsStateController.rootType"></tabs-list>',
        breadcrumb: {
            label: 'Static.Page.Tabs',
            root: true
        },
        params: {
            rootType: {
                value: '',
                dynamic: true
            }
        },
        admin: true
    });

    $stateProvider.state('tab-groups', {
        url: '/tab-groups',
        template: '<tab-group-list></tab-group-list>',
        breadcrumb: {
            label: 'Static.Page.TabGroups',
            root: true
        },
        admin: true
    });

});