'use strict';

angular.module('uasApp').component('effortReportPage', {
    bindings: {
        entity: '<',
        page: '<',
        workflow: '<?',
        operations: '<'
    },
    templateUrl: 'es6/staffing/effort/report/effort.report.page.html',
    controllerAs: 'effortReportPageController'
});