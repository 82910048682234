'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ValidationOverride
 * @description
 * The ValidationOverride service.
 */
angular.module('uasApp').factory('ValidationOverride', function (UasResource) {
  return UasResource('/validation-override');
});
