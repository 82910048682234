'use strict';

/**
 * @ngdoc directive
 * @name uasApp.component:validLabe
 * @description Shows a valid or invalid icon.
 */
angular.module('uasApp').component('validLabel', {
  templateUrl: 'es6/app/forms/validation/valid.label.html',
  bindings: {
    valid: '@?',
    label: '@?',
    viewClasses: '<?'
  },
  controllerAs: 'validLabelController'
});
