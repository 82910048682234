'use strict';

angular.module('uasApp').filter('minutesAsHours', function($filter) {
    return function(minutes) {
        if (angular.isUndefined(minutes)) {
            return minutes;
        }

        const hours = Math.floor(minutes / 60);
        const remainder = Math.floor(minutes % 60);
        return '' + hours + ':' + $filter('numberFill')(remainder, 2);
    };
});
