'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowProgress
 * @description
 * uasWorkflowProgress Shows the workflow progress.
 */
angular.module('uasApp')
  .component('uasProcessProceed', {
    bindings: {
      entity: '<',
      process: '<',
      onChange: '&',
      btnSize: '@?'
    },
    templateUrl: 'es6/process/progress/process.proceed.html',
    controllerAs: 'proceedController',
    controller: function ($uibModal, Process) {
      const proceedController = this;

      proceedController.$onChanges = function() {
        loadData();
      };

      function loadData() {
        const entityType = _.get(proceedController.entity, 'self.type');

        if (proceedController.entity) {
          Process.outcomes({
            id: proceedController.process.id,
            entityType,
            entityId: proceedController.entity.id
          }).$promise.then((result) => {
            proceedController.successes = _.filter(result.outcomes, { success: true });
            proceedController.failures = _.filter(result.outcomes, { success: false });
          });
        }
      }

      proceedController.approve = function(outcomes) {
        perform(true, outcomes);
      };

      proceedController.reject = function(outcomes) {
        perform(false, outcomes);
      };

      function perform(success, outcomes) {
        $uibModal.open({
          resolve: {
            workflow: function () {
              return proceedController.workflow;
            }
          },
          controllerAs: 'workflowCompleteController',
          controller: function (workflow, $uibModalInstance) {
            const workflowCompleteController = this;

            workflowCompleteController.workflow = workflow;
            workflowCompleteController.outcomes = outcomes;
            workflowCompleteController.outcome = outcomes[0];
            workflowCompleteController.success = success === true;

            workflowCompleteController.submit = function () {
              $uibModalInstance.dismiss();
              doComplete(workflowCompleteController.outcome, workflowCompleteController.message);
            };
          },
          templateUrl: 'es6/workflow/workflow.complete.modal.html'
        });
      }

      function doComplete(outcome, message) {
        const body = {
          entity: {
            type: proceedController.entity.self.type,
            id: proceedController.entity.id
          },
          success: outcome.success,
          status: outcome.next.id,
          message: message
        };

        Process.proceed({
          id: proceedController.process.id
        }, body).$promise.then((result) => {
          loadData();
          proceedController.onChange({ status: result.status });
        });
      }
    }
  });
