'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Studyable
 * @description The Studyable service.
 */
angular.module('uasApp')
    .factory('Studyable', function(UasResource) {
        return UasResource('/studyable', {
          programme: {
            url: '/studyable/programme',
            method: 'GET',
            isArray: true
          }
        });
    });
