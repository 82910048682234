'use strict';

angular.module('uasApp')
    .directive('secured', function(SecurityService, $timeout) {
        const instance = {};

        const disableAll = function(element) {
            const elementsDisable = [];
            const elementsDisplayNone = [];
            const elementsReadonly = [];

            if (element.hasClass('authorized')) {
                elementsDisplayNone.push(element);
            }

            elementsDisable.push(element.find('toggle'));
            elementsDisable.push(element.find('input:not(.public)'));
            elementsDisable.push(element.find('textarea'));
            elementsDisable.push(element.find('select'));

            elementsDisplayNone.push(element.find('button:not(.public)'));
            elementsDisplayNone.push(element.find('div.button:not(.public)'));
            elementsDisplayNone.push(element.find('a:not(.public)'));
            elementsDisplayNone.push(element.find('.authorized'));

            elementsReadonly.push(element.find('.input-group-addon:not(.public)'));

            const buttonOptions = element.find('button-options');
            buttonOptions.addClass('btn-options-disabled');

            elementsDisable.forEach((e) => {
                e.attr('disabled', 'disabled');
                e.attr('secured-touched', 'true');
            });
            elementsDisplayNone.forEach((e) => {
                e.attr('style', 'display:none');
                e.attr('secured-touched', 'true');
            });
            elementsReadonly.forEach((e) => {
                e.addClass('readonly');
                e.attr('secured-touched', 'true');
            });
        };

        const enableAll = function(element) {
            const touched = element.find('[secured-touched=true]');
            touched.removeAttr('disabled');
            touched.removeAttr('style');
            touched.removeClass('readonly');

            if (element.hasClass('authorized')) {
                element.removeAttr('style');
            }
        };

        instance.checkSecurity = function(scope, element) {
            const readOnly = scope.isReadOnly === true;
            const granted = !readOnly && SecurityService.isAllowed(scope.secured, scope.operations);
            instance.updateSecurity(granted, element);
        };

        instance.updateSecurity = function(allowed, element) {
            if (allowed !== true) {
                disableAll(element);
            } else {
                enableAll(element);
            }
            instance.allowed = allowed;
        };

        return {
            restrict: 'A',
            scope: {
                secured: '@',
                operations: '<',
                isReadOnly: '<?'
            },
            link: function(scope, element) {
                // run security checks after DOM has been rendered.
                // Must be delayed to allow input and components and directives to render first
                $timeout(() => {
                    scope.$watch('operations', () => {
                        instance.checkSecurity(scope, element);
                    });
                    scope.$on('secure', () => {
                        instance.checkSecurity(scope, element);
                    });
                }, 0);
            },
            controller: function($scope) {
                this.resecure = function(element) {
                    $timeout(() => {
                        instance.checkSecurity($scope, element);
                    }, 0);
                };
            }
        };
    });
