'use strict';

angular.module('uasApp').component('studiesReportPage', {
  templateUrl: 'es6/study/report/studies.report.page.html',
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<'
  },
  controllerAs: 'studiesReportPageController',
  controller: function (Page, Study, WorkflowModal, entityRedirect) {
    const studiesReportPageController = this;

    studiesReportPageController.$onInit = function () {
      studiesReportPageController.readOnly = Page.getParameterAsBoolean(studiesReportPageController.page, 'readOnly', false);

      const modal = Page.getParameterAsBoolean(studiesReportPageController.page, 'modal', false);
      studiesReportPageController.click = modal ? open : redirect;
      studiesReportPageController.refresh = { loadData: _.noop };

      studiesReportPageController.reportTemplate = Page.getParameter(studiesReportPageController.page, 'reportTemplate');
      studiesReportPageController.hideReportTemplates = Page.getParameterAsBoolean(studiesReportPageController.page, 'hideReportTemplates', false);

      studiesReportPageController.showAll = Page.getParameterAsBoolean(studiesReportPageController.page, 'showAll', false);
      if (studiesReportPageController.showAll) {
        delete studiesReportPageController.entity;
      }
    };

    function open(row) {
      Study.get({
        id: row.entity.id
      }).$promise.then((study) => {
        WorkflowModal.open({
          entity: study,
          onSave: studiesReportPageController.refresh.loadData,
          onCancel: studiesReportPageController.refresh.loadData
        });
      });
    }

    function redirect(row) {
      entityRedirect.go('study.general', { id: row.entity.id }, { target: '_blank' });
    }
  }
});
