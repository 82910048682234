'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('admin', {
        url: '/admin',
        template: '<uas-admin-screen></uas-admin-screen>',
        breadcrumb: {
            label: 'Static.Page.Admin',
            root: true
        },
        admin: true
    });

    $stateProvider.state('config', {
        url: '/config',
        template: '<uas-admin-config></uas-admin-config>',
        breadcrumb: {
            label: 'Static.Page.Config',
            root: true
        },
        admin: true
    });

    $stateProvider.state('exports', {
        url: '/exports',
        template: '<exports></exports>',
        breadcrumb: {
            label: 'Static.Page.Exports',
            root: true
        },
        admin: true
    });

    $stateProvider.state('features', {
        url: '/features',
        template: '<uas-features></uas-features>',
        breadcrumb: {
            label: 'Static.Page.Features',
            root: true
        },
        admin: true
    });

    $stateProvider.state('mails', {
        url: '/mails',
        template: '<mail-list></mail-list>',
        breadcrumb: {
            label: 'Static.Page.Mails',
            root: true
        },
        admin: true
    });

    $stateProvider.state('jobs', {
        url: '/jobs',
        template: '<uas-jobs></uas-jobs>',
        breadcrumb: {
            label: 'Static.Page.Jobs',
            root: true
        },
        admin: true
    });

    $stateProvider.state('notification-templates', {
        url: '/notification-templates',
        template: '<notification-templates></notification-templates>',
        breadcrumb: {
            label: 'Static.Page.NotificationTemplates',
            root: true
        },
        admin: true
    });

    $stateProvider.state('notification-styles', {
        url: '/notification-styles',
        template: '<notification-styles></notification-styles>',
        breadcrumb: {
            label: 'Static.Page.NotificationStyles',
            root: true
        },
        admin: true
    });

    $stateProvider.state('appraisal-templates', {
        url: '/appraisal-templates',
        template: '<appraisal-templates></appraisal-templates>',
        breadcrumb: {
            label: 'Static.Page.AppraisalTemplates',
            root: true
        },
        admin: true
    });

    $stateProvider.state('script', {
        url: '/script',
        template: '<uas-admin-script></uas-admin-script>',
        breadcrumb: {
            label: 'Script',
            root: true
        },
        admin: true
    });

});
