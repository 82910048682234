'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Pageable
 * @description
 * The Pageable service.
 */
angular.module('uasApp')
  .factory('Pageable', function (Sort, orderByFilter) {

    function of(values) {
        const pageable = _.extend({
            currentPage: 1,
            pageSize: integerOf(localStorage.pageSize) || 10,
            maxSize: 3,
            order: '',
            reverse: false
        }, values);

        return _.extend(pageable, {
            next: function() {
                const result = angular.copy(this);
                result.currentPage = this.currentPage + 1;
                return result;
            },
            get: function() {
                return {
                    page: this.currentPage,
                    size: this.pageSize,
                    sort: Sort.get(this)
                };
            },
            build: function(args) {
                const params = this.get();
                return _.extend(angular.copy(args), params);
            },
            unsorted: function() {
                const result = angular.copy(this);
                result.order = '';
                return result;
            },
            save: function() {
                localStorage.pageSize = this.pageSize;
            },
            sort: function(rows, defaultSort) {
                const args = _.concat(this.order, defaultSort);
                return orderByFilter(rows, args, this.reverse);
            }
        });
    }

    function integerOf(value) {
        if (value) {
            return parseInt(value);
        }
    }

    function parse(parameters) {
        const sort = Sort.parse(parameters.sort);

        let values = {
            order: sort.order,
            reverse: sort.reverse
        };

        if (parameters.page) {
            values.currentPage = parseInt(parameters.page);
        }

        if (parameters.size) {
            values.pageSize = parseInt(parameters.size);
        }

        return of(values);
    }

    function empty(pageable) {
        return {
            contents: [],
            totalElements: 0,
            totalPages: 0,
            page: pageable.page,
            size: pageable.size
        };
    }

    function wrap(content, pageable, totalElements) {
        const totalPages = Math.ceil(totalElements / pageable.pageSize);

        return {
            number: pageable.currentPage,
            size: pageable.pageSize,
            totalElements,
            totalPages,
            content,
            first: pageable.currentPage <= 1,
            last: pageable.currentPage >= (totalPages - 1)
        };
    }

    return { of, parse, empty, wrap };
  });