'use strict';

angular.module('uasApp').component('uasDateRange', {
  bindings: {
    startDate: '<?',
    endDate: '<?'
  },
  templateUrl: 'es6/app/date.range.html',
  controllerAs: 'dateRangeController',
  controller: function (configDateFilter, translateFilter, Language) {
    const dateRangeController = this;

    dateRangeController.$onInit = function () {
      dateRangeController.formatted = formatDates();
      dateRangeController.onLanguage = Language.onChange(formatDates);
    };

    dateRangeController.$onDestroy = function () {
      dateRangeController.onLanguage();
    };

    function formatDates() {
      if (!dateRangeController.startDate && !dateRangeController.endDate) {
        return;
      }
      let dates = [];
      if (dateRangeController.startDate) {
        dates.push(`${translateFilter('Static.Label.From')} ${configDateFilter(dateRangeController.startDate)}`);
      }
      if (dateRangeController.endDate) {
        dates.push(`${translateFilter('Static.Label.To')} ${configDateFilter(dateRangeController.endDate)}`);
      }
      return `(${dates.join(' ')})`;
    }
  }
});
