'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:NotificationTemplate
 * @description
 * The NotificationTemplate service.
 */
angular.module('uasApp')
    .factory('NotificationTemplate', function(UasResource) {
        return UasResource('/notification-template', {
            manual: {
                method: 'GET',
                url: '/notification-template/manual',
                isArray: true
            },
            events: {
                method: 'GET',
                url: '/notification-template/events',
                isArray: true
            },
            tags: {
                method: 'GET',
                url: '/notification-template/tags',
                isArray: true
            }
        });
    });
