'use strict';

angular.module('uasApp').component('scaleEdit', {
  templateUrl: 'es6/subjects/scale/scale.edit.html',
  bindings: {
    scale: '<',
    onSave: '&',
    onCancel: '&'
  },
  controllerAs: 'scaleEditController'
});
