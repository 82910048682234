'use strict';

angular.module('uasApp').component('scaleList', {
  templateUrl: 'es6/subjects/scale/scale.list.html',
  bindings: {
    selected: '<',
    onSelect: '&'
  },
  controllerAs: 'scaleListController',
  controller: function ($uibModal, Message, Pageable, Scale) {
    const scaleListController = this;
    scaleListController.loading = true;

    scaleListController.$onInit = () => {
      scaleListController.pageable = Pageable.of({
        order: 'code'
      });

      scaleListController.search();
    };

    scaleListController.search = function () {
      const params = scaleListController.pageable.build();

      scaleListController.loading = true;
      return Scale.get(params).$promise.then((scales) => {
        scaleListController.scales = scales;

        const found = _.find(scales, { id: _.get(scaleListController.selected, 'id') });
        if (!found) {
          const scale = _.first(scales.content);
          scaleListController.select(scale);
        }
      }).finally(() => {
        scaleListController.loading = false;
      });
    };

    scaleListController.select = function (scale) {
      if (angular.isDefined(scale)) {
        scaleListController.selected = scale;
        scaleListController.onSelect({ scale });
      }
    };

    scaleListController.create = function () {
      scaleListController.edit({ color: {} });
    };

    scaleListController.edit = function (scale) {
      $uibModal.open({
        size: 'lg',
        template: `
          <scale-edit 
            scale="modalController.scale"
            on-save="modalController.save(scale)"
            on-cancel="modalController.close()">
          </scale-edit>`,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.scale = angular.copy(scale);

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };

          modalController.save = function (updated) {
            Scale.store(updated).$promise.then(() => {
              modalController.close();
              scaleListController.search();
              Message.onSaved();
            });
          };
        }
      });
    };

    scaleListController.delete = function (scale) {
      Scale.remove(scale).$promise.then(() => {
        scaleListController.search();
        Message.onRemoved();
      });
    };
  }
});
