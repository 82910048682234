'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assessmentFilter
 * @description
 * Shows the different assessment report filters.
 */
angular.module('uasApp')
  .component('assessmentFilter', {
    bindings: {
        mode: '<',
        onClick: '&'
    },
    templateUrl: 'es6/assessment/assessment.filter.html',
    controllerAs: 'assessmentFilterController',
    controller: function() {
        const assessmentFilterController = this;

        assessmentFilterController.$onInit = function() {
            assessmentFilterController.modes = [
                { name: 'SHOW_ALL', icon: 'fa-asterisk', label: 'Enum.SHOW_ALL' },
                { name: 'OWNED_MODULE', icon: 'fa-paperclip', label: 'Enum.OWNED_MODULE' },
                { name: 'IN_FACULTY', icon: 'fa-building-o', label: 'Enum.IN_FACULTY' }
            ];

            assessmentFilterController.current = findMode(assessmentFilterController.mode);
        };

        function findMode(name) {
            return _.find(assessmentFilterController.modes, { name: name });
        }

        assessmentFilterController.setMode = function(mode) {
            assessmentFilterController.current = mode;

            const name = _.get(mode, 'name');
            assessmentFilterController.mode = name;
            assessmentFilterController.onClick({ mode: name });
        };
    }
});
