'use strict';

angular.module('uasApp').component('effortTable', {
    bindings: {
        entity: '<',
        template: '<',
        relations: '<',
        periodId: '<?',
        active: '<',
        operations: '<',
        isReadOnly: '<',
        showUnassigned: '<?',
        workflowMode: '<',
        onChange: '&',
        onSwitch: '&'
    },
    templateUrl: 'es6/staffing/effort/effort.table.html',
    controllerAs: 'effortTableController',
    controller: function(Task, TaskModal, Relation, RelationModal, Effort, AvailabilityModal, SecurityService, Pageable, Message, feedbackObserver, $q, $uibModal) {
        const effortTableController = this;

        effortTableController.$onInit = function() {
            effortTableController.pageable = Pageable.of({
                order: 'hours',
                reverse: true
            });

            const workflowMode = effortTableController.workflowMode === true;
            effortTableController.operationsToEdit = workflowMode ? ['EDIT_TASKS_WORKFLOW'] : ['EDIT_TASKS'];

            effortTableController.editable = 
                effortTableController.isReadOnly !== true && 
                SecurityService.isAllowed(effortTableController.operationsToEdit, effortTableController.operations);

            effortTableController.correctable = 
                effortTableController.isReadOnly !== true && 
                SecurityService.isAllowed(workflowMode ? 'EDIT_EFFORTS_WORKFLOW' : 'EDIT_EFFORTS', effortTableController.operations) && 
                SecurityService.isAllowed('VIEW_EFFORTS_CORRECTION', effortTableController.operations);

            loadData();
        };

        function loadData() {
            effortTableController.loading = true;
            getTasks().then((tasks) => {
                setRows(tasks);
                effortTableController.onSort();
            }).finally(() => {
                effortTableController.loading = false;
            });
        }

        function getTasks() {
            if (angular.isUndefined(effortTableController.template)) {
                return $q.resolve([]);
            }

            return Task.query({
                templateId: effortTableController.template.id,
                entityType: effortTableController.entity.type,
                entityId: effortTableController.entity.id,
                periodId: effortTableController.periodId
            }).$promise;
        }

        function setRows(tasks) {
            let rows = _.map(effortTableController.relations, (relation) => {
                const task = _.find(tasks, { relationId: relation.id });
                const assignment = relation.assignment;
                const availability = relation.availability;

                return {
                    allocationValue: _.result(task, 'allocationValue', -1),
                    assignment,
                    availableHours: _.result(availability, 'availableHours', 0),
                    availability,
                    correction: _.result(task, 'correction', 0),
                    hours: _.result(task, 'hours', 0),
                    relation: _.omit(relation, ['assignment', 'availability']),
                    task
                };
            });

            if (effortTableController.showUnassigned !== true) {
                rows = _.filter(rows, (row) => angular.isDefined(row.assignment));
            }

            effortTableController.rows = rows;
            setEffort(tasks);
        }

        function setEffort(tasks) {
            if (_.isEmpty(tasks)) {
                delete effortTableController.effort;
            } else {
                const taskId = _(tasks).map('id').find();
                Effort.findByTask(taskId).then((effort) => {
                    effortTableController.effort = effort;
                });
            }

            effortTableController.allocationValue = _.sumBy(tasks, 'allocationValue');
        }

        effortTableController.onSort = function() {
            effortTableController.rows = effortTableController.pageable.sort(effortTableController.rows, 'id');
        };

        effortTableController.show = function(assignment) {
            const assignmentId = _.result(assignment, 'id');
            if (angular.isDefined(assignmentId)) {
                effortTableController.onSwitch();
                AvailabilityModal.open({
                    assignmentId,
                    isReadOnly: effortTableController.isReadOnly,
                    workflowMode: effortTableController.workflowMode,
                    onChange: () => effortTableController.onChange()
                });
            }
        };

        effortTableController.createRelation = function() {
            openRelation({
                entity: effortTableController.entity
            });
        };

        effortTableController.editRelation = function(relation) {
            const form = angular.copy(relation);
            openRelation(form);
        };

        function openRelation(relation) {
            RelationModal.open({
                relation,
                role: effortTableController.template.role,
                entity: effortTableController.entity,
                operations: effortTableController.operations,
                workflowMode: effortTableController.workflowMode,
                onChange: effortTableController.onChange
            });
        }

        effortTableController.removeRelation = function(relation, comment) {
            Relation.save({
                comment: {
                    message: comment
                },
                removes: [relation.id]
            }).$promise.then(() => {
                Message.onRemoved();
                feedbackObserver.dataChanged();
                effortTableController.onChange();
            });
        };

        effortTableController.editTask = function(task, assignment) {
            if (angular.isDefined(task)) {
                const form = angular.copy(task);
                form.templateId = task.template.id;
                form.assignmentId = assignment.id;
                
                TaskModal.open({
                    task: form,
                    correctable: effortTableController.correctable,
                    workflowMode: effortTableController.workflowMode,
                    onChange: loadData
                });
            }
        };

        effortTableController.saveTask = function(task) {
            const form = angular.copy(task);
            form.templateId = task.template.id;

            return Task.store(form).$promise.then(() => {
                effortTableController.onChange({ refresh: false });

                return getTasks().then((tasks) => {
                    const found = _.find(tasks, { id: task.id });
                    if (angular.isDefined(found)) {
                        task.formula = found.formula;
                        task.hours = found.hours;
                        task.correctedHours = found.correctedHours;
                    }

                    setEffort(tasks);
                });
            });
        };

        effortTableController.edit = function() {
            $uibModal.open({
                template: `
                    <effort-edit
                        effort="modalController.effort"
                        on-save="modalController.save(effort)"
                        on-close="modalController.close()">
                    </effort-edit>
                `,
                controllerAs: 'modalController',
                controller: function($uibModalInstance) {
                    const modalController = this;
                    modalController.effort = angular.copy(effortTableController.effort);

                    modalController.save = function(effort) {
                        effortTableController.effort = effort;
                        effortTableController.onChange();
                        modalController.close();
                    };

                    modalController.close = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        };
    }
});