'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:moduleGroupsPage
 * @description
 * The moduleGroupsPage component.
 */
angular.module('uasApp').component('moduleGroupsPage', {
  templateUrl: 'es6/module/module.groups.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    workflow: '<?',
    isReadOnly: '<?'
  },
  controllerAs: 'moduleGroupsPageController',
  controller: function (Page, GroupType) {
    const moduleGroupsPageController = this;

    moduleGroupsPageController.$onInit = function () {
      moduleGroupsPageController.readOnly = moduleGroupsPageController.isReadOnly || Page.getParameterAsBoolean(moduleGroupsPageController.page, 'readOnly', false);
      moduleGroupsPageController.showRedirect = Page.getParameterAsBoolean(moduleGroupsPageController.page, 'redirect', false);

      loadData();
    };

    function loadData() {
      const typeCode = Page.getParameter(moduleGroupsPageController.page, 'type');
      if (typeCode) {
        moduleGroupsPageController.loading = true;
        GroupType.query().$promise.then((types) => {
          moduleGroupsPageController.type = _.find(types, { code: typeCode });
        }).finally(() => {
          moduleGroupsPageController.loading = false;
        });
      }
    }
  }
});
