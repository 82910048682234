'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:statusLabel
 * @description
 * # StatusLabel
 * Clickable label for any entity.
 */
angular.module('uasApp')
    .directive('changeStatusLabel', function() {
        return {
            restrict: 'E',
            scope: {
                change: '=',
                clickable: '=',
                entityTypes: '<?',
                noText: '@?'
            },
            templateUrl: 'es6/changes/change.status.label.html',
            controller: function($scope, ChangeModal, CHANGE_STATUS) {
                $scope.resolveMapping = function(status) {
                    const mapping = CHANGE_STATUS[status] || {};
                    return {
                        label: 'label-' + mapping.color,
                        icon: mapping.icon
                    };
                };

                $scope.openRevisionModal = function($event) {
                    if ($scope.clickable === false) {
                        return;
                    }

                    $event.preventDefault();
                    $event.stopPropagation();

                    ChangeModal.openRevisions($scope.change.id);
                };
            }
        };
    });
