'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:reportTemplateDropdown
 * @description Shows the dropdown to search, select and edit report templates
 */
angular.module('uasApp').component('reportTemplateDropdown', {
  bindings: {
    type: '@',
    template: '<?',
    defaultTemplate: '@?',
    hide: '<?',
    onSave: '&',
    onSelect: '&'
  },
  templateUrl: 'es6/report/template/report.template.dropdown.html',
  controllerAs: 'reportTemplateDropdownController',
  controller: function ($q, AuthService, Changes, Message, Report, ReportTemplate, ReportTemplateService) {
    const reportTemplateDropdownController = this;

    reportTemplateDropdownController.$onInit = function () {
      reportTemplateDropdownController.filterId = _.uniqueId();
      reportTemplateDropdownController.isAdmin = AuthService.isAdmin();
    };

    reportTemplateDropdownController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'template')) {
        getTemplates().then(() => {
          if (!reportTemplateDropdownController.template) {
            setDefaultTemplate();
          }
        });
      }
    };

    function getTemplates() {
      return ReportTemplateService.getByType(reportTemplateDropdownController.type).then((templates) => {
        reportTemplateDropdownController.templates = templates;
        prepareData();
      });
    }

    function prepareData() {
      const grouped = _.groupBy(reportTemplateDropdownController.templates, 'visible');

      reportTemplateDropdownController.groups = _(grouped).map((templates, visible) => {
        return {
          open: true,
          templates: _.keyBy(templates, 'name'),
          label: visible === 'true' ? 'Public' : 'Private',
          visible
        };
      }).sortBy('visible').value();

      reportTemplateDropdownController.filterTemplates();
    }

    reportTemplateDropdownController.select = function (template) {
      reportTemplateDropdownController.template = template;

      if (template) {
        Report.savePreferences(reportTemplateDropdownController.type, template);
      }

      reportTemplateDropdownController.onSelect({ template });
    };

    reportTemplateDropdownController.toggleGroup = function (group) {
      group.open = group.open !== true;
    };

    reportTemplateDropdownController.edit = function () {
      reportTemplateDropdownController.dropdownIsOpen = false;
      reportTemplateDropdownController.onSave({ createNew: false });
    };

    reportTemplateDropdownController.delete = function (template) {
      ReportTemplate.delete({
        id: template.id
      }).$promise.then(() => {
        Message.onRemoved();
        getTemplates().then(() => {
          setDefaultTemplate();
        });
      });
    };

    reportTemplateDropdownController.filterTemplates = function () {
      _.forEach(reportTemplateDropdownController.groups, (group) => {
        group.filteredTemplates = _(group.templates).filter((template) =>
          !reportTemplateDropdownController.templateFilter || _.includes(_.toLower(template.name), _.toLower(reportTemplateDropdownController.templateFilter))
        ).sortBy(['sequence', 'id']).value();

        group.open = !_.isEmpty(group.filteredTemplates);
      });
    };

    reportTemplateDropdownController.removeFilter = function () {
      delete reportTemplateDropdownController.templateFilter;
      reportTemplateDropdownController.filterTemplates();
      angular.element(`#${reportTemplateDropdownController.filterId}`).focus();
    };

    function setDefaultTemplate() {
      return getDefaultTemplate().then((template) =>
        reportTemplateDropdownController.onSelect({ template })
      );
    }

    function getDefaultTemplate() {
      const preference = getPreference();

      if (preference) {
        return ReportTemplate.get({
          type: reportTemplateDropdownController.type,
          name: preference
        }).$promise.then((template) => {
          if (isValid(template)) {
            return $q.resolve(template);
          } else {
            return getFirstValidTemplate();
          }
        });
      }

      return getFirstValidTemplate();
    }

    function getFirstValidTemplate() {
      Report.deletePreferences(reportTemplateDropdownController.type);

      const template = _(reportTemplateDropdownController.templates).filter(isValid).head();
      return $q.resolve(template);
    }

    function isValid(template) {
      if (angular.isUndefined(template)) {
        return false;
      }

      return !_.isEmpty(template.headers);
    }

    function getPreference() {
      if (reportTemplateDropdownController.defaultTemplate) {
        return reportTemplateDropdownController.defaultTemplate;
      }

      const preference = sessionStorage[`report-${reportTemplateDropdownController.type}`];
      return _.isString(preference) ? preference : '';
    }
  }
});
