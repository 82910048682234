'use strict';

angular.module('uasApp').component('uasChangesDetail', {
  bindings: {
    entity: '<',
    filterType: '@?',
    hideEmpty: '<?'
  },
  templateUrl: 'es6/changes/changes.detail.html',
  controllerAs: 'changesDetailController',
  controller: function ($scope, $q, Feedback, Language, EntityTypes) {
    const changesDetailController = this;

    changesDetailController.$onInit = function() {
      loadData();
    };

    function loadData() {
      changesDetailController.loading = true;

      $q.all([
        EntityTypes.load(),
        Feedback.get({
          entityType: changesDetailController.entity.type,
          entityId: changesDetailController.entity.id,
          filterType: changesDetailController.filterType,
          hideEmpty: changesDetailController.hideEmpty,
          language: Language.get()
        }).$promise
      ]).then(([entityTypes, { changes }]) => {
          $scope.entityTypes = entityTypes;
          _.forEach(changes, (change) => {
            if (change.type) {
              change.change.type = change.type;
            }
          });

          changesDetailController.grouped = _.groupBy(changes, (it) => it.change.entity.type);

          changesDetailController.types = _(_.keys(changesDetailController.grouped)).map((type) => {
            const content = changesDetailController.grouped[type];

            return {
              name: type,
              changes: content.length,
              creationTime: _(content).map((it) => it.change.creationTime).sort().reverse().first()
            };
          }).sortBy(['creationTime', 'type']).reverse().value();

          changesDetailController.select(_.first(changesDetailController.types));
        }).finally(() => {
          changesDetailController.loading = false;
      });
    }

    changesDetailController.select = function(type) {
      if (angular.isDefined(type)) {
        changesDetailController.changes = changesDetailController.grouped[type.name];
        changesDetailController.type = type.name;
      } else {
        changesDetailController.changes = [];
        changesDetailController.type = '';
      }
    };
  }
});
