'use strict';

angular.module('uasApp').config(function ($stateProvider) {
    $stateProvider.state('study-types', {
        url: '/study-types',
        template: '<study-type-list></study-type-list>',
        breadcrumb: {
            label: 'Static.Page.StudyTypes',
            translate: true,
            root: true
        },
        admin: true
    });
});
