'use strict';

angular.module('uasApp').component('deliveryReport', {
  bindings: {
    module: '<'
  },
  templateUrl: 'es6/inventory/delivery/delivery.report.html',
  controllerAs: 'deliveryReportController',
  controller: function (Delivery, entityRedirect, Language) {
    const deliveryReportController = this;

    deliveryReportController.$onInit = function () {
      loadData();
      Language.onChange(loadData);
    };

    function loadData() {
      deliveryReportController.loading = true;
      return Delivery.report({
        moduleId: deliveryReportController.module.id,
        language: Language.get()
      }).$promise.then((report) => {
        deliveryReportController.report = report;
      }).finally(() => deliveryReportController.loading = false);
    }

    deliveryReportController.onClick = function () {
      entityRedirect.go('module.assets', { id: deliveryReportController.module.id }, { target: '_blank' });
    };
  }
});
