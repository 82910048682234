'use strict';

angular.module('uasApp').component('studyLinksPage', {
  templateUrl: 'es6/link/study.links.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'studyLinksPageController',
  controller: function (Page) {
    const studyLinksPageController = this;

    studyLinksPageController.$onInit = function () {
      studyLinksPageController.readOnly = studyLinksPageController.isReadOnly || Page.getParameterAsBoolean(studyLinksPageController.page, 'readOnly', false);
      studyLinksPageController.categoryId = Page.getParameterAsInt(studyLinksPageController.page, 'categoryId');
    };
  }
});
