'use strict';

angular.module('uasApp').component('groupTypeSelect', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/group/type/group.type.select.html',
  controllerAs: 'groupTypeSelectController',
  controller: function (i18nFilter, GroupType) {
    const groupTypeSelectController = this;

    groupTypeSelectController.$onInit = function () {
        groupTypeSelectController.ngModelCtrl.$render = () => {
            groupTypeSelectController.value = groupTypeSelectController.ngModelCtrl.$modelValue;
        };

        GroupType.query().$promise.then((types) => {
            groupTypeSelectController.types = _(types).map((type) => {
              const name = i18nFilter(type.names) || type.code;
              return _.extend(type, { name });
            }).sortBy(['name', 'code', 'id']).value();
        });
    };

    groupTypeSelectController.onChange = function () {
        groupTypeSelectController.ngModelCtrl.$setViewValue(groupTypeSelectController.value);
    };
  }
});
