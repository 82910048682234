'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasGroupStudies
 * @description
 * uasStudies Shows studies in group overview
 */
angular.module('uasApp')
    .component('uasGroupStudies', {
        bindings: {
            studies: '<'
        },
        templateUrl: 'es6/group/group.studies.html',
        controllerAs: 'groupStudiesController'
    });
