'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:TimeBlock
 * @description
 * The TimeBlock service.
 */
angular.module('uasApp').factory('TimeBlock', function(UasResource) {
    return UasResource('/time-blocks');
});