'use strict';

import htmldiff from 'htmldiff-angular';

/**
 * @ngdoc service
 * @name uasApp.factory:DiffHtml
 * @description
 * Determine differences between texts.
 */
angular.module('uasApp').factory('DiffHtml', function ($sce) {

  const ATOMIC_TAGS = 'u,i,b,iframe,object,math,svg,script,video,head,style';
  const NEWLINE_TAG = '<NEWLINE/>';

  function buildDiff(before, after) {
    const difference = htmldiff(toNewlines(before), toNewlines(after), null, null, ATOMIC_TAGS);
    return $sce.trustAsHtml(toHtmlBreaks(difference));
  }

  /**
   * Replace paragraphs with html breaks.
   * Replace <br> with <br/>.
   * Remove last html break.
   * Replace all html breaks with NEWLINE_TAG.
   *
   * @param {*} html
   * @returns html with NEWLINE_TAG
   */
  function toNewlines(html) {
    if (!html) {
      return '';
    }
    return html
      .replace(/<p[^>]*><br><\/p>/g, '<br/>')
      .replace(/<p[^>]*>/g, '')
      .replace(/<\/p>/g, '<br/>')
      .replace(/<br>/g, '<br/>')
      .replace(/(.*)<br\/>$/, '$1')
      .replace(/<br\/>/g, NEWLINE_TAG);
  }

  /**
   * Replace NEWLINE_TAG with html breaks.
   *
   * @param {*} html
   * @returns html with <br/>
   */
  function toHtmlBreaks(html) {
    return html
      .replace(new RegExp(NEWLINE_TAG, 'g'), '<br/>')
      .replace(/<del([^>]*)>(<br\/>)+<\/del>/g, '<del$1>&nbsp;&nbsp;<br/><\/del>')
      .replace(/<ins([^>]*)>(<br\/>)+<\/ins>/g, '<ins$1>&nbsp;&nbsp;<br/><\/ins>');
  }

  return { buildDiff };
});
