'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:buttonOptionsBoolean
 * @description Shows boolean (true/false) button options, colored as TimeEdit green/red.
 * @see buttonOptions
 */
angular.module('uasApp')
  .component('buttonOptionsBoolean', {
    bindings: {
      classes: '@?',
      squared: '<?',
      trueLabelKey: '@?',
      falseLabelKey: '@?',
      isDisabled: '<?'
    },
    transclude: true,
    templateUrl: 'es6/app/forms/buttons/button.options.boolean.html',
    controllerAs: 'booleanController',
    require: {
      ngModelCtrl: 'ngModel',
      securedCtrl: '?^secured'
    },
    controller: function ($element) {
      const booleanController = this;

      booleanController.$onInit = function () {
        booleanController.ngModelCtrl.$render = function () {
          booleanController.value = booleanController.ngModelCtrl.$modelValue;
        };

        booleanController.trueLabelKey_ = _.defaultTo(booleanController.trueLabelKey, 'Boolean.TRUE');
        booleanController.falseLabelKey_ = _.defaultTo(booleanController.falseLabelKey, 'Boolean.FALSE');

        secure();
      };

      function secure() {
        if (booleanController.securedCtrl) {
          booleanController.securedCtrl.resecure($element);
        }
      }
  
      booleanController.onToggle = function () {
        booleanController.ngModelCtrl.$setViewValue(booleanController.value);
      };
    }
  });
