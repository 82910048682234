'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:referenceLabel
 * @description Shows the name of a reference.
 * This method is preferred over the reference filter for read-only values,
 * as that loads entire reference lists, which is not performant for large lists.
 */
angular.module('uasApp').component('referenceLabel', {
  bindings: {
    referenceId: '@'
  },
  templateUrl: 'es6/reference/reference.label.html',
  controllerAs: 'referenceLabelController',
  controller: function ($q, Reference) {
    const referenceLabelController = this;

    referenceLabelController.$onChanges = function () {
      loadName();
    };

    function loadName() {
      if (_.isEmpty(referenceLabelController.referenceId)) {
        return;
      }

      referenceLabelController.loading = true;
      Reference.get({ id: referenceLabelController.referenceId }).$promise.then((reference) => {
        referenceLabelController.reference = reference;
        referenceLabelController.name = Reference.getName(referenceLabelController.reference);
      }).finally(() => {
        referenceLabelController.loading = false;
      });
    }
  }
});
