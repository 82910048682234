'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Faculty
 * @description
 * The Faculty service.
 */
angular.module('uasApp')
    .factory('Faculty', function(UasResource) {
        return UasResource('/faculty', {
            deleteFull: {
                method: 'DELETE',
                url: '/faculty/:id/full',
                isArray: false
            },
            update: {
                method: 'PUT'
            }
        });
    });
