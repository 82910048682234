'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:tooltipLabel
 * @description
 * The tooltipLabel component.
 */
angular.module('uasApp')
  .component('tooltipLabel', {
    bindings: {
      message: '@',
      placement: '@?'
    },
    templateUrl: 'es6/app/forms/tooltip.label.html',
    controllerAs: 'tooltipController'
  });
