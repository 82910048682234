'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamList
 * @description teamList Manages Team entities.
 */
angular.module('uasApp').component('teamList', {
  templateUrl: 'es6/team/team.list.html',
  bindings: {
    owner: '<?',
    searchParams: '<?',
    isReadOnly: '<?',
    operations: '<',
    operationsToEdit: '@?'
  },
  controllerAs: 'teamListController',
  controller: function ($uibModal, Changes, EntityService, Message, Pageable, SecurityService, Team) {
    const teamListController = this;

    teamListController.$onInit = function () {
      teamListController.editable = !teamListController.isReadOnly
        && SecurityService.isAllowed(teamListController.operationsToEdit, teamListController.operations);
    };

    teamListController.$onChanges = function (changes) {
      if (!teamListController.pageable) {
        teamListController.pageable = Pageable.of({
          order: 'code'
        });
      }
      if (Changes.hasChanged(changes, 'searchParams')) {
        teamListController.search();
      }
    };

    teamListController.search = function () {
      const parameters = getParameters();

      teamListController.loading = true;
      return Team.page(parameters).$promise.then((teams) => {
        teamListController.teams = teams;
      }).finally(() => {
        teamListController.loading = false;
      });
    };

    function getParameters() {
      const parameters = teamListController.pageable.build({
        text: _.get(teamListController.searchParams, 'text'),
        active: _.get(teamListController.searchParams, 'active')
      });

      if (teamListController.owner) {
        _.extend(parameters, {
          'owner.id': _.get(teamListController.owner, 'id'),
          'owner.type': EntityService.getType(teamListController.owner)
        });
      }

      return parameters;
    }

    teamListController.create = function () {
      const team = {
        academicYearId: sessionStorage.academicYear,
        owner: EntityService.toReference(teamListController.owner)
      };
      openModal(team);
    };

    teamListController.edit = function (team, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      openModal(team);
    };

    function openModal(team) {
      $uibModal.open({
        template: `
          <team-edit
            team="modalController.team"
            hide-owner="modalController.owner !== undefined"
            operations="modalController.operations"
            on-cancel="modalController.close()"
            on-save="modalController.onSave()">
          </team-edit>
        `,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.team = angular.copy(team);
            modalController.owner = teamListController.owner;
            modalController.operations = teamListController.operations;
          };

          modalController.onSave = function () {
            modalController.close();
            teamListController.search();
          };

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    teamListController.remove = function (team) {
      Team.remove({
        id: team.id
      }).$promise.then(() => {
        Message.onRemoved();
        teamListController.search();
      });
    };
  }
});
