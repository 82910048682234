'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ModuleCost
 * @description
 * The ModuleCost service.
 */
angular.module('uasApp').factory('ModuleCost', function(UasResource) {
    return UasResource('/module-cost');
});
