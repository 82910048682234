'use strict';

angular.module('uasApp').component('taskTable', {
    bindings: {
        assignment: '<',
        editable: '<',
        tasks: '<',
        onBack: '&?',
        onChange: '&',
        onClose: '&',
        operations: '<',
        isReadOnly: '<',
        workflowMode: '<'
    },
    templateUrl: 'es6/staffing/task/task.table.html',
    controllerAs: 'taskTableController',
    controller: function(i18nFilter, feedbackObserver, Task, TaskModal, Relation, RelationModal, Effort, EffortModal, SecurityService, Pageable, Message, Changes) {
        const taskTableController = this;

        taskTableController.$onInit = function() {
            taskTableController.correctable = SecurityService.isAllowed('VIEW_TASKS_CORRECTION', taskTableController.operations);
        };

        taskTableController.$onChanges = function(changes) {
            if (Changes.hasChanged(changes, 'tasks')) {
                if (taskTableController.tasks) {
                    taskTableController.groups = buildGroups();
                } else {
                    delete taskTableController.groups;
                }
            }
        };

        function buildGroups() {
            const tasks = _.map(taskTableController.tasks, (task) => {
                const label = i18nFilter(task.template.names) || task.template.code || task.template.externalId;
                return _.extend(task, {
                    name: _.result(task.template, 'tag', ''),
                    label
                });
            });

            const groups = _.groupBy(tasks, 'name');
            const names = _.isEmpty(groups) ? [''] :_.keys(groups);

            return _(names).map((name) => {
                const filtered = groups[name];
                const currentGroup = _.find(taskTableController.groups, { name });
                const pageable = _.get(currentGroup, 'pageable', Pageable.of({ order: 'label' }));

                const totalHours = _.sumBy(filtered, 'correctedHours');

                const group = {
                    name,
                    tasks: filtered,
                    totalHours,
                    pageable
                };

                taskTableController.onSort(group);
                return group;
            }).sortBy('name').value();
        }

        taskTableController.onSort = function(group) {
            group.tasks = group.pageable.sort(group.tasks, ['period.sequence', 'period.startDate', 'period.endDate', 'id']);
        };

        taskTableController.show = function(task) {
            Effort.findByTask(task.id).then((effort) => {
                if (angular.isDefined(effort)) {
                    taskTableController.onClose();

                    EffortModal.open({
                        effort,
                        isReadOnly: taskTableController.isReadOnly,
                        workflowMode: taskTableController.workflowMode,
                        onBack: taskTableController.onBack,
                        onChange: taskTableController.onChange
                    });
                }
            });
        };

        taskTableController.create = function() {
            open({
                assignmentId: taskTableController.assignment.id,
                academicYearId: sessionStorage.academicYear,
                target: _.result(taskTableController.assignment, 'person.self', taskTableController.assignment.self) 
            });
        };

        taskTableController.edit = function(task) {
            if (taskTableController.editable) {
                const form = build(task);
                open(form);
            }
        };

        function build(task) {
            const form = angular.copy(task);
            form.templateId = _.result(task, 'template.id');
            return form;
        }

        function open(task) {
            TaskModal.open({ 
                task, 
                correctable: taskTableController.correctable,
                workflowMode: taskTableController.workflowMode,
                onChange: taskTableController.onChange
            });
        }

        taskTableController.save = function(task) {
            const form = build(task);
            Task.store(form).$promise.then((result) => {
                task.hours = result.hours;
                task.correctedHours = result.correctedHours;

                taskTableController.onChange();
                Message.onSaved();
            });
        };

        taskTableController.remove = function(task) {
            Task.delete(task).$promise.then(() => {
                Message.onRemoved();
                return taskTableController.onChange();
            });
        };

        taskTableController.editRelation = function(task) {
            Relation.get({
                id: task.relationId
            }).$promise.then((relation) => {
                RelationModal.open({
                    relation,
                    role: task.template.role,
                    entity: task.entity,
                    operations: taskTableController.operations,
                    workflowMode: taskTableController.workflowMode,
                    onChange: taskTableController.onChange
                });
            });
        };

        taskTableController.removeRelation = function(relationId, comment) {
            Relation.save({
                comment: {
                    message: comment
                },
                removes: [relationId]
            }).$promise.then(() => {
                Message.onRemoved();
                feedbackObserver.dataChanged();
                taskTableController.onChange();
            });
        };
    }
});
