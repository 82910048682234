'use strict';

angular.module('uasApp').factory('ModuleType', function (UasResource) {
  return UasResource('/module-types', {
    search: {
      method: 'GET',
      url: '/module-types/search'
    }
  });
});
