'use strict';

/**
 * @ngdoc function
 * @name uasApp.resource:Mine
 * @description
 * # Mine
 * Mine resource of the uasApp
 */
angular.module('uasApp')
  .factory('Mine', function (UasResource) {
    return UasResource('/mine');
  });
