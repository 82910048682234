'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasDashboard
 * @description
 * # uasDashboard
 * Displays dashboard with widgets.
 */
angular.module('uasApp').component('uasDashboard', {
  templateUrl: 'es6/dashboard/dashboard.html',
  controllerAs: 'dashboardController',
  controller: function ($q, $state, AuthService, CreateManager, Faculty, Session, Parameter) {
    const dashboardController = this;

    dashboardController.$onInit = function () {
      dashboardController.user = Session;
      dashboardController.academicYearId = sessionStorage.academicYear;

      if (angular.isDefined(dashboardController.academicYearId) && AuthService.isAuthenticated) {
        Parameter.load().then(() => {
          dashboardController.showAddModule = Parameter.getParameterAsBoolean('dashboard.add.module');
        });
      }
    };

    dashboardController.addModule = function() {
      $q.all([
        AuthService.operations('academic-year', sessionStorage.academicYear),
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([operations, faculties]) => {
        let module = {
          academicYearId: sessionStorage.academicYear
        };

        if (faculties.length === 1) {
          module.facultyId = _.head(faculties).id;
        }
  
        CreateManager.open('module', module, operations, (result) => {
          $state.go('module.general', { id: result.id });
        });
      });
    };
  }
});
