'use strict';

angular.module('uasApp').factory('Note', function ($resource, BackendService) {
  return $resource(BackendService.getBaseUrl() + '/note/:id', {}, {
    'get': {
      method: 'GET',
      isArray: true
    },
    'update': {
      method: 'PUT'
    }
  });
});
