'use strict';

angular.module('uasApp').component('buttonConfirm', {
    bindings: {
        onClick: '&',
        color: '@',
        icon: '@',
        size: '@?',
        label: '@',
        message: '@',
        form: '<?'
    },
    transclude: true,
    templateUrl: 'es6/app/buttons/button.confirm.html',
    controllerAs: 'buttonConfirmController',
    controller: function ($uibModal) {
        const buttonConfirmController = this;

        buttonConfirmController.open = function() {
            $uibModal.open({
                templateUrl: 'es6/app/buttons/button.confirm.modal.html',
                controllerAs: 'buttonConfirmPopupController',
                controller: function($uibModalInstance) {
                    const buttonConfirmPopupController = this;

                    buttonConfirmPopupController.$onInit = function() {
                        buttonConfirmPopupController.color = buttonConfirmController.color;
                        buttonConfirmPopupController.icon = buttonConfirmController.icon;
                        buttonConfirmPopupController.label = buttonConfirmController.label;
                        buttonConfirmPopupController.message = buttonConfirmController.message;
                    };

                    buttonConfirmPopupController.confirm = function() {
                        buttonConfirmController.onClick();
                        $uibModalInstance.dismiss();
                    };

                    buttonConfirmPopupController.cancel = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };
    }
});
