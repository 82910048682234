'use strict';

angular.module('uasApp').component('entityPanel', {
  bindings: {
    view: '@',
    entity: '<',
    tabs: '<',
    showFeedback: '<?',
    showVersions: '<?',
    operations: '<'
  },
  templateUrl: 'es6/app/tabs/entity.panel.html',
  controllerAs: 'entityPanelController',
  controller: function(Tabs) {
    const entityPanelController = this;

    entityPanelController.$onInit = function () {
      entityPanelController.entityType = _.result(entityPanelController.entity, 'self.type');
      entityPanelController.entityId = _.result(entityPanelController.entity, 'id');

      Tabs.build(
        entityPanelController.tabs,
        entityPanelController.operations,
        entityPanelController.entityType,
        entityPanelController.entityType,
        entityPanelController.entityId
      ).then((tabs) => {
        entityPanelController.visibleTabs = tabs;
      });
    };

    entityPanelController.onTab = function (tab) {
      entityPanelController.activeTab = tab;
    };
  }
});