'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:modulesReportPageController
 * @description
 * Shows the modules in a configurable page.
 */
angular.module('uasApp')
  .component('modulesReportPage', {
    bindings: {
      entity: '<',
      operations: '<?',
      process: '<',
      page: '<'
    },
    templateUrl: 'es6/module/report/modules.report.page.html',
    controllerAs: 'modulesReportPageController',
    controller: function (Module, WorkflowModal, Page, entityRedirect) {
      const modulesReportPageController = this;

      modulesReportPageController.$onInit = function () {
        modulesReportPageController.readOnly = Page.getParameterAsBoolean(modulesReportPageController.page, 'readOnly', false);

        const modal = Page.getParameterAsBoolean(modulesReportPageController.page, 'modal', false);
        modulesReportPageController.click = modal ? open : redirect;
        modulesReportPageController.refresh = { loadData: _.noop };

        modulesReportPageController.reportTemplate = Page.getParameter(modulesReportPageController.page, 'reportTemplate');
        modulesReportPageController.hideReportTemplates = Page.getParameterAsBoolean(modulesReportPageController.page, 'hideReportTemplates', false);

        modulesReportPageController.mode = Page.getParameter(modulesReportPageController.page, 'mode');
        modulesReportPageController.recursive = !Page.getParameterAsBoolean(modulesReportPageController.page, 'hideNested', false);

        modulesReportPageController.showAll = Page.getParameterAsBoolean(modulesReportPageController.page, 'showAll', false);
        if (modulesReportPageController.showAll) {
          delete modulesReportPageController.entity;
        }
      };

      function open(row) {
        Module.get({
          id: row.entity.id
        }).$promise.then((module) => {
          WorkflowModal.open({
            entity: module,
            onSave: modulesReportPageController.refresh.loadData,
            onCancel: modulesReportPageController.refresh.loadData
          });
        });
      }

      function redirect(row) {
        entityRedirect.go('module.general', { id: row.entity.id }, { target: '_blank' });
      }
    }
  });
