'use strict';

angular.module('uasApp').component('entityText', {
  templateUrl: 'es6/app/entity/entity.text.html',
  bindings: {
    entity: '<',
    showRoot: '<?',
    icon: '<?',
    code: '<?'
  },
  controllerAs: 'entityTextController',
  controller: function ($scope, Changes, EntityService, EntityTextHolder, Language) {
    const entityTextController = this;

    entityTextController.$onInit = function () {
      Language.onChange(updateEntityText);
    };

    entityTextController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'entity')) {
        updateEntityReference();
        updateEntityText();
      }
    };

    function updateEntityReference() {
      entityTextController.entityReference = EntityService.toReference(entityTextController.entity);
    }

    function updateEntityText() {
      delete entityTextController.entityText;

      if (entityTextController.entity) {
        EntityTextHolder.get(entityTextController.entity, (entityText) => {
          entityTextController.entityText = entityText;
        }, true, entityTextController.language);
      }
    }

    $scope.$on('entityTextUpdated', function(event, entityReference) {
      if (EntityService.hasSameReference(entityReference, entityTextController.entityReference)) {
        updateEntityText();
      }
    });
  }
});
