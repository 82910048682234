'use strict';

angular.module('uasApp').component('noteUpdatePanel', {
  bindings: {
    note: '<',
    onSave: '&',
    onDelete: '&',
    onCancel: '&'
  },
  templateUrl: 'es6/note/note.update.panel.html',
  controllerAs: 'noteUpdatePanelController',
  controller: function (Note, Message) {
    const noteUpdatePanelController = this;

    noteUpdatePanelController.$onInit = function () {
      noteUpdatePanelController.model = angular.copy(noteUpdatePanelController.note);
    };

    noteUpdatePanelController.update = function () {
      Note.update({
        id: noteUpdatePanelController.model.id
      }, noteUpdatePanelController.model).$promise.then(() => {
        Message.onSaved();
        noteUpdatePanelController.onSave();
      });
    };

    noteUpdatePanelController.delete = function () {
      Note.delete({
        id: noteUpdatePanelController.model.id
      }).$promise.then(() => {
        Message.onRemoved();
        noteUpdatePanelController.onDelete();
      });
    };
  }
});
