'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamAdmin
 * @description teamAdmin Admin the team details.
 */
angular.module('uasApp').component('teamAdmin', {
  templateUrl: 'es6/team/team.admin.html',
  bindings: {
    team: '<',
    operations: '<'
  },
  controllerAs: 'teamAdminController',
  controller: function ($state, Message, Team) {
    const teamAdminController = this;

    teamAdminController.save = function () {
      const body = angular.copy(teamAdminController.team);

      return Team.save(body).$promise.then((result) => {
        teamAdminController.form.$setPristine();

        $state.reload();
        Message.onSaved();

        return result;
      });
    };
  }
});
