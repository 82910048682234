'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Delivery
 * @description
 * The Delivery service.
 */
angular.module('uasApp').factory('Delivery', function (UasResource) {
  return UasResource('/deliveries', {
    search: {
      url: '/deliveries/search',
      method: 'GET',
      isArray: false
    },
    report: {
      url: '/deliveries/report',
      method: 'GET'
    }
  });
});
