'use strict';

angular.module('uasApp').config(function ($stateProvider) {
  $stateProvider.state('link-types', {
    url: '/link-types',
    template: '<link-type-list></link-type-list>',
    breadcrumb: {
      label: 'Static.Page.LinkTypes',
      translate: true,
      root: true
    },
    admin: true
  });
});
