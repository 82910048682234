'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingEdit
 * @description
 */
angular.module('uasApp').component('meetingEdit', {
  bindings: {
    meeting: '<',
    activity: '<',
    persons: '<',
    vacancies: '<',
    weeks: '<',
    onSubmit: '&',
    onCancel: '&'
  },
  templateUrl: 'es6/schedule/meeting/meeting.edit.html',
  controllerAs: 'meetingEditController',
  controller: function (Element, Parameter) {
    const meetingEditController = this;

    meetingEditController.$onInit = function() {
        Parameter.load().then(() => {
            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            meetingEditController.weeks = _.map(meetingEditController.weeks, (week) => {
                return {
                    number: week.week,
                    label: calendarWeeks ? week.yearWeek : week.week,
                    selected: _.inRange(week.week, meetingEditController.meeting.week.week, meetingEditController.meeting.lastWeek.week + 1)
                };
            });
        });

        const groupNumbers = _.range(1, meetingEditController.activity.numberOfGroups + 1);
        meetingEditController.groups = _.map(groupNumbers, (groupNumber) => {
            return {
                number: groupNumber,
                selected: groupNumber === meetingEditController.meeting.group
            };
        });

        const periodId = _.get(meetingEditController.activity, 'period.id');
        Element.getByType('AssignmentType', periodId).then((assignmentTypes) => {
            meetingEditController.assignmentTypes = assignmentTypes;
        });
    };

    meetingEditController.onChange = function(meeting) {
        meetingEditController.meeting = _.extend(meetingEditController.meeting, meeting);
    };

    meetingEditController.onSelect = function(entry) {
        entry.selected = entry.selected !== true;
    };

    meetingEditController.onSelectAll = function(entries) {
        const selected = _.every(entries, { selected: true });
        _.forEach(entries, (entry) => {
            entry.selected = selected !== true;
        });
    };

    meetingEditController.cancel = function() {
        meetingEditController.onCancel();
    };

    meetingEditController.submit = function() {
        const data = {
            body: buildBody(meetingEditController.meeting),
            groups: _(meetingEditController.groups).filter({ selected: true }).value(),
            weeks: _(meetingEditController.weeks).filter({ selected: true }).value()
        };

        meetingEditController.onSubmit(data);
    };

    function buildBody(meeting) {
        return _.extend(meeting, {
            personIds: _.map(meeting.persons, 'id'),
            vacancyIds: _.map(meeting.vacancies, 'id')
        });
    }
  }
});
