'use strict';

angular.module('uasApp').component('personTypeahead', {
  templateUrl: 'es6/person/person.typeahead.html',
  bindings: {
    inputId: '@',
    isRequired: '<?',
    isReadOnly: '<?',
    inModal: '<?',
    classes: '<?',
    viewClasses: '<?',
    showIgnored: '<?',
    roleId: '<?',
    onSearch: '&'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'personTypeaheadController',
  controller: function (Person) {
    const personTypeaheadController = this;

    personTypeaheadController.$onInit = function () {
      personTypeaheadController.order = 'fullName';
      personTypeaheadController.pageSize = 250;

      personTypeaheadController.ngModelCtrl.$render = function () {
        personTypeaheadController.personId = personTypeaheadController.ngModelCtrl.$modelValue;
      };
    };

    personTypeaheadController.search = function (params) {
      params.active = true;
      params.ignore = personTypeaheadController.showIgnored;
      params.roleId = personTypeaheadController.roleId;
      params.size = personTypeaheadController.pageSize;

      return Person.page(params).$promise.then((result) => {
        if (_.isFunction(personTypeaheadController.onSearch)) {
          personTypeaheadController.onSearch({ text: params.text, result });
        }
        return result;
      });
    };

    personTypeaheadController.formatValue = function (person) {
      if (person) {
        return `${person.fullName} (${person.externalId})`;
      }
    };

    personTypeaheadController.find = function (id) {
      return Person.find(id).$promise;
    };

    personTypeaheadController.onChange = function () {
      personTypeaheadController.ngModelCtrl.$setViewValue(personTypeaheadController.personId);
    };
  }
});
