'use strict';

angular.module('uasApp').component('planboardRemoveModuleWarning', {
  templateUrl: 'es6/planboard/details/planboard.remove.module.warning.html',
  bindings: {
    usedGroups: '<',
    module: '<',
    warningLabel: '@',
    usedElsewhereLabel: '@'
  },
  controllerAs: 'removeModuleWarningController'
});
