'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasForbidden
 * @description
 * # uasForbidden
 * uasForbidden component of the uasApp
 */
angular.module('uasApp').component('uasForbidden', {
  templateUrl: 'es6/security/forbidden.html',
  controllerAs: 'forbiddenCtrl',
  controller: function(AuthService) {
    this.logout = AuthService.logout;
  }
});
