'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessReport
 * @description
 * uasProcessProgress Shows the process statuses in a table.
 */
angular
    .module('uasApp')
    .component('uasProcessReport', {
        templateUrl: 'es6/process/progress/process.report.html',
        bindings: {
            process: '<'
        },
        controllerAs: 'progressController',
        controller: function ($q, Faculty, Organisation, Study, Calendar, CalendarPeriod,
            RoleType, ProcessProgress, AuthService, Process, Session) {

            const progressController = this;

            progressController.$onInit = function() {
                const processType = progressController.process.type;

                progressController.offered = 'true';
                progressController.expression = processType.condition;

                progressController.loading = true;
                $q.all([
                    Faculty.query({
                        academicYearId: sessionStorage.academicYear
                    }).$promise,
                    Calendar.query({
                        academicYearId: sessionStorage.academicYear
                    }).$promise,
                    Process.query({
                        academicYearId: sessionStorage.academicYear,
                        typeId: progressController.process.type.id
                    }).$promise
                ]).then(([faculties, calendars, processes]) => {
                    progressController.faculties = faculties;
                    progressController.calendars = calendars;
                    progressController.periods = [];
                    progressController.progress = [];
                    progressController.processes = processes;

                    if (faculties.length === 1) {
                        progressController.faculty = _.head(faculties).id;
                    }

                    progressController.onFaculty();
                }).finally(() => {
                    progressController.loading = false;
                });
            };

            progressController.onFaculty = function () {
                if (_.isNumber(progressController.faculty)) {
                    progressController.organisations = Organisation.query({ facultyId: progressController.faculty });
                    progressController.studies = Study.query({ facultyId: progressController.faculty });
                } else {
                    progressController.organisations = [];
                    progressController.studies = [];
                }
                getOperations().then((operations) => progressController.operations = operations);
            };

            function getOperations() {
                if (progressController.process.type.rootType === 'PERSON') {
                    return AuthService.operations('person', Session.personId);
                } else if (_.isNumber(progressController.faculty)) {
                    return AuthService.operations('faculty', progressController.faculty);
                } else {
                    return AuthService.operations('academic-year', sessionStorage.academicYear);
                }
            }

            progressController.onCalendar = function () {
                delete progressController.period;

                if (angular.isDefined(progressController.calendarId)) {
                    CalendarPeriod.query({
                        calendarId: progressController.calendarId
                    }).$promise.then((periods) => {
                        progressController.periods = periods;
                    });
                } else {
                    progressController.periods = [];
                }
            };

            progressController.onRole = function () {
                delete progressController.person;
            };

            progressController.search = function () {
                progressController.loading = true;
                ProcessProgress.get({
                    process: progressController.process.id,
                    facultyId: progressController.faculty,
                    organisationId: progressController.organisation,
                    studyId: progressController.studyId,
                    appliedStudyId: progressController.appliedStudyId,
                    offered: progressController.offered,
                    calendarId: progressController.calendarId,
                    period: progressController.period,
                    property: progressController.property,
                    startDate: progressController.startDate,
                    endDate: progressController.endDate,
                    code: progressController.code,
                    roleId: progressController.role,
                    personId: progressController.personId,
                    expression: progressController.expression
                }).$promise.then((result) => {
                    progressController.result = result;
                }).finally(() => {
                    progressController.loading = false;
                });
            };
        }
    });
