'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:inputGroupPrefix
 * @description Shows the transcluded content before the input.
 */
angular.module('uasApp').component('inputGroupPrefix', {
  transclude: true,
  controllerAs: 'inputGroupPrefixController',
  template: `
    <div ng-transclude class="input-group-prefix d-f"></div>
  `
});
