'use strict';

angular.module('uasApp').component('notificationsPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/app/notifications/notifications.page.html',
  controllerAs: 'notificationsPageController',
  controller: function () {
    const notificationsPageController = this;

    notificationsPageController.$onInit = function () {
      notificationsPageController.isSupported = _.includes(['faculty', 'study', 'module-group', 'module'], notificationsPageController.entity.self.type);
    };
  }
});