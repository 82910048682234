'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:copyToClipboardButton
 * @description
 * The copyToClipboardButton component.
 * Shows a button to copy the text from an element matching the target CSS selector to the clipboard.
 */
angular.module('uasApp').component('copyToClipboardButton', {
  bindings: {
    targetSelector: '@'
  },
  transclude: true,
  templateUrl: 'es6/app/buttons/copy.to.clipboard.button.html',
  controllerAs: 'copyToClipboardButtonController',
  controller: function (Message) {
    const copyToClipboardButtonController = this;

    copyToClipboardButtonController.onSuccess = function(e) {
      e.clearSelection();

      Message.addSuccessLabel('Static.Component.Clipboard.Success');
    };

    copyToClipboardButtonController.onError = function() {
      Message.addErrorLabel('Static.Component.Clipboard.Error');
    };
  }
});
