'use strict';

angular.module('uasApp').component('rootTypeahead', {
  bindings: {
    inputId: '@',
    prefixProperty: '@?',
    entityType: '<',
    filter: '<?',
    isRequired: '<',
    isReadOnly: '<?',
    viewClasses: '<?',
    classes: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/entity/root.typeahead.html',
  controllerAs: 'rootTypeaheadController',
  controller: function (Root, Language, entityTranslateFilter) {
    const rootTypeaheadController = this;

    rootTypeaheadController.$onInit = function () {
      rootTypeaheadController.order = rootTypeaheadController.prefixProperty || Language.getPropertyName();

      rootTypeaheadController.ngModelCtrl.$render = function () {
        rootTypeaheadController.rootId = rootTypeaheadController.ngModelCtrl.$modelValue;
      };
    };

    rootTypeaheadController.search = function (args) {
      const parameters = _.extend(args, {
        filter: rootTypeaheadController.filter,
        type: rootTypeaheadController.entityType
      });

      return Root.page(parameters).$promise;
    };

    rootTypeaheadController.find = function (id) {
      return Root.find(id).$promise;
    };

    rootTypeaheadController.formatValue = function(root) {
      const name = entityTranslateFilter(root);
      const prefix = _.get(root, rootTypeaheadController.prefixProperty, '');

      if (!_.isEmpty(prefix) && !_.isEmpty(name) && prefix !== name) {
        return `${prefix} - ${name}`;
      }

      return prefix || name;
    };

    rootTypeaheadController.onChange = function() {
      rootTypeaheadController.ngModelCtrl.$setViewValue(rootTypeaheadController.rootId);
    };
  }
});
