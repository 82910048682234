'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingPersonSelect
 * @description Select the teachers for a meeting.
 */
angular.module('uasApp').component('meetingPersonSelect', {
  bindings: {
    meeting: '<',
    persons: '<',
    assignmentTypes: '<?',
    vacancies: '<',
    onChange: '&'
  },
  templateUrl: 'es6/schedule/meeting/meeting.person.select.html',
  controllerAs: 'meetingPersonSelectController',
  controller: function (Assignment, Parameter, Person) {
    const meetingPersonSelectController = this;

    meetingPersonSelectController.$onInit = function () {
      meetingPersonSelectController.personMode = true;

      Parameter.load().then(() => {
        meetingPersonSelectController.showAll = Parameter.getParameterAsBoolean('schedule.teacher.show_all', false);
      });

      setSelected();
    };

    function setSelected() {
      _.forEach(meetingPersonSelectController.persons, (person) => {
        person.selected = _.some(meetingPersonSelectController.meeting.persons, { id: person.id });
      });
      _.forEach(meetingPersonSelectController.vacancies, (vacancy) => {
        vacancy.selected = _.some(meetingPersonSelectController.meeting.vacancies, { id: vacancy.id });
      });
    }

    meetingPersonSelectController.onAssignmentType = function (assignmentTypeId) {
      meetingPersonSelectController.meeting.assignmentTypeId = assignmentTypeId;

      deselect(meetingPersonSelectController.persons);
      deselect(meetingPersonSelectController.vacancies);

      update();
    };

    function deselect(objects) {
      _.forEach(objects, (object) => {
        object.selected = false;
      });
    }

    meetingPersonSelectController.addPerson = function (personId) {
      if (angular.isUndefined(personId)) {
        return;
      }

      const found = _.find(meetingPersonSelectController.persons, { id: personId });
      if (angular.isUndefined(found)) {
        Person.find(personId).$promise.then((person) => {
          meetingPersonSelectController.persons.push(person);
          meetingPersonSelectController.onPerson(person);
        });
      } else {
        meetingPersonSelectController.onPerson(found);
      }
    };

    meetingPersonSelectController.onPerson = function (person) {
      person.selected = person.selected !== true;
      if (person.selected) {
        delete meetingPersonSelectController.meeting.assignmentTypeId;
      }

      update();
    };

    meetingPersonSelectController.addVacancy = function (vacancyId) {
      if (angular.isUndefined(vacancyId)) {
        return;
      }

      const found = _.find(meetingPersonSelectController.vacancies, { id: vacancyId });
      if (angular.isUndefined(found)) {
        Assignment.find(vacancyId).$promise.then((vacancy) => {
          vacancy.label = Assignment.getLabel(vacancy);
          meetingPersonSelectController.vacancies.push(vacancy);
          meetingPersonSelectController.onVacancy(vacancy);
        });
      } else {
        meetingPersonSelectController.onVacancy(found);
      }
    };

    meetingPersonSelectController.onVacancy = function (vacancy) {
      vacancy.selected = vacancy.selected !== true;
      if (vacancy.selected) {
        delete meetingPersonSelectController.meeting.assignmentTypeId;
      }

      update();
    };

    function update() {
      const persons = _.filter(meetingPersonSelectController.persons, { selected: true });
      const vacancies = _.filter(meetingPersonSelectController.vacancies, { selected: true });
      const meeting = _.extend(meetingPersonSelectController.meeting, { persons, vacancies });
      meetingPersonSelectController.onChange({ meeting });
    }
  }
});