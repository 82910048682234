'use strict';

angular.module('uasApp').component('pageRoute', {
  templateUrl: 'es6/page/page.route.html',
  bindings: {
    page: '<'
  },
  controllerAs: 'pageRouteController',
  controller: function () {
    const pageRouteController = this;

    pageRouteController.$onChanges = function () {
      if (pageRouteController.page) {
        _.forEach(pageRouteController.page.parameters, (parameter) => {
          parameter.label = getLabel(parameter.name);
        });
      }
    };

    function getLabel(name) {
      const words = name.match(/[A-Za-z][a-z]*/g) || [];
      return _.capitalize(words.join(' '));
    }
  }
});
