'use strict';

angular.module('uasApp').component('linkBuilderPage', {
  templateUrl: 'es6/link/link.builder.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    workflow: '<?',
    isReadOnly: '<?'
  },
  controllerAs: 'linkBuilderPageController',
  controller: function (Page) {
    const linkBuilderPageController = this;

    linkBuilderPageController.$onInit = function () {
      linkBuilderPageController.readOnly = linkBuilderPageController.isReadOnly || Page.getParameterAsBoolean(linkBuilderPageController.page, 'readOnly', false);
      linkBuilderPageController.categoryId = Page.getParameterAsInt(linkBuilderPageController.page, 'categoryId');
      linkBuilderPageController.workflowMode = angular.isDefined(linkBuilderPageController.workflow);
    };
  }
});
