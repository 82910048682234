'use strict';

angular.module('uasApp').component('typeEdit', {
  templateUrl: 'es6/type/type.edit.html',
  bindings: {
    type: '<',
    categories: '<?',
    onSave: '&',
    onCancel: '&',
    headerLabel: '@',
    validator: '@'
  },
  transclude: true,
  controllerAs: 'typeEditController'
});
