'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:appraisalReportPage
 * @description
 * The appraisalReportPage component.
 */
angular.module('uasApp').component('appraisalReportPage', {
    templateUrl: 'es6/assessment/appraisal/report/appraisal.report.page.html',
    bindings: {
        entity: '<',
        period: '<',
        operations: '<',
        page: '<'
    },
    controllerAs: 'appraisalReportPageController',
    controller: function (Language, Page, EntityService, Appraisal) {
        const appraisalReportPageController = this;

        appraisalReportPageController.$onInit = function() {
            appraisalReportPageController.context = `${appraisalReportPageController.entity.self.type}.appraisals`;
            appraisalReportPageController.typeIds = Page.getParameterAsArray(appraisalReportPageController.page, 'type', '', _.parseInt);
            appraisalReportPageController.onLanguage = Language.onChange(loadData);
            loadData();
        };

        appraisalReportPageController.$onDestroy = function() {
            appraisalReportPageController.onLanguage();
        };

        function loadData() {
            appraisalReportPageController.loading = true;
            Appraisal.report({
                definition: true
            }).$promise.then((report) => {
                appraisalReportPageController.columns = report.headers;
                appraisalReportPageController.groups = report.groups;
            }).finally(() => {
                appraisalReportPageController.loading = false;
            });
        }

        appraisalReportPageController.getRows = function(pageable) {
            const parameters = getParameters(pageable);
            return Appraisal.report(parameters).$promise;
        };

        function getParameters(pageable) {
            let parameters = {
                academicYearId: sessionStorage.academicYear,
                typeIds: appraisalReportPageController.typeIds
            };

            const entityPath = EntityService.getEntityPath(appraisalReportPageController.entity);
            parameters[entityPath] = appraisalReportPageController.entity.id;

            return _.extend(pageable, parameters);
        }
    }
});
