
'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:JobsModal
 * @description
 * The JobsModal service.
 */
angular.module('uasApp').factory('JobModal', function (JobExecution, configDateTimeFilter, configDateFilter, $uibModal) {

  function open(jobExecution) {
    $uibModal.open({
      size: 'lg',
      templateUrl: 'es6/admin/jobs/job.modal.html',
      controllerAs: 'jobDetailsController',
      controller: function ($uibModalInstance, AcademicYear) {
        const jobDetailsController = this;
        jobDetailsController.jobExecution = angular.copy(jobExecution);
        jobDetailsController.close = $uibModalInstance.close;

        jobDetailsController.onTab = function (tab) {
          _.each(jobDetailsController.tabs, (it) => it.active = false);
          tab.active = true;
          jobDetailsController.tab = tab;
        };

        jobDetailsController.refresh = function () {
          JobExecution.get({
            id: jobExecution.id
          }).$promise.then((data) => {
            jobDetailsController.jobExecution = angular.copy(data);
          });
        };

        loadData();

        function loadData() {
          jobDetailsController.tabs = [
            { name: 'details', label: 'Static.Page.Jobs.Details' },
            { name: 'parameters', label: 'Static.Page.Jobs.Parameter' },
            { name: 'messages', label: 'Static.Page.Jobs.Message' }
          ];

          jobDetailsController.onTab(jobDetailsController.tabs[0]);
          loadParameters();
        }

        function loadParameters() {
          JobExecution.parameters({
            jobExecutionId: jobExecution.id
          }).$promise.then((parameters) => {
            jobDetailsController.parameters = _.map(parameters, translate);
          });
        }

        function translate(parameter) {
          const name = parameter.id.key;
          let value = getValue(parameter);

          if (name === 'academicYearId') {
            let result = { name: 'academicYear' };
            AcademicYear.get({ id: value }).$promise.then((year) => {
              result.value = year.externalId + ' (' + year.code + ')';
            });
            return result;
          }

          if (name === 'timestamp') {
            value = configDateTimeFilter(value);
          }

          return { name, value };
        }

        function getValue(parameter) {
          switch (parameter.type) {
            case 'DATE':
              return configDateFilter(parameter.value);
            case 'LONG':
              return parseInt(parameter.value);
            case 'DOUBLE':
              return parseFloat(parameter.value);
            default:
              return parameter.value;
          }
        }
      }
    });
  }

  return { open };

});