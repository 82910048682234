'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasRelationEdit
 * @description Edit an existing relation or create a new relation
 */
angular.module('uasApp')
    .component('uasRelationEdit', {
        templateUrl: 'es6/staffing/relation/relation.edit.html',
        bindings: {
            relation: '<',
            role: '<',
            fields: '<',
            onSave: '&',
            onCancel: '&',
            operations: '<',
            operationsToEdit: '<?',
            inModal: '<?'
        },
        controllerAs: 'relationEditController',
        controller: function($q, Person, Parameter, AuthService, SecurityService, CustomField) {
            const relationEditController = this;

            const EXTENSIONS = [
                { name: 'id', visible: false },
                { name: 'uid', visible: false },
                { name: 'version', visible: false },
                { name: 'created', visible: false },
                { name: 'active', visible: false },
                { name: 'changeType', visible: false },
                { name: 'target', visible: false },
                { name: 'entity', visible: false },
                { name: 'roleId', visible: false },
                { name: 'vacancyId', visible: false },
                { name: 'remark', visible: false },
                { name: 'external', visible: false },
                { name: 'externalId', operation: 'EDIT_EXTERNAL_ID', hideNotAllowed: true },
                { name: 'startDate', maxValue: 'endDate', hideNotAllowed: true },
                { name: 'endDate', minValue: 'startDate', hideNotAllowed: true }
            ];

            relationEditController.$onInit = function() {
                Parameter.load().then(() => {
                    relationEditController.vacancy = Parameter.getParameterAsBoolean('relations.vacancy');
                });

                relationEditController.operationsToEdit_ = AuthService.buildOperationsToEdit(relationEditController.operationsToEdit, ['EDIT_RELATIONS']);
                relationEditController.showCreateNewPerson = false;

                relationEditController.fields_ = _.keyBy(relationEditController.fields, 'name');
                relationEditController.extendedFields = CustomField.extend(_.sortBy(relationEditController.fields, 'sequence'), EXTENSIONS);

                relationEditController.selected = relationEditController.relation.target;
                relationEditController.person = {
                    role: 'USER'
                };

                relationEditController.mode = isAssignment(relationEditController.relation) ? 'vacancy' : 'person';
                if (relationEditController.mode === 'vacancy') {
                    relationEditController.organisationId = _.get(relationEditController.relation, 'organisation.id');
                }

                CustomField.query({
                    rootType: 'person',
                    entityType: 'person'
                }).$promise.then((fields) => {
                    relationEditController.personFields = _.keyBy(fields, 'name');
                });

                setEvaluation();
            };

            function isAssignment(relation) {
                return _.get(relation, 'target.type') === 'assignment';
            }

            relationEditController.$onChanges = function () {
                relationEditController.createNewPersonAllowed = SecurityService.isAllowed(['CREATE_PERSON'], relationEditController.operations);
            };

            /**
             * The target is editable if relation is new or has no target assigned (vacancy)
             * @return {boolean} Whether or not the target of the relation (selected person, team or vacancy) is editable.
             */
            relationEditController.isTargetEditable = function() {
                const relation = relationEditController.relation;
                if (angular.isUndefined(relation)) {
                    return true;
                }

                const targetType = _.result(relation.target, 'type');
                return !targetType || targetType === 'assignment';
            };

            relationEditController.onSearchResult = function(text, result) {
                relationEditController.showCreateNewPerson = result.totalElements === 0 && relationEditController.createNewPersonAllowed;
                relationEditController.person.fullName = text;
                delete relationEditController.selected;
            };

            relationEditController.onPerson = function (personId) {
                delete relationEditController.selected;
                if (angular.isDefined(personId)) {
                    Person.find(personId).$promise.then((person) => {
                        selectPerson(person);
                    });
                }
            };

            function selectPerson(person) {
                relationEditController.selected = {
                    externalId: person.externalId,
                    code: person.externalId,
                    localName: person.fullName,
                    englishName: person.fullName,
                    type: 'person',
                    id: person.id
                };
            }

            relationEditController.onTeam = function(target) {
                relationEditController.selectedTeam = target;
                relationEditController.selected = {
                    externalId: target.code,
                    code: target.code,
                    localName: target.code,
                    englishName: target.code,
                    type: 'team',
                    id: target.id
                };
            };

            relationEditController.onOrganisation = function(organisation) {
                relationEditController.organisationId = _.get(organisation, 'id');

                delete relationEditController.relation.vacancyId;
            };

            relationEditController.onVacancy = function(vacancyId) {
                relationEditController.relation.assignmentId = vacancyId;

                if (angular.isDefined(vacancyId)) {
                    relationEditController.selected = { type: 'assignment', id: vacancyId };
                } else {
                    delete relationEditController.selected;
                }
                setEvaluation();
            };

            relationEditController.save = function() {
                relationEditController.saving = true;
                const promise = prepare(relationEditController.relation);
                promise.then((relation) => {
                    update(relation);
                    relationEditController.onSave({ relation });
                }).finally(() => {
                    relationEditController.saving = false;
                });
                return promise;
            };

            function prepare(relation) {
                const deferred = $q.defer();

                if (relationEditController.mode === 'person' && relationEditController.showCreateNewPerson) {
                    Person.save(relationEditController.person).$promise.then((person) => {
                        selectPerson(person);
                        deferred.resolve(relation);
                    }, deferred.reject);
                } else {
                    deferred.resolve(relation);
                }

                return deferred.promise;
            }

            function update(relation) {
                relation.roleId = relationEditController.role.id;

                if (angular.isDefined(relationEditController.selected)) {
                    relation.target = angular.copy(relationEditController.selected);
                    delete relation.remark;
                }
            }

            relationEditController.onChange = function () {
                setEvaluation();
            };

            function setEvaluation() {
                const relation = { 
                    type: 'relation', 
                    id: _.result(relationEditController.relation, 'id')
                };
    
                relationEditController.evaluation = CustomField.getEvaluation(
                    relation,
                    relationEditController.relation,
                    relationEditController.fields);
            }
        }
    });
