'use strict';

angular.module('uasApp').component('referenceType', {
  templateUrl: 'es6/reference/reference.type.html',
  bindings: {
    type: '<'
  },
  controllerAs: 'referenceTypeController',
  controller: function ($state, $uibModal, i18nFilter, Message, Reference, ReferenceType) {
    const referenceTypeController = this;

    referenceTypeController.$onInit = function () {
      if (referenceTypeController.type.id) {
        referenceTypeController.search();
      }
    };

    referenceTypeController.search = function () {
      referenceTypeController.loading = true;
      return Reference.getByType(referenceTypeController.type.id).then((references) => {
        _.each(references, (reference) => {
          const referenceNames = _.get(reference, 'names');
          reference.displayName = i18nFilter(referenceNames);
        });

        referenceTypeController.references = references;
      }).finally(() => {
        referenceTypeController.loading = false;
      });
    };

    function openReferenceModal(reference) {
      $uibModal.open({
        template: `<reference-modal
                     reference="modalController.reference"
                     add-label="Static.Page.Reference.Add"
                     edit-label="Static.Page.Reference.Edit"
                     modal-instance="modalController.modalInstance"
                     on-save="modalController.save()">
                   </reference-modal>`,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.reference = angular.copy(reference);
            modalController.reference.type = referenceTypeController.type.id;
            modalController.modalInstance = $uibModalInstance;
          };

          modalController.save = function () {
            Reference.save(modalController.reference).$promise.then(() => {
              referenceTypeController.search();
              Message.onSaved();
              $uibModalInstance.dismiss();
            });
          };
        }
      });
    }

    referenceTypeController.addReference = function () {
      openReferenceModal({});
    };

    referenceTypeController.editReference = function (reference) {
      openReferenceModal(reference);
    };

    referenceTypeController.removeReference = function (reference) {
      Reference.remove(reference).$promise.then(() => {
        referenceTypeController.search();
        Message.onRemoved();
      });
    };

    referenceTypeController.save = function (form) {
      return ReferenceType.save(referenceTypeController.type).$promise.then((type) => {
        form.$setPristine();
        $state.go('reference-type.type', { id: type.id });
        Message.onSaved();
      });
    };
  }
});
