'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Person
 * @description
 * The Person service.
 */
angular.module('uasApp')

.factory('Person', function(UasResource) {
    return UasResource('/person', {
      avatar: {
        method: 'GET',
        url: '/person/:id/avatar',
        isArray: false
      },
      current: {
        method: 'GET',
        url: '/person/current'
      },
      password: {
        method: 'POST',
        url: '/person/:id/password'
      },
      report: {
        method: 'GET',
        url: '/person-reports',
        isArray: false
      },
      secure: {
        method: 'GET',
        url: '/person/:id/secure',
        isArray: false
      }
    });
});
