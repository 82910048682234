'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:deliveryReportPage
 * @description
 * deliveryReportPage Show deliveries on a workflow page
 */
angular.module('uasApp').component('deliveryReportPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/inventory/delivery/delivery.report.page.html',
  controllerAs: 'deliveryReportPageController',
  controller: function (Page) {
    const deliveryReportPageController = this;

    deliveryReportPageController.$onInit = function () {
      deliveryReportPageController.readOnly = Page.getParameterAsBoolean(deliveryReportPageController.page, 'readOnly', false);
    };
  }
});
