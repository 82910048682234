'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasStudies
 * @description
 * # uasStudies
 */
angular.module('uasApp').component('uasFacultiesStudies', {
    templateUrl: 'es6/faculties/faculties.studies.html',
    bindings: {
        faculty: '<?',
        operations: '<'
    },
    controllerAs: 'studiesController'
});
