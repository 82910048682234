'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityErrors
 * @description
 */
angular.module('uasApp').component('activityErrors', {
  bindings: {
    validation: '<'
  },
  templateUrl: 'es6/schedule/activity/validation/activity.errors.html',
  controllerAs: 'activityErrorsController',
  controller: function() {
    const activityErrorsController = this;

    activityErrorsController.$onChanges = function() {
      setErrors(activityErrorsController.validation);
    };

    function setErrors(validation) {
      const errors = _.result(validation, 'messages', []);
      activityErrorsController.inactive = _.some(errors, {
        fieldName: 'typeId',
        message: 'Error.Inactive'
      });
    }
  }
});