'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:ruleEdit
 * @description
 * Rule input fields
 */
angular.module('uasApp').component('ruleEdit', {
    bindings: {
        rule: '<',
        operations: '<',
        operationsToEdit: '<',
        onCancel: '&',
        onSave: '&'
    },
    templateUrl: 'es6/rule/rule.edit.html',
    controllerAs: 'ruleEditController',
    controller: function(Rule, Message) {
        const ruleEditController = this;

        ruleEditController.cancel = function() {
            ruleEditController.onCancel();
        };

        ruleEditController.save = function(rule, comment) {
            Rule.store({
              content: rule,
              comment  
            }).$promise.then(() => {
                Message.onSaved();
                ruleEditController.onSave();
            });
        };
    }
});
