'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTypeModal
 * @description
 * statusTypeModal Shows the status type
 */
angular.module('uasApp').component('statusTypeModal', {
        bindings: {
            statusType: '<',
            rootType: '<',
            instance: '<'
        },
        templateUrl: 'es6/status/type/status.type.modal.html',
        controllerAs: 'statusTypeModalController',
        controller: function ($q, RoleType, StatusType, Workflow, Hook, Message) {
            const statusTypeModalController = this;

            statusTypeModalController.$onInit = function () {
                loadData();
            };

            function loadData() {
                statusTypeModalController.loading = true;
                $q.all([
                    Workflow.query().$promise,
                    Hook.query().$promise
                ]).then(([workflows, hooks]) => {
                    statusTypeModalController.workflows = _.filter(workflows, {
                        type: statusTypeModalController.rootType
                    });

                    statusTypeModalController.hooks = hooks;
                }).finally(() => {
                    statusTypeModalController.loading = false;
                });
            }

            statusTypeModalController.close = function () {
                statusTypeModalController.instance.dismiss();
            };

            statusTypeModalController.save = function () {
                StatusType.store(statusTypeModalController.statusType).$promise.then(() => {
                    Message.onSaved();
                    statusTypeModalController.close();
                });
            };
        }
    });
