'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:i18nInput
 * @description
 * The i18nInput component.
 */
angular.module('uasApp').component('i18nInput', {
  templateUrl: 'es6/i18n/i18n.input.html',
  bindings: {
    disabled: '@?',
    editor: '@?',
    type: '@?',
    languages: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'i18nInputController',
  controller: function (Language) {
    const i18nInputController = this;

    i18nInputController.$onInit = function () {
      i18nInputController.id = Math.random().toString(36).substring(7);
      i18nInputController.descriptions = [];
      i18nInputController.editable = angular.isUndefined(i18nInputController.disabled);

      setRender();
    };

    function setRender() {
      i18nInputController.ngModelCtrl.$render = function () {
        const current = i18nInputController.ngModelCtrl.$modelValue || [];
        const languages = i18nInputController.languages || Language.supported;

        i18nInputController.descriptions = _.map(languages, (language) => {
          let model = _.find(current, { language });
          if (angular.isUndefined(model)) {
            model = { language };
          }
          return model;
        });
      };
    }

    i18nInputController.update = function () {
      const values = angular.copy(i18nInputController.descriptions);
      i18nInputController.ngModelCtrl.$setViewValue(values);
    };
  }
});
