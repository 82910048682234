'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesQualifications
 * @description
 * Displays qualifications for a faculty.
 */
angular.module('uasApp').component('facultiesQualifications', {
  templateUrl: 'es6/faculties/faculties.qualifications.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'qualificationsController'
});
