'use strict';

angular.module('uasApp').component('personRoles', {
    bindings: {
        person: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/person/roles/person.roles.html',
    controllerAs: 'personRolesController',
    controller: function($uibModal, AuthService, Message, PersonRole) {
        const personRolesController = this;
        personRolesController.loading = true;

        personRolesController.$onInit = function() {                
            personRolesController.isAllowedToEdit = AuthService.isAdmin() && personRolesController.isReadOnly !== true;
            loadData();
        };

        function loadData() {
            personRolesController.loading = true;
            return PersonRole.query({
                personId: personRolesController.person.id
            }).$promise.then((roles) => {
                personRolesController.roles = roles;
            }).finally(() => {
                personRolesController.loading = false;
            });
        }

        personRolesController.create = function() {
            personRolesController.edit({
                personId: personRolesController.person.id
            });
        };

        personRolesController.edit = function(globalRole) {
            $uibModal.open({
                template: `
                    <person-role-edit 
                        person-role="modalController.personRole"
                        operations="modalController.operations"
                        on-save="modalController.onSave(role)"
                        on-close="modalController.close()">
                    </person-role-edit>
                `,
                resolve: {
                    personRole: function() {
                        return angular.copy(globalRole);
                    }
                },
                controllerAs: 'modalController',
                controller: function($uibModalInstance, personRole) {
                    const modalController = this;
                    modalController.personRole = personRole;
                    modalController.operations = personRolesController.operations;

                    modalController.onSave = function(savedRole) {
                        if (!_.isNumber(globalRole.id)) {
                            personRolesController.roles.push(savedRole);
                            modalController.close();
                            Message.addSuccessLabel('Static.Message.DataSaved');
                        } else {
                            const index = _.findIndex(personRolesController.roles, { id: savedRole.id });
                            if (index >= 0) {
                                personRolesController.roles[index] = savedRole;
                                modalController.close();
                                Message.addSuccessLabel('Static.Message.DataSaved');
                            }
                        }
                    };

                    modalController.close = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        };

        personRolesController.delete = function(role) {
            PersonRole.delete({ id: role.id }).$promise.then(() => {
                _.remove(personRolesController.roles, { id: role.id });
                Message.addSuccessLabel('Static.Message.DataRemoved');
            });
        };
    }
});
