'use strict';

angular.module('uasApp').component('viewButton', {
  bindings: {
    btnSize: '@?',
    btnIconOnly: '<?'
  },
  require: '?^secured',
  templateUrl: 'es6/app/forms/buttons/view.button.html',
  controllerAs: 'viewButtonController',
  controller: function ($element) {
    const viewButtonController = this;

    viewButtonController.$onInit = function () {
      if (viewButtonController.securedCtrl) {
        viewButtonController.securedCtrl.resecure($element);
      }
    };
  }
});
