'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:ruleGeneral
 * @description
 * Displays general qualification information
 */
angular.module('uasApp').component('ruleGeneral', {
    bindings: {
        rule: '<'
    },
    templateUrl: 'es6/rule/rule.general.html',
    controllerAs: 'ruleGeneralController'
});
