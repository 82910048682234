'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasVideoFrame
 * @description
 * uasVideoFrame Manages a video frame.
 */
angular.module('uasApp')
  .component('uasVideoFrame', {
    bindings: {
      video: '<'
    },
    templateUrl: 'es6/app/instruction/video.frame.html',
    controllerAs: 'videoFrameController',
    controller: function($sce) {
      const videoFrameController = this;

      videoFrameController.trustSrc = function(src) {
          return $sce.trustAsResourceUrl(src);
      };
    }
});