'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Category
 * @description
 * The Category service.
 */
angular.module('uasApp')
    .factory('Category', function (UasResource) {
        return UasResource('/categories');
    });