'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:FacultyProcess
 * @description
 * # FacultyProcess
 * FacultyProcess resource of the uasApp
 */
angular.module('uasApp')
  .factory('FacultyProcess', function (UasResource) {
    return UasResource('/faculty-process');
});