'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasAccessRuleList
 * @description Manages an access rule list for a relation type
 */
angular.module('uasApp')
    .component('uasAccessRuleList', {
        bindings: {
            roleType: '<',
            modal: '<',
            onSave: '&'
        },
        templateUrl: 'es6/security/access.rule.list.html',
        controllerAs: 'listController',
        controller: function ($q, AccessRule, Message, ProcessType, StatusType, ENTITY_TYPES) {
            const listController = this;

            listController.$onInit = loadData;

            function loadData() {
                listController.loading = true;
                $q.all([
                    AccessRule.query().$promise,
                    ProcessType.query().$promise
                ]).then(([rules, processTypes]) => {
                    listController.processTypes = processTypes;

                    listController.rules = _.filter(rules, (rule) => rule.roleType.id === listController.roleType.id);

                    const entityType = toType(listController.roleType.entityType);
                    listController.entityType = ENTITY_TYPES[entityType];

                    const children = _.map(_.result(listController.entityType, 'children') || [], toEnum);
                    listController.entityTypes = children.concat(listController.roleType.entityType);
                }).finally(() => {
                    listController.loading = false;
                });
            }

            function toEnum(value) {
                return _.snakeCase(value).toUpperCase();
            }

            function toType(value) {
                return _.kebabCase(value).toLowerCase();
            }

            listController.selectEntityType = function (entityType) {
                if (entityType) {
                    listController.filteredProcessTypes = _.filter(listController.processTypes, { rootType: entityType });
                }
            };

            listController.selectProcess = function (processType) {
                delete listController.rule.status;
                delete listController.rule.statusType;
                delete listController.statusTypes;

                if (processType) {
                    return StatusType.query({
                        processTypeId: processType.id
                    }).$promise.then((statusTypes) =>
                        listController.statusTypes = statusTypes
                    );
                }
            };

            listController.selectStatus = function (status) {
                if (status) {
                    delete listController.rule.statusType;
                }
            };

            listController.toggle = function (rule) {
                delete listController.processType;

                listController.rule = {
                    id: rule.id,
                    roleType: listController.roleType.id,
                    operation: rule.operation,
                    entityType: rule.entityType,
                    condition: rule.condition,
                    status: rule.status,
                    propagate: rule.propagate
                };

                listController.selectEntityType(rule.entityType);
                if (rule.statusType) {
                    listController.selectProcess(rule.statusType.processType).then(() => {
                        listController.processType = _.find(listController.processTypes, { id: rule.statusType.processType.id });
                        listController.rule.statusType = _.get(rule.statusType, 'id');
                    });
                }
            };

            listController.close = function () {
                listController.modal.close();
            };

            listController.cancel = function () {
                delete listController.rule;
            };

            listController.remove = function(rule) {
                AccessRule.remove({ id: rule.id }).$promise.then(function() {
                    _.remove(listController.roleType.rules, { id: rule.id });
                    Message.addSuccessLabel('Static.Message.DataRemoved');
                    loadData();
                    listController.onSave();
                });
            };

            listController.save = function (rule, form) {
                AccessRule.store(rule).$promise.then(function() {
                    form.$setPristine(false);
                    listController.cancel();
                    Message.addSuccessLabel('Static.Message.DataSaved');
                    loadData();
                    listController.onSave();
                });
            };
        }
    });
