'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personEdit
 * @description Shows a form to edit a person.
 */
angular.module('uasApp')
  .component('personEdit', {
    bindings: {
      person: '<',
      operations: '<',
      onChange: '&',
      onCancel: '&'
    },
    templateUrl: 'es6/person/person.edit.html',
    controllerAs: 'personEditController',
    controller: function($q, Message, Person) {
      const personEditController = this;

      personEditController.save = function(person, comment) {
        person.comment = comment;

        return Person.store(person).$promise.then(() =>
          updatePassword(personEditController.person.password)
        ).then(() => {
          personEditController.onChange();
          Message.onSaved();
        });
      };

      function updatePassword(password) {
        if (!_.isEmpty(password)) {
          return Person.password({
            id: personEditController.person.id
          }, { password });
        }
        return $q.resolve();
      }

      personEditController.removePassword = function() {
        Person.password({
          id: personEditController.person.id
        }, { password: '' }).$promise.then(() => {
          personEditController.onChange();
          Message.onSaved();
        });
      };

      personEditController.cancel = function() {
        personEditController.onCancel();
      };
    }
});
