'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Comment
 * @description
 * The Comments service.
 */
angular.module('uasApp')
    .factory('Comment', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/comment/:id', {}, {
            'update': {
                method: 'PUT'
            },
            'mine': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/comment/mine',
                isArray: false
            },
            'asked': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/comment/mine/asked',
                isArray: false
            }
        });
    });
