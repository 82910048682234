'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesMethods
 * @description
 * Displays methods for a faculty.
 */
angular.module('uasApp').component('facultiesMethods', {
  templateUrl: 'es6/faculties/faculties.methods.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'facultiesMethodsController'
});
