'use strict';

angular.module('uasApp').component('pagePreviewModal', {
    templateUrl: 'es6/page/render/page.preview.modal.html',
    bindings: {
        page: '<',
        instance: '<'
    },
    controllerAs: 'pagePreviewModalController',
    controller: function ($q, Entity, EntityService, AuthService) {
        const pagePreviewModalController = this;

        pagePreviewModalController.$onInit = function () {
            const rootType = pagePreviewModalController.page.rootType;
            pagePreviewModalController.entityType = EntityService.getEntityType(rootType);
        };

        pagePreviewModalController.close = function () {
            pagePreviewModalController.instance.dismiss();
        };

        pagePreviewModalController.render = function (reference) {
            delete pagePreviewModalController.entity;
            delete pagePreviewModalController.operations;

            if (angular.isDefined(reference)) {
                pagePreviewModalController.loading = true;
                $q.all([
                    Entity.find(reference).$promise,
                    AuthService.operations(reference.type, reference.id)
                ]).then(([entity, operations]) => {
                    pagePreviewModalController.entity = entity;
                    pagePreviewModalController.operations = operations;
                }).finally(() => {
                    pagePreviewModalController.loading = false;
                });
            }
        };
    }
});