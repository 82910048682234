'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AcademicYearHolder
 * @description
 * The AcademicYearHolder service.
 */
angular.module('uasApp')
    .factory('AcademicYearHolder', function (AcademicYear, Message, translateFilter, $rootScope, $state, $q, Settings) {

        var instance = {};

        instance.hasLoaded = function() {
          return _.has(instance, 'current');
        };

        instance.isCurrent = function(academicYear) {
          const currentId = _.get(instance, 'current.id');

          if (_.isNumber(academicYear)) {
            return currentId === academicYear;
          }

          return currentId === academicYear.id;
        };

        /**
         * Checks if the requested academic year is the current year.
         * If not, auto-switch the application to the new year and show a message that the user has switched years.
         * @param year The year to check if it's the current year.
         */
        instance.setCurrent = function(year) {
          // supports both id and objects.
          const isId = _.isNumber(year);
          const id = _.isNumber(year) ? year : _.get(year, 'id', undefined);

          const deferred = $q.defer();
          if (id && !instance.isCurrent(id)) {
            // If only the id is supplied, fetch the year from the backend and set it as current
            if (isId) {
              return AcademicYear.get({ id }).$promise.then((found) => {
                deferred.resolve(found);
                setCurrent(found);
              });
            } else { // otherwise, directly set it as the current year.
              deferred.resolve(year);
              setCurrent(year);
            }
          } else {
            deferred.resolve(year);
          }
          return deferred.promise;
        };

        function setCurrent(year) {
          instance.set(year);
          Message.addSuccess(translateFilter('Static.Message.SwitchAcademicYear', { academicyear: year.code }));
        }

        instance.set = function(academicYear) {
            if (academicYear && !instance.isCurrent(academicYear)) {
                sessionStorage.academicYear = academicYear.id;
                instance.current = academicYear;
                Settings.set('year', academicYear.id.toString());
                $rootScope.$broadcast('SessionStorage.AcademicYear.Set', academicYear);
            }
        };

        instance.isSimulation = function() {
            return instance.current && instance.current.simulation === true;
        };

        instance.transition = function(academicYear) {
            instance.set(academicYear);
            $state.transitionTo('dashboard', {}, {
                reload: true,
                inherit: false,
                notify: true
            });
        };

        instance.onChange = function(callback) {
            return $rootScope.$on('SessionStorage.AcademicYear.Set', callback);
        };

        return instance;

    });
