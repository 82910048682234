'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:AuthorizationList
 * @description Shows the roles rules in list.
 */
angular.module('uasApp')
    .component('accessRuleTable', {
        templateUrl: 'es6/security/access.rule.table.html',
        controllerAs: 'roleListController',
        controller: function ($scope, $uibModal, $filter, $q, SecuredOperation, Pageable, RoleType, AccessRule) {
            const roleListController = this;

            roleListController.$onInit = function() {
                loadData();
            };

            roleListController.onType = function() {
                loadData();
            };

            roleListController.onGroup = function(group) {
                group.open = group.open !== true;
            };

            function loadData() {
                roleListController.loading = true;

                $q.all([
                    RoleType.query({
                        academicYearId: sessionStorage.academicYear
                    }).$promise.then((roles) => {
                        return _.sortBy(roles, ['sequence', 'code']);
                    }),
                    AccessRule.query().$promise,
                    SecuredOperation.getGroups()
                ]).then(([roles, rules, groups]) => {
                    roleListController.entityTypes = _(roles).map('entityType').uniq().value();
                    roleListController.roles = roles;
                    roleListController.rules = rules;
                    roleListController.groups = groups;

                    update();
                }).finally(() => {
                    roleListController.loading = false;
                });
            }

            roleListController.onRole = function(role) {
                const enabled = _.filter(roleListController.roles, { selected: true });
                if (enabled.length > 3) {
                    role.selected = false;
                }
                update();
            };

            function update() {
                const roles = _.filter(roleListController.roles, { selected: true });

                _.forEach(roleListController.groups, (group) => {
                    _.forEach(group.operations, (operation) => {
                        operation.rules = _.map(roles, (role) => {
                            const rules = _.filter(roleListController.rules, (it) => {
                                return it.operation === operation.name && it.roleType.id === role.id;
                            });

                            return {
                                role: role,
                                enabled: rules.length > 0,
                                entities: rules,
                                complex: _.some(rules, (rule) => {
                                    return rule.entityType || rule.propagate || rule.statusType || rule.condition;
                                })
                            };
                        });
                    });
                });
            }

            roleListController.toggle = function(rule, operation) {
                if (rule.entities.length > 0) {
                    const promises = _.map(rule.entities, (it) => AccessRule.remove({ id: it.id }).$promise.then(() => {
                        _.remove(roleListController.rules, { id: it.id });
                    }));

                    $q.all(promises).then(() => update());
                } else {
                    AccessRule.store({
                        operation: operation.name,
                        roleType: rule.role.id
                    }).$promise.then(function(result) {
                        roleListController.rules.push(result);
                        update();
                    });
                }
            };

            roleListController.open = function(role) {
                $uibModal.open({
                    controllerAs: 'modalController',
                    controller: function ($uibModalInstance) {
                        const modalController = this;
                        modalController.modal = $uibModalInstance;
                        modalController.role = role;
                        modalController.refresh = function() {
                            AccessRule.query().$promise.then((rules) => {
                                roleListController.rules = rules;
                                update();
                            });
                        };
                    },
                    size: 'lg',
                    template: `
                        <uas-access-rule-list 
                            role-type="modalController.role" 
                            modal="modalController.modal"
                            on-save="modalController.refresh()">
                        </uas-access-rule-list>
                    `
                });
            };
        }
    });
