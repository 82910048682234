'use strict';

angular.module('uasApp').component('notificationStyles', {

    templateUrl: 'es6/admin/notification/styles/notification.styles.html',
    controllerAs: 'notificationStylesController',
    controller: function ($uibModal, NotificationStyle, Message) {

        const notificationStylesController = this;

        notificationStylesController.$onInit = function () {
            loadData();
        };

        function loadData() {
            notificationStylesController.loading = true;
            NotificationStyle.query().$promise.then((styles) => {
                notificationStylesController.styles = styles;
            }).finally(() => {
                notificationStylesController.loading = false;
            });
        }

        notificationStylesController.create = function () {
            open({});
        };

        notificationStylesController.edit = function (style) {
            const form = angular.copy(style);
            open(form);
        };

        function open(style) {
            $uibModal.open({
                size: 'lg',
                templateUrl: 'es6/admin/notification/styles/notification.style.edit.html',
                controllerAs: 'notificationStyleEditController',
                controller: function ($uibModalInstance) {
                    const notificationStyleEditController = this;

                    notificationStyleEditController.$onInit = function () {
                        notificationStyleEditController.style = style;
                    };

                    notificationStyleEditController.save = function () {
                        NotificationStyle.store(notificationStyleEditController.style).$promise.then(() => {
                            loadData();
                            Message.addSuccessLabel('Static.Message.DataSaved');
                            $uibModalInstance.close();
                        });
                    };
                }
            });
        }

        notificationStylesController.delete = function (style) {
            NotificationStyle.delete({
                id: style.id
            }).$promise.then(() => {
                loadData();
                Message.addSuccessLabel('Static.Message.DataRemoved');
            });
        };

    }

});