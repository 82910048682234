'use strict';

/**
 * Display boolean value as a check mark icon.
 */
angular.module('uasApp')
    .component('uasCheck', {
        bindings: {
            value: '<',
            showFalse: '<?'
        },
        template: `
            <i class="fa fa-fw fa-{{ checkController.checked ? \'check\' : \'times\' }}" 
               ng-show="checkController.checked || checkController.showFalse">
            </i>
        `,        
        controllerAs: 'checkController',
        controller: function(translateFilter) {
            const checkController = this;

            checkController.$onChanges = function() {
                checkController.checked = isTrue(checkController.value);
            };

            function isTrue(value) {
                if (_.isString(value)) {
                    return _.lowerCase(value) === 'true' || value === translateFilter('Boolean.TRUE');
                }

                return value === true;
            }
        }
    });
