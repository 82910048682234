'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:colorInput
 * @description Input to pick a color combined with an input to enter a hexadecimal color value.
 */
angular.module('uasApp').component('colorPicker', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/color/color.picker.html',
  controllerAs: 'colorPickerController',
  controller: function () {
    const colorPickerController = this;

    colorPickerController.$onInit = function () {
      colorPickerController.colorRegex = /^#[0-9a-f]{6}$/i;

      colorPickerController.ngModelCtrl.$render = function () {
        colorPickerController.color = colorPickerController.ngModelCtrl.$modelValue;
      };
    };

    colorPickerController.onColorChange = function () {
      colorPickerController.ngModelCtrl.$setViewValue(colorPickerController.color);
    };

    colorPickerController.onHexChange = function () {
      if (colorPickerController.colorRegex.test(colorPickerController.color)) {
        colorPickerController.ngModelCtrl.$setViewValue(colorPickerController.color);
      } else {
        colorPickerController.ngModelCtrl.$setViewValue();
      }
    };
  }
});