'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Sort
 * @description
 * The Sort service.
 */
angular.module('uasApp')
  .factory('Sort', function () {

    function get(parameters) {
      const { order, reverse } = parameters;

      if (_.isEmpty(order)) {
        return '';
      }
      if (_.isArray(order)) {
        return _.map(order, (property) => build(property, reverse));
      }

      return build(order, reverse);
    }

    function build(property, reverse) {
      return `${property},${reverse ? 'desc' : 'asc'}`;
    }

    function parse(sort) {
      let order = '';
      let reverse = false;

      if (!_.isEmpty(sort)) {
        const fragments = sort.split(',');
        order = fragments[0];
        reverse = fragments[1] === 'desc';
      }

      return { order, reverse };
    }

    return { get, parse };

  });
