'use strict';

/**
 * @ngdoc filter
 * @name uasApp.filter:elementTranslate
 * @description Translates an element to a string.
 */
angular.module('uasApp').filter('elementTranslate', function (Element) {
  return function (element, elements) {
    return getLabel(element, elements);
  };

  function getLabel(value, elements) {
    if (angular.isUndefined(value)) {
      return '';
    } else if (_.isArray(value)) {
      return _(value).map((it) => findAndTranslate(it, elements)).join(', ');
    }

    return findAndTranslate(value, elements);
  }

  function findAndTranslate(id, elements) {
    const element = _.find(elements, { id });
    return angular.isDefined(element) ? Element.getLabel(element) : id;
  }
});
