'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('custom-pages', {
        url: '/custom-pages',
        template: '<uas-custom-page-list pages="customPageStateController.pages"></uas-custom-page-list>',
        resolve: {
            pages: function (CustomPage) {
              return CustomPage.query().$promise;
            },
            states: function ($state) {
              return $state.get();
            }
        },
        controllerAs: 'customPageStateController',
        controller: function (pages, states) {
          const routes = _(states)
            .map('name')
            .filter((name) => (name || '').length > 0)
            .uniq()
            .sort()
            .value();

          _.forEach(routes, (route) => {
            let page = _.find(pages, { route });
            if (angular.isUndefined(page)) {
              // Create default page on demand
              page = {
                route: route,
                visible: true,
                visibleSimulation: true
              };

              pages = pages.concat(page);
            }

            page.state = true;
          });

          this.pages = pages;
        },
        breadcrumb: {
            label: 'Static.Page.CustomPages',
            root: true
        },
        admin: true
    });

});
