'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesPersons
 * @description
 * Displays persons for a faculty.
 */
angular.module('uasApp').component('facultiesPersons', {
  templateUrl: 'es6/faculties/faculties.persons.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'facultiesPersonsController'
});
