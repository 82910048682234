'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:methodParameterList
 * @description
 * # methodParameterList
 * Manage parameter values on faculty.
 */
angular.module('uasApp').component('methodParameterList', {
  bindings: {
    facultyId: '<',
    operations: '<',
    isReadOnly: '<?',
    hideCopy: '<?',
    showAllCategories: '<?'
  },
  templateUrl: 'es6/methods/parameter/method.parameter.list.html',
  controllerAs: 'methodParameterController',
  controller: function ($q, AcademicYear, CustomField, Message, Category, MethodType, MethodParameter, SecurityService) {
    const methodParameterController = this;

    methodParameterController.$onInit = function () {
      methodParameterController.academicYearId = sessionStorage.academicYear;

      loadData();
    };

    function loadData() {
      methodParameterController.loading = true;
      $q.all([
        Category.query({
          rootType: 'METHOD'
        }).$promise,
        MethodType.query({
          academicYearId: methodParameterController.academicYearId
        }).$promise,
        MethodParameter.query({
          facultyId: methodParameterController.facultyId,
          academicYearId: methodParameterController.academicYearId
        }).$promise,
        CustomField.query({
          rootType: 'faculty',
          entityType: 'method-parameter'
        }).$promise,
        AcademicYear.previous({
          id: methodParameterController.academicYearId
        }).$promise
      ]).then(([categories, types, parameters, fields, previous]) => {
        methodParameterController.fields = _(fields)
          .filter({ visible: true })
          .sortBy('sequence')
          .value();

        methodParameterController.categories = _(categories)
          .filter((category) => methodParameterController.showAllCategories || category.budget)
          .sortBy(['sequence', 'code'])
          .map((category) => {
            category.types = _(types)
              .filter({ categoryId: category.id })
              .map((type) => buildType(type, parameters))
              .sortBy('code')
              .value();

            return category;
          }).value();

        methodParameterController.types = _(methodParameterController.categories)
          .map('types')
          .flatten()
          .value();

        methodParameterController.previous = previous;
      }).finally(() => {
        methodParameterController.loading = false;
      });

      
      methodParameterController.viewAllowed = SecurityService.isAllowed('VIEW_COST', methodParameterController.operations);
      const editAllowed = SecurityService.isAllowed('EDIT_COST', methodParameterController.operations);
      methodParameterController.editable = editAllowed && !methodParameterController.isReadOnly;
    }

    function buildType(type, parameters) {
      const parameter = _.find(parameters, (it) => it.type.id === type.id) || {};
      const values = getValues(parameter);
      values.id = parameter.id;

      type.values = values;
      type.original = angular.copy(type.values);

      return type;
    }

    function getValues(parameter) {
      const values = {};
      _.forEach(methodParameterController.fields, (field) => {
        values[field.name] = parameter[field.name];
      });
      return values;
    }

    methodParameterController.save = function (form) {
      const promises = _(methodParameterController.types).filter(isDirty).map((type) => {
        const body = _.extend(type.values, {
          typeId: type.id,
          facultyId: methodParameterController.facultyId,
          academicYearId: methodParameterController.academicYearId
        });

        return MethodParameter.store(body).$promise;
      }).value();

      $q.all(promises).then(() => {
        Message.addSuccessLabel('Static.Message.SavedChanges');
        form.$setPristine(true);
        loadData();
      });
    };

    function isDirty(type) {
      if (angular.isUndefined(type.values.id)) {
        return true;
      }

      return !_.isEqual(type.values, type.original);
    }

    methodParameterController.import = function (form) {
      MethodParameter.query({
        facultyId: methodParameterController.facultyId,
        academicYearId: methodParameterController.previous.id
      }).$promise.then((parameters) => {
        _.forEach(parameters, (parameter) => {
          const type = _.find(methodParameterController.types, {
            id: parameter.type.id
          });

          if (angular.isDefined(type)) {
            const values = getValues(parameter);
            type.values = _.extend(type.values, values);
          }
        });

        form.$setDirty(true);
      });
    };
  }
});
