'use strict';

/**
 * Feature resource, determines if certain pages are available or not.
 */
angular.module('uasApp')
    .factory('Feature', function(UasResource) {
        const NEVER = 'false = true'; // Condition that always evaluaties false

        const resource = UasResource('/features', {
            'allowed': {
                method: 'GET',
                url: '/features/allowed',
                isArray: true
            }
        });

        resource.getCondition = function(feature) {
            const enabled = _.result(feature, 'enabled') === true;
            if (!enabled) {
                return NEVER;
            }

            return _.result(feature, 'condition');
        };

        return resource;
    });
