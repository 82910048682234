'use strict';

angular.module('uasApp').component('personNotifications', {
    templateUrl: 'es6/app/notifications/person.notifications.html',
    controllerAs: 'personNotificationsController',
    controller: function(Language, Message, Notification, Person) {
        let personNotificationsController = this;

        personNotificationsController.$onInit = function() {
            loadData();
        };

        function loadData() {
            personNotificationsController.supportedLanguages = Language.supported;

            personNotificationsController.loading = true;
            Person.current().$promise.then((person) => {
                personNotificationsController.person = person;
            }).finally(() => personNotificationsController.loading = false);
        }

        personNotificationsController.save = function () {
            Notification.changeSettings({
                email: personNotificationsController.person.email,
                language: personNotificationsController.person.preferredEmailLanguage
            }).$promise.then(() => {
                Message.addSuccessLabel('Static.Message.DataSaved');
            });
        };
    }
});
