'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:costDivisionLabel
 * @description
 * costDivisionLabel cost division label
 */
angular.module('uasApp')
  .component('costDivisionLabel', {
    bindings: {
      organisation: '<',
      percentage: '<'
    },
    templateUrl: 'es6/cost/division/cost.division.label.html',
    controllerAs: 'costDivisionLabelController'
});