'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:entityTooltip
 * @description
 * entityTooltip Shows the entity details
 */
angular.module('uasApp').component('entityTooltip', {
    bindings: {
        entity: '<',
        rootType: '@',
        entityType: '@',
        header: '@',
        placement: '@?'
    },
    controllerAs: 'entityTooltipController',
    templateUrl: 'es6/app/tooltip/entity.tooltip.html'
});