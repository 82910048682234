'use strict';

/**
 * @ngdoc service
 * @name uasServices.factory:CalendarWeek
 * @description
 * The CalendarWeek service.
 */
angular.module('uasApp').factory('CalendarWeek', function (UasResource) {
    return UasResource('/calendar-weeks');
});
