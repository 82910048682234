'use strict';

angular.module('uasApp')
  /**
  * @param {String} input The input.
  * @param {String} query The query text to be bolded.
  * @return {String} The input but all occurences of @code{query} are surrounded with a span with class super-class.
  */
    .filter('boldSearch', function() {
        return function(input, query) {
          if (!input) {
            return input;
          }
            return input.replace(new RegExp('(' + query + ')', 'gi'), '<span class="super-class">$1</span>');
        };
    });