'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:customFieldDetails
 * @description
 * customFieldDetails Shows the details of an entity in a popover
 */
angular.module('uasApp').component('customFieldDetails', {
    bindings: {
        entity: '<',
        rootType: '@',
        entityType: '@',
        header: '@'
    },
    templateUrl: 'es6/app/tooltip/custom.field.details.html',
    controllerAs: 'customFieldDetailsController',
    controller: function(CustomField) {
        const customFieldDetailsController = this;

        customFieldDetailsController.$onInit = function() {
            loadData();
        };

        function loadData() {
            customFieldDetailsController.loading = true;

            CustomField.query({
                rootType: customFieldDetailsController.rootType,
                entityType: customFieldDetailsController.entityType
            }).$promise.then((fields) => {
                customFieldDetailsController.fields = _(fields)
                    .filter('displayOnGeneral')
                    .sortBy(['sequence', 'name', 'id'])
                    .value();

                setValues();
            }).finally(() => {
                customFieldDetailsController.loading = false;
            });
        }

        function setValues() {
          customFieldDetailsController.values = {};

          _.forEach(customFieldDetailsController.entity.values, (value) => {
            customFieldDetailsController.values[value.name] = value.value;
          });
        }
    }
});
