'use strict';

/* eslint-disable angular/document-service */

/**
 * @ngdoc function
 * @name uasApp.controller:documentList
 * @description Shows all documents of an entity.
 */
angular.module('uasApp')
    .component('documentList', {
        bindings: {
            entity: '<',
            type: '<?',
            typeIds: '<?', // Id's of element type 'DOCUMENT_TYPE'
            nameFormula: '<?',
            operations: '<',
            operationsToEdit: '<',
            isReadOnly: '<?',
            columns: '<?'
        },
        templateUrl: 'es6/document/document.list.html',
        controllerAs: 'documentListController',
        controller: function ($q, $uibModal, $http, Column, CustomField, Document, Pageable, Download, Message, SecurityService, feedbackObserver) {
            const documentListController = this;

            documentListController.$onInit = function () {
                documentListController.active = true;
                documentListController.pageable = Pageable.of();
                documentListController.editable = !documentListController.isReadOnly
                    && SecurityService.isAllowed(documentListController.operationsToEdit, documentListController.operations);

                documentListController.loadData();
            };

            documentListController.loadData = function () {
                documentListController.loading = true;

                $q.all([
                    loadDocuments(),
                    loadFields()
                ]).then(([documents, fields]) => {
                    documentListController.documents = documents;
                    documentListController.fields = CustomField.keyByName(fields);
                    documentListController.columns_ = Column.fromFields(fields, documentListController.columns);
                }).finally(() => {
                    documentListController.loading = false;
                });
            };

            function loadDocuments() {
                const params = documentListController.pageable.build({
                    entityType: documentListController.entity.self.type,
                    entityId: documentListController.entity.id,
                    active: documentListController.active,
                    typeIds: documentListController.typeIds
                });

                return Document.get(params).$promise;
            }

            function loadFields() {
                return CustomField.query({
                    rootType: _.get(documentListController, 'entity.self.type'),
                    entityType: 'document'
                }).$promise;
            }

            documentListController.download = function (document, version) {
                if (_.isEmpty(document.href)) {
                    $http({
                        url: `api/documents/${document.id}/download?version=${version.id}`,
                        method: 'POST',
                        responseType: 'arraybuffer'
                    }).then((response) => {
                        Download.downloadResponse(response.data, response.headers());
                    });
                }
            };

            documentListController.create = function () {
                open({ entity: documentListController.entity.self });
            };

            documentListController.update = function (document) {
                open(angular.copy(document));
            };

            function open(data) {
                $uibModal.open({
                    template: `
                        <document-edit
                            document="documentEditModalController.document"
                            entity="documentEditModalController.entity"
                            type="documentEditModalController.type"
                            type-ids="documentEditModalController.typeIds"
                            name-formula="documentEditModalController.nameFormula"
                            instance="documentEditModalController.instance"
                            operations="documentEditModalController.operations"
                            operations-to-edit="documentEditModalController.operationsToEdit"
                            on-submit="documentEditModalController.onSubmit(result)">
                        </document-edit>
                    `,
                    size: 'lg',
                    controllerAs: 'documentEditModalController',
                    controller: function ($uibModalInstance) {
                        this.$onInit = function () {
                            this.document = _.omit(data, ['latest']);
                            this.entity = documentListController.entity;
                            this.document.href = _.get(data, 'latest.href');
                            this.type = documentListController.type;
                            this.typeIds = documentListController.typeIds;
                            this.nameFormula = documentListController.nameFormula;
                            this.onSubmit = documentListController.loadData;
                            this.operations = documentListController.operations;
                            this.operationsToEdit = documentListController.operationsToEdit;

                            this.instance = $uibModalInstance;
                        };
                    }
                });
            }

            documentListController.delete = function (entity) {
                Document.remove({
                    id: entity.id
                }).$promise.then(() => {
                    feedbackObserver.dataChanged();
                    Message.onRemoved();
                    documentListController.loadData();
                });
            };
        }
    });
