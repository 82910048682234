'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:qualificationGeneral
 * @description
 * Displays general qualification information
 */
angular.module('uasApp').component('qualificationGeneral', {
    bindings: {
        qualification: '<'
    },
    templateUrl: 'es6/qualification/qualification.general.html',
    controllerAs: 'qualificationGeneralController',
    controller: function(AwardQualification, $state) {
        const qualificationGeneralController = this;

        qualificationGeneralController.$onInit = function() {
            setEntities();
        };

        qualificationGeneralController.onStatus = function() {
            $state.reload();
        };

        function setEntities() {
            AwardQualification.query({
                qualificationId: qualificationGeneralController.qualification.id
            }).$promise.then((awards) => {
                qualificationGeneralController.entities = _.map(awards, 'entity');
            });
        }
    }
});
