'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ImportIgnore
 * @description
 * The ImportIgnore service.
 */
angular.module('uasApp')
    .factory('ImportIgnore', function(UasResource) {
        return UasResource('/import-ignore');
    });