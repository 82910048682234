'use strict';

angular.module('uasApp').component('workflowPageSettings', {
    templateUrl: 'es6/workflow/setup/workflow.setup.page-settings.html',
    bindings: {
        workflow: '<',
        page: '<'
    },
    controllerAs: 'workflowPageSettingsController',
    controller: function (workflowSetupIsDirtyChecker) {
        const workflowPageSettingsController = this;

        workflowPageSettingsController.$onInit = function () {
            workflowPageSettingsController.flagDirty = workflowSetupIsDirtyChecker.flagDirty;
        };
    }
});
