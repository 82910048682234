'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:studyStudyableModules
 * @description
 * # studyableModules
 * Displays the studyability of modules.
 */
angular.module('uasApp')
    .component('studyStudyableModules', {
        bindings: {
            modules: '<',
            studyId: '@'
        },
        templateUrl: 'es6/study/studyable/study.studyable.modules.html',
        controllerAs: 'studyStudyableModulesController',
        controller: function(AuthService, Pageable, Report, Language) {
            const studyStudyableModulesController = this;

            studyStudyableModulesController.$onInit = function() {              
                studyStudyableModulesController.loading = false;
                studyStudyableModulesController.all = false; 
                studyStudyableModulesController.pageable = Pageable.of({
                    order: 'code'
                });

                studyStudyableModulesController.filterModules();

                AuthService.operations('study', studyStudyableModulesController.studyId).then((operations) => {
                    studyStudyableModulesController.operations = operations;
                });
            };

            studyStudyableModulesController.toggle = function(all) {
                studyStudyableModulesController.all = all;
                studyStudyableModulesController.filterModules();
            };

            studyStudyableModulesController.filterModules = function() {
                studyStudyableModulesController.filteredModules = _(studyStudyableModulesController.modules)
                    .filter((module) => {
                        return studyStudyableModulesController.all === true ||
                               (module.terminated === true || module.unadvicedOfferings === true);
                    }).value();
            };


            studyStudyableModulesController.filterStudies = function(study) {
                return study.id === parseInt(studyStudyableModulesController.studyId);
            };

            studyStudyableModulesController.getReport = function() {
                const query = {
                    incorrectOnly: !studyStudyableModulesController.all,
                    language: Language.get()
                };

                query.studyId = studyStudyableModulesController.studyId;
                return Report.studyableModules(query).$promise;
            };

        }
    });
