'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ManualNotification
 * @description
 * The ManualNotification service.
 */
angular.module('uasApp')
    .factory('ManualNotification', function (UasResource) {
        return UasResource('/manual-notification', {
            send: {
                method: 'POST',
                url: '/manual-notification/send',
                isArray: false
            },
            module: {
                method: 'POST',
                url: '/manual-notification/send/module',
                isArray: false
            },
            study: {
                method: 'POST',
                url: '/manual-notification/send/study',
                isArray: false
            }
        });
    });
