'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('method', {
        url: '/method/{id:[0-9]{1,8}}',
        resolve: {
            method: function(Method, AcademicYearHolder, $stateParams) {
                const instance = this;
                return Method.get({
                    id: $stateParams.id
                }).$promise.then((method) => {
                    instance.self.data = method;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(method.academicYearId).then(() => {
                        return method;
                    });
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('method', $stateParams.id);
            }
        },
        controllerAs: 'methodStateController',
        controller: function(method, operations) {
            this.method = method;
            this.operations = operations;
            this.tabs = [{
                view: 'general',
                route: 'method.general',
                readOnly: true
            }, {
                title: 'Static.Tab.Method.Admin',
                view: 'admin',
                route: 'method.admin',
                label: 'Static.Help.Method.Admin'
            }];
        },
        template: `
            <entity-tabs 
                view="method" 
                entity="methodStateController.method"
                tabs="methodStateController.tabs"
                retrieve-feedback-from-root="false"
                operations="methodStateController.operations"
            />
        `, // do not retrieve feedback from root, since that is (for now) Module.
        abstract: true,
        entityType: 'method',
        redirectTo: 'method.general',
        breadcrumb: {}
    });

    $stateProvider.state('method.general', {
        url: '/general',
        views: {
            'method': {
                controllerAs: 'methodGeneralStateController',
                controller: function(method, operations) {
                    this.method = method;
                    this.operations = operations;
                },
                template: `
                    <page-loader 
                        route="general" 
                        entity="methodGeneralStateController.method" 
                        operations="methodGeneralStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Method.General',
            label: 'Static.Help.Method.General'
        }
    });

    $stateProvider.state('method.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'method': {
                controllerAs: 'methodPageStateController',
                controller: function(method, operations, $stateParams) {
                    this.method = method;
                    this.operations = operations;
                    this.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="methodPageStateController.pageId" 
                        entity="methodPageStateController.method" 
                        operations="methodPageStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });

    $stateProvider.state('method.admin', {
        url: '/admin',
        views: {
            'method': {
                controllerAs: 'adminStateController',
                controller: function (method, operations) {
                    this.method = method;
                    this.operations = operations;
                },
                template: `
                    <method-admin
                        initial-values="adminStateController.method"
                        operations="adminStateController.operations">
                    </method-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Method.Admin',
            label: 'Static.Help.Method.Admin'
        }
    });
});
