'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:workflowPreview
 * @description
 * workflowPreview Preview the workflow
 */
angular.module('uasApp').component('workflowPreview', {
  bindings: {
    workflow: '<'
  },
  templateUrl: 'es6/workflow/preview/workflow.preview.html',
  controllerAs: 'previewWorkflowController',
  controller: function ($uibModal, Entity) {
    const previewWorkflowController = this;

    previewWorkflowController.open = function () {
      $uibModal.open({
        templateUrl: 'es6/workflow/preview/workflow.preview.modal.html',
        controllerAs: 'workflowPreviewModalController',
        controller: function ($uibModalInstance, WorkflowModal, EntityService) {
          const workflowPreviewModalController = this;

          workflowPreviewModalController.$onInit = function () {
            workflowPreviewModalController.workflow = angular.copy(previewWorkflowController.workflow);

            const rootType = workflowPreviewModalController.workflow.type;
            workflowPreviewModalController.entityType = EntityService.getEntityType(rootType);
          };

          workflowPreviewModalController.close = function () {
            $uibModalInstance.dismiss();
          };

          workflowPreviewModalController.render = function (reference) {
            Entity.find(reference).$promise.then((entity) => {
              if (angular.isDefined(entity)) {
                WorkflowModal.open({
                  entity,
                  workflow: workflowPreviewModalController.workflow
                });
              }
            });
          };
        }
      });
    };
  }
});
