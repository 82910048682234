'use strict';

angular.module('uasApp').component('taskAttention', {
    templateUrl: 'es6/staffing/task/task.attention.html',
    bindings: {
        assignment: '<',
        relation: '<',
        task: '<',
        period: '<',
        classes: '@?'
    },
    controllerAs: 'taskAttentionController'
});