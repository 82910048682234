'use strict';

angular.module('uasApp').component('assessmentFields', {
  bindings: {
    assessment: '<',
    category: '<?',
    evaluation: '<',
    includeTypes: '<?',
    operations: '<?',
    operationsToEdit: '<?',
    onChange: '&'
  },
  templateUrl: 'es6/assessment/assessment.fields.html',
  controllerAs: 'assessmentFieldsController',
  controller: function ($q, CustomField, Category, EntityType, MethodType) {
    const assessmentFieldsController = this;

    const EXTENSIONS = [
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' }
    ];

    assessmentFieldsController.$onInit = function () {
      loadData();
    };

    function loadData() {
      assessmentFieldsController.loading = true;
      $q.all([
        Category.query({
          rootType: 'METHOD'
        }).$promise,
        CustomField.query({
          rootType: 'assessment'
        }).$promise,
        getTypes()
      ]).then(([categories, fields, types]) => {
        assessmentFieldsController.categories = categories;
        assessmentFieldsController.fields = _.sortBy(CustomField.extend(fields, EXTENSIONS), 'sequence');
        assessmentFieldsController.types = _.filter(types, (type) => {
          return EntityType.isIncluded(assessmentFieldsController.includeTypes, type);
        });

        setShowDurationInMinutes();
        setEvaluation();
      }).finally(() => {
        assessmentFieldsController.loading = false;
      });
    }

    function getTypes() {
      if (angular.isDefined(assessmentFieldsController.category)) {
        return MethodType.query({
          categoryId: assessmentFieldsController.category.id,
          academicYearId: sessionStorage.academicYear
        }).$promise;
      }

      return MethodType.query({
        academicYearId: sessionStorage.academicYear,
        exam: true
      }).$promise;
    }

    function setShowDurationInMinutes() {
      const typeId = _.get(assessmentFieldsController.assessment, 'typeId');

      if (!typeId) {
        assessmentFieldsController.showDurationInMinutes = false;
        return;
      }

      const methodType = _.find(assessmentFieldsController.types, (type) => type.id === typeId);
      const methodCategory = _.find(assessmentFieldsController.categories, (category) => category.id === methodType.categoryId);

      assessmentFieldsController.showDurationInMinutes = _.get(methodCategory, 'showDurationInMinutes', false);
    }

    function setEvaluation() {
      const entity = {
        type: 'assessment',
        id: _.result(assessmentFieldsController.assessment, 'id')
      };

      assessmentFieldsController.evaluation = CustomField.getEvaluation(entity, assessmentFieldsController.assessment, assessmentFieldsController.fields);
    }

    assessmentFieldsController.onValue = function() {
      assessmentFieldsController.onChange({
        assessment: assessmentFieldsController.assessment
      });

      setEvaluation();
      setShowDurationInMinutes();
    };
  }
});
