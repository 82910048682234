'use strict';

angular.module('uasApp').component('columnParameter', {
    templateUrl: 'es6/page/config/column.parameter.html',
    bindings: {
        selectedColumnsMax: '<?',
        definitions: '<?',
        rootType: '@?',
        entityType: '@?',
        infoLabel: '@?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'columnParameterController',
    controller: function ($q, Changes, CustomField, EntityService, Page) {
        const columnParameterController = this;

        columnParameterController.$onInit = function() {
            columnParameterController.ngModelCtrl.$render = function () {
                const value = columnParameterController.ngModelCtrl.$modelValue || '';
                columnParameterController.values = Page.asArray(value);
                setColumns();
            };

            columnParameterController.filterId = _.uniqueId('column-parameter-filter-');
            getColumns();
            setTooltips();
        };

        columnParameterController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'rootType') || Changes.hasChanged(changes, 'entityType')) {
              setTooltips();
            }
        };

        function setTooltips() {
          setAvailableColumnsTooltipKey();
          setSelectedColumnsTooltipKey();
        }

        function setAvailableColumnsTooltipKey() {
          if (_.isEmpty(columnParameterController.rootType)) {
            columnParameterController.availableColumnsAlertKey = 'Static.ColumnParameter.Options.Available.Page.RootType.Empty';
            columnParameterController.availableColumnsAlertTooltipKey = 'Static.ColumnParameter.Options.Available.Page.RootType.Empty.Tooltip';
          }
        }

        function setSelectedColumnsTooltipKey() {
            const keyLimited = 'Static.ColumnParameter.Options.Selected.Amounts.Limited';
            const keyUnlimited = 'Static.ColumnParameter.Options.Selected.Amounts.Unlimited';

            columnParameterController.selectedColumnsAlertTooltipKey = angular.isDefined(columnParameterController.selectedColumnsMax) ? keyLimited : keyUnlimited;
        }

        function getColumns() {
          columnParameterController.loading = true;

            getFields().then((fields) => {
                const definitions = columnParameterController.definitions || [];
                columnParameterController.columns = _(definitions).concat(fields).uniqBy('name').value();
                setColumns();
            }).finally(() => {
                columnParameterController.loading = false;
            });
        }

        function getFields() {
            if (columnParameterController.rootType || columnParameterController.entityType) {
                return CustomField.query({
                    rootType: EntityService.getEntityType(columnParameterController.rootType),
                    entityType: columnParameterController.entityType
                }).$promise.then((fields) => {
                    return _(fields).filter({ visible: true }).map((field) => {
                        return { name: field.name, label: CustomField.getLabel(field) };
                    }).value();
                });
            }

            return $q.resolve([]);
        }

        function setColumns() {
            columnParameterController.availableColumns = _.filter(columnParameterController.columns, (column) => !isSelected(column));

            const unknownColumns = _(columnParameterController.values)
                .filter((value) => !_.find(columnParameterController.columns, { name: value }))
                .map((value) => ({ name: value, label: value, unknown: true }))
                .value();

            columnParameterController.selectedColumns = _(columnParameterController.columns)
              .filter(isSelected)
              .sortBy(sortSelected)
              .concat(unknownColumns)
              .value();
        }

        function sortSelected(column) {
            if (_.isEmpty(columnParameterController.values) || _.isEmpty(column.name)) {
                return 0;
            }

            return _.indexOf(columnParameterController.values, column.name);
        }

        function isSelected(column) {
            return _.includes(columnParameterController.values, column.name);
        }

        columnParameterController.onChange = function() {
            columnParameterController.values = _.map(columnParameterController.selectedColumns, 'name');
            setColumns();

            const value = _.join(columnParameterController.values, ',');
            columnParameterController.ngModelCtrl.$setViewValue(value);
        };
    }
});
