'use strict';

angular.module('uasApp').component('deleteButtonConfirm', {
    bindings: {
      btnLabel: '@?',
      btnType: '@?',
      btnSize: '@?',
      btnIconOnly: '=?',
      type: '@?',
      btnDisabled: '=?',
      confirm: '<?',
      confirmText: '<?',
      preCallback: '&?',
      callback: '&',
      commentType: '<?',
      rootType: '@?',
      entityType: '@?'
    },
    require: '?^secured',
    templateUrl: 'es6/app/forms/buttons/delete.button.confirm.html',
    controllerAs: 'deleteController',
    controller: function ($element, $uibModal) {
      const deleteController = this;

      deleteController.$onInit = function() {
        if (deleteController.securedCtrl) {
          deleteController.securedCtrl.resecure($element);
        }
      };

      deleteController.onClick = function($event) {
        if (deleteController.preCallback) {
          deleteController.preCallback();
        }

        if (deleteController.confirm !== false) {
          open($event);
        } else {
          deleteController.callback({ comment: '' });
        }
      };

      function open($event) {
        $event.preventDefault();
        $event.stopPropagation();

        $uibModal.open({
          templateUrl: 'es6/app/forms/buttons/delete.button.confirm.modal.html',
          controllerAs: 'modalController',
          controller: function ($uibModalInstance) {
            const modalController = this;

            modalController.$onInit = function() {
              modalController.confirmText = deleteController.confirmText;
              modalController.commentType = deleteController.commentType;
              modalController.rootType = deleteController.rootType;
              modalController.entityType = deleteController.entityType;
            };

            modalController.remove = function () {
              deleteController.callback({ comment: modalController.comment });
              $uibModalInstance.close();
            };
          }
        });
      }
    }
  }
);
