'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:itemReportPage
 * @description
 * The itemReportPage component.
 */
angular.module('uasApp').component('itemReportPage', {
    templateUrl: 'es6/item/report/item.report.page.html',
    bindings: {
      entity: '<',
      period: '<',
      operations: '<',
      page: '<'
    },
    controllerAs: 'itemReportPageController',
    controller: function (Language, Page, EntityService, Item) {
        const itemReportPageController = this;

        itemReportPageController.$onInit = function() {
          const rootType = itemReportPageController.page.filterType || itemReportPageController.page.rootType;
          itemReportPageController.entityType = EntityService.getEntityType(rootType);
          itemReportPageController.typeCode = Page.getParameter(itemReportPageController.page, 'typeCode');
          itemReportPageController.context = `${itemReportPageController.entity.self.type}.items.${itemReportPageController.typeCode}`;
          itemReportPageController.onLanguage = Language.onChange(loadData);
          loadData();
        };

        itemReportPageController.$onDestroy = function() {
          itemReportPageController.onLanguage();
        };

        function loadData() {
          itemReportPageController.loading = true;
          Item.report({
            academicYearId: sessionStorage.academicYear,
            entityType: itemReportPageController.entityType,
            typeCode: itemReportPageController.typeCode,
            definition: true
          }).$promise.then((report) => {
            itemReportPageController.columns = report.headers;
            itemReportPageController.groups = report.groups;
          }).finally(() => {
            itemReportPageController.loading = false;
          });
        }

        itemReportPageController.getRows = function(pageable) {
          const parameters = getParameters(pageable);
          return Item.report(parameters).$promise;
        };

        function getParameters(pageable) {
            const parent = EntityService.toReference(itemReportPageController.entity);

            let parameters = {
              academicYearId: sessionStorage.academicYear,
              entityType: itemReportPageController.entityType,
              typeCode: itemReportPageController.typeCode,
              parentType: _.get(parent, 'type'),
              parentId: _.get(parent, 'id')
            };

            const entityPath = EntityService.getEntityPath(itemReportPageController.entity);
            parameters[entityPath] = itemReportPageController.entity.id;

            return _.extend(pageable, parameters);
        }
    }
});
