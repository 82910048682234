'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Process
 * @description
 * # Process
 * Process resource of the uasApp
 */
angular.module('uasApp')
  .factory('ProcessNotification', function (UasResource) {
    return UasResource('/process-notification');
  });
