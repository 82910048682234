'use strict';


/**
 * @ngdoc function
 * @name uasApp.component:searchAdvanced
 * @description Search for entities with advanced parameters.
 */
 angular.module('uasApp')
    .component('searchAdvanced', {
        templateUrl: 'es6/search/search.advanced.html',
        controllerAs: 'searchController',
        controller: function (enumFilter, Pageable, Faculty, Study, Period, Enums, Search, EntityService, Language) {
            const searchController = this;

            searchController.$onInit = function() {
                searchController.model = {};
                searchController.pageable = Pageable.of({
                    order: 'code'
                });
    
                searchController.faculties = Faculty.query({
                    academicYearId: sessionStorage.academicYear
                });
    
                searchController.studies = Study.query({
                    academicYearId: sessionStorage.academicYear
                });
    
                searchController.periods = Period.query({
                    academicYearId: sessionStorage.academicYear
                });
    
                Enums.constants('RootType').$promise.then((constants) => {
                    searchController.types = _.map(constants, buildRootType);
                });

                setNameProperty();
                searchController.onLanguage = Language.onChange(setNameProperty);
            };

            searchController.$onDestroy = function() {
                searchController.onLanguage();
            };

            function setNameProperty() {
                searchController.nameProperty = Language.getPropertyName();
            }

            function buildRootType(constant) {
                return {
                    value: EntityService.getEntityType(constant),
                    label: enumFilter(constant)
                };
            }

            searchController.search = function() {
                const parameters = buildParameters();

                searchController.searching = true;
                Search.advanced(parameters).$promise.then((results) => {
                    searchController.results = results;
                }).finally(() => {
                    searchController.searching = false;
                });
            };

            function buildParameters() {
                const parameters = _.extend(searchController.model, {
                    academicYearId: sessionStorage.academicYear,
                    language: Language.get()
                });

                const relationId = _.result(searchController, 'relation.id');
                if (relationId) {
                    parameters.roleType = 'person';
                    parameters.relationId = relationId;
                } else {
                    delete parameters.roleType;
                    delete parameters.relationId;
                }

                if (_.isEmpty(parameters.type)) {
                    delete parameters.expression;
                }

                if (parameters.credits <= 0) {
                    delete parameters.credits;
                }

                return searchController.pageable.build(parameters);
            }
        }
    });