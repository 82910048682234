'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Studyable
 * @description The Studyable service.
 */
angular.module('uasApp')
    .factory('StudyCheck', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/study-check', {}, {});
    });