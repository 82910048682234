'use strict';

/**
 * @ngdoc component
 * @name uasApp.directive:items
 * @description
 * The items component.
 */
angular.module('uasApp').component('items', {
    templateUrl: 'es6/item/items.html',
    bindings: {
        entity: '<',
        operations: '<'
    },
    controllerAs: 'itemsController',
    controller: function(ItemType) {
        const itemsController = this;

        itemsController.$onInit = function() {
            loadData();
        };

        function loadData() {
            itemsController.loading = true;
            ItemType.query({
                entityType: itemsController.entity.type
            }).$promise.then((types) => {
                itemsController.types = types;
            }).finally(() => {
                itemsController.loading = false;
            });
        }
    }
});