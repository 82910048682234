'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Offering
 * @description
 * The Offering service.
 */
angular.module('uasApp').factory('Offering', function (UasResource) {
  return UasResource('/offerings', {
    'scheduled': {
      method: 'GET',
      url: '/offerings/scheduled',
      isArray: true
    },
    'calendars': {
      method: 'GET',
      url: '/offerings/calendars',
      isArray: true
    },
    'periods': {
      method: 'GET',
      url: '/offerings/periods',
      isArray: true
    },
    'weeks': {
      method: 'GET',
      url: '/offerings/:id/weeks',
      isArray: true
    },
    'studies': {
      method: 'GET',
      url: '/study-offerings',
      isArray: false
    },
    'report': {
      method: 'GET',
      url: '/offerings-report/',
      isArray: false
    }
  });
});
