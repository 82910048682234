'use strict';

angular.module('uasApp').component('resource', {
  templateUrl: 'es6/inventory/resource/resource.html',
  bindings: {
    resource: '<',
    operations: '<'
  },
  controllerAs: 'resourceController',
  controller: function ($q, $state, $uibModal, AuthService, CustomField, Message, Pageable, Resource, Reference, ResourceVersion, SecurityService) {
    const resourceController = this;

    resourceController.$onInit = function () {
      resourceController.operationsToEdit = ['EDIT_RESOURCES'];
      resourceController.pageable = Pageable.of({
        order: 'externalId'
      });

      resourceController.loading = true;
      $q.all([
        CustomField.query({
          rootType: 'resource',
          entityType: 'resource'
        }).$promise,
        Reference.query({
          typeCode: 'RESOURCE_TYPE'
        }).$promise
      ]).then(([fields, types]) => {
        resourceController.fields = fields;
        resourceController.types = types;
        AuthService.resecureAll();

        if (resourceController.resource.id) {
          resourceController.search();
        }
      }).finally(() => {
        resourceController.loading = false;
      });
    };

    resourceController.search = function () {
      const params = resourceController.pageable.build({
        resourceId: resourceController.resource.id
      });

      resourceController.loading = true;
      return ResourceVersion.page(params).$promise.then((versions) => {
        resourceController.versions = versions;
      }).finally(() => {
        resourceController.loading = false;
      });
    };
    
    function openVersionModal(version) {
      $uibModal.open({
        size: 'lg',
        templateUrl: 'es6/inventory/resource/resource.version.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            loadVersion().then((result) => {
              modalController.version = result;
              modalController.version.resourceId = resourceController.resource.id;
            });

            modalController.operations = resourceController.operations;

            modalController.editAdditionalAllowed = SecurityService.isAllowed('EDIT_ADDITIONAL', resourceController.operations);
            modalController.viewAdditionalAllowed = SecurityService.isAllowed(['VIEW_ADDITIONAL', 'EDIT_ADDITIONAL'], resourceController.operations);
            modalController.editResourceAllowed = SecurityService.isAllowed('EDIT_RESOURCES', resourceController.operations);

            loadFields();
          };

          function loadVersion() {
            if (version.id) {
              return ResourceVersion.get({ id: version.id }).$promise;
            }
            return $q.resolve(version);
          }

          function loadFields() {
            CustomField.query({
              rootType: 'resource',
              entityType: 'resource-version'
            }).$promise.then((fields) => {
              modalController.fields = fields;
            });
          }

          modalController.save = function () {
            ResourceVersion.save(modalController.version).$promise.then(() => {
              resourceController.search();
              Message.addSuccessLabel('Static.Message.DataAdjusted');
              $uibModalInstance.dismiss();
            });
          };
        }
      });
    }

    resourceController.addVersion = function () {
      openVersionModal({});
    };

    resourceController.editVersion = function (version) {
      openVersionModal(version);
    };

    resourceController.removeVersion = function (version) {
      ResourceVersion.remove(version).$promise.then(() => {
        resourceController.search();
        Message.addSuccessLabel('Static.Message.DataRemoved');
      });
    };

    resourceController.save = function (form) {
      return Resource.save(resourceController.resource).$promise.then((resource) => {
        form.$setPristine();
        $state.go('resource', { id: resource.id });
        Message.addSuccessLabel('Static.Message.DataAdjusted');
      });
    };
  }
});