'use strict';

angular.module('uasApp').component('debugOnly', {
  bindings: {
    viewClasses: '<?'
  },
  templateUrl: 'es6/app/debug/debug.only.html',
  controllerAs: 'debugOnlyController',
  transclude: true,
  controller: function (Debug) {
    const debugOnlyController = this;

    debugOnlyController.$onInit = function () {
      debugOnlyController.onDebug = Debug.onChange(setDebug);
      setDebug();
    };

    function setDebug() {
      debugOnlyController.debug = Debug.isDebug();
    }

    debugOnlyController.$onDestroy = function () {
      debugOnlyController.onDebug();
    };
  }
});
