'use strict';

angular.module('uasApp').component('processTypeSelect', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    academicYearId: '<?',
    isReadOnly: '<?',
    rootType: '<?'
  },
  templateUrl: 'es6/process/process.type.select.html',
  controllerAs: 'processTypeSelectController',
  controller: function (ProcessType) {
    const processTypeSelectController = this;

    processTypeSelectController.$onInit = function () {
      processTypeSelectController.ngModelCtrl.$render = () => {
        processTypeSelectController.value = processTypeSelectController.ngModelCtrl.$modelValue;
      };

      ProcessType.query({
        academicYearId: processTypeSelectController.academicYearId,
        rootType: processTypeSelectController.rootType
      }).$promise.then((processTypes) => {
        processTypeSelectController.processTypes = _(processTypes).map((processType) => {
          processType.label = ProcessType.getLabel(processType);
          return processType;
        }).sortBy('label').value();
      });
    };

    processTypeSelectController.onChange = function () {
      processTypeSelectController.ngModelCtrl.$setViewValue(processTypeSelectController.value);
    };
  }
});
