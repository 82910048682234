'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:weeks
 * @description Displays the calendar weeks or period weeks depending on the system parameter calendar.weeks.
 */
angular.module('uasApp').component('weeks', {
  bindings: {
    weeks: '<'
  },
  templateUrl: 'es6/calendar/week/weeks.html',
  controllerAs: 'weeksController',
  controller: function (Parameter) {
    const weeksController = this;

    weeksController.$onInit = function () {
      Parameter.load().then(() => {
        const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
        const property = calendarWeeks ? 'yearWeek' : 'week';

        weeksController.displayWeeks = _(weeksController.weeks)
          .map((week) => week[property])
          .filter((displayValue) => !!displayValue)
          .value();
      });
    };
  }
});
