'use strict';

angular.module('uasApp').component('currentDateTime', {
  templateUrl: 'es6/app/current.date.time.html',
  controllerAs: 'dateTimeController',
  controller: function (configDateTimeFilter) {
    const dateTimeController = this;

    dateTimeController.$onInit = function () {
      dateTimeController.formattedDate = configDateTimeFilter(new Date());
    };
  }
});
