'use strict';

angular.module('uasApp').component('tooltipIcon', {
  template: `
    <i class="fa fa-info-circle text-primary"
       uib-tooltip="{{ tooltipIconController.tooltip }}"
       tooltip-placement="right">
    </i>
  `,
  bindings: {
    label: '@'
  },
  controllerAs: 'tooltipIconController',
  controller: function (translateFilter) {
    const tooltipIconController = this;
    tooltipIconController.$onInit = function() {
        tooltipIconController.tooltip = translateFilter(tooltipIconController.label);
    };
  }
});