'use strict';

angular.module('uasApp').component('processExpanderPage', {
    templateUrl: 'es6/process/progress/process.expander.page.html',
    bindings: {
        entity: '<',
        process: '<?',
        page: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    controllerAs: 'processExpanderPageController',
    controller: function (i18nFilter, entityTranslateFilter, Page, Process, EntityService, Language) {
        const processExpanderPageController = this;

        processExpanderPageController.$onInit = function () {
            processExpanderPageController.processId = _.result(processExpanderPageController.process, 'id');
            processExpanderPageController.readOnly = processExpanderPageController.isReadOnly || Page.getParameterAsBoolean(processExpanderPageController.page, 'readOnly', false);
            processExpanderPageController.onLanguage = Language.onChange(loadData);
        };

        processExpanderPageController.$onDestroy = function () {
            processExpanderPageController.onLanguage();
        };

        processExpanderPageController.$onChanges = function () {
            loadData();
        };

        function loadData() {
            const entityType = _.result(processExpanderPageController.entity, 'self.type');
            processExpanderPageController.loading = true;
            Process.query({
                academicYearId: sessionStorage.academicYear,
                rootType: EntityService.getRootType(entityType)
            }).$promise.then((processes) => {
                processExpanderPageController.processes = _(processes)
                    .sortBy(['type.sequence', 'period.startDate', 'period.endDate', 'id'])
                    .map(build)
                    .value();

                if (angular.isDefined(processExpanderPageController.processId)) {
                    processExpanderPageController.onProcess(processExpanderPageController.processId);
                } else if (processes.length === 1) {
                    processExpanderPageController.processId = _(processes).map('id').first();
                    processExpanderPageController.onProcess(processExpanderPageController.processId);
                }
            }).finally(() => {
                processExpanderPageController.loading = false;
            });
        }

        function build(process) {
            let name = `${i18nFilter(process.type.labels)}`;
            if (angular.isDefined(process.period)) {
                name += ` - ${entityTranslateFilter(process.period)}`;
            }

            return _.extend(process, { name });
        }

        processExpanderPageController.onProcess = function(processId) {
            processExpanderPageController.process = _.find(processExpanderPageController.processes, { id: processId });
        };
    }
});
