'use strict';

/**
 * @ngdoc directive
 * @name uasApp.component:customerStyle
 * @description Adds a <style> with style presumed to be loaded in app.js
 */
angular.module('uasApp').component('customerStyle', {
    templateUrl: 'es6/app/app-owner/customer.style.html',
    transclude: true,
    controllerAs: 'customerStyleController',
    controller: function(Customer, Language, $rootScope) {
        const customerStyleController = this;

        customerStyleController.$onInit = function() {
            customerStyleController.onLanguage = Language.onChange(setClasses);
            setClasses();
        };

        customerStyleController.$onDestroy = function() {
            setClasses();
        };

        function setClasses() {
            customerStyleController.classes = getClasses($rootScope.environment);
        }

        function getClasses(environment) {
            let classes = [];

            const language = Language.get();
            if (language) {
                classes.push(`customer-body-lang-${language.toLowerCase()}`);
            }

            const name = _.get(environment, 'name', 'production');
            if (name) {
                classes.push(`customer-body-${name.toLowerCase()}`);
            }

            const alert = _.get(environment, 'alert', false);
            if (alert === true) {
                classes.push('customer-body-alert');
            }

            return classes;
        }
    }
});
