'use strict';

angular.module('uasApp').component('taskEdit', {
    bindings: {
        task: '<',
        templates: '<',
        correctable: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/staffing/task/task.edit.html',
    controllerAs: 'taskEditController',
    controller: function(Task, Relation, Period) {
        const taskEditController = this;

        taskEditController.$onInit = function() {
            Period.query({
                academicYearId: sessionStorage.academicYear,
                planning: true
            }).$promise.then((periods) => {
                taskEditController.periods = periods;
            });
        };

        taskEditController.$onChanges = function() {
            setFields();
        };

        taskEditController.onTemplate = function() {
            delete taskEditController.task.periodId;
            taskEditController.task.entity = {};

            setFields();
        };

        function setFields() {
            const template = _.find(taskEditController.templates, {
                id: taskEditController.task.templateId
            });

            taskEditController.template = template;
        }

        taskEditController.save = function() {
            if (angular.isUndefined(taskEditController.task.id) && angular.isDefined(taskEditController.template.role)) {
                addRelation().then(taskEditController.onSave);
            } else {
                Task.store(taskEditController.task).$promise.then(taskEditController.onSave);
            }
        };

        function addRelation() {
            return Relation.save({
                comment: {
                    message: ''
                },
                content: {
                    roleId: taskEditController.template.role.id,
                    assignmentId: taskEditController.task.assignmentId,
                    entity: taskEditController.task.entity,
                    target: taskEditController.task.target
                }
            }).$promise;
        }

        taskEditController.close = function() {
            taskEditController.onClose();
        };
    }
});