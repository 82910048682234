'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Events
 * @description Provides methods to handle DOM events
 */
angular.module('uasApp').factory('Events', function() {
  /**
   * @ngdoc method
   * @description Checks if the clicked element is a clickable target (link or non-expand button).
   * @returns {Boolean} true if it is, false otherwise.
   */
  function isClickableTarget($event) {
    const clickableTargets = findClickableElement($event);
    return !_.isEmpty(clickableTargets);
  }

  function findClickableElement($event) {
    return $event.target.closest('a, button:not(expand-button > button), .btn');
  }

  return { isClickableTarget };
});
