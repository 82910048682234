'use strict';

angular.module('uasApp').config(function ($stateProvider) {
  $stateProvider.state('reference-types', {
    url: '/reference-types',
    template: '<reference-type-list></reference-type-list>',
    breadcrumb: {
      label: 'Static.Page.ReferenceTypes',
      translate: true,
      root: true
    },
    admin: true
  });

  $stateProvider.state('reference-type', {
    url: '/reference-types/:id',
    template: '<reference-type-tabs type="tabsController.type"></reference-type-tabs>',
    abstract: true,
    resolve: {
      type: function ($stateParams, ReferenceType) {
        return ReferenceType.get({ id: $stateParams.id }).$promise;
      }
    },
    controllerAs: 'tabsController',
    controller: function (type) {
      this.type = type;
    },
    breadcrumb: {},
    admin: true
  });

  $stateProvider.state('reference-type.type', {
    url: '/type',
    views: {
      'tab': {
        controllerAs: 'typeController',
        controller: function (type) {
          this.type = type;
        },
        template: '<reference-type type="typeController.type"></reference-type>'
      }
    },
    breadcrumb: {},
    admin: true
  });

  $stateProvider.state('reference-type.lists', {
    url: '/lists',
    views: {
      'tab': {
        controllerAs: 'listsController',
        controller: function (type) {
          this.type = type;
        },
        template: '<reference-lists-items reference-type="listsController.type"></reference-lists-items>'
      }
    },
    breadcrumb: {},
    admin: true
  });
});
