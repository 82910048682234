'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Feedback
 * @description
 * The Feedback service.
 */
angular.module('uasApp')
    .factory('Feedback', function(UasResource) {
        return UasResource('/feedback', {
            'full': {
                method: 'GET',
                url: '/feedback/full',
                isArray: true
            },
            'code': {
                method: 'GET',
                url: '/feedback/code',
                isArray: false
            }
        });
    });
