'use strict';

angular.module('uasApp').component('objectiveReport', {
  bindings: {
    entity: '<',
    typeId: '<',
    operations: '<'
  },
  templateUrl: 'es6/objective/report/objective.report.html',
  controllerAs: 'objectiveReportController',
  controller: function (Objective, Language, EntityService) {
    const objectiveReportController = this;

    objectiveReportController.$onInit = function () {
      const entityType = EntityService.getType(objectiveReportController.entity);
      objectiveReportController.context = `${entityType}.objectives`;

      objectiveReportController.onLanguage = Language.onChange(loadData);
      loadData();
    };

    objectiveReportController.$onDestroy = function () {
      objectiveReportController.onLanguage();
    };

    function loadData() {
      objectiveReportController.loading = true;
      Objective.report({
        definition: true
      }).$promise.then((report) => {
        objectiveReportController.columns = report.headers;
        objectiveReportController.groups = report.groups;
      }).finally(() => {
        objectiveReportController.loading = false;
      });
    }

    objectiveReportController.getRows = function (pageable) {
      const parameters = getParameters(pageable);
      return Objective.report(parameters).$promise;
    };

    function getParameters(pageable) {
      let parameters = {
        academicYearId: sessionStorage.academicYear,
        typeId: objectiveReportController.typeId
      };

      const entityPath = EntityService.getEntityPath(objectiveReportController.entity);
      parameters[entityPath] = objectiveReportController.entity.id;

      return _.extend(pageable, parameters);
    }
  }
});