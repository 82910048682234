'use strict';

angular.module('uasApp').component('previewPage', {
    templateUrl: 'es6/page/render/preview.page.html',
    bindings: {
        entity: '<',
        period: '<?',
        operations: '<',
        hideHelp: '<?'
    },
    controllerAs: 'previewPageController',
    controller: function(Page, EntityService) {
        const previewPageController = this;

        previewPageController.$onInit = function() {
            previewPageController.rootType = EntityService.getRootType(previewPageController.entity);
        };

        previewPageController.onPage = function() {
            previewPageController.loading = true;
            Page.find(previewPageController.pageId).$promise.then((page) => {
                previewPageController.page = page;
            }).finally(() => {
                previewPageController.loading = false;
            });
        };
    }
});