'use strict';

/**
 * Input elements with this directive will not cause the form to be
 * dirty when they are changed.
 */
angular.module('uasApp').directive('noDirtyCheck', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, ngModelCtrl) {
      const alwaysFalse = {
        get: function () { return false; },
        set: _.noop
      };
      Object.defineProperty(ngModelCtrl, '$pristine', alwaysFalse);
      Object.defineProperty(ngModelCtrl, '$dirty', alwaysFalse);
    }
  };
});
