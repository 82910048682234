'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityReportPage
 * @description
 * The activityReportPage component.
 */
angular.module('uasApp').component('activityReportPage', {
    templateUrl: 'es6/schedule/activity/report/activity.report.page.html',
    bindings: {
        entity: '<',
        period: '<',
        operations: '<',
        page: '<'
    },
    controllerAs: 'activityReportPageController',
    controller: function (Language, Page, EntityService, Activity) {
        const activityReportPageController = this;

        activityReportPageController.$onInit = function() {
            activityReportPageController.context = `${activityReportPageController.entity.self.type}.activities`;
            activityReportPageController.exam = Page.getParameterAsBoolean(activityReportPageController.page, 'exam', false);
            
            activityReportPageController.defaultTemplate = Page.getParameter(activityReportPageController.page, 'defaultTemplate');
            activityReportPageController.hideReportTemplates = Page.getParameterAsBoolean(activityReportPageController.page, 'hideReportTemplates', false);

            activityReportPageController.onLanguage = Language.onChange(loadData);
            loadData();
        };

        activityReportPageController.$onDestroy = function() {
            activityReportPageController.onLanguage();
        };

        function loadData() {
            activityReportPageController.loading = true;
            Activity.report({
                definition: true,
                exam: activityReportPageController.exam
            }).$promise.then((report) => {
                activityReportPageController.columns = report.headers;
                activityReportPageController.groups = report.groups;
            }).finally(() => {
                activityReportPageController.loading = false;
            });
        }

        activityReportPageController.getRows = function(pageable) {
            const parameters = getParameters(pageable);
            return Activity.report(parameters).$promise;
        };

        function getParameters(pageable) {
            let parameters = {
                academicYearId: sessionStorage.academicYear,
                exam: activityReportPageController.exam
            };

            const entityPath = EntityService.getEntityPath(activityReportPageController.entity);
            parameters[entityPath] = activityReportPageController.entity.id;

            return _.extend(pageable, parameters);
        }
    }
});
