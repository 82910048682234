'use strict';

angular.module('uasApp').factory('Page', function(UasResource, SecurityService, CustomField, PAGES) {
    const instance = UasResource('/page', {
        parents: {
            method: 'GET',
            url: '/page/:id/parents',
            isArray: true
        },
        children: {
            method: 'GET',
            url: '/page/:id/children',
            isArray: true
        },
        labels: {
            method: 'GET',
            url: '/page/:id/labels',
            isArray: true
        },
        items: {
            method: 'GET',
            url: '/page/:id/submit',
            isArray: true
        },
        message: {
            method: 'GET',
            url: '/page/:id/message',
            isArray: false
        },
        submit: {
            method: 'POST',
            url: '/page/submit'
        },
        batch: {
            method: 'POST',
            url: '/page/batch',
            isArray: true
        },
        generate: {
            method: 'POST',
            url: '/page/generate',
            isArray: false
        }
    });

    function getParameter(page, name, defaultValue) {
        const parameter = _.find(page.parameters, {
            name: name
        });

        const value = _.get(parameter, 'value', '');
        return value || defaultValue;
    }

    function getParameterAsArray(page, name, defaultValue = '', convertFn) {
        const value = getParameter(page, name, defaultValue);
        return asArray(value, convertFn);
    }

    function asArray(value, convertFn) {
        const convert = _.isFunction(convertFn) ? convertFn : _.identity;
        return _(value.split(','))
            .map((fragment) => fragment.trim())
            .filter((fragment) => !_.isEmpty(fragment))
            .map(convert)
            .value();
    }

    function getParameterAsBoolean(page, name, defaultValue) {
        let defaultValue_;
        if (angular.isDefined(defaultValue)) {
          defaultValue_ = _.toString(defaultValue);
        } else {
          defaultValue_ = getDefaultValueFromConstants(page, name);
        }

        const value = getParameter(page, name, defaultValue_);
        if (angular.isDefined(value)) {
            return value === 'true';
        }
    }

    function getDefaultValueFromConstants(page, name) {
        const foundPage = _.find(PAGES, { name: page.route });
        if (foundPage) {
            const foundParameter = _.find(foundPage.parameters, { name: name });
            if (foundParameter) {
                const defaultValue = _.get(foundParameter, 'defaultValue');
                if (angular.isDefined(defaultValue)) {
                    return _.toString(defaultValue);
                }
            }
        }

        return '';
    }

    function getParameterAsInt(page, name, defaultValue) {
        const value = getParameter(page, name, defaultValue);
        if (angular.isDefined(value)) {
            return parseInt(value);
        }
    }

    function build(route) {
        const page = _.find(PAGES, { name: route });
        if (angular.isDefined(page)) {
            return {
                route
            };
        }
    }

    function getItems(args) {
        const { items, entity, root, operationsToEdit, operations } = args;

        return _(items)
            .filter((item) => angular.isDefined(item.field))
            .filter((item) => item.field.visible && (!item.field.condition || item.field.evaluated))
            .filter((item) => CustomField.isEditable(item.field, entity || {}, root))
            .filter((item) => SecurityService.isAllowed(item.field.operation || operationsToEdit, operations))
            .map((item) => ({
                customFieldId: item.field.id,
                value: item.values
            }))
            .value();
    }

    function getEvaluation(entity, items) {
        const properties = getProperties(items);
        const fields = _.map(items, 'field');

        return CustomField.getEvaluation(entity, properties, fields);
    }

    function getProperties(items) {
        let properties = {};
        _.forEach(items, (item) => {
            properties[item.field.name] = item.values;
        });
        return properties;
    }

    return _.extend(instance, {
        build,
        getParameter,
        getParameterAsArray,
        getParameterAsBoolean,
        getParameterAsInt,
        getEvaluation,
        getItems,
        asArray
    });
});
