'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:organisationReport
 * @description
 * # organisationReport
 * Faculty report shows all organisations.
 */
angular.module('uasApp').component('organisationReport', {
    bindings: {
        faculty: '<?',
        operations: '<?'
    },
    templateUrl: 'es6/organisation/organisation.report.html',
    controllerAs: 'organisationReportController',
    controller: function ($state, Changes, CreateManager, Language, Report) {
        const organisationReportController = this;

        organisationReportController.$onInit = function () {
            Language.onChange(loadData);

            loadData().then(() => {
                organisationReportController.isInitalised = true;
            });
        };

        organisationReportController.$onChanges = function (changes) {
            if (organisationReportController.isInitalised && Changes.hasChanged(changes, 'faculty')) {
              loadData();
            }
        };

        function loadData() {
            organisationReportController.loading = true;
            setName();

            return Report.organisations({
                facultyId: _.get(organisationReportController.faculty, 'id'),
                language: Language.get()
            }).$promise.then((report) => {
                organisationReportController.report = report;
            }).finally(() => {
                organisationReportController.loading = false;
            });
        }

        function setName() {
            const defaultName = 'organisations';

            if (organisationReportController.faculty) {
                organisationReportController.name = `${organisationReportController.faculty.code}-${defaultName}`;
            } else {
                organisationReportController.name = defaultName;
            }
        }

        organisationReportController.create = function () {
            const organisation = {
                facultyId: _.get(organisationReportController.faculty, 'id'),
                academicYearId: sessionStorage.academicYear
            };

            CreateManager.open('organisation', organisation, organisationReportController.operations, () => {
                $state.reload();
            });
        };
    }
});
