'use strict';

angular.module('uasApp').component('facultyProcessList', {
    templateUrl: 'es6/process/faculty/faculty.process.list.html',
    bindings: {
        process: '<'
    },
    controllerAs: 'facultyProcessListController',
    controller: function($q, $uibModal, FacultyProcess, Faculty) {
        const facultyProcessListController = this;
        facultyProcessListController.$onInit = loadData;

        function loadData() {
            facultyProcessListController.loading = true;

            $q.all([
                FacultyProcess.query({
                    processId: facultyProcessListController.process.id
                }).$promise,
                Faculty.query({ 
                    academicYearId: facultyProcessListController.process.academicYearId 
                }).$promise
            ]).then(([processes, faculties]) => {
                facultyProcessListController.faculties = _.map(faculties, (faculty) => {
                    const process = _.find(processes, {
                        facultyId: faculty.id
                    });

                    if (angular.isDefined(process)) {
                        faculty.process = process;
                    } else {
                        faculty.process = {
                            facultyId: faculty.id,
                            processId: facultyProcessListController.process.id
                        };
                    }
                    
                    return faculty;
                });
            }).finally(() => {
                facultyProcessListController.loading = false;
            });
        }

        facultyProcessListController.edit = function(faculty) {
            $uibModal.open({
                size: 'lg',
                controllerAs: 'facultyProcessModalController',
                controller: function ($uibModalInstance) {
                    const facultyProcessModalController = this;
                    facultyProcessModalController.instance = $uibModalInstance;
                    facultyProcessModalController.faculty = faculty;
                    facultyProcessModalController.process = facultyProcessListController.process;
                    facultyProcessModalController.data = angular.copy(faculty.process);
                    facultyProcessModalController.onChange = function(result) {
                        faculty.process = result;
                    };
                },
                template: `
                    <faculty-process-edit 
                      instance="facultyProcessModalController.instance"
                      faculty="facultyProcessModalController.faculty"
                      process="facultyProcessModalController.process"
                      data="facultyProcessModalController.data"
                      on-change="facultyProcessModalController.onChange(result)">
                    </faculty-process-edit>
                `
            }).result.then(loadData);
        };

    }

});
