'use strict';

/**
 * Source: https://gist.github.com/tommaitland/7579618
 * Updates the ViewValue of an input element after a given number of ms.
 */
angular.module('uasApp')
    .directive('uasDebounce', function($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            priority: 99,
            link: function(scope, elm, attr, ngModelCtrl) {
                if (attr.type === 'radio' || attr.type === 'checkbox') {
                    return;
                }

                elm.unbind('input');

                var debounce;
                elm.bind('input', function() {
                    $timeout.cancel(debounce);
                    debounce = $timeout(function() {
                        scope.$apply(function() {

                            ngModelCtrl.$setViewValue(elm.val());
                        });
                    }, attr.debounce || 1000);
                });
                elm.bind('blur', function() {
                    scope.$apply(function() {
                        ngModelCtrl.$setViewValue(elm.val());
                    });
                });
            }

        };
    });
