'use strict';

angular.module('uasApp')
    .component('feedback', {
            transclude: true,
            templateUrl: 'es6/changes/feedback.html',
            bindings: {
                entityType: '@',
                entityId: '<',
                full: '@?',
                inline: '@?',
                historyMode: '<?',
                operations: '<',
                retrieveFromRoot: '<?'
            },
            controllerAs: 'feedbackController',
            controller: function($timeout, $q, $document, EntityHolder, EntityTypes, Feedback, Language, SecurityService, feedbackObserver) {
                const feedbackController = this;

                feedbackController.$onInit = function() {
                    feedbackController.tab = 0;

                    if (angular.isUndefined(feedbackController.entityId)) {
                        feedbackController.authorized = false;
                    } else {
                        feedbackController.authorized = SecurityService.isAllowed('VIEW_FEEDBACK', feedbackController.operations);
                    }

                    feedbackController.status = true;
                    feedbackController.hide = localStorage.getItem('feedback.hide') === 'true';

                    feedbackObserver.onDataChanged(loadData);

                    feedbackController.openChanges = true;
                    feedbackController.searchParams = {
                        entityType: feedbackController.entityType,
                        entityId: feedbackController.entityId,
                        hideEmpty: true,
                        language: Language.get(),
                        root: angular.isUndefined(feedbackController.retrieveFromRoot) ? true : feedbackController.retrieveFromRoot,
                        status: feedbackController.openChanges ? 'OPEN' : undefined
                    };
                    loadData();
                };

                feedbackController.$onDestroy = function() {
                    feedbackObserver.onDataChanged(null);
                };

                function loadData() {
                    if (feedbackController.authorized === true) {
                        feedbackController.loading = true;

                        $q.all([EntityTypes.load(), Feedback.get(feedbackController.searchParams).$promise])
                        .then(([entityTypes, feedback]) => {
                            feedbackController.entityTypes = entityTypes;
                            feedbackController.comments = feedback.comments || [];

                            _.forEach(feedback.comments, (comment) => {
                                comment.status = true;
                            });

                            _.forEach(feedback.statuses, (status) => {
                                if (status.comment && status.comment.message) {
                                    const comment = status.comment;
                                    comment.status = status.status;
                                    feedbackController.comments.push(comment);
                                }
                            });

                            feedbackController.changes = feedback.changes;
                            _.forEach(feedbackController.changes, (change) => {
                                change.isRoot = change.change.entity.type === feedbackController.entityType;
                                if (change.type) {
                                    change.change.type = change.type;
                                }
                            });

                            scrollToElement();
                        }).finally(() => {
                            feedbackController.loading = false;
                            if (feedbackController.historyMode === true) {
                                feedbackController.tab = 1;
                            }
                        });
                    }
                }

                feedbackController.onToggleOpenChanges = function() {
                    delete feedbackController.searchParams.status;
                    if (feedbackController.openChanges) {
                        feedbackController.searchParams.status = 'OPEN';
                    }
                    loadData();
                };

                /**
                 * Toggles the visibility of the feedback.
                 */
                feedbackController.toggle = function() {
                    feedbackController.hide = !feedbackController.hide;
                    localStorage.setItem('feedback.hide', '' + feedbackController.hide);
                };

                function scrollToElement() {
                    const current = EntityHolder.get();
                    const type = _.result(current, 'type');

                    if (type === 'change') {
                        feedbackController.tab = 1;
                        const found = findIndividualChange(current.id);
                        if (found) {
                            found.container.status = true;
                            found.individual.comment.selected = true;

                            scrollToComment(found.individual.comment.id);
                        }
                    }
                }

                function findIndividualChange(changeId) {
                    const search = {
                        change: {
                            id: changeId
                        }
                    };

                    return _(feedbackController.changes)
                        .filter((change) => {
                            return _.find(change.individuals, search);
                        })
                        .map((change) => ({
                            container: change,
                            individual: _.find(change.individuals, search)
                        }))
                        .head();
                }

                function scrollToComment(commentId) {
                    // Scroll to comment after the dom has rendered
                    $timeout(() => {
                        const id = 'reply-' + commentId;
                        $document[0].getElementById(id).scrollIntoView(true);
                    });
                    EntityHolder.set(null);
                }

                this.sendReview = function(merge, complete, ok, comment) {
                    feedbackController.sendReview(merge, complete, ok, comment, undefined);
                };
            }
    });
