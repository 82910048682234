'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasOptions
 * @description
 * Select options for elements.
 */
angular.module('uasApp').component('uasOptions', {
  bindings: {
    inputId: '@?',
    classes: '@?',
    elements: '<',
    tagsPlacement: '@?',
    toggleLabel: '@?',
    toggleIcon: '@?',
    dropdownAlign: '@?',
    dropdownClasses: '@?',
    dropdownLabel: '@?',
    dropdownInModal: '<?',
    dropdownIsOpen: '<?',
    dropdownHasActions: '<?',
    onToggleDropdown: '&?',
    groups: '<?',
    searchLabel: '@?',
    selectedOptionsLabel: '@?',
    availableOptionsLabel: '@?',
    onChange: '&?',
    uasDisabled: '<?',
    uasRequired: '<?',
    isReadOnly: '<?',
    isReorderable: '<?',
    viewClasses: '@?',
    displayType: '<?',
    excludeIds: '<?', // Ids that should be excluded from options
    multipleValues: '<?',
    formatValue: '&',
    setNames: '&',
    arrayModel: '<?', // Indicates if the ng-model is an array or a single value. Default = false
    referenceLists: '<?',
    evaluation: '<?',
    minItems: '<?',
    maxItems: '<?',
    sort: '<?', // Array of sort properties for unselected elements. Default: ['sequence', 'name']
    selectedSort: '<?', // Array of sort properties for selected elements. Default: ['sequence', 'name']
    truncate: '<?' // If true read-only values will be truncated with ellipsis and tooltip on hover when content is too large
  },
  require: {
    ngModelCtrl: '^ngModel',
    securedCtrl: '?^secured'
  },
  transclude: true,
  templateUrl: 'es6/app/options.html',
  controllerAs: 'optionsController',
  controller: function ($q, $scope, $element, Expression, Language, CustomField) {
    const optionsController = this;

    optionsController.$onInit = function () {
      optionsController.ngModelCtrl.$validators.minItems = (modelValue) => {
        return !optionsController.minItems || _.size(modelValue) >= optionsController.minItems;
      };
      optionsController.ngModelCtrl.$validators.maxItems = (modelValue) => {
        return !optionsController.maxItems || _.size(modelValue) <= optionsController.maxItems;
      };
      optionsController.ngModelCtrl.$validators.required = (modelValue) => {
        return !optionsController.required || CustomField.hasValue(modelValue);
      };
      // After adding the validators we should validate to initialize the model validity
      optionsController.ngModelCtrl.$validate();
    };

    optionsController.$onChanges = function () {
      optionsController.disabled = optionsController.uasDisabled || false;
      optionsController.required = optionsController.uasRequired || false;
      optionsController.arrayModel = optionsController.multipleValues || optionsController.arrayModel || false;

      if (optionsController.isReadOnly || optionsController.disabled) {
        optionsController.ngModelCtrl.$setDirty = _.noop;
      }

      if (optionsController.elements) {
        getElements(optionsController.elements, optionsController.referenceLists).then((elements) => {
          optionsController.activeElements = filterElements(angular.copy(elements));

          setCurrentValue();
          $scope.$watch('optionsController.ngModelCtrl.$modelValue', setCurrentValue);

          setNames();
          Language.onChange(setNames);

          setSelectedValuesInModel();
          secure();
        });
      }

      setSort();
    };

    function setSort() {
      optionsController.sort_ = ['sequence', 'name'];
      if (optionsController.sort) {
        optionsController.sort_ = optionsController.sort;
      }

      optionsController.selectedSort_ = optionsController.sort_;
      if (optionsController.selectedSort) {
        optionsController.selectedSort_ = optionsController.selectedSort;
      }
    }

    function getElements(elements, lists) {
      if (!optionsController.evaluation) {
        return $q.resolve(elements);
      }

      if (!_.isEmpty(lists)) {
        return getElementsFromLists(lists, elements);
      } else {
        return $q.resolve(elements);
      }
    }

    function getElementsFromLists(lists, elements) {
      return evaluate(lists).then((evaluatedLists) => {
        delete optionsController.evaluation.evaluationId;
        const referenceIds = _(evaluatedLists)
          .filter({ match: true })
          .map((list) => list.items)
          .flatten()
          .map((item) => item.referenceId)
          .uniq()
          .value();
        if (!_.isEmpty(referenceIds)) {
          return _.filter(elements, (element) =>
            _.includes(referenceIds, element.id)
          );
        }
        return elements;
      });
    }

    function evaluate(lists) {
      const promises = _.map(lists, (list) => {
        if (!_.isEmpty(list.condition)) {
          const evaluation = angular.copy(optionsController.evaluation);
          evaluation.expression = list.condition;
          evaluation.academicYearId = sessionStorage.academicYear;

          return Expression.evaluate(evaluation).$promise.then((result) => {
            list.match = result.value === true;
            return list;
          });
        }

        list.match = true;
        return $q.resolve(list);
      });

      return $q.all(promises);
    }

    function setNames() {
      optionsController.setNames({ elements: optionsController.activeElements });
    }

    /**
     * Set selected values in model if they differ from model values.
     * This can occur if one or more model values are not active anymore.
     */
    function setSelectedValuesInModel() {
      if (!optionsController.isReadOnly) {
        const selectedValues = getSelectedValues();

        if (!equalToModel(selectedValues)) {
          setViewValue(selectedValues);

          if (_.isEmpty(selectedValues) && optionsController.required) {
            // This will set possible default values
            optionsController.ngModelCtrl.$setPristine();
          }
        }
      }
    }

    function equalToModel(values) {
      if (optionsController.arrayModel) {
        return _.difference(optionsController.ngModelCtrl.$modelValue, values).length === 0;
      }

      return values === optionsController.ngModelCtrl.$modelValue;
    }

    function filterElements(elements) {
      const activeElements = _.filter(elements, (element) => !_.includes(optionsController.excludeIds, element.id));

      // Guard against ng-repeat duplicates due to race conditions, swift model updates, etc.
       return _.uniqBy(activeElements, 'id');
    }

    function setCurrentValue() {
      const modelValues = getModelValues();
      optionsController.modelValues = modelValues;
      optionsController.currentValues = _(optionsController.activeElements)
        .map((element) => [element.id, {
          id: element.id,
          selected: _.includes(modelValues, element.id),
          element: element
        }])
        .fromPairs()
        .value();

      if (!optionsController.multipleValues) {
        delete optionsController.current;

        const elementId = _.get(modelValues, 0);
        if (elementId) {
          optionsController.current = _.get(optionsController.currentValues, `${elementId}.element`);
        }
      }

      setDefaultValue(modelValues);
    }

    function getModelValues() {
      let modelValues = _.get(optionsController.ngModelCtrl, '$modelValue', []);
      if (!_.isArray(modelValues) && modelValues) {
        modelValues = [modelValues];
      }
      return _.map(modelValues, (value) => parseInt(value));
    }

    function setDefaultValue(modelValues) {
      if (_.isEmpty(modelValues) && optionsController.required &&
        !optionsController.isReadOnly && !optionsController.disabled && !optionsController.ngModelCtrl.$dirty) {
        let defaultValues = _(optionsController.activeElements)
          .filter({ defaultSelected: true })
          .sortBy(['sequence', 'id'])
          .map('id')
          .value();
        if (!optionsController.multipleValues && _.size(defaultValues) > 1) {
          defaultValues = _.pullAt(defaultValues, 0);
        }
        _.forEach(defaultValues, (value) => optionsController.setSelected(value, true));
      }
    }

    function secure() {
      // Secure options after they have been rendered
      if (optionsController.securedCtrl) {
        optionsController.securedCtrl.resecure($element);
      }
    }

    optionsController.setSelected = function (elementId, selected) {
      if (angular.isDefined(elementId)) {
        optionsController.currentValues[elementId].selected = selected;
      }

      optionsController.onChangeInternal(elementId);
    };

    optionsController.onChangeInternal = function (elementId) {
      if (!optionsController.multipleValues) {
        _.values(optionsController.currentValues)
          .filter((value) => value.id !== elementId)
          .forEach((value) => value.selected = false);
      }

      updateSelectedValues();
    };

    function updateSelectedValues() {
      const selectedValues = getSelectedValues();
      setViewValue(selectedValues);
    }

    function getSelectedValues() {
      return _.values(optionsController.currentValues)
        .filter((value) => value.selected)
        .map((value) => value.id);
    }

    function setViewValue(values) {
      const copy = angular.copy(values);
      if (optionsController.arrayModel) {
        optionsController.ngModelCtrl.$setViewValue(copy);
      } else {
        optionsController.ngModelCtrl.$setViewValue(copy[0]);
      }

      if (_.isFunction(optionsController.onChange)) {
        optionsController.onChange({ values: copy });
      }
    }

    optionsController.setViewValue = function(values) {
      setViewValue(values);
    };

    optionsController.removeValue = function (elementId) {
      delete optionsController.current;
      optionsController.setSelected(elementId, false);
    };
  }
});
