'use strict';

angular.module('uasApp')
    .directive('showField', function() {
        function update(element, visible, readOnly) {
            element.attr('style', visible ? '' : 'display:none;');

            if (readOnly) {
                const input = element.find('input');
                input.attr('readonly', 'readonly');
            }
        }

        return {
            scope: {
                showField: '@',
                fields: '=',
                property: '@?'
            },
            restrict: 'A',
            replace: false,
            link: function(scope, element) {
                const name = scope.showField;
                const property = scope.property || 'visible';

                perform();
                scope.$watch('fields', perform);

                function perform() {
                    const field = getField();

                    if (angular.isDefined(field)) {
                        const visible = _.result(field, property, true);
                        const readOnly = _.result(field, 'readOnly', false);
                        update(element, visible, readOnly);
                    } else {
                        const defaultVisible = element.attr('default-visible') !== 'false';
                        update(element, defaultVisible, false);
                    }
                }

                function getField() {
                    if (_.isArray(scope.fields)) {
                        return _.find(scope.fields, { name });
                    }

                    return _.result(scope.fields, name);
                }
            }
        };
    });
