'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Worklog
 * @description
 * The Worklog service.
 */
angular.module('uasApp')
  .factory('Worklog', function (UasResource) {
    return UasResource('/worklogs', {
      'reset': {
        method: 'POST',
        url: '/worklogs/reset/:moduleId',
        isArray: true
      },
      'generate': {
        method: 'POST',
        url: '/worklogs/generate/:studyId',
        isArray: true
      },
      'report': {
        method: 'GET',
        url: '/hour-report',
        isArray: false
      }
    });
  });