'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionEdit
 * @description
 * descriptionEdit Edit a description
 */
angular.module('uasApp').component('descriptionEdit', {
    bindings: {
        element: '<',
        description: '<',
        name: '<',
        isDisabled: '<',
        editor: '<',
        onChange: '&'
    },
    templateUrl: 'es6/i18n/descriptions/description.edit.html',
    controllerAs: 'descriptionEditController'
});
