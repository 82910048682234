'use strict';

angular.module('uasApp').factory('maintenanceInterceptor', function (maintenanceState) {
  return {
    response: function(response) {
        const state = response.headers('Maintenance');
        maintenanceState.setState(state);
        return response;
    }
  };
});
