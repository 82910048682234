'use strict';

angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('method-types', {
        url: '/method-types',
        template: '<method-type-list></method-type-list>',
        breadcrumb: {
            label: 'Static.Page.MethodType',
            root: true
        },
        admin: true
    });

});
