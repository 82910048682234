'use strict';

angular.module('uasApp').component('publicationLabel', {
    bindings: {
        entity: '<',
        operations: '<',
        onChange: '&'
    },
    templateUrl: 'es6/changes/publication.label.html',
    controllerAs: 'publicationLabelController',
    controller: function (Publication, Message, EntityService, SecurityService) {
        const publicationLabelController = this;

        publicationLabelController.$onInit = function() {
            publicationLabelController.allowed = SecurityService.isAllowed('ADMIN_OBJECT', publicationLabelController.operations);
            publicationLabelController.entityType = EntityService.getType(publicationLabelController.entity);
        };

        publicationLabelController.clear = function() {
            Publication.remove({
                entityType: publicationLabelController.entityType,
                entityId: publicationLabelController.entity.id
            }, {}).$promise.then(() => onChange());
        };

        function onChange() {
            Message.onSaved();
            publicationLabelController.onChange();
        }
    }
});
