'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:RoleType
 * @description
 * # RoleType
 * Resource for managing role types.
 */
angular.module('uasApp').factory('RoleType', function (UasResource) {
  const resource = UasResource('/role-types', {
    findAll: {
      method: 'GET',
      url: '/role-types',
      isArray: true
    }
  });

  resource.getTypes = function (entityType) {
    return resource.query({
      academicYearId: sessionStorage.academicYear,
      entityType: _.kebabCase(entityType)
    }).$promise;
  };

  return resource;
});
