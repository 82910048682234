'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AwardQualification
 * @description
 * The AwardQualification service.
 */
angular.module('uasApp').factory('AwardQualification', function (UasResource) {
  return UasResource('/award-qualifications');
});
