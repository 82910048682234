'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:EntityTextHolder
 * @description
 * The EntityTextHolder service.
 */
angular.module('uasApp')
    .factory('EntityTextHolder', function($rootScope, EntityService, Describe, Language) {
        const instance = {};

        /*
         * Retrieve the current entity description.
         */
        instance.get = function(entity, callback = () => {}, retrieve, language) {
            let result = '';
            if (!entity || !entity.type || entity.id === undefined) {
                callback(result);
                return result;
            }

            if (!language) {
                language = Language.get();
            }

            const sessionValue = sessionStorage.getItem(language + '-entity');
            const parsedValues = JSON.parse(sessionValue) || {};

            // Use cached value
            const identifier = generateId(entity);
            result = parsedValues[identifier];

            if (result) {
                callback(result);
                return result;
            } else if (retrieve !== false) {
                // Retrieve from backend and store
                Describe.get({
                    language: language,
                    entityId: entity.id,
                    entityType: entity.type
                }).$promise.then(function(value) {
                    callback(value);
                    instance.set(entity, language, value);
                });
            }
        };

        const generateId = function(entity) {
            return entity.id + '-' + entity.type;
        };

        /**
         * Update the entity description.
         */
        instance.set = function(entity, language, value) {
            const sessionValue = sessionStorage.getItem(language + '-entity');
            const parsedValues = JSON.parse(sessionValue) || {};

            doStore(entity, value, parsedValues);

            // Store the updated cache in session storage
            const newSessionValue = JSON.stringify(parsedValues);
            sessionStorage.setItem(language + '-entity', newSessionValue);
        };

        const doStore = function(entity, value, parsedValues) {
            const identifier = generateId(entity);
            if (!value) {
                delete parsedValues[identifier];
            } else {
                parsedValues[identifier] = value;

                // Recursively store the root entity
                if (value.root) {
                    doStore(value.root.reference, {
                        entity: value.root
                    }, parsedValues);
                }
            }
        };

        instance.forceUpdate = function(updatedEntity) {
            const entity = EntityService.toReference(updatedEntity);

            if (entity) {
                instance.clear(entity);
                instance.get(entity, (retrievedEntity) => {
                    if (angular.isDefined(retrievedEntity)) {
                        const entityReference = EntityService.toReference(retrievedEntity.entity);
                        if (!_.isEmpty(entityReference)) {
                          $rootScope.$broadcast('entityTextUpdated', entityReference);
                        }
                    }
                }, true);
            }
        };

        /**
         * Clear the cache of a certain entity.
         */
        instance.clear = function(entity) {
            Language.supported.forEach((language) => {
                instance.set(entity, language);
            });
        };

        /**
         * Clear all caches.
         */
        instance.clearAll = function() {
            sessionStorage.removeItem('NL-entity');
            sessionStorage.removeItem('EN-entity');
        };

        return instance;
    });
