'use strict';

angular.module('uasApp').factory('preferencesInterceptor', function($injector) {
    return {
        request: function (request) {
          request.headers['Accept-Language'] = $injector.get('Language').get();
          request.headers['Accept-Year-Id'] = sessionStorage.academicYear;
          return request;
        }
    };
});
