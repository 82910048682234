'use strict';

angular.module('uasApp').factory('FeedbackService', function ($state, feedbackObserver, Message, EntityTextHolder, EntityService) {

    function onUpdate(entity) {
        feedbackObserver.dataChanged();
        Message.onSaved();

        EntityTextHolder.clear(EntityService.toReference(entity));
        $state.reload();
    }

    return { onUpdate };
});