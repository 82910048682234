'use strict';

angular.module('uasApp').component('labelPage', {
    templateUrl: 'es6/page/label.page.html',
    bindings: {
        entity: '<',
        page: '<',
        operations: '<',
        hideHelp: '<?'
    },
    controllerAs: 'labelPageController'
});