'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:personLabel
 * @personLabel Displays a person
 */
angular.module('uasApp')
  .component('personLabel', {
    bindings: {
      person: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/person/person.label.html',
    controllerAs: 'personLabelController',
    controller: function(Session) {
      const personLabelController = this;

      personLabelController.$onInit = function() {
        const personId = _.result(personLabelController.person, 'id');
        personLabelController.valid = angular.isDefined(personId);
        personLabelController.mine = personId === Session.personId;
      };
    }
});
