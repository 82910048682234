'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasModal
 * @description
 * # uasModal
 * Default modal template
 */
angular.module('uasApp')
    .component('uasModal', {
        bindings: {
            title: '@',
            icon: '@?',
            instance: '<',
            onSubmit: '&?'
        },
        controllerAs: 'modalController',
        controller: function() {
            const modalController = this;

            modalController.close = function() {
                modalController.instance.dismiss();
            };

            modalController.submit = function() {
                const result = modalController.onSubmit();
                if (result && result.then) {
                    return result.then(modalController.close);
                } else {
                    modalController.close();
                }
            };
        },
        transclude: true,
        templateUrl: 'es6/app/templates/modal.html'
    });