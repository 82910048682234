'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:customField
 * @description Edit or create a custom field
 */
angular.module('uasApp')
    .component('customField', {
        bindings: {
            customField: '<',
            onSave: '&',
            onCancel: '&'
        },
        templateUrl: 'es6/custom/fields/custom.field.html',
        controllerAs: 'customFieldController',
        controller: function (CustomField, Message, translateFilter, ReferenceType, Entity, i18nFilter, Changes, typeFilter) {
            const customFieldController = this;

            customFieldController.$onInit = function () {
                setSubTypes();
            };

            function setSubTypes() {
              const valueType = _.get(customFieldController.customField, 'valueType');
              const subType = _.get(customFieldController.customField, 'subType');

              switch (valueType) {
                case 'REFERENCE':
                  ReferenceType.query().$promise.then((referenceTypes) => {
                    customFieldController.referenceTypes = _(referenceTypes)
                      .map((type) => {
                        type.displayName = i18nFilter(type.names);
                        return type;
                      })
                      .sortBy('displayName')
                      .value();
                  });
                  break;

                case 'ENTITY':
                  Entity.types().$promise.then((entityTypes) => {
                    customFieldController.entityTypes = _(entityTypes)
                      .concat(subType)
                      .filter(angular.isDefined)
                      .map((type) => ({
                        type,
                        displayName: typeFilter(type)
                      }))
                      .sortBy('displayName')
                      .value();
                  });
                  break;

                default:
                  break;
              }
            }

            customFieldController.$onChanges = function (changes) {
                if (Changes.hasChanged(changes, 'customField') && customFieldController.customField) {
                    customFieldController.defaultCustomField = {
                        name: 'defaultValue',
                        valueType: customFieldController.customField.valueType,
                        subType: customFieldController.customField.subType,
                        visible: 'ALWAYS',
                        value: customFieldController.customField.defaultValue,
                        labels: [{ value: translateFilter('Static.Page.CustomFields.DefaultValue') }]
                    };

                    if (customFieldController.customField.valueType === 'REFERENCE') {
                        customFieldController.customField.subType = parseInt(customFieldController.customField.subType);
                    }
                }
            };

            customFieldController.save = function () {
                const body = toBody();

                CustomField.store(body).$promise.then((result) => {
                    _.extend(customFieldController.customField, result);
                    customFieldController.customFieldForm.$setPristine();
                    Message.onSaved();
                    customFieldController.onSave({ result });
                });
            };

            function toBody() {
                const body = angular.copy(customFieldController.customField);

                if (_.isArray(body.defaultValue)) {
                    body.defaultValue = _.head(body.defaultValue);
                }

                if (body.required) {
                    body.defaultValue = CustomField.getDefaultValue(body);
                }

                body.sectionId = _.get(customFieldController.customField, 'section.id');
                delete body.section;

                return body;
            }

            customFieldController.setDefaultValue = function () {
                customFieldController.customField.defaultValue = customFieldController.defaultCustomField.value;
            };

            customFieldController.onValueTypeChanged = function () {
                delete customFieldController.customField.subType;
                delete customFieldController.customField.filter;
                delete customFieldController.customField.decimals;
                delete customFieldController.customField.defaultValue;

                setSubTypes();
                resetDefaultCustomField();
            };

            function resetDefaultCustomField() {
                delete customFieldController.defaultCustomField.subType;
                delete customFieldController.defaultCustomField.value;

                // Make a copy so change will be noticed by the onChanges of custom field
                customFieldController.defaultCustomField = angular.copy(customFieldController.defaultCustomField);
                customFieldController.defaultCustomField.valueType = customFieldController.customField.valueType;
                customFieldController.defaultCustomField.subType = customFieldController.customField.subType;
            }

            customFieldController.cancel = function () {
                customFieldController.onCancel();
            };

            customFieldController.onEntityTypeChanged = function () {
                delete customFieldController.customField.defaultValue;
                resetDefaultCustomField();
            };

            customFieldController.cancel = function () {
                customFieldController.onCancel();
            };

            customFieldController.onRequired = function () {
                if (!customFieldController.customField.required) {
                    delete customFieldController.customField.defaultValue;
                    delete customFieldController.defaultCustomField.value;
                }
            };
        }
    });
