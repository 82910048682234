'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ModuleWeek
 * @description
 * The ModuleWeek service.
 */
angular.module('uasApp')
    .factory('ModuleWeek', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/module-week/:id', {}, {
            'update': {
                method: 'PUT'
            }
        });
    });
