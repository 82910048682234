'use strict';

angular.module('uasApp')
    .component('uasMoveModule', {
        bindings: {
            entity: '<',
            module: '<',
            group: '<',
            studyId: '<',
            open: '<',
            onMove: '&',
            onClose: '&'
        },
        templateUrl: 'es6/planboard/move/move.module.html',
        controllerAs: 'moveModuleController',
        controller: function(ModuleGroup) {

            const moveModuleController = this;

            moveModuleController.$onInit = function() {
                moveModuleController.target = angular.copy(moveModuleController.group);
                loadModules();
            };

            function loadModules() {
                moveModuleController.loading = true;

                ModuleGroup.modules({
                    id: moveModuleController.group.id,
                    active: true
                }).$promise.then((modules) => {
                    moveModuleController.modules = _.map(modules, (it) => {
                        it.active = it.module.id === moveModuleController.module.id;
                        return it;
                    });
                    calculate();
                }).finally(() => {
                    moveModuleController.loading = false;
                });
            }

            moveModuleController.onGroup = function (group) {
                moveModuleController.target = group;
            };

            moveModuleController.next = function() {
                moveModuleController.selected = true;
            };

            moveModuleController.back = function() {
                moveModuleController.selected = false;
            };

            moveModuleController.onAll = function() {
                _.forEach(moveModuleController.modules, (module) => {
                    module.active = moveModuleController.all;
                });

                calculate();
            };

            moveModuleController.onModule = function() {
                calculate();
            };

            function calculate() {
                moveModuleController.credits = _(moveModuleController.modules).filter({ 
                    active: true
                }).map('credits').sum();
            }

            moveModuleController.move = function() {
                const modules = _.filter(moveModuleController.modules, { active: true });

                moveModuleController.onMove({
                    targetId: moveModuleController.target.id,
                    modules: modules
                });
            };

            moveModuleController.close = function() {
                moveModuleController.onClose();
            };
        }
    });
