'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:qualificationsReportPage
 * @description
 * Displays qualifications for an entity.
 */
angular.module('uasApp').component('qualificationsReportPage', {
  templateUrl: 'es6/qualification/report/qualifications.report.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<'
  },
  controllerAs: 'qualificationsReportPageController',
  controller: function (Page) {
    const qualificationsReportPageController = this;

    qualificationsReportPageController.$onInit = function () {
      qualificationsReportPageController.readOnly = Page.getParameterAsBoolean(qualificationsReportPageController.page, 'readOnly', false);
      qualificationsReportPageController.reportTemplate = Page.getParameter(qualificationsReportPageController.page, 'reportTemplate');
      qualificationsReportPageController.hideReportTemplates = Page.getParameterAsBoolean(qualificationsReportPageController.page, 'hideReportTemplates', false);
    };
  }
});
