'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasInstitutionList
 * @description
 * # uasInstitutionList
 * Shows the institutions.
 */
angular.module('uasApp')
    .component('uasInstitutionList', {
        controllerAs: 'institutionListController',
        templateUrl: 'es6/organisation/institution/institution.list.html',
        controller: function(Institution, Pageable, Message, $uibModal) {
            const institutionListController = this;
            
            institutionListController.$onInit = function() {
                institutionListController.pageable = Pageable.of({
                    order: 'code'
                });

                loadData();
            };

            institutionListController.onPage = function() {
                loadData();
            };

            function loadData() {
                const params = institutionListController.pageable.build();
                
                institutionListController.loading = true;
                Institution.get(params).$promise.then((institutions) => {
                    institutionListController.institutions = institutions;
                }).finally(() => {
                    institutionListController.loading = false;
                });
            }

            institutionListController.create = function() {
                institutionListController.edit({
                    academicYearId: sessionStorage.academicYear
                });
            };

            institutionListController.edit = function(institution) {
                $uibModal.open({
                    controllerAs: 'institutionModalController',
                    controller: function($uibModalInstance) {
                        const institutionModalController = this;

                        institutionModalController.$onInit = function() {
                            institutionModalController.institution = angular.copy(institution);
                            institutionModalController.instance = $uibModalInstance;
                        };

                        institutionModalController.onSave = function() {
                            Message.onSaved();
                            return loadData();
                        };
                    },
                    template: `
                        <uas-institution-edit 
                            institution="institutionModalController.institution"
                            instance="institutionModalController.instance"
                            on-save="institutionModalController.onSave()">
                        </uas-institution-edit>
                    `
                });
            };

            institutionListController.delete = function(institution) {
                return Institution.remove(institution).$promise.then(() => {
                    Message.onRemoved();
                    return loadData();
                });
            };
        }
    });
