'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Description
 * @description
 * The Description service.
 */
angular.module('uasApp')
    .factory('Description', function($resource, BackendService) {
        const resource = $resource(BackendService.getBaseUrl() + '/description/:id', {}, {
            'update': {
                method: 'PUT'
            },
            'types': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/description/types',
                isArray: true
            },
            'list': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/descriptions/list',
                isArray: true
            },
            'report': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/descriptions-report',
                isArray: false
            }
        });

        function getTypes(entityType) {
            const formattedType = _.kebabCase(entityType);

            return resource.types({
                entityType: formattedType,
                academicYearId: sessionStorage.academicYear
            }).$promise;
        }

        return _.extend(resource, { getTypes });
    });
