'use strict';

angular.module('uasApp').component('menuItem', {
  bindings: {
    state: '@',
    label: '@',
    icon: '@'
  },
  templateUrl: 'es6/admin/menu.item.html',
  controllerAs: 'menuItemController'
});
