'use strict';

angular.module('uasApp').component('adviceEmptyAlert', {
  templateUrl: 'es6/advice/advice.empty.alert.html',
  bindings: {
    advices: '<?',
    role: '<?',
    classes: '@?'
  },
  controllerAs: 'adviceEmptyAlertController'
});
