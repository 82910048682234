'use strict';

angular.module('uasApp').component('filterInput', {
  templateUrl: 'es6/app/filter/filter.input.html',
  bindings: {
    inputId: '@',
    classes: '<?',
    placeholder: '@?',
    total: '<?',
    reset: '<?',
    onFilter: '&'
  },
  controllerAs: 'filterInputController',
  controller: function () {
    const filterInputController = this;

    filterInputController.$onInit = function () {
      if (filterInputController.reset) {
        filterInputController.reset.reset = resetFilter;
      }
    };

    filterInputController.clearFilter = function (event) {
      event.stopPropagation();

      resetFilter();
      angular.element(`#${filterInputController.inputId}`).focus();
    };

    function resetFilter() {
      delete filterInputController.filter;
      filterInputController.onFilter();
    }
  }
});
