'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:exportDetails
 * @description Shows an export detail modal title, footer and dynamically refreshable body.
 */
angular.module('uasApp').component('exportDetails', {
  bindings: {
    lines: '<',
    onClose: '&',
    onRefresh: '&'
  },
  templateUrl: 'es6/export/export.details.html',
  controllerAs: 'exportDetailsController',
  controller: function ($q, Feedback, Language) {
    const exportDetailsController = this;

    exportDetailsController.$onInit = function () {
      exportDetailsController.themes = {
        'FAILED': 'danger',
        'COMPLETED': 'success',
        'IN_PROGRESS': 'info',
        'QUEUED': 'warning'
      };
    };

    exportDetailsController.$onChanges = function () {
      loadData().then(() => {
        const pendingStatuses = ['IN_PROGRESS', 'QUEUED'];
        const hasPendingStatus = (status) => !_.isEmpty(exportDetailsController.statuses[status]);
        exportDetailsController.hasNotFinished = _.some(pendingStatuses, hasPendingStatus);
      });
    };

    exportDetailsController.close = function () {
      exportDetailsController.onClose();
    };

    exportDetailsController.refresh = function () {
      exportDetailsController.refreshing = true;

      exportDetailsController.onRefresh();
    };

    function loadData() {
      exportDetailsController.loading = true;

      const promises = _(exportDetailsController.lines).map(loadAndMapFeedback).value();

      return $q.all(promises).then((resolvedArray) => {
        exportDetailsController.statuses = groupByStatus(resolvedArray);
      }).finally(() => {
        exportDetailsController.loading = false;
        exportDetailsController.refreshing = false;
      });
    }

    function loadAndMapFeedback(line) {
      const mapped = angular.copy(line);

      if (angular.isDefined(line.minChangeId) && angular.isDefined(line.maxChangeId)) {
        mapped.feedback = Feedback.get({
          minChangeId: line.minChangeId,
          maxChangeId: line.maxChangeId,
          language: Language.get()
        }).$promise;

        return mapped.feedback.then((feedback) => {
          mapped.feedback = feedback;
          return mapped;
        });
      }

      return $q.resolve(mapped);
    }

    function groupByStatus(resolvedArray) {
      return _.groupBy(resolvedArray, 'status');
    }
  }
});
