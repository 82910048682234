'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Process
 * @description
 * # Process
 * Process resource of the uasApp
 */
angular.module('uasApp')
  .factory('ProcessType', function (UasResource, enumFilter, i18nFilter) {
    const instance = UasResource('/process-type');

    function getLabel(type) {
      return `${enumFilter(type.rootType)} - ${i18nFilter(type.labels) || type.name || ''}`;
    }

    return _.extend(instance, { getLabel });
  });
