'use strict';

angular.module('uasApp').component('progressBar', {
  bindings: {
    percentage: '<',
    label: '@'
  },
  templateUrl: 'es6/app/progress.bar.html',
  controllerAs: 'progressBarController',
  controller: function () {
    const progressBarController = this;

    progressBarController.$onChanges = function () {
      if (angular.isUndefined(progressBarController.percentage)) {
        progressBarController.percentage = 0;
      }

      progressBarController.width = Math.max(Math.min(progressBarController.percentage, 100), 0);
    };
  }
});