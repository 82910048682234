'use strict';

angular.module('uasApp').component('personRolesPage', {
  templateUrl: 'es6/person/roles/person.roles.page.html',
  bindings: {
    page: '<',
    entity: '<?',
    operations: '<?',
    isReadOnly: '<?'
  },
  controllerAs: 'personRolesPageController',
  controller: function (Page) {
    const personRolesPageController = this;

    personRolesPageController.$onInit = function () {
      personRolesPageController.readOnly = personRolesPageController.isReadOnly || Page.getParameterAsBoolean(personRolesPageController.page, 'readOnly', false);
    };
  }
});
