'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessReportTable
 * @description
 * uasProcessReportTable Shows the process statuses in a table.
 */
angular
    .module('uasApp')
    .component('uasProcessReportTable', {
        templateUrl: 'es6/process/progress/process.report.table.html',
        bindings: {
            process: '<',
            operations: '<',
            result: '<',
            callback: '&'
        },
        controllerAs: 'progressController',
        controller: function ($q, $uibModal, EmailAddress, Pageable, RoleType, Language, ProcessTakeover, ProcessModal,
            filterFilter, enumFilter, entityTranslateFilter, i18nFilter) {
            const progressController = this;

            progressController.$onInit = function () {
                progressController.filter = '';
                progressController.pageable = Pageable.of({
                    sort: 'code'
                });

                progressController.headers = [
                    { code: 'code', name: 'code', valueType: 'STRING' },
                    { code: 'name', name: 'name', valueType: 'STRING' },
                    { code: 'status', name: 'status', valueType: 'STRING' },
                    { code: 'participants', name: 'participants', valueType: 'NUMBER' },
                    { code: 'lastSeen', name: 'last seen', valueType: 'DATE_TIME' }
                ];

                if (angular.isDefined(progressController.result)) {
                    loadData(progressController.result);
                    loadRoles();
                }
            };

            function loadRoles() {
                const rootType = progressController.process.type.rootType;
                if (rootType === 'PERSON') {
                    progressController.roles = [{
                        id: -9999, // relation type select of email expects an id, but for person processes we only have a fixed "person" relation type. Therefore assign a fake id to it.
                        isCustomPersons: true,
                        names: _.map(Language.supported, (language) => {
                            return { language, value: enumFilter('PERSON') };
                        })
                    }];
                } else {
                    RoleType.query({
                        academicYearId: sessionStorage.academicYear,
                        entityType: _.kebabCase(rootType),
                        parent: progressController.process.type.emailParents
                    }).$promise.then((roles) => {
                        progressController.roles = _(roles)
                            .groupBy('entityType')
                            .map((grouped) => {
                                _.forEach(grouped, (role) => {
                                    role.displayName = i18nFilter(role.names);
                                });
                                const sorted = _.sortBy(grouped, 'displayName');
                                sorted[0].first = true;
                                return sorted;
                            })
                            .flatten()
                            .value();
                    });
                }
            }

            function loadData(result) {
                _.each(result.entities, (wrapper) => {
                    if (wrapper.status) {
                        wrapper.status = _.find(result.statuses, {
                            id: wrapper.status
                        });
                    }

                    wrapper.self = {
                        type: _.trim(wrapper.type),
                        id: wrapper.id
                    };
                });

                result.totals = buildTotal(result);

                progressController.progress = result;
                progressController.filterData();
            }

            function buildTotal(data) {
                const total = _.size(data.entities);
                const grouped = _.groupBy(data.entities, (entity) =>
                    _.result(entity.status, 'name')
                );
                return _.map(data.statuses, (status) => {
                    const result = angular.copy(status);
                    const found = grouped[status.name] || [];
                    result.amount = found.length;
                    result.active = result.amount > 0;
                    result.percentage = Math.floor(result.amount / total * 100);
                    return result;
                });
            }

            progressController.toggleAll = function () {
                const selected = !_.every(progressController.entities, {
                    selected: true
                });
                _.each(progressController.entities, (e) => {
                    e.selected = selected;
                });
                countSelected();
            };

            progressController.onSelect = function () {
                countSelected();
            };

            function countSelected() {
                progressController.selected = _.filter(progressController.entities, {
                    selected: true
                }).length;

                allSelected();
            }

            function allSelected() {
                progressController.selectedAll = _.every(progressController.entities, {
                    selected: true
                });
            }

            progressController.showEmail = function (role) {
                const entities = _(progressController.entities)
                    .filter({ selected: true })
                    .map((e) => _.pick(e, ['id', 'type']))
                    .value();

                openNotificationModal(role, entities);
            };

            function getRecipients(role, entities) {
                if (role.isCustomPersons) {
                    return EmailAddress.persons({}, {
                        personIds: _.map(entities, 'id')
                    }).$promise;
                } else {
                    return EmailAddress.entities({}, {
                        entities: entities,
                        roleId: role.id
                    }).$promise;
                }
            }

            function openNotificationModal(role, entities) {
                $uibModal.open({
                    size: 'lg',
                    template: `
                        <manual-notifications-modal
                            role="notificationModalController.role"
                            get-recipients="notificationModalController.getRecipients()"
                            operations="notificationModalController.operations"
                            on-send="notificationModalController.close()"
                            on-close="notificationModalController.close()"
                        </manual-notifications-modal>
                    `,
                    controllerAs: 'notificationModalController',
                    controller: function ($uibModalInstance) {
                        const notificationModalController = this;

                        notificationModalController.$onInit = function () {
                            notificationModalController.role = role;
                            notificationModalController.operations = progressController.operations;
                        };

                        notificationModalController.getRecipients = function () {
                            return getRecipients(role, entities);
                        };

                        notificationModalController.close = function () {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            }

            progressController.filterStep = function (id, name, object) {
                const step = { id, name };

                if (_.isEmpty(step) || progressController.filter && progressController.filter.id === step.id && progressController.filter.name === step.name) {
                    _.each(progressController.progress.totals, (e) => {
                        e.active = e.amount > 0;
                    });
                    delete progressController.filter;
                } else {
                    progressController.filter = { id: step.id, name: step.name };
                    _.each(progressController.progress.totals, (e) => {
                        e.active = false;
                    });
                    object.active = true;
                }

                progressController.filterData();
            };

            progressController.filterData = function () {
                progressController.pageable.currentPage = 1;
                progressController.entities = _.filter(
                    progressController.progress.entities, (e) => {
                        return isWithinStep(e, progressController.filter);
                    }
                );
                progressController.entities = filterFilter(progressController.entities, progressController.searchQuery);
                countSelected();
            };

            function isWithinStep(entity, step) {
                if (!step) {
                    return true;
                }

                return step.name === _.result(entity.status, 'name');
            }

            // Direct takeover of multiple entities (all selected entities)
            progressController.takeover = function () {
                ProcessTakeover.open({
                    entityType: _.kebabCase(progressController.process.type.rootType),
                    entityIds: _(progressController.entities).filter({
                        selected: true
                    }).map('id').value(),
                    process: progressController.process,
                    statuses: progressController.progress.statuses,
                    callback: progressController.callback
                });
            };

            // Progress history of single entity. Has option to advance one step or to go to takeover for that entity.
            progressController.show = function (entity) {
                ProcessModal.open({
                    entity: entity,
                    statuses: _.filter(progressController.progress.statuses, 'name'),
                    status: entity.status,
                    process: progressController.process,
                    callback: progressController.callback
                });
            };

            progressController.getReportRows = function() {
                const rows = _(progressController.entities).filter({
                    selected: true
                }).map((entity) => {
                    const status = entity.status || {};
                    return {
                        code: entity.code,
                        name: entityTranslateFilter(entity),
                        status: i18nFilter(status.labels) || status.name,
                        participants: entity.participants,
                        lastSeen: entity.lastSeen
                    };
                }).value();

                return $q.resolve(rows);
            };

            progressController.getEntities = function() {
                const rows = _(progressController.entities).filter({
                    selected: true
                }).map((entity) => {
                    return _.pick(entity, ['type', 'id']);
                }).value();

                return $q.resolve(rows);
            };
        }
    });
