'use strict';

angular.module('uasApp').component('notes', {
  bindings: {
    entity: '<',
    isReadOnly: '<?',
    operations: '<?',
    operationsToEdit: '<?',
    showEmpty: '<?'
  },
  templateUrl: 'es6/note/notes.html',
  controllerAs: 'notesController',
  controller: function (Note, EntityService, AuthService, SecurityService) {
    const notesController = this;

    notesController.$onInit = function() {
      notesController.entityType = EntityService.getType(notesController.entity);
      notesController.visible = SecurityService.isAllowed('VIEW_NOTES', notesController.operations);

      const operationsToEdit = AuthService.buildOperationsToEdit(notesController.operationsToEdit, 'EDIT_NOTES');
      notesController.editable = notesController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, notesController.operations);
    
      if (notesController.visible) {
        loadData();
      }
    };

    function loadData() {
      notesController.loading = true;
      Note.get({
        entityId: notesController.entity.id,
        entityType: notesController.entityType
      }).$promise.then((notes) => {
        notesController.notes = notes;
      }).finally(() => {
        notesController.loading = false;
      });
    }

    notesController.onChange = function () {
      loadData();
    };
  }
});
