'use strict';

/**
 * @ngdoc function
 * @name uasApp.config:CsvStates
 * @description
 * # CsvStates
 */
angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('csv', {
    url: '/csv',
    template: '<uas-csv-upload></uas-csv-upload>',
    breadcrumb: {
      label: 'Static.Page.Csv',
      root: true
    },
    data: {
      root: true
    }
  });
});
