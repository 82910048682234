'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:offeringListPage
 * @description
 * offeringListPage Shows workflow offerings
 */
angular.module('uasApp')
    .component('offeringListPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/offerings/offering.list.page.html',
        controllerAs: 'offeringListPageController',
        controller: function (Page) {
            const offeringListPageController = this;

            offeringListPageController.$onInit = function () {
                offeringListPageController.readOnly = offeringListPageController.isReadOnly || Page.getParameterAsBoolean(offeringListPageController.page, 'readOnly', false);
                offeringListPageController.operationsToEdit = angular.isDefined(offeringListPageController.workflow) ? 'EDIT_OFFERINGS_WORKFLOW' : 'EDIT_OFFERINGS';
                offeringListPageController.showInactive = Page.getParameterAsBoolean(offeringListPageController.page, 'showInactive', false);
                offeringListPageController.hideEdit = Page.getParameterAsBoolean(offeringListPageController.page, 'hideEdit', false);
                offeringListPageController.adhoc = Page.getParameterAsBoolean(offeringListPageController.page, 'adhoc', false);
                offeringListPageController.columns = Page.getParameterAsArray(offeringListPageController.page, 'columns');
            };
        }
    });
