'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:OrganisationCtrl
 * @description
 * # OrganisationCtrl
 * Organisation controller
 */
angular.module('uasApp')
    .controller('OrganisationCtrl', function (organisation, $scope, $state, Tabs, operations) {

        // Tabs which uses ui-router states to generate content.
        var tabs = [{
            view: 'general',
            route: 'organisation.general',
            readOnly: true
        }, {
            view: 'relations',
            route: 'organisation.relations'
        }, {
            view: 'assignments',
            route: 'organisation.assignments'
        }, {
            view: 'availability',
            route: 'organisation.availability'
        }, {
            view: 'vacancies',
            route: 'organisation.vacancies'
        }, {
            view: 'hours',
            route: 'organisation.hours'
        }, {
            view: 'cost-divisions',
            route: 'organisation.cost-divisions'
        }, {
            view: 'modules',
            route: 'organisation.modules'
        }, {
            view: 'resources',
            route: 'organisation.resources'
        }, {
            view: 'methods',
            route: 'organisation.methods'
        }, {
            view: 'tasks',
            route: 'organisation.tasks'
        }, {
            view: 'admin',
            route: 'organisation.admin'
        }];

        $scope.organisationId = $state.params.id;
        $scope.organisation = organisation;

        $scope.operations = operations;
        Tabs.build(tabs, operations, 'organisation', 'organisation', $scope.organisationId).then((builtTabs) => {
            $scope.tabs = builtTabs;
        });
    });
