'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamEdit
 * @description teamEdit Edit or create a team.
 */
angular.module('uasApp').component('teamEdit', {
  templateUrl: 'es6/team/team.edit.html',
  bindings: {
    team: '<',
    hideOwner: '<',
    operations: '<',
    onSave: '&',
    onCancel: '&'
  },
  controllerAs: 'teamEditController',
  controller: function (Message, Team) {
    const teamEditController = this;

    teamEditController.save = function () {
      Team.save(teamEditController.team).$promise.then(() => {
        Message.onSaved();
        teamEditController.onSave();
      });
    };
  }
});
