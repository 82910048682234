'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Workflow
 * @description
 * # Workflow
 * Workflow resource of the uasApp
 */
angular.module('uasApp')
  .factory('Workflow', function (UasResource) {
    return UasResource('/workflows', {
        widgets: {
            url: '/workflow-widgets',
            method: 'GET',
            isArray: true
        },
        participant: {
            url: '/workflows/:id/participant',
            method: 'GET',
            isArray: false
        },
        begin: {
            url: '/workflows/begin',
            method: 'POST',
            isArray: false
        },
        assign: {
            url: '/workflows/:id/assign',
            method: 'PUT',
            isArray: false
        },
        updateProgress: {
            url: '/workflows/:id/update',
            method: 'PUT',
            isArray: false
        },
        complete: {
            url: '/workflows/:id/complete',
            method: 'PUT',
            isArray: false
        },
        cancel: {
            url: '/workflows/:id/remove',
            method: 'DELETE'
        },
        delete: {
            url: '/workflows/:id',
            method: 'DELETE'
        }
    });
  });
