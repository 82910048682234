'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasFacultyGeneral
 * @description
 * # uasFacultyGeneral
 * Faculty general tab
 */
angular.module('uasApp').component('uasFacultyGeneral', {
  bindings: {
    faculty: '<',
    operations: '<'
  },
  templateUrl: 'es6/organisation/faculty/faculty.general.html',
  controllerAs: 'facultyGeneralController',
  controller: function ($state, Process) {
    const facultyGeneralController = this;

    facultyGeneralController.$onInit = function () {
      facultyGeneralController.processes = Process.query({
        academicYearId: sessionStorage.academicYear,
        rootType: 'FACULTY'
      });
    };

    facultyGeneralController.onStatus = function () {
      $state.reload();
    };
  }
});