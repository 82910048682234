'use strict';

angular.module('uasApp').component('requiredCheck', {
  templateUrl: 'es6/app/forms/validation/required.check.html',
  bindings: {
    value: '<',
    iconClasses: '@?'
  },
  controllerAs: 'requiredCheckController'
});
