'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('calendar-list', {
        url: '/calendars',
        breadcrumb: {
            label: 'Static.Page.Calendar',
            root: true
        },
        template: '<uas-calendar-list></uas-calendar-list>'
    });

    $stateProvider.state('calendar-create', {
        url: '/calendars/create',
        breadcrumb: {
            label: 'Static.Page.Calendar'
        },
        controllerAs: 'calendarCreateStateController',
        controller: function() {
            this.$onInit = function() {
                this.calendar = {
                    academicYearId: sessionStorage.academicYear
                };
            };
        },
        template: `
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h1 class="panel-title">
                        <i class="fa fa-calendar"></i> <span translate>Static.Page.Calendar</span>
                    </h1>
                </div>
                <div class="panel-body">
                    <uas-calendar-form calendar="calendarCreateStateController.calendar">
                    </uas-calendar-form>
                </div>
            </div>
        `
    });

    $stateProvider.state('calendar', {
        url: '/calendar/{id:[0-9]{1,8}}',
        resolve: {
            calendar: function (Calendar, $stateParams) {
                const instance = this;
                return Calendar.get({
                    id: $stateParams.id
                }).$promise.then((calendar) => _.extend(calendar, {
                    self: {
                        type: 'calendar',
                        id: calendar.id
                    }
                })).then((calendar) => {
                    instance.self.data = calendar;
                    return calendar;
                });
            }
        },
        controllerAs: 'calendarController',
        controller: function(calendar) {
            this.calendar = calendar;
            this.tabs = [{
                title: 'Static.Page.Calendar.General',
                view: 'general',
                route: 'calendar.general'
            }, {
                title: 'Static.Page.CalendarPeriods',
                view: 'periods',
                route: 'calendar.periods'
            }, {
                title: 'Static.Page.CalendarWeeks',
                view: 'weeks',
                route: 'calendar.weeks'
            }, {
                title: 'Static.Page.TimeBlocks',
                view: 'blocks',
                route: 'calendar.blocks'
            }, {
                title: 'Static.Page.RibbonPeriods',
                view: 'ribbons',
                route: 'calendar.ribbons'
            }];
        },        
        template: `
            <entity-tabs 
                view="calendar" 
                entity="calendarController.calendar"
                tabs="calendarController.tabs"
            />
        `,
        abstract: true
    });

    $stateProvider.state('calendar.general', {
        url: '/general',
        views: {
            'calendar': {
                controllerAs: 'calendarGeneralStateController',
                controller: function(calendar) {    
                    this.calendar = calendar;
                },
                template: `<uas-calendar-form calendar="calendarGeneralStateController.calendar">
                           </uas-calendar-form>`
            }
        },
        parent: 'calendar',
        breadcrumb: {},
        admin: true
    });

    $stateProvider.state('calendar.periods', {
        url: '/periods',
        views: {
            'calendar': {
                controllerAs: 'periodsStateController',
                controller: function(calendar) {    
                    this.calendar = calendar;
                },
                template: `<calendar-period-list calendar="periodsStateController.calendar">
                           </calendar-period-list>`
            }
        },
        parent: 'calendar',
        breadcrumb: {},
        admin: true
    });

    $stateProvider.state('calendar.weeks', {
        url: '/weeks',
        views: {
            'calendar': {
                controllerAs: 'weeksStateController',
                controller: function(calendar) {    
                    this.calendar = calendar;
                },
                template: `<calendar-week-list calendar="weeksStateController.calendar">
                           </calendar-week-list>`
            }
        },
        parent: 'calendar',
        breadcrumb: {},
        admin: true
    });

    $stateProvider.state('calendar.blocks', {
        url: '/blocks',
        views: {
            'calendar': {
                controllerAs: 'blocksStateController',
                controller: function(calendar) {    
                    this.calendar = calendar;
                },
                template: `<time-block-list calendar="blocksStateController.calendar">
                           </time-block-list>`
            }
        },
        parent: 'calendar',
        breadcrumb: {},
        admin: true
    });

    $stateProvider.state('calendar.ribbons', {
        url: '/ribbons',
        views: {
            'calendar': {
                controllerAs: 'ribbonsStateController',
                controller: function(calendar) {    
                    this.calendar = calendar;
                },
                template: `<period-list calendar="ribbonsStateController.calendar">
                           </period-list>`
            }
        },
        parent: 'calendar',
        breadcrumb: {},
        admin: true
    });

});
