'use strict';

angular.module('uasApp').component('objectiveTreePage', {
  bindings: {
    entity: '<',
    operations: '<',
    page: '<'
  },
  templateUrl: 'es6/objective/tree/objective.tree.page.html',
  controllerAs: 'objectiveTreePageController',
  controller: function (Page) {
    const objectiveTreePageController = this;

    objectiveTreePageController.$onInit = function () {
        objectiveTreePageController.typeId = Page.getParameterAsInt(objectiveTreePageController.page, 'type');
    };
  }
});