'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Util
 * @description
 * The Util service.
 */
angular.module('uasApp')
    .factory('Util', function() {
        var instance = {};

        /*
         * Retrieves the data, whenever the data is a
         * $promise we wait for the callback.
         */
        instance.getAsynch = function(data, callback) {
            if (angular.isDefined(data)) {
                if (data.$promise) {
                    return instance.getAsynch(data.$promise, callback);
                } else if (data.then) {
                    data.then(function(result) {
                        callback(result);
                    });
                } else {
                    callback(data);
                }
            }
        };

        /*
         * Waits until the property is defined on our scope.
         * Once the property is found we automatically unwatch.
         */
        instance.wait = function($scope, property, callback) {
            var result = $scope[property];
            if (result) {
                callback(result);
            } else {
                var unwatch = $scope.$watch(property, function(newResult) {
                    if (newResult) {
                        callback(newResult);
                        unwatch();
                    }
                });
            }
        };

        /**
         * Capitalizes strings.
         * @param string  string which should be capitalized
         * @return string Capitalized string
         */
        instance.capitalize = function(string) {
            if (!string || string.length === 0) {
                return '';
            }

            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        /**
         * Convert a value into an array.
         * @param {*} value the value
         * @param {*} defaultValue the default value
         * @returns 
         */
        instance.toArray = function(value, defaultValue) {
            if (_.isArray(value)) {
              return value;
            }
      
            return angular.isDefined(value) ? [value] : defaultValue;
        };

        return instance;
    });
