'use strict';

angular.module('uasApp').component('workflowSetupWizard', {
    templateUrl: 'es6/workflow/setup/workflow.setup.wizard.html',
    bindings: {
        setupSteps: '=',
        workflow: '<?'
    },
    controllerAs: 'workflowSetupWizardController',
    controller: function($scope, $state, $stateParams, Message, Workflow, workflowSetupWizardService, workflowSetupIsDirtyChecker, WorkflowValidator) {
        const workflowSetupWizardController = this;
        let isPendingStep = false;

        workflowSetupWizardController.$onInit = function() {
            // Get the current step and set its active status.
            // If the step is undefined (like when navigating directly to the page settings state) it needs to be determined first.
            // In this case isPendingStep is set to true.
            // If isPendingStep is true and the additional steps are loaded, the newly added additional step will be automatically set active.
            workflowSetupWizardController.currentStep = getCurrentStep();

            if (angular.isUndefined(workflowSetupWizardController.currentStep)) {
                isPendingStep = true;
            } else {
                workflowSetupWizardController.goToStep(workflowSetupWizardController.currentStep);
            }

            workflowSetupWizardService.setAdditionalSteps($scope, workflowSetupWizardController.workflow.pages);
            workflowSetupIsDirtyChecker.onDirtyFlagged(workflowSetupWizardController.setDirty);
        };

        workflowSetupWizardController.$onDestroy = function() {
            workflowSetupIsDirtyChecker.unsubscribe(workflowSetupWizardController.setDirty);
            WorkflowValidator.reset();
        };

        function getCurrentStep() {
            return _.find(workflowSetupWizardController.setupSteps, (step) =>  {
                if (step.stateParams) {
                    return step.route === $state.current.name && step.stateParams.pageIndex === parseInt($stateParams.pageIndex);
                }
                return step.route === $state.current.name;
            });
        }

        function updateSteps() {
            _.each(workflowSetupWizardController.setupSteps, function(step, $index) {
                step.active = $index === workflowSetupWizardController.currentStep.index;
                step.done = $index < workflowSetupWizardController.currentStep.index;
                step.clickable = true;
            });
        }

        workflowSetupWizardController.setDirty = function() {
            workflowSetupWizardController.isDirty = true;
        };

        /**
         * Place additional wizard steps for setting up the pages in the wizard.
         * This function is called upon reloading the page and after each time a workflow page is added / removed / re-ordered.
         */
        $scope.$on('setAdditionalSteps', function (event, additionalSteps) {
            const baseSteps = _.filter(angular.copy(workflowSetupWizardController.setupSteps), function(step) {
                return !step.isAdditional;
            });
            workflowSetupWizardController.setupSteps = baseSteps.concat(additionalSteps);

            if (isPendingStep) {
                workflowSetupWizardController.goToStep(getCurrentStep());
                isPendingStep = false;
            }
        });

        /**
         * Checks if we can go to the previous step. Checks if the current step is not the first step.
         */
        workflowSetupWizardController.canGoToPreviousStep = function() {
            return !isPendingStep && workflowSetupWizardController.currentStep.index > 0;
        };

        /**
         * Checks if we can go to the next step. Checks if the current step is not the last step.
         */
        workflowSetupWizardController.canGoToNextStep = function() {
            return !isPendingStep && workflowSetupWizardController.currentStep.index !== (workflowSetupWizardController.setupSteps.length -1);
        };

        workflowSetupWizardController.nextStep = function() {
            const index = workflowSetupWizardController.currentStep.index + 1;
            const next = workflowSetupWizardController.setupSteps[index];
            workflowSetupWizardController.goToStep(next);
        };

        workflowSetupWizardController.disableNext = function () {
            return !WorkflowValidator.isValid();
        };

        workflowSetupWizardController.previousStep = function() {
            const index = workflowSetupWizardController.currentStep.index - 1;
            const previous = workflowSetupWizardController.setupSteps[index];
            workflowSetupWizardController.goToStep(previous);
        };

        workflowSetupWizardController.goToStep = function(step) {
            const stateParams = (step.stateParams) ? step.stateParams : {};
            return $state.go(step.route, stateParams).then(function() {
                workflowSetupWizardController.currentStep = step;
                updateSteps();
            });
        };

        workflowSetupWizardController.save = function () {
            const model = buildModel(workflowSetupWizardController.workflow);
            return Workflow.store(model).$promise.then((workflow) => {
                Message.onSaved();

                if (angular.isUndefined(model.id)) {
                    const params = _.omit($state.params, 'workflowId'); // Can contain page number if we are in page setup screen.
                    params.workflowId = workflow.id;
                    $state.go($state.$current.name, params, { reload: false });
                } else {
                    $state.reload();
                }
            });
        };

        function buildModel(workflow) {
            const model = angular.copy(workflow);
            model.disabled = !(angular.copy(workflow.enabled));
            delete model.enabled;

            if (angular.isDefined(model.videoId)) {
                model.video = model.videoId;
            }

            return model;
        }

        workflowSetupWizardController.exit = function() {
            $state.go('workflows');
        };

        workflowSetupWizardController.isInvalid = function() {
            return !WorkflowValidator.isValid();
        };
    }
});
