'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:changeRevisions
 * @description Show change revisions and approve/reject a change
 */
angular.module('uasApp').component('changeRevisions', {
    bindings: {
        changeId: '<',
        entityTypes: '<',
        onChange: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/changes/change.revisions.html',
    controllerAs: 'changeRevisionsController',
    controller: function(Feedback, Change, AuthService, SecurityService, EntityService, EntityTypes, Language, Message, $q) {

        const changeRevisionsController = this;

        changeRevisionsController.$onInit = function() {
            loadData();
        };

        function loadData() {
            changeRevisionsController.loading = true;

            Change.get({
                id: changeRevisionsController.changeId
            }).$promise.then((change) => {
                changeRevisionsController.change = change;
                changeRevisionsController.entity = getEntity(change);

                return $q.all([
                    Change.query({
                        entityType: changeRevisionsController.entity.type,
                        entityId: changeRevisionsController.entity.id
                    }).$promise,
                    AuthService.operations(
                      changeRevisionsController.entity.type,
                      changeRevisionsController.entity.id
                    )
                ]).then(([changes, operations]) => {
                    changeRevisionsController.changes = changes;
                    changeRevisionsController.isApprover = SecurityService.isAllowed('APPROVE', operations);

                    return changeRevisionsController.focus(change.id);
                });
            }).finally(() => {
                changeRevisionsController.loading = false;
            });
        }

        function getEntity(change) {
            let entityId = change.entity.id;
            let entityType = change.entity.type;
            if (change.type === 'CREATE' && change.statusType === 'REJECTED') {
                entityId = change.root.id;
                entityType = change.root.type;
            }

            return {
                id: entityId,
                type: entityType
            };
        }

        changeRevisionsController.focus = function(changeId) {
            _.each(changeRevisionsController.changes, function (change) {
                change.active = change.id === changeId;
                change.loading = true;

                Feedback.get({
                    changeId: changeId,
                    language: Language.get()
                }).$promise.then((feedback) => {
                    change.feedback = feedback;
                    change.type = change.entity.type;
                }).finally(() => {
                    change.loading = false;
                });
            });

            EntityTypes.load().then((entityTypes) => {
                changeRevisionsController.entityTypes = entityTypes;
            });

            changeRevisionsController.statuses = Change.statuses({
                id: changeId
            });
        };

        changeRevisionsController.onCustomMessageLoaded = function(change) {
            change.hasCustomMessage = true;
        };

        changeRevisionsController.cancel = function() {
            changeRevisionsController.onCancel();
        };

        changeRevisionsController.redirect = function() {
            changeRevisionsController.cancel();
            EntityService.redirect(changeRevisionsController.change.entity, changeRevisionsController.change.root);
        };

        changeRevisionsController.approve = function() {
            Change.approve({
                entityType: changeRevisionsController.change.entity.type,
                entityId: changeRevisionsController.change.entity.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then(function() {
                Message.addInfoLabel('Static.Message.ApprovedChanges');
            }).finally(changeRevisionsController.onChange);
        };

        changeRevisionsController.unapprove = function() {
            Change.unapprove(undefined, {
                entityType: changeRevisionsController.change.entity.type,
                entityId: changeRevisionsController.change.entity.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then(function() {
                Message.addInfoLabel('Static.Message.DataSaved');
            }).finally(changeRevisionsController.onChange);
        };
    }
});
