'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:hoverColumns
 * @description
 * The hoverColumns directive. Adds a background color to all cells in a column when hovering over a cell in that column.
 * Even with complex and nested tables. Caches observers for performance.
 */
angular.module('uasApp')
  .directive('hoverColumns', function () {

    return {
      restrict: 'A',
      link: function ($scope, element) {
        const siblingObservers = new WeakMap();
        let currentColumnIndex;

        element.on('click', 'tr', handleClick);
        element.on('mouseover', 'tr', handleMouseOver);
        element.on('mouseout', 'tr', handleMouseOut);
        $scope.$on('$destroy', cleanUp);

        function cleanUp() {
          _.forEach(siblingObservers, ({ observer }) => observer.disconnect());
          element.off('click', 'tr', handleClick);
          element.off('mouseover', 'tr', handleMouseOver);
          element.off('mouseout', 'tr', handleMouseOut);
        }

        function handleClick(e) {
          const newColumnIndex = getColumnIndex(e.target);

          if (_.isNumber(newColumnIndex)) {
            const nextRow = e.target.closest('tr');

            if (_.isObject(nextRow)) {
              const nextSibling = nextRow.nextElementSibling;
              handleSiblingObserver(nextSibling, newColumnIndex);
            }
          }
        }

        function handleMouseOver(e) {
          const columnIndex = getColumnIndex(e.target);

          if (_.isNumber(columnIndex)) {
            highlightColumns(columnIndex, true);
          }
        }

        function handleMouseOut(e) {
          const columnIndex = getColumnIndex(e.target);

          if (_.isNumber(columnIndex)) {
            highlightColumns(columnIndex, false);
          }
        }

        function getColumnIndex(target) {
          const cell = target.closest(':is(td, th):not([scope="colgroup"])');

          if (cell) {
            return cell.cellIndex;
          }

          return null;
        }

        function removeColumnHighlight(columnIndex) {
          const index = columnIndex + 1;
          const matchingColumns = element[0].querySelectorAll(`:is(thead, tbody) .matrix-cell:nth-child(${index})[column-hovered]`);

          _.forEach(matchingColumns, (cell) => {
            cell.removeAttribute('column-hovered');
          });
        }

        function highlightColumns(columnIndex, addHighlight) {
          if (_.isNumber(columnIndex)) {
            if (_.isNumber(currentColumnIndex) && currentColumnIndex !== columnIndex) {
              removeColumnHighlight(currentColumnIndex);
            }

            const index = columnIndex + 1;
            const matchingColumns = element[0].querySelectorAll(`:is(thead, tbody) .matrix-cell:nth-child(${index})`);

            _.forEach(matchingColumns, (cell) => {
              cell.toggleAttribute('column-hovered', addHighlight);
            });

            currentColumnIndex = columnIndex;
          }
        }

        function handleSiblingObserver(nextSibling, newColumnIndex) {
          disconnectExistingObserver(nextSibling);
          addNewObserver(nextSibling, newColumnIndex);
        }

        function disconnectExistingObserver(nextSibling) {
          if (nextSibling && siblingObservers.has(nextSibling)) {
            const { observer } = siblingObservers.get(nextSibling);
            observer.disconnect();
            siblingObservers.delete(nextSibling);
          }
        }

        function addNewObserver(nextSibling, newColumnIndex) {
          if (nextSibling && nextSibling.nodeType === 1) {
            const newObserver = new MutationObserver((mutations) => handleMutations(mutations, nextSibling));

            newObserver.observe(nextSibling, { childList: true, subtree: true });
            siblingObservers.set(nextSibling, { observer: newObserver, columnIndex: newColumnIndex });
          }
        }

        function handleMutations(mutations, nextSibling) {
          _.forEach(mutations, (mutation) => {
            if (mutation.type === 'childList') {
              const addedElements = _.filter(Array.from(mutation.addedNodes), (node) => node.nodeType === 1);

              if (addedElements.length > 0) {
                const { columnIndex } = siblingObservers.get(nextSibling);
                highlightColumns(columnIndex, true);
              }
            }
          });
        }
      }
    };
  });
