'use strict';

angular.module('uasApp').component('appraisalTemplateList', {
  templateUrl: 'es6/assessment/appraisal/template/appraisal.template.list.html',
  bindings: {
    onSelect: '&'
  },
  controllerAs: 'appraisalTemplateController',
  controller: function($uibModal, AppraisalTemplate, Message, Pageable) {
    const appraisalTemplateController = this;

    appraisalTemplateController.$onInit = function () {
      appraisalTemplateController.pageable = Pageable.of({
        order: 'code'
      });

      appraisalTemplateController.search();
    };

    appraisalTemplateController.search = () => {
      const params = appraisalTemplateController.pageable.build({
        text: appraisalTemplateController.searchText || ''
      });

      appraisalTemplateController.loading = true;
      return AppraisalTemplate.page(params).$promise.then((templates) => {
        appraisalTemplateController.templates = templates;

        if (angular.isUndefined(appraisalTemplateController.selected)) {
          const template = _.first(appraisalTemplateController.templates.content);
          appraisalTemplateController.select(template);
        }
      }).finally(() => {
        appraisalTemplateController.loading = false;
      });
    };

    appraisalTemplateController.select = function (template) {
      appraisalTemplateController.selected = template;
      appraisalTemplateController.onSelect({ template });
    };

    appraisalTemplateController.create = function () {
      openModal({});
    };

    appraisalTemplateController.edit = function (template) {
      openModal(angular.copy(template));
    };

    function openModal(template) {
      $uibModal.open({
        templateUrl: 'es6/assessment/appraisal/template/appraisal.template.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.template = template;
          };

          modalController.save = function () {
            AppraisalTemplate.save(modalController.template).$promise.then(() => {
              appraisalTemplateController.search();
              Message.addSuccessLabel('Static.Message.DataAdjusted');
            });
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    appraisalTemplateController.remove = function (template) {
      AppraisalTemplate.remove(template).$promise.then(() => {
        appraisalTemplateController.search();
        Message.addSuccessLabel('Static.Message.DataRemoved');
      });
    };
  }
});