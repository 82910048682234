'use strict';

angular.module('uasApp').component('tabGroupSelect', {
  bindings: {
    classes: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/tabs/group/tab.group.select.html',
  controllerAs: 'tabGroupSelectController',
  controller: function(i18nFilter, Language, TabGroup) {
    const tabGroupSelectController = this;

    tabGroupSelectController.$onInit = function () {
      TabGroup.query().$promise.then((groups) => {
        tabGroupSelectController.groups = groups;
        setDisplayName();
      });

      tabGroupSelectController.ngModelCtrl.$render = function () {
        tabGroupSelectController.model = tabGroupSelectController.ngModelCtrl.$modelValue;
      };

      Language.onChange(setDisplayName);
    };

    function setDisplayName() {
      _.forEach(tabGroupSelectController.groups, (group) => {
        group.displayName = i18nFilter(group.names);
      });
      tabGroupSelectController.groups = _.sortBy(tabGroupSelectController.groups, ['sequence', 'displayName']);
    }

    tabGroupSelectController.onChange = function () {
      tabGroupSelectController.ngModelCtrl.$setViewValue(tabGroupSelectController.model);
    };
  }
});