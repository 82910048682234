'use strict';

angular.module('uasApp').component('personAssignments', {
    templateUrl: 'es6/staffing/assignment/person.assignments.html',
    bindings: {
        person: '<',
        operations: '<'
    },
    controllerAs: 'personAssignmentsController',
    controller: function(Assignment, Pageable) {
        const personAssignmentsController = this;

        personAssignmentsController.$onInit = function() {
            personAssignmentsController.pageable = Pageable.of({
                order: 'role.code'
            });
            
            loadData();
        };

        function loadData() {
            personAssignmentsController.loading = true;
            Assignment.query({
                personId: personAssignmentsController.person.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then((assignments) => {
                personAssignmentsController.assignments = _.sortBy(assignments, ['startDate', 'endDate', 'id']);
            }).finally(() => {
                personAssignmentsController.loading = false;
            });
        }

        personAssignmentsController.refresh = function() {
            loadData();
        };
    }
});