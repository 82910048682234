'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('create-simulation', {
        url: '/simulations/create',
        template: '<simulation-create></simulation-create>',
        breadcrumb: {
            label: 'Static.Page.Simulation.Create',
            root: false
        }
    });

    $stateProvider.state('simulation', {
        url: '/simulations/:id',
        template: '<simulation-edit simulation="simulationStateController.simulation"></simulation-edit>',
        breadcrumb: {
            label: 'Static.Page.Simulation.Edit',
            root: false
        },
        resolve: {
            simulation: function (AcademicYear, $stateParams) {
                return AcademicYear.get({
                    id: $stateParams.id
                }).$promise;
            }
        },
        controllerAs: 'simulationStateController',
        controller: function (simulation) {
            const simulationStateController = this;
            simulationStateController.simulation = simulation;
        }
    });

});
