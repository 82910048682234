'use strict';

angular.module('uasApp')
    .directive('fieldLabel', function() {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/field-label.html',
            scope: {
                path: '@'
            }
        };
    });
