'use strict';

angular.module('uasApp').component('methodLabels', {
    bindings: {
        moduleId: '<'
    },
    templateUrl: 'es6/methods/method.labels.html',
    controllerAs: 'methodLabelsController',
    controller: function (MethodSchema) {
        const methodLabelsController = this;

        methodLabelsController.$onInit = () => {
            MethodSchema.getMethods(methodLabelsController.moduleId).then((categories) => {
                methodLabelsController.categories = categories;
            });
        };
    }
});
