'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Credits
 * @description
 * The credits service.
 */
angular.module('uasApp').factory('Credits', function ($resource, BackendService) {
  const resource = $resource(BackendService.getBaseUrl() + '/credits/:id', {}, {
    'update': {
      method: 'PUT'
    }
  });

  function isValid(value, credits, required) {
    if (angular.isUndefined(credits)) {
      return true;
    }

    const selected = value || 0;
    const minimum = getMinimum(credits);

    let valid = false;
    if (selected >= minimum) {
      const maximum = _.result(credits, 'maximum');
      if (required === true && angular.isDefined(maximum)) {
        valid = maximum >= selected;
      } else {
        valid = true;
      }
    }
    return valid;
  }

  function getMinimum(credits) {
    let minimum = _.result(credits, 'minimum');
    if (angular.isUndefined(minimum)) {
      minimum = _.result(credits, 'optimum', 0);
    }
    return minimum;
  }

  return _.extend(resource, { isValid, getMinimum });
});
