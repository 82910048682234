'use strict';

angular.module('uasApp').component('effortEdit', {
    bindings: {
        effort: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/staffing/effort/effort.edit.html',
    controllerAs: 'effortEditController',
    controller: function(Effort) {
        const effortEditController = this;

        effortEditController.save = function() {
            Effort.store({
                id: effortEditController.effort.id,
                correction: effortEditController.effort.correction,
                description: effortEditController.effort.description
            }).$promise.then((result) => {
                effortEditController.onSave({ effort: result });
            });
        };

        effortEditController.close = function() {
            effortEditController.onClose();
        };
    }
});