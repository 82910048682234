'use strict';

/**
 * @ngdoc config
 * @name uasApp.config:facultiesStates
 * @description The state config for the various reports on faculty level.
 * Optimized for performance and user experience by only (re)loading the required data.
 */
angular.module('uasApp').config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/faculties', function ($injector) {
    // Redirect to a specific faculty tab or fallback route without initializing the controller unnecessarily.
    const Faculties = $injector.get('Faculties');
    const facultyId = Faculties.getFacultyId();
    const $state = $injector.get('$state');
    const fallbackRoute = 'faculties.faculty';

    if (facultyId) {
      Faculties.getTabs()
        .then((tabs) => {
          const lastTab = _.find(tabs, { active: true });
          const defaultTab = _.find(tabs, { default: true });

          const lastTabRoute = _.get(lastTab, 'route');
          const defaultTabRoute = _.get(defaultTab, 'route');

          const route = lastTabRoute || defaultTabRoute || fallbackRoute;

          $state.go(route, {
            facultyId: facultyId
          });
        })
        .catch(() => {
          $state.go(fallbackRoute);
        });
    } else {
      $state.go(fallbackRoute);
    }
  });

  $stateProvider.state('faculties', {
    url: '/faculties?facultyId',
    templateUrl: 'es6/faculties/faculties.html',
    controller: 'FacultiesCtrl',
    abstract: true,
    breadcrumb: {},
    resolve: {
      tabs: function (Faculties) {
        return Faculties.getTabs();
      }
    }
  });

  $stateProvider.state('faculties.faculty', {
    url: '',
    views: {
      'faculties@faculties': {
        templateUrl: 'es6/faculties/faculties.list.html'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Faculties',
      root: true
    }
  });

  $stateProvider.state('faculties.faculty.organisation', {
    url: '/organisations',
    views: {
      'studies@faculties': {
        template: '<faculties-organisations faculty="faculty" operations="operations"></faculties-organisations>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Organisations'
    }
  });

  $stateProvider.state('faculties.faculty.study', {
    url: '/studies',
    views: {
      'studies@faculties': {
        template: '<uas-faculties-studies faculty="faculty" operations="operations"></uas-faculties-studies>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Studies'
    }
  });

  $stateProvider.state('faculties.faculty.group', {
    url: '/groups',
    views: {
      'studies@faculties': {
        template: '<uas-faculties-groups faculty="faculty" operations="operations"></uas-faculties-groups>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Groups'
    }
  });

  $stateProvider.state('faculties.faculty.module', {
    url: '/modules',
    views: {
      'studies@faculties': {
        template: '<faculties-modules faculty="faculty" operations="operations"></faculties-modules>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Modules'
    }
  });

  $stateProvider.state('faculties.faculty.assessment', {
    url: '/assessments',
    views: {
      'studies@faculties': {
        template: '<faculties-assessments faculty="faculty" operations="operations"></faculties-assessments>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Assessments'
    }
  });

  $stateProvider.state('faculties.faculty.specification', {
    url: '/specifications',
    views: {
      'studies@faculties': {
        template: '<faculties-specifications faculty-id="facultyId" operations="operations"></faculties-specifications>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Specifications'
    },
    data: {
      secured: ['VIEW_SPECIFICATIONS']
    }
  });

  $stateProvider.state('faculties.faculty.qualification', {
    url: '/qualifications',
    views: {
      'studies@faculties': {
        template: '<faculties-qualifications faculty="faculty" operations="operations"></faculties-qualifications>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Qualifications'
    }
  });

  $stateProvider.state('faculties.faculty.rule', {
    url: '/rules',
    views: {
      'studies@faculties': {
        template: '<faculties-rules faculty="faculty" operations="operations"></faculties-rules>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Rules'
    }
  });

  $stateProvider.state('faculties.faculty.method', {
    url: '/methods',
    views: {
      'studies@faculties': {
        template: '<faculties-methods faculty="faculty" operations="operations"></faculties-methods>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Methods'
    }
  });

  $stateProvider.state('faculties.faculty.person', {
    url: '/persons',
    views: {
      'studies@faculties': {
        template: '<faculties-persons faculty="faculty" operations="operations"></faculties-persons>'
      }
    },
    reloadOnSearch: false,
    breadcrumb: {
      label: 'Static.Page.Persons'
    },
    data: {
      secured: ['VIEW_PERSONS']
    }
  });
});
