'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:notificationModal
 * @name uasApp.component:notificationModal
 * @description
 * The notificationModal component.
 */
angular.module('uasApp').component('notificationModal', {
    bindings: {
        notification: '<',
        onCancel: '&'
    },
    templateUrl: 'es6/app/notifications/notification.modal.html',
    controllerAs: 'notificationModalController',
    controller: function() {
        const notificationModalController = this;

        notificationModalController.cancel = function() {
            notificationModalController.onCancel();
        };
    }
});