'use strict';

/**
 * Manage features on a faculty.
 */
angular.module('uasApp')
  .component('uasFeatures', {
    templateUrl: 'es6/feature/features.html',
    controllerAs: 'featuresController',
    controller: function ($q, Feature, Enums, Message) {
        const featuresController = this;

        featuresController.$onInit = function() {
            loadData();
        };

        function loadData() {
            featuresController.loading = true;
            return $q.all([
                Feature.query().$promise,
                Enums.constants('FeatureType').$promise
            ]).then(([features, featureTypes]) => {
                featuresController.features = _.map(featureTypes, (featureType) => {
                    let feature = _.find(features, {
                        featureType: featureType
                    });

                    if (angular.isUndefined(feature)) {
                        feature = {
                            featureType: featureType,
                            enabled: false
                        };
                    }

                    return feature;
                });
            }).finally(() => {
                featuresController.loading = false;
            });
        }

        featuresController.onChange = function(feature) {
            feature.dirty = true;
        };

        featuresController.save = function(form) {
            const promises = _(featuresController.features).filter({ dirty: true }).map((feature) => {
                return Feature.store(feature).$promise.then(() => {
                    feature.dirty = false;
                });
            }).value();

            $q.all(promises).then(() => {
                form.$setPristine(true);
                Message.onSaved();
            });
        };

    }
});
