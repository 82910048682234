'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:customFieldButton
 * @description
 * customFieldButton Opens a modal with custom fields
 */
angular.module('uasApp')
    .component('customFieldButton', {
        bindings: {
            customType: '<'
        },
        templateUrl: 'es6/custom/fields/custom.field.button.html',
        controllerAs: 'fieldButtonController',
        controller: function ($uibModal) {
            const fieldButtonController = this;

            fieldButtonController.open = function() {
                $uibModal.open({
                    template: `
                        <div class="modal-header">
                            <button class="close" ng-click="fieldModalController.close()"></button>
                            <h2 class="modal-title" translate>Static.Page.CustomFields</h2>
                        </div>
                        <div class="modal-body">
                            <uas-custom-field-list custom-type="fieldModalController.customType"></uas-custom-field-list>
                        </div>
                        <div class="modal-footer">
                            <cancel-button class="pull-left" ng-click="fieldModalController.close()"></cancel-button>
                        </div>
                    `,
                    size: 'xl',
                    controllerAs: 'fieldModalController',
                    controller: function ($uibModalInstance) {
                        const fieldModalController = this;
    
                        fieldModalController.$onInit = function() {
                            fieldModalController.customType = angular.copy(fieldButtonController.customType);
                        };

                        fieldModalController.close = function() {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            };
        }
    });
