'use strict';

angular.module('uasApp').directive('taMaxLength', (taLength) => {
  return {
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {

      ctrl.$validators.taMaxLength = (modelValue) => {
        if (attrs.taMaxLength === '') {
          return true;
        }
        return taLength.getTextLength(modelValue) <= attrs.taMaxLength;
      };
    }
  };
});
