'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasHistoric
 * @description
 * Wraps HTML with a historical label.
 */
angular.module('uasApp').component('uasHistoric', {
  bindings: {
    active: '<?',
    entity: '<?',
    root: '<?',
    changeType: '<',
    displayType: '@?',
    viewClasses: '@?',
    brand: '@?'
  },
  transclude: true,
  templateUrl: 'es6/changes/historic.html',
  controllerAs: 'historicController',
  controller: function(ChangeModal) {
    const historicController = this;

    historicController.$onChanges = function() {
      if (_.isEmpty(historicController.displayType)) {
        historicController.displayType = 'label';
      }

      historicController.changeClasses = getChangeClasses();
      updateVisible();
    };

    function getChangeClasses() {
      if (historicController.changeType === 'CREATE') {
        return 'success';
      } else if (historicController.changeType === 'REMOVE') {
        return 'danger text-strikethrough';
      } else if (historicController.changeType === 'MODIFY') {
        return 'warning';
      } else {
        return historicController.brand || '';
      }
    }

    function updateVisible() {
      const active = _.result(historicController, 'active', false);
      historicController.visible = !active && historicController.changeClasses;
    }

    historicController.open = function($event) {
      if (angular.isDefined(historicController.entity)) {
        $event.preventDefault();
        $event.stopPropagation();

        ChangeModal.openChanges(historicController.entity, historicController.root !== false);
      }
    };
  }
});