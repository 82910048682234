'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Weekday
 * @description The Weekday service.
 */
angular.module('uasApp').factory('Weekday', function (Element) {
  const WEEKDAYS = [
    { day: 1, code: 'MONDAY', ignore: false },
    { day: 2, code: 'TUESDAY', ignore: false },
    { day: 3, code: 'WEDNESDAY', ignore: false },
    { day: 4, code: 'THURSDAY', ignore: false },
    { day: 5, code: 'FRIDAY', ignore: false },
    { day: 6, code: 'SATURDAY', ignore: true },
    { day: 7, code: 'SUNDAY', ignore: true }
  ];

  function getVisible() {
    return Element.getActive('WEEKDAY').then((elements) => {
      if (elements.length === 0) {
        return WEEKDAYS;
      }

      return _.map(WEEKDAYS, (weekDay) => {
        const element = _.find(elements, { code: weekDay.code });
        return _.extend(element, { day: weekDay.day });
      });
    }).then((weekDays) => _.filter(weekDays, { ignore: false }));
  }

  return { getVisible };
});
