'use strict';

angular.module('uasApp').filter('prettyJson', function() {
    return function(json) {
        if (!json) {
            return json;
        }

        try {
            const parsed = angular.fromJson(json);
            return angular.toJson(parsed, true);
        } catch (err) {
            return json;
        }
    };
});
