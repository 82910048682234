'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasExternalSystemList
 * @uasExternalSystemList
 * uasExternalSystemList Manages the external systems
 */
angular.module('uasApp')
  .component('uasExternalSystemList', {
    templateUrl: 'es6/integration/system/external.system.list.html',
    controllerAs: 'externalSystemListController',
    controller: function ($q, $uibModal, Pageable, ExternalSystem, Message) {
      const externalSystemListController = this;

      externalSystemListController.$onInit = function () {
        externalSystemListController.pageable = Pageable.of({
          order: 'code'
        });

        loadData();
      };

      function loadData() {
        const params = externalSystemListController.pageable.build();

        externalSystemListController.loading = true;
        $q.all([
          ExternalSystem.get(params).$promise,
          getClients()
        ]).then(([systems, clients]) => {
          _.forEach(systems.content, (system) => {
            system.available = ExternalSystem.isAvailable({ system, clients });
          });

          externalSystemListController.systems = systems;
        }).finally(() => {
          externalSystemListController.loading = false;
        });
      }

      function getClients() {
        if (angular.isDefined(externalSystemListController.clients)) {
          return $q.resolve(externalSystemListController.clients);
        }

        return ExternalSystem.clients().$promise.then((clients) => {
          externalSystemListController.clients = clients;
          return clients;
        });
      }

      externalSystemListController.onPage = function() {
        loadData();
      };

      externalSystemListController.remove = function (system) {
        ExternalSystem.remove(system).$promise.then(() => {
          Message.onRemoved();
          loadData();
        });
      };

      externalSystemListController.edit = function(system) {
        open(angular.copy(system));
      };

      externalSystemListController.create = function() {
        open({});
      };

      function open(system) {
        $uibModal.open({
          template: `
            <uas-external-system 
              modal-instance="externalSystemModalController.modalInstance" 
              system="externalSystemModalController.system"
              on-save="externalSystemModalController.onSave()">
            </uas-method-type>
          `,
          controllerAs: 'externalSystemModalController',
          controller: function ($uibModalInstance) {
            this.system = angular.copy(system);
            this.modalInstance = $uibModalInstance;
            this.onSave = function () {
              Message.onSaved();
              loadData();
            };
          }
        });
      }
    }
  });
