'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uasLanguageSelect
 * @description
 * The uasLanguageSelect directive.
 */
angular.module('uasApp').component('uasLanguageSelect', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    id: '@?',
    languages: '<?',
    squared: '<?',
    ngChange: '&?'
  },
  templateUrl: 'es6/i18n/language.select.html',
  controllerAs: 'languageSelectController',
  controller: function (Language) {
    const languageSelectController = this;

    languageSelectController.$onInit = function () {      
      languageSelectController.ngModelCtrl.$render = function () {
        languageSelectController.current = languageSelectController.ngModelCtrl.$modelValue;
      };
    };

    languageSelectController.$onChanges = function () {
      setLanguages();
    };

    function setLanguages() {
      if (angular.isUndefined(languageSelectController.languages)) {
        languageSelectController.languages = Language.supported;
      }

      languageSelectController.languages_ = _.map(languageSelectController.languages, (value) => {
        return {
          label: `Enum.${value}`,
          icon: Language.getIcon(value),
          value
        };
      });
    }

    languageSelectController.onSelect = function (newLanguage) {
      languageSelectController.current = newLanguage;
      languageSelectController.ngModelCtrl.$setViewValue(newLanguage);
      
      if (_.isFunction(languageSelectController.ngChange)) {
        languageSelectController.ngChange({ language: newLanguage });
      }
    };
  }
});
