'use strict';

angular.module('uasApp').component('personAvailability', {
    templateUrl: 'es6/staffing/availability/person.availability.html',
    bindings: {
        person: '<'
    },
    controllerAs: 'personAvailabilityController',
    controller: function(Assignment) {
        const personAvailabilityController = this;

        personAvailabilityController.$onInit = function() {
            loadData();
        };

        function loadData() {
            personAvailabilityController.loading = true;
            Assignment.query({
                personId: personAvailabilityController.person.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then((assignments) => {
                personAvailabilityController.assignments = _.orderBy(assignments, ['fte', 'id'], ['desc', 'asc']);
            }).finally(() => {
                personAvailabilityController.loading = false;
            });
        }
    }
});