'use strict';

angular.module('uasApp').component('effortListPage', {
    bindings: {
        entity: '<',
        page: '<',
        workflow: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/staffing/effort/effort.list.page.html',
    controllerAs: 'effortListPageController',
    controller: function(Page, EntityService, TaskTemplate) {
        const effortListPageController = this;

        effortListPageController.$onInit = function() {
            effortListPageController.readOnly = effortListPageController.isReadOnly || Page.getParameterAsBoolean(effortListPageController.page, 'readOnly', false);
            effortListPageController.showUnassigned = Page.getParameterAsBoolean(effortListPageController.page, 'showUnassigned', false);

            loadData();
        };

        function loadData() {
            const entityType = EntityService.getType(effortListPageController.entity);

            effortListPageController.loading = true;
            TaskTemplate.query({
                academicYearId: sessionStorage.academicYear,
                entityId: effortListPageController.entity.id,
                entityType
            }).$promise.then((templates) => {
                effortListPageController.templates = _.sortBy(templates, ['sequence', 'code', 'id']);
            }).finally(() => {
                effortListPageController.loading = false;
            });
        }
    }
});
