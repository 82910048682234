'use strict';

angular.module('uasApp').factory('StudiesReport', function ($resource, BackendService) {
  return $resource(BackendService.getBaseUrl() + '/report/studies', {}, {
    'studies': {
      url: BackendService.getBaseUrl() + '/report/studies',
      method: 'GET',
      isArray: false
    },
    'types': {
      url: BackendService.getBaseUrl() + '/report/studies/types',
      method: 'GET',
      isArray: true
    }
  });
});
