'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:CustomField
 * @description Factory for Custom fields
 */
angular.module('uasApp')
    .factory('CustomField', function (UasResource, EntityService, i18nFilter, enumFilter, typeFilter, referenceTypeFilter) {
        const EMPTY_SECTION = {
            id: -1,
            sequence: -1,
            names: []
        };

        function getSections(fields) {
            _.forEach(fields, (field) => field.section = _.get(field, 'section', EMPTY_SECTION));

            const sections = _(fields)
                .filter((field) => angular.isDefined(field.section))
                .map('section')
                .uniqBy('id')
                .sortBy(['sequence'])
                .value();

            const grouped = _.groupBy(fields, 'section.id');
            _.forEach((grouped), (value, key) => {
                const section = _.find(sections, { id: parseInt(key) });
                section.fields = value;
                section.fields[0].rowSpan = section.fields.length;
            });

            return sections;
        }

        function parseValues(values, field) {
            const parsedValues = _.map(values, (value) => parseValue(value, field.valueType));
            if (field.multipleValues) {
                return parsedValues || [];
            }

            let parsedValue = _.head(parsedValues);
            if (angular.isUndefined(parsedValue)) {
                parsedValue = getEmptyValue(field);
            }
            return parsedValue;
        }

        function parseValue(value, valueType) {
            if (_.isArray(value)) {
                return _.map(value, (it) => parseValue(it, valueType));
            }

            if (_.isString(value) && !_.isEmpty(value)) {
                if (valueType === 'ELEMENT' || valueType === 'REFERENCE' || valueType === 'ENTITY') {
                    value = parseInt(value);
                } else if (valueType === 'BOOLEAN') {
                    value = value === 'true';
                } else if (valueType === 'NUMBER') {
                    value = parseFloat(value);
                }
            } else if (!_.isString(value) && !_.isObject(value) && isNotEmpty(value) && valueType === 'STRING') {
                value = _.toString(value);
            }

            return value;
        }

        function hasValue(value) {
            if (_.isArray(value)) {
                return _.some(value, isNotEmpty);
            }

            return isNotEmpty(value);
        }

        function isNotEmpty(value) {
            return angular.isDefined(value) && value !== '' && value !== null;
        }

        function extend(fields, extensions) {
            if (_.isEmpty(extensions)) {
              return fields;
            }

            return _.map(fields, (field) => {
                const extension = _.find(extensions, { name: field.name });
                const copied = angular.copy(field);
                return _.extend(copied, extension);
            });
        }

        function hide(fields, name) {
            if (_.isArray(name)) {
                _.forEach(name, (value) => hide(fields, value));
            } else if (_.isString(name)) {
                _(fields).filter({ name }).forEach((field) => {
                    field.visible = false;
                });
            }

            return fields;
        }

        function show(fields, names) {
            _.forEach(fields, (field) => {
                field.visible = _.includes(names, field.name);
            });
            return fields;
        }

        function getLabel(field, pattern) {
            const displayName = getDisplayName(field);
            return displayName || getLabelKey(field, pattern);
        }

        function getDisplayName(field) {
            if (angular.isUndefined(field)) {
                return '';
            }

            const labels = _.get(field, 'labels', []);
            return i18nFilter(labels);
        }

        function getLabelKey(field, pattern) {
            const fieldName = _.get(field, 'name');
            const displayName = _.upperFirst(fieldName);

            if (!pattern) {
                return displayName;
            }

            return pattern.replace(/{name}/g, displayName);
        }

        function getEvaluation(entity, container, fields) {
            const values = getValues(container, fields);

            return {
                entity: EntityService.toReference(entity),
                values
            };
        }

        function getFilter(field, evaluation) {
            let filter = _.get(field, 'filter', '');
            if (angular.isDefined(evaluation)) {
                let values = evaluation.values;
                if (angular.isDefined(evaluation.entity.id)) {
                    values = EntityService.extend(evaluation.values, evaluation.entity);
                }

                _.forEach(values, (value, name) => {
                    const replacement = _.isNumber(value) ? `${value}` : `'${value}'`;
                    filter = filter.replace(`':${name}'`, replacement);
                });
            }
            return filter;
        }

        function getValues(container, fields) {
            const properties = getProperties(container);
            const values = _.get(container, 'values', {});
            const combined = _.extend(properties, values);

            return _.transform(combined, (result, value, name) => {
                let field = _.find(fields, { name });
                if (angular.isUndefined(field) && name.match('\\d+')) {
                    field = _.find(fields, { id: parseInt(name) });
                }

                if (angular.isDefined(field)) {
                    const valueType = _.get(field, 'valueType', 'STRING');
                    const parsedValue = parseValue(value, valueType);
                    result[field.name] = _.defaultTo(parsedValue, getEmptyValue(field));
                } else {
                    result[name] = value;
                }
            }, {});
        }

        function getProperties(container) {
            const properties = _.omit(container, ['id', 'self', 'values', 'created', '$resolved']);
            return _.omitBy(properties, (value) => !_.isArray(value) && _.isObject(value));
        }

        function isEditable(field, entity, root) {
            if (angular.isUndefined(entity) || angular.isUndefined(field) || field.readOnly) {
                return false;
            }

            const publicationDate = _.get(root || entity, 'publicationDate');

            switch (field.editable || 'ALWAYS') {
                case 'ALWAYS':
                    return true;
                case 'NEW':
                    return angular.isUndefined(entity.id);
                case 'EXISTING':
                    return angular.isDefined(entity.id);
                case 'UNPUBLISHED':
                    return angular.isUndefined(publicationDate);
                case 'PUBLISHED':
                    return angular.isDefined(publicationDate);
                default:
                    return false;
            }
        }

        function disable(field) {
            let disabled = angular.copy(field);
            disabled = _.omit(disabled, ['id', 'pattern', 'maxCharacters', 'min', 'max', 'decimals']);

            // Default value should be correctly set for read-only required fields
            const required = field.required && angular.isDefined(field.defaultValue);

            return _.extend(disabled, { required, readOnly: true });
        }

        function require(field) {
            return _.assign(angular.copy(field), { required: true });
        }

        function getSubTypeName(field) {
            if (!field.subType) {
                return '';
            }

            if (field.valueType === 'REFERENCE') {
                return referenceTypeFilter(field.subType);
            } else if (field.valueType === 'ELEMENT') {
                return enumFilter(field.subType);
            }
            return typeFilter(field.subType);
        }

        function keyByName(fields) {
            return _.keyBy(fields, 'name');
        }

        function getDefaultValue(field) {
            if (angular.isDefined(field.defaultValue)) {
                return parseValue(field.defaultValue, field.valueType);
            }

            const valueType = _.get(field, 'valueType');
            if (valueType === 'BOOLEAN') {
                return false;
            }
        }

        function getEmptyValue(field) {
            const valueType = _.get(field, 'valueType');
            if (valueType === 'BOOLEAN') {
                return false;
            } else if (valueType === 'NUMBER') {
                return 0;
            }

            return '';
        }

        const resource = UasResource('/custom-field').withCache('custom-fields');
        return _.extend(resource, {
            getEvaluation,
            getFilter,
            getSections,
            getSubTypeName,
            getLabel,
            getDefaultValue,
            parseValues,
            parseValue,
            hasValue,
            extend,
            show,
            hide,
            disable,
            require,
            isEditable,
            keyByName
        });
    });
