'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodAdd
 * @description
 * # methodAdd
 * The component for adding a method.
 */
 angular.module('uasApp').component('methodAdd', {
    bindings: {
        module: '<',
        methods: '<',
        category: '<',
        operations: '<',
        operationsToEdit: '<',
        askComments: '<',
        onAdd: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/methods/modal/method.add.html',
    controllerAs: 'methodAddController',
    controller: function() {
        const methodAddController = this;

        methodAddController.$onInit = function() {
            methodAddController.method = {};
            methodAddController.selected = false;
        };

        methodAddController.onSelect = function() {
            methodAddController.method.type = _.find(methodAddController.methods, {
                id: methodAddController.method.typeId
            });
        };

        methodAddController.add = function() {
            methodAddController.onAdd({
                method: methodAddController.method,
                comment: {
                    message: methodAddController.message
                }
            });
        };

        methodAddController.cancel = function() {
            methodAddController.onCancel();
        };

    }
});