'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:MethodType
 * @description
 * The MethodType service.
 */
angular.module('uasApp').factory('MethodType', function (Dates, UasResource) {
    const resource = UasResource('/method-types');
  
    resource.getAll = function (period) {
      return resource.query().$promise.then((types) =>
        Dates.setActive(types, period)
      );
    };

    return resource;
});