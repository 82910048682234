'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Simulation
 * @description
 * The Simulation service.
 */
angular.module('uasApp')
  .factory('Simulation', function (UasResource) {
    return UasResource('/simulation', {
      'mine': {
        method: 'GET',
        url: '/simulation/mine',
        isArray: false
      }
    });
  });
