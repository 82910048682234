'use strict';

angular.module('uasApp').component('markdownText', {
  bindings: {
    inputId: '@?',
    language: '@',
    editor: '@?',
    maxCharacters: '<?',
    isRequired: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    showRequiredLabel: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/markdown.text.html',
  controllerAs: 'markdownTextController',
  controller: function (Parameter) {
    const markdownTextController = this;

    markdownTextController.$onInit = function () {
      markdownTextController.name = markdownTextController.editor || 'markdown.editor';
      markdownTextController.countPlainText = Parameter.getParameterAsBoolean(markdownTextController.name +'.count.plain_text', true);

      markdownTextController.ngModelCtrl.$render = function () {
        markdownTextController.model = markdownTextController.ngModelCtrl.$modelValue;
      };
    };

    markdownTextController.getButtons = function () {
      return Parameter.load().then(() => {
        return Parameter.getParameterAsList(markdownTextController.name);
      });
    };

    markdownTextController.onChange = function () {
      markdownTextController.ngModelCtrl.$setViewValue(markdownTextController.model);
    };
  }
});
