'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ChangeExportAllCtrl
 * @description # ChangeExportAllCtrl
 */
angular.module('uasApp')
    .controller('ChangeExportAllCtrl', function($scope, $q, $uibModalInstance, systemId, study, loadChangesByRoot, onExport, Export, ExportDetails) {

        $scope.study = study.study;
        $scope.entities = study.entities;
        $scope.running = false;

        $scope.export = function() {
            $scope.running = true;

            const promises = _.map($scope.entities, loadChangesByRoot);
            $q.all(promises).then((roots) => {
                const entities = _(roots).map('changes').flatten().map((change) => {
                    return {
                        reference: change.entity,
                        minChangeId: change.minChangeId,
                        maxChangeId: change.maxChangeId
                    };
                });

                return {
                    root: {
                        type: 'study',
                        id: $scope.study.id
                    },
                    academicYearId: sessionStorage.academicYear,
                    entities: entities,
                    systemId: systemId
                };
              })
              .then((command) => Export.submit(command).$promise)
              .then((lines) => {
                  onExport(lines);
                  $uibModalInstance.close();
                  ExportDetails.show(lines);
              })
              .finally(() => {
                  $scope.running = false;
              });
        };

    });
