'use strict';

angular.module('uasApp').component('teamRelations', {
  bindings: {
    team: '<'
  },
  templateUrl: 'es6/team/relations/team.relations.html',
  controllerAs: 'teamRelationsController',
  controller: function(Language, Pageable, Relation, entityTranslateFilter) {
    const teamRelationsController = this;

    teamRelationsController.$onInit = function() {
      teamRelationsController.pageable = Pageable.of({
        order: 'translatedRole'
      });

      loadData();
    };

    function loadData() {
      teamRelationsController.loading = true;

      return Relation.query({
        teamId: teamRelationsController.team.id,
        academicYearId: sessionStorage.academicYear
      }).$promise.then((relations) => {
        teamRelationsController.relations = _.map(relations, (relation) => {
          relation.translatedRole = entityTranslateFilter(relation.role);
          return relation;
        });
      }).finally(() => {
        teamRelationsController.loading = false;
      });
    }

    Language.onChange(() => {
      if (_.get(teamRelationsController, 'pageable.order') === 'translatedRole' && _.isArray(teamRelationsController.relations)) {
        teamRelationsController.relations = _.forEach(teamRelationsController.relations, (relation) => {
          relation.translatedRole = entityTranslateFilter(relation.role);
        });
      }
    });
  }
});
