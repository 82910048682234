'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:academicYearList
 * @description
 * # academicYearList
 * Manages academic year entities.
 */
angular.module('uasApp').component('academicYearList', {
    templateUrl: 'es6/admin/year/year.list.html',
    controllerAs: 'academicYearListController',
    controller: function (Pageable, Message, State, AcademicYear) {
        const academicYearListController = this;

        academicYearListController.$onInit = function() {
            academicYearListController.pageable = Pageable.of({
                order: 'externalId',
                reverse: true
            });
            
            loadData();
        };

        function loadData() {
            academicYearListController.loading = true;
            AcademicYear.query().$promise.then((years) => {
                academicYearListController.years = _.filter(years, { simulation: false });
            }).finally(() => {
                academicYearListController.loading = false;
            });
        }

        academicYearListController.remove = function(year) {
            AcademicYear.remove({
                id: year.id
            }).$promise.then(() => {
                Message.onRemoved();
                State.reload();
            });
        };
    }
});