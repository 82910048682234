'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCustomFieldList
 * @description
 * uasCustomFieldList Manages the custom fields
 */
angular.module('uasApp')
    .component('uasCustomFieldList', {
        bindings: {
            customType: '<'
        },
        templateUrl: 'es6/custom/fields/custom.field.list.html',
        controllerAs: 'fieldListController',
        controller: function ($uibModal, CustomField, Dates, filterFilter, Language) {
            const fieldListController = this;

            fieldListController.$onInit = function () {
                fieldListController.active = 'true';

                loadData();
                Language.onChange(setDisplayName);
            };

            function loadData() {
                fieldListController.loading = true;
                CustomField.queryWithoutCache({
                    type: fieldListController.customType.id
                }).$promise.then((fields) => Dates.setActive(fields)).then((fields) => {
                    fieldListController.fields = _.orderBy(fields, ['visible', 'sequence', 'name', 'id'], ['desc', 'asc', 'asc', 'asc']);
                    setDisplayName();
                    fieldListController.search();
                }).finally(() => {
                    fieldListController.loading = false;
                });
            }

            function setDisplayName() {
                _.forEach(fieldListController.fields, (field) => {
                    field.subTypeName = CustomField.getSubTypeName(field);
                });
            }

            fieldListController.search = function () {
                let filteredFields = filterFilter(fieldListController.fields, fieldListController.searchTerm);
                if (fieldListController.active) {
                    const active = fieldListController.active === 'true';
                    filteredFields = _.filter(filteredFields, { active });
                }
                fieldListController.visibleFields = filteredFields;
            };

            fieldListController.create = function () {
                open({
                    type: fieldListController.customType.id,
                    valueType: 'STRING',
                    visible: true,
                    editable: 'ALWAYS',
                    readOnly: false
                });
            };

            fieldListController.edit = function (field) {
                open(field);
            };

            function open(field) {
                $uibModal.open({
                    template: `
                        <custom-field
                            custom-field="fieldController.field"
                            on-save="fieldController.save(result)"
                            on-cancel="fieldController.close()">
                        </custom-field>
                    `,
                    size: 'lg',
                    controllerAs: 'fieldController',
                    controller: function ($uibModalInstance) {
                        const fieldController = this;

                        fieldController.$onInit = function () {
                            fieldController.field = angular.copy(field);
                        };

                        fieldController.save = function () {
                            loadData();
                            fieldController.close();
                        };

                        fieldController.close = function () {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            }

            fieldListController.delete = function (index, customField) {
                CustomField.remove({
                    id: customField.id
                }).$promise.then(loadData);
            };
        }
    });
