'use strict';

angular.module('uasApp').component('labelInput', {
  bindings: {
    inputId: '@',
    translateValues: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/admin/labels/input/label.input.html',
  controllerAs: 'labelInputController',
  controller: function ($element, $translate, $q, $uibModal, translateFilter, Label) {
    const labelInputController = this;

    labelInputController.$onInit = function () {
      labelInputController.ngModelCtrl.$render = function () {
        labelInputController.label = labelInputController.ngModelCtrl.$modelValue;
        setPreview();
      };
    };

    labelInputController.onBlur = function () {
      if (angular.isDefined(labelInputController.term)) {
        labelInputController.label = labelInputController.term;
        setValue(labelInputController.term);
      }
    };

    labelInputController.onSelect = function ($item) {
      delete labelInputController.term;
      if (angular.isDefined($item)) {
        setValue($item.key);
      }
    };

    function setValue(label) {
      labelInputController.ngModelCtrl.$setViewValue(label);
      setPreview();
    }

    function setPreview() {
      let translation = labelInputController.label;
      if (labelInputController.label) {
        translation = translateFilter(labelInputController.label, labelInputController.translateValues);
      }

      if (translation !== labelInputController.label) {
        labelInputController.preview = translation;
      } else {
        delete labelInputController.preview;
      }
    }

    labelInputController.onType = function (term) {
      labelInputController.term = term || '';
      return search(term);
    };

    function search(key) {
      labelInputController.loading = true;
      return Label.search({
        key,
        size: 20,
        sort: ['key,ASC']
      }).$promise.then((page) => {
        return _.uniqBy(page.content || [], 'key');
      }).finally(() => {
        labelInputController.loading = false;
      });
    }

    labelInputController.clear = function () {
      delete labelInputController.label;
      setValue();
      focus();
    };

    function focus() {
      $element.find('input')[0].focus();
    }

    labelInputController.edit = function () {
      $uibModal.open({
        template: `
          <label-edit
            label-key="labelModalController.labelKey"
            on-change="labelModalController.onChange()"
            on-cancel="labelModalController.close()">
          </label-edit>
        `,
        controllerAs: 'labelModalController',
        controller: function($uibModalInstance) {
          const labelModalController = this;
          labelModalController.labelKey = labelInputController.label;

          labelModalController.onChange = function() {
            labelModalController.close();
            $translate.refresh().then(() => {
              setPreview();
            });
          };

          labelModalController.close = function() {
            $uibModalInstance.dismiss();
          };
        }
      });
    };
  }
});
