'use strict';

angular.module('uasApp').config(function($stateProvider) {
    $stateProvider.state('choice-types', {
        url: '/choice-types',
        template: '<choice-type-list></choice-type-list>',
        breadcrumb: {
            label: 'Static.Page.ChoiceTypes',
            translate: true,
            root: true
        },
        admin: true
    });
});
