'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:LoggedIn
 * @description
 * The LoggedIn service.
 */
angular.module('uasApp').factory('LoggedIn', function (UasResource) {
	return UasResource('/logged-in', {
		'daily': {
			url: '/logged-in/daily',
			method: 'GET',
			isArray: true
		}
	});
});
