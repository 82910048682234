'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:costPage
 * @description
 * costPage Shows the costs.
 */
angular.module('uasApp')
  .component('costPage', {
    bindings: {
      entity: '<',
      page: '<',
      process: '<?',
      workflow: '<?',
      operations: '<'
    },
    templateUrl: 'es6/cost/cost.page.html',
    controllerAs: 'costPageController',
    controller: function (EntityService, Page) {
      const costPageController = this;

      costPageController.$onInit = function () {
        costPageController.readOnly = costPageController.isReadOnly || Page.getParameterAsBoolean(costPageController.page, 'readOnly', false);
        costPageController.entityType = EntityService.getType(costPageController.entity);
      };
    }
  });
