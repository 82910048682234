'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasAdminScript
 * @description
 * uasAdminScript Shows admin script page
 */
angular.module('uasApp')
  .component('uasAdminScript', {
    templateUrl: 'es6/admin/scripts/admin.script.html',
    controllerAs: 'scriptController',
    controller: function($interval, Script, ScheduledScript, Message) {
      const scriptController = this;

      scriptController.$onInit = function () {
        scriptController.queue = [];
        scriptController.intervalQueue = $interval(loadQueue, 5000);
        loadQueue();
        loadData();
      };

      scriptController.$onDestroy = function() {
        $interval.cancel(scriptController.intervalQueue);
      };

      function loadData() {
        scriptController.schedules = ScheduledScript.query();
        Script.query().$promise.then((scripts) => {
          scriptController.scripts = _.sortBy(scripts, 'name');
        });
      }

      function loadQueue() {
        Script.queue().$promise.then((queue) => {
          scriptController.queue = queue;
        });
      }

      scriptController.onSelect = function() {
        const script = _.find(scriptController.scripts, { name: scriptController.name });
        scriptController.arguments = _.result(script, 'variables') || [];
        _.each(scriptController.arguments, (argument) => {
          argument.isRequired = argument.required === true && !argument.defaultValue;
        });
      };

      scriptController.schedule = function() {
        const body = buildBody();
        body.cron = scriptController.cron;
        ScheduledScript.save(body).$promise.then((result) => {
          scriptController.schedules.push(result);
        });
      };

      scriptController.remove = function(schedule) {
        ScheduledScript.remove({
          id: schedule.id
        }, {}).$promise.then(() => {
          _.remove(scriptController.schedules, schedule);
        });
      };

      scriptController.execute = function() {
        const body = buildBody();

        scriptController.executing = true;
        Script.save(body).$promise.then((result) => {
          Message.addSuccess('Executed script');
          scriptController.queue = scriptController.queue.concat(result);
        }).finally(() => {
          scriptController.executing = false;
        });
      };

      function buildBody() {
          const body = {
            name: scriptController.name,
            arguments: {}
          };

          _.forEach(scriptController.arguments, (argument) => {
            if (!_.isEmpty(argument.value)) {
              body.arguments[argument.name] = argument.value;
            }
          });

          return body;
      }

      scriptController.clearQueue = function() {
        Script.clearQueue().$promise.then(() => {
          return loadQueue();
        });
      };
    }
});
