'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:schedulePreferenceReport
 * @schedulePreferenceReport
 * schedulePreferenceReport Schedule preference report for a period
 */
angular.module('uasApp').component('schedulePreferenceReport', {
  bindings: {
    periodId: '<',
    facultyId: '<'
  },
  templateUrl: 'es6/schedule/preference/schedule.preference.report.html',
  controllerAs: 'reportController',
  controller: function ($q, $uibModal, $state, Period, SchedulePreference, entityTranslateFilter) {
    const reportController = this;

    reportController.$onInit = function () {
      reportController.period = Period.get({ id: reportController.periodId });

      Period.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((periods) => {
        reportController.periods = _(periods).filter({ planning: true }).sortBy('start').value();
      });
    };

    reportController.getReport = function () {
      if (angular.isDefined(reportController.facultyId)) {
        return SchedulePreference.report({
          facultyId: reportController.facultyId,
          periodId: reportController.periodId
        }).$promise.then((reports) => {
          return _.forEach(reports, (report) => {
            report.entity.prefix = report.entity.code;
            report.entity.displayName = entityTranslateFilter(report.entity);
          });
        });
      }
      return $q.resolve();
    };

    reportController.onFacultySelected = function (facultyId) {
      $state.go('schedule-preference-report', { periodId: reportController.periodId, facultyId });
    };

    reportController.open = function (studyId, column, type) {
      $uibModal.open({
        templateUrl: 'es6/schedule/preference/schedule.preference.report.details.html',
        size: 'lg',
        controllerAs: 'detailsController',
        controller: function ($uibModalInstance, COLUMN_TYPES, Study) {
          const detailsController = this;

          detailsController.$onInit = function () {
            detailsController.studyId = studyId;
            detailsController.period = reportController.period;
            detailsController.close = $uibModalInstance.dismiss;

            detailsController.columns = [
              { code: 'person.fullName', label: 'Static.Report.PersonName', visible: true },
              { code: 'person.email', label: 'Static.Report.PersonEmail' },
              { code: 'period.localName', label: 'Static.Report.PeriodLocalName', visible: true },
              { code: 'period.englishName', label: 'Static.Report.PeriodEnglishName' },
              { code: 'day', label: 'Static.Page.SchedulePreference.Day', valueType: COLUMN_TYPES.DAY, visible: true },
              { code: 'timeBlock.externalId', label: 'Static.Page.SchedulePreference.Timeblock', visible: true },
              { code: 'type', label: 'Static.Page.SchedulePreference.Type', valueType: COLUMN_TYPES.ENUM, visible: true }
            ];

            loadStudies();
          };

          function loadStudies() {
            return Study.query({
              facultyId: reportController.facultyId
            }).$promise.then((studies) => {
              detailsController.studies = studies;
            });
          }

          detailsController.retrieveData = function (pageable) {
            const params = angular.copy(pageable);
            _.extend(params, {
              studyId: detailsController.studyId,
              facultyId: reportController.facultyId,
              periodId: reportController.periodId,
              day: _.get(column, 'day'),
              timeBlockId: _.get(column, 'timeBlockId'),
              type
            });

            return SchedulePreference.reportDetails(params).$promise;
          };

          detailsController.onSearch = function () {
            detailsController.reload = {
              reset: true
            };
          };
        }
      });
    };
  }
});
