'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasRoleTypeList
 * @description
 * uasRoleTypeList Shows the role types in list.
 */
angular.module('uasApp')
    .component('uasRoleTypeList', {
        templateUrl: 'es6/staffing/role/role.type.list.html',
        controllerAs: 'roleTypeListController',
        controller: function (RoleType, Message, Pageable, $uibModal) {
            const roleTypeListController = this;

            roleTypeListController.$onInit = function () {
                roleTypeListController.pageable = Pageable.of({
                    order: 'sequence'
                });

                loadData();
            };

            function loadData() {
                const params = {
                    academicYearId: roleTypeListController.active ? sessionStorage.academicYear : undefined
                };

                roleTypeListController.loading = true;
                return RoleType.query(params).$promise.then((types) => {
                    roleTypeListController.types = types;
                }).finally(() => {
                    roleTypeListController.loading = false;
                });
            }

            roleTypeListController.onChange = function () {
                loadData();
            };

            roleTypeListController.remove = function (type) {
                RoleType.delete({
                    id: type.id,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then(() => {
                    loadData().then(() => {
                        const roleType = _.find(roleTypeListController.types, { id: type.id });
                        if (!roleType) {
                            Message.addSuccessLabel('Static.Message.DataRemoved');
                        } else {
                            Message.addSuccessLabel('Static.Page.RoleTypes.DeleteEndDateSet');
                        }
                    });
                });
            };

            roleTypeListController.add = function () {
                roleTypeListController.edit({});
            };

            roleTypeListController.edit = function (type) {
                $uibModal.open({
                    controllerAs: 'modalController',
                    controller: function ($uibModalInstance) {
                        const modalController = this;
                        modalController.modal = $uibModalInstance;
                        modalController.type = type;
                        modalController.onSave = function () {
                            Message.addSuccessLabel('Static.Message.DataSaved');
                            loadData();
                        };
                    },
                    size: 'lg',
                    template: '<uas-role-type type="modalController.type" on-save="modalController.onSave" modal="modalController.modal"></uas-role-type>'
                });
            };
        }
    });
