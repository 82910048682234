'use strict';

// Routes for the login functionality.
angular.module('uasApp')
    .config(function ($stateProvider) {
        $stateProvider.state('login', {
            url: '/login?successUrl',
            template: `
                <uas-login 
                    redirect="true" 
                    success-url="loginRouteController.successUrl">
                </uas-login>
            `,
            controllerAs: 'loginRouteController',
            controller: function ($stateParams) {
                const loginRouteController = this;
                loginRouteController.successUrl = $stateParams.successUrl;
            },
            data: {
                public: true,
                root: true
            }
        });
    })
    .run(function ($rootScope, AuthService, $state, $window) {
        /*
         On each transition to another state we check if the user is
         allowed to make the transition.
         */
        const unregisterStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState) {
            // Public pages are always available
            if (_.get(toState, 'data.public', false) === true) {
                return;
            }

            // Skip the login page when already authenticated
            const isLoggedIn = AuthService.isAuthenticated();
            if (isLoggedIn && toState.name === 'login') {
                event.preventDefault();
                $state.transitionTo('dashboard');
                return;
            }

            // When not authenticated go to the login page
            if (!isLoggedIn && toState.name !== 'login') {
                event.preventDefault();
                $state.transitionTo('login', {
                    reload: false,
                    successUrl: $window.location.href.split('#')[1]
                });
                return;
            }

            // Admin pages are only available to admins
            if (toState.admin === true && !AuthService.isAdmin()) {
                event.preventDefault();
                $state.transitionTo('unauthorized');
            }
        });

        $rootScope.$on('$destroy', function () {
            unregisterStateChangeStart();
        });
    });
