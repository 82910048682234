'use strict';

angular.module('uasApp').component('facultyProcessEdit', {

    templateUrl: 'es6/process/faculty/faculty.process.edit.html',
    controllerAs: 'facultyProcessEditController',
    bindings: {
        instance: '<',
        process: '<',
        faculty: '<',
        data: '<',
        onChange: '&'
    },
    controller: function(FacultyProcess, Message) {
        const facultyProcessEditController = this;

        facultyProcessEditController.close = function() {
            facultyProcessEditController.instance.close();
        };

        facultyProcessEditController.save = function(data) {
            FacultyProcess.store(data).$promise.then((result) => {
                Message.addSuccessLabel('Static.Message.DataSaved');
                facultyProcessEditController.onChange({ 
                    result: result 
                });
                facultyProcessEditController.close();
            });
        };

    }
});
