'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasStudyFilter
 * @description
 * Shows the different study report filters.
 */
angular.module('uasApp')
  .component('uasStudyFilter', {
    bindings: {
        mode: '<',
        onClick: '&'
    },
    templateUrl: 'es6/study/study.filter.html',
    controllerAs: 'studyFilterController',
    controller: function() {
        const studyFilterController = this;

        studyFilterController.$onInit = function() {
            studyFilterController.modes = [
                { name: 'USED_STUDY', icon: 'fa-asterisk', label: 'Enum.USED_STUDY' },
                { name: 'SAME_FACULTY', icon: 'fa-building-o', label: 'Enum.SAME_FACULTY' },
                { name: 'OWNED_STUDY', icon: 'fa-graduation-cap', label: 'Enum.OWNED_STUDY' }
            ];

            studyFilterController.current = findMode(studyFilterController.mode);
        };

        function findMode(name) {
            return _.find(studyFilterController.modes, { name: name });
        }

        studyFilterController.setMode = function(mode) {
            studyFilterController.current = mode;

            const name = _.get(mode, 'name');
            studyFilterController.mode = name;
            studyFilterController.onClick({ mode: name });
        };
    }
});
