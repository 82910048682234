'use strict';

angular.module('uasApp').component('processTypeParameter', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/process/process.type.parameter.html',
  controllerAs: 'processTypeParameterController',
  controller: function (ProcessType) {
    const processTypeParameterController = this;

    processTypeParameterController.$onInit = function () {
        processTypeParameterController.ngModelCtrl.$render = () => {
        processTypeParameterController.id = `${processTypeParameterController.ngModelCtrl.$modelValue || ''}`;
      };

      ProcessType.query().$promise.then((processTypes) => {
        processTypeParameterController.processTypes = _(processTypes).map((processType) => {
          processType.id = `${processType.id}`;
          processType.label = ProcessType.getLabel(processType);
          return processType;
        }).sortBy('label').value();
      });
    };

    processTypeParameterController.onChange = function () {
        processTypeParameterController.ngModelCtrl.$setViewValue(processTypeParameterController.id);
    };
  }
});
