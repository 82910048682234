'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessProgress
 * @description
 * uasProcessProgress Shows the process statuses in progress bars.
 */
angular
  .module('uasApp')
  .component('uasProcessProgress', {
    bindings: {
      process: '<'
    },
    templateUrl: 'es6/process/progress/process.progress.html',
    controllerAs: 'progressController',
    controller: function ($q, Process, ProcessProgress) {
      const progressController = this;

      progressController.$onInit = function() {
        loadData();
      };

      function loadData() {
        progressController.loading = true;

        $q.all([
          Process.query({
            academicYearId: sessionStorage.academicYear,
            typeId: progressController.process.type.id
          }).$promise,
          Process.children({
            academicYearId: sessionStorage.academicYear,
            parentId: progressController.process.type.id
          }).$promise,
          ProcessProgress.summary({
            id: progressController.process.id
          }).$promise
        ]).then(([processes, children, summary]) => {
          progressController.processes = processes;
          progressController.children = children;
          progressController.summary = summary;
        }).finally(function () {
          progressController.loading = false;
        });
      }

      progressController.toggleSupported = function () {
        // Toggle for person processes is not supported
        return progressController.process.type.rootType !== 'PERSON';
      };

      progressController.toggle = function (row) {
        row.open = row.open !== true;
        if (row.open === true && angular.isUndefined(row.children)) {
          ProcessProgress.summary({
            id: progressController.process.id,
            facultyId: row.entity.id
          }).$promise.then((summary) => {
            row.children = summary.rows;
          });
        }
      };
    }
  });
