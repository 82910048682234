'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTablePage
 * @description
 * statusTablePage Shows states for an entity
 */
angular.module('uasApp').component('statusTablePage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/status/status.table.page.html',
  controllerAs: 'statusPageController'
});
