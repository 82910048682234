'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Flow
 * @description
 * The flow service.
 */
angular.module('uasApp').factory('Flow', function() {

    let counter = 0;

    function build() {
        const instance = {
            voters: [],
            valid: true
        };

        function vote(index) {
            if (index < instance.voters.length) {
                const voter = instance.voters[index];
                const chain = () => vote(index + 1);
                return voter.callback(chain) !== false;
            } else {
                return true;
            }
        }

        return {
            add: (callback) => {
                const voter = {
                    id: counter++,
                    callback: callback
                };
    
                instance.voters.push(voter);
                return () =>  _.remove(instance.voters, { id: voter.id });
            },
            proceed: () => {
                return vote(0);
            },
            setValid: (valid) => {
                instance.valid = valid !== false;
            },
            isValid: () => {
                return instance.valid;
            }
        };
    }

    function add(flow, callback) {
        if (angular.isDefined(flow)) {
            return flow.add(callback);
        } else {
            return () => {};
        }
    }

    return { build, add };

});
