'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowComplete
 * @description
 * uasWorkflowComplete Completes the workflow
 */
angular.module('uasApp')
  .component('uasWorkflowComplete', {
    bindings: {
      entity: '<',
      page: '<'
    },
    templateUrl: 'es6/workflow/workflow.complete.html',
    controllerAs: 'completeWorkflowController'
});