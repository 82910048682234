'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:printMode
 * @description Shows two buttons to toggle between normal and print modes.
 */
angular.module('uasApp').component('printMode', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/switchers/print.mode.html',
  controllerAs: 'printModeController',
  controller: function () {
    const printModeController = this;

    printModeController.$onInit = function () {
      printModeController.ngModelCtrl.$setDirty = _.noop;

      printModeController.ngModelCtrl.$render = function () {
        printModeController.isPrintMode = printModeController.ngModelCtrl.$modelValue;
      };
    };

    printModeController.updateViewValue = function () {
      printModeController.ngModelCtrl.$setViewValue(printModeController.isPrintMode);
    };
  }
});
