'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:roleTypeParameter
 * @description Select the role types that should be visible on a page
 */
angular.module('uasApp').component('roleTypeParameter', {
  bindings: {
    rootType: '@?',
    parameter: '<'
  },
  templateUrl: 'es6/staffing/role/role.type.parameter.html',
  controllerAs: 'roleTypeParameterController',
  controller: function ($q, RoleType) {
    const roleTypeParameterController = this;

    roleTypeParameterController.getTypes = function () {
      if (!_.isEmpty(roleTypeParameterController.rootType)) {
        return RoleType.getTypes(roleTypeParameterController.rootType);
      }
      $q.resolve([]);
    };
  }
});