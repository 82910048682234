'use strict';

angular.module('uasApp').component('importButton', {
  templateUrl: 'es6/app/buttons/import.button.html',
  bindings: {
    entity: '<',
    onImported: '&'
  },
  controllerAs: 'importButtonController',
  controller: function (EntityService, Import, Message) {
    const importButtonController = this;

    importButtonController.start = function() {
      const entity = EntityService.toReference(importButtonController.entity);

      importButtonController.loading = true;
      Import.save({
        entityType: _.get(entity, 'type'),
        entityId: _.get(entity, 'id')
      }).$promise.then(() => {
        Message.addSuccessLabel('Static.Message.ImportStarted');
        importButtonController.onImported();
      }).finally(() => {
        importButtonController.loading = false;
      });
    };
  }
});
