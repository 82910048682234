'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Language
 * @description
 * The Language service.
 */
angular.module('uasApp').factory('Language', function(Settings, Parameter, UasResource) {
    const instance = {
        supported: [],
        descriptions: []
    };
    
    const resource = UasResource('/languages');

    instance.query = function(params) {
        return resource.query(params);
    };

    instance.get = function() {
        const language = Settings.get('language');
        return getSupported(language);
    };

    instance.set = function(language) {
        const supported = getSupported(language);
        Settings.set('language', supported);
    };

    function getSupported(language) {
        if (!language || _.some(instance.supported, language)) {
            language = _.first(instance.supported);
        }
        return language;
    }

    instance.init = function() {
        instance.supported = Parameter.getParameterAsList('language.supported');
        instance.descriptions = Parameter.getParameterAsList('language.descriptions');

        if (_.isEmpty(instance.supported)) {
            instance.supported = ['EN'];
        }
        if (_.isEmpty(instance.descriptions)) {
            instance.descriptions = instance.supported;
        }
    };

    instance.getPropertyName = function(language) {
        if (angular.isUndefined(language)) {
            language = instance.get();
        }
        return language === 'EN' ? 'englishName' : 'localName';
    };

    instance.onChange = function(event, language) {
        return Settings.onChange('language', event, language);
    };

    instance.getIcon = function(language) {
        if (!language) {
            return;
        }
    
        const icon = language.toLowerCase();
        return icon.replace(/\_.*/, '');
    };

    return instance;
});
