'use strict';

angular.module('uasApp').component('pagePreviewButton', {
    templateUrl: 'es6/page/render/page.preview.button.html',
    bindings: {
        page: '<',
        btnSize: '@?'
    },
    controllerAs: 'pagePreviewController',
    controller: function ($uibModal) {
        const pagePreviewController = this;

        pagePreviewController.open = function() {
            $uibModal.open({
                controllerAs: 'pagePreviewOpenController',
                controller: function($uibModalInstance) {
                    this.page = pagePreviewController.page;
                    this.instance = $uibModalInstance;
                },
                size: 'lg',
                template: `
                    <page-preview-modal 
                        page="pagePreviewOpenController.page" 
                        instance="pagePreviewOpenController.instance">
                    </page-preview-modal>
                `
            });
        };
    }
});