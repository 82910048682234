'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:structurePage
 * @description Shows a structure tree in a customized page.
 */
angular.module('uasApp')
  .component('structurePage', {
    bindings: {
      entity: '<',
      page: '<',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/planboard/structure.page.html',
    controllerAs: 'structurePageController',
    controller: function ($q, Column, Page, Language) {
      const structurePageController = this;
      const MAX_COLUMNS = 2;

      structurePageController.$onInit = function () {
        structurePageController.readOnly = structurePageController.isReadOnly || Page.getParameterAsBoolean(structurePageController.page, 'readOnly', false);
        structurePageController.compare = Page.getParameterAsBoolean(structurePageController.page, 'compare', false);
        structurePageController.showPeriod = Page.getParameterAsBoolean(structurePageController.page, 'showPeriod', false);
        structurePageController.showInactive = Page.getParameterAsBoolean(structurePageController.page, 'showInactive', false);
        structurePageController.showRedirect = Page.getParameterAsBoolean(structurePageController.page, 'showRedirect', false);
        structurePageController.showDescription = Page.getParameterAsBoolean(structurePageController.page, 'showDescription', false);
        structurePageController.editDescription = Page.getParameterAsBoolean(structurePageController.page, 'editDescription', false);
        structurePageController.hideEditOffering = Page.getParameterAsBoolean(structurePageController.page, 'hideEditOffering', false);
        structurePageController.markInactiveOfferings = Page.getParameterAsBoolean(structurePageController.page, 'markInactiveOfferings');
        structurePageController.expandAll = Page.getParameterAsBoolean(structurePageController.page, 'expandAll', false);

        setDefaultSort();
        Language.onChange(setDefaultSort);

        loadData();
      };

      function setDefaultSort() {
        let defaultSort = Page.getParameter(structurePageController.page, 'defaultSort');
        if (defaultSort === 'name') {
          defaultSort = Language.getPropertyName();
        }

        structurePageController.defaultSort = defaultSort;
      }

      function loadData() {
        structurePageController.loading = true;

        $q.all([
          getColumns('module-group', 'groupColumns'),
          getColumns('module', 'moduleColumns')
        ]).then(([groupColumns, moduleColumns]) => {
          structurePageController.groupColumns = groupColumns;
          structurePageController.moduleColumns = moduleColumns;
        }).finally(() => {
          structurePageController.loading = false;
        });
      }

      function getColumns(type, parameter) {
        const columns = Page.getParameterAsArray(structurePageController.page, parameter);
        return Column.fromType(type, columns, MAX_COLUMNS);
      }
    }
  });
