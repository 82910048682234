'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasHourReportLine
 * @description
 * uasHourReportLine represents a single line in the uasHourReport.
 */
angular.module('uasApp')
  .component('uasHourReportLine', {
    bindings: {
        line: '<',
        pageable: '<'
    },
    templateUrl: 'es6/staffing/worklog/report/hour.report.line.html',
    controllerAs: 'reportLineController',
    controller: function () {
        const reportLineController = this;

        reportLineController.open = function() {
            reportLineController.line.open = true;
        };

        reportLineController.close = function() {
            reportLineController.line.open = false;
        };
    }
});
