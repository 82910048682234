'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:elementLabel
 * Shows the label of an element, retrieved (normally from cache) by id
 */
angular.module('uasApp').component('elementLabel', {
  templateUrl: 'es6/element/element.label.html',
  bindings: {
    value: '<'
  },
  controllerAs: 'elementLabelController',
  controller: function (Element, elementTranslateFilter) {
    const elementLabelController = this;

    elementLabelController.$onChanges = function () {
        Element.load().then((elements) => {
          elementLabelController.label = elementTranslateFilter(elementLabelController.value, elements);
        });
    };
  }
});
