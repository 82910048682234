'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:elementOptions
 * @description
 * Select options for a specific element type.
 */
angular.module('uasApp').component('elementOptions', {
  bindings: {
    inputId: '@?',
    classes: '@?',
    type: '<',
    dropdownAlign: '@?',
    tagsPlacement: '@?',
    toggleLabel: '@?',
    filter: '<?', // Filter expression
    onChange: '&?',
    uasDisabled: '<?',
    uasRequired: '<?',
    isReadOnly: '<?',
    viewClasses: '@?',
    displayType: '<?',
    excludeIds: '<?',
    multipleValues: '<?',
    arrayModel: '<?', // Indicates if the ng-model is an array or a single value. Default = false
    evaluation: '<?',
    minItems: '<?',
    maxItems: '<?',
    filterValues: '<?', // Option filter function
    truncate: '<?' // If true read-only values will be truncated with ellipsis and tooltip on hover when content is too large
  },
  templateUrl: 'es6/element/element.options.html',
  controllerAs: 'optionsController',
  controller: function (entityTranslateFilter, $q, Element, Language, Changes, ReferenceList) {
    const optionsController = this;

    optionsController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'type')) {
        delete optionsController.elements;

        if (optionsController.type) {
          const elementType = _.snakeCase(optionsController.type).toUpperCase();

          $q.all([
            Element.query({
              type: elementType,
              filter: optionsController.filter,
              academicYearId: sessionStorage.academicYear
            }).$promise,
            ReferenceList.query({ elementType }).$promise
          ]).then(([elements, referenceLists]) => {
            optionsController.elements = filterValues(elements);
            optionsController.referenceLists = referenceLists;
          });
        }
      }
    };

    function filterValues(elements) {
      if (_.isFunction(optionsController.filterValues)) {
        return _.filter(elements, optionsController.filterValues);
      }

      return elements;
    }

    optionsController.formatValue = function (value) {
      const element = _.find(optionsController.elements, { id: value });
      return entityTranslateFilter(element) || value;
    };

    optionsController.setNames = function (elements) {
      const name = Language.getPropertyName();
      _.each(elements, (element) => {
        element.name = element[name] || element.code || element.externalId;
      });
    };
  }
});
