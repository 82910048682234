'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:hookModal
 * @description
 * hookModal Edit the hook
 */
angular.module('uasApp').component('hookModal', {
    bindings: {
      hook: '<',
      descriptions: '<',
      onCancel: '&',
      onSave: '&'
    },
    templateUrl: 'es6/process/hook/hook.modal.html',
    controllerAs: 'hookModalController',
    controller: function (Hook) {
        const hookModalController = this;

        hookModalController.$onInit = function() {
            setArguments();
        };

        function setArguments() {
            const name = _.get(hookModalController.hook, 'name');
            const description = _.find(hookModalController.descriptions, { name });

            if (description) {
                const values = hookModalController.hook.arguments || {};
                hookModalController.arguments = _.map(description.variables, (variable) => {
                    const result = angular.copy(variable);
                    const value = _.get(values, variable.name, '');
                    return _.extend(result, { value });
                });
            } else {
                hookModalController.arguments = [];
            }
        }

        hookModalController.onName = function() {
            setArguments();
        };

        hookModalController.submit = function() {
            const model = angular.copy(hookModalController.hook);
            model.arguments = getArguments();

            Hook.store(model).$promise.then((hook) => {
                hookModalController.onSave({ hook });
            });
        };

        function getArguments() {
            let values = {};
            _.forEach(hookModalController.arguments, ({ name, value }) => {
                values[name] = value;
            });
            return values;
        }

        hookModalController.cancel = function() {
            hookModalController.onCancel();
        };
    }
});
