'use strict';

import defaultAvatar from '../../images/default-avatar.png';

/**
 * @ngdoc function
 * @name uasApp.component:personAvatar
 * @description
 * Shows the photo (avatar) of a person, with fallbacks to the default photo
 */
angular.module('uasApp').component('personAvatar', {
  bindings: {
    photoUrl: '<',
    photoClasses: '@?'
  },
  templateUrl: 'es6/person/person.avatar.html',
  controllerAs: 'personAvatarController',
  controller: function ($sce) {
    const personAvatarController = this;

    personAvatarController.$onInit = function () {
      personAvatarController.fallbackPhotoUrl = defaultAvatar;
      personAvatarController.selectedPhotoUrl = $sce.trustAsResourceUrl(personAvatarController.photoUrl);
    };
  }
});
