'use strict';

angular.module('uasApp')
    .component('bulkChange', {
        bindings: {
            entityType: '<',
            entityIds: '<',
            field: '<',
            labelKey: '@?',
            onChange: '&',
            onCancel: '&'
        },
        templateUrl: 'es6/report/bulk/bulk.change.html',
        controllerAs: 'bulkChangeController',
        controller: function (Page, Promises, Message, $uibModal) {
            const bulkChangeController = this;
            const chunkSize = 10;

            bulkChangeController.$onInit = function () {
                if (bulkChangeController.field.valueType === 'BOOLEAN') {
                    bulkChangeController.value = false;
                }
            };

            bulkChangeController.submit = function () {
                const chunks = _.chunk(bulkChangeController.entityIds, chunkSize);
                const requests = _.map(chunks, (entityIds) => {
                    return () => Page.batch({}, {
                        academicYearId: sessionStorage.academicYear,
                        entityType: bulkChangeController.entityType,
                        entityIds: entityIds,
                        items: [{
                            customFieldId: _.result(bulkChangeController.field, 'id'),
                            value: bulkChangeController.value
                        }],
                        comment: {
                            message: bulkChangeController.message
                        }
                    }).$promise;
                });

                const progress = Promises.chain(requests);
                bulkChangeController.progress = progress;

                return progress.$promise.then((result) => {
                    Message.onSaved();
                    bulkChangeController.onChange();
                    show(result);
                    return result;
                });
            };

            function show(result) {
                $uibModal.open({
                    template: `
                        <bulk-result
                            result="bulkResultModalController.result"
                            on-close="bulkResultModalController.close()">
                        </bulk-result>
                    `,
                    controllerAs: 'bulkResultModalController',
                    controller: function ($uibModalInstance) {
                        this.result = result;
                        this.close = () => $uibModalInstance.dismiss();
                    }
                });
            }

            bulkChangeController.cancel = function () {
                bulkChangeController.onCancel();
            };
        }
    });