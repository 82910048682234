'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:NotificationStyle
 * @description
 * The NotificationStyle service.
 */
angular.module('uasApp').factory('NotificationStyle', function(UasResource) {
    return UasResource('/notification-styles');
});
