'use strict';

angular.module('uasApp').component('pageLoader', {
    templateUrl: 'es6/page/page.loader.html',
    bindings: {
        page: '<?',
        pageId: '<?',
        route: '@?',
        entity: '<',
        period: '<?',
        operations: '<'
    },
    controllerAs: 'pageLoaderController',
    controller: function($q, Page) {
        const pageLoaderController = this;

        pageLoaderController.$onInit = function() {
            loadData();
        };

        function loadData() {
            pageLoaderController.loading = true;
            getPage().then((page) => {
                pageLoaderController.page = page;
            }).finally(() => {
                pageLoaderController.loading = false;
                pageLoaderController.loaded = true;
            });
        }

        function getPage() {
            if (angular.isDefined(pageLoaderController.page)) {
                return $q.resolve(pageLoaderController.page);
            }

            return Page.find(pageLoaderController.pageId).$promise.then((page) => {
                if (angular.isUndefined(page)) {
                    return Page.build(pageLoaderController.route);
                }

                return page;
            });
        }
    }
});
