'use strict';

/**
 * @ngdoc service
 * @name uasApp.component:moveButton
 * @description
 * The move button.
 */
angular.module('uasApp').component('moveButton', {
  bindings: {
    btnType: '@?',
    btnSize: '@?',
    btnIconOnly: '<?',
    btnContext: '@?',
    btnCustomLabel: '@?',
    btnDisabled: '<?',
    type: '@?'
  },
  templateUrl: 'es6/app/forms/buttons/move.button.html',
  controllerAs: 'moveButtonController',
  controller: function() {
    const moveButtonController = this;

    moveButtonController.$onInit = function() {
      moveButtonController.btnContext = moveButtonController.btnContext || 'default';
      moveButtonController.btnLabel = moveButtonController.btnCustomLabel || 'Static.Button.Move';
    };
  }
});
