'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:timeBlockList
 * @description
 * timeBlockList shows the time blocks in a calendar
 */
angular.module('uasApp').component('timeBlockList', {
    bindings: {
        calendar: '<'
    },
    templateUrl: 'es6/calendar/time/time.block.list.html',
    controllerAs: 'timeBlockListController',
    controller: function($q, CalendarPeriod, TimeBlock, Message, $uibModal) {
        const timeBlockListController = this;

        timeBlockListController.$onInit = function() {
            loadData();
        };

        function loadData() {
            $q.all([
                CalendarPeriod.query({
                    calendarId: timeBlockListController.calendar.id
                }).$promise,
                TimeBlock.query({
                    calendarId: timeBlockListController.calendar.id
                }).$promise
            ]).then(([periods, blocks]) => {
                timeBlockListController.periods = _(periods).map((period) => {
                    period.blocks = _.filter(blocks, { periodId: period.id });
                    return period;
                }).sortBy('period').value();
            });
        }

        timeBlockListController.create = function(period) {
            edit({
                periodId: period.id
            });
        };

        timeBlockListController.edit = function(block) {
            edit(block);
        };

        function edit(model) {
            $uibModal.open({
                size: 'lg',
                template: `
                    <modal-form
                        modal-title="Static.Page.TimeBlocks"
                        entity="timeBlockModalController.block"
                        on-close="timeBlockModalController.close()"
                        on-save="timeBlockModalController.save(entity)">

                        <time-block-form
                            block="timeBlockModalController.block">
                        </time-block-form>

                    </modal-form>
                `,
                controllerAs: 'timeBlockModalController',
                controller: function($uibModalInstance) {
                    const timeBlockModalController = this;
                    timeBlockModalController.block = angular.copy(model);

                    timeBlockModalController.save = function(block) {
                        TimeBlock.store(block).$promise.then(() => {
                            timeBlockModalController.close();
                            Message.onSaved();
                            loadData();
                        });
                    };

                    timeBlockModalController.close = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        }

        timeBlockListController.remove = function(block) {
            TimeBlock.remove(block).$promise.then(() => {
                Message.onRemoved();
                loadData();
            });
        };
    }
});
