'use strict';

/**
 * The redirect strategies for the main module 'uasApp'.
 */
angular.module('uasApp')
    .factory('entityRedirect', function(ENTITY_STATES, $state, $window) {

        function redirect(type, args, options) {
            let success = false;
            const target = ENTITY_STATES[type];
            if (!_.isEmpty(target)) {
                go(target, args, options);
                success = true;
            }
            return success;
        }

        function go(stateToGo, args, options) {
            const state = $state.get(stateToGo);
            if (state.visible !== false) {
                goToState(stateToGo, args, options);
            } else if (state.parent) {
                const parentState = $state.get(state.parent).redirectTo ? $state.get(state.parent).redirectTo : state.parent;
                goToState(parentState, args, options);
            }
        }

        function goToState(stateToGo, args, options) {
            options = options || {};

            if (_.isEmpty(options.target)) {
                $state.go(stateToGo, args, options);
            } else {
                const url = $state.href(stateToGo, args);
                $window.open(url, options.target);
            }
        }

        return { redirect, go };

    });
