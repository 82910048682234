'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Specification
 * @description The Specification service.
 */
angular.module('uasApp').factory('Specification', function (UasResource) {
    return UasResource('/specifications', {
        connect: {
            url: '/specifications/connect',
            method: 'POST',
            isArray: false
        },
        report: {
            url: '/specifications/report',
            method: 'GET',
            isArray: false
        },
        deleteFull: {
            url: '/specifications/:id/full',
            method: 'DELETE',
            isArray: false
        }
    });
});
