'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:StudyFormCtrl
 * @description
 * # StudyFormCtrl
 * Study form controller. used to add a study
 */
angular.module('uasApp')
    .controller('StudyCtrl', function ($scope, $state, study, Tabs, StudyTabs, historyMode, operations) {

        // Bind the id for the feedback directive
        $scope.studyId = $state.params.id;
        $scope.study = study;
        $scope.historyMode = historyMode;
        $scope.operations = operations;

        Tabs.build(StudyTabs.get(), operations, 'study', 'study', $scope.studyId).then((builtTabs) => {
            $scope.tabs = builtTabs;
        });
    });
