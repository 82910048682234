'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityGenerate
 * @description
 * Generate activities based on a previous period.
 */
angular.module('uasApp').component('activityGenerate', {
  templateUrl: 'es6/schedule/activity/activity.generate.html',
  bindings: {
    module: '<',
    period: '<',
    categoryId: '<?',
    isEmpty: '<?',
    getRows: '&?',
    onChange: '&'
  },
  controllerAs: 'activityGenerateController',
  controller: function (AcademicYear, Activity, Offering, Promises, $q, $uibModal) {
    const activityGenerateController = this;

    function getRows() {
        if (angular.isDefined(activityGenerateController.getRows)) {
            return activityGenerateController.getRows();
        }

        return $q.resolve([activityGenerateController.module]);
    }

    activityGenerateController.reset = function() {
        $uibModal.open({
            templateUrl: 'es6/schedule/activity/activity.generate.reset.modal.html',
            controllerAs: 'modalController',
            controller: function($uibModalInstance) {
                const modalController = this;

                modalController.reset = function() {
                    getRows().then((rows) => {
                        const requests = _.map(rows, (row) => {
                            return () => Activity.reset({
                                entityType: row.self.type,
                                entityId: row.id,
                                periodId: activityGenerateController.period.id,
                                categoryId: activityGenerateController.categoryId
                            }).$promise;
                        });

                        const progress = Promises.chain(requests);
                        activityGenerateController.progress = progress;
                        return progress.$promise;
                    }).then(() => {
                        $uibModalInstance.dismiss();
                        activityGenerateController.onChange();
                    });
                };
            }
        });
    };

    activityGenerateController.generate = function() {
        $uibModal.open({
            resolve: {
                periods: function() {
                    return Offering.periods({
                        moduleId: activityGenerateController.module.id,
                        periodId: activityGenerateController.period.id,
                        historical: true
                    }).$promise.then((periods) => {
                        return _.filter(periods, (period) => period.id !== activityGenerateController.period.id);
                    });
                },
                years: function() {
                    return AcademicYear.query().$promise;
                }
            },
            templateUrl: 'es6/schedule/activity/activity.generate.generate.modal.html',
            controllerAs: 'modalController',
            controller: function($uibModalInstance, periods, years, entityTranslateFilter) {
                const modalController = this;
                modalController.periods = periods;

                modalController.formatPeriod = function (period) {
                    const year = _.find(years, { id: period.academicYearId });
                    return `${entityTranslateFilter(period) || period.code} (${formatYear(year)})`;
                };

                function formatYear(year) {
                    return entityTranslateFilter(year) || year.code;
                }

                modalController.generate = function() {
                    getRows().then((rows) => {
                        const requests = _.map(rows, (row) => {
                            return () => Activity.generate({
                                entityType: row.self.type,
                                entityId: row.id,
                                periodId: activityGenerateController.period.id,
                                previousPeriodId: modalController.previousPeriodId,
                                categoryId: activityGenerateController.categoryId
                            }).$promise;
                        });

                        const progress = Promises.chain(requests);
                        activityGenerateController.progress = progress;
                        return progress.$promise;
                    }).then(() => {
                        $uibModalInstance.dismiss();
                        activityGenerateController.onChange();
                    });
                };
            }
        });
    };

  }
});
