'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Method
 * @description
 * The Method service.
 */
angular.module('uasApp').factory('Method', function(UasResource) {
    return UasResource('/methods', {
        deleteFull: {
            method: 'DELETE',
            url: '/methods/:id/full',
            isArray: false
        },
        search: {
            url: '/methods/search',
            method: 'GET',
            isArray: false
        },
        validate: {
            method: 'GET',
            url: '/methods/validate'
        }
    });
});
