'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processTree
 * @description
 * processTree Shows the process tree
 */
angular.module('uasApp').component('processTree', {
  bindings: {
    entity: '<',
    process: '<',
    onStatus: '&',
    onShow: '&'
  },
  templateUrl: 'es6/process/progress/process.tree.html',
  controllerAs: 'processTreeController',
  controller: function() {
    const processTreeController = this;

    processTreeController.toggle = function () {
        processTreeController.process.open = processTreeController.process.open !== true;
    };

    processTreeController.toggleStatus = function (status) {
        processTreeController.onStatus({ 
            process: processTreeController.process,
            status
        });
    };

    processTreeController.show = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
  
        processTreeController.onShow({ 
            process: processTreeController.process,
            status: processTreeController.process.status
        });
    };
  }
});