'use strict';

angular.module('uasApp').component('subjectTypeSelect', {
  templateUrl: 'es6/subjects/type/subject.type.select.html',
  bindings: {
    entity: '<',
    categoryId: '<',
    isRequired: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'subjectTypeSelectController',
  controller: function (Pageable, SubjectVersion) {
    const subjectTypeSelectController = this;

    subjectTypeSelectController.$onInit = function () {
      subjectTypeSelectController.required = subjectTypeSelectController.isRequired === true;

      subjectTypeSelectController.ngModelCtrl.$render = function () {
        subjectTypeSelectController.typeId = subjectTypeSelectController.ngModelCtrl.$modelValue;
      };
    };

    subjectTypeSelectController.$onChanges = function () {
      subjectTypeSelectController.pageable = Pageable.of({
        order: 'type.code'
      });

      subjectTypeSelectController.search();
    };

    subjectTypeSelectController.search = function () {
      const args = subjectTypeSelectController.pageable.build({
        text: subjectTypeSelectController.text || '',
        categoryId: subjectTypeSelectController.categoryId,
        entityType: _.get(subjectTypeSelectController.entity, 'type'),
        entityId: _.get(subjectTypeSelectController.entity, 'id'),
        active: true
      });

      subjectTypeSelectController.loading = true;
      return SubjectVersion.search(args).$promise.then((versions) => {
        subjectTypeSelectController.versions = versions;
      }).finally(() => {
        subjectTypeSelectController.loading = false;
      });
    };

    subjectTypeSelectController.onSelect = function (version) {
      subjectTypeSelectController.typeId = version.type.id;
      subjectTypeSelectController.ngModelCtrl.$setViewValue(version.type.id);
    };
  }
});