'use strict';

angular.module('uasApp').component('customFieldFormGroup', {
  bindings: {
    field: '<',
    isRequired: '<?',
    evaluation: '<?',
    labelKey: '@?',
    labelWidth: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/fields/custom.field.form.group.html',
  transclude: true,
  controllerAs: 'customFieldFormGroupController',
  controller: function (Language, Changes, CustomField, Expression) {
    const customFieldFormGroupController = this;

    customFieldFormGroupController.$onInit = function () {
      customFieldFormGroupController.labelWidth_ = _.isEmpty(customFieldFormGroupController.labelWidth) ? '3' : customFieldFormGroupController.labelWidth;
      customFieldFormGroupController.onLanguage = Language.onChange(setLabel);
      customFieldFormGroupController.ngModelCtrl.$render = setModelValue;
    };

    customFieldFormGroupController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, ['evaluation', 'field'])) {
        update(customFieldFormGroupController.field);
      }
      if (Changes.hasChanged(changes, 'field')) {
        setLabel();
      }
    };

    function update(field) {
      if (angular.isDefined(field)) {
        evaluate(field.condition, (value) => {
          field.evaluated = value === true;
        });
      }
    }

    function evaluate(expression, callback) {
      if (customFieldFormGroupController.evaluation && !_.isEmpty(expression)) {
        const evaluation = angular.copy(customFieldFormGroupController.evaluation);
        evaluation.expression = expression;
        evaluation.academicYearId = sessionStorage.academicYear;
  
        Expression.evaluate(evaluation).$promise.then((result) => {
          callback(result.value);
        });
      }
    }

    function setLabel() {
      customFieldFormGroupController.label = CustomField.getLabel(customFieldFormGroupController.field, customFieldFormGroupController.labelKey);
    }

    function setModelValue() {
      customFieldFormGroupController.value = customFieldFormGroupController.ngModelCtrl.$modelValue;
    }

    customFieldFormGroupController.hasValue = function() {
      return CustomField.hasValue(customFieldFormGroupController.value);
    };
  }
});
