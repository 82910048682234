'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionElement
 * @description
 * descriptionElement The element of the description
 */
angular.module('uasApp').component('descriptionElement', {
  bindings: {
    element: '<',
    language: '<?',
    isDisabled: '<',
    mode: '<'
  },
  templateUrl: 'es6/i18n/descriptions/description.element.html',
  controllerAs: 'descriptionElementController',
  controller: function (Language) {
    const descriptionElementController = this;

    descriptionElementController.$onInit = function () {
      descriptionElementController.element = descriptionElementController.element || {};

      setPropertyName();
      Language.onChange(setPropertyName);
    };

    function setPropertyName() {
      descriptionElementController.name = Language.getPropertyName();
    }
  }
});
