'use strict';

angular.module('uasApp').component('entityBadge', {
    bindings: {
        entity: '<?',
        emptyLabel: '@?'
    },
    templateUrl: 'es6/app/entity/entity.badge.html',
    controllerAs: 'entityBadgeController',
    controller: function (EntityService) {
        const entityBadgeController = this;

        entityBadgeController.$onInit = function() {
            const entity = entityBadgeController.entity || {};
            entityBadgeController.code = entity.code || entity.abbreviation;
            entityBadgeController.href = EntityService.getHref(entityBadgeController.entity);
        };
    }
});