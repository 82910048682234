'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:ruleFields
 * @description
 * Rule input fields
 */
angular.module('uasApp').component('ruleFields', {
    bindings: {
        rule: '<',
        operations: '<',
        operationsToEdit: '<'
    },
    templateUrl: 'es6/rule/rule.fields.html',
    controllerAs: 'ruleFieldsController',
    controller: function(CustomField) {
        const ruleFieldsController = this;

        ruleFieldsController.$onInit = function() {
            CustomField.query({
                rootType: 'rule'
            }).$promise.then((fields) => {
                _.remove(fields, { name: 'owner' });
                ruleFieldsController.fields = fields;
            });
        };
    }
});
