'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodEdit
 * @description
 * # methodEdit
 * The component for adding a method.
 */
 angular.module('uasApp').component('methodEdit', {
    bindings: {
        method: '<',
        root: '<?',
        type: '<',
        category: '<',
        operations: '<',
        operationsToEdit: '<',
        excludedFields: '<?',
        onSubmit: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/methods/modal/method.edit.html',
    controllerAs: 'methodEditController',
    controller: function() {
        const methodEditController = this;

        methodEditController.submit = function() {
            methodEditController.onSubmit({
                method: methodEditController.method
            });
        };

        methodEditController.cancel = function() {
            methodEditController.onCancel();
        };
    }
});