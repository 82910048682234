'use strict';

/**
 * Shows a list of mapped subjects and provides the option to add new ones,
 * remove existing ones or edit descriptions of already selected ones.
 */
angular.module('uasApp').component('subjectList', {
    bindings: {
        entityId: '<',
        entityType: '@',
        categoryId: '<?',
        typeId: '<?',
        operations: '<',
        operationsToEdit: '<?',
        isReadOnly: '<?',
        inactive: '<?'
    },
    templateUrl: 'es6/subjects/subject.list.html',
    controllerAs: 'subjectListController',
    controller: function ($q, $scope, AuthService, SecurityService, SubjectCategory, Subject, SubjectModal, Subjects, Message, CustomField,
                          feedbackObserver, i18nFilter, translateFilter, Language, WorkflowValidator, EntityService) {
        const subjectListController = this;

        subjectListController.$onInit = function () {
            WorkflowValidator.setValidity(() => subjectListController.subjectForm);

            subjectListController.operationsToEdit_ = AuthService.buildOperationsToEdit(subjectListController.operationsToEdit, 'EDIT_SUBJECTS');
            subjectListController.entity = { id: subjectListController.entityId, type: subjectListController.entityType };
            subjectListController.readOnly = subjectListController.isReadOnly === true;

            const allowed = SecurityService.isAllowed(subjectListController.operationsToEdit_, subjectListController.operations);
            subjectListController.editable = !subjectListController.readOnly && allowed;
            subjectListController.active = subjectListController.inactive !== true;

            loadData();

            $scope.$watch('subjectListController.subjectForm', () => setFormValidity());

            subjectListController.onLanguage = Language.onChange(() => {
                _.forEach(subjectListController.categories, translate);
            });
        };

        subjectListController.$onDestroy = function() {
            WorkflowValidator.reset();
            subjectListController.onLanguage();
        };

        function loadData() {
            subjectListController.loading = true;

            $q.all([
                SubjectCategory.query({
                    typeId: subjectListController.typeId
                }).$promise,
                Subject.query({
                    entityType: subjectListController.entityType,
                    entityId: subjectListController.entityId
                }).$promise,
                CustomField.query({
                    rootType: EntityService.getType(subjectListController.entity),
                    entityType: 'subject'
                }).$promise
            ]).then(([categories, subjects, fields]) => {
                subjectListController.fields = _.sortBy(fields, 'sequence');

                if (angular.isDefined(subjectListController.categoryId)) {
                    subjectListController.category = _.find(categories, { id: subjectListController.categoryId });
                }

                subjectListController.categories = _(categories)
                    .filter((category) => angular.isUndefined(subjectListController.categoryId) || category.id === subjectListController.categoryId)
                    .map((category) => {
                        category.subjects = _.filter(subjects, (subject) => subject.type.category.id === category.id);
                        category.activeSubjects = _.filter(category.subjects, (subject) => subject.changeType !== 'REMOVE');

                        setPercentages(category);
                        validate(category);
                        translate(category);

                        return category;
                    }).value();

                subjectListController.categoryIds = _.map(subjectListController.categories, 'id');

                setFormValidity();
                subjectListController.onMode();
            }).finally(() => {
                subjectListController.loading = false;
            });
        }

        subjectListController.onMode = function() {
            _.forEach(subjectListController.categories, (category) => {
                category.filtered = _.filter(category.subjects, (subject) =>
                    !subjectListController.active || subject.changeType !== 'REMOVE'
                );

                category.visible = !_.isEmpty(category.filtered) || !_.isEmpty(category.errors);
            });

            subjectListController.empty = !_.some(subjectListController.categories, { visible: true });
        };

        subjectListController.create = function(category) {
            const subject = {
                entity: subjectListController.entity,
                type: { category }
            };

            SubjectModal.edit({
                subject,
                categoryIds: subjectListController.categoryIds,
                operations: subjectListController.operations,
                operationsToEdit: subjectListController.operationsToEdit_,
                callback: loadData
            });
        };

        subjectListController.edit = function(subject) {
            SubjectModal.edit({
                subject,
                categoryIds: subjectListController.categoryIds,
                operations: subjectListController.operations,
                operationsToEdit: subjectListController.operationsToEdit_,
                callback: loadData
            });
        };

        subjectListController.remove = function(subject, comment) {
            Subject.remove({
                comment: comment,
                id: subject.id
            }).$promise.then(() => {
                feedbackObserver.dataChanged();
                Message.onRemoved();
                loadData();
            });
        };

        function setPercentages(category) {
            if (category.percentage) {
                const total = _.sumBy(category.activeSubjects, 'value.value');
                _.forEach(category.activeSubjects, (subject) => {
                    if (subject.value) {
                        subject.percentage = (subject.value.value / total) * 100;
                    }
                 });
            }
        }

        function validate(category) {
            category.errors = [];
            if (angular.isDefined(category.min) && category.activeSubjects.length < category.min) {
                category.errors.push(translateFilter('Static.Page.SubjectCategories.MinError', { minimum: category.min }));
            }
            if (angular.isDefined(category.max) && category.activeSubjects.length > category.max) {
                category.errors.push(translateFilter('Static.Page.SubjectCategories.MaxError', { maximum: category.max }));
            }

            _.forEach(category.scale.values, (value) => validateScaleValue(category, value));
        }

        function validateScaleValue(category, scaleValue) {
            const subjects = _.filter(category.activeSubjects, (subject) => _.get(subject.value, 'id') === scaleValue.id);

            if (angular.isDefined(scaleValue.min) && subjects.length < scaleValue.min) {
                category.errors.push(translateFilter('Static.Page.SubjectCategories.MinScaleValueError', {
                    minimum: scaleValue.min,
                    name: i18nFilter(scaleValue.names)
                }));
            }
            if (angular.isDefined(scaleValue.max) && subjects.length > scaleValue.max) {
                category.errors.push(translateFilter('Static.Page.SubjectCategories.MaxScaleValueError', {
                    maximum: scaleValue.max,
                    name: i18nFilter(scaleValue.names)
                }));
            }
        }

        function translate(category) {
            _.each(category.subjects, (subject) => {
                return Subjects.translateSubject(subject);
            });
        }

        function setFormValidity() {
            if (angular.isDefined(subjectListController.subjectForm)) {
                const valid = _.every(subjectListController.categories, (category) => _.isEmpty(category.errors));
                subjectListController.subjectForm.$setValidity('subjects', valid);
            }
        }
    }
});
