'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasMethodTypeList
 * @uasMethodTypeList
 * uasCustomFieldList Manages the method types
 */
angular.module('uasApp').component('uasMethodType', {
    bindings: {
        type: '<',
        onSave: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/methods/type/method.type.html',
    controllerAs: 'methodTypeController',
    controller: function (MethodType, Category, CustomField) {
        const methodTypeController = this;

        methodTypeController.$onInit = function() {
            Category.query({
                rootType: 'METHOD'
            }).$promise.then((categories) => {
                methodTypeController.categories = categories;
            });
            
            CustomField.query({
                rootType: 'method-type', 
                entityType: 'method-type'
            }).$promise.then(function(fields) {
                methodTypeController.fields = fields;
            });
        };

        methodTypeController.cancel = function() {
            methodTypeController.onCancel();
        };

        methodTypeController.save = function() {
            MethodType.store(methodTypeController.type).$promise.then(() => {
                methodTypeController.onSave();
            });
        };
    }
});