'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodTreePage
 * @description
 * methodTreePage Manages the method.
 */
angular.module('uasApp')
  .component('methodTreePage', {
    bindings: {
      module: '<',
      period: '<?',
      page: '<',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/methods/schema/method.tree.page.html',
    controllerAs: 'methodTreePageController',
    controller: function (Page) {
      const methodTreePageController = this;

      methodTreePageController.$onInit = function () {
        methodTreePageController.readOnly = methodTreePageController.isReadOnly || Page.getParameterAsBoolean(methodTreePageController.page, 'readOnly', false);
        methodTreePageController.showInactive = Page.getParameterAsBoolean(methodTreePageController.page, 'showInactive', false);
        methodTreePageController.navigate = !Page.getParameterAsBoolean(methodTreePageController.page, 'disableNavigate', false);
        methodTreePageController.columns = Page.getParameterAsArray(methodTreePageController.page, 'columns');

        getChildPage();
      };

      function getChildPage() {
        const childPageId = Page.getParameterAsInt(methodTreePageController.page, 'childPage');

        methodTreePageController.loading = true;
        Page.find(childPageId).$promise.then((page) => {
          methodTreePageController.childPage = page || Page.build('activity-list');
        }).finally(() => {
          methodTreePageController.loading = false;
        });
      }
    }
  });
