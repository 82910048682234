'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:calendarWeekList
 * @description
 * calendarWeekList shows the weeks of a calendar period
 */
angular.module('uasApp').component('calendarWeekList', {
    bindings: {
        calendar: '<'
    },
    templateUrl: 'es6/calendar/week/calendar.week.list.html',
    controllerAs: 'weekListController',
    controller: function(CalendarPeriod, CalendarWeek, Element, Message, $uibModal, $q) {
        const weekListController = this;

        weekListController.$onInit = function() {
            loadData();
        };

        function loadData() {
            $q.all([
                CalendarPeriod.query({
                    calendarId: weekListController.calendar.id
                }).$promise,
                CalendarWeek.query({
                    calendarId: weekListController.calendar.id
                }).$promise,
                Element.getActive('CALENDAR_WEEK_TYPE')
            ]).then(([periods, weeks, weekTypes]) => {
                weekListController.periods = _(periods).sortBy('period').map((period) => {
                    period.weeks = _(weeks).filter({ periodId: period.id }).sortBy('week').value();
                    return period;
                }).value();
                weekListController.weekTypes = weekTypes;
            });
        }

        weekListController.edit = function(model) {
            $uibModal.open({
                size: 'lg',
                template: `
                    <modal-form
                        modal-title="Static.Page.CalendarWeeks"
                        entity="weekModalController.week"
                        on-close="weekModalController.close()"
                        on-save="weekModalController.save(entity)">

                        <calendar-week-form week="weekModalController.week"></calendar-week-form>

                    </modal-form>
                `,
                controllerAs: 'weekModalController',
                controller: function($uibModalInstance) {
                    const weekModalController = this;
                    weekModalController.week = angular.copy(model);

                    weekModalController.save = function(week) {
                        CalendarWeek.store(week).$promise.then(() => {
                            weekModalController.close();
                            Message.onSaved();
                            loadData();
                        });
                    };

                    weekModalController.close = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        };
    }
});
