'use strict';

angular.module('uasApp').component('referenceTypeTabs', {
  templateUrl: 'es6/reference/reference.type.tabs.html',
  bindings: {
    type: '<'
  },
  controllerAs: 'tabsController',
  controller: function () {
    const tabsController = this;

    tabsController.$onInit = function () {
      tabsController.tabs = [{
        title: 'Static.Tab.ReferenceType.Type',
        route: 'reference-type.type',
        visible: true
      }, {
        title: 'Static.Tab.ReferenceType.Lists',
        route: 'reference-type.lists',
        visible: true
      }];
    };
  }
});
