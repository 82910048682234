'use strict';

angular.module('uasApp')
    .component('methodList', {
        bindings: {
            module: '<',
            operations: '<',
            workflowMode: '<?',
            active: '<?',
            isReadOnly: '<?',
            showEdit: '<?',
            showBudget: '<?',
            showEffort: '<?',
            isAutoSave: '<?',
            columns: '<?'
        },
        templateUrl: 'es6/methods/method.list.html',
        controllerAs: 'methodListController',
        controller: function ($q, translateFilter, Column, CustomField, Method, MethodType, Category, SecurityService, AcademicYear,
            Message, Language, MethodModals, Credits, Feedback, WorkflowValidator, feedbackObserver) {

            const methodListController = this;
            const MAX_COLUMNS = 3;

            methodListController.$onInit = function () {
                methodListController.operationsToEdit = methodListController.workflowMode === true ? 'EDIT_METHODS_WORKFLOW' : 'EDIT_METHODS';
                methodListController.editable = methodListController.isReadOnly !== true && SecurityService.isAllowed(methodListController.operationsToEdit, methodListController.operations);
                methodListController.active = methodListController.active === true;

                WorkflowValidator.setValidity(() => methodListController.methodsForm);

                if (!methodListController.isAutoSave) {
                    WorkflowValidator.onSave((comment) => {
                        return methodListController.save(methodListController.buildSaveModel(), comment);
                    }, {
                        rootType: 'method',
                        entityType: 'method'
                    });
                }
            };

            methodListController.$onChanges = function (changes) {
                if (changes.module && methodListController.module) {
                    loadData();
                }
            };

            methodListController.$onDestroy = function () {
                WorkflowValidator.reset();
            };

            function loadData() {
                methodListController.loading = true;

                $q.all([
                    CustomField.query({
                        rootType: 'method',
                        entityType: 'method'
                    }).$promise,
                    Credits.get({
                        entityId: methodListController.module.id,
                        entityType: 'module'
                    }).$promise,
                    AcademicYear.get({
                        id: sessionStorage.academicYear
                    }).$promise
                ]).then(([fields, credits, year]) => {
                    methodListController.columns_ = Column.fromFields(
                        _.filter(fields, (field) => field.name !== 'credits' && field.name !== 'contactHours'),
                        methodListController.columns,
                        MAX_COLUMNS);

                    methodListController.fields_ = CustomField.keyByName(fields);
                    methodListController.credits = _.get(credits, 'optimum');
                    methodListController.year = year;

                    return loadMethods();
                }).finally(() => {
                    methodListController.loading = false;
                });
            }

            function loadMethods() {
                return $q.all([
                    Category.query({
                        rootType: 'METHOD',
                        entityType: 'module',
                        entityId: methodListController.module.id,
                        academicYearId: sessionStorage.academicYear
                    }).$promise,
                    MethodType.getAll(methodListController.year),
                    Method.query({
                        moduleId: methodListController.module.id
                    }).$promise,
                    Feedback.get({
                        filterType: 'method',
                        entityType: 'module',
                        entityId: methodListController.module.id,
                        language: Language.get()
                    }).$promise
                ]).then(([categories, types, methods, feedback]) => {
                    methodListController.feedback = feedback;

                    methodListController.types = types;
                    methodListController.categories = _(categories).filter({ editable: true }).map((category) => {
                        const result = angular.copy(category);

                        result.methods = _(types).filter({
                            categoryId: category.id
                        }).flatMap((type) => {
                            return _(methods)
                                .filter({ typeId: type.id })
                                .map((method) => build(method, type))
                                .value();
                        }).filter(angular.isDefined).value();

                        return calculate(result);
                    }).value();

                    methodListController.hasBudget = _.some(methodListController.categories, { budget: true });
                    validateAll();

                    methodListController.onMode();
                });
            }

            function calculate(category) {
                const active = _.filter(category.methods, (object) => object.removed !== true);

                category.totalCredits = getSum(active, 'content.credits');
                category.totalContactHours = getSum(active, 'content.contactHours');
                category.originalCredits = getSum(category.filtered, 'originalCredits');
                category.originalContactHours = getSum(category.filtered, 'originalContactHours');
                return category;
            }

            function getSum(objects, property) {
                const value = _.sumBy(objects, (object) => {
                    return _.result(object, property, 0);
                });

                return fixFloatingPointPrecision(value);
            }

            // Fixes possible floating point precision issues ex. 0.1 + 0.2 = 0.30000000000000004
            function fixFloatingPointPrecision(number) {
                return _.isNumber(number) ? +(number).toFixed(10) : undefined;
            }

            function validateAll() {
                _.each(methodListController.categories, (category) => {
                    const maxHoursPerCredit = _.result(category, 'contactHoursPerCredit', 0);
                    if (maxHoursPerCredit > 0) {
                        _.each(category.methods, (method) => validate(method, maxHoursPerCredit));
                        category.error = _(category.methods).map('error').uniq().filter((value) => !!value).sort().join(', ');
                    }
                });
            }

            /**
             * Checks if the credits * 28 is less than the contactHours.
             * Sets displayContactHours warning to true if any method is invalid
             */
            function validate(method, maxHoursPerCredit) {
                const credits = _.result(method.content, 'credits', 0);
                const contactHours = _.result(method.content, 'contactHours', 0);

                const exceeds = (credits * maxHoursPerCredit) < contactHours;
                if (exceeds) {
                    method.error = translateFilter('Static.Page.Methods.ContactHoursWarning', {
                        amount: maxHoursPerCredit
                    });
                } else {
                    delete method.error;
                }
            }

            function build(method, type) {
                if (angular.isUndefined(method)) {
                    return undefined;
                }

                const model = {
                    id: method.id,
                    type: type,
                    content: angular.copy(method),
                    self: method.self,
                    changeType: method.changeType,
                    removed: method.changeType === 'REMOVE',
                    amount: method.amount,
                    numberOfGroups: method.numberOfGroups,
                    duration: method.duration,
                    totalDuration: (method.amount || 1) * (method.numberOfGroups || 1) * (method.duration || 0)
                };

                const change = _.find(methodListController.feedback.changes, {
                    change: {
                        entity: method.self
                    }
                });

                if (change) {
                    const properties = angular.fromJson(change.properties) || [];
                    const grouped = {};
                    _.each(properties, (property) => {
                        grouped[property.fieldName] = property.value;
                    });
                    model.change = grouped;
                }

                model.originalCredits = getSource(model, 'credits');
                model.originalContactHours = getSource(model, 'contactHours');

                return model;
            }

            function getSource(method, property) {
                if (isCreated(method)) {
                    return 0;
                }

                const change = _.result(method.change, property);
                const value = _.result(change, 'source');

                // When the property has never changed return the current value
                if (angular.isUndefined(value)) {
                    return _.result(method.content, property, 0);
                } else {
                    const parsed = parseFloat(value.replace(/,/g, '.'));
                    return fixFloatingPointPrecision(parsed);
                }
            }

            function isCreated(method) {
                return angular.isUndefined(method.content.id) || method.content.created === true;
            }

            methodListController.onChange = function (method, category) {
                method.dirty = true;
                onChange(category);
            };

            function onChange(category) {
                calculate(category);
                validateAll();

                category.dirty = _.some(category.methods, { dirty: true });
                const dirty = _.some(methodListController.categories, { dirty: true });
                if (dirty) {
                    methodListController.methodsForm.$setDirty();
                } else {
                    methodListController.methodsForm.$setPristine();
                }

                methodListController.onMode();
            }

            methodListController.add = function (category) {
                const types = getUsableTypes(category);

                MethodModals.addMethod({
                    module: methodListController.module,
                    methods: types,
                    category: category,
                    operations: methodListController.operations,
                    operationsToEdit: methodListController.operationsToEdit,
                    onAdd: function (method) {
                        onAdd(method, category);
                        autoSave();
                    }
                });
            };

            function getUsableTypes(category) {
                return _(methodListController.types)
                    .filter({
                        active: true,
                        categoryId: category.id
                    })
                    .filter((type) => {
                        if (category.duplicates) {
                            return true;
                        }

                        const methods = _.filter(category.methods, (method) => method.type.id === type.id);
                        return _.isEmpty(methods) || _.every(methods, { removed: true });
                    })
                    .value();
            }

            function onAdd(form, category) {
                const content = _.extend(form, {
                    moduleId: methodListController.module.id,
                    academicYearId: sessionStorage.academicYear
                });

                const type = _.find(methodListController.types, { id: form.typeId });
                const method = build(content, type);
                method.changeType = 'CREATE';
                method.removed = false;
                method.dirty = true;

                category.methods.push(method);
                onChange(category);
            }

            methodListController.edit = function (method, category) {
                MethodModals.editMethod({
                    method: method.content,
                    type: method.type,
                    category: category,
                    operations: methodListController.operations,
                    operationsToEdit: methodListController.operationsToEdit,
                    onSubmit: function (result) {
                        method.content = angular.copy(result);
                        method.dirty = true;

                        onChange(category);
                        autoSave();
                    }
                });
            };

            methodListController.delete = function (method, category) {
                const isNew = angular.isDefined(method.content.id);

                if (isNew) {
                    method.changeType = 'REMOVE';
                    method.removed = true;
                    method.dirty = true;
                } else {
                    _.remove(category.methods, (it) => it.type.id === method.type.id);
                }

                onChange(category);
                autoSave();
            };

            function autoSave() {
                if (methodListController.isAutoSave === true) {
                    methodListController.save(methodListController.buildSaveModel());
                }
            }

            /**
             * Saves all the teaching methods
             */
            methodListController.save = function (methods, comment) {
                const body = {
                    comment,
                    contents: _.filter(methods, { removed: false }),
                    removes: _(methods).filter({ removed: true }).map('id').value()
                };

                return Method.update(body).$promise.then(() => {
                    methodListController.methodsForm.$setPristine();
                    feedbackObserver.dataChanged();
                    Message.addSuccess(translateFilter('Static.Message.DataAdjusted'));
                    return loadData();
                });
            };

            /**
             * Retrieve all dirty methods.
             */
            methodListController.buildSaveModel = function () {
                return _(methodListController.categories).map((category) => {
                    return _(category.methods).filter({ dirty: true }).map((method) => {
                        const model = angular.copy(method.content);
                        return _.extend(model, {
                            removed: method.removed === true
                        });
                    }).value();
                }).flatten().value();
            };

            methodListController.onMode = function () {
                _.forEach(methodListController.categories, (category) => {
                    category.filtered = _.filter(category.methods, (method) => !methodListController.active || !method.removed || method.dirty);
                    calculate(category);
                });
            };
        }
    });
