'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusPropagationModal
 * @description
 * statusPropagationModal Shows the status propagation
 */
angular.module('uasApp').component('statusPropagationModal', {
        bindings: {
            propagation: '<',
            processType: '<',
            instance: '<'
        },
        templateUrl: 'es6/status/propagation/status.propagation.modal.html',
        controllerAs: 'statusPropagationModalController',
        controller: function ($q, StatusPropagation, ProcessType, StatusType, Message, i18nFilter) {
            const statusPropagationModalController = this;

            statusPropagationModalController.$onInit = function () {
                loadData();
            };

            function loadData() {
                statusPropagationModalController.loading = true;
                $q.all([
                    ProcessType.query().$promise,
                    StatusType.query().$promise
                ]).then(([processTypes, statusTypes]) => {
                    statusPropagationModalController.statusTypes = _(statusTypes).filter((propagation) => {
                        return propagation.processType !== statusPropagationModalController.processType.id;
                    }).map((statusType) => {
                        const processType = _.find(processTypes, { id: statusType.processType });
                        return _.extend(statusType, {
                            processType: processType,
                            label: `${i18nFilter(processType.labels)} - ${i18nFilter(statusType.labels) || statusType.name}`
                        });
                    }).sortBy(['processType.sequence', 'processType.id', 'index', 'id']).value();
                }).finally(() => {
                    statusPropagationModalController.loading = false;
                });
            }

            statusPropagationModalController.close = function () {
                statusPropagationModalController.instance.dismiss();
            };

            statusPropagationModalController.save = function () {
                StatusPropagation.store(statusPropagationModalController.propagation).$promise.then(() => {
                    Message.onSaved();
                    statusPropagationModalController.close();
                });
            };

            statusPropagationModalController.remove = function() {
                StatusPropagation.remove({ id: statusPropagationModalController.propagation.id }).$promise.then(() => {
                    Message.onRemoved();
                    statusPropagationModalController.close();
                });
            };
        }
    });
