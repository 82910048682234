'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:MethodParameter
 * @description
 * The MethodParameter service.
 */
angular.module('uasApp').factory('MethodParameter', function(UasResource) {
    return UasResource('/method-parameters');
});
