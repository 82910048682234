'use strict';

/**
 * Retrieves the joins arguments
 */
angular.module('uasApp').filter('join', function() {
    return function(values, separator) {
        return values.join(separator || ', ');
    };
});
