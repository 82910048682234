'use strict';

angular.module('uasApp').component('entitySearch', {
  bindings: {
    inputId: '@',
    entity: '<',
    isReadOnly: '<',
    isRequired: '<',
    isOpen: '<',
    onEdit: '&',
    onRemove: '&',
    viewClasses: '<?',
    classes: '<?'
  },
  templateUrl: 'es6/app/entity/entity.search.html',
  transclude: true,
  controllerAs: 'entitySearchController',
  controller: function () {
    const entitySearchController = this;

    entitySearchController.$onChanges = function () {
      entitySearchController.open = entitySearchController.isOpen === true && entitySearchController.isReadOnly !== true;
    };
  }
});
