'use strict';

angular.module('uasApp').component('taskMove', {
    bindings: {
        tasks: '<',
        onChange: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/staffing/task/task.move.html',
    controllerAs: 'taskMoveController',
    controller: function($uibModal, Task, Message) {
        const taskMoveController = this;

        taskMoveController.confirm  = function() {
            $uibModal.open({
                templateUrl: 'es6/staffing/task/task.move.confirm.html',
                controllerAs: 'taskConfirmController',
                controller: function($uibModalInstance) {
                    const taskConfirmController = this;

                    taskConfirmController.move = function() {
                        return Task.move({
                            taskIds: _.map(taskMoveController.tasks, 'id'),
                            assignmentId: taskMoveController.assignmentId
                        }).$promise.then(() => {
                            Message.onSaved();
                            $uibModalInstance.dismiss();
                            taskMoveController.onChange();
                        });
                    };

                    taskConfirmController.cancel = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };

        taskMoveController.cancel = function() {
            taskMoveController.onCancel();
        };
    }
});