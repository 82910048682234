'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasUnauthorized
 * @description
 * # uasUnauthorized
 * uasUnauthorized component of the uasApp
 */
angular.module('uasApp').component('uasUnauthorized', {
  bindings: {
    message: '@?'
  },
  templateUrl: 'es6/security/unauthorized.html',
  controllerAs: 'unauthorizedController'
});
