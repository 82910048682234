'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTypeLabel
 * @description
 * statusTypeLabel Shows the status type
 */
angular.module('uasApp').component('statusTypeLabel', {
    bindings: {
        statusType: '<'
    },
    templateUrl: 'es6/status/type/status.type.label.html',
    controllerAs: 'statusTypeLabelController'
});