'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('process-type-list', {
        url: '/process-type',
        template: `
            <uas-process-type-list>
            </uas-process-type-list>
        `,
        breadcrumb: {
            label: 'Static.Page.ProcessType',
            root: true
        },
        admin: true
    });

    $stateProvider.state('process-type-create', {
        url: '/process-type/create',
        template: `
            <uas-process-type-edit 
                process="{}">
            </uas-process-type-edit>
        `,
        admin: true
    });

    $stateProvider.state('process-type', {
        url: '/process-type/:id',
        resolve: {
          process: function($stateParams, ProcessType) {
            return ProcessType.get({ id: $stateParams.id }).$promise;
          }
        },
        controllerAs: 'processEditStateController',
        controller: function(process) {
          this.process = process;
        },
        template: `
            <uas-process-type-edit 
                process="processEditStateController.process">
            </uas-process-type-edit>
        `,
        admin: true
    });

    $stateProvider.state('process-navigator', {
        url: '/process-navigator/:id',
        resolve: {
          process: function($stateParams, ProcessType) {
            return ProcessType.get({ id: $stateParams.id }).$promise;
          }
        },
        controllerAs: 'processNavigatorStateController',
        controller: function(process) {
          this.process = process;
        },
        template: `
            <process-navigator 
                process="processNavigatorStateController.process">
            </process-navigator>
        `,
        admin: true
    });

    $stateProvider.state('process-report', {
        url: '/process/:id/report',
        resolve: {
			process: function(Process, $stateParams) {
				return Process.get($stateParams).$promise;
            }
        },
        controllerAs: 'reportStateCtrl',
        controller: function (process) {
            const reportStateCtrl = this;
            reportStateCtrl.process = process;
        },
        template: `
            <uas-process-report 
                process="reportStateCtrl.process">
            </uas-process-report>
        `,
        breadcrumb: {
            label: 'Static.Page.Process.Report',
            root: true
        }
    });

    $stateProvider.state('process-progress', {
        url: '/process/:id/progress',
        resolve: {
            process: function(Process, $stateParams) {
                return Process.get($stateParams).$promise;
            }
        },
        controllerAs: 'processProgressStateCtrl',
        controller: function(process) {
            const progressStateCtrl = this;
            progressStateCtrl.process = process;
        },
        template: `
            <uas-process-progress 
                process="processProgressStateCtrl.process">
            </uas-process-progress>
        `,
        breadcrumb: {
            label: 'Static.Page.Process.Progress',
            root: true
        }
    });
});
