'use strict';

angular.module('uasApp').component('objectiveTree', {
  templateUrl: 'es6/objective/tree/objective.tree.html',
  bindings: {
    entity: '<',
    typeId: '<',
    operations: '<'
  },
  controllerAs: 'objectiveTreeController',
  controller: function (Nodes, ModuleGroup, MethodType, Objective, CalendarPeriod, Scale, EntityService, Events, Promises, $q) {
    const objectiveTreeController = this;

    const manager = Nodes.manager({
      load: loadGroup
    });

    objectiveTreeController.$onInit = function() {
      objectiveTreeController.entityType = EntityService.getType(objectiveTreeController.entity);
      loadData();
    };

    function loadData() {
      objectiveTreeController.loading = true;

      $q.all([
        manager.init({
          entity: objectiveTreeController.entity,
          active: true
        }),
        CalendarPeriod.query({
          academicYearId: sessionStorage.academicYear
        }).$promise,
        MethodType.query({
          academicYearId: sessionStorage.academicYear,
          exam: true
        }).$promise,
        Scale.query().$promise.then((scale) => {
          return _(scale).map('values').flatten().value();
        })
      ]).then(([groups, periods, assessmentTypes, scaleValues]) => {
        objectiveTreeController.groups = groups;
        objectiveTreeController.periods = periods;
        objectiveTreeController.assessmentTypes = assessmentTypes;
        objectiveTreeController.scaleValues = scaleValues;

        objectiveTreeController.search();
      }).finally(() => {
        objectiveTreeController.loading = false;
      });
    }

    objectiveTreeController.search = function() {
      manager.traverse(objectiveTreeController.groups, searchGroup);
    };

    function searchGroup(group) {
      const period = _.find(objectiveTreeController.periods, {
        id: objectiveTreeController.periodId
      });

      group.foundModules = _(group.modules)
        .filter((module) => hasPeriod(module, period))
        .map(searchModule)
        .filter((module) => module.foundObjectives.length > 0)
        .value();

      return group;
    }

    function hasPeriod(module, period) {
      if (angular.isUndefined(period)) {
        return true;
      }

      // Search for offerings that end in the selected period
      return _.some(module.offerings, (offering) =>
        offering.endDate >= period.startDate && offering.endDate <= period.endDate
      );
    }

    function searchModule(module) {
      module.foundObjectives = _(module.objectives)
        .filter(hasScaleValue)
        .filter(hasAssessmentType)
        .value();

      return module;
    }

    function hasScaleValue(objective) {
      if (angular.isUndefined(objectiveTreeController.scaleValueId)) {
        return true;
      }

      return _.get(objective, 'value.id') === objectiveTreeController.scaleValueId;
    }

    function hasAssessmentType(objective) {
      if (angular.isUndefined(objectiveTreeController.assessmentTypeId)) {
        return true;
      }

      return _.some(objective.appraisals, (appraisal) => {
        return _.get(appraisal, 'assessment.typeId') === objectiveTreeController.assessmentTypeId;
      });
    }

    objectiveTreeController.toggleGroup = function(group, $event) {
      if (!Events.isClickableTarget($event)) {
        manager.toggle(group);
      }
    };

    function loadGroup(group) {
      return ModuleGroup.modules({
        id: group.id,
        active: true
      }).$promise.then((modules) => {
        const promises = _.map(modules, (module) => {
          return () => loadModule(module);
        });

        return Promises.chain(promises).$promise;
      }).then((modules) => {
        group.modules = _.map(modules, (module, $index) => {
          module.index = $index;
          module.module.type = 'module';
          return module;
        });

        return searchGroup(group);
      });
    }

    function loadModule(module) {
      return Objective.nodes({
        entityType: 'module',
        entityId: module.module.id
      }).$promise.then((objectives) => {
        module.objectives = objectives;
        return searchModule(module);
      });
    }

    objectiveTreeController.toggleModule = function(module, $event) {
      if (!Events.isClickableTarget($event)) {
        module.open = module.open !== true;
      }
    };

    objectiveTreeController.expandAll = function (expanded) {
      return manager.expand(objectiveTreeController.groups, expanded, undefined, (group) => {
        _.forEach(group.modules, (module) => {
          module.open = expanded;
        });
      });
    };
  }
});
