'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:uasActivities
 * @description
 */
angular.module('uasApp').component('uasActivities', {
  bindings: {
    module: '<',
    period: '<',
    operations: '<',
    mode: '@?',
    hideScheduleWeek: '<?',
    hideDate: '<?',
    hideCopy: '<?',
    categoryId: '<?',
    filterOnCategory: '<?',
    isReadOnly: '<?',
    isWorkflow: '<?',
    onValidate: '&'
  },
  templateUrl: 'es6/schedule/activity/grid/activities.html',
  controllerAs: 'activitiesController',
  controller: function($q, $uibModal, Offering, ActivityManager, Activity, EntityService, Element, Message, Parameter,
        CalendarPeriod, translateFilter, Method, MethodType, Category, ModuleWeek, SecurityService) {

        const activitiesController = this;

        activitiesController.setMode = function(mode) {
            activitiesController.mode = mode;
            setWeeks();
        };

        function setWeeks() {
            if (activitiesController.mode === 'edit') {
                activitiesController.groupedWeeks = groupByPeriod(activitiesController.weeks);

                const weeksBefore = _(activitiesController.weeks)
                    .filter('beforePeriod')
                    .sortBy('scheduleWeek')
                    .value();

                if (weeksBefore.length > 0) {
                    activitiesController.groupedWeeks.unshift({
                        label: translateFilter('Static.Tab.Schedule.Activities.BeforePeriod'),
                        weeks: weeksBefore,
                        open: true
                    });
                }

                const weeksAfter = _(activitiesController.weeks)
                    .filter('afterPeriod')
                    .sortBy('scheduleWeek')
                    .value();

                if (weeksAfter.length > 0) {
                    activitiesController.groupedWeeks.push({
                        label: translateFilter('Static.Tab.Schedule.Activities.AfterPeriod'),
                        weeks: weeksAfter,
                        open: true
                    });
                }
            }
        }

        function outsidePeriod(week) {
            return week.beforePeriod || week.afterPeriod;
        }

        function groupByPeriod(weeks) {
            return activitiesController.periodWeeks = _(weeks)
                .filter((week) => !outsidePeriod(week))
                .groupBy('period')
                .map((values, periodNumber) => {
                    const period = _.find(activitiesController.periods, { number: parseInt(periodNumber) });
                    return {
                        label: _.get(period, 'code'),
                        weeks: values,
                        open: true
                    };
                })
                .sortBy('period')
                .value();
        }

        activitiesController.$onInit = function () {
            activitiesController.typeSequence = 0;
            activitiesController.isSaving = false;
            activitiesController.showBalloon = false;
            activitiesController.popoverIsOpen = true;

            activitiesController.searchParams = {
                entityType: 'module',
                entityId: activitiesController.module.id,
                periodId: activitiesController.period.id
            };

            if (activitiesController.filterOnCategory === true) {
                activitiesController.searchParams.categoryId = activitiesController.categoryId;
            }

            loadData();
        };

        activitiesController.onChange = function () {
            loadData();
            updateForm();
        };

        function loadData() {
            activitiesController.loading = true;
            $q.all([
                Method.query({
                    moduleId: activitiesController.module.id,
                    active: true
                }).$promise,
                MethodType.getAll(activitiesController.period),
                Category.query({
                    rootType: 'METHOD'
                }).$promise,
                CalendarPeriod.query({
                    calendarId: activitiesController.period.calendarId
                }).$promise,
                ModuleWeek.query({
                    moduleId: activitiesController.module.id,
                    periodId: activitiesController.period.id
                }).$promise,
                Offering.weeks({
                    entityType: 'module',
                    entityId: activitiesController.module.id,
                    periodId: activitiesController.period.id,
                    exceedPeriod: true
                }).$promise,
                Activity.query(activitiesController.searchParams).$promise,
                Element.getActive('CALENDAR_WEEK_TYPE'),
                Parameter.load()
            ]).then(([methods, methodTypes, categories, periods, moduleWeeks, weeks, activities, weekTypes]) => {
                activitiesController.allWeeks = weeks;
                activitiesController.weekTypes = weekTypes;

                activitiesController.methods = methods;
                activitiesController.methodTypes = _.map(methodTypes, (methodType) => {
                    const result = angular.copy(methodType);
                    result.category = _.find(categories, { id: methodType.categoryId });
                    return result;
                });

                loadPeriods(periods);

                activitiesController.scheduleData = {
                    module: activitiesController.module,
                    period: activitiesController.period,
                    weeks: buildWeeks(weeks, moduleWeeks),
                    types: _.map(activities, buildType),
                    activities: _(activities).map((activity) => {
                        return _.map(activity.plannings, (planning) => {
                            return _.extend(planning, { activityId: activity.id });
                        });
                    }).flatten().value()
                };

                const operationsToEdit = activitiesController.isWorkflow ? 'EDIT_SCHEDULE_WORKFLOW' : 'EDIT_SCHEDULE';
                activitiesController.editable = activitiesController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, activitiesController.operations);

                if (activitiesController.editable) {
                    activitiesController.mode = activitiesController.mode ? activitiesController.mode : 'edit';
                } else {
                    activitiesController.mode = 'read';
                }

                return loadActivities();
            }).finally(() => {
                activitiesController.loading = false;
            });
        }

        function buildType(activity) {
            const result = angular.copy(activity);
            result.typeId = _.result(activity.type, 'id');
            delete result.type;
            delete result.plannings;
            return result;
        }

        function buildWeeks(weeks, settings) {
            return _(weeks)
                .filter((week) => !outsidePeriod(week))
                .sortBy('week').map((week) => {
                    const current = _.find(settings, { week: week.week });
                    return _.extend(week, current);
                }).value();
        }

        function loadPeriods(periods) {
            activitiesController.periods = _.map(periods, (period) => ({
                id: period.id,
                code: _.result(period, 'code', translateFilter('Offering.PeriodId') + ' ' + period.period),
                number: period.period
            }));
        }

        function loadActivities() {
            activitiesController.types = [];
            activitiesController.activities = [];
            activitiesController.weeks = [];

            const data = activitiesController.scheduleData;

            const types = data.types;
            let activities = _.sortBy(data.activities, 'sequence');

            // Assign bulk identifiers for unsaved types
            _.each(types, (type) => type.bulkId = activitiesController.typeSequence++);

            // Loop through the schedule weeks and place the activities in them.
            const weeks = _(data.weeks)
              .map((week) => {
                return _.extend(week, {
                    activities: _.filter(activities, { week: week.week })
                });
              })
              .sortBy('week')
              .value();

            const minWeek = _(data.weeks).map('week').min();
            const maxWeek = _(data.weeks).map('week').max();

            // Show weeks if they are in the schedule calendar, or if they still have activities in them.
            // In that case, show a warning that the user has to move the activities out of them.
            const grouped = _.groupBy(data.activities, 'week');
            _.forEach(grouped, (it, weekText) => {
                const week = parseInt(weekText);
                if ((week < minWeek || week > maxWeek) && !_.some(weeks, { week })) {
                    const foundWeek = _.find(activitiesController.allWeeks, { week }) || { week };
                    foundWeek.activities = it;
                    weeks.push(foundWeek);
                }
            });

            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');

            /* Only put activities in the registry that belong to the current period.
               Otherwise we will delete all activities belonging to other periods when saving. */
            _.forEach(weeks, (week) => {
                resequenceWeek(week);
                week.label = calendarWeeks ? week.yearWeek : week.week;

                // Render the gridsters sequentially for a performance boost, otherwise rendering multiple gridsters takes to long.
                _.forEach(week.activities, (activity) => {
                    const type = _.find(types, {
                        id: activity.activityId
                    });
                    type.element = _.find(activitiesController.methodTypes, { id: type.typeId });
                    activity.type = type;
                });
                if (week.week < minWeek || week.week > maxWeek) {
                    week.invalid = !_.some(week.activities, activityOutsidePeriod);
                }
            });

            _.each(types, resetTypeOrigin);
            _.each(activities, resetOrigin);

            activitiesController.types = types;
            activitiesController.activities = activities;
            activitiesController.weeks = weeks;
            activitiesController.showBalloon = activitiesController.activities.length === 0;

            setWeeks();

            return activitiesController.validate();
        }

        function activityOutsidePeriod(activity) {
            const beforePeriod = _.get(activity, 'type.element.category.beforePeriod');
            const afterPeriod = _.get(activity, 'type.element.category.afterPeriod');

            return beforePeriod || afterPeriod;
        }

        activitiesController.move = function(week, activity, index) {
            week.activities.splice(index, 1);
            _.each(activitiesController.weeks, resequenceWeek);
        };

        activitiesController.validate = function() {
            return Activity.validate({
                moduleId: activitiesController.module.id,
                periodId: activitiesController.period.id,
                categoryId: activitiesController.categoryId
            }).$promise.then((validation) => {
                _.forEach(activitiesController.types, (activity) => {
                    activity.validation = _.find(validation.entities, (it) => it.entity.id === activity.id);
                });

                activitiesController.validation = validation;
                activitiesController.onValidate(validation);
            });
        };

        function resetOrigin(activity) {
            delete activity.deleted;
            delete activity.dirty;
            activity.origin = buildOrigin(activity);
        }

        function resetTypeOrigin(type) {
            delete type.dirty;
            type.origin = buildTypeOrigin(type);
        }

        activitiesController.isDirty = function(activity) {
            const origin = buildOrigin(activity);
            const modified = !_.isEqual(origin, activity.origin);
            return activity.dirty === true || activity.deleted === true || modified;
        };

        activitiesController.isTypeDirty = function(type) {
            const origin = buildTypeOrigin(type);
            const modified = !_.isEqual(origin, type.origin);
            return type.dirty === true || modified;
        };

        function buildOrigin(activity) {
            return {
                typeId: activity.type.bulkId,
                week: activity.week,
                sequence: activity.sequence,
                remark: activity.remark || ''
            };
        }

        function buildTypeOrigin(type) {
            return {
                externalId: type.externalId,
                code: type.code,
                localName: type.localName,
                englishName: type.englishName,
                typeId: type.typeId,
                assessmentId: type.assessmentId,
                methodId: type.methodId,
                duration: type.duration,
                numberOfGroups: type.numberOfGroups,
                minGroupSize: type.minGroupSize,
                maxGroupSize: type.maxGroupSize,
                parallelScheduling: type.parallelScheduling,
                roomTypeId: type.roomTypeId,
                facilities: type.facilities,
                predefinedFacilities: type.predefinedFacilities,
                suggestedDay: type.suggestedDay,
                suggestedTime: type.suggestedTime,
                remark: type.remark,
                totalExpectedParticipants: type.totalExpectedParticipants,
                values: type.values
            };
        }

        function updateForm() {
            let dirty = _.some(activitiesController.activities, { deleted: true });
            dirty = dirty || _.some(activitiesController.activities, activitiesController.isDirty);
            dirty = dirty || _.some(activitiesController.types, activitiesController.isTypeDirty);

            if (angular.isDefined(activitiesController.scheduleForm)) {
                activitiesController.scheduleForm.$dirty = dirty;
                activitiesController.scheduleForm.$pristine = !dirty;
                activitiesController.scheduleForm.$invalid = false;
            }

            return dirty;
        }

        function doAddActivity(newActivities, type) {
            // Add new activity type when not yet known
            if (angular.isUndefined(type.bulkId)) {
                doAddActivityType(type);
            }

            _.each(newActivities, (newActivity) => {
                const week = _.find(activitiesController.allWeeks, {
                    week: newActivity.week
                });
                week.activities = week.activities || [];

                newActivity.type = type;
                newActivity.sequence = week.activities.length;
                newActivity.dirty = true;

                week.activities.push(newActivity);
                activitiesController.activities.push(newActivity);
            });

            updateForm();
        }

        function doAddActivityType(type) {
            type.bulkId = activitiesController.typeSequence++;
            type.dirty = true;
            type.element = _.find(activitiesController.methodTypes, { id: type.typeId });
            activitiesController.types.push(type);
        }

        function doRemove(week, activity) {
            week.activities = _.reject(week.activities, activity);

            // Remove activities that were not not saved to the backend yet
            if (angular.isUndefined(activity.id)) {
                activitiesController.activities = _.reject(activitiesController.activities, activity);
            }

            resequenceWeek(week);
            activity.deleted = true;
            activity.dirty = true;
        }

        activitiesController.save = function(hideSaveMessage) {
            activitiesController.isSaving = true;

            function afterSave() {
                if (!hideSaveMessage) {
                    Message.addSuccess(translateFilter('Static.Message.DataSaved'));
                }
                if (!_.isEmpty(activitiesController.scheduleForm)) {
                    // UAS-1062 Added a setPristine here because the save button sometimes duplicated newly added activities (because the form was seen as dirty even after saving).
                    activitiesController.scheduleForm.$setPristine();
                }

                activitiesController.isSaving = false;
                return loadData();
            }

            const body = buildSaveBody();
            if (body.activities.length > 0) {
                return Activity.batch(body).$promise.then(afterSave).catch(loadData);
            } else {
                return afterSave();
            }
        };

        function buildSaveBody() {
            return {
                entity: EntityService.toReference(activitiesController.module),
                periodId: activitiesController.period.id,
                activities: buildActivities()
            };
        }

        function buildActivities() {
            const plannings = _(activitiesController.activities)
                .filter((activity) => activitiesController.isDirty(activity) || activitiesController.isTypeDirty(activity.type))
                .map((planning) => {
                    return {
                        id: planning.id,
                        week: planning.week,
                        sequence: planning.sequence,
                        remark: planning.remark,
                        bulkId: planning.type.bulkId,
                        active: planning.deleted !== true
                    };
                }).value();

            return _(activitiesController.types).uniqBy('bulkId').map((type) => {
                return _.extend(type, {
                    plannings: _.filter(plannings, { bulkId: type.bulkId })
                });
            }).filter((type) => {
                return type.plannings.length > 0;
            }).value();
        }

        // Update the id of the updated weeks because it can be a new week
        activitiesController.updateWeeks = function(weeks) {
            _.forEach(weeks, (entity) => {
                const week = _.find(activitiesController.weeks, { week: entity.entity.week });
                week.id = entity.entity.id;
            });
        };

        activitiesController.create = function() {
            ActivityManager.create({
                typeIds: _.map(activitiesController.types, 'typeId'),
                periodId: activitiesController.period.id,
                module: activitiesController.module,
                entity: activitiesController.module,
                categoryId: activitiesController.categoryId,
                operations: activitiesController.operations,
                onSave: update
            });
        };

        activitiesController.edit = function(activity, $event) {
            $event.stopPropagation();
            $event.preventDefault();

            const model = build(activity.type);
            ActivityManager.edit({
                activity: model,
                category: _.get(activity.type.element, 'category'),
                week: activity,
                module: activitiesController.module,
                operations: activitiesController.operations,
                onSave: (result) => {
                    const original = buildTypeOrigin(activity.type);
                    const modified = buildTypeOrigin(result);

                    if (!_.isEqual(original, modified) && model.plannings.length > 1) {
                        confirm(result, activity);
                    } else {
                        update(result);
                    }
                }
            });
        };

        function confirm(activity, week) {
            $uibModal.open({
                templateUrl: 'es6/schedule/activity/grid/activity.confirm.modal.html',
                controller: function($scope, $uibModalInstance) {
                    $scope.model = {
                        all: true,
                        name: ''
                    };

                    $scope.activity = angular.copy(activity);
                    $scope.week = angular.copy(week);

                    $scope.close = function() {
                        $uibModalInstance.close();
                    };

                    $scope.confirm = function() {
                        $scope.close();

                        if ($scope.model.all === false) {
                            const created = _.omit(activity, ['id', 'bulkId']);
                            created.name = $scope.model.name;
                            created.plannings = [{
                                week: week.week
                            }];
                            update(created);

                            const container = _.find(activitiesController.weeks, { week: week.week });
                            doRemove(container, week);
                        } else {
                            update(activity);
                        }
                    };
                }
            });
        }

        function update(activity) {
            if (angular.isUndefined(activity.id)) {
                doAddActivity(activity.plannings, buildTypeOrigin(activity));
            } else {
                const type = _.find(activitiesController.types, { bulkId: activity.bulkId });
                if (angular.isDefined(type)) {
                    updateType(type, activity);

                    const plannings = _(activitiesController.activities).filter((it) => it.type.bulkId === type.bulkId).value();
                    updateWeeks(plannings, activity, type);
                }
            }

            _.each(activitiesController.allWeeks, resequenceWeek);
            updateAndSave();
        }

        function updateType(current, activity) {
            const modified = buildTypeOrigin(activity);
            _.assign(current, modified);
        }

        function updateWeeks(weeks, activity, type) {
            _(activity.plannings).map('week').forEach((weekNumber) => {
                const current = _.filter(weeks, { week: weekNumber });
                const modified = _.filter(activity.plannings, { week: weekNumber });
                const active = !_.some(modified, { active: false });

                if (active) {
                    if (current.length === 0) {
                        doAddActivity(modified, type);
                    }
                } else {
                    const week = _.find(activitiesController.weeks, { week: weekNumber });
                    _.forEach(current, (it) => doRemove(week, it));
                }
            });
        }

        function build(type) {
            const result = angular.copy(type);
            result.plannings = _(activitiesController.activities).filter((it) => it.type.bulkId === type.bulkId).value();
            return result;
        }

        activitiesController.addWeek = function(type, week, $event) {
            $event.stopPropagation();
            $event.preventDefault();

            const activity = { week: week.week };
            doAddActivity([activity], type);
        };

        activitiesController.copy = function(type, week, $event) {
            $event.stopPropagation();
            $event.preventDefault();

            const newType = _.omit(angular.copy(type), ['id', 'bulkId']);
            newType.code = `${type.code} (2)`;

            $uibModal.open({
                templateUrl: 'es6/schedule/activity/grid/activity.copy.modal.html',
                controller: function($scope, $uibModalInstance) {
                    $scope.newType = newType;
                    $scope.type = type;
                    $scope.week = angular.copy(week);

                    $scope.model = {
                        all: false
                    };

                    $scope.close = function() {
                        $uibModalInstance.close();
                    };

                    $scope.copy = function() {
                        $scope.close();

                        if ($scope.model.all === false) {
                            const activity = { week: week.week };
                            doAddActivity([activity], $scope.newType);
                        } else {
                            const activities = _(activitiesController.activities)
                                .filter((it) => it.type.bulkId === type.bulkId)
                                .map((activity) => { return { week: activity.week }; })
                                .value();

                            doAddActivity(activities, $scope.newType);
                        }

                        updateAndSave();
                    };
                }
            });
        };

        activitiesController.remove = function(activity, week, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            $uibModal.open({
                templateUrl: 'es6/schedule/activity/grid/activity.remove.modal.html',
                controller: function($scope, $uibModalInstance) {
                    $scope.model = {
                        all: false
                    };

                    $scope.remove = function() {
                        if ($scope.model.all === true) {
                            _.each(activitiesController.weeks, function(currentWeek) {
                                _(currentWeek.activities)
                                    .filter((currentActivity) => currentActivity.type.bulkId === activity.type.bulkId)
                                    .each((currentActivity) => doRemove(currentWeek, currentActivity));
                            });
                        } else {
                            doRemove(week, activity);
                        }

                        $uibModalInstance.close();
                        updateAndSave();
                    };

                }
            });
        };

        function updateAndSave() {
            const dirty = updateForm();
            if (dirty === true) {
                activitiesController.save(false);
            }
        }

        function resequenceWeek(week) {
            _(week.activities).each((activity, index) => {
                activity.sequence = index;
                activity.week = week.week;

                if (activitiesController.activities.length > 0) {
                    activitiesController.activities.splice(_.findIndex(activitiesController.activities, { id: activity.id }), 1, activity);
                }
            });

            updateForm();
        }

        activitiesController.setValid = function(valid) {
            activitiesController.valid = valid;
        };
    }
});
