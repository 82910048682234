'use strict';

/**
 * @ngdoc service
 * @name uasServices.factory:ModuleGroup
 * @description
 * The ModuleGroup service.
 */
angular.module('uasApp')
    .factory('ModuleGroup', function(UasResource) {
        return UasResource('/module-group', {
            'offering': {
                method: 'GET', 
                url: '/module-group/:id/offering',  
                isArray: false
            },
            'modules': {
                method: 'GET', 
                url: '/module-group/:id/modules',  
                isArray: true
            },
            'tree': {
                method: 'GET', 
                url: '/module-group/tree',
                isArray: true
            },
            'studies': {
                method: 'GET', 
                url: '/module-group/:id/studies',  
                isArray: true
            },
            'search': {
                method: 'GET', 
                url: '/module-group/search',
                isArray: false
            },
            'generate': {
                method: 'POST',
                url: '/module-group/generate',
                isArray: false
            },
            'deleteFull': {
                method: 'DELETE',
                url: '/module-group/:id/full',
                isArray: false
            },
            'move': {
                method: 'POST',
                url: '/module-group/move',
                isArray: false
            }
        });
    });
