'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:documentReportPage
 * @description
 * Display the document report.
 */
angular.module('uasApp').component('documentReportPage', {
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<',
    workflow: '<?'
  },
  templateUrl: 'es6/document/report/document.report.page.html',
  controllerAs: 'documentReportPageController',
  controller: function (Page) {
    const documentReportPageController = this;

    documentReportPageController.$onInit = function () {
      documentReportPageController.reportTemplate = Page.getParameter(documentReportPageController.page, 'reportTemplate');
      documentReportPageController.hideReportTemplates = Page.getParameterAsBoolean(documentReportPageController.page, 'hideReportTemplates', false);
    };
  }
});
