'use strict';

angular.module('uasApp').component('organisationFields', {
  templateUrl: 'es6/organisation/organisation.fields.html',
  bindings: {
    organisation: '<',
    operations: '<'
  },
  controllerAs: 'organisationFieldsController',
  controller: function (CustomField, Faculty, Organisation) {
    const organisationFieldsController = this;

    const FIELDS = ['externalId', 'code', 'localName', 'englishName', 'typeId', 'facultyId', 'parentId',
      'website', 'logoUrl', 'terminated', 'startDate', 'endDate', 'staffingVisibleFrom', 'staffingVisibleTo'];

    const EXTENSIONS = [
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' },
      { name: 'staffingVisibleFrom', maxValue: 'staffingVisibleTo' },
      { name: 'staffingVisibleTo', minValue: 'staffingVisibleFrom' }
    ];

    organisationFieldsController.$onInit = function () {
      loadFaculties();
      organisationFieldsController.loadParents();

      CustomField.query({
        rootType: 'organisation',
        entityType: 'organisation'
      }).$promise.then((fields) => {
        organisationFieldsController.fields = CustomField.show(CustomField.extend(fields, EXTENSIONS), FIELDS);

        _.forEach(fields, (field) => {
          if (organisationFieldsController.isRemoved()) {
            field.readOnly = true;
          }
        });

        organisationFieldsController.onEvaluate();
      });
    };

    organisationFieldsController.onEvaluate = function () {
      const entity = {
        type: 'organisation',
        id: _.result(organisationFieldsController.organisation, 'id')
      };
      organisationFieldsController.evaluation = CustomField.getEvaluation(
        entity,
        organisationFieldsController.organisation,
        organisationFieldsController.fields);
    };

    function loadFaculties() {
      Faculty.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((faculties) => {
        organisationFieldsController.faculties = faculties;
      });
    }

    organisationFieldsController.loadParents = function () {
      const facultyId = organisationFieldsController.organisation.facultyId;
      if (angular.isDefined(facultyId)) {
        Organisation.query({ facultyId }).$promise.then((organisations) => {
          organisationFieldsController.parents = organisations;
        });
      }

      organisationFieldsController.onEvaluate();
    };

    organisationFieldsController.isRemoved = function () {
      return organisationFieldsController.organisation.changeType === 'REMOVE';
    };
  }
});
