'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uadGroupAdmin
 * @description
 * uadGroupAdmin Shows module group admin.
 */
angular.module('uasApp').component('uasGroupAdmin', {
  bindings: {
    group: '<',
    operations: '<'
  },
  templateUrl: 'es6/group/group.admin.html',
  controllerAs: 'groupAdminController',
  controller: function ($q, $state, Message, ModuleGroup, Faculty, Study, CustomPage, CustomField, EntityType, FeedbackService) {
    const groupAdminController = this;

    const EXTENSIONS = [
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' },
      // Hide fields
      { name: 'changeType', visible: false },
      { name: 'created', visible: false },
      { name: 'id', visible: false },
      { name: 'importDateTime', visible: false },
      { name: 'originalCode', visible: false },
      { name: 'originalId', visible: false },
      { name: 'publicationDate', visible: false },
      { name: 'terminated', visible: false },
      { name: 'type', visible: false },
      { name: 'uid', visible: false },
      { name: 'version', visible: false }
    ];

    groupAdminController.$onInit = function () {
      groupAdminController.group_ = angular.copy(groupAdminController.group);
      loadData();
    };

    function loadData() {
      groupAdminController.loading = true;

      loadStudies();
      $q.all([
        CustomPage.query({
          basePath: 'group.admin'
        }).$promise,
        CustomField.query({
          rootType: 'module-group',
          entityType: 'module-group'
        }).$promise,
        EntityType.get({
          rootType: 'module-group',
          entityType: 'module-group'
        }).$promise,
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([pages, fields, customType, faculties]) => {
        groupAdminController.pages = pages;
        groupAdminController.fields = CustomField.extend(fields, EXTENSIONS);
        groupAdminController.customType = customType;
        groupAdminController.faculties = faculties;

        groupAdminController.onEvaluate();
      }).finally(() => {
        groupAdminController.loading = false;
      });
    }

    groupAdminController.onEvaluate = function () {
      const entity = {
        type: 'module-group',
        id: _.result(groupAdminController.group_, 'id')
      };
      groupAdminController.evaluation = CustomField.getEvaluation(entity, groupAdminController.group_, groupAdminController.fields);
    };

    groupAdminController.loadModuleGroup = function () {
      groupAdminController.adminForm.$setPristine();
      $state.reload();
    };

    groupAdminController.onFacultyChanged = function () {
      delete groupAdminController.group_.studyId;
      groupAdminController.onStudyChanged();
      loadStudies();

      groupAdminController.onEvaluate();
    };

    function loadStudies() {
      const facultyId = groupAdminController.group_.facultyId;
      if (angular.isUndefined(facultyId)) {
        groupAdminController.studies = [];
      } else {
        Study.query({
          facultyId: facultyId
        }).$promise.then((studies) => {
          groupAdminController.studies = studies;
        });
      }
    }

    groupAdminController.onStudyChanged = function () {
      groupAdminController.onEvaluate();
    };

    groupAdminController.save = function (group, comment) {
      const body = {
        comment: comment,
        content: group
      };

      return ModuleGroup.update(body).$promise.then((result) => {
        groupAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(groupAdminController.group_);
        return result;
      });
    };

    groupAdminController.delete = function (group, comment) {
      const body = {
        comment: comment,
        id: group.id
      };

      ModuleGroup.remove(body).$promise.then(() => {
        Message.onRemoved();
        $state.reload();
      });
    };

    groupAdminController.deleteFull = function (group) {
      ModuleGroup.deleteFull({
        id: group.id
      }).$promise.then(() => {
        Message.onRemoved();

        groupAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});
