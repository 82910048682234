'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:quickLinks
 * @description
 * # quickLinks
 * Displays the entity quicklinks.
 */
angular.module('uasApp')
  .component('quickLinks', {
    bindings: {
        entity: '<',
        links: '<',
        viewClasses: '<?'
    },
    templateUrl: 'es6/dashboard/quick.links.html',
    controllerAs: 'quickLinksController',
    controller: function($state, AuthService, EntityService, SecurityService) {
        const quickLinksController = this;

        quickLinksController.$onInit = function () {
            quickLinksController.loading = true;

            AuthService.operations(
                quickLinksController.entity.type,
                quickLinksController.entity.id
            ).then((operations) => {
                initializeLinks(operations);
            }).finally(() => {
                quickLinksController.loading = false;
            });
        };
        
        function initializeLinks(operations) {
            const states = $state.get();
            _.forEach(quickLinksController.links, (link) => {
                if (link.page) {
                    link.route = getPageRoute(quickLinksController.entity);
                } else if (link.route) {
                    const state = _.find(states, {
                        name: link.route
                    });
                    link.title = _.result(state, 'data.title');

                    const stateOperations = _.result(state, 'data.secured');
                    if (_.size(stateOperations) > 0) {
                        link.visible = SecurityService.isAllowed(stateOperations, operations);
                    }
                }
            });
        }

        function getPageRoute(entity) {            
            let path = EntityService.getType(entity);
            const index = path.lastIndexOf('-');
            if (index > 0) {
                path = path.substring(index + 1);
            }
            return `${path}.page`;
        }
    }
});