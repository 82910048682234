'use strict';

/**
 * Directive to optionally focus.
 */
angular.module('uasApp').directive('focusMe', function ($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            const model = $parse(attrs.focusMe);

            scope.$watch(model, (value) => {
                if (value === true) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });

            element.bind('blur', () => {
                if (model.assign) {
                    scope.$apply(model.assign(scope, false));
                }
            });
        }
    };
});
