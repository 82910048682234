'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:qualificationsList
 * @description Shows a list of (award-)qualifications in an entity, to manage them.
 */
angular.module('uasApp').component('qualificationsList', {
  templateUrl: 'es6/qualification/list/qualifications.list.html',
  bindings: {
    entity: '<',
    operations: '<',
    isReadOnly: '<?',
    columns: '<?'
  },
  controllerAs: 'qualificationListController',
  controller: function ($uibModal, AwardQualification, Column, CustomField, EntityService, FeedbackService, Language, Message, activeFilter) {
    const qualificationListController = this;

    qualificationListController.$onInit = function () {
      qualificationListController.active = true;
      qualificationListController.entityType = EntityService.getType(qualificationListController.entity);
      qualificationListController.onLanguage = Language.onChange(loadData);
      qualificationListController.defaultColumns = ['qualification', 'sequence', 'rule', 'description'];

      loadFields().then(loadData);
    };

    qualificationListController.$onDestroy = function () {
      qualificationListController.onLanguage();
    };

    function loadFields() {
      return CustomField.query({
        rootType: EntityService.getType(qualificationListController.entity),
        entityType: 'award-qualification'
      }).$promise.then((fields) => {
        qualificationListController.fields = fields;
        qualificationListController.columns_ = Column.fromFields(
          fields,
          qualificationListController.columns,
          undefined,
          qualificationListController.defaultColumns
        );
      });
    }

    function loadData() {
      qualificationListController.loading = true;
      AwardQualification.query({
        entityType: qualificationListController.entityType,
        entityId: qualificationListController.entity.id
      }).$promise.then((entities) => {
        qualificationListController.entities = _(entities)
          .filter((entity) => activeFilter(entity, qualificationListController.active))
          .orderBy(['sequence', 'qualification.code', 'id'])
          .value();
      }).finally(() => {
        qualificationListController.loading = false;
      });
    }

    qualificationListController.onActive = function() {
      loadData();
    };

    qualificationListController.create = function() {
      const model = {
        entity: EntityService.toReference(qualificationListController.entity)
      };

      $uibModal.open({
        template: `
          <award-qualification-create
            model="modalController.model"
            entity="modalController.entity"
            fields="modalController.fields"
            operations="modalController.operations"
            on-save="modalController.onSave(entity)"
            on-cancel="modalController.close()">
          </award-qualification-create>
        `,
        controllerAs: 'modalController',
        controller: function($uibModalInstance) {
          const modalController = this;
          modalController.model = model;
          modalController.entity = qualificationListController.entity;
          modalController.fields = qualificationListController.fields;
          modalController.operations = qualificationListController.operations;

          modalController.close = function() {
            $uibModalInstance.dismiss();
          };

          modalController.onSave = function(entity) {
            modalController.close();
            onSave(entity);
          };
        }
      });
    };

    qualificationListController.edit = function(row) {
      const model = angular.copy(row);

      _.extend(model, {
        qualification: _.get(row, 'qualification.id'),
        rule: _.get(row, 'rule.id')
      });

      $uibModal.open({
        template: `
          <award-qualification-edit
            model="modalController.model"
            fields="modalController.fields"
            operations="modalController.operations"
            on-save="modalController.onSave(entity)"
            on-cancel="modalController.close()">
          </award-qualification-edit>
        `,
        controllerAs: 'modalController',
        controller: function($uibModalInstance) {
          const modalController = this;
          modalController.model = model;
          modalController.fields = qualificationListController.fields;
          modalController.operations = qualificationListController.operations;

          modalController.close = function() {
            $uibModalInstance.dismiss();
          };

          modalController.onSave = function(entity) {
            modalController.close();
            onSave(entity);
          };
        }
      });
    };

    function onSave(entity) {
      FeedbackService.onUpdate(entity);
      Message.onSaved();
      loadData();
    }

    qualificationListController.remove = function(entity, message) {
      AwardQualification.remove({
        comment: {
          message
        },
        id: entity.id
      }).$promise.then(() => {
        Message.onRemoved();
        loadData();
      });
    };
  }
});
