'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Team
 * @description
 * The Team service.
 */
angular.module('uasApp')
    .factory('Team', function(UasResource) {
        return UasResource('/teams');
    });
