'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:costDivisionReportPage
 * @description
 * costDivisionReportPage Shows the cost divisions.
 */
angular.module('uasApp')
  .component('costDivisionReportPage', {
    bindings: {
      entity: '<',
      page: '<',
      process: '<?',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/cost/division/cost.division.report.page.html',
    controllerAs: 'costDivisionReportPageController',
    controller: function (Page) {
      const costDivisionReportPageController = this;

      costDivisionReportPageController.$onInit = function () {
        costDivisionReportPageController.readOnly = costDivisionReportPageController.isReadOnly || Page.getParameterAsBoolean(costDivisionReportPageController.page, 'readOnly', false);
        costDivisionReportPageController.showCalculate = Page.getParameterAsBoolean(costDivisionReportPageController.page, 'showCalculate', false);
      };
    }
  });
