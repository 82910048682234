'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Study
 * @description
 * The Study service.
 */
angular.module('uasApp')
    .factory('Study', function (UasResource) {
        return UasResource('/study', {
            'validateCommission': {
                method: 'PUT',
                url: '/study/:id/validate-commission',
                isArray: false
            },
            'deleteFull': {
                method: 'DELETE',
                url: '/study/:id/full',
                isArray: false
            },
            'studies': {
                method: 'GET',
                url: '/studies',
                isArray: true
            }
        });
    });
