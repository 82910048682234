'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:entityLabel
 * @description
 * # EntityLabel
 * Clickable label for any entity.
 */
angular.module('uasApp').component('entityLabel', {
    bindings: {
        entity: '<',
        clickable: '<?'
    },
    transclude: true,
    templateUrl: 'es6/app/entity/entity.label.html',
    controllerAs: 'entityLabelController',
    controller: function(ENTITY_STATES, entityTranslateFilter, EntityService, Describe, Language) {
        const entityLabelController = this;

        entityLabelController.$onInit = function() {
            entityLabelController.onLanguage = Language.onChange(setText);
            setText();
            setState();
        };

        entityLabelController.$onDestroy = function() {
            entityLabelController.onLanguage();
        };

        function setText() {
            const entityId = _.get(entityLabelController.entity, 'id');
            const name = entityTranslateFilter(entityLabelController.entity);

            if (name) {
                entityLabelController.current = _.extend(entityLabelController.entity, { name });
                entityLabelController.root = _.extend(entityLabelController.current, {
                    reference: EntityService.toReference(entityLabelController.current)
                });
            } else if (angular.isDefined(entityId)) {
                Describe.get({
                    entityId: entityLabelController.entity.id,
                    entityType: entityLabelController.entity.type,
                    language: Language.get()
                }).$promise.then((result) => {
                    entityLabelController.current = result.entity;
                    entityLabelController.root = result.root ? result.root : entityLabelController.current;
                });
            }
        }

        function setState() {
            if (entityLabelController.clickable !== false) {
                const type = _.get(entityLabelController, 'entity.type');
                let state = ENTITY_STATES[type];
                if (state) {
                    state += '({ id: entityLabelController.entity.id })';
                }
                entityLabelController.state = state;
            }
        }
    }
});
