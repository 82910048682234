'use strict';

angular.module('uasApp').component('groupTypeList', {
    templateUrl: 'es6/group/type/group.type.list.html',
    controllerAs: 'groupTypeListController',
    controller: function ($uibModal, Pageable, GroupType, Message) {
        const groupTypeListController = this;

        groupTypeListController.$onInit = () => {
            groupTypeListController.pageable = Pageable.of({
                order: 'sequence'
            });
            groupTypeListController.search();
        };

        groupTypeListController.search = () => {
            const params = groupTypeListController.pageable.build({
                text: groupTypeListController.text || ''
            });

            groupTypeListController.loading = true;
            return GroupType.search(params).$promise.then((groupTypes) => {
                groupTypeListController.groupTypes = groupTypes;
            }).finally(() => {
                groupTypeListController.loading = false;
            });
        };

        groupTypeListController.create = function () {
            open({
                groups: true,
                modules: true
            });
        };

        groupTypeListController.edit = function (groupType) {
            GroupType.get({
                id: groupType.id
            }).$promise.then(open);
        };

        function open(groupType) {
            $uibModal.open({
                resolve: {
                    groupTypes: function () {
                        return GroupType.query().$promise;
                    },
                    moduleTypes: function (ModuleType) {
                        return ModuleType.query().$promise;
                    }
                },
                size: 'lg',
                templateUrl: 'es6/group/type/group.type.edit.html',
                controllerAs: 'groupTypeEditController',
                controller: function ($uibModalInstance, groupTypes, moduleTypes) {
                    const groupTypeEditController = this;

                    groupTypeEditController.$onInit = function () {
                        groupTypeEditController.groupType = angular.copy(groupType);
                        groupTypeEditController.groupTypes = groupTypes;
                        groupTypeEditController.moduleTypes = moduleTypes;

                        groupTypeEditController.groupTypesModel = _.filter(groupTypeEditController.groupType.types, (type) =>
                            _.find(groupTypeEditController.groupTypes, { id: type.typeId }));
                        groupTypeEditController.moduleTypesModel = _.filter(groupTypeEditController.groupType.types, (type) =>
                            _.find(groupTypeEditController.moduleTypes, { id: type.typeId }));
                    };

                    groupTypeEditController.save = function () {
                        groupTypeEditController.groupType.types = _.concat(groupTypeEditController.groupTypesModel, groupTypeEditController.moduleTypesModel);

                        GroupType.store(groupTypeEditController.groupType).$promise.then(() => {
                            groupTypeEditController.close();
                            groupTypeListController.search();
                            Message.onSaved();
                        });
                    };

                    groupTypeEditController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

        groupTypeListController.delete = function (groupType) {
            GroupType.delete(groupType).$promise.then(() => {
                groupTypeListController.search();
                Message.onRemoved();
            });
        };
    }
});
