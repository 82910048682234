'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:MethodModals
 * @description
 * The MethodModals service.
 */
angular.module('uasApp').factory('MethodModals', function($uibModal) {

    const instance = {};
    
    /**
     * Opens the modal to add a new method to the current module.
     */
    instance.addMethod = function(params) {
        $uibModal.open({
            size: 'lg',
            controllerAs: 'methodAddModalController',
            controller: function($uibModalInstance) {
                this.module = params.module;
                this.methods = _.filter(params.methods || {}, (method) => method.active !== false);
                this.category = params.category || {};
                this.operations = params.operations || [];
                this.operationsToEdit = params.operationsToEdit || [];
                this.askComments = params.askComments;
                this.autoSave = params.autoSave;

                this.add = function(method, comment) {
                    params.onAdd(method, comment);
                    $uibModalInstance.dismiss();
                };

                this.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            template: `
                <method-add
                    module="methodAddModalController.module"
                    methods="methodAddModalController.methods"
                    category="methodAddModalController.category"
                    operations="methodAddModalController.operations"
                    operations-to-edit="methodAddModalController.operationsToEdit"
                    ask-comments="methodAddModalController.askComments"
                    auto-save="methodAddModalController.autoSave"
                    on-add="methodAddModalController.add(method, comment)"
                    on-cancel="methodAddModalController.close()">
                </method-add>
            `
        });
    };

    /**
     * Opens the modal to edit method of the current module.
     */
     instance.editMethod = function(params) {
        $uibModal.open({
            size: 'lg',
            controllerAs: 'methodEditModalController',
            controller: function($uibModalInstance) {
                this.method = angular.copy(params.method);
                this.type = params.type;
                this.category = params.category;
                this.operations = params.operations;
                this.operationsToEdit = params.operationsToEdit || [];
                this.autoSave = params.autoSave;

                this.submit = function(method, comment) {
                    params.onSubmit(method, comment);
                    $uibModalInstance.dismiss();
                };

                this.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            template: `
                <method-edit
                    method="methodEditModalController.method"
                    type="methodEditModalController.type"
                    category="methodEditModalController.category"
                    operations="methodEditModalController.operations"
                    operations-to-edit="methodEditModalController.operationsToEdit"
                    excluded-fields="['typeId']"
                    auto-save="methodEditModalController.autoSave"
                    on-submit="methodEditModalController.submit(method, comment)"
                    on-cancel="methodEditModalController.close()">
                </method-edit>
            `
        });
    };

    return instance;
});
