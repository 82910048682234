'use strict';

angular.module('uasApp').component('mailingList', {
  bindings: {
    assets: '<',
    onClose: '&'
  },
  templateUrl: 'es6/inventory/mailing/mailing.list.html',
  controllerAs: 'mailingListController',
  controller: function(Mailing) {
    const mailingListController = this;

    mailingListController.$onInit = function() {
        mailingListController.mailings = Mailing.generate(mailingListController.assets);
    };

    mailingListController.close = function() {
        mailingListController.onClose();
    };
  }
});