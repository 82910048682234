'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:statusLabel
 * @description
 * # StatusLabel
 * Clickable label for any entity.
 */
angular.module('uasApp')
    .directive('entityStatusLabel', function() {
        return {
            restrict: 'E',
            scope: {
                status: '<'
            },
            template: '<status-label status="status" mapping="mapping"></status-label>',
            controller: function($scope) {
                $scope.mapping = {
                    'TERMINATED': {
                        label: 'label-default',
                        icon: 'trash'
                    },
                    'FROZEN': {
                        label: 'label-default',
                        icon: 'lock'
                    },
                    'STRUCTURE': {
                        label: 'label-primary',
                        icon: 'calendar'
                    },
                    'STRUCTURE_ADVICE': {
                        label: 'label-warning',
                        icon: 'comments'
                    },
                    'STRUCTURE_FINAL': {
                        label: 'label-info',
                        icon: 'eye'
                    },
                    'STRUCTURE_VALIDATED': {
                        label: 'label-success',
                        icon: 'check'
                    },
                    'CONTENT': {
                        label: 'label-danger',
                        icon: 'indent'
                    },
                    'CONTENT_FINAL': {
                        label: 'label-info',
                        icon: 'eye'
                    },
                    'CONTENT_VALIDATED': {
                        label: 'label-success',
                        icon: 'check'
                    },
                    'FINISHED': {
                        label: 'label-success',
                        icon: 'gift'
                    },
                    'UNSCHEDULED': {
                        label: 'label-danger',
                        icon: 'hourglass-half'
                    },
                    'SCHEDULE_PREPARING': {
                        label: 'label-warning',
                        icon: 'pencil'
                    },
                    'SCHEDULE_PREPARED': {
                        label: 'label-success',
                        icon: 'calendar'
                    }
                };
            }
        };
    });
