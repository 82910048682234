'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('import-ignore', {
        url: '/import-ignore',
        template: '<uas-import-ignore></uas-import-ignore>',
        breadcrumb: {
            label: 'Static.Page.ImportIgnore',
            root: true
        },
        admin: true
    });

});
