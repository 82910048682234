'use strict';

angular.module('uasApp').component('saveButton', {
  templateUrl: 'es6/app/forms/buttons/save.button.html',
  bindings: {
    form: '<?',
    unchanged: '<?',
    btnContext: '@?',
    btnLabel: '@?',
    btnIcon: '@?',
    btnSize: '@?',
    isDisabled: '<?',
    isSaving: '<?'
  },
  require: {
    securedCtrl: '?^secured',
    formCtrl: '?^^form'
  },
  controllerAs: 'saveButtonController',
  controller: function ($element) {
    const saveButtonController = this;

    saveButtonController.$onInit = function () {
      saveButtonController.form_ = saveButtonController.form || saveButtonController.formCtrl;

      saveButtonController.btnClass = `btn-${saveButtonController.btnContext || 'primary'}`;
      saveButtonController.iconClass = saveButtonController.btnIcon || 'fa-hdd-o';

      if (saveButtonController.btnSize) {
        saveButtonController.btnSizeClass = `btn-${saveButtonController.btnSize}`;
      }

      if (saveButtonController.securedCtrl) {
        saveButtonController.securedCtrl.resecure($element);
      }
    };
  }
});
