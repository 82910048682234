'use strict';

angular.module('uasApp')
    .component('methodReport', {
        bindings: {
            entity: '<',
            operations: '<?'
        },
        templateUrl: 'es6/methods/report/method.report.html',
        controllerAs: 'methodReportController',
        controller: function (Report, Language, EntityService, entityRedirect) {
            const methodReportController = this;

            methodReportController.$onInit = function() {
                setName();
                loadData();

                methodReportController.onLanguage = Language.onChange(loadData);
            };

            methodReportController.$onDestroy = function() {
                methodReportController.onLanguage();
            };

            function loadData() {
                methodReportController.loading = true;
                Report.methods({
                    definition: true
                }).$promise.then((report) => {
                    methodReportController.columns = report.headers;
                    methodReportController.groups = report.groups;
                }).finally(() => {
                    methodReportController.loading = false;
                });
            }

            function setName() {
                methodReportController.name = 'methods';
                methodReportController.context = 'methods';

                methodReportController.entityType = EntityService.getType(methodReportController.entity);

                if (angular.isDefined(methodReportController.entity)) {
                    methodReportController.name = `${methodReportController.entity.code}-methods`;
                    methodReportController.context = `${methodReportController.entityType}.methods`;
                }
            }

            methodReportController.onChange = function() {
                refreshReport();
            };

            function refreshReport() {
                methodReportController.reload = {
                    reset: true
                };
            }

            methodReportController.getRows = function (pageable) {
                const parameters = getParameters(pageable);
                return Report.methods(parameters).$promise;
            };

            function getParameters(pageable) {
                const parameters = EntityService.extend({
                    academicYearId: sessionStorage.academicYear,
                    active: methodReportController.active,
                    language: Language.get()
                }, methodReportController.entity);

                return _.extend(pageable, parameters);
            }

            methodReportController.onClick = function (row, col) {
                if (col.group === 'module') {
                    entityRedirect.go('module.teaching-method', { id: row.data.moduleId }, { target: '_blank' });
                } else {
                    entityRedirect.go('method.general', { id: row.entity.id }, { target: '_blank' });
                }
            };
        }
    });