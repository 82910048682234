'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionReportPage
 * @description
 * descriptionReportPage Shows description for all studies that use the entity
 */
angular.module('uasApp').component('descriptionReportPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/i18n/descriptions/description.report.page.html',
  controllerAs: 'descriptionReportPageController',
  controller: function (Description, EntityService, Language) {
    const descriptionReportPageController = this;

    descriptionReportPageController.$onInit = function () {
      descriptionReportPageController.rootType = EntityService.getType(descriptionReportPageController.entity);
      descriptionReportPageController.context = `${descriptionReportPageController.rootType}.descriptions`;
      descriptionReportPageController.parameters = {};

      descriptionReportPageController.onLanguage = Language.onChange(loadData);
      loadData();
    };

    descriptionReportPageController.$onDestroy = function () {
      descriptionReportPageController.onLanguage();
    };

    descriptionReportPageController.search = function() {
      loadData();
    };

    function loadData() {
      descriptionReportPageController.loading = true;
      Description.report({
        definition: true
      }).$promise.then((report) => {
        descriptionReportPageController.columns = report.headers;
        descriptionReportPageController.groups = report.groups;
      }).finally(() => {
        descriptionReportPageController.loading = false;
      });
    }

    descriptionReportPageController.getRows = function (pageable) {
      const parameters = getParameters(pageable);
      return Description.report(parameters).$promise;
    };

    function getParameters(pageable) {
      let parameters = _.extend(descriptionReportPageController.parameters, {
        academicYearId: sessionStorage.academicYear,
        typeId: descriptionReportPageController.typeId,
        rootType: descriptionReportPageController.rootType,
        rootId: descriptionReportPageController.entity.id,
        entityType: EntityService.getEntityType(descriptionReportPageController.entityType)
      });

      return _.extend(pageable, parameters);
    }
  }
});
