'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:CustomPage
 * @description
 * The CustomPage service.
 */
angular.module('uasApp').factory('CustomPage', function (UasResource) {
  const resource = UasResource('/custom-page', {
    'query': {
      method: 'GET',
      isArray: true
    },
    'get': {
      method: 'GET'
    },
    'update': {
      method: 'POST',
      isArray: false
    }
  });

  return resource;
});
