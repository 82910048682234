'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAdd
 * @description
 * The component for adding a new or existing module to a module group.
 */
angular.module('uasApp').component('uasModuleAdd', {
  bindings: {
    uibModalInstance: '<',
    groupId: '<',
    studyId: '<',
    facultyId: '<',
    academicYearId: '<',
    showGroupAlert: '<?',
    operations: '<',
    operationsToEdit: '<',
    onAddNewModule: '&',
    onAddExistingModule: '&',
    onDelete: '&?'
  },
  controllerAs: 'moduleAddController',
  templateUrl: 'es6/planboard/add/module.add.html',
  controller: function ($q, GroupType, ModuleGroup, ModuleType, Tabs, Type) {
    const moduleAddController = this;

    moduleAddController.$onInit = function () {
      loadData().finally(() => {
        buildTabs();
      });
    };

    function loadData() {
      moduleAddController.loading = true;

      return $q.all([
        getGroup(),
        ModuleType.query().$promise,
        getExcludeTypeIds()
      ]).then(([group, moduleTypes, excludeTypeIds]) => {
        moduleAddController.group = group;

        moduleAddController.excludeIdsForCreate = excludeTypeIds.create;
        moduleAddController.createTypes = Type.getAllowed(moduleTypes, [], excludeTypeIds.create);

        moduleAddController.excludeIdsForReuse = excludeTypeIds.reuse;
        moduleAddController.existingTypes = Type.getAllowed(moduleTypes, [], excludeTypeIds.reuse);
      }).finally(() => {
        moduleAddController.loading = false;
      });
    }

    function getGroup() {
      if (moduleAddController.groupId) {
        return ModuleGroup.get({
          id: moduleAddController.groupId
        }).$promise;
      }
      return $q.resolve({});
    }

    function getExcludeTypeIds() {
      return GroupType.excluded({
        groupId: moduleAddController.groupId,
        studyId: moduleAddController.studyId
      }).$promise;
    }

    function buildTabs() {
      const tabs = [{
        title: 'Static.Modal.ModuleAdd.Existing.Tab',
        route: 'study.cost.add-existing-module',
        sequence: 1,
        visible: moduleAddController.existingTypes.length > 0
      }, {
        title: 'Static.Modal.ModuleAdd.New.Tab',
        route: 'study.cost.add-new-module',
        sequence: 2,
        visible: moduleAddController.createTypes.length > 0
      }];

      Tabs.build(tabs, [], 'study').then((constructedTabs) => {
        moduleAddController.tabs = constructedTabs;
        updateTabs();
      });
    }

    function updateTabs() {
      _.each(moduleAddController.tabs, (tab) => {
        tab.active = Tabs.isActive(tab);
        if (tab.active) {
          moduleAddController.tab = tab;
        }
      });
    }

    moduleAddController.addNewModule = function (module, message) {
      moduleAddController.onAddNewModule({ module: module, message: message });
    };

    moduleAddController.addExistingModule = function (module, message) {
      moduleAddController.onAddExistingModule({ module: module, message: message });
    };

    moduleAddController.cancel = function () {
      moduleAddController.uibModalInstance.close();
    };
  }
});
