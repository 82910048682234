'use strict';

angular.module('uasApp').component('facultiesOrganisations', {
  templateUrl: 'es6/faculties/faculties.organisations.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'organisationsController'
});
