'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:uasAdminOnly
 * @description Shows admin screen
 */
angular.module('uasApp')
  .component('uasAdminOnly', {
    transclude: true,
    templateUrl: 'es6/admin/admin.only.html',
    bindings: {
      visibleWhenImpersonating: '<?'
    },
    controllerAs: 'adminOnlyController',
    controller: function(AuthService, Session) {
      const adminOnlyController = this;
      adminOnlyController.$onInit = function() {
        adminOnlyController.visible = AuthService.isAdmin() || shouldShowWhenImpersonating();
      };

      function shouldShowWhenImpersonating() {
        return adminOnlyController.visibleWhenImpersonating && Session.restorable;
      }
    }
});
