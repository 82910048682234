'use strict';

angular.module('uasApp').factory('TaskTemplate', function (UasResource) {
    return UasResource('/task-templates', {
        search: {
            method: 'GET',
            url: '/task-templates/search',
            isArray: false
        }
    });
});
