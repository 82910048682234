'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Import
 * @description
 * The Import service.
 */
angular.module('uasApp')
    .factory('Import', function(UasResource) {
        return UasResource('/import');
    });