'use strict';

angular.module('uasApp').component('fteEdit', {
    templateUrl: 'es6/staffing/assignment/fte/fte.edit.html',
    bindings: {
        assignment: '<',
        fte: '<',
        isReadOnly: '<?',
        onChange: '&',
        onCancel: '&'
    },
    controllerAs: 'fteEditController',
    controller: function(Fte, Message) {
        const fteEditController = this;

        fteEditController.save = function() {
            Fte.save({
                comment: {},
                content: fteEditController.fte
            }).$promise.then((fte) => {
                Message.onSaved();
                fteEditController.onChange({ fte });
            });
        };

        fteEditController.cancel = function() {
            fteEditController.onCancel();
        };
    }
});