'use strict';

angular.module('uasApp')
    .directive('videoIcon', function() {
        return {
            restrict: 'E',
            scope: {
                'video': '=?'
            },
            templateUrl: 'es6/app/instruction/video.icon.html',
            controller: function($scope, $state, $rootScope, Video, Language) {

                $rootScope.$on('$stateChangeSuccess', update);
                update();

                function update() {
                    if (_.isEmpty($scope.video) && !_.isEmpty($state.current.videoId)) {
                        loadVideo();
                    } else {
                        delete $scope.video;
                    }
                }

                function loadVideo() {
                    Video.get({
                        id: $state.current.videoId
                    }).$promise.then(function(video) {
                        $scope.video = _.find(video.languages, {
                            language: Language.get()
                        });
                    });
                }

            }
        };
    });
