'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodAdmin
 * @description
 * methodAdmin Shows method admin.
 */
angular.module('uasApp').component('methodAdmin', {
  bindings: {
    initialValues: '<',
    operations: '<'
  },
  templateUrl: 'es6/methods/method.admin.html',
  controllerAs: 'methodAdminController',
  controller: function ($q, $state, Message, Method, CustomField, CustomPage, EntityType, FeedbackService) {
    const methodAdminController = this;

    methodAdminController.$onInit = function () {
      // Copy the method, because otherwise changing organisation or role, without saving this page, would empty the fields on the general tab.
      methodAdminController.method = angular.copy(methodAdminController.initialValues);

      loadData();
    };

    function loadData() {
      methodAdminController.loading = true;

      $q.all([
        CustomPage.query({
          basePath: 'method.admin'
        }).$promise,
        CustomField.query({
          rootType: 'method',
          entityType: 'method'
        }).$promise,
        EntityType.get({
          rootType: 'method',
          entityType: 'method'
        }).$promise
      ]).then(([pages, fields, customType]) => {
        methodAdminController.pages = pages;
        methodAdminController.fields = _.keyBy(fields, 'name');
        methodAdminController.customType = customType;
      }).finally(() => {
        methodAdminController.loading = false;
      });
    }

    methodAdminController.save = function (content, comment) {
      return Method.update({
        content,
        comment
      }).$promise.then((result) => {
        methodAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(methodAdminController.method);
        return result;
      });
    };

    methodAdminController.delete = function (method, comment) {
      Method.remove({
        comment: comment,
        id: method.id
      }).$promise.then(() => {
        Message.onRemoved();
        $state.reload();
      });
    };

    methodAdminController.deleteFull = function (method) {
      Method.deleteFull({
        id: method.id
      }).$promise.then(() => {
        Message.onRemoved();

        methodAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});
