'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:relationsPage
 * @description
 * relationsPage Shows relations of an entity
 */
angular.module('uasApp')
    .component('relationsPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/staffing/relation/relations.page.html',
        controllerAs: 'relationsPageController',
        controller: function (Page) {
            const relationsPageController = this;

            relationsPageController.$onInit = function () {
                relationsPageController.readOnly = relationsPageController.isReadOnly || Page.getParameterAsBoolean(relationsPageController.page, 'readOnly', false);
                relationsPageController.operationsToEdit = angular.isDefined(relationsPageController.workflow) ? 'EDIT_RELATIONS_WORKFLOW' : 'EDIT_RELATIONS';
                relationsPageController.showInactive = Page.getParameterAsBoolean(relationsPageController.page, 'showInactive', false);
                relationsPageController.types = Page.getParameterAsArray(relationsPageController.page, 'types');
            };
        }
    });
