'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:calendarPeriodList
 * @description
 * calendarPeriodList shows the periods of a calendar
 */
angular.module('uasApp').component('calendarPeriodList', {
    bindings: {
        calendar: '<'
    },
    templateUrl: 'es6/calendar/period/calendar.period.list.html',
    controllerAs: 'periodListController',
    controller: function(CalendarPeriod, Dates, Message, $uibModal) {
        const periodListController = this;

        periodListController.$onInit = function() {
            loadData();
        };

        function loadData() {
            periodListController.loading = true;
            CalendarPeriod.query({
                calendarId: periodListController.calendar.id
            }).$promise.then((periods) => {
                periodListController.periods = _.map(periods, (period) => {
                    period.numberOfWeeks = Dates.getNumberOfWeeks(period.startDate, period.endDate);
                    return period;
                });
            }).finally(() => {
                periodListController.loading = false;
            });
        }

        periodListController.create = function() {
            edit({
                calendarId: periodListController.calendar.id
            });
        };

        periodListController.edit = function(period) {
            edit(period);
        };

        function edit(model) {
            $uibModal.open({
                size: 'lg',
                template: `
                    <modal-form
                        modal-title="Static.Page.CalendarPeriods"
                        entity="periodModalController.period"
                        on-close="periodModalController.close()"
                        on-save="periodModalController.save(entity)">

                        <calendar-period-form period="periodModalController.period"></calendar-period-form>

                    </modal-form>
                `,
                controllerAs: 'periodModalController',
                controller: function($uibModalInstance) {
                    const periodModalController = this;
                    periodModalController.period = angular.copy(model);

                    periodModalController.save = function(period) {
                        CalendarPeriod.store(period).$promise.then(() => {
                            periodModalController.close();
                            Message.onSaved();
                            loadData();
                        });
                    };

                    periodModalController.close = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        }

        periodListController.remove = function(period) {
            CalendarPeriod.remove(period).$promise.then(() => {
                Message.onRemoved();
                loadData();
            });
        };
    }
});
