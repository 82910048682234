'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodGeneral
 * @description
 * Displays general method information
 */
angular.module('uasApp').component('methodGeneral', {
    bindings: {
        method: '<'
    },
    templateUrl: 'es6/methods/method.general.html',
    controllerAs: 'methodGeneralController',
    controller: function($q, MethodType, MethodSchema, Module) {
        const methodGeneralController = this;

        methodGeneralController.$onInit = function() {
            loadData();
        };

        function loadData() {
            methodGeneralController.loading = true;
            $q.all([
                MethodType.get({
                    id: methodGeneralController.method.typeId
                }).$promise,
                MethodSchema.parents({
                    methodId: methodGeneralController.method.id
                }).$promise,
                MethodSchema.children({
                    entityType: 'method',
                    entityId: methodGeneralController.method.id
                }).$promise,
                Module.get({ 
                    id: methodGeneralController.method.moduleId
                }).$promise
            ]).then(([type, parents, children, module]) => {
                methodGeneralController.type = type;
                methodGeneralController.parents = parents;
                methodGeneralController.children = children;
                methodGeneralController.module = module;
            }).finally(() => {
                methodGeneralController.loading = false;
            });
        }
    }
});
