'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingAssignPage
 * @description
 * The meetingAssignPage component.
 */
angular.module('uasApp').component('meetingAssignPage', {
  templateUrl: 'es6/schedule/meeting/meeting.assign.page.html',
  bindings: {
    entity: '<',
    page: '<',
    process: '<?',
    workflow: '<?',
    operations: '<'
  },
  controllerAs: 'meetingAssignPageController',
  controller: function (EntityService, Offering) {
    const meetingAssignPageController = this;

    meetingAssignPageController.$onInit = function () {
      meetingAssignPageController.operationsToEdit = angular.isDefined(meetingAssignPageController.workflow) ? ['EDIT_SCHEDULE_WORKFLOW'] : ['EDIT_SCHEDULE'];

      const type = EntityService.getType(meetingAssignPageController.entity);

      if (type === 'module') {
        loadPeriods(meetingAssignPageController.entity.id);
      }
    };

    function loadPeriods(moduleId) {
      meetingAssignPageController.loading = true;
      Offering.periods({
        entityType: 'module',
        entityId: moduleId,
        periodId: _.result(meetingAssignPageController.process, 'period.id')
      }).$promise.then((periods) => {
        meetingAssignPageController.periods = periods;
      }).finally(() => {
        meetingAssignPageController.loading = false;
      });
    }
  }
});