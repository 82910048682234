'use strict';

angular.module('uasApp').filter('type', function(translateFilter) {
    function getLabelKey(name) {
        const formatted = _.camelCase(name);
        return _.upperFirst(formatted);
    }

    return function(name) {
        if (!name) {
            return '';
        }

        const label = getLabelKey(name);
        let result = translateFilter(label);
        if (result === label) {
            const formatted = label.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
            result = _.upperFirst(formatted);
        }
        return result;
    };
});
