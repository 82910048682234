'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uppercased
 * @description
 * Uppercase the ngModel value.
 */
angular.module('uasApp').directive('uppercased', function () {
  return {
    require: 'ngModel',
    link: function (_scope, element, _attrs, modelCtrl) {
      modelCtrl.$parsers.push((input) => {
        return input ? input.toUpperCase() : '';
      });
      element.css('text-transform', 'uppercase');
    }
  };
});
