'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Notification
 * @description
 * The Notification service.
 */
angular.module('uasApp')
    .factory('Notification', function(UasResource) {
        return UasResource('/notification', {
            count: {
                url: '/notification/number-of-unread',
                method: 'GET'
            },
            changeSettings: {
                url: '/notification/change-settings',
                method: 'PUT'
            },
            markAsRead: {
                url: '/notification/mark-read/:id',
                method: 'PUT'
            },
            deleteAll: {
                url: '/notification',
                method: 'DELETE'
            }
        });
    });
