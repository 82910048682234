'use strict';

angular.module('uasApp').component('availabilityPage', {
    templateUrl: 'es6/staffing/availability/availability.page.html',
    bindings: {
        entity: '<?',
        page: '<',
        workflow: '<',
        operations: '<?'
    },
    controllerAs: 'availabilityPageController',
    controller: function(Page) {
        const availabilityPageController = this;

        availabilityPageController.$onInit = function() {
            availabilityPageController.readOnly = Page.getParameterAsBoolean(availabilityPageController.page, 'readOnly', false);
        };
    }
});
