'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:dropdownSelectButton
 * @description
 * Shows part of a custom button to toggle a dropdown select.
 */
angular.module('uasApp').component('dropdownSelectButton', {
  bindings: {
    btnIcon: '@',
    btnLabel: '@'
  },
  controllerAs: 'dropdownSelectButtonController',
  template: `
    <span>
      <i class="fa {{ dropdownSelectButtonController.btnIcon }} m-r-5" aria-hidden="true"></i>
      <span>{{ dropdownSelectButtonController.btnLabel }}</span>
    </span>
    `
});
