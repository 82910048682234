'use strict';

angular.module('uasApp').component('notesPanel', {
  bindings: {
    note: '<',
    onChange: '&',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/note/notes.panel.html',
  controllerAs: 'notesPanelController',
  controller: function(AuthService, Session) {
    const notesPanelController = this;

    notesPanelController.$onInit = function() {
      notesPanelController.editable = notesPanelController.isReadOnly !== true && isEditable();
      notesPanelController.editing = false;
    };

    function isEditable() {
      if (AuthService.isAdmin()) {
        return true;
      }

      const personId = _.get(notesPanelController.note, 'person.id');

      return personId === Session.personId;
    }

    notesPanelController.setEditing = function(editing) {
      notesPanelController.editing = editing;
    };

    notesPanelController.onUpdate = function() {
      notesPanelController.setEditing(false);
      notesPanelController.onChange({
        note: notesPanelController.note
      });
    };
  }
});
