'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:DropdownSelectGroup
 * @description Factory to create a list of valid option groups with their code, name, and options for a dropdown select.
 */
angular.module('uasApp').factory('DropdownSelectGroup', function() {
  /**
   * @ngdoc method
   * @param {Array} groupConfigurations An array of configuration objects, each with properties:
   *                                    unique type, keyPath (defaults to 'code'), namePath (defaults to 'displayName'), optionsPath and iterateePath.
   * @returns {Object} An object containing all valid groups with the group type as key,
   */
  function createAll(groupConfigurations) {
    const keyedConfigs = _.keyBy(groupConfigurations, 'type');

    return _.mapValues(keyedConfigs, (groupConfiguration) => createGroups(groupConfiguration));
  }

  function createGroups({ groups, keyPath = 'code', namePath = 'displayName', optionsPath, iterateePath }) {
    return _(groups).map((group) => {
      const code = _.get(group, keyPath);
      const name = _.get(group, namePath);
      let options = _.get(group, optionsPath, []);

      if (angular.isUndefined(code) || angular.isUndefined(name) || _.isEmpty(options)) {
        return null;
      }

      options = _.map(options, (option) => {
        const iteratee = _.get(option, iterateePath, 'default');

        return _.extend(option, { group: iteratee });
      });

      return { code, name, options };
    }).compact().value();
  }

  return { createAll };
});
