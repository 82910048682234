'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowHelp
 * @description
 * uasWorkflowHelp Helps during a workflow
 */
angular.module('uasApp')
  .component('uasWorkflowHelp', {
    bindings: {
      page: '<'
    },
    templateUrl: 'es6/workflow/workflow.help.html',
    controllerAs: 'workflowHelpController',
    controller: function(i18nFilter, Language) {
      const workflowHelpController = this;

      workflowHelpController.$onInit = function() {
        workflowHelpController.clear = Language.onChange(setMessage);
        setMessage();
      };

      workflowHelpController.$onDestroy = function() {
        workflowHelpController.clear();
      };

      function setMessage() {
        workflowHelpController.message = i18nFilter(workflowHelpController.page.messages);
      }
    }
});
