'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:UasVersion
 * @description
 * The UasVersion factory is used to keep cache versions between different tabs.
 */
angular.module('uasApp')
    .factory('UasVersion', function() {
        return function(key) {
            let date = new Date();

            function isChanged() {
                let changed = false;
                const json = localStorage.getItem(key);
                if (json) {
                    const data = angular.fromJson(json);
                    changed = data && data.date && new Date(data.date).getTime() > date.getTime();
                }
                return changed;
            }

            function update() {
                date = new Date();
                localStorage.setItem(key, angular.toJson({ date }));
            }

            return { isChanged, update };
        };
    });