'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Mail
 * @description
 * The Mails service.
 */
angular.module('uasApp')
    .factory('Mail', function(UasResource) {
        return UasResource('/mails', {
            retry: {
                method: 'POST',
                url: '/mails/:id/retry',
                isArray: false
            },
            statistics: {
                method: 'GET',
                url: '/mails/statistics',
                isArray: false
            }
        });
    });
