'use strict';

angular.module('uasApp').directive('ifTranslationExists', function(ngIfDirective, $translate) {
  const ngIf = ngIfDirective[0];

  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority,
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    link: function($scope, $element, $attr) {
      const labelKey = $attr.ifTranslationExists; // label key passed to this directive.
      const translated = $translate.instant(labelKey);

      const keyExists = translated !== labelKey && translated !== ''; // if the translation outcome is the same as the label key or is empty, the translation does not exist. otherwise, it does.

      $attr.ngIf = function() {
        return keyExists;
      };

      ngIf.link.apply(ngIf, arguments);
    }
  };
});
