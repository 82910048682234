'use strict';

angular.module('uasApp').component('workflowPageSelection', {
    templateUrl: 'es6/workflow/setup/workflow.setup.page-selection.html',
    bindings: {
        workflow: '=',
        pages: '<'
    },
    controllerAs: 'workflowPageSelectionController',
    controller: function($scope, workflowSetupWizardService, workflowSetupIsDirtyChecker) {

        const workflowPageSelectionController = this;

        workflowPageSelectionController.$onInit = function() {
            _.map(workflowPageSelectionController.pages, (page) => {
                page.label = `${page.route} - ${page.name}`;
                return page;
            });

            workflowPageSelectionController.workflow.pages = _.map(workflowPageSelectionController.workflow.pages, (page) => {
                page.origin = _.find(workflowPageSelectionController.pages, { id: page.id });
                page.label = _.get(page.origin, 'label');
                return page;
            });

            setAvailablePages();
        };

        function setAvailablePages() {
            workflowPageSelectionController.availablePages = _.filter(workflowPageSelectionController.pages, (page) =>
                !_.some(workflowPageSelectionController.workflow.pages, { id: page.id })
            );
        }

        workflowPageSelectionController.onPage = function() {
            setAvailablePages();
            workflowSetupWizardService.setAdditionalSteps($scope, workflowPageSelectionController.workflow.pages);
            workflowSetupIsDirtyChecker.flagDirty();
        };
    }
});
