'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:generalPage
 * @description
 * generalPage Shows an entity in read-only mode
 */
angular.module('uasApp')
  .component('generalPage', {
    bindings: {
      entity: '<',
      page: '<',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/general/general.page.html',
    controllerAs: 'generalPageController',
    controller: function (EntityService) {
      const generalPageController = this;

      generalPageController.$onInit = function () {
        generalPageController.entityType = EntityService.getType(generalPageController.entity);
      };
    }
  });
