'use strict';

/**
 * comma separates a list of numbers and groups three or more adjacent numbers
 * 
 * for example: [1,2,4,5,6,8] becomes "1, 2, 4-6, 8" 
 */
angular.module('uasApp').filter('groupAdjacent', () => {
    return function(values) {
        let groups = [[]];
        
        _.uniq(values).forEach((value) => {
            let lastSequence = _.last(groups);
            if (lastSequence.length > 0) {
                let lastValue = _.last(lastSequence);
                if (lastValue === value - 1) {
                    lastSequence.push(value);
                } else {
                    groups.push([value]);
                }
            } else {
                lastSequence.push(value);
            }
        });

        return _.map(groups, (group) => {
            if (group.length < 2) {
                return group.join(', ');
            } else {
                return _.head(group) + ' - ' + _.last(group);
            }
        }).join(', ');
    };
});
