'use strict';

angular.module('uasApp')
    .directive('feedbackCount', function () {
        return {
            restrict: 'E',
            templateUrl: 'es6/changes/feedback.count.html',
            scope: {
                count: '=',
                entity: '='
            },
            controller: function ($scope, ChangeModal) {
                $scope.show = function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    ChangeModal.openChanges($scope.entity);
                };
            }
        };
    });
