'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesTabs
 * @description Displays the faculty tabs and sets the active tab.
 */
angular.module('uasApp').component('facultiesTabs', {
  bindings: {
    facultyId: '<',
    tabs: '<'
  },
  templateUrl: 'es6/faculties/faculties.tabs.html',
  controllerAs: 'facultiesTabsController',
  controller: function ($scope, $state) {
    const facultiesTabsController = this;

    facultiesTabsController.$onInit = function () {
      setActive();
    };

    // Update the active tab when the state changes.
    $scope.$on('$stateChangeSuccess', setActive);

    function setActive() {
      _.each(facultiesTabsController.tabs, function (tab) {
        tab.active = $state.is(tab.route);
      });
    }
  }
});
