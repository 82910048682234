'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:StudyCost
 * @description The StudyCost service.
 */
angular.module('uasApp')
    .factory('StudyCost', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/study-cost/:id', {}, {
            'studies': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/study-cost/studies',
                isArray: true
            },
            'generateYear': {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/study-cost/generate-year',
                isArray: false
            },
            'properties': {
              method: 'GET',
                url: BackendService.getBaseUrl() + '/study-cost/properties',
                isArray: false
            },
            'update': {
                method: 'PUT',
                url: BackendService.getBaseUrl() + '/study-cost'
            }
        });
    });
