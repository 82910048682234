'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:columnSelector
 * @description
 * Shows an entity-options dropdown with display-type DROPDOWN_SELECT.
 * Handles changes in selected columns and offers dynamic save buttons.
 */
 angular.module('uasApp').component('columnSelector', {
  bindings: {
    type: '@',
    template: '<?',
    columns: '<',
    selectedColumnsIds: '<?',
    groups: '<?',
    hideTemplates: '<?',
    onColumn: '&',
    onSave: '&'
  },
  templateUrl: 'es6/report/column.selector.html',
  controllerAs: 'columnSelectorController',
  controller: function (translateFilter) {
    const columnSelectorController = this;

    columnSelectorController.$onChanges = function () {
      updateTemplateInfo();
    };

    function updateTemplateInfo() {
      columnSelectorController.hasTemplate = !_.isEmpty(columnSelectorController.template) && angular.isDefined(columnSelectorController.template.name);

      if (columnSelectorController.hasTemplate) {
        const visibility = columnSelectorController.template.visible === 'true' ? 'Public' : 'Private';

        columnSelectorController.saveChangesLabels = {
          adjective: translateFilter(`Static.Page.ReportTemplates.Visibility.${visibility}.Adjective`),
          name: columnSelectorController.template.name
        };
      }
    }

    columnSelectorController.onChange = function () {
      const selectedIds = columnSelectorController.selectedColumnsIds || [];

      _.forEach(columnSelectorController.columns, (column) => {
        column.visible = _.includes(selectedIds, column.id);
      });

      columnSelectorController.onColumn({ selectedIds });
    };

    columnSelectorController.save = function (createNew) {
      columnSelectorController.dropdownIsOpen = false;

      columnSelectorController.onSave({ createNew });
    };

    columnSelectorController.onToggleDropdown = function(open) {
      columnSelectorController.dropdownIsOpen = open;
    };
  }
});
