'use strict';

angular.module('uasApp').component('uasDownloadButton', {
    bindings: {
        name: '@?',
        headers: '<?',
        rows: '&?',
        report: '&?',
        size: '@?',
        btnSize: '@?',
        btnContext: '@?',
        hideLoader: '<?'
    },
    templateUrl: 'es6/download/download.button.html',
    controllerAs: 'downloadButtonController',
    controller: function (Report, $uibModal) {

        const downloadButtonController = this;
        const RESULT_SIZE_WARNING = 20000;

        downloadButtonController.btnContext = downloadButtonController.btnContext || 'default';

        downloadButtonController.downloadCsv = function () {
            download(() => {
                getReport((report) => {
                    Report.csv(downloadButtonController.name, report.rows, report.headers);
                });
            });
        };

        downloadButtonController.downloadExcel = function () {
            download(() => {
                getReport((report) => {
                    Report.excel(downloadButtonController.name, report.rows, report.headers);
                });
            });
        };

        function download(onDownload) {
            if (downloadButtonController.size > RESULT_SIZE_WARNING) {
                showDownloadWarning(onDownload);
            } else {
                onDownload();
            }
        }

        function showDownloadWarning(onDownload) {
            $uibModal.open({
                templateUrl: 'es6/download/download.warning.html',
                controllerAs: 'modalController',
                controller: function ($uibModalInstance) {
                    const modalController = this;
                    modalController.size = downloadButtonController.size;

                    modalController.download = function () {
                        $uibModalInstance.dismiss();
                        onDownload();
                    };
                }
            });
        }

        function getReport(onSuccess) {
            let promise;
            if (downloadButtonController.report) {
                promise = downloadButtonController.report().then((report) => {
                    return {
                        rows: _.map(report.rows, 'data'),
                        headers: report.headers
                    };
                });
            } else {
                promise = fetchRows(1).then((rows) => {
                    return {
                        rows,
                        headers: downloadButtonController.headers
                    };
                });
            }

            downloadButtonController.loading = true;
            return promise.then(onSuccess).finally(() => {
                downloadButtonController.loading = false;
                delete downloadButtonController.progress;
            });
        }

        function fetchRows(page) {
            return downloadButtonController.rows({
                page
            }).then((data) => {
                let result = data;

                const paged = angular.isDefined(data.last);
                if (paged) {
                    result = data.content;

                    downloadButtonController.progress = {
                        current: data.number * data.size,
                        total: data.totalElements
                    };

                    if (data.last === false) {
                        return fetchRows(page + 1).then((children) => {
                            return _.concat(result, children);
                        });
                    }
                }

                return result;
            });
        }

    }
});
