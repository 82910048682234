'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCsvUpload
 * @description
 * Upload CSV files
 */
angular.module('uasApp')
  .component('uasCsvUpload', {
    templateUrl: 'es6/csv/csv.upload.html',
    controllerAs: 'uploadController',
    controller: function(Csv, JobModal, Upload, Message) {
      const uploadController = this;

      uploadController.$onInit = function() {
        uploadController.loading = true;
        uploadController.maxSize = 2097152;

        Csv.get().$promise.then((parameters) => {
          uploadController.types = _.sortBy(parameters.types);
          uploadController.parameters = _.pick(parameters, ['separator', 'quote']);
        }).finally(() => {
          uploadController.loading = false;
        });
      };

      uploadController.onType = function() {
        Csv.document({
          type: uploadController.parameters.type
        }).$promise.then((document) => {
          uploadController.document = document;
        });
      };

      uploadController.startJob = function() {
        if (!validate()) {
          return;
        }

        uploadController.loading = true;
        Upload.upload({
          url: 'api/csv/job',
          data: {
            file: uploadController.file,
            type: uploadController.parameters.type,
            separator: uploadController.parameters.separator,
            quote: uploadController.parameters.quote
          }
        }).then((response) => {
          JobModal.open(response.data);
        }).finally(() => {
          uploadController.result = undefined;
          uploadController.error = undefined;
          uploadController.file = undefined;
          uploadController.loading = false;
        });
      };

      uploadController.upload = function() {
        if (!validate()) {
          return;
        }

        uploadController.loading = true;
        const upload = Upload.upload({
          url: 'api/csv',
          data: {
            file: uploadController.file,
            type: uploadController.parameters.type,
            separator: uploadController.parameters.separator,
            quote: uploadController.parameters.quote
          }
        });
        
        upload.then((response) => {
          uploadController.result = response.data;
          uploadController.error = undefined;
          uploadController.file = undefined;
          uploadController.loading = false;
        });
        
        upload.error(() => {
          uploadController.file = undefined;
          uploadController.loading = false;
        });
      };

      function validate() {
        let valid = false;
        if (angular.isDefined(uploadController.file)) {
          if (uploadController.file.size > uploadController.maxSize) {
            Message.addErrorLabel('Server.Error.LimitExceeded');
          } else {
            valid = true;
          }
        }
        return valid;
      }

    }
  });