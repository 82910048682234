'use strict';

/**
 * Pretty prints how long ago the date was.
 * Usage goes as follows:
 * {{ date | timeago }}
 */
angular.module('uasApp').filter('timeago', function($filter) {
    return function(date, pattern, defaultText) {
        if (angular.isUndefined(date)) {
            return '';
        }

        var millisAgo = new Date().getTime() - new Date(date).getTime();
        return $filter('duration')(millisAgo, pattern, 1, defaultText) + ' ' + $filter('translate')('Duration.Ago');
    };
});
