'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodFields
 * @description
 * # methodFields
 * The component fields for a method.
 */
 angular.module('uasApp').component('methodFields', {
    bindings: {
        method: '<',
        root: '<?',
        category: '<?',
        includeTypes: '<?',
        excludedFields: '<?',
        operations: '<',
        operationsToEdit: '<',
        onChange: '&'
    },
    templateUrl: 'es6/methods/method.fields.html',
    controllerAs: 'methodFieldsController',
    controller: function($q, CustomField, EntityType, MethodType) {
        const methodFieldsController = this;

        methodFieldsController.$onChanges = function() {
            loadData();

            if (methodFieldsController.root) {
                methodFieldsController.isPublished = angular.isDefined(methodFieldsController.root.publicationDate);
            }
        };

        methodFieldsController.onValue = function() {
            methodFieldsController.onChange({
                method: methodFieldsController.method
            });

            setEvaluation();
        };

        function loadData() {
            methodFieldsController.loading = true;
            $q.all([
                CustomField.query({
                    rootType: 'method'
                }).$promise.then((fields) => {
                    return angular.copy(fields);
                }),
                MethodType.query({
                    academicYearId: sessionStorage.academicYear,
                    categoryId: _.get(methodFieldsController.category, 'id')
                }).$promise
            ]).then(([fields, types]) => {
                methodFieldsController.fields = _.sortBy(getFields(fields), 'sequence');
                methodFieldsController.types = _.filter(types, (type) => {
                    return EntityType.isIncluded(methodFieldsController.includeTypes, type);
                });
          
                setEvaluation();
            }).finally(() => {
                methodFieldsController.loading = false;
            });
        }

        function getFields(fields) {
            CustomField.hide(fields, methodFieldsController.excludedFields);

            const category = methodFieldsController.category || {};
            if (category.credits === false) {
                CustomField.hide(fields, 'credits');
            }
            if (category.contactHours === false) {
                CustomField.hide(fields, 'contactHours');
            }

            return fields;
        }

        function setEvaluation() {
            const entity = { 
                type: 'method', 
                id: methodFieldsController.method.id
            };
            
            methodFieldsController.evaluation = CustomField.getEvaluation(entity, methodFieldsController.method, methodFieldsController.fields);
        }
    }
});