'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ItemType
 * @description
 * The item type service.
 */
angular.module('uasApp')
    .factory('ItemType', function(UasResource) {
        return UasResource('/item-type');
    });
