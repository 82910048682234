'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Session
 * @description
 * The Session service.
 */
angular.module('uasApp')
       .factory('Session', function() {
        const listeners = [];

        this.create = function(authentication) {
            this.personId = authentication.personId;
            this.name = authentication.name;
            this.externalId = authentication.externalId;
            this.role = authentication.role;
            this.authenticated = authentication.authenticated;
            this.authorized = authentication.authorized;
            this.restorable = authentication.restorable;
            this.provider = authentication.provider;
            this.simulation = authentication.simulation;
            this.photoUrl = authentication.photoUrl;

            notifyAll();
        };

        function notifyAll() {
            _.each(listeners, function(listener) {
                listener(this);
            });
        }

        this.destroy = function() {
            delete sessionStorage.token;

            this.personId = undefined;
            this.name = undefined;
            this.externalId = undefined;
            this.role = undefined;
            this.authenticated = false;
            this.authorized = false;
            this.restorable = false;
            this.provider = undefined;
            this.simulation = false;
            this.photoUrl = undefined;

            
            notifyAll();
        };

        this.onChange = function(listener) {
            listeners.push(listener);
        };

        return this;
    });
