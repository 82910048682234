'use strict';

angular.module('uasApp').component('customFieldWrapper', {
  bindings: {
    field: '<',
    entity: '<',
    operations: '<',
    operationsToEdit: '<',
    isRequired: '<?',
    isDisabled: '<?',
    isPublished: '<?',
    evaluation: '<?',
    labelKey: '@?',
    labelWidth: '@?',
    hideValidMaxLength: '<?',
    minValue: '<?',
    maxValue: '<?',
    filterValues: '<?',
    onChange: '&?'
  },
  templateUrl: 'es6/fields/custom.field.wrapper.html',
  controllerAs: 'customFieldWrapperController'
});
