'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('schedule-preference-report', {
    url: '/schedule-preference-report?periodId&facultyId',
    controllerAs: 'reportController',
    controller: function($stateParams) {
      const reportController = this;
      reportController.periodId = parse($stateParams.periodId);
      reportController.facultyId = parse($stateParams.facultyId);

      function parse(value) {
        if (value) {
          return parseInt(value);
        }
      }
    },
    template: `
      <schedule-preference-report
        period-id="reportController.periodId"
        faculty-id="reportController.facultyId">
      </schedule-preference-report>
    `,
    params: {
      periodId: {
        value: '',
        dynamic: true
      },
      facultyId: {
        value: '',
        dynamic: true
      }
    },
    breadcrumb: {
      label: 'Static.Page.SchedulePreference',
      root: true
    }
  });

});
