'use strict';

angular.module('uasApp').component('personRoleEdit', {
    bindings: {
        personRole: '<',
        operations: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/person/roles/person.role.edit.html',
    controllerAs: 'personRoleEditController',
    controller: function(PersonRole) {
        const personRoleEditController = this;

        personRoleEditController.$onInit = function() {
            personRoleEditController.personRole = build(personRoleEditController.personRole);
        };

        personRoleEditController.save = function() {
            PersonRole.store(personRoleEditController.personRole).$promise.then((result) => {
                personRoleEditController.onSave({
                    role: build(result)
                });
            });
        };

        personRoleEditController.cancel = function() {
            personRoleEditController.onClose();
        };

        function build(role) {
            role.personId = _.get(role, 'person.id', role.personId);
            role.typeId = _.get(role, 'type.id', role.typeId);
            return role;
        }
    }
});
