'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Attributes
 * @description
 * Factory for creating attribute objects with value, unique numeric id, and displayName
 * from an array of non-null attribute values e.g., ['code', 'subject']
 */
angular.module('uasApp').factory('Attributes', function() {

  function create(values) {
    return _(values).uniq().map((value) => ({
        value,
        id: _.parseInt(_.uniqueId()),
        displayName: getDisplayName(value)
      })
    ).sortBy('displayName').value();
  }

  function getDisplayName(value) {
    const words = value.match(/[A-Za-z][a-z]*/g) || [];
    return _.capitalize(words.join(' '));
  }

  return { create };
});
