'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Subjects
 * @description
 * A utility factory for subjects, and its categories, scales en scale values.
 *
 * - Subjects can be defined globally or per study.
 * - Each subject is linked to a subject category, which has a 'Subject type' (element),
 *      accessed via `subject.type.category.typeId`, often referred to as `typeId` or `subjectTypeId`.
 * - Subjects can have multiple `Version`s (`subject.versions`), making `ng-repeat track by` with just `subject.id` not viable.
 * - Modules can 'use' (versions of) subjects (`module.subjects`). Each 'usage' becomes a subject with its own id,
 *      referring back to the original subject definition (`subject.type.id`), often referred to just `subjectId`.
 */
angular.module('uasApp').factory('Subjects', function (DropdownSelectGroup, entityTranslateFilter, i18nFilter) {

  function getCategories(typeId, categories, subjects) {
    if (!_.isArray(categories) || !_.isArray(subjects)) {
      return [];
    }

    return _(categories)
      .filter((category) => categoryMatchesTypeId(typeId, category))
      .map((category) => buildCategory(category, subjects))
      .filter((category) => !_.isEmpty(category.subjects))
      .orderBy(['sequence', 'code', 'id'])
      .value();
  }

  function categoryMatchesTypeId(typeId, category) {
    return angular.isUndefined(typeId) || _.get(category, 'typeId') === typeId;
  }

  function buildCategory(category, subjects) {
    return _.extend(category,
      translateCategory(category),
      {
        subjects: _(subjects)
          .filter((subject) => subjectIsActive(subject) && subjectMatchesCategory(subject, category))
          .sortBy('type.code')
          .map(buildSubject)
          .value()
      });
  }

  function subjectIsActive(subject) {
    return subject.changeType !== 'REMOVE';
  }

  function subjectMatchesCategory(subject, category) {
    return _.get(subject, 'type.category.id') === _.get(category, 'id');
  }

  function buildSubject(subject, index) {
    return _.extend(subject,
      translateSubject(subject),
      {
        matchingModules: [],
        isFirstInCategory: index === 0
      }
    );
  }

  function translateSubject(subject) {
    return _.extend(subject, {
      displayName: i18nFilter(subject.type.names) || _.get(subject, 'type.code') || _.get(subject, 'type.externalId'),
      description: entityTranslateFilter(subject) || (subject.version && i18nFilter(subject.version.descriptions))
    });
  }

  function translateCategoriesAndSubjects(categories) {
    return _.each(categories, (category) => {
      translateCategory(category);
      _.each(category.subjects, (subject) => {
        translateSubject(subject);
      });
    });
  }

  function getSubjectGroups(categories, scales) {
    return DropdownSelectGroup.createAll([
      { type: 'subjects', groups: categories, optionsPath: 'subjects', iterateePath: 'type.category.code' },
      { type: 'scaleValues', groups: scales, optionsPath: 'values', iterateePath: 'scaleCode' }
    ]);
  }

  function translateCategory(category) {
    return _.extend(category, { displayName: i18nFilter(category.names) });
  }

  function getSubjects(categories) {
    return _.flatMap(categories, 'subjects');
  }

  function getUsedScales(categories, scales) {
    return _(scales)
      .filter((scale) => {
        return _.some(categories, (category) => {
          return category.scale.id === scale.id;
        });
      })
      .map((scale) => {
        const values = _.map(scale.values, (value) =>
          _.extend(value, { scaleCode: scale.code })
        );

        return _.extend(scale, {
          displayName: i18nFilter(scale.names),
          values: values
        });
      })
      .value();
  }

  function getScaleValues(scales) {
    return _.flatMap(scales, 'values');
  }

  function isSubjectVisible(subjectIds, selectedSubjectTypeId, subjectTypeId, subjectId) {
    if (angular.isUndefined(subjectTypeId)) {
      return false; // Subjects with a category with no type are never shown
    }

    if (selectedSubjectTypeId !== subjectTypeId) {
      return false;
    }

    return _.includes(subjectIds, subjectId);
  }

  return { getCategories, translateSubject, translateCategoriesAndSubjects, getSubjectGroups, getSubjects, getUsedScales, getScaleValues, isSubjectVisible };
});
