'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:CreateButton
 * @description
 * The CreateButton service.
 */
angular.module('uasApp').component('createButton', {
  templateUrl: 'es6/app/forms/buttons/create.button.html',
  bindings: {
    btnType: '@?',
    btnSize: '@?',
    btnIcon: '<?',
    btnIconOnly: '<?',
    btnContext: '@?',
    btnLabel: '@?',
    btnDisabled: '<?',
    isSaving: '<?',
    type: '@?'
  },
  controllerAs: 'createButtonController',
  controller: function() {
    const createButtonController = this;

    createButtonController.$onInit = function() {
      createButtonController.btnContext = createButtonController.btnContext || 'default';
      createButtonController.btnLabel = createButtonController.btnLabel || 'Static.Button.Add';
      createButtonController.iconClass = createButtonController.btnIcon || 'fa-plus';
    };
  }
});
