'use strict';

angular.module('uasApp')
    .directive('feedbackChanges', function() {
        return {
            restrict: 'E',
            templateUrl: 'es6/changes/feedback.changes.html',
            scope: {
                data: '=',
                entityTypes: '<',
                onScroll: '&'
            },
            link: function(scope) {
                scope.$watch('data', function() {
                    if (angular.isDefined(scope.data)) {
                        let changes = scope.data;
                        if (scope.skipEmpty === 'true') {
                            changes = _.filter(changes, (change) => {
                                return Object.keys(change.properties).length > 0;
                            });
                        }
                        scope.changes = changes;
                    }
                });

                scope.onCustomMessageLoaded = function(change) {
                    change.hasCustomMessage = true;
                    change.hideDetails = true;
                };

                scope.showDetails = function(change) {
                    change.hideDetails = false;
                };

                scope.onScrollComment = function (changeId) {
                    const change = _.find(scope.changes, {
                        change: {
                            id: changeId
                        }
                    });
                    if (change) {
                        change.hideDetails = false;
                    }
                    scope.onScroll();
                };
            }
        };
    });
