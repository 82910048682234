'use strict';

angular.module('uasApp')
    .directive('stateVisible', function($state, $rootScope) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                function getPlainState(route) {
                    var result = route;
                    var index = route.indexOf('(');
                    if (index > 0) {
                        result = route.substring(0, index);
                    }
                    return result;
                }

                function secure() {
                    var route = getPlainState(element.attr('state-visible') || element.attr('ui-sref'));
                    var state = _.find($state.get(), {
                        name: route
                    });
                    if (state && state.visible === false) {
                        element.attr('style', 'display:none;');
                    }
                }
                secure();
                $rootScope.$on('$state:secured', secure);
            }
        };
    });
