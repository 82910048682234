'use strict';

angular.module('uasApp').component('roleTypeSelect', {
  bindings: {
    selectId: '@',
    roles: '<?',
    parent: '<?',
    entityType: '@?',
    isRequired: '<?',
    isDisabled: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/staffing/role/role.type.select.html',
  controllerAs: 'roleTypeSelectController',
  controller: function (entityTranslateFilter, enumFilter, RoleType) {
    const roleTypeSelectController = this;

    roleTypeSelectController.$onInit = function () {
      roleTypeSelectController.sort = ['name', 'sequence'];

      roleTypeSelectController.ngModelCtrl.$render = () => {
        roleTypeSelectController.value = roleTypeSelectController.ngModelCtrl.$modelValue;
      };

      if (angular.isUndefined(roleTypeSelectController.roles)) {
        loadData();
      }

      roleTypeSelectController.showContext = _.isEmpty(roleTypeSelectController.entityType);
    };

    function loadData() {
      roleTypeSelectController.loading = true;
      RoleType.query({
        academicYearId: sessionStorage.academicYear,
        entityType: roleTypeSelectController.entityType,
        parent: roleTypeSelectController.parent
      }).$promise.then((roles) => {
        roleTypeSelectController.roles = roles;
      }).finally(() => {
        roleTypeSelectController.loading = false;
      });
    }

    roleTypeSelectController.format = function (roleType) {
      const name = entityTranslateFilter(roleType) || roleType.code;
      if (roleTypeSelectController.showContext) {
        const type = enumFilter(roleType.entityType);
        return `${type} - ${name}`;
      }
      return name;
    };

    roleTypeSelectController.onChange = function () {
      roleTypeSelectController.ngModelCtrl.$setViewValue(roleTypeSelectController.value);
    };
  }
});
