'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:teacherSchedulePage
 * @description
 * Displays schedule preference of teachers.
 */
angular.module('uasApp').component('teacherSchedulePage', {
  templateUrl: 'es6/schedule/meeting/teacher.schedule.page.html',
  bindings: {
    entity: '<',
    period: '<?',
    page: '<',
    operations: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'teacherSchedulePageController',
  controller: function ($q, Meeting, Relation, Page) {
    const teacherSchedulePageController = this;

    teacherSchedulePageController.$onInit = function () {
      teacherSchedulePageController.readOnly = teacherSchedulePageController.isReadOnly || Page.getParameterAsBoolean(teacherSchedulePageController.page, 'readOnly', false);
      teacherSchedulePageController.displayInfo_ = Page.getParameterAsBoolean(teacherSchedulePageController.page, 'displayInfo', false);

      teacherSchedulePageController.saveCallbacks = [];
      teacherSchedulePageController.forms = [];

      if (teacherSchedulePageController.entity.self.type === 'module') {
        loadTeachers(teacherSchedulePageController.entity.id);
      }
    };

    function loadTeachers(moduleId) {
      const periodId = _.result(teacherSchedulePageController.period, 'id', sessionStorage.academicYear);

      teacherSchedulePageController.loading = true;
      return Relation.teachers({
        entityType: 'module',
        entityId: moduleId,
        periodId
      }).$promise.then((teachers) => {
        teacherSchedulePageController.teachers = teachers;
      }).finally(() => {
        teacherSchedulePageController.loading = false;
      });
    }

    teacherSchedulePageController.unsavedChanges = function() {
      return _.some(teacherSchedulePageController.forms, (form) => form.$dirty);
    };

    teacherSchedulePageController.save = function (form) {
      const promises = _(teacherSchedulePageController.saveCallbacks)
        .filter((saveFn) => _.isFunction(saveFn))
        .map((saveFn) => saveFn())
        .value();

      $q.all(promises).then(() => {
        form.$setPristine();
      });
    };
  }
});
