'use strict';

angular.module('uasApp')
    .directive('showVideo', function($uibModal) {
        return {
            restrict: 'A',
            scope: {
                'showVideo': '='
            },
            link: function(scope, element) {
                element.bind('click', function() {
                    $uibModal.open({
                        templateUrl: 'es6/app/instruction/video.frame.modal.html',
                        windowClass: 'video-modal-window',
                        resolve: {
                            video: function() {
                                return scope.showVideo;
                            }
                        },
                        controller: function($scope, video) {
                            $scope.video = video;
                        }
                    });
                });
            }
        };
    });
