'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionForm
 * @description
 * descriptionForm Edit a description
 */
angular.module('uasApp').component('descriptionForm', {
    bindings: {
        element: '<',
        description: '<',
        name: '<',
        isDisabled: '<',
        editor: '<',
        onChange: '&'
    },
    templateUrl: 'es6/i18n/descriptions/description.form.html',
    controllerAs: 'descriptionFormController'
});
