'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:periodSwitcher
 * @description
 * periodSwitcher switch the period
 */
angular.module('uasApp').component('periodSwitcher', {
  bindings: {
    periods: '<',
    periodId: '<',
    onChange: '&'
  },
  templateUrl: 'es6/calendar/ribbon/period.switcher.html',
  controllerAs: 'periodSwicherController',
  controller: function() {
    const periodSwicherController = this;

    periodSwicherController.onPeriod = function(period) {
      const periodId = _.get(period, 'id');
      if (periodId !== periodSwicherController.periodId) {
        periodSwicherController.onChange({ period });
      }
    };
  }
});
