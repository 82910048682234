'use strict';

angular.module('uasApp').component('studyLinks', {
  bindings: {
    entity: '<',
    categoryId: '<?',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/link/study.links.html',
  controllerAs: 'studyLinksController',
  controller: function ($q, EntityService, Link, LinkType) {
    const studyLinksController = this;

    studyLinksController.$onInit = function () {
      studyLinksController.open = false;
      loadData();
    };

    function loadData() {
      if (EntityService.getType(studyLinksController.entity) !== 'study') {
        return;
      }

      studyLinksController.loading = true;
      $q.all([
        Link.query({
          studyId: studyLinksController.entity.id,
          categoryId: studyLinksController.categoryId
        }).$promise,
        LinkType.query().$promise
      ]).then(([links, types]) => {
        studyLinksController.links = links;
        studyLinksController.types = types;
        prepare();

        studyLinksController.grouped = _.groupBy(studyLinksController.links, 'entity.type');
      }).finally(() =>
        studyLinksController.loading = false
      );
    }

    function prepare() {
      studyLinksController.links = Link.prepare(studyLinksController.links, studyLinksController.types, studyLinksController.entity.id);
    }

    studyLinksController.expandAll = function (expanded) {
      studyLinksController.expanded = expanded;

      _.forEach(studyLinksController.links, (link) => {
        if (link.target.type === 'rule') {
          link.open = expanded;
        }
      });
    };
  }
});
