'use strict';

angular.module('uasApp').component('dragAndDrop', {
  templateUrl: 'es6/app/drag.and.drop.html',
  bindings: {
    filterId: '@',
    name: '@',
    availableItems: '<',
    availableItemsAlertKey: '@?',
    availableItemsAlertTooltipKey: '@?',
    selectedItems: '<',
    selectedItemsMax: '<?',
    selectedItemsAlertTooltipKey: '@?',
    infoLabel: '@?',
    onChange: '&?'
  },
  controllerAs: 'dragAndDropController',
  controller: function (Language, translateFilter, $element) {
    const dragAndDropController = this;

    dragAndDropController.$onChanges = function () {
      setTotal();
      Language.onChange(setTotal);
    };

    dragAndDropController.add = function (item) {
      const index = _.findIndex(dragAndDropController.availableItems, item);
      dragAndDropController.availableItems.splice(index, 1);
      onChange();
    };

    dragAndDropController.move = function (item) {
      const index = _.findIndex(dragAndDropController.selectedItems, item);
      dragAndDropController.selectedItems.splice(index, 1);
      onChange();
    };

    function onChange() {
      if (angular.isDefined(dragAndDropController.onChange)) {
        setTotal();
        dragAndDropController.onChange();
      }
    }

    dragAndDropController.onFilter = function (filter) {
      dragAndDropController.filter = filter;
    };

    dragAndDropController.removeFilter = function () {
      delete dragAndDropController.filter;
      setFocus();
    };

    dragAndDropController.filterItem = function (item) {
      return _.includes(_.toLower(item[dragAndDropController.name]), _.toLower(dragAndDropController.filter));
    };

    function setFocus() {
      $element.find(`#${dragAndDropController.filterId}`).focus();
    }

    function setTotal() {
      if (angular.isDefined(dragAndDropController.selectedItemsAlertTooltipKey)) {
        const items = dragAndDropController.selectedItems || [];

        let translationParams = {
          amountSelected: items.length
        };

        if (angular.isDefined(dragAndDropController.selectedItemsMax)) {
          translationParams.amountAvailable = dragAndDropController.selectedItemsMax;
          dragAndDropController.totalIsExceeded = items.length > dragAndDropController.selectedItemsMax;
        }

        dragAndDropController.totalLabel = translateFilter(dragAndDropController.selectedItemsAlertTooltipKey, translationParams);
      }
    }
  }
});
