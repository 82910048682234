'use strict';

angular.module('uasApp')
       .component('jobMessages', {
            templateUrl: 'es6/admin/jobs/job.messages.html',
            bindings: {
                sourceType: '@',
                sourceId: '<',
                beforeRedirect: '&'
            },
            controllerAs: 'messagesController',
            controller: function(Messages, Language, Pageable, EntityService) {
                const messagesController = this;

                messagesController.loadMessages = function() {
                    messagesController.loading = true;
                    Messages.grouped({
                        sourceType: messagesController.sourceType,
                        sourceId: messagesController.sourceId
                    }).$promise.then((messages) => {
                        _.each(messages, (message) => {
                            message.more = true;
                        });

                        const severities = _.groupBy(messages, 'severity');
                        _.each(messagesController.types, (type) => {
                            type.messages = severities[type.severity];
                            type.entityCount = _.sumBy(type.messages, 'entityCount');
                        });
                    }).finally(() => {
                        messagesController.loading = false;
                    });
                };

                messagesController.$onInit = function() {
                    messagesController.types = [{
                        style: 'danger',
                        severity: 'ERROR'
                    }, {
                        style: 'warning',
                        severity: 'WARNING'
                    }, {
                        style: 'info',
                        severity: 'INFO'
                    }];

                    messagesController.type = 'ERROR';
                    messagesController.loadMessages();
                };

                messagesController.setType = function(type) {
                    messagesController.type = type;
                };

                messagesController.open = function(message) {
                    if (angular.isUndefined(message.pageable)) {
                        message.pageable = Pageable.of();
                    }

                    message.open = message.open !== true;
                    if (message.open) {
                        messagesController.loadEntities(message);
                    }
                };

                messagesController.loadEntities = function(message) {
                    const params = message.pageable.build({
                        sourceType: messagesController.sourceType,
                        sourceId: messagesController.sourceId,
                        severity: message.severity,
                        value: message.value,
                        language: Language.get(),
                        academicYearId: sessionStorage.academicYear
                    });

                    message.loading = true;
                    Messages.entities(params).$promise.then((entities) => {
                        message.entities = entities;
                    }).finally(() => {
                        message.loading = false;
                    });
                };

                messagesController.redirect = function(entity) {
                    if (entity.reference) {
                        if (angular.isDefined(messagesController.beforeRedirect)) {
                            messagesController.beforeRedirect();
                        }

                        EntityService.redirectPlain(entity.reference, {
                            target: '_blank'
                        });
                    }
                };
            }
        });
