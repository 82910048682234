'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:CreateManager
 * @description
 * The CreateManager factory.
 */
angular.module('uasApp').factory('CreateManager', function ($uibModal, EntityService, EntityType, WorkflowStarter, WorkflowModal) {

    function open(rootType, entity, operations, onSuccess) {
        $uibModal.open({
            controllerAs: 'createModalStateController',
            controller: function($uibModalInstance) {
                const createModalStateController = this;
                createModalStateController.rootType = rootType;
                createModalStateController.entity = angular.copy(entity);
                createModalStateController.operations = angular.copy(operations);

                createModalStateController.handle = function(result) {
                    createModalStateController.close();
                    onCreate(result, onSuccess);
                };

                createModalStateController.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            template: `
                <create-modal
                    root-type="createModalStateController.rootType"
                    entity="createModalStateController.entity"
                    operations="createModalStateController.operations"
                    on-create="createModalStateController.handle(entity)"
                    on-cancel="createModalStateController.close()">
                </create-modal>
            `
        });
    }

    // Perform action after creating an entity
    function onCreate(entity, onClose, onDelete) {
        if (angular.isUndefined(entity)) {
            return; // Do nothing
        }

        function onSuccess() {
            if (_.isFunction(onClose)) {
                onClose(entity);
            }
        }

        const reference = EntityService.toReference(entity);
        EntityType.get({
            rootType: reference.type
        }).$promise.then((entityType) => {
            const action = _.result(entityType, 'createAction', '');
            if (action === 'OPEN_POPUP') {
                WorkflowModal.open({
                    entity,
                    entityType: reference.type,
                    onCancel: onClose,
                    onSave: onClose,
                    onDelete: onDelete
                });
            } else if (action === 'START_PROCESS') {
                WorkflowStarter.startWithNavigate({ 
                    entity
                }).finally(onSuccess);
            } else if (action === 'START_PROCESS_MODAL') {
                WorkflowStarter.startWithModal({
                    entity,
                    onDelete
                }).finally(onSuccess);
            } else if (action === 'OPEN_TAB') {
                onSuccess();
                EntityService.redirectPlain(reference, { target: '_blank' });
            } else {
                onSuccess();
            }
        });
    }


    return { open, onCreate };
});

