'use strict';

angular.module('uasApp').component('vacanciesPage', {
    bindings: {
        entity: '<',
        page: '<',
        operations: '<'
    },
    templateUrl: 'es6/staffing/vacancy/vacancies.page.html',
    controllerAs: 'vacanciesPageController',
    controller: function(Page) {
        const vacanciesPageController = this;

        vacanciesPageController.$onInit = function() {
            vacanciesPageController.readOnly = Page.getParameterAsBoolean(vacanciesPageController.page, 'readOnly', false);
        };
    }
});
