'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityFields
 * @description
 */
angular.module('uasApp').component('activityFields', {
    bindings: {
        activity: '<',
        entity: '<',
        period: '<',
        category: '<',
        operations: '<',
        hideFields: '<'
    },
    templateUrl: 'es6/schedule/activity/form/activity.fields.html',
    controllerAs: 'activityFieldsController',
    controller: function ($scope, CustomField, Changes, MethodType, Dates) {
        const activityFieldsController = this;

        activityFieldsController.$onInit = function () {
            $scope.$watch('activityFieldsController.activity.typeId', () => {
                activityFieldsController.onEvaluate();
            });

            activityFieldsController.onEvaluate();
        };

        activityFieldsController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'activity')) {
                activityFieldsController.onEvaluate();
            }

            if (Changes.hasChanged(changes, 'category') && angular.isDefined(activityFieldsController.category)) {
                const exam = activityFieldsController.category.exam;

                CustomField.query({
                    rootType: exam ? 'assessment' : 'method',
                    entityType: 'activity'
                }).$promise.then((fields) => {
                    return CustomField.hide(fields, activityFieldsController.hideFields);
                }).then((fields) => {
                    activityFieldsController.fields = _.keyBy(fields, 'name');
                    activityFieldsController.fields_ = _(fields).filter({ visible: true }).sortBy('sequence').value();
                    _.forEach(fields, (field) => {
                        field.isEditable = isEditable(field);
                    });
                });

                MethodType.query({
                    academicYearId: sessionStorage.academicYear,
                    categoryId: activityFieldsController.category.id
                }).$promise.then((types) => {
                    activityFieldsController.types = types;
                });
            }
        };

        function isEditable(field) {
            return CustomField.isEditable(field, activityFieldsController.activity, activityFieldsController.entity);
        }

        activityFieldsController.isActive = function (element) {
            return Dates.isActive(element, activityFieldsController.period);
        };

        activityFieldsController.onEvaluate = function () {
            if (activityFieldsController.activity) {
                const entity = {
                    type: 'activity',
                    id: activityFieldsController.activity.id
                };

                const values = activityFieldsController.activity;
                activityFieldsController.evaluation = CustomField.getEvaluation(entity, values, activityFieldsController.fields);
            }
        };
    }
});
