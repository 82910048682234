'use strict';

angular.module('uasApp')
  .component('uasNewsWidget', {
    templateUrl: 'es6/app/news/news.widget.html',
    controllerAs: 'newsWidgetController',
    controller: function(News) {
      const newsWidgetController = this;

      newsWidgetController.$onInit = function() {
        loadData();
      };

      function loadData() {
        newsWidgetController.news = News.active({
            sort: 'creationDate,desc'
        });
      }
    }
});
