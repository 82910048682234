'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Validation
 * @description
 * The Validation service.
 */
angular.module('uasApp').factory('Validation', function (UasResource) {
  const validator = UasResource('/validate');

  function validate(args) {
      return validator.get(args);
  }

  return { validate };
});
