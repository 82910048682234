'use strict';

angular.module('uasApp').component('assessmentSearch', {
  bindings: {
    inputId: '@',
    isRequired: '<',
    isReadOnly: '<?',
    multipleValues: '<?',
    defaultOpen: '<?',
    viewClasses: '<?',
    classes: '@?',
    parent: '<?',
    entity: '<?',
    category: '<?',
    includeTypes: '<?',
    filter: '<?'
  },
  require: {
    ngModelCtrl: '^ngModel',
    securedCtrl: '?^secured'
  },
  templateUrl: 'es6/assessment/assessment.search.html',
  controllerAs: 'assessmentSearchController',
  controller: function (Assessment, Changes, Pageable, $element) {
    const assessmentSearchController = this;

    assessmentSearchController.$onInit = function () {
      assessmentSearchController.text = '';
      setPageable();

      assessmentSearchController.ngModelCtrl.$render = function () {
        const values = assessmentSearchController.ngModelCtrl.$modelValue;
        assessmentSearchController.assessmentIds = _.isArray(values) ? values : [values];
        if (!assessmentSearchController.multipleValues) {
          assessmentSearchController.assessmentId = _.head(assessmentSearchController.assessmentIds);
        }
      };

      secure();
    };

    function setPageable() {
      if (!assessmentSearchController.pageable) {
        assessmentSearchController.pageable = Pageable.of({
          order: 'code'
        });
      }
    }

    assessmentSearchController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'defaultOpen')) {
        setPageable();

        assessmentSearchController.open = assessmentSearchController.defaultOpen === true;
        if (assessmentSearchController.open) {
          assessmentSearchController.search();
        }
      }
    };

    function secure() {
      if (assessmentSearchController.securedCtrl) {
        assessmentSearchController.securedCtrl.resecure($element);
      }
    }

    assessmentSearchController.search = function () {
      const params = assessmentSearchController.pageable.build({
        appraisalId: _.get(assessmentSearchController.parent, 'id'),
        categoryId: _.get(assessmentSearchController.category, 'id'),
        entityId: _.get(assessmentSearchController.entity, 'id'),
        entityType: _.get(assessmentSearchController.entity, 'type'),
        types: assessmentSearchController.includeTypes,
        text: assessmentSearchController.text,
        filter: assessmentSearchController.filter
      });

      assessmentSearchController.loading = true;
      return Assessment.search(params).$promise.then((assessments) => {
        assessmentSearchController.assessments = assessments;

        _.forEach(assessments.content, (assessment) => {
          assessment.selected = _.intersection(assessmentSearchController.assessmentIds, [assessment.id]).length > 0;
        });
      }).finally(() => {
        assessmentSearchController.loading = false;
      });
    };

    assessmentSearchController.onSelect = function (selected) {
      const assessmentId = _.result(selected, 'id');

      if (assessmentSearchController.multipleValues) {
        const assessmentIds = _(assessmentSearchController.assessmentIds).pull(assessmentId).concat(assessmentId).value();
        assessmentSearchController.assessmentIds = assessmentIds;
        assessmentSearchController.ngModelCtrl.$setViewValue(assessmentIds);
      } else {
        assessmentSearchController.assessmentId = assessmentId;
        assessmentSearchController.assessmentIds = [];
        if (assessmentId) {
          assessmentSearchController.assessmentIds.push(assessmentId);
        }
        assessmentSearchController.ngModelCtrl.$setViewValue(assessmentId);
      }

      if (_.isEmpty(assessmentSearchController.assessmentIds)) {
        assessmentSearchController.open = true;
      } else {
        assessmentSearchController.open = false;
      }
    };

    assessmentSearchController.edit = function () {
      assessmentSearchController.open = true;
      assessmentSearchController.search();
    };

    assessmentSearchController.reset = function () {
      delete assessmentSearchController.assessmentId;
      assessmentSearchController.ngModelCtrl.$setViewValue();
      assessmentSearchController.open = false;
    };
  }
});
