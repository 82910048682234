'use strict';

angular.module('uasApp').component('itemActions', {
  templateUrl: 'es6/item/item.actions.html',
  bindings: {
    entity: '<',
    item: '<',
    type: '<',
    commentType: '<?',
    operations: '<',
    operationsToEdit: '<?',
    labelWidth: '<',
    iconOnly: '<?',
    isReadOnly: '<?',
    showDates: '<',
    onAction: '&'
  },
  controllerAs: 'itemActionsController',
  controller: function (feedbackObserver, Item, ItemModal, Message) {
    const itemActionsController = this;

    itemActionsController.delete = function (item, comment) {
      Item.remove({
        comment: {
          message: comment
        },
        id: item.id
      }).$promise.then(() => {
        feedbackObserver.dataChanged();
        Message.addSuccessLabel('Static.Message.DataRemoved');
        itemActionsController.onAction();
      });
    };

    itemActionsController.restore = function (item) {
      Item.restore({
        id: item.id
      }, {}).$promise.then(() => {
        feedbackObserver.dataChanged();
        Message.addSuccessLabel('Static.Message.DataSaved');
        itemActionsController.onAction();
      });
    };

    itemActionsController.edit = function (item) {
      openModal(item.id);
    };

    function openModal(id) {
      Item.get({ id }).$promise.then((item) => {
        ItemModal.open({
          item: item,
          type: itemActionsController.type,
          entity: itemActionsController.entity,
          operations: itemActionsController.operations,
          operationsToEdit: itemActionsController.operationsToEdit,
          isReadOnly: itemActionsController.isReadOnly,
          commentType: itemActionsController.commentType,
          labelWidth: itemActionsController.labelWidth,
          showDates: itemActionsController.showDates,
          onEdit: itemActionsController.onAction
        });
      });
    }
  }
});