'use strict';

angular.module('uasApp').component('mailingButton', {
  bindings: {
    assets: '<'
  },
  templateUrl: 'es6/inventory/mailing/mailing.button.html',
  controllerAs: 'mailingButtonController',
  controller: function($uibModal) {
    const mailingButtonController = this;

    mailingButtonController.open = function() {
        $uibModal.open({
            size: 'lg',
            template: `
                <mailing-list 
                    assets="mailingModalController.assets"
                    on-close="mailingModalController.close()">
                </mailing-list>
            `,
            controllerAs: 'mailingModalController',
            controller: function($uibModalInstance) {
                this.assets = mailingButtonController.assets;
                this.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    };

  }
});