'use strict';

angular.module('uasApp').component('previewEntity', {
  bindings: {
    pageId: '<',
    entity: '<'
  },
  templateUrl: 'es6/app/preview/preview.entity.html',
  controllerAs: 'previewEntityController',
  controller: function() {
      const previewEntityController = this;

      previewEntityController.$onInit = function() {
        previewEntityController.operations = [];
      };
  }
});