'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityGridPage
 * @description
 * The activityGridPage component.
 */
angular.module('uasApp').component('activityGridPage', {
    templateUrl: 'es6/schedule/activity/grid/activity.grid.page.html',
    bindings: {
        entity: '<',
        process: '<',
        page: '<',
        workflow: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    controllerAs: 'activityGridPageController',
    controller: function (Offering, EntityService, Page, WorkflowValidator) {
        const activityGridPageController = this;

        activityGridPageController.$onInit = function () {
            activityGridPageController.readOnly = activityGridPageController.isReadOnly || Page.getParameterAsBoolean(activityGridPageController.page, 'readOnly', false);
            activityGridPageController.categoryId = Page.getParameterAsInt(activityGridPageController.page, 'category');
            activityGridPageController.filterOnCategory = Page.getParameterAsBoolean(activityGridPageController.page, 'filterOnCategory', false);
            activityGridPageController.entityType = EntityService.getType(activityGridPageController.entity);
            activityGridPageController.mode = Page.getParameter(activityGridPageController.page, 'mode', 'edit');
            activityGridPageController.expandAll = Page.getParameterAsBoolean(activityGridPageController.page, 'expandAll', false);
            activityGridPageController.hideScheduleWeek = Page.getParameterAsBoolean(activityGridPageController.page, 'hideScheduleWeek', false);
            activityGridPageController.hideDate = Page.getParameterAsBoolean(activityGridPageController.page, 'hideDate', false);
            activityGridPageController.hideCopy = Page.getParameterAsBoolean(activityGridPageController.page, 'hideCopy', false);

            if (activityGridPageController.entityType === 'module') {
                loadPeriods(activityGridPageController.entity.id);
            }

            if (!activityGridPageController.readOnly) {
                WorkflowValidator.setValidity(() => activityGridPageController.valid);
            }
        };

        activityGridPageController.$onDestroy = function () {
            WorkflowValidator.reset();
        };

        function loadPeriods(moduleId) {
            activityGridPageController.loading = true;
            Offering.periods({
                entityType: 'module',
                entityId: moduleId,
                periodId: _.result(activityGridPageController.process, 'period.id')
            }).$promise.then((periods) => {
                activityGridPageController.periods = _.sortBy(periods, ['startDate', 'endDate', 'code']);
                expand();
            }).finally(() => {
                activityGridPageController.loading = false;
            });
        }

        function expand() {
            if (activityGridPageController.expandAll) {
                _.forEach(activityGridPageController.periods, (period) => period.open = true);
            } else {
                const period = _.head(activityGridPageController.periods);
                _.set(period, 'open', true);
            }
        }

        activityGridPageController.setValidity = function(period, valid) {
            period.valid = valid;
            validate();
        };

        function validate() {
            activityGridPageController.valid = _.every(activityGridPageController.periods, { valid: true });
        }
    }
});
