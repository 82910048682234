'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAdmin
 * @description
 * uasModuleAdmin Shows module admin.
 */
angular.module('uasApp').component('assignmentAdmin', {
  bindings: {
    initialValues: '<',
    operations: '<'
  },
  templateUrl: 'es6/staffing/assignment/assignment.admin.html',
  controllerAs: 'assignmentAdminController',
  controller: function ($q, $state, Message, Assignment, CustomField, CustomPage, feedbackObserver, EntityType) {
    const assignmentAdminController = this;

    const FIELDS = ['externalId', 'name', 'organisation', 'role', 'person', 'startDate', 'endDate'];

    const EXTENSIONS = [
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' }
    ];

    assignmentAdminController.$onInit = function () {
      assignmentAdminController.assignment = buildModel(assignmentAdminController.initialValues);

      loadData();
    };

    function loadData() {
      assignmentAdminController.loading = true;

      $q.all([
        CustomPage.query({
          basePath: 'assignment.admin'
        }).$promise,
        CustomField.query({
          rootType: 'assignment',
          entityType: 'assignment'
        }).$promise,
        EntityType.get({
          rootType: 'assignment',
          entityType: 'assignment'
        }).$promise
      ]).then(([pages, fields, customType]) => {
        assignmentAdminController.pages = pages;
        assignmentAdminController.fields = CustomField.show(CustomField.extend(fields, EXTENSIONS), FIELDS);
        assignmentAdminController.customType = customType;

        assignmentAdminController.onEvaluate();
      }).finally(() => {
        assignmentAdminController.loading = false;
      });
    }

    assignmentAdminController.onEvaluate = function () {
      const entity = {
        type: 'assignment',
        id: _.result(assignmentAdminController.assignment, 'id')
      };
      assignmentAdminController.evaluation = CustomField.getEvaluation(
        entity,
        assignmentAdminController.assignment,
        assignmentAdminController.fields);
    };

    function buildModel(assignment) {
      // Copy the assignment, because otherwise changing organisation or role, without saving this page, would empty the fields on the general tab.
      const copy = angular.copy(assignment);

      copy.organisation = _.get(copy.organisation, 'id');
      copy.role = _.get(copy.role, 'id');
      copy.person = _.get(copy.person, 'id');

      return copy;
    }

    assignmentAdminController.save = function (content, comment) {
      return Assignment.update({
        content: buildSaveModel(content),
        comment
      }).$promise.then((result) => {
        feedbackObserver.dataChanged();
        Message.onSaved();
        $state.reload(); // soft-reload, so the other tabs get the changed data.
        return result;
      });
    };

    function buildSaveModel(assignment) {
      const copy = angular.copy(assignment);

      copy.organisationId = copy.organisation;
      delete copy.organisation;

      copy.roleId = copy.role;
      delete copy.role;

      copy.personId = copy.person;
      delete copy.person;

      return copy;
    }

    assignmentAdminController.delete = function (assignment, comment) {
      Assignment.remove({
        comment: comment,
        id: assignment.id
      }).$promise.then(() => {
        feedbackObserver.dataChanged();
        Message.onRemoved();
      });
    };

    assignmentAdminController.deleteFull = function (assignment) {
      Assignment.deleteFull({
        id: assignment.id
      }).$promise.then(() => {
        Message.onRemoved();

        assignmentAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});
