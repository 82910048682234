'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityTypeSelector
 * @description
 */
angular.module('uasApp').component('activityTypeSelector', {
    bindings: {
        categoryId: '<?',
        showCategory: '<?',
        isDisabled: '<?',
        module: '<',
        typeId: '<',
        onChange: '&'
    },
    templateUrl: 'es6/schedule/activity/form/activity.type.selector.html',
    controllerAs: 'activityTypeSelectorController',
    controller: function ($q, Faculty, Category, MethodType, Assessment, Method, MethodParameter) {
        const activityTypeSelectorController = this;

        activityTypeSelectorController.$onInit = function () {
            loadData();
        };

        function loadData() {
            const facultyId = _.result(activityTypeSelectorController.module, 'facultyId');
            const academicYearId = _.result(activityTypeSelectorController.module, 'academicYearId');

            activityTypeSelectorController.loading = true;
            $q.all([
                Category.query({
                    rootType: 'METHOD'
                }).$promise,
                MethodType.query({
                    academicYearId
                }).$promise,
                Method.query({
                    moduleId: activityTypeSelectorController.module.id,
                    active: true
                }).$promise,
                Assessment.query({
                    moduleId: activityTypeSelectorController.module.id,
                    active: true
                }).$promise,
                Faculty.find(facultyId).$promise,
                MethodParameter.query({
                    facultyId,
                    academicYearId
                }).$promise
            ]).then(([categories, types, methods, assessments, faculty, parameters]) => {
                activityTypeSelectorController.faculty = faculty;
                activityTypeSelectorController.categories = _.filter(categories, (category) => category.scheduleable || category.exam);
                activityTypeSelectorController.types = _(types).map((type) => {
                    const parameter = _.find(parameters, { type: { id: type.id } });
                    return _.extend(type, {
                        methods: _.filter(methods, { typeId: type.id }),
                        assessments: _.filter(assessments, { typeId: type.id }),
                        ignore: _.result(parameter, 'ignore', false)
                    });
                }).orderBy(types, ['sequence', 'code', 'externalId', 'id']).value();

                loadType();
            }).finally(() => {
                activityTypeSelectorController.loading = false;
            });
        }

        function loadType() {
            const type = _.find(activityTypeSelectorController.types, { id: activityTypeSelectorController.typeId });
            if (angular.isDefined(type)) {
                activityTypeSelectorController.category = _.find(activityTypeSelectorController.categories, {
                    id: type.categoryId
                });

                activityTypeSelectorController.onChange({
                    type,
                    category: activityTypeSelectorController.category
                });
            } else if (angular.isDefined(activityTypeSelectorController.categoryId)) {
                activityTypeSelectorController.category = _.find(activityTypeSelectorController.categories, {
                    id: activityTypeSelectorController.categoryId
                });
                activityTypeSelectorController.onCategory();
            }

            setFilteredTypes();
        }

        function setFilteredTypes() {
            activityTypeSelectorController.filteredTypes = [];

            if (angular.isDefined(activityTypeSelectorController.category)) {
                const types = _.filter(activityTypeSelectorController.types, {
                    categoryId: activityTypeSelectorController.category.id
                });

                const showAll = _.result(activityTypeSelectorController.faculty, 'showAllTypes', false);
                if (activityTypeSelectorController.category.exam) {
                    let usableTypes = _.filter(types, (type) => type.assessments.length > 0);
                    if (_.isEmpty(usableTypes)) {
                        usableTypes = types;
                    }
                    activityTypeSelectorController.filteredTypes = usableTypes;
                } else if (showAll) {
                    activityTypeSelectorController.filteredTypes = _.filter(types, { ignore: false });
                } else {
                    activityTypeSelectorController.filteredTypes = _.filter(types, (type) => type.methods.length > 0);
                }
            }
        }

        activityTypeSelectorController.onCategory = function () {
            delete activityTypeSelectorController.typeId;
            activityTypeSelectorController.onType();
            setFilteredTypes();
        };

        activityTypeSelectorController.onType = function (id) {
            const type = _.find(activityTypeSelectorController.types, { id });
            activityTypeSelectorController.onChange({
                type,
                category: activityTypeSelectorController.category
            });
        };
    }
});
