'use strict';

angular.module('uasApp').component('academicYearCreate', {
    templateUrl: 'es6/admin/year/year.create.html',
    controllerAs: 'academicYearCreateController',
    controller: function (AcademicYear, Message, State) {
        const academicYearCreateController = this;

        academicYearCreateController.$onInit = function() {
            academicYearCreateController.year = {};
        };

        academicYearCreateController.create = function(year) {
            academicYearCreateController.loading = true;
            AcademicYear.store(year).$promise.then((created) => {
                Message.onSaved();
                State.reload('academicYear.general', { id: created.id });
            }).finally(() => {
                academicYearCreateController.loading = false;
            });
        };
    }
});