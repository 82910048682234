'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:JobExecution
 * @description
 * The JobExecution service.
 */
angular.module('uasApp')
    .factory('JobExecution', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/job-execution/:id', {}, {
            parameters: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/job-execution/parameters',
                isArray: true
            }
        });
    });
