'use strict';

angular.module('uasApp').component('subjectCategorySelect', {
  templateUrl: 'es6/subjects/category/subject.category.select.html',
  bindings: {
    entity: '<?',
    idModel: '<?', // Indicates if the ng-model is the category id
    categoryIds: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'subjectCategorySelectController',
  controller: function ($q, SubjectCategory) {
    const subjectCategorySelectController = this;

    subjectCategorySelectController.$onInit = function () {
      setRender();
    };

    function setRender() {
      subjectCategorySelectController.ngModelCtrl.$render = function () {
        loadCategories().then(() => {
          delete subjectCategorySelectController.category;

          const categoryId = getCategoryId(subjectCategorySelectController.ngModelCtrl.$modelValue);
          if (categoryId) {
            subjectCategorySelectController.category = _.find(subjectCategorySelectController.categories, { id: categoryId });
          }
          if (!subjectCategorySelectController.category && subjectCategorySelectController.categories.length === 1) {
            subjectCategorySelectController.category = subjectCategorySelectController.categories[0];
          }
          if (categoryId !== _.get(subjectCategorySelectController.category, 'id') || modelChanged()) {
            subjectCategorySelectController.onSelect();
          }
        });
      };
    }

    function getCategoryId(model) {
      if (hasValue(model)) {
        if (_.isObject(model)) {
          return model.id;
        }
        return _.parseInt(model);
      }
    }

    function hasValue(value) {
      return angular.isDefined(value) && value !== '' && value !== null;
    }

    function modelChanged() {
      const modelValue = subjectCategorySelectController.ngModelCtrl.$modelValue;

      // true if the expected model (id or object) differs from the actual model type
      return (!subjectCategorySelectController.idModel && !_.isObject(modelValue)) || (subjectCategorySelectController.idModel && _.isObject(modelValue));
    }

    subjectCategorySelectController.onSelect = function () {
      if (subjectCategorySelectController.idModel === true) {
        subjectCategorySelectController.ngModelCtrl.$setViewValue(_.get(subjectCategorySelectController.category, 'id'));
      } else {
        subjectCategorySelectController.ngModelCtrl.$setViewValue(angular.copy(subjectCategorySelectController.category));
      }
    };

    function loadCategories() {
      if (!subjectCategorySelectController.categories) {
        return SubjectCategory.query().$promise.then((categories) => {
          subjectCategorySelectController.categories = _.filter(categories, (category) =>
            angular.isUndefined(subjectCategorySelectController.categoryIds) || _.includes(subjectCategorySelectController.categoryIds, category.id));
        });
      } else {
        return $q.resolve(subjectCategorySelectController.categories);
      }
    }
  }
});
