'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ChangeModal
 * @description
 * The ChangeModal service.
 */
angular.module('uasApp').factory('ChangeModal', function ($uibModal, EntityTypes) {
  const instance = {};

  instance.openRevisions = function (changeId, onChange) {
    $uibModal.open({
      controllerAs: 'changeModalController',
      controller: function ($uibModalInstance) {
        this.changeId = changeId;

        EntityTypes.load().then((entityTypes) => {
          this.entityTypes = entityTypes;
        });

        this.onChange = function (change) {
          if (angular.isDefined(onChange)) {
            onChange(change);
          }
          $uibModalInstance.dismiss();
        };

        this.close = function () {
          $uibModalInstance.dismiss();
        };
      },
      template: `
        <change-revisions 
          change-id="changeModalController.changeId"
          entity-types="changeModalController.entityTypes"
          on-change="changeModalController.onChange(change)"
          on-cancel="changeModalController.close()">
        </change-revisions>`
    });
  };

  instance.openChanges = function (entity, root) {
    $uibModal.open({
      resolve: {
        changes: function (Feedback, Language) {
          return Feedback.get({
            entityType: entity.type,
            entityId: entity.id,
            language: Language.get(),
            status: 'OPEN',
            root
          }).$promise.then((feedback) => feedback.changes);
        }
      },
      controllerAs: 'feedbackModalController',
      templateUrl: 'es6/changes/feedback.modal.html',
      controller: function (changes) {
        this.changes = changes;
        this.entity = entity;
      }
    });
  };

  return instance;
});
