'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:elementList
 * @description Displays elements of an element type.
 */
angular.module('uasApp').component('elementList', {
  bindings: {
    type: '<'
  },
  templateUrl: 'es6/element/element.list.html',
  controllerAs: 'elementListController',
  controller: function ($uibModal, Element, Message, Pageable, translateFilter) {
    const elementListController = this;

    elementListController.$onInit = function () {
      elementListController.pageable = Pageable.of({
        order: 'externalId'
      });

      elementListController.retrieveData();
    };

    elementListController.$onChanges = function () {
      elementListController.search();
    };

    elementListController.retrieveData = function () {
      elementListController.loading = true;

      Element.getAll().then((elements) => {
        elementListController.elements = elements;
        elementListController.search();
      }).finally(() => {
        elementListController.loading = false;
      });
    };

    elementListController.search = function () {
      const { type, keyword } = elementListController;

      elementListController.filtered = _.filter(elementListController.elements, (element) => {
        return element.type === type && (
          contains(element.externalId, keyword) ||
          contains(element.localName, keyword) ||
          contains(element.code, keyword) ||
          contains(element.englishName, keyword)
        );
      });
    };

    function contains(value, keyword) {
      return _.includes(_.toLower(value), _.toLower(keyword));
    }

    elementListController.create = function () {
      elementListController.open({
        type: elementListController.type,
        manual: true
      });
    };

    /**
     * Opens the modal for editing or adding a new element.
     * @param {Object} element Element you want to edit or create.
     */
    elementListController.open = function (element) {
      $uibModal.open({
        templateUrl: 'es6/element/element.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.element = angular.copy(element);
            modalController.type = elementListController.type;
            modalController.propagateFutureYears = _.endsWith(elementListController.type, '_DESCRIPTION');
          };

          modalController.save = function () {
            Element.store(modalController.element).$promise.then(() => {
              Message.onSaved();
              modalController.close();
              elementListController.retrieveData();
            });
          };

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };
        }
      });
    };

    /**
     * Deletes the element
     * @param {Object} element The element to be removed.
     */
    elementListController.remove = function (element) {
      Element.remove({
        id: element.id
      }).$promise.then(() => {
        Message.addSuccess(translateFilter('Static.Message.ElementRemoved'));
        elementListController.retrieveData();
      });
    };
  }
});
