'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasPlanner
 * @description
 * # uasPlanner
 * Planner component.
 */
angular.module('uasApp')
  .component('uasPlanner', {
    bindings: {
      entity: '<',
      operations: '<',
      showPeriod: '<?'
    },
    templateUrl: 'es6/planboard/planner/planner.html',
    controllerAs: 'plannerController',
    controller: function(ModuleGroup, Credits, Qualification, Link, LinkType, Nodes, $q, CalendarPeriod) {
        const plannerController = this;
        const LIMIT = 10;

        const manager = Nodes.manager({
            build,
            load
        });

        function build(node, parent) {
            const result = Nodes.build(node, parent);
            result.choiceRequired = result.required || isChoiceRequired(parent);

            return _.extend(result, {
                selected: node.choiceRequired || _.some(plannerController.selectedNodes, node.entity),
                open: node.choiceRequired || _.some(plannerController.openNodes, node.entity),
                limit: LIMIT
            });
        }

        function isChoiceRequired(node) {
            return _.result(node, 'choiceType.required', false);
        }

        function load(node) {
            return ModuleGroup.modules({
                id: node.id,
                active: true,
                referenceDate: CalendarPeriod.getReferenceDate(plannerController.period),
                propertyName: _.result(plannerController.fieldFilter, 'name'),
                propertyValues: _.result(plannerController.fieldFilter, 'values'),
                propertyType: _.result(plannerController.fieldFilter, 'type')
            }).$promise.then((modules) => {
                const choiceRequired = isChoiceRequired(node);
                node.modules = _.map(modules, (module) => {
                    module.moduleId = _.result(module, 'module.id');
                    module.moduleGroupId = node.id;
                    module.selected = true;
                    module.visible = true;
                    module.choiceRequired = module.required || choiceRequired;
                    return module;
                });
            });
        }

        plannerController.$onInit = function() {
            plannerController.limit = LIMIT;
            plannerController.groups = [];

            plannerController.setFiltered(false);
            plannerController.setSimple(true);
            plannerController.academicYearId = sessionStorage.academicYear;

            loadData();
        };

        function loadData() {
            plannerController.openNodes = Nodes.getOpen(plannerController.groups);
            plannerController.selectedNodes = Nodes.getNodes(plannerController.groups, { selected: true }).map((node) => node.entity);

            plannerController.loading = true;
            $q.all([
                manager.init({
                    entity: plannerController.entity
                }),
                Credits.get({
                    entityType: _.result(plannerController.entity, 'self.type'),
                    entityId: plannerController.entity.id
                }).$promise,
                LinkType.query().$promise,
                Qualification.planned({
                    entityType: 'study',
                    entityId: plannerController.entity.id
                }).$promise
            ]).then(([groups, credits, linkTypes, qualifications]) => {
                plannerController.groups = groups;
                plannerController.credits = _.result(credits, 'optimum', 0);
                plannerController.linkTypes = linkTypes;
                plannerController.qualifications = _.orderBy(qualifications, ['sequence', 'qualification.code'], ['desc', 'asc']);

                update();
            }).finally(() => {
                plannerController.loading = false;
            });
        }
        
        plannerController.onExpand = function (expanded) {
            return manager.expand(plannerController.groups, expanded);
        };

        plannerController.setSimple = function(simple) {
            plannerController.simple = simple;
        };

        plannerController.setFiltered = function(filtered) {
            const filter = { visible: true };
            if (filtered === true) {
                filter.selected = true;
            }

            plannerController.filter = filter;
            plannerController.filtered = filtered;
        };

        plannerController.showUpcoming = function(upcoming) {
            plannerController.upcoming = upcoming;
        };

        plannerController.filterUpcoming = function(module) {
            if (plannerController.upcoming) {
                return module.progress.remaining > 0;
            }
            return true;
        };

        plannerController.onPeriod = function () {
            loadData();
        };

        plannerController.onField = function () {
            const oldValue = _.result(plannerController.oldFieldFilter, 'values');
            const newValue = _.result(plannerController.fieldFilter, 'values');

            plannerController.oldFieldFilter = plannerController.fieldFilter;

            if (!_.isEqual(oldValue, newValue)) {
                loadData();
            }
        };

        plannerController.onSelect = function(group) {
            group.selected = group.selected !== true;
            group.open = group.selected;
            manager.prepare(group).finally(update);
        };

        plannerController.onMore = function(group) {
            group.limit += LIMIT;
        };

        plannerController.onLess = function(group) {
            group.limit -= LIMIT;
        };

        plannerController.onGroup = function(group) {
            manager.toggle(group).finally(update);
        };

        plannerController.onModule = function(module) {
            module.selected = module.selected !== true;
            update();
        };

        function update() {            
            const selected = [];
            _.forEach(plannerController.groups, (group) => updateGroup(group, selected));
            
            calculate();
        }

        function updateGroup(group, selected, parent) {
            const visible = _.result(parent, 'visible', true);
            group.visible = visible && isVisible(group, selected);

            if (group.visible === true && group.selected === true) {
                selected.push({
                    type: 'module-group', 
                    id: group.id 
                });
            }

            _.forEach(group.children, (child) => updateGroup(child, selected, group));

            updateCredits(group);
            validate(group);
        }

        function updateCredits(group) {
            const modules = getModuleCredits(group.modules);
            const children = getTotalCredits(group.children);

            if (isEmpty(group)) {
                group.total = Credits.getMinimum(group.credits);
            } else {
                group.total = modules + children;
            }
        }

        function isVisible(group, selected) {
            return _.every(group.requires, (require) => _.some(selected, require));
        }

        function calculate() {
            plannerController.selected = getTotalCredits(plannerController.groups);
            plannerController.completed = plannerController.credits > 0 && plannerController.selected >= plannerController.credits;
            
            setQualification();
        }

        function getTotalCredits(groups) {
            return _(groups).filter({ 
                selected: true,
                visible: true,
                completed: true
            }).filter((group) => {
                return group.unqualified !== true;
            }).map((group) => {
                const total = group.total || 0;
                const maximum = _.result(group, 'credits.maximum', 0);
                if (maximum) {
                    return Math.min(total, maximum);
                } else {
                    return total;
                }
            }).sum();
        }

        function getModuleCredits(modules) {
            return _(modules).filter({ 
                selected: true 
            }).map((module) => module.credits || 0).sum();
        }

        function validate(group) {
            group.choiceValid = hasValidChoice(group);
            group.creditsValid = hasValidCredits(group);
            group.requiredValid = requiredSelected(group.modules) && requiredSelected(group.children);
            group.completed = group.choiceValid && group.creditsValid && group.requiredValid;
        }

        function hasValidChoice(group) {
            const type = _.result(group, 'choiceType.code');
            if (!group.loaded || !type) {
                return true;
            }

            const value = _.result(group, 'choiceValue', 1);
            const count = getSelected(group.modules) || getSelected(group.children);
            if (type === 'EQ') {
                return count === value;
            } else if (type === 'MIN') {
                return count >= value;
            } else if (type === 'MAX') {
                return count <= value;
            } else {
                return true;
            }
        }

        function getSelected(nodes) {
            return _.filter(nodes, { selected: true }).length;
        }

        function hasValidCredits(group) {
            if (isEmpty(group)) {
                return true;
            }

            let completed = false;
            if (group.selected && group.visible) {
                const choiceRequired = isChoiceRequired(group);
                completed = Credits.isValid(group.total, group.credits, choiceRequired);
            }
            return completed;
        }

        function isEmpty(group) {
            return _.result(group, 'groupType.empty', false);
        }

        function requiredSelected(nodes) {
            return _(nodes).filter('choiceRequired').every('selected');
        }

        function setQualification() {
            const qualification = getHighestQualification();
            plannerController.qualification = qualification;

            if (angular.isDefined(qualification) && !_.isEqual(plannerController.qualification, qualification)) {
                Link.query({
                    entityType: 'qualification',
                    entityId: qualification.id
                }).$promise.then((links) => {
                    plannerController.links = _.map(links, (link) => {
                        link.type = _.find(plannerController.linkTypes, { id: link.typeId });
                        return link;
                    });
                });
            } else {
                delete plannerController.links;
            }
        }

        function getHighestQualification() {
            const nodes = Nodes.getNodes(plannerController.groups, { selected: true });
            const selected = _.map(nodes, (node) => ({ id: node.id, type: 'module-group' }));

            return _(plannerController.qualifications)
                .filter((qualification) => {
                    if (angular.isDefined(qualification.requirements)) {
                        return _.every(qualification.requirements, (requirement) => 
                            _.some(selected, requirement)
                        );
                    }
                    return true;
                })
                .map('qualification')
                .find();
        }
    }
});
