'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Asset
 * @description
 * The Asset service.
 */
angular.module('uasApp').factory('Asset', function (UasResource) {
  return UasResource('/assets', {
    report: {
      url: '/assets/report',
      method: 'GET'
    }
  });
});
