'use strict';

angular.module('uasApp')
  .component('uasNewsHeader', {
    templateUrl: 'es6/app/news/news.header.html',
    controllerAs: 'newsHeaderController',
    controller: function(News, AuthService) {
      const newsHeaderController = this;

      newsHeaderController.$onInit = function() {
        loadData();
        AuthService.addAuthenticationListener(loadData);
      };

      function loadData() {
        News.active({
            sort: 'creationDate,desc'
        }).$promise.then((news) => {
            newsHeaderController.news = _.filter(news, { fixed: true });
        });
      }
    }
});
