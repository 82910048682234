'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:modalForm
 * @description Creates a modal form
 */
angular.module('uasApp').component('modalForm', {
    bindings: {
        modalTitle: '@',
        entity: '<',
        onClose: '&',
        onSave: '&'
    },
    templateUrl: 'es6/app/modal/modal.form.html',
    transclude: true,
    controllerAs: 'modalFormController',
    controller: function() {
        const modalFormController = this;

        modalFormController.close = function() {
            modalFormController.onClose();
        };

        modalFormController.save = function() {
            modalFormController.onSave({
                entity: modalFormController.entity
            });
        };
    }
});
