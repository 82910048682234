'use strict';

angular.module('uasApp').component('confirmInput', {
    bindings: {
      confirmText: '<'
    },
    require: {
      ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/app/forms/buttons/confirm.input.html',
    controllerAs: 'confirmInputController',
    controller: function() {
      const confirmInputController = this;

      confirmInputController.$onInit = function () {
        confirmInputController.ngModelCtrl.$render = function () {
          confirmInputController.value = confirmInputController.ngModelCtrl.$modelValue;
        };
  
        confirmInputController.ngModelCtrl.$validators.similar = function (modelValue) {
          return !confirmInputController.confirmText || confirmInputController.confirmText === modelValue;
        };
      };

      confirmInputController.onChange = function() {
        confirmInputController.ngModelCtrl.$setViewValue(confirmInputController.value);
      };
    }
  });