'use strict';

angular.module('uasApp').component('workflowPageTemplate', {
  bindings: {
    page: '<',
    isReadOnly: '<?',
    operations: '<?',
    requiredOperations: '<?'
  },
  transclude: true,
  templateUrl: 'es6/workflow/workflow.page.template.html',
  controllerAs: 'workflowPageController'
});
