'use strict';

angular.module('uasApp').component('mineComments', {
    templateUrl: 'es6/mine/mine.comments.html',
    controllerAs: 'mineCommentsController',
    controller: function (Pageable, Comment) {
        const mineCommentsController = this;

        mineCommentsController.$onInit = function() {
            mineCommentsController.pageable = Pageable.of({
                order: 'creationTime',
                reverse: true
            });

            loadData();
        };

        function loadData() {
            const parameters = mineCommentsController.pageable.build({
                academicYearId: sessionStorage.academicYear
            });

            Comment.mine(parameters).$promise.then((comments) => {
                mineCommentsController.comments = comments;
            });
        }

        mineCommentsController.onChange = function() {
            loadData();
        };
    }
});
