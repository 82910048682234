'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processNavigator
 * @description
 * processNavigator Navigate the content of a process
 */
angular.module('uasApp')
  .component('processNavigator', {
    bindings: {
      process: '<'
    },
    templateUrl: 'es6/process/process.navigator.html',
    controllerAs: 'processNavigatorController',
    controller: function (ProcessType) {
      const processNavigatorController = this;

      processNavigatorController.$onInit = function() {
        processNavigatorController.label = ProcessType.getLabel(processNavigatorController.process);
      };
    }
});
