'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:onCtrlCmdEnter
 * @description Executes a function on Ctrl+Enter (Windows/Linux) or Cmd+Enter (macOS)
 * if the directive element is not an overlay, and the parent form is valid and not pristine.
 *
 * @example
 * <form name="myForm">
 *   <textarea name="myTextarea" on-ctrl-cmd-enter="myFunction()" ...></textarea>
 * </form>
 */
angular.module('uasApp')
  .directive('onCtrlCmdEnter', function() {
    return {
      restrict: 'A',
      require: '^form',
      link: function(scope, elem, attrs, formCtrl) {
        elem.bind('keydown', function(event) {
          const code = event.keyCode || event.which;

          if (code === 13 && (event.ctrlKey || event.metaKey)) {
            if (elem.attr('overlay') || formCtrl.$invalid || formCtrl.$pristine) {
              return;
            }

            event.preventDefault();
            scope.$apply(attrs.onCtrlCmdEnter);
          }
        });
      }
    };
  });
