'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('rule', {
        url: '/rule/{id:[0-9]{1,8}}',
        resolve: {
            rule: function(Rule, $stateParams) {
                const instance = this;
                return Rule.get({
                    id: $stateParams.id
                }).$promise.then((rule) => {
                    instance.self.data = rule;
                    return rule;
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('rule', $stateParams.id);
            }
        },
        controllerAs: 'ruleController',
        controller: function(rule, operations) {
            this.rule = rule;
            this.operations = operations;
            this.tabs = [{
                route: 'rule.general'
            }, {
                route: 'rule.links'
            }, {
                route: 'rule.admin'
            }];
        },
        template: `
            <entity-tabs 
                view="rule" 
                entity="ruleController.rule"
                tabs="ruleController.tabs" 
                operations="ruleController.operations"
            />
        `,
        abstract: true,
        entityType: 'rule',
        redirectTo: 'rule.general',
        breadcrumb: {}
    });

    $stateProvider.state('rule.general', {
        url: '/general',
        views: {
            'rule': {
                controllerAs: 'ruleGeneralStateController',
                controller: function(rule, operations) {
                    this.rule = rule;
                    this.operations = operations;
                },
                template: `
                    <rule-general
                        rule="ruleGeneralStateController.rule" 
                        operations="ruleGeneralStateController.operations">
                    </rule-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Rule.General',
            label: 'Static.Help.Rule.General'
        }
    });

    $stateProvider.state('rule.links', {
        url: '/general',
        views: {
            'rule': {
                controllerAs: 'ruleTreeStateController',
                controller: function(rule, operations) {
                    this.rule = rule;
                    this.operations = operations;
                },
                template: `
                    <link-list
                        entity="ruleTreeStateController.rule" 
                        operations="ruleTreeStateController.operations">
                    </link-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_LINKS'],
            title: 'Static.Tab.Rule.Links',
            label: 'Static.Help.Rule.Links'
        }
    });

    $stateProvider.state('rule.admin', {
        url: '/admin',
        views: {
            'rule': {
                controllerAs: 'ruleAdminStateController',
                controller: function(rule, operations) {
                    this.rule = rule;
                    this.operations = operations;
                },
                template: `
                    <rule-admin
                        rule="ruleAdminStateController.rule" 
                        operations="ruleAdminStateController.operations">
                    </rule-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Rule.Admin',
            label: 'Static.Help.Rule.Admin'
        }
    });

    $stateProvider.state('rule.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'rule': {
                controllerAs: 'rulePageStateController',
                controller: function(rule, operations, $stateParams) {
                    this.rule = rule;
                    this.operations = operations;
                    this.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="rulePageStateController.pageId" 
                        entity="rulePageStateController.rule" 
                        operations="rulePageStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});
