'use strict';

angular.module('uasApp').component('workflowWidgets', {
  templateUrl: 'es6/dashboard/workflow.widgets.html',
  controllerAs: 'workflowWidgetsController',
  controller: function (Language, Workflow) {
    const workflowWidgetsController = this;

    workflowWidgetsController.$onInit = function () {
      loadData();
      workflowWidgetsController.onLanguage = Language.onChange(loadData);
    };

    workflowWidgetsController.$onDestroy = function () {
      workflowWidgetsController.onLanguage();
    };

    function loadData() {
      workflowWidgetsController.loading = true;
      Workflow.widgets({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((workflows) => {
        workflowWidgetsController.workflows = workflows;
      }).finally(() => {
        workflowWidgetsController.loading = false;
      });
    }
  }
});
