'use strict';

angular.module('uasApp').factory('AvailabilityModal', function($uibModal) {
    
    function open(args) {
        const assignmentId = _.result(args, 'assignmentId');

        $uibModal.open({
            templateUrl: 'es6/staffing/availability/availability.modal.html',
            size: 'xl',
            resolve: {
                assignment: function(Assignment) {
                    return Assignment.find(assignmentId).$promise;
                },
                operations: function (AuthService) {
                    return AuthService.operations('assignment', assignmentId);
                }
            },
            controllerAs: 'availabilityModalController',
            controller: function($uibModalInstance, assignment, operations, SecurityService) {
                const availabilityModalController = this;
                availabilityModalController.assignment = assignment;
                availabilityModalController.operations = operations;
                availabilityModalController.isReadOnly = args.isReadOnly;
                availabilityModalController.workflowMode = args.workflowMode;

                const operationsToEdit = args.workflowMode === true ? ['EDIT_TASKS_WORKFLOW'] : ['EDIT_TASKS'];
                availabilityModalController.editable = SecurityService.isAllowed(operationsToEdit, operations);

                availabilityModalController.onChange = function() {
                    if (_.isFunction(args.onChange)) {
                        args.onChange();
                    }
                };

                availabilityModalController.close = function() {
                    $uibModalInstance.dismiss();
                };

                availabilityModalController.setMode = function(mode) {
                    availabilityModalController.mode = mode;
                };
            }
        });
    }

    return { open };

});
