'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:btnState
 * @description
 * # btnState
 * Defines the state of a button
 */
angular.module('uasApp')
    .directive('stateButton', function() {
        return {
            restrict: 'E',
            scope: {
                states: '=',
                current: '=',
                changeable: '=',
                onChange: '&'
            },
            templateUrl: 'views/directives/state-button.html',
            controller: function($scope) {
                $scope.index = 0;
                if ($scope.current) {
                    $scope.index = _.findIndex($scope.states, {
                        name: $scope.current
                    });
                }

                $scope.state = $scope.states[$scope.index];

                $scope.next = function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if ($scope.changeable !== false) {
                        $scope.index++;
                        if ($scope.index >= $scope.states.length) {
                            $scope.index = 0;
                        }
                        $scope.state = $scope.states[$scope.index];
                        $scope.current = $scope.state.name;

                        $scope.onChange({ changed: $scope.current });
                    }
                };
            }
        };
    });
