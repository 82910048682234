'use strict';

angular.module('uasApp').component('objectiveEdit', {
  templateUrl: 'es6/objective/objective.edit.html',
  bindings: {
    objective: '<',
    category: '<?',
    entity: '<',
    fields: '<',
    operations: '<',
    operationsToEdit: '<',
    showAssessmentCode: '<',
    showSubjectCode: '<',
    selectAllSubjects: '<?',
    hideAssessment: '<?',
    hideSubject: '<?',
    onChange: '&',
    onCancel: '&'
  },
  controllerAs: 'objectiveEditController',
  controller: function ($uibModal, CustomField, Objective) {
    const objectiveEditController = this;

    objectiveEditController.$onInit = function () {
      objectiveEditController.fields_ = _(objectiveEditController.fields)
        .filter((field) => field.visible)
        .sortBy(['sequence', 'name', 'id'])
        .value();

      if (!objectiveEditController.selectAllSubjects) {
        objectiveEditController.subjectTypeIds = _(objectiveEditController.objective.subjects)
          .map('type.id')
          .filter(angular.isDefined)
          .uniq()
          .value();
      }
      objectiveEditController.assessmentIds = _(objectiveEditController.objective.appraisals)
        .map('assessment.id')
        .filter(angular.isDefined)
        .uniq()
        .value();

      setEvaluation();
    };

    objectiveEditController.onValue = function () {
      setEvaluation();
    };

    function setEvaluation() {
      const entity = {
        type: 'objective',
        id: _.get(objectiveEditController.objective, 'id')
      };

      objectiveEditController.evaluation = CustomField.getEvaluation(entity, objectiveEditController.objective, objectiveEditController.fields);
    }

    objectiveEditController.onSubmit = function (objective, comment) {
      const content = map(objective);

      Objective.store({
        content,
        comment
      }).$promise.then(() => {
        objectiveEditController.onChange();
      });
    };

    function map(objective) {
      const model = angular.copy(objective);
      model.subjects = mapSubjects();
      model.appraisals = _.map(objectiveEditController.assessmentIds, (assessmentId) => {
        return { assessmentId, academicYearId: sessionStorage.academicYear };
      });
      return model;
    }

    function mapSubjects() {
      if (objectiveEditController.selectAllSubjects) {
        _.forEach(objectiveEditController.objective.subjects, (subject) => {
          subject.typeId = _.get(subject.type, 'id');
          subject.valueId = _.get(subject.value, 'id');

          delete subject.type;
          delete subject.value;
        });
        return objectiveEditController.objective.subjects;
      }

      return _.map(objectiveEditController.subjectTypeIds, (typeId) => ({ typeId }));
    }

    objectiveEditController.cancel = function () {
      objectiveEditController.onCancel();
    };

    objectiveEditController.deleteSubject = function (subject) {
      _.remove(objectiveEditController.objective.subjects, subject);
      objectiveEditController.objectiveForm.$setDirty();
    };

    objectiveEditController.createSubject = function () {
      open({});
    };

    objectiveEditController.editSubject = function (subject) {
      const model = angular.copy(subject);
      open(model);
    };

    function open(subject) {
      $uibModal.open({
        size: 'lg',
        templateUrl: 'es6/objective/objective.edit.subject.modal.html',
        controllerAs: 'subjectModalController',
        controller: function ($q, $uibModalInstance, Scale, SubjectCategory) {
          const subjectModalController = this;

          subjectModalController.$onInit = function () {
            subjectModalController.modal = $uibModalInstance;
            subjectModalController.subject = subject;
            subjectModalController.operations = objectiveEditController.operations;
            subjectModalController.operationsToEdit = objectiveEditController.operationsToEdit;
          };

          subjectModalController.save = function () {
            objectiveEditController.objectiveForm.$setDirty();

            getScaleValue().then((value) => {
              subjectModalController.subject.value = value;
            });

            if (subjectModalController.subject.type) {
              _.remove(objectiveEditController.objective.subjects, { type: { id: subjectModalController.subject.type.id } });
            }
            objectiveEditController.objective.subjects.push(subjectModalController.subject);

            subjectModalController.close();
          };

          function getScaleValue() {
            return getScale().then((scale) => _.find(scale.values, { id: subjectModalController.subject.valueId }));
          }

          function getScale() {
            if (angular.isDefined(subjectModalController.subject.valueId)) {
              const categoryId = _.get(subjectModalController.subject, 'type.category.id', subjectModalController.subject.type.category);

              return SubjectCategory.find(categoryId).$promise.then((category) => {
                subjectModalController.subject.type.category = category;
                return Scale.get({ id: category.scale.id }).$promise;
              });
            }
            return $q.resolve({});
          }

          subjectModalController.close = function () {
            subjectModalController.modal.dismiss();
          };
        }
      });
    }
  }
});
