'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionTypeParameter
 * @description Select the description types that should be visible on a page
 */
angular.module('uasApp').component('descriptionTypeParameter', {
  bindings: {
    rootType: '@?',
    parameter: '<'
  },
  templateUrl: 'es6/i18n/description.type.parameter.html',
  controllerAs: 'descriptionTypeParameterController',
  controller: function ($q, Description) {
    const descriptionTypeParameterController = this;

    descriptionTypeParameterController.getTypes = function () {
      if (!_.isEmpty(descriptionTypeParameterController.rootType)) {
        return Description.getTypes(descriptionTypeParameterController.rootType);
      }
      return $q.resolve([]);
    };
  }
});
