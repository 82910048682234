'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityEdit
 * @description
 */
angular.module('uasApp').component('activityEdit', {
    bindings: {
        activity: '<',
        entity: '<',
        module: '<',
        period: '<',
        weeks: '<',
        category: '<',
        showCategory: '<?',
        fields: '<',
        operations: '<',
        disableType: '<?',
        onCancel: '&',
        onSubmit: '&'
    },
    templateUrl: 'es6/schedule/activity/form/activity.edit.html',
    controllerAs: 'activityEditController',
    controller: function(Activity) {
        const activityEditController = this;

        activityEditController.submit = function() {
            const activity = Activity.build(activityEditController.activity, activityEditController.weeks);
            activityEditController.onSubmit({ activity });
        };
        
        activityEditController.cancel = function() {
            activityEditController.onCancel();
        };
    }
});