'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCalendarList
 * @description
 * uasCalendarList shows the calendar list of an academic year
 */
angular.module('uasApp')
    .component('uasCalendarList', {
        templateUrl: 'es6/calendar/calendar.list.html',
        controllerAs: 'calendarListController',
        controller: function (Pageable, Calendar, Message) {
            const calendarListController = this;

            calendarListController.$onInit = function() {
                calendarListController.pageable = Pageable.of();
                loadData();
            };

            function loadData() {
                calendarListController.loading = true;
                Calendar.query({
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((calendars) => {
                    calendarListController.calendars = calendars;
                }).finally(() => {
                    calendarListController.loading = false;
                });
            }

            calendarListController.remove = function(calendar) {
                Calendar.remove({ id: calendar.id }).$promise.then(() => {
                    Message.addSuccessLabel('Static.Message.DataRemoved');
                    loadData();
                });
            };
        }

    });