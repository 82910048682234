'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:formPage
 * @description Shows a form page with fields to edit.
 */
angular.module('uasApp').component('formPage', {
    templateUrl: 'es6/page/form.page.html',
    bindings: {
        entity: '<',
        page: '<',
        workflow: '<?',
        excludeIds: '<?',
        operations: '<',
        isReadOnly: '<?',
        onEvaluate: '&?'
    },
    controllerAs: 'formPageController',
    controller: function ($q, Page, AuthService, Message, EntityTextHolder, Entity, feedbackObserver) {
        const formPageController = this;

        formPageController.$onInit = function () {
            formPageController.readOnly = formPageController.isReadOnly || Page.getParameterAsBoolean(formPageController.page, 'readOnly', false);
            formPageController.operationsToEdit_ = getOperations();

            loadData();
        };

        function getOperations() {
            const operationsToEdit = Page.getParameterAsArray(formPageController.page, 'operationsToEdit');
            const defaultOperations = angular.isDefined(formPageController.workflow) ? ['EDIT_ADDITIONAL_WORKFLOW'] : ['EDIT_ADDITIONAL'];
            return AuthService.buildOperationsToEdit(operationsToEdit, defaultOperations);
        }

        function loadData() {
            formPageController.loading = true;

            $q.all([
                Page.items({
                    id: formPageController.page.id,
                    entityId: formPageController.entity.self.id,
                    entityType: formPageController.entity.self.type
                }).$promise,
                getRoot()
            ]).then(([items, root]) => {
                formPageController.items = getItems(items);
                formPageController.root = root;
            }).finally(() => {
                formPageController.loading = false;
            });
        }

        function getRoot() {
            if (formPageController.entity.root) {
                return Entity.find(formPageController.entity.root).$promise;
            }
            return $q.resolve();
        }

        function getItems(items) {
            return _.transform(formPageController.page.items, (result, pageItem) => {
                if (angular.isUndefined(pageItem.field)) {
                    result.push({ type: pageItem.type });
                } else {
                    const item = _.find(items, (it) => it.field.id === _.get(pageItem.field, 'id'));
                    if (item) {
                        result.push(item);
                    }
                }
            }, []);
        }

        formPageController.setEvaluation = function (evaluation) {
            if (_.isFunction(formPageController.onEvaluate)) {
                formPageController.onEvaluate({ evaluation });
            }
        };

        formPageController.save = function (comment) {
            const form = {
                entityType: formPageController.entity.self.type,
                entityId: formPageController.entity.id,
                academicYearId: sessionStorage.academicYear,
                pageId: formPageController.page.id,
                items: Page.getItems({
                    items: formPageController.items,
                    entity: formPageController.entity,
                    root: formPageController.root,
                    operations: formPageController.operations,
                    operationsToEdit: formPageController.operationsToEdit_
                }),
                comment
            };

            return Page.submit({}, form).$promise.then((result) => {
                Message.addSuccessLabel('Static.Message.DataSaved');
                feedbackObserver.dataChanged();
                loadData();
                EntityTextHolder.clear({ id: result.entityId, type: result.entityType });
                return result;
            });
        };
    }
});
