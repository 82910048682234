'use strict';

/**
 * Shows which operations are mapped to which relation type and entity type for the given
 * entity and entity Id in a table form.
 */
angular.module('uasApp').component('entityOperationsOverview', {
    bindings: {
        entityId: '<',
        entityType: '@',
        personId: '<?'
    },
    templateUrl: 'es6/entity-operations-overview/entity-operations-overview.html',
    controllerAs: 'entityOperationsOverviewCtrl',
    controller: function (translateFilter, AuthService, enumFilter) {
        const entityOperationsOverviewCtrl = this;

        // The order in which the relation types (headers) should be shown
        const ORDER = ['PERSON', 'ACADEMIC_YEAR', 'FACULTY', 'ORGANISATION', 'STUDY', 'MODULE_GROUP', 'MODULE', 'MODULE_OFFERING_SCHEDULE'];

        entityOperationsOverviewCtrl.$onChanges = function() {
            entityOperationsOverviewCtrl.accessRules = [];
            entityOperationsOverviewCtrl.roleTypes = [];
            entityOperationsOverviewCtrl.operations = [];

            reload();
        };

        /**
         * Fetches and initialises the relation types and operations
         */
        function reload() {
            AuthService.overview({
                entityId: entityOperationsOverviewCtrl.entityId,
                entityType: entityOperationsOverviewCtrl.entityType,
                personId: entityOperationsOverviewCtrl.personId
            }).then((accessRules) => {
                entityOperationsOverviewCtrl.accessRules = _.filter(accessRules, (r) => angular.isDefined(r.roleType));

                // Extract all relation types (these will be the headers)
                entityOperationsOverviewCtrl.roleTypes = getRoleTypes(entityOperationsOverviewCtrl.accessRules);

                // Extract all the operations
                entityOperationsOverviewCtrl.operations = translateOperations(_.map(entityOperationsOverviewCtrl.accessRules, 'operation'));
            });

            if (entityOperationsOverviewCtrl.personId) {
                entityOperationsOverviewCtrl.noRights = translateFilter('Static.Tab.Operations.NoRights');
            } else {
                entityOperationsOverviewCtrl.noRights = translateFilter(
                    `Static.Tab.Operations.NoRights.${_.snakeCase(entityOperationsOverviewCtrl.entityType).toUpperCase()}`
                );
            }
        }

        // Extract all the relation types with the binded entity type
        // Aka the headers of the table
        function getRoleTypes(accessRules) {
            return _(accessRules)
                .map((r) => ({
                    // Usually you would use the spread operator, but current eslint does not support it
                    entity: _.assign({}, r.entity, {
                        enum: _.snakeCase(r.entity.type).toUpperCase()
                    }),
                    code: r.roleType.code,
                    names: r.roleType.names
                }))
                .uniqBy(({ entity, code }) => `${entity.type}${entity.id}${code}`)
                .value()
                .sort((a, b) => {
                    // order by entity order and then by relation type code.
                    const aIndex = ORDER.indexOf(a.entity.enum);
                    const bIndex = ORDER.indexOf(b.entity.enum);
                    return aIndex === bIndex ? _.defaultTo(a.code, '').localeCompare(b.code) : aIndex - bIndex;
                });
        }

        // Set the translation of each operation
        function translateOperations(operations) {
            return _(operations)
                .map((operation) => ({
                    code: operation,
                    i18n: enumFilter(operation)
                }))
                .uniqBy('code')
                .sortBy('code')
                .value();
        }
    }
});
