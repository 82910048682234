'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AcademicYear
 * @description
 * The AcademicYear service.
 */
angular.module('uasApp').factory('AcademicYear', function(UasResource) {
    return UasResource('/year', {
        'rollover': {
            method: 'POST',
            url: '/year/:id/rollover',
            isArray: false
        },
        'previous': {
            method: 'GET',
            url: '/year/:id/previous',
            isArray: false
        }
    }).withCache('years');
});
