'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:feedbackObserver
 * @description
 * The feedbackObserver is used to tell the <feedback> component
 * that the feedback data might have changed, and that it needs to
 * reload the data.
 */
angular.module('uasApp').factory('feedbackObserver', function () {
  let observer = null;
  let disabled = false;

  return {
    dataChanged,
    onDataChanged,
    disable,
    enable
  };

  function dataChanged() {
    if (observer && !disabled) {
      observer();
    }
  }

  function onDataChanged(newObserver) {
    observer = newObserver;
  }

  function disable() {
    disabled = true;
  }

  function enable() {
    disabled = false;
  }
});
