'use strict';

angular.module('uasApp').component('vacancyEdit', {
    bindings: {
        vacancy: '<',
        onSave: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/staffing/vacancy/vacancy.edit.html',
    controllerAs: 'vacancyEditController',
    controller: function(Vacancy, Message, RoleType) {
        const vacancyEditController = this;

        vacancyEditController.$onInit = function() {
            RoleType.query({
                academicYearId: sessionStorage.academicYear,
                entityType: 'assignment'
            }).$promise.then((roles) => {
                vacancyEditController.roles = roles;
            });
        };

        vacancyEditController.cancel = function() {
            vacancyEditController.onCancel();
        };

        vacancyEditController.save = function() {
            Vacancy.store(vacancyEditController.vacancy).$promise.then(() => {
                Message.onSaved();
                vacancyEditController.onSave();
            });
        };
    }
});