'use strict';

angular.module('uasApp').component('customFieldCell', {
  bindings: {
    field: '<',
    additionalValues: '<?',
    viewClasses: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/fields/custom.field.cell.html',
  controllerAs: 'fieldCellController',
  controller: function() {
    const fieldCellController = this;

    fieldCellController.$onInit = function() {
      fieldCellController.ngModelCtrl.$render = function () {
        fieldCellController.value = getValue();
      };
    };

    function getValue() {
      if (angular.isUndefined(fieldCellController.field)) {
        return;
      }

      const entities = _.concat(fieldCellController.ngModelCtrl.$modelValue, fieldCellController.additionalValues || []);
      return _(entities).map((entity) => {
        let value;
        if (fieldCellController.field.custom === false) {
          value = _.get(entity, fieldCellController.field.name);
        } else if (entity.values) {
          value = entity.values[fieldCellController.field.id];
        }
        return value;
      }).find();
    }
  }
});
