'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTable
 * @description
 * Shows the full status history of an entity
 */
angular.module('uasApp').component('statusTable', {
  bindings: {
    entityType: '@',
    entityId: '<'
  },
  templateUrl: 'es6/status/status.table.html',
  controllerAs: 'statusTableController',
  controller: function (Status) {
    const statusTableController = this;

    statusTableController.$onInit = function () {
      statusTableController.statuses = Status.query({
        entityType: statusTableController.entityType,
        entityId: statusTableController.entityId
      });
    };
  }
});
