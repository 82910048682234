'use strict';

angular.module('uasApp').component('categoryEdit', {
  templateUrl: 'es6/type/category.edit.html',
  bindings: {
    category: '<',
    onSave: '&',
    onCancel: '&'
  },
  controllerAs: 'categoryEditController',
  controller: function () {
    const categoryEditController = this;

    categoryEditController.save = function () {
        categoryEditController.onSave({
            category: categoryEditController.category
        });
    };

    categoryEditController.cancel = function () {
        categoryEditController.onCancel();
    };
  }
});
