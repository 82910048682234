'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusPropagationList
 * @description
 * statusPropagationList Shows the status propagations
 */
angular.module('uasApp').component('statusPropagationList', {
    bindings: {
        transition: '<',
        processType: '<'
    },
    templateUrl: 'es6/status/propagation/status.propagation.list.html',
    controllerAs: 'statusPropagationListController',
    controller: function($uibModal, StatusPropagation) {
        const statusPropagationListController = this;

        statusPropagationListController.$onInit = function() {
            loadData();
        };
        
        function loadData() {
            statusPropagationListController.loading = true;
            StatusPropagation.query({
                transitionId: statusPropagationListController.transition.id
            }).$promise.then((propagations) => {
                statusPropagationListController.propagations = _.sortBy(propagations, ['target.index', 'id']);
            }).finally(() => {
                statusPropagationListController.loading = false;
            });
        }

        statusPropagationListController.create = function() {
            open({
                transitionId: statusPropagationListController.transition.id
            });
        };

        statusPropagationListController.edit = function(propagation) {
            const model = angular.copy(propagation);
            model.transitionId = _.result(propagation, 'transition.id');
            model.targetId = _.result(propagation, 'target.id');
            
            open(model);
        };

        function open(propagation) {
            $uibModal.open({
                controllerAs: 'modalController',
                controller: function ($uibModalInstance) {
                    this.propagation = propagation;
                    this.processType = statusPropagationListController.processType;
                    this.instance = $uibModalInstance;
                },
                size: 'lg',
                template: `
                    <status-propagation-modal 
                        propagation="modalController.propagation" 
                        process-type="modalController.processType" 
                        instance="modalController.instance">
                    </status-propagation-modal>
                `
            }).result.then(loadData, loadData);
        }
    }
});
