'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:subjectMatrixTable
 * @description
 * Displays a matrix of modules, dynamic columns and category-grouped subjects, with a sticky table layout.
 * Supports both structured and flat views, as well as interactivity through links, filters and tooltips.
 *
 * @param {Array} groupColumns - Array of group objects (columns).
 * @param {Array} moduleColumns - Array of module objects (columns).
 * @param {Array} categories - Array of category objects (column-groups).
 * @param {Array} subjects - Array of subject objects sorted by category (columns).
 * @param {Array} scales - Array of scale objects displayed.
 * @param {Array} groups - Array of group objects (rows).
 * @param {boolean} showStructure - Indicates if the matrix should display the structured view.
 * @param {boolean} stickyHeaders - Indicates if the matrix should have sticky headers, can cause horizontal overflow when enabled.
 * @param {Object} filter - Represents the current filter state.
 * @param {Function} onSubject - Function to change the filtered subject.
 * @param {Function} onToggle - Function to toggle nodes.
 * @param {Function} onScaleValueRemove - Function to remove a scale value from a subject.
 */

angular.module('uasApp').component('subjectMatrixTable', {
  templateUrl: 'es6/subjects/matrix/subject.matrix.table.html',
  bindings: {
    moduleColumns: '<',
    groupColumns: '<',
    categories: '<',
    subjects: '<',
    filterValues: '<',
    groups: '<',
    showPhase: '<',
    showPeriods: '<',
    showStructure: '<',
    stickyHeaders: '<',
    filter: '<',
    onSubject: '&',
    onToggle: '&',
    onFilter: '&'
  },
  controllerAs: 'subjectMatrixTableController',
  controller: function ($interval, Changes, EntityService) {
    const subjectMatrixTableController = this;

    const CHUNK_SIZE = 50;
    const INTERVAL_MS = 100;

    subjectMatrixTableController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'groups') && subjectMatrixTableController.groups) {
        if (!subjectMatrixTableController.showStructure) {
          renderModules();
        }
      }
    };

    /**
     * Add modules in chunks to group list because large number of table rows
     * can take some time to render.
     */
    function renderModules() {
      let chunks = _.chunk(subjectMatrixTableController.groups[0].modules, CHUNK_SIZE);
      subjectMatrixTableController.groups[0].modules = [];
      subjectMatrixTableController.loading = true;

      const interval = $interval(() => {
        const modules = _.head(chunks);

        if (modules) {
          subjectMatrixTableController.groups[0].modules.push(...modules);
        }

        chunks = _.tail(chunks);

        if (_.isEmpty(chunks)) {
          $interval.cancel(interval);
          subjectMatrixTableController.loading = false;
        }
      }, INTERVAL_MS);
    }

    subjectMatrixTableController.addFilter = function (filterValue) {
      filterValue.active = true;
      subjectMatrixTableController.onFilter({ filterValue });
    };

    subjectMatrixTableController.removeFilter = function (filterValue) {
      filterValue.active = false;
      subjectMatrixTableController.onFilter();
    };

    subjectMatrixTableController.onSubjectClick = function (subject, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      subjectMatrixTableController.onSubject({ subject });
    };

    subjectMatrixTableController.redirect = function (node, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      EntityService.redirectPlain({
        type: node.type,
        id: node.id
      }, {
        target: '_blank'
      });
    };
  }
});
