'use strict';

import Editor from '@toast-ui/editor';

angular.module('uasApp').component('toastuiViewer', {
  bindings: {
    viewerId: '@?',
    markdown: '<?',
    classes: '@?'
  },
  templateUrl: 'es6/app/toastui.viewer.html',
  controllerAs: 'toastuiViewerController',
  controller: function ($element, $timeout, Changes) {
    const toastuiViewerController = this;

    const DEFAULT_OPTIONS = {
      height: '250px',
      usageStatistics: false,
      viewer: true
    };

    toastuiViewerController.$onInit = function () {
      toastuiViewerController.id = toastuiViewerController.viewerId || Math.random().toString(36).substring(10);

      $timeout(() => {
        toastuiViewerController.viewer = createViewer();
      });
    };

    toastuiViewerController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'markdown') && toastuiViewerController.viewer) {
        toastuiViewerController.viewer.setMarkdown(toastuiViewerController.markdown, false);
      }
    };

    function createViewer() {
      const options = angular.copy(DEFAULT_OPTIONS);
      const element = findElementById(toastuiViewerController.id);

      return Editor.factory(_.extend(options, {
        el: element,
        initialValue: toastuiViewerController.markdown
      }));
    }

    function findElementById(id) {
      return $element.find(`#${id}`)[0];
    }
  }
});
