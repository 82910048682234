'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:awardQualificationEdit
 * @description Edit qualification data
 */
angular.module('uasApp').component('awardQualificationEdit', {
  bindings: {
    model: '<',
    fields: '<',
    operations: '<',
    onCancel: '&',
    onSave: '&'
  },
  templateUrl: 'es6/qualification/award/award.qualification.edit.html',
  controllerAs: 'awardQualificationEditController',
  controller: function (AwardQualification) {
    const awardQualificationEditController = this;

    awardQualificationEditController.submit = function (comment) {
      const entity = awardQualificationEditController.model;

      return AwardQualification.update({
        content: entity,
        comment
      }).$promise.then(() => {
        awardQualificationEditController.onSave({ entity });
      });
    };

    awardQualificationEditController.cancel = function () {
      awardQualificationEditController.onCancel();
    };
  }
});
