'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assessmentGeneral
 * @description
 * Displays general assessment information
 */
angular.module('uasApp').component('assessmentGeneral', {
    bindings: {
        assessment: '<'
    },
    templateUrl: 'es6/assessment/assessment.general.html',
    controllerAs: 'assessmentGeneralController',
    controller: function($q, MethodType, Module, Faculty, Appraisal) {
        const assessmentGeneralController = this;

        assessmentGeneralController.$onInit = function() {
            loadData();
        };

        function loadData() {
            assessmentGeneralController.loading = true;
            $q.all([
                MethodType.find(assessmentGeneralController.assessment.typeId).$promise,
                Faculty.find(assessmentGeneralController.assessment.facultyId).$promise,
                Module.find(assessmentGeneralController.assessment.moduleId).$promise,
                Appraisal.parents({
                    assessmentId: assessmentGeneralController.assessment.id
                }).$promise,
                Appraisal.children({
                    entityType: 'assessment',
                    entityId: assessmentGeneralController.assessment.id
                }).$promise
            ]).then(([type, faculty, module, parents, children]) => {
                assessmentGeneralController.type = type;
                assessmentGeneralController.faculty = faculty;
                assessmentGeneralController.module = module;
                assessmentGeneralController.parents = parents;
                assessmentGeneralController.children = children;
            }).finally(() => {
                assessmentGeneralController.loading = false;
            });
        }
    }
});
