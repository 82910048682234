'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Assessment
 * @description
 * The Assessment service.
 */
angular.module('uasApp').factory('Assessment', function (UasResource) {
  return UasResource('/assessments', {
    deleteFull: {
      method: 'DELETE',
      url: '/assessments/:id/full',
      isArray: false
    },
    report: {
      url: '/assessments-report',
      method: 'GET',
      isArray: false
    },
    search: {
      url: '/assessments/search',
      method: 'GET',
      isArray: false
    },
    validate: {
      method: 'GET',
      url: '/assessments/validate'
    }
  });
});
