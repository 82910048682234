'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasRoleType
 * @description Manages a role type.
 */
angular.module('uasApp')
    .component('uasRoleType', {
        bindings: {
            type: '<',
            onSave: '<',
            modal: '<'
        },
        templateUrl: 'es6/staffing/role/role.type.html',
        controllerAs: 'roleTypeController',
        controller: function (RoleType, Language) {
            const roleTypeController = this;

            roleTypeController.close = function () {
                roleTypeController.modal.close();
            };

            roleTypeController.save = function () {
                const roleType = roleTypeController.type;

                // No resource.store because we need the language parameter and store() does not support more than 1 function argument.
                const operation = _.isNumber(roleType.id) ? 'update' : 'save';

                RoleType[operation]({ language: Language.get(), id: roleType.id }, roleType).$promise.then((result) => {
                    roleTypeController.onSave(result);
                    roleTypeController.close();
                });
            };
        }
    });
