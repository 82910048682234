'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:EntityService
 * @description Service for entities.
 */
angular.module('uasApp')
    .factory('EntityService', function($log, $state, entityRedirect, EntityHolder, ENTITY_STATES, ENTITY_TYPES) {
        function resolveType (entityType, rootType) {
            let type = entityType;
            if (entityType !== rootType) {
                if (entityType.indexOf(rootType) !== 0) {
                    type = rootType + '-' + type;
                }
            }
            return type;
        }

        function redirect (entity, root, scroll) {
            if (angular.isUndefined(root)) {
                root = entity;
            }

            const type = resolveType(entity.type, root.type);
            const success = entityRedirect.redirect(type, { id: root.id }) || entityRedirect.redirect(root.type, { id: root.id });
            if (success) {
                EntityHolder.set(scroll);
            } else {
                $log.error('Could not redirect to %o - %o', entity, root);
            }
        }

        function redirectPlain (entity, options) {
            entityRedirect.redirect(entity.type, { id: entity.id }, options);
        }

        function getEntityType(rootType) {
            if (_.isEmpty(rootType)) {
                return rootType;
            }

            return rootType.replace('_', '-').toLowerCase();
        }

        function getRootType(entityType) {
            if (_.isEmpty(entityType)) {
                return entityType;
            } else if (_.isObject(entityType)) {
                return getRootType(getType(entityType));
            }

            return entityType.replace('-', '_').toUpperCase();
        }

      /**
       * Returns true if the entity is (one) of the given type(s).
       * @param entity {object}
       * @param types {string|string[]}
       * @returns {boolean}
       */
        function hasType(entity, types) {
            if (_.isEmpty(types)) {
                return false;
            }

            const type = getType(entity);
            if (!type) {
                return false;
            }

            if (_.isArray(types)) {
                return _.includes(types, type);
            } else {
                return types === type;
            }
        }

        function getType(entity) {
            if (_.isString(entity)) {
                return entity;
            } else if (!_.isObject(entity)) {
                return '';
            }

            const type = _.get(entity, 'self.type', entity.type) || '';
            if (!_.isString(type)) {
                return ''; // Initial save, numeric typeId
            }

            return type.replace('_', '-');
        }

        function toReference(entity) {
            if (angular.isUndefined(entity)) {
                return entity;
            }

            const reference = _.get(entity, 'reference');
            if (reference) {
                return reference;
            }

            return {
                type: getType(entity),
                id: entity.id
            };
        }

        function hasSameReference(entity1, entity2) {
          return _.isEqual(entity1, entity2);
        }

        function extend(params, entity) {
            const name = getIdName(entity);
            if (!name) {
                return params;
            }

            let result = _.extend({}, params);
            result[name] = entity.id;
            return result;
        }

        function getIdName(entity) {
            const type = getType(entity);
            if (!type) {
                return '';
            }

            const formatted = _.camelCase(type);
            return `${formatted}Id`;
        }

        function getEntityPath(entity) {
            const entityType = getType(entity);
            return `${_.camelCase(entityType)}Id`;
        }

        function getHref(entity) {
            const type = getType(entity);
            const state = ENTITY_STATES[type];
            if (state && angular.isDefined(entity.id)) {
                return $state.href(state, { id: entity.id });
            }
        }

        function getUriSref(entity) {
          if (angular.isUndefined(entity.id) || angular.isUndefined(entity.type)) {
            return undefined;
          }

          const route = ENTITY_STATES[entity.type];

          return angular.isDefined(route) ? `${route}({ id: ${entity.id} })` : undefined;
        }

        function getIcon(entity) {
          const entityType = getType(entity);

          let icon = 'fa fa-question fa-fw';
          const type = ENTITY_TYPES[_.kebabCase(entityType)];
          if (type && type.icon) {
            icon = type.icon;
          }

          return icon;
        }

        return {
          redirect,
          redirectPlain,
          toReference,
          hasSameReference,
          getEntityType,
          getRootType,
          getEntityPath,
          getType,
          getHref,
          getUriSref,
          hasType,
          extend,
          getIcon
        };
    });
