'use strict';

angular.module('uasApp')
  .factory('authenticationErrorInterceptor', function (Message, $injector, $q) {
    return {
      responseError: function (response) {
        if (response.status === 403) {
          Message.addInfo('You have no access to this page.');
        } else if (response.status === 401) {
          $injector.get('Session').destroy();

          const $state = $injector.get('$state');
          if (!_.get($state.current, 'data.public', false)) {
            $state.go('login', {
              successUrl: encodeURIComponent(location)
            });

            Message.addInfo('Please log in with your username/password.');
          }
        }
        return $q.reject(response);
      }
    };
  });
