'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:WorkflowStarter
 * @description
 * The workflow starter service.
 */
angular.module('uasApp').factory('WorkflowStarter', function(EntityService, Participant, WorkflowModal, $q, $state, $window, $uibModal) {

    function navigate(participant) {
        const url = $state.href('start-workflow', {
            entityId: participant.entity.id,
            entityType: participant.entity.type,
            id: participant.id
        });

        $window.open(url, '_blank');
    }

    function startWithNavigate(args) {
        const { entity, processId } = args;
        return withParticipant(entity, processId, navigate);
    }

    function startWithModal(args) {
        const { entity, processId, onClose, onDelete } = args;
        return withParticipant(entity, processId, (participant) => {
            WorkflowModal.openProcess({
                entity,
                participant,
                onClose,
                onDelete
            });
        });
    }

    function withParticipant(entity, processId, callback) {
        const reference = EntityService.toReference(entity);

        return Participant.mine({
            entityType: reference.type,
            entityId: reference.id
        }).$promise.then((participants) => {
            if (angular.isUndefined(processId)) {
                return participants;
            }

            return _.filter(participants, { processId });
        }).then(getParticipant).then((participant) => {
            if (angular.isDefined(participant)) {
                callback(participant);
            }
            return participant;
        });
    }

    function getParticipant(participants) {
        if (participants.length > 1) {
            return selectParticipant(participants);
        }

        const participant = _.head(participants);
        return $q.resolve(participant);
    }

    function selectParticipant(participants) {
        const deferred = $q.defer();

        $uibModal.open({
            resolve: {
                processes: function(Process) {
                    return Process.query({
                        academicYearId: sessionStorage.academicYear,
                        rootType: _(participants).map('entity.type').map(EntityService.getRootType).head()
                    }).$promise;
                }
            },
            templateUrl: 'es6/workflow/workflow.starter.modal.html',
            controllerAs: 'workflowModalController',
            controller: function(processes, $uibModalInstance) {
                const workflowModalController = this;
                workflowModalController.participants = _.map(participants, (participant) => {
                    return _.extend({
                        process: _.find(processes, { id: participant.processId })
                    }, participant);
                });

                workflowModalController.onSelect = function(participant) {
                    deferred.resolve(participant);
                    $uibModalInstance.dismiss();
                };
            }
        });

        return deferred.promise;
    }

    return { navigate, startWithNavigate, startWithModal };

});
