'use strict';

angular.module('uasApp')
  .component('uasEditModuleGroupStructure', {
      bindings: {
          entity: '<',
          group: '<',
          fields: '<',
          resource: '<',
          onCancel: '&',
          onSave: '&',
          operations: '<',
          operationsToEdit: '<?'
      },
      templateUrl: 'es6/planboard/edit/edit.module.group.structure.html',
      controllerAs: 'editModuleGroupStructureController',
      controller: function() {
        const editModuleGroupStructureController = this;

        editModuleGroupStructureController.save = function(entity) {
          editModuleGroupStructureController.resource.save({
            content: entity,
            message: editModuleGroupStructureController.message
          }).$promise.then(() => {
            editModuleGroupStructureController.cancel();
            editModuleGroupStructureController.onSave();
          });
        };

        editModuleGroupStructureController.cancel = function() {
            editModuleGroupStructureController.onCancel();
        };
      }
    });
