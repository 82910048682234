'use strict';

angular.module('uasApp').component('uasLinkButton', {
  bindings: {
    onClick: '&',
    onDelete: '&?',
    isReadOnly: '<?'
  },
  transclude: true,
  require: {
    securedCtrl: '?^secured'
  },
  templateUrl: 'es6/app/buttons/link.button.html',
  controllerAs: 'linkButtonController',
  controller: function ($element) {
    const linkButtonController = this;

    linkButtonController.$onInit = function () {
      // Secure button after it has been rendered
      if (linkButtonController.securedCtrl) {
        linkButtonController.securedCtrl.resecure($element);
      }
    };

    linkButtonController.delete = function ($event) {
      $event.stopPropagation();
      linkButtonController.onDelete();
    };
  }
});
