'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodListPage
 * @description
 * methodListPage Shows teaching methods
 */
angular.module('uasApp')
    .component('methodListPage', {
        bindings: {
            module: '<',
            page: '<',
            workflow: '<?',
            operations: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/methods/method.list.page.html',
        controllerAs: 'methodListPageController',
        controller: function (Page) {
            const methodListPageController = this;

            methodListPageController.$onInit = function () {
                methodListPageController.readOnly = methodListPageController.isReadOnly || Page.getParameterAsBoolean(methodListPageController.page, 'readOnly', false);
                methodListPageController.workflowMode = angular.isDefined(methodListPageController.workflow) &&
                  Page.getParameterAsBoolean(methodListPageController.page, 'preventWorkflow', false) !== true;
                methodListPageController.operationsToEdit = methodListPageController.workflowMode ? ['EDIT_METHODS_WORKFLOW'] : ['EDIT_METHODS'];
                methodListPageController.showBudget = Page.getParameterAsBoolean(methodListPageController.page, 'showBudget', false);
                methodListPageController.showEdit = Page.getParameterAsBoolean(methodListPageController.page, 'showEdit', false);
                methodListPageController.showEffort = Page.getParameterAsBoolean(methodListPageController.page, 'showEffort', false);
                methodListPageController.autoSave = Page.getParameterAsBoolean(methodListPageController.page, 'autoSave', false);
                methodListPageController.columns = Page.getParameterAsArray(methodListPageController.page, 'columns');
                methodListPageController.active = Page.getParameterAsBoolean(methodListPageController.page, 'active', false);
            };
        }
    });
