'use strict';

angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('subject-types', {
        url: '/subject-types',
        template: '<subject-type-list></subject-type-list>',
        breadcrumb: {
            label: 'Static.Page.SubjectTypes',
            root: true
        },
        admin: true
    });

    $stateProvider.state('scales', {
        url: '/scales',
        template: '<scale-value-list></scale-value-list>',
        breadcrumb: {
            label: 'Static.Page.Scales',
            root: true
        },
        admin: true
    });

});
