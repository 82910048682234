'use strict';

angular.module('uasApp').filter('paged', function(startFromFilter, limitToFilter) {
    return function(content, pageable) {
        if (!_.isArray(content) || angular.isUndefined(pageable)) {
            return content;
        }

        const currentPage = _.result(pageable, 'currentPage') || 1;
        const pageSize = _.result(pageable, 'pageSize') || 10;
        const startIndex = (currentPage - 1) * pageSize;

        if (!_.isEmpty(pageable.order)) {
            content = pageable.sort(content);
        }
        
        return limitToFilter(startFromFilter(content, startIndex), pageSize);
    };
});
