'use strict';

angular.module('uasApp').component('uasExportType', {
  templateUrl: 'es6/export/export.type.html',
  bindings: {
    customType: '<'
  },
  controllerAs: 'exportTypeController',
  controller: function ($q, ExternalSystem, ExportType, Export) {
    const exportTypeController = this;

    exportTypeController.$onInit = function() {
      loadData();
    };

    function loadData() {
      exportTypeController.loading = true;
      $q.all([
        ExternalSystem.query().$promise.then((systems) => {
          return _.filter(systems, { exportable: true });
        }),
        ExportType.query({
          type: exportTypeController.customType.id
        }).$promise,
        Export.handlers({
          rootType: exportTypeController.customType.rootType,
          entityType: exportTypeController.customType.entityType
        }).$promise
      ]).then(([systems, types, handlers]) => {
        exportTypeController.systems = systems;
        exportTypeController.types = _.map(systems, (system) => {
          const type = _.find(types, { 
            system: system.id 
          });
        
          return _.extend(type, { system });
        });

        exportTypeController.handlers = handlers;
      }).finally(() => {
        exportTypeController.loading = false;
      });
    }

    exportTypeController.select = function(type) {
      exportTypeController.type = angular.copy(type);
    };

    exportTypeController.cancel = function() {
      delete exportTypeController.type;
    };

    exportTypeController.save = function(type) {
      const body = angular.copy(type);
      body.system = _.result(body.system, 'id');
      body.type = exportTypeController.customType.id;

      ExportType.store(body).$promise.then(() => {
        exportTypeController.cancel();
        loadData();
      });
    };

    exportTypeController.delete = function(type) {
      ExportType.delete({
        id: type.id
      }).$promise.then(() => {
        exportTypeController.cancel();
        loadData();
      });
    };
  }
});
