'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processList
 * @description
 * processList Shows the processes
 */
angular.module('uasApp')
  .component('processList', {
    bindings: {
      processType: '<'
    },
    templateUrl: 'es6/process/process.list.html',
    controllerAs: 'processListController',
    controller: function (Process, Pageable, Message, $uibModal) {

      const processListController = this;

      processListController.$onInit = function() {
        processListController.pageable = Pageable.of({
          order: 'startDate',
          reverse: true
        });

        loadData();
      };

      function loadData() {
        const params = processListController.pageable.build({
          typeId: processListController.processType.id
        });

        processListController.loading = true;
        Process.search(params).$promise.then((processes) => {
          processListController.processes = processes;
        }).finally(() => {
          processListController.loading = false;
        });
      }

      processListController.search = function() {
        loadData();
      };

      processListController.remove = function(data) {
        return Process.remove({ id: data.id }).$promise.then(() => {
            Message.onRemoved();
            return loadData();
        });
      };

      processListController.create = function() {
        open({
          academicYearId: sessionStorage.academicYear,
          type: processListController.processType
        });
      };

      processListController.edit = open;

      function open(process) {
          const body = angular.copy(process);
          body.typeId = _.result(process.type, 'id');
          body.periodId = _.result(process.period, 'id');

          $uibModal.open({
              controllerAs: 'processModalController',
              controller: function ($uibModalInstance) {
                  const processModalController = this; 
                  processModalController.process = body;
                  processModalController.type = process.type;
                  processModalController.instance = $uibModalInstance;
              },
              size: 'lg',
              template: `
                <uas-process-modal 
                  process="processModalController.process" 
                  type="processModalController.type"
                  instance="processModalController.instance">
                </uas-process-modal>
              `
          }).result.then(loadData, loadData);
      }

    }
});
