'use strict';

angular.module('uasApp').factory('serverErrorInterceptor', function (Message, $q, $injector) {
  return {
    responseError: function responseError(response) {
      const $state = $injector.get('$state');
      const message = _.get(response, 'data.error.message');

      switch (response.status) {
        case 400:
          Message.addErrorLabel(message);
          break;
        case 422:
          Message.addErrorLabel(message || 'Status.422', 'Invalid input');
          break;
        case 500:
          Message.addErrorLabel(message || 'Status.500', 'Server error');
          break;
        case 503:
          if ($state.current.name !== 'maintenance') {
            $state.transitionTo('maintenance');
          }
          break;
        case 504:
          Message.addErrorLabel('Status.504', 'Server timeout');
          break;
      }

      return $q.reject(response);
    }
  };
});
