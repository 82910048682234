'use strict';

angular.module('uasApp').config(function ($stateProvider) {
    $stateProvider.state('video-list', {
        url: '/videos',
        breadcrumb: {
            label: 'Static.Page.Videos',
            root: true
        },
        template: '<uas-video-list></uas-video-list>',
        admin: true
    });
});
