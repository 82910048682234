'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:ruleAdmin
 * @description
 * Rule admin fields
 */
angular.module('uasApp').component('ruleAdmin', {
  bindings: {
    rule: '<',
    operations: '<'
  },
  templateUrl: 'es6/rule/rule.admin.html',
  controllerAs: 'ruleAdminController',
  controller: function(Rule, Message) {
    const ruleAdminController = this;

    ruleAdminController.$onInit = function() {
      ruleAdminController.operationsToEdit = ['ADMIN_OBJECT'];
    };

    ruleAdminController.save = function(rule, comment) {
      Rule.store({
        content: rule,
        comment  
      }).$promise.then(() => {
        Message.onSaved();
      });
    };
  }
});