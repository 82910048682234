'use strict';

angular.module('uasApp').component('taskFormulaList', {
    bindings: {
        template: '<',
        onChange: '&'
    },
    templateUrl: 'es6/staffing/task/template/task.formula.list.html',
    controllerAs: 'taskFormulaListController',
    controller: function ($uibModal) {
        const taskFormulaListController = this;

        taskFormulaListController.create = function() {
            const formula = {
                unit: 'HOURS'
            };

            open(formula, (result) => {
                getFormulas().push(result);
                save();
            });
        };

        function getFormulas() {
            if (angular.isUndefined(taskFormulaListController.template.formulas)) {
                taskFormulaListController.template.formulas = [];
            }
            return taskFormulaListController.template.formulas;
        }

        taskFormulaListController.edit = function(formula) {
            open(formula, (result) => {
                _.extend(formula, result);
                save();
            });
        };

        function open(formula, onSubmit) {
            $uibModal.open({
                template: `
                    <task-formula-edit 
                        template="modalController.template" 
                        formula="modalController.formula" 
                        on-submit="modalController.save(formula)" 
                        on-cancel="modalController.close()">
                    </task-formula-edit>
                `,
                controllerAs: 'modalController',
                controller: function ($uibModalInstance) {
                    const modalController = this;
                    modalController.template = taskFormulaListController.template;
                    modalController.formula = angular.copy(formula);

                    modalController.close = function() {
                        $uibModalInstance.dismiss();
                    };

                    modalController.save = function(result) {
                        modalController.close();
                        onSubmit(result);
                    };
                }
            });
        }

        taskFormulaListController.delete = function(formula) {
            _.remove(taskFormulaListController.template.formulas, formula);
            save();
        };

        function save() {
            taskFormulaListController.onChange({
                template: taskFormulaListController.template
            });
        }
    }
});
