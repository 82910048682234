'use strict';

angular.module('uasApp').constant('PREFERENCE_TYPES', [
    {
        name: 'AVAILABLE',
        icon: 'check',
        type: 'success',
        order: 1
    }, {
        name: 'RATHER_NOT',
        icon: 'question',
        type: 'warning',
        order: 2
    }, {
        name: 'UNAVAILABLE',
        icon: 'times',
        type: 'danger',
        order: 3
    }
]);
