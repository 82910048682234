'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowBegin
 * @description
 * uasWorkflowBegin Begins the workflow
 */
angular.module('uasApp')
  .component('uasWorkflowBegin', {
    bindings: {
      entity: '<',
      processId: '<?'
    },
    templateUrl: 'es6/workflow/workflow.begin.html',
    controllerAs: 'beginWorkflowController',
    controller: function (Workflow, $state) {
      const beginWorkflowController = this;

      beginWorkflowController.$onInit = function() {
        loadData();
      };

      function loadData() {
        beginWorkflowController.loading = true;

        if (!beginWorkflowController.processId) {
          $state.go('dashboard');
        } else {
          Workflow.begin({
            entity: beginWorkflowController.entity,
            process: beginWorkflowController.processId
          }).$promise.then((result) => {  
            const page = result.page;
            if (angular.isUndefined(page)) {
              $state.go('dashboard');
            } else {
              $state.go('workflow', {
                id: result.id,
                entityId: result.entity.id,
                entityType: result.entity.type,
                processId: beginWorkflowController.processId,
                pageId: page.id
              });
            }
          }, function() {
            $state.go('dashboard');
          }).finally(() => {
            beginWorkflowController.loading = false;
          });
        }
      }
    }
});
