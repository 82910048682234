'use strict';

angular.module('uasApp').component('notificationTemplates', {

    templateUrl: 'es6/admin/notification/templates/notification.templates.html',
    controllerAs: 'notificationTemplateController',
    controller: function ($uibModal, Pageable, Message, NotificationTemplate) {

        const notificationTemplateController = this;

        notificationTemplateController.$onInit = function () {
            notificationTemplateController.pageable = Pageable.of({
                order: 'templateName'
            });
            notificationTemplateController.search();
        };

        notificationTemplateController.search = function() {
            const params = notificationTemplateController.pageable.build({
                searchTerms: notificationTemplateController.searchTerms
            });

            notificationTemplateController.loading = true;
            NotificationTemplate.get(params).$promise.then(function(templates) {
                notificationTemplateController.templates = templates;
            }).finally(function() {
                notificationTemplateController.loading = false;
            });
        };

        notificationTemplateController.edit = function (notificationTemplate) {
            let template = angular.copy(notificationTemplate) || {};

            $uibModal.open({
                size: 'lg',
                templateUrl: 'es6/admin/notification/templates/notification.template.edit.html',
                resolve: {
                    events: function() {
                        return NotificationTemplate.events().$promise;
                    },
                    styles: function(NotificationStyle) {
                        return NotificationStyle.query().$promise;
                    }
                },
                controllerAs: 'notificationTemplateEditController',
                controller: function ($uibModalInstance, events, styles) {
                    const notificationTemplateEditController = this;

                    notificationTemplateEditController.$onInit = function () {
                        notificationTemplateEditController.notificationTemplate = template;
                        notificationTemplateEditController.events = events;
                        notificationTemplateEditController.styles = styles;
                    };

                    notificationTemplateEditController.save = function () {
                        NotificationTemplate.store(notificationTemplateEditController.notificationTemplate).$promise.then(() => {
                            notificationTemplateController.search();
                            Message.addSuccessLabel('Static.Message.DataSaved');
                            $uibModalInstance.close();
                        });
                    };
                }
            });
        };

        notificationTemplateController.delete = function (notificationTemplate) {
            NotificationTemplate.delete({
                id: notificationTemplate.id
            }).$promise.then(() => {
                notificationTemplateController.search();
                Message.addSuccessLabel('Static.Message.DataRemoved');
            });
        };
    }
});
