'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTypeList
 * @description
 * statusTypeList Shows the status types
 */
angular.module('uasApp').component('statusTypeList', {
  bindings: {
    processType: '<',
    descriptionId: '@',
    editable: '<?',
    expandable: '<?'
  },
  templateUrl: 'es6/status/type/status.type.list.html',
  controllerAs: 'statusTypeListController',
  controller: function (StatusType, Workflow, RoleType, Message, Page, $uibModal, $q) {
    const statusTypeListController = this;

    statusTypeListController.$onInit = function () {
      statusTypeListController.allToggled = false;

      loadData();
    };

    function loadData() {
      if (angular.isDefined(statusTypeListController.processType.id)) {
        statusTypeListController.loading = true;
        $q.all([
          StatusType.query({
            processTypeId: statusTypeListController.processType.id
          }).$promise,
          Workflow.query().$promise,
          RoleType.query().$promise
        ]).then(([types, workflows, roles]) => {
          statusTypeListController.types = _(types).sortBy(['index', 'name', 'id']).map((type) => {
            const workflow = _.find(workflows, { id: type.workflowId });
            type.workflow = workflow;

            if (angular.isDefined(workflow)) {
              if (!_.isEmpty(workflow.pages)) {
                type.isExpandable = true;

                const roleIds = _(workflow.relations || []).map('typeId').filter(angular.isDefined).value();
                type.roles = _(roles).filter((role) => {
                  return _.includes(roleIds, role.id);
                }).sortBy(['sequence', 'id']).value();

                setExpandable(workflow.pages);
              }
            }

            return type;
          }).value();
        }).finally(() => {
          statusTypeListController.loading = false;
        });
      }
    }

    function setExpandable(pages) {
      _.forEach(pages, (page) => {
        page.isExpandable = !_.isEmpty(page.items) || (!_.isEmpty(page.children) && page.route === 'rows');
      });
    }

    statusTypeListController.toggleAll = function () {
      statusTypeListController.allToggled = statusTypeListController.allToggled !== true;

      _.forEach(statusTypeListController.types, (status) => {
        status.open = status.isExpandable && statusTypeListController.allToggled;

        if (angular.isDefined(status.workflow) && angular.isDefined(status.workflow.pages)) {
          toggleAllPages(status.workflow.pages, statusTypeListController.allToggled);
        }
      });
    };

    function toggleAllPages(pages, open) {
      _.forEach(pages, (page) => {
        page.open = page.isExpandable && open;

        if (!_.isEmpty(page.children)) {
          if (open) {
            loadChildren(page).then((children) => {
              toggleAllPages(children, open);
            });
          } else {
            toggleAllPages(page.pages, open);
          }
        }
      });
    }

    function loadChildren(page) {
      if (!page.loaded) {
        return Page.children({ id: page.id }).$promise.then((pages) => {
          page.pages = pages;
          page.loaded = true;
          setExpandable(pages);

          return pages;
        });
      }
      return $q.resolve(page.pages);
    }

    statusTypeListController.toggle = function (section) {
      if (statusTypeListController.expandable && section.isExpandable) {
        section.open = section.open !== true;

        if (!_.isEmpty(section.children) && section.open) {
          loadChildren(section);
        }
        updateAllToggled();
      }
    };

    function updateAllToggled() {
      statusTypeListController.allToggled = _.every(statusTypeListController.types, (type) => {
        return isExpanded(type) && (!type.isExpandable || pagesExpanded(type.workflow.pages));
      });
    }

    function isExpanded(section) {
      return !section.isExpandable || section.open;
    }

    function pagesExpanded(pages) {
      return _.every(pages, (page) => {
        return isExpanded(page) && pagesExpanded(page.pages);
      });
    }

    statusTypeListController.create = function () {
      edit({
        processType: statusTypeListController.processType.id
      });
    };

    statusTypeListController.edit = function (statusType) {
      StatusType.get({
        id: statusType.id
      }).$promise.then(edit);
    };

    function edit(statusType) {
      $uibModal.open({
        controllerAs: 'statusTypeModalStateController',
        controller: function ($uibModalInstance) {
          this.statusType = buildModel(statusType);
          this.rootType = statusTypeListController.processType.rootType;
          this.instance = $uibModalInstance;
        },
        size: 'lg',
        template: `
          <status-type-modal
              status-type="statusTypeModalStateController.statusType"
              root-type="statusTypeModalStateController.rootType"
              instance="statusTypeModalStateController.instance">
          </status-transition-modal>
        `
      }).result.then(loadData, loadData);
    }

    function buildModel(statusType) {
      const model = angular.copy(statusType);
      model.hookId = _.result(statusType, 'hook.id');
      return model;
    }

    statusTypeListController.remove = function (type) {
      StatusType.remove({ id: type.id }).$promise.then(function () {
        Message.onRemoved();
        loadData();
      });
    };

    statusTypeListController.notifications = function (type) {
      $uibModal.open({
        controller: function ($scope, $uibModalInstance) {
          $scope.type = angular.copy(type);
          $scope.instance = $uibModalInstance;
        },
        size: 'lg',
        template: `
          <div class="modal-header">
              <button class="close" ng-click="instance.dismiss()"></button>
              <h2 class="modal-title">{{ type.labels | i18n }} - <span translate>Static.Page.Notifications.Header</span></h2>
          </div>
          <div class="modal-body">
              <uas-process-notifications status-type="type" on-cancel="instance.dismiss()"></uas-process-notifications>
          </div>
        `
      });
    };
  }
});
