'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:QualificationCtrl
 * @description
 * # QualificationCtrl
 * Qualification controller
 */
angular.module('uasApp')
    .controller('QualificationCtrl', function ($scope, $state, qualification, Tabs, operations) {

        // Bind the id for the feedback directive
        $scope.qualificationId = $state.params.id;
        $scope.qualification = qualification;

        // Tabs which uses ui-router states to generate content.
        const tabs = [{
            view: 'general',
            route: 'qualification.general',
            readOnly: true
        }, {
            view: 'relations',
            route: 'qualification.relations'
        }, {
            view: 'description',
            route: 'qualification.description'
        }, {
            view: 'links',
            route: 'qualification.links'
        }, {
            view: 'admin',
            route: 'qualification.admin'
        }];

        $scope.operations = operations;

        Tabs.build(tabs, operations, 'qualification', 'qualification', $scope.qualificationId).then((result) => {
            $scope.tabs = result;
        });
    });
