'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Customer
 * @description
 * The Customer resource.
 */
angular.module('uasApp').factory('Customer', function (UasResource) {
  return UasResource('/customer', {
    get: {
      method: 'GET',
      url: '/customer',
      isArray: false
    }
  });
});
