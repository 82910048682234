'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:EmailAddress
 * @description
 * The EmailAddress service.
 */
angular.module('uasApp').factory('EmailAddress', function ($resource, BackendService, EmailClipboard) {
    const resource = $resource(BackendService.getBaseUrl() + '/email-address', {}, {
        'study': {
            method: 'GET',
            url: BackendService.getBaseUrl() + '/email-address/study',
            isArray: true
        },
        'module': {
            method: 'GET',
            url: BackendService.getBaseUrl() + '/email-address/module',
            isArray: true
        },
        'persons': {
            method: 'POST',
            url: BackendService.getBaseUrl() + '/email-address/persons',
            isArray: true
        },
        'entities': {
            method: 'POST',
            url: BackendService.getBaseUrl() + '/email-address/entities',
            isArray: true
        }
    });

    resource.export = function (recipients) {
        const emailAddresses = _.map(recipients, (recipient) =>
            `"${recipient.fullName}" <${recipient.email}>`
        );
        EmailClipboard.copy(emailAddresses);
    };

    return resource;
});
