'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:buttonOptions
 * @description
 * Container for buttonOptions directives.
 * Together, they create an accessible group of selectable radio (single-value) buttons.
 * Can be used in forms, or as tabs (use squared).
 */
angular.module('uasApp')
  .component('buttonOptions', {
    bindings: {
      classes: '@?',
      squared: '<?'
    },
    transclude: true,
    templateUrl: 'es6/app/forms/buttons/button.options.html',
    controllerAs: 'buttonOptionsController'
  });
