'use strict';

/**
 * Retrieves the joins arguments
 */
angular.module('uasApp').filter('pluck', function() {
    return function(values, property) {
        return _.map(values, property);
    };
});
