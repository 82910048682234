'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:dropdown
 * @description
 * Responsive uib-dropdown that dynamically switches between dropdown (default) and dropdown,
 * based on available space at the top and bottom of the window. Autodrop, if you will.
 * The autodrop is repositioned and resized on init and on window resize. But, not (yet) on scroll.
 * Supports appended and non-appended to the body.
 * Must be transcluded with an uib-dropdown-toggle and an uib-dropdown-menu.
 * @see https://angular-ui.github.io/bootstrap/#!#dropdown
 */
angular.module('uasApp').component('dropdown', {
  bindings: {
    appendToBody: '<?',
    autoClose: '@?',
    inline: '<?',
    classes: '<?',
    isDisabled: '<?',
    isOpen: '<?',
    onToggle: '&?'
  },
  templateUrl: 'es6/app/forms/dropdown/dropdown.html',
  transclude: true,
  controllerAs: 'dropdownController',
  controller: function ($document, $element, $scope, $window) {
    const dropdownController = this;

    const MARGIN_TO_TOGGLE = 5;
    const MARGIN_TO_WINDOW = 15;

    dropdownController.$onInit = function () {
      dropdownController.window = angular.element($window);

      angular.element($window).on('resize', function () {
        debouncedCheckPositioning();
      });
    };

    dropdownController.$onChanges = function () {
      dropdownController.inline_ = _.defaultTo(dropdownController.inline, false);
      dropdownController.isDisabled_ = _.defaultTo(dropdownController.isDisabled, false);
      dropdownController.isOpen_ = _.defaultTo(dropdownController.isOpen, false);
      dropdownController.appendToBody_ = _.defaultTo(dropdownController.appendToBody, false);
      dropdownController.autoClose_ = _.defaultTo(dropdownController.autoClose, 'always');
    };

    dropdownController.onToggleClick = function (open) {
      dropdownController.isOpen_ = open;

      checkPositioning();

      if (_.isFunction(dropdownController.onToggle)) {
        dropdownController.onToggle({ open: dropdownController.isOpen_ });
      }
    };

    function checkPositioning() {
      if (dropdownController.isOpen_) {
        updatePositioning();
      }
    }

    function updatePositioning() {
      setElements();

      if (dropdownController.toggleElement && dropdownController.menuElement) {
        setMenuStyle();
        setCurrentDimensions();
        reposition();
      }
    }

    function setElements() {
      dropdownController.toggleElement = getToggleElement();
      dropdownController.menuElement = getMenuElement();
    }

    function setMenuStyle() {
      dropdownController.menuStyle = dropdownController.menuElement.style;
    }

    function setCurrentDimensions() {
      dropdownController.toggleOffset = angular.element(dropdownController.toggleElement).offset();
      dropdownController.toggleElementHeight = _.get(dropdownController.toggleElement[0], 'offsetHeight', 0);
      updateMenuHeight();
    }

    function reposition() {
      const becomeDropup = shouldBecomeDropup();

      if (dropdownController.appendToBody_) {
        autodropBodyAppended(becomeDropup);
      } else {
        autodropInlineAppended(becomeDropup);
      }
    }

    function setHorizontalPosition() {
      if (!dropdownController.menuElement.classList.contains('dropdown-menu-right')) {
        const offsetLeft = _.get(dropdownController.toggleOffset, 'left', 0);

        dropdownController.menuStyle.left = toPx(offsetLeft);
      }
    }

    function autodropBodyAppended(becomeDropup) {
      setHorizontalPosition();
      if (becomeDropup) {
        toBodyAppendedDropup();
      } else {
        toBodyAppendedDropdown();
      }
    }

    function toBodyAppendedDropup() {
      setMenuMaxHeight(dropdownController.spaceUp);
      updateMenuHeight();
      setBodyAppendedDropupTop();
    }

    function toBodyAppendedDropdown() {
      setMenuMaxHeight(dropdownController.spaceDown);
    }

    function autodropInlineAppended(becomeDropup) {
      const space = becomeDropup ? dropdownController.spaceUp : dropdownController.spaceDown;
      setMenuMaxHeight(space);

      $element.toggleClass('dropup', becomeDropup);
    }

    function getToggleElement() {
      return $element.find('.dropdown-toggle');
    }

    function getMenuElement() {
      if (dropdownController.appendToBody_) {
        return $document[0].querySelector('[uib-dropdown-menu][style*="display: block"]');
      }

      return $element.find('.dropdown-menu')[0];
    }

    function shouldBecomeDropup() {
      dropdownController.spaceUp = getSpaceUp();
      dropdownController.spaceDown = getSpaceDown();

      const lacksSpaceDown = dropdownController.spaceDown < dropdownController.menuHeight;
      const hasMoreSpaceUp = dropdownController.spaceUp > dropdownController.spaceDown;

      return lacksSpaceDown && hasMoreSpaceUp;
    }

    function getSpaceUp() {
      const rect = dropdownController.toggleElement[0].getBoundingClientRect();
      const spaceBetweenToggleTopAndViewportTop = _.get(rect, 'top', 0);

      return _.round(spaceBetweenToggleTopAndViewportTop);
    }

    function getSpaceDown() {
      const windowHeight = dropdownController.window.height();
      const rect = dropdownController.toggleElement[0].getBoundingClientRect();
      const spaceBetweenToggleBottomAndViewportTop = _.get(rect, 'bottom', 0);
      const spaceDown = windowHeight - spaceBetweenToggleBottomAndViewportTop;

      return _.round(spaceDown);
    }

    function setMenuMaxHeight(idealHeight) {
      const heightWithMargin = idealHeight - MARGIN_TO_WINDOW;
      const maxHeight = Math.min(heightWithMargin, dropdownController.initialMaxHeight);

      dropdownController.menuStyle.maxHeight = toPx(maxHeight);
    }

    function updateMenuHeight() {
      dropdownController.menuHeight = _.get(dropdownController.menuElement, 'offsetHeight', 0);
      dropdownController.initialMaxHeight = _.defaultTo(dropdownController.initialMaxHeight, getComputedMaxHeight());
    }

    function getComputedMaxHeight() {
      const computedStyle = dropdownController.window[0].getComputedStyle(dropdownController.menuElement);
      const maxHeight = computedStyle.getPropertyValue('max-height');

      return parseFloat(maxHeight);
    }

    function setBodyAppendedDropupTop() {
      const currentTop = _.defaultTo(_.parseInt(dropdownController.menuStyle.top), 0);
      const newTop = currentTop - dropdownController.menuHeight - dropdownController.toggleElementHeight - MARGIN_TO_TOGGLE;

      dropdownController.menuStyle.top = toPx(newTop);
    }

    function toPx(number) {
      return `${number}px`;
    }

    const debouncedCheckPositioning = _.debounce(function () {
      checkPositioning();
    }, 200);

    dropdownController.$onDestroy = function() {
      angular.element($window).off('resize', debouncedCheckPositioning);
    };
  }
});
