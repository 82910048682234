'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processReportPage
 * @description
 * processReportPage Pick the process status
 */
angular.module('uasApp').component('processReportPage', {
    bindings: {
        entity: '<',
        page: '<',
        process: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/process/progress/process.report.page.html',
    controllerAs: 'processReportPageController',
    controller: function (Process, ProcessProgress, ProcessType, Page, Promises, EntityService, ENTITY_STATES) {
        const processReportPageController = this;

        processReportPageController.$onInit = function() {
            processReportPageController.readOnly = processReportPageController.isReadOnly === true || Page.getParameterAsBoolean(processReportPageController.page, 'readOnly', false);
            loadData();
        };

        function loadData() {
            let processTypeId = Page.getParameterAsInt(processReportPageController.page, 'type') || _.get(processReportPageController.process, 'type.id');

            processReportPageController.loading = true;
            return ProcessType.find(processTypeId).$promise.then((type) => {
                if (angular.isUndefined(type)) {
                    return [];
                }

                processReportPageController.type = type;

                const entityType = EntityService.getEntityType(type.rootType);
                processReportPageController.state = ENTITY_STATES[entityType];

                return Process.query({
                    typeId: type.id,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((processes) => {
                    const requests = _.map(processes, (process) => {
                        return () => ProcessProgress.get({
                            process: process.id
                        }).$promise;
                    });

                    const progress = Promises.chain(requests);
                    processReportPageController.progress = progress;
                    return progress.$promise;
                });
            }).then((results) => {
                processReportPageController.processes = _.map(results, 'process');
                processReportPageController.statuses = _(results).map('statuses').flatten().uniqBy('id').value();
                processReportPageController.entities = _(results).map('entities').flatten().groupBy('id').map(mergeRows).value();
            }).finally(() => {
                processReportPageController.loading = false;
            });
        }

        function mergeRows(rows) {
            const { id, code, localName, englishName } = _.head(rows);

            let statuses = {};
            _.forEach(rows, (row) => {
                statuses[row.process] = _.find(processReportPageController.statuses, { id: row.status });
            });

            const status = _(statuses).sortBy('index').find();

            return { id, code, localName, englishName, status, statuses };
        }
    }
});
