'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:PersonCtrl
 * @description
 * # PersonCtrl
 * Person root controller
 */
angular.module('uasApp')
    .controller('PersonCtrl', function ($scope, $state, person, currentPerson, Tabs, operations, historyMode) {

        $scope.person = person;
        $scope.personId = $state.params.id;

        // Tabs which uses ui-router states to generate content.
        const tabs = [{
            title: 'Static.Tab.Person.General',
            view: 'general',
            route: 'person.general',
            label: 'Static.Help.Person.General',
            visible: true
        }, {
            title: 'Static.Tab.Person.Assignments',
            view: 'assignments',
            route: 'person.assignments',
            label: 'Static.Help.Person.Assignments'
        }, {
            title: 'Static.Tab.Person.Roles',
            view: 'roles',
            route: 'person.roles',
            label: 'Static.Help.Person.Roles'
        }, {
            title: 'Static.Tab.Person.Relations',
            view: 'relations',
            route: 'person.relations',
            label: 'Static.Help.Person.Relations'
        }, {
            title: 'Static.Tab.Person.Availability',
            view: 'availability',
            route: 'person.availability',
            label: 'Static.Help.Person.Availability',
            visible: true
        }, {
            title: 'Static.Tab.Person.Preferences',
            view: 'preferences',
            route: 'person.preferences',
            label: 'Static.Help.Person.Preferences',
            visible: true
        }, {
            title: 'Static.Page.LoginAsPermission',
            view: 'loginas',
            route: 'person.loginpermission',
            visible: true
        }, {
            title: 'Static.Tab.Person.Admin',
            view: 'admin',
            route: 'person.admin',
            label: 'Static.Help.Person.Admin',
            visible: true
        }];

        $scope.operations = operations;
        $scope.historyMode = historyMode;

        Tabs.build(tabs, operations, 'person', 'person', $scope.person.id).then((personTabs) => {
            // Only show loginas and notification tab if the person is the current user
            // Would be better if it is blocked on route level, because you can still manually go to the pages
            // But every part of this app is written this way
            personTabs.filter((tab) => tab.view === 'loginas').forEach((tab) => {
                tab.visible = tab.visible && currentPerson.id === person.id;
            });

            $scope.tabs = personTabs;
        });
    });
