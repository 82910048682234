'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:planboardPage
 * @description
 * planboardPage Manages a workflow.
 */
angular.module('uasApp')
  .component('planboardPage', {
    bindings: {
      study: '<',
      operations: '<',
      page: '<',
      workflow: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/planboard/planboard.page.html',
    controllerAs: 'planboardPageController',
    controller: function(Page, Attributes) {
      const planboardPageController = this;

      planboardPageController.$onInit = function() {
        planboardPageController.readOnly = planboardPageController.isReadOnly || Page.getParameterAsBoolean(planboardPageController.page, 'readOnly', false);
        planboardPageController.showAdvice = Page.getParameterAsBoolean(planboardPageController.page, 'showAdvice', false);
        planboardPageController.showPrint = Page.getParameterAsBoolean(planboardPageController.page, 'showPrint', false);

        planboardPageController.defaultMode = Page.getParameter(planboardPageController.page, 'mode', 'edit');
        planboardPageController.setMode();
        planboardPageController.modes = Page.getParameter(planboardPageController.page, 'modes', '')
          .split(',')
          .map((mode) => _.trim(mode))
          .filter((mode) => !_.isEmpty(mode));

        const attributes = Page.getParameterAsArray(planboardPageController.page, 'extraAttributes');
        planboardPageController.attributes = Attributes.create(attributes);

        planboardPageController.subjectTypeId = Page.getParameterAsInt(planboardPageController.page, 'subjectType');
      };

      planboardPageController.setMode = function() {
        planboardPageController.mode = planboardPageController.defaultMode;
        planboardPageController.isPrintMode = planboardPageController.mode === 'print';
      };

      planboardPageController.setPrintMode = function() {
        planboardPageController.mode = 'print';
        planboardPageController.isPrintMode = true;
      };
    }
  });
