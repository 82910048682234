'use strict';

/**
 * Filter that formats date with format configured in system parameter ui.datetimeformat.
 * If ui.dateformat is not defined the date is formatted with 'dd-MM-yyyy HH:mm:ss' pattern.
 * Usage:
 * {{ date | configDateTime }}
 */
angular.module('uasApp').filter('configDateTime', function($filter, $rootScope) {
    return function(date) {
        if (angular.isUndefined(date)) {
            return '';
        }

        let dateFormat = $rootScope.configDateTimeFormat;
        if (_.isEmpty(dateFormat)) {
            dateFormat = 'dd-MM-yyyy HH:mm:ss';
        }

        return $filter('date')(date, dateFormat);

    };
});
