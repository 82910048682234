'use strict';

angular.module('uasApp').component('assignmentSelect', {
    templateUrl: 'es6/staffing/assignment/assignment.select.html',
    bindings: {
        personId: '<',
        autoSelect: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'assignmentSelectController',
    controller: function(Assignment, Changes) {
        const assignmentSelectController = this;

        assignmentSelectController.$onInit = function() {
            assignmentSelectController.ngModelCtrl.$render = function () {
                assignmentSelectController.assignmentId = assignmentSelectController.ngModelCtrl.$modelValue;
            };
        };

        assignmentSelectController.$onChanges = function(changes) {
            if (Changes.hasChanged(changes, 'personId')) {
                loadData();
            }
        };

        function loadData() {
            if (angular.isDefined(assignmentSelectController.personId)) {
                Assignment.query({
                    personId: assignmentSelectController.personId,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((assignments) => {
                    assignmentSelectController.assignments = _(assignments).map(build).sortBy(['label', 'id']).value();

                    const selectable = _.filter(assignmentSelectController.assignments, (assignment) => assignment.fte > 0);
                    if (assignmentSelectController.autoSelect && selectable.length === 1) {
                        assignmentSelectController.assignmentId = _.head(selectable).id;
                        assignmentSelectController.onChange();
                    }
                });
            } else if (angular.isDefined(assignmentSelectController.assignmentId)) {
                Assignment.get({ id: assignmentSelectController.assignmentId }).$promise.then(build).then((assignment) => {
                    assignmentSelectController.assignments = [assignment];
                });
            } else {
                assignmentSelectController.assignments = [];
            }
        }

        function build(assignment) {
            const label = Assignment.getLabel(assignment, true);
            return _.extend(assignment, { label });
        }

        assignmentSelectController.onChange = function() {
            assignmentSelectController.ngModelCtrl.$setViewValue(assignmentSelectController.assignmentId);
        };
    }
});