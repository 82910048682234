'use strict';

angular.module('uasApp').filter('prettyPrint', function(dateFilter) {
    return function(value) {
        if (value instanceof Date) {
            return dateFilter(value, 'yyyy-MM-dd');
        }
        return value;
    };
});
