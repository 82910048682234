'use strict';

angular.module('uasApp').component('commissioner', {
    templateUrl: 'es6/advice/commissioner.html',
    bindings: {
        entity: '<',
        role: '<?',
        hideHistory: '<?'
    },
    controllerAs: 'commissionerController',
    controller: function (Advice) {
        const commissionerController = this;

        commissionerController.$onInit = function () {
            loadData();
        };

        function loadData() {
            commissionerController.loading = true;
            Advice.query({
                entityType: commissionerController.entity.self.type,
                entityId: commissionerController.entity.id
            }).$promise.then((advices) => {
                commissionerController.advices = advices;
                if (commissionerController.hideHistory && commissionerController.advices.length > 1) {
                    commissionerController.advices = [_.head(advices)];
                }
            }).finally(() => {
                commissionerController.loading = false;
            });
        }
    }
});
