'use strict';

angular.module('uasApp').component('resourceTypeahead', {
  templateUrl: 'es6/inventory/resource/resource.typeahead.html',
  bindings: {
    inputName: '<',
    facultyId: '<',
    moduleId: '<',
    typeId: '<',
    uasRequired: '<?',
    inModal: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'typeaheadController',
  controller: function ($element, i18nFilter, Pageable, ResourceVersion, translateFilter) {
    const typeaheadController = this;

    typeaheadController.$onInit = function () {
      typeaheadController.module = true;
      typeaheadController.pageable = Pageable.of({
        order: 'externalId',
        pageSize: 15
      });

      setRender();
      typeaheadController.ngModelCtrl.$parsers.push((viewValue) => {
        return _.get(viewValue, 'id');
      });
    };

    function setRender() {
      typeaheadController.ngModelCtrl.$render = () => {
        if (typeaheadController.ngModelCtrl.$viewValue) {
          ResourceVersion.get({
            id: typeaheadController.ngModelCtrl.$viewValue
          }).$promise.then((version) => {
            typeaheadController.model = version;
          });
        } else {
          delete typeaheadController.model;
        }
      };
    }

    typeaheadController.getVersions = function (text) {
      const params = typeaheadController.pageable.build({
        facultyId: typeaheadController.faculty && !typeaheadController.global ? typeaheadController.facultyId : undefined,
        moduleId: typeaheadController.module && !typeaheadController.global ? typeaheadController.moduleId : undefined,
        typeId: typeaheadController.typeId,
        text: text || ''
      });

      typeaheadController.loading = true;
      return ResourceVersion.page(params).$promise.then((results) => {
        if (_.isEmpty(results.content)) {
          results.content.push({
            displayName: translateFilter('Static.Message.NoResultsFound'),
            noResults: true
          });
        }
        return results.content;
      }).finally(() =>
        typeaheadController.loading = false
      );
    };

    typeaheadController.displayName = function (version) {
      if (version) {
        if (version.noResults) {
          return version.displayName;
        }
        return _.truncate(`${version.externalId} - ${i18nFilter(version.resource.names)}`, {
          length: 100
        });
      }
    };

    typeaheadController.setFocus = function () {
      $element.find('input')[0].focus();
    };

    typeaheadController.onChange = function (value) {
      const noResults = _.get(value, 'noResults');
      if (!value || noResults) {
        delete typeaheadController.model;
        typeaheadController.ngModelCtrl.$setViewValue();
      } else {
        typeaheadController.ngModelCtrl.$setViewValue(angular.copy(value));
      }
    };
  }
});
