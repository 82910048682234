'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowList
 * @description
 * uasWorkflowList Displays list of workflows
 */
angular.module('uasApp')
  .component('uasWorkflowList', {
    templateUrl: 'es6/workflow/workflow.list.html',
    controllerAs: 'workflowListController',
    controller: function ($state, Language, Message, Pageable, Workflow, emptyToUndefinedFilter, enumFilter, i18nFilter) {
      const workflowListController = this;

      workflowListController.$onInit = function () {
        workflowListController.pageable = Pageable.of({
          order: 'translatedType'
        });

        loadData();
      };

      function loadData() {
        workflowListController.loading = true;
        Workflow.query().$promise.then((workflows) => {
          // Translate the title and type, so we can sort on the translated values.
          workflowListController.workflows = _.map(workflows, (workflow) => {
            workflow.code = emptyToUndefinedFilter(workflow.code);
            workflow.translatedType = enumFilter(workflow.type);
            workflow.translatedTitle = i18nFilter(workflow.titles);

            return workflow;
          });

          if (hasDefinedCodes(workflows)) {
            workflowListController.pageable.order = ['code', 'translatedType'];
          }
        }).finally(() => {
          workflowListController.loading = false;
        });
      }

      workflowListController.create = function () {
        startSetupWizard();
      };

      workflowListController.edit = function (workflow) {
        startSetupWizard(workflow.id);
      };

      function startSetupWizard(workflowId) {
        const stateParams = angular.isDefined(workflowId) ? { workflowId: workflowId } : {};
        $state.go('workflow-setup.base-settings', stateParams);
      }

      function hasDefinedCodes(workflows) {
        return _.some(workflows, (workflow) => {
          return _.has(workflow, 'code') && !_.isEmpty(workflow.code);
        });
      }

      workflowListController.remove = function (workflow) {
        Workflow.remove({ id: workflow.id }).$promise.then(() => {
          _.remove(workflowListController.workflows, (w) => w.id === workflow.id);
          Message.addSuccessLabel('Static.Message.WorkflowDeleted');
        });
      };

      Language.onChange(() => {
        const order = _.get(workflowListController, 'pageable.order');
        const orderIsTranslated = (order === 'translatedType') || (order === 'translatedTitle');

        if (orderIsTranslated && _.isArray(workflowListController.workflows)) {
          workflowListController.workflows = _.forEach(workflowListController.workflows, (workflow) => {
            workflow.translatedType = enumFilter(workflow.type);
            workflow.translatedTitle = i18nFilter(workflow.titles);
          });
        }
      });
    }
  });
