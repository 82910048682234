'use strict';

angular.module('uasApp').component('uasMoveModuleGroup', {
  bindings: {
    entity: '<',
    group: '<',
    parent: '<',
    study: '<',
    open: '<',
    onMove: '&',
    onClose: '&'
  },
  templateUrl: 'es6/planboard/move/move.module.group.html',
  controllerAs: 'moveModuleGroupController',
  controller: function () {
    const moveModuleGroupController = this;

    moveModuleGroupController.$onInit = function () {
      moveModuleGroupController.target = moveModuleGroupController.parent;
    };

    moveModuleGroupController.onGroup = function (group) {
      moveModuleGroupController.target = group;
    };

    moveModuleGroupController.onStudy = function () {
      delete moveModuleGroupController.target;
    };

    moveModuleGroupController.next = function () {
      moveModuleGroupController.selected = true;
    };

    moveModuleGroupController.back = function () {
      moveModuleGroupController.selected = false;
    };

    moveModuleGroupController.move = function () {
      moveModuleGroupController.onMove({
        targetId: _.result(moveModuleGroupController.target, 'id')
      });
    };

    moveModuleGroupController.close = function () {
      moveModuleGroupController.onClose();
    };
  }
});
