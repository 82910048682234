'use strict';

angular.module('uasApp')
    .component('offeringCreate', {
        bindings: {
            offering: '<',
            calendar: '<?',
            fields: '<',
            operations: '<',
            operationsToEdit: '<',
            onClose: '&',
            onSave: '&'
        },
        templateUrl: 'es6/offerings/offering.create.html',
        controllerAs: 'offeringCreateController',
        controller: function(Period, TimeBlock, CustomField) {
            const offeringCreateController = this;

            offeringCreateController.$onInit = function() {
                offeringCreateController.ribbon = false;
                offeringCreateController.selected = [];
                loadData();
            };

            function loadData() {
                Period.query({
                    calendarId: _.result(offeringCreateController.calendar, 'id'),
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((periods) => {
                    offeringCreateController.periods = _(periods).filter({ 
                        selectable: true
                    }).sortBy(['sequence', 'code']).value();

                    autoSelectPeriod();
                });
            }

            function autoSelectPeriod() {
                if (offeringCreateController.periods.length === 1) {
                    const periodId = _(offeringCreateController.periods).map('id').head();
                    offeringCreateController.offering.periodId = periodId;
                    offeringCreateController.onPeriod(periodId);
                    setDirty();
                } else {
                    setEvaluation();
                }
            }

            function setDirty() {
                _.set(offeringCreateController.createOfferingForm, '$pristine', false);
            }

            offeringCreateController.onPeriod = function(periodId) {
                offeringCreateController.selected = [];
                
                const period = _.find(offeringCreateController.periods, { id: periodId });
                offeringCreateController.period = period;

                if (angular.isDefined(period)) {
                    TimeBlock.query({
                        calendarId: period.calendarId
                    }).$promise.then((blocks) => {
                        offeringCreateController.blocks = _(blocks).filter((block) => {
                            return block.period >= period.start;
                        }).sortBy(['sequence', 'code']).value();

                        autoSelectTimeBlock();
                    });
                } else {
                    offeringCreateController.selectable = [];
                    offeringCreateController.blocks = [];
                }

                setEvaluation();
            };

            function autoSelectTimeBlock() {
                if (offeringCreateController.blocks.length === 1 && offeringCreateController.fields.blockIds.visible) {
                    offeringCreateController.block = _.head(offeringCreateController.blocks);
                    offeringCreateController.add(offeringCreateController.block);
                    setDirty();
                }
            }

            offeringCreateController.add = function(block) {                
                if (angular.isDefined(block)) {
                    _.remove(offeringCreateController.selected, { id: block.id });

                    const result = _.concat(offeringCreateController.selected, [block]);
                    offeringCreateController.selected = _.sortBy(result, ['sequence']);
                    offeringCreateController.ribbon = true;

                    setEvaluation();
                }
            };

            offeringCreateController.remove = function(block) {
                _.remove(offeringCreateController.selected, { id: block.id });

                if (_.isEmpty(offeringCreateController.selected)) {
                    offeringCreateController.ribbon = false;
                }

                setEvaluation();
            };

            offeringCreateController.isPartOfDayVisible = function () {
                const blocks = angular.copy(offeringCreateController.selected);
                if (angular.isDefined(offeringCreateController.block)) {
                    blocks.push(offeringCreateController.block);
                }
                return _.uniqBy(blocks, 'partOfDayId').length > 1;
            };

            offeringCreateController.save = function() {
                offeringCreateController.add(offeringCreateController.block);

                const body = _.extend(offeringCreateController.offering, {
                    period: offeringCreateController.period,
                    blocks: angular.copy(offeringCreateController.selected),
                    blockIds: _.map(offeringCreateController.selected, 'id')
                });

                offeringCreateController.onSave({
                    offering: body,
                    message: offeringCreateController.message
                });
            };

            offeringCreateController.close = function() {
                offeringCreateController.onClose();
            };

            offeringCreateController.onChange = function() {
                setEvaluation();
            };

            function setEvaluation() {
                offeringCreateController.evaluation = CustomField.getEvaluation(
                    offeringCreateController.offering.entity, 
                    offeringCreateController.offering, 
                    offeringCreateController.fields
                );
            }
        }
    });