'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:changeReportPage
 * @description
 * changeReportPage Change report page
 */
angular.module('uasApp').component('changeReportPage', {
  bindings: {
    entity: '<',
    operations: '<',
    page: '<'
  },
  templateUrl: 'es6/changes/change.report.page.html',
  controllerAs: 'changeReportPageController'
});
