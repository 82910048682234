'use strict';

angular.module('uasApp').component('specificationFields', {
  bindings: {
    specification: '<',
    operations: '<'
  },
  templateUrl: 'es6/specification/specification.fields.html',
  controllerAs: 'specificationFieldsController',
  controller: function (CustomField) {
    const specificationFieldsController = this;

    specificationFieldsController.$onInit = function () {
      loadData();
    };

    function loadData() {
      specificationFieldsController.loading = true;
      CustomField.query({
        rootType: 'specification',
        entityType: 'specification'
      }).$promise.then((fields) => {
        CustomField.hide(fields, ['publicationDate']);
        specificationFieldsController.fields = fields;
        specificationFieldsController.setEvaluation();
      }).finally(() => {
        specificationFieldsController.loading = false;
      });
    }

    specificationFieldsController.setEvaluation = function () {
      const entity = {
        type: 'specification',
        id: _.result(specificationFieldsController.specification, 'id')
      };

      specificationFieldsController.evaluation = CustomField.getEvaluation(entity, specificationFieldsController.specification, specificationFieldsController.fields);
    };
  }
});
