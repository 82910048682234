'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ReferenceType
 * @description
 * The ReferenceType service.
 */
angular.module('uasApp').factory('ReferenceType', function (UasResource) {
  return UasResource('/reference-types').withCache('reference-types');
});
