'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:qualificationAdmin
 * @description
 * Manage qualification data
 */
angular.module('uasApp').component('qualificationAdmin', {
    bindings: {
        qualification: '<',
        operations: '<'
    },
    templateUrl: 'es6/qualification/qualification.admin.html',
    controllerAs: 'qualificationAdminController',
    controller: function ($q, $state, CustomField, EntityType, FeedbackService, Message, Qualification) {
        const qualificationAdminController = this;

        qualificationAdminController.$onInit = function () {
            qualificationAdminController.qualification_ = angular.copy(qualificationAdminController.qualification);
            loadData();
        };

        function loadData() {
            qualificationAdminController.loading = true;
            $q.all([
                CustomField.query({
                    rootType: 'qualification',
                    entityType: 'qualification'
                }).$promise,
                EntityType.get({
                    rootType: 'qualification',
                    entityType: 'qualification'
                }).$promise
            ]).then(([fields, customType]) => {
                qualificationAdminController.fields = _.keyBy(fields, 'name');
                qualificationAdminController.customType = customType;
            }).finally(() => {
                qualificationAdminController.loading = false;
            });
        }

        qualificationAdminController.save = function (qualification, comment, form) {
            const body = {
                comment: comment,
                content: qualification
            };

            return Qualification.update(body).$promise.then((result) => {
                form.$setPristine();
                FeedbackService.onUpdate(qualificationAdminController.qualification_);
                return result;
            });
        };

        qualificationAdminController.delete = function (qualification, comment) {
            const body = {
                comment: comment,
                id: qualification.id
            };

            Qualification.remove(body).$promise.then(() => {
                Message.onRemoved();
                $state.reload();
            });
        };

        qualificationAdminController.deleteFull = function (qualification) {
            Qualification.deleteFull({
                id: qualification.id
            }).$promise.then(() => {
                Message.onRemoved();

                qualificationAdminController.adminForm.$setPristine();
                $state.go('dashboard');
            });
        };
    }
});