'use strict';

/**
 * @ngdoc filter
 * @name uasApp.filter:sum
 * @function
 * @description Sums all values of a given property, including nested properties.
 *
 * @param {Array} items The input data that needs to be summed.
 * @param {String} prop The property name that needs to be summed.
 * @return {Number} The total count of the specified property.
 */
angular.module('uasApp').filter('sum', function () {
  function countAmount(item, prop) {
    const propertyValue = _.get(item, prop);
    let count = 0;

    if (_.isArray(propertyValue)) {
      count = propertyValue.length;
    }

    if (item.children) {
      for (const child of item.children) {
        count += countAmount(child, prop);
      }
    }

    return count;
  }

  return function (items, prop) {
    return _.sumBy(items, function (item) {
      return countAmount(item, prop);
    });
  };
});
