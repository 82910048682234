'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowWizard
 * @description
 * uasWorkflowWizard Shows the workflow wizard
 */
angular.module('uasApp')
  .component('uasWorkflowWizard', {
    bindings: {
      entity: '<',
      participant: '<',
      assignee: '<?',
      participants: '<?',
      process: '<?',
      workflow: '<',
      page: '<',
      currentStep: '<?',
      operations: '<',
      onStep: '&',
      onComplete: '&',
      onDelete: '&',
      onError: '&',
      onBeforeComplete: '&?'
    },
    templateUrl: 'es6/workflow/workflow.wizard.html',
    controllerAs: 'workflowController',
    controller: function ($scope, $timeout, $uibModal, Flow, Participant, Workflow, WorkflowEvaluator, WorkflowValidator,
        Process, Message, Language, Feedback, Changes) {

        const workflowController = this;

        workflowController.$onInit = function() {
            workflowController.evaluation = {};
            workflowController.flow = Flow.build();

            $scope.$on('$stateChangeSuccess', function(event, toState) {
                if (toState.name === 'workflow') {
                    setCurrent();
                    updateSteps();
                }
            });

            $scope.$on('beforeCompleteWorkflow', function(event, onSuccess) {
                $timeout(() => {
                    if (!event.defaultPrevented) {
                        onSuccess();
                    }
                }, 0);
            });
        };

        workflowController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'workflow') || Changes.hasChanged(changes, 'currentStep')) {
                if (angular.isDefined(workflowController.workflow)) {
                    workflowController.pages = _.forEach(workflowController.workflow.pages, (page) => page.order = page.index);

                    setConditionalSteps().then(() => {
                        setVisibleSteps();
                        setCurrent();
                        updateSteps();
                    });

                    if (!_.isEmpty(workflowController.workflow.video)) {
                        workflowController.video = _.find(workflowController.workflow.video.languages, {
                            language: Language.get()
                        });
                    }
                }
            }
        };

        function setVisibleSteps() {
            workflowController.steps = _(workflowController.pages)
                .filter((step) => angular.isUndefined(step.visible) || step.visible === true)
                .sortBy('order')
                .forEach((step, $index) => step.index = $index);
        }

        function setCurrent() {
            WorkflowValidator.setValidity();

            if (angular.isDefined(workflowController.currentStep)) {
                workflowController.current = _.find(workflowController.steps, { id: workflowController.currentStep });
            }
            if (!workflowController.current) {
                workflowController.current = _.head(workflowController.steps);
            }
        }

        function updateSteps() {
            updateCodeChanges();

            _.each(workflowController.steps, function (step, $index) {
                step.active = $index === workflowController.current.index;
                step.done = $index < workflowController.current.index;
                step.clickable = (!step.active && $index <= workflowController.participant.maxIndex) || workflowController.workflow.clickableSteps === true;
                step.visible = true;
            });

            Process.outcomes({
                id: workflowController.process.id,
                participantId: workflowController.participant.id
            }).$promise.then((result) => {
                workflowController.successes = _.filter(result.outcomes, { success: true });
                workflowController.failures = _.filter(result.outcomes, { success: false });
                workflowController.outcomes = result.outcomes;
                workflowController.completable = result.completable;
            });
        }

        function setConditionalSteps() {
            return WorkflowEvaluator.getValues(workflowController.pages, workflowController.entity).then((values) => {
                return workflowController.onEvaluate({
                    entity: workflowController.entity.self,
                    values
                });
            });
        }

        function updateCodeChanges() {
            workflowController.codeChanges = Feedback.code({
                entityType: workflowController.entity.self.type,
                entityId: workflowController.entity.self.id,
                language: Language.get()
            });
        }

        workflowController.disableNext = function () {
            return !WorkflowValidator.isValid();
        };

        workflowController.hasNext = function() {
            const currentIndex = _.get(workflowController.current, 'index', 0);
            const length = _.get(workflowController.steps, 'length');
            return currentIndex < (length - 1);
        };

        workflowController.next = function () {
            if (workflowController.flow.proceed()) {
                WorkflowValidator.save(() => {
                    workflowController.goTo(getNextStep());
                });
            }
        };

        function getNextStep() {
            const nextStep = workflowController.steps[workflowController.current.index + 1];
            if ((workflowController.current.route === 'expire' && workflowController.entity.terminated) || !nextStep) {
                return _.last(workflowController.steps);
            }
            return nextStep;
        }

        workflowController.disablePrevious = function () {
            return !WorkflowValidator.isValid();
        };

        workflowController.showPrevious = function() {
            const currentIndex = _.get(workflowController.current, 'index', 0);
            return currentIndex > 0;
        };

        workflowController.previous = function () {
            WorkflowValidator.save(() => {
                workflowController.goTo(getPreviousStep());
            });
        };

        function getPreviousStep() {
            let index = workflowController.current.index - 1;
            let previousStep = workflowController.steps[index];

            while (!previousStep.visible && index > 0) {
                index--;
                previousStep = workflowController.steps[index];
            }

            if (!previousStep) {
                previousStep = _.head(workflowController.steps);
            }
            return previousStep;
        }

        workflowController.onTab = function (tab, event) {
            checkForUnsavedChanges(event, () => {
                workflowController.goTo(tab);
            });
        };

        function checkForUnsavedChanges(event, callback) {
            if (!event) {
                callback();
            } else {
                WorkflowValidator.checkForUnsavedChanges(event, () => {
                    callback();
                });
            }
        }

        workflowController.goTo = function (step) {
            const index = _.indexOf(workflowController.steps, step);

            updateProgress(index).$promise.then(() => {
                workflowController.onStep({ pageId: step.id });
            }, () => {
                workflowController.onError();
            });
        };

        workflowController.onEvaluate = function (evaluation) {
            setEvaluation(evaluation);
            
            return WorkflowEvaluator.evaluateAll(workflowController.pages, workflowController.evaluation).then(() =>
                setVisibleSteps()
            );
        };

        function setEvaluation(evaluation) {
            _.extend(workflowController.evaluation, evaluation);
            _.set(workflowController.evaluation, 'values.operations', workflowController.operations);
        }

        workflowController.assign = function () {
            const personId = _.get(workflowController.assignee, 'id');
            Workflow.assign({
                id: workflowController.participant.id
            }, { personId });
        };

        function updateProgress(index) {
            return Workflow.updateProgress({
                id: workflowController.participant.id
            }, { index });
        }

        workflowController.reject = function(outcomes) {
            complete(false, outcomes);
        };

        workflowController.approve = function(outcomes) {
            complete(true, outcomes);
        };

        function complete(success, outcomes) {
            if (_.isFunction(workflowController.onBeforeComplete)) {
                workflowController.onBeforeComplete({ callback: () => openComplete(success, outcomes) });
            } else {
                openComplete(success, outcomes);
            }
        }

        function openComplete(success, outcomes) {
            $uibModal.open({
                templateUrl: 'es6/workflow/workflow.complete.modal.html',
                controllerAs: 'workflowCompleteController',
                controller: function ($uibModalInstance) {
                    const workflowCompleteController = this;

                    workflowCompleteController.workflow = workflowController.workflow;
                    workflowCompleteController.entity = workflowController.entity;
                    workflowCompleteController.outcomes = outcomes;
                    workflowCompleteController.outcome = _.head(outcomes);
                    workflowCompleteController.success = success === true;

                    workflowCompleteController.submit = function () {
                        $uibModalInstance.dismiss();
                        workflowController.submit(workflowCompleteController.outcome, workflowCompleteController.message);
                    };
                }
            });
        }

        workflowController.complete = function() {
            WorkflowValidator.save(() => {
                workflowController.loading = true;
                Participant.complete({
                    id: workflowController.participant.id
                }, {}).$promise.then(() => {
                    Message.addSuccessLabel('Static.Message.CompletedWorkflow');
                    workflowController.onComplete();
                }).finally(() => {
                    workflowController.loading = false;
                });
            });
        };

        workflowController.submit = function(outcome, message) {
            WorkflowValidator.save(() => {
                workflowController.loading = true;
                Workflow.complete({
                    id: workflowController.participant.id
                }, {
                    success: _.get(outcome, 'success') !== false,
                    status: outcome.next.id,
                    message: message
                }).$promise.then(() => {
                    Message.addSuccessLabel('Static.Message.CompletedWorkflow');
                    workflowController.onComplete();
                }).finally(() => {
                    workflowController.loading = false;
                });
            });
        };

        workflowController.delete = function() {
            workflowController.loading = true;
            Workflow.cancel({
                id: workflowController.participant.id
            }).$promise.then(() => {
                Message.addSuccessLabel('Static.Message.DataRemoved');
                workflowController.onDelete();
            }).finally(() => {
                workflowController.loading = false;
            });
        };

        workflowController.revertCode = function() {
            $uibModal.open({
                template: `
                    <uas-revert-code
                        feedback="codeChangesController.feedback"
                        entity="codeChangesController.entity"
                        uib-modal-instance="codeChangesController.uibModalInstance">
                    </uas-revert-code>`,
                controllerAs: 'codeChangesController',
                controller: function ($uibModalInstance) {
                    const codeChangesController = this;

                    codeChangesController.uibModalInstance = $uibModalInstance;
                    codeChangesController.feedback = workflowController.codeChanges;
                    codeChangesController.entity = workflowController.entity;
                }
            });
        };
    }
});
