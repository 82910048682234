'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowModuleResources
 * @description
 * uasWorkflowModuleResources Shows module resources
 */
angular.module('uasApp')
  .component('uasWorkflowModuleResources', {
    bindings: {
      module: '<',
      page: '<',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/module/workflow/workflow.module.resources.html',
    controllerAs: 'workflowModuleResourcesController',
    controller: function (Page) {
      const workflowModuleResourcesController = this;

      workflowModuleResourcesController.$onInit = function () {
        workflowModuleResourcesController.readOnly = workflowModuleResourcesController.isReadOnly
          || Page.getParameterAsBoolean(workflowModuleResourcesController.page, 'readOnly', false);
      };
    }
  });
