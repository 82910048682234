'use strict';

angular.module('uasApp').component('facultiesAssessments', {
  templateUrl: 'es6/faculties/faculties.assessments.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'assessmentsController'
});
