'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:exportRequests
 * @description
 * exportRequests Show export request(s)
 */
angular.module('uasApp')
  .component('exportRequests', {
    bindings: {
      export: '<'
    },
    templateUrl: 'es6/export/export.requests.html',
    controllerAs: 'exportRequestsController',
    controller: function(Pageable, RequestLog, RequestLogDetails) {
      const exportRequestsController = this;

      exportRequestsController.$onInit = function() {
        exportRequestsController.open = false;
        exportRequestsController.pageable = Pageable.of({
          order: 'requestDate',
          reverse: true
        });
      };

      exportRequestsController.toggle = function() {
        exportRequestsController.open = exportRequestsController.open !== true;
        if (exportRequestsController.open) {
          loadData();
        }
      };

      function loadData() {
        exportRequestsController.loading = true;
        RequestLog.get({
          correlationId: exportRequestsController.export.correlationId
        }).$promise.then((requests) => {
          exportRequestsController.requests = requests;
        }).finally(() => {
          exportRequestsController.loading = false;
        });
      }

      exportRequestsController.onPage = function() {
        loadData();
      };

      exportRequestsController.show = function(id) {
        RequestLog.get({ id }).$promise.then((requestLog) => {
          RequestLogDetails.open(requestLog);
        });
      };
    }
});
