'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusList
 * @description
 * Shows the list status history of an entity
 */
angular.module('uasApp').component('statusList', {
  bindings: {
    entity: '<',
    process: '<'
  },
  templateUrl: 'es6/status/status.list.html',
  controllerAs: 'statusListController',
  controller: function (Status) {
    const statusListController = this;

    statusListController.$onInit = function () {
        loadData();
    };

    function loadData() {
        if (statusListController.entity && statusListController.process) {
            Status.query({
              entityType: statusListController.entity.type,
              entityId: statusListController.entity.id
            }).$promise.then(function (statuses) {
              const processId = _.result(statusListController.process, 'id');
  
              statusListController.statuses = _(statuses).filter((status) => {
                const message = _.result(status, 'comment.message');
                return !_.isEmpty(message) && status.process === processId;
              }).sortBy('creationTime').value();
            });
        }
    }
  }
});
