'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:moduleGeneral
 * @description
 * Displays general module information
 */
angular.module('uasApp').component('moduleGeneral', {
    bindings: {
        moduleId: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/module/module.general.html',
    controllerAs: 'moduleGeneralController',
    controller: function ($state, Module, Faculty, Organisation, Study, CustomField, CustomPage, ModuleType) {
        const moduleGeneralController = this;

        moduleGeneralController.$onInit = () => {
            CustomField.query({
                rootType: 'module'
            }).$promise.then((fields) => {
                moduleGeneralController.fields = _.keyBy(fields, 'name');
            });

            CustomPage.query({
                basePath: 'module.general'
            }).$promise.then((pages) => {
                moduleGeneralController.pages = pages;
            });

            Module.get({
                id: moduleGeneralController.moduleId
            }).$promise.then((module) => {
                moduleGeneralController.module = module;
                
                moduleGeneralController.credits = {
                    minimum: module.minimumCredits,
                    maximum: module.maximumCredits,
                    optimum: module.optimumCredits
                };

                if (angular.isDefined(module.facultyId)) {
                    Faculty.get({
                        id: module.facultyId
                    }).$promise.then((faculty) => {
                        moduleGeneralController.faculty = faculty;
                    });
                }

                if (angular.isDefined(module.organisationId)) {
                    Organisation.get({
                        id: module.organisationId
                    }).$promise.then((organisation) => {
                        moduleGeneralController.organisation = organisation;
                    });
                }

                if (angular.isDefined(module.studyId)) {
                    Study.get({
                        id: module.studyId
                    }).$promise.then((study) => {
                        moduleGeneralController.study = study;
                    });
                }

                if (angular.isDefined(module.typeId)) {
                    ModuleType.get({
                        id: module.typeId
                    }).$promise.then((moduleType) => {
                        moduleGeneralController.moduleType = moduleType;
                    });
                }
            });
        };

        moduleGeneralController.onStatus = function () {
            $state.reload();
        };

        moduleGeneralController.onStudies = function(studies) {
            moduleGeneralController.hideUsedInStudies = _.isEmpty(studies);
        };

        moduleGeneralController.onPeriods = function(periods) {
            moduleGeneralController.hideOfferings = _.isEmpty(periods);
        };
    }
});
