'use strict';

/**
 * Filter that formats date with format configured in system parameter ui.dateformat.
 * If ui.dateformat is not defined the date is formatted with 'dd-MM-yyyy' pattern.
 * Usage:
 * {{ date | configDate }}
 */
angular.module('uasApp').filter('configDate', function($filter, $rootScope) {
    return function(date) {
        if (angular.isUndefined(date)) {
            return '';
        }

        let dateFormat = $rootScope.configDateFormat;
        if (_.isEmpty(dateFormat)) {
            dateFormat = 'dd-MM-yyyy';
        }

        return $filter('date')(date, dateFormat);

    };
});
