'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleRemove
 * @description
 * The component for removing a module from a module group.
 */
angular.module('uasApp').component('uasModuleRemove', {
  bindings: {
    module: '<',
    group: '<',
    entity: '<',
    studyId: '<',
    onCancel: '&',
    onChange: '&'
  },
  controllerAs: 'moduleRemoveController',
  templateUrl: 'es6/planboard/remove/module.remove.html',
  controller: function (Offering, Planning) {
    const moduleRemoveController = this;

    moduleRemoveController.$onInit = function() {
      if (moduleRemoveController.entity.owned === true) {
        Offering.scheduled({
          entityType: 'module',
          entityId: moduleRemoveController.module.id,
          active: true
        }).$promise.then((offerings) => {
          moduleRemoveController.offerings = offerings;
        });
      }
    };

    moduleRemoveController.cancel = function() {
        moduleRemoveController.onCancel();
    };

    moduleRemoveController.removeFromGroup = function() {
      const model = {
        studyId: moduleRemoveController.studyId,
        modules: [{
          id: moduleRemoveController.entity.id,
          moduleGroupId: moduleRemoveController.group.id,
          moduleId: moduleRemoveController.module.id,
          remove: true
        }]
      };

      return save(model);
    };

    moduleRemoveController.removeOffering = function(offering) {
      const model = {
        studyId: moduleRemoveController.studyId,
        offerings: [{
          id: offering.id,
          moduleId: moduleRemoveController.module.id,
          periodId: offering.period.id,
          remove: true
        }]
      };

      return save(model);
    };

    moduleRemoveController.terminateModule = function() {
      const model = {
        studyId: moduleRemoveController.studyId,
        modules: [{
          id: moduleRemoveController.entity.id,
          moduleGroupId: moduleRemoveController.group.id,
          moduleId: moduleRemoveController.module.id,
          terminated: true,
          remove: true
        }]
      };

      return save(model);
    };

    function save(model) {
      return Planning.save(model).$promise.then(() => moduleRemoveController.onChange());
    }
  }
});
