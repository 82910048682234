'use strict';

angular.module('uasApp').component('workflowBaseSettings', {
    templateUrl: 'es6/workflow/setup/workflow.setup.base-settings.html',
    bindings: {
        roleTypes: '<',
        videos: '<',
        workflow: '='
    },
    controllerAs: 'workflowBaseSettingsController',
    controller: function ($q, $scope, i18nFilter, workflowSetupIsDirtyChecker, workflowSetupWizardService, WorkflowValidator, Page, StatusType) {
        const workflowBaseSettingsController = this;

        workflowBaseSettingsController.$onInit = function () {
            workflowBaseSettingsController.forms = {};

            loadData();

            _.forEach(workflowBaseSettingsController.videos, (video) => {
                _.forEach(video.languages, (language) => {
                    language.value = language.name;
                });
            });

            WorkflowValidator.setValidity(() => workflowBaseSettingsController.settingsForm);

            $scope.$watch('workflowBaseSettingsController.settingsForm.$dirty', () => {
                if (workflowBaseSettingsController.settingsForm.$dirty) {
                    workflowSetupIsDirtyChecker.flagDirty();
                }
            });
        };

        function loadData() {
            $q.all([
                loadPages(),
                loadStatuses()
            ]).then(([pages, statuses]) => {
                workflowBaseSettingsController.pages = pages;
                workflowBaseSettingsController.statuses = statuses;
            });
        }

        function loadPages() {
            if (workflowBaseSettingsController.workflow.type) {
                return Page.query({
                    rootType: workflowBaseSettingsController.workflow.type
                }).$promise.then((pages) => {
                    return _(pages)
                        .map((page) => {
                            page.displayName = i18nFilter(page.titles);
                            return page;
                        })
                        .sortBy('displayName')
                        .value();
                });
            }
            return $q.resolve();
        }

        function loadStatuses() {
            if (angular.isDefined(workflowBaseSettingsController.workflow.id)) {
                return StatusType.query({
                    workflowId: workflowBaseSettingsController.workflow.id
                }).$promise;
            }
            return $q.resolve();
        }

        workflowBaseSettingsController.onType = function () {
            delete workflowBaseSettingsController.workflow.pages;
            workflowSetupWizardService.setAdditionalSteps($scope, workflowBaseSettingsController.workflow.pages);

            loadPages().then((pages) => {
                workflowBaseSettingsController.pages = pages;
            });
        };

        workflowBaseSettingsController.onShowCompleted = function () {
            if (!workflowBaseSettingsController.workflow.showCompleted) {
                delete workflowBaseSettingsController.workflow.completedPage;
            }
        };

        workflowBaseSettingsController.addRelation = function () {
            getRelations().push({});
            workflowSetupIsDirtyChecker.flagDirty();
        };

        workflowBaseSettingsController.removeRelation = function (index) {
            getRelations().splice(index, 1);
            workflowSetupIsDirtyChecker.flagDirty();
        };

        function getRelations() {
            if (angular.isUndefined(workflowBaseSettingsController.workflow.relations)) {
                workflowBaseSettingsController.workflow.relations = [];
            }
            return workflowBaseSettingsController.workflow.relations;
        }
    }
});
