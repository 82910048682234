'use strict';

angular.module('uasApp').component('personRelations', {
  bindings: {
    person: '<'
  },
  templateUrl: 'es6/person/relations/person.relations.html',
  controllerAs: 'personRelationsController',
  controller: function (Language, Pageable, Relation, entityTranslateFilter) {
    const personRelationsController = this;

    personRelationsController.$onInit = function () {
      personRelationsController.pageable = Pageable.of({
        order: 'translatedRole'
      });

      loadData();
    };

    function loadData() {
      personRelationsController.loading = true;

      Relation.query({
        personId: personRelationsController.person.id,
        academicYearId: sessionStorage.academicYear
      }).$promise.then((relations) => {
        personRelationsController.relations = _.map(relations, (relation) => {
          relation.translatedRole = entityTranslateFilter(relation.role);
          relation.teamType = _.get(relation, 'target.type') !== 'team' && _.isEmpty(_.property('target.code'));
          return relation;
        });
      }).finally(() => {
        personRelationsController.loading = false;
      });
    }

    Language.onChange(() => {
      if (_.get(personRelationsController, 'pageable.order') === 'translatedRole' && _.isArray(personRelationsController.relations)) {
        personRelationsController.relations = _.forEach(personRelationsController.relations, (relation) => {
          relation.translatedRole = entityTranslateFilter(relation.role);
        });
      }
    });
  }
});
