'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalEdit
 * @description
 * appraisalEdit Edits an appraisal.
 */
angular.module('uasApp').component('appraisalEdit', {
    bindings: {
        appraisal: '<',
        fields: '<',
        operations: '<',
        operationsToEdit: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/assessment/appraisal/appraisal.edit.html',
    controllerAs: 'appraisalEditController',
    controller: function (Appraisal) {
        const appraisalEditController = this;

        appraisalEditController.save = function (content, comment) {
            Appraisal.save({
                content,
                comment
            }).$promise.then((result) => {
                appraisalEditController.onSave({ content, result });
            });
        };

        appraisalEditController.close = function() {
            appraisalEditController.onClose();
        };
    }
});