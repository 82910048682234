'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ChangeExportCtrl
 * @description # ChangeExportCtrl
 */
angular.module('uasApp')
  .controller('ChangeExportCtrl', function($scope, $uibModalInstance,
    Feedback, Language, Export, ExportDetails, Change, Message, CustomPage, EntityTypes,
    systemId, entries, loadChangesByRoot, onExport) {

    $scope.running = false;
    $scope.entries = _.sortBy(entries, ['entity.code', 'entity.id']);

    $scope.load = function (entry) {
      return loadAllChanges(entry).then((entity) => {
        $scope.entity = entity.entity;
        $scope.changes = _.orderBy(entity.changes, ['isRoot', 'sequence', 'entity.type', 'merged.creationTime'], ['desc', 'asc', 'asc', 'desc']);

        $scope.strategies = getStrategies($scope.changes);
        $scope.setStrategy();
      });
    };

    function getStrategies(changes) {
      const groupedByStrategies = _.groupBy(changes, 'strategy');
      const names = _.keys(groupedByStrategies);

      return _(names).map((name) => {
        return {
          name,
          numberOfChanges: groupedByStrategies[name].length
        };
      }).sortBy(['name', 'numberOfChanges']).value();
    }

    goToFirstEntry();

    CustomPage.query({
      basePath: 'changes.export'
    }).$promise.then((pages) => {
      $scope.pages = pages;
    });

    EntityTypes.load().then((entityTypes) => {
      $scope.entityTypes = entityTypes;
    });

    function goToFirstEntry() {
      if ($scope.entries.length > 0) {
        $scope.load($scope.entries[0]);
      }
    }

    function loadAllChanges(entry) {
      const copied = angular.copy(entry);
      if (angular.isUndefined(copied.changes)) {
        copied.changes = [];
      }

      return loadChangesByRoot(copied).then((result) => {
        const entityType = _.result(result, 'entity.self.type');
        _.each(result.changes, (change) => {
          if (change.entity.type === entityType) {
            change.externalId = result.entity.externalId;
            change.code = result.entity.code;
          }

          loadChange(change, entityType);
          copied.changes.push(change);
        });
        return copied;
      });
    }

    function loadChange(change, entityType) {
      if (angular.isUndefined(change.selected)) {
        const merged = change.merged;

        change.isRoot = merged.entity.type === entityType;
        change.selected = _.result(change, 'strategy') === 'AUTOMATIC' || (merged.type === 'CREATE' && change.isRoot === true);

        change.loading = true;

        Feedback.get({
          minChangeId: change.minChangeId,
          maxChangeId: change.maxChangeId,
          language: Language.get()
        }).$promise.then((feedback) => {
          change.feedback = feedback;
          change.type = change.feedback.type || change.merged.type;
        }).finally(() => {
          change.loading = false;
        });
      }
    }

    $scope.setStrategy = function (strategy) {
      $scope.strategy = strategy;

      _.each($scope.changes, (change) => {
        change.visible = hasStrategy(change);
      });

      $scope.onSelect();
    };

    function hasStrategy(change) {
      if (!$scope.strategy) {
        return true;
      }

      return change.strategy === $scope.strategy;
    }

    $scope.onSelect = function () {
      const visible = _.filter($scope.changes, { visible: true });
      $scope.amount = _.filter(visible, { selected: true }).length;
      $scope.selected = $scope.amount === visible.length;
    };

    $scope.selectAll = function () {
      const selected = $scope.selected !== true;

      _.each($scope.changes, (change) => {
        if (change.visible) {
          change.selected = selected;
        }
      });

      $scope.onSelect();
    };

    $scope.export = function (strategy) {
      const params = {
        systemId: systemId,
        root: $scope.entity.self,
        academicYearId: sessionStorage.academicYear,
        entities: getSelectedEntities(),
        strategy
      };

      $scope.running = true;
      Export.submit(params).$promise.then((lines) => {
        $scope.lines = lines;

        ExportDetails.show($scope.lines);

        const completed = _.every($scope.lines, { status: 'COMPLETED' });
        if (completed) {
          onExport($scope.lines);

          _.remove($scope.changes, { selected: true });
          if ($scope.changes.length === 0) {
            if ($scope.entries.length > 1) {
              _.remove($scope.entries, (row) => _.isEqual(row.entity.self, $scope.entity.self));
              goToFirstEntry();
            } else {
              $uibModalInstance.dismiss();
            }
          } else {
            $scope.strategies = getStrategies($scope.changes);
            $scope.onSelect();
          }
        }
      }).finally(() => {
        $scope.running = false;
      });
    };

    function getSelectedEntities() {
      return _($scope.changes).filter({
        selected: true,
        visible: true
      }).map((change) => {
        return {
          reference: change.entity,
          minChangeId: change.minChangeId,
          maxChangeId: change.maxChangeId,
          externalId: change.externalId,
          code: change.code
        };
      }).value();
    }

    $scope.reject = function (change) {
      Change.reject({
        entityType: change.entity.type,
        entityId: change.entity.id,
        academicYearId: sessionStorage.academicYear
      }).$promise.then(() => {
        Message.addInfoLabel('Static.Message.RejectedChanges');
        remove(change);
      });
    };

    $scope.onCustomMessageLoaded = function (change) {
      change.hasCustomMessage = true;
      change.hideDetails = true;
    };

    $scope.showDetails = function (change) {
      change.hideDetails = false;
    };

    $scope.approve = function (change) {
      Change.approve({
        entityType: change.entity.type,
        entityId: change.entity.id,
        academicYearId: sessionStorage.academicYear
      }).$promise.then(() => {
        Message.addInfoLabel('Static.Message.ApprovedChanges');
        remove(change);
      });
    };

    function remove(change) {
      _.remove($scope.changes, { entity: change.entity });

      if ($scope.changes.length === 0) {
        $uibModalInstance.dismiss();
      } else {
        $scope.strategies = getStrategies($scope.changes);
        $scope.onSelect();
      }
    }
  });
