'use strict';

angular.module('uasApp')
    .directive('saveWithComment', function() {
        return {
            restrict: 'E',
            templateUrl: 'es6/app/forms/buttons/save.with.comment.html',
            bindToController: {
                content: '&',
                callback: '=',
                form: '<',
                commentType: '<?',
                entityType: '@',
                rootType: '@',
                btnLabel: '@?',
                btnIcon: '@?',
                unchanged: '<?'
            },
            controllerAs: 'saveWithCommentController',
            controller: function(EntityType, CommentModal, $q) {
                const saveWithCommentController = this;

                saveWithCommentController.startSave = function(event) {
                    event.preventDefault();
                    event.stopPropagation();

                    // Prevents IE from firing when form is invalid or unchanged.
                    if (saveWithCommentController.form && (
                        saveWithCommentController.form.$pristine ||
                        saveWithCommentController.form.$invalid ||
                        saveWithCommentController.unchanged)
                    ) {
                        return;
                    }

                    getCommentType().then((commentType) => {
                        if (commentType === 'NEVER') {
                            performSave(
                                saveWithCommentController.content(),
                                saveWithCommentController.form,
                                saveWithCommentController.callback,
                                ''
                            );
                        } else {
                            CommentModal.openModal(
                                saveWithCommentController.content(),
                                saveWithCommentController.callback,
                                commentType,
                                saveWithCommentController.form
                            );
                        }
                    });
                };

                function getCommentType() {
                    if (saveWithCommentController.commentType) {
                        return $q.resolve(saveWithCommentController.commentType);
                    }

                    return EntityType.get({
                        rootType: saveWithCommentController.rootType,
                        entityType: saveWithCommentController.entityType
                    }).$promise.then((customType) => {
                        return _.result(customType, 'commentType', 'NEVER');
                    });
                }

                function performSave(data, form, callback, message) {
                    const body = {
                        message: message
                    };

                    if (!_.isEmpty(data)) {
                        callback(data, body, form);
                    }
                    if (form && form.$setPristine) {
                        form.$setPristine();
                    }
                }
            }
        };
    });
