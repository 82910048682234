'use strict';

angular.module('uasApp').component('expressionEvaluate', {
  bindings: {
    expression: '@',
    onClose: '&'
  },
  templateUrl: 'es6/expression/expression.evaluate.html',
  controllerAs: 'expressionEvaluateController',
  controller: function (Expression) {
    const expressionEvaluateController = this;

    expressionEvaluateController.$onInit = function() {
      expressionEvaluateController.arguments = [];
    };

    expressionEvaluateController.evaluate = function() {
      let values = {};
      _.forEach(expressionEvaluateController.arguments, (argument) => {
        if (argument.value) {
          values[argument.name] = argument.value;
        }
      });

      expressionEvaluateController.loading = true;
      Expression.evaluate({
          expression: expressionEvaluateController.expression,
          entity: _.result(expressionEvaluateController.entity, 'self'),
          academicYearId: sessionStorage.academicYear,
          values
      }).$promise.then((result) => {
          expressionEvaluateController.result = result;
          setProperties(result.properties);
      }).finally(() => {
          expressionEvaluateController.loading = false;
      });
    };

    function setProperties(properties) {
      _.forEach(properties, (value, name) => {
        let argument = _.find(expressionEvaluateController.arguments, { name });
        if (angular.isUndefined(argument)) {
          argument = { name };
          expressionEvaluateController.arguments.push(argument);
        }

        argument.placeholder = value;
      });
    }

    expressionEvaluateController.onEntity = function(entity) {
      expressionEvaluateController.entity = entity;
    };

    expressionEvaluateController.close = function() {
      expressionEvaluateController.onClose();
    };
  }
});