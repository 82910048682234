'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodTree
 * @description
 * Displays a list of module methods.
 */
angular.module('uasApp').component('methodTree', {
  bindings: {
    module: '<',
    period: '<?',
    operations: '<',
    isReadOnly: '<?',
    inactive: '<?',
    navigate: '<?',
    workflowMode: '<?',
    childPage: '<?',
    columns: '<?'
  },
  templateUrl: 'es6/methods/schema/method.tree.html',
  controllerAs: 'methodTreeController',
  controller: function ($q, AcademicPeriod, SecurityService, Method, MethodSchema, Category, MethodType, WorkflowValidator, activeFilter) {
    const methodTreeController = this;

    methodTreeController.$onInit = function () {
      methodTreeController.operationsToEdit = methodTreeController.workflowMode === true ? 'EDIT_METHODS_WORKFLOW' : 'EDIT_METHODS';
      const allowed = SecurityService.isAllowed(methodTreeController.operationsToEdit, methodTreeController.operations);

      methodTreeController.periodId = ''; // Force trigger ng-change on undefined
      methodTreeController.editable = methodTreeController.isReadOnly !== true && allowed;
      methodTreeController.active = methodTreeController.inactive !== true;

      WorkflowValidator.setValidity(() => {
        return _.result(methodTreeController.validation, 'valid', true) === true;
      });
    };

    methodTreeController.$onDestroy = function () {
      WorkflowValidator.reset();
    };

    function loadData() {
      setOpened();

      methodTreeController.loading = true;
      $q.all([
        MethodSchema.query({
          entityType: methodTreeController.module.self.type,
          entityId: methodTreeController.module.id
        }).$promise,
        Method.validate({
          entityType: methodTreeController.module.self.type,
          entityId: methodTreeController.module.id,
          periodId: _.result(methodTreeController.selectedPeriod, 'id')
        }).$promise,
        Category.query({
          rootType: 'METHOD'
        }).$promise,
        MethodType.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([schemas, validation, categories, types]) => {
        methodTreeController.validation = validation;
        methodTreeController.categories = _.filter(categories, { exam: false });
        methodTreeController.types = types;

        const nodes = buildAll(schemas, 0, true);
        _.forEach(methodTreeController.categories, (category) => {
          category.schemas = _.filter(nodes, (node) => {
            const categoryId = _.get(node, 'type.categoryId');
            return angular.isUndefined(categoryId) || categoryId === category.id;
          });
        });
      }).finally(() => {
        methodTreeController.loading = false;
      });
    }

    function setOpened() {
      const schemas = _(methodTreeController.categories)
        .map('schemas')
        .flatten()
        .value();
      methodTreeController.opened = getOpened(schemas);
    }

    function getOpened(schemas) {
      return _(schemas)
        .filter((schema) => schema.open)
        .transform((result, schema) => result.push(schema, ...getOpened(schema.children)), [])
        .value();
    }

    function buildAll(schemas, level, editable) {
      return _(schemas)
        .map((schema) => build(schema, level, editable))
        .sortBy(['sequence', 'type.sequence', 'id'])
        .value();
    }

    function build(schema, level, editable) {
      const method = schema.method;

      const result = angular.copy(schema);
      result.level = level;
      result.owned = _.get(method, 'moduleId') === methodTreeController.module.id;
      result.editable = editable && schema.changeType !== 'REMOVE';

      if (angular.isDefined(method)) {
        result.type = _.find(methodTreeController.types, { id: _.get(method, 'typeId') }) || {};
        result.container = _.get(result.type, 'container', false);
      }

      result.open = isOpen(result);
      result.children = [];
      result.validations = getValidations(schema);
      return result;
    }

    function isOpen(schema) {
      const found = _.find(methodTreeController.opened, { id: schema.id, level: schema.level });
      if (found) {
        methodTreeController.toggle(schema);
      }
      return angular.isDefined(found);
    }

    function getValidations(schema) {
      const validations = [];
      validations.push(_.find(methodTreeController.validation.entities, { entity: schema.self }));
      if (angular.isDefined(schema.method)) {
        validations.push(_.find(methodTreeController.validation.entities, { entity: schema.method.self }));
      }
      return validations;
    }

    methodTreeController.toggle = function (schema) {
      if (angular.isDefined(schema)) {
        schema.open = schema.open !== true;

        if (!schema.loaded) {
          return loadChildren(schema).then(() => {
            setOpened();
          });
        }
      }
      return $q.resolve(schema);
    };

    function loadChildren(schema) {
      if (schema.container) {
        schema.loading = true;

        return MethodSchema.query({
          entityType: 'method',
          entityId: schema.method.id
        }).$promise.then((children) => {
          schema.children = buildAll(children, schema.level + 1, schema.owned);
          return schema;
        }).finally(() => {
          schema.loading = false;
          schema.loaded = true;
        });
      } else {
        return $q.resolve(schema);
      }
    }

    methodTreeController.onChange = function (parent) {
      if (angular.isUndefined(parent)) {
        loadData();
      } else {
        validate().then(() => {
          parent.validations = getValidations(parent);
          loadChildren(parent);
        });
      }
    };

    function validate() {
      return Method.validate({
        entityType: methodTreeController.module.self.type,
        entityId: methodTreeController.module.id,
        periodId: _.result(methodTreeController.selectedPeriod, 'id')
      }).$promise.then((validation) => {
        methodTreeController.validation = validation;

        _.forEach(methodTreeController.categories, (category) => {
          _.forEach(category.schemas, (schema) => {
            schema.validations = getValidations(schema);
          });
        });
      });
    }

    methodTreeController.onPeriod = function () {
      AcademicPeriod.find(methodTreeController.periodId).$promise.then((period) => {
        methodTreeController.selectedPeriod = period;
        loadData();
      });
    };

    methodTreeController.expandAll = function (expanded) {
      _.forEach(methodTreeController.categories, (category) => {
        _.forEach(category.schemas, (schema) => {
          expand(schema, expanded);
        });
      });
    };

    function expand(schema, expanded) {
      schema.open = expanded;
      setOpened();

      let promise = $q.resolve();
      if (expanded) {
        promise = load(schema);
      }

      promise.then(() => {
        _.forEach(schema.children, (child) => expand(child, expanded));
      });
    }

    methodTreeController.getMethods = function () {
      const promises = _.map(methodTreeController.categories, loadAll);
      return $q.all(promises).then(() => {
        const schemas = _(methodTreeController.categories).map('schemas').flatten().flatMap(flatten).value();
        return _(activeFilter(schemas)).filter({ owned: true }).map('method').filter(angular.isDefined).uniqBy('id').value();
      });
    };

    function loadAll(category) {
      const promises = _.map(category.schemas, load);
      return $q.all(promises);
    }

    function load(schema) {
      let promise = $q.resolve();
      if (!schema.loaded) {
        promise = loadChildren(schema);
      }

      return promise.then(() => {
        const promises = _.map(schema.children, load);
        return $q.all(promises);
      });
    }

    function flatten(schema) {
      if (angular.isUndefined(schema.children)) {
        return [schema];
      }

      return _(schema.children).flatMap(flatten).concat([schema]).value();
    }
  }
});
