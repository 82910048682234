'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowWidget
 * @description
 * uasWorkflowWidget Shows workflow widgets
 */
angular.module('uasApp')
  .component('uasWorkflowWidget', {
    bindings: {
      entity: '<',
      period: '<'
    },
    templateUrl: 'es6/workflow/workflow.widget.html',
    controllerAs: 'workflowWidgetController',
    controller: function (Workflow, WorkflowStarter) {
      const workflowWidgetController = this;

      workflowWidgetController.$onChanges = function() {
        loadData();
      };

      workflowWidgetController.begin = function(participant) {
        WorkflowStarter.navigate(participant);
      };

      function loadData() {
        Workflow.widgets({
          academicYearId: sessionStorage.academicYear
        }).$promise.then((widgets) => {
          workflowWidgetController.workflows = _(widgets || []).map((widget) => {
              var entities = widget.entities;
              if (widget.workflow.grouped === true) {
                entities = _(widget.entities).map((entity) => {
                  if (_.isEmpty(entity.children)) {
                    return [entity]; // Could not make group, fallback on regular entity
                  }

                  return entity.children;
                }).flatten().value();
              }

              return _.map(entities, (entity) => {
                return _.extend(entity, {
                  workflow: widget.workflow
                });
              });
            }).flatten().filter(isEntity).value();
        });
      }

      function isEntity(item) {
        const expected = _.result(workflowWidgetController.entity, 'self');
        return _.isEqual(item.root.self, expected);
      }
    }
});
