'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:relationListPage
 * @description
 * Shows relations in compact read-only view.
 */
angular.module('uasApp').component('relationsListPage', {
  templateUrl: 'es6/staffing/relation/relations.list.page.html',
  bindings: {
    entity: '<',
    page: '<',
    operations: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'relationListPageController',
  controller: function (Page) {
    const relationListPageController = this;

    relationListPageController.$onInit = function () {
      relationListPageController.readOnly = relationListPageController.isReadOnly || Page.getParameterAsBoolean(relationListPageController.page, 'readOnly', false);
    };
  }
});
