'use strict';

angular.module('uasApp').component('languageFlag', {
  bindings: {
    language: '<',
    viewClasses: '<?'
  },
  templateUrl: 'es6/i18n/language.flag.html',
  controllerAs: 'languageFlagController'
});
