'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowStudyCheck
 * @description
 * uasWorkflowStudyCheck Validates a study.
 */
angular.module('uasApp')
  .component('uasWorkflowStudyCheck', {
    bindings: {
      study: '<',
      page: '<',
      operations: '<?',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/study/workflow/workflow.study.check.html',
    controllerAs: 'studyCheckWorkflowController',
    controller: function (StudyCheck, WorkflowModal, Language) {
      const studyCheckWorkflowController = this;

      studyCheckWorkflowController.$onInit = function() {
        studyCheckWorkflowController.order = 'entity.code';
        studyCheckWorkflowController.reverse = false;

        loadData();
      };

      function loadData() {
        StudyCheck.query({
          studyId: studyCheckWorkflowController.study.id,
          language: Language.get()
        }).$promise.then(function(entities) {
          studyCheckWorkflowController.messages = _(entities).map((wrapper) => {
            return _.map(wrapper.messages, (message) => {
              return { entity: wrapper.entity, message };
            });
          }).flatten().value();
        });
      }

      studyCheckWorkflowController.open = function(module) {
        WorkflowModal.open({
          entity: module,
          onSuccess: loadData,
          onCancel: loadData
        });
      };
    }
  });