'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ExternalSystem
 * @description
 * The ExternalSystem service.
 */
angular.module('uasApp').factory('ExternalSystem', function(UasResource) {
    const resource = UasResource('/external-system', {
        clients: {
            method: 'GET',
            url: '/external-system/clients',
            isArray: true
        }
    });
    
    function isAvailable({ system, clients }) {
        let available = true;
        if (_.get(system, 'exportable', false)) {
            const code = _.get(system, 'code', '').toLowerCase();
            available = _.some(clients, (client) => client.toLowerCase() === code);
        }
        return available;
    }

    return _.extend(resource, { isAvailable });
});
