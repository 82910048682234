'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Search
 * @description
 * The Search service.
 */
angular.module('uasApp')
    .factory('Search', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/search/', {}, {
            'advanced': {
                url: BackendService.getBaseUrl() + '/search/advanced',
                method: 'GET',
                isArray: false
            }
        });
    });
