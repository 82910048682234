'use strict';

angular.module('uasApp').factory('SubjectVersion', function (UasResource) {
    return UasResource('/subject-versions', {
        search: {
            method: 'GET',
            url: '/subject-versions/search',
            isArray: false
        }
    });
});
