'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAdmin
 * @description
 * uasModuleAdmin Shows module admin.
 */
angular.module('uasApp').component('uasModuleAdmin', {
  bindings: {
    module: '<',
    operations: '<'
  },
  templateUrl: 'es6/module/module.admin.html',
  controllerAs: 'moduleAdminController',
  controller: function ($q, $state, Message, Module, Faculty, Study, CustomField, CustomPage, EntityType, FeedbackService) {
    const moduleAdminController = this;

    const EXTENSIONS = [
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' },
      // Hide fields
      { name: 'academicYearId', visible: false },
      { name: 'changeType', visible: false },
      { name: 'created', visible: false },
      { name: 'id', visible: false },
      { name: 'importDateTime', visible: false },
      { name: 'originalCode', visible: false },
      { name: 'originalId', visible: false },
      { name: 'publicationDate', visible: false },
      { name: 'type', visible: false },
      { name: 'uid', visible: false },
      { name: 'version', visible: false }
    ];

    moduleAdminController.$onInit = function () {
      moduleAdminController.module_ = angular.copy(moduleAdminController.module);
      loadData();
    };

    function loadData() {
      moduleAdminController.loading = false;
      loadStudies();

      $q.all([
        CustomPage.query({
          basePath: 'module.admin'
        }).$promise,
        CustomField.query({
          rootType: 'module',
          entityType: 'module'
        }).$promise,
        EntityType.get({
          rootType: 'module',
          entityType: 'module'
        }).$promise,
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([pages, fields, customType, faculties]) => {
        moduleAdminController.pages = pages;
        moduleAdminController.fields = CustomField.extend(fields, EXTENSIONS);
        moduleAdminController.customType = customType;
        moduleAdminController.faculties = faculties;

        moduleAdminController.onEvaluate();
      }).finally(() => {
        moduleAdminController.loading = false;
      });
    }

    moduleAdminController.onEvaluate = function () {
      const entity = {
        type: 'module',
        id: _.result(moduleAdminController.module_, 'id')
      };
      moduleAdminController.evaluation = CustomField.getEvaluation(entity, moduleAdminController.module_, moduleAdminController.fields);
    };

    moduleAdminController.onFacultyChanged = function () {
      delete moduleAdminController.module_.studyId;
      loadStudies();
      moduleAdminController.onEvaluate();
    };

    function loadStudies() {
      const facultyId = moduleAdminController.module_.facultyId;
      if (!facultyId) {
        moduleAdminController.studies = [];
      } else {
        Study.query({
          facultyId: facultyId
        }).$promise.then((studies) => {
          moduleAdminController.studies = studies;
        });
      }
    }

    moduleAdminController.loadModule = function () {
      moduleAdminController.adminForm.$setPristine();
      $state.reload();
    };

    moduleAdminController.save = function (content, comment) {
      return Module.update({
        content,
        comment
      }).$promise.then((result) => {
        moduleAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(moduleAdminController.module_);
        return result;
      });
    };

    moduleAdminController.delete = function (module, comment) {
      const body = {
        comment: comment || '',
        id: module.id
      };

      Module.remove(body).$promise.then(function () {
        Message.onRemoved();
        $state.reload();
      });
    };

    moduleAdminController.deleteFull = function (module) {
      Module.deleteFull({
        id: module.id
      }).$promise.then(() => {
        Message.onRemoved();

        moduleAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});
