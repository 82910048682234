'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:PersonModal
 * @description Opens a modal to edit a person.
 */
angular.module('uasApp').factory('PersonModal', function (Person, $q, $uibModal) {
  function edit({ person, operations, onChange }) {
    $uibModal.open({
      template: `
        <person-edit
          person="modalController.person"
          operations="modalController.operations"
          on-change="modalController.onChange()"
          on-cancel="modalController.close()">
        </person-edit>
      `,
      resolve: {
        model: function () {
          if (angular.isUndefined(person.id)) {
            return $q.resolve(person);
          }

          return Person.secure({
            id: person.id
          }).$promise;
        }
      },
      controllerAs: 'modalController',
      controller: function ($uibModalInstance, model) {
        const modalController = this;

        modalController.person = model;
        modalController.operations = operations;

        modalController.onChange = function () {
          modalController.close();

          if (_.isFunction(onChange)) {
            onChange();
          }
        };

        modalController.close = function () {
          $uibModalInstance.dismiss();
        };
      }
    });
  }

  return { edit };
});
