'use strict';

angular.module('uasApp').factory('workflowSetupIsDirtyChecker', function() {

  let observers = [];

  return { flagDirty, onDirtyFlagged, unsubscribe };
  /**
   * Mark that the form has become dirty. All observers will be called when this method is called.
   */
  function flagDirty() {
    _.forEach(observers, (observer) => observer());
  }

  /**
   * Adds a function to call when the form is considered to be 'dirty'.
   * @param observer Function which gets called when the workflow settings have changed
   */
  function onDirtyFlagged(observer) {
    if (_.isFunction(observer)) {
      observers.push(observer);
    }
  }

  /**
   * Remove a function from the list of registered observer functions
   * @param observer observer to remove.
   */
  function unsubscribe(observer) {
    _.remove(observers, (o) => o === observer);
  }
});
