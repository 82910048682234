'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:UasCache
 * @description
 * The UasCache service.
 */
angular.module('uasApp')
    .factory('UasCache', function(UasVersion) {
        return function(cacheName) {
            const version = UasVersion(`cache.${cacheName}`);

            const instance = {};
            let values = {};

            instance.fetch = function(key, fetch) {
                if (version.isChanged()) {
                    instance.clear();
                }

                const value = values[key];
                if (angular.isDefined(value) && isResolved(value)) {
                    return angular.copy(value);
                } else if (fetch) {
                    const fetched = fetch();
                    values[key] = fetched;
                    return fetched;
                }
            };

            function isResolved(value) {
                return value.$resolved === true;
            }

            instance.get = function(key) {
                const value = values[key];
                return angular.copy(value);
            };

            instance.clear = function() {
                version.update();
                values = {};
            };

            return instance;
        };
    });
