'use strict';

angular.module('uasApp').component('typeItems', {
  templateUrl: 'es6/type/type.items.html',
  bindings: {
    types: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'typeItemsController',
  controller: function() {
    const typeItemsController = this;

    typeItemsController.$onInit = function() {
        typeItemsController.options = [{
            label: 'INCLUDE'
        }, {
            value: 'DISABLE_REUSE',
            label: 'DISABLE_REUSE'
        }, {
            value: 'DISABLE_CREATE',
            label: 'DISABLE_CREATE'
        }, {
            value: 'EXCLUDE',
            label: 'EXCLUDE'
        }];

        typeItemsController.ngModelCtrl.$render = function () {
            const items = typeItemsController.ngModelCtrl.$modelValue;
            typeItemsController.items = _.map(typeItemsController.types, (type) => {
                const item = _.find(items, { typeId: type.id }) || {};
                return _.extend({ type }, item);
            });
        };
    };

    typeItemsController.setAccess = function (item, access) {
        item.access = access;
        onChange();
    };

    function onChange() {
        const items = _(typeItemsController.items)
            .filter((item) => !_.isEmpty(item.access))
            .map((item) => {
                return {
                    id: item.id,
                    access: item.access,
                    typeId: item.type.id
                };
            })
            .value();

        typeItemsController.ngModelCtrl.$setViewValue(items);
    }
  }
});
