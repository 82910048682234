'use strict';

/**
 * Convert checkbox to toggle switch
 */
angular.module('uasApp').component('toggle', {
  templateUrl: 'es6/app/toggle.html',
  bindings: {
    toggleId: '@',
    ngDisabled: '<?',
    isReadOnly: '<?',
    isBound: '<?',
    inputClasses: '<?',
    viewClasses: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel',
    securedCtrl: '?^secured'
  },
  controllerAs: 'toggleController',
  controller: function ($element) {
    const toggleController = this;

    toggleController.$onInit = function () {
      toggleController.ngModelCtrl.$render = () => {
        const value = toggleController.ngModelCtrl.$viewValue;
        toggleController.value = value;
        toggleController.checked = value === true || value === 'true';
        toggleController.inputElement = $element.find('input')[0];
        setChecked();
      };

      secure();
    };

    function setChecked() {
      toggleController.inputElement.checked = toggleController.checked;
    }

    function secure() {
      if (toggleController.securedCtrl) {
        toggleController.securedCtrl.resecure($element);
      }
    }

    toggleController.isDisabled = function () {
      return toggleController.ngDisabled || toggleController.inputElement.disabled;
    };

    toggleController.toggle = function () {
      if (!toggleController.isDisabled() && !toggleController.isReadOnly && !toggleController.isBound) {
        toggleController.checked = !toggleController.checked;
        setChecked();
        toggleController.ngModelCtrl.$setViewValue(toggleController.checked);
      }
    };
  }
});
