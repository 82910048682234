'use strict';

angular.module('uasApp').component('entityTabs', {
  bindings: {
    view: '@',
    entity: '<',
    tabs: '<',
    showVersions: '<?',
    showFeedback: '<?',
    operations: '<',
    retrieveFeedbackFromRoot: '<?'
  },
  templateUrl: 'es6/app/tabs/entity.tabs.html',
  controllerAs: 'entityTabsController'
});
