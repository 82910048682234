'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalCreate
 * @description
 * appraisalCreate Creates an appraisal.
 */
angular.module('uasApp').component('appraisalCreate', {
    bindings: {
        appraisal: '<',
        parent: '<?',
        category: '<?',
        entity: '<',
        module: '<',
        fields: '<',
        includeTypes: '<?',
        operations: '<',
        operationsToEdit: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/assessment/appraisal/appraisal.create.html',
    controllerAs: 'appraisalCreateController',
    controller: function (Appraisal, EntityService) {
        const appraisalCreateController = this;

        appraisalCreateController.$onInit = function () {
            appraisalCreateController.mode = 'ASSESSMENT';
            appraisalCreateController.assessment = emptyAssessment();
            appraisalCreateController.moduleReference = EntityService.toReference(appraisalCreateController.module);
            appraisalCreateController.assessmentField = _.find(appraisalCreateController.fields, { name: 'assessmentId' });
        };

        function emptyAssessment() {
            return {
                moduleId: _.result(appraisalCreateController.module, 'id'),
                academicYearId: sessionStorage.academicYear,
                values: {}
            };
        }

        appraisalCreateController.onAssessment = function () {
            const assessmentId = _.result(appraisalCreateController.assessment, 'id');
            appraisalCreateController.appraisal.assessmentId = assessmentId;

            if (!appraisalCreateController.assessment) {
                appraisalCreateController.assessment = emptyAssessment();
            }
        };

        appraisalCreateController.save = function (content, comment) {
            const assessmentId = _.result(appraisalCreateController.assessment, 'id');
            if (angular.isUndefined(assessmentId)) {
                appraisalCreateController.assessment.moduleId = _.result(appraisalCreateController.module, 'id');

                Appraisal.combined({
                    appraisal: content,
                    assessment: appraisalCreateController.assessment,
                    comment
                }).$promise.then((result) => {
                    appraisalCreateController.onSave({ content, result });
                });
            } else {
                Appraisal.save({
                    content,
                    comment
                }).$promise.then((result) => {
                    appraisalCreateController.onSave({ content, result });
                });
            }
        };

        appraisalCreateController.close = function () {
            appraisalCreateController.onClose();
        };
    }
});