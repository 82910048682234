'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processManagerPage
 * @description
 * processManagerPage Manage the status of entities in a process
 */
angular.module('uasApp').component('processManagerPage', {
    bindings: {
        entity: '<',
        page: '<',
        process: '<',
        operations: '<',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/process/progress/process.manager.page.html',
    controllerAs: 'processManagerPageController',
    controller: function (Process, ProcessType, Page, EntityService, $uibModal) {
        const processManagerPageController = this;

        processManagerPageController.$onInit = function() {
            processManagerPageController.readOnly = processManagerPageController.isReadOnly === true || Page.getParameterAsBoolean(processManagerPageController.page, 'readOnly', false);
            loadData();
        };

        function loadData() {
            const processTypeId = Page.getParameterAsInt(processManagerPageController.page, 'type') || _.get(processManagerPageController.process, 'type.id');

            processManagerPageController.loading = true;
            return ProcessType.find(processTypeId).$promise.then((type) => {
                if (angular.isUndefined(type)) {
                    return [];
                }

                processManagerPageController.type = type;
                return Process.query({
                    typeId: type.id,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then((processes) => {
                    const entity = EntityService.toReference(processManagerPageController.entity);
                    processManagerPageController.processes = _.filter(processes, { entity });
                });
            }).finally(() => {
                processManagerPageController.loading = false;
            });
        }

        processManagerPageController.create = function() {
            $uibModal.open({
                controllerAs: 'processModalController',
                controller: function ($uibModalInstance) {
                    const processModalController = this;
                    processModalController.process = {
                        typeId: processManagerPageController.type.id,
                        academicYearId: sessionStorage.academicYear,
                        entity: EntityService.toReference(processManagerPageController.entity)
                    };
                    processModalController.type = processManagerPageController.type;
                    processModalController.instance = $uibModalInstance;
                },
                size: 'lg',
                template: `
                    <uas-process-modal
                        process="processModalController.process"
                        type="processModalController.type"
                        instance="processModalController.instance">
                    </uas-process-modal>
                `
            }).result.then(loadData, loadData);
        };
    }
});
