'use strict';

angular.module('uasApp').component('conditionalButton', {
  bindings: {
    visible: '<',
    iconClasses: '@?',
    btnSize: '@'
  },
  transclude: true,
  templateUrl: 'es6/app/buttons/conditional.button.html',
  controllerAs: 'conditionalButtonController'
});
