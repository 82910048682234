'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assetListPage
 * @description
 * assetListPage Show assets on a workflow page
 */
angular.module('uasApp').component('assetListPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/inventory/asset/asset.list.page.html',
  controllerAs: 'assetListPageController',
  controller: function (Page) {
    const assetListPageController = this;

    assetListPageController.$onInit = function () {
      assetListPageController.readOnly = assetListPageController.isReadOnly || Page.getParameterAsBoolean(assetListPageController.page, 'readOnly', false);
    };
  }
});
