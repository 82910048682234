'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasSimulationBar
 * @description
 * uasSimulationBar Shows simulation bar
 */
angular.module('uasApp')
  .component('uasSimulationBar', {
    bindings: {
      year: '='
    },
    templateUrl: 'es6/simulation/simulation.bar.component.html',
    controllerAs: 'simulationBarController',
    controller: function (AcademicYear, AcademicYearHolder, Message, translateFilter) {
      const simulationBarController = this;

      simulationBarController.backToOriginYear = function() {
        AcademicYear.get({
          id: simulationBarController.year.originId
        }).$promise.then(function(origin) {
          AcademicYearHolder.transition(origin);
          Message.addSuccess(translateFilter('Static.Message.SwitchAcademicYear', { academicyear: origin.code }));
        });
      };
    }
});
