'use strict';

angular.module('uasApp')
    .directive('relationsView', function() {
        return {
            restrict: 'E',
            templateUrl: 'es6/staffing/relation/relations.view.html',
            scope: {
                entityType: '@',
                entityId: '=',
                isReadOnly: '<?'
            },
            controller: function($scope, $q, Relation, RoleType) {

                $q.all([
                    RoleType.query({
                        academicYearId: sessionStorage.academicYear,
                        entityType: $scope.entityType
                    }).$promise,
                    Relation.query({
                        entityType: $scope.entityType,
                        entityId: $scope.entityId
                    }).$promise
                ]).then(([roles, relations]) => {
                    $scope.relations = [];

                    let activeRelations = _.filter(relations, (relation) => relation.changeType !== 'REMOVE');
                    let groupedRelations = _.groupBy(activeRelations, 'roleId');
                    _.each(groupedRelations, (perRole, roleId) => {
                        const role = _.find(roles, {
                            id: parseInt(roleId)
                        });
                        if (role) {
                            $scope.relations.push({
                                type: role,
                                data: _.map(perRole, mapToTarget)
                            });
                        }
                    });
                });

                function mapToTarget(relation) {
                    const target = relation.target || {};

                    return _.extend(target, {
                        remark: relation.remark
                    });
                }
            }
        };
    });
