'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('search', {
        url: '/search',
        template: '<search-advanced></search-advanced>',
        breadcrumb: {
            label: 'Static.Page.Search',
            root: true
        }
    });

});
