'use strict';

angular.module('uasApp').component('uasReportCell', {
    bindings: {
        column: '<',
        value: '<',
        decoration: '<?'
    },
    transclude: true,
    templateUrl: 'es6/report/report.cell.html',
    controllerAs: 'reportCellController',
    controller: function (Changes) {
        const reportCellController = this;

        reportCellController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'value')) {
                delete reportCellController.value_;
                delete reportCellController.tooltip;

                if (angular.isDefined(reportCellController.value)) {
                    if (_.isObject(reportCellController.value) && angular.isDefined(reportCellController.value.value)) {
                        reportCellController.value_ = reportCellController.value.value;
                        reportCellController.tooltip = reportCellController.value.tooltip;
                    } else {
                        reportCellController.value_ = reportCellController.value;
                    }
                }
            }
        };
    }
});
