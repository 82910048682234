'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Change
 * @description
 * The Change service.
 */
angular.module('uasApp')
    .factory('Change', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/change/:id', {}, {
            mine: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/change/mine',
                isArray: true
            },
            progress: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/change/progress',
                isArray: true
            },
            report: {
                url: BackendService.getBaseUrl() + '/change/report',
                method: 'GET',
                isArray: false
            },
            entities: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/change/entity',
                isArray: true
            },
            countByEntities: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/change/entity/count',
                isArray: true
            },
            transition: {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/change/transition',
                isArray: true
            },
            approve: {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/change/approve',
                isArray: true
            },
            unapprove: {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/change/unapprove',
                isArray: true
            },
            reject: {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/change/reject',
                isArray: true
            },
            revert: {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/change/revert',
                isArray: false
            },
            statuses: {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/change/:id/status',
                isArray: true
            }
        });
    });
