'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:elementTooltip
 * @description
 * Displays element information
 */
angular.module('uasApp').component('elementTooltip', {
  bindings: {
    element: '<'
  },
  templateUrl: 'es6/element/element.tooltip.html',
  controllerAs: 'elementTooltipController'
});