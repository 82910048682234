'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:inlineAlert
 * @description Displays an inline alert with a context-based icon and a message.
 * @param {string} context - The context of the alert to be displayed, 'success', 'warning', 'danger', or 'info'.
 * @param {string} message - The i18n translation key to be translated and displayed next to the icon.
 * @param {boolean} hideIcon - Optional flag to hide the icon, controls 'squared' class
 * @param {string} viewClasses - Optional classes for the inline-alert
 *
 */
angular.module('uasApp').component('inlineAlert', {
  bindings: {
    context: '@',
    message: '@',
    hideIcon: '<?',
    viewClasses: '@?'
  },
  controllerAs: 'inlineAlertController',
  controller: function (Changes) {
    const inlineAlertController = this;

    inlineAlertController.$onInit = function () {
      inlineAlertController.iconClass = '';
      inlineAlertController.showIcon = inlineAlertController.hideIcon !== true;

      setContext();
    };

    // Ensure that the icon is updated when the context changes
    inlineAlertController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'context')) {
        setContext();
      }
    };

    function setContext() {
      switch (inlineAlertController.context) {
        case 'success':
          inlineAlertController.iconClass = 'check-circle';
          break;
        case 'info':
          inlineAlertController.iconClass = 'info-circle';
          break;
        case 'warning':
          inlineAlertController.iconClass = 'exclamation-triangle';
          break;
        case 'danger':
          inlineAlertController.iconClass = 'exclamation-circle';
          break;
      }
    }
  },
  template: `
      <span
        class="inline-alert inline-alert-{{ inlineAlertController.context }} d-ib"
        ng-class="[{
          'squared': !inlineAlertController.showIcon,
          }, inlineAlertController.viewClasses
        ]">
        <i class="fa fa-{{ inlineAlertController.iconClass }}" ng-if="inlineAlertController.showIcon"></i>
        <span>{{ inlineAlertController.message }}</span>
      </span>
    `
});
