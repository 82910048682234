'use strict';

angular.module('uasApp').directive('htmlTruncate', () => {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const DEFAULT_LENGTH = 50;

      scope.$watch(() => element.text(), (text) => {
        angular.element(element).text(_.truncate(text, { length: getLength(attrs.htmlTruncate) }));
      });

      function getLength(value) {
        const parsed = parseInt(value);
        if (!isNaN(parsed)) {
          return parsed;
        }
        return DEFAULT_LENGTH;
      }
    }
  };
});
