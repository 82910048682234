'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Rule
 * @description
 * The Rule service.
 */
angular.module('uasApp').factory('Rule', function (UasResource) {
  return UasResource('/rules', {
    report: {
      url: '/rules-report',
      method: 'GET',
      isArray: false
    },
    search: {
      method: 'GET',
      url: '/rules/search',
      isArray: false
    }
  });
});
