'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalFields
 * @description
 * appraisalFields Manages an appraisal.
 */
angular.module('uasApp').component('appraisalFields', {
    bindings: {
        appraisal: '<',
        assessment: '<?',
        fields: '<',
        operations: '<',
        operationsToEdit: '<'
    },
    templateUrl: 'es6/assessment/appraisal/appraisal.fields.html',
    controllerAs: 'appraisalFieldsController',
    controller: function(CustomField) {
        const appraisalFieldsController = this;

        appraisalFieldsController.$onInit = function() {
            setEvaluation();
        };

        appraisalFieldsController.$onChanges = function() {
            appraisalFieldsController.fields_ = _(appraisalFieldsController.fields)
                .filter((field) => field.name !== 'assessmentId')
                .sortBy(['sequence', 'name'])
                .value();

            setEvaluation();
        };

        appraisalFieldsController.onValue = function() {
            setEvaluation();
        };

        function setEvaluation() {
            const assessment = { 
                type: 'assessment', 
                id: _.result(appraisalFieldsController.assessment, 'id')
            };

            let properties = angular.copy(appraisalFieldsController.assessment);
            properties = _.extend(properties, appraisalFieldsController.appraisal);

            appraisalFieldsController.evaluation = CustomField.getEvaluation(assessment, properties, appraisalFieldsController.fields);
        }

    }
});