'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasAdminScreen
 * @description
 * uasAdminScreen Shows admin screen
 */
angular.module('uasApp').component('uasAdminScreen', {
  templateUrl: 'es6/admin/admin.screen.html',
  controllerAs: 'adminController',
  controller: function(AuthService, SecurityService) {
    const adminController = this;

    adminController.$onInit = function() {
      checkAuthorization();
    };

    function checkAuthorization() {
      adminController.loading = true;
      AuthService.operations().then((operations) => {
        adminController.authorized = SecurityService.isAllowed(['ADMIN_OBJECT'], operations);
      }).finally(() => {
        adminController.loading = false;
      });
    }
  }
});
