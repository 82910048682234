'use strict';

/**
 * @ngdoc service
 * @name uasApp.Message
 * @description
 * # Message
 * Service in the uasApp.
 */
angular.module('uasApp')
    .factory('Message', function($timeout, translateFilter) {
        var instance = {};
        instance.messages = [];

        var createMessage = function(type, text, duration, callback, _instance) {
            var self = {
                type: type,
                text: text,
                duration: duration,
                callback: callback
            };

            self.remove = function() {
                var index = _instance.messages.indexOf(this);
                _instance.messages.splice(index, 1);
            };

            self.click = function() {
                if (self.callback) {
                    self.callback();
                }
            };

            self.timer = $timeout(function() {
                self.remove();
            }, self.duration * 1000);

            return self;
        };

        instance.addMessage = function(type, text, duration, callback) {
            var existMessage = _.find(instance.messages, {
                text: text
            });

            if (existMessage) {
                $timeout.cancel(existMessage.timer);
                existMessage.timer = $timeout(function() {
                    existMessage.remove();
                }, existMessage.duration * 1000);
                return;
            }

            instance.messages.push(createMessage(type, text, duration, callback, instance));
        };

        instance.addInfo = function(text, callback) {
            instance.addMessage('info', text, 5, callback);
        };

        instance.addSuccess = function(text, callback) {
            instance.addMessage('success', text, 5, callback);
        };

        instance.addWarning = function(text, callback) {
            instance.addMessage('warning', text, 7, callback);
        };

        instance.addError = function(text, callback) {
            instance.addMessage('danger', text, 10, callback);
        };

        instance.addInfoLabel = function(label, defaultValue) {
            instance.addInfo(getMessage(label, defaultValue));
        };

        instance.addSuccessLabel = function(label, defaultValue) {
            instance.addSuccess(getMessage(label, defaultValue));
        };

        instance.addWarningLabel = function(label, defaultValue) {
            instance.addWarning(getMessage(label, defaultValue));
        };

        instance.addErrorLabel = function(label, defaultValue) {
            instance.addError(getMessage(label, defaultValue));
        };

        instance.onSaved = function() {
            instance.addSuccessLabel('Static.Message.DataSaved', 'The data has been saved.');
        };

        instance.onRemoved = function() {
            instance.addSuccessLabel('Static.Message.DataRemoved', 'The data has been removed.');
        };

        function getMessage(label, defaultValue) {
            let message = translateFilter(label);
            if (message === label && defaultValue) {
                message = defaultValue;
            }
            return message;
        }

        return instance;
    });
