'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Tabs
 * @description
 * The Tabs service.
 */
angular.module('uasApp')
  .factory('Tabs', function ($state, $q, CustomPage, AcademicYearHolder, Feature, SecurityService, Tab) {
    /*
     * Determine if a tab is active.
     */
    function isActive(tab) {
      let result = false;
      if ($state.is(tab.route)) {
        result = tab.checkParams === false || _.every(tab.params, function (value, key) {
          return tab.params[key] === $state.params[key];
        });
      }
      return result;
    }

    /*
     * Mix our default tab with custom tabs and overload configurations.
     */
    function build(defaultTabs, operations, basePath, entityType, entityId) {
      return $q.all([
        getFeatures(entityType, entityId),
        getTabs(entityType, entityId, basePath),
        CustomPage.query({ basePath }).$promise
      ]).then(([features, tabs, pages]) => {
        if (_.isEmpty(tabs)) {
          // Fallback to factory default when no tabs configured
          tabs = _.map(defaultTabs, (tab) => {
            const page = _.find(pages, { route: tab.route }) || {};
            return buildByCustomPage(tab, page);
          });
        } else {
          tabs = _.map((tabs), (tab) => {
            const defaultTab = _.find(defaultTabs, { route: tab.route }) || {};
            return _.extend(defaultTab, tab);
          });
        }

        return _(tabs).sortBy(['sequence', 'index']).map((tab, index) => {
          tab.index = index;
          tab.sequence = index;

          // Hide tab when the feature is not available for this context
          const granted = _.isEmpty(tab.features) || _.intersection(features, tab.features).length > 0;
          if (!granted) {
            tab.visible = false;
          }

          // Hide tab when the user is not granted to see this data
          tab.disabled = !SecurityService.isAllowed(tab.operations, operations);
          tab.secured = !_.isEmpty(tab.operations);
          return tab;
        }).filter({ visible: true }).value();
      });
    }

    function getFeatures(entityType, entityId) {
      if (angular.isDefined(entityType) && angular.isDefined(entityId)) {
        return Feature.allowed({
          entityType,
          entityId
        }).$promise;
      }
      
      return $q.resolve([]);
    }

    function getTabs(entityType, entityId, basePath) {
      if (angular.isUndefined(entityType)) {
        return $q.resolve([]);
      }

      return Tab.query({ entityType, entityId }).$promise.then((tabs) => {
        return _.map(tabs, (tab) => {
          const page = tab.page;

          if (angular.isDefined(page)) {
            if (tab.operation) {
              tab.operations = [tab.operation];
            }
            if (tab.feature) {
              tab.features = [tab.feature];
            }
            return _.extend(tab, {
              route: `${basePath}.page`,
              params: {
                pageId: `${page.id}`,
                id: `${entityId}`
              },
              title: page.title,
              titles: page.titles
            });
          } else {
            return addState(tab);
          }
        });
      });
    }

    function addState(tab) {
      const state = getState(tab);
      if (state) {
        const title = _.result(state, 'data.title');
        if (title) {
          tab.title = title;
        }

        const label = _.result(state, 'data.label');
        if (label) {
          tab.label = label;
        }

        if (tab.operation) {
          tab.operations = [tab.operation];
        } else {
          tab.operations = _.get(state, 'data.secured');
        }

        if (tab.feature) {
          tab.features = [tab.feature];
        } else {
          tab.features = _.get(state, 'data.features');
        }
      }
      return tab;
    }

    function getState(tab) {
      return $state.get(tab.route);
    }

    function buildByCustomPage(tab, page) {
      const result = angular.copy(tab);
      result.sequence = _.get(page, 'sequence', tab.sequence);

      if (tab.visible !== false) {
        if (AcademicYearHolder.isSimulation()) {
          result.visible = page.visibleSimulation !== false;
        } else {
          result.visible = page.visible !== false;
        }
      }

      return addState(result);
    }

    function extend(tabs, basePath) {
      return CustomPage.query({
        basePath
      }).$promise.then((pages) => {
        return _(tabs).map((tab, index) => {
          const page = _.find(pages, { route: tab.route }) || {};
          const result = buildByCustomPage(tab, page);
          result.index = index;
          return result;
        }).sortBy(['sequence', 'index']).value();
      });
    }

    return {
      build,
      extend,
      isActive
    };
  });
