'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:studyModulesReportPage
 * @description
 * Shows study module group modules and opens module modal when row is clicked.
 */
angular.module('uasApp')
  .component('studyModulesReportPage', {
    bindings: {
      study: '<',
      page: '<',
      operations: '<?'
    },
    templateUrl: 'es6/module/report/study.modules.report.page.html',
    controllerAs: 'studyModulesReportPageController',
    controller: function (Module, WorkflowModal, Page, entityRedirect) {
      const studyModulesReportPageController = this;

      studyModulesReportPageController.$onInit = function () {
        studyModulesReportPageController.readOnly = Page.getParameterAsBoolean(studyModulesReportPageController.page, 'readOnly', false);

        const modal = Page.getParameterAsBoolean(studyModulesReportPageController.page, 'modal', false);
        studyModulesReportPageController.click = modal ? open : redirect;
        studyModulesReportPageController.refresh = { loadData: _.noop };

        studyModulesReportPageController.reportTemplate = Page.getParameter(studyModulesReportPageController.page, 'reportTemplate');
        studyModulesReportPageController.hideReportTemplates = Page.getParameterAsBoolean(studyModulesReportPageController.page, 'hideReportTemplates', false);

        studyModulesReportPageController.mode = Page.getParameter(studyModulesReportPageController.page, 'mode');
      };

      function open(row) {
        Module.get({
          id: row.entity.id
        }).$promise.then((module) => {
          WorkflowModal.open({
            entity: module,
            onSave: studyModulesReportPageController.refresh.loadData,
            onCancel: studyModulesReportPageController.refresh.loadData
          });
        });
      }

      function redirect(row) {
        entityRedirect.go('module.general', { id: row.entity.id }, { target: '_blank' });
      }
    }
  });
