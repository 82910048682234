'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:changeCustomMessage
 * @description
 * Shows a custom message (if defined on Custom Type affected by the change).
 * If a message is shown, hide the rest of the info unless clicked on 'show details' below.
 */
angular.module('uasApp').component('changeCustomMessage', {
  bindings: {
    change: '<',
    entityTypes: '<',
    onMessageLoaded: '&'
  },
  templateUrl: 'es6/changes/change.custom.message.html',
  controllerAs: 'changeCustomMessageController',
  controller: function(Changes, EntityTextHolder, entityTranslateFilter, Language, $q, translateFilter) {
    const changeCustomMessageController = this;

    changeCustomMessageController.$onInit = function () {
      changeCustomMessageController.onLanguage = Language.onChange(loadData);
      loadData();
    };

    changeCustomMessageController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'change')) {
        loadData();
      }
    };

    function loadData() {
      const change = _.get(changeCustomMessageController.change, 'feedback', changeCustomMessageController.change);
      const changeObject = change.change || {};
      changeCustomMessageController.changeType = changeObject.type;

      const customType = _.find(changeCustomMessageController.entityTypes, (it) => {
        const entityType = _.result(changeObject.entity, 'type');
        const rootType = _.result(changeObject.root, 'type');
        return it.entityType === entityType && it.rootType === rootType;
      });

      const changesLabel = _.get(customType, 'changesLabel');
      if (!changesLabel) {
        return;
      }

      const person = change.comment.person || {};
      const changeType = translateFilter(`Change.ActionWithinObject.${changeObject.type}`);

      const parameters = {
        createdBy: person.fullName || person.externalId,
        changeType: changeType,
        changeTypeLowerCase: changeType.toLocaleLowerCase()
      };

      getEntity(changeObject.entity).then((changedEntity) => {
        if (angular.isDefined(changedEntity)) {
          parameters.changedEntity = buildName(changedEntity.entity, change.name);
          parameters.rootEntity = buildName(changedEntity.root, '?');
        }

        buildMessage(changesLabel, parameters);
      });
    }

    function getEntity(reference) {
      const deferred = $q.defer();
      EntityTextHolder.get(reference, (entity) => {
        deferred.resolve(entity);
      }, true);
      return deferred.promise;
    }

    function buildName(entity, defaultValue) {
      let name = _.result(entity, 'name');
      if (!name) {
        return defaultValue;
      }

      const code = _.result(entity, 'code');
      if (code) {
        name = `(${code}) ${name}`;
      }
      return name;
    }

    function buildMessage(messageKey, parameters) {
      changeCustomMessageController.message = translateFilter(messageKey, parameters);

      if (changeCustomMessageController.message) {
        changeCustomMessageController.onMessageLoaded({
          message: changeCustomMessageController.message
        });
      }
    }
  }
});
