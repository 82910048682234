'use strict';

angular.module('uasApp').component('moduleGroupSelector', {
  bindings: {
    entity: '<',
    group: '<',
    studyId: '<',
    excludeGroupId: '<?',
    open: '<',
    onSelect: '&'
  },
  templateUrl: 'es6/planboard/move/module.group.selector.html',
  controllerAs: 'groupSelectorController',
  controller: function (Nodes) {
    const groupSelectorController = this;

    const manager = Nodes.manager({
      build: build
    });

    function build(node, parent) {
      const result = Nodes.build(node, parent);

      const studyId = node.studyId;
      const owned = angular.isUndefined(studyId) || studyId === groupSelectorController.studyId;

      return _.extend(result, {
        open: _.some(groupSelectorController.open, node.entity),
        owned: owned
      });
    }

    groupSelectorController.$onInit = function () {
      loadGroups();
    };

    groupSelectorController.$onChanges = function () {
      groupSelectorController.target = angular.copy(groupSelectorController.group);
    };

    function loadGroups() {
      groupSelectorController.loading = true;

      manager.init({
        entity: groupSelectorController.entity,
        active: true
      }).then((groups) => {
        groupSelectorController.groups = groups;
      }).finally(() => {
        groupSelectorController.loading = false;
      });
    }

    groupSelectorController.toggle = function (group) {
      if (group.owned === true) {
        manager.toggle(group);
      }
    };

    groupSelectorController.onGroup = function (group) {
      // Can not move group to itself
      if (group.id === groupSelectorController.excludeGroupId) {
        return;
      }
      if (group.owned === true) {
        groupSelectorController.target = group;
        groupSelectorController.onSelect({ group });
      }
    };
  }
});
