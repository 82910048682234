'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasPlanboard
 * @description
 * uasPlanboard Manages a workflow.
 */
angular.module('uasApp')
  .component('uasPlanboard', {
    bindings: {
      study: '<',
      mode: '<?',
      modes: '<?',
      operations: '<',
      workflowMode: '<?',
      isReadOnly: '<?',
      showPrint: '<?',
      attributes: '<?',
      subjectTypeId: '<?',
      onPrint: '&'
    },
    templateUrl: 'es6/planboard/planboard.html',
    controllerAs: 'planboardController',
    controller: function(Parameter, Period, Planboard, PlanboardGridster, PlanboardModal, Planning, Message,
        SecurityService, Study, Subject, UnsavedChangesModal, translateFilter, i18nFilter, $q) {

        const planboardController = this;
        const academicYear = sessionStorage.academicYear;

        planboardController.$onInit = () => {
            planboardController.mode = planboardController.mode || 'normal';
            planboardController.modes = _.isEmpty(planboardController.modes) ? ['normal', 'history', 'edit'] : planboardController.modes;
            planboardController.printMode = false;

            planboardController.editable = false;
            planboardController.allowed = true;

            planboardController.attribute = _(planboardController.attributes).map('value').head();
            planboardController.attributeValues = [];

            planboardController.board = new Planboard(planboardController.studyId);
            planboardController.sumEcts = Planboard.sumEcts;
            planboardController.totalEcts = Planboard.totalEcts;

            planboardController.studyId = planboardController.study.id;
            planboardController.showCode = false;

            loadData();
        };

        function loadData() {
            planboardController.loading = true;

            return $q.all([
                Period.getSelectable(academicYear),
                Planning.calendar({
                    studyId: planboardController.studyId
                }).$promise,
                Planning.get({
                    studyId: planboardController.studyId
                }).$promise,
                Subject.query({
                    entityType: 'study',
                    entityId: planboardController.studyId,
                    typeId: planboardController.subjectTypeId
                }).$promise,
                Parameter.load()
            ]).then(([periods, calendar, data, subjects]) => {
                planboardController.periods = _.filter(periods, (period) =>
                    period.calendarId === calendar.id
                );

                planboardController.calendar = calendar;
                planboardController.showCode = Parameter.getParameterAsBoolean('planboard.show_code');
                planboardController.subjects = _(subjects).map('type').uniq('id').value();

                planboardController.permissions = planboardController.workflowMode === true ? {
                    editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_WORKFLOW', planboardController.operations),
                    editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP_WORKFLOW', planboardController.operations),
                    editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP_WORKFLOW', planboardController.operations)
                } : {
                    editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE', planboardController.operations),
                    editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP', planboardController.operations),
                    editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP', planboardController.operations)
                };

                planboardController.allowed = _.some(planboardController.permissions);

                // Reset mode from edit to normal when not allowed or read-only
                if ((planboardController.allowed !== true && planboardController.mode === 'edit') || planboardController.isReadOnly === true) {
                    planboardController.mode = 'normal';
                    _.pull(planboardController.modes, 'edit');
                }

                const numberOfPeriods = calendar.periods.length;
                planboardController.columnWidth = getColumnWidth(numberOfPeriods);

                // Register gridster functions and options
                PlanboardGridster.bind(planboardController.board);
                PlanboardGridster.init(planboardController, { columns: numberOfPeriods });

                // Register modal functions
                PlanboardModal.init(planboardController);

                planboardController.switchMode(planboardController.mode);

                setFilters();

                return planboardController.board.init(planboardController.study, data.moduleGroups, planboardController.periods, planboardController.groupLoaded);
            }).finally(() => {
                planboardController.loading = false;
            });
        }

        function setFilters() {
            planboardController.board.attribute = planboardController.attribute;
            planboardController.board.subjectTypeId = planboardController.subjectTypeId;
            planboardController.board.subjectId = planboardController.subjectId;
        }

        planboardController.groupLoaded = function(group) {
            addAttributeValues(group);
            refreshMatches(group);
            return group;
        };

        function addAttributeValues(group) {
            _(group.modules).flatMap('attributeValues').filter(angular.isDefined).uniq('id').forEach(addAttributeValue);
        }

        function addAttributeValue(attributeValue) {
            const { id } = attributeValue;

            if (!_.some(planboardController.attributeValues, { id })) {
                planboardController.attributeValues.push(angular.copy(attributeValue));
            }
        }

        planboardController.isModeEnabled = function (mode) {
            return _.includes(planboardController.modes, mode);
        };

        planboardController.addReportContext = function(url) {
            return planboardController.board.getClosedGroups().then((closedGroups) => {
                let suffix = _(closedGroups).map((id) => '&exclude=' + id).join('');
                return url + suffix;
            });
        };

        planboardController.ensureGroupOpenOnDrag = function(group) {
            if (group.isOpen !== true && planboardController.board.dragging === true) {
                planboardController.board.toggleGroup(group);
            }
        };

        planboardController.getUnusedModules = function(group) {
            return _.filter(group.modules, function(module) {
                return (planboardController.mode !== 'normal' || module.changeType !== 'REMOVE') &&
                    (angular.isUndefined(module.offerings) ||
                    _.every(module.offerings, function(offering) {
                        return offering.changeType === 'REMOVE';
                    }));
            });
        };

        planboardController.checkForUnsavedChanges = function() {
            return planboardController.board.hasUnsavedChanges();
        };

        /**
         * Loads all the module offerings on the $scope only when group.isOpen is set to false.
         * Also sets the gridsterPeriod attribute to be used by gridster
         * @param {Object} group A Module instance.
         */
        planboardController.loadModules = function(group) {
            planboardController.board.loadModules(group);
        };

        /**
         * Filter used by a view.
         * @param {Object} item The Module(Group) object.
         * @return true if the mode is in historyView,
         * false if mode isn't in historyView and the item is removed.
         */
        planboardController.isVisible = function(item) {
            let visible = true;
            if (planboardController.mode === 'normal') {
                visible = item.changeType !== 'REMOVE';
            }
            return visible;
        };

        /**
         * Toggles the edit mode on the page and for gridster.
         */
        planboardController.switchMode = function(mode) {
            planboardController.mode = mode;
            planboardController.editable = mode === 'edit';
            planboardController.setDraggableEnabled(planboardController.editable);
            if (mode !== 'history') {
                planboardController.board.removeHistory();
            }
        };

        planboardController.canUndoModuleGroup = function(group) {
            return planboardController.mode === 'edit' && group.changeType === 'REMOVE';
        };

        planboardController.undoModuleGroup = function(group, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            planboardController.board.resetModuleGroup(group);
        };

        planboardController.canUndoOffering = function(offering, module) {
            return planboardController.mode === 'edit' && planboardController.board.canUndo(offering, module);
        };

        planboardController.undoOffering = function(offering, module, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            planboardController.board.resetOffering(offering, module);
        };

        planboardController.getModeClass = function() {
            return `${planboardController.mode}-mode`;
        };

        planboardController.toggleHistory = function(module, offering, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            planboardController.board.toggleHistory(module, offering);
        };

        planboardController.getCombinedDisplayType = function(module, offering) {
            if (planboardController.mode === 'normal') {
                return '';
            }
            return planboardController.board.getCombinedDisplayType(module, offering);
        };

        planboardController.saveNewModule = function(module, message) {
            const offerings = {};
            _.each(module.offerings, function (offering) {
                if (offering.dirty === true) {
                    offerings[module.moduleId + '-' + offering.boardId] = offering;
                }
            });

            return planboardController.saveStructure({
                groups: [],
                modules: [module],
                offerings: _.values(offerings),
                type: 'planboard'
            }, { message: message });
        };

        planboardController.onLeave = function (onClick, onSave) {
            if (planboardController.board.hasUnsavedChanges()) {
                UnsavedChangesModal.open({
                    rootType: 'module',
                    entityType: 'offering',
                    content: planboardController.board.getAllDirty(),
                    showCancel: true,
                    onSave: (structure, comment) => {
                        return planboardController.saveStructure(structure, comment).then(onSave);
                    }
                });
            } else {
                return onClick();
            }
        };

        /**
         * Saves all the changes by the user.
         * @param {Object} structure with modules, groups and offerings to save.
         * @param {Object} comment The message typed by the user.
         */
        planboardController.saveStructure = function(structure, comment) {
            const model = buildSaveModel(structure, comment);
            return savePlanning(model);
        };

        const buildSaveModel = function(structure, comment) {
            return {
                studyId: planboardController.studyId,
                comment: comment,
                groups: transform(structure.groups, buildGroupModel),
                modules: transform(structure.modules, buildModuleModel),
                offerings: transform(structure.offerings, buildOfferingModel)
            };
        };

        const transform = function(array, callback) {
            var result = [];
            _.each(array, function(element) {
                result.push(callback(element));
            });
            return result;
        };

        const buildGroupModel = function(input) {
            return {
                id: input.id,
                remove: input.changeType === 'REMOVE',
                moduleGroupId: input.moduleGroupId,
                required: input.required,
                parentId: input.parentId,
                description: input.description,
                values: input.values
            };
        };

        const buildModuleModel = function(input) {
            return {
                id: input.id,
                remove: input.changeType === 'REMOVE',
                moduleGroupId: input.moduleGroupId,
                moduleId: input.moduleId,
                required: input.required,
                terminated: input.moduleTerminated,
                values: input.values
            };
        };

        const buildOfferingModel = function(input) {
            return {
                id: input.moduleOfferingId,
                remove: input.changeType === 'REMOVE',
                moduleId: input.moduleId,
                periodId: _.result(input.period, 'id')
            };
        };

        // Saves the complete planning model
        const savePlanning = function (model) {
            return Planning.save(model).$promise.then(function (result) {
                if (!result.hasWarnings) {
                    Message.addSuccessLabel('Static.Message.DataSaved');
                } else {
                    Message.addWarningLabel('Static.Message.NotAllDataSaved');
                }
                return planboardController.board.refreshBoard();
            });
        };

        const isUnchanged = function(item) {
            return item.length === 0;
        };

        // Around 4% is reserved for the outer margins of the planboard. Therefore, only allocate 96% of space.
        function getColumnWidth(numberOfColumns) {
            return 96.0 / numberOfColumns;
        }

        // Checks if there are changes in the planboard
        planboardController.isUnchanged = function(dirty) {
            return isUnchanged(dirty.groups) && isUnchanged(dirty.modules) && isUnchanged(dirty.offerings);
        };

        /**
         * Validates the study, causing it to transition to the next status.
         */
        planboardController.validate = function() {
            Study.validate({
                id: planboardController.studyId
            }, {}).$promise.then(function(status) {
                planboardController.study.status = '' + status.type;
                Message.addSuccess(translateFilter('Static.Message.StudyApproved'));
            });
        };

        planboardController.getStyleClasses = function (prefix, changeType) {
            return planboardController.board.getStyleClasses(prefix, changeType);
        };

        planboardController.activatePrintMode = function() {
            planboardController.onPrint();
        };

        planboardController.onFilter = function () {
            planboardController.attributeValues = [];
            delete planboardController.selectedValue;

            setFilters();

            planboardController.board.refreshBoard().then(() => {
                planboardController.board.groups.traverse(planboardController.groupLoaded);
            });
        };

        planboardController.onAttributeValue = function (attributeValue) {
            planboardController.selectedValue = attributeValue;
            planboardController.board.groups.traverse(refreshMatches);
        };

        function refreshMatches(group) {
            const id = _.get(planboardController.selectedValue, 'id');
            _.each(group.modules, (module) => {
                _.each(module.attributeValues, (attributeValue) => {
                    attributeValue.matches = attributeValue.id === id;
                });
                module.matches = _.some(module.attributeValues, { matches: true });
            });
        }

        planboardController.formatSubject = function (subject) {
            return i18nFilter(subject.names);
        };
    }
});
