'use strict';

angular.module('uasApp').component('exportsWidget', {
  templateUrl: 'es6/dashboard/exports.widget.html',
  controllerAs: 'exportsWidgetController',
  controller: function (Export, Parameter) {
    const exportsWidgetController = this;

    exportsWidgetController.$onInit = function () {
      Parameter.load().then(() => {
         if (Parameter.getParameterAsBoolean('dashboard.export', false)) {
          loadData();
         }
      });
    };

    function loadData() {
      exportsWidgetController.loading = true;
      getEntities().then((entities) => {
        exportsWidgetController.entities = entities;
      }).finally(() => {
        exportsWidgetController.loading = false;
      });
    }

    function getEntities() {
      const type = `${Parameter.getParameterValue('dashboard.export.root') || ''}`.toLowerCase();
      if (type === 'study') {
        return Export.studies().$promise;
      }
      return Export.faculties().$promise;
    }
  }
});
