'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:awardQualificationCreate
 * @description
 * Create qualification data
 */
angular.module('uasApp').component('awardQualificationCreate', {
  bindings: {
    model: '<',
    entity: '<',
    fields: '<',
    operations: '<',
    onCancel: '&',
    onSave: '&'
  },
  templateUrl: 'es6/qualification/award/award.qualification.create.html',
  controllerAs: 'awardQualificationCreateController',
  controller: function (AwardQualification) {
    const awardQualificationCreateController = this;

    awardQualificationCreateController.$onInit = function () {
      awardQualificationCreateController.activeTab = 0;
      awardQualificationCreateController.selected = false;
      awardQualificationCreateController.reset();
    };

    awardQualificationCreateController.submit = function (comment) {
      const entity = angular.copy(awardQualificationCreateController.model);
      entity.qualification = awardQualificationCreateController.qualification;

      return AwardQualification.store({
        content: entity,
        comment
      }).$promise.then(() => {
        awardQualificationCreateController.onSave({ entity });
      });
    };

    awardQualificationCreateController.cancel = function () {
      awardQualificationCreateController.onCancel();
    };

    awardQualificationCreateController.reset = function () {
      awardQualificationCreateController.qualification = {};
    };

    awardQualificationCreateController.select = function (qualification) {
      awardQualificationCreateController.qualification = qualification;
      awardQualificationCreateController.next();
    };

    awardQualificationCreateController.next = function () {
      awardQualificationCreateController.model.qualification = awardQualificationCreateController.qualification;
      awardQualificationCreateController.selected = true;
    };

    awardQualificationCreateController.previous = function () {
      awardQualificationCreateController.selected = false;
    };
  }
});
