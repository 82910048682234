'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesSpecifications
 * @description
 * Displays specifications for a faculty.
 */
angular.module('uasApp').component('facultiesSpecifications', {
  templateUrl: 'es6/faculties/faculties.specifications.html',
  bindings: {
    facultyId: '<',
    operations: '<'
  },
  controllerAs: 'specificationsController'
});
