'use strict';

angular.module('uasApp')
    .filter('entityIdToName', function($filter) {
        return function(ids, entities, property) {
            if (!ids) {
                return '';
            }
            var results = [];
            _.each(ids, function(id) {
                var entity = _.find(entities, {
                    id: id
                });
                if (entity) {
                    var result = property ? entity[property] : $filter('entityTranslate')(entity);
                    if (result) {
                        results.push(result);
                    }
                }
            });
            return _.sortBy(results).join(', ');
        };
    });
