'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Oer
 * @description
 * The Oer service.
 */
angular.module('uasApp')
    .factory('Oer', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/oer');
    });
