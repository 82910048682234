'use strict';

angular.module('uasApp').component('assetModal', {
  bindings: {
    asset: '<',
    module: '<',
    operations: '<',
    operationsToEdit: '<',
    onChange: '&',
    onCancel: '&'
  },
  templateUrl: 'es6/inventory/asset/asset.modal.html',
  controllerAs: 'modalController',
  controller: function ($q, Asset, Delivery, CustomField, Reference) {
    const modalController = this;

    modalController.$onInit = function () {
      loadData();
    };

    function loadData() {
        $q.all([
            CustomField.query({
                rootType: 'module',
                entityType: 'asset'
            }).$promise,
            Reference.query({
                typeCode: 'RECIPIENT'
            }).$promise
        ]).then(([fields, recipients]) => {
            modalController.fields_ = fields;
            modalController.fields = _(fields).sortBy('sequence').keyBy('name').value();

            const deliveries = _.result(modalController.asset, 'deliveries', []);
            modalController.recipients = _.map(recipients, (recipient) => {
                const result = angular.copy(recipient);
                const delivery = _.find(deliveries, { recipientId: recipient.id }) || {};
                return _.extend(result, { delivery: delivery });
            });
        });
    }

    modalController.save = function (entity, comment) {
      saveAsset(entity, comment).then(() => {
        const promises = saveDeliveries(comment);
        return $q.all(promises);
      }).then(modalController.onChange);
    };

    function saveDeliveries(comment) {
      return _.map(modalController.recipients, (recipient) => {
        const body = {
          id: recipient.delivery.id,
          recipientId: recipient.id,
          assetId: modalController.asset.id,
          amount: recipient.delivery.amount || 0
        };

        return Delivery.save({
          content: body,
          comment: comment
        }).$promise;
      });
    }

    function saveAsset(entity, comment) {
      const content = angular.copy(entity);

      delete content.version;
      delete content.resource;
      delete content.type;

      content.entity = {
        type: 'module',
        id: modalController.module.id
      };

      return Asset.save({ 
        content: content, 
        comment: comment 
      }).$promise.then((result) => {
        if (angular.isUndefined(modalController.asset.id)) {
          modalController.asset.id = _.result(result, 'entities[0].entity.id');
        }
        return result;
      });
    }

    modalController.close = function() {
        modalController.onCancel();
    };
  }
});