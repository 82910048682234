'use strict';

angular.module('uasApp').config(function ($stateProvider) {
  $stateProvider.state('external-systems', {
    url: '/external-systems',
    template: '<uas-external-system-list></uas-external-system-list>',
    breadcrumb: {
      label: 'Static.Page.ExternalSystems',
      root: true
    },
    admin: true
  });
});
