'use strict';

/**
 * Prevents using more than two decimals in an input.
 * Example:
 * <input type="number" ng-model="myNumber" valuta-filter>
 */
angular.module('uasApp').
directive('valutaFilter', function() {
    return {
        link: function(scope, $el) {
            $el.bind('keypress', function(e) {
                var newVal = $(this).val() +
                    (e.charCode !== 0 ? String.fromCharCode(e.charCode) : '');
                if ($(this).val().search(/(.*)\.[0-9][0-9]/) === 0 &&
                    newVal.length > $(this).val().length) {
                    e.preventDefault();
                }
            });
        }
    };
});
