'use strict';

angular.module('uasApp').component('activityPopover', {
  templateUrl: 'es6/schedule/activity/grid/activity.popover.html',
  bindings: {
    activity: '<',
    teachers: '<?'
  },
  controllerAs: 'activityPopoverController',
  controller: function() {
  }
});