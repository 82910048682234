'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Root
 * @description
 * The Root service.
 */
angular.module('uasApp').factory('Root', function (UasResource) {
    return UasResource('/roots');
  });