'use strict';

angular.module('uasApp').component('personLoginPermission', {
    templateUrl: 'es6/person/permissions/person.login.permission.html',
    controllerAs: 'personLoginPermissionController',
    bindings: {
        modal: '<',
        permission: '<?',
        onUpdate: '&'
    },
    controller: function(Person, Pageable, LoginAsPermission) {
        const personLoginPermissionController = this;

        personLoginPermissionController.$onInit = function() {
            personLoginPermissionController.pageable = Pageable.of();
            personLoginPermissionController.loadData();
        };

        personLoginPermissionController.loadData = function() {
            Person.current().$promise.then((person) => {
                if (personLoginPermissionController.permission) {
                    personLoginPermissionController.loginAsPermission = personLoginPermissionController.permission;
                } else {
                    personLoginPermissionController.loginAsPermission = {
                        person: person,
                        startDate: '',
                        endDate: '',
                        persons: []
                    };
                }
            });
        };
        
        personLoginPermissionController.search = function() {
            const params = personLoginPermissionController.pageable.build({
                text: personLoginPermissionController.personSearch || '',
                active: true
            });

            Person.page(params).$promise.then((persons) => {
                personLoginPermissionController.personsPage = persons;
            });
        };

        personLoginPermissionController.doSelectPerson = function(person, form) {
            form.$setDirty(true);

            personLoginPermissionController.loginAsPermission.persons.push(person);
            personLoginPermissionController.personSearch = '';
        };

        personLoginPermissionController.isNotSelectedPerson = function(person) {
            return !personLoginPermissionController.loginAsPermission.persons.some((p) => p.id === person.id);
        };

        personLoginPermissionController.closeModal = function() {
            personLoginPermissionController.modal.close();
        };

        personLoginPermissionController.removePerson = function(person, form) {
            form.$setDirty(true);

            personLoginPermissionController.loginAsPermission.persons = personLoginPermissionController.loginAsPermission.persons.filter((p) => 
                p.id !== person.id
            );
        };

        personLoginPermissionController.save = function() {
            const saveObject = angular.copy(personLoginPermissionController.loginAsPermission);
            saveObject.personIds = personLoginPermissionController.loginAsPermission.persons.map((person) => person.id);
            saveObject.person = saveObject.person.id;

            LoginAsPermission.store(
                saveObject
            ).$promise.then(() => {
                personLoginPermissionController.modal.close();
                personLoginPermissionController.onUpdate({});
            });
        };
   
    }
});
