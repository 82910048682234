'use strict';

angular.module('uasApp').component('uasTag', {
  bindings: {
    isReadOnly: '<?',
    onRemove: '&',
    viewClasses: '<?'
  },
  transclude: true,
  templateUrl: 'es6/fields/tag.html',
  controllerAs: 'tagController'
});
