'use strict';

angular.module('uasApp').component('uasChangesList', {
    bindings: {
        entity: '<',
        processTypeId: '<',
        hideEmpty: '<',
        showFilters: '<'
    },
    templateUrl: 'es6/changes/changes.list.html',
    controllerAs: 'changesListController',
    controller: function ($q, Enums, Pageable, Change, $uibModal) {
      const changesListController = this;

      changesListController.$onInit = function() {
        changesListController.academicYearId = sessionStorage.academicYear;

        changesListController.arguments = {
          processTypeId: changesListController.processTypeId
        };

        changesListController.pageable = Pageable.of();
        changesListController.loadData();
      };

      changesListController.loadData = function() {
        const params = buildParams();

        changesListController.loading = true;
        $q.all([
          Change.entities(params).$promise,
          Change.countByEntities(params).$promise
        ]).then(([changes, totals]) => {
          changesListController.totals = totals;
          changesListController.totalElements = _.sumBy(totals, 'numberOfChanges');
          changesListController.changes = Pageable.wrap(changes, changesListController.pageable, changesListController.totalElements);
        }).finally(() => {
          changesListController.loading = false;
        });
      };

      function buildParams() {
        return changesListController.pageable.build({
          academicYearId: sessionStorage.academicYear,
          entityType: changesListController.entity.type,
          entityId: changesListController.entity.id,
          showAll: changesListController.showAll,
          rootType: changesListController.arguments.rootType,
          changeType: changesListController.arguments.changeType,
          processTypeId: changesListController.arguments.processTypeId,
          hideEmpty: changesListController.hideEmpty
        });
      }

      changesListController.setShowAll = function(showAll) {
        changesListController.showAll = showAll;
        changesListController.loadData();
      };

      changesListController.setRootType = function(rootType) {
        if (rootType === changesListController.arguments.rootType) {
          delete changesListController.arguments.rootType;
        } else {
          changesListController.arguments.rootType = rootType;
        }

        changesListController.loadData();
      };

      changesListController.open = function(row) {
        $uibModal.open({
          size: 'lg',
          templateUrl: 'es6/changes/changes.modal.html',
          controllerAs: 'changesModalController',
          controller: function ($uibModalInstance) {
            const changesModalController = this;

            changesModalController.$onInit = function () {
              changesModalController.entity = row.entity;
              changesModalController.hideEmpty = changesListController.hideEmpty;
            };

            changesModalController.close = function () {
              $uibModalInstance.close();
            };
          }
        });
      };

      changesListController.onPage = function() {
        const params = buildParams();

        changesListController.loading = true;
        Change.entities(params).$promise.then((changes) => {
          changesListController.changes = Pageable.wrap(changes, changesListController.pageable, changesListController.totalElements);
        }).finally(() => {
          changesListController.loading = false;
        });
      };
    }
});
