'use strict';

angular.module('uasApp').component('groupsReportPage', {
  templateUrl: 'es6/group/report/groups.report.page.html',
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'groupsReportPageController',
  controller: function (Page, Language, ModuleGroup, WorkflowModal, entityRedirect) {
    const groupsReportPageController = this;

    groupsReportPageController.$onInit = function () {
      groupsReportPageController.readOnly = Page.getParameterAsBoolean(groupsReportPageController.page, 'readOnly', false);

      const modal = Page.getParameterAsBoolean(groupsReportPageController.page, 'modal', false);
      groupsReportPageController.click = modal ? open : redirect;
      groupsReportPageController.refresh = { loadData: _.noop };

      groupsReportPageController.reportTemplate = Page.getParameter(groupsReportPageController.page, 'reportTemplate');
      groupsReportPageController.hideReportTemplates = Page.getParameterAsBoolean(groupsReportPageController.page, 'hideReportTemplates', false);

      groupsReportPageController.showAll = Page.getParameterAsBoolean(groupsReportPageController.page, 'showAll', false);
      if (groupsReportPageController.showAll) {
        delete groupsReportPageController.entity;
      }
    };

    function open(row) {
      ModuleGroup.get({
        id: row.entity.id
      }).$promise.then((group) => {
        WorkflowModal.open({
          entity: group,
          onSave: groupsReportPageController.refresh.loadData,
          onCancel: groupsReportPageController.refresh.loadData
        });
      });
    }

    function redirect(row) {
      entityRedirect.go('group.general', { id: row.entity.id }, { target: '_blank' });
    }
  }
});
