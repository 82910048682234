'use strict';

angular.module('uasApp').component('subjectFields', {
  templateUrl: 'es6/subjects/subject.fields.html',
  bindings: {
    subject: '<',
    rootEntity: '<?',
    operations: '<',
    operationsToEdit: '<',
    categoryIds: '<?'
  },
  controllerAs: 'subjectFieldsController',
  controller: function (CustomField, EntityService, SubjectType, SubjectVersion) {
    const subjectFieldsController = this;

    subjectFieldsController.$onInit = function () {
      subjectFieldsController.rootEntity_ = subjectFieldsController.rootEntity || subjectFieldsController.subject.entity;
      subjectFieldsController.category = _.get(subjectFieldsController.subject, 'type.category');
      subjectFieldsController.subject.valueId = _.get(subjectFieldsController.subject, 'value.id');
      setVersion();

      loadData();
    };

    function loadData() {
      subjectFieldsController.loading = true;
      CustomField.query({
        rootType: EntityService.getType(subjectFieldsController.rootEntity_),
        entityType: 'subject'
      }).$promise.then((fields) => {
        subjectFieldsController.fields = _.sortBy(fields, 'sequence');

        setEvaluation();
      }).finally(() => {
        subjectFieldsController.loading = false;
      });
    }

    function setEvaluation() {
      const entity = {
        type: 'subject',
        id: subjectFieldsController.subject.id
      };

      subjectFieldsController.evaluation = CustomField.getEvaluation(entity, subjectFieldsController.subject, subjectFieldsController.fields);
    }

    subjectFieldsController.onGroup = function () {
      delete subjectFieldsController.subject.typeId;
      delete subjectFieldsController.type;
    };

    subjectFieldsController.onType = function () {
      SubjectType.find(subjectFieldsController.subject.typeId).$promise.then((type) => {
        subjectFieldsController.subject.type = type;
        setVersion();
      });
    };

    function setVersion() {
      const typeId = _.result(subjectFieldsController.subject, 'type.id');
      if (angular.isDefined(typeId)) {
        return SubjectVersion.query({ typeId }).$promise.then((versions) => {
          subjectFieldsController.version = _.head(versions);
        });
      } else {
        delete subjectFieldsController.version;
      }
    }
  }
});
