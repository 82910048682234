'use strict';

/**
 * Shows the loading icon when loading. Can also show textual progress when provided.
 */
angular.module('uasApp')
    .component('loaderIcon', {
        bindings: {
            icon: '<',
            loading: '<',
            progress: '<?'
        },
        templateUrl: 'es6/app/loader/loader.icon.html',
        controllerAs: 'loaderIconController'
    });
