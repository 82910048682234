'use strict';

angular.module('uasApp').component('relationsReport', {
    templateUrl: 'es6/staffing/relation/report/relations.report.html',
    bindings: {
        entity: '<?',
        operations: '<?',
        operationsToEdit: '@?',
        isTarget: '<?',
        isReadOnly: '<?',
        isVacancy: '<?',
        modal: '<?'
    },
    controllerAs: 'relationsReportController',
    controller: function (Relation, Language, EntityService, $uibModal) {
        const relationsReportController = this;

        relationsReportController.$onInit = function() {
            const entityType = _.result(relationsReportController.entity, 'self.type');
            relationsReportController.context = `${entityType}.relations`;
            relationsReportController.readOnly = relationsReportController.isReadOnly === true;
            relationsReportController.vacancy = relationsReportController.isVacancy;

            relationsReportController.onLanguage = Language.onChange(relationsReportController.loadData);
            relationsReportController.loadData();
        };

        relationsReportController.$onDestroy = function() {
            relationsReportController.onLanguage();
        };

        relationsReportController.loadData = function() {
            relationsReportController.loading = true;
            Relation.report({
                definition: true
            }).$promise.then((report) => {
                relationsReportController.columns = report.headers;
                relationsReportController.groups = report.groups;
            }).finally(() => {
                relationsReportController.loading = false;
            });
        };

        relationsReportController.getRows = function(pageable) {
            const parameters = _.extend(pageable, {
                academicYearId: sessionStorage.academicYear,
                vacancy: relationsReportController.vacancy
            });

            if (relationsReportController.isTarget === true) {
                parameters.targetType = _.result(relationsReportController.entity, 'self.type');
                parameters.targetId = _.result(relationsReportController.entity, 'id');
            } else {
                parameters.entityType = _.result(relationsReportController.entity, 'self.type');
                parameters.entityId = _.result(relationsReportController.entity, 'id');
            }

            return Relation.report(parameters).$promise;
        };

        relationsReportController.onClick = function(row) {
            if (relationsReportController.modal && !relationsReportController.readOnly) {
                Relation.getAsResult({
                    id: row.entity.id
                }).$promise.then(edit);
            } else if (!relationsReportController.modal) {
                EntityService.redirectPlain({
                    id: row.target.id,
                    type: row.target.type
                }, {
                    target: '_blank'
                });
            }
        };

        function edit(relation) {
            $uibModal.open({
                resolve: {
                    fields: function(CustomField) {
                        return CustomField.query({
                            rootType: relation.entity.type,
                            entityType: 'relation'
                        }).$promise;
                    },
                    operations: function(AuthService) {
                        const entity = relation.entity;
                        return AuthService.operations(entity.type, entity.id);
                    }
                },
                template: `
                    <uas-relation-edit
                        relation="relationModalController.relation"
                        role="relationModalController.role"
                        fields="relationModalController.fields"
                        on-save="relationModalController.onSave(relation)"
                        on-cancel="relationModalController.onCancel()"
                        operations="relationModalController.operations">
                    </uas-relation-edit>
                `,
                size: 'lg',
                controllerAs: 'relationModalController',
                controller: function($uibModalInstance, fields, operations, Message) {
                    const relationModalController = this;
                    relationModalController.relation = angular.copy(relation);
                    relationModalController.role = relation.role;
                    relationModalController.fields = fields;
                    relationModalController.operations = operations;

                    relationModalController.onSave = function(result) {
                        Relation.save({
                            comment: {
                                message: ''
                            },
                            content: result
                        }).$promise.then(() => {
                            Message.addSuccessLabel('Static.Message.DataAdjusted');
                            relationsReportController.loadData();
                        }).finally(() => $uibModalInstance.close());
                    };

                    relationModalController.onCancel = function() {
                        $uibModalInstance.close();
                    };
                }
            });
        }
    }
});