'use strict';

angular.module('uasApp').component('methodTypeListGroup', {
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    exam: '<?',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/methods/type/method.type.list.group.html',
  controllerAs: 'methodTypeListController',
  controller: function ($q, Category, MethodType) {
    const methodTypeListController = this;

    methodTypeListController.$onInit = function () {
      $q.all([
        Category.query({
          rootType: 'METHOD'
        }).$promise,
        MethodType.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([methodCategories, methodTypes]) => {
        const categoryIds = _(methodCategories).filter({ exam: methodTypeListController.exam }).map('id').value();
        methodTypeListController.methods = _.filter(methodTypes, (type) => _.includes(categoryIds, type.categoryId));
      });
    };

    methodTypeListController.select = function (method) {
      methodTypeListController.ngModel.$setViewValue(method.id);
    };
  }
});