'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCustomType
 * @description
 * uasCustomType Edit or create a custom type
 */
angular.module('uasApp')
    .component('uasCustomType', {
        bindings: {
            customType: '<'
        },
        templateUrl: 'es6/custom/types/custom.type.html',
        controllerAs: 'customTypeController',
        controller: function (CustomType, $state) {
            const customTypeController = this;

            customTypeController.$onInit = function () {
                customTypeController.tabs = [{
                    title: 'Static.Tab.CustomType.General',
                    route: 'custom-type.general',
                    visible: true
                }, {
                    title: 'Static.Tab.CustomType.Fields',
                    route: 'custom-type.fields',
                    visible: true
                }, {
                    title: 'Static.Page.Sections',
                    route: 'custom-type.sections',
                    visible: true
                }, {
                    title: 'Static.Page.ExportTypes',
                    route: 'custom-type.export',
                    visible: true
                }];

                customTypeController.customTypeId = customTypeController.customType.id;
                customTypeController.label = CustomType.getLabel(customTypeController.customType);
                
                CustomType.query({
                    rootType: customTypeController.customType.rootType
                }).$promise.then((customTypes) => {
                    customTypeController.customTypes = _(customTypes).filter({
                        invalid: false
                    }).map((customType) => {
                        const label = CustomType.getLabel(customType);
                        const isRoot = customType.entityType === customType.rootType;
                        return _.extend(customType, { label, isRoot });
                    }).orderBy(['isRoot', 'label', 'id'], ['desc', 'asc', 'asc']).value();
                });
            };

            customTypeController.onType = function() {
                $state.go($state.current.name, {
                    id: customTypeController.customTypeId
                });
            };
        }
    });
