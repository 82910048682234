'use strict';

angular.module('uasApp').component('specificationReport', {
  bindings: {
    facultyId: '<?',
    operations: '<'
  },
  templateUrl: 'es6/specification/specification.report.html',
  controllerAs: 'specificationReportController',
  controller: function ($state, CreateManager, Changes, Language, Specification) {
    const specificationReportController = this;

    specificationReportController.$onInit = function () {
      Language.onChange(loadData);

      loadData().then(() => {
        specificationReportController.isInitalised = true;
      });
    };

    specificationReportController.$onChanges = function (changes) {
      if (specificationReportController.isInitalised && Changes.hasChanged(changes, 'facultyId')) {
        setEntity();
        refreshReport();
      }
    };

    function setEntity() {
      specificationReportController.entity = {
        type: 'faculty',
        id: specificationReportController.facultyId
      };
    }

    function refreshReport() {
      specificationReportController.reload = {
        reset: true
      };
    }

    function loadData() {
      specificationReportController.loading = true;

      setEntity();

      return Specification.report({
        academicYearId: specificationReportController.academicYear,
        language: Language.get(),
        definition: true
      }).$promise.then((report) => {
        specificationReportController.groups = report.groups;
        specificationReportController.columns = report.headers;
      }).finally(() => {
        specificationReportController.loading = false;
      });
    }

    specificationReportController.getRows = function(pageable) {
      const parameters = _.extend(pageable, {
        academicYearId: specificationReportController.academicYear,
        facultyId: specificationReportController.facultyId,
        language: Language.get()
      });

      return Specification.report(parameters).$promise;
    };

    specificationReportController.onClick = function(row) {
      $state.go('specification.general', { id: row.entity.id });
    };

    specificationReportController.create = function () {
      const specification = {
        facultyId: specificationReportController.facultyId,
        academicYearId: sessionStorage.academicYear
      };

      CreateManager.open('specification', specification, specificationReportController.operations, () => {
        $state.reload();
      });
    };
  }
});
