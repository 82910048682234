'use strict';

angular.module('uasApp').factory('Tab', function (UasResource) {
  return UasResource('/tabs', {
    search: {
      method: 'GET',
      url: '/tabs/search',
      isArray: true
    }
  });
});
