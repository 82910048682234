'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:FacultiesCtrl
 * @description
 * Performance-optimized controller for (de)selecting faculties and state transitions to faculty states.
 */
angular.module('uasApp').controller('FacultiesCtrl', function ($scope, $state, AuthService, SecurityService, Faculties) {
  $scope.facultyId = Faculties.getFacultyId();
  $scope.tabs = Faculties.getCachedTabs();

  $scope.onToggle = function (faculty, isInitial = false) {
    toggleFaculty(faculty, isInitial);
    goToState();
  };

  function toggleFaculty(faculty, isInitial) {
    if (!_.isEmpty(faculty)) {
      if (isInitial || faculty.id !== $scope.facultyId) {
        return selectFaculty(faculty, faculty.id);
      }
    }

    return deselectFaculty();
  }

  function selectFaculty(faculty, facultyId) {
    $scope.faculty = faculty;
    $scope.facultyId = facultyId;
    sessionStorage.setItem('faculties.facultyId', facultyId);
    setOperations();
  }

  function setOperations() {
    $scope.loading = true;

    getOperations().then((operations) => {
      $scope.operations = operations;
      _.forEach($scope.tabs, (tab) => {
        tab.authorized = SecurityService.isAllowed(tab.operations, operations);
      });
    }).finally(() => {
      $scope.loading = false;
    });
  }

  function getOperations() {
    let entityType = 'faculty';
    let entityId = $scope.facultyId;

    if (angular.isUndefined(entityId)) {
      entityType = 'academic-year';
      entityId = sessionStorage.academicYear;
    }

    return AuthService.operations(entityType, entityId);
  }

  function deselectFaculty() {
    delete $scope.faculty;
    delete $scope.facultyId;
    delete sessionStorage['faculties.facultyId'];
    setOperations();
  }

  function goToState() {
    const target = _.result($state, 'current.name');
    if (target) {
      $state.transitionTo(target, {
        facultyId: $scope.facultyId
      });
    }
  }
});
