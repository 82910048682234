'use strict';

angular.module('uasApp').component('reportLink', {
  bindings: {
    entity: '<'
  },
  templateUrl: 'es6/report/report.link.html',
  controllerAs: 'reportLinkController',
  controller: function (Changes, EntityService) {
    const reportLinkController = this;

    reportLinkController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'entity') && reportLinkController.entity) {
        reportLinkController.entities = angular.copy(reportLinkController.entity);

        if (!_.isArray(reportLinkController.entity)) {
          reportLinkController.entities = [reportLinkController.entity];
        }

        setHrefs();
      }
    };

    function setHrefs() {
      _.forEach(reportLinkController.entities, (entity) => {
        entity.href = EntityService.getHref(entity.entity);
      });
    }

    reportLinkController.open = function (entity, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      EntityService.redirectPlain(entity.entity);
    };
  }
});
