'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:referenceSelector
 * @deprecated It seems this display value type isn't used by customers, and it's not working either.
 * ReferenceOptions should be used instead.
 */
angular.module('uasApp').component('referenceSelector', {
  bindings: {
    inputId: '@?',
    classes: '@?',
    typeId: '<',
    filter: '<?',
    onChange: '&?',
    uasDisabled: '<?',
    uasRequired: '<?',
    isReadOnly: '<?',
    viewClasses: '@?'
  },
  require: {
    securedCtrl: '?^secured',
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/reference/reference.selector.html',
  controllerAs: 'selectorController',
  controller: function ($element, $q, i18nFilter, Language, Reference, ReferenceList) {
    const selectorController = this;

    selectorController.$onInit = function () {
      selectorController.resetFilter = {};
    };

    selectorController.$onChanges = function (changes) {
      if (hasChanged(changes, 'typeId') && angular.isDefined(selectorController.typeId)) {
        $q.all([
          Reference.getActive(selectorController.typeId, selectorController.filter),
          ReferenceList.query({
            referenceTypeId: selectorController.typeId
          }).$promise
        ]).then(([references, lists]) => {
          selectorController.references = references;
          selectorController.lists = lists;

          _.forEach(lists, (list) => {
            _.forEach(list.items, (item) => {
              item.reference = _.find(references, { id: item.referenceId });
            });
          });

          selectorController.setNames();
          Language.onChange(selectorController.setNames);

          const selectedIds = _.get(selectorController.ngModelCtrl, '$modelValue', []);
          setSelected(selectedIds);

          secure();
        });
      }
    };

    function hasChanged(changes, propertyName) {
      const change = changes[propertyName];
      return change && change.currentValue !== change.previousValue;
    }


    function secure() {
      // Secure options after they have been rendered
      if (selectorController.securedCtrl) {
        selectorController.securedCtrl.resecure($element);
      }
    }

    selectorController.setNames = function () {
      _.each(selectorController.references, (reference) => {
        reference.name = i18nFilter(reference.names);
      });
    };

    selectorController.updateValues = function () {
      const selectedIds = getSelectedIds();
      setSelected(selectedIds);
      setViewValue(selectedIds);
    };

    function setSelected(selectedIds) {
      _.forEach(selectorController.references, (reference) => {
        reference.selected = _.includes(selectedIds, reference.id);
      });

      _.forEach(selectorController.lists, (list) => {
        list.selections = _.filter(list.items, (item) => {
          return _.result(item, 'reference.selected', false);
        });
      });
    }

    function getSelectedIds() {
      return _(selectorController.references)
        .flatten()
        .filter({ selected: true })
        .map('id')
        .uniq()
        .value();
    }

    function setViewValue(values) {
      selectorController.ngModelCtrl.$setViewValue(values);

      if (_.isFunction(selectorController.onChange)) {
        selectorController.onChange({ values });
      }
    }

    selectorController.selectItems = function (selected) {
      selectorController.ngModelCtrl.$setDirty();

      _.forEach(selectorController.filteredItems, (item) => {
        _.set(item, 'reference.selected', selected);
      });

      selectorController.updateValues();
    };

    selectorController.toggleDropdown = function (list) {
      selectorController.open = !selectorController.open;
      selectorController.list = list;

      selectorController.resetFilter.reset();
      setCurrentItems(list);
    };

    function setCurrentItems(list) {
      selectorController.items = _.get(list, 'items', []);
      selectorController.filterItems();
    }

    selectorController.filterItems = function (filter) {
      selectorController.filteredItems = _.filter(selectorController.items, (item) =>
        _.includes(_.toLower(item.reference.name), _.toLower(filter))
      );
    };
  }
});
