'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalTable
 * @description Displays the appraisals on a table.
 */
angular.module('uasApp').component('appraisalTable', {
    bindings: {
        entity: '<',
        parent: '<?',
        appraisals: '<',
        period: '<',
        editable: '<?',
        operations: '<',
        operationsToEdit: '<',
        active: '<?',
        navigate: '<?',
        classes: '<?',
        onToggle: '&',
        onChange: '&',
        workflowMode: '<?',
        category: '<?',
        childPage: '<?',
        columns: '<?'
    },
    templateUrl: 'es6/assessment/appraisal/appraisal.table.html',
    transclude: true,
    controllerAs: 'appraisalTableController',
    controller: function($q, Assessment, Appraisal, AppraisalModal, Column, CustomField, CreateManager, EntityType, EntityService, Events, Message, WorkflowModal) {
        const appraisalTableController = this;

        appraisalTableController.$onInit = function() {
            appraisalTableController.rootType = EntityService.getType(appraisalTableController.entity);

            loadData();
        };

        function loadData() {
            appraisalTableController.loading = true;
            return $q.all([
                EntityType.get({
                    rootType: appraisalTableController.rootType,
                    entityType: 'appraisal'
                }).$promise,
                CustomField.query({
                    rootType: appraisalTableController.rootType,
                    entityType: 'appraisal'
                }).$promise,
                CustomField.query({
                    rootType: 'assessment'
                }).$promise
            ]).then(([type, appraisalFields, assessmentFields]) => {
                appraisalTableController.includeTypes = EntityType.getIncludeTypes(type);
                appraisalTableController.fields = appraisalFields;
                
                const columnFields = _.concat(appraisalFields, assessmentFields);
                appraisalTableController.columns_ = Column.fromFields(columnFields, appraisalTableController.columns);

                setClasses();
            }).finally(() => {
                appraisalTableController.loading = false;
            });
        }

        function setClasses() {
            let assessmentCol = 5;
            if (appraisalTableController.active && !appraisalTableController.editable) {
                assessmentCol = 6;
            }
            appraisalTableController.assessmentClass = `col-xs-${assessmentCol}`;

            const typeCol = 2;
            let actionCol = 12 - typeCol - assessmentCol - appraisalTableController.columns_.length * 2;
            if (actionCol <= 0) {
                actionCol = 1;
            }
            appraisalTableController.actionClass = `col-xs-${actionCol}`;
        }

        appraisalTableController.toggle = function(appraisal, $event) {
          if (!Events.isClickableTarget($event)) {
            appraisalTableController.onToggle({ appraisal });
          }
        };

        appraisalTableController.create = function() {
            AppraisalModal.create({
                parent: appraisalTableController.parent,
                entity: appraisalTableController.entity,
                category: appraisalTableController.category,
                fields: appraisalTableController.fields,
                includeTypes: appraisalTableController.includeTypes,
                operations: appraisalTableController.operations,
                operationsToEdit: appraisalTableController.operationsToEdit,
                onChange: function(content, result) {
                    if (angular.isUndefined(content.assessmentId)) {
                        const created = _(result.entities).map('entity').map('assessment').head();
                        CreateManager.onCreate(created, onChange);
                    }

                    return onChange();
                }
            });
        };

        function onChange() {
            return appraisalTableController.onChange({
                parent: appraisalTableController.parent
            });
        }

        appraisalTableController.onActivity = function() {
            onChange();
        };

        appraisalTableController.edit = function(appraisal) {
            AppraisalModal.edit({
                appraisal,
                fields: appraisalTableController.fields,
                operations: appraisalTableController.operations,
                operationsToEdit: appraisalTableController.operationsToEdit,
                onChange
            });
        };

        appraisalTableController.editAssessment = function (assessment) {
          WorkflowModal.open({
            entityType: 'assessment',
            entity: angular.copy(assessment),
            period: appraisalTableController.period,
            onCancel: appraisalTableController.onChange,
            onSave: function(content, comment) {
              return Assessment.save({ comment, content }).$promise.then(() => {
                Message.onSaved();
                return appraisalTableController.onChange();
              });
            },
            scope: {
                operationsToEdit: appraisalTableController.operationsToEdit,
                includeTypes: appraisalTableController.includeTypes
            },
            template: `
                <assessment-modal
                    assessment="workflowModalStateController.entity"
                    include-types="workflowModalStateController.includeTypes"
                    operations="workflowModalStateController.operations"
                    operations-to-edit="workflowModalStateController.operationsToEdit"
                    on-cancel="workflowModalStateController.onCancel()"
                    on-submit="workflowModalStateController.onSave(assessment, comment)">
                </assessment-modal>
            `
          });
        };

        appraisalTableController.delete = function (appraisal, message) {
            return Appraisal.remove({
                id: appraisal.id,
                comment: {
                    message
                }
            }).$promise.then(() => {
                Message.onRemoved();
                appraisalTableController.onChange();
            });
        };
    }
});
