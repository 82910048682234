'use strict';

/* eslint-disable angular/document-service */

/**
 * @ngdoc function
 * @name uasApp.controller:documentEdit
 * @description
 * Modal to create or edit a document.
 */
angular.module('uasApp').component('documentEdit', {
  bindings: {
    document: '<',
    entity: '<',
    type: '<?',
    typeIds: '<?',
    nameFormula: '<?',
    instance: '<',
    operations: '<',
    operationsToEdit: '<',
    onSubmit: '&'
  },
  templateUrl: 'es6/document/document.edit.html',
  controllerAs: 'documentEditController',
  controller: function ($q, Document, feedbackObserver, Message, Upload) {
    const documentEditController = this;

    documentEditController.cancel = function () {
      documentEditController.instance.dismiss();
    };

    documentEditController.save = function () {
      Document.store(documentEditController.document).$promise.then((result) => {
        const id = _.get(result.entities, '[0].entity.id', documentEditController.document.id);
        return upload(id);
      }).then((result) => {
        feedbackObserver.dataChanged();
        Message.onSaved();
        return documentEditController.onSubmit({ result });
      }).finally(documentEditController.cancel);
    };

    function upload(documentId) {
      if (documentEditController.file) {
        return Upload.upload({
          url: `api/documents/${documentId}/upload`,
          data: {
            file: documentEditController.file
          }
        });
      }
      return $q.resolve();
    }
  }
});
