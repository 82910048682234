'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AuditLog
 * @description
 * The AuditLog service.
 */
angular.module('uasApp').factory('AuditLog', function ($resource, BackendService) {
  return $resource(BackendService.getBaseUrl() + '/audit-log/:id');
});
