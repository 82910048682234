'use strict';

angular.module('uasApp').component('uasPrintButton', {
  templateUrl: 'es6/app/buttons/print.button.html',
  controllerAs: 'buttonController',
  controller: function ($timeout, $window) {
    const buttonController = this;

    buttonController.openPrintPopup = function () {
      $timeout($window.print, 0);
    };
  }
});
