'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:singleDescription
 * @description
 * singleDescription Shows study details.
 */
angular.module('uasApp')
  .component('singleDescription', {
    bindings: {
      entityType: '@',
      entityId: '<',
      isReadOnly: '<?',
      viewClasses: '<?'
    },
    templateUrl: 'es6/i18n/descriptions/single.description.html',
    controllerAs: 'descriptionController',
    controller: function($q, $uibModal, Description, Language, Parameter, Changes) {
      const descriptionController = this;

      descriptionController.$onChanges = function (changes) {
        if (Changes.hasChanged(changes, 'entityId')) {
          loadData();
        }
      };

      function loadData() {
        descriptionController.loading = true;
        $q.all([
          Description.get({
            entityType: descriptionController.entityType,
            entityId: descriptionController.entityId,
            academicYearId: sessionStorage.academicYear,
            language: Language.get(),
            default: true
          }).$promise,
          Parameter.load()
        ]).then(([description]) => {
          descriptionController.type = description.type;
          descriptionController.description = _.head(description.wrappers);
          descriptionController.editor = Parameter.getParameterValue('description.editor', 'text');
        }).finally(() => {
          descriptionController.loading = false;
        });
      }

      descriptionController.isVisible = function () {
        return hasText();
      };

      function hasText() {
        const description = _.result(descriptionController.description, 'description');
        const text = _.result(description, 'text');
        return !_.isEmpty(text);
      }

      function prepare(description, entity) {
        description.typeId = descriptionController.type.id;
        description.language = Language.get();
        description.entity = entity.self;
        description.academicYearId = sessionStorage.academicYear;
      }

      descriptionController.edit = function (wrapper) {
        // Prepares the description to edit
        prepare(wrapper.description, wrapper.entity);

        $uibModal.open({
          resolve: {
            operations: function (AuthService) {
              return AuthService.operations(descriptionController.entityType, wrapper.entity.id);
            }
          },
          templateUrl: 'es6/i18n/descriptions/single.description.edit.modal.html',
          controllerAs: 'editDescriptionController',
          controller: function ($uibModalInstance, operations, SecurityService, Message) {
            const editDescriptionController = this;

            editDescriptionController.entity = wrapper.entity;
            editDescriptionController.description = angular.copy(wrapper.description);
            editDescriptionController.type = descriptionController.type;

            editDescriptionController.editor = descriptionController.editor;
            editDescriptionController.editable = SecurityService.isAllowed([
              'EDIT_DESCRIPTIONS_WORKFLOW', 'EDIT_DESCRIPTIONS'
            ], operations);


            editDescriptionController.save = function () {
              Description.update({
                comment: '',
                contents: [editDescriptionController.description]
              }).$promise.then(function () {
                Message.addSuccessLabel('Static.Message.DataAdjusted');
                $uibModalInstance.close();
              });
            };
          }
        }).result.then(loadData);
      };
    }
});
