'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Hook
 * @description
 * The Hook resource.
 */
angular.module('uasApp').factory('Hook', function (UasResource) {
  return UasResource('/invokable-hooks', {
    describe: {
      method: 'GET',
      isArray: true,
      url: '/hooks'
    },
    invoke: {
      method: 'POST',
      url: '/invokable-hooks/:id/invoke'
    }
  });
});
