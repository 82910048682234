'use strict';

angular.module('uasApp').component('subjectSelect', {
  templateUrl: 'es6/subjects/subject.select.html',
  bindings: {
    entity: '<',
    inputId: '@',
    tagsPlacement: '@?',
    showCode: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'subjectSelectController',
  controller: function (Pageable, EntityService, Subject) {
    const subjectSelectController = this;

    subjectSelectController.$onInit = function () {
        subjectSelectController.ngModelCtrl.$render = function () {
            subjectSelectController.selectedIds = subjectSelectController.ngModelCtrl.$modelValue;
        };

        loadData();
    };

    function loadData() {
        subjectSelectController.loading = true;
        return Subject.query({
            entityType: EntityService.getType(subjectSelectController.entity),
            entityId: subjectSelectController.entity.id,
            active: true
        }).$promise.then((subjects) => {
            subjectSelectController.types = _.map(subjects, 'type');
        }).finally(() => {
            subjectSelectController.loading = false;
        });
    }

    subjectSelectController.onSelect = function () {
        subjectSelectController.ngModelCtrl.$setViewValue(subjectSelectController.selectedIds);
    };

  }
});
