'use strict';

angular.module('uasApp')
    .component('methodReportPage', {
        bindings: {
            entity: '<',
            operations: '<',
            page: '<'
        },
        templateUrl: 'es6/methods/report/method.report.page.html',
        controllerAs: 'methodReportPageController'
    });