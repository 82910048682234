'use strict';

angular.module('uasApp').component('referenceLists', {
  templateUrl: 'es6/reference/reference.lists.html',
  bindings: {
    referenceType: '<?',  // Reference type or element type should be present
    elementType: '<?',
    onSelect: '&'
  },
  controllerAs: 'referenceListController',
  controller: function ($uibModal, Message, Pageable, ReferenceList, Element, Reference, entityTranslateFilter, i18nFilter) {
    const referenceListController = this;

    referenceListController.$onChanges = function () {
      referenceListController.pageable = Pageable.of({
        order: 'sequence'
      });
      referenceListController.search();
    };

    referenceListController.search = function () {
      const params = referenceListController.pageable.build({
        referenceTypeId: _.get(referenceListController.referenceType, 'id'),
        elementType: referenceListController.elementType,
        text: referenceListController.filter || ''
      });

      referenceListController.loading = true;
      return ReferenceList.search(params).$promise.then((lists) => {
        referenceListController.lists = lists;
        referenceListController.select(_.head(lists.content));
      }).finally(() => {
        referenceListController.loading = false;
      });
    };

    referenceListController.remove = function (list) {
      ReferenceList.remove(list).$promise.then(() => {
        referenceListController.search();
        Message.onRemoved();
      });
    };

    referenceListController.select = function (list) {
      if (list) {
        referenceListController.selectedId = list.id;
      }
      referenceListController.onSelect({ list });
    };

    referenceListController.create = function () {
      openListModal({
        referenceTypeId: _.get(referenceListController.referenceType, 'id'),
        elementType: referenceListController.elementType
      });
    };

    referenceListController.edit = function (list) {
      openListModal(list);
    };

    function openListModal(list) {
      $uibModal.open({
        resolve: {
          references: function () {
            if (angular.isDefined(list.referenceTypeId)) {
              return Reference.getByType(list.referenceTypeId).then((references) =>
                _.forEach(references, (reference) => reference.displayName = i18nFilter(reference.names))
              );
            } else {
              return Element.getByType(list.elementType).then((elements) =>
                _.forEach(elements, (element) => element.displayName = entityTranslateFilter(element))
              );
            }
          }
        },
        templateUrl: 'es6/reference/reference.list.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance, references) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.list = angular.copy(list);
            modalController.references = references;
          };

          modalController.close = function () {
            $uibModalInstance.close();
          };

          modalController.save = function () {
            ReferenceList.save(modalController.list).$promise.then((savedList) => {
              $uibModalInstance.dismiss();
              Message.onSaved();
              referenceListController.search().then(() =>
                referenceListController.select(savedList)
              );
            });
          };
        }
      });
    }
  }
});
