'use strict';

angular.module('uasApp').component('workflowButton', {
  bindings: {
    isDisabled: '<?',
    onClick: '&',
    btnClass: '@?'
  },
  transclude: true,
  templateUrl: 'es6/workflow/workflow.button.html',
  controllerAs: 'workflowButtonController'
});
