'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:System
 * @description
 * The System resource.
 */
angular.module('uasApp').factory('System', function (UasResource) {
  return UasResource('/actuator', {
    environment: {
      method: 'GET',
      url: '/actuator/environment',
      isArray: false
    },
    info: {
      method: 'GET',
      url: '/actuator/info',
      isArray: false
    },
    criticalHealth: {
      method: 'GET',
      url: '/actuator/health/critical',
      isArray: false
    },
    clientInfo: {
      method: 'GET',
      url: '/actuator/clientInfo',
      isArray: false
    },
    reportInfo: {
      method: 'GET',
      url: '/actuator/reportInfo',
      isArray: false
    }
  });
});
