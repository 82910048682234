'use strict';

angular.module('uasApp')
    .factory('Label', function(UasResource) {
        return UasResource('/labels', {
            load: {
                method: 'GET',
                url: '/labels/:language',
                isArray: false
            },
            search: {
                method: 'GET',
                url: '/labels/search',
                isArray: false
            }
        });
    });
