'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:creditsPage
 * @description
 * creditsPage Shows credits
 */
angular.module('uasApp')
    .component('creditsPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<'
        },
        templateUrl: 'es6/app/credits/credits.page.html',
        controllerAs: 'creditsPageController',
        controller: function() {
            const creditsPageController = this;

            creditsPageController.$onInit = function () {
                creditsPageController.operationsToEdit = angular.isDefined(creditsPageController.workflow) ? 'EDIT_CREDITS_WORKFLOW' : 'EDIT_CREDITS';
            };
        }
    });
