'use strict';

angular.module('uasApp').constant('COLUMN_TYPES', {
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  ENUM: 'ENUM',
  DAY: 'DAY',
  BOOLEAN: 'BOOLEAN',
  HTML: 'HTML',
  ICON: 'ICON'
});
