'use strict';

angular.module('uasApp')
    .directive('ctrlEscape', function() {
        return {
            restrict: 'A',
            link: function(scope, elem, attrs) {
                elem.bind('keydown', function(event) {
                    var code = event.keyCode || event.which;
                    if (code === 27) {
                            event.preventDefault();
                            scope.$apply(attrs.ctrlEscape);
                    }
                });
            }
        };
    });
