'use strict';

angular.module('uasApp').factory('SubjectType', function (UasResource) {
    return UasResource('/subject-types', {
        search: {
            method: 'GET',
            url: '/subject-types/search',
            isArray: false
        }
    });
});
