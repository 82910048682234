'use strict';

angular.module('uasApp').component('choiceTypeEdit', {
    templateUrl: 'es6/group/choice/choice.type.edit.html',
    bindings: {
        choiceType: '<',
        onSave: '&',
        onCancel: '&'
    },
    controllerAs: 'choiceTypeEditController',
    controller: function (ChoiceType) {
        const choiceTypeEditController = this;

        choiceTypeEditController.save = function() {
            ChoiceType.store(choiceTypeEditController.choiceType).$promise.then(() => {
                choiceTypeEditController.onSave();
            });
        };

        choiceTypeEditController.cancel = function() {
            choiceTypeEditController.onCancel();
        };
    }
});
