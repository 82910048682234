'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:GroupTabs
 * @description
 * # GroupTabs
 * Tabs for groups
 */
angular.module('uasApp').factory('GroupTabs', function() {
  return {
    get: function() {
      // Tabs which uses ui-router states to generate content.
      return [{
        view: 'general',
        route: 'group.general',
        readOnly: true
      }, {
        view: 'description',
        route: 'group.description'
      }, {
        view: 'credits',
        route: 'group.credits'
      }, {
        view: 'capacity',
        route: 'group.capacity'
      }, {
        view: 'additional',
        route: 'group.additional'
      }, {
        view: 'relations',
        route: 'group.relations'
      }, {
        view: 'structure',
        route: 'group.structure'
      }, {
        view: 'modules',
        route: 'group.modules'
      }, {
        view: 'changes',
        route: 'group.changes'
      }, {
        view: 'studies',
        route: 'group.studies',
        outline: false
      }, {
        view: 'admin',
        route: 'group.admin'
      }, {
        view: 'links',
        route: 'group.links'
      }];
    }
  };
});
