'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:statusLabel
 * @description
 * # StatusLabel
 * Clickable label for any entity.
 */
angular.module('uasApp')
    .directive('statusLabel', function() {
        return {
            restrict: 'E',
            scope: {
                status: '=',
                mapping: '=',
                noText: '@?'
            },
            templateUrl: 'views/directives/status-label.html',
            controller: function($scope, $filter) {
                function updateProperties() {
                    if (_.isEmpty($scope.status)) {  
                        delete $scope.labelClass;
                        delete $scope.icon;
                        delete $scope.text;
                    } else {
                        let current = getStatus($scope.status) || {};
                        $scope.labelClass = _.result(current, 'label', 'label-primary');
                        $scope.icon = _.result(current, 'icon', '');

                        if (current.text) {
                            $scope.text = current.text();
                        } else {
                            $scope.text = $filter('enum')($scope.status);
                        }
                    }
                }

                function getStatus(status) {
                    const mapping = $scope.mapping ? $scope.mapping : {};
                    if (_.isFunction(mapping)) {
                        return mapping(status);
                    } else {
                        return mapping[status];
                    }
                }

                updateProperties();
                $scope.$watch('status', updateProperties);
            }
        };
    });
