'use strict';

angular.module('uasApp').component('resourceList', {
  templateUrl: 'es6/inventory/resource/resource.list.html',
  bindings: {
    operations: '<'
  },
  controllerAs: 'resourceController',
  controller: function ($state, Pageable, EntityService, Message, Reference, Resource, translateFilter) {
    const resourceController = this;

    resourceController.$onInit = function () {
      resourceController.text = '';
      resourceController.pageable = Pageable.of({
        order: 'resource.code'
      });

      resourceController.parameters = {
      };

      resourceController.search();
      Reference.query({
        typeCode: 'RESOURCE_TYPE'
      }).$promise.then((types) => {
        resourceController.types = types;
      });
    };

    resourceController.onEntity = function(entity) {
      resourceController.parameters.entityType = _.result(entity, 'self.type');
      resourceController.parameters.entityId = _.result(entity, 'id');
    };

    resourceController.search = () => {
      const parameters = resourceController.pageable.build(
        resourceController.parameters
      );

      resourceController.loading = true;
      return Resource.search(parameters).$promise.then((resources) => {
        resourceController.resources = resources;
      }).finally(() => {
        resourceController.loading = false;
      });
    };

    resourceController.redirect = function (entity) {
      EntityService.redirect(entity);
    };

    resourceController.create = function () {
      resourceController.edit({});
    };

    resourceController.edit = function (resource) {
      $state.go('resource', { id: resource.id });
    };

    resourceController.remove = function (resource) {
      Resource.remove(resource).$promise.then(() => {
        resourceController.search();
        Message.addSuccess(translateFilter('Static.Message.DataRemoved'));
      });
    };
  }
});
