'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTransitionList
 * @description
 * statusTransitionList Shows the status transitions
 */
angular.module('uasApp').component('statusTransitionList', {
    bindings: {
        processType: '<'
    },
    templateUrl: 'es6/status/transition/status.transition.list.html',
    controllerAs: 'statusTransitionListController',
    controller: function($q, $uibModal, StatusTransition, StatusType, Message) {
        const statusTransitionListController = this;

        statusTransitionListController.$onInit = function() {
            loadData();
        };
        
        function loadData() {
            statusTransitionListController.loading = true;
            $q.all([
                StatusTransition.query({
                    processTypeId: statusTransitionListController.processType.id
                }).$promise,
                StatusType.query().$promise
            ]).then(([transitions, statuses]) => {
                statusTransitionListController.transitions = _(transitions).map((transition) => {
                    transition.source = _.find(statuses, { id: transition.sourceId });
                    transition.target = _.find(statuses, { id: transition.targetId });
                    transition.propagation = _.find(statuses, { id: transition.propagationId });
                    return transition;
                }).sortBy(['source.index', 'target.index', 'id']).value();
            }).finally(() => {
                statusTransitionListController.loading = false;
            });
        }

        statusTransitionListController.create = function() {
            open({
                commentType: 'REQUIRED'
            });
        };

        statusTransitionListController.edit = function(transition) {
            open(transition);
        };

        function open(transition) {
            $uibModal.open({
                controllerAs: 'statusTransitionModalStateController',
                controller: function ($uibModalInstance) {
                    this.transition = buildModel(transition);
                    this.processType = statusTransitionListController.processType;
                    this.instance = $uibModalInstance;
                },
                size: 'lg',
                template: `
                    <status-transition-modal 
                        transition="statusTransitionModalStateController.transition" 
                        process-type="statusTransitionModalStateController.processType" 
                        instance="statusTransitionModalStateController.instance">
                    </status-transition-modal>
                `
            }).result.then(loadData, loadData);
        }

        function buildModel(transition) {
            const model = angular.copy(transition);
            model.hookId = _.result(transition, 'hook.id');
            return model;
        }

        statusTransitionListController.remove = function(transition) {
            StatusTransition.remove({ id: transition.id }).$promise.then(() => {
                Message.onRemoved();
                loadData();
            });
        };
    }
});
