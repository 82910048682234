'use strict';

angular.module('uasApp').component('allocationInput', {
    templateUrl: 'es6/staffing/relation/allocation/allocation.input.html',
    bindings: {
        allocationType: '<',
        isReadOnly: '<?',
        viewClasses: '<?',
        inputClasses: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'allocationInputController',
    controller: function() {
        const allocationInputController = this;

        allocationInputController.$onInit = function() {
            allocationInputController.ngModelCtrl.$render = function () {
                allocationInputController.value = allocationInputController.ngModelCtrl.$modelValue;
            };

            allocationInputController.labels = {
                'ASSIGNMENT_PERCENTAGE': '%',
                'AMOUNT_OF_STUDENTS': '#',
                'AMOUNT_OF_HOURS': 'hr.'
            };
        };

        allocationInputController.onChange = function() {
            allocationInputController.ngModelCtrl.$setViewValue(allocationInputController.value);
        };
    }
});