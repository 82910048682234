'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:booleanOptions
 * @description Shows select input with boolean options.
 */
angular.module('uasApp').component('booleanOptions', {
    bindings: {
        inputId: '@?',
        noSelectLabel: '@?',
        viewClasses: '@?',
        showUndefinedOptions: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/app/filter/boolean.options.html',
    controllerAs: 'booleanOptionsController',
    controller: function() {
        const booleanOptionsController = this;

        booleanOptionsController.$onInit = function () {
            booleanOptionsController.ngModelCtrl.$render = function () {
                booleanOptionsController.value = booleanOptionsController.ngModelCtrl.$modelValue || undefined;
            };
        };

        booleanOptionsController.onChange = function () {
            booleanOptionsController.ngModelCtrl.$setViewValue(booleanOptionsController.value);
        };
    }
});
