'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasFacultiesGroups
 * @description Displays the faculty groups.
 */
angular.module('uasApp').component('uasFacultiesGroups', {
  templateUrl: 'es6/faculties/faculties.groups.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'groupsController'
});
