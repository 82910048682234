'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasHourReport
 * @description
 * uasHourReport shows hours per person/activity type for a study
 */
angular.module('uasApp')
  .component('uasHourReport', {
    bindings: {
      report: '<',
      operations: '<',
      entityId: '@',
      generate: '&?'
    },
    templateUrl: 'es6/staffing/worklog/report/hour.report.html',
    controllerAs: 'hourReportController',
    controller: function ($q, $state, Pageable) {
      const hourReportController = this;

      hourReportController.calculate = function() {
        hourReportController.loading = true;
        hourReportController.generate().finally(() => {
          hourReportController.loading = false;
          $state.reload();
        });
      };

      hourReportController.$onInit = function() {
        hourReportController.pageable = Pageable.of({
          order: 'code'
        });

        hourReportController.stateName = $state.current.name;
        hourReportController.open = false;
        
        loadData();
      };

      function loadData() {
        hourReportController.loading = true;
        $q.all([
          hourReportController.report,
          hourReportController.operations
        ]).then((results) => {
          hourReportController.lines = results[0].lines;
          hourReportController.operations = results[1];
          setIndentation(hourReportController.lines, 1);
        }).finally(() => {
          hourReportController.loading = false;
        });
      }

      function setIndentation(lines, indentation) {
        _.each(lines, (line) => {
          line.level = indentation;
          setIndentation(line.children, indentation + 1);
        });
      }

      hourReportController.expandAll = function () {
        const open = hourReportController.open !== true;
        expandRecursively(hourReportController.lines, open);
        hourReportController.open = open;
      };

      function expandRecursively(lines, open) {
        _.each(lines, (line) => {
          line.open = open;
          expandRecursively(line.children, open);
        });
      }
    }
  });
