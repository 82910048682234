'use strict';

angular.module('uasApp').component('linkType', {
  bindings: {
    type: '<',
    direction: '<'
  },
  templateUrl: 'es6/link/type/link.type.html',
  controllerAs: 'linkTypeController'
});
