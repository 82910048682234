'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Relation
 * @description
 * The Relation service.
 */
angular.module('uasApp')
    .factory('Relation', function(UasResource) {
        return UasResource('/relation', {
            getAsResult: {
                url: '/relation/:id/result',
                method: 'GET',
                isArray: false
            },
            teachers: {
                method: 'GET',
                isArray: true,
                url: '/relation/teachers'
            },
            vacancies: {
                method: 'GET',
                isArray: true,
                url: '/relation/vacancies'
            },
            report: {
                url: '/relations-report',
                method: 'GET',
                isArray: false
            }
        });
    });
