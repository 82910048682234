'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:authorized
 * @description Only show a component when authorized.
 */
angular.module('uasApp')
    .component('authorized', {
        bindings: {
            requested: '@',
            operations: '<',
            showError: '<?'
        },
        templateUrl: 'es6/security/authorized.html',
        transclude: true,
        controllerAs: 'authorizedController',
        controller: function (SecurityService) {
            const authorizedController = this;

            authorizedController.$onChanges = function() {
                authorizedController.requiredOperations = SecurityService.getOperations(authorizedController.requested);
                authorizedController.authorized = SecurityService.isAllowed(authorizedController.requiredOperations, authorizedController.operations);
                authorizedController.isShowError = authorizedController.showError === true;
            };
        }
    });