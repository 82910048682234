'use strict';

angular.module('uasApp').component('customFieldHeader', {
  bindings: {
    field: '<',
    labelKey: '@?',
    viewClasses: '<?'
  },
  templateUrl: 'es6/fields/custom.field.header.html',
  controllerAs: 'fieldHeaderController',
  controller: function(Language, CustomField) {
    const fieldHeaderController = this;

    fieldHeaderController.$onInit = function() {
        fieldHeaderController.onLanguage = Language.onChange(setLabel);
        setLabel();
    };

    fieldHeaderController.$onDestroy = function() {
        fieldHeaderController.onLanguage();
    };

    function setLabel() {
        fieldHeaderController.label = CustomField.getLabel(fieldHeaderController.field, fieldHeaderController.labelKey);
    }
  }
});
