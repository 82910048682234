'use strict';

/**
 * @ngdoc filter
 * @name uasApp.filter:reference
 * @description Translate reference ids to names.
 * @param {string} identifier The reference id.
 * @param {Array} references The reference list. Should already be filtered on type for performance.
 * @returns {string} The translated reference name.
 */
angular.module('uasApp').filter('reference', function (i18nFilter) {
  function translate(identifier, references) {
    if (angular.isUndefined(identifier)) {
      return '';
    } else if (_.isArray(identifier)) {
      return _(identifier).map((it) => translate(it, references)).uniq().sort().join(', ');
    }

    const found = _.find(references, { id: identifier });
    return found ? i18nFilter(found.names) : identifier;
  }

  return translate;
});
