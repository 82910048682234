'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:dropdownSelectPicker
 * @description
 * Shows part of a custom button to toggle a dropdown select,
 * indicating the amount of options selected of total.
 */
angular.module('uasApp').component('dropdownSelectPicker', {
  bindings: {
    btnLabel: '@?',
    amountSelected: '<',
    amountTotal: '<'
  },
  template: '<span>{{ dropdownSelectPickerController.label }}</span>',
  controllerAs: 'dropdownSelectPickerController',
  controller: function (translateFilter) {
    const dropdownSelectPickerController = this;

    dropdownSelectPickerController.$onChanges = function () {
      if (dropdownSelectPickerController.amountSelected) {
        dropdownSelectPickerController.label = dropdownSelectPickerController.btnLabel
          ? `${dropdownSelectPickerController.btnLabel}:`
          : translateFilter('Static.Input.DropdownSelect.Options.Amounts', {
            amountSelected: dropdownSelectPickerController.amountSelected,
            amountAvailable: dropdownSelectPickerController.amountTotal
          });
        return;
      }

      dropdownSelectPickerController.label = dropdownSelectPickerController.btnLabel
        ? translateFilter('Static.AttributeSelector.Action.Select', { name: dropdownSelectPickerController.btnLabel })
        : translateFilter('Static.Placeholder.NoOptionSelected');
    };
  }
});
