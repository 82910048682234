'use strict';

angular.module('uasApp').component('availabilityFilter', {
  bindings: {
    onSearch: '&',
    defaultOpen: '<'
  },
  templateUrl: 'es6/staffing/availability/availability.filter.html',
  controllerAs: 'availabilityFilterController',
  controller: function ($q, RoleType, Study) {
    const availabilityFilterController = this;

    availabilityFilterController.$onInit = function () {
      loadData().then(() => {
        availabilityFilterController.search();
      });
    };

    function loadData() {
      return $q.all([
        RoleType.query({
          academicYearId: sessionStorage.academicYear,
          entityType: 'assignment'
        }).$promise,
        Study.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([roles, studies]) => {
        availabilityFilterController.roles = roles;
        availabilityFilterController.studies = studies;
      });
    }

    availabilityFilterController.search = function () {
      availabilityFilterController.onSearch({
        params: {
          studyId: availabilityFilterController.studyId,
          term: availabilityFilterController.term,
          roleId: availabilityFilterController.roleIds,
          attention: availabilityFilterController.attention,
          complete: availabilityFilterController.complete,
          vacancy: availabilityFilterController.vacancy
        }
      });
    };
  }
});