'use strict';

angular.module('uasApp').filter('entityTranslate', function($filter, i18nFilter, Language, EntityTextHolder) {
    return function(value, localParam, englishParam, language) {
        if (!value) {
            return '';
        } else if (_.isArray(value)) {
            let total = [];
            _.each(value, (item) => {
                const result = $filter('entityTranslate')(item);
                if (result) {
                    total.push(result);
                }
            });
            return total.join(', ');
        }

        return translateEntity(value, localParam, englishParam, language);
    };

    function translateEntity(value, localParam, englishParam, language) {
        const request = value.self ? value.self : value;
        const found = EntityTextHolder.get(request, undefined, false);
        if (found && found.entity.name) {
            return found.entity.name;
        }

        if (language === undefined) {
            language = Language.get();
        }

        let result;
        if (value.names !== undefined) {
            result = i18nFilter(value.names, language);
        } else if (value.labels !== undefined) {
            result = i18nFilter(value.labels, language);
        } else if (value.titles !== undefined) {
            result = i18nFilter(value.titles, language);
        } else {
            const localParamName = localParam || 'localName';
            const englishParamName = englishParam || 'englishName';

            if (language === 'EN') {
                result = value[englishParamName] || value[localParamName];
            } else {
                result = value[localParamName] || value[englishParamName];
            }
        }

        return result || value.name || value.fullName;
    }
});
