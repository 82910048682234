'use strict';

angular.module('uasApp').component('taskFormulaEdit', {
    templateUrl: 'es6/staffing/task/template/task.formula.edit.html',
    bindings: {
        template: '<',
        formula: '<',
        onSubmit: '&',
        onCancel: '&'
    },
    controllerAs: 'taskFormulaEditController',
    controller: function(Category) {
        const taskFormulaEditController = this;

        taskFormulaEditController.$onInit = function() {
            Category.query({
                rootType: 'METHOD'
            }).$promise.then((categories) => {
                taskFormulaEditController.categories = categories;
            });
        };

        taskFormulaEditController.close = function() {
            taskFormulaEditController.onCancel();
        };

        taskFormulaEditController.save = function() {
            taskFormulaEditController.onSubmit({ 
                formula: taskFormulaEditController.formula
            });
        };
    }
});
