'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ModuleGroupModule
 * @description
 * The ModuleGroupModule service.
 */
angular.module('uasApp')
    .factory('ModuleGroupModule', function(UasResource) {
        return UasResource('/module-group-module', {
            'move': {
                method: 'POST',
                url: '/module-group-module/move',
                isArray: false
            },
            'bulkUpdate': {
                method: 'POST',
                url: '/module-group-module/update',
                isArray: false
            },
            'restore': {
                method: 'PUT',
                url: '/module-group-module/:id/restore',
                isArray: false
            }
        });
    });
