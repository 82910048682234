'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:SimulationPerson
 * @description
 * The SimulationPerson service.
 */
angular.module('uasApp')
  .factory('SimulationPerson', function (UasResource) {
    return UasResource('/simulation-persons');
  });
