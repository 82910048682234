'use strict';

/**
* The change type constants for the main module 'uasApp'.
*/
angular.module('uasApp')
    .constant('CHANGE_STATUS', {
        'OPEN': {
            color: 'primary',
            icon: 'comment',
            sequence: 0
        },
        'SKIPPED': {
            color: 'default',
            icon: 'reply-all',
            sequence: 1
        },
        'REJECTED': {
            color: 'danger',
            icon: 'ban',
            sequence: 2
        },
        'APPROVED': {
            color: 'success',
            icon: 'check',
            sequence: 3
        },
        'EXPORTED': {
            color: 'info',
            icon: 'truck',
            sequence: 4
        }
});
