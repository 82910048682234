'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowModuleWorklog
 * @description
 * uasWorkflowModuleWorklog Manages the module worklog.
 */
angular.module('uasApp')
  .component('uasWorkflowModuleWorklog', {
    bindings: {
      module: '<',
      page: '<',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/module/workflow/workflow.module.worklog.html',
    controllerAs: 'workflowModuleWorklogController',
    controller: function (Page) {
      const workflowModuleWorklogController = this;

      workflowModuleWorklogController.$onInit = function () {
        workflowModuleWorklogController.readOnly = workflowModuleWorklogController.isReadOnly
          || Page.getParameterAsBoolean(workflowModuleWorklogController.page, 'readOnly', false);
      };
    }
});
