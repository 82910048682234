'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasStudyGeneral
 * @description
 * uasStudyGeneral Shows study details.
 */
angular.module('uasApp').component('uasStudyGeneral', {
    bindings: {
        study: '<',
        operations: '<'
    },
    templateUrl: 'es6/study/study.general.html',
    controllerAs: 'studyGeneralController',
    controller: function ($state, Study, Faculty, Organisation, Specification, Process, CustomField, CustomPage, Report, Oer, Qualification) {
        const studyGeneralController = this;

        studyGeneralController.$onInit = function () {
            studyGeneralController.processes = Process.query({
                academicYearId: sessionStorage.academicYear,
                rootType: 'STUDY'
            });

            studyGeneralController.credits = {
                minimum: studyGeneralController.study.minimumCredits,
                maximum: studyGeneralController.study.maximumCredits,
                optimum: studyGeneralController.study.optimumCredits
            };

            Faculty.find(studyGeneralController.study.facultyId).$promise.then((faculty) => {
                studyGeneralController.faculty = faculty;
            });

            Organisation.find(studyGeneralController.study.organisationId).$promise.then((organisation) => {
                studyGeneralController.organisation = organisation;
            });

            Specification.find(studyGeneralController.study.specificationId).$promise.then((specification) => {
                studyGeneralController.specification = specification;
            });

            Study.find(studyGeneralController.study.scopeId).$promise.then((scope) => {
                studyGeneralController.scope = scope;
            });

            Study.query({
                scopeId: studyGeneralController.study.id
            }).$promise.then((studies) => {
                studyGeneralController.studies = _.sortBy(studies, 'code');
            });

            Qualification.query({
                entityType: 'study',
                entityId: studyGeneralController.study.id
            }).$promise.then((qualifications) => {
                studyGeneralController.qualifications = _.sortBy(qualifications, 'code');
            });

            CustomField.query({
                rootType: 'study'
            }).$promise.then((fields) => {
                studyGeneralController.fields = _.keyBy(fields, 'name');
            });

            CustomPage.query({
                basePath: 'study.general'
            }).$promise.then((pages) => {
                studyGeneralController.pages = pages;
            });
        };

        studyGeneralController.downloadOer = function (study) {
            Oer.get({
                studyId: study.id
            }).$promise.then(function (result) {
                const code = study.code || study.localName;
                const fileName = code.replace(' ', '') + '-oer.csv';
                const data = _.map(result.rows, function (row) {
                    return row.data;
                });

                Report.csv(fileName, data, result.headers);
            });
        };

        studyGeneralController.onStatus = function () {
            $state.reload();
        };
    }
});
