'use strict';

angular.module('uasApp').component('motivation', {
    templateUrl: 'es6/custom/motivation.html',
    bindings: {
        commentType: '<?',
        entityType: '@?',
        rootType: '@?',
        focus: '<',
        classes: '@?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'motivationController',
    controller: function(EntityType) {
        const motivationController = this;

        motivationController.$onInit = function() {
            motivationController.ngModelCtrl.$render = function () {
                motivationController.message = motivationController.ngModelCtrl.$modelValue;
            };

            if (_.isEmpty(motivationController.commentType)) {
                motivationController.commentType = motivationController.commentType || 'NEVER';
                if (motivationController.rootType && motivationController.entityType) {
                    EntityType.get({ 
                        rootType: motivationController.rootType, 
                        entityType: motivationController.entityType 
                    }).$promise.then((customType) => {
                        motivationController.commentType = customType.commentType;
                    });
                }
            }
        };

        motivationController.update = function(message) {
            motivationController.ngModelCtrl.$setViewValue(message);
        };
    }
});
