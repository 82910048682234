'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:SchedulePreference
 * @description
 * The SchedulePreference service.
 */
angular.module('uasApp').factory('SchedulePreference', function (UasResource) {
  return UasResource('/schedule-preference', {
    'periods': {
      url: '/schedule-preference/periods',
      method: 'GET',
      isArray: true
    },
    'report': {
      url: '/schedule-preference-report',
      method: 'GET',
      isArray: true
    },
    'persons': {
      url: '/schedule-preference-report/persons',
      method: 'GET',
      isArray: true
    },
    'reportDetails': {
      url: '/schedule-preference-report/details',
      method: 'GET'
    }
  });
});
