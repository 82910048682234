'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:stickyObserver
 * @description
 * Toggles an 'is-sticky' class if the directive element is intersecting with the sentinel (stickied).
 *
 * @param {boolean=} stickyHorizontal - Optional parameter to set the orientation to horizontal instead of vertical (default).
 * @param {boolean=} stickyDisabled - Optional parameter to disable the sticky behavior.
 */
angular.module('uasApp')
  .directive('stickyObserver', function () {
    return {
      restrict: 'A',
      scope: {
        stickyHorizontal: '<?',
        stickyDisabled: '<?'
      },
      link: function (scope, $element) {
        const element = $element;

        if (scope.stickyDisabled === true) {
          element.toggleClass('sticky-disabled', true);
          return;
        }

        const sentinelClass = scope.stickyHorizontal === true ? 'sticky-sentinel-left' : 'sticky-sentinel-top';
        const sentinel = angular.element(`<div class="${sentinelClass}"></div>`);

        element.parent().before(sentinel);

        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: 0
        };

        const observerCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.target === sentinel[0]) {
              element.toggleClass('is-sticky', !entry.isIntersecting);
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(sentinel[0]);

        scope.$on('$destroy', () => {
          observer.disconnect();
        });
      }
    };
  });
