'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:BulkModal
 * @description
 * # BulkModal
 */
angular.module('uasApp').factory('BulkModal', function($uibModal) {

    function open(args) {
        const { rows, column, onChange } = args;
        const entityType = _(rows).map('type').uniq().find();
        const entityIds = _.map(rows, 'id');

        $uibModal.open({
            resolve: {
                field: function(CustomField) {
                    return CustomField.get({
                        id: column.fieldId
                    }).$promise;
                }
            },
            template: `
                <bulk-change
                    entity-type="bulkChangeModalController.entityType"
                    entity-ids="bulkChangeModalController.entityIds"
                    field="bulkChangeModalController.field"
                    label-key="{{ bulkChangeModalController.labelKey }}"
                    on-change="bulkChangeModalController.onChange()"
                    on-cancel="bulkChangeModalController.close()">
                </bulk-change>
            `,
            controllerAs: 'bulkChangeModalController',
            controller: function($uibModalInstance, field) {
                const bulkChangeModalController = this;
                bulkChangeModalController.entityType = entityType;
                bulkChangeModalController.entityIds = entityIds;
                bulkChangeModalController.field = field;
                bulkChangeModalController.labelKey = column.name;

                bulkChangeModalController.close = function() {
                    $uibModalInstance.dismiss();
                };

                bulkChangeModalController.onChange = function() {
                    bulkChangeModalController.close();
                    onChange();
                };
            }
        });
    }

    return { open };

});
