'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Authentication
 * @description
 * The Authentication service.
 */
angular.module('uasApp')
  .factory('AuthService', function ($http, $state, $rootScope, Session, BackendService, Settings, Message, $window) {

    var instance = {};
    instance.authenticationListeners = [];

    instance.addAuthenticationListener = function (listener) {
      instance.authenticationListeners.push(listener);
    };

    instance.getConfig = function() {
      const configUrl = BackendService.getBaseUrl() + '/authentication/config';
      return $http.get(configUrl).then((result) => result.data);
    };

    instance.authenticateToken = function(token) {
      const authenticateUrl = BackendService.getBaseUrl() + '/token/authenticate';
      return $http.post(authenticateUrl, {
        token,
        logoutUrl: '/#/token?action=logout'
      }).then((result) => result.data);
    };

    instance.logout = function () {
      const logoutUrl = BackendService.getBaseUrl() + '/authentication';
      $http.delete(logoutUrl).then((result) => {
        Session.destroy();

        const data = angular.fromJson(result.data);
        const redirectUrl = _.get(data, 'logoutUrl');
        if (redirectUrl) {
          $window.location.href = redirectUrl;
        } else {
          $state.go('login');
        }
      });
    };

    instance.resecureAll = function () {
      $rootScope.$broadcast('secure');
    };

    instance.token = function () {
      return $http.post(BackendService.getBaseUrl() + '/token').then((response) => {
        return response.data.token;
      });
    };

    instance.current = function () {
      return $http.get(BackendService.getBaseUrl() + '/authentication').then((response) => {
        const authentication = response.data;
        setAuthentication(authentication);
        return authentication;
      });
    };

    instance.login = function (username, password) {
      return $http.post(BackendService.getBaseUrl() + '/authentication', {
        username,
        password
      }).then((result) => {
        const authentication = result.data;
        setAuthentication(authentication);
        return authentication;
      });
    };

    var setAuthentication = function (authentication) {
      Session.create(authentication);

      if (authentication.authenticated) {
        _.forEach(instance.authenticationListeners, (listener) => {
          listener(authentication);
        });
      }

      Settings.load(authentication.settings);
    };

    instance.setAuthentication = setAuthentication;

    instance.loginAs = function (person) {
      if (Session.restorable === true) {
        Message.addErrorLabel('Static.Page.LoginAsPermission.AlreadyLoggedInAs');
      } else {
        $state.transitionTo('dashboard').then(() => {
          const url = `${BackendService.getBaseUrl()}/login/impersonate/${person.id}`;
          $http.post(url).then(reloadDashboard);
        });
      }
    };

    instance.restore = function () {
      $state.transitionTo('dashboard').then(() => {
        const url = `${BackendService.getBaseUrl()}/logout/impersonate`;
        $http.post(url).then(reloadDashboard);
      });
    };

    instance.operations = function (entityType, entityId) {
      var url = BackendService.getBaseUrl() + '/security/operations';
      if (angular.isDefined(entityType)) {
        url += '?entityType=' + entityType;
        if (angular.isDefined(entityId)) {
          url += '&entityId=' + entityId;
        }
      }
      
      return $http.get(url).then((response) => {
        return response.data;
      });
    };

    instance.overview = function (params) {
      const paramString = _.map(params, (value, key) => value ? `${key}=${value}` : null).filter(Boolean).join('&');
      const url = `${BackendService.getBaseUrl()}/security/overview?${paramString}`;

      return $http.get(url).then((response) => {
        return response.data;
      });
    };

    instance.isAuthenticated = function () {
      return !!Session.authenticated;
    };

    instance.isAdmin = function () {
      return instance.isAuthorized(['ADMIN', 'SYSTEM_ADMIN']);
    };

    instance.isSystemAdmin = function () {
      return instance.isAuthorized(['SYSTEM_ADMIN']);
    };

    instance.isAuthorized = function (roles) {
      return instance.isAuthenticated() && hasAnyOfRoles(roles, [Session.role]);
    };

    // Returns an array containing operations required to edit, plus the 'EDIT' operation (which always allows editing of a component).
    // The first set of operations are usually passed from a parent component. (such as EDIT_XXX_WORKFLOW).
    // The second set of operation(s) will be used when there are no operations from a parent component. (such as EDIT_XXX_TAB).
    instance.buildOperationsToEdit = function (predefinedOperations, fallbackOperations) {
      let operations = angular.copy(predefinedOperations); // Copy because these might come from bindings, and we don't want Angular to update these in the parent.

      if (_.isEmpty(operations)) {
        operations = [];

        if (_.isArray(fallbackOperations)) {
          operations = (_.uniq(fallbackOperations));
        } else if (_.isString(fallbackOperations)) {
          operations.push(fallbackOperations);
        }

      } else if (_.isString(operations)) {
        operations = [operations];
      }

      return operations;
    };

    // Returns an array containing operations required to view, plus the 'VIEW' operation (which always allows viewing of a component).
    // The first set of operations are those which are required to edit (the right to edit implies the right to view).
    // The second set of operation(s) are custom operations which also grant view access (such as VIEW_RELATIONS).
    instance.buildOperationsToView = function (operationsToEdit, customOperationsToView) {
      let operations = angular.copy(operationsToEdit); // Copy because these might be used within the calling component.

      if (_.isString(customOperationsToView)) {
        operations.push(customOperationsToView);
      } else {
        operations = _.union(operations, customOperationsToView);
      }

      if (!_.includes(operations, 'VIEW')) {
        operations.push('VIEW');
      }

      return operations;
    };

    function hasAnyOfRoles(allowedRoles, grantedRoles) {
      let authorized = false;
      for (let i = 0; i < grantedRoles.length; i++) {
        if (_.includes(allowedRoles, grantedRoles[i])) {
          authorized = true;
          break;
        }
      }

      return authorized;
    }

    /*
     * After login-as or restore we want
     * to completely reload the dashboard page
     * so that all settings are retrieved again
     * (academic year, etc.)
     *
     * The body is hidden so you don't see the
     * dashboard appearing for the 'old' user.
     */
    function reloadDashboard() {
      angular.element('body').hide();
      $state.transitionTo('dashboard');
      $window.location.reload();
    }

    return instance;
  });
