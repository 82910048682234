'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:workloadReport
 * @description
 * workloadReport show the workload report
 */
angular.module('uasApp')
  .component('workloadReport', {
    bindings: {
      entity: '<',
      operations: '<',
      page: '<',
      process: '<?'
    },
    templateUrl: 'es6/staffing/workload/workload.report.html',
    controllerAs: 'workloadReportController',
    controller: function (Workload, Period, AvailabilityModal, $q) {
      const workloadReportController = this;

      workloadReportController.$onInit = function() {
        workloadReportController.academicYearId = sessionStorage.academicYear;
        workloadReportController.operationsToEdit = angular.isDefined(workloadReportController.process) ? ['EDIT_RELATIONS_WORKFLOW'] : ['EDIT_RELATIONS']; 
        
        loadPeriods();
        loadRows();
      };

      function loadPeriods() {
        return Period.query({
          academicYearId: workloadReportController.academicYearId
        }).$promise.then((periods) => {
          workloadReportController.periods = _.filter(periods, { planning: true });
        });
      }

      function loadRows() {
        workloadReportController.loading = true;
        Workload.report({
          entityType: workloadReportController.entity.self.type,
          entityId: workloadReportController.entity.id,
          periodId: workloadReportController.periodId || workloadReportController.academicYearId
        }).$promise.then((rows) => {
          workloadReportController.rows = _.orderBy(rows, ['allocatedPercentage', 'allocatedHours', 'availableHours', 'assignment.id'], ['desc', 'desc', 'desc', 'asc']);
          workloadReportController.tags = _(rows).flatMap((row) => _.keys(row.taggedHours)).uniq().sort().value();
          workloadReportController.headers = _.concat(
            { code: 'name', name: 'Name', valueType: 'STRING' },
            { code: 'availableHours', name: 'Available', valueType: 'NUMBER' },
            { code: 'allocatedHours', name: 'Allocated', valueType: 'NUMBER' },
            _.map(workloadReportController.tags, (tag) => {
              return { code: `taggedHours.${tag}`, name: _.capitalize(tag), valueType: 'NUMBER' };
            })
          );

          workloadReportController.availableHours = _.sumBy(rows, 'availableHours');
          workloadReportController.allocatedHours = _.sumBy(rows, 'allocatedHours');
          
          let taggedHours = {};
          _.forEach(rows, (row) => {
            _.forEach(row.taggedHours, (value, tag) => {
              taggedHours[tag] = (taggedHours[tag] || 0) + value;
            });
          });

          workloadReportController.taggedHours = taggedHours;
        }).finally(() => {
          workloadReportController.loading = false;
        });
      }

      workloadReportController.onPeriod = function(period) {
        workloadReportController.periodId = _.get(period, 'id');
        loadRows();
      };

      workloadReportController.onClick = function(row) {
        AvailabilityModal.open({
          assignmentId: row.assignment.id,
          operationsToEdit: workloadReportController.operationsToEdit,
          onChange: () => loadRows()
        });
      };

      workloadReportController.getRows = function() {
        const rows = _.map(workloadReportController.rows, ({ assignment, availableHours, allocatedHours, taggedHours }) => {
          return {
            name: _.get(assignment, 'person.fullName') || assignment.name,
            availableHours,
            allocatedHours,
            taggedHours
          };
        });

        return $q.resolve(rows);
      };
    }
  });
