'use strict';

angular.module('uasApp').factory('OfferingManager', function($uibModal) {
    
    function adhoc(args) {
        $uibModal.open({
            size: 'lg',
            template: `
                <offering-adhoc 
                    offering="adhocModalController.offering" 
                    calendar="adhocModalController.calendar"
                    fields="adhocModalController.fields"
                    operations="adhocModalController.operations"
                    operations-to-edit="adhocModalController.operationsToEdit"
                    on-close="adhocModalController.close()"
                    on-save="adhocModalController.save(offering, message)">
                </offering-adhoc>
            `,
            controllerAs: 'adhocModalController',
            controller: function ($uibModalInstance) {
                const adhocModalController = this;
                adhocModalController.offering = args.offering;
                adhocModalController.calendar = args.calendar;
                adhocModalController.fields = _.keyBy(args.fields, 'name');
                adhocModalController.operations = args.operations;
                adhocModalController.operationsToEdit = args.operationsToEdit || ['EDIT_OFFERINGS', 'EDIT_OFFERINGS_WORKFLOW'];

                adhocModalController.save = function(offering, message) {
                    args.onSave(offering, message);
                    adhocModalController.close();
                };

                adhocModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    function create(args) {
        const EXTENSIONS = [
            { name: 'periodId', required: true }
        ];

        $uibModal.open({
            size: 'lg',
            template: `
                <offering-create 
                    offering="createModalController.offering" 
                    calendar="createModalController.calendar"
                    fields="createModalController.fields"
                    operations="createModalController.operations"
                    operations-to-edit="createModalController.operationsToEdit"
                    on-close="createModalController.close()"
                    on-save="createModalController.save(offering, message)">
                </offering-create>
            `,
            controllerAs: 'createModalController',
            controller: function ($uibModalInstance, CustomField) {
                const createModalController = this;
                createModalController.offering = args.offering;
                createModalController.calendar = args.calendar;
                createModalController.fields = _.keyBy(CustomField.extend(args.fields, EXTENSIONS), 'name');
                createModalController.operations = args.operations;
                createModalController.operationsToEdit = args.operationsToEdit || ['EDIT_OFFERINGS', 'EDIT_OFFERINGS_WORKFLOW'];

                createModalController.save = function(offering, message) {
                    args.onSave(offering, message);
                    createModalController.close();
                };

                createModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    function edit(args) {
        $uibModal.open({
            size: 'lg',
            template: `
                <offering-edit 
                    offering="editModalController.offering" 
                    fields="editModalController.fields"
                    operations="editModalController.operations"
                    on-close="editModalController.close()"
                    on-save="editModalController.save(offering, message)">
                </offering-edit>
            `,
            controllerAs: 'editModalController',
            controller: function ($uibModalInstance) {
                const editModalController = this;
                editModalController.offering = angular.copy(args.offering);
                editModalController.fields = angular.copy(args.fields);
                editModalController.operations = args.operations;
                
                editModalController.save = function(offering, message) {
                    args.onSave(offering, message);
                    editModalController.close();
                };

                editModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    return { adhoc, create, edit };
});
