'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Publication
 * @description
 * The Publication service.
 */
angular.module('uasApp')
    .factory('Publication', function(UasResource) {
        return UasResource('/publications');
    });
