'use strict';

angular.module('uasApp').component('itemTypeSelect', {
  bindings: {
    rootType: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/item/item.type.select.html',
  controllerAs: 'itemTypeSelectController',
  controller: function ($q, EntityService, ItemType, i18nFilter) {
    const itemTypeSelectController = this;

    itemTypeSelectController.$onInit = function () {
      itemTypeSelectController.ngModelCtrl.$render = () => {
        if (itemTypeSelectController.ngModelCtrl.$modelValue) {
          loadTypes().then(() => {
            const type = _.find(itemTypeSelectController.types, {
              code: itemTypeSelectController.ngModelCtrl.$modelValue
            });
            itemTypeSelectController.typeId = _.get(type, 'id');
          });
        } else {
          delete itemTypeSelectController.type;
        }
      };
    };

    itemTypeSelectController.$onChanges = function () {
      delete itemTypeSelectController.types;
      itemTypeSelectController.entityType = EntityService.getEntityType(itemTypeSelectController.rootType);

      loadTypes();
    };

    function loadTypes() {
      if (!itemTypeSelectController.types) {
        if (!_.isEmpty(itemTypeSelectController.entityType)) {
          return ItemType.query({
            entityType: itemTypeSelectController.entityType
          }).$promise.then((types) => {
            _.forEach(types, (type) => {
              type.displayName = `${type.code} - ${i18nFilter(type.labels)}`;
            });
            itemTypeSelectController.types = _.sortBy(types, 'displayName');
          });
        } else {
          itemTypeSelectController.types = [];
        }
      }
      return $q.resolve(itemTypeSelectController.types);
    }

    itemTypeSelectController.onChange = function () {
      const type = _.find(itemTypeSelectController.types, {
        id: itemTypeSelectController.typeId
      });
      itemTypeSelectController.ngModelCtrl.$setViewValue(_.get(type, 'code'));
    };
  }
});
