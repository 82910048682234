'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processDetails
 * @description
 * processDetails Shows the process details.
 */
angular
  .module('uasApp') // eslint-disable-line angular/no-services
  .component('processDetails', {
    bindings: {
      entity: '<',
      process: '<',
      active: '<?',
      status: '<',
      statuses: '<'
    },
    templateUrl: 'es6/process/progress/process.details.html',
    controllerAs: 'processDetailsController',
    controller: function($q, RoleType, Status, Workflow) {
      const processDetailsController = this;

      processDetailsController.$onChanges = function() {
        loadData();
      };

      function loadData() {
        processDetailsController.loading = true;
        return Status.query({
          entityType: processDetailsController.entity.self.type,
          entityId: processDetailsController.entity.id
        }).$promise.then((logs) => {
          processDetailsController.logs = _(logs).filter({
            process: processDetailsController.process.id,
            type: _.result(processDetailsController.status, 'name')
          }).sortBy('creationTime').reverse().value();

          return setActive(processDetailsController.status);
        }).finally(() => {
          processDetailsController.loading = false;
        });
      }

      function setActive(status) {
        if (angular.isDefined(status)) {
          processDetailsController.status = status;
          return loadWorkflow(status);
        } else {
          delete processDetailsController.participants;
          delete processDetailsController.workflow;
          delete processDetailsController.roles;
          return $q.resolve();
        }
      }

      function loadWorkflow(status) {
        return $q.all([
          Workflow.find(status.workflowId).$promise,
          RoleType.query({
            academicYearId: processDetailsController.process.academicYearId
          }).$promise
        ]).then(([workflow, roles]) => {
          processDetailsController.workflow = workflow;

          const relations = _.result(workflow, 'relations', []);
          processDetailsController.roles = _.filter(roles, (role) => {
            return _.some(relations, { typeId: role.id });
          });
        });
      }
  }

});
