'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleStudies
 * @description
 * uasModuleStudies Shows studies overview
 */
angular.module('uasApp')
    .component('uasModuleStudies', {
        bindings: {
            moduleId: '<',
            studyId: '<?',
            isReadOnly: '<?',
            disableTreeDetailsLink: '<?'
        },
        templateUrl: 'es6/module/module.studies.html',
        controllerAs: 'studiesController',
        controller: function (Module, Parameter, Pageable) {
            const studiesController = this;

            studiesController.$onInit = function () {
              studiesController.pageable = Pageable.of({
                order: 'study.code'
              });

              Parameter.load().then(() =>
                studiesController.tableMode = Parameter.getParameterAsBoolean('module.studies.table_mode', true)
              );

              Module.studies({
                id: studiesController.moduleId
              }).$promise.then((studies) => {
                if (angular.isDefined(studiesController.studyId)) {
                  studies = _.filter(studies, { id: studiesController.studyId });
                }
                return studies;
              }).then((studies) => {
                studiesController.studies = studies;
                mergeStudiesInRoutes(studies);
                studiesController.maxGroups = _.get(_.maxBy(studiesController.routes, (route) => _.get(route, 'groups.length', 0)), 'groups');
              });

              studiesController.openStudyIds = [];
            };

            studiesController.isOpen = (study) => {
              return _.includes(studiesController.openStudyIds, study.id);
            };

            studiesController.toggle = (study) => {
              if (studiesController.isOpen(study)) {
                studiesController.openStudyIds = _.filter(studiesController.openStudyIds, (id) => id !== study.id);
              } else {
                studiesController.openStudyIds.push(study.id);
              }
            };

            studiesController.showAll = () => {
              studiesController.openStudyIds = _.map(studiesController.studies, 'id');
            };

            function mergeStudiesInRoutes (studies) {
              studiesController.routes = [];
              _.each(studies, (study) => {
                  _.each(study.routes, (route) => {
                      route.study = study;
                      route.groups.reverse();
                      studiesController.routes.push(route);
                  });
              });
            }
        }
    });
