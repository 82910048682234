'use strict';

angular.module('uasApp').component('entityInput', {
    bindings: {
        entityTypes: '<?',
        excludeIds: '<?',
        uasRequired: '<?',
        isReadOnly: '<?',
        inModal: '<?',
        showCurrent: '<?',
        onChange: '&?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/app/entity/entity.input.html',
    controllerAs: 'entityInputController',
    controller: function () {
        const entityInputController = this;

        entityInputController.$onInit = function() {
            entityInputController.ngModelCtrl.$render = () => {
                const value = entityInputController.ngModelCtrl.$viewValue;
                entityInputController.entity = value;
            };

            entityInputController.ngModelCtrl.$validators.required = (value) => {
                return entityInputController.uasRequired !== true || !isEmpty(value);
            };
        };

        function isEmpty(entity) {
            if (!entity) {
                return true;
            }

            return _.isEmpty(entity.type) || angular.isUndefined(entity.id);
        }

        entityInputController.onSelect = function(entity) {
            entityInputController.entity = _.result(entity, 'self', {});
            entityInputController.ngModelCtrl.$setViewValue(entityInputController.entity);

            if (_.isFunction(entityInputController.onChange)) {
                entityInputController.onChange({ entity });
            }
        };
    }
});