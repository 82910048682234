'use strict';

angular.module('uasApp').component('taskListPage', {
    templateUrl: 'es6/staffing/task/task.list.page.html',
    bindings: {
        entity: '<?',
        page: '<',
        workflow: '<',
        operations: '<?'
    },
    controllerAs: 'taskListPageController',
    controller: function(Page) {
        const taskListPageController = this;

        taskListPageController.$onInit = function() {
            taskListPageController.readOnly = Page.getParameterAsBoolean(taskListPageController.page, 'readOnly', false);
            taskListPageController.hidePersonal = Page.getParameterAsBoolean(taskListPageController.page, 'hidePersonal', false);
        };
    }
});
