'use strict';

angular.module('uasApp').factory('OrganisationType', function (UasResource) {
  return UasResource('/organisation-types', {
    search: {
      method: 'GET',
      url: '/organisation-types/search'
    }
  });
});
