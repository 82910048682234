'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:pageNavigator
 * @description
 * pageNavigator Navigate the content of pages
 */
angular.module('uasApp')
  .component('pageNavigator', {
    bindings: {
      pages: '<',
      descriptionId: '@',
      onToggle: '&'
    },
    templateUrl: 'es6/page/page.navigator.html',
    controllerAs: 'pageNavigatorController',
    controller: function (CustomField) {
      const pageNavigatorController = this;

      pageNavigatorController.$onInit = function () {
        prepare();
      };

      function prepare() {
        _.forEach(pageNavigatorController.pages, (page) => {
          _.forEach(page.items, (item) => {
            if (item.field) {
              setSubTypeName(item.field);
            }
          });
        });
      }

      function setSubTypeName(field) {
        field.subTypeName = CustomField.getSubTypeName(field);
      }
    }
  });
