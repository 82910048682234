'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasAdminConfig
 * @description
 * uasAdminScreen Shows admin config
 */
angular.module('uasApp')
    .component('uasAdminConfig', {
        templateUrl: 'es6/admin/admin.config.html',
        controllerAs: 'adminConfigController',
        controller: function ($q, Parameter, Message) {
            const adminConfigController = this;

            adminConfigController.$onInit = function () {
                loadData();
            };

            function loadData() {
                adminConfigController.loading = true;
                Parameter.reload().then((parameters) => {
                    adminConfigController.parameters = parameters;
                }).finally(() => {
                    adminConfigController.loading = false;
                });
            }

            adminConfigController.save = function () {
                const promises = _(adminConfigController.parameters)
                    .filter((parameter) => _.result(adminConfigController.form[parameter.name], '$dirty'))
                    .map((parameter) => Parameter.save(parameter).$promise)
                    .value();

                $q.all(promises).then(() => {
                    Message.onSaved();
                    loadData();
                });
            };
        }
    });
