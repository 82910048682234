'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasFacultyAdmin
 * @description
 * uasFacultyAdmin Shows faculty admin.
 */
angular.module('uasApp').component('uasFacultyAdmin', {
  bindings: {
    faculty: '<',
    operations: '<'
  },
  templateUrl: 'es6/organisation/faculty/faculty.admin.html',
  controllerAs: 'facultyAdminController',
  controller: function ($state, $q, CustomField, CustomPage, EntityType, Faculty, Message, Institution, FeedbackService) {
    const facultyAdminController = this;

    const EXTENSIONS = [
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' }
    ];

    facultyAdminController.$onInit = function () {
      facultyAdminController.faculty_ = angular.copy(facultyAdminController.faculty);
      loadData();
    };

    function loadData() {
      facultyAdminController.loading = false;
      $q.all([
        CustomPage.query({
          basePath: 'faculty.admin'
        }).$promise,
        CustomField.query({
          rootType: 'faculty',
          entityType: 'faculty'
        }).$promise,
        EntityType.get({
          rootType: 'faculty',
          entityType: 'faculty'
        }).$promise,
        Institution.query().$promise
      ]).then(([pages, fields, type, institutions]) => {
        facultyAdminController.pages = pages;
        facultyAdminController.fields = CustomField.extend(fields, EXTENSIONS);
        facultyAdminController.type = type;
        facultyAdminController.institutions = institutions;

        facultyAdminController.onEvaluate();
      }).finally(() => {
        facultyAdminController.loading = false;
      });
    }

    facultyAdminController.onEvaluate = function () {
      const entity = {
        type: 'faculty',
        id: _.result(facultyAdminController.faculty_, 'id')
      };
      facultyAdminController.evaluation = CustomField.getEvaluation(
        entity,
        facultyAdminController.faculty_,
        facultyAdminController.fields);
    };

    facultyAdminController.save = function (faculty, comment) {
      const body = {
        comment: comment,
        content: faculty
      };

      return Faculty.update(body).$promise.then((result) => {
        facultyAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(facultyAdminController.faculty_);
        return result;
      });
    };

    facultyAdminController.onFeatureChange = function () {
      facultyAdminController.adminForm.$setPristine();
      $state.reload();
    };

    facultyAdminController.loadFaculty = function () {
      facultyAdminController.adminForm.$setPristine();
      $state.reload();
    };

    facultyAdminController.deleteFull = function (faculty) {
      Faculty.deleteFull({
        id: faculty.id
      }).$promise.then(() => {
        Message.onRemoved();

        facultyAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };
  }
});
