'use strict';

angular.module('uasApp').factory('EffortModal', function($uibModal) {
    
    function open(args) {
        const effort = args.effort;
        const entity = effort.entity || {};

        $uibModal.open({
            templateUrl: 'es6/staffing/effort/effort.modal.html',
            size: 'xl',
            resolve: {
                operations: function(AuthService) {
                    return AuthService.operations(entity.type, entity.id);
                },
                type: function(EntityType) {
                    return EntityType.get({ rootType: entity.type });
                }
            },
            controllerAs: 'effortModalController',
            controller: function($uibModalInstance, SecurityService, type, operations) {
                const effortModalController = this;
                effortModalController.effort = angular.copy(effort);
                effortModalController.type = type;
                effortModalController.pageId = _.get(effort, 'task.pageId');
                effortModalController.operations = operations;
                effortModalController.isReadOnly = args.isReadOnly;
                effortModalController.workflowMode = args.workflowMode;
                effortModalController.periodId = args.periodId || effortModalController.effort.period.id;

                const operationsToEdit = args.workflowMode === true ? 'EDIT_TASKS_WORKFLOW' : 'EDIT_TASKS';
                effortModalController.editable = args.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, operations);

                effortModalController.onChange = function() {
                    if (_.isFunction(args.onChange)) {
                        args.onChange();
                    }
                };

                if (_.isFunction(args.onBack)) {
                    effortModalController.back = function() {
                        args.onBack();
                        effortModalController.close();
                    };
                }

                effortModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    return { open };

});
