'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityList
 * @description
 */
angular.module('uasApp').component('activityList', {
  bindings: {
    entity: '<',
    period: '<',
    categoryId: '<?',
    filterOnCategory: '<?',
    showCategory: '<?',
    hidePeriodHeader: '<?',
    operations: '<',
    template: '<?',
    isReadOnly: '<?',
    disableType: '<?',
    workflowMode: '<?',
    columns: '<?',
    onValidate: '&',
    onChange: '&'
  },
  templateUrl: 'es6/schedule/activity/list/activity.list.html',
  controllerAs: 'activityListController',
  controller: function ($q, Message, Activity, ActivityManager, SecurityService, CustomField, EntityService, Module, Offering) {
    const activityListController = this;

    const DEFAULT_COLUMNS = ['code', 'weeks', 'scheduleWeeks', 'duration', 'amount', 'totalDuration'];
    const LABELS = {
      code: 'Static.Tab.Schedule.Activities.Name',
      weeks: 'Static.Tab.Schedule.Activities.Week',
      scheduleWeeks: 'Static.Tab.Schedule.Activities.ScheduleWeek',
      amount: 'Static.Tab.Schedule.Activities.Amount',
      totalDuration: 'Static.Tab.Schedule.Activities.TotalDuration'
    };

    activityListController.$onInit = function () {
      activityListController.entityType = EntityService.getType(activityListController.entity);
      activityListController.rootType = activityListController.entityType === 'assessment' ? 'assessment' : 'method';

      const operationsToEdit = activityListController.workflowMode ? 'EDIT_SCHEDULE_WORKFLOW' : 'EDIT_SCHEDULE';
      activityListController.editable = activityListController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, activityListController.operations);

      activityListController.loading = true;
      $q.all([
        getModule(),
        CustomField.query({
          rootType: activityListController.rootType,
          entityType: 'activity'
        }).$promise
      ]).then(([module, fields]) => {
        activityListController.module = module;
        activityListController.fields = _.keyBy(fields, 'name');

        activityListController.visibleColumns = _.map(getColumns(), (column) => ({
          name: column,
          label: LABELS[column],
          field: _.find(fields, { name: column })
        }));

        return loadData();
      });
    };

    function getColumns() {
      if (_.isEmpty(activityListController.columns)) {
        return DEFAULT_COLUMNS;
      }
      return activityListController.columns;
    }

    function getModule() {
      if (activityListController.entityType === 'module') {
        return $q.resolve(activityListController.entity);
      }

      return Module.find(activityListController.entity.moduleId).$promise;
    }

    function loadData() {
      if (angular.isUndefined(activityListController.module)) {
        activityListController.loading = false;
        return [];
      }

      activityListController.loading = true;
      $q.all([
        getActivities(),
        Offering.weeks({
          entityType: 'module',
          entityId: activityListController.module.id,
          periodId: activityListController.period.id,
          exceedPeriod: true
        }).$promise
      ]).then(([activities, weeks]) => {
        activityListController.activities = _.map(activities, (activity) => {
          const weekNumbers = _.map(activity.plannings, 'week');
          activity.amount = weekNumbers.length;
          activity.actualDuration = Activity.getDuration(activity);
          activity.totalDuration = activity.actualDuration * activity.amount;
          activity.weeks = _.map(weekNumbers, (week) => _.find(weeks, { week }) || { week });
          activity.scheduleWeeks = getWeeks(weeks, weekNumbers, 'scheduleWeek');
          activity.startWeek = _.min(weekNumbers);
          activity.endWeek = _.max(weekNumbers);
          return activity;
        });

        return activityListController.validate();
      }).finally(() => {
        activityListController.loading = false;
      });
    }

    function getActivities() {
      const params = {
        entityType: EntityService.getType(activityListController.entity),
        entityId: activityListController.entity.id,
        periodId: activityListController.period.id
      };

      if (activityListController.filterOnCategory === true) {
        params.categoryId = activityListController.categoryId;
      }

      return Activity.query(params).$promise;
    }

    function getWeeks(weeks, weekNumbers, property) {
      return _(weeks).filter((week) => _.includes(weekNumbers, week.week)).map(property).value();
    }

    activityListController.validate = function () {
      const entityType = EntityService.getType(activityListController.entity);
      if (entityType === 'module') {
        Activity.validate({
          moduleId: _.result(activityListController.entity, 'id'),
          periodId: _.result(activityListController.period, 'id'),
          categoryId: activityListController.categoryId
        }).$promise.then((validation) => {
          _.forEach(activityListController.activities, (activity) => {
            activity.validation = _.find(validation.entities, (it) => it.entity.id === activity.id);
          });

          activityListController.validation = validation;
          activityListController.onValidate(validation);
        });
      } else {
        activityListController.onValidate({
          period: activityListController.period,
          valid: true
        });
      }
    };

    activityListController.create = function () {
      ActivityManager.create({
        activity: mapToBody(activityListController.template || {}),
        typeIds: _.map(activityListController.activities, (it) => _.get(it, 'type.id')),
        periodId: activityListController.period.id,
        module: activityListController.module,
        entity: activityListController.entity,
        categoryId: activityListController.categoryId,
        showCategory: activityListController.showCategory,
        disableType: activityListController.disableType,
        operations: activityListController.operations,
        onSave: save
      });
    };

    function save(activity) {
      return Activity.store(activity).$promise.then(() => {
        activityListController.onChange();
        Message.onSaved();
        loadData();
      });
    }

    activityListController.edit = function (activity) {
      ActivityManager.edit({
        activity: mapToBody(activity),
        category: activity.type.category,
        showCategory: activityListController.showCategory,
        module: activityListController.module,
        entity: activityListController.entity,
        disableType: activityListController.disableType,
        operations: activityListController.operations,
        onSave: (result) => save(result)
      });
    };

    function mapToBody(activity) {
      const body = angular.copy(activity);
      body.typeId = _.result(activity, 'type.id', activity.typeId);
      return _.omitBy(body, 'type');
    }

    activityListController.delete = function (activity) {
      const body = mapToBody(activity);
      _.forEach(body.plannings, (planning) => {
        planning.active = false;
      });

      save(body);
    };

    activityListController.onGenerate = function () {
      loadData();
    };
  }
});
