'use strict';

angular.module('uasApp').component('truncateText', {
  bindings: {
    text: '@?',
    truncate: '<?'
  },
  templateUrl: 'es6/app/truncate.text.html',
  controllerAs: 'truncateTextController'
});
