'use strict';

angular.module('uasApp').filter('decimalSeparator', function() {
    return function(input, limit) {
        input = input || 0;
        limit = limit || 0;

        return Number(input).toLocaleString('en-EN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: limit,
            useGrouping: true
        });
    };
});
