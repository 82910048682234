'use strict';

angular.module('uasApp').component('uasCredits', {
  bindings: {
    entity: '<',
    operations: '<',
    operationsToEdit: '@?',
    onParentSave: '<?' // save callback should have form callback(entity, comment)
  },
  templateUrl: 'es6/app/credits/credits.html',
  controllerAs: 'creditsController',
  controller: function ($q, Credits, CustomField, EntityService, feedbackObserver, Message, AuthService, SecurityService) {
    const creditsController = this;

    creditsController.$onInit = function () {
      creditsController.reference = EntityService.toReference(creditsController.entity);
      creditsController.operationsToEdit_ = AuthService.buildOperationsToEdit(creditsController.operationsToEdit, ['EDIT_CREDITS']);

      const editable = SecurityService.isAllowed(creditsController.operationsToEdit_, creditsController.operations);
      if (editable && creditsController.onParentSave) {
        creditsController.onParentSave.push(save);
      }

      loadData();
    };

    function loadData() {
      $q.all([
        getCredits(),
        CustomField.query({
          rootType: creditsController.reference.type,
          entityType: 'credits'
        }).$promise
      ]).then(([credits, fields]) => {
        creditsController.credits = credits;        
        creditsController.fields = fields;
        creditsController.setEvaluation();
      });
    }

    function getCredits() {
      return Credits.get({
        entityType: creditsController.reference.type,
        entityId: creditsController.reference.id
      }).$promise.then((credits) => {
        return _.extend(credits, {
          entity: creditsController.reference
        });
      });
    }

    /**
     * Function to be called from onParentSave callbacks
     */
    function save(entity, comment) {
      _.extend(creditsController.credits, {
        entity: _.get(entity, 'self')
      });
      return creditsController.save(creditsController.credits, comment);
    }

    creditsController.save = function (credits, comment) {
      if (angular.isUndefined(credits.academicYearId)) {
        credits.academicYearId = sessionStorage.academicYear;
      }

      return Credits.update({
        comment: comment,
        content: credits
      }).$promise.then((result) => {
        if (!creditsController.onParentSave) {
          feedbackObserver.dataChanged();
          Message.onSaved();
        }
        if (angular.isUndefined(creditsController.credits.id)) {
          creditsController.credits.id = result.entities[0].entity.id;
        }
        return result;
      });
    };

    creditsController.setEvaluation = function () {
      creditsController.evaluation = CustomField.getEvaluation(
        creditsController.reference,
        creditsController.credits,
        creditsController.fields
      );
    };
  }
});
