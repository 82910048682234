'use strict';

angular.module('uasApp').component('selectAll', {
  templateUrl: 'es6/app/select.all.html',
  bindings: {
    onSelect: '&?'
  },
  controllerAs: 'selectController',
  controller: function () {
    const selectController = this;

    selectController.selectAll = function (selected) {
      if (_.isFunction(selectController.onSelect)) {
        selectController.onSelect({ selected });
      }
    };
  }
});
