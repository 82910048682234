'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:planboardAttribute
 * @description Shows the planboard module attributes.
 */
angular.module('uasApp')
  .component('planboardAttribute', {
    bindings: {
      module: '<'
    },
    templateUrl: 'es6/planboard/planboard.attribute.html',
    controllerAs: 'planboardAttributeController'
  });
