'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('assessment', {
        url: '/assessment/{id:[0-9]{1,8}}',
        resolve: {
            assessment: function (Assessment, AcademicYearHolder, $stateParams) {
                const instance = this;
                return Assessment.get({
                    id: $stateParams.id
                }).$promise.then((assessment) => {
                    instance.self.data = assessment;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(assessment.academicYearId).then(() => {
                        return assessment;
                    });
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('assessment', $stateParams.id);
            }
        },
        controllerAs: 'assessmentController',
        controller: function (assessment, operations) {
            this.assessment = assessment;
            this.operations = operations;
            this.tabs = [{
                view: 'general',
                route: 'assessment.general',
                readOnly: true
            },
            {
                title: 'Static.Tab.Assessment.Admin',
                view: 'admin',
                route: 'assessment.admin',
                label: 'Static.Help.Assessment.Admin'
            }];
        },
        template: `
            <entity-tabs
                view="assessment"
                entity="assessmentController.assessment"
                tabs="assessmentController.tabs"
                retrieve-feedback-from-root="false"
                operations="assessmentController.operations">
            </entity-tabs>
        `,
        abstract: true,
        entityType: 'assessment',
        redirectTo: 'assessment.general',
        breadcrumb: {}
    });

    $stateProvider.state('assessment.general', {
        url: '/general',
        views: {
            'assessment': {
                controllerAs: 'assessmentGeneralStateController',
                controller: function (assessment, operations) {
                    this.assessment = assessment;
                    this.operations = operations;
                },
                template: `
                    <page-loader 
                        route="general" 
                        entity="assessmentGeneralStateController.assessment" 
                        operations="assessmentGeneralStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Assessment.General',
            label: 'Static.Help.Assessment.General'
        }
    });

    $stateProvider.state('assessment.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'assessment': {
                controllerAs: 'assessmentPageStateController',
                controller: function (assessment, operations, $stateParams) {
                    this.assessment = assessment;
                    this.operations = operations;
                    this.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="assessmentPageStateController.pageId" 
                        entity="assessmentPageStateController.assessment" 
                        operations="assessmentPageStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });

    $stateProvider.state('assessment.admin', {
        url: '/admin',
        views: {
            'assessment': {
                controllerAs: 'adminStateController',
                controller: function (assessment, operations) {
                    this.assessment = assessment;
                    this.operations = operations;
                },
                template: `
                    <assessment-admin
                        assessment="adminStateController.assessment"
                        operations="adminStateController.operations">
                    </assessment-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Assessment.Admin',
            label: 'Static.Help.Assessment.Admin'
        }
    });
});
