'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Dates
 * @description
 * Contains date utilities.
 */
angular.module('uasApp').factory('Dates', function (AcademicYear, $q) {

  function setActive(expirables, period) {
    return getDates(period).then((dates) => {
      return _.forEach(expirables, (expirable) => {
        expirable.active = isActive(expirable, dates);
      });
    });
  }

  function getDates(period) {
    if (_.isObject(period)) {
      return $q.resolve(period);
    }

    return AcademicYear.find(sessionStorage.academicYear).$promise.then((year) => {
      return _.defaultTo(year, {}); // Return an empty object when no year is found
    });
  }

  function isActive(expirable, period) {
    const expirableStartDate = asDate(expirable.startDate);
    const expirableEndDate = asDate(expirable.endDate);
    const periodStartDate = asDate(period.startDate);
    const periodEndDate = asDate(period.endDate);

    const started = angular.isUndefined(expirableStartDate) || expirableStartDate <= periodEndDate;
    const notEnded = angular.isUndefined(expirableEndDate) || expirableEndDate >= periodStartDate;

    return started && notEnded;
  }

  function getActive(expirables, period) {
    if (angular.isUndefined(period)) {
      return expirables;
    }

    return _.filter(expirables, (expirable) => isActive(expirable, period));
  }

  function getNumberOfWeeks(d1, d2) {
    if (angular.isUndefined(d1) || angular.isUndefined(d2)) {
        return undefined;
    }

    return Math.ceil((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  /**
   * Converts string to Date object if necessary.
   * @param {string | Date | undefined} date - The date to convert if necessary.
   * @return {Date | undefined} - The original or converted Date object, or undefined if input was too.
   * @see https://stackoverflow.com/questions/2627650/why-javascript-gettime-is-not-a-function
   */
  function asDate(date) {
    if (angular.isUndefined(date)) {
      return undefined;
    }

    if (!_.isDate(date)) {
      return new Date(date);
    }

    return date;
  }

  return {
    setActive,
    getActive,
    isActive,
    getNumberOfWeeks,
    asDate
  };

});
