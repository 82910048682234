'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:inputGroupSuffix
 * @description Shows an in-input total badge and an appended search, refresh or clear icon.
 */
angular.module('uasApp').component('inputGroupSuffix', {
  bindings: {
    loading: '<?',
    total: '<?',
    filter: '<?',
    onFilterRemove: '&'
  },
  templateUrl: 'es6/app/filter/input.group.suffix.html',
  controllerAs: 'inputGroupSuffixController',
  controller: function (Changes, Language, translateFilter) {
    const inputGroupSuffixController = this;

    inputGroupSuffixController.$onInit = function () {
      inputGroupSuffixController.onLanguage = Language.onChange(setLabels);
      setLabels();
    };

    inputGroupSuffixController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'total')) {
        setTotalLabel();
      }
    };

    function setLabels() {
      setTotalLabel();
      inputGroupSuffixController.onFilterRemoveLabel = translateFilter('Static.Input.Suffix.Action.Clear', { fallbackTo: 'Clear filter(s)' });
    }

    function setTotalLabel() {
      inputGroupSuffixController.totalLabel = translateFilter('Static.Input.Suffix.Total', {
        total: inputGroupSuffixController.total || 0,
        fallbackTo: 'Total number of results'
      });
    }
  }
});
