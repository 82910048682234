'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ChangeReportTemplate
 * @description
 * Resource for ChangeReportTemplate
 */
angular.module('uasApp').factory('ChangeReportTemplate', function(UasResource) {
    return UasResource('/change-report-templates');
});

