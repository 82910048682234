'use strict';

angular.module('uasApp')
    .component('feedbackComments', {
        bindings: {
            entityType: '@',
            entityId: '<',
            comments: '<',
            isReadOnly: '<?',
            processId: '<?',
            onScroll: '&?'
        },
        templateUrl: 'es6/changes/feedback.comments.html',
        controllerAs: 'feedbackCommentsController',
        controller: function (Comment, Message, WorkflowValidator, $state, translateFilter) {
            const feedbackCommentsController = this;

            feedbackCommentsController.$onInit = function () {
                WorkflowValidator.setValidity(() => feedbackCommentsController.commentForm);
                WorkflowValidator.onSave(() => {
                    return feedbackCommentsController.send();
                }, {}, 'NEVER');

                feedbackCommentsController.readOnly = feedbackCommentsController.isReadOnly === true;
                feedbackCommentsController.model = {
                    message: ''
                };
            };

            feedbackCommentsController.$onDestroy = function () {
                WorkflowValidator.setValidity();
            };

            feedbackCommentsController.onChange = function () {
                if (_.isEmpty(feedbackCommentsController.model.message)) {
                    feedbackCommentsController.commentForm.$setPristine();
                }
            };

            feedbackCommentsController.send = function () {
                const result = add(feedbackCommentsController.model);

                feedbackCommentsController.model.message = '';
                feedbackCommentsController.model.responseToggle = false;
                feedbackCommentsController.commentForm.$setPristine();

                return result;
            };

            function add(model) {
                return Comment.save({
                    entityType: feedbackCommentsController.entityType,
                    entityId: feedbackCommentsController.entityId
                }, {
                    message: model.message,
                    processId: feedbackCommentsController.processId,
                    link: $state.href($state.current.name, $state.params, { absolute: false })
                }).$promise.then((comment) => {
                    feedbackCommentsController.comments.push(comment);

                    Message.addSuccess(translateFilter('Static.Message.MessagePlaced'));
                    return comment;
                });
            }
        }
    });
