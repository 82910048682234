'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:News
 * @description
 * The News service.
 */
angular.module('uasApp')
    .factory('News', function(UasResource) {
        return UasResource('/news', {
            active: {
                url: '/news/active',
                method: 'GET',
                isArray: true
            }
        });
    });
