'use strict';

angular.module('uasApp').component('typeParameter', {
  bindings: {
    parameter: '<',
    infoLabel: '@?',
    getTypes: '&'
  },
  require: {
    ngModelCtrl: '^ngModel'
  },
  templateUrl: 'es6/page/config/type.parameter.html',
  controllerAs: 'typeParameterController',
  controller: function (entityTranslateFilter) {
    const typeParameterController = this;

    typeParameterController.$onInit = function () {
      typeParameterController.loading = true;

      typeParameterController.getTypes().then((types) => {
        typeParameterController.types = _(types)
          .filter((type) => !_.isEmpty(getIdentifier(type)))
          .map((type) =>
            _.extend(type, {
              label: typeLabel(type)
            })
          ).value();

        update();
      }).finally(() => {
        typeParameterController.loading = false;
      });
    };

    function typeLabel(type) {
      const prefix = getIdentifier(type);
      return `${prefix} - ${entityTranslateFilter(type)}`;
    }

    function getIdentifier(type) {
      return !_.isEmpty(type.code) ? type.code : type.externalId;
    }

    function update() {
      const values = (typeParameterController.parameter.value || '').split(',');
      const codes = _.filter(values, (value) => !_.isEmpty(value));

      typeParameterController.codes = codes;

      typeParameterController.selected = _(codes).map((code) => {
        return findType(code);
      }).filter(angular.isDefined).value();

      typeParameterController.available = _.filter(typeParameterController.types, (type) => {
        return !_.includes(codes, type.code) && !_.includes(codes, type.externalId);
      });
    }

    function findType(code) {
      let found = _.find(typeParameterController.types, { code });
      if (angular.isUndefined(found)) {
        found = _.find(typeParameterController.types, { externalId: code });
      }
      return found;
    }

    typeParameterController.onChange = function () {
      const value = _(typeParameterController.selected).map(getIdentifier).join(',');
      setValue(value);
      update();
    };

    function setValue(value) {
      typeParameterController.parameter.value = value;
      typeParameterController.ngModelCtrl.$setViewValue(value);
    }
  }
});