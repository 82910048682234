'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasStudyAdmin
 * @description
 * uasStudyAdmin Shows study admin.
 */
angular.module('uasApp').component('uasStudyAdmin', {
  bindings: {
    study: '<',
    operations: '<'
  },
  templateUrl: 'es6/study/study.admin.html',
  controllerAs: 'studyAdminController',
  controller: function ($q, $state, CustomField, CustomPage, Faculty, Message, Study, EntityType, FeedbackService) {
    const studyAdminController = this;

    const EXTENSIONS = [
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' },
      // Hide fields
      { name: 'academicYearId', visible: false },
      { name: 'changeType', visible: false },
      { name: 'created', visible: false },
      { name: 'id', visible: false },
      { name: 'importDateTime', visible: false },
      { name: 'originalCode', visible: false },
      { name: 'originalId', visible: false },
      { name: 'publicationDate', visible: false },
      { name: 'terminated', visible: false },
      { name: 'type', visible: false },
      { name: 'uid', visible: false },
      { name: 'version', visible: false }
    ];

    studyAdminController.$onInit = function () {
      studyAdminController.study_ = angular.copy(studyAdminController.study);
      loadData();
    };

    function loadData() {
      studyAdminController.loading = true;
      $q.all([
        CustomPage.query({
          basePath: 'study.admin'
        }).$promise,
        CustomField.query({
          rootType: 'study',
          entityType: 'study'
        }).$promise,
        EntityType.get({
          rootType: 'study',
          entityType: 'study'
        }).$promise,
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([pages, fields, customType, faculties]) => {
        studyAdminController.pages = pages;
        studyAdminController.fields = CustomField.extend(fields, EXTENSIONS);
        studyAdminController.customType = customType;
        studyAdminController.faculties = faculties;

        studyAdminController.onEvaluate();
      }).finally(() => {
        studyAdminController.loading = false;
      });
    }

    studyAdminController.onEvaluate = function () {
      const entity = {
        type: 'study',
        id: _.result(studyAdminController.study_, 'id')
      };
      studyAdminController.evaluation = CustomField.getEvaluation(entity, studyAdminController.study_, studyAdminController.fields);
    };

    studyAdminController.save = function (study, comment) {
      const body = {
        comment: comment,
        content: study
      };

      return Study.update(body).$promise.then((result) => {
        studyAdminController.adminForm.$setPristine();
        FeedbackService.onUpdate(studyAdminController.study_);
        studyAdminController.study_ = result;
        return result;
      });
    };

    studyAdminController.delete = function (study, comment) {
      const body = {
        comment: comment,
        id: study.id
      };

      Study.remove(body).$promise.then(function () {
        Message.onRemoved();
        $state.reload();
      });
    };

    studyAdminController.deleteFull = function (study) {
      Study.deleteFull({
        id: study.id
      }).$promise.then(function () {
        Message.onRemoved();

        studyAdminController.adminForm.$setPristine();
        $state.go('dashboard');
      });
    };

    studyAdminController.loadStudy = function () {
      studyAdminController.adminForm.$setPristine();
      $state.reload();
    };
  }
});
