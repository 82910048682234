'use strict';

angular.module('uasApp').component('generalFields', {
  bindings: {
    entity: '<'
  },
  templateUrl: 'es6/general/general.fields.html',
  controllerAs: 'generalFieldsController',
  controller: function (EntityService, General) {
    const generalFieldsController = this;

    generalFieldsController.$onChanges = () => {
      generalFieldsController.entityType = EntityService.getType(generalFieldsController.entity);
      if (generalFieldsController.entityType) {
        loadData();
      }
    };

    function loadData() {
      generalFieldsController.loading = true;
      General.query({
        entityType: generalFieldsController.entityType,
        entityId: generalFieldsController.entity.id
      }).$promise.then((items) => {
        generalFieldsController.items = items;
      }).finally(() => {
        generalFieldsController.loading = false;
      });
    }
  }
});
