'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:groupsReport
 * @description
 * # groupsReport
 * Report page for module groups
 */
angular.module('uasApp').component('groupsReport', {
    bindings: {
        entity: '<?',
        facultyId: '<?',
        clicked: '&?',
        refresh: '<?',
        isReadOnly: '<?',
        reportTemplate: '@?',
        hideReportTemplates: '<?',
        operations: '<?'
    },
    templateUrl: 'es6/group/report/groups.report.html',
    controllerAs: 'groupsReportController',
    controller: function($q, $state, CreateManager, Changes, EntityService, GroupsReport, GroupTabs, Language, ReportLinks) {
        const groupsReportController = this;

        groupsReportController.$onInit = function() {
            groupsReportController.readOnly = groupsReportController.isReadOnly === true;

            groupsReportController.mode = 'USED_STUDY';
            groupsReportController.name = 'groups';
            groupsReportController.context = 'groups';

            Language.onChange(loadData);

            if (_.isObject(groupsReportController.refresh)) {
                groupsReportController.refresh.loadData = () => loadTypesAndRefreshReport();
            }

            loadData().then(() => {
                groupsReportController.isInitalised = true;
            });
        };

        groupsReportController.$onChanges = function (changes) {
            if (groupsReportController.isInitalised && Changes.hasChanged(changes, 'entity')) {
                applyEntity();
                loadTypesAndRefreshReport();
            }
        };

        function loadData() {
            groupsReportController.loading = true;

            applyEntity();

            return $q.all([
                loadDefinitions(),
                loadTypes()
            ]).finally(() => {
                groupsReportController.loading = false;
            });
        }

        function loadDefinitions() {
            return GroupsReport.moduleGroups({
                definition: true
            }).$promise.then((report) => {
                groupsReportController.columns = report.headers;
                groupsReportController.groups = report.groups;
            });
        }

        function loadTypes() {
            return GroupsReport.types(getParameters()).$promise.then((types) => {
                groupsReportController.types = _.sortBy(types, 'sequence');
            });
        }

        function applyEntity() {
            groupsReportController.entityType = EntityService.getType(groupsReportController.entity);

            if (angular.isDefined(groupsReportController.entity)) {
                groupsReportController.name = `${groupsReportController.entity.code}-groups`;
                groupsReportController.context = `${groupsReportController.entityType}.groups`;
            }
        }

        function refreshReport() {
            groupsReportController.reload = {
                reset: true
            };
        }

        function loadTypesAndRefreshReport() {
            // When switching faculties or updating group types through workflows, the types need to be updated
            loadTypes().then(() => {
                refreshReport();
            });
        }

        groupsReportController.getRows = function(pageable) {
            const parameters = getParameters(pageable);

            return GroupsReport.moduleGroups(parameters).$promise;
        };

        function getParameters(pageable) {
            const params = {
                academicYearId: sessionStorage.academicYear,
                typeId: groupsReportController.typeId,
                language: Language.get()
            };

            if (groupsReportController.entityType === 'study') {
                if (groupsReportController.mode === 'SAME_FACULTY') {
                    params.facultyId = groupsReportController.entity.facultyId;
                } else if (groupsReportController.mode === 'OWNED_STUDY') {
                    params.studyId = groupsReportController.entity.id;
                } else {
                    params.usedStudyId = groupsReportController.entity.id;
                }
            } else if (groupsReportController.entityType) {
                const entityPath = EntityService.getEntityPath(groupsReportController.entityType);
                params[entityPath] = groupsReportController.entity.id;
            }

            return _.extend(params, pageable);
        }

        groupsReportController.onType = function(type) {
            groupsReportController.typeId = _.result(type, 'id');
            refreshReport();
        };

        groupsReportController.onMode = function(mode) {
            groupsReportController.mode = mode;
            refreshReport();
        };

        const generalState = 'group.general';
        const states = {
            general: generalState,
            additional: 'group.additional',
            description: 'group.description',
            relation: 'group.relations'
        };

        groupsReportController.onClick = function (row, col) {
            if (!groupsReportController.readOnly) {
                onClick(row, col);
            }
        };

        function onClick(row, col) {
            if (angular.isDefined(groupsReportController.clicked)) {
                groupsReportController.clicked({ row, col });
            } else {
                const state = states[col.group];
                if (!_.isEmpty(state)) {
                    ReportLinks.navigateTo(state, generalState, 'group', 'module-group', row.entity.id, GroupTabs.get());
                }
            }
        }

        groupsReportController.create = function () {
            const group = {
                facultyId: groupsReportController.facultyId,
                academicYearId: sessionStorage.academicYear
            };

            CreateManager.open('module-group', group, groupsReportController.operations, () => {
                $state.reload();
            });
        };
    }
});
