'use strict';

angular.module('uasApp')
    .component('methodCost', {
        bindings: {
            module: '<',
            categories: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/methods/method.cost.html',
        controllerAs: 'methodCostController',
        controller: function (ModuleCost) {
            const methodCostController = this;

            methodCostController.$onChanges = function() {
                if (methodCostController.module && methodCostController.categories) {
                    methodCostController.capacity = 1;
                    loadData();
                }
            };

            methodCostController.calculate = function() {
                loadData();
            };

            function loadData() {
                var currents = [];
                var originals = [];

                _(methodCostController.categories).filter({ budget: true }).each((category) => {
                    _.each(category.methods, (method) => {
                        if (method.changeType !== 'REMOVE') {
                            currents.push({
                                typeId: method.type.id,
                                code: method.content.code,
                                credits: method.content.credits
                            });
                        }

                        if (angular.isDefined(method.content.id) && method.content.created !== true) {
                            originals.push({
                                typeId: method.type.id,
                                code: method.content.code,
                                credits: _.get(method, 'originalCredits', method.content.credits)
                            });
                        }
                    });
                });

                methodCostController.loading = true;
                return ModuleCost.save({
                    id: methodCostController.module.id
                }, {
                    capacity: methodCostController.capacity,
                    currents: currents,
                    originals: originals
                }).$promise.then((cost) => {
                    methodCostController.cost = cost;
                }).finally(() => {
                    methodCostController.loading = false;
                });
            }

        }
    });
