'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:dropdownSelect
 * @description
 * Renders a dropdown select input. It allows selecting one or multiple options from the dropdown.
 * The dropdown can be grouped, or not. When not grouped, the options are displayed in two columns.
 * Also supports filtering options, reordering selected options, and executing actions upon selection.
 */
angular.module('uasApp').component('dropdownSelect', {
  bindings: {
    inputId: '@?',
    tagsPlacement: '@?',
    toggleLabel: '@?',
    toggleIcon: '@?',
    dropdownAlign: '@?',
    dropdownClasses: '<?',
    dropdownLabel: '@?',
    dropdownInModal: '<?',
    dropdownIsOpen: '<?',
    dropdownHasActions: '<?',
    options: '<',
    groups: '<?',
    searchLabel: '@?',
    selectedOptionsLabel: '@?',
    availableOptionsLabel: '@?',
    isDisabled: '<?',
    isRequired: '<?',
    isReorderable: '<?',
    multipleValues: '<?',
    sort: '<?',
    selectedSort: '<?',
    onToggleDropdown: '&?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  transclude: true,
  templateUrl: 'es6/app/forms/dropdown/dropdown.select.html',
  controllerAs: 'dropdownSelectController',
  controller: function ($timeout, Changes, translateFilter) {
    const dropdownSelectController = this;

    dropdownSelectController.$onInit = function () {
      dropdownSelectController.resetFilter = {};
      dropdownSelectController.selectedOptionsGroupIsOpen = true;

      dropdownSelectController.inputId_ = dropdownSelectController.inputId || _.uniqueId('ds-');
      dropdownSelectController.filterId = `${dropdownSelectController.inputId_}-filter`;
      dropdownSelectController.dropdownMenuId = `${dropdownSelectController.inputId_}-dropdownMenu`;
      dropdownSelectController.dropdownInModal_ = _.defaultTo(dropdownSelectController.dropdownInModal, true);

      dropdownSelectController.hasCustomToggle = dropdownSelectController.toggleLabel && dropdownSelectController.toggleIcon;
      dropdownSelectController.isReorderable_ = dropdownSelectController.isReorderable === true;
      dropdownSelectController.inlineTags = dropdownSelectController.tagsPlacement === 'inline';
      dropdownSelectController.showActions = dropdownSelectController.dropdownHasActions === true;

      dropdownSelectController.selectedOptionsLabel_ = dropdownSelectController.selectedOptionsLabel || translateFilter('Static.Input.DropdownSelect.Options.Selected');
      dropdownSelectController.availableOptionsLabel_ = dropdownSelectController.availableOptionsLabel || translateFilter('Static.Input.DropdownSelect.Options.Available');
      dropdownSelectController.searchLabel_ = dropdownSelectController.searchLabel || translateFilter('Static.Placeholder.Search');

      dropdownSelectController.ngModelCtrl.$render = function () {
        dropdownSelectController.selectedIds = dropdownSelectController.ngModelCtrl.$modelValue;
      };
    };

    dropdownSelectController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'options') && dropdownSelectController.options) {
        setupOptions();
        updateOptions();
        filterOptions(false);
      }
    };

    function setupOptions() {
      dropdownSelectController.numberOfOptions = _.size(dropdownSelectController.options);

      // Set initial sequence of selected options based on their current display order
      if (dropdownSelectController.selectedIds) {
      _.forEach(dropdownSelectController.options, (option) => {
        delete option.element.sequence;

        const index = _.findIndex(dropdownSelectController.selectedIds, (id) => id === option.element.id);
        if (index !== -1) {
          option.element.sequence = index;
        }
      });
      }
    }

    function updateOptions() {
      dropdownSelectController.selectedOptions = _(dropdownSelectController.options)
        .filter('selected')
        .map('element')
        .sortBy(dropdownSelectController.selectedSort)
        .value();

      dropdownSelectController.filteredOptions = _(dropdownSelectController.options)
        .filter((option) => !option.selected)
        .map('element')
        .sortBy(dropdownSelectController.sort)
        .value();
    }

    function updateFilteredGroups(termChanged) {
      if (angular.isUndefined(dropdownSelectController.groups)) {
        dropdownSelectController.filteredGroups = [{
          code: 'default',
          filteredOptions: dropdownSelectController.filteredOptions,
          open: termIsEmpty() || dropdownSelectController.filteredOptions.length
        }];
      } else {
        dropdownSelectController.filteredGroups = _.forEach(dropdownSelectController.groups, (group) => {
          group.filteredOptions = _.filter(dropdownSelectController.filteredOptions, (option) => {
            const groupCode = _.get(option, 'group', '');
            return groupCode === group.code;
          });

          if (termChanged) {
            group.open = !termIsEmpty() && _.size(group.filteredOptions) > 0;
          }
        });
      }
    }

    function termIsEmpty() {
      return angular.isUndefined(dropdownSelectController.term) || _.isEmpty(dropdownSelectController.term);
    }

    function filterOptions(termChanged) {
      dropdownSelectController.filteredOptions = _(dropdownSelectController.options)
        .filter((option) => {
          const selected = _.get(option, 'selected', false);
          const name = _.get(option, 'element.name', '');
          const includesTerm = _.isEmpty(dropdownSelectController.term) || name.toLowerCase().includes(dropdownSelectController.term.toLowerCase());

          return !selected && includesTerm;
        })
        .map('element')
        .sortBy(dropdownSelectController.sort)
        .value();

      updateFilteredGroups(termChanged);
    }

    dropdownSelectController.clearAndFocusFilter = function () {
      dropdownSelectController.resetFilter.reset();
      focusFilter();
    };

    function focusFilter() {
      const elements = angular.element(`#${dropdownSelectController.filterId}`);

      if (elements && elements.length) {
        $timeout(function () {
          elements[0].focus();
        });
      }
    }

    dropdownSelectController.onFilter = function (term) {
      dropdownSelectController.term = term;

      filterOptions(true);
    };

    dropdownSelectController.onRemove = function (item) {
      const option = _.find(dropdownSelectController.options, { id: item.id });
      option.selected = false;
      delete option.element.sequence;

      updateOptions();
      setSequences();
      setViewValue();
    };

    dropdownSelectController.onToggle = function (item) {
      // If the dropdown is not multiple, unselect all other options
      if (!dropdownSelectController.multipleValues) {
        _(dropdownSelectController.options)
          .filter((option) => option.id !== item.id)
          .forEach((option) => {
            option.selected = false;
          });
      }

      updateOptions();
      setSequences();
      setViewValue();

      focusFilter();
    };

    dropdownSelectController.onToggleAll = function () {
      const allSelected = dropdownSelectController.selectedOptions.length === dropdownSelectController.numberOfOptions;

      _.each(dropdownSelectController.options, (option) => {
        option.selected = !allSelected;
      });

      updateOptions();
      setViewValue();
    };

    dropdownSelectController.toggleSelectedOptionsGroup = function () {
      dropdownSelectController.selectedOptionsGroupIsOpen = dropdownSelectController.selectedOptionsGroupIsOpen !== true;
    };

    dropdownSelectController.toggleGroup = function (group) {
      group.open = group.open !== true;
    };

    dropdownSelectController.onMove = function (item, index) {
      // Remove the old (now duplicate) option
      dropdownSelectController.selectedOptions.splice(index, 1);

      setSequences();
      setViewValue();
    };

    function setSequences() {
      _.forEach(dropdownSelectController.options, (option) => {
        delete option.element.sequence;

        const index = _.findIndex(dropdownSelectController.selectedOptions, { id: option.element.id });
        if (index !== -1) {
          option.element.sequence = index;
        }
      });
    }

    function setViewValue() {
      const selectedIds = _.map(dropdownSelectController.selectedOptions, 'id');
      dropdownSelectController.ngModelCtrl.$setViewValue(selectedIds);
    }
  }
});
