'use strict';

angular.module('uasApp').component('uasDatepicker', {
  templateUrl: 'es6/app/datepicker.html',
  bindings: {
    inputName: '@',
    placeholder: '@?',
    uasRequired: '<?',
    uasDisabled: '<?',
    classes: '@?',
    isReadOnly: '<?',
    viewClasses: '<?',
    minDate: '<?',
    maxDate: '<?',
    appendToBody: '<?' // Used to position popup correctly in modal. Does not work when using modal in modal. Default = true.
  },
  require: {
    securedCtrl: '?^secured',
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'datepickerController',
  controller: function ($element) {
    const datepickerController = this;

    datepickerController.$onInit = function () {
      setModelFormatter();
      setModelRender();

      datepickerController.format = 'dd-MM-yyyy';
      datepickerController.opened = false;
      datepickerController.options = {};
      datepickerController.altInputFormats = ['d!-M!-yyyy', 'yyyy-MM-dd'];

      secure();
    };

    function setModelFormatter() {
      datepickerController.ngModelCtrl.$formatters.push((modelValue) => {
        // If model is a number then value is epoch millis and value is converted to number
        const value = parseInt(modelValue);
        if (isNumber(value)) {
          return value;
        }
        return modelValue;
      });
    }

    function isNumber(value) {
      return !isNaN(value);
    }

    function setModelRender() {
      datepickerController.ngModelCtrl.$render = () => {
        datepickerController.model = datepickerController.ngModelCtrl.$viewValue;
      };
    }

    function secure() {
      if (datepickerController.securedCtrl) {
        datepickerController.securedCtrl.resecure($element);
      }
    }

    datepickerController.isDisabled = function () {
      const inputElement = $element.find('input')[0];
      return datepickerController.uasDisabled || inputElement.disabled;
    };

    datepickerController.onChange = function () {
      datepickerController.ngModelCtrl.$setViewValue(datepickerController.model);
    };
  }
});
