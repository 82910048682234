'use strict';

angular.module('uasApp').component('qualificationFields', {
  bindings: {
    qualification: '<',
    operations: '<'
  },
  templateUrl: 'es6/qualification/qualification.fields.html',
  controllerAs: 'qualificationFieldsController',
  controller: function ($q, CustomField, Faculty, Study) {
    const qualificationFieldsController = this;

    const EXTENSIONS = [
      { name: 'code', operation: 'EDIT_CODE' },
      { name: 'externalId', operation: 'EDIT_EXTERNAL_ID' },
      { name: 'startDate', maxValue: 'endDate' },
      { name: 'endDate', minValue: 'startDate' }
    ];

    qualificationFieldsController.$onInit = function () {
      loadData();
    };

    function loadData() {
      qualificationFieldsController.loading = true;
      $q.all([
        CustomField.query({
          rootType: 'qualification',
          entityType: 'qualification'
        }).$promise,
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise,
        loadStudies()
      ]).then(([fields, faculties, studies]) => {
        qualificationFieldsController.fields = CustomField.extend(fields, EXTENSIONS);
        qualificationFieldsController.faculties = faculties;
        qualificationFieldsController.studies = studies;

        setEvaluation();
      }).finally(() => {
        qualificationFieldsController.loading = false;
      });
    }

    function setEvaluation() {
      const entity = {
        type: 'qualification',
        id: _.result(qualificationFieldsController.qualification, 'id')
      };

      qualificationFieldsController.evaluation = CustomField.getEvaluation(
        entity,
        qualificationFieldsController.qualification,
        qualificationFieldsController.fields
      );
    }

    qualificationFieldsController.onChange = function () {
      setEvaluation();
    };

    qualificationFieldsController.onEntity = function () {
      setEvaluation();
    };

    qualificationFieldsController.onFaculty = function () {
      loadStudies().then((studies) => {
        qualificationFieldsController.studies = studies;
        setEvaluation();
      });
    };

    function loadStudies() {
      if (angular.isDefined(qualificationFieldsController.qualification.facultyId)) {
        return Study.query({
          facultyId: qualificationFieldsController.qualification.facultyId
        }).$promise;
      }
      
      return $q.resolve([]);
    }
  }
});
