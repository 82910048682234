'use strict';

/**
 * List based report of changes in the faculty.
 */
angular.module('uasApp')
    .component('clusterInput', {
        bindings: {
            ngModel: '=',
            inputId: '@?'
        },
        template: `
          <input class="form-control"
                 id="{{ clusterController.inputId || 'cluster' }}"
                 name="{{ clusterController.inputId || 'cluster' }}"
                 placeholder="CS, RO-1, RO-1A..."
                 ng-model="clusterController.ngModel"
                 ng-pattern="/(^CS$)|(^RO-\\d+\\w*$)/" />
        `,        
        controllerAs: 'clusterController'
    });
