'use strict';

angular.module('uasApp').component('manualNotifications', {
  bindings: {
    entity: '<',
    operations: '<'
  },
  templateUrl: 'es6/app/notifications/manual.notifications.html',
  controllerAs: 'manualNotificationsController',
  controller: function (EmailAddress, EntityService, ManualNotification, Message, NotificationTemplate, entityTranslateFilter) {
    const manualNotificationsController = this;

    manualNotificationsController.$onInit = function () {
      manualNotificationsController.notification = emptyNotification();
      manualNotificationsController.entityType = 'module';
      manualNotificationsController.owned = false;

      manualNotificationsController.getEmailAddresses = {
        module: EmailAddress.module,
        study: EmailAddress.study
      };

      manualNotificationsController.sendNotification = {
        module: ManualNotification.module,
        study: ManualNotification.study
      };

      NotificationTemplate.manual().$promise.then((templates) => {
        manualNotificationsController.templates = templates;
      });
    };

    function emptyNotification() {
      return {
        academicYearId: sessionStorage.academicYear,
        online: true,
        mail: true
      };
    }

    manualNotificationsController.onTemplate = function (template) {
      const roleId = manualNotificationsController.notification.roleId;
      manualNotificationsController.notification = emptyNotification();
      manualNotificationsController.notification.roleId = roleId;

      if (template) {
        manualNotificationsController.notification.subjects = template.titles;
        manualNotificationsController.notification.texts = template.bodies;
        manualNotificationsController.notification.notificationTemplateId = template.id;
      }
    };

    manualNotificationsController.clearRecipients = function () {
      delete manualNotificationsController.recipients;
      delete manualNotificationsController.selectedRecipients;
    };

    manualNotificationsController.showEmailAddresses = function () {
      if (angular.isUndefined(manualNotificationsController.notification.roleId)) {
        return;
      }

      const requestParams = buildRequestParams();
      requestParams.roleId = manualNotificationsController.notification.roleId;
      manualNotificationsController.loading = true;

      const getEmailAddresses = manualNotificationsController.getEmailAddresses[manualNotificationsController.entityType];
      getEmailAddresses(requestParams).$promise.then((recipients) => {
        manualNotificationsController.recipients = _.forEach(recipients, (recipient, index) => {
          recipient.id = index;
          const entities = _(recipient.entities).map((entity) => entity.code || entityTranslateFilter(entity)).join(', ');
          recipient.displayName = `${recipient.fullName} - ${recipient.email} (${entities})`;
        });
        manualNotificationsController.selectedRecipients = recipients;
      }).finally(() => {
        manualNotificationsController.loading = false;
      });
    };

    manualNotificationsController.send = function () {
      const requestParams = buildRequestParams();

      manualNotificationsController.notification.personIds = _.map(manualNotificationsController.selectedRecipients, mapRecipient);

      const sendNotification = manualNotificationsController.sendNotification[manualNotificationsController.entityType];
      sendNotification(requestParams, manualNotificationsController.notification).$promise.then(() => {
        Message.addSuccessLabel('Static.Message.ManualNotificationSent');

        // Clear all fields after sending emails
        manualNotificationsController.clearRecipients();
        manualNotificationsController.notification = emptyNotification();
        delete manualNotificationsController.template;
        manualNotificationsController.owned = false;
      });
    };

    function buildRequestParams() {
      const idName = EntityService.getEntityPath(manualNotificationsController.entity);
      return {
        academicYearId: sessionStorage.academicYear,
        [idName]: manualNotificationsController.entity.id,
        owned: manualNotificationsController.owned
      };
    }

    function mapRecipient(recipient) {
      const found = _.find(manualNotificationsController.recipients, { id: recipient.id });
      return {
        personId: found.personId,
        entities: _.map(found.entities, (entity) => ({ id: entity.id, type: entity.type }))
      };
    }
  }
});
