'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:activityGeneral
 * @description
 * Displays general activity information
 */
angular.module('uasApp').component('activityGeneral', {
    bindings: {
        activity: '<'
    },
    templateUrl: 'es6/schedule/activity/activity.general.html',
    controllerAs: 'activityGeneralController',
    controller: function(Entity) {
        const activityGeneralController = this;

        activityGeneralController.$onInit = function() {
            loadData();
        };

        function loadData() {
            activityGeneralController.loading = true;
            Entity.find(activityGeneralController.activity.entity).$promise.then((parent) => {
                activityGeneralController.parent = parent;
            }).finally(() => {
                activityGeneralController.loading = false;
            });
        }
    }
});
