'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:FacultyCtrl
 * @description
 * # FacultyCtrl
 * Faculty controller
 */
angular.module('uasApp')
    .controller('FacultyCtrl', function ($scope, $state, faculty, Tabs, historyMode, operations) {

        // Tabs which uses ui-router states to generate content.
        const tabs = [{
            view: 'general',
            route: 'faculty.general',
            readOnly: true
        }, {
            view: 'description',
            route: 'faculty.description'
        }, {
            view: 'relations',
            route: 'faculty.relations'
        }, {
            view: 'export',
            route: 'faculty.export'
        }, {
            view: 'changes',
            route: 'faculty.changes'
        }, {
            view: 'qualifications',
            route: 'faculty.qualifications'
        }, {
            view: 'studies',
            route: 'faculty.studies'
        }, {
            view: 'groups',
            route: 'faculty.groups'
        }, {
            view: 'modules',
            route: 'faculty.modules'
        }, {
            view: 'organisations',
            route: 'faculty.organisations'
        }, {
            view: 'availability',
            route: 'faculty.availability'
        }, {
            view: 'cost',
            route: 'faculty.cost'
        }, {
            view: 'cost-divisions',
            route: 'faculty.cost-divisions'
        }, {
            view: 'cost',
            route: 'faculty.method-parameters'
        }, {
            view: 'scheduled-activities-report',
            route: 'faculty.scheduled-activities-report'
        }, {
            view: 'studyable',
            route: 'faculty.studyable'
        }, {
            view: 'methods',
            route: 'faculty.methods'
        }, {
            view: 'offerings',
            route: 'faculty.offerings',
            checkParams: false,
            params: {
                terminated: false
            }
        }, {
            view: 'specifications',
            route: 'faculty.specifications'
        }, {
            view: 'assessments',
            route: 'faculty.assessments',
            outline: false
        }, {
            view: 'admin',
            route: 'faculty.admin'
        }];

        $scope.facultyId = $state.params.id;
        $scope.faculty = faculty;
        $scope.historyMode = historyMode;

        $scope.operations = operations;
        Tabs.build(tabs, operations, 'faculty', 'faculty', $scope.facultyId).then((builtTabs) => {
            $scope.tabs = builtTabs;
        });
    });
