'use strict';

angular.module('uasApp')
    .component('offeringList', {
        bindings: {
            entity: '<',
            operations: '<',
            operationsToEdit: '<?',
            isReadOnly: '<?',
            inactive: '<?',
            hideEdit: '<?',
            workflowMode: '<?',
            adhoc: '<?',
            columns: '<?'
        },
        templateUrl: 'es6/offerings/offering.list.html',
        controllerAs: 'offeringListController',
        controller: function($q, AdhocOffering, AuthService, Calendar, Column, EntityService, SecurityService, Offering, Message, CustomField, Dates, OfferingManager,
                             feedbackObserver, entityTranslateFilter) {
            const offeringListController = this;

            offeringListController.$onInit = function() {
                offeringListController.active = offeringListController.inactive !== true;
                offeringListController.entityType = EntityService.getType(offeringListController.entity);

                offeringListController.operationsToEdit_ = AuthService.buildOperationsToEdit(offeringListController.operationsToEdit,
                    offeringListController.workflowMode === true ? 'EDIT_OFFERINGS_WORKFLOW' : 'EDIT_OFFERINGS');

                const authorized = SecurityService.isAllowed(offeringListController.operationsToEdit_, offeringListController.operations);
                offeringListController.editable = offeringListController.isReadOnly !== true && authorized;

                loadData();
            };

            function loadData() {
                const entityId = _.get(offeringListController, 'entity.id');

                offeringListController.loading = true;
                $q.all([
                    Offering.scheduled({
                        entityType: offeringListController.entityType,
                        entityId,
                        active: false
                    }).$promise,
                    Calendar.get({
                        entityType: offeringListController.entityType,
                        entityId,
                        academicYearId: sessionStorage.academicYear
                    }).$promise,
                    CustomField.query({
                        rootType: offeringListController.entityType,
                        rootId: entityId,
                        entityType: 'offering'
                    }).$promise
                ]).then(([offerings, calendar, fields]) => {
                    offeringListController.calendar = calendar;
                    offeringListController.offerings = _.map(offerings, mapToRow);
                    offeringListController.columns_ = Column.fromFields(fields, offeringListController.columns, undefined, ['periodId']);
                    offeringListController.fields = fields;
                    offeringListController.onMode();
                }).finally(() => {
                    offeringListController.loading = false;
                });
            }

            function mapToRow(offering) {
                const row = angular.copy(offering);
                row.numberOfWeeks = Dates.getNumberOfWeeks(offering.startDate, offering.endDate);
                row.original = angular.copy(offering.blockIds);
                row.values = _.defaultTo(row.values, {});
                updateRow(row);
                return row;
            }

            function updateRow(offering) {
                if (angular.isDefined(offering.period)) {
                    offering.periodName = entityTranslateFilter(offering.period) || offering.period.code;
                }

                if (!_.isEmpty(offering.blocks)) {
                    offering.blockNames = _(offering.blocks).sortBy('period.period', 'sequence', 'id').map((block) => {
                        return block.code || entityTranslateFilter(block);
                    }).filter(angular.isDefined).join(' + ');
                }

                offering.removed = offering.changeType === 'REMOVE';
            }

            offeringListController.onMode = function() {
                offeringListController.filtered = _.filter(offeringListController.offerings, (offering) =>
                    !offeringListController.active || offering.changeType !== 'REMOVE');
            };

            offeringListController.create = function() {
                const offering = mapToRow({
                    entity: {
                        type: offeringListController.entityType,
                        id: offeringListController.entity.id
                    },
                    changeType: 'CREATE',
                    periods: [],
                    period: {
                        academicYearId: sessionStorage.academicYear
                    },
                    adhoc: offeringListController.adhoc === true,
                    dirty: true
                });

                const params = {
                    offering,
                    calendar: offeringListController.calendar,
                    fields: offeringListController.fields,
                    operations: offeringListController.operations,
                    operationsToEdit: offeringListController.operationsToEdit_,
                    onSave: doSave
                };

                if (offering.adhoc) {
                    OfferingManager.adhoc(params);
                } else {
                    OfferingManager.create(params);
                }
            };

            function doSave(offering, message) {
                const content = _.extend(angular.copy(offering), {
                    entity: {
                        type: offeringListController.entityType,
                        id: offeringListController.entity.id
                    }
                });

                if (offering.adhoc) {
                    delete content.startDate;
                    delete content.endDate;
                }

                const resource = offering.adhoc ? AdhocOffering : Offering;
                return resource.store({
                    content,
                    comment: {
                        message
                    }
                }, offering.id).$promise.then(() => {
                    Message.onSaved();
                    feedbackObserver.dataChanged();
                    loadData();
                });
            }

            offeringListController.edit = function(offering) {
                let params = {
                    offering,
                    calendar: offeringListController.calendar,
                    fields: offeringListController.fields,
                    operations: offeringListController.operations,
                    operationsToEdit: offeringListController.operationsToEdit_,
                    onSave: doSave
                };

                if (offering.adhoc === true) {
                    OfferingManager.adhoc(params);
                } else {
                    Offering.get({
                        id: offering.id
                    }).$promise.then((model) => {
                        model.periodName = offering.periodName;
                        OfferingManager.edit(_.extend(params, { offering: model }));
                    });
                }
            };

            offeringListController.remove = function(offering, comment) {
                Offering.remove({
                    ids: [offering.id],
                    comment: comment
                }).$promise.then(() => {
                    Message.onRemoved();
                    feedbackObserver.dataChanged();
                    loadData();
                });
            };
        }
    });
