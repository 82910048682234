'use strict';

angular.module('uasApp').component('uniqueChecker', {
  bindings: {
    field: '<',
    value: '<'
  },
  templateUrl: 'es6/fields/unique.checker.html',
  controllerAs: 'uniqueCheckerController',
  controller: function (GeneratedValue) {
    const uniqueCheckerController = this;

    uniqueCheckerController.$onChanges = function () {
      if (angular.isUndefined(uniqueCheckerController.original)) {
        uniqueCheckerController.original = angular.copy(uniqueCheckerController.value);
        return;
      }

      if (uniqueCheckerController.value && uniqueCheckerController.value !== uniqueCheckerController.original) {
        validate();
      } else {
        delete uniqueCheckerController.available;
      }
    };

    const validate = _.debounce(setAvailable, 250);
    
    function setAvailable() {
      GeneratedValue.available({
        fieldId: uniqueCheckerController.field.id,
        value: uniqueCheckerController.value
      }).$promise.then((result) => {
        uniqueCheckerController.available = result.available;
      });
    }
  }
});
