'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:MethodSchema
 * @description
 * The MethodSchema service.
 */
angular.module('uasApp').factory('MethodSchema', function ($q, UasResource, MethodType, Category, CustomPage) {
  const resource = UasResource('/method-schemas', {
    combined: {
      method: 'POST',
      url: '/method-schemas/combined'
    },
    children: {
      url: '/method-schemas/children',
      isArray: true
    },
    parents: {
      url: '/method-schemas/parents',
      isArray: true
    }
  });

  resource.getMethods = function (moduleId) {
    return $q.all([
      resource.children({
        entityType: 'module',
        entityId: moduleId
      }).$promise,
      MethodType.query({
        academicYearId: sessionStorage.academicYear
      }).$promise,
      Category.query({
        rootType: 'METHOD'
      }).$promise,
      getCodeProperty()
    ]).then(([methods, types, categories, property]) => {
      const editableCategories = _.filter(categories, { editable: true });

      _.forEach(methods, (method) => {
        const type = _.find(types, { id: method.typeId });
        if (angular.isDefined(type)) {
          const category = _.find(editableCategories, { id: type.categoryId });
          if (angular.isDefined(category)) {
            category.methods = category.methods || [];
            category.methods.push({
              id: method.id,
              code: method.code || type[property],
              numberOfGroups: method.numberOfGroups,
              amount: method.amount,
              duration: method.duration,
              localName: method.localName,
              englishName: method.englishName,
              changeType: method.changeType,
              self: method.self
            });
          }
        }
      });

      return editableCategories;
    });

    function getCodeProperty() {
      return CustomPage.query({ basePath: 'module.teaching-method' }).$promise.then((pages) => {
        if (_.find(pages, { route: 'module.teaching-method.externalId', visible: true })) {
          return 'externalId';
        }
        return 'code';
      });
    }
  };

  return resource;
});
