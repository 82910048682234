'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTransitionModal
 * @description
 * statusTransitionModal Shows the status transition
 */
angular.module('uasApp').component('statusTransitionModal', {
        bindings: {
            transition: '<',
            processType: '<',
            instance: '<'
        },
        templateUrl: 'es6/status/transition/status.transition.modal.html',
        controllerAs: 'statusTransitionModalController',
        controller: function ($q, StatusTransition, StatusType, Hook, Message) {
            const statusTransitionModalController = this;

            statusTransitionModalController.$onInit = function () {
                loadData();
            };

            function loadData() {
                statusTransitionModalController.loading = true;
                $q.all([
                    StatusType.query({
                        processTypeId: statusTransitionModalController.processType.id
                    }).$promise,
                    Hook.query().$promise
                ]).then(([statuses, hooks]) => {
                    statusTransitionModalController.statuses = _.sortBy(statuses, ['index', 'id']);
                    statusTransitionModalController.hooks = hooks;
                }).finally(() => {
                    statusTransitionModalController.loading = false;
                });
            }

            statusTransitionModalController.close = function () {
                statusTransitionModalController.instance.dismiss();
            };

            statusTransitionModalController.save = function () {
                StatusTransition.store(statusTransitionModalController.transition).$promise.then(function () {
                    Message.onSaved();
                    statusTransitionModalController.close();
                });
            };
        }
    });
