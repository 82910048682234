'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:capacityPage
 * @description
 * capacityPage Shows capacity
 */
angular.module('uasApp')
    .component('capacityPage', {
        bindings: {
            entity: '<',
            page: '<',
            workflow: '<?',
            operations: '<'
        },
        templateUrl: 'es6/app/capacity/capacity.page.html',
        controllerAs: 'capacityPageController',
        controller: function() {
            const capacityPageController = this;

            capacityPageController.$onInit = function () {
                capacityPageController.operationsToEdit = angular.isDefined(capacityPageController.workflow) ? 'EDIT_CAPACITY_WORKFLOW' : 'EDIT_CAPACITY';
            };
        }
    });
