'use strict';

angular.module('uasApp').factory('Assignment', function(UasResource, entityTranslateFilter, numberFilter) {
    const resource = UasResource('/assignments', {
        search: {
            url: '/assignments/search',
            method: 'GET',
            isArray: false
        },
        deleteFull: {
            method: 'DELETE',
            url: '/assignments/:id/full',
            isArray: false
        }
    });

    function getLabel(assignment, displayRole) {
        const role = displayRole ? getRoleName(assignment) : '';
        const name = role || _.result(assignment, 'person.fullName') || assignment.name || '';
        const fte = assignment.fte || 0;

        let label = `${name} (${numberFilter(fte)}) - ${assignment.organisation.code}`;
        if (assignment.externalId) {
            label = `${label} (${assignment.externalId})`;
        }
        return label;
    }

    function getRoleName(assignment) {
        const role = assignment.role;
        return entityTranslateFilter(role) || _.get(role, 'code');
    }

    return _.extend(resource, { getLabel });
});
