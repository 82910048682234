'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasOrganisationGeneral
 * @description
 * # uasOrganisationGeneral
 * Shows the general data of an organisation.
 */
angular.module('uasApp')
    .component('uasOrganisationGeneral', {
        bindings: {
            organisation: '<'
        },
        controllerAs: 'organisationGeneralController',
        templateUrl: 'es6/organisation/organisation.general.html',
        controller: function($state, Faculty, Organisation) {
          const organisationGeneralController = this;
          organisationGeneralController.$onInit = function() {
            loadData();
          };

          function loadData() {
            if (angular.isDefined(organisationGeneralController.organisation.facultyId)) {
              Faculty.get({
                id: organisationGeneralController.organisation.facultyId
              }).$promise.then((faculty) => {
                organisationGeneralController.faculty = faculty;
              });
            }

            if (angular.isDefined(organisationGeneralController.organisation.parentId)) {
              Organisation.get({
                id: organisationGeneralController.organisation.parentId
              }).$promise.then((parent) => {
                organisationGeneralController.parent = parent;
              });
            }
          }

          organisationGeneralController.onStatus = function () {
            $state.reload();
          };
        }
    });