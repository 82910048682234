'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasTypeahead
 * @description
 * Select options for elements.
 */
angular.module('uasApp').component('uasTypeahead', {
  bindings: {
    inputId: '@',
    isRequired: '<?',
    isReadOnly: '<?',
    viewClasses: '<?',
    classes: '<?',
    order: '<?',
    fixedHeight: '<?',
    formatValue: '&',
    minLength: '<?',
    inModal: '<?',
    onSearch: '&', // Function that returns promise to search entities
    onLoad: '&' // Function that returns promise to load single entity
  },
  require: {
    securedCtrl: '?^secured',
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/typeahead.html',
  controllerAs: 'typeaheadController',
  controller: function ($element, $timeout, Changes, Pageable) {
    const typeaheadController = this;

    typeaheadController.$onInit = function () {
      if (typeaheadController.fixedHeight) {
        $timeout(() => {
          const inputElement = $element.find(`#${typeaheadController.inputId}`)[0];
          const typeaheadId = inputElement.getAttribute('aria-owns');
          document.getElementById(typeaheadId).classList.add('fixed-height');
        });
      }

      typeaheadController.minLength = angular.isDefined(typeaheadController.minLength) ? typeaheadController.minLength : 2;

      typeaheadController.pageable = Pageable.of({
        order: typeaheadController.order,
        pageSize: 5
      });

      typeaheadController.ngModelCtrl.$render = function () {
        const id = typeaheadController.ngModelCtrl.$modelValue;
        typeaheadController.onLoad({ id }).then((entity) => {
          typeaheadController.entity = entity;
        });
      };

      secure();
    };

    typeaheadController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'isRequired')) {
        delete typeaheadController.ngModelCtrl.$validators.required;
        typeaheadController.ngModelCtrl.$setValidity('required', false);

        if (typeaheadController.isRequired) {
          typeaheadController.ngModelCtrl.$validators.required = function (modelValue, viewValue) {
            const value = modelValue || viewValue;
            return !typeaheadController.ngModelCtrl.$isEmpty(value);
          };
        }
      }
    };

    function secure() {
      if (typeaheadController.securedCtrl) {
        typeaheadController.securedCtrl.resecure($element);
      }
    }

    typeaheadController.search = function (text) {
      const params = typeaheadController.pageable.build({
        academicYearId: sessionStorage.academicYear,
        text
      });

      typeaheadController.loading = true;
      return typeaheadController.onSearch({ params }).then((page) => {
        typeaheadController.totalElements = page.totalElements;
        return _.flatten(page.content);
      }).finally(() => {
        typeaheadController.loading = false;
      });
    };

    typeaheadController.onSelect = function () {
      delete typeaheadController.totalElements;

      const id = _.get(typeaheadController.entity, 'id');
      typeaheadController.onChange(id);
    };

    typeaheadController.onChange = function (id) {
      typeaheadController.ngModelCtrl.$setViewValue(id);
    };

    typeaheadController.remove = function () {
      delete typeaheadController.totalElements;
      delete typeaheadController.entity;

      typeaheadController.onChange();
      $element.find('input')[0].focus();
    };
  }
});
