'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAddNew
 * @description
 * Component for adding a new module to a module group.
 */
angular.module('uasApp').component('uasModuleAddNew', {
  bindings: {
    uibModalInstance: '<',
    group: '<',
    studyId: '<',
    facultyId: '<',
    academicYearId: '<',
    excludeIds: '<',
    showGroupAlert: '<?',
    operations: '<',
    operationsToEdit: '<',
    onAdd: '&',
    onDelete: '&?'
  },
  controllerAs: 'moduleAddController',
  templateUrl: 'es6/planboard/add/module.add.new.html',
  controller: function ($q, Message, Module, Credits, CustomField, GroupType, translateFilter, entityTranslateFilter, CreateManager) {
    const moduleAddController = this;

    const MODULE_FIELDS = ['localName', 'englishName', 'originalId'];

    moduleAddController.$onInit = function () {
      $q.all([
        CustomField.query({
          rootType: 'module-group',
          entityType: 'module-group-module'
        }).$promise,
        CustomField.query({
          rootType: 'module'
        }).$promise
      ]).then(([fields, moduleFields]) => {
        moduleAddController.fields = _.keyBy(fields, 'name');
        moduleAddController.moduleFields = _(moduleFields)
          .filter((field) => _.includes(MODULE_FIELDS, field.name))
          .sortBy('sequence')
          .value();

        if (moduleAddController.group.typeId) {
          GroupType.get({
            id: moduleAddController.group.typeId
          }).$promise.then((groupType) =>
            moduleAddController.group.groupType = groupType
          );
        }
      });

      moduleAddController.newModule = {
        studyId: moduleAddController.studyId,
        facultyId: moduleAddController.facultyId,
        academicYearId: moduleAddController.academicYearId,
        optimumCredits: 0,
        values: {}
      };
    };

    moduleAddController.groupModulesWarning = function () {
      return translateFilter('ModuleGroup.NoModules', {
        modulegroup: (moduleAddController.group.code || '') + ' ' + entityTranslateFilter(moduleAddController.group)
      });
    };

    function getEntityName(entity) {
      return '<strong>' + entityTranslateFilter(entity) + '</strong>';
    }

    /**
     * Adds a new module to the group.
     */
    moduleAddController.addNew = function (module, message) {
      module.code = '';

      Module.generate({
        module,
        message,
        originalId: _.get(moduleAddController, 'original.id')
      }).$promise.then((result) => {
        const created = result.entities[0].entity;
        moduleAddController.onCreate(created);
      });
    };

    moduleAddController.onCreate = function (created) {
      CreateManager.onCreate(created,
        () => onAddSuccess(created),
        () => onDelete(created)
      );
    };

    function onAddSuccess(module) {
      return refresh(module).then(() => {
        const moduleCopy = angular.copy(module);
        moduleCopy.required = moduleAddController.required;
        moduleCopy.alterative = moduleAddController.alterative;
        moduleCopy.phaseId = moduleAddController.phaseId;
        moduleCopy.cluster = moduleAddController.cluster;
        moduleCopy.values = moduleAddController.newModule.values;

        moduleAddController.onAdd({
          module: moduleCopy,
          message: moduleAddController.message
        });

        Message.addSuccess(translateFilter('Static.Message.NewAddedModuleToGroup', {
          module: getEntityName(module),
          modulegroup: getEntityName(moduleAddController.group)
        }));

        moduleAddController.uibModalInstance.close();
      });
    }

    function refresh(module) {
      return $q.all([
        Module.get({ id: module.id }).$promise,
        Credits.get({ entityId: module.id, entityType: 'module' }).$promise
      ]).then(([createdModule, credits]) => {
        module.code = createdModule.code;
        module.localName = createdModule.localName;
        module.englishName = createdModule.englishName;
        module.optimumCredits = _.result(credits, 'optimum');
      });
    }

    function onDelete(module) {
      return Module.deleteFull({
        id: module.id
      }).$promise.then(() => {
        moduleAddController.cancel();
        if (_.isFunction(moduleAddController.onDelete)) {
          moduleAddController.onDelete();
        }
      });
    }

    /**
     * Closes the modal
     */
    moduleAddController.cancel = function () {
      moduleAddController.uibModalInstance.dismiss();
    };
  }
});
