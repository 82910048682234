'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorklogEdit
 * @description
 * uasWorklogEdit create and or modify a worklog
 */
angular.module('uasApp')
  .component('uasWorklogEdit', {
    bindings: {
        worklog: '<',
        methodTypes: '<',
        tasks: '<',
        onCancel: '&',
        onSave: '&'
    },
    templateUrl: 'es6/staffing/worklog/worklog.edit.html',
    controllerAs: 'worklogEditController',
    controller: function () {
        const worklogEditController = this;

        worklogEditController.cancel = function() {
            worklogEditController.onCancel({});
        };

        worklogEditController.save = function() {
            worklogEditController.onSave({
                data: worklogEditController.worklog
            });
        };

    }
});