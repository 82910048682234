'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesList
 * @description
 * Displays a list of interactive faculties.
 */
angular.module('uasApp').component('facultiesList', {
  bindings: {
    facultyId: '<',
    operations: '<',
    onToggle: '&'
  },
  templateUrl: 'es6/faculties/faculties.list.html',
  controllerAs: 'facultiesListController',
  controller: function ($q, $scope, $state, $stateParams, CreateManager, Faculty, Institution, Pageable) {
    const facultiesListController = this;

    facultiesListController.$onInit = function () {
      facultiesListController.pageable = Pageable.of({
        order: 'code'
      });

      loadData();
      watchFacultyIdParam();
    };

    function loadData() {
      facultiesListController.loading = true;
      return $q.all([
        Institution.query().$promise,
        Faculty.query({
          academicYearId: sessionStorage.academicYear
        }).$promise
      ]).then(([institutions, faculties]) => {
        _.forEach(faculties, (faculty) => {
          faculty.institution = _.find(institutions, {
            id: _.get(faculty, 'institutionId')
          });
        });

        facultiesListController.faculties = faculties;
        selectInitialFaculty();
      }).finally(() => {
        facultiesListController.loading = false;
      });
    }

    function selectInitialFaculty() {
      const faculty = getFacultyById(facultiesListController.facultyId);
      facultiesListController.toggleFaculty(faculty, true);
    }

    function getFacultyById(facultyId) {
      if (angular.isDefined(facultyId)) {
        const foundFaculty = _.find(facultiesListController.faculties, { id: facultyId });

        if (foundFaculty) {
          return foundFaculty;
        } else if (!_.isEmpty(facultiesListController.faculties)) {
          return _.first(facultiesListController.faculties);
        }
      }

      return {};
    }

    facultiesListController.toggleFaculty = function (faculty, isInitial = false) {
      facultiesListController.onToggle({ faculty, isInitial });
    };

    facultiesListController.addFaculty = function (name) {
      const faculty = {
        code: name,
        academicYearId: sessionStorage.academicYear,
        studies: true,
        moduleGroups: true,
        modules: true
      };

      CreateManager.open('faculty', faculty, facultiesListController.operations, (created) => {
        facultiesListController.faculties.push(created);
        facultiesListController.toggleFaculty(created);
      });
    };

    facultiesListController.openFacultyDetail = function (faculty, event) {
      event.preventDefault();
      event.stopPropagation();

      $state.transitionTo('faculty.general', {
        id: faculty.id
      });
    };

    // When users manually change the URL, the selected faculty should be updated.
    function watchFacultyIdParam() {
      $scope.$watch(() => $stateParams.facultyId, (newFacultyId, oldFacultyId) => {
        if (newFacultyId !== oldFacultyId) {
          const newFacultyIdParsed = angular.isDefined(newFacultyId) ? _.parseInt(newFacultyId) : undefined;
          if (newFacultyIdParsed !== facultiesListController.facultyId) {
            if (angular.isDefined(newFacultyIdParsed)) {
              const faculty = getFacultyById(newFacultyIdParsed);
              facultiesListController.toggleFaculty(faculty);
            } else {
              facultiesListController.toggleFaculty({});
            }
          }
        }
      });
    }
  }
});
