'use strict';

/**
 * Retrieves the first element that matches our args.
 */
angular.module('uasApp').filter('first', function() {
    return function(values, args) {
        return _.find(values, args);
    };
});
