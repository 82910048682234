'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:hookInvokeButton
 * @description Invoke a hook manually
 */
angular.module('uasApp').component('hookInvokeButton', {
    bindings: {
        periodId: '<?',
        rootType: '<',
        entities: '<'
    },
    templateUrl: 'es6/process/hook/hook.invoke.button.html',
    controllerAs: 'hookInvokeButtonController',
    controller: function ($q, Changes, EntityService, Hook, Promises) {
        const hookInvokeButtonController = this;

        hookInvokeButtonController.$onChanges = function(changes) {
            if (Changes.hasChanged(changes, 'rootType') && hookInvokeButtonController.rootType) {
                setHooks();
            }
        };

        function setHooks() {
            Hook.query({
                rootType: hookInvokeButtonController.rootType
            }).$promise.then((hooks) => {
                hookInvokeButtonController.hooks = hooks;
            });
        }

        hookInvokeButtonController.invoke = function(hook) {
            getEntities().then((entities) => {
                const entityType = EntityService.getEntityType(hookInvokeButtonController.rootType);
                const promises = _(entities).map('id').chunk(5).map((entityIds) => {
                    return () => invoke(hook, entityType, entityIds);
                }).value();

                hookInvokeButtonController.progress = Promises.chain(promises);
            });
        };

        function getEntities() {
            if (_.isFunction(hookInvokeButtonController.entities)) {
                return hookInvokeButtonController.entities();
            }

            return $q.resolve(hookInvokeButtonController.entities);
        }

        function invoke(hook, entityType, entityIds) {
            return Hook.invoke({ id: hook.id }, {
                periodId: hookInvokeButtonController.periodId || sessionStorage.academicYear,
                entityType,
                entityIds
            }).$promise;
        }
    }
});
