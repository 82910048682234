'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uasPersonPopover
 * @description Shows the popover photo of a person
 */
angular.module('uasApp').component('uasPersonPopover', {
  templateUrl: 'es6/person/person.popover.html',
  bindings: {
    personId: '@'
  },
  controllerAs: 'personPopoverController',
  controller: function($window, Person) {
    const personPopoverController = this;

    personPopoverController.$onInit = function() {
      personPopoverController.person = Person.avatar({ 
        id: personPopoverController.personId 
      });
    };

    personPopoverController.sendEmail = function(email, event) {
      event.preventDefault();
      event.stopPropagation();
      $window.location.href = 'mailto: ' + email;
    };
  }
});
