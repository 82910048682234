'use strict';

angular.module('uasApp').component('qualificationSearch', {
  bindings: {
    onSelect: '&',
    specificationId: '<?'
  },
  templateUrl: 'es6/qualification/qualification.search.html',
  controllerAs: 'qualificationSearchController',
  controller: function (Qualification, Pageable) {
    const qualificationSearchController = this;

    qualificationSearchController.$onInit = function () {
      qualificationSearchController.pageable = Pageable.of({
        order: 'code'
      });

      if (angular.isDefined(qualificationSearchController.specificationId)) {
        qualificationSearchController.specification = {
          type: 'specification', 
          id: qualificationSearchController.specificationId
        };
      }

      qualificationSearchController.search();
    };

    qualificationSearchController.search = function () {
      const parameters = qualificationSearchController.pageable.build();

      Qualification.search(parameters, {
        text: qualificationSearchController.text,
        specificationId: _.get(qualificationSearchController.specification, 'id')
      }).$promise.then((qualifications) => {
        qualificationSearchController.qualifications = qualifications;
      });
    };
  }
});
