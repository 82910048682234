'use strict';

/**
 * The target states for a certain entity.
 */
angular.module('uasApp')
    .constant('ENTITY_STATES', {
        'faculty': 'faculty.general',
        'faculty-description': 'faculty.description',
        'faculty-relation': 'faculty.relations',

        'organisation': 'organisation.general',

        'person': 'person.general',
        'assignment': 'assignment.general',
        'team': 'team.general',

        'specification': 'specification.general',

        'qualification': 'qualification.general',
        'qualification-links': 'qualification.links',

        'study': 'study.general',
        'study-description': 'study.description',
        'study-additional': 'study.additional',
        'study-relation': 'study.relations',
        'study-groups': 'study.structure',
        'study-links': 'study.links',

        'module-group': 'group.general',
        'module-group-description': 'group.description',
        'module-group-additional': 'group.additional',
        'module-group-relation': 'group.relations',
        'module-group-module': 'group.modules',
        'module-group-links': 'group.links',

        'module': 'module.general',
        'module-offering': 'module.general',
        'module-appraisal': 'module.appraisal',
        'module-method': 'module.teaching-method',
        'module-additional': 'module.additional',
        'module-description': 'module.description',
        'module-relation': 'module.relations',
        'module-links': 'module.links',

        'method': 'method.general',
        'assessment': 'assessment.general',
        'activity': 'activity.general',
        'rule': 'rule.general',
        
        'academic-year': 'academicYear.general'
    });
