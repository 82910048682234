'use strict';

angular.module('uasApp').factory('ReportTemplate', function (UasResource) {
  return UasResource('/report-template', {
    search: {
      url: '/report-template/search',
      method: 'GET',
      isArray: false
    }
  });
});
