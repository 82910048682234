'use strict';

angular.module('uasApp').component('iconMessage', {
  bindings: {
    icon: '@',
    message: '@'
  },
  templateUrl: 'es6/app/icon.message.html',
  controllerAs: 'iconMessageController'
});
