'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCostDivisionReport
 * @description
 * uasCostDivisionReport cost division
 */
angular.module('uasApp')
  .component('uasCostDivisionReport', {
    bindings: {
      entity: '<',
      operations: '<',
      defaultPeriod: '<?',
      workflowMode: '<?',
      isReadOnly: '<?',
      showCalculate: '<?'
    },
    templateUrl: 'es6/cost/division/cost.division.report.html',
    controllerAs: 'costDivisionReportController',
    controller: function ($q, $uibModal, $state, Feature, EntityService, Pageable, AcademicYear, CalendarPeriod, CostDivision, Organisation, Module, Study, Promises, entityTranslateFilter) {
      const costDivisionReportController = this;

      costDivisionReportController.$onInit = function () {
        costDivisionReportController.entityType = EntityService.getType(costDivisionReportController.entity);
        costDivisionReportController.editable = costDivisionReportController.isReadOnly !== true;
        costDivisionReportController.pageable = Pageable.of({ order: 'module.code' });
        costDivisionReportController.stateName = $state.current.name;
        costDivisionReportController.loading = true;

        if (costDivisionReportController.entityType === 'faculty') {
          Organisation.query({
            facultyId: costDivisionReportController.entity.id,
            academicYearId: sessionStorage.academicYear
          }).$promise.then((organisations) => {
            costDivisionReportController.organisations = organisations;
          });

          Study.query({
            facultyId: costDivisionReportController.entity.id,
            academicYearId: sessionStorage.academicYear
          }).$promise.then((studies) => {
            costDivisionReportController.studies = studies;
          });
        }

        $q.all([
          Feature.get({
            type: 'COST'
          }).$promise,
          CalendarPeriod.query({
            academicYearId: sessionStorage.academicYear
          }).$promise
        ]).then(([feature, periods]) => {
          costDivisionReportController.feature = feature;
          costDivisionReportController.periods = periods;

          setDefaultPeriod(periods);
          loadData();
        });
      };

      function setDefaultPeriod(periods) {
        if (angular.isDefined(costDivisionReportController.defaultPeriod)) {
          const endDate = costDivisionReportController.defaultPeriod.endDate;
          costDivisionReportController.periodId = _(periods).filter({ endDate }).map('id').find();
        }
      }

      costDivisionReportController.search = function() {
        loadData();
      };

      function loadData() {
        costDivisionReportController.loading = true;

        const params = buildParams();
        $q.all([
          Module.query(params).$promise,
          CostDivision.query(params).$promise
        ]).then(([modules, divisions]) => {
          costDivisionReportController.headers = getHeaders(divisions);
          costDivisionReportController.rows = _(divisions)
            .map('module')
            .concat(modules)
            .uniqBy('id')
            .map((module) => buildRow(module, divisions))
            .value();

          const amount = _(costDivisionReportController.rows).map('amount').max() || 0;
          costDivisionReportController.placeholders = _.times(amount, (index) => {
            return { index };
          });
        }).finally(() => {
          costDivisionReportController.loading = false;
        });
      }

      function buildParams() {
        const condition = Feature.getCondition(costDivisionReportController.feature);
        const period = getPeriod();

        let params = {
          organisationId: costDivisionReportController.organisationId,
          studyId: costDivisionReportController.studyId,
          startDate: _.result(period, 'startDate'),
          endDate: _.result(period, 'endDate'),
          condition
        };

        const entityPath = EntityService.getEntityPath(costDivisionReportController.entity);
        params[entityPath] = costDivisionReportController.entity.id;

        return params;
      }

      function getPeriod() {
        return _.find(costDivisionReportController.periods, {
          id: costDivisionReportController.periodId
        });
      }

      function buildRow(module, divisions) {
        const found = _(divisions).filter((division) => division.module.id === module.id).sortBy(['organisation.code', 'percentage', 'id']).value();
        const changed = _.some(found, { changed: true });
        const ready = found.length > 0 && _.every(found, { ready: true });
        const approved = found.length > 0 && _.every(found, { approved: true });
        const percentage = _.sumBy(found, 'percentage');
        const hours = _.sumBy(found, 'hours');
        
        return {
          module,
          divisions: found,
          amount: found.length,
          changed,
          ready,
          approved,
          percentage,
          hours
        };
      }

      function getHeaders(divisions) {
        const headers = [
          { code: 'year', name: 'year', valueType: 'STRING' },
          { code: 'module', name: 'module', valueType: 'STRING' },
          { code: 'moduleName', name: 'module name', valueType: 'STRING' },
          { code: 'ready', name: 'ready', valueType: 'BOOLEAN' },
          { code: 'approved', name: 'approved', valueType: 'BOOLEAN' },
          { code: 'percentage', name: 'percentage', valueType: 'NUMBER' },
          { code: 'hours', name: 'hours', valueType: 'NUMBER' },
          { code: 'organisations', name: 'organisations', valueType: 'STRING' }
        ];

        const codes = _(divisions).map('organisation').uniqBy('id').sortBy('code').map((organisation) => {
          return {
            code: organisation.code,
            name: `(${organisation.code}) ${entityTranslateFilter(organisation)}`,
            valueType: 'NUMBER'
          };
        }).value();

        return _.concat(headers, codes);
      }

      costDivisionReportController.getReportRows = function() {
        return AcademicYear.get({
          id: sessionStorage.academicYear
        }).$promise.then((year) => {
          const ordered = costDivisionReportController.pageable.sort(costDivisionReportController.rows);
          return _.map(ordered, (row) => {
            let data = {
              year: year.externalId,
              module: row.module.code || row.module.externalId,
              moduleName: entityTranslateFilter(row.module),
              ready: row.ready,
              approved: row.approved,
              percentage: row.percentage,
              hours: row.hours,
              organisations: _(row.divisions).map('organisation.code').filter((code) => !!code).sort().join(', ')
            };

            _.forEach(row.divisions, (division) => {
              if (division.organisation.code) {
                data[division.organisation.code] = division.percentage || 0;
              }
            });

            return data;
          });
        });
      };

      costDivisionReportController.generate = function() {
        const period = getPeriod();

        costDivisionReportController.loading = true;
        getStudyIds().then((studyIds) => {
          const requests = _.map(studyIds, (studyId) => {
            return () => generateStudy(studyId, period);
          });

          const progress = Promises.chain(requests);
          costDivisionReportController.progress = progress;
          return progress.$promise;
        }).then(loadData).finally(() => {
          costDivisionReportController.loading = false;
          delete costDivisionReportController.progress;
        });
      };

      function getStudyIds() {
        let studyId = costDivisionReportController.studyId;
        if (costDivisionReportController.entityType === 'study') {
          studyId = costDivisionReportController.entity.id;
        }

        if (angular.isDefined(studyId)) {
          return $q.resolve([studyId]);
        } else if (costDivisionReportController.entityType === 'faculty') {
          return Study.query({
            facultyId: costDivisionReportController.entity.id
          }).$promise.then((studies) => _.map(studies, 'id'));
        } else {
          return $q.resolve([]);
        }
      }

      function generateStudy(studyId, period) {
        return CostDivision.generate({ studyId }, {
          startDate: _.result(period, 'startDate'),
          endDate: _.result(period, 'endDate')
        }).$promise;
      }

      costDivisionReportController.edit = function(row) {
        const moduleId = row.module.id;

        $uibModal.open({
          resolve: {
            module: function() {
              return Module.get({ id: moduleId }).$promise;
            },
            operations: function(AuthService) {
              return AuthService.operations('module', moduleId);
            }
          },
          size: 'lg',
          templateUrl: 'es6/cost/division/cost.division.modal.html',
          controllerAs: 'costDivisionModalController',
          controller: function($uibModalInstance, module, operations) {
            const costDivisionModalController = this;
            costDivisionModalController.module = module;
            costDivisionModalController.operations = operations;
            costDivisionModalController.workflowMode = costDivisionReportController.workflowMode;

            costDivisionModalController.onChange = function() {
              loadData();
            };

            costDivisionModalController.cancel = function() {
              $uibModalInstance.dismiss();
            };
          }
        });
      };
    }
});
