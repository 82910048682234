'use strict';

/**
 * Creates the default bootstrap form group label with input
 * It is also possible to set an info label.
 * If info-label-type is set to tooltip it will generate an uib-tooltip
 * if else the type is sub-label it will generate a h6 label under the label.
 * The tooltip can be given as html or as plain text.
 */
angular.module('uasApp')
    .component('formGroup', {
        bindings: {
            label: '@',
            labelAlign: '@?',
            labelDefault: '@?',
            labelWidth: '@?',
            isRequired: '<?',
            isReadOnly: '<?',
            requiredModel: '<?',
            requiredFunction: '&?',
            infoLabel: '@?',
            infoLabelHtml: '@?',
            infoLabelType: '@?',
            vertical: '<?',
            viewClasses: '<?'
        },
        transclude: true,
        templateUrl: 'es6/app/forms/form.group.html',
        controllerAs: 'formGroupController',
        controller: function ($sanitize, Changes) {
            const formGroupController = this;

            formGroupController.$onInit = function() {
                formGroupController.infoLabelHtml = formGroupController.infoLabelHtml ?
                $sanitize(formGroupController.infoLabelHtml) : undefined;

                if (formGroupController.vertical) {
                    formGroupController.labelAlign_ = 'left';
                    formGroupController.labelWidth_ = '12';
                    formGroupController.inputWidth = '12';
                } else {
                    formGroupController.labelAlign_ = formGroupController.labelAlign || 'right';
                    formGroupController.labelWidth_ = _.isEmpty(formGroupController.labelWidth) ? '3' : formGroupController.labelWidth;
                    formGroupController.inputWidth = 12 - (parseInt(formGroupController.labelWidth_));
                }

                formGroupController.isRequired = formGroupController.isRequired === true;

                if (angular.isDefined(formGroupController.requiredFunction)) {
                    formGroupController.requiredModel = formGroupController.requiredFunction;
                }
            };

            formGroupController.$onChanges = function (changes) {
                if (Changes.hasChanged(changes, 'isRequired')) {
                    formGroupController.isRequired = formGroupController.isRequired === true;
                }
            };
        }
    });
