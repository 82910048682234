'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:General
 * @description
 * The General service.
 */
angular.module('uasApp')
    .factory('General', function(UasResource) {
        return UasResource('/general');
    });
