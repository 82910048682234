'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodParameterPage
 * @description
 * methodParameterPage Shows method parameters as a page
 */
angular.module('uasApp')
  .component('methodParameterPage', {
    bindings: {
      entity: '<',
      page: '<',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/methods/parameter/method.parameter.page.html',
    controllerAs: 'methodParameterPageController',
    controller: function (Page) {
      const methodParameterPageController = this;

      methodParameterPageController.$onInit = function () {
        methodParameterPageController.readOnly = methodParameterPageController.isReadOnly ||
          Page.getParameterAsBoolean(methodParameterPageController.page, 'readOnly', false);
        methodParameterPageController.hideCopy = Page.getParameterAsBoolean(methodParameterPageController.page, 'hideCopy', false);
        methodParameterPageController.showAllCategories = Page.getParameterAsBoolean(methodParameterPageController.page, 'showAllCategories', false);

        if (angular.isDefined(methodParameterPageController.entity)) {
          methodParameterPageController.facultyId = angular.isDefined(methodParameterPageController.entity.facultyId) ?
            methodParameterPageController.entity.facultyId : methodParameterPageController.entity.id;
        }
      };
    }
  });
