'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AcademicPeriod
 * @description
 * The AcademicPeriod service.
 */
angular.module('uasApp')
    .factory('AcademicPeriod', function(UasResource) {
        return UasResource('/academic-period');
    });
