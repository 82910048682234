'use strict';

angular.module('uasApp').component('uasLabelList',
    {
        templateUrl: 'es6/admin/labels/label.list.html',
        controllerAs: 'labelListController',
        controller: function ($q, $translate, $uibModal, Pageable, Label, Message) {
            const labelListController = this;

            labelListController.$onInit = function () {
                labelListController.type = 'all';
                labelListController.types = [
                    { name: 'all', label: 'All', icon: 'fa-asterisk' },
                    { name: 'touched', label: 'Touched', icon: 'fa-pencil' },
                    { name: 'manual', label: 'Manual', icon: 'fa-plus' }
                ];

                labelListController.term = '';
                labelListController.pageable = Pageable.of({
                    order: 'key'
                });

                labelListController.search();
            };

            labelListController.setType = function (type) {
                labelListController.type = type;
                labelListController.search();
            };

            /**
             * Appends the original attribute to each label and sorts them by language (reversed).
             * This is needed to check if the labels has changed.
             */
            labelListController.search = function() {
                loadData();
            };
            
            function loadData () {
                const parameters = labelListController.pageable.build(getParameters());

                labelListController.loading = true;
                Label.search(parameters).$promise.then((labels) => {
                    labelListController.labels = labels;
                }).finally(() => {
                    labelListController.loading = false;
                });
            }

            function getParameters() {
                const parameters = {
                    term: labelListController.term
                };

                if (labelListController.type === 'manual') {
                    parameters.manual = true;
                } else if (labelListController.type === 'touched') {
                    parameters.touched = true;
                }
                return parameters;
            }

            labelListController.create = function () {
                open('');
            };

            labelListController.edit = function (label) {
                open(label.key);
            };

            function open (labelKey) {
                $uibModal.open({
                    template: `
                      <label-edit
                        label-key="labelModalController.labelKey"
                        on-change="labelModalController.onChange()"
                        on-cancel="labelModalController.close()">
                      </label-edit>
                    `,
                    controllerAs: 'labelModalController',
                    controller: function($uibModalInstance) {
                      const labelModalController = this;
                      labelModalController.labelKey = labelKey;
            
                      labelModalController.onChange = function() {
                        labelModalController.close();
                        $translate.refresh().then(() => {
                            labelListController.search();
                        });
                      };
            
                      labelModalController.close = function() {
                        $uibModalInstance.dismiss();
                      };
                    }
                });
            }

            labelListController.delete = function(label) {
                Label.delete(label).$promise.then(() => {
                    labelListController.search();
                    Message.onRemoved();
                });
            };
        }
    });
