'use strict';

angular.module('uasApp')
    .component('offeringEdit', {
        bindings: {
            offering: '<',
            fields: '<',
            operations: '<',
            onClose: '&',
            onSave: '&'
        },
        templateUrl: 'es6/offerings/offering.edit.html',
        controllerAs: 'offeringEditController',
        controller: function(CustomField) {
            const offeringEditController = this;

            offeringEditController.$onInit = function() {
                offeringEditController.operationsToEdit = ['EDIT_OFFERINGS', 'EDIT_OFFERINGS_WORKFLOW'];
                offeringEditController.fields_ = _.keyBy(offeringEditController.fields, 'name');
                setEvaluation();
            };

            offeringEditController.save = function() {
                return offeringEditController.onSave({
                    offering: offeringEditController.offering,
                    message: offeringEditController.message
                });
            };

            offeringEditController.close = function() {
                offeringEditController.onClose();
            };

            offeringEditController.onChange = function() {
                setEvaluation();
            };

            function setEvaluation() {
                offeringEditController.evaluation = CustomField.getEvaluation(
                    offeringEditController.offering, 
                    offeringEditController.offering, 
                    offeringEditController.fields
                );
            }
        }
    });