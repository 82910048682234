'use strict';

angular.module('uasApp').component('versionSwitcher', {
    templateUrl: 'es6/app/version/version.switcher.html',
    bindings: {
        entity: '<',
        periodId: '<?'
    },
    controllerAs: 'versionSwitcherController',
    controller: function (Changes, Version, AcademicYearHolder, entityRedirect, entityTranslateFilter, configDateFilter, $state) {
        const versionSwitcherController = this;

        versionSwitcherController.$onInit = function () {
            versionSwitcherController.visible = !AcademicYearHolder.isSimulation();
        };

        versionSwitcherController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'entity') || Changes.hasChanged(changes, 'periodId')) {
                if (_.isEmpty(versionSwitcherController.periodId)) {
                    versionSwitcherController.periodId = sessionStorage.academicYear;
                }

                if (angular.isDefined(versionSwitcherController.entity)) {
                    Version.query({
                        entityType: versionSwitcherController.entity.type,
                        entityId: versionSwitcherController.entity.id
                    }).$promise.then((versions) => {
                        versionSwitcherController.versions = _.map(versions, build);
                        versionSwitcherController.current = _.find(versions, isCurrent);
                    });
                } else {
                    delete versionSwitcherController.versions;
                }
            }
        };

        function build(version) {
            const label = getLabel(version.period);
            return _.extend(version, { label });
        }

        function getLabel(period) {
            if (angular.isUndefined(period)) {
                return '';
            }
            
            let name = entityTranslateFilter(period);
            if (period.type === 'academic-year') {
                return name;
            }

            return `${name} (${configDateFilter(period.startDate)})`;
        }

        function isCurrent(version) {
            return `${version.period.id}` === `${versionSwitcherController.periodId}`;
        }

        versionSwitcherController.onSelect = function(version) {
            const matches = _.isEqual(version.entity, versionSwitcherController.entity);
            if (matches && version.period.type === 'academic-year') {
                AcademicYearHolder.set(version.period);
                $state.reload();
            } else {
                entityRedirect.redirect(version.entity.type, { 
                    id: version.entity.id,
                    periodId: '' + version.period.id
                });
            }
        };
    }
});