'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionsApprovedPage
 * @description Workflow page to display approved descriptions
 */
angular.module('uasApp').component('descriptionsApprovedPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/i18n/descriptions.approved.page.html',
  controllerAs: 'descriptionsApprovedPageController',
  controller: function ($q, noHtmlFilter, Description, Element, Feedback, Language) {
    const descriptionsApprovedPageController = this;

    descriptionsApprovedPageController.$onInit = function () {
      descriptionsApprovedPageController.mode = 'change';
      descriptionsApprovedPageController.text = 'text';

      loadData();
    };

    descriptionsApprovedPageController.onMode = function() {
      loadData();
    };

    function loadData() {
      const entityType = descriptionsApprovedPageController.entity.self.type;
      const entityId = descriptionsApprovedPageController.entity.id;

      descriptionsApprovedPageController.loading = true;
      $q.all([
        Description.getTypes(entityType),
        Description.query({
          entityType: entityType,
          entityId: entityId,
          academicYearId: sessionStorage.academicYear
        }).$promise,
        Feedback.get({
          entityType: entityType,
          entityId: entityId,
          language: Language.get(),
          status: 'OPEN',
          filterType: 'description'
        }).$promise
      ]).then(([elements, descriptions, feedback]) => {
        const groupedElements = _.keyBy(elements, 'id');

        descriptionsApprovedPageController.descriptions = _(descriptions)
          .map((description) => addChanges(description, feedback))
          .map((description) => addElement(description, groupedElements))
          .filter((description) => angular.isDefined(description.element))
          .filter((description) => angular.isDefined(description.text) && description.text !== '')
          .filter((description) => {
            return descriptionsApprovedPageController.mode === 'change' ? angular.isDefined(description.change) : true;
          })
          .map((description) => {
            let result = angular.copy(description);
            result.html = description.text;
            result.text = noHtmlFilter(description.text);
            return result;
          })
          .sortBy(['element.sequence', 'element.externalId'])
          .value();
      }).finally(() => {
        descriptionsApprovedPageController.loading = false;
      });
    }

    function addChanges(description, feedback) {
      const selector = {
        change: {
          entity: {
            type: 'description',
            id: description.id
          }
        }
      };

      description.change = _.find(feedback.changes, selector);
      return description;
    }

    function addElement(description, elements) {
      description.element = elements[description.typeId];
      return description;
    }

    descriptionsApprovedPageController.getDescriptionType = function (description) {
      const property = Language.getPropertyName();
      return description.element[property];
    };
  }
});
