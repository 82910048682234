'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:evaluationParticipatePage
 * @description
 * evaluationParticipatePage Participate in evaluation
 */
angular.module('uasApp').component('evaluationParticipatePage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?'
  },
  templateUrl: 'es6/evaluation/evaluation.participate.page.html',
  controllerAs: 'participatePageController',
  controller: function (EntityService, Evaluation, Page, WorkflowValidator, $uibModal) {
    const participatePageController = this;

    participatePageController.$onInit = function () {
      participatePageController.evaluationType = Page.getParameter(participatePageController.page, 'evaluationType');
      participatePageController.forceComplete = Page.getParameterAsBoolean(participatePageController.page, 'forceComplete');
      participatePageController.openPopup = Page.getParameterAsBoolean(participatePageController.page, 'openPopup');

      WorkflowValidator.setValidity(() => isValid());
      loadData();
    };

    function isValid() {
      let valid = true;
      if (participatePageController.forceComplete) {
        const url = _.get(participatePageController, 'participant.url');
        if (!_.isEmpty(url)) {
          valid = _.get(participatePageController, 'participant.completed');
        }
      }
      return valid;
    }

    participatePageController.$onDestroy = function () {
      WorkflowValidator.reset();
    };

    function loadData() {
      participatePageController.loading = true;
      getParticipant().finally(() => {
        participatePageController.loading = false;
      });
    }

    function getParticipant() {
      return getEvaluation().then((evaluation) => {
        participatePageController.evaluation = evaluation;

        const evaluationId = _.get(evaluation, 'id');
        if (angular.isUndefined(evaluationId)) {
          return;
        }

        const reference = EntityService.toReference(participatePageController.entity);

        return Evaluation.participant({
          entityType: _.get(reference, 'type'),
          entityId: _.get(reference, 'id'),
          evaluationId
        }).$promise;
      }).then((participant) => {
        participatePageController.participant = participant;
        participatePageController.buttonLabel = 'Static.Page.Evaluation.Participate.Start';
        if (participant.completed) {
          participatePageController.buttonLabel = 'Static.Page.Evaluation.Participate.ViewReport';
        }
      });
    }

    function getEvaluation() {
      const reference = EntityService.toReference(participatePageController.entity);
      const params = {
        evaluationType: participatePageController.evaluationType,
        entityType: _.get(reference, 'type'),
        entityId: _.get(reference, 'id')
      };

      return Evaluation.get(params).$promise;
    }

    participatePageController.start = function () {
      $uibModal.open({
        size: 'xl',
        templateUrl: 'es6/evaluation/evaluation.participate.modal.html',
        controllerAs: 'participateModalController',
        controller: function ($sce, $uibModalInstance) {
          const participateModalController = this;
          participateModalController.url = $sce.trustAsResourceUrl(participatePageController.participant.url);
          participateModalController.close = function () {
            $uibModalInstance.dismiss();
          };
        }
      }).result.then(loadData, loadData);
    };

    participatePageController.refresh = function () {
      participatePageController.refreshing = true;
      getParticipant().finally(() => {
        participatePageController.refreshing = false;
      });
    };
  }
});
