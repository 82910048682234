'use strict';

angular.module('uasApp')
    .filter('day', function($filter) {
        var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        return function(index, type) {
            if (index === undefined) {
                return '';
            }

            var day = days[index - 1];
            if (!day) {
                return '?';
            }

            var key = day.toUpperCase();
            if (type === 'short') {
                key += '.Short';
            }
            return $filter('enum')(key);
        };
    });
