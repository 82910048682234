'use strict';

angular.module('uasApp').component('assessmentModal', {
    bindings: {
        assessment: '<',
        includeTypes: '<?',
        operations: '<',
        operationsToEdit: '<',
        onSubmit: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/assessment/assessment.modal.html',
    controllerAs: 'assessmentModalController',
    controller: function() {
        const assessmentModalController = this;

        assessmentModalController.save = function (assessment, comment) {
            return assessmentModalController.onSubmit({
                assessment, comment
            });
        };

        assessmentModalController.cancel = function () {
            assessmentModalController.onCancel();
        };
    }
});