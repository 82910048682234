'use strict';

angular.module('uasApp').component('diffLabel', {
    bindings: {
        left: '<',
        right: '<'
    },
    templateUrl: 'es6/changes/diff.label.html',
    controllerAs: 'diffLabelController',
    controller: function ($sce, DiffHtml) {
        const diffLabelController = this;

        diffLabelController.$onChanges = function() {
            diffLabelController.difference = DiffHtml.buildDiff(
                diffLabelController.left,
                diffLabelController.right
            );
        };
    }
});