'use strict';

/**
 * A directive to show the loader icon and text.
 * The callbacks attribute expects an array of promises.
 */
angular.module('uasApp')
    .component('loader', {
        transclude: true,
        bindings: {
            loading: '<',
            error: '<',
            message: '@?',
            content: '@?',
            overlay: '@?',
            progress: '<?'
        },
        templateUrl: 'es6/app/loader/loader.component.html',
        controllerAs: 'loaderController',
        controller: function(translateFilter) {
            const loaderController = this;

            loaderController.$onInit = function() {
                loaderController.message = loaderController.message || translateFilter('Static.Label.Loading') + '...';
            };
        }
    });
