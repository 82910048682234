'use strict';

angular.module('uasApp').component('tabGroupList', {
  templateUrl: 'es6/app/tabs/group/tab.group.list.html',
  controllerAs: 'tabGroupController',
  controller: function($uibModal, Message, Pageable, TabGroup) {
    const tabGroupController = this;

    tabGroupController.$onInit = function () {
      tabGroupController.pageable = Pageable.of({
        order: 'sequence'
      });

      tabGroupController.search();
    };

    tabGroupController.search = () => {
      const params = tabGroupController.pageable.build({
        text: tabGroupController.searchText || ''
      });

      tabGroupController.loading = true;
      return TabGroup.page(params).$promise.then((groups) => {
        tabGroupController.groups = groups;
      }).finally(() => {
        tabGroupController.loading = false;
      });
    };

    tabGroupController.create = function () {
      open({});
    };

    tabGroupController.edit = function (group) {
      open(group);
    };

    function open(group) {
      $uibModal.open({
        templateUrl: 'es6/app/tabs/group/tab.group.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.group = angular.copy(group);

          modalController.save = function () {
            TabGroup.save(modalController.group).$promise.then(() => {
              tabGroupController.search();
              Message.onSaved();
            });
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    tabGroupController.remove = function (group) {
      TabGroup.remove(group).$promise.then(() => {
        tabGroupController.search();
        Message.onRemoved();
      });
    };
  }
});