'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ExportType
 * @description
 * The ExportType service.
 */
angular.module('uasApp')
    .factory('ExportType', function(UasResource) {
        return UasResource('/export-type');
    });
