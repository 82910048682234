'use strict';

angular.module('uasApp').component('facultiesModules', {
  templateUrl: 'es6/faculties/faculties.modules.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'modulesController'
});
