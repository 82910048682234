'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:qualificationsReport
 * @description
 * Displays qualifications for an entity.
 */
angular.module('uasApp').component('qualificationsReport', {
  templateUrl: 'es6/qualification/report/qualifications.report.html',
  bindings: {
    entity: '<',
    operations: '<',
    isReadOnly: '<?',
    reportTemplate: '<?',
    hideReportTemplates: '<?'
  },
  controllerAs: 'qualificationReportController',
  controller: function ($state, CreateManager, EntityService, Language, Qualification, WorkflowModal) {
    const qualificationReportController = this;

    qualificationReportController.$onInit = function () {
      qualificationReportController.entityType = EntityService.getType(qualificationReportController.entity);

      setName();
      loadData();

      qualificationReportController.onLanguage = Language.onChange(loadData);
    };

    qualificationReportController.$onDestroy = function () {
      qualificationReportController.onLanguage();
    };

    function loadData() {
      qualificationReportController.loading = true;

      return Qualification.report({
        definition: true
      }).$promise.then((report) => {
        qualificationReportController.groups = report.groups;
        qualificationReportController.columns = report.headers;
      }).finally(() => {
        qualificationReportController.loading = false;
      });
    }

    function setName() {
      qualificationReportController.name = 'qualifications';
      qualificationReportController.context = 'qualifications';

      if (angular.isDefined(qualificationReportController.entity)) {
        qualificationReportController.name = `${qualificationReportController.entity.code}-qualifications`;
        qualificationReportController.context = `${qualificationReportController.entityType}.qualifications`;
      }
    }

    qualificationReportController.getRows = function (parameters) {
      if (qualificationReportController.entityType === 'faculty') {
        parameters.facultyId = qualificationReportController.entity.id;
      } else if (qualificationReportController.entityType === 'specification') {
        parameters.specificationId = qualificationReportController.entity.id;
      } else {
        parameters.entityType = qualificationReportController.entityType;
        parameters.entityId = _.get(qualificationReportController.entity, 'id');
      }

      return Qualification.report(parameters).$promise;
    };

    qualificationReportController.onClick = function (row) {
      if (qualificationReportController.isReadOnly) {
        goToDetails(row);
      } else {
        edit(row);
      }
    };

    qualificationReportController.create = function () {
      const qualification = {
        facultyId: _.get(qualificationReportController.entity, 'facultyId'),
        specificationId: _.get(qualificationReportController.entity, 'specificationId')
      };

      if (qualificationReportController.entity) {
        const entityPath = EntityService.getEntityPath(qualificationReportController.entity);
        qualification[entityPath] = qualificationReportController.entity.id;
      }

      CreateManager.open('qualification', qualification, qualificationReportController.operations, () => {
        loadData();
      });
    };

    function edit(row) {
      Qualification.get({
        id: row.entity.id
      }).$promise.then((qualification) => {
        WorkflowModal.open({
          entity: qualification,
          onCancel: loadData,
          onEmpty: () => goToDetails(row),
          onSave: loadData
        });
      });
    }

    function goToDetails(row) {
      $state.go('qualification.general', { id: row.entity.id });
    }
  }
});