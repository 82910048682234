'use strict';

angular.module('uasApp').component('textPage', {
    templateUrl: 'es6/page/text.page.html',
    bindings: {
        entity: '<',
        page: '<',
        process: '<?',
        operations: '<',
        hideHelp: '<?'
    },
    controllerAs: 'textPageController',
    controller: function (Page, Language) {
        const textPageController = this;

        textPageController.$onInit = function() {
            // Hide the page messages, as the text page shows them already
            textPageController.page_ = _.omit(textPageController.page, 'messages');

            textPageController.onLanguage = Language.onChange(loadData);
            loadData();
        };

        textPageController.$onDestroy = function() {
            textPageController.onLanguage();
        };

        function loadData() {
            textPageController.loading = true;
            Page.message({
                id: textPageController.page.id,
                entityType: textPageController.entity.self.type,
                entityId: textPageController.entity.id,
                periodId: _.get(textPageController.process, 'period.id')
            }).$promise.then((result) => {
                textPageController.message = result.message;
            }).finally(() => {
                textPageController.loading = false;
            });
        }
    }
});
