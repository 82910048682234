'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:AppraisalModal
 * @description
 * The AppraisalModal factory.
 */
angular.module('uasApp').factory('AppraisalModal', function ($uibModal, EntityService, Message, feedbackObserver) {

  function create({ parent, entity, category, fields, includeTypes, operations, operationsToEdit, onChange }) {
    const moduleId = findModuleId(entity);

    $uibModal.open({
        size: 'lg',
        template: `
            <appraisal-create
                appraisal="modalController.appraisal"
                parent="modalController.parent"
                category="modalController.category"
                entity="modalController.entity"
                module="modalController.module"
                fields="modalController.fields"
                include-types="modalController.includeTypes"
                operations="modalController.operations"
                operations-to-edit="modalController.operationsToEdit"
                on-save="modalController.onSave(content, result)"
                on-close="modalController.close()">
            </appraisal-create>
        `,
        resolve: {
            module: function(Module) {
                return Module.find(moduleId).$promise;
            }
        },
        controllerAs: 'modalController',
        controller: function (module, $uibModalInstance) {
            const modalController = this;

            modalController.$onInit = function() {
                modalController.appraisal = { 
                    academicYearId: sessionStorage.academicYear,
                    entity: entity.self,
                    assessment: {
                        moduleId: _.result(module, 'id')
                    }
                };

                modalController.parent = parent;
                modalController.category = category;
                modalController.entity = entity;
                modalController.module = module;
                modalController.fields = fields;
                modalController.includeTypes = includeTypes;
                modalController.operations = operations;
                modalController.operationsToEdit = operationsToEdit;
            };

            modalController.onSave = function(content, result) {
                Message.onSaved();
                feedbackObserver.dataChanged();
                onChange(content, result);
                modalController.close();
            };

            modalController.close = function() {
                $uibModalInstance.dismiss();
            };
        }
    });
  }

  function edit({ appraisal, fields, operations, operationsToEdit, onChange }) {
    $uibModal.open({
        size: 'lg',
        template: `
            <appraisal-edit
                appraisal="modalController.appraisal"
                fields="modalController.fields"
                operations="modalController.operations"
                operations-to-edit="modalController.operationsToEdit"
                on-save="modalController.onSave(content, result)"
                on-close="modalController.close()">
            </appraisal-edit>
        `,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
            const modalController = this;

            modalController.$onInit = function() {
                modalController.appraisal = buildModel();
                modalController.fields = fields;
                modalController.operations = operations;
                modalController.operationsToEdit = operationsToEdit;
            };

            function buildModel() {
                const model = angular.copy(appraisal);
                model.assessmentId = _.result(appraisal, 'assessment.id');
                model.choiceTypeId = _.result(appraisal, 'choiceType.id');
                return model;
            }
    
            modalController.onSave = function() {
                feedbackObserver.dataChanged();
                Message.onSaved();
                onChange();
                modalController.close();
            };

            modalController.close = function() {
                $uibModalInstance.dismiss();
            };
        }
    });
  }

  function findModuleId(entity) {
    const entityType = EntityService.getType(entity);
    return entityType === 'module' ? entity.id : entity.moduleId;
  }

  return { create, edit };

});