'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:mineWidget
 * @description
 * # mineWidget
 * Displays entities and roles of the current user.
 */
angular.module('uasApp')
  .component('mineWidget', {
    bindings: {
        academicYearId: '<'
    },
    templateUrl: 'es6/dashboard/mine.widget.html',
    controllerAs: 'mineWidgetController',
    controller: function(Mine, Feature, StudyCost, ENTITY_STATES) {
        const mineWidgetController = this;
        const batchSize = 5;

        mineWidgetController.$onInit = function() {
            mineWidgetController.mines = [];
            mineWidgetController.page = 0;
            loadNext();
        };

        mineWidgetController.next = function() {
            loadNext();
        };

        function loadNext() {
            mineWidgetController.page++;
            mineWidgetController.loading = true;

            Mine.get({
                academicYearId: mineWidgetController.academicYearId,
                page: mineWidgetController.page,
                size: batchSize
            }).$promise.then((mines) => {                
                const values = _.map(mines.content, (mine) => {
                    mine.href = ENTITY_STATES[mine.entity.type] + `({ id: ${mine.entity.id }})`;
                    mine.roles = _.uniqBy(mine.roles, 'id');
                    
                    addCost(mine);
                    return mine;
                });

                mineWidgetController.mines = _.concat(mineWidgetController.mines, values);
                mineWidgetController.more = mines.last === false;
            }).finally(() => {
                mineWidgetController.loading = false;
            });
        }

        function addCost(mine) {
            if (mine.entity.self.type === 'study' && _.some(mine.roles, { showBudget: true })) {
                Feature.allowed({
                  entityType: 'study',
                  entityId: mine.entity.id
                }).$promise.then((enabledFeatures) => {
                    if (_.includes(enabledFeatures, 'COST')) {
                        StudyCost.get({
                            studyId: mine.entity.id
                        }).$promise.then((result) => {
                            mine.cost = result.studyCost;
                        });
                    }
                });
            }
        }
    }
});
