'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processWidget
 * @description
 * processWidget Shows the process as widget.
 */
angular
  .module('uasApp')
  .component('processWidget', {
    templateUrl: 'es6/process/progress/process.widget.html',
    controllerAs: 'processWidgetController',
    controller: function (ProcessProgress) {
        const processWidgetController = this;

        processWidgetController.$onInit = function() {
            ProcessProgress.widgets({
                academicYearId: sessionStorage.academicYear
            }).$promise.then((widgets) => {
                processWidgetController.widgets = widgets;
            });
        };
    }
});
