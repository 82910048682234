'use strict';

/**
 * The redirect strategies for the main module 'uasApp'.
 */
angular.module('uasApp')
    .factory('ProcessTakeover', function($uibModal) {

      const chunkSize = 25;
      let instance = {};

      instance.open = function(args) {
          return $uibModal.open({
            templateUrl: 'es6/process/progress/process.takeover.html',
            controllerAs: 'takeoverController',
            controller: function($uibModalInstance, Process, Promises, translateFilter) {
              const takeoverController = this;

              takeoverController.entityType = args.entityType;
              takeoverController.entityIds = args.entityIds;
              takeoverController.process = args.process;
              takeoverController.statuses = args.statuses;

              const unusedStatus = _.find(takeoverController.statuses, { index: -1 });
              if (unusedStatus) {
                unusedStatus.labels = [{ value: translateFilter('Static.Label.Unused') }];
              }

              takeoverController.submit = function() {
                const chunks = _.chunk(takeoverController.entityIds, chunkSize);
                const requests = _.map(chunks, build);
                const progress = Promises.chain(requests);

                takeoverController.progress = progress;
                progress.$promise.finally(() => {
                  args.callback();
                  $uibModalInstance.dismiss();
                });
              };

              function build(entityIds) {
                const body = {
                    entityType: takeoverController.entityType,
                    entityIds: entityIds,
                    newStatus: _.result(takeoverController.newStatus, 'id'),
                    message: takeoverController.message
                };

                return () => Process.transition({
                  id: _.result(takeoverController.process, 'id')
                }, body).$promise;
              }
            }
          });
      };

      return instance;

    });

