'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorklog
 * @description
 * uasWorklog show the work hours of a person on a module
 */
angular.module('uasApp')
  .component('uasWorklog', {
    bindings: {
      module: '<',
      operations: '<',
      operationsToEdit: '@',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/staffing/worklog/worklog.html',
    controllerAs: 'worklogController',
    controller: function ($q, $uibModal, TaskTemplate, MethodType, Period, Offering, Worklog,
      Message, i18nFilter, entityTranslateFilter) {
      const worklogController = this;

      worklogController.$onInit = function() {
        worklogController.readOnly = worklogController.isReadOnly === true;

        worklogController.loading = true;
        $q.all([
          Offering.periods({
            entityType: 'module',
            entityId: worklogController.module.id
          }).$promise,
          TaskTemplate.query({
            academicYearId: worklogController.module.academicYearId
          }).$promise,
          MethodType.query().$promise
        ]).then(([periods, tasks, methodTypes]) => {
          worklogController.periods = periods;
          worklogController.periods.push({
            id: undefined
          });

          worklogController.tasks = _.sortBy(tasks, (task) => {
            return i18nFilter(task.names);
          });
          
          worklogController.methodTypes = methodTypes;
        }).then(loadData).finally(() => {
          worklogController.loading = false;
        });
      };

      function loadData() {
        return Worklog.query({ 
          moduleId: worklogController.module.id
        }).$promise.then((worklogs) => {
            _.forEach(worklogs, (worklog) => {
              worklog.task = _.find(worklogController.tasks, { id: worklog.taskId });
              worklog.methodType = _.find(worklogController.methodTypes, { id: worklog.methodTypeId });
            });

            worklogController.worklogs = sort(worklogs);
            
            if (angular.isDefined(worklogController.worklogForm)) {
              worklogController.worklogForm.$setPristine();
            }

            validate();
            return worklogs;
          });
      }

      function sort(worklogs) {
        return _.sortBy(worklogs, [
          // Sort on method type of duration task
          (worklog) => {
            if (worklog.periodId) {
              return entityTranslateFilter(worklog.methodType);
            } else {
              return i18nFilter(_.get(worklog.task, 'names'));
            }
          },
          // Sort on person
          (worklog) => _.result(worklog, 'person.fullName')
        ]);
      }

      const validate = function() {
        worklogController.incomplete = _.some(worklogController.worklogs, (worklog) => {
          return angular.isUndefined(worklog.person);
        });

        worklogController.unapproved = _.some(worklogController.worklogs, { 
          approved: false 
        });
      };

      worklogController.reset = function() {
        worklogController.loading = true;
        Worklog.reset({
          moduleId: worklogController.module.id
        }, {}).$promise.then(loadData).finally(() => {
          worklogController.loading = false;
        });
      };

      // Remove all worklogs
      worklogController.clear = function() {
        const promises = _.map(worklogController.worklogs, (worklog) => Worklog.remove({
          id: worklog.id
        }).$promise);

        worklogController.loading = true;
        $q.all(promises).then(loadData).finally(() => {
          worklogController.loading = false;
        });
      };

      // Remove one worklog
      worklogController.remove = function(worklog) {
        worklogController.loading = true;
        Worklog.remove({
          id: worklog.id
        }).$promise.then(loadData).finally(() => {
          worklogController.loading = false;
        });
      };

      worklogController.add = function(periodId) {
        const worklog = {
          moduleId: worklogController.module.id,
          academicYearId: sessionStorage.academicYear,
          periodId: periodId,
          approved: false,
          contactMinutes: 0,
          nonContactMinutes: 0
        };

        worklogController.edit(worklog);
      };

      worklogController.approve = function() {
        const promises = _(worklogController.worklogs).filter({ 
          approved: false 
        }).map((worklog) => {
          const personId = _.result(worklog, 'person.id');

          return _.extend(worklog, { 
            person: personId,
            approved: true 
          });
        }).map((worklog) => Worklog.store(worklog).$promise).value();

        worklogController.loading = true;
        $q.all(promises).then(loadData).finally(() => {
          worklogController.loading = false;
        });
      };

      worklogController.edit = function(worklog) {
        $uibModal.open({
          controllerAs: 'worklogEditController',
          controller: function ($uibModalInstance) {
              const worklogEditController = this;
              worklogEditController.worklog = build(worklog);
              worklogEditController.methodTypes = worklogController.methodTypes;
              worklogEditController.tasks = worklogController.tasks;

              worklogEditController.close = function() {
                $uibModalInstance.close();
              };

              worklogEditController.onSave = function(result) {
                const body = angular.copy(result);
                body.approved = true;

                worklogController.loading = true;
                Worklog.store(body).$promise.then(loadData).finally(() => {
                  validate();
                  Message.addSuccessLabel('Static.Message.DataSaved');
                  worklogEditController.close();
                  worklogController.loading = false;
                });
              };
          },
          template: `<uas-worklog-edit
                        worklog="worklogEditController.worklog"
                        method-types="worklogEditController.methodTypes"
                        tasks="worklogEditController.tasks"
                        on-cancel="worklogEditController.close()"
                        on-save="worklogEditController.onSave(data)"
                     </uas-worklog-edit>`
        });
      };

      function build(worklog) {
        const model = angular.copy(worklog);
        model.person = _.result(worklog, 'person.id');
        return model;
      }
    }
  });
