'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasImportIgnore
 * @description
 * uasImportIgnore Shows additional
 */
angular.module('uasApp')
    .component('uasImportIgnore', {
        templateUrl: 'es6/import/import.ignore.html',
        controllerAs: 'importIgnoreController',
        controller: function (ImportIgnore, Pageable, $uibModal) {
            const importIgnoreController = this;

            importIgnoreController.$onInit = function() {
                importIgnoreController.pageable = Pageable.of({
                    order: 'externalId'
                });
                loadData();
            };

            importIgnoreController.onPage = function() {
                loadData();
            };

            function loadData() {
                const params = importIgnoreController.pageable.build({
                    academicYearId: sessionStorage.academicYear
                });

                importIgnoreController.loading = true;
                ImportIgnore.get(params).$promise.then(function(ignores) {
                    importIgnoreController.ignores = ignores;
                }).finally(function() {
                    importIgnoreController.loading = false;
                });
            }

            importIgnoreController.create = function() {
                importIgnoreController.edit({
                    academicYearId: sessionStorage.academicYear
                });
            };

            importIgnoreController.edit = function(ignore) {
                $uibModal.open({
                    templateUrl: 'es6/import/import.ignore.edit.html',
                    controllerAs: 'importIgnoreEditController',
                    controller: function($uibModalInstance) {
                        this.ignore = ignore;
                        this.save = function() {
                            ImportIgnore.store(this.ignore).$promise.then(function() {
                                $uibModalInstance.close();
                                loadData();
                            });
                        };
                    }
                });
            };

            importIgnoreController.remove = function(ignore) {
                ImportIgnore.remove({ id: ignore.id }).$promise.then(loadData);
            };
        }
    });
