'use strict';

angular.module('uasApp').component('authorizedContent', {
  templateUrl: 'es6/security/authorized.content.html',
  transclude: true,
  controllerAs: 'authorizedContentController',
  controller: function ($scope, AuthService, Session, Parameter) {
    const authorizedContentController = this;

    authorizedContentController.$onInit = function () {
      Parameter.load().then(() =>
        authorizedContentController.noRolesAllowed = Parameter.getParameterAsBoolean('user.noroles.allowed', false)
      );

      $scope.$watch(() => Session.personId, () => {
        authorizedContentController.authorized = Session.authorized;
      });
    };

    /**
     * Only show content if user is authorized or not authenticated (login page).
     */
    authorizedContentController.showContent = function () {
      return !AuthService.isAuthenticated() || authorizedContentController.authorized || authorizedContentController.noRolesAllowed;
    };
  }
});