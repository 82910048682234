'use strict';

angular.module('uasApp').component('entityFieldInput', {
  bindings: {
    inputId: '@',
    field: '<?',
    operations: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    classes: '@?',
    viewClasses: '@?',
    excludeIds: '<?',
    evaluation: '<?',
    filterValues: '<?',
    minItems: '<?',
    maxItems: '<?',
    onRemove: '&'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/entity/entity.field.input.html',
  controllerAs: 'entityFieldInputController',
  controller: function (Changes, CustomField, CreateManager) {
    const entityFieldInputController = this;

    entityFieldInputController.$onInit = function () {
      entityFieldInputController.displayTags = (entityFieldInputController.isReadOnly || entityFieldInputController.isDisabled) && entityFieldInputController.field.valueType === 'ENTITY';
      entityFieldInputController.ngModelCtrl.$render = function () {
        updateValues();
      };
    };

    entityFieldInputController.$onChanges = function(changes) {
      if (Changes.hasChanged(changes, 'evaluation')) {
        setFilter();
      }
    };

    function setFilter() {
      entityFieldInputController.filter = CustomField.getFilter(entityFieldInputController.field, entityFieldInputController.evaluation);
    }

    function updateValues() {
      const value = entityFieldInputController.ngModelCtrl.$modelValue;

      entityFieldInputController.values = _.isArray(value) ? value : [value];
      if (!entityFieldInputController.field.multipleValues) {
        entityFieldInputController.value = _.head(value);
      }
    }

    entityFieldInputController.onValueChange = function (value) {
      if (!entityFieldInputController.field.multipleValues || _.isArray(value)) {
        entityFieldInputController.ngModelCtrl.$setViewValue(value);
      } else {
        delete entityFieldInputController.value;

        // Add value to existing values
        if (angular.isDefined(value)) {
          const values = _(entityFieldInputController.values).pull(value).concat(value).value();
          entityFieldInputController.ngModelCtrl.$setViewValue(values);
        }
      }

      updateValues();
    };

    entityFieldInputController.create = function () {
      CreateManager.open(entityFieldInputController.field.subType, {}, entityFieldInputController.operations, (created) => {
        entityFieldInputController.onValueChange(created.id);
      });
    };
  }
});
