'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasFacultyOfferings
 * @description
 * # uasFacultyOfferings
 * Faculty report shows all module offerings.
 */
angular.module('uasApp')
    .component('uasFacultyOfferings', {
        bindings: {
            faculty: '<',
            terminated: '<',
            onChange: '&'
        },
        templateUrl: 'es6/organisation/faculty/faculty.offerings.html',
        controllerAs: 'facultyOfferingsController',
        controller: function($q, AuthService, Offering) {
            const facultyOfferingsController = this;

            facultyOfferingsController.$onInit = function() {
                loadData();
            };

            function loadData() {
                facultyOfferingsController.loading = true;
                $q.all([
                    AuthService.operations('faculty', facultyOfferingsController.faculty.id),
                    Offering.studies({ definition: true }).$promise
                ]).then(([operations, report]) => {
                    facultyOfferingsController.operations = operations;
                    facultyOfferingsController.columns = report.headers;
                    facultyOfferingsController.groups = report.groups;
                }).finally(() => {
                    facultyOfferingsController.loading = false; 
                });
            }

            facultyOfferingsController.getRows = function(pageable) {
                const params = _.extend(pageable, getParameters());
                return Offering.studies(params).$promise;
            };

            facultyOfferingsController.search = function() {
                facultyOfferingsController.onChange({
                    parameters: getParameters()
                });
            };

            function getParameters() {
                return {
                    academicYearId: sessionStorage.academicYear,
                    facultyId: facultyOfferingsController.faculty.id,
                    terminated: facultyOfferingsController.terminated
                };
            }
        }
    });
