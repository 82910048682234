'use strict';

angular.module('uasApp').component('customFieldInput', {
  bindings: {
    customField: '<',
    inputId: '@?',
    operations: '<',
    operationsToEdit: '<?',
    classes: '@?',
    isDisabled: '<?',
    isNew: '<?',
    isPublished: '<?',
    isReadOnly: '<?',
    isRequired: '<?',
    viewClasses: '<?',
    textPattern: '<?',
    arrayModel: '<?', // Indicates if the ng-model is an array or a single value. Default = false
    evaluation: '<?',
    placeholder: '@?',
    maxLength: '<?', // If defined overrides max characters defined in custom field
    hideValidMaxLength: '<?',
    minValue: '<?',
    maxValue: '<?',
    filterValues: '<?', // Option filter function
    excludeIds: '<?',
    truncate: '<?' // If true read-only values will be truncated with ellipsis and tooltip on hover when content is too large
  },
  require: {
    ngModelCtrl: 'ngModel',
    formCtrl: '?^form'
  },
  templateUrl: 'es6/fields/custom.field.input.html',
  controllerAs: 'fieldController',
  controller: function ($scope, $timeout, AuthService, CustomField, Changes, Util) {
    const fieldController = this;

    fieldController.$onInit = function () {
      const defaultOperations = fieldController.isNew ? ['CREATE'] : ['EDIT', 'EDIT_WORKFLOW'];
      fieldController.operationsToEdit_ = AuthService.buildOperationsToEdit(fieldController.operationsToEdit, defaultOperations);

      setModelFormatter();
      setModelRender();
      setModelParser();
    };

    function setModelFormatter() {
      fieldController.ngModelCtrl.$formatters.push((modelValue) => {
        let values = angular.isDefined(modelValue) ? modelValue : [];
        if (!_.isArray(values)) {
          values = [values];
        }
        return values;
      });
    }

    function setModelRender() {
      fieldController.ngModelCtrl.$render = () => {
        fieldController.values = _.map(fieldController.ngModelCtrl.$viewValue, (value) =>
          CustomField.parseValue(value, fieldController.customField.valueType)
        );

        if (!fieldController.customField.multipleValues) {
          fieldController.model = _.head(fieldController.values);

          const defaultValue = CustomField.getDefaultValue(fieldController.customField);
          if (shouldSetDefaultValue(defaultValue)) {
            $timeout(() => {
              fieldController.onValueChanged(defaultValue);
            }, 0);
          }
        }
      };
    }

    function setModelParser() {
      fieldController.ngModelCtrl.$parsers.push((viewValue) => {
        let parsedValue = null;
        if (fieldController.arrayModel) {
          parsedValue = _.map(viewValue, (value) => CustomField.parseValue(value, fieldController.customField.valueType));
        } else if (!_.isEmpty(viewValue)) {
          parsedValue = CustomField.parseValue(_.head(viewValue), fieldController.customField.valueType);
        }
        // Return the original view value if the parsed value is undefined.
        // Returning undefined will result in a parse error registered at the form.
        // This prevents submitting an empty value in a select box.
        if (angular.isDefined(parsedValue)) {
          return parsedValue;
        }
        return viewValue;
      });
    }

    fieldController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'customField') && fieldController.customField) {
        fieldController.arrayModel = fieldController.customField.multipleValues || fieldController.arrayModel || false;
        fieldController.id = fieldController.inputId || getReadableValidId(fieldController.customField);
      }

      updateValidators(changes);

      if (Changes.hasChanged(changes, ['customField', 'isDisabled', 'isReadOnly', 'isNew'])) {
        fieldController.readOnly = fieldController.isReadOnly === true;
        fieldController.disabled = fieldController.isDisabled === true || !isEditable();

        if (fieldController.readOnly || fieldController.disabled) {
          setDisabled();
        }

        if (fieldController.required) {
          setRequired();
        }
      }
    };

    function updateValidators(changes) {
      if (!(fieldController.customField.disabled || fieldController.customField.readOnly)) {
        if (Changes.hasChanged(changes, 'customField') && fieldController.customField) {
          setRequiredValidity();
        }

        if (Changes.hasChanged(changes, ['customField', 'min', 'max'])) {
          setMinValidity();
          setMaxValidity();
        }

        if (Changes.hasChanged(changes, ['customField', 'maxLength'])) {
          setMaxCharacters();
        }
      }
    }

    function setDisabled() {
      fieldController.customField = CustomField.disable(fieldController.customField);
      fieldController.ngModelCtrl.$setDirty = _.noop;
      fieldController.ngModelCtrl.$validators = {};
    }

    function setRequired() {
      fieldController.customField = CustomField.require(fieldController.customField);
    }

    function isEditable() {
      if (fieldController.customField.readOnly) {
        return false;
      }

      const editable = fieldController.customField.editable;
      if (editable === 'ALWAYS') {
        return true;
      } else if (editable === 'NEW' && angular.isDefined(fieldController.isNew)) {
        return fieldController.isNew === true;
      } else if (editable === 'EXISTING' && angular.isDefined(fieldController.isNew)) {
        return fieldController.isNew === false;
      } else if (editable === 'PUBLISHED' && angular.isDefined(fieldController.isPublished)) {
        return fieldController.isPublished === true;
      } else if (editable === 'UNPUBLISHED' && angular.isDefined(fieldController.isPublished)) {
        return fieldController.isPublished === false;
      }
      return true;
    }

    function shouldSetDefaultValue(defaultValue) {
      return !fieldController.isReadOnly
        && !hasValue(fieldController.model)
        && angular.isDefined(defaultValue)
        && fieldController.customField.valueType !== 'ELEMENT' // Default values for elements are configured with defaultSelected in elements
        && fieldController.customField.valueType !== 'REFERENCE' // Default values for references are configured with defaultSelected in references
        && fieldController.customField.required;
    }

    function hasValue(value) {
      return angular.isDefined(value) && value !== '' && value !== null;
    }

    function getReadableValidId(field) {
      const name = _.get(field, 'name', '');
      const simplifiedName = _.kebabCase(_.deburr(name));

      return _.uniqueId(`cf-input-${simplifiedName}`);
    }

    function setRequiredValidity() {
      if (fieldController.customField.multipleValues) {
        fieldController.ngModelCtrl.$isEmpty = function (values) {
          return _.filter(values, (value) => hasValue(value)).length === 0;
        };
        fieldController.ngModelCtrl.$validators.required = function (modelValue, viewValue) {
          const value = modelValue || viewValue;
          return !(fieldController.customField.required && fieldController.ngModelCtrl.$isEmpty(value));
        };
      }
    }

    function setMinValidity() {
      const minValue = angular.isDefined(fieldController.minValue) ? fieldController.minValue : fieldController.customField.min;
      if (fieldController.customField.valueType === 'NUMBER' && angular.isDefined(minValue)) {
        fieldController.ngModelCtrl.$validators.min = function (modelValue, viewValue) {
          return _.every(viewValue, (value) => angular.isUndefined(value) || value === null || value >= minValue);
        };
      }
    }

    function setMaxValidity() {
      const maxValue = angular.isDefined(fieldController.maxValue) ? fieldController.maxValue : fieldController.customField.max;
      if (fieldController.customField.valueType === 'NUMBER' && angular.isDefined(maxValue)) {
        fieldController.ngModelCtrl.$validators.max = function (modelValue, viewValue) {
          return _.every(viewValue, (value) => angular.isUndefined(value) || value === null || value <= maxValue);
        };
      }
    }

    function setMaxCharacters() {
      fieldController.maxCharacters = _.get(fieldController.customField, 'maxCharacters');
      if (angular.isDefined(fieldController.maxLength)) {
        fieldController.maxCharacters = fieldController.maxLength;
      }
    }

    // When the valueType is changed the model is deleted to prevent parse exceptions because the model can be invalid for the new type.
    $scope.$watch('fieldController.customField.valueType', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        delete fieldController.model;
      }
    });

    function setViewValue() {
      // A copy is passed to the view value because ngModel does not perform a deep watch
      fieldController.ngModelCtrl.$setViewValue(angular.copy(fieldController.values));
    }

    fieldController.addValue = function () {
      if (angular.isUndefined(fieldController.model)) {
        return;
      }
      fieldController.values.push(fieldController.model);
      delete fieldController.model;
      setViewValue();
    };

    fieldController.removeValue = function (value) {
      _.pull(fieldController.values, value);
      setViewValue();
    };

    fieldController.onValueChanged = function (value) {
      fieldController.model = value;
      if (!fieldController.customField.multipleValues) {
        fieldController.values = Util.toArray(value);
      } else if (fieldController.customField.valueType === 'DATE') {
        const exists = _(fieldController.values)
          .filter((v) => _.isEqual(v, fieldController.model))
          .head();
        if (!exists) {
          $timeout(() => fieldController.addValue(), 0);
        }
      }
      setViewValue();
    };
  }
});
