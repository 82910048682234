'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Workflow
 * @description
 * # Participant
 * Participant resource of the uasApp
 */
angular.module('uasApp').factory('Participant', function (UasResource) {
  return UasResource('/participants', {
    complete: {
      url: '/participants/:id/complete',
      method: 'PUT'
    },
    mine: {
      url: '/participants/mine',
      method: 'GET',
      isArray: true
    }
  });
});
