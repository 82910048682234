'use strict';

angular.module('uasApp').component('history', {
    bindings: {
        content: '<',
        changeType: '<'
    },
    controllerAs: 'historyController',
    templateUrl: 'es6/changes/history.html'
});
