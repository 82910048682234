'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:descriptionListPage
 * @description
 * descriptionListPage Shows description for all studies that use the entity
 */
angular.module('uasApp').component('descriptionListPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/i18n/descriptions/description.list.page.html',
  controllerAs: 'descriptionListPageController',
  controller: function (Description, Language, Page) {
    const descriptionListPageController = this;

    descriptionListPageController.$onInit = function () {
      descriptionListPageController.typeCode = Page.getParameter(descriptionListPageController.page, 'type');
      descriptionListPageController.noDescriptionsLabel = Page.getParameter(descriptionListPageController.page, 'noDescriptionsLabel');

      Language.onChange(loadData);
      loadData();
    };

    function loadData() {
      descriptionListPageController.loading = true;

      Description.list({
        entityType: descriptionListPageController.entity.self.type,
        entityId: descriptionListPageController.entity.id,
        rootType: 'study',
        typeCode: descriptionListPageController.typeCode,
        language: Language.get()
      }).$promise.then((descriptions) => {
        descriptionListPageController.descriptions = _(descriptions).map((description) => {
          return _.extend(description, {
            owned: description.entity.id === descriptionListPageController.entity.studyId
          });
        }).orderBy(['owned', 'entity.code', 'entity.id'], ['desc', 'asc', 'asc']).value();
      }).finally(() => {
        descriptionListPageController.loading = false;
      });
    }
  }
});
