'use strict';

angular.module('uasApp').component('scaleSelect', {
  templateUrl: 'es6/subjects/scale/scale.select.html',
  bindings: {
    scaleId: '<',
    isRequired: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'scaleSelectController',
  controller: function (Changes, Scale) {
    const scaleSelectController = this;

    scaleSelectController.$onInit = function () {
      scaleSelectController.ngModelCtrl.$render = function () {
        scaleSelectController.valueId = scaleSelectController.ngModelCtrl.$modelValue;
      };
    };

    scaleSelectController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'scaleId')) {
        loadValues();
      }
      scaleSelectController.required = scaleSelectController.isRequired === true;
    };

    function loadValues() {
      delete scaleSelectController.values;
      if (angular.isDefined(scaleSelectController.scaleId)) {
        Scale.get({
          id: scaleSelectController.scaleId
        }).$promise.then((scale) => {
          scaleSelectController.values = scale.values;
        });
      }
    }

    scaleSelectController.onSelect = function () {
      scaleSelectController.ngModelCtrl.$setViewValue(scaleSelectController.valueId);
    };
  }
});