'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processManager
 * @description
 * processManager Pick the process status
 */
angular.module('uasApp').component('processManager', {
    bindings: {
        isReadOnly: '<?',
        process: '<'
    },
    templateUrl: 'es6/process/progress/process.manager.html',
    controllerAs: 'processManagerController',
    controller: function (Process, ProcessProgress, EntityService) {
        const processManagerController = this;

        processManagerController.$onInit = function() {
            processManagerController.editable = processManagerController.isReadOnly !== true;
            loadData();
        };

        function loadData() {
            processManagerController.loading = true;
            ProcessProgress.get({
                process: processManagerController.process.id
            }).$promise.then((progress) => {
                processManagerController.entities = progress.entities;
                processManagerController.statuses = _.filter(progress.statuses, (status) => angular.isDefined(status.id));
                processManagerController.search();
            }).finally(() => {
                processManagerController.loading = false;
            });
        }

        processManagerController.search = function() {
            processManagerController.filtered = _(processManagerController.entities).filter(matches).map((entity) => {
                const visible = isVisible(entity);
                return _.extend(entity, { visible });
            }).value();

            processManagerController.empty = !_.some(processManagerController.filtered, { visible: true });

            setTotals();
        };

        function matches(entity) {
            if (_.isEmpty(processManagerController.term)) {
                return true;
            }

            return includesText(entity.code, processManagerController.term) || 
                   includesText(entity.localName, processManagerController.term) || 
                   includesText(entity.englishName, processManagerController.term);
        }

        function includesText(text, term) {
            return _.includes(_.toLower(text), _.toLower(term));
        }

        function isVisible(entity) {
            return angular.isUndefined(processManagerController.statusId) || entity.status === processManagerController.statusId;
        }

        function setTotals() {
            _.forEach(processManagerController.statuses, (status) => {
                status.total = _.filter(processManagerController.filtered, {
                    status: status.id
                }).length;
            });
        }

        processManagerController.filterStatus = function(status) {
            processManagerController.statusId = _.result(status, 'id');
            processManagerController.search();
        };

        processManagerController.selectAll = function() {
            processManagerController.selected = processManagerController.selected !== true;
            _(processManagerController.filtered).filter({ visible: true }).forEach((entity) => {
                entity.selected = processManagerController.selected;
            });
        };

        processManagerController.changeSelected = function(status) {
            const selected = _.filter(processManagerController.filtered, { selected: true, visible: true });
            setStatus(selected, status);
        };

        processManagerController.changeStatus = function(entity, status) {
            setStatus([entity], status);
        };

        function setStatus(entities, status) {
            if (processManagerController.editable && entities.length > 0) {
                const entityType = EntityService.getType(entities[0]);
                return Process.transition({
                    id: processManagerController.process.id
                }, {
                    entityType,
                    entityIds: _.map(entities, 'id'),
                    newStatus: status.id,
                    message: ''
                }).$promise.then(() => {
                    _.forEach(entities, (entity) => {
                        entity.status = status.id;
                        entity.statusTime = new Date();
                    });

                    processManagerController.search();
                });
            }
        }
    }
});