'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ExportDetails
 * @description Shows a modal with exports, that can be refreshed.
 */
angular.module('uasApp')
  .factory('ExportDetails', function($uibModal) {
    function show(lines) {
      $uibModal.open({
        template: `
          <export-details
              lines="exportModalController.lines"
              on-close="exportModalController.close()"
              on-refresh="exportModalController.onRefresh()">
          </export-details>
      `,
        size: 'lg',
        controllerAs: 'exportModalController',
        controller: function($uibModalInstance, Export, Promises) {
          const modalController = this;
          modalController.lines = lines;
          modalController.close = () => $uibModalInstance.dismiss();

          modalController.onRefresh = () => {
            const promises = _.map(modalController.lines, (line) => {
              return () => loadExport(line.id);
            });
            
            Promises.chain(promises).$promise.then((exports) => {
              modalController.lines = exports;
            });
          };
 
          function loadExport(exportId) {
            return Export.get({
              id: exportId
            }).$promise;
          }
        }
      });
    }

    return { show };
  });
