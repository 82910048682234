'use strict';

angular.module('uasApp').component('requiredBadge', {
  templateUrl: 'es6/app/forms/validation/required.badge.html',
  bindings: {
    valid: '@?'
  },
  transclude: true,
  controllerAs: 'requiredBadgeController',
  controller: function() {
    const requiredBadgeController = this;

    requiredBadgeController.$onInit = function() {
      setSuccess();
    };

    requiredBadgeController.$onChanges = function() {
      setSuccess();
    };

    function setSuccess() {
      requiredBadgeController.success = requiredBadgeController.valid === 'true';
    }
  }
});
