'use strict';

angular.module('uasApp').component('markdownEditorModal', {
  bindings: {
    markdown: '<',
    modal: '<', // $uibModalInstance
    editorId: '@',
    language: '@?',
    getButtons: '&?', // Callback that return list of buttons in promise
    isRequired: '<?',
    onSave: '&'
  },
  templateUrl: 'es6/app/markdown.editor.modal.html',
  controllerAs: 'markdownEditorModalController',
  controller: function($window, translateFilter) {
    const markdownEditorModalController = this;

    markdownEditorModalController.cancel = function() {
      if (!markdownEditorModalController.isDirty || $window.confirm(translateFilter('Static.MarkdownEditor.Message.CloseWithoutSaving'))) {
        markdownEditorModalController.modal.close();
      }
    };

    markdownEditorModalController.onChange = function() {
      markdownEditorModalController.isDirty = true;
    };

    markdownEditorModalController.save = function() {
      markdownEditorModalController.onSave({ markdown: markdownEditorModalController.markdown });
      markdownEditorModalController.modal.close();
    };
  }
});
