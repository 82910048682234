'use strict';

angular.module('uasApp')
    .factory('SecurityService', function($log) {

        function isAllowed(operations, granted) {
            if (_.isString(operations)) {
                operations = getOperations(operations);
            }

            if (_.isEmpty(operations)) {
                return true;
            } else if (_.isEmpty(granted) || !_.isArray(operations)) {
                return false;
            }

            return _.intersection(operations, granted).length > 0;
        }

        function getOperations(value) {
            if (_.isEmpty(value)) {
                return [];
            }

            if (_.isArray(value)) {
                return value;
            }

            if (_.includes(value, '[') && _.includes(value, ']')) {
                try {
                    return angular.fromJson(value);
                } catch (e) {
                    $log.error(`Cannot get JS-array of operations out of text-value ${value}`);
                }
            }

            const result = ('' || value).split(',');
            return _.map(result, (valueInResult) => valueInResult.trim());
        }

        return { isAllowed, getOperations };
    });
