'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:EditButton
 * @description
 * The EditButton service.
 */
angular.module('uasApp')
  .directive('editButton', function () {
    return {
      restrict: 'E',
      scope: {
        btnType: '@?',
        btnSize: '@?',
        btnIconOnly: '=?',
        btnContext: '@?',
        btnClass: '@?',
        customTooltip: '@?',
        type: '@?'
      },
      require: '?^secured',
      templateUrl: 'es6/app/forms/buttons/edit.button.html',
      link: function (scope, element, attrs, securedCtrl) {
        scope.btnContext = scope.btnContext || 'default';

        // Secure button after it has been rendered
        if (securedCtrl) {
          securedCtrl.resecure(element);
        }
      }
    };
  });
