'use strict';

angular.module('uasApp').component('facultyScheduledActivitiesReport', {
  bindings: {
    facultyId: '<',
    studyId: '<?'
  },
  templateUrl: 'es6/schedule/scheduled/faculty.scheduled.activities.report.html',
  controllerAs: 'activitiesReportController',
  controller: function ($state, Study) {
    const activitiesReportController = this;

    activitiesReportController.$onInit = function () {
      return Study.query({
        facultyId: activitiesReportController.facultyId
      }).$promise.then((studies) => {
        activitiesReportController.studies = _.sortBy(studies, 'code');
      });
    };

    activitiesReportController.loadReport = function () {
      $state.go('faculty.scheduled-activities-report', {
        studyId: activitiesReportController.studyId
      });
    };
  }
});
