'use strict';

angular.module('uasApp').factory('workflowSetupWizardService', function () {

    const NUMBER_OF_BASE_STEPS = 2;

    function setAdditionalSteps(scope, pages) {
        // Set the indices to all the pages
        pages = _.each(pages, (page, index) => {
            page.index = index;
        });

        // Make all the additional steps to show up as workflow-wizard tabs.
        let additionalSteps = _.map(pages, (page) => {
            let pageIndex = pages.indexOf(page);
            return {
                isAdditional: true,
                titles: page.titles,
                route: 'workflow-setup.page-settings',
                stateParams: { pageIndex: pageIndex },
                index: NUMBER_OF_BASE_STEPS + pageIndex
            };
        });

        scope.$emit('setAdditionalSteps', additionalSteps);
    }

    return { setAdditionalSteps };
});
