'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasHourInput
 * @description
 * uasHourInput component for formatted HH:mm input
 */
angular.module('uasApp').component('approvedIcon', {
    bindings: {
        approved: '@'
    },
    templateUrl: 'es6/staffing/approved.icon.html',
    controllerAs: 'approvedIconController'
});