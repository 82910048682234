'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('status-report', {
        url: '/status-report',
        controllerAs: 'statusReportStateController',
        breadcrumb: {
            label: 'Static.Page.Status.Report',
            root: true
        },
        template: `
            <status-report></status-report>
        `
    });
    
});
