'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasExpired
 * @description
 * # uasExpired
 * Expired component of the uasApp
 */
angular.module('uasApp').component('uasExpired', {
  templateUrl: 'es6/security/expired.html',
  controllerAs: 'expiredCtrl',
  controller: function(AuthService) {
    this.logout = AuthService.logout;
  }
});
