'use strict';
/**
 * @ngdoc function
 * @name uasApp.directive:canCreate
 * @description
 * # canCreate
 * Only shows element when an entity can be created.
 * Usage:
 * <button can-create="faculty">Bla</button>
 */
angular.module('uasApp').directive('canCreate', function (EntityType) {
    return {
        restrict: 'A',
        link: function ($scope, element) {
            element.attr('style', 'display:none;');
            const entityType = element.attr('can-create');
            const rootType = element.attr('root-type');

            EntityType.canCreate(entityType, rootType).then((value) => {
                if (value) {
                    element.attr('style', '');
                }
            });
        }
    };
});
