'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:colorLegendLabel
 * @description Displays an interactive label with a color-legend, a label, and an optional close button.
 */
angular.module('uasApp')
  .component('colorLegendLabel', {
    bindings: {
      code: '@',
      codeColor: '<?',
      codeBackgroundColor: '<?',
      label: '@',
      labelColor: '<?',
      labelBackgroundColor: '<?',
      isActive: '<?',
      onClick: '&?',
      onDelete: '&?'
    },
    templateUrl: 'es6/app/color/color.legend.label.html',
    controllerAs: 'colorLegendLabelController',
    controller: function() {
      const colorLegendLabelController = this;

      colorLegendLabelController.deleteClick = function($event) {
        $event.preventDefault();
        $event.stopPropagation();

        colorLegendLabelController.onDelete();
      };
    }
  });
