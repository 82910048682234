'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uasForm
 * @description
 *  Creates a form that acts as a data provider for <form-input>'s.
 *
 *  Provides the following data to <form-input>'s:
 *
 *    1) Provide the prefix for ng-model to the <form-input>
 *    2) Provide the prefix for the validator for the jarb-input directive
 *    3) Provide the name of the <form> for ng-messages and ng-model $error objects and states
 *
 *  For example:
 *
 *  <form uas-form model="userController.user" validator="User" name="userForm">
 *    <form-input id="username" label="Username"></form-input>
 *  </form>
 */
angular.module('uasApp')
  .directive('uasForm', function ($compile) {
    return {
      restrict: 'A',
      controllerAs: 'formController',
      controller: function ($attrs, $scope) {
        // Provide the prefix for ng-model to the <form-input>
        this.model = $attrs.model;

        // Provide the prefix for the validator for the jarb-input directive.
        // Validator is first evaluated because it can be an Angular expression.
        // If evaluated value is undefined or zero then the plain string value is used.
        const validator = $scope.$eval($attrs.validator) || $attrs.validator;
        this.validator = _.upperFirst(_.camelCase(validator));

        // Provide the name of the <form> for ng-messages and ng-model $error objects and states.
        this.name = $attrs.name;

        this.labelSize = $attrs.labelSize;

        // Editable
        var disabled = $scope.$eval($attrs.ngDisabled);
        this.editable = disabled !== true;
      },
      link: function link(scope, element, attrs) {
        let formError = angular.element('<form-error form="' + attrs.name + '"></form-error>');
        let firstFormGroup = element.find('.form-group')[0];

        if (firstFormGroup !== undefined) {
          $(firstFormGroup).before(formError);
        } else {
          element.prepend(formError);
        }

        $compile(formError)(scope);
      }
    };
  });
