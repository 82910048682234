'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:linkListPage
 * @description
 * The linkListPage component.
 */
angular.module('uasApp').component('linkListPage', {
  templateUrl: 'es6/link/link.list.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    workflow: '<?',
    isReadOnly: '<?'
  },
  controllerAs: 'linkListPageController',
  controller: function (Page) {
    const linkListPageController = this;

    linkListPageController.$onInit = function () {
      linkListPageController.readOnly = linkListPageController.isReadOnly || Page.getParameterAsBoolean(linkListPageController.page, 'readOnly', false);
      linkListPageController.categoryId = Page.getParameterAsInt(linkListPageController.page, 'categoryId');
      linkListPageController.workflowMode = angular.isDefined(linkListPageController.workflow);
    };
  }
});
