'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:advicePage
 * @description
 * advicePage Manages the advice.
 */
angular.module('uasApp').component('advicePage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?',
    operations: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/advice/advice.page.html',
  controllerAs: 'advicePageController',
  controller: function (Page, SecurityService) {
    const advicePageController = this;

    advicePageController.$onInit = function () {
      advicePageController.readOnly = advicePageController.isReadOnly || Page.getParameterAsBoolean(advicePageController.page, 'readOnly', false);
      advicePageController.operationsToEdit = angular.isDefined(advicePageController.workflow) ? ['EDIT_ADVICE_WORKFLOW', 'EDIT_ADVICE'] : ['EDIT_ADVICE'];
      advicePageController.editable = !advicePageController.readOnly && SecurityService.isAllowed(advicePageController.operationsToEdit, advicePageController.operations);
      advicePageController.forceNewAdvice = Page.getParameterAsBoolean(advicePageController.page, 'forceNewAdvice', false);
      advicePageController.roleId = Page.getParameterAsInt(advicePageController.page, 'role');
    };
  }
});
