'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Report
 * @description
 * Report service that generates a CSV based on selected report items.
 */
angular.module('uasApp')
    .factory('Report', function($resource, $q, BackendService, Download, Language, Promises) {
        let resource = $resource(BackendService.getBaseUrl() + '/report', {}, {
            'changes': {
                url: BackendService.getBaseUrl() + '/report/changes/:templateId',
                method: 'GET',
                isArray: false
            },
            'decorate': {
                url: BackendService.getBaseUrl() + '/report',
                method: 'POST',
                isArray: true
            },
            'meetings': {
                url: BackendService.getBaseUrl() + '/report/meetings',
                method: 'GET',
                isArray: false
            },
            'methods': {
                url: BackendService.getBaseUrl() + '/report/methods',
                method: 'GET',
                isArray: false
            },
            'moduleGroupModules': {
                url: BackendService.getBaseUrl() + '/report/module-group-modules',
                method: 'GET',
                isArray: false
            },
            'modules': {
                url: BackendService.getBaseUrl() + '/report/modules',
                method: 'GET',
                isArray: false
            },
            'organisations': {
                url: BackendService.getBaseUrl() + '/report/organisations',
                method: 'GET',
                isArray: false
            },
            'persons': {
                url: BackendService.getBaseUrl() + '/report/persons',
                method: 'GET',
                isArray: false
            },
            'schedules': {
              url: BackendService.getBaseUrl() + '/report/schedules',
              method: 'GET',
              isArray: false
            },
            'studyableModules': {
                url: BackendService.getBaseUrl() + '/report/studyable-modules',
                method: 'GET',
                isArray: false
            },
            'studyableProgramme': {
                url: BackendService.getBaseUrl() + '/report/studyable-programme',
                method: 'GET',
                isArray: false
            }
        });

        return _.extend(resource, {
            getFilterValue: function (row, header) {
                const rowValues = _.get(row, 'data', {});
                const rowValueByCode = rowValues[header.code];
                const rowValue = rowValueByCode || '';
                const processedValue = this.unwrap(rowValue).toString().toLowerCase();

                if (header.valueType === 'BOOLEAN') {
                    if (header.empty) {
                        return rowValueByCode;
                    }

                    if (header.exact) {
                        return `${rowValueByCode}`;
                    }

                    return `${processedValue === 'true'}`;
                  }
                return processedValue;
            },

            // The service returns a downloadable CSV
            csv: function(name, rows, columns) {
                name = name + '.csv' || 'report.csv';
                Download.downloadCsv(name, rows, columns, this.unwrap);
            },

            // The service returns a downloadable CSV
            excel: function(name, rows, columns) {
                name = name + '.xlsx' || 'report.xlsx';
                Download.downloadExcel(name, rows, columns, this.unwrap);
            },

            // Populates the report rows with a column when missing
            ensure: function(rows, column) {
                if (_.isEmpty(rows) || column.provided !== false) {
                    return $q.resolve();
                } else if (column.promise) {
                    return column.promise;
                }

                const requests = _(rows).filter((row) => isNotLoaded(row, column)).chunk(100).map((chunk) => {
                    return () => ensureRows(chunk, column);
                }).value();

                column.loading = true;
                const promise = column.promise = Promises.chain(requests).$promise.finally(() => {
                    delete column.promise;
                    column.loading = false;
                });

                return promise;
            },

            getPreferences: function (template, defaultValues) {
                const preferences = defaultValues || {};
                if (angular.isUndefined(template)) {
                    return _.extend({
                        headers: []
                    }, preferences);
                }

                return _.extend(preferences, {
                    order: template.sort,
                    reverse: template.direction === 'DESC',
                    headers: _(template.headers).map((header) => ({
                      code: header.name,
                      search: header.value,
                      exact: header.exact,
                      sequence: header.sequence
                    })).value()
                });
            },

            savePreferences: function (type, template) {
                sessionStorage[`report-${type}`] = _.result(template, 'name');
            },

            deletePreferences: function (type) {
                sessionStorage.removeItem(`report-${type}`);
            },

            unwrap: function (value) {
                if (_.isObject(value)) {
                    if (_.isArray(value)) {
                        return _.map(value, (v) => resource.unwrap(v));
                    } else if (angular.isDefined(value.label)) {
                        return value.label;
                    } else if (angular.isDefined(value.value)) {
                        return value.value;
                    } else if (angular.isDefined(value.visible)) {
                        return value.visible;
                    }
                }
                return value;
            }
        });

        function isNotLoaded(row, column) {
            const data = row.data[column.code];
            return angular.isUndefined(data);
        }

        function ensureRows(rows, column) {
            const entityType = rows[0].entity.type;
            const entityIds = _(rows).map((row) => {
                row.data[column.code] = 'loading';
                return row.entity.id;
            }).uniq().value();

            return resource.decorate({
                entityType: entityType,
                entityIds: entityIds,
                headerType: column.type,
                headerCode: column.code,
                language: Language.get(),
                academicYearId: sessionStorage.academicYear
            }).$promise.then((newRows) => {
                _.each(newRows, function (newRow) {
                    const filtered = _.filter(rows, {
                        entity: newRow.entity
                    });

                    _.each(filtered, function (row) {
                        delete row.data[column.code];

                        // Enhance the existing row with our new decorations
                        row.data = _.extend(row.data, newRow.data);
                    });
                });
            });
        }
    });
