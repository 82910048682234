'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:exportPage
 * @description
 * exportPage Show export as a configurable page
 */
angular.module('uasApp')
  .component('exportPage', {
    bindings: {
      entity: '<',
      operations: '<',
      page: '<',
      process: '<',
      collapsible: '<?',
      collapsed: '<?'
    },
    templateUrl: 'es6/export/export.page.html',
    controllerAs: 'exportPageController',
    controller: function(Page) {
      const exportPageController = this;

      exportPageController.$onInit = function() {
        exportPageController.legend = Page.getParameterAsArray(exportPageController.page, 'legend');
        if (angular.isUndefined(exportPageController.entity.facultyId) && _.get(exportPageController.entity, 'self.type') === 'faculty') {
          exportPageController.entity.facultyId = exportPageController.entity.id;
        }
      };
    }
});
