'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Button
 * @description
 * The base button with several options.
 */
angular.module('uasApp')
  .directive('baseButton', function () {
    return {
      restrict: 'E',
      scope: {
        btnType: '@?',
        btnSize: '@?',
        btnContext: '@?',
        btnClick: '&?',
        btnDisabled: '<?',
        btnClass: '@?',
        type: '@?',
        tooltip: '@?'
      },
      transclude: true,
      require: '?^secured',
      templateUrl: 'es6/app/forms/buttons/base.button.html',
      link: function (scope, element, attrs, securedCtrl) {
        scope.btnContext = scope.btnContext || 'default';
        scope.btnDisabled = scope.btnDisabled || false;

        if (scope.type) {
          element.find('button').attr('type', scope.type);
        }

        scope.executeBtnClick = function (event) {
          if (scope.btnClick) {
            scope.btnClick({ event: event });
          }
        };

        scope.setType = function (type) {
          return type ? 'btn-' + type : '';
        };

        // Secure button after it has been rendered
        if (securedCtrl) {
          securedCtrl.resecure(element);
        }
    }
    };
  });
