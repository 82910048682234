'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('mine', {
        url: '/mine',
        controllerAs: 'mineStateController',
        controller: function() {
            this.tabs = [{
                my: 'Static.Page.Mine.MyComments',
                title: 'Static.Page.Mine.Comments',
                view: 'comments',
                route: 'mine.comments',
                label: 'Static.Page.Mine.MyComments'
            }, {
                my: 'Static.Page.Mine.MyQuestions',
                title: 'Static.Page.Mine.Questions',
                view: 'questions',
                route: 'mine.questions',
                label: 'Static.Page.Mine.MyQuestions'
            }];
        },
        template: `
            <div class="panel panel-default">
                <uas-tabs tabs="mineStateController.tabs"></uas-tabs>
                <div class="panel-body" ui-view="mine"></div>
            </div>
        `,
        abstract: true,
        redirectTo: 'mine.comments',
        breadcrumb: {}
    });

    $stateProvider.state('mine.comments', {
        url: '/comments',
        views: {
            'mine': {
                template: '<mine-comments></mine-comments>'
            }
        },
        breadcrumb: {}
    });

    $stateProvider.state('mine.questions', {
        url: '/questions',
        views: {
            'mine': {
                template: '<mine-questions></mine-questions>'
            }
        },
        breadcrumb: {}
    });

});
