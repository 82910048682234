'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAddGroup
 * @description
 * # uasModuleAddGroup
 * Component for adding a group to a module.
 */
angular.module('uasApp').component('uasModuleAddGroup', {
    bindings: {
        module: '<',
        typeId: '<?',
        onAdd: '&',
        onCancel: '&'
    },
    templateUrl: 'es6/planboard/add/module.add.group.html',
    controllerAs: 'moduleAddGroupController',
    controller: function (ModuleGroup) {
        const moduleAddGroupController = this;
        
        moduleAddGroupController.$onInit = function() {
            moduleAddGroupController.parameters = {
                typeId: moduleAddGroupController.typeId
            };
        };

        moduleAddGroupController.search = function(parameters) {
            return ModuleGroup.search(parameters).$promise;
        };

        moduleAddGroupController.onSelect = function(groups) {
            moduleAddGroupController.groups = groups;
        };

        moduleAddGroupController.submit = function() {
            _.forEach(moduleAddGroupController.groups, add);
        };

        function add(group) {
            moduleAddGroupController.onAdd({ group });
        }

        moduleAddGroupController.cancel = function() {
            moduleAddGroupController.onCancel();
        };
    }
});