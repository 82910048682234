'use strict';

angular.module('uasApp').filter('hours', function () {

    /**
     * Formats decimal hours as {hours:minutes} (42.70 becomes 42:42, 1 becomes 1:00).
     */
    return function (input) {
        if (!_.isNumber(input) || _.isNaN(input)) {
            return '';
        }

        // Obtain decimal part of input (e.g. 45.60 becomes 0.60).
        const decimalValueInput = Math.abs(input % 1);

        // Obtain whole amount minutes from decimal value of input. A decimal value of 0.60 becomes 36.
        const minutes = _.round(decimalValueInput * 60.0, 0);

        // Obtain whole amount of hours. 45.60 becomes 45 and -1.5 becomes -1
        const hours = input >= 0 ? _.floor(input) : _.ceil(input);

        // Return input as {hours:minutes}. Ensure there are 2 places for the numbers. 6 minutes becomes :06, 0 minutes becomes :00.o
        // Note: String.prototype.padStart() is polyfilled for IE11 support.
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    };
});
