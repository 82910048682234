'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleAddExisting
 * @description
 * Component for adding an existing module to a module group.
 */
angular.module('uasApp').component('uasModuleAddExisting', {
  bindings: {
    uibModalInstance: '<',
    group: '<',
    studyId: '<?',
    excludeIds: '<',
    showGroupAlert: '<?',
    operations: '<',
    operationsToEdit: '<',
    onAdd: '&'
  },
  controllerAs: 'moduleAddController',
  templateUrl: 'es6/planboard/add/module.add.existing.html',
  controller: function (Message, Module, GroupType, CustomField, entityTranslateFilter, translateFilter) {
    const moduleAddController = this;

    moduleAddController.$onInit = function () {
      moduleAddController.modules = [];
      loadData();
    };

    function loadData() {
      moduleAddController.loading = true;
      CustomField.query({
        rootType: 'module-group',
        entityType: 'module-group-module'
      }).$promise.then((fields) => {
        moduleAddController.fields = _.keyBy(fields, 'name');

        const typeId = _.result(moduleAddController.group, 'typeId');
        return GroupType.find(typeId).$promise.then((groupType) => {
          moduleAddController.groupType = groupType;
        });
      }).finally(() => {
        moduleAddController.loading = false;
      });
    }

    moduleAddController.groupModulesWarning = function () {
      return translateFilter('ModuleGroup.NoModules', {
        modulegroup: (moduleAddController.group.code || '') + ' ' + entityTranslateFilter(moduleAddController.group)
      });
    };

    /**
     * Populates the list of modules with the user input.
     */
    moduleAddController.search = function (parameters) {
      const args = _.extend({
        excludeGroupId: moduleAddController.group.id,
        excludeTypeIds: moduleAddController.excludeIds
      }, parameters);

      return Module.page(args).$promise.then((modules) => {
        _.forEach(modules.content, (module) => {
          module.owned = isOwned(module);
        });
        return modules;
      });
    };

    function isOwned(module) {
      return angular.isUndefined(moduleAddController.studyId) || angular.isUndefined(module.studyId) || module.studyId === moduleAddController.studyId;
    }

    moduleAddController.onSelect = function (modules) {
      moduleAddController.modules = modules;
      moduleAddController.showNotOwnedAlert = _.some(moduleAddController.modules, { owned: false });
    };

    /**
     * Adds the selected modules to the group
     */
    moduleAddController.add = function () {
      _.forEach(moduleAddController.modules, add);
      moduleAddController.uibModalInstance.close();
    };

    function add(data) {
      const module = angular.copy(data);
      module.required = moduleAddController.required;
      module.alternative = moduleAddController.alternative;
      moduleAddController.onAdd({ module });

      Message.addSuccess(translateFilter('Static.Message.AddedModuleToGroup', {
        module: getEntityName(module),
        modulegroup: getEntityName(moduleAddController.group)
      }));
    }

    function getEntityName(entity) {
      return `<strong>${entityTranslateFilter(entity)}</strong>`;
    }

    /**
     * Closes the modal
     */
    moduleAddController.cancel = function () {
      moduleAddController.uibModalInstance.dismiss();
    };
  }
});
