'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingList
 * @description
 */
angular.module('uasApp').component('meetingList', {
  bindings: {
    module: '<',
    period: '<',
    flow: '<',
    operations: '<',
    operationsToEdit: '<?',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/schedule/meeting/meeting.list.html',
  controllerAs: 'meetingListController',
  controller: function ($q, $uibModal, Activity, Meeting, Relation, Offering, Flow, Parameter, SecurityService, Language, entityTranslateFilter) {
    const meetingListController = this;

    meetingListController.$onInit = function () {
        Parameter.load().then(() => {
            addFlow();
            loadData();
        });

        const operationsToEdit = meetingListController.operationsToEdit || ['EDIT_SCHEDULE'];
        meetingListController.editable = meetingListController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, meetingListController.operations);
        meetingListController.onLanguage = Language.onChange(setDisplayName);
    };
    
    function addFlow() {
        const required = !Parameter.getParameterAsBoolean('schedule.teacher.optional', false);

        meetingListController.onFlow = Flow.add(meetingListController.flow, (chain) => {
            const incomplete = _.find(meetingListController.types, { completed: false });
            if (required && angular.isDefined(incomplete)) {
                meetingListController.type = incomplete;
                return false;
            } else {
                return chain();
            }
        });
    }

    function setDisplayName() {
        _.forEach(meetingListController.types, (type) => {
            _.forEach(type.activities, (activity) => {
                activity.displayName = getDisplayName(activity);
            });
        });
    }

    function getDisplayName(activity) {
        return `(${activity.code}) ${entityTranslateFilter(activity)}`;
    }

    meetingListController.$onDestroy = function() {
        meetingListController.onFlow();
        meetingListController.onLanguage();
    };

    function loadData() {
        meetingListController.loading = true;
        $q.all([
            Activity.query({
                entityType: 'module',
                entityId: meetingListController.module.id,
                periodId: meetingListController.period.id
            }).$promise,
            Meeting.getMeetings({
                entityType: 'module',
                entityId: meetingListController.module.id,
                periodId: meetingListController.period.id
            }),
            Offering.weeks({
                entityType: 'module',
                entityId: meetingListController.module.id,
                periodId: meetingListController.period.id,
                exceedPeriod: true
            }).$promise,
            Relation.teachers({
                entityType: 'module',
                entityId: meetingListController.module.id,
                periodId: meetingListController.period.id
            }).$promise,
            Relation.vacancies({
                entityType: 'module',
                entityId: meetingListController.module.id,
                periodId: meetingListController.period.id
              }).$promise
        ]).then(([activities, meetings, weeks, teachers, vacancies]) => {
            meetingListController.teachers = teachers;
            meetingListController.vacancies = Meeting.getVacancies(meetings, vacancies);

            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            _.forEach(weeks, (week) => {
                week.label = calendarWeeks ? week.yearWeek : week.week;
            });

            const types = _(activities).map('type').uniqBy('id').sortBy('sequence').value();
            meetingListController.types = _.map(types, (type) => {
                type.activities = _(activities).filter((activity) => activity.type.id === type.id).map((activity) => {
                    const result = angular.copy(activity);

                    const extended = _(activity.plannings).map((planning) => {
                        return _(meetings).filter({ planning: planning.id }).map((meeting) => {
                            const week = _.find(weeks, { week: planning.week }) || { week: planning.week };
                            return _.extend(meeting, { 
                                completed: angular.isDefined(meeting.assignmentTypeId) || !_.isEmpty(meeting.persons) || !_.isEmpty(meeting.vacancies),
                                week
                            });
                        }).value();
                    }).flatten().value();

                    result.meetings = extended;
                    result.grouped = Meeting.reduce(extended);
                    result.completed = _.every(extended, { completed: true });
                    result.displayName = getDisplayName(result);
                    return result;
                }).value();

                type.completed = _.every(type.activities, { completed: true });
                return type;
            });

            goToFirst(types);
        }).finally(() => {
            meetingListController.loading = false;
        });
    }

    function goToFirst(types) {
        const id = _.result(meetingListController.type, 'id');
        if (angular.isDefined(id)) {
            meetingListController.type = _.find(types, { id: id });
        } else {
            meetingListController.type = _.first(types);
        }
    }
    
    meetingListController.onType = function(type) {
        meetingListController.type = type;
    };

    meetingListController.edit = function(meeting, activity) {
        if (!meetingListController.editable) {
            return;
        }

        $uibModal.open({
            template: `
                <meeting-edit 
                    meeting="meetingModalController.meeting"
                    activity="meetingModalController.activity"
                    persons="meetingModalController.persons"
                    vacancies="meetingModalController.vacancies"
                    weeks="meetingModalController.weeks"
                    on-submit="meetingModalController.save(body, groups, weeks)"
                    on-cancel="meetingModalController.close()">
                </meeting-edit>
            `,
            controllerAs: 'meetingModalController',
            controller: function($uibModalInstance) {
                const meetingModalController = this;

                meetingModalController.$onInit = function() {
                    meetingModalController.meeting = angular.copy(meeting);
                    meetingModalController.activity = angular.copy(activity);
                    meetingModalController.persons = _(meeting.persons || [])
                        .concat(meetingListController.teachers)
                        .uniqBy('id')
                        .value();
                    meetingModalController.vacancies = meetingListController.vacancies;
                };
      
                meetingModalController.weeks = angular.copy(
                    _(activity.meetings).map('week').uniqBy('week').sortBy('week').value()
                );

                meetingModalController.close = function() {
                    $uibModalInstance.dismiss();
                };

                meetingModalController.save = function(body, groups, weeks) {
                    const requests = _(activity.meetings).filter((it) => {
                        return _.some(weeks, { number: it.week.week }) && _.some(groups, { number: it.group });
                    }).map((entity) => {
                        return {
                            id: entity.id,
                            personIds: body.personIds,
                            vacancyIds: body.vacancyIds,
                            assignmentTypeId: body.assignmentTypeId
                        };
                    }).map((mapped) => Meeting.store(mapped).$promise).value();
                    
                    $q.all(requests).then(() => {
                        meetingModalController.close();
                        loadData();
                    });
                };
            }
        });
    };
  }
});
