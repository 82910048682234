'use strict';

angular.module('uasApp')
  .component('uasEditModuleGroupModule', {
      bindings: {
          entity: '<',
          module: '<',
          onCancel: '&',
          onSave: '&',
          operations: '<',
          operationsToEdit: '<'
      },
      templateUrl: 'es6/planboard/edit/edit.module.group.module.html',
      controllerAs: 'editModuleGroupModuleController',
      controller: function($q, Offering, OfferingManager, ModuleGroupModule, entityTranslateFilter, CustomField, Calendar) {
        const editModuleGroupModuleController = this;

        editModuleGroupModuleController.$onInit = function() {
          loadData();
        };
        
        function loadData() {
          $q.all([
            Offering.scheduled({
              entityType: 'module',
              entityId: editModuleGroupModuleController.module.id
            }).$promise.then((offerings) => _.map(offerings, updateRow)),
            Offering.scheduled({
              entityType: 'module',
              entityId: editModuleGroupModuleController.module.id,
              moduleGroupId: editModuleGroupModuleController.entity.moduleGroupId
            }).$promise.then((offerings) => _.map(offerings, updateRow)),
            Calendar.get({
              entityType: 'module',
              entityId: editModuleGroupModuleController.module.id,
              academicYearId: sessionStorage.academicYear
            }).$promise,
            CustomField.query({
              rootType: 'module-group',
              entityType: 'module-group-module'
            }).$promise,
            CustomField.query({
              rootType: 'module-group',
              entityType: 'offering'
            }).$promise
          ]).then(([advices, selected, calendar, fields, offeringFields]) => {
              editModuleGroupModuleController.fields = _.sortBy(fields, ['sequence', 'id']);
              editModuleGroupModuleController.offeringFields = offeringFields;
              editModuleGroupModuleController.calendar = calendar;

              const remaining = selected;
              editModuleGroupModuleController.offerings = _.map(advices, (offering) => {
                const found = _.find(selected, (selection) => isEqual(offering, selection));
                offering.id = _.result(found, 'id');
                offering.selected = angular.isDefined(found);
                offering.sequence = _.head(_(offering.periods).sortBy('sequence').map('sequence').value());

                _.remove(remaining, { id: offering.id });
                return offering;
              });

              editModuleGroupModuleController.alternativeOfferings = _.map(remaining, (offering) => {
                offering.sequence = _.head(_(offering.periods).sortBy('sequence').map('sequence').value());
                return offering;
              });
          });
        }

        function isEqual(left, right) {
          if (!_.isEmpty(left.blockIds)) {
            return _.isEqual(left.blockIds, right.blockIds);
          } else if (angular.isDefined(left.periodId)) {
            return left.periodId === right.periodId;
          } else {
            return false;
          }
        }

        function updateRow(row) {
          row.startId = _.result(row.start, 'id');
          row.periodId = _.result(row.period, 'id');
          row.blockIds = _.map(row.blocks, 'id');

          updateName(row);
          return row;
        }

        function updateName(row) {
          if (!_.isEmpty(row.blocks)) {
            row.name = _(row.blocks).sortBy('externalId').map('externalId').value().join(' + ');
          } else if (angular.isDefined(row.period)) {
            row.name = entityTranslateFilter(row.period) || row.period.code;
          } else if (angular.isDefined(row.start)) {
            row.name = row.start.code || row.start.period;
          } else {
            delete row.name;
          }
        }

        //
        // Start offerings
        //

        editModuleGroupModuleController.addOffering = function() {
          OfferingManager.create({
            offering: {},
            calendar: editModuleGroupModuleController.calendar,
            fields: editModuleGroupModuleController.offeringFields,
            operations: editModuleGroupModuleController.operations,
            onSave: function(offering) {
              const model = angular.copy(offering);
              updateRow(model);
              
              editModuleGroupModuleController.alternativeOfferings.push(model);
              editModuleGroupModuleController.editModuleGroupModuleForm.$setDirty();
            }
          });
        };

        editModuleGroupModuleController.editOffering = function (offering) {
          OfferingManager.edit({
            offering: offering,
            fields: editModuleGroupModuleController.offeringFields,
            operations: editModuleGroupModuleController.operations,
            onSave: function(model) {
              updateRow(model);
              _.assign(offering, model);

              editModuleGroupModuleController.editModuleGroupModuleForm.$setDirty();
            }
          });
        };

        editModuleGroupModuleController.removeOffering = function (offering) {
          _.remove(editModuleGroupModuleController.alternativeOfferings, offering);
          editModuleGroupModuleController.editModuleGroupModuleForm.$setDirty();
        };

        //
        // End offerings
        //

        editModuleGroupModuleController.save = function(mgm) {
          const content = angular.copy(mgm);
          if (mgm.alternative) {
            content.offerings = getOfferings();
          } else {
            content.offerings = [];
          }
          content.comment = {
            message: editModuleGroupModuleController.message
          };

          ModuleGroupModule.bulkUpdate(content).$promise.then(() => {
            editModuleGroupModuleController.cancel();
            editModuleGroupModuleController.onSave();
          });
        };

        function getOfferings() {
          var offerings = _.filter(editModuleGroupModuleController.offerings, { selected: true });
          offerings = offerings.concat(editModuleGroupModuleController.alternativeOfferings);
          return _.map(offerings, stripOffering);
        }

        function stripOffering(offering) {
          offering.blockIds = _.map(offering.blocks, 'id');
          return offering;
        }

        editModuleGroupModuleController.cancel = function() {
            editModuleGroupModuleController.onCancel();
        };
      }
    });
