'use strict';

angular.module('uasApp').component('pageSelect', {
  bindings: {
    selectId: '@',
    route: '@?',
    rootType: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/page/page.select.html',
  controllerAs: 'pageSelectController',
  controller: function ($q, i18nFilter, Page) {
    const pageSelectController = this;

    pageSelectController.$onInit = function () {
      pageSelectController.ngModelCtrl.$render = () => {
        setPageId();
      };

      loadPages();
    };

    function setPageId() {
      const value = pageSelectController.ngModelCtrl.$modelValue;
      if (angular.isDefined(value)) {
        pageSelectController.pageId = _.isString(value) ? parseInt(value) : value;
      } else {
        delete pageSelectController.pageId;
      }
    }

    function loadPages() {
      if (angular.isDefined(pageSelectController.pages)) {
        return $q.resolve(pageSelectController.pages);
      }

      pageSelectController.loading = true;
      return Page.query({
        rootType: pageSelectController.rootType,
        route: pageSelectController.route
      }).$promise.then((pages) => {
        pageSelectController.pages = _(pages).map((page) => {
          page.label = i18nFilter(page.titles) || page.name;
          return page;
        }).sortBy('label').value();
      }).finally(() => {
        pageSelectController.loading = false;
      });
    }

    pageSelectController.onChange = function () {
      pageSelectController.ngModelCtrl.$setViewValue(pageSelectController.pageId);
    };
  }
});
