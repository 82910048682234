'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('organisation', {
        url: '/organisation/{id:[0-9]{1,8}}',
        templateUrl: 'es6/organisation/organisation.html',
        controller: 'OrganisationCtrl',
        abstract: true,
        entityType: 'organisation',
        breadcrumb: {},
        resolve: {
            organisation: function(Organisation, $stateParams) {
                const instance = this;
                return Organisation.get({
                    id: $stateParams.id
                }).$promise.then(function(organisation) {
                    instance.self.data = organisation;
                    return organisation;
                });
            },
            operations: function (AuthService, organisation) {
                return AuthService.operations('organisation', organisation.id);
            }
        }
    });

    $stateProvider.state('organisation.general', {
        url: '/general',
        views: {
            'organisation': {
                template: `
                    <uas-organisation-general 
                        organisation="organisation">
                    </uas-organisation-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Organisation.General',
            label: 'Static.Help.Organisation.General'
        }
    });

    $stateProvider.state('organisation.relations', {
        url: '/relations',
        views: {
            'organisation': {
                template: `
                    <relations 
                        data-entity-type="organisation"
                        data-entity-id="organisationId"
                        data-operations="operations">
                    </relations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Organisation.Relations',
            label: 'Static.Help.Organisation.Relations'
        }
    });

    $stateProvider.state('organisation.assignments', {
        url: '/assignments',
        views: {
            'organisation': {
                template: `
                    <assignment-list 
                        organisation="organisation" 
                        operations="operations">
                    </assignment-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ASSIGNMENTS'],
            title: 'Static.Tab.Organisation.Assignments',
            label: 'Static.Help.Organisation.Assignments'
        }
    });

    $stateProvider.state('organisation.availability', {
        url: '/availability',
        views: {
            'organisation': {
                template: `
                    <availability-report 
                        entity="organisation" 
                        operations="operations">
                    </availability-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_AVAILABILITY'],
            title: 'Static.Tab.Organisation.Availability',
            label: 'Static.Help.Organisation.Availability'
        }
    });

    $stateProvider.state('organisation.vacancies', {
        url: '/vacancies',
        views: {
            'organisation': {
                template: `
                    <vacancies 
                        organisation="organisation" 
                        operations="operations">
                    </vacancies>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ASSIGNMENTS'],
            title: 'Static.Tab.Organisation.Vacancies',
            label: 'Static.Help.Organisation.Vacancies'
        }
    });

    $stateProvider.state('organisation.modules', {
        url: '/modules',
        views: {
            'organisation': {
                template: `
                    <modules-report 
                        entity="organisation"
                        operations="operations">
                    </modules-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Organisation.Modules',
            label: 'Static.Help.Organisation.Modules'
        }
    });

    $stateProvider.state('organisation.methods', {
        url: '/methods',
        views: {
            'organisation': {
                template: `
                    <method-report 
                        entity="organisation"
                        operations="operations">
                    </method-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_METHODS'],
            title: 'Static.Tab.Organisation.Methods',
            label: 'Static.Help.Organisation.Methods'
        }
    });

    $stateProvider.state('organisation.resources', {
        url: '/costs',
        views: {
            'organisation': {
                template: `
                    <uas-cost
                        read-only="true" 
                        entity="organisation"
                        operations="operations">
                    </uas-cost>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST'],
            title: 'Static.Tab.Organisation.Resources',
            label: 'Static.Help.Organisation.Resources'
        }
    });

    $stateProvider.state('organisation.hours', {
        url: '/hours',
        views: {
            'organisation': {
                resolve: {
                    organisationId: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                controllerAs: 'organisationHoursController',
                controller: function (organisationId, Worklog, AuthService) {
                    this.organisationId = organisationId;
                    this.report = Worklog.report({ organisationId }).$promise;
                    this.operations = AuthService.operations('organisation', organisationId);
                },
                template: `
                    <uas-hour-report
                        report="organisationHoursController.report"
                        operations="organisationHoursController.operations"
                        entity-id="{{ organisationHoursController.organisationId }}">
                    </uas-hour-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_HOURS_PERSON_REPORT'],
            title: 'Static.Tab.Organisation.Hours',
            label: 'Static.Help.Organisation.Hours'
        }
    });

    $stateProvider.state('organisation.cost-divisions', {
        url: '/cost-divisions',
        views: {
            'organisation': {
                template: `
                    <uas-cost-division-report
                        entity="costDivisionsController.organisation"
                        operations="costDivisionsController.operations">
                    </uas-cost-division-report>
                `,
                controllerAs: 'costDivisionsController',
                controller: function (organisation, operations) {
                    this.organisation = organisation;
                    this.operations = operations;
                }
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST_DIVISION'],
            title: 'Static.Tab.Organisation.CostDivisions',
            label: 'Static.Help.Organisation.CostDivisions'
        }
    });

    $stateProvider.state('organisation.tasks', {
        url: '/tasks',
        views: {
            'organisation': {
                template: `
                    <tasks-report 
                        entity="organisation" 
                        operations="operations">
                    </tasks-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_TASKS'],
            title: 'Static.Tab.Organisation.Tasks',
            label: 'Static.Help.Organisation.Tasks'
        }
    });

    $stateProvider.state('organisation.admin', {
        url: '/admin',
        views: {
            'organisation': {
                template: `
                    <uas-organisation-admin 
                        organisation="organisation"
                        operations="operations">
                    </uas-organisation-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Organisation.Admin',
            label: 'Static.Help.Organisation.Admin'
        }
    });

    $stateProvider.state('organisation.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'organisation': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="pageId" 
                        entity="organisation" 
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });

});
