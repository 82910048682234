'use strict';

angular.module('uasApp').config(function($stateProvider) {
    $stateProvider
        .state('statistics', {
            url: '/statistics',
            template: '<uas-statistics></uas-statistics>',
            breadcrumb: {
                label: 'Static.Page.Statistics',
                root: true
            }
        });
});
