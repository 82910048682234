'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:checkbox
 * @description Shows a themed and accessible checkbox (or radio option) with a label.
 *
 * @param {string=} label The label displayed next to the checkbox, truncated if too long.
 * @param {string} boldedText If defined, the part of the label to be bolded.
 * @param {boolean} asRadio If defined, the checkbox will be rendered as a radio option.
 */
angular.module('uasApp').component('checkbox', {
  bindings: {
    label: '@',
    boldedText: '@?',
    asRadio: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/forms/checkbox.html',
  controllerAs: 'checkboxController',
  controller: function () {
    const checkboxController = this;

    checkboxController.$onInit = function () {
      checkboxController.ngModelCtrl.$render = function () {
        checkboxController.value = checkboxController.ngModelCtrl.$modelValue;
      };
    };

    checkboxController.onToggle = function () {
      checkboxController.ngModelCtrl.$setViewValue(checkboxController.value);
    };
  }
});
