'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodSchemaCreate
 * @description
 * methodSchemaCreate Creates a method schema.
 */
angular.module('uasApp').component('methodSchemaCreate', {
    bindings: {
        schema: '<',
        category: '<',
        entity: '<',
        module: '<',
        parent: '<?',
        fields: '<',
        includeTypes: '<?',
        operations: '<',
        operationsToEdit: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/methods/schema/method.schema.create.html',
    controllerAs: 'methodSchemaCreateController',
    controller: function (MethodSchema) {
        const methodSchemaCreateController = this;

        methodSchemaCreateController.$onInit = function () {
            methodSchemaCreateController.mode = 'METHOD';
            methodSchemaCreateController.method = emptyMethod();
            methodSchemaCreateController.showSchema = _.some(methodSchemaCreateController.fields, 'visible');
        };

        function emptyMethod() {
            return {
                moduleId: methodSchemaCreateController.module.id,
                academicYearId: sessionStorage.academicYear,
                values: {}
            };
        }

        methodSchemaCreateController.onMethod = function () {
            const methodId = _.get(methodSchemaCreateController.method, 'id');
            methodSchemaCreateController.schema.methodId = methodId;

            if (_.isEmpty(methodSchemaCreateController.method)) {
                methodSchemaCreateController.method = emptyMethod();
            }
        };

        methodSchemaCreateController.save = function (content, comment) {
            const methodId = _.get(methodSchemaCreateController.method, 'id');
            if (angular.isUndefined(methodId)) {
                MethodSchema.combined({
                    schema: content,
                    method: _.extend(methodSchemaCreateController.method, {
                        moduleId: methodSchemaCreateController.module.id,
                        academicYearId: sessionStorage.academicYear
                    }),
                    comment
                }).$promise.then((result) => {
                    methodSchemaCreateController.onSave({ content, result });
                });
            } else {
                MethodSchema.save({
                    content,
                    comment
                }).$promise.then((result) => {
                    methodSchemaCreateController.onSave({ content, result });
                });
            }
        };

        methodSchemaCreateController.close = function () {
            methodSchemaCreateController.onClose();
        };
    }
});