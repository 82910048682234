'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:schedulePreference
 * @description
 * # schedulePreference
 * Defines the schedule preference of a person
 */
angular.module('uasApp').component('schedulePreference', {
  templateUrl: 'es6/schedule/preference/schedule.preference.html',
  controllerAs: 'schedulePreferenceController',
  bindings: {
    personId: '<',
    periodId: '<',
    operations: '<?',
    operationsToEdit: '@?',
    isReadOnly: '@?',
    checkUnsavedChanges: '<?', // Indicates if popup should be displayed if there are unsaved changes
    saveCallbacks: '<?',
    forms: '<?',
    displayInfo: '<?'
  },
  controller: function($q, AcademicYear, Period, SchedulePreference, Message, Element, AuthService, SecurityService, Language, Weekday, PREFERENCE_TYPES) {

    const schedulePreferenceController = this;

    schedulePreferenceController.$onInit = function() {
      schedulePreferenceController.operationsToEdit_ = AuthService.buildOperationsToEdit(schedulePreferenceController.operationsToEdit, 'EDIT_SCHEDULE_PREFERENCE');
      schedulePreferenceController.allowed = schedulePreferenceController.isReadOnly !== 'true' && SecurityService.isAllowed(schedulePreferenceController.operationsToEdit_, schedulePreferenceController.operations);

      schedulePreferenceController.types = PREFERENCE_TYPES;
      schedulePreferenceController.fteRequired = true;

      loadData();
    };

    function loadData() {
      schedulePreferenceController.periods = [];
      schedulePreferenceController.days = [];

      schedulePreferenceController.loading = true;
      $q.all([
        getPeriods(),
        Weekday.getVisible()
      ]).then(([periods, weekDays]) => {
        schedulePreferenceController.days = _.map(weekDays, 'day');
        schedulePreferenceController.periods = _(periods)
          .filter({ planning: true })
          .sortBy(['start', 'end'])
          .value();

        if (angular.isDefined(schedulePreferenceController.periodId)) {
          schedulePreferenceController.show(schedulePreferenceController.periodId);
        } else if (schedulePreferenceController.periods.length > 0) {
          schedulePreferenceController.show(schedulePreferenceController.periods[0].id);
        }
      }).finally(() => {
        schedulePreferenceController.loading = false;
      });
    }

    function getPeriods() {
      if (schedulePreferenceController.allowed) {
        return Period.query({
          academicYearId: sessionStorage.academicYear
        }).$promise;
      }

      return SchedulePreference.periods({
        personId: schedulePreferenceController.personId,
        academicYearId: sessionStorage.academicYear
      }).$promise;
    }

    schedulePreferenceController.show = function (periodId) {
      schedulePreferenceController.loading = true;

      return SchedulePreference.get({
        personId: schedulePreferenceController.personId,
        periodId: periodId
      }).$promise.then((preference) => {
        return $q.all([
          AcademicYear.get({
            id: preference.period.academicYearId
          }).$promise,
          Element.getByType('TIME_BLOCK', preference.period.academicYearId)
        ]).then(([year, blocks]) => {
          schedulePreferenceController.year = year;
          schedulePreferenceController.blocks = blocks;
          schedulePreferenceController.preference = build(preference);

          setDisplayName();
          Language.onChange(setDisplayName);
        });
      }).finally(() => {
        schedulePreferenceController.loading = false;
      });
    };

    function setDisplayName() {
      _.forEach(schedulePreferenceController.blocks, (block) => {
        block.displayName = block[Language.getPropertyName()];
      });
    }

    function build(data) {
      let preference = angular.copy(data);
      preference.states = getStates(data.days || []);
      return preference;
    }

    function getStates(days) {
      const states = {};
      _.each(schedulePreferenceController.days, function(day) {
          _.each(schedulePreferenceController.blocks, function(block) {
              const attrs = {
                  day,
                  timeBlockId: block.id
              };
              let current = _.find(days, attrs);
              if (!current) {
                  current = _.extend(attrs, {
                      type: 'AVAILABLE'
                  });
                  days.push(current);
              }
              states[day + '-' + block.id] = current;
          });
      });
      return states;
    }

    schedulePreferenceController.setInitial = function(form, preference) {
      if (angular.isUndefined(preference.id) && schedulePreferenceController.allowed === true) {
        form.$setDirty();
      }
      
      if (schedulePreferenceController.saveCallbacks && schedulePreferenceController.allowed === true) {
        schedulePreferenceController.saveCallbacks.push(() => {
          if (form.$dirty) {
            return schedulePreferenceController.save(preference, form);
          }
          return $q.resolve();
        });
      }
      if (schedulePreferenceController.forms) {
        schedulePreferenceController.forms.push(form);
      }
    };

    schedulePreferenceController.setDirty = function(form, preference, day, blockId) {
      schedulePreferenceController.preference.states[day + '-' + blockId].type = preference;
      form.$setDirty();
    };

    schedulePreferenceController.save = function(preference, form) {
        const body = _.omit(preference, 'states');
        body.period = _.result(body, 'period.id');
        body.days = _.values(preference.states);

        return SchedulePreference.store(body).$promise.then((result) => {
          schedulePreferenceController.preference = build(result);
          Message.onSaved();

          if (angular.isDefined(form)) {
            form.$setPristine();
          }
        });
    };
  }
});
