'use strict';


angular.module('uasApp').filter('isEmpty', function() {
    /**
     * Checks if the given value is empty or not.
     * {} [] "" will all give true.
     * undefined and null will return false.
     * @param {Number | Object | String} value The value to check if it is empty or not.
     * @return {Boolean} true iff value is empty, false otherwise.
     */
    return function(value) {
        if (value === undefined || value === null) {
            return false;
        }

        switch (typeof value) {
            case 'string':
                return value.length === 0;
            case 'object':
                return Object.keys(value).length === 0;
            default:
                return true;
        }
    };
});
