'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:moduleStudiesPage
 * @description
 * The moduleStudiesPage component.
 */
angular.module('uasApp').component('moduleStudiesPage', {
  templateUrl: 'es6/module/module.studies.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'moduleStudiesPageController',
  controller: function (Page) {
    const moduleStudiesPageController = this;

    moduleStudiesPageController.$onInit = function () {
      moduleStudiesPageController.readOnly = moduleStudiesPageController.isReadOnly || Page.getParameterAsBoolean(moduleStudiesPageController.page, 'readOnly', false);
    };
  }
});
