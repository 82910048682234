'use strict';

angular.module('uasApp').component('vacancyTypeahead', {
  templateUrl: 'es6/staffing/vacancy/vacancy.typeahead.html',
  bindings: {
    inputId: '@?',
    isDisabled: '<?',
    isRequired: '<?',
    inModal: '<?',
    minLength: '<?',
    organisationId: '<',
    autoSelect: '<?',
    classes: '<?',
    viewClasses: '<?',
    onSearch: '&'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'vacancyTypeaheadController',
  controller: function (Assignment, Changes, Pageable) {
    const vacancyTypeaheadController = this;

    vacancyTypeaheadController.$onInit = function () {
      setPageable();

      vacancyTypeaheadController.ngModelCtrl.$render = () => {
        vacancyTypeaheadController.vacancyId = vacancyTypeaheadController.ngModelCtrl.$modelValue;
      };
    };

    vacancyTypeaheadController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'organisationId') && vacancyTypeaheadController.organisationId) {
        setPageable();
        vacancyTypeaheadController.search(vacancyTypeaheadController.pageable);
      }
    };

    function setPageable() {
      vacancyTypeaheadController.pageable = Pageable.of({
        order: 'name',
        pageSize: 250
      });
    }

    vacancyTypeaheadController.search = function (pageable) {
      const term = _.get(pageable, 'text', '');

      const params = vacancyTypeaheadController.pageable.build({
        active: true,
        organisationId: vacancyTypeaheadController.organisationId,
        term: term,
        vacancy: true
      });

      return Assignment.search(params).$promise.then((vacancies) => {
        vacancyTypeaheadController.vacancies = _.get(vacancies, 'content', []);

        vacancyTypeaheadController.onSearch({
          term: term,
          vacancies
        });

        autoSelect();

        return vacancies;
      });
    };

    function autoSelect() {
      if (vacancyTypeaheadController.autoSelect && _.size(vacancyTypeaheadController.vacancies) === 1) {
        vacancyTypeaheadController.vacancyId = _.head(vacancyTypeaheadController.vacancies).id;
        vacancyTypeaheadController.onChange();
      }
    }

    vacancyTypeaheadController.find = function (id) {
      return Assignment.find(id).$promise;
    };

    vacancyTypeaheadController.formatValue = function (vacancy) {
      return vacancy ? (vacancy.name ? vacancy.name : vacancy.externalId) : '';
    };

    vacancyTypeaheadController.onChange = function () {
      vacancyTypeaheadController.ngModelCtrl.$setViewValue(vacancyTypeaheadController.vacancyId);
    };
  }
});
