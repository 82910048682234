'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCustomTypeGeneral
 * @description
 * uasCustomTypeGeneral Manages the custom type general information
 */
angular.module('uasApp')
    .component('uasCustomTypeGeneral', {
        bindings: {
            customType: '<',
            onSave: '&'
        },
        templateUrl: 'es6/custom/types/custom.type.general.html',
        controllerAs: 'customTypeGeneralController',
        controller: function (EntityService, EntityType, ItemType, Message, ProcessType, Workflow, Page, i18nFilter) {
            const customTypeGeneralController = this;

            customTypeGeneralController.$onInit = function () {
                const rootType = EntityService.getRootType(customTypeGeneralController.customType.rootType);

                ProcessType.query().$promise.then((processTypes) => {
                    customTypeGeneralController.processTypes = processTypes;
                });

                Workflow.query().$promise.then((workflows) => {
                    customTypeGeneralController.workflows = _(workflows)
                        .filter((workflow) => _.kebabCase(workflow.type) === customTypeGeneralController.customType.rootType)
                        .map((workflow) => {
                            workflow.displayName = i18nFilter(workflow.titles);
                            return workflow;
                        })
                        .sortBy('displayName')
                        .value();
                });

                Page.query().$promise.then((pages) => {
                    customTypeGeneralController.pages = _(pages)
                        .filter((page) => !page.rootType || rootType === page.rootType)
                        .map((page) => {
                            page.name = i18nFilter(page.titles) || page.name;
                            return page;
                        }).sortBy('name').value();

                    customTypeGeneralController.forms = _.filter(customTypeGeneralController.pages, {
                        route: 'form'
                    });
                });

                // Supply the same values to have the tooltip show possible placeholders.
                customTypeGeneralController.changesLabelPlaceHolder = {
                    createdBy: '{{createdBy}}',
                    changeType: '{{changeType}}',
                    changeTypeLowerCase: '{{changeTypeLowerCase}}',
                    changedEntity: '{{changedEntity}}',
                    rootEntity: '{{rootEntity}}'
                };
            };

            customTypeGeneralController.save = function () {
                const body = angular.copy(customTypeGeneralController.customType);
                const resource = _.isEmpty(customTypeGeneralController.customType.entityType) ? ItemType : EntityType;

                return resource.store(body).$promise.then((result) => {
                    _.extend(customTypeGeneralController.customType, result);
                    customTypeGeneralController.customTypeForm.$setPristine();
                    Message.addSuccessLabel('Static.Message.DataAdjusted');
                    if (angular.isDefined(customTypeGeneralController.onSave)) {
                        customTypeGeneralController.onSave({ result });
                    }
                });
            };
        }
    });
