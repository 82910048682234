'use strict';

angular.module('uasApp').component('createModal', {
    templateUrl: 'es6/app/create/create.modal.html',
    transclude: true,
    bindings: {
        rootType: '<',
        entity: '<',
        operations: '<',
        onCreate: '&',
        onCancel: '&'
    },
    controllerAs: 'createModalController',
    controller: function() {
        const createModalController = this;

        createModalController.cancel = function() {
            createModalController.onCancel();
        };
    }
});