'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:studiesReport
 * @description
 * Report that shows all studies.
 */
angular.module('uasApp').component('studiesReport', {
  bindings: {
    entity: '<?',
    clicked: '&?',
    refresh: '<?',
    isReadOnly: '<?',
    reportTemplate: '@?',
    hideReportTemplates: '<?',
    operations: '<?'
  },
  templateUrl: 'es6/study/report/studies.report.html',
  controllerAs: 'studiesReportController',
  controller: function ($q, $state, CreateManager, Changes, EntityService, Language, ReportLinks, StudiesReport, StudyTabs) {
    const studiesReportController = this;

    studiesReportController.$onInit = function () {
      studiesReportController.readOnly = studiesReportController.isReadOnly === true;

      studiesReportController.name = 'studies';
      studiesReportController.context = 'studies';

      Language.onChange(loadData);

      if (_.isObject(studiesReportController.refresh)) {
        studiesReportController.refresh.loadData = () => { loadTypesAndRefreshReport(); };
      }

      loadData().then(() => {
        studiesReportController.isInitalised = true;
      });
    };

    studiesReportController.$onChanges = function (changes) {
      if (studiesReportController.isInitalised && Changes.hasChanged(changes, 'entity')) {
        loadTypesAndRefreshReport();
      }
    };

    function loadData() {
      studiesReportController.loading = true;

      return $q.all([
        loadDefinitions(),
        loadTypes()
      ]).finally(() => {
        studiesReportController.loading = false;
      });
    }

    function loadDefinitions() {
      return StudiesReport.studies({
        definition: true
      }).$promise.then((report) => {
        studiesReportController.columns = report.headers;
        studiesReportController.groups = report.groups;
      });
    }

    function loadTypes() {
      return StudiesReport.types(getParameters()).$promise.then((types) => {
        studiesReportController.types = _.sortBy(types, 'sequence');
      });
    }

    function refreshReport() {
      studiesReportController.reload = {
        reset: true
      };
    }

    function loadTypesAndRefreshReport() {
      // When switching faculties or updating study types through workflows, the types need to be updated
      loadTypes().then(() => {
        refreshReport();
      });
    }

    studiesReportController.getRows = function(pageable) {
      const parameters = getParameters(pageable);
      return StudiesReport.studies(parameters).$promise;
    };

    function getParameters(pageable) {
      const params = {
        academicYearId: sessionStorage.academicYear,
        typeId: studiesReportController.typeId,
        language: Language.get()
      };

      if (studiesReportController.entity) {
        const entityPath = EntityService.getEntityPath(studiesReportController.entity);
        params[entityPath] = studiesReportController.entity.id;
      }

      return _.extend(params, pageable);
    }

    studiesReportController.create = function() {
      const study = EntityService.extend({
        academicYearId: sessionStorage.academicYear,
        typeId: studiesReportController.typeId
      }, studiesReportController.entity);

      CreateManager.open('study', study, studiesReportController.operations, () => {
        $state.reload();
      });
    };

    studiesReportController.onType = function(type) {
      studiesReportController.typeId = _.get(type, 'id');

      refreshReport();
    };

    studiesReportController.decorator = function(row) {
      let decoration = {};

      const totalCost = parseToNumber(row.data.totalCost);
      const budget = parseToNumber(row.data.budget);
      if (totalCost > budget) {
        decoration.remainder = decoration.totalCost = {
          text: 'text text-danger',
          tooltip: 'Static.Message.BudgetExceeded',
          icon: 'exclamation-triangle'
        };
      }

      return decoration;
    };

    function parseToNumber(value) {
      if (_.isNumber(value)) {
        return value;
      } else if (!value) {
        return 0;
      }

      const raw = value.replace(/,/g, '');
      return parseFloat(raw);
    }

    const generalState = 'study.general';
    const states = {
      general: generalState,
      commission: 'discussion', // Is the route of a configurable page
      additional: 'study.additional',
      credits: 'study.credits',
      capacity: 'study.capacity',
      description: 'study.description',
      diploma: 'study.general',
      relation: 'study.relations',
      costs: 'study.cost'
    };

    studiesReportController.onClick = function (row, col) {
      if (!studiesReportController.readOnly) {
        onClick(row, col);
      }
    };

    function onClick(row, col) {
      if (angular.isDefined(studiesReportController.clicked)) {
        studiesReportController.clicked({ row, col });
      } else {
        const state = states[col.group];
        if (!_.isEmpty(state)) {
          ReportLinks.navigateTo(state, generalState, 'study', 'study', row.entity.id, StudyTabs.get());
        }
      }
    }
  }
});
