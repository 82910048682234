'use strict';

angular.module('uasApp').component('relationsReportPage', {
    templateUrl: 'es6/staffing/relation/report/relations.report.page.html',
    bindings: {
        page: '<',
        entity: '<?',
        operations: '<?',
        isReadOnly: '<?'
    },
    controllerAs: 'relationsReportPageController',
    controller: function (Page) {
        const relationsReportPageController = this;

        relationsReportPageController.$onInit = function() {
            relationsReportPageController.target = Page.getParameterAsBoolean(relationsReportPageController.page, 'target', false);
            relationsReportPageController.readOnly = relationsReportPageController.isReadOnly || Page.getParameterAsBoolean(relationsReportPageController.page, 'readOnly', false);
            relationsReportPageController.modal = Page.getParameterAsBoolean(relationsReportPageController.page, 'modal', false);
            relationsReportPageController.vacancy = Page.getParameter(relationsReportPageController.page, 'vacancy');
        };
    }
});
