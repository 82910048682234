'use strict';

angular.module('uasApp').component('iconPicker', {
  bindings: {
    inputId: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/forms/icon.picker.html',
  controllerAs: 'iconPickerController',
  controller: function () {
    const iconPickerController = this;

    iconPickerController.$onInit = function () {
        iconPickerController.ngModelCtrl.$render = function () {
            iconPickerController.value = iconPickerController.ngModelCtrl.$modelValue;
        };
    };

    iconPickerController.onChange = function () {
        iconPickerController.ngModelCtrl.$setViewValue(iconPickerController.value);
    };
  }
});
