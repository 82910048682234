'use strict';

angular.module('uasApp').component('academicYearEdit', {
    templateUrl: 'es6/admin/year/year.edit.html',
    bindings: {
        year: '<'
    },
    controllerAs: 'academicYearEditController',
    controller: function (AcademicYear, Message, State) {
        const academicYearEditController = this;

        academicYearEditController.save = function(year) {
            academicYearEditController.loading = true;
            AcademicYear.store(year).$promise.then(() => {
                Message.onSaved();
                State.reload();
            }).finally(() => {
                academicYearEditController.loading = false;
            });
        };
    }
});