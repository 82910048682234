'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasJobs
 * @description
 * # uasJobs
 * Manages jobs.
 */
angular.module('uasApp').component('uasJobs', {
  templateUrl: 'es6/admin/jobs/jobs.html',
  controllerAs: 'jobsController',
  controller: function ($timeout, $uibModal, Pageable, JobExecution, JobModal) {

    const jobsController = this;

    jobsController.$onInit = function () {
      jobsController.progress = false;
      jobsController.pageable = Pageable.of({
        order: 'startTime',
        reverse: true
      });

      jobsController.parameters = {};
      jobsController.loadJobs();
    };

    function refreshWhileRunning(jobExecution, index) {
      if (jobExecution.running) {
        JobExecution.get({
          id: jobExecution.id
        }).$promise.then(function (updatedJobExecution) {
          jobsController.jobs.content.splice(index, 1, updatedJobExecution);
          $timeout(function () {
            refreshWhileRunning(updatedJobExecution, index);
          }, 1000);
        });
      } else {
        jobsController.progress = _.some(jobsController.jobs.content, { running: true });
      }
    }

    jobsController.cancel = function (jobExecutionId, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      JobExecution.delete({
        id: jobExecutionId
      }).$promise.then(function (updatedJobExecution) {
        var index = _.findIndex(jobsController.jobs, {
          id: jobExecutionId
        });
        jobsController.jobs.content.splice(index, 1, updatedJobExecution);
      });
    };

    jobsController.loadJobs = function () {
      const params = jobsController.pageable.build(jobsController.parameters);

      jobsController.loading = true;
      JobExecution.get(params).$promise.then((jobs) => {
        jobsController.jobs = jobs;
        _.each(jobsController.jobs.content, refreshWhileRunning);
      }).finally(() => {
        jobsController.loading = false;
      });
    };

    jobsController.open = function (jobExecution) {
      JobModal.open(jobExecution);
    };
  }

});
