'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Reference
 * @description The Reference service.
 */
angular.module('uasApp').factory('Reference', function (i18nFilter, Dates, UasResource) {
  const resource = UasResource('/references').withCache('references');

  resource.getActive = function (typeId, filter) {
    return resource.query({
      typeId,
      filter,
      academicYearId: sessionStorage.academicYear
    }).$promise;
  };

  resource.getByType = function (typeId) {
    return resource.query({ typeId }).$promise.then((references) =>
      Dates.setActive(references)
    );
  };

  resource.getName = function (reference) {
    return i18nFilter(reference.names) || reference.code || reference.externalId || reference.id;
  };

  return resource;
});
