'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamGeneral
 * @description teamGeneral Shows the team details.
 */
angular.module('uasApp').component('teamGeneral', {
  templateUrl: 'es6/team/team.general.html',
  bindings: {
    team: '<'
  },
  controllerAs: 'teamGeneralController',
  controller: function (Member) {
    const teamGeneralController = this;

    teamGeneralController.$onInit = function () {
      loadData();
    };

    function loadData() {
      return Member.query({
        teamId: teamGeneralController.team.id
      }).$promise.then((members) => {
        teamGeneralController.members = _.sortBy(members, ['person.fullName', 'id']);
      });
    }
  }
});
