'use strict';

angular.module('uasApp').component('methodSearch', {
  bindings: {
    inputId: '@',
    isRequired: '<',
    isReadOnly: '<?',
    multipleValues: '<?',
    defaultOpen: '<?',
    viewClasses: '<?',
    classes: '@?',
    category: '<?',
    includeTypes: '<?',
    parent: '<?',
    filter: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel',
    securedCtrl: '?^secured'
  },
  templateUrl: 'es6/methods/method.search.html',
  controllerAs: 'methodSearchController',
  controller: function (Method, Pageable, $element) {
    const methodSearchController = this;

    methodSearchController.$onInit = function() {
        methodSearchController.text = '';
        methodSearchController.pageable = Pageable.of({
            order: 'code'
        });

        methodSearchController.open = methodSearchController.defaultOpen === true;
        if (methodSearchController.open) {
            methodSearchController.search();
        }

        methodSearchController.ngModelCtrl.$render = function () {
            const values = methodSearchController.ngModelCtrl.$modelValue;
            methodSearchController.methodIds = _.isArray(values) ? values : [values];
            if (!methodSearchController.multipleValues) {
                methodSearchController.methodId = _.head(methodSearchController.methodIds);
            }
        };

        secure();
    };
  
    function secure() {
        if (methodSearchController.securedCtrl) {
            methodSearchController.securedCtrl.resecure($element);
        }
    }

    methodSearchController.search = function() {
        const params = methodSearchController.pageable.build({
            methodSchemaId: _.result(methodSearchController.parent, 'id'),
            category: _.result(methodSearchController.category, 'id'),
            types: methodSearchController.includeTypes,
            text: methodSearchController.text,
            filter: methodSearchController.filter
        });

        methodSearchController.loading = true;
        return Method.search(params).$promise.then((methods) => {
            methodSearchController.methods = methods;

            _.forEach(methods.content, (method) => {
                method.selected = _.intersection(methodSearchController.methodIds, [method.id]).length > 0;
            });
        }).finally(() => {
            methodSearchController.loading = false;
        });
    };

    methodSearchController.onSelect = function(selected) {
        const methodId = _.result(selected, 'id');

        if (methodSearchController.multipleValues) {
            const methodIds = _(methodSearchController.methodIds).pull(methodId).concat(methodId).value();
            methodSearchController.methodIds = methodIds;
            methodSearchController.ngModelCtrl.$setViewValue(methodIds);
        } else {
            methodSearchController.methodId = methodId;
            methodSearchController.methodIds = [methodId];
            methodSearchController.ngModelCtrl.$setViewValue(methodId);
        }

        methodSearchController.open = false;
    };

    methodSearchController.edit = function() {
        methodSearchController.open = true;
        methodSearchController.search();
    };

    methodSearchController.reset = function() {
        delete methodSearchController.methodId;
        methodSearchController.ngModelCtrl.$setViewValue();
        methodSearchController.open = false;
    };
  }
});
