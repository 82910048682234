'use strict';

angular.module('uasApp')
    .component('uasStatistics', {
        templateUrl: 'es6/report/statistics/statistics.html',
        controllerAs: 'statisticsController',
        controller: function ($timeout, configDateFilter, Dates, FacultyStatistics, LoggedIn) {
            const statisticsController = this;

            statisticsController.$onInit = function () {
                statisticsController.appliedFilters = [];
                statisticsController.order = 'faculty.code';
                statisticsController.academicYearId = sessionStorage.academicYear;
                statisticsController.changes = {
                    academicYearId: statisticsController.academicYearId
                };

                // Hide the points on top of the chart. These don't look nice when there are lots of days with data.
                statisticsController.chartOptions = {
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 10,
                            hoverRadius: 5
                        }
                    }
                };

                LoggedIn.daily().$promise.then((rows) => {
                    statisticsController.userStatistics = formatData(rows);

                    // Make a copy of the statistics to restore when "clear filter" is clicked.
                    // This prevents extra calls to the backend.
                    statisticsController.originalUserStatistics = angular.copy(statisticsController.userStatistics);
                });

                FacultyStatistics.query({
                  academicYearId: statisticsController.academicYearId
                }).$promise.then(function(faculties) {
                  statisticsController.faculties = faculties;
                  statisticsController.total = {
                    studyCount: _.sumBy(faculties, 'studyCount'),
                    moduleGroupCount: _.sumBy(faculties, 'moduleGroupCount'),
                    moduleCount: _.sumBy(faculties, 'moduleCount')
                  };
                });
            };

            /**
             * Filters the statistics to only include values from a specific year.
             * @param year Year to get the user statistics for.
             */
            statisticsController.filterOnYear = function (year) {
                addFilter('Static.Page.Statistics.Users.Filter.Year', year, (row) => getYear(row.loginDate) === year);
            };

            /**
             * Filters the statistics to only include values from a specific quarter.
             * @param selectedQuarter Quarter to get the user statistics for.
             */
            statisticsController.filterOnQuarter = function (selectedQuarter) {
                addFilter('Static.Page.Statistics.Users.Filter.Quarter', selectedQuarter.displayValue, (row) => getQuarter(row.loginDate) === selectedQuarter.internalValue.quarter && getYear(row.loginDate) === selectedQuarter.internalValue.year);
            };

            /**
             * Filters the statistics to only include values from a specific month
             * @param selectedMonth Month to get the user statistics for.
             */
            statisticsController.filterOnMonth = function (selectedMonth) {
                addFilter('Static.Page.Statistics.Users.Filter.Month', selectedMonth.displayValue, (row) => getMonth(row.loginDate) === selectedMonth.internalValue.month && getYear(row.loginDate) === selectedMonth.internalValue.year);
            };

            /**
             * Toggles the statistics whether to include or not to include days without any users in the application.
             * @param event Click event to set the checkbox as selected (read below).
             */
            statisticsController.toggleDaysWithoutUsers = function (event) {
                // Prevent the dropdown menu from closing when clicking on this row.
                event.preventDefault();
                event.stopPropagation();

                statisticsController.hideDaysWithoutUsers = !statisticsController.hideDaysWithoutUsers;

                const filterLabel = 'Static.Page.Statistics.Users.Filter.HideDaysWithoutUsers';
                toggleHideDaysWithoutUsersCheckbox(statisticsController.hideDaysWithoutUsers);

                if (!statisticsController.hideDaysWithoutUsers) {
                    const existingFilterIndex = _.findIndex(statisticsController.appliedFilters, (filter) => filter.label === filterLabel);

                    if (existingFilterIndex > -1) {
                        statisticsController.removeFilter(statisticsController.appliedFilters[existingFilterIndex]);
                    }
                } else {
                    addFilter(filterLabel, null, (row) => row.userCount > 0, toggleHideDaysWithoutUsersCheckbox);
                }
            };

            /**
             * Check the checkbox if the user clicked on the checkbox itself.
             * As it is nested in an <a> tag, it needs to be manually checked on a separate thread.
             * See https://codepen.io/arjan1995/pen/wymoOy (forked from https://codepen.io/bseth99/pen/fboKH).
             * @param checked whether or not the checkbox must be checked. Defaults to false when omitted.
             */
            function toggleHideDaysWithoutUsersCheckbox(checked) {
                checked = checked || false;
                statisticsController.hideDaysWithoutUsers = checked;
                $timeout(() => angular.element('#hideDaysWithoutUsers').prop('checked', checked), 0);
            }

            /**
             * Adds a filter to the list of filters to filter the statistics on, and updates the graph.
             * @param label User visible name of the filter
             * @param value Filtered value to show to the user (e.g. month number)
             * @param predicate Function to which the user statistics must comply in order to be shown in the chart.
             * @param onRemove Optional function to execute when the filter is removed using the delete button in the badge.
             */
            function addFilter(label, value, predicate, onRemove) {
                statisticsController.isFilterSet = true;

                const existingFilterIndex = _.findIndex(statisticsController.appliedFilters, (filter) => filter.label === label && filter.value === filter.value);

                // If the filter is not already selected, add it. Otherwise, don't add it again.
                if (existingFilterIndex === -1) {
                    const filter = {
                        label: label,
                        value: value,
                        predicate: predicate,
                        onRemove: onRemove
                    };

                    statisticsController.appliedFilters.push(filter);
                }

                statisticsController.userStatistics = applyFilters(statisticsController.appliedFilters);
            }

            /**
             * Removes a filter from the list of filters on which the statistics are filtered
             * @param filter Filter to remove from the list. If it contains a onRemove function, that function will be executed.
             */
            statisticsController.removeFilter = function (filter) {
                const index = _.indexOf(statisticsController.appliedFilters, filter);

                // If the filter exists, remove it.
                if (index >= 0) {
                    statisticsController.appliedFilters.splice(index, 1);
                }

                statisticsController.userStatistics = applyFilters(statisticsController.appliedFilters);

                // Execute the onRemove function if it exists.
                if (_.isFunction(filter.onRemove)) {
                    filter.onRemove();
                }
            };


            /**
             * Removes all filters from the statistics.
             * @returns {*} returns the un-filtered user statistics
             */
            statisticsController.clearAllFilters = function () {
                statisticsController.userStatistics = angular.copy(statisticsController.originalUserStatistics);
                statisticsController.appliedFilters = [];
                statisticsController.isFilterSet = false;
                statisticsController.hideDaysWithoutUsers = false;
                return statisticsController.userStatistics;
            };

            /**
             * Applies all passed filters to a fresh copy of the user statistics
             * @param filters Filters to apply
             * @returns {*} Filtered user statistics
             */
            function applyFilters(filters) {
                const predicates = _.map(filters, 'predicate');

                if (_.isEmpty(predicates)) {
                    return statisticsController.clearAllFilters();
                }

                const filteredData = _.filter(statisticsController.originalUserStatistics.raw, (row) => {
                    return _.every(predicates, (predicate) => predicate(row) === true);
                });

                return formatData(filteredData);
            }

            /**
             * Adds labels and chart data to the user statistics.
             * @param rows User statistics to format
             * @return {object} Object containing all labels, login dates, available years, available quarters and available months.
             */
            function formatData(rows) {
                const users = _(rows).map(function (row) {
                    return {
                        label: configDateFilter(row.loginDate),
                        date: row.loginDate,
                        value: row.userCount
                    };
                }).sortBy('date').value();

                const dates = _(users)
                    .map('date')
                    .map((date) => new Date(date))
                    .value();

                return {
                    labels: _.map(users, 'label'),
                    data: [_.map(users, 'value')],
                    raw: angular.copy(rows),
                    years: getYears(dates),
                    quarters: getQuarters(dates),
                    months: getMonths(dates)
                };
            }

            /**
             * Returns all the years available in the given list of dates.
             * @param dates {array} Dates to find the years of.
             * @return {array} List of found years.
             */
            function getYears(dates) {
                return _(dates).map((date) => date.getFullYear())
                    .uniq()
                    .sortBy()
                    .reverse()
                    .value();
            }

            /**
             * returns the year of a single date
             * @param date {string | Date} String or date to get the year of
             * @return {number} Year of the date.
             */
            function getYear(date) {
                return Dates.asDate(date).getFullYear();
            }

            /**
             * Returns all the quarters available in the given list of dates.
             * @param dates {array} Dates to find the quarters of.
             * @return {array} List of found quarters. Contains internal value (separate year and quarter) and display value (as string)
             */
            function getQuarters(dates) {
                return _(dates).map((date) => {
                    const year = getYear(date);
                    const quarter = getQuarter(date);

                    return {
                        internalValue: { year: year, quarter: quarter },
                        displayValue: `${year}-${quarter}`
                    };
                })
                    .uniqBy('displayValue')
                    .sortBy('displayValue')
                    .reverse()
                    .value();
            }

            /**
             * returns the quarter of a single date
             * @param date {string | Date} String or date to get the quarter of
             * @return {number} Quarter of the date.
             */
            function getQuarter(date) {
                const quarters = [1, 2, 3, 4];
                return quarters[Math.floor(Dates.asDate(date).getMonth() / 3)];
            }

            /**
             * Returns all the months available in the given list of dates.
             * @param dates {array} Dates to find the months of.
             * @return {array} List of found months. Contains internal value (separate year and month) and display value (as string)
             */
            function getMonths(dates) {
                return _(dates).map((date) => {
                    const year = getYear(date);
                    const month = getMonth(date);

                    return {
                        internalValue: { year: year, month: month },
                        displayValue: `${year}-${pad(month, 2)}`
                    };
                })
                    .uniqBy('displayValue')
                    .sortBy('displayValue')
                    .reverse()
                    .value();
            }

            /**
             * Returns human-readable month number of a date.
             * @param date {string | Date} Date to get the month number of
             * @return {int} month number
             */
            function getMonth(date) {
                return Dates.asDate(date).getMonth() + 1;
            }

            /**
             * Adds leading zeroes to numbers
             * @param num {int} number
             * @param size {int} amount of digits the number must be (including num)
             * @return {string} padded number
             */
            function pad(num, size) {
                let s = num + '';
                while (s.length < size) {
                    s = '0' + s;
                }
                return s;
            }
        }

    });
