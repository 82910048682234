'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:inlineTag
 * @description Displays an inline tag with transcluded content and a remove button.
 */
angular.module('uasApp').component('inlineTag', {
  bindings: {
    isReadOnly: '<?',
    isSmall: '<?',
    isPulsing: '<?',
    isSquared: '<?',
    onRemove: '&',
    viewClasses: '@?',
    textColor: '<?',
    color: '<?',
    tooltipLabel: '@?'
  },
  transclude: true,
  controllerAs: 'inlineTagController',
  template: `
    <span
      class="inline-tag align-items-center gap-4"
      ng-class="[{
        'squared': inlineTagController.isSquared,
        'small': inlineTagController.isSmall,
        'animate-pulsing': inlineTagController.isPulsing
        }, inlineTagController.viewClasses]"
      ng-style="{
        'color': inlineTagController.textColor,
        'background-color': inlineTagController.color,
      }"
      uib-tooltip="{{ inlineTagController.tooltipLabel }}"
      tooltip-enable="inlineTagController.tooltipLabel.length > 0"
      tooltip-append-to-body="true"
      aria-label="{{ inlineTagController.tooltipLabel }}">
      <span ng-transclude></span>
      <i
        ng-if="!inlineTagController.isReadOnly"
        ng-click="inlineTagController.onRemove()"
        class="inline-tag__remove fa fa-times">
      </i>
    </span>
    `
});
