'use strict';

angular.module('uasApp')
    .component('uasPlanboardModuleStudy', {
        bindings: {
            module: '<'
        },
        templateUrl: 'es6/planboard/move/planboard.module.study.html',
        controllerAs: 'planboardModuleStudyController',
        controller: function (Module) {
            const planboardModuleStudyController = this;

            planboardModuleStudyController.$onInit = function () {
                // Module id can be undefined if a new added unsaved module is dragged
                if (angular.isDefined(planboardModuleStudyController.module.moduleId)) {

                    Module.studies({
                        id: planboardModuleStudyController.module.moduleId
                    }).$promise.then((studies) => {
                        planboardModuleStudyController.studies = studies;
                        planboardModuleStudyController.multipleUsages = planboardModuleStudyController.studies.length > 1
                            || (planboardModuleStudyController.studies.length === 1 && planboardModuleStudyController.studies[0].routes.length > 1);
                    });
                }
            };
        }
    });

