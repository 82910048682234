'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:weekCheck
 * @description
 */
angular.module('uasApp').component('weekCheck', {
  bindings: {
    week: '<',
    weeks: '<',
    period: '<',
    module: '<',
    editable: '<',
    onChange: '&'
  },
  templateUrl: 'es6/schedule/week/week.check.html',
  controllerAs: 'weekCheckController',
  controller: function($uibModal, Message) {

    const weekCheckController = this;

    weekCheckController.edit = function($event) {
        $event.stopPropagation();
        $event.preventDefault();

        if (weekCheckController.editable !== true) {
            return;
        }

        $uibModal.open({
            templateUrl: 'es6/schedule/week/week.check.edit.modal.html',
            controllerAs: 'editWeekController',
            controller: function($uibModalInstance, ModuleWeek) {
                const editWeekController = this;

                editWeekController.$onInit = function () {
                    editWeekController.weeks = _(weekCheckController.weeks).map((week) => {
                        return {
                            active: week.keepSequence,
                            week: week.week,
                            scheduleWeek: week.scheduleWeek,
                            periodWeek: week.periodWeek,
                            yearWeek: week.yearWeek,
                            typeId: week.typeId
                        };
                    }).sortBy('week').value();
                };

                editWeekController.save = function() {
                    const contents = getDirtyWeeks();
                    ModuleWeek.save({ contents }).$promise.then((result) => {
                        weekCheckController.onChange({
                            weeks: result.entities
                        });
                        Message.addSuccessLabel('Static.Message.DataSaved');
                        $uibModalInstance.close();
                    });
                };

                function getDirtyWeeks() {
                    return _(editWeekController.weeks)
                      .filter({ dirty: true })
                      .map((week) => {
                          const currentWeek = _.find(weekCheckController.weeks, { week: week.week });
                          currentWeek.keepSequence = week.active;

                          return {
                              id: currentWeek.id,
                              moduleId: weekCheckController.module.id,
                              periodId: weekCheckController.period.id,
                              week: currentWeek.week,
                              keepSequence: week.active
                          };
                      }).value();
                }

                editWeekController.onWeeks = function(weeks) {
                    _.each(weeks, (week) => {
                        week.dirty = true;
                    });
                };
            }
        });
    };

  }
});
