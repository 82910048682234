'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusReport
 * @description
 * Shows the full status history of an entity
 */
angular.module('uasApp').component('statusReport', {
    bindings: {
        entity: '<?'
    },
    templateUrl: 'es6/status/report/status.report.html',
    controllerAs: 'statusReportController',
    controller: function ($timeout, $q, AcademicYear, ProcessType, Status, Language, i18nFilter, typeFilter) {
        const statusReportController = this;

        statusReportController.$onInit = function () {
            statusReportController.name = 'statuses';
            statusReportController.context = 'statuses';

            statusReportController.parameters = {
                academicYearId: parseInt(sessionStorage.academicYear)
            };

            statusReportController.onLanguage = Language.onChange(loadData);
            loadData();
        };

        statusReportController.$onDestroy = function() {
            statusReportController.onLanguage();
        };

        function loadData() {
            statusReportController.loading = true;
            $q.all([
                AcademicYear.query().$promise,
                ProcessType.query().$promise
            ]).then(([academicYears, processes]) => {
                statusReportController.academicYears = _.filter(academicYears, { enabled: true, simulation: false });
                statusReportController.processTypes = _(processes).map(setDisplayName).value();
            }).finally(() => {
                statusReportController.loading = false;
            });
        }

        statusReportController.loadReport = function () {
            statusReportController.loading = true;
            Status.report({
                definition: true
            }).$promise.then((report) => {
                statusReportController.columns = report.headers;
                statusReportController.groups = report.groups;
            }).finally(() => {
                statusReportController.loading = false;
            });
        };

        statusReportController.getRows = function(pageable) {
            const params = _.extend({
                academicYearId: statusReportController.parameters.academicYearId,
                processTypeId: statusReportController.parameters.processTypeId,
                fromDate: statusReportController.parameters.fromDate,
                toDate: statusReportController.parameters.toDate
            }, pageable);

            return Status.report(params).$promise;
        };

        function setDisplayName(processType) {
            const label = i18nFilter(processType.labels);
            const typeLabel = typeFilter(processType.rootType);
            processType.displayName = `${label} (${typeLabel})`;
            return processType;
        }
    }
});
