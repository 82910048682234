'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:ReportLinks
 * @description
 * # ReportLinks
 * Handles report navigation
 */
angular.module('uasApp').factory('ReportLinks', function($state, $window, AuthService, entityRedirect, Tabs) {

  /**
   * Navigates to the detail page, if a tab for it exists and is enabled.
   * @param requestedState Requested detail state. Can be a direct route (classic page) or the route of a configurable page
   * @param fallbackState Fallback state. If detail state is not visible (e.g. tab hidden or not configured), this will be used instead
   * @param basePath Base path to request available tabs (e.g. group for module-group entities)
   * @param entityType Entity type
   * @param entityId Entity id
   * @param tabs Default tabs which are used in case no tabs are user-defined
   */
  function navigateTo(requestedState, fallbackState, basePath, entityType, entityId, tabs) {
    // We can always go to fallback state. Skip the checks below to reduce requests and speed things up.
    if (requestedState === fallbackState) {
      entityRedirect.go(requestedState, { id: entityId }, { target: '_blank' });
      return;
    }

    AuthService.operations(entityType, entityId).then((operations) => {
      Tabs.build(tabs, operations, basePath, entityType, entityId).then((builtTabs) => {
        const foundTab = builtTabs.find((tab) => tab.visible && ((tab.page && tab.page.route === requestedState) || tab.route === requestedState));

        // If the tab for the requested state is not enabled, go to the general tab.
        if (!foundTab) {
          entityRedirect.go(fallbackState, { id: entityId }, { target: '_blank' });
          return;
        }

        // Use href since a configurable page cannot be requested using entityRedirect.
        const params = angular.copy(foundTab.params) || {};
        if (!params.id) {
          params.id = entityId;
        }
        const url = $state.href(foundTab.route, params);
        $window.open(url, '_blank');
      });
    });
  }

  return {
    navigateTo: navigateTo
  };
});
