'use strict';

angular.module('uasApp').component('methodSelector', {
  bindings: {
    category: '<?',
    parent: '<?',
    includeTypes: '<?',
    operations: '<?',
    operationsToEdit: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/methods/method.selector.html',
  controllerAs: 'methodSelectorController',
  controller: function (Method) {
    const methodSelectorController = this;

    methodSelectorController.$onInit = function () {
      methodSelectorController.ngModelCtrl.$render = function () {
        methodSelectorController.method = methodSelectorController.ngModelCtrl.$viewValue;
        methodSelectorController.methodId = _.get(methodSelectorController.method, 'id');

        if (_.isEmpty(methodSelectorController.mode)) {
          setDefaultMode();
        }
      };
    };

    function setDefaultMode() {
      methodSelectorController.mode = angular.isDefined(methodSelectorController.methodId) || _.isEmpty(methodSelectorController.method) ? 'search' : 'create';
    }

    methodSelectorController.setMode = function (mode) {
      methodSelectorController.mode = mode;
      reset();
    };

    function reset() {
      delete methodSelectorController.method;
      delete methodSelectorController.methodId;
      setViewValue();
    }

    methodSelectorController.onMethod = function () {
      setMethod().then((method) => {
        methodSelectorController.method = angular.copy(method);
      });
    };

    function setMethod() {
      return Method.find(methodSelectorController.methodId).$promise.then((method) => {
        setViewValue(method);
        return method;
      });
    }

    methodSelectorController.onCopy = function () {
      Method.find(methodSelectorController.methodId).$promise.then((method) => {
        methodSelectorController.method = _.omit(angular.copy(method), ['id', 'externalId', 'code', 'self']);
        methodSelectorController.mode = 'create';

        setViewValue(methodSelectorController.method);
      });
    };

    methodSelectorController.onField = function () {
      setViewValue(methodSelectorController.method);
    };

    function setViewValue(method) {
      const model = angular.copy(method);
      methodSelectorController.ngModelCtrl.$setViewValue(model);
    }
  }
});
