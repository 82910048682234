'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:LinkCategory
 * @description
 * The LinkCategory service.
 */
angular.module('uasApp').factory('LinkCategory', function (UasResource) {
  return UasResource('/link-categories');
});
