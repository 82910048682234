'use strict';

/**
 * @ngdoc component
 * @name uasApp.directive:documentPage
 * @description
 * The documentPage component.
 */
angular.module('uasApp').component('documentPage', {
  templateUrl: 'es6/document/document.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    isReadOnly: '<?',
    workflow: '<?'
  },
  controllerAs: 'documentPageController',
  controller: function (Page) {
    const documentPageController = this;

    documentPageController.$onInit = function () {
      documentPageController.readOnly = documentPageController.isReadOnly || Page.getParameterAsBoolean(documentPageController.page, 'readOnly', false);
      documentPageController.operationsToEdit = angular.isDefined(documentPageController.workflow) ? 'EDIT_DOCUMENT_WORKFLOW' : 'EDIT_DOCUMENT';
      documentPageController.type = Page.getParameter(documentPageController.page, 'type');
      documentPageController.typeIds = Page.getParameterAsArray(documentPageController.page, 'documentTypes', '', _.parseInt);
      documentPageController.nameFormula = Page.getParameter(documentPageController.page, 'nameFormula');
      documentPageController.columns = Page.getParameterAsArray(documentPageController.page, 'columns');
    };
  }
});
