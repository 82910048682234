'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:entityLink
 * @description
 * Displays translated entities as links or text, optionally with an icon and/or code.
 * The type is derived from `entity(.self).type` via the `EntityService`.
 */
angular.module('uasApp').component('entityLink', {
  bindings: {
    entity: '<?',
    target: '@?',
    viewClasses: '@?',
    hasIcon: '<?',
    navigate: '<?'
  },
  templateUrl: 'es6/app/entity/entity.link.html',
  controllerAs: 'entityLinkController',
  controller: function (EntityService) {
    const entityLinkController = this;

    entityLinkController.$onChanges = function () {
      entityLinkController.showIcon = entityLinkController.hasIcon === true;
      entityLinkController.entityType = EntityService.getType(entityLinkController.entity);

      if (entityLinkController.navigate !== false) {
        entityLinkController.href = EntityService.getHref(entityLinkController.entity);
      }
    };
  }
});
