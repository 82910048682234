'use strict';

angular.module('uasApp').component('studyLinksRule', {
  bindings: {
    entity: '<',
    studyId: '<',
    types: '<',
    expanded: '<?',
    showRule: '<?',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/link/study.links.rule.html',
  controllerAs: 'studyLinksRuleController',
  controller: function ($q, Language, Link, Rule) {
    const studyLinksRuleController = this;

    studyLinksRuleController.$onInit = function () {
      loadData();

      studyLinksRuleController.onLanguage = Language.onChange(prepare);
    };

    function prepare() {
      studyLinksRuleController.links = Link.prepare(studyLinksRuleController.links, studyLinksRuleController.types, studyLinksRuleController.studyId);
    }

    studyLinksRuleController.$onChanges = function (changes) {
      if (changes.expanded && studyLinksRuleController.links) {
        setOpen();
      }
    };

    function setOpen() {
      _.forEach(studyLinksRuleController.links, (link) => {
        link.open = studyLinksRuleController.expanded;
      });
    }

    function loadData() {
      $q.all([
        Link.query({
          entityType: studyLinksRuleController.entity.type,
          entityId: studyLinksRuleController.entity.id
        }).$promise,
        Rule.get({
          id: studyLinksRuleController.entity.id
        }).$promise
      ]).then(([links, type]) => {
        studyLinksRuleController.links = links;
        studyLinksRuleController.type = type;

        prepare();
        setOpen();
      });
    }
  }
});
