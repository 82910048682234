'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assessmentsReport
 * @description
 * Shows assessments in a report.
 */
angular.module('uasApp').component('assessmentsReport', {
    bindings: {
      entity: '<?',
      isReadOnly: '<?',
      operations: '<?',
      workflowMode: '<?',
      refresh: '<?',
      reportTemplate: '@?',
      hideReportTemplates: '<?',
      showCreate: '<?',
      showModal: '<?'
    },
    templateUrl: 'es6/assessment/report/assessments.report.html',
    controllerAs: 'assessmentsReportController',
    controller: function(Assessment, Changes, EntityService, Language, Message, SecurityService, WorkflowModal) {
        const assessmentsReportController = this;

        assessmentsReportController.$onInit = function() {
            assessmentsReportController.readOnly = assessmentsReportController.isReadOnly === true;

            assessmentsReportController.operationsToEdit = assessmentsReportController.workflowMode === true ? 'EDIT_ASSESSMENTS_WORKFLOW' : 'EDIT_ASSESSMENTS';
            const editable = !assessmentsReportController.readOnly && SecurityService.isAllowed(assessmentsReportController.operationsToEdit, assessmentsReportController.operations);

            if (editable && assessmentsReportController.showModal) {
                assessmentsReportController.onClick = open;
            }

            assessmentsReportController.name = 'assessments';
            assessmentsReportController.context = 'assessments';

            Language.onChange(loadData);

            if (_.isObject(assessmentsReportController.refresh)) {
                assessmentsReportController.refresh.loadData = () => refreshReport();
            }

            loadData().then(() => {
              assessmentsReportController.isInitalised = true;
            });
        };

        assessmentsReportController.$onChanges = function (changes) {
            if (assessmentsReportController.isInitalised && Changes.hasChanged(changes, 'entity')) {
                applyEntity();
                refreshReport();
            }
        };

        function loadData() {
            assessmentsReportController.loading = true;

            applyEntity();

            return loadDefinitions().finally(() => {
                assessmentsReportController.loading = false;
            });
        }

        function loadDefinitions() {
            return Assessment.report({
                definition: true
            }).$promise.then((report) => {
                assessmentsReportController.columns = report.headers;
                assessmentsReportController.groups = report.groups;
            });
        }

        function refreshReport() {
            assessmentsReportController.reload = {
                reset: true
            };
        }

        function applyEntity() {
            assessmentsReportController.entityType = EntityService.getType(assessmentsReportController.entity);

            if (angular.isDefined(assessmentsReportController.entity)) {
                assessmentsReportController.name = `${assessmentsReportController.entity.code}-assessments`;
                assessmentsReportController.context = `${assessmentsReportController.entityType}.assessments`;
                assessmentsReportController.values = EntityService.extend({
                    facultyId: assessmentsReportController.entity.facultyId
                }, assessmentsReportController.entity);
            }
        }

        assessmentsReportController.onChange = function() {
            refreshReport();
        };

        assessmentsReportController.getRows = function(pageable) {
            const parameters = getParameters(pageable);
            return Assessment.report(parameters).$promise;
        };

        function getParameters(pageable) {
            const parameters = EntityService.extend({
                academicYearId: sessionStorage.academicYear,
                active: assessmentsReportController.active,
                language: Language.get()
            }, assessmentsReportController.entity);

            return _.extend(pageable, parameters);
        }

        function open(row) {
            Assessment.get({
                id: row.entity.id
            }).$promise.then((assessment) => {
                WorkflowModal.open({
                    entity: assessment,
                    onCancel: refreshReport,
                    onSave: function(content, comment) {
                        return Assessment.save({ comment, content }).$promise.then(() => {
                            Message.onSaved();
                            return refreshReport();
                        });
                    },
                    scope: {
                        operations: assessmentsReportController.operations,
                        operationsToEdit: assessmentsReportController.operationsToEdit
                    },
                    template: `
                        <assessment-modal
                            assessment="workflowModalStateController.entity"
                            operations="workflowModalStateController.operations"
                            operations-to-edit="workflowModalStateController.operationsToEdit"
                            on-cancel="workflowModalStateController.onCancel()"
                            on-submit="workflowModalStateController.onSave(assessment, comment)">
                        </assessment-modal>
                    `
                });
            });
        }
    }
});
