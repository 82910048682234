'use strict';

angular.module('uasApp').directive('minDate', (uasDateService) => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {
      ctrl.$validators.minDate = (modelValue) => {
        
        const minDate = uasDateService.convert(scope.$eval(attrs.minDate));
        return !minDate || !modelValue || modelValue >= minDate;
      };

      scope.$watch(() => {
        return scope.$eval(attrs.minDate);
      }, () => {
        ctrl.$validate();
      });
    }
  };
});
