'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:costDivisionPage
 * @description
 * costDivisionPage Manages the module cost division.
 */
angular.module('uasApp')
  .component('costDivisionPage', {
    bindings: {
      module: '<',
      operations: '<',
      page: '<',
      workflow: '<?',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/cost/division/cost.division.page.html',
    controllerAs: 'costDivisionPageController',
    controller: function (Page) {
      const costDivisionPageController = this;

      costDivisionPageController.$onInit = function () {
        costDivisionPageController.readOnly = costDivisionPageController.isReadOnly || Page.getParameterAsBoolean(costDivisionPageController.page, 'readOnly', false);
        costDivisionPageController.showInactive = Page.getParameterAsBoolean(costDivisionPageController.page, 'showInactive', false);
      };
    }
  });
