'use strict';

angular.module('uasApp').component('planboardRemovedText', {
  bindings: {
    moduleChangeType: '<',
    moduleTerminated: '<',
    offeringChangeType: '<'
  },
  templateUrl: 'es6/planboard/planboard.removed.text.html',
  controllerAs: 'removedTextController',
  controller: function (translateFilter) {
    const removedTextController = this;

    removedTextController.$onChanges = function () {
      removedTextController.removedText = getRemovedText(
        removedTextController.moduleChangeType,
        removedTextController.moduleTerminated,
        removedTextController.offeringChangeType
      );
    };

    function getRemovedText(moduleChangeType, moduleTerminated, offeringChangeType) {
      if (moduleTerminated === true) {
        return translateFilter('Static.Module.Terminated');
      }
      if (moduleChangeType === 'REMOVE' && moduleTerminated === false) {
        return translateFilter('Static.Module.Removed');
      }
      if (angular.isUndefined(moduleChangeType) && offeringChangeType === 'REMOVE') {
        return translateFilter('Static.Module.RemovedOffering');
      }
    }
  }
});
