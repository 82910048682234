'use strict';

angular.module('uasApp').component('taskMoveList', {
    bindings: {
        assignment: '<',
        operations: '<',
        workflowMode: '<',
        isReadOnly: '<',
        onChange: '&'
    },
    templateUrl: 'es6/staffing/task/task.move.list.html',
    transclude: true,
    controllerAs: 'taskMoveListController',
    controller: function($uibModal, Task, TaskModal, Changes, AuthService, SecurityService) {
        const taskMoveListController = this;

        taskMoveListController.$onInit = function() {
            const operationsToEdit = taskMoveListController.workflowMode === true ? 'EDIT_TASKS_WORKFLOW' : 'EDIT_TASKS';
            taskMoveListController.editable = taskMoveListController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, taskMoveListController.operations);
        };

        taskMoveListController.$onChanges = function (changes) {
            if (Changes.hasChanged(changes, 'assignment')) {
                loadData();
            }
        };

        function loadData() {
            taskMoveListController.loading = true;
            return Task.query({
                assignmentId: taskMoveListController.assignment.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then((tasks) => {
                taskMoveListController.tasks = _(tasks).map((task) => {
                    const entityType = _.result(task, 'template.role.entityType');
                    task.editable = entityType !== 'ASSIGNMENT';
                    return task;
                }).orderBy(['editable', 'template.code', 'correctedHours', 'id'], ['desc', 'asc', 'desc', 'asc']).value();
            }).finally(() => {
                taskMoveListController.loading = false;
            });
        }

        taskMoveListController.onSelect = function() {
            const editable = _.filter(taskMoveListController.tasks, { editable: true });
            taskMoveListController.selected = _.filter(editable, { selected: true });
            taskMoveListController.selectAll = taskMoveListController.selected.length === editable.length;
        };

        taskMoveListController.onSelectAll = function() {        
            _.forEach(taskMoveListController.tasks, (task) => {
                if (task.editable) {
                    task.selected = taskMoveListController.selectAll;
                }
            });

            taskMoveListController.onSelect();
        };

        taskMoveListController.move = function() {
            $uibModal.open({
                template: `
                    <task-move 
                        tasks="modaController.tasks" 
                        organisation-id="modaController.organisationId"
                        on-change="modaController.onChange()"
                        on-cancel="modaController.close()">
                    </task-move>
                `,
                controllerAs: 'modaController',
                controller: function($uibModalInstance) {
                    const modaController = this;
                    modaController.tasks = taskMoveListController.selected;
                    modaController.organisationId = taskMoveListController.assignment.organisation.id;

                    modaController.onChange = function() {
                        modaController.close();
                        taskMoveListController.onChange();
                        loadData();
                    };

                    modaController.close = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };
    }
});