'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingAssign
 * @description Displays a list of meetings to which you can assign teachers.
 */
angular.module('uasApp').component('meetingAssign', {
  bindings: {
    module: '<',
    period: '<',
    operations: '<',
    operationsToEdit: '<?'
  },
  templateUrl: 'es6/schedule/meeting/meeting.assign.html',
  controllerAs: 'meetingAssignController',
  controller: function ($q, Activity, AvailabilityModal, Assignment, Language, Meeting, Message, Relation, Pageable, Offering, Parameter,
    SecurityService, configDateFilter, entityTranslateFilter) {

    const meetingAssignController = this;

    meetingAssignController.$onInit = function () {
      meetingAssignController.editable = SecurityService.isAllowed(meetingAssignController.operationsToEdit, meetingAssignController.operations);
      meetingAssignController.pageable = Pageable.of({
        order: 'week.startDate'
      });

      meetingAssignController.headers = [
        { property: 'week.period', label: 'Static.Tab.Schedule.Teachers.Period' },
        { property: 'week.label', label: 'Static.Tab.Schedule.Teachers.Week' },
        { property: 'group', label: 'Static.Tab.Schedule.Teachers.Group' },
        { property: 'week.startDate', label: 'Static.Tab.Schedule.Teachers.Date' },
        { property: 'activity.displayType', label: 'Static.Tab.Schedule.Teachers.ActivityType' },
        { property: 'activity.code', label: 'Static.Tab.Schedule.Teachers.ActivityCode' },
        { property: 'activity.duration', search: false, label: 'Static.Tab.Schedule.Teachers.Duration' }
      ];

      loadData();
      Language.onChange(updateRows);
    };

    function loadData() {
      meetingAssignController.loading = true;
      $q.all([
        Activity.query({
          entityType: 'module',
          entityId: meetingAssignController.module.id,
          periodId: meetingAssignController.period.id
        }).$promise,
        Meeting.getMeetings({
          entityType: 'module',
          entityId: meetingAssignController.module.id,
          periodId: meetingAssignController.period.id
        }),
        Offering.weeks({
          entityType: 'module',
          entityId: meetingAssignController.module.id,
          periodId: meetingAssignController.period.id,
          exceedPeriod: true
        }).$promise,
        Relation.teachers({
          entityType: 'module',
          entityId: meetingAssignController.module.id,
          periodId: meetingAssignController.period.id
        }).$promise,
        Relation.vacancies({
          entityType: 'module',
          entityId: meetingAssignController.module.id,
          periodId: meetingAssignController.period.id
        }).$promise,
        loadTeacherHours(),
        Parameter.load()
      ]).then(([activities, meetings, weeks, teachers, vacancies]) => {
        meetingAssignController.vacancies = Meeting.getVacancies(meetings, vacancies);
        meetingAssignController.persons = _(meetings)
          .flatMap('persons')
          .concat(teachers)
          .filter(angular.isDefined)
          .uniqBy('id')
          .value();

        const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
        meetingAssignController.meetings = _(activities)
          .map((activity) => {
            const extended = _(activity.plannings).map((planning) => {
              return _(meetings).filter({ planning: planning.id }).map((meeting) => {
                const week = _.find(weeks, { week: planning.week }) || { week: planning.week };
                week.label = calendarWeeks ? week.yearWeek : week.week;

                return _.extend(meeting, {
                  week,
                  activity
                });
              }).value();
            }).flatten().value();

            activity.meetings = extended;
            return activity;
          })
          .map((activity) => activity.meetings)
          .flatten()
          .sortBy(['week.period', 'week.week', 'activity.type.id', 'activity.code'])
          .value();

        updateRows();

        meetingAssignController.duration = _.sumBy(meetingAssignController.meetings, 'activity.duration');
        meetingAssignController.filtered = meetingAssignController.meetings;
      }).finally(() => {
        meetingAssignController.loading = false;
      });
    }

    function updateRows() {
      _.forEach(meetingAssignController.meetings, (meeting) => {
        meeting.empty = _.isEmpty(meeting.persons) && _.isEmpty(meeting.vacancies);
        formatActivityType(meeting.activity);
        formatStartDate(meeting.week);
      });
    }

    function formatActivityType(activity) {
      if (activity.type) {
        activity.displayType = entityTranslateFilter(activity.type);
      }
    }

    function formatStartDate(week) {
      if (week) {
        week.displayStartDate = configDateFilter(week.startDate);
      }
    }

    function loadTeacherHours() {
      return Meeting.durations({
        entityType: 'module',
        entityId: meetingAssignController.module.id,
        periodId: meetingAssignController.period.id
      }).$promise.then((teachers) => {
        meetingAssignController.teachers = _.orderBy(teachers, ['hours', 'name'], ['desc', 'asc']);
        meetingAssignController.teacherHours = _.sumBy(teachers, 'hours');
      });
    }

    meetingAssignController.showAvailability = function (availability) {
      AvailabilityModal.open({
        assignmentId: availability.assignment.id
      });
    };

    meetingAssignController.onChange = function (meeting) {
      return store(meeting).then(() => {
        afterSave();
      });
    };

    meetingAssignController.removePerson = function (meeting, person, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      _.remove(meeting.persons, { id: person.id });
      meetingAssignController.onChange(meeting);
    };

    meetingAssignController.removeVacancy = function (meeting, vacancy, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      _.remove(meeting.vacancies, { id: vacancy.id });
      meetingAssignController.onChange(meeting);
    };

    function afterSave() {
      Message.onSaved();
      loadTeacherHours();
      updateRows();
    }

    meetingAssignController.copy = function (template, $index) {
      const remainder = meetingAssignController.filtered.slice($index + 1, meetingAssignController.filtered.length + 1);
      const promises = _.map(remainder, (meeting) => {
        meeting.persons = angular.copy(template.persons);
        meeting.vacancies = angular.copy(template.vacancies);
        meeting.assignmentTypeId = template.assignmentTypeId;
        return store(meeting);
      });

      $q.all(promises).then(() => {
        afterSave();
      });
    };

    function store(meeting) {
      return Meeting.store({
        id: meeting.id,
        personIds: _.map(meeting.persons, 'id'),
        vacancyIds: _.map(meeting.vacancies, 'id'),
        assignmentTypeId: meeting.assignmentTypeId
      }).$promise;
    }

    meetingAssignController.onSort = function () {
      const direction = meetingAssignController.pageable.reverse ? 'desc' : 'asc';
      meetingAssignController.filtered = _.orderBy(meetingAssignController.filtered, meetingAssignController.pageable.order, direction);
    };

    meetingAssignController.filter = function () {
      meetingAssignController.filtered = _.filter(meetingAssignController.meetings, (meeting) =>
        _.every(meetingAssignController.search, (value, key) => matches(value, _.get(meeting, key)))
      );
    };

    function matches(search, value) {
      if (isEmpty(search.value)) {
        return true;
      }
      if (search.exact) {
        return value === search.value;
      }
      return _.includes(_.toLower(value), _.toLower(search.value));
    }

    function isEmpty(value) {
      return angular.isUndefined(value) || value === null || value === '';
    }

    meetingAssignController.getFilterValues = function (property) {
      return _(meetingAssignController.meetings)
        .map((meeting) => _.get(meeting, property))
        .map((value) => mapValue(value))
        .uniqBy('display')
        .value();
    };

    function mapValue(value) {
      if (value instanceof Date) {
        return { value, display: configDateFilter(value) };
      }
      return { value, display: '' + value };
    }

    meetingAssignController.clear = function (property) {
      delete meetingAssignController.search[property];
      meetingAssignController.filter();
    };

    meetingAssignController.selectFilter = function (property) {
      meetingAssignController.search[property].exact = true;
      meetingAssignController.filter();
    };

    meetingAssignController.formatValue = function (model) {
      if (model instanceof Date) {
        return configDateFilter(model);
      }
      return model;
    };
  }
});