'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessStatus
 * @description
 * uasProcessStatus Shows the process statuses
 */
angular.module('uasApp')
  .component('uasProcessStatus', {
    bindings: {
      entity: '<',
      onChange: '&?'
    },
    templateUrl: 'es6/process/progress/process.status.html',
    controllerAs: 'processStatusController',
    controller: function (ProcessProgress, ProcessModal, Process, WorkflowStarter) {
      const processStatusController = this;

      processStatusController.$onInit = function () {
        loadData();
      };

      function loadData() {
        processStatusController.loading = true;
        ProcessProgress.query({
          entityType: processStatusController.entity.self.type,
          entityId: processStatusController.entity.id
        }).$promise.then((processes) => {
          processStatusController.processes = processes;
        }).finally(() => {
          processStatusController.loading = false;
        });
      }

      function onChange() {        
        if (_.isFunction(processStatusController.onChange)) {
          processStatusController.onChange();
        } else {
          loadData();
        }
      }

      processStatusController.show = function (row) {
        ProcessModal.open({
          entity: processStatusController.entity,
          statuses: row.statuses,
          status: row.status,
          process: row.process,
          callback: () => onChange()
        });
      };

      processStatusController.start = function (row) {
        Process.start({
          id: row.process.id
        }, {
          entityType: processStatusController.entity.self.type,
          entityId: processStatusController.entity.id
        }).$promise.then(() => {
          WorkflowStarter.startWithNavigate({
            entity: processStatusController.entity,
            processId: row.process.id
          }).finally(loadData);
        });
      };
    }
  });
