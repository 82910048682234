'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Downloads
 * @description
 * Service for managing and downloading files.
 */
angular.module('uasApp')
    .factory('Downloads', function(UasResource) {
        return UasResource('/downloads', {
            deleteAll: {
                method: 'DELETE'
            }
        });
    });
