'use strict';

angular.module('uasApp').filter('active', function() {
    return function(input, active) {
        if (active === false) {
            return input;
        }

        if (_.isArray(input)) {
            return _.filter(input, isActive);
        }
        return isActive(input);
    };

    function isActive(object) {
        const changeType = _.get(object, 'changeType');
        return changeType !== 'REMOVE';
    }
});
