'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Process
 * @description
 * # Process
 * Process resource of the uasApp
 */
angular.module('uasApp')
  .factory('Process', function (UasResource) {
    return UasResource('/process', {
        children: {
            url: '/process/children',
            method: 'GET',
            isArray: true
        },
        search: {
            url: '/process/search',
            method: 'GET',
            isArray: false
        },
        outcomes: {
            url: '/process/:id/outcomes',
            method: 'GET',
            isArray: false
        },
        propagations: {
            url: '/process/:id/propagations',
            method: 'GET',
            isArray: true
        },
        proceed: {
            url: '/process/:id/proceed',
            method: 'PUT',
            isArray: false
        },
        start: {
            url: '/process/:id/start',
            method: 'PUT',
            isArray: false
        },
        transition: {
            url: '/process/:id/transition',
            method: 'PUT',
            isArray: false
        },
        timeline: {
            url: '/process/:id/timeline',
            method: 'GET',
            isArray: true
        }
    });
  });
