'use strict';

angular.module('uasApp').component('tasksReportPage', {
    templateUrl: 'es6/staffing/task/report/tasks.report.page.html',
    bindings: {
        entity: '<?',
        page: '<',
        workflow: '<',
        operations: '<?'
    },
    controllerAs: 'tasksReportPageController',
    controller: function(Page) {
        const tasksReportPageController = this;

        tasksReportPageController.$onInit = function() {
            tasksReportPageController.readOnly = Page.getParameterAsBoolean(tasksReportPageController.page, 'readOnly', false);
        };
    }
});
