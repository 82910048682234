'use strict';

angular.module('uasApp')
    .component('uasPlanboardOrder', {
        bindings: {
            modal: '<',
            content: '<',
            contents: '<',
            btnSaveLabel: '@?',
            btnCancelLabel: '@?',
            onSave: '&'
        },        
        templateUrl: 'es6/planboard/order/planboard.order.html',
        controllerAs: 'planboardOrderController',
        controller: function () {
            const planboardOrderController = this;

            planboardOrderController.$onInit = function() {
                planboardOrderController.close = planboardOrderController.modal.close;
            };

            planboardOrderController.onMove = function(item, index) {
                planboardOrderController.contents.splice(index, 1);
            };

            planboardOrderController.save = function() {
                planboardOrderController.onSave({ contents: planboardOrderController.contents });
                planboardOrderController.close();
            };
        }
    });