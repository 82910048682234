'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:evaluationReportPage
 * @description
 * evaluationReportPage Report on evaluation
 */
angular.module('uasApp').component('evaluationReportPage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?'
  },
  templateUrl: 'es6/evaluation/evaluation.report.page.html',
  controllerAs: 'reportPageController',
  controller: function (EntityService, Evaluation, Page) {
    const reportPageController = this;

    reportPageController.$onInit = function () {
      reportPageController.evaluationType = Page.getParameter(reportPageController.page, 'evaluationType');
      reportPageController.urlPattern = Page.getParameter(reportPageController.page, 'urlPattern', '');

      loadData();
    };

    function loadData() {
      const reference = EntityService.toReference(reportPageController.entity);
      const params = {
        evaluationType: reportPageController.evaluationType,
        entityType: _.get(reference, 'type'),
        entityId: _.get(reference, 'id')
      };

      reportPageController.loading = true;
      Evaluation.get(params).$promise.then((evaluation) => {
        reportPageController.evaluation = evaluation;
        reportPageController.url = reportPageController.urlPattern.replace('${evaluationId}', evaluation.id);
      }).finally(() => {
        reportPageController.loading = false;
      });
    }
  }
});
