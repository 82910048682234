'use strict';

angular.module('uasApp')
  .component('moduleCost', {
    bindings: {
        moduleId: '<',
        operations: '<'
    },
    templateUrl: 'es6/cost/module.cost.html',
    controllerAs: 'moduleCostController',
    controller: function(StudyCost) {
        const moduleCostController = this;

        moduleCostController.$onInit = function() {
            loadData();
        };

        function loadData() {
            moduleCostController.loading = true;
            moduleCostController.studies = [];
            StudyCost.studies({
                entityType: 'module',
                entityId: moduleCostController.moduleId
            }).$promise.then((studies) => {
                _.each(studies, (study) => {
                    study.totalCost = _(study.lines).map('totalCost').reduce(accumulate, 0);
                    study.capacity = _(study.lines).map('capacity').reduce(accumulate, 0);
                    study.open = true;
                });
    
                moduleCostController.totalCost = _(studies).map('totalCost').reduce(accumulate, 0);
                moduleCostController.totalCapacity = _(studies).map('capacity').reduce(accumulate, 0);
                moduleCostController.totalCount = _(studies).map('lines').flatten().value().length;
    
                _.each(studies, (study) => {
                    _.each(study.lines, (line) => {
                        line.percentage = (line.proRatoFactor || 0) * 100;
                        line.calculatedPercentage = (line.capacity / moduleCostController.totalCapacity) * 100;
                    });
    
                    study.percentage = _(study.lines).map('percentage').reduce(accumulate, 0);
                });
    
                moduleCostController.totalPercentage = _(studies).map('percentage').reduce(accumulate, 0);
                moduleCostController.studies = studies;
            }).finally(() => {
                moduleCostController.loading = false;
            });
        }

        function accumulate(a, b) {
            return a + b;
        }

        moduleCostController.open = function(line) {
            line.open = true;
        };

        moduleCostController.close = function(line) {
            line.open = false;
        };
    }
});
