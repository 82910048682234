'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('academicYears', {
    url: '/academic-years',
    template: '<academic-year-list></academic-year-list>',
    breadcrumb: {
      label: 'Static.Page.AcademicYears',
      root: true
    },
    admin: true
  });

  $stateProvider.state('academicYearNew', {
    url: '/academic-year/new',
    template: '<academic-year-create></academic-year-create>',
    entityType: 'academicYear',
    breadcrumb: {},
    admin: true
  });

  $stateProvider.state('academicYear', {
    url: '/academic-year/{id:[0-9]{1,8}}',
    resolve: {
      academicYearId: function ($stateParams) {
        return $stateParams.id;
      },
      academicYear: function (AcademicYear, academicYearId) {
        return AcademicYear.get({
          id: academicYearId
        }).$promise.then((year) => {
          this.self.data = year;
          return _.extend(year, {
            self: { type: 'academic-year', id: year.id }
          });
        });
      },
      operations: function (AuthService, academicYearId) {
          return AuthService.operations('academic-year', academicYearId);
      }
    },
    controllerAs: 'academicYearCtrl',
    controller: function(academicYear, operations) {
      this.academicYear = academicYear;
      this.operations = operations;
      this.tabs = [{
        title: 'Static.Page.AcademicYears.Edit',
        view: 'general',
        route: 'academicYear.general',
        label: 'Static.Help.AcademicYears.Edit',
        visible: true
      }, {
        title: 'Static.Page.AcademicYears.Rollover',
        view: 'rollover',
        route: 'academicYear.rollover',
        label: 'Static.Help.AcademicYears.Rollover',
        visible: true
      }, {
        title: 'Static.Page.AcademicYears.Simulations',
        view: 'simulations',
        route: 'academicYear.simulations',
        label: 'Static.Help.AcademicYears.Simulations',
        visible: true
      }];    
    },
    template: `
      <entity-tabs
        view="academicYear"
        entity="academicYearCtrl.academicYear"
        tabs="academicYearCtrl.tabs"
        show-feedback="false"
        show-versions="false"
        operations="academicYearCtrl.operations"
      />
    `,
    abstract: true,
    redirectTo: 'academicYear.general',
    entityType: 'academicYear',
    breadcrumb: {},
    admin: true
  });

  $stateProvider.state('academicYear.general', {
    url: '/general',
    views: {
      'academicYear': {
        controllerAs: 'academicYearGeneralCtrl',
        controller: function(academicYear) {
          this.academicYear = academicYear;
        },
        template: `
          <academic-year-edit 
            year="academicYearGeneralCtrl.academicYear">
          </academic-year-edit>
        `
      }
    },
    parent: 'academicYear',
    breadcrumb: {},
    admin: true
  });

  $stateProvider.state('academicYear.rollover', {
    url: '/rollover',
    views: {
      'academicYear': {
        controllerAs: 'academicYearRolloverCtrl',
        controller: function(academicYear) {
          this.academicYear = academicYear;
        },
        template: `
          <year-rollover 
            year="academicYearRolloverCtrl.academicYear">
          </year-rollover>
        `
      }
    },
    breadcrumb: {},
    parent: 'academicYear',
    admin: true
  });

  $stateProvider.state('academicYear.simulations', {
    url: '/simulations',
    views: {
      'academicYear': {
        controllerAs: 'academicYearSimulationsCtrl',
        controller: function(academicYear) {
          this.academicYear = academicYear;
        },
        template: `
          <academic-year-simulations 
            year="academicYearSimulationsCtrl.academicYear">
          </academic-year-simulations>
        `
      }
    },
    breadcrumb: {},
    parent: 'academicYear',
    admin: true
  });
});
