'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:workflowAssign
 * @description
 * workflowAssign Show the assignee of the workflow.
 */
angular.module('uasApp').component('workflowAssign', {
  bindings: {
    participants: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/workflow/workflow.assign.html',
  controllerAs: 'workflowAssignController',
  controller: function (Session) {
    const workflowAssignController = this;

    workflowAssignController.$onInit = function () {
      workflowAssignController.ngModelCtrl.$render = function () {
        workflowAssignController.assignee = workflowAssignController.ngModelCtrl.$modelValue;
        workflowAssignController.current = _.find(workflowAssignController.participants, { id: Session.personId });
      };
    };

    workflowAssignController.assign = function (assignee) {
      workflowAssignController.assignee = angular.copy(assignee);
      workflowAssignController.ngModelCtrl.$setViewValue(workflowAssignController.assignee);
    };
  }
});