'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:AccessRule
 * @description
 * # AccessRule
 * AccessRule resource of the uasApp
 */
angular.module('uasApp')
  .factory('AccessRule', function (UasResource) {
    return UasResource('/access-rules');
  });
