'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Appraisal
 * @description
 * The Appraisal service.
 */
angular.module('uasApp').factory('Appraisal', function (UasResource) {
  return UasResource('/appraisals', {
    combined: {
      method: 'POST',
      url: '/appraisals/combined'
    },
    children: {
      method: 'GET',
      isArray: true,
      url: '/appraisals/children'
    },
    generate: {
      method: 'POST',
      url: '/appraisals/generate/:moduleId'
    },
    parents: {
      method: 'GET',
      isArray: true,
      url: '/appraisals/parents'
    },
    report: {
      url: '/appraisal-report',
      method: 'GET'
    }
  });
});
