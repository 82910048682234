'use strict';

angular.module('uasApp').component('assignmentList', {
    templateUrl: 'es6/staffing/assignment/assignment.list.html',
    bindings: {
        organisation: '<',
        operations: '<',
        operationsToEdit: '@?',
        inactive: '<?',
        isReadOnly: '<?'
    },
    controllerAs: 'assignmentListController',
    controller: function ($uibModal, Pageable, RoleType, Assignment, FteModal, AuthService, Message, SecurityService) {
        const assignmentListController = this;

        assignmentListController.$onInit = function () {
            const operationsToEdit = AuthService.buildOperationsToEdit(assignmentListController.operationsToEdit, 'EDIT_ASSIGNMENTS');
            assignmentListController.editable = assignmentListController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, assignmentListController.operations);

            assignmentListController.academicYearId = sessionStorage.academicYear;
            assignmentListController.active = assignmentListController.inactive !== true;

            assignmentListController.pageable = Pageable.of({
                order: 'person.fullName'
            });

            RoleType.query({
                academicYearId: assignmentListController.academicYearId,
                entityType: 'assignment'
            }).$promise.then((roles) => {
                assignmentListController.roles = roles;
            });

            loadData();
        };

        function loadData() {
            const params = assignmentListController.pageable.build({
                organisationId: assignmentListController.organisation.id,
                academicYearId: assignmentListController.academicYearId,
                term: assignmentListController.term,
                roleId: assignmentListController.roleIds,
                vacancy: assignmentListController.vacancy,
                active: assignmentListController.active
            });

            assignmentListController.loading = true;
            Assignment.search(params).$promise.then((assignments) => {
                assignmentListController.assignments = assignments;
            }).finally(() => {
                assignmentListController.loading = false;
            });
        }

        assignmentListController.search = function() {
            loadData();
        };

        assignmentListController.create = function() {
            open({
                organisationId: assignmentListController.organisation.id,
                fte: 1
            });
        };

        assignmentListController.edit = function (assignment) {
            if (assignment.changeType !== 'REMOVE' && assignmentListController.editable) {
                const form = angular.copy(assignment);
                form.personId = _.result(assignment, 'person.id');
                form.organisationId = _.result(assignment, 'organisation.id');
                form.roleId = _.result(assignment, 'role.id');

                open(form);
            }
        };

        function open(assignment) {
            $uibModal.open({
                controllerAs: 'assignmentController',
                controller: function ($uibModalInstance) {
                    const assignmentController = this;
                    assignmentController.assignment = assignment;

                    assignmentController.onSave = function () {
                        Message.onSaved();
                        loadData();
                        assignmentController.close();
                    };

                    assignmentController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                },
                template: `
                    <assignment-edit 
                        assignment="assignmentController.assignment"
                        on-save="assignmentController.onSave()"
                        on-cancel="assignmentController.close()">
                    </assignment-edit>
                `
            });
        }

        assignmentListController.remove = function (assignment) {
            Assignment.remove({
                comment: {},
                id: assignment.id
            }).$promise.then(() => {
                loadData();
                Message.onRemoved();
            });
        };

        assignmentListController.editFte = function (assignment) {
            FteModal.open({
                assignment,
                onChange: () => loadData()
            });
        };
    }
});