'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('group', {
        url: '/group/{id:[0-9]{1,8}}',
        templateUrl: 'es6/group/group.html',
        controller: 'GroupCtrl',
        abstract: true,
        entityType: 'module-group',
        breadcrumb: {},
        resolve: {
            group: function (AcademicYearHolder, ModuleGroup, $stateParams) {
                const instance = this;
                return ModuleGroup.get({
                    id: $stateParams.id
                }).$promise.then(function (group) {
                    instance.self.data = group;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(group.academicYearId).then(() => {
                        return group;
                    });
                });
            },
            operations: function (AuthService, group) {
                return AuthService.operations('module-group', group.id);
            }
        }
    });

    $stateProvider.state('group.general', {
        url: '/general',
        views: {
            'group': {
                template: `
                    <uas-group-general
                        group="group"
                        operations="operations">
                    </uas-group-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Group.General',
            label: 'Static.Help.Group.General'
        }
    });

    $stateProvider.state('group.description', {
        url: '/description',
        views: {
            'group': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('module-group');
                    }
                },
                controller: function($scope, types) {
                    $scope.types = types;
                },
                template: `
                    <description
                        data-types="types"
                        data-entity-type="module-group"
                        data-entity-id="groupId"
                        data-status="group.status"
                        data-operations="operations">
                    </description>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Group.Description',
            label: 'Static.Help.Group.Description'
        }
    });

    $stateProvider.state('group.capacity', {
        url: '/group',
        views: {
            'group': {
                template: `
                    <uas-capacity
                        entity="group"
                        operations="operations">
                    </uas-capacity>
                `
            }
        },
        parent: 'group',
        breadcrumb: {},
        data: {
            secured: ['VIEW_CAPACITY'],
            title: 'Static.Tab.Group.Capacity',
            label: 'Static.Help.Group.Capacity'
        }
    });

    $stateProvider.state('group.credits', {
        url: '/credits',
        views: {
            'group': {
                template: `
                    <uas-credits
                        entity="group"
                        operations="operations">
                    </uas-credits>
                `
            }
        },
        parent: 'group',
        breadcrumb: {},
        data: {
            secured: ['VIEW_CREDITS'],
            title: 'Static.Tab.Group.Credits',
            label: 'Static.Help.Group.Credits'
        }
    });

    $stateProvider.state('group.additional', {
        url: '/additional',
        views: {
            'group': {
                resolve: {
                    groupId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                template: `
                    <uas-additional
                        entity="group"
                        operations="operations">
                    </uas-additional>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ADDITIONAL'],
            title: 'Static.Tab.Group.Extra',
            label: 'Static.Help.Group.Extra'
        }
    });

    $stateProvider.state('group.relations', {
        url: '/relations',
        views: {
            'group': {
                template: `
                    <relations
                        data-entity-type="module-group"
                        data-entity-id="groupId"
                        data-operations="operations">
                    </relations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Group.Relations',
            label: 'Static.Help.Group.Relations'
        }
    });

    $stateProvider.state('group.structure', {
        url: '/structure',
        views: {
            'group': {
                template: `
                    <structure
                        show-redirect="true"
                        entity="group"
                        operations="operations">
                    </structure>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Group.Structure',
            label: 'Static.Help.Group.Structure'
        }
    });

    $stateProvider.state('group.modules', {
        url: '/modules',
        views: {
            'group': {
                template: `
                    <modules-report
                        entity="group"
                        operations="operations">
                    </modules-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Group.Modules',
            label: 'Static.Help.Group.Modules'
        }
    });

    $stateProvider.state('group.studies', {
        url: '/studies',
        views: {
            'group': {
                resolve: {
                    studies: function($stateParams, ModuleGroup) {
                        return ModuleGroup.studies({
                            id: $stateParams.id
                        }).$promise;
                    }
                },
                controllerAs: 'stateController',
                controller: function(studies) {
                    const stateController = this;
                    stateController.studies = studies;
                },
                template: `
                    <uas-group-studies
                        studies="stateController.studies">
                    </uas-group-studies>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Group.Studies',
            label: 'Static.Help.Group.Studies'
        }
    });

    $stateProvider.state('group.changes', {
        url: '/changes',
        views: {
            'group': {
                resolve: {
                    groupId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                controllerAs: 'stateController',
                controller: function(groupId) {
                    const stateController = this;
                    stateController.entity = { type: 'module-group', id: groupId };
                },
                template: `
                    <uas-changes-detail
                        entity="stateController.entity">
                    </uas-changes-detail>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CHANGE'],
            title: 'Static.Tab.Group.Changes',
            label: 'Static.Help.Group.Changes'
        }
    });

    $stateProvider.state('group.admin', {
        url: '/admin',
        views: {
            'group': {
                template: `
                    <uas-group-admin
                        group="group"
                        operations="operations">
                    </uas-group-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Group.Admin',
            label: 'Static.Help.Group.Admin'
        }
    });

    $stateProvider.state('group.links', {
        url: '/links',
        views: {
            'group': {
                template: `
                    <link-list
                        entity="group"
                        operations="operations">
                    </link-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_LINKS'],
            title: 'Static.Tab.Group.Links',
            label: 'Static.Help.Group.Links'
        }
    });

    $stateProvider.state('group.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'group': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader
                        page-id="pageId"
                        entity="group"
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });

});
