'use strict';

angular.module('uasApp')
    .directive('comments', function() {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'es6/changes/comments.html',
            scope: {
                comment: '=comment',
                change: '=?',
                editable: '@?',
                entityTypes: '<?',
                last: '<?',
                visible: '=?',
                onScroll: '&'
            },
            controller: function($q, $element, $scope, $timeout, $state, $document, Comment, Message, Person, $filter, EntityHolder) {
                $scope.visible = $scope.visible !== false;
                $scope.showReply = false;
                initializeComment();

                function initializeComment() {
                    if (angular.isDefined($scope.comment)) {
                        $scope.comment.inputId = 'message-' + $scope.comment.id;
                        withHtmlBody($scope.comment);
                        selectCommentIfNeeded([$scope.comment]);
                    }
                }

                $scope.toggleShowReply = function() {
                    $scope.showReply = !$scope.showReply;
                    $scope.toggleComment($scope.comment, true);
                    focusInput();
                };

                function loadReplies() {
                    const replyCount = _.get($scope.comment, 'replyCount', 0);

                    if ($scope.comment && replyCount > 0 && _.isEmpty($scope.replies)) {
                        $scope.loadingReplies = true;

                        Comment.query({
                            parentId: $scope.comment.id
                        }).$promise
                            .then(buildReplies)
                            .finally(() => $scope.loadingReplies = false);
                    }
                }

                function buildReplies(replies) {
                    if ($scope.change && angular.isDefined($scope.change.id)) {
                        $scope.replies = _.filter(replies, {
                            parentId: $scope.change.commentId
                        });
                    } else {
                        $scope.replies = replies;
                    }

                    buildComments();
                }

                function buildComments() {
                    $scope.comments = _.concat($scope.comment, $scope.replies);
                    _.each($scope.comments, withHtmlBody);
                    selectCommentIfNeeded($scope.comments);
                }

                /**
                 * Transform users found in comments to clickable links.
                 * If an incorrect user is found, the link is not clickable and the user gets feedback that this user does not exist.
                 *
                 * @param  {Object} comment with at least and id, message and fullName
                 * @return {Object} New Comment object with edited message.
                 */
                function withHtmlBody(comment) {
                    comment.html = _.escape(comment.message) || '';
                    const tags = comment.html.match(/\[([^ ]+?)\]/g);
                    if (tags) {
                        _.each(tags, (tag) => {
                            const externalId = tag.replace(/[\[\]]/g, '');
                            Person.get({ externalId }).$promise.then((person) => {
                                if (person) {
                                    const link = buildPersonLink(person);
                                    comment.html = comment.html.replace(tag, link);
                                }
                            });
                        });
                    }
                    return comment;
                }

                function buildPersonLink(person) {
                    const url = $state.href('person.general', { id: person.id }, { absolute: true });
                    return `<a class="link-button" href="${url}"><i class="fa fa-user"></i> ${person.fullName}</a>`;
                }

                function selectCommentIfNeeded(comments) {
                    const current = EntityHolder.get();
                    const type = _.get(current, 'type');
                    if (type === 'comment') {
                        const found = _.find(comments, { id: current.id });
                        if (found) {
                            scrollToComment(found);
                            EntityHolder.set(null);
                        }
                    }
                }

                function scrollToComment(comment) {
                    comment.selected = true;

                    $scope.onScroll({
                        changeId: _.get($scope.change, 'id')
                    });

                    $timeout(() => {
                        const id = 'reply-' + comment.id;
                        $document[0].getElementById(id).scrollIntoView(true);
                    }, 100);
                }

                function focusInput() {
                    const formId = _.get($scope.comment, 'inputId');

                    $timeout(() => {
                        const inputElement = $element.find(`#${formId}`);
                        if (inputElement && inputElement.length > 0) {
                            inputElement[0].focus();
                        }
                    });
                }

                $scope.toggleComment = function(comment, doOpen = !comment.open) {
                    comment.open = doOpen;

                    if (comment.open) {
                        loadReplies();
                    }
                };

                $scope.$on('status', function(obj, result) {
                    $scope.comment.status = result;
                });

                /**
                 * Adds a comment
                 * @param {String} content Text input typed by the user.
                 * @param {Object} replyForm Form state.
                 */
                $scope.add = function(content, replyForm) {
                    let body = {
                        message: content,
                        link: $state.href($state.current.name, $state.params, { absolute: false })
                    };

                    // Attach change identifier when available
                    if ($scope.change && angular.isDefined($scope.change.id)) {
                        body.changeId = $scope.change.id;
                    }

                    Comment.save({
                        parentId: $scope.comment.id
                    }, body).$promise.then(function(result) {
                        result.isNew = true;
                        replyForm.$setPristine(true);
                        updateReplies(result);

                        Comment.get({ id: $scope.comment.id }).$promise.then(function(comment) {
                            _.extend($scope.comment, {
                                numberOfMentions: comment.numberOfMentions,
                                numberOfAnsweredMentions: comment.numberOfAnsweredMentions
                            });
                        });

                        Message.addSuccess($filter('translate')('Static.Message.ResponseCreated'));
                        $scope.message = '';
                        $scope.showReply = false;
                    });
                };

                function updateReplies(newReply) {
                    const transformedReply = withHtmlBody(newReply);

                    $scope.replies = _.defaultTo($scope.replies, []);
                    $scope.replies.push(transformedReply);
                    $scope.comment.replyCount = $scope.replies.length;
                }
            }
        };
    });
