'use strict';

angular.module('uasApp').directive('pageParameter', function ($compile) {
    return {
        restrict: 'E',
        scope: {
            page: '<',
            parameter: '<',
            onChange: '&?'
        },
        link: function link(scope, element) {

            update();
            scope.$watch('parameter', update);

            function update() {
                if (angular.isDefined(scope.parameter)) {
                    render();
                }
            }

            function render() {
                element.empty();

                const editor = getEditor();
                const component = angular.element(editor.html);
                const extended = _.extend(scope, editor.scope);

                $compile(component)(extended);
                element.append(component);
            }

            function getEditor() {
                if (_.isFunction(scope.parameter.editor)) {
                    return scope.parameter.editor(scope.parameter);
                }

                return getInput(scope.parameter);
            }

            function getInput(parameter) {
                const field = {
                    name: parameter.name,
                    valueType: _.get(parameter, 'type', 'STRING').toUpperCase(),
                    visible: 'ALWAYS',
                    value: parameter.value,
                    multipleValues: parameter.isArray,
                    labels: [{ value: parameter.name }],
                    placeholder: parameter.placeholder || ''
                };

                const html = `
                    <custom-field-input
                        ng-model="parameter.value"
                        ng-change='onChange()'
                        custom-field="field"
                        operations="['EDIT']"
                        array-model="${parameter.isArray}"
                        placeholder="{{ field.placeholder }}">
                    </custom-field-input>
                `;

                return {
                    html,
                    scope: {
                        field
                    }
                };
            }
        }
    };
});
