'use strict';

angular.module('uasApp').factory('UnsavedChangesModal', function ($q, $uibModal, CommentModal, EntityType) {

  const instance = {};

  function getCommentType(params) {
    if (params.saveWithComment !== false) {
      if (angular.isDefined(params.commentType)) {
        return $q.resolve(params.commentType);
      }
      
      return EntityType.get({
        rootType: params.rootType,
        entityType: params.entityType
      }).$promise.then((customType) => {
        return _.get(customType, 'commentType', 'NEVER');
      });
    }
    return $q.resolve('NEVER');
  }

  instance.open = function (params) {
    $uibModal.open({
      templateUrl: 'es6/app/unsaved.changes.modal.html',
      controllerAs: 'modalController',
      resolve: {
        commentType: function () {
          return getCommentType(params);
        }
      },
      controller: function ($uibModalInstance, commentType) {
        const modalController = this;
        modalController.showCancel = params.showCancel === true;
        modalController.invalid = params.invalid === true;

        function onSaveCallback(structure, comment) {
          const saveResult = params.onSave(structure, comment);
          if (angular.isDefined(saveResult)) {
            saveResult.then(() => {
              modalController.discard();
            });
          } else {
            modalController.discard();
          }
        }

        modalController.save = function () {
          if (commentType !== 'NEVER') {
            CommentModal.openModal(
              params.content,
              onSaveCallback,
              commentType
            );
          } else {
            onSaveCallback(params.content);
          }
        };

        modalController.discard = function () {
          modalController.dismiss();
          if (angular.isDefined(params.onDiscard)) {
            params.onDiscard();
          }
        };

        modalController.dismiss = function () {
          $uibModalInstance.dismiss();
        };
      }
    });
  };

  return instance;
});
