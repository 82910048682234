'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:itemEdit
 * @description
 * The itemEdit component.
 */
angular.module('uasApp').component('itemEdit', {
    templateUrl: 'es6/item/item.edit.html',
    bindings: {
        item: '<',
        type: '<',
        entity: '<',
        commentType: '<?',
        instance: '<',
        operations: '<',
        operationsToEdit: '<?',
        onSave: '&',
        isReadOnly: '<?',
        showDates: '<',
        labelWidth: '<'
    },
    controllerAs: 'itemEditController',
    controller: function(Item, CustomField) {
        const itemEditController = this;

        itemEditController.$onInit = function() {
            itemEditController.saveCallbacks = [];

            itemEditController.loading = true;
            CustomField.query({
                type: itemEditController.type.id
            }).$promise.then((fields) => {
                itemEditController.fields = _.sortBy(fields, ['sequence', 'name', 'id']);
                _.forEach(itemEditController.fields, (field) => {
                    field.values = _.get(itemEditController.item.values, field.id, []);
                });

                itemEditController.hasSections = _.some(itemEditController.fields, (field) => field.section);
                if (itemEditController.hasSections) {
                    itemEditController.sections = CustomField.getSections(itemEditController.fields);
                    itemEditController.fields = _(itemEditController.sections).map((section) => section.fields).flatMap().value();
                }

                itemEditController.onChange();
            }).finally(() => {
                itemEditController.loading = false;
            });
        };

        itemEditController.onChange = function() {
            itemEditController.evaluation = {
                entity: itemEditController.entity,
                values: _.mapValues(_.keyBy(getValues(), 'id'), 'value')
            };
        };

        itemEditController.save = function() {
            Item.update({
                comment: {
                    message: itemEditController.message
                },
                content: mapToForm(itemEditController.item)
            }).$promise.then(() => {
                itemEditController.onSave();
                itemEditController.close();
            });
        };

        function mapToForm(item) {
            return {
                id: item.id,
                entity: item.entity,
                startId: item.startId,
                endId: item.endId,
                type: item.type,
                values: getValues()
            };
        }

        function getValues() {
            return _(itemEditController.fields)
                .map((field) =>
                    _(field.values)
                        .filter(hasValue)
                        .map((value) => ({
                            field: field.id,
                            value: value
                        }))
                        .value()
                )
                .flatten()
                .value();
        }

        function hasValue(value) {
            return angular.isDefined(value) && value !== null && value !== '';
        }

        itemEditController.close = function() {
            itemEditController.instance.close();
        };
    }
});