'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodTypeList
 * @methodTypeList
 * methodTypeList Manages the method types
 */
angular.module('uasApp').component('methodTypeList', {
    templateUrl: 'es6/methods/type/method.type.list.html',
    controllerAs: 'methodTypeListController',
    controller: function ($uibModal, Pageable, MethodType, Message) {
        const methodTypeListController = this;
        
        methodTypeListController.$onInit = function() {
            methodTypeListController.pageable = Pageable.of({
                order: 'code'
            });
        };

        methodTypeListController.onCategory = function(categoryId) {
            methodTypeListController.categoryId = categoryId;
            loadData();
        };

        methodTypeListController.onPage = function() {
            loadData();
        };

        function loadData() {
            const params = methodTypeListController.pageable.build({
                categoryId: methodTypeListController.categoryId
            });

            methodTypeListController.loading = true;
            MethodType.get(params).$promise.then((types) => {
                methodTypeListController.types = types;
            }).finally(() => {
                methodTypeListController.loading = false;
            });
        }

        methodTypeListController.remove = function(type) {
            MethodType.remove(type).$promise.then(() => {
                loadData();
                Message.onRemoved();
            });
        };

        methodTypeListController.create = function() {
            methodTypeListController.edit({
                categoryId: methodTypeListController.categoryId
            });
        };

        methodTypeListController.edit = function(type) {
            $uibModal.open({
                template: `
                    <uas-method-type 
                        type="methodTypeModalController.type"
                        on-cancel="methodTypeModalController.close()"
                        on-save="methodTypeModalController.onSave()">
                    </uas-method-type>
                `,
                controllerAs: 'methodTypeModalController',
                controller: function($uibModalInstance) {
                    const methodTypeModalController = this;

                    methodTypeModalController.$onInit = function() {
                        methodTypeModalController.type = angular.copy(type);
                    };

                    methodTypeModalController.close = function() {
                        $uibModalInstance.dismiss();
                    };

                    methodTypeModalController.onSave = function() {
                        methodTypeModalController.close();
                        Message.onSaved();
                        loadData();
                    };
                }
            });
        };
    }
});