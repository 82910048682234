'use strict';

angular.module('uasApp').component('hourReportPage', {
    templateUrl: 'es6/staffing/worklog/report/hour.report.page.html',
    bindings: {
        entity: '<?',
        page: '<',
        workflow: '<',
        operations: '<?'
    },
    controllerAs: 'hourReportPageController',
    controller: function(Worklog, EntityService, $q) {
        const hourReportPageController = this;

        hourReportPageController.$onInit = function() {
            loadData();
        };

        function loadData() {
            hourReportPageController.loading = true;
            getReport().then((report) => {
                hourReportPageController.report = report;
            }).finally(() => {
                hourReportPageController.loading = false;
            });
        }

        function getReport() {
            const entityType = EntityService.getType(hourReportPageController.entity);
            if (entityType === 'organisation') {
                return Worklog.report({
                    organisationId: hourReportPageController.entity.id
                }).$promise;
            } else if (entityType === 'study') {
                return Worklog.report({
                    studyId: hourReportPageController.entity.id
                }).$promise;
            }

            return $q.resolve({
                lines: []
            });
        }
    }
});