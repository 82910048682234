'use strict';

angular.module('uasApp').component('planboardPrintPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/planboard/planboard.print.page.html',
  controllerAs: 'planboardPrintPageController'
});