'use strict';

angular.module('uasApp').component('mineQuestions', {
    templateUrl: 'es6/mine/mine.questions.html',
    controllerAs: 'mineQuestionsController',
    controller: function (Pageable, Comment) {
        const mineQuestionsController = this;

        mineQuestionsController.$onInit = function() {
            mineQuestionsController.pageable = Pageable.of({
                order: 'creationTime',
                reverse: true
            });

            loadData();
        };

        function loadData() {
            const parameters = mineQuestionsController.pageable.build({
                academicYearId: sessionStorage.academicYear
            });

            Comment.asked(parameters).$promise.then((questions) => {
                mineQuestionsController.questions = questions;
            });
        }

        mineQuestionsController.onChange = function() {
            loadData();
        };
    }
});
