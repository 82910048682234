'use strict';

angular.module('uasApp').component('entityButton', {
  bindings: {
    entity: '<?',
    target: '@',
    hasIcon: '<?',
    navigate: '<?'
  },
  templateUrl: 'es6/app/entity/entity.button.html',
  controllerAs: 'entityButtonController'
});
