'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Expression
 * @description
 * The Expression service.
 */
angular.module('uasApp')
    .factory('Expression', function(UasResource) {
        return UasResource('/expressions', {
            'validate': {
                method: 'POST',
                url: '/expressions/validate',
                isArray: false
            },
            'evaluate': {
                method: 'POST',
                url: '/expressions/evaluate',
                isArray: false
            }
        });
    });
