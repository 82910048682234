'use strict';

angular.module('uasApp')
    .component('uasReportOverview', {
        bindings: {
            report: '<',
            tab: '@?',
            pageable: '<?',
            name: '@?',
            cacheKey: '@',
            decorator: '&?',
            entity: '<?',
            reportType: '@?',
            operations: '<?',
            defaultTemplate: '@?',
            hideTemplates: '<?',
            rowClasses: '@?',
            template: '<?',
            searchColumns: '<?',
            selectable: '<?',
            selectAll: '<?',
            onSelect: '&?',
            onClick: '&?',
            onEdit: '&?'
        },
        transclude: {
            'buttons': '?reportButtons',
            'heading': '?reportHeading'
        },
        templateUrl: 'es6/report/report.overview.html',
        controllerAs: 'reportOverviewController',
        controller: function ($scope, $filter, $uibModal, $q, BulkModal, AuthService, Changes, Clipboard, EntityService,
                              EntityTypes, Language, Pageable, Report, ReportTemplateService, SecurityService) {
            const reportOverviewController = this;

            reportOverviewController.$onInit = function () {
                reportOverviewController.academicYearId = sessionStorage.academicYear;
                reportOverviewController.language = Language.get();
                reportOverviewController.isAdmin = AuthService.isAdmin();
                reportOverviewController.editable = SecurityService.isAllowed('BULK_CHANGE', reportOverviewController.operations);
                reportOverviewController.showOptions = false;

                EntityTypes.load().then((entityTypes) => {
                    reportOverviewController.entityTypes = entityTypes;
                });
            };

            reportOverviewController.$onChanges = function (changes) {
                if (!reportOverviewController.pageable) {
                    reportOverviewController.pageable = Pageable.of();
                }

                if (Changes.hasChanged(changes, 'report') && reportOverviewController.report) {
                    reportOverviewController.filteredRows = initReportRows(reportOverviewController.report);
                    loadData();
                }

                if (Changes.hasChanged(changes, 'reportType')) {
                    reportOverviewController.rootType = EntityService.getRootType(reportOverviewController.reportType);
                }
            };

            function loadData() {
                loadTabs();
                decorate();
                if (!reportOverviewController.template) {
                    loadPreference();
                }
                updateHeaders();
                filterRows();
                setupAllToggled();
            }

            function initReportRows(report) {
                // Get all date column names.
                const dateColumnCodes = _(report.headers)
                    .filter((h) => h.valueType === 'DATE' || h.valueType === 'DATE_TIME')
                    .map((h) => h.code)
                    .value();

                return _.map(report.rows, (r) => {
                    _.forEach(dateColumnCodes, (dcc) => {
                        // If there is data for the date column, convert it to a JS date.
                        const dateString = r.data[dcc];

                        if (dateString) {
                            r.data[dcc] = new Date(dateString);
                        }
                    });

                    return r;
                });
            }

            function loadTabs() {
                if (!_.isEmpty(reportOverviewController.tab)) {
                    reportOverviewController.tabs = _(reportOverviewController.report.rows)
                        .map((row) => _.get(row.data, reportOverviewController.tab))
                        .filter((value) => !_.isEmpty(value))
                        .uniq()
                        .sortBy()
                        .value();
                }
            }

            $scope.filter = function (column) {
                $uibModal.open({
                    templateUrl: 'es6/report/report.overview.filter.modal.html',
                    controllerAs: 'filterController',
                    controller: function ($uibModalInstance) {
                        const filterController = this;

                        filterController.mode = undefined;
                        filterController.columnName = column.name;
                        filterController.searchValue = column.search;
                        filterController.exact = column.exact;
                        filterController.empty = column.empty;

                        filterController.clear = function () {
                            filterController.searchValue = '';
                        };

                        filterController.filter = function () {
                            column.exact = filterController.exact;
                            column.empty = filterController.empty;

                            const value = parseValues();
                            reportOverviewController.filterHeader(column, value);

                            $uibModalInstance.dismiss();
                        };

                        function parseValues() {
                            if (filterController.empty) {
                                return '';
                            }

                            const termWithCommas = (filterController.searchValue || '').replace(/\n/g, ',');
                            const terms = termWithCommas.split(',');

                            return _(terms)
                              .filter((term) => !_.isEmpty(term))
                              .sort()
                              .value()
                              .join(',');
                        }
                    }
                });
            };

            reportOverviewController.edit = function (header, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                BulkModal.open({
                    rows: reportOverviewController.selectedRows,
                    column: header,
                    onChange: reportOverviewController.onEdit
                });
            };

            reportOverviewController.onSort = function (header) {
                ensureHeader(header).then(filterRows);
            };

            function decorate() {
                if (reportOverviewController.decorator) {
                    _.each(reportOverviewController.report.rows, function (row) {
                        row.decoration = reportOverviewController.decorator({ row });
                    });
                }
            }

            reportOverviewController.getRow = function (columnCode) {
                return _(reportOverviewController.filteredRows)
                    .map((row) => Report.unwrap(row.data[columnCode]))
                    .flatten()
                    .filter((value) => !_.isEmpty(value))
                    .uniq()
                    .sortBy()
                    .value();
            };

            reportOverviewController.open = function (header, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                ensureHeader(header).then(() => {
                    const values = reportOverviewController.getRow(header.code);
                    const joined = _.join(values, ', ');

                    Clipboard.copy({
                        value: joined
                    });
                });
            };

            //
            // Selection
            //

            reportOverviewController.onToggleAll = function() {
                _.forEach(reportOverviewController.filteredRows, (row) => {
                    row.selected = reportOverviewController.allToggled;
                });

                updateSelection();
            };

            reportOverviewController.select = function () {
                updateSelection();
            };

            function updateSelection() {
                if (reportOverviewController.selectable_) {
                    reportOverviewController.selectedRows = _(reportOverviewController.filteredRows).filter({
                        selected: true
                    }).map('entity').value();

                    if (angular.isDefined(reportOverviewController.onSelect)) {
                        reportOverviewController.onSelect({
                            rows: reportOverviewController.selectedRows
                        });
                    }
                } else {
                    reportOverviewController.selectedRows = reportOverviewController.filteredRows;
                }
            }

            //
            // Filtering
            //

            reportOverviewController.filterTab = function (value, exact) {
                const header = _.find(reportOverviewController.report.headers, {
                    code: reportOverviewController.tab
                });

                header.exact = exact;
                reportOverviewController.filterHeader(header, value);
            };

            reportOverviewController.filterHeader = function (header, value) {
                header.search = value;

                if (header.valueType === 'BOOLEAN') {
                    header.empty = header.search === 'undefined';
                    header.exact = header.search === 'true' || header.search === 'false';
                }

                ensureHeader(header).then(filterRows);
            };

            function filterRows() {
                function isHeaderRelevantForFiltering(header) {
                    return !_.isEmpty(header.search) || header.empty === true;
                }

                function doesValueMatchHeaderCriteria(value, header) {
                    if (header.valueType === 'BOOLEAN') {
                        if (header.empty) {
                            return angular.isUndefined(value);
                        } else if (header.exact) {
                            return value === header.search;
                        }
                    } else if (header.empty) {
                        return _.isEmpty(value);
                    } else if (_.isEmpty(value)) {
                        return false;
                    }

                    const terms = header.search.toLowerCase().split(',');
                    return _.some(terms, (term) => {
                        const query = _.trim(term);
                        if (_.isEmpty(query)) {
                            return false;
                        }

                        return header.exact ? value === query : value.includes(query);
                    });
                }

                reportOverviewController.filteredRows = _.filter(reportOverviewController.report.rows, (row) => {
                    return _(reportOverviewController.report.headers)
                        .filter(isHeaderRelevantForFiltering)
                        .every((header) => {
                          const value = Report.getFilterValue(row, header);
                          return doesValueMatchHeaderCriteria(value, header);
                        });
                });

                updateRows();
            }

            function setupAllToggled() {
                if (reportOverviewController.selectAll) {
                    reportOverviewController.allToggled = true;
                    reportOverviewController.onToggleAll();
                }
            }

            reportOverviewController.onPage = function() {
                updateRows();
            };

            function updateRows() {
                const sortedRows = getSortedRows();

                const pageable = reportOverviewController.pageable.unsorted();
                reportOverviewController.pagedRows = $filter('paged')(sortedRows, pageable);

                ensurePageHeaders();
                updateSelection();
            }

            reportOverviewController.predicate = function (val) {
                return Report.unwrap(val.data[reportOverviewController.pageable.order]);
            };

            // Templates

            reportOverviewController.setTemplate = function (template) {
                reportOverviewController.template = template;

                loadPreference();
                updateHeaders();

                return ensurePageHeaders().then(() => {
                    const header = _.find(reportOverviewController.report.headers, {
                        code: reportOverviewController.pageable.order
                    });

                    if (header) {
                        return ensureHeader(header);
                    } else {
                        return $q.resolve();
                    }
                }).then(filterRows);
            };

            reportOverviewController.getVisibleColumns = function () {
                return _(reportOverviewController.report.headers)
                    .filter({ visible: true })
                    .map((header) => ({
                        name: header.code,
                        value: header.search,
                        exact: header.exact,
                        sequence: header.sequence
                    }))
                    .value();
            };

            function showColumn(header) {
                header.visible = true;
                ensurePageHeader(header);
            }

            reportOverviewController.onColumn = function (selectedIds) {
                _.forEach(reportOverviewController.report.headers, (header) => {
                    delete header.sequence;

                    const index = _.findIndex(selectedIds, (id) => id === header.id);
                    if (index !== -1) {
                        header.sequence = index;
                    }
                });

                updateHeaders();
                ensurePageHeaders();
            };

            function ensureHeaders() {
                return forEachHeader(ensureHeader);
            }

            function forEachHeader(callback) {
                const promises = _(reportOverviewController.report.headers)
                    .filter({ visible: true })
                    .map(callback)
                    .sortBy('sequence')
                    .value();

                return $q.all(promises);
            }

            function ensureHeader(header) {
                return ensureRows(reportOverviewController.report.rows, header);
            }

            function ensureRows(rows, header) {
                return Report.ensure(rows, header);
            }

            function ensurePageHeaders() {
                return forEachHeader(ensurePageHeader);
            }

            function ensurePageHeader(header) {
                return ensureRows(reportOverviewController.pagedRows, header);
            }

            //
            // Redirects
            //

            reportOverviewController.doOnClick = function (row, col) {
                if (reportOverviewController.onClick) {
                    reportOverviewController.onClick({
                        row,
                        col
                    });
                } else {
                    routeToEntity(row);
                }
            };

            function routeToEntity(row) {
                EntityService.redirectPlain(row.entity, {
                    target: '_blank'
                });
            }

            //
            // Reporting
            //

            function updateHeaders() {
                reportOverviewController.headers = _(reportOverviewController.report.headers)
                    .filter('visible').sortBy('sequence').value();

                setHeaderIds();

                reportOverviewController.selectable_ = reportOverviewController.selectable || (reportOverviewController.editable && reportOverviewController.reportType);
            }

            function setHeaderIds() {
              _.forEach(reportOverviewController.report.headers, (header) => {
                header.id = header.id || header.fieldId || _.parseInt(_.uniqueId());
              });

              reportOverviewController.headerIds = _.map(reportOverviewController.headers, 'id');
            }

            reportOverviewController.getEntities = function() {
                const rows = getSortedRows();
                const entities = _(rows).filter({ selected: true }).map('entity').value();
                return $q.resolve(entities);
            };

            reportOverviewController.getRows = function() {
                return ensureHeaders().then(() => {
                    let rows = getSortedRows();
                    if (reportOverviewController.selectable_) {
                        rows = _.filter(rows, { selected: true });
                    }

                    return _.map(rows, function(row) {
                        const obj = {};
                        _(reportOverviewController.headers)
                            .map('code')
                            .each(function(code) {
                                obj[code] = row.data[code];
                            });
                        return obj;
                    });
                });
            };

            function getSortedRows() {
                return $filter('orderBy')(reportOverviewController.filteredRows, reportOverviewController.predicate, reportOverviewController.pageable.reverse);
            }

            //
            // Preferences
            //

            function loadPreference() {
                const preference = Report.getPreferences(reportOverviewController.template, {
                    order: 'code',
                    reverse: false
                });

                reportOverviewController.pageable.order = preference.order;
                reportOverviewController.pageable.reverse = preference.reverse;

                if (!_.isEmpty(preference.headers)) {
                    loadPreferredHeaders(preference);
                }
            }

            function loadPreferredHeaders(preference) {
                reportOverviewController.searchColumns = reportOverviewController.searchColumns || _.some(preference.headers, (header) => !_.isEmpty(header.search));

                const headers = reportOverviewController.report.headers || [];
                _.each(headers, (header) => {
                    header.visible = false;
                    delete header.search;
                    delete header.exact;
                    delete header.sequence;
                });

                _.each(preference.headers, (args) => {
                    _(headers).filter({
                        code: args.code
                    }).each((header) => {
                        header.search = args.search;
                        header.exact = args.exact;
                        header.sequence = args.sequence;
                        showColumn(header);
                    });
                });
            }

            //
            // Report templates
            //

            reportOverviewController.onSave = function (createNew) {
                ReportTemplateService.save({
                    type: reportOverviewController.cacheKey,
                    createNew: createNew,
                    template: reportOverviewController.template,
                    pageable: reportOverviewController.pageable,
                    headers: reportOverviewController.getVisibleColumns
                }).then((template) => {
                    reportOverviewController.template = template;
                    Report.savePreferences(reportOverviewController.cacheKey, reportOverviewController.template);
                });
            };
        }
    });
