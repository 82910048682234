'use strict';

angular.module('uasApp')
  .directive('formInput', function ($compile, jarbInputStore, jarbInputSelector) {
    return {
      templateUrl: 'es6/app/forms/form.input.html',
      require: '^uasForm',
      attrs: ['inputId', 'label'],
      link: function (scope, element, attrs, formController) {
        const modelPath = formController.model + '.' + attrs.inputId;
        const validationPath = formController.validator + '.' + attrs.inputId;

        const label = element.find('label');
        label.attr('for', attrs.inputId);

        if (attrs.label) {
          label.append('<span translate="' + attrs.label + '"></span>');
        }

        if (attrs.message) {
          label.append('<tooltip-label message="' + attrs.message + '"></tooltip-label>');
        }

        $compile(label)(scope);

        if (formController.labelSize) {
          updateSize(parseInt(formController.labelSize), element);
        }

        const editable = formController.editable && attrs.isReadOnly !== 'true';
        const constraints = jarbInputStore.getConstraints();
        if (constraints === null) {
            jarbInputStore.loadConstraints();
            jarbInputStore.onConstraintsChanged((result) => {
                applyConstraints(result, modelPath, validationPath, attrs, element, scope, editable);
          });
        } else {
          applyConstraints(constraints, modelPath, validationPath, attrs, element, scope, editable);
        }
      }
    };

    function updateSize(labelSize, element) {
      const label = element.find('label');
      label.removeClass('col-sm-3');
      label.addClass('col-sm-' + labelSize);

      const container = element.find('div.col-sm-9');
      container.removeClass('col-sm-9');
      container.addClass('col-sm-' + (12 - labelSize));
    }

    function applyConstraints(constraints, modelPath, validationPath, attrs, element, scope, editable) {
      const rules = jarbInputSelector.validationRulesFor(validationPath, constraints);

      if (_.isEmpty(rules)) {
        return; // Skip when not found
      }

      const input = element.find('.form-input-container');
      const container = input.parent();

      let newInput;

      if (_.includes(rules.types, 'descriptions')) {
        newInput = angular.element('<i18n-input></i18n-input>');
      } else if (rules.javaType === 'boolean') {
        newInput = angular.element(`<toggle toggle-id="${attrs.inputId}"></toggle>`);
      } else if (_.includes(rules.types, 'date')) {
        newInput = angular.element('<uas-datepicker></uas-datepicker>');
        newInput.attr('input-name', attrs.inputId);
        newInput.attr('min-date', attrs.min);
        newInput.attr('max-date', attrs.max);
      } else {
        newInput = angular.element('<input class="form-control">');
        if (rules.max) {
          newInput.attr('max', rules.max);
        }
      }

      newInput.attr('id', attrs.inputId);
      newInput.attr('name', attrs.inputId);
      newInput.attr('ng-model', modelPath);
      newInput.attr('jarb-input', validationPath);
      if (editable !== true) {
        newInput.attr('disabled', 'disabled');
      }
      const attributesToCopy = ['type', 'placeholder', 'required', 'disabled', 'ngModel', 'id', 'name', 'step'];
      _.each(attributesToCopy, function(attributeToCopy) {
        const value = attrs[attributeToCopy];
        if (value) {
          newInput.attr(_.kebabCase(attributeToCopy), value);
        }
      });
      input.replaceWith(newInput);

      scope.hasErrors = function () {
        if (!newInput[0]) {
          return false;
        }
        return false;
      };

      if (rules.required && editable === true) {
        container.append('<required-label ng-model="' + modelPath + '"></required-label>');
      }

      $compile(container)(scope);
    }
  });
