'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasInstitutionEdit
 * @description
 * # uasInstitutionEdit
 * Edit the institution.
 */
angular.module('uasApp')
    .component('uasInstitutionEdit', {
        bindings: {
            institution: '<',
            instance: '<',
            onSave: '&'
        },
        controllerAs: 'institutionEditController',
        templateUrl: 'es6/organisation/institution/institution.edit.html',
        controller: function(Institution) {
            const institutionEditController = this;

            institutionEditController.save = function() {
                return Institution.save({
                    content: institutionEditController.institution
                }).$promise.then(institutionEditController.onSave);
            };
        }
    });
