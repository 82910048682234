'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasOrganisationAdmin
 * @description
 * # uasOrganisationAdmin
 * Shows the administration data of an organisation.
 */
angular.module('uasApp')
  .component('uasOrganisationAdmin', {
    bindings: {
      organisation: '<',
      operations: '<'
    },
    controllerAs: 'organisationAdminController',
    templateUrl: 'es6/organisation/organisation.admin.html',
    controller: function (Organisation, CustomField, CustomPage, Message, EntityType, $state, $q, FeedbackService) {
      const organisationAdminController = this;

      organisationAdminController.$onInit = function() {
        organisationAdminController.organisation_ = angular.copy(organisationAdminController.organisation);
        loadData();
      };

      function loadData() {
        organisationAdminController.loading = true;

        $q.all([
          CustomPage.query({
            basePath: 'organisation.admin'
          }).$promise,
          CustomField.query({
            rootType: 'organisation',
            entityType: 'organisation'
          }).$promise,
          EntityType.get({
            rootType: 'organisation',
            entityType: 'organisation'
          }).$promise
        ]).then(([pages, fields, type]) => {
          organisationAdminController.pages = pages;
          organisationAdminController.fields = _.keyBy(fields, 'name');
          organisationAdminController.type = type;

          _.forEach(organisationAdminController.fields, (field) => {
            if (organisationAdminController.isRemoved()) {
              field.readOnly = true;
            }
          });
        }).finally(() => {
          organisationAdminController.loading = false;
        });
      }

      organisationAdminController.isRemoved = function () {
        return organisationAdminController.organisation_.changeType === 'REMOVE';
      };

      organisationAdminController.loadOrganisation = function () {
        organisationAdminController.adminForm.$setPristine();
        $state.reload();
      };

      organisationAdminController.save = function (organisation, comment) {
        return Organisation.update({
          comment: comment,
          content: organisation
        }).$promise.then((result) => {
          organisationAdminController.adminForm.$setPristine();
          FeedbackService.onUpdate(organisationAdminController.organisation_);
          return result;
        });
      };

      organisationAdminController.enableRestore = function (form) {
        if (organisationAdminController.isRemoved()) {
          form.$setDirty();
        }
      };

      organisationAdminController.restore = function (organisation, comment) {
        delete organisation.changeType;
        return organisationAdminController.save(organisation, comment).then(() => {
          $state.reload();
        });
      };

      organisationAdminController.deleteFull = function (organisation) {
        Organisation.deleteFull({
          id: organisation.id
        }).$promise.then(() => {
          Message.onRemoved();
  
          organisationAdminController.adminForm.$setPristine();
          $state.go('dashboard');
        });
      };

      organisationAdminController.delete = function (organisation, comment) {
        return Organisation.remove({
          id: organisation.id,
          comment
        }).$promise.then(() => {
          Message.onRemoved();
          $state.reload();
        });
      };
    }
  });
