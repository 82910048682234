'use strict';

angular.module('uasApp')
    .component('uasDownloadReport', {
        bindings: {
            context: '@',
            entityId: '=?',
            buttonSize: '@?',
            dropdownClass: '@?',
            toggleClass: '@?',
            operations: '=',
            arguments: '&?',
            tooltipLabel: '@?'
        },
        templateUrl: 'es6/report/download.report.html',
        controllerAs: 'downloadReportController',
        controller: function ($http, $scope, Language, Parameter, SecurityService, AuthService, translateFilter, $window, $uibModal) {
            const downloadReportController = this;

            downloadReportController.$onInit = function () {
                downloadReportController.reports = [];

                downloadReportController.buttonClass = `${downloadReportController.toggleClass} btn-${downloadReportController.buttonSize || 'md'}`;
                Parameter.load().then(() => loadReports());
                $scope.$watch('downloadReportController.operations', updateVisibility);
            };

            function loadReports() {
                const url = Parameter.getParameterValue('report.url');
                downloadReportController.url = url;
                if (!_.isEmpty(url)) {
                    $http.get(url + '?context=' + downloadReportController.context).then(function (response) {
                        downloadReportController.reports = initialize(response.data);
                        updateVisibility();
                    });
                }
            }

            function initialize(reports) {
                reports = reports || [];
                const initializedReports = [];

                // Normally, this would have been a FlatMap. However, FlatMap only works with Lodash 4.0+, and we don't have Lodash 4.0 yet.
                // Updating to 4.0 is not an easy task, as may functions were changed or removed in that version.
                // Therefore, use a regular for loop and a new array to insert the various report formats in it.
                for (let i = 0; i < reports.length; i++) {
                    const report = reports[i];

                    // If the report specifies formats, process them. If not, leave the entry as-is.
                    if (report.formats && report.formats.length) {

                        for (let formatIndex = 0; formatIndex < report.formats.length; formatIndex++) {
                            const format = report.formats[formatIndex];
                            const formatLabel = `Static.Report.Format.${format}`;

                            const newReport = angular.copy(report);
                            newReport.originalName = angular.copy(newReport.name);
                            newReport.name += ` (${translateFilter(formatLabel)})`;
                            newReport.format = format;
                            delete newReport.formats;

                            initializedReports.push(newReport);
                        }
                    } else {
                        initializedReports.push(report);
                    }
                }

                return _.sortBy(initializedReports, ['name']);
            }

            function updateVisibility() {
                _.each(downloadReportController.reports, function (report) {
                    report.visible = SecurityService.isAllowed(report.operation, downloadReportController.operations);
                });
            }

            downloadReportController.execute = function (report) {
                getURL(report, downloadReportController.arguments).then((url) => {
                    if (report.format === 'ONSCREEN') {
                        downloadReportController.executeOnScreen(report, url);
                    } else {
                        downloadReportController.executeUrl(url);
                    }
                });
            };

            downloadReportController.executeOnScreen = function (report, url) {
                // The on-screen report is opened inside a report modal. This allows placing this report component anywhere within the application.
                $uibModal.open({
                    controllerAs: 'modalController',
                    controller: function ($uibModalInstance) {
                        const modalController = this;
                        modalController.modal = $uibModalInstance;
                        modalController.reportRequest = angular.copy(report);
                        modalController.reportRequest.url = angular.copy(url);
                    },
                    size: 'lg',
                    template: '<uas-report-modal requested-report="modalController.reportRequest" modal="modalController.modal"></uas-report-modal>'
                });
            };

            downloadReportController.executeUrl = function (url) {
                // The Excel sheet is downloaded simply by opening a new tab with the URL of the report.
                $window.open(url);
            };

            function getURL(report, decorator) {
                return AuthService.token().then((token) => {
                    let url = downloadReportController.url + '/' + report.id;

                    if (downloadReportController.entityId) {
                        url += ('/' + downloadReportController.entityId);
                    }

                    if (report.format) {
                        url += ('/' + report.format);
                    }

                    url += ('?token=' + token + '&language=' + Language.get() + '&year=' + sessionStorage.academicYear);

                    if (angular.isDefined(decorator)) {
                        url = decorator({ url });
                    }

                    return url;
                });
            }
        }
    });
