'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityListPage
 * @description
 * The activityListPage component.
 */
angular.module('uasApp').component('activityListPage', {
    templateUrl: 'es6/schedule/activity/list/activity.list.page.html',
    bindings: {
        entity: '<',
        period: '<',
        operations: '<',
        page: '<',
        workflow: '<',
        workflowMode: '<?',
        isReadOnly: '<?',
        template: '<?',
        category: '<?',
        showCategory: '<?',
        hidePeriodHeader: '<?',
        onChange: '&?'
    },
    controllerAs: 'activityListPageController',
    controller: function (Page, EntityService, Offering, WorkflowValidator) {
        const activityListPageController = this;

        activityListPageController.$onInit = function() {
            activityListPageController.readOnly = activityListPageController.isReadOnly || Page.getParameterAsBoolean(activityListPageController.page, 'readOnly', false);
            
            activityListPageController.categoryId = _.get(activityListPageController.category, 'id');
            if (angular.isUndefined(activityListPageController.categoryId)) {
                activityListPageController.categoryId = Page.getParameterAsInt(activityListPageController.page, 'category');
            }
            
            activityListPageController.filterOnCategory = Page.getParameterAsBoolean(activityListPageController.page, 'filterOnCategory', false);
            activityListPageController.disableType = Page.getParameterAsBoolean(activityListPageController.page, 'disableType', false);

            activityListPageController.entityType = EntityService.getType(activityListPageController.entity);
            activityListPageController.columns = Page.getParameterAsArray(activityListPageController.page, 'columns');

            const modulePath = activityListPageController.entityType === 'module' ? 'id' : 'moduleId';
            activityListPageController.moduleId = _.result(activityListPageController.entity, modulePath);

            if (angular.isDefined(activityListPageController.moduleId)) {
                loadPeriods(activityListPageController.moduleId);
            }

            if (!activityListPageController.readOnly) {
                WorkflowValidator.setValidity(() => activityListPageController.valid);
            }
        };

        activityListPageController.$onDestroy = function () {
            WorkflowValidator.reset();
        };

        function loadPeriods(moduleId) {
            activityListPageController.loading = true;
            Offering.periods({
                entityType: 'module',
                entityId: moduleId,
                periodId: _.get(activityListPageController.period, 'id')
            }).$promise.then((periods) => {
                activityListPageController.periods = _.sortBy(periods, ['startDate', 'endDate', 'code']);
                validate();
            }).finally(() => {
                activityListPageController.loading = false;
            });
        }

        activityListPageController.setValidity = function(period, valid) {
            period.valid = valid;
            validate();
        };

        function validate() {
            activityListPageController.valid = _.every(activityListPageController.periods, { valid: true });
        }
    }
});
