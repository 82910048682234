'use strict';

angular.module('uasApp').component('maintenance', {
  templateUrl: 'es6/app/maintenance/maintenance.html',
  controllerAs: 'maintenanceController',
  controller: function(AuthService, maintenanceState) {

    const maintenanceController = this;
    maintenanceController.$onInit = function() {
      maintenanceController.authenticated = AuthService.isAuthenticated();
      maintenanceController.state = maintenanceState.getState();
    };

  }
});
