'use strict';

angular.module('uasApp').component('deliveryList', {
  templateUrl: 'es6/inventory/delivery/delivery.list.html',
  controllerAs: 'deliveryListController',
  controller: function (Delivery, Reference, Pageable, $q) {
    const deliveryListController = this;

    deliveryListController.$onInit = function() {
        deliveryListController.pageable = Pageable.of({
            order: 'dispatchDate'
        });

        deliveryListController.parameters = {
            dateType: 'DISPATCH',
            fromDate: new Date()
        };

        deliveryListController.dateTypes = [
            { value: 'DISPATCH', label: 'Static.Page.Deliveries.DispatchDate' },
            { value: 'USE', label: 'Static.Page.Deliveries.UseDate' },
            { value: 'PACKING', label: 'Static.Page.Deliveries.PackingDate' }
        ];

        loadData();
        deliveryListController.search();
    };

    function loadData() {
        deliveryListController.loading = true;
        $q.all([
            Reference.query({
                typeCode: 'RECIPIENT'
            }).$promise,
            Reference.query({
                typeCode: 'RESOURCE_TYPE'
            }).$promise
        ]).then(([recipients, types]) => {
            deliveryListController.recipients = recipients;
            deliveryListController.types = types;
        }).finally(() => {
            deliveryListController.loading = false;
        });
    }

    deliveryListController.onEntity = function(entity) {
        deliveryListController.parameters.entityType = _.get(entity, 'self.type');
        deliveryListController.parameters.entityId = _.get(entity, 'id');
    };

    deliveryListController.onType = function() {
        delete deliveryListController.parameters.versionId;
    };

    deliveryListController.search = function() {
        const params = deliveryListController.pageable.build(
            deliveryListController.parameters
        );

        deliveryListController.searching = true;
        Delivery.search(params).$promise.then((deliveries) => {
            deliveryListController.deliveries = deliveries;
        }).finally(() => {
            deliveryListController.searching = false;
        });
    };
  }
});
