'use strict';

angular.module('uasApp')
    .directive('requiredLabel', function() {
        return {
            restrict: 'E',
            templateUrl: 'es6/app/forms/validation/required.label.html',
            scope: {
                ngModel: '=?',
                viewClasses: '<?'
            },
            controller: function($scope) {
              $scope.isCorrect = function() {
                let correct = true;
                let model = $scope.ngModel;
                if (model === undefined || model === null || model === '') {
                  correct = false;
                } else if (_.isFunction(model)) {
                  const result = model();
                  correct = !!result;
                }
                return correct;
              };
            }
        };
    });
