'use strict';

angular.module('uasApp')
       .component('uasMessages', {
            templateUrl: 'es6/admin/messages/messages.html',
            controllerAs: 'messagesController',
            controller: function(Messages, Pageable) {
                const messagesController = this;

                messagesController.$onInit = function() {
                    messagesController.pageable = Pageable.of({
                        order: 'creationDate',
                        reverse: true
                    });
                    
                    loadData();
                };

                function loadData() {
                    const params = messagesController.pageable.build();

                    messagesController.loading = true;
                    Messages.page(params).$promise.then((messages) => {
                        messagesController.messages = messages;
                    }).finally(() => {
                        messagesController.loading = false;
                    });
                }

                messagesController.onChange = function() {
                    loadData();
                };
            }
        });
