'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleResources
 * @description
 * Displays module resource information
 */
angular.module('uasApp').component('uasModuleResources', {
  bindings: {
    moduleId: '<',
    operations: '<',
    operationsToEdit: '@?',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/module/module.resources.html',
  controllerAs: 'moduleResourcesController',
  controller: function ($uibModal, $q, Offering, ModuleResource, TimeBlock, Element, Message,
    feedbackObserver, AuthService) {
    const moduleResourcesController = this;

    moduleResourcesController.$onInit = function() {
      moduleResourcesController.readOnly = moduleResourcesController.isReadOnly === true;
      loadData();
    };

    function loadData() {
      moduleResourcesController.loading = true;
      $q.all([
        Offering.calendars({
          entityType: 'module',
          entityId: moduleResourcesController.moduleId
        }).$promise,
        ModuleResource.query({
          moduleId: moduleResourcesController.moduleId
        }).$promise,
        Element.getActive('SOFTWARE')
      ]).then(([calendars, resources, types]) => {
        moduleResourcesController.periods = getPeriods(calendars);
        moduleResourcesController.resources = resources;
        moduleResourcesController.types = types;

        _.forEach(resources, (resource) => {
          resource.type = _.find(moduleResourcesController.types, {
            id: resource.typeId
          });
        });

        initTypes();

        moduleResourcesController.operationsToEdit_ = AuthService.buildOperationsToEdit(
          moduleResourcesController.operationsToEdit,
          ['EDIT_RESOURCES', 'EDIT_RESOURCES_WORKFLOW']
        );
      }).finally(() => {
        moduleResourcesController.loading = false;
        AuthService.resecureAll();
      });
    }

    function getUniqueResourceTypes(resources, types) {
      return _(resources)
        .map('typeId')
        .uniq()
        .map((typeId) => _.find(types, { id: typeId }))
        .filter(angular.isDefined)
        .value();
    }

    function getPeriods(calendars) {
      return _(calendars)
        .map('periods')
        .flatten()
        .filter({ active: true })
        .map('period')
        .filter(angular.isDefined)
        .uniq()
        .sort()
        .map((period) => { return { period }; })
        .value();
    }

    moduleResourcesController.checkAllPeriods = function(periods) {
      _.forEach(periods, (period) => {
        period.selected = true;
        period.dirty = true;
      });
    };

    moduleResourcesController.togglePeriod = function(period) {
      period.selected = !period.selected;
      period.dirty = true;
    };

    moduleResourcesController.save = function(resources, comment) {
      const touched = _.filter(resources, { dirty: true });

      const command = {
        comment: comment,
        contents: _(touched)
          .filter((resource) => resource.changeType !== 'REMOVE')
          .map(buildModel)
          .value(),
        removes: _(touched)
          .filter({ changeType: 'REMOVE' })
          .map('id')
          .value()
      };

      return ModuleResource.save(command).$promise.then(() => {
        Message.addSuccessLabel('Static.Message.DataAdjusted');
        feedbackObserver.dataChanged();
        loadData();
      });
    };

    function buildModel(resource) {
      return {
        id: resource.id,
        moduleId: resource.moduleId,
        academicYearId: sessionStorage.academicYear,
        typeId: resource.typeId,
        period: resource.period
      };
    }


    moduleResourcesController.remove = function(type) {
      const resourcesByType = _.filter(moduleResourcesController.resources, {
        typeId: type.id
      });
      _.forEach(resourcesByType, (resource) => {
        resource.changeType = 'REMOVE';
        resource.dirty = true;
      });

      updateDirty();
    };

    function initTypes() {
      moduleResourcesController.uniqueResourceTypes = getUniqueResourceTypes(
        moduleResourcesController.resources,
        moduleResourcesController.types
      );
      moduleResourcesController.typesAvailable =
        moduleResourcesController.uniqueResourceTypes.length <
        moduleResourcesController.types.length;
    }

    function updateDirty() {
      const dirty = _.some(moduleResourcesController.resources, {
        dirty: true
      });
      if (moduleResourcesController.resourcesForm) {
        if (dirty) {
          moduleResourcesController.resourcesForm.$setDirty();
        } else {
          moduleResourcesController.resourcesForm.$setPristine();
        }
      }
    }

    moduleResourcesController.create = function() {
      $uibModal.open({
        templateUrl: 'es6/module/module.resources.create.modal.html',
        controllerAs: 'moduleResourceCreateController',
        controller: function($uibModalInstance) {
          const moduleResourceCreateController = this;

          moduleResourceCreateController.save = function() {
            const periods = _.filter(moduleResourceCreateController.periods, { selected: true });
            _.forEach(periods, (period) => {
              const resource = angular.copy(moduleResourceCreateController.resource);
              const type = _.find(moduleResourcesController.types, {
                id: resource.typeId
              });

              if (angular.isDefined(type)) {
                resource.type = type;
                resource.period = period.period;
                moduleResourcesController.resources.push(resource);
              }
            });

            updateDirty();
            initTypes();
            moduleResourceCreateController.close();
          };

          moduleResourceCreateController.onType = function() {
            moduleResourceCreateController.type = _.find(moduleResourcesController.types, {
              id: moduleResourceCreateController.resource.typeId
            });
          };

          moduleResourceCreateController.checkAllPeriods = function() {
            moduleResourcesController.checkAllPeriods(
              moduleResourceCreateController.periods
            );
          };

          moduleResourceCreateController.hasAnyPeriods = function() {
            return _.some(moduleResourceCreateController.periods, {
              selected: true
            });
          };

          moduleResourceCreateController.togglePeriod = function(period) {
            moduleResourcesController.togglePeriod(period);
          };

          moduleResourceCreateController.$onInit = function() {
            moduleResourceCreateController.close = $uibModalInstance.close;
            moduleResourceCreateController.resource = {
              moduleId: moduleResourcesController.moduleId,
              changeType: 'CREATE',
              created: true,
              dirty: true
            };
            moduleResourceCreateController.selectedResourceIds = _.map(moduleResourcesController.uniqueResourceTypes, (resource) => resource.id);

            moduleResourceCreateController.periods = _.map(moduleResourcesController.periods, (period) => {
              const result = angular.copy(period);
              return _.extend(result, { selected: false });
            });

            moduleResourceCreateController.types = _.filter(moduleResourcesController.types, isUnused);
          };

          function isUnused(element) {
            return !_.some(moduleResourcesController.uniqueResourceTypes, { id: element.id });
          }
        }
      });
    };

    moduleResourcesController.edit = function(type) {
      $uibModal.open({
        templateUrl: 'es6/module/module.resources.edit.modal.html',
        controllerAs: 'moduleResourceEditController',
        controller: function($uibModalInstance) {
          const moduleResourceEditController = this;

          moduleResourceEditController.save = function() {
            const periods = angular.copy(moduleResourceEditController.periods);

            _.forEach(periods, (period) => {
              const resource = angular.copy(
                moduleResourceEditController.resource
              );

              const existing = _.find(moduleResourcesController.resources, {
                typeId: moduleResourceEditController.type.id,
                period: period.period
              });

              if (angular.isUndefined(existing) && period.selected) {
                moduleResourcesController.resources.push(
                  _.extend(resource, {
                    typeId: type.id,
                    type: moduleResourceEditController.type,
                    period: period.period
                  })
                );
              } else if (angular.isDefined(existing) && period.dirty) {
                existing.changeType = period.selected ? 'CREATE' : 'REMOVE';
                existing.dirty = true;
              }
            });

            updateDirty();
            initTypes();
            moduleResourceEditController.close();
          };

          moduleResourceEditController.checkAllPeriods = function() {
            moduleResourcesController.checkAllPeriods(
              moduleResourceEditController.periods
            );
          };

          moduleResourceEditController.togglePeriod = function(period) {
            moduleResourcesController.togglePeriod(period);
          };

          moduleResourceEditController.$onInit = function() {
            moduleResourceEditController.close = $uibModalInstance.close;
            moduleResourceEditController.type = type;
            moduleResourceEditController.resource = {
              moduleId: moduleResourcesController.moduleId,
              changeType: 'CREATE',
              created: true,
              dirty: true
            };

            moduleResourceEditController.periods = _.map(moduleResourcesController.periods, (period) => {
              const selected = isSelected(period);
              const result = angular.copy(period);
              return _.extend(result, { selected });
            });
          };

          function isSelected(period) {
            let selected = false;

            let resource = _.find(moduleResourcesController.resources, {
              period: period.period,
              typeId: moduleResourceEditController.type.id
            });

            if (angular.isDefined(resource)) {
              selected = resource.changeType !== 'REMOVE';
            }

            return selected;
          }
        }
      });
    };
  }
});
