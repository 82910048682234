'use strict';

angular.module('uasApp').component('subjectMatrixPage', {
  templateUrl: 'es6/subjects/matrix/subject.matrix.page.html',
  bindings: {
    entity: '<',
    page: '<',
    operations: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'subjectMatrixPageController',
  controller: function ($q, Column, Page) {
    const subjectMatrixPageController = this;
    const MAX_COLUMNS = 3;

    subjectMatrixPageController.$onInit = function () {
      subjectMatrixPageController.readOnly = subjectMatrixPageController.isReadOnly || Page.getParameterAsBoolean(subjectMatrixPageController.page, 'readOnly', false);
      subjectMatrixPageController.typeId = Page.getParameterAsInt(subjectMatrixPageController.page, 'type');
      subjectMatrixPageController.showPhase = Page.getParameterAsBoolean(subjectMatrixPageController.page, 'showPhase', false);
      subjectMatrixPageController.showPeriods = Page.getParameterAsBoolean(subjectMatrixPageController.page, 'showPeriods', false);
      subjectMatrixPageController.showStructure = Page.getParameterAsBoolean(subjectMatrixPageController.page, 'showStructure', false);
      subjectMatrixPageController.stickyHeaders = Page.getParameterAsBoolean(subjectMatrixPageController.page, 'stickyHeaders', false);
      subjectMatrixPageController.expandFilters = Page.getParameterAsBoolean(subjectMatrixPageController.page, 'expandFilters', false);
      subjectMatrixPageController.displayType = Page.getParameter(subjectMatrixPageController.page, 'displayType') || 'scales';

      loadData();
    };

    function loadData() {
      subjectMatrixPageController.loading = true;

      $q.all([
        getColumns('module-group', 'groupColumns'),
        getColumns('module', 'moduleColumns')
      ]).then(([groupColumns, moduleColumns]) => {
        subjectMatrixPageController.groupColumns = groupColumns;
        subjectMatrixPageController.moduleColumns = moduleColumns;
      }).finally(() => {
        subjectMatrixPageController.loading = false;
      });
    }

    function getColumns(type, parameter) {
      const columns = Page.getParameterAsArray(subjectMatrixPageController.page, parameter);
      return Column.fromType(type, columns, MAX_COLUMNS);
    }
  }
});
