'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:BreadcrumbsService
 * @description
 * The BreadcrumbsService service.
 */
angular.module('uasApp')
    .factory('BreadcrumbsService', function($state, $stateParams) {
        const breadcrumbService = {};
        let breadcrumbs = [];

        function getRootName(name) {
            return name.substring(0, name.lastIndexOf('.'));
        }

        function getUrl(state) {
            return $state.href(state, $stateParams || {});
        }

        function addBreadcrumbObj(state) {
            const root = state.name.indexOf('faculties') === 0 ? state.name : getRootName(state.name);
            let rootIndex = 0;
            const exists = _.some(breadcrumbs, function(breadcrumb, $index) {
                if (breadcrumb.root === root) {
                    rootIndex = $index;
                    return true;
                }
                return false;
            });

            const rootState = $state.get(root);
            const rootCrumb = _.result(rootState, 'breadcrumb') || {};

            //if the current state is a subview and the root state is not in our list of breadcrumbs yet
            if (root !== '' && !exists && root.indexOf('.') === -1) {
                breadcrumbs.push({
                    name: state.name,
                    root: root,
                    label: rootCrumb.label,
                    originalLabel: rootCrumb.label,
                    data: _.result(rootState, 'data'),
                    url: getUrl(state)
                });
            } else if (!exists) {
                //if we are not a subview, simply add the state
                breadcrumbs.push({
                    name: state.name,
                    root: root,
                    label: state.breadcrumb.label,
                    originalLabel: state.breadcrumb.label,
                    data: state.data,
                    url: getUrl(state)
                });
            } else if (exists) {
                //if the root does exist, delete everything after the existing root
                breadcrumbs.splice(rootIndex + 1, Math.max(1, breadcrumbs.length - (rootIndex + 1)));
                breadcrumbs[rootIndex] = {
                    name: state.name,
                    root: root,
                    label: _.result(rootCrumb, 'label'),
                    originalLabel: _.result(rootCrumb, 'label'),
                    data: _.result(rootState, 'data'),
                    url: getUrl(state)
                };
            }
        }

        function addParentsFor(state) {
            var parentState = $state.get(state.breadcrumb.parent);
            var rootFound = false;

            while (parentState && !rootFound) {
                addBreadcrumbObj(state);
                state = $state.get(state.breadcrumb.parent);
                rootFound = state.breadcrumb.root;
            }
        }

        breadcrumbService.getBreadcrumbs = function() {
            return breadcrumbs;
        };

        breadcrumbService.addBreadcrumb = function(toState) {
            if (toState.breadcrumb) {
                if (toState.breadcrumb.root) {
                    breadcrumbs = [];
                }

                if (toState.breadcrumb && !toState.breadcrumb.skip) {
                    addBreadcrumbObj(toState);
                    if (toState.breadcrumb.parent) {
                        addParentsFor(toState);
                    }

                }
            }
        };

        return breadcrumbService;
    });
