'use strict';

angular.module('uasApp').component('mailList', {

    templateUrl: 'es6/admin/mail/mail.list.html',
    controllerAs: 'mailListController',
    controller: function($uibModal, $q, Pageable, Mail) {

        const mailListController = this;

        mailListController.$onInit = function () {
            mailListController.pageable = Pageable.of({
                order: 'creationDate',
                reverse: true
            });

            mailListController.search();
        };

        mailListController.search = function() {
            const params = mailListController.pageable.build({
                searchTerms: mailListController.searchText,
                date: mailListController.date
            });

            mailListController.loading = true;
            $q.all([
                Mail.get(params).$promise,
                Mail.statistics().$promise
            ]).then(([mails, statistics]) => {
                mailListController.mails = mails;
                mailListController.statistics = statistics;
            }).finally(function() {
                mailListController.loading = false;
            });
        };

        mailListController.retry = function(mail) {
            Mail.retry({
                id: mail.id
            }, {}).$promise.then((result) => {
                mail.sendDate = result.sendDate;
                mail.success = result.success;
                mail.errorMessage = result.errorMessage;
            });
        };

        mailListController.showDetails = function (mail) {
            $uibModal.open({
                templateUrl: 'es6/admin/mail/mail.details.html',
                controllerAs: 'mailDetailsController',
                controller: function ($sce, $uibModalInstance) {
                    const mailDetailsController = this;

                    mailDetailsController.retry = function () {
                        mailListController.retry(mailDetailsController.mail);
                        mailDetailsController.close();
                    };

                    mailDetailsController.close = function() {
                        $uibModalInstance.dismiss();
                    };

                    mailDetailsController.$onInit = function () {
                        mailDetailsController.mail = mail;
                        mailDetailsController.text = $sce.trustAsHtml(mail.text);
                    };
                }
            });
        };

    }
});
