'use strict';

angular.module('uasApp').component('subjectTypeEdit', {
    templateUrl: 'es6/subjects/type/subject.type.edit.html',
    bindings: {
        version: '<',
        fields: '<',
        onChange: '&',
        onCancel: '&'
    },
    controllerAs: 'subjectTypeEditController',
    controller: function (SubjectVersion, SubjectType, SubjectCategory, Message) {
        const subjectTypeEditController = this;

        subjectTypeEditController.$onInit = function() {
            subjectTypeEditController.categories = SubjectCategory.query();
            loadVersions();
        };

        function loadVersions() {
            const typeId = _.result(subjectTypeEditController.version, 'type.id');
            if (angular.isDefined(typeId)) {
                SubjectVersion.query({ typeId }).$promise.then((versions) => {
                    subjectTypeEditController.versions = versions;
                });
            } else {
                subjectTypeEditController.versions = [];
            }
        }

        subjectTypeEditController.getTypes = function(text) {
            return SubjectType.search({
                categoryId: subjectTypeEditController.version.type.category,
                text
            }).$promise.then((page) => page.content);
        };

        subjectTypeEditController.onType = function(type) {
            subjectTypeEditController.version.type = angular.copy(type);
            loadVersions();
        };

        subjectTypeEditController.onVersion = function(version) {
            subjectTypeEditController.version = angular.copy(version);
        };

        subjectTypeEditController.create = function() {
            const version = _.extend(subjectTypeEditController.version, {
                id: undefined,
                descriptions: [],
                startDate: undefined,
                endDate: undefined
            });

            subjectTypeEditController.onVersion(version);
        };

        subjectTypeEditController.remove = function(version) {
            SubjectVersion.remove(version).$promise.then(() => {
                subjectTypeEditController.onChange();
                Message.onRemoved();
            });
        };

        subjectTypeEditController.cancel = function() {
            subjectTypeEditController.onCancel();
        };

        subjectTypeEditController.save = function(version) {
            SubjectVersion.store(version).$promise.then(() => {
                subjectTypeEditController.onChange();
                Message.onSaved();
            });
        };
    }
});
