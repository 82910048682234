'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasVideoList
 * @description Displays the videos.
 */
angular.module('uasApp').component('uasVideoList', {
  templateUrl: 'es6/app/instruction/video.list.html',
  controllerAs: 'videoListController',
  controller: function ($uibModal, Message, Video) {
    const videoListController = this;

    videoListController.$onInit = function () {
      loadData();
    };

    function loadData() {
      videoListController.loading = true;

      Video.query().$promise.then((videos) => {
        videoListController.videos = videos;
      }).finally(() => {
        videoListController.loading = false;
      });
    }

    videoListController.create = function () {
      open({ languages: [] });
    };

    videoListController.edit = function (video) {
      open(video);
    };

    function open(video) {
      $uibModal.open({
        size: 'lg',
        template: `
          <uas-video-modal
            video="videoModalController.video"
            on-save="videoModalController.onSave()"
            on-close="videoModalController.onClose()">
          </uas-video-modal>`,
        controllerAs: 'videoModalController',
        controller: function ($uibModalInstance) {
          const videoModalController = this;

          videoModalController.$onInit = function () {
            videoModalController.video = angular.copy(video);
          };

          videoModalController.onSave = function () {
            loadData();
            $uibModalInstance.close();
          };

          videoModalController.onClose = function () {
            $uibModalInstance.close();
          };
        }
      });
    }

    videoListController.remove = function (video) {
      Video.remove({
        id: video.id
      }).$promise.then(() => {
        Message.onRemoved();
        loadData();
      });
    };
  }
});
