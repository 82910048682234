'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:CostDivision
 * @description
 * The CostDivision service.
 */
angular.module('uasApp')
  .factory('CostDivision', function (UasResource) {
    return UasResource('/cost-divisions', {
      'saveAll': {
        method: 'POST',
        url: '/cost-divisions/batch-update',
        isArray: false
      },
      'generate': {
        method: 'POST',
        url: '/cost-divisions/generate',
        isArray: false
      }
    });
  });