'use strict';

angular.module('uasApp').component('offeringFields', {
    bindings: {
        offering: '<',
        fields: '<',
        evaluation: '<',
        operations: '<',
        operationsToEdit: '<',
        onChange: '&'
    },
    templateUrl: 'es6/offerings/offering.fields.html',
    controllerAs: 'offeringFieldsController',
    controller: function(CustomField) {
        const offeringFieldsController = this;

        const EXTENSIONS = [
            { name: 'periodId', visible: false },
            { name: 'blockIds', visible: false },
            { name: 'partOfDayId', visible: false },
            { name: 'moduleGroupId', visible: false },
            { name: 'startDate', maxValue: 'endDate' },
            { name: 'endDate', minValue: 'startDate' },
            { name: 'registrationStartDate', maxValue: 'registrationEndDate' },
            { name: 'registrationEndDate', minValue: 'registrationStartDate' },
            { name: 'optimumCapacity', minValue: 'minimumCapacity', maxValue: 'maximumCapacity' },
            { name: 'minimumCapacity', maxValue: 'maximumCapacity' },
            { name: 'maximumCapacity', minValue: 'minimumCapacity' }
        ];

        offeringFieldsController.$onInit = function() {
            offeringFieldsController.fields_ = CustomField.extend(offeringFieldsController.fields, EXTENSIONS);
        };
    }
});