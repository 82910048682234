'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Column
 * @description Factory to get custom fields for usage as columns.
 */
angular.module('uasApp').factory('Column', function (CustomField) {

  /**
   * Filter fields by column names. If no columns are specified select visible fields where displayOnGeneral is selected.
   * @param {Array} fields - Fields to filter.
   * @param {Array} columnNames - Column names to filter by (e.g. ['code', 'localName']).
   * @param {number} [max] - Maximum number of fields. All if not specified.
   * @param {Array} [defaultColumns] - Default columns, shown when no columns defined and no fields are visible.
   * @returns {Object[]} Filtered fields, or visible fields with displayOnGeneral = true if no column names are specified.
   */
  function fromFields(fields, columnNames, max, defaultColumns) {
    const sortedFields = _.sortBy(fields, ['sequence', 'name']);

    if (_.isEmpty(columnNames)) {
      const displayFields = _(sortedFields).filter({ displayOnGeneral: true, visible: true }).map('name').value();
      columnNames = _.isEmpty(displayFields) ? defaultColumns : displayFields;
    }

    return _(sortedFields)
      .filter((field) => _.includes(columnNames, field.name))
      .filter({ visible: true })
      .sortBy((field) => _.isEmpty(columnNames) ? 1 : _.indexOf(columnNames, field.name))
      .take(angular.isDefined(max) ? max : fields.length)
      .value();
  }

  /**
   * Retrieve fields for type and filter fields by column names.
   * @param {String} type - The custom type (e.g. 'module' or 'study')
   * @param {Array} columns - Column names to filter by (e.g. ['code', 'localName']).
   * @param {number} [max] - Maximum number of fields. All if not specified.
   * @returns {Object[]} Filtered fields, or empty when no column names are specified.
   */
  function fromType(type, columns, max) {
    return CustomField.query({
      rootType: type,
      entityType: [type, 'additional']
    }).$promise.then((fields) => {
      return _(fields)
        .filter((field) => _.includes(columns, field.name))
        .map((field) => _.assign(field, { label: CustomField.getLabel(field) }))
        .sortBy((field) => _.indexOf(columns, field.name))
        .take(angular.isDefined(max) ? max : columns.length)
        .value();
    });
  }

  return {
    fromFields,
    fromType
  };
});
