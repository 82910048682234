'use strict';

import Editor from '@toast-ui/editor';
import '../../../node_modules/@toast-ui/editor/dist/esm/i18n/nl-nl';

angular.module('uasApp').component('toastuiEditor', {
  bindings: {
    editorId: '@?',
    language: '@?',
    getButtons: '&?', // Callback that return list of buttons in promise
    isRequired: '<?',
    isDisabled: '<?',
    maxCharacters: '<?',
    countPlainText: '<?',
    height: '@?',
    isModal: '<?' // Do not show button to open modal if already in a modal
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/toastui.editor.html',
  controllerAs: 'toastuiEditorController',
  controller: function ($compile, $element, $q, $scope, $timeout, $uibModal, Changes, Language) {
    const toastuiEditorController = this;

    const SUPPORTED_BUTTONS = ['heading', 'bold', 'italic', 'strike', 'hr', 'quote', 'ul', 'ol', 'task',
      'indent', 'outdent', 'table', 'image', 'link', 'code', 'codeblock', 'scrollSync'];

    const DEFAULT_OPTIONS = {
      autofocus: false,
      minHeight: '242px',
      height: 'auto',
      initialEditType: 'wysiwyg',
      frontMatter: true,
      previewStyle: 'tab',
      usageStatistics: false,
      customMarkdownRenderer: {
        strong: () => {
          return {
            rawHTML: '**'
          };
        },
        emph: () => {
          return {
            rawHTML: '*'
          };
        },
        strike: () => {
          return {
            rawHTML: '~~'
          };
        }
      }
    };

    toastuiEditorController.$onInit = function () {
      toastuiEditorController.disabled = toastuiEditorController.isDisabled === true;
      toastuiEditorController.id = toastuiEditorController.editorId || _.uniqueId('toastui-editor-');
      toastuiEditorController.active = false;

      $timeout(() => {
        getToolbarItems().then((toolbarItems) => {
          toastuiEditorController.editor = createEditor(toolbarItems);

          const contents = $element.find('.toastui-editor-contents');
          setDisabled(contents);

          const editorDiv = $element.find('.toastui-editor div');
          setDisabled(editorDiv);

          updatePlainText();
        });
      });

      toastuiEditorController.ngModelCtrl.$render = function () {
        toastuiEditorController.model = toastuiEditorController.ngModelCtrl.$modelValue;

        if (toastuiEditorController.editor) {
          toastuiEditorController.editor.setMarkdown(toastuiEditorController.model, false);
          updatePlainText();
        }
      };
    };

    toastuiEditorController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'isDisabled')) {
        if (toastuiEditorController.isDisabled) {
          toastuiEditorController.ngModelCtrl.$setDirty = _.noop;
        }
      }

      if (Changes.hasChanged(changes, 'isRequired')) {
        delete toastuiEditorController.ngModelCtrl.$validators.required;

        if (toastuiEditorController.isRequired) {
          toastuiEditorController.ngModelCtrl.$validators.required = function (modelValue, viewValue) {
            const value = modelValue || viewValue;
            const isEmpty = toastuiEditorController.ngModelCtrl.$isEmpty(value);
            setRequiredClass(isEmpty);

            return !isEmpty;
          };
        }
      }

      if (Changes.hasChanged(changes, 'maxCharacters')) {
        if (angular.isDefined(toastuiEditorController.maxCharacters)) {
          toastuiEditorController.ngModelCtrl.$validators.maxCharacters = function (modelValue, viewValue) {
            const value = toastuiEditorController.countPlainText ? toastuiEditorController.plainText : (modelValue || viewValue);
            return  _.isEmpty(value) || value.length <= toastuiEditorController.maxCharacters;
          };
        }
      }
    };

    function setRequiredClass(isEmpty) {
      const editorContainer = $element.find('.toastui-editor-main-container');
      if (isEmpty) {
        editorContainer.addClass('ng-invalid');
      } else {
        editorContainer.removeClass('ng-invalid');
      }
    }

    function createEditor(toolbarItems) {
      const options = angular.copy(DEFAULT_OPTIONS);
      const element = findElementById(toastuiEditorController.id);

      if (toastuiEditorController.height) {
        options.height = toastuiEditorController.height;
      }

      return new Editor(_.extend(options, {
        el: element,
        initialValue: toastuiEditorController.model,
        events: {
          change: toastuiEditorController.onChange,
          focus: () => {
            toastuiEditorController.active = true;
          },
          blur: () => {
            toastuiEditorController.active = false;
          }
        },
        language: _.lowerCase(Language.get()),
        toolbarItems
      }));
    }

    function findElementById(id) {
      return $element.find(`#${id}`)[0];
    }

    function setDisabled(elements) {
      _.forEach(elements, (element) => {
        if (toastuiEditorController.disabled) {
          element.setAttribute('contenteditable', 'false');
        }
      });
    }

    function getToolbarItems() {
      if (toastuiEditorController.disabled || !_.isFunction(toastuiEditorController.getButtons)) {
        return $q.resolve([]);
      }

      return toastuiEditorController.getButtons().then((buttons) => {
        const items = [];
        if (buttons) {
          items.push(_.filter(buttons, (button) => _.includes(SUPPORTED_BUTTONS, button)));
        }
        if (toastuiEditorController.language) {
          items.unshift([languageItem()]);
        }

        if (!toastuiEditorController.isModal && _.some(buttons, (button) => button === 'popup-editor')) {
          items.push([popupEditorItem()]);
        }

        return items;
      });
    }

    function languageItem() {
      const languageElem = angular.element('<language-flag class="float-left" language="toastuiEditorController.language"></language-flag>');
      return {
        name: 'language',
        el: $compile(languageElem)($scope)[0]
      };
    }

    function updatePlainText() {
      if (toastuiEditorController.countPlainText && angular.isDefined(toastuiEditorController.editor)) {
        toastuiEditorController.plainText = getPlainText();
      }
    }

    function getPlainText() {
      const editor = toastuiEditorController.editor;

      if (editor.isMarkdownMode()) {
        return _.get(editor, 'preview.previewContent.textContent', '').replace(/\n/g, '');
      } else if (editor.isWysiwygMode()) {
        return _.get(editor, 'wwEditor.el.textContent', '');
      }

      return '';
    }

    function popupEditorItem() {
      const popupEditorElem = angular.element(`
        <button
          type="button"
          ng-click="toastuiEditorController.openModal()"
          class="fa fa-expand"
          style="background: transparent"
          uib-tooltip="{{'Static.MarkdownEditor.Tooltip.EditInNewWindow' | translate}}"
          tooltip-placement="bottom"
        ></button>
      `);

      return {
        name: 'popupEditor',
        el: $compile(popupEditorElem)($scope)[0]
      };
    }

    toastuiEditorController.openModal = function() {
      $uibModal.open({
        controllerAs: 'modalController',
        size: 'xl',
        controller: function($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function() {
            modalController.modal = $uibModalInstance;
            toastuiEditorController.active = true;

            const { editorId, language, getButtons, isRequired } = toastuiEditorController;
            modalController.editorId = editorId + '-modal';
            modalController.language = language;
            modalController.getButtons = getButtons;
            modalController.isRequired = isRequired;
            modalController.markdown = toastuiEditorController.ngModelCtrl.$viewValue;
          };

          modalController.onModalSaved = function(markdown) {
            toastuiEditorController.editor.setMarkdown(markdown, false); // Copy markdown from modal editor in to original editor.
            toastuiEditorController.editor.moveCursorToStart();
            toastuiEditorController.onChange();
          };

        },
        template: `
          <markdown-editor-modal
            modal="modalController.modal"
            editor-id="{{ modalController.editorId }}"
            language="{{ modalController.language }}"
            get-buttons="modalController.getButtons()"
            is-required="modalController.isRequired"
            markdown="modalController.markdown"
            on-save="modalController.onModalSaved(markdown)">
          </markdown-editor-modal>`
      });
    };

    toastuiEditorController.onChange = function () {
      if (toastuiEditorController.active) {
        updatePlainText();
        toastuiEditorController.ngModelCtrl.$setViewValue(toastuiEditorController.editor.getMarkdown());
      }
    };
  }
});
