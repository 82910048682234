'use strict';

angular.module('uasApp').component('personReport', {
  bindings: {
    entity: '<',
    operations: '<?'
  },
  templateUrl: 'es6/person/report/person.report.html',
  controllerAs: 'personReportController',
  controller: function (Person, Language, EntityService, SecurityService, WorkflowModal) {
    const personReportController = this;

    personReportController.$onInit = function () {
      setName();
      loadData();

      personReportController.onLanguage = Language.onChange(loadData);
    };

    personReportController.$onChanges = function () {
      personReportController.editable = SecurityService.isAllowed('EDIT_PERSONS', personReportController.operations);
    };

    personReportController.$onDestroy = function () {
      personReportController.onLanguage();
    };

    function loadData() {
      personReportController.loading = true;

      Person.report({
        definition: true
      }).$promise.then((report) => {
        personReportController.columns = report.headers;
        personReportController.groups = report.groups;
      }).finally(() => {
        personReportController.loading = false;
      });
    }

    function setName() {
      personReportController.name = 'persons';
      personReportController.context = 'persons';
      personReportController.entityType = EntityService.getType(personReportController.entity);

      if (angular.isDefined(personReportController.entity)) {
        personReportController.name = `${personReportController.entity.code}-persons`;
        personReportController.context = `${personReportController.entityType}.persons`;
      }
    }

    personReportController.onChange = function () {
      refreshReport();
    };

    function refreshReport() {
      personReportController.reload = {
        reset: true
      };
    }

    personReportController.getRows = function (pageable) {
      const parameters = getParameters(pageable);
      parameters.ignore = false;

      return Person.report(parameters).$promise;
    };

    function getParameters(pageable) {
      const parameters = EntityService.extend(
        {
          language: Language.get()
        },
        personReportController.entity
      );

      return _.extend(pageable, parameters);
    }

    personReportController.edit = function (row) {
      if (personReportController.editable) {
        Person.get({
          id: row.entity.id
        }).$promise.then((person) => {
          WorkflowModal.open({
            entity: person,
            onSave: personReportController.onChange,
            onCancel: personReportController.onChange
          });
        });
      }
    };
  }
});
