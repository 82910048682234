'use strict';

angular.module('uasApp').component('elementTabs', {
  templateUrl: 'es6/element/element.tabs.html',
  controllerAs: 'elementTabsController',
  controller: function () {
    const elementTabsController = this;

    elementTabsController.$onInit = function () {
      elementTabsController.tabs = [{
        title: 'Static.Page.Elements',
        route: 'elementList',
        visible: true
      }, {
        title: 'Static.Page.Elements.Lists',
        route: 'referenceLists',
        visible: true
      }];
    };
  }
});
