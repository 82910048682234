'use strict';

angular.module('uasApp').filter('numberFill', function() {
    return function(value, amount) {
        var result = value ? '' + value : '';
        var numbersToAdd = (amount || 2) - result.length;
        _.times(numbersToAdd, function() {
            result = '0' + result;
        });
        return result;
    };
});
