'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:colorLabel
 * @description Shows a label with custom background and text color.
 */
angular.module('uasApp').component('colorLabel', {
  bindings: {
    label: '@',
    tooltipLabel: '@?',
    color: '<?',
    textColor: '<?',
    squared: '<?',
    small: '<?',
    pulsing: '<?'
  },
  templateUrl: 'es6/app/color/color.label.html',
  controllerAs: 'colorLabelController',
  controller: function () {
    const colorLabelController = this;

    colorLabelController.$onChanges = function () {
      setLabel();
    };

    function setLabel() {
      colorLabelController.trimmedLabel = colorLabelController.squared ? colorLabelController.label.substring(0, 2) : colorLabelController.label;
      colorLabelController.tooltip = _.defaultTo(colorLabelController.tooltipLabel, colorLabelController.label);
    }
  }
});
