'use strict';

angular.module('uasApp')
    .directive('checkForUnsavedChangesWithComment', function () {
        return {
            restrict: 'A',
            bindToController: {
                checkForUnsavedChangesWithComment: '<',
                onSave: '&',
                content: '&',
                entityType: '@',
                rootType: '@',
                operations: '<?',
                secured: '@?'
            },
            controllerAs: 'checkForUnsavedChangesController',
            controller: function ($scope, $state, SecurityService, UnsavedChangesModal) {
                const checkForUnsavedChangesController = this;

                const operations = (angular.isUndefined(checkForUnsavedChangesController.operations) ? [] : checkForUnsavedChangesController.operations);
                const secured = (angular.isUndefined(checkForUnsavedChangesController.secured)) ? '' : checkForUnsavedChangesController.secured;

                checkForUnsavedChangesController.isAllowed = (_.isEmpty(secured)) ? true : SecurityService.isAllowed(secured, operations);

                checkForUnsavedChangesController.$onInit = function() {
                    if (checkForUnsavedChangesController.isAllowed) {
                        $scope.$on('$stateChangeStart', function (event, toState, toParams) {
                            if (toState.ignore === true) {
                                toState.ignore = false;
                                return;
                            }
   
                            // Changes in workflow are automatically saved
                            if (toState.name !== 'workflow') {
                                open(event, toState, toParams);
                            }
                        });
                    }
                };

                function open(event, toState, toParams) {
                    const dirty = _.result(checkForUnsavedChangesController.checkForUnsavedChangesWithComment, '$dirty');
                    if (checkForUnsavedChangesController.checkForUnsavedChangesWithComment === true || dirty) {
                        event.preventDefault();

                        UnsavedChangesModal.open({
                            rootType: checkForUnsavedChangesController.rootType,
                            entityType: checkForUnsavedChangesController.entityType,
                            content: checkForUnsavedChangesController.content(),
                            invalid: _.result(checkForUnsavedChangesController.checkForUnsavedChanges, '$invalid'),
                            onSave: (structure, comment) => checkForUnsavedChangesController.onSave({ structure, comment }),
                            onDiscard: () => {
                                toState.ignore = true;
                                return $state.go(toState, toParams);
                            }
                        });
                    }
                }
            }
        };
    });
