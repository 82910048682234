'use strict';

angular.module('uasApp').component('availabilityLabel', {
    templateUrl: 'es6/staffing/availability/availability.label.html',
    bindings: {
        availability: '<',
        labelSuffix: '<?'
    },
    controllerAs: 'availabilityLabelController',
    controller: function() {
        const availabilityLabelController = this;

        availabilityLabelController.$onChanges = function() {
            availabilityLabelController.total = _.result(availabilityLabelController.availability, 'availableHours', 0);
            availabilityLabelController.assignable = _.result(availabilityLabelController.availability, 'remainingHours', 0);
            availabilityLabelController.percentage = (availabilityLabelController.assignable / availabilityLabelController.total) * 100;
        };
    }
});