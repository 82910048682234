'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Changes
 * @description
 * Factory to check if a one-way component binding has changed.
 */
angular.module('uasApp').factory('Changes', function () {

    function hasChanged(changes, property) {
        let properties = property;
        if (!_.isArray(properties)) {
            properties = [property];
        }
        return _.some(properties, (prop) => {
            const change = changes[prop];
            return change && change.currentValue !== change.previousValue;
        });
    }

    return { hasChanged };
});