'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:studyTypeSelect
 * @description
 */
angular.module('uasApp').component('studyTypeSelect', {
  bindings: {
    categoryFirst: '<?',
    field: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/study/type/study.type.select.html',
  controllerAs: 'typeSelectController',
  controller: function ($q, Category, StudyType) {
    const typeSelectController = this;

    typeSelectController.$onInit = function () {
      typeSelectController.ngModelCtrl.$render = function () {
        typeSelectController.typeId = _.head(typeSelectController.ngModelCtrl.$modelValue);
        setCategory();
      };

      loadData();
    };

    function loadData() {
      $q.all([
        getCategories(),
        getTypes()
      ]).then(([categories, types]) => {
        typeSelectController.categories = categories;
        typeSelectController.categoryRequired = typeSelectController.categoryFirst === true && categories.length > 1;
        typeSelectController.types = types;
        setCategory();
      });
    }

    function setCategory() {
      const type = _.find(typeSelectController.types, { id: typeSelectController.typeId });
      if (angular.isDefined(type)) {
        typeSelectController.categoryId = type.categoryId;
      }
    }

    function getTypes() {
      return StudyType.query({
        academicYearId: sessionStorage.academicYear,
        categoryId: typeSelectController.categoryId
      }).$promise.then((types) => {
        return _.sortBy(types, ['sequence', 'code']);
      });
    }

    function getCategories() {
      if (typeSelectController.categoryFirst !== true) {
        return $q.resolve([]);
      }

      return Category.query({
        rootType: 'STUDY',
        academicYearId: sessionStorage.academicYearId
      }).$promise;
    }

    typeSelectController.onCategory = function () {
      delete typeSelectController.typeId;
      typeSelectController.onChange();

      getTypes().then((types) => {
        typeSelectController.types = types;
      });
    };

    typeSelectController.onChange = function () {
      typeSelectController.ngModelCtrl.$setViewValue(typeSelectController.typeId);
    };
  }
});