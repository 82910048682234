'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasPanel
 * @description
 * # uasPanel
 * Default panel template
 */
angular.module('uasApp')
    .component('uasPanel', {
        bindings: {
            title: '@',
            icon: '@?'
        },
        controllerAs: 'panelController',
        transclude: true,
        templateUrl: 'es6/app/templates/panel.html'
    });