'use strict';

angular.module('uasApp').component('groupStudiesPage', {
  templateUrl: 'es6/group/group.studies.page.html',
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<'
  },
  controllerAs: 'groupStudiesPageController',
  controller: function (EntityService, ModuleGroup, $q) {
    const groupStudiesPageController = this;

    groupStudiesPageController.$onInit = function () {
      const reference = EntityService.toReference(groupStudiesPageController.entity);
      loadData(reference);
    };

    function loadData(reference) {
        groupStudiesPageController.loading = true;
        getStudies(reference).then((studies) => {
            groupStudiesPageController.studies = studies;
        }).finally(() => {
            groupStudiesPageController.loading = false;
        });
    }

    function getStudies(reference) {
        if (reference.type === 'module-group') {
            return ModuleGroup.studies({
                id: reference.id
            }).$promise;
        }

        return $q.resolve([]);
    }
  }
});