'use strict';

angular.module('uasApp').component('labelEdit', {
  bindings: {
    labelKey: '<',
    onChange: '&',
    onCancel: '&'
  },
  templateUrl: 'es6/admin/labels/input/label.edit.html',
  controllerAs: 'labelEditController',
  controller: function (Promises, Label, Language) {
    const labelEditController = this;

    labelEditController.$onInit = function() {
      labelEditController.create = _.isEmpty(labelEditController.labelKey);

      if (labelEditController.labelKey) {
        Label.query({
          key: labelEditController.labelKey
        }).$promise.then((labels) => {
          setLabels(labelEditController.labelKey, labels);
        });
      }
    };

    function setLabels(key, labels) {
      labelEditController.labels = _.map(Language.supported, (language) => {
        return _.find(labels, { language }) || { key, language };
      });
    }

    labelEditController.onKey = function() {
      if (labelEditController.labelKey) {
        setLabels(labelEditController.labelKey);
      }
    };

    labelEditController.submit = function() {
      const promises = _.map(labelEditController.labels, (label) => {
        return () => Label.store(label).$promise;
      });

      Promises.chain(promises).$promise.then(() => {
        labelEditController.onChange({
          key: labelEditController.labelKey
        });
      });
    };

    labelEditController.cancel = function() {
      labelEditController.onCancel();
    };
  }
});