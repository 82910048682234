'use strict';

angular.module('uasApp').component('appraisalTemplates', {
  templateUrl: 'es6/assessment/appraisal/template/appraisal.templates.html',
  controllerAs: 'appraisalTemplatesController',
  controller: function () {
    const appraisalTemplatesController = this;

    appraisalTemplatesController.select = function (template) {
      appraisalTemplatesController.template = template;
    };
  }
});
