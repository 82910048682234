'use strict';

angular.module('uasApp').component('methodCategorySelect', {
  bindings: {
    exam: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/schedule/activity/form/method.category.select.html',
  controllerAs: 'methodCategorySelectController',
  controller: function (Category) {
    const methodCategorySelectController = this;

    methodCategorySelectController.$onInit = function () {
      methodCategorySelectController.ngModelCtrl.$render = () => {
        methodCategorySelectController.categoryId = parseInt(methodCategorySelectController.ngModelCtrl.$modelValue);
      };

      loadData();
    };

    function loadData() {
      methodCategorySelectController.loading = true;

      Category.query({
        rootType: 'METHOD'
      }).$promise.then((categories) => {
        methodCategorySelectController.categories = _.filter(categories, (category) => {
          if (angular.isDefined(methodCategorySelectController.exam)) {
            return category.exam === methodCategorySelectController.exam;
          }

          return category.scheduleable || category.exam;
        });
      }).finally(() => {
        methodCategorySelectController.loading = false;
      });
    }

    methodCategorySelectController.onCategory = function () {
      methodCategorySelectController.ngModelCtrl.$setViewValue(methodCategorySelectController.categoryId);
    };
  }
});
