'use strict';

angular.module('uasApp').component('manualNotificationsModal', {
  bindings: {
    role: '<',
    getRecipients: '&',
    operations: '<',
    onSend: '&',
    onClose: '&'
  },
  templateUrl: 'es6/app/notifications/manual.notifications.modal.html',
  controllerAs: 'manualNotificationsModalController',
  controller: function (Message, ManualNotification, NotificationTemplate, entityTranslateFilter) {
    const manualNotificationsModalController = this;

    manualNotificationsModalController.$onInit = function () {
      manualNotificationsModalController.notification = emptyNotification();

      manualNotificationsModalController.loading = true;
      manualNotificationsModalController.getRecipients().then((recipients) => {
        setRecipients(recipients);
      }).finally(() => {
        manualNotificationsModalController.loading = false;
      });

      NotificationTemplate.manual().$promise.then((templates) => {
        manualNotificationsModalController.templates = templates;
      });
    };

    function emptyNotification() {
      return {
        roleId: manualNotificationsModalController.role.id,
        online: true,
        mail: true
      };
    }

    function setRecipients(recipients) {
      manualNotificationsModalController.recipients = _.forEach(recipients, (recipient, index) => {
        recipient.id = index;
        const entities = _(recipient.entities).map((entity) => entity.code || entityTranslateFilter(entity)).join(', ');
        recipient.displayName = `${recipient.fullName} - ${recipient.email} (${entities})`;
      });

      manualNotificationsModalController.selectedRecipients =  manualNotificationsModalController.recipients;
    }

    manualNotificationsModalController.onTemplate = function (template) {
      manualNotificationsModalController.notification = emptyNotification();
      manualNotificationsModalController.notification.roleId = manualNotificationsModalController.role.id;

      if (template) {
        manualNotificationsModalController.notification.subjects = template.titles;
        manualNotificationsModalController.notification.texts = template.bodies;
        manualNotificationsModalController.notification.notificationTemplateId = template.id;
      }
    };

    manualNotificationsModalController.send = function () {
      manualNotificationsModalController.notification.recipients = _.map(manualNotificationsModalController.selectedRecipients, mapRecipient);

      ManualNotification.send(manualNotificationsModalController.notification).$promise.then(() => {
        Message.addSuccessLabel('Static.Message.ManualNotificationSent');
        manualNotificationsModalController.onSend();
      });
    };

    function mapRecipient(recipient) {
      const found = _.find(manualNotificationsModalController.recipients, { id: recipient.id });
      return {
        personId: found.personId,
        entities: _.map(found.entities, (entity) => ({ id: entity.id, type: entity.type }))
      };
    }
  }
});
