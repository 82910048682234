'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Element
 * @description The Element service.
 */
angular.module('uasApp').factory('Element', function (entityTranslateFilter, Dates, UasResource) {
  const resource = UasResource('/element').withCache('elements');

  resource.getActive = function (type) {
    return resource.getByType(type, sessionStorage.academicYear);
  };

  resource.getByType = function (type, periodId) {
    return resource.query({
      type: _.snakeCase(type).toUpperCase(),
      periodId
    }).$promise;
  };

  resource.getAll = function () {
    return resource.query().$promise.then((elements) =>
      Dates.setActive(elements)
    );
  };

  resource.getLabel = function (element) {
    return entityTranslateFilter(element);
  };

  return resource;
});
