'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:studyableModules
 * @description
 * # studyableModules
 * Displays the studyability of modules.
 */
angular.module('uasApp').component('studyableModules', {
  bindings: {
    modules: '<',
    onChangeOrder: '&?',
    filterStudies: '&?',
    pageable: '<',
    showUnadvicedOfferings: '@?',
    showUnused: '@?',
    getReport: '&',
    onToggle: '&',
    all: '<',
    totalElements: '<',
    allowedOperations: '<',
    onFilter: '&?',
    currentFilter: '<?'
  },
  templateUrl: 'es6/study/studyable/studyable.modules.html',
  controllerAs: 'studyableModulesController',
  controller: function (Studyable) {
    const studyableModulesController = this;

    studyableModulesController.searchColumns = false;
    if (studyableModulesController.currentFilter) {
      studyableModulesController.searchColumns = true;
    } else {
      studyableModulesController.currentFilter = {};
    }

    studyableModulesController.totalColumns = 4 + (studyableModulesController.showUnused ? 1 : 0) + (studyableModulesController.showUnadvicedOfferings ? 1 : 0);

    studyableModulesController.open = function (row) {
      if (row.initialized !== true) {
        row.studies = Studyable.query({
          moduleId: row.id
        });
        row.studies.$promise.then(function () {
          row.initialized = true;
        });
      }
      row.visible = row.visible !== true;
    };

    studyableModulesController.changeOrder = function (order, direction) {
      studyableModulesController.order = order;
      studyableModulesController.reverse = direction;
      studyableModulesController.onChangeOrder({ order: order, direction: direction });
    };

    studyableModulesController.doFilterStudies = function (study) {
      return angular.isUndefined(studyableModulesController.filterStudies) || studyableModulesController.filterStudies({ study: study });
    };

    studyableModulesController.toggle = function (all) {
      studyableModulesController.onToggle({
        all: all
      });
    };

    studyableModulesController.filter = function () {
      studyableModulesController.onFilter({
        search: studyableModulesController.currentFilter,
        order: studyableModulesController.order,
        direction: studyableModulesController.reverse
      });
    };

    studyableModulesController.enableSearch = function () {
      studyableModulesController.searchColumns = !studyableModulesController.searchColumns;

      if (!studyableModulesController.searchColumns) {
        studyableModulesController.onFilter({
          search: null,
          order: studyableModulesController.order,
          direction: studyableModulesController.reverse
        });
      }
    };
  }
});
