'use strict';

angular.module('uasApp').component('linkList', {
  bindings: {
    entity: '<',
    operations: '<',
    categoryId: '<?',
    workflowMode: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/link/link.list.html',
  controllerAs: 'linksController',
  controller: function(Link, Language, CustomField, Message, SecurityService, $q, $uibModal) {
    const linksController = this;

    linksController.$onChanges = function(changes) {
      linksController.operationsToEdit = linksController.workflowMode === true ? 'EDIT_LINKS_WORKFLOW' : 'EDIT_LINKS';
      linksController.editable = linksController.isReadOnly !== true && SecurityService.isAllowed(linksController.operationsToEdit, linksController.operations);

      if (changes.entity && linksController.entity) {
        loadData();
      }
    };

    function loadData() {
      linksController.loading = true;
      return $q.all([
        Link.usage({
          categoryId: linksController.categoryId,
          entityType: linksController.entity.self.type,
          entityId: linksController.entity.id,
          language: Language.get()
        }).$promise,
        CustomField.query({
          rootType: linksController.entity.self.type,
          entityType: 'link'
        }).$promise
      ]).then(([links, fields]) => {
        linksController.types = _(links).map(({ type, direction }) => {
          return { 
            key: `${type}-${direction}`,
            type, 
            direction
          };
        }).uniqBy('key').sortBy('key').map(({ key, type, direction }) => {
          return {
            key,
            name: type,
            links: _.filter(links, { type, direction }),
            editable: linksController.editable && direction === 'OUTWARD'
          };
        }).value();

        linksController.fields = _.keyBy(fields, 'name');
      }).finally(() => {
        linksController.loading = false;
      });
    }

    linksController.onChange = function() {
      loadData();
    };

    linksController.edit = function(link) {
      Link.find(link.id).$promise.then(open);
    };

    function open(link) {
      $uibModal.open({
        template: `
          <link-edit 
            link="linkModalController.link"
            entity="linkModalController.entity"
            fields="linkModalController.fields"
            operations="linkModalController.operations"
            operations-to-edit="linkModalController.operationsToEdit"
            on-cancel="linkModalController.close()"
            on-save="linkModalController.onSave()">
          </link-edit>
        `,
        controllerAs: 'linkModalController',
        controller: function($uibModalInstance) {
          const linkModalController = this;

          linkModalController.link = angular.copy(link);
          linkModalController.entity = angular.copy(linksController.entity);
          linkModalController.fields = linksController.fields;
          linkModalController.operations = linksController.operations;
          linkModalController.operationsToEdit = linksController.operationsToEdit;

          linkModalController.close = function() {
            $uibModalInstance.dismiss();
          };

          linkModalController.onSave = function() {
            linkModalController.close();
            Message.onSaved();
            loadData();
          };
        }
      });
    }

    linksController.remove = function(link) {
      Link.remove({
        id: link.id
      }).$promise.then(() => {
        Message.onRemoved();
        loadData();
      });
    };
  }
});