'use strict';

angular.module('uasApp').component('taskTemplateList', {
    templateUrl: 'es6/staffing/task/template/task.template.list.html',
    controllerAs: 'taskTemplateListController',
    controller: function ($uibModal, $timeout, Pageable, TaskTemplate, Message) {
        const taskTemplateListController = this;

        taskTemplateListController.$onInit = function() {
            taskTemplateListController.pageable = Pageable.of({
                order: 'sequence'
            });

            taskTemplateListController.search();
        };

        taskTemplateListController.search = function() {
            const params = taskTemplateListController.pageable.build({
                text: taskTemplateListController.term || '',
                academicYearId: sessionStorage.academicYear,
                tag: taskTemplateListController.tag
            });

            taskTemplateListController.loading = true;
            return TaskTemplate.search(params).$promise.then((tasks) => {
                taskTemplateListController.tasks = tasks;
            }).finally(() => {
                taskTemplateListController.loading = false;
            });
        };

        taskTemplateListController.setTag = function(tag, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            if (tag === taskTemplateListController.tag) {
                delete taskTemplateListController.tag;
            } else {
                taskTemplateListController.tag = tag;
            }

            delete taskTemplateListController.selected;
            return taskTemplateListController.search();
        };

        taskTemplateListController.create = function() {
            open({
                academicYearId: sessionStorage.academicYear,
                unit: 'HOURS'
            });
        };

        taskTemplateListController.edit = function(template) {
            const form = build(template);
            open(form);
        };

        function build(template) {
            const form = angular.copy(template);
            form.roleId = _.get(template, 'role.id');
            form.typeId = _.get(template, 'type.id');
            return _.omit(form, ['role', 'type']);
        }

        function open(template) {
            $uibModal.open({
                template: `
                    <task-template-edit 
                        template="modalController.template" 
                        on-submit="modalController.save(template)" 
                        on-cancel="modalController.close()">
                    </task-template-edit>
                `,
                controllerAs: 'modalController',
                controller: function ($uibModalInstance) {
                    const modalController = this;
                    modalController.template = angular.copy(template);

                    modalController.close = function() {
                        $uibModalInstance.dismiss();
                    };

                    modalController.save = function(result) {
                        modalController.close();
                        save(result).then(() => {
                            delete taskTemplateListController.selected;
                            taskTemplateListController.search();
                        });
                    };
                }
            });
        }

        taskTemplateListController.delete = function(task) {
            TaskTemplate.delete({ id: task.id }).$promise.then(() => {
                Message.onRemoved();
                taskTemplateListController.search();
            });
        };

        taskTemplateListController.onSelect = function(task) {
            if (_.isEqual(task, taskTemplateListController.selected)) {
                delete taskTemplateListController.selected;
            } else {
                taskTemplateListController.selected = angular.copy(task);
            }
        };

        taskTemplateListController.onChange = function(template) {
            const form = build(template);
            save(form);
        };

        function save(template) {
            return TaskTemplate.store(template).$promise.then(() => {
                Message.onSaved();
                taskTemplateListController.search();
            });
        }
    }
});
