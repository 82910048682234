'use strict';
/**
 * @ngdoc function
 * @name uasApp.directive:autofocus
 * @description
 * # autofocus
 * Set autofocus on an element
 * Usage:
 * <input type="text" autofocus>
 */
angular.module('uasApp')
    .directive('autofocus', function($timeout) {
        return {
            restrict: 'A',
            link: function($scope, $element) {
                $timeout(function() {
                    $element[0].focus();
                });
            }
        };
    });
