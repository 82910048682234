'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Item
 * @description
 * The Item service.
 */
angular.module('uasApp')
    .factory('Item', function (UasResource) {
        return UasResource('/item', {
            describe: {
                method: 'GET',
                isArray: true,
                // Override transform response because we don't want string dates
                // to be converted to dates. Item values in the response are
                // already formatted.
                transformResponse: function(data) {
                    return angular.fromJson(data);
                }
            },
            report: {
                method: 'GET',
                url: '/item-report'
            },
            restore: {
                method: 'PUT',
                url: '/item/:id/restore'
            }
        });
    });
