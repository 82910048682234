'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:SettingsManager
 * @description
 * The SettingsManager service.
 */
angular.module('uasApp').factory('Settings', function(BackendService, $rootScope, $resource) {

  const resource = $resource(BackendService.getBaseUrl() + '/person-settings', {}, {
        'update': {
            method: 'POST'
        }
    });

  const instance = {};
  const NAME_PREFIX = 'setting-';

  instance.load = function(settings) {
    clearSettings();
    _.each(settings, (value, name) => {
      store(name, value);
    });
  };

  function clearSettings() {
    _.keys(sessionStorage)
    .filter((key) => _.startsWith(key, NAME_PREFIX))
    .forEach((key) => delete sessionStorage[key]);
  }

  instance.set = function(name, value) {
    const current = instance.get(name);
    if (current !== value) {
      store(name, value);
      resource.update({}, [{ name, value }]);
    }
  };

  function store(name, value) {
    sessionStorage[NAME_PREFIX + name] = value;
    $rootScope.$broadcast(getChangeName(name), value);
  }

  function getChangeName(name) {
    return 'Settings.' + name + '.Set';
  }

  instance.get = function(name) {
    return sessionStorage[NAME_PREFIX + name];
  };

  instance.is = function(name, expected) {
    const value = instance.get(name);
    return value === '' + expected;
  };

  instance.onChange = function(name, callback) {
    return $rootScope.$on(getChangeName(name), callback);
  };

  return instance;

});
