'use strict';

angular.module('uasApp').component('diff', {
    bindings: {
        left: '<',
        right: '<',
        modalTitle: '@',
        entityType: '<?'
    },
    transclude: true,
    controllerAs: 'diffController',
    controller: function ($uibModal) {
        const diffController = this;

        diffController.open = function () {
            if (diffController.entityType === 'description') {
                return;
            }

            $uibModal.open({
                templateUrl: 'es6/changes/diff.modal.html',
                size: 'lg',
                controllerAs: 'diffModalController',
                controller: function ($uibModalInstance) {
                    this.title = diffController.modalTitle;
                    this.left = diffController.left;
                    this.right = diffController.right;
                    this.close = () => $uibModalInstance.dismiss();
                }
            });
        };
    },
    template: `
        <div ng-click="diffController.open()">
            <div ng-transclude></div>
        </div>
    `
});