'use strict';

angular.module('uasApp').component('studyTypeList', {
    templateUrl: 'es6/study/type/study.type.list.html',
    controllerAs: 'studyTypeListController',
    controller: function ($uibModal, Pageable, StudyType, Message) {
        const studyTypeListController = this;

        studyTypeListController.$onInit = () => {
            studyTypeListController.pageable = Pageable.of({
                order: 'sequence'
            });

            studyTypeListController.search();
        };

        studyTypeListController.search = function () {
            const params = studyTypeListController.pageable.build({
                categoryId: studyTypeListController.categoryId,
                text: studyTypeListController.text || ''
            });

            studyTypeListController.loading = true;
            return StudyType.search(params).$promise.then((studyTypes) => {
                studyTypeListController.studyTypes = studyTypes;
            }).finally(() => {
                studyTypeListController.loading = false;
            });
        };

        studyTypeListController.onCategory = function(categoryId) {
            studyTypeListController.categoryId = categoryId;
            return studyTypeListController.search();
        };

        studyTypeListController.create = function () {
            open({});
        };

        studyTypeListController.edit = function (studyType) {
            StudyType.get({
                id: studyType.id
            }).$promise.then(open);
        };

        function open(studyType) {
            $uibModal.open({
                resolve: {
                    categories: function (Category) {
                        return Category.query({
                            rootType: 'STUDY' 
                        }).$promise;
                    },
                    groupTypes: function (GroupType) {
                        return GroupType.query().$promise;
                    }
                },
                size: 'lg',
                templateUrl: 'es6/study/type/study.type.edit.html',
                controllerAs: 'studyTypeEditController',
                controller: function ($uibModalInstance, categories, groupTypes) {
                    const studyTypeEditController = this;

                    studyTypeEditController.$onInit = function () {
                        studyTypeEditController.studyType = studyType;
                        studyTypeEditController.categories = categories;
                        studyTypeEditController.groupTypes = groupTypes;
                    };

                    studyTypeEditController.save = function () {
                        StudyType.store(studyTypeEditController.studyType).$promise.then(() => {
                            studyTypeEditController.close();
                            studyTypeListController.search();
                            Message.onSaved();
                        });
                    };

                    studyTypeEditController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

        studyTypeListController.delete = function (studyType) {
            StudyType.delete(studyType).$promise.then(() => {
                studyTypeListController.search();
                Message.onRemoved();
            });
        };
    }
});
