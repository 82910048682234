/**
 * Enables really annoying easter egg features.
 */

/* eslint-disable angular/angularelement, no-console, angular/log */

// Import necessary functions
import { Cornify } from '../../../src/components/eastereggs/cornify/cornify';
import { DesignMode } from '../../../src/components/eastereggs/designmode/designmode';

// Add season class to html based on current month
$(document).ready(() => {
  const month = new Date().getMonth() + 1;
  $('html').addClass(getSeason(month));
});

// Function to determine the current season
const getSeason = function(month) {
  if (month >= 3 && month < 6) {
    return 'spring';
  } else if (month >= 6 && month < 9) {
    return 'summer';
  } else if (month >= 9 && month < 12) {
    return 'fall';
  } else {
    return 'winter';
  }
};

// Initialize an array to keep track of key codes
let kkeys = [];

// Define secret codes
const secretCodes = {
  konami: '38,38,40,40,37,39,37,39,66,65',
  harryIsHappy: '72,65,82,82,89,73,83,72,65,80,80,89',
  groetjesVanWillem: '71,82,79,69,84,74,69,83,86,65,78,87,73,76,76,69,77',
  karaoke: '75,65,82,65,79,75,69',
  time2edit: '84,73,77,69,50,69,68,73,84'
};

// Keydown event to track and trigger easter eggs
$(document).keydown((e) => {
  // Ignore the space key (keyCode 32)
  if (e.keyCode === 32) {
    return;
  }

  if (kkeys.length > 100) {
    kkeys = [];
  } else {
    kkeys.push(e.keyCode);
  }

  Object.keys(secretCodes).forEach((codeName) => {
    if (kkeys.toString().includes(secretCodes[codeName])) {
      triggerEasterEgg(codeName, e);
      kkeys = [];
    }
  });
});

// Trigger easter egg based on the key sequence
function triggerEasterEgg(codeName, e) {
  switch (codeName) {
    case 'konami':
      $('body').css('filter', 'invert(100%)');
      Cornify.start();
      break;
    case 'harryIsHappy':
      Cornify.start();
      break;
    case 'groetjesVanWillem':
      $('body').css('filter', 'blur(2px)');
      break;
    case 'karaoke':
      toggleKaraoke();
      break;
    case 'time2edit':
      DesignMode.toggle(e);
      break;
  }
}


// Function to toggle Karaoke machine
function toggleKaraoke() {
  const body = $('.customer-container > .customer-body');

  if (body.has('#start-karaoke').length === 0) {
    body.append(`
      <button class="btn btn-primary btn-easter-egg" type="button" id="start-karaoke">
        <i class="fa fa-volume-up"></i>
        <span>Karaoke machine</span>
      </button>
    `);
  }

  $('#start-karaoke').click(function() {
    if ($('#karaoke').length > 0) {
      $('#karaoke').remove();
    } else {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      body.prepend(`
        <div id="karaoke" class="panel panel-default panel-easter-egg">
          <div class="panel-heading">
            <h2 class="panel-title">
              <i class="fa fa-volume-up"></i>
              <span>Karaoke machine</span>
            </h2>
          </div>
          <div class="panel-body">
            <iframe width="560"
                    height="315"
                    src="https://www.youtube.com/embed/videoseries?list=PLvkXlcPCXvXE76hNp1hy31eswVbJET4eo&autoplay=1"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen>
            </iframe>
          </div>
        </div>
      `);
    }
  });
}

/* eslint-enable angular/angularelement, no-console, angular/log */
