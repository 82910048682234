'use strict';

angular.module('uasApp').component('creditsLabel', {
    bindings: {
        selected: '<?',
        credits: '<',
        isRequired: '<?',
        isValid: '<?'
    },
    templateUrl: 'es6/app/credits/credits.label.html',
    controllerAs: 'creditsLabelController',
    controller: function () {
        const creditsLabelController = this;

        creditsLabelController.$onChanges = function () {
            creditsLabelController.valid = creditsLabelController.isValid === true;
            creditsLabelController.label = getLabel(creditsLabelController.credits);
        };

        function getLabel(credits) {
            if (angular.isUndefined(credits)) {
                return '';
            }

            const minimum = credits.minimum;
            const maximum = credits.maximum;
            const optimum = credits.optimum;

            let label = '';
            if (angular.isDefined(minimum) && angular.isDefined(maximum)) {
                label = minimum === maximum ? `${minimum}` : `${minimum}-${maximum}`;
            } else if (isNotNegative(minimum)) {
                label = `${minimum}+`;
            } else if (isNotNegative(maximum)) {
                label = `0-${maximum}`;
            }

            if (isNotNegative(optimum) && label !== `${optimum}`) {
                label = label ? `${label} (${optimum})` : `${optimum}`;
            }

            return label;
        }

        function isNotNegative(value) {
            return _.isNumber(value) && value >= 0;
        }
    }
});