'use strict';

angular.module('uasApp').component('vacancies', {
    bindings: {
        organisation: '<',
        operations: '<',
        operationsToEdit: '@?',
        isReadOnly: '<?'
    },
    templateUrl: 'es6/staffing/vacancy/vacancies.html',
    controllerAs: 'vacanciesController',
    controller: function($uibModal, Vacancy, AcademicYear, Availability, AvailabilityModal, Message, SecurityService, Pageable) {
        const vacanciesController = this;

        vacanciesController.$onInit = function() {
            vacanciesController.active = true;
            vacanciesController.editable = vacanciesController.isReadOnly !== true && SecurityService.isAllowed('EDIT_VACANCIES', vacanciesController.operations);
            
            vacanciesController.pageable = Pageable.of({
                order: 'allocatedPercentage',
                reverse: true
            });

            AcademicYear.get({
                id: sessionStorage.academicYear
            }).$promise.then((year) => {
                vacanciesController.year = year;  
            });

            loadData();
        };

        function loadData() {
            const params = vacanciesController.pageable.build({
                organisationId: vacanciesController.organisation.id,
                academicYearId: sessionStorage.academicYear,
                active: vacanciesController.active,
                vacancy: true
            });

            vacanciesController.loading = true;
            Availability.page(params).$promise.then((availabilities) => {
                vacanciesController.availabilities = availabilities;
            }).finally(() => {
                vacanciesController.loading = false;
            });
        }

        vacanciesController.onChange = function() {
            loadData();
        };

        vacanciesController.calculate = function() {
            vacanciesController.loading = true;
            Availability.calculate({
                organisationId: vacanciesController.organisation.id,
                academicYearId: sessionStorage.academicYear
            }, {}).$promise.then(loadData).finally(() => {
                vacanciesController.loading = false;
            });
        };

        vacanciesController.show = function(assignment) {
            AvailabilityModal.open({
                assignmentId: assignment.id,
                isReadOnly: vacanciesController.isReadOnly,
                onChange: loadData
            });
        };

        vacanciesController.create = function() {
            open({
                organisationId: vacanciesController.organisation.id,
                startDate: vacanciesController.year.startDate,
                endDate: vacanciesController.year.endDate
            });
        };

        vacanciesController.edit = function(vacancy, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            const form = angular.copy(vacancy);
            form.organisationId = _.result(vacancy, 'organisation.id');
            form.roleId = _.result(vacancy, 'role.id');

            open(form);
        };

        function open(assignment) {
            $uibModal.open({
                template: `
                    <vacancy-edit
                        vacancy="modalController.vacancy"
                        on-save="modalController.onSave()"
                        on-cancel="modalController.close()">
                    </vacancy-edit>
                `,
                controllerAs: 'modalController',
                controller: function($uibModalInstance) {
                    const modalController = this;
                    modalController.vacancy = angular.copy(assignment);

                    modalController.onSave = function() {
                        modalController.close();
                        loadData();
                    };

                    modalController.close = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

        vacanciesController.remove = function(vacancy) {
            Vacancy.remove({
                id: vacancy.id
            }).$promise.then(() => {
                Message.onRemoved();
                loadData();
            });
        };
    }
});
