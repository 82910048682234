'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:Csv
 * @description
 * # Csv
 * Csv resource
 */
angular.module('uasApp').factory('Csv', function (UasResource) {
  return UasResource('/csv', {
    document: {
      method: 'GET',
      url: '/csv/document',
      isArray: false
    }
  });
});
