'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:commentRepliesToggle
 * @description Shows or hides replies to a comment.
 */
angular.module('uasApp')
  .component('commentRepliesToggle', {
    bindings: {
      isToggled: '<',
      replyCount: '<',
      onToggle: '&'
    },
    templateUrl: 'es6/changes/comment.replies.toggle.html',
    controllerAs: 'commentRepliesToggleController'
  });
