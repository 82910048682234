'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:TaLength
 * @description
 * The UAS Date service.
 */
angular.module('uasApp').factory('taLength', function () {

  return {
    getTextLength: function (content) {
      if (angular.isUndefined(content)) {
        return 0;
      }
      const regExp = new RegExp(/<span id="selectionBoundary_\d+_\d+" class="rangySelectionBoundary".*?>[^<>]+?<\/span>/ig);
      const contentString = content.replace(regExp, '');
      let newLineCount = (contentString.length === 0 ? 0 : contentString.split('\n').length - 1);
      // new lines are counted as 1 but are actually 2 chars:
      return contentString.length + newLineCount;
    }
  };
});
