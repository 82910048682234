'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:commentMentionsCount
 * @description Shows count of mention
 */
angular.module('uasApp')
    .component('commentMentionsCount',  {
      bindings: {
        mentionsCount: '<',
        mentionsAnsweredCount: '<'
      },
      templateUrl: 'es6/changes/comment.mentions.count.html',
      controllerAs: 'commentMentionsCountController',
      controller: function() {
        const commentMentionsCountController = this;

        commentMentionsCountController.$onChanges = function() {
          setCounts();
        };

        function setCounts() {
          commentMentionsCountController.mentionsCount_ = _.defaultTo(commentMentionsCountController.mentionsCount, 0);
          commentMentionsCountController.mentionsAnsweredCount_ = _.defaultTo(commentMentionsCountController.mentionsAnsweredCount, 0);
        }
      }
    });
