'use strict';

angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('pages', {
        url: '/pages?page&size&sort&route&name&rootType',
        controllerAs: 'pagesStateController',
        controller: function($stateParams) {
            this.parameters = $stateParams;
        },
        template: '<page-list parameters="pagesStateController.parameters"></page-list>',
        params: {
            page: {
               value: '1',
               dynamic: true
            },
            size: {
                value: '',
                dynamic: true
            },
            sort: {
                value: 'route',
                dynamic: true
            },
            route: {
                value: '',
                dynamic: true
            },
            name: {
                value: '',
                dynamic: true
            },
            rootType: {
                value: '',
                dynamic: true
            }
        },
        breadcrumb: {
            label: 'Static.Page.Pages',
            root: true
        },
        admin: true
    });

    $stateProvider.state('page-create', {
        url: '/page/create/:type',
        resolve: {
            page: function($stateParams) {
                return {
                    type: $stateParams.type
                };
            }
        },
        controllerAs: 'pageCreateStateController',
        template: '<page-edit page="pageCreateStateController.page"></page-edit>',
        controller: function(page) {
            const pageCreateStateController = this;
            pageCreateStateController.page = page;
        }
    });

    $stateProvider.state('page-edit', {
        url: '/page/:id',
        resolve: {
            page: function($stateParams, Page) {
                return Page.get({
                    id: $stateParams.id
                }).$promise;
            }
        },
        controllerAs: 'pageEditStateController',
        template: '<page-edit page="pageEditStateController.page"></page-edit>',
        controller: function(page) {
            const pageEditStateController = this;
            pageEditStateController.page = page;
        }
    });

});
