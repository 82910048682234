'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:calendarWeekForm
 * @description Edit a calendar week
 */
angular.module('uasApp').component('calendarWeekForm', {
    bindings: {
        week: '<'
    },
    templateUrl: 'es6/calendar/week/calendar.week.form.html',
    controllerAs: 'calenderWeekFormController'
});
