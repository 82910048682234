'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:EntityTypes.
 * @description
 * Returns a Promise of entity types from sessionStorage if already stored there.
 * If not, it will retrieve the entity types from the backend and store them in sessionStorage.
 */
angular.module('uasApp').factory('EntityTypes', function ($q, EntityType) {
  function load() {
    const json = sessionStorage.getItem('entity-types');
    const cache = angular.fromJson(json);

    if (!_.isEmpty(cache)) {
      const entityTypes = angular.copy(cache);
      return $q.resolve(entityTypes);
    }

    return EntityType.query().$promise.then((entityTypes) => {
      sessionStorage.setItem('entity-types', angular.toJson(entityTypes));
      return entityTypes;
    });
  }

  return { load };
});
