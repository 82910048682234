'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('institutions', {
        url: '/institutions',
        template: '<uas-institution-list></uas-institution-list>',
        breadcrumb: {
            label: 'Static.Page.Institutions',
            root: true
        }
    });

});
