'use strict';

angular.module('uasApp').component('studyDeliveries', {
  bindings: {
    study: '<'
  },
  templateUrl: 'es6/study/study.deliveries.html',
  controllerAs: 'deliveriesController',
  controller: function (Asset, Delivery, entityRedirect, Language) {
    const deliveriesController = this;

    deliveriesController.$onInit = function () {
      loadData();
      Language.onChange(loadData);
    };

    function loadData() {
      deliveriesController.loading = true;
      return Delivery.report({
        studyId: deliveriesController.study.id,
        language: Language.get()
      }).$promise.then((report) => {
        deliveriesController.report = report;
      }).finally(() => deliveriesController.loading = false);
    }

    deliveriesController.onClick = function (row) {
      Asset.get({ id: row.entity.id }).$promise.then((asset) => {
        entityRedirect.go('module.assets', { id: asset.module.id }, { target: '_blank' });
      });
    };
  }
});
