'use strict';

angular.module('uasApp').factory('Effort', function (UasResource) {
    const resource = UasResource('/efforts', {
        calculate: {
            method: 'POST',
            url: '/efforts/calculate',
            isArray: true
        },
        report: {
            url: '/efforts-report',
            method: 'GET',
            isArray: false
        }
    });

    resource.findByTask = function (taskId) {
        return resource.get({ taskId }).$promise.then((effort) => {
            // Object is empty when no effort found 
            if (angular.isDefined(effort.id)) {
                return effort;
            }
        });
    };

    return resource;
});
