'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:discussionPage
 * @description
 * discussionPage Shows all discussions
 */
angular.module('uasApp')
  .component('discussionPage', {
    bindings: {
      entity: '<',
      operations: '<',
      page: '<',
      workflow: '<?',
      process: '<'
    },
    templateUrl: 'es6/changes/discussion.page.html',
    controllerAs: 'discussionPageController',
    controller: function ($q, Page, Advice, Feedback, Note, Language) {
      const discussionPageController = this;

      discussionPageController.$onInit = function() {
        discussionPageController.showAdvice = Page.getParameterAsBoolean(discussionPageController.page, 'showAdvice', false);
        discussionPageController.showNotes = Page.getParameterAsBoolean(discussionPageController.page, 'showNotes', false);
        discussionPageController.showStatus = Page.getParameterAsBoolean(discussionPageController.page, 'showStatus', false);

        loadData();
      };

      function loadData() {
        discussionPageController.loading = true;
        $q.all([
          getAdvices(),
          getComments(),
          getNotes()
        ]).then(([advices, comments, notes]) => {
          const discussions = _.concat(advices, comments, notes);
          discussionPageController.discussions = _.orderBy(discussions, 'creationTime', 'desc');
        }).finally(() => {
          discussionPageController.loading = false;
        });
      }

      function getAdvices() {
        if (!discussionPageController.showAdvice) {
          return $q.resolve([]);
        }

        return Advice.query({
          entityType: discussionPageController.entity.self.type,
          entityId: discussionPageController.entity.id
        }).$promise.then((advices) => {
          return _(advices)
            .map((advice) => _.extend(advice, { type: 'advice', creationTime: advice.date }))
            .sort((a, b) => b.creationTime - a.creationTime)
            .map((advice, index) => _.extend(advice, { simpleColor: index > 0 }))
            .value();
        });
      }

      function getComments() {
        return Feedback.get({
          entityType: discussionPageController.entity.self.type,
          entityId: discussionPageController.entity.id,
          language: Language.get()
        }).$promise.then((feedback) => {
          const comments = angular.copy(feedback.comments) || [];

          if (discussionPageController.showStatus) {
            _.each(feedback.statuses, (status) => {
              if (status.comment && status.comment.message) {
                const comment = angular.copy(status.comment);
                comment.status = status.status;
                comments.push(comment);
              }
            });
          }

          return comments;
        });
      }

      function getNotes() {
        if (!discussionPageController.showNotes) {
          return $q.resolve([]);
        }

        return Note.query({
          entityType: discussionPageController.entity.self.type,
          entityId: discussionPageController.entity.id
        }).$promise;
      }
    }
});
