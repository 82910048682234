'use strict';

angular.module('uasApp').factory('RelationModal', function($uibModal, EntityService, Message, Relation) {
    
    function open(args) {
        const rootType = EntityService.getType(args.entity);

        $uibModal.open({
            template: `
                <uas-relation-edit
                    relation="modalController.relation"
                    role="modalController.role"
                    fields="modalController.fields"
                    on-save="modalController.save(relation)"
                    on-cancel="modalController.close()"
                    operations="modalController.operations"
                    operations-to-edit="modalController.operationsToEdit"
                    in-modal="true">
                </uas-relation-edit>
            `,
            size: 'lg',
            resolve: {
                fields: function(CustomField) {
                    return CustomField.query({
                        rootType,
                        entityType: 'relation'
                    }).$promise;
                }
            },
            controllerAs: 'modalController',
            controller: function(fields, $uibModalInstance) {
                const modalController = this;
                modalController.relation = args.relation;
                modalController.role = args.role;
                modalController.fields = fields;
                modalController.operations = args.operations || [];
                modalController.operationsToEdit = args.workflowMode === true ? 'EDIT_RELATIONS_WORKFLOW' : 'EDIT_RELATIONS';

                modalController.save = function(form) {
                    Relation.save({
                        content: form
                    }).$promise.then(() => {
                        Message.onSaved();
                        args.onChange();
                    }).finally(modalController.close);
                };

                modalController.close = function() {
                    $uibModalInstance.close();
                };
            }
        });
    }

    return { open };

});
