'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('module-types', {
    url: '/module-types',
    template: `
      <type-list
        validator='module-type'
        header-label='Static.Page.ModuleTypes'
        help-label='Static.Help.ModuleTypes'
        icon='fa-paperclip'
        resource='moduleTypeController.resource'>
      </type-list>
    `,
    controllerAs: 'moduleTypeController',
    controller: function (ModuleType) {
      const moduleTypeController = this;
      moduleTypeController.resource = ModuleType;
    },
    breadcrumb: {
      label: 'Static.Page.ModuleTypes',
      translate: true,
      root: true
    },
    admin: true
  });

});
