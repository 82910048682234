'use strict';

angular.module('uasApp').component('adviceMessage', {
    templateUrl: 'es6/advice/advice.message.html',
    bindings: {
        advice: '<',
        simpleColor: '<?'
    },
    controllerAs: 'adviceMessageController'
});
