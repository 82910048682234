'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:modulesReport
 * @description
 * Shows modules in a report.
 */
angular.module('uasApp').component('modulesReport', {
    bindings: {
      entity: '<?',
      operations: '<?',
      clicked: '&?',
      refresh: '<?',
      recursive: '<?',
      isReadOnly: '<?',
      reportTemplate: '@?',
      hideReportTemplates: '<?',
      defaultMode: '<?'
    },
    templateUrl: 'es6/module/report/modules.report.html',
    controllerAs: 'modulesReportController',
    controller: function($q, $state, CreateManager, Changes, EntityService, Language, Report, ModuleTabs, ReportLinks) {
        const modulesReportController = this;

        modulesReportController.$onInit = function() {
            modulesReportController.readOnly = modulesReportController.isReadOnly === true;
            modulesReportController.mode = modulesReportController.defaultMode || 'USED_STUDY';

            modulesReportController.name = 'modules';
            modulesReportController.context = 'modules';

            Language.onChange(loadData);

            if (_.isObject(modulesReportController.refresh)) {
                modulesReportController.refresh.loadData = () => refreshReport();
            }

            loadData().then(() => {
                modulesReportController.isInitalised = true;
            });
        };

        modulesReportController.$onChanges = function (changes) {
            if (modulesReportController.isInitalised && Changes.hasChanged(changes, 'entity')) {
                applyEntity();
                refreshReport();
            }
        };

        function loadData() {
            modulesReportController.loading = true;

            applyEntity();

            return loadDefinitions().finally(() => {
              modulesReportController.loading = false;
            });
        }

        function loadDefinitions() {
            return Report.modules({
                definition: true
            }).$promise.then((report) => {
                modulesReportController.columns = report.headers;
                modulesReportController.groups = report.groups;
            });
        }

        function applyEntity() {
            modulesReportController.entityType = EntityService.getType(modulesReportController.entity);

            if (angular.isDefined(modulesReportController.entity)) {
                modulesReportController.name = `${modulesReportController.entity.code}-modules`;
                modulesReportController.context = `${modulesReportController.entityType}.modules`;

                if (modulesReportController.entityType === 'study') {
                    modulesReportController.studyId = modulesReportController.entity.id;
                } else {
                    modulesReportController.studyId = modulesReportController.entity.studyId;
                }
                modulesReportController.facultyId = modulesReportController.entity.facultyId;
            }
        }

        function refreshReport() {
            modulesReportController.reload = {
                reset: true
            };
        }

        modulesReportController.create = function () {
            const module = EntityService.extend({
              academicYearId: sessionStorage.academicYear,
              facultyId: modulesReportController.facultyId,
              studyId: modulesReportController.studyId,
              typeId: modulesReportController.typeId
            }, modulesReportController.entity);
      
            CreateManager.open('module', module, modulesReportController.operations, (result) => {
              $state.go('module.general', { id: result.id });
            });
        };

        modulesReportController.onMode = function(mode) {
            modulesReportController.mode = mode;
            refreshReport();
        };

        modulesReportController.getRows = function(pageable) {
            const parameters = getParameters(pageable);

            return Report.modules(parameters).$promise;
        };

        function getParameters(pageable) {
            let params = {
                academicYearId: sessionStorage.academicYear,
                language: Language.get()
            };

            if (modulesReportController.entityType === 'study') {
                params.usedStudyId = modulesReportController.entity.id;

                // Is module used by study and owned by a study in the same faculty
                params.ownedByStudy = true;
            } else if (modulesReportController.entityType === 'module-group' && modulesReportController.recursive !== false) {
                params.usedModuleGroupId = modulesReportController.entity.id;
            } else if (modulesReportController.entityType) {
                const entityPath = EntityService.getEntityPath(modulesReportController.entityType);
                params[entityPath] = modulesReportController.entity.id;
            }

            if (modulesReportController.mode === 'SAME_FACULTY') {
                params.facultyId = modulesReportController.facultyId;
            } else if (modulesReportController.mode === 'OWNED_STUDY') {
                params.studyId = modulesReportController.studyId;
                delete params.usedStudyId;
                delete params.ownedByStudy;
            }

            return _.extend(params, pageable);
        }

        const generalState = 'module.general';
        const states = {
            general: generalState,
            additional: 'module.additional',
            credits: 'module.credits',
            capacity: 'module.capacity',
            description: 'module.description',
            relation: 'module.relations',
            costs: 'module.cost'
        };

        modulesReportController.onClick = function(row, col) {
            if (!modulesReportController.readOnly) {
                onClick(row, col);
            }
        };

        function onClick(row, col) {
            if (angular.isDefined(modulesReportController.clicked)) {
                modulesReportController.clicked({ row, col });
            } else {
                const state = states[col.group];
                if (!_.isEmpty(state)) {
                    ReportLinks.navigateTo(state, generalState, 'module', 'module', row.entity.id, ModuleTabs.get());
                }
            }
        }
    }
});
