'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:simulationCreate
 * @description
 * simulationCreate Shows start a simulation.
 */
angular.module('uasApp')
  .component('simulationCreate', {
    templateUrl: 'es6/simulation/simulation.create.html',
    controllerAs: 'simulationController',
    controller: function (Simulation, AcademicYearHolder, Message) {
      const simulationController = this;
      simulationController.loading = false;

      simulationController.$onInit = function () {
        Simulation.mine().$promise.then((result) => {
          simulationController.years = _(result.years || [])
            .filter((year) => year.simulation || year.defaultSelected)
            .sortBy(['simulation', 'code'])
            .value();
          });

          simulationController.simulation = {};
      };

      simulationController.save = function() {
        simulationController.loading = true;
        Simulation.store(simulationController.simulation).$promise.then(function(result) {
            Message.addSuccessLabel('Static.Message.DataAdjusted');
            AcademicYearHolder.transition(result);
        }).finally(() => {
            simulationController.loading = false;
        });
      };
    }
});
