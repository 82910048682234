'use strict';

angular.module('uasApp').component('schedulePreferencePage', {
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/schedule/preference/schedule.preference.page.html',
  controllerAs: 'schedulePreferencePageController',
  controller: function (Page) {
    const schedulePreferencePageController = this;

    schedulePreferencePageController.$onInit = function () {
      schedulePreferencePageController.displayInfo_ = Page.getParameterAsBoolean(schedulePreferencePageController.page, 'displayInfo', false);
    };
  }
});
