'use strict';

angular.module('uasApp').component('availabilityDetails', {
    templateUrl: 'es6/staffing/availability/availability.details.html',
    bindings: {
        assignment: '<',
        operations: '<',
        isReadOnly: '<?',
        workflowMode: '<',
        onChange: '&',
        onClose: '&'
    },
    controllerAs: 'availabilityDetailsController',
    controller: function (SecurityService) {
        const availabilityDetailsController = this;

        availabilityDetailsController.$onInit = function () {
            if (SecurityService.isAllowed(['VIEW_AVAILABILITY'], availabilityDetailsController.operations)) {
                availabilityDetailsController.mode = 'availability';
            } else if (SecurityService.isAllowed(['VIEW_TASKS'], availabilityDetailsController.operations)) {
                availabilityDetailsController.mode = 'tasks';
            }
        };

    }
});
