'use strict';

angular.module('uasApp').component('scaleValueList', {
  templateUrl: 'es6/subjects/scale/scale.value.list.html',
  controllerAs: 'scaleValueListController',
  controller: function ($uibModal, Scale, Message, Pageable) {
    const scaleValueListController = this;

    scaleValueListController.$onInit = function () {
      scaleValueListController.pageable = Pageable.of({
        order: ['sequence', 'value', 'code']
      });
    };

    scaleValueListController.onScale = function (scale) {
      scaleValueListController.scale = scale;
    };

    scaleValueListController.remove = function (value) {
      _.remove(scaleValueListController.scale.values, value);
      Scale.store(scaleValueListController.scale).$promise.then((scale) => {
        scaleValueListController.scale = scale;
        Message.onRemoved();
      });
    };

    scaleValueListController.create = function () {
      scaleValueListController.edit({
        value: getHighestValue() + 1
      });
    };

    function getHighestValue() {
      return _(scaleValueListController.scale.values).map('value').max() || 0;
    }

    scaleValueListController.edit = function (value) {
      $uibModal.open({
        template: `
          <scale-value-edit 
            value="modalController.value"
            on-save="modalController.save(value)"
            on-cancel="modalController.close()">
          </scale-value-edit>
        `,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.value = angular.copy(value);
          };

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };

          modalController.save = function (updated) {
            modalController.close();

            _.extend(value, updated);
            if (angular.isUndefined(scaleValueListController.scale.values)) {
              scaleValueListController.scale.values = [];
            }
            if (angular.isUndefined(value.id)) {
              scaleValueListController.scale.values.push(value);
            }

            Scale.store(scaleValueListController.scale).$promise.then((scale) => {
              scaleValueListController.scale = scale;
              Message.onSaved();
            });
          };
        }
      });
    };
  }
});