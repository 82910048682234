'use strict';

angular.module('uasApp').factory('objectLinkInterceptor', function() {
    return {
        request: function (request) {
            // Add the origin of the current object to the request. Used to create links to objects.

            const angularPrefix = '/#/';

            // Remove the domain URL and the Angular prefix.
            let index = window.location.href.indexOf(angularPrefix);

            request.headers['Object-origin'] = window.location.href.substring(index + angularPrefix.length);
            return request;
        }
    };
});
