'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasNotFound
 * @description
 * # uasNotFound
 * uasNotFound component of the uasApp
 */
angular.module('uasApp').component('uasNotFound', {
  templateUrl: 'es6/app/not.found.html'
});
