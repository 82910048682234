'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('qualification', {
        url: '/qualification/{id:[0-9]{1,8}}',
        templateUrl: 'es6/qualification/qualification.html',
        controller: 'QualificationCtrl',
        abstract: true,
        entityType: 'qualification',
        redirectTo: 'qualification.general',
        breadcrumb: {},
        resolve: {
            qualification: function(Qualification, AcademicYearHolder, $stateParams) {
                const instance = this;
                return Qualification.get({
                    id: $stateParams.id
                }).$promise.then(function (qualification) {
                    instance.self.data = qualification;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(qualification.academicYearId).then(() => {
                        return qualification;
                    });
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('qualification', $stateParams.id);
            }
        }
    });

    $stateProvider.state('qualification.general', {
        url: '/general',
        views: {
            'qualification': {
                template: '<qualification-general qualification="qualification"></qualification-general>'
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Qualification.General',
            label: 'Static.Help.Qualification.General'
        }
    });

    $stateProvider.state('qualification.relations', {
        url: '/relations',
        views: {
            'qualification': {
                template: `
                    <relations
                        entity-type="qualification"
                        entity-id="qualification.id"
                        operations="operations">
                    </relations>`
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Qualification.Relations',
            label: 'Static.Help.Qualification.Relations'
        }
    });

    $stateProvider.state('qualification.admin', {
        url: '/admin',
        views: {
            'qualification': {
                template: `
                    <qualification-admin 
                        qualification="qualification" 
                        operations="operations">
                    </qualification-admin>
                `
            }
        },
        parent: 'qualification',
        breadcrumb: {},
        data: {
            secured: ['EDIT'],
            title: 'Static.Tab.Qualification.Admin',
            label: 'Static.Help.Qualification.Admin'
        }
    });

    $stateProvider.state('qualification.description', {
        url: '/description',
        views: {
            'qualification': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('qualification');
                    }
                },
                controller: function($scope, types) {
                    $scope.types = types;
                },
                template: `
                    <description 
                        data-types="types"
                        data-entity-type="qualification"
                        data-entity-id="qualificationId"
                        data-status="qualification.status"
                        data-operations="operations">
                    </description>
                `
            }
        },
        parent: 'qualification',
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Qualification.Description',
            label: 'Static.Help.Qualification.Description'
        }
    });

    $stateProvider.state('qualification.links', {
        url: '/links',
        views: {
            'qualification': {
                template: `
                    <link-list 
                        entity="qualification" 
                        operations="operations">
                    </link-list>
                `
            }
        },
        parent: 'qualification',
        breadcrumb: {},
        data: {
            secured: ['VIEW_LINKS'],
            title: 'Static.Tab.Qualification.Links',
            label: 'Static.Help.Qualification.Links'
        }
    });

    $stateProvider.state('qualification.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'qualification': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="pageId" 
                        entity="qualification" 
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});
