'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:fallbackSrc
 * @description
 * Ensures that if the primary image source (ng-src) is undefined, empty, or fails to load, a specified fallback source is used instead.
 */
angular.module('uasApp')
  .directive('fallbackSrc', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        attrs.$observe('ngSrc', function() {
          checkAndSetFallbackSrc();
        });

        checkAndSetFallbackSrc();

        function checkAndSetFallbackSrc() {
          if (angular.isUndefined(attrs.ngSrc) || _.isEmpty(attrs.ngSrc)) {
            setFallbackSrc();
          }
        }

        function setFallbackSrc() {
          if (element.attr('src') !== attrs.fallbackSrc) {
            element.attr('src', attrs.fallbackSrc);
          }
        }

        element.on('error', function() {
          setFallbackSrc();
        });

      }
    };
  });
