'use strict';

angular.module('uasApp').component('subjectMatrix', {
  templateUrl: 'es6/subjects/matrix/subject.matrix.html',
  bindings: {
    entity: '<',
    isReadOnly: '<?',
    typeId: '<?',
    showPhase: '<',
    showPeriods: '<',
    showStructure: '<',
    stickyHeaders: '<',
    moduleColumns: '<?',
    groupColumns: '<?',
    expandFilters: '<?',
    displayType: '<?' // 'scales' or 'assessments'
  },
  controllerAs: 'subjectMatrixController',
  controller: function ($q, entityTranslateFilter, EntityService, Language, Nodes, Subject, SubjectCategory, SubjectMatrix, Subjects) {
    const subjectMatrixController = this;

    const manager = Nodes.manager({
      build: buildGroup,
      load: loadGroup
    });

    subjectMatrixController.$onInit = function () {
      subjectMatrixController.filter = {};
      subjectMatrixController.onLanguage = Language.onChange(() => {
        translateHeaders();
      });

      loadData();
    };

    function loadData() {
      subjectMatrixController.loading = true;

      const showStructure = subjectMatrixController.showStructure === true;

      $q.all([
        SubjectCategory.query({
          typeId: subjectMatrixController.typeId
        }).$promise,
        Subject.query({
          entityType: _.get(subjectMatrixController.entity, 'self.type'),
          entityId: _.get(subjectMatrixController.entity, 'id')
        }).$promise
      ]).then(([categories, subjects]) => {
        subjectMatrixController.categories = Subjects.getCategories(subjectMatrixController.typeId, categories, subjects);
        subjectMatrixController.filterValues = [];

        setOptionGroups();

        return manager.init({
          entity: subjectMatrixController.entity,
          active: true,
          groupColumns: subjectMatrixController.groupColumns,
          getRoots: showStructure ? undefined : loadStudy
        }).then((groups) => {
          subjectMatrixController.groups = groups;
          search();
        });
      }).finally(() => {
        subjectMatrixController.loading = false;
      });
    }

    function translateHeaders() {
      subjectMatrixController.categories = Subjects.translateCategoriesAndSubjects(subjectMatrixController.categories);

      setOptionGroups();
    }

    function setOptionGroups() {
      const subjectGroups = Subjects.getSubjectGroups(subjectMatrixController.categories, []);
      subjectMatrixController.subjectGroups = _.get(subjectGroups, 'subjects');
    }

    function getHeaders(subjects) {
      const headers = [
        { code: 'code', name: 'code', valueType: 'STRING' },
        { code: 'name', name: 'name', valueType: 'STRING' },
        { code: 'group', name: 'group', valueType: 'STRING' }
      ];

      const dynamicColumnHeaders = _.map(subjectMatrixController.moduleColumns, (column) => ({
        code: column.name,
        name: column.name,
        valueType: column.valueType
      }));

      const subjectColumnHeaders = _.map(subjects, (subject) => ({
        code: subject.type.code,
        name: subject.type.code,
        valueType: 'STRING'
      }));

      return _.concat(headers, dynamicColumnHeaders, subjectColumnHeaders);
    }

    subjectMatrixController.getRows = function () {
      const modules = _.flatMap(subjectMatrixController.groups, getModules);
      return $q.resolve(modules);
    };

    function getModules(node, parent) {
      if (node.open !== true) {
        return [];
      }

      const context = _.concat(parent || [], [node.code]);
      const modules = _(node.modules)
        .filter({ matches: true })
        .map((module) => {
          let row = {
            code: module.code,
            name: entityTranslateFilter(module),
            group: _.join(context, ', ')
          };

          _.forEach(subjectMatrixController.moduleColumns, (column) => {
            row[column.name] = _.get(module, 'values')[column.name];
          });

          _.forEach(subjectMatrixController.subjects, (subject) => {
            const typeId = `${subject.type.id}`;
            row[subject.type.code] = _(module.subjects[typeId]).map('code').join(', ');
          });

          return row;
        })
        .value();

      const children = _.flatMap(node.children, (child) => getModules(child, context));
      return _.concat(modules, children);
    }

    subjectMatrixController.setExpanded = function (expanded) {
      return manager.expand(subjectMatrixController.groups, expanded);
    };

    function updateGroup(node) {
      node.matches = true;

      if (node.loaded === true) {
        _.forEach(node.modules, updateObject);
        _.forEach(node.children, updateGroup);

        const children = _.concat(node.modules, node.children);
        node.matches = !isFiltered() || _.some(children, { matches: true });
      } else {
        node.matches = !isFiltered();
      }
    }

    subjectMatrixController.toggle = function (node) {
      if (node.expandable === true) {
        manager.toggle(node).then(() => {
          if (node.type === 'module-group') {
            _.forEach(subjectMatrixController.groups, updateGroup);
          } else if (node.type === 'module') {
            loadModule(node);
          }
        });
      }
    };

    function loadStudy() {
      const params = {
        fields: _.map(subjectMatrixController.moduleColumns, 'id'),
        attribute: _.upperCase(subjectMatrixController.displayType)
      };

      const entityPath = EntityService.getEntityPath(subjectMatrixController.entity);
      params[entityPath] = subjectMatrixController.entity.id;

      return SubjectMatrix.query(params).$promise.then((modules) => {
        const group = {
          id: subjectMatrixController.entity.id,
          loaded: true,
          open: true
        };

        setModules(group, modules);
        return [group];
      });
    }

    function loadGroup(group) {
      return SubjectMatrix.query({
        moduleGroupId: group.id,
        fields: _.map(subjectMatrixController.moduleColumns, 'id'),
        attribute: _.upperCase(subjectMatrixController.displayType)
      }).$promise.then((modules) => {
        setModules(group, modules);
        return group;
      });
    }

    function setModules(group, modules) {
      group.modules = _.map(modules, (module) => 
        buildObject(module, 'module', group)
      );
      group.hasModules = hasModules(group);
      updateGroup(group);
    }

    function hasModules(group) {
      const found = _.isArray(group.modules) && group.modules.length > 0;
      if (found) {
        return true;
      }

      return _.isArray(group.children) && _.some(group.children, hasModules);
    }

    function buildGroup(group, parent) {
      const result = Nodes.build(group, parent);
      updateGroup(result);
      return _.extend(result, {
        type: 'module-group',
        expandable: true,
        owned: true
      });
    }

    function buildObject(object, type, parent) {
      _.forEach(object.subjects, (values) => {
        _.forEach(values, addFilterValue);
      });

      const result = _.extend(object, {
        type,
        level: angular.isDefined(parent.level) ? parent.level + 1 : 0,
        periods: _(object.offerings).map('name').join(', '),
        matchingSubjectsCount: getMatchingSubjectsCount(object),
        expandable: type === 'module'
      });

      updateObject(result);
      return result;
    }

    function addFilterValue(value) {
      const found = _.find(subjectMatrixController.filterValues, { id: value.id });
      if (!found && value.id > 0) {
        subjectMatrixController.filterValues.push(angular.copy(value));
      }
    }

    function getMatchingSubjectsCount(object) {
      const typeIds = _(subjectMatrixController.subjects)
        .filter((subject) => {
          return _.isEmpty(subjectMatrixController.filter.subjectIds) || subject.matches;
        })
        .map('type.id')
        .value();

      return _(typeIds)
        .filter((typeId) => {
          const values = object.subjects[typeId];
          return angular.isDefined(values);
        })
        .size();
    }

    function loadModule(module) {
      if (angular.isUndefined(module.objectives)) {
        module.loading = true;

        SubjectMatrix.query({
          moduleId: module.id,
          attribute: _.upperCase(subjectMatrixController.displayType)
        }).$promise.then((objectives) => {
          module.objectives = _.map(objectives, (objective) => 
            buildObject(objective, 'objective', module)
          );
        }).finally(() => {
          module.loading = false;
        });
      }
    }

    subjectMatrixController.onSubject = function (subject) {
      const subjectIds = _.get(subjectMatrixController.filter, 'subjectIds');

      subjectMatrixController.filter.subjectIds = _.xor(subjectIds, [subject.id]);
      subjectMatrixController.expandAndSearch();
    };

    subjectMatrixController.formatSubject = function (subject) {
      return subject.displayName;
    };

    subjectMatrixController.expandAndSearch = function () {
      let promise = $q.resolve();

      if (!subjectMatrixController.expanded) {
        subjectMatrixController.expandFilters = true;
        promise = subjectMatrixController.setExpanded(true);
      }

      promise.then(() => {
        search();
      });
    };

    function search() {
      const subjectIds = _.get(subjectMatrixController.filter, 'subjectIds');

      _.forEach(subjectMatrixController.categories, (category) => {
        _.forEach(category.subjects, (subject) => {
          const selected = _.includes(subjectIds, subject.id);
          subject.filtered = selected;
          subject.matches = selected;
        });
      });

      subjectMatrixController.subjects = Subjects.getSubjects(subjectMatrixController.categories);

      manager.traverse(subjectMatrixController.groups, updateGroup);

      subjectMatrixController.headers = getHeaders(subjectMatrixController.subjects);
    }

    function updateObject(object) {
      object.matchingSubjectsCount = getMatchingSubjectsCount(object);

      const typeIds = _(subjectMatrixController.subjects)
        .filter('matches')
        .map('type.id')
        .map((id) => `${id}`)
        .value();

      const filtered = isFiltered();
      object.matches = !filtered || _.some(object.subjects, (attributes, typeId) => {
        return hasValue(attributes) && (_.isEmpty(typeIds) || _.includes(typeIds, typeId));
      });

      _.forEach(subjectMatrixController.subjects, (subject) => {
        const typeId = _.get(subject, 'type.id');

        if (object.subjects[typeId]) {
          if (!_.includes(subject.matchingModules, object.id)) {
            subject.matchingModules.push(object.id);
          }
        } else {
          subject.matchingModules = _.without(subject.matchingModules, object.id);
        }
      });

      return object;
    }

    function isFiltered() {   
      if (_.isEmpty(subjectMatrixController.filter)) {
        return false;
      }
      
      return _.some(subjectMatrixController.filter, (value) => {
        if (_.isArray(value)) {
          return !_.isEmpty(value);
        }

        return angular.isDefined(value);
      });
    }

    function hasValue(attributes) {
      const valueId = _.get(subjectMatrixController.filter, 'valueId');
      return angular.isUndefined(valueId) || _.some(attributes, {
        id: valueId
      });
    }

    subjectMatrixController.onFilter = function (filterValue) {
      subjectMatrixController.filter.valueId = _.get(filterValue, 'id');
      subjectMatrixController.expandAndSearch();
    };
  }
});
