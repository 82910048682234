'use strict';

angular.module('uasApp').component('objectiveReportPage', {
  bindings: {
    entity: '<',
    operations: '<',
    page: '<'
  },
  templateUrl: 'es6/objective/report/objective.report.page.html',
  controllerAs: 'objectiveReportPageController',
  controller: function (Page) {
    const objectiveReportPageController = this;

    objectiveReportPageController.$onInit = function () {
      objectiveReportPageController.typeId = Page.getParameterAsInt(objectiveReportPageController.page, 'type');
    };
  }
});