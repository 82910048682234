'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:notificationTemplateInput
 * @description Component for editing a notification template.
 */
angular.module('uasApp').component('notificationTemplateInput', {
  templateUrl: 'es6/admin/notification/templates/notification.template.input.html',
  bindings: {
    eventType: '<?',
    templateId: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'notificationTemplateInputController',
  controller: function (Changes, Language, NotificationTemplate) {
    const notificationTemplateInputController = this;

    notificationTemplateInputController.$onInit = function () {
      notificationTemplateInputController.id = _.uniqueId('notification-template-input-');
      setRenderFunction();
    };

    function setRenderFunction() {
      notificationTemplateInputController.ngModelCtrl.$render = setContainers;
    }

    notificationTemplateInputController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'eventType')) {
        loadTags(notificationTemplateInputController.eventType);
      }

      if (Changes.hasChanged(changes, 'templateId') && angular.isDefined(notificationTemplateInputController.templateId)) {
        setContainers();
      }
    };

    function setContainers() {
      const currentContainers = notificationTemplateInputController.ngModelCtrl.$modelValue || [];

      notificationTemplateInputController.containers = _.map(Language.supported, (language) => {
        let model = _.find(currentContainers, { language });
        if (angular.isUndefined(model)) {
          model = { language };

        }
        model.key = `${notificationTemplateInputController.templateId}-${model.language}`; // Ensure re-rendering when templateId changes
        return model;
      });

      notificationTemplateInputController.onChange();
    }

    function loadTags(eventType) {
      return NotificationTemplate.tags({
        eventType: eventType
      }).$promise.then((tags) => {
        notificationTemplateInputController.tags = tags;
      });
    }

    notificationTemplateInputController.onChange = function() {
      notificationTemplateInputController.ngModelCtrl.$setViewValue(angular.copy(notificationTemplateInputController.containers));
    };
  }
});
