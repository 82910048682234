'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:planboardAttributeLegend
 * @description
 * planboardAttributeLegend Shows the attribute legend.
 */
angular.module('uasApp')
  .component('planboardAttributeLegend', {
    bindings: {
      attributeValues: '<',
      selectedValue: '<',
      onSelect: '&'
    },
    templateUrl: 'es6/planboard/planboard.attribute.legend.html',
    controllerAs: 'planboardAttributeLegendController'
  });