'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:studyCostPage
 * @description
 * studyCostPage Displays the study cost
 */
angular.module('uasApp')
  .component('studyCostPage', {
    bindings: {
      study: '<',
      page: '<',
      workflow: '<',
      operations: '<'
    },
    templateUrl: 'es6/cost/study.cost.page.html',
    controllerAs: 'studyCostWorkflowController',
    controller: function() {
      const studyCostWorkflowController = this;

      studyCostWorkflowController.$onInit = function() {
        studyCostWorkflowController.operationsToEdit = angular.isDefined(studyCostWorkflowController.workflow) ? ['EDIT_COST_WORKFLOW'] : ['EDIT_COST'];
      };
    }
});
