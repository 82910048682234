'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleGroupAdd
 * @description
 * # uasModuleGroupAdd
 * Component for adding a module(group) to a study.
 */
angular.module('uasApp').component('uasModuleGroupAdd', {
  bindings: {
    onAdd: '&',
    study: '<',
    uibModalInstance: '<',
    operations: '<',
    operationsToEdit: '<?',
    workflowMode: '<?'
  },
  templateUrl: 'es6/planboard/add/module.group.add.html',
  controllerAs: 'moduleGroupAddController',
  controller: function ($q, CustomField, EntityType, Message, ModuleGroup, GroupType, entityTranslateFilter, translateFilter, Tabs, Type, CreateManager) {
    const moduleGroupAddController = this;

    moduleGroupAddController.$onInit = function () {
      moduleGroupAddController.saveCallbacks = [];

      loadData().finally(() => {
        buildTabs();
      });
    };

    function loadData() {
      moduleGroupAddController.loading = true;
      return $q.all([
        CustomField.query({
          rootType: 'module-group',
          entityType: 'module-group'
        }).$promise,
        CustomField.query({
          rootType: 'study',
          entityType: 'study-module-group'
        }).$promise,
        EntityType.get({
          rootType: 'study',
          entityType: 'study-module-group'
        }).$promise,
        GroupType.query().$promise,
        getExcludeTypeIds()
      ]).then(([groupFields, fields, entityType, groupTypes, excludeTypeIds]) => {
        moduleGroupAddController.groupFields = groupFields;
        moduleGroupAddController.fields = fields;
        moduleGroupAddController.fields_ = _.keyBy(fields, 'name');
        moduleGroupAddController.includeTypes = EntityType.getIncludeTypes(entityType);

        moduleGroupAddController.excludeTypeIdsForCreate = excludeTypeIds.create;
        moduleGroupAddController.createTypes = Type.getAllowed(groupTypes, moduleGroupAddController.includeTypes, excludeTypeIds.create);

        moduleGroupAddController.excludeTypeIdsForReuse = excludeTypeIds.reuse;
        moduleGroupAddController.existingTypes = Type.getAllowed(groupTypes, moduleGroupAddController.includeTypes, excludeTypeIds.reuse);
      }).finally(() => {
        moduleGroupAddController.loading = false;
      });
    }

    function getExcludeTypeIds() {
      return GroupType.excluded({
        studyId: moduleGroupAddController.study.id
      }).$promise;
    }

    function buildTabs() {
      const tabs = [{
        title: 'Static.Modal.ModuleGroupAdd.New.Tab',
        route: 'study.cost.add-new-module-group',
        content: 'add-new-module-group.html',
        visible: moduleGroupAddController.createTypes.length > 0
      }, {
        title: 'Static.Modal.ModuleGroupAdd.Existing.Tab',
        route: 'study.cost.add-existing-module-group',
        content: 'add-existing-module-group.html',
        visible: moduleGroupAddController.existingTypes.length > 0
      }];

      Tabs.build(tabs, [], 'study').then((createdTabs) => {
        moduleGroupAddController.tabs = createdTabs;
        updateTabs();
      });
    }

    function updateTabs() {
      _.each(moduleGroupAddController.tabs, function (tab) {
        tab.active = Tabs.isActive(tab);
        if (tab.active) {
          moduleGroupAddController.tab = tab;
        }
      });
    }

    moduleGroupAddController.newGroup = {
      facultyId: moduleGroupAddController.study.facultyId,
      academicYearId: moduleGroupAddController.study.academicYearId,
      studyId: moduleGroupAddController.study.id,
      self: { type: 'module-group' }
    };

    /**
     * Populates the list of modules with the user input.
     */
    moduleGroupAddController.search = function (parameters) {
      parameters.excludeTypeIds = moduleGroupAddController.excludeTypeIdsForReuse;
      return ModuleGroup.search(parameters).$promise;
    };

    moduleGroupAddController.onSelect = function (groups) {
      moduleGroupAddController.groups = groups;
    };

    function getEntityName (entity) {
      return `<strong>${entityTranslateFilter(entity)}</strong>`;
    }

    /**
     * Adds module groups to the study.
     */
    moduleGroupAddController.add = function () {
      _.forEach(moduleGroupAddController.groups, add);
      moduleGroupAddController.uibModalInstance.close();
    };

    function add(data) {
      const group = angular.copy(data);
      group.required = moduleGroupAddController.required;

      moduleGroupAddController.onAdd({
        group, 
        study: moduleGroupAddController.study, 
        message: moduleGroupAddController.message 
      });

      Message.addSuccess(translateFilter('Static.Message.AddedGroupToTarget', {
        group: getEntityName(group),
        target: getEntityName(moduleGroupAddController.study)
      }));
    }

    /**
     * Adds a new group to the study.
     */
    moduleGroupAddController.addNew = function (group, message) {
      ModuleGroup.generate({
        message: message,
        moduleGroup: group
      }).$promise.then((result) => {
        group.id = result.entities[0].entity.id;
        group.self.id = group.id;
        return $q.all(executeSaveFunctions(group));
      }).then(() => {
        moduleGroupAddController.onCreate(group);
      });
    };

    moduleGroupAddController.onCreate = function(group) {
      CreateManager.onCreate(group, () => onAddSuccess(group));
    };

    function executeSaveFunctions(group) {
      return _(moduleGroupAddController.saveCallbacks)
        .filter((saveFn) => _.isFunction(saveFn))
        .map((saveFn) => $q.resolve(saveFn(group, moduleGroupAddController.message)))
        .value();
    }

    function onAddSuccess(group) {
      moduleGroupAddController.onAdd({
        group: group,
        study: moduleGroupAddController.study,
        message: moduleGroupAddController.message
      });

      moduleGroupAddController.uibModalInstance.close(Message.addSuccess(translateFilter('Static.Message.NewAddedGroupToTarget', {
        modulegroup: getEntityName(group),
        target: getEntityName(moduleGroupAddController.study)
      })));
    }

    /**
     * Closes the modal
     */
    moduleGroupAddController.cancel = function () {
      moduleGroupAddController.uibModalInstance.close();
    };
  }
});
