'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Link
 * @description
 * The Link service.
 */
angular.module('uasApp').factory('Link', function (UasResource) {
  const resource = UasResource('/links', {
    usage: {
      method: 'GET',
      url: '/links/usage',
      isArray: true
    }
  });

  resource.prepare = function (links, types, studyId) {
    return _(links).map((link) => {
      link.type = _.find(types, { id: link.typeId });
      link.entity.owned = _.get(link.entity.study, 'id') === studyId;
      link.target.owned = _.get(link.target.study, 'id') === studyId;
      return link;
    }).sortBy(['type.sequence', 'type.code', 'entity.code', 'id']).value();
  };

  return resource;
});
