'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processParticipantsPage
 * @description
 * Shows the process participants.
 */
angular.module('uasApp').component('processParticipantsPage', {
    bindings: {
        entity: '<',
        page: '<',
        process: '<'
    },
    templateUrl: 'es6/process/participants/process.participants.page.html',
    controllerAs: 'processParticipantsPageController',
    controller: function (Process) {
        const processParticipantsPageController = this;

        processParticipantsPageController.$onInit = function() {
          loadData();
        };

        function loadData() {
          processParticipantsPageController.loading = true;
          Process.children({
            parentId: processParticipantsPageController.process.type.id,
            academicYearId: processParticipantsPageController.process.year.id
          }).$promise.then((children) => {
            processParticipantsPageController.children = children;
          }).finally(() => {
            processParticipantsPageController.loading = false;
          });
        }
    }
});
