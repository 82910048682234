'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:notifications
 * @name uasApp.component:notifications
 * @description
 * The notifications component.
 */
angular.module('uasApp').component('notifications', {
    templateUrl: 'es6/app/notifications/notifications.html',
    controllerAs: 'notificationsController',
    controller: function ($interval, $uibModal, $window, Pageable, Notification) {
        const notificationsController = this;

        notificationsController.$onInit = function() {
            reset();
            updateBadge();
            notificationsController.onBadge = $interval(updateBadge, 60000);
        };

        notificationsController.$onDestroy = function() {
            if (_.isFunction(notificationsController.onBadge)) {
                notificationsController.onBadge();
            }
        };

        function updateBadge() {
            Notification.count().$promise.then((result) => {
                notificationsController.numberOfUnreadNotifications = result.count;
            });
        }

        // Adds a click listener to the document to close the notification menu when clicking outside of it.
        angular.element(document).click(function (event) {
            const target = angular.element(event.target);

            // If the click is not on notification menu or on the toggle, hide it.
            if (!target.closest('#notification-dropdown').length && !target.closest('#notification-container').length) {
                hideDropdown();
            }
        });

        // Toggles the visibility of the notification tray.
        notificationsController.toggleDropdown = function () {
            const dropdown = angular.element('#notification-dropdown');

            if (dropdown.hasClass('hidden')) {
                dropdown.removeClass('hidden');
                reset();
            } else {
                dropdown.addClass('hidden');
            }
        };

        // Hides the notification tray.
        function hideDropdown() {
            const dropdown = angular.element.find('#notification-dropdown');
            if (dropdown && dropdown.length > 0) {
                angular.element(dropdown).removeClass('hidden').addClass('hidden');
            }
        }

        function reset() {
            notificationsController.notifications = [];
            notificationsController.pageable = Pageable.of({
                order: 'sendDate',
                reverse: true
            });

            loadData();
        }

        function loadData() {
            const params = notificationsController.pageable.build();

            notificationsController.loading = true;
            Notification.page(params).$promise.then((notifications) => {
                notificationsController.notifications = _.concat(notificationsController.notifications, notifications.content);
                notificationsController.totalElements = notifications.totalElements;
            }).finally(() => {
                notificationsController.loading = false;
            });
        }

        notificationsController.next = function($event) {
            $event.stopPropagation();
            $event.preventDefault();

            notificationsController.pageable = notificationsController.pageable.next();
            loadData();
        };

        notificationsController.go = function(notification) {
            markAsRead(notification);

            if (!_.isEmpty(notification.url)) {
                $window.open(notification.url);
            } else {
                open(notification);
            }
        };

        notificationsController.open = function (notification, $event) {
            $event.stopPropagation();
            $event.preventDefault();

            markAsRead(notification);
            open(notification);
        };

        function open(notification) {
            hideDropdown();

            $uibModal.open({
                template: `
                    <notification-modal
                        notification="modalController.notification"
                        on-cancel="modalController.close()">
                    </notification-modal>
                `,
                controllerAs: 'modalController',
                controller: function($uibModalInstance) {
                    this.notification = angular.copy(notification);
                    this.close = function() {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        }

        function markAsRead(notification) {
            if (notification.read !== true) {
                Notification.markAsRead({
                    id: notification.id
                }, notification).$promise.then(() => {
                    notification.read = true;
                    updateBadge();
                });
            }
        }

        notificationsController.delete = function (notification, $event) {
            $event.stopPropagation();
            $event.preventDefault();

            Notification.delete({
                id: notification.id
            }).$promise.then(onDelete);
        };

        notificationsController.deleteAll = function () {
            Notification.deleteAll().$promise.then(onDelete);
        };

        function onDelete() {
            updateBadge();
            reset();
        }
    }
});
