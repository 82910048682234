'use strict';

angular.module('uasApp').component('filterOptions', {
  bindings: {
    inputId: '@',
    elements: '<',
    classes: '<?',
    dropdownClasses: '<?',
    dropdownAlign: '@?',
    inModal: '<?',
    isDisabled: '<?',
    isRequired: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/filter.options.html',
  controllerAs: 'filterOptionsController',
  controller: function ($element, $timeout, filterFilter, CustomField) {
    const filterOptionsController = this;

    filterOptionsController.$onInit = function () {
      filterOptionsController.resetFilter = {};
      filterOptionsController.ngModelCtrl.$render = function () {
        setSelected();
      };
      filterOptionsController.ngModelCtrl.$validators.required = (modelValue) => {
        return !filterOptionsController.isRequired || CustomField.hasValue(modelValue);
      };

      filterOptionsController.dropdownMenuId = `${filterOptionsController.inputId}-dropdownMenu`;
      filterOptionsController.noSelectOptionId = `${filterOptionsController.inputId}-option-noselect`;

      $timeout(() => {
        filterOptionsController.dropdownElement = $element.find(`#${filterOptionsController.dropdownMenuId}`);
        filterOptionsController.dropdownElement.on('keydown', keyDownHandler);
      });
    };

    filterOptionsController.$onDestroy = function () {
      if (filterOptionsController.dropdownElement) {
        filterOptionsController.dropdownElement.off('keydown', keyDownHandler);
      }
    };

    function keyDownHandler(event) {
      if (filterOptionsController.isOpen && (event.which === 38 || event.which === 40)) {
        event.preventDefault();
        event.stopPropagation();

        if (event.which === 38) {
          // up
          if (filterOptionsController.focusOption > 0) {
            filterOptionsController.focusOption--;
          } else {
            focusSearch();
            return;
          }
        } else if (event.which === 40) {
          // down
          if (filterOptionsController.focusOption < filterOptionsController.filteredOptions.length) {
            filterOptionsController.focusOption++;
          }
        }
        focusOption();
      }
    }

    function focusOption(index) {
      angular.element(`#${getFocusedOptionId(index)}`).find('a').eq(0).focus();
    }

    function getFocusedOptionId(index) {
      if (angular.isDefined(index)) {
        return index;
      }
      if (filterOptionsController.focusOption === 0) {
        return filterOptionsController.noSelectOptionId;
      }
      return filterOptionsController.filteredOptions[filterOptionsController.focusOption - 1].elementId;
    }

    function setSelected() {
      delete filterOptionsController.selected;

      const value = filterOptionsController.ngModelCtrl.$modelValue;
      if (value) {
        filterOptionsController.selected = _.find(filterOptionsController.options, {
          id: value.id
        });
      }
    }

    filterOptionsController.$onChanges = function (changes) {
      if (changes.elements) {
        filterOptionsController.options = angular.copy(filterOptionsController.elements);
        _.forEach(filterOptionsController.options, (option) => {
          option.elementId = `${filterOptionsController.inputId}-option-${option.id}`;
        });
        filterOptionsController.options = _.sortBy(filterOptionsController.options, 'name');

        setSelected();
        search();
      }
    };

    function search(term) {
      filterOptionsController.filteredOptions = filterFilter(filterOptionsController.options, term);
      filterOptionsController.focusOption = -1;
    }

    filterOptionsController.onFilter = function (term) {
      filterOptionsController.term = term;
      search(term);
    };

    filterOptionsController.onToggle = function (open) {
      filterOptionsController.isOpen = open;
      filterOptionsController.resetFilter.reset();
      search(filterOptionsController.term);
      if (open) {
        scroll();
        focusSearch();
      }
    };

    function scroll() {
      if (filterOptionsController.selected) {
        $timeout(() => {
          const element = angular.element(`#${filterOptionsController.selected.elementId}`);
          if (element[0]) {
            element[0].scrollIntoView({ block: 'center' });
          }
        });
      }
    }

    function focusSearch() {
      const element = angular.element(`#${filterOptionsController.inputId}-filter`);
      element[0].focus();
      filterOptionsController.focusOption = -1;
    }

    filterOptionsController.onSelect = function (option) {
      filterOptionsController.selected = option;
      filterOptionsController.ngModelCtrl.$setViewValue(option);
    };
  }
});
