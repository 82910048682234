'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:uasCurrentLanguage
 * @description
 * The uasCurrentLanguage component.
 */
angular.module('uasApp').component('uasCurrentLanguage', {
  templateUrl: 'es6/i18n/current.language.html',
  controllerAs: 'currentLanguageController',
  controller: function (Language, Settings) {
    const currentLanguageController = this;

    currentLanguageController.$onInit = function () {
      loadData();
      Language.onChange(loadData);
    };

    function loadData() {
      currentLanguageController.language = Language.get();
      currentLanguageController.supported = Language.supported;

      if (_.isEmpty(Settings.get('language')) && currentLanguageController.language) {
        currentLanguageController.setLanguage(currentLanguageController.language);
      }
    }

    currentLanguageController.setLanguage = function (language) {
      currentLanguageController.language = language;
      Language.set(language);
    };
  }
});
