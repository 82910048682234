'use strict';

angular.module('uasApp').component('loggedIn', {
  templateUrl: 'es6/report/statistics/logged.in.html',
  controllerAs: 'loggedInController',
  controller: function (Pageable, LoggedIn) {
    const loggedInController = this;

    loggedInController.$onInit = function () {
      loggedInController.pageable = Pageable.of({
        order: 'loginDateTime',
        reverse: true
      });
      loggedInController.search();
    };

    loggedInController.search = function () {
      loggedInController.loading = true;

      const params = loggedInController.pageable.build({
        success: loggedInController.success
      });
      
      LoggedIn.page(params).$promise.then((logins) => {
        loggedInController.logins = logins;
      }).finally(() =>
        loggedInController.loading = false
      );
    };
  }
});
