'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:PlanboardModal
 * @description
 * # PlanboardModal
 * Factory that registers all modal popovers for the planboard.
 */
angular.module('uasApp').factory('PlanboardModal', function($uibModal, Structures) {

    return { init };

    function init(controller) {

        /**
         * Opens a modal window with module group details
         * @param {Object} module A Module Group instance.
         */
        controller.viewModuleGroup = function(group, event, removeMode) {
            event.preventDefault();
            event.stopPropagation();

            $uibModal.open({
                templateUrl: 'es6/planboard/details/module.group.html',
                resolve: {
                    board: function() {
                        return controller.board;
                    },
                    mode: function() {
                        return controller.mode;
                    },
                    group: function() {
                        return group;
                    },
                    removeMode: function() {
                        return removeMode;
                    },
                    onLeave: function() {
                        return controller.onLeave;
                    }
                },
                controller: 'ModuleGroupDetailsModalCtrl'
            });
        };

        /**
         * Opens a modal window with module details
         * @param {Object} module A Module instance.
         */
        controller.viewModule = function(offering, module, group, event, removeMode) {
            event.preventDefault();
            event.stopPropagation();

            $uibModal.open({
                templateUrl: 'es6/planboard/details/module.modal.html',
                resolve: {
                    board: function() {
                        return controller.board;
                    },
                    group: function() {
                        return group;
                    },
                    module: function() {
                        return module;
                    },
                    offering: function() {
                        return offering;
                    },
                    periods: function() {
                        return controller.periods;
                    },
                    mode: function() {
                        return controller.mode;
                    },
                    removeMode: function() {
                        return removeMode;
                    },
                    onLeave: function() {
                        return controller.onLeave;
                    }
                },
                controller: 'ModuleDetailsModalCtrl'
            });
        };

        controller.moveModuleToOtherGroup = function(module, moduleGroupModule, event) {
          event.preventDefault();
          event.stopPropagation();

          Structures.moveModule({
            entity: controller.study, // Planboard is always shown for a study.
            moduleId: module.moduleId,
            studyId: controller.study.id,
            groupId: moduleGroupModule.moduleGroupId,
            open: controller.board.getOpenedGroups(), // Same format as Nodes.getOpen, a list of entity references.
            onSave: (targetId) => {
              // Returns moduleGroupId of targetGroup
              controller.board.refreshGroupByModuleGroupId(targetId);
              controller.board.refreshGroup(moduleGroupModule);
            }
          });
        };

        /**
         * Opens the modal to add a new module group to the study.
         * @param {Object} event The event that is being fired by clicking.
         */
        controller.addGroupToStudy = function(event) {
            event.preventDefault();
            event.stopPropagation();

            controller.onLeave(() => {
                return Structures.addGroupToStudy({
                    study: controller.study,
                    operations: controller.operations,
                    operationsToEdit: controller.operationsToEdit,
                    workflowMode: controller.workflowMode,
                    onAdd: function(group, study) {
                        return controller.board.addModuleGroup(group, undefined, study);
                    }
                });
            });
        };

        /**
         * Opens the modal to add a new module group to the current group.
         * @param {Object} event The event that is being fired by clicking.
         */
        controller.addGroupToParent = function(parent, event) {
            if (parent.loaded) {
                event.preventDefault();
                event.stopPropagation();
            }

            controller.onLeave(() => {
                return Structures.addGroupToParent({
                    studyId: controller.study.id,
                    parent: parent.moduleGroupId,
                    operations: controller.operations,
                    operationsToEdit: controller.operationsToEdit,
                    workflowMode: controller.workflowMode,
                    onAdd: function(group) {
                        return controller.board.addModuleGroup(group, parent);
                    }
                });
            });
        };

        /**
         * Opens the modal to add a new or existing module to the current group.
         */
        controller.addModuleToGroup = function(group) {
            controller.onLeave(() => {
                return Structures.addModuleToGroup({
                    studyId: controller.study.id,
                    facultyId: controller.study.facultyId,
                    academicYearId: controller.study.academicYearId,
                    groupId: group.moduleGroupId,
                    operations: controller.operations,
                    operationsToEdit: controller.operationsToEdit,
                    onAddExistingModule: function(module) {
                        controller.board.addModule(module, group);
                    },
                    onAddNewModule: function(module) {
                        controller.board.addModule(module, group);
                    }
                });
            });
        };
    }
});
