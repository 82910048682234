'use strict';

angular.module('uasApp').factory('GroupsReport', function ($resource, BackendService) {
  return $resource(BackendService.getBaseUrl() + '/report/module-groups', {}, {
    'moduleGroups': {
      url: BackendService.getBaseUrl() + '/report/module-groups',
      method: 'GET',
      isArray: false
    },
    'types': {
      url: BackendService.getBaseUrl() + '/report/module-groups/types',
      method: 'GET',
      isArray: true
    }
  });
});
