'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('qualification-types', {
    url: '/qualification-types',
    template: `
      <type-list
        validator='qualification-type'
        header-label='Static.Page.QualificationTypes'
        help-label='Static.Help.QualificationTypes'
        icon='fa-newspaper-o'
        resource='qualificationTypeController.resource'>
      </type-list>
    `,
    controllerAs: 'qualificationTypeController',
    controller: function (QualificationType) {
      const qualificationTypeController = this;
      qualificationTypeController.resource = QualificationType;
    },
    breadcrumb: {
      label: 'Static.Page.QualificationTypes',
      translate: true,
      root: true
    },
    admin: true
  });

});
