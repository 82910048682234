'use strict';

angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('deliveries', {
        url: '/deliveries',
        template: '<delivery-list></delivery-list>',
        breadcrumb: {
            label: 'Static.Page.Deliveries',
            root: true
        }
    });

    $stateProvider.state('resources', {
        url: '/resources',
        resolve: {
            operations: function (AuthService) {
                return AuthService.operations('academic-year', sessionStorage.academicYear);
            }
        },
        template: '<resource-list operations="resourceStateController.operations"></resource-list>',
        controllerAs: 'resourceStateController',
        controller: function (operations) {
            const resourceStateController = this;
            resourceStateController.operations = operations;
        },
        breadcrumb: {
            label: 'Static.Page.Resources',
            root: true
        },
        data: {
            secured: ['VIEW_RESOURCES']
        }
    });

    $stateProvider.state('resource', {
        url: '/resources/:id',
        template: '<resource resource="resourceController.resource" operations="resourceController.operations"></resource>',
        resolve: {
            resource: function ($q, $stateParams, Resource) {
                if (angular.isDefined($stateParams.id) && _.trim($stateParams.id).length > 0) {
                    return Resource.get({ id: $stateParams.id }).$promise;
                } else {
                    return $q.resolve({});
                }
            },
            operations: function (AuthService) {
                return AuthService.operations('academic-year', sessionStorage.academicYear);
            }
        },
        controllerAs: 'resourceController',
        controller: function (resource, operations) {
            const resourceController = this;
            resourceController.resource = resource;
            resourceController.operations = operations;
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RESOURCES']
        }
    });
});
