'use strict';

angular.module('uasApp').directive('enableButton', function() {
    return {
        restrict: 'E',
        scope: {
            btnType: '@?',
            btnSize: '@?',
            btnIconOnly: '=?',
            btnContext: '@?',
            type: '@?'
        },
        link: function (scope) {
            scope.btnContext = scope.btnContext || 'default';
        },
        templateUrl: 'es6/app/forms/buttons/enable.button.html'
    };
});
