'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:durationDropdown
 * @description
 * The duration dropdown contains the duration values of an activity element, for example exams.
 * The duration is determined by the external id of an activity element which contain the duration in minutes.
 * It is also possible to enter a custom duration in hours and minutes by selecting the 'Other' option in the dropdown.
 */
angular.module('uasApp').component('durationDropdown', {
  bindings: {
    selectId: '@',
    type: '@',
    period: '<',
    showDurationInMinutes: '<?',
    isRequired: '<?',
    isDisabled: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/schedule/duration.dropdown.html',
  controllerAs: 'durationDropdownController',
  controller: function ($q, minutesAsHoursFilter, entityTranslateFilter, translateFilter, Dates, Element) {
    const durationDropdownController = this;

    durationDropdownController.$onInit = function () {
      durationDropdownController.required = durationDropdownController.isRequired === true;

      durationDropdownController.ngModelCtrl.$formatters.push(format);
      durationDropdownController.ngModelCtrl.$render = onRender;
    };

    function format(value) {
      if (_.isString(value)) {
        return parseInt(value);
      } else {
        return value;
      }
    }

    function onRender() {
      durationDropdownController.value = durationDropdownController.ngModelCtrl.$viewValue;

      loadDurations().then(() => {
        if (angular.isDefined(durationDropdownController.value)) {
          const duration = _.find(durationDropdownController.durations, {
            value: durationDropdownController.value
          });

          durationDropdownController.duration = _.result(duration, 'value', 0);
          durationDropdownController.custom = durationDropdownController.duration === 0;
          durationDropdownController.hours = Math.floor(durationDropdownController.value / 60);
          durationDropdownController.minutes = durationDropdownController.value % 60;
        } else {
          durationDropdownController.custom = false;
          if (durationDropdownController.required) {
            selectDefault();
          }
        }
      });
    }

    function loadDurations() {
      if (durationDropdownController.durations) {
        return $q.resolve(durationDropdownController.durations);
      }

      durationDropdownController.loading = true;
      return Element.getActive(durationDropdownController.type).then((elements) => {
        return Dates.getActive(elements, durationDropdownController.period);
      }).then((durations) => {
        durationDropdownController.durations = _(durations).map((element) => {
          const minutes = parseInt(element.externalId);
          const name = durationDropdownController.showDurationInMinutes
                  ? (minutes + ' ' + translateFilter('Duration.Minutes'))
                  : entityTranslateFilter(element);
          const formatted = minutesAsHoursFilter(minutes);

          return {
            value: minutes,
            label: name || formatted,
            defaultSelected: element.defaultSelected
          };
        }).orderBy('value').value();

        durationDropdownController.durations.push({
          value: 0,
          label: translateFilter('Static.Tab.Schedule.Activities.Duration.Option.Other')
        });

        return durationDropdownController.durations;
      }).finally(() => {
        durationDropdownController.loading = false;
      });
    }

    function selectDefault() {
      const defaultValue = _(durationDropdownController.durations).filter('defaultSelected').map('value').find();
      if (angular.isDefined(defaultValue)) {
        durationDropdownController.onSelect(defaultValue);
      }
    }

    durationDropdownController.onSelect = function (value) {
      if (value > 0) {
        durationDropdownController.duration = value;
        durationDropdownController.value = value;
        durationDropdownController.ngModelCtrl.$setViewValue(value);
        durationDropdownController.custom = false;
      } else {
        durationDropdownController.duration = 0;
        durationDropdownController.custom = true;
        durationDropdownController.onValue();
      }
    };

    durationDropdownController.onValue = function () {
      if (_.isNumber(durationDropdownController.hours) || _.isNumber(durationDropdownController.minutes)) {
        const hours = _.isNumber(durationDropdownController.hours) ? durationDropdownController.hours : 0;
        const minutes = _.isNumber(durationDropdownController.minutes) ? durationDropdownController.minutes : 0;
  
        const value = (hours * 60) + minutes;
        durationDropdownController.value = value;
        durationDropdownController.ngModelCtrl.$setViewValue(value);
      } else {
        delete durationDropdownController.value;
        durationDropdownController.ngModelCtrl.$setViewValue(undefined);
      }
    };
  }
});
