'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:teDateTimeRangePicker
 * @description Wraps React component DateTimeRangePicker to enable ng-model binding.
 */
angular.module('uasApp').component('teDateTimeRangePicker', {
  bindings: {
    defaultFromDate: '<',
    defaultToDate: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'dateTimeRangePickerController',
  controller: function () {
    const dateTimeRangePickerController = this;

    dateTimeRangePickerController.$onInit = function () {
      dateTimeRangePickerController.ngModelCtrl.$render = function () {
        dateTimeRangePickerController.fromTo = dateTimeRangePickerController.ngModelCtrl.$modelValue;
      };
    };

    dateTimeRangePickerController.onChange = function (fromTo) {
      dateTimeRangePickerController.fromTo = fromTo;
      dateTimeRangePickerController.ngModelCtrl.$setViewValue(dateTimeRangePickerController.fromTo);
    };
  },
  template: `
    <date-time-range-picker
      default-from-date="dateTimeRangePickerController.defaultFromDate"
      default-to-date="dateTimeRangePickerController.defaultToDate"
      on-change="dateTimeRangePickerController.onChange(fromTo)">
    </date-time-range-picker>
  `
});
