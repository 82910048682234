'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleGroups
 * @description
 * uasModuleGroups Shows groups overview
 */
angular.module('uasApp')
    .component('uasModuleGroups', {
        bindings: {
            module: '<',
            operations: '<',
            workflowMode: '<?',
            isReadOnly: '<?',
            showRedirect: '<?',
            type: '<?'
        },
        templateUrl: 'es6/module/module.groups.html',
        controllerAs: 'moduleGroupsController',
        controller: function(Language, Pageable, ModuleGroupModule, Structures, Message, SecurityService, activeFilter) {
            const moduleGroupsController = this;

            moduleGroupsController.$onInit = function() {
                moduleGroupsController.readOnly = moduleGroupsController.isReadOnly === true;
                moduleGroupsController.pageable = Pageable.of({
                    order: 'moduleGroupCode'
                });

                moduleGroupsController.language = Language.get();
                moduleGroupsController.onLanguage = Language.onChange(() => {
                    moduleGroupsController.language = Language.get();
                });

                moduleGroupsController.operationsToEdit = moduleGroupsController.workflowMode === true ?
                    ['EDIT_MODULE_GROUP_MODULE_WORKFLOW', 'EDIT_MODULE_GROUP_MODULE_GROUP_WORKFLOW', 'EDIT_STUDY_MODULE_GROUP_WORKFLOW']:
                    ['EDIT_MODULE_GROUP_MODULE', 'EDIT_MODULE_GROUP_MODULE_GROUP', 'EDIT_STUDY_MODULE_GROUP'];

                moduleGroupsController.editable = moduleGroupsController.isReadOnly !== true && SecurityService.isAllowed(moduleGroupsController.operationsToEdit, moduleGroupsController.operations);
                moduleGroupsController.active = true;

                loadData();
            };

            moduleGroupsController.$onDestroy = function() {
                moduleGroupsController.onLanguage();
            };

            function loadData() {
                moduleGroupsController.loading = true;
                ModuleGroupModule.query({
                    moduleId: moduleGroupsController.module.id
                }).$promise.then((groups) => {
                    const typeId = _.result(moduleGroupsController.type, 'id');
                    if (angular.isDefined(typeId)) {
                        return _.filter(groups, { moduleGroupTypeId: typeId });
                    }

                    return groups;
                }).then((groups) => {
                    moduleGroupsController.groups = activeFilter(groups, moduleGroupsController.active);
                }).finally(() => {
                    moduleGroupsController.loading = false;
                });
            }

            moduleGroupsController.onMode = function() {
                loadData();
            };

            moduleGroupsController.create = function() {
                Structures.addGroupToModule({
                    module: moduleGroupsController.module,
                    typeId: _.result(moduleGroupsController.type, 'id'),
                    onAdd: function(group) {
                        return ModuleGroupModule.save({
                            comment: {
                                message: ''
                            },
                            content: {
                                moduleGroupId: group.id,
                                moduleId: moduleGroupsController.module.id
                            }
                        }).$promise.then(() => {
                            Message.onSaved();
                            return loadData();
                        });
                    }
                });
            };

            moduleGroupsController.edit = function(node) {
                Structures.editModuleInStructure({
                    entity: {
                        id: node.id
                    },
                    module: moduleGroupsController.module,
                    operations: moduleGroupsController.operations,
                    operationsToEdit: moduleGroupsController.operationsToEdit_,
                    onSave: () => {
                        Message.onSaved();
                        return loadData();
                    }
                });
            };

            moduleGroupsController.remove = function(node) {
                return ModuleGroupModule.remove({
                    id: node.id,
                    comment: {
                      message: ''
                    }
                  }).$promise.then(() => {
                    Message.onRemoved();
                    loadData();
                });
            };
        }
    });