'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasExternalSystem
 * @uasExternalSystem
 * uasExternalSystem Manages the external systems
 */
angular.module('uasApp')
  .component('uasExternalSystem', {
    bindings: {
      system: '<',
      modalInstance: '<',
      onSave: '&'
    },
    templateUrl: 'es6/integration/system/external.system.html',
    controllerAs: 'externalSystemController',
    controller: function (ExternalSystem) {
      const externalSystemController = this;

      externalSystemController.$onInit = function() {
        ExternalSystem.query().$promise.then((systems) => {
          externalSystemController.systems = systems;
        });
      };

      externalSystemController.close = function() {
        externalSystemController.modalInstance.dismiss();
      };

      externalSystemController.save = function () {
        const body = externalSystemController.system;
        ExternalSystem.store(body).$promise.then((result) => {
          externalSystemController.onSave({ result });
          externalSystemController.close();
        });
      };

    }
  });
