'use strict';

angular.module('uasApp').component('historyItem', {
    bindings: {
        item: '<'
    },
    templateUrl: 'es6/changes/history.item.html',
    controllerAs: 'historyItemController',
    controller: function (translateFilter, configDateFilter) {
        const historyItemController = this;

        historyItemController.$onInit = function () {
            const item = historyItemController.item;

            historyItemController.title = translateFilter('Static.Modal.History.Title', { value: item.displayName });
            historyItemController.name = item.displayName;
            historyItemController.original = angular.copy(item.value);
            historyItemController.value = formatValues(item.value);
            historyItemController.type = getType(historyItemController.value);
        };

        function formatValues(container) {
            return {
                source: formatValue(container.source),
                target: formatValue(container.target)
            };
        }

        function formatValue(value) {
            if (value instanceof Date) {
                value = configDateFilter(value);
            } else if (_.isString(value)) {
                return stripHtml(value);
            }
            return value;
        }

        function stripHtml(value) {
            return value.replace(/(<([^>]+)>)/gi, '');
        }

        function getType(container) {
            const { source, target } = container;
            let type = '';

            if (_.isEmpty(source)) {
                if (!_.isEmpty(target)) {
                    type = 'create';
                }
            } else if (!_.isEmpty(target)) {
                type = 'modify';
            } else {
                type = 'remove';
            }
            return type;
        }
    }
});
