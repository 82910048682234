'use strict';

angular.module('uasApp').component('uasNews', {
  templateUrl: 'es6/app/news/news.html',
  controllerAs: 'newsController',
  controller: function ($uibModal, i18nFilter, AuthService, Pageable, Message, News) {
    const newsController = this;

    newsController.$onInit = function () {
      newsController.editable = AuthService.isAdmin();
      newsController.pageable = Pageable.of({
        order: 'creationDate',
        reverse: true
      });

      loadData();
    };

    function loadData() {
      const params = newsController.pageable.build();
      News.get(params).$promise.then((news) => {
        _.forEach(news.content, prepare);
        newsController.news = news;
      });
    }

    function prepare(news) {
      news.title = i18nFilter(news.titles);
      news.message = i18nFilter(news.messages);
      return news;
    }

    newsController.search = function() {
      loadData();
    };

    newsController.create = function () {
      newsController.edit({});
    };

    newsController.delete = function (news) {
      return News.remove(news).$promise.then(() => {
        Message.onRemoved();
        loadData();
      });
    };

    newsController.edit = function (news) {
      $uibModal.open({
        templateUrl: 'es6/app/news/news.edit.modal.html',
        controllerAs: 'newsModalController',
        controller: function ($uibModalInstance) {
          const newsModalController = this;

          newsModalController.$onInit = function () {
            newsModalController.news = angular.copy(news);
          };

          newsModalController.delete = function (entity) {
            newsController.delete(entity).then(() => {
              $uibModalInstance.dismiss();
            });
          };

          newsModalController.save = function () {
            News.store(newsModalController.news).$promise.then(() => {
              $uibModalInstance.dismiss();
              Message.onSaved();
              loadData();
            });
          };
        }
      });
    };
  }
});
