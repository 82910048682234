'use strict';

angular.module('uasApp').factory('Task', function(UasResource) {
    return UasResource('/tasks', {
        move: {
            url: '/tasks/move',
            method: 'POST',
            isArray: false
        },
        report: {
            url: '/tasks-report',
            method: 'GET',
            isArray: false
        }
    });
});
