'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessTimeline
 * @description
 * uasProcessTimeline Shows the process timeline.
 */
angular
  .module('uasApp')
  .component('uasProcessTimeline', {
    bindings: {
      entity: '<',
      process: '<',
      isReadOnly: '<',
      hideTitle: '<?',
      onChange: '&'
    },
    templateUrl: 'es6/process/progress/process.timeline.html',
    controllerAs: 'entityTimelineController',
    controller: function (ProcessModal, Process) {
      const entityTimelineController = this;

      entityTimelineController.$onChanges = () => {
        if (_.get(entityTimelineController.entity, 'self')) {
          loadData();
        }
      };

      function loadData() {
        entityTimelineController.loading = true;

        Process.timeline({
          id: entityTimelineController.process.id,
          entityId: entityTimelineController.entity.self.id,
          entityType: entityTimelineController.entity.self.type
        }).$promise.then(function (statuses) {
          entityTimelineController.statuses = _(statuses).map((status) => {
            const result = angular.copy(status.type);
            result.active = status.active;
            return result;
          }).sortBy('index').value();

          entityTimelineController.current = _.find(entityTimelineController.statuses, { active: true });
        }).finally(function () {
          entityTimelineController.loading = false;
        });
      }

      entityTimelineController.openStatus = function () {
        ProcessModal.open({
          entity: entityTimelineController.entity,
          statuses: entityTimelineController.statuses,
          status: entityTimelineController.current,
          process: entityTimelineController.process,
          readOnly: entityTimelineController.isReadOnly,
          callback: entityTimelineController.onChange
        });
      };
    }
  })
;
