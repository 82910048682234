'use strict';

angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('faculty', {
        url: '/faculty/{id:[0-9]{1,8}}',
        templateUrl: 'es6/organisation/faculty/faculty.html',
        controller: 'FacultyCtrl',
        abstract: true,
        entityType: 'faculty',
        breadcrumb: {},
        resolve: {
            faculty: function (Faculty, $stateParams) {
                const instance = this;
                return Faculty.get({
                    id: $stateParams.id
                }).$promise.then(function (faculty) {
                    instance.self.data = faculty;
                    return faculty;
                });
            },
            historyMode: function ($stateParams) {
                return $stateParams.historyMode === 'true';
            },
            operations: function (AuthService, faculty) {
                return AuthService.operations('faculty', faculty.id);
            }
        },
        params: {
            historyMode: {
                value: 'false'
            }
        }
    });

    $stateProvider.state('faculty.general', {
        url: '/general?historyMode',
        views: {
            'faculty': {
                template: `
                    <uas-faculty-general
                        faculty="faculty"
                        operations="operations">
                    </uas-faculty-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Faculty.General',
            label: 'Static.Help.Faculty.General'
        }
    });

    $stateProvider.state('faculty.description', {
        url: '/description',
        views: {
            'faculty': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('faculty');
                    }
                },
                controller: function($scope, types) {
                    $scope.types = types;
                },
                template: `
                    <description 
                        data-types="types"
                        data-entity-type="faculty"
                        data-entity-id="facultyId"
                        data-status="faculty.status"
                        data-operations="operations">
                    </description>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Faculty.Description',
            label: 'Static.Help.Faculty.Description'
        }
    });

    $stateProvider.state('faculty.relations', {
        url: '/relations',
        views: {
            'faculty': {
                template: `
                    <relations
                        entity-type="faculty"
                        entity-id="facultyId"
                        operations="operations">
                    </relations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Faculty.Relations',
            label: 'Static.Help.Faculty.Relations'
        }
    });

    $stateProvider.state('faculty.export', {
        url: '/export',
        views: {
            'faculty': {
                template: `
                    <export-list
                        faculty-id="facultyExportController.facultyId"
                        legend="facultyExportController.legend">
                    </export-list>
                `,
                controllerAs: 'facultyExportController',
                controller: function($stateParams, Parameter) {
                    this.facultyId = $stateParams.id;
                    this.legend = Parameter.getParameterAsList('faculty.export.legend');
                }
            }
        },
        breadcrumb: {},
        data: {
            secured: ['EXPORT'],
            title: 'Static.Tab.Faculty.Export',
            label: 'Static.Help.Faculty.Export'
        }
    });

    $stateProvider.state('faculty.changes', {
        url: '/changes',
        views: {
            'faculty': {
                resolve: {
                    facultyId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                controllerAs: 'facultyChangesController',
                controller: function(facultyId) {
                    this.entity = { type: 'faculty', id: facultyId };
                },
                template: `
                    <change-report
                        entity="facultyChangesController.entity">
                    </change-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Faculty.Changes',
            label: 'Static.Help.Faculty.Changes'
        }
    });

    $stateProvider.state('faculty.qualifications', {
        url: '/qualifications',
        views: {
            'faculty': {
                template: `
                    <qualifications-report
                        entity="faculty"
                        operations="operations">
                    </qualifications-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Faculty.Qualifications',
            label: 'Static.Help.Faculty.Qualifications'
        }
    });

    $stateProvider.state('faculty.studies', {
        url: '/studies',
        views: {
            'faculty': {
                template: `
                    <studies-report
                        entity="faculty"
                        operations="operations">
                    </studies-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Faculty.StudyReport',
            label: 'Static.Help.Faculty.StudyReport'
        }
    });

    $stateProvider.state('faculty.groups', {
        url: '/groups',
        views: {
            'faculty': {
                template: `
                    <groups-report
                        entity="faculty"
                        operations="operations">
                    </groups-reports>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_GROUPS'],
            title: 'Static.Tab.Faculty.ModuleGroupReport',
            label: 'Static.Help.Faculty.ModuleGroupReport'
        }
    });

    $stateProvider.state('faculty.modules', {
        url: '/modules',
        views: {
            'faculty': {
                template: `
                    <modules-report
                        entity="faculty"
                        operations="operations">
                    </modules-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Faculty.ModuleReport',
            label: 'Static.Help.Faculty.ModuleReport'
        }
    });

    $stateProvider.state('faculty.organisations', {
        url: '/organisations',
        views: {
            'faculty': {
                template: `
                    <uas-faculty-organisations
                        faculty="faculty"
                        operations="operations">
                    </uas-faculty-organisations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ORGANISATIONS'],
            title: 'Static.Tab.Faculty.Organisations',
            label: 'Static.Help.Faculty.Organisations'
        }
    });

    $stateProvider.state('faculty.offerings', {
        url: '/offerings?terminated',
        params: {
            terminated: {
                value: '',
                dynamic: true
            }
        },
        views: {
            'faculty': {
                controllerAs: 'facultyOfferingStateController',
                controller: function($stateParams, $state, $scope) {
                    this.terminated = $stateParams.terminated;
                    this.faculty = $scope.faculty;

                    this.onChange = function(parameters) {
                        $state.go('faculty.offerings', {
                            id: $stateParams.id,
                            terminated: parameters.terminated
                        });
                    };
                },
                template: `
                    <uas-faculty-offerings
                        faculty="facultyOfferingStateController.faculty"
                        terminated="facultyOfferingStateController.terminated"
                        on-change="facultyOfferingStateController.onChange(parameters)">
                    </uas-faculty-offerings>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_OFFERINGS'],
            title: 'Static.Tab.Faculty.Offerings',
            label: 'Static.Help.Faculty.Offerings'
        }
    });

    $stateProvider.state('faculty.methods', {
        url: '/methods',
        views: {
            'faculty': {
                template: `
                    <method-report
                        entity="faculty"
                        operations="operations">
                    </method-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_METHODS', 'VIEW_COST'],
            title: 'Static.Tab.Faculty.Methods',
            label: 'Static.Help.Faculty.Methods'
        }
    });

    $stateProvider.state('faculty.availability', {
        url: '/availability',
        views: {
            'faculty': {
                template: `
                    <availability-report
                        entity="faculty"
                        operations="operations">
                    </availability-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_AVAILABILITY'],
            title: 'Static.Tab.Faculty.Availability',
            label: 'Static.Help.Faculty.Availability'
        }
    });

    $stateProvider.state('faculty.cost', {
        url: '/cost',
        views: {
            'faculty': {
                template: `
                    <uas-cost
                        entity="faculty"
                        operations="operations">
                    </uas-cost>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST'],
            title: 'Static.Tab.Faculty.Cost',
            label: 'Static.Help.Faculty.Cost'
        }
    });

    $stateProvider.state('faculty.cost-divisions', {
        url: '/cost-divisions',
        views: {
            'faculty': {
                template: `
                    <uas-cost-division-report
                        entity="costDivisionsController.faculty"
                        operations="costDivisionsController.operations">
                    </uas-cost-division-report>
                `,
                controllerAs: 'costDivisionsController',
                controller: function (faculty, operations) {
                    this.faculty = faculty;
                    this.operations = operations;
                }
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST_DIVISION'],
            title: 'Static.Tab.Faculty.CostDivisions',
            label: 'Static.Help.Faculty.CostDivisions'
        }
    });

    $stateProvider.state('faculty.admin', {
        url: '/admin',
        views: {
            'faculty': {
                template: `
                    <uas-faculty-admin
                        faculty="faculty"
                        operations="operations">
                    </uas-faculty-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Faculty.Admin',
            label: 'Static.Help.Faculty.Admin'
        }
    });

    $stateProvider.state('faculty.studyable', {
        url: '/studyable',
        views: {
            'faculty': {
                resolve: {
                    facultyId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                template: `
                    <faculty-studyable-modules
                        faculty-id="facultyStudyableCtrl.facultyId">
                    </faculty-studyable-modules>
                `,
                controllerAs: 'facultyStudyableCtrl',
                controller: function(facultyId) {
                    this.facultyId = facultyId;
                }
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDYABLE'],
            title: 'Static.Tab.Faculty.Studyable',
            label: 'Static.Help.Faculty.Studyable'
        }
    });

    $stateProvider.state('faculty.scheduled-activities-report', {
        url: '/scheduled-activities-report?:studyId',
        views: {
            'faculty': {
                controllerAs: 'reportController',
                controller: function ($stateParams) {
                    const reportController = this;
                    if (angular.isDefined($stateParams.studyId)) {
                        reportController.studyId = parseInt($stateParams.studyId);
                    }
                },
                template: `
                    <faculty-scheduled-activities-report
                        faculty-id="faculty.id"
                        study-id="reportController.studyId">
                    </faculty-scheduled-activities-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_REPORT'],
            title: 'Static.Tab.Faculty.ScheduledActivities',
            label: 'Static.Tab.Faculty.ScheduledActivities'
        }
    });

    $stateProvider.state('faculty.method-parameters', {
        url: '/method-parameters',
        views: {
            'faculty': {
                template: `
                    <method-parameter-list
                        faculty-id="methodParameterStateController.facultyId"
                        operations="methodParameterStateController.operations">
                    </method-parameter-list>
                `,
                controllerAs: 'methodParameterStateController',
                controller: function ($stateParams, operations) {
                    this.facultyId = $stateParams.id;
                    this.operations = operations;
                }
            }
        },
        breadcrumb: {},
        parent: 'faculty',
        data: {
            secured: ['VIEW_COST'],
            title: 'Static.Tab.Faculty.MethodParameters',
            label: 'Static.Help.Faculty.MethodParameters'
        }
    });

    $stateProvider.state('faculty.specifications', {
        url: '/specifications',
        views: {
            'faculty': {
                template: `
                    <specification-report
                        faculty-id="faculty.id"
                        operations="operations">
                    </specification-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_SPECIFICATIONS'],
            title: 'Static.Tab.Faculty.Specifications',
            label: 'Static.Help.Faculty.Specifications'
        }
    });

    $stateProvider.state('faculty.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'faculty': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader
                        page-id="pageId"
                        entity="faculty"
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});
