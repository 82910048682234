'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Debug
 * @description
 * The Debug service.
 */
angular.module('uasApp').factory('Debug', function(Settings) {

    function isDebug() {
        return Settings.is('debug', true);
    }

    function setDebug(debug) {
        return Settings.set('debug', debug);
    }

    function onChange(callback) {
        return Settings.onChange('debug', callback);
    }

    return { isDebug, setDebug, onChange };

});
