'use strict';

angular.module('uasApp').component('emailSelector', {
  bindings: {
    recipients: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/notifications/email.selector.html',
  controllerAs: 'emailSelectorController',
  controller: function (EmailAddress) {
    const emailSelectorController = this;

    emailSelectorController.$onInit = function () {
      emailSelectorController.ngModelCtrl.$render = () => {
        emailSelectorController.selectedRecipients = emailSelectorController.ngModelCtrl.$modelValue;
      };
    };

    emailSelectorController.export = function () {
      const recipients = _.map(emailSelectorController.selectedRecipients, (recipient) =>
        _.find(emailSelectorController.recipients, { id: recipient.id })
      );
      EmailAddress.export(recipients);
    };

    emailSelectorController.onChange = function () {
      emailSelectorController.ngModelCtrl.$setViewValue(emailSelectorController.selectedRecipients);
    };
  }
});
