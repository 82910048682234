'use strict';


angular.module('uasApp').component('uasCommentModal', {
  bindings: {
    content: '<',
    onSave: '&',
    commentType: '<',
    modal: '<',
    form: '<'
  },
  templateUrl: 'es6/app/forms/buttons/comment.modal.html',
  controllerAs: 'commentModalController',
  controller: function ($stateParams) {
    const commentModalController = this;

    commentModalController.$onInit = function () {
      if (commentModalController.content && commentModalController.content.type === 'planboard') {
        commentModalController.dirtyChanges = {
          offerings: _.groupBy(commentModalController.content.offerings, 'changeType'),
          modules: _.groupBy(commentModalController.content.modules, 'changeType'),
          groups: _.groupBy(commentModalController.content.groups, 'changeType')
        };
      }
    };

    commentModalController.save = function () {
      const comment = buildComment();

      commentModalController.onSave({
        content: commentModalController.content,
        form: commentModalController.form,
        comment
      });

      if (commentModalController.form && commentModalController.form.$setPristine) {
        commentModalController.form.$setPristine();
      }

      commentModalController.modal.close();
    };

    function buildComment() {
      return {
        message: commentModalController.message,
        processId: $stateParams.processId
      };
    }

    commentModalController.cancel = function () {
      commentModalController.modal.close();
    };
  }
});
