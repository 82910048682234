'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodSchemaTable
 * @description
 * Displays the methods on a table.
 */
angular.module('uasApp').component('methodSchemaTable', {
    bindings: {
        category: '<',
        schemas: '<',
        entity: '<',
        root: '<',
        parent: '<?',
        period: '<?',
        editable: '<?',
        operations: '<',
        operationsToEdit: '<',
        active: '<?',
        navigate: '<?',
        classes: '<?',
        onToggle: '&',
        onChange: '&',
        workflowMode: '<?',
        childPage: '<?',
        columns: '<?'
    },
    templateUrl: 'es6/methods/schema/method.schema.table.html',
    transclude: true,
    controllerAs: 'methodSchemaTableController',
    controller: function ($q, Method, MethodSchema, CreateManager, Column, CustomField, EntityType, EntityService, Message, WorkflowModal, $uibModal) {
        const methodSchemaTableController = this;
        const MAX_COLUMNS = 2;

        methodSchemaTableController.$onInit = function () {
            methodSchemaTableController.rootType = EntityService.getType(methodSchemaTableController.entity);

            loadData();
        };

        function loadData() {
            methodSchemaTableController.loading = true;
            return $q.all([
                EntityType.get({
                    rootType: methodSchemaTableController.rootType,
                    entityType: 'method-schema'
                }).$promise,
                CustomField.query({
                    rootType: methodSchemaTableController.rootType,
                    entityType: 'method-schema'
                }).$promise,
                CustomField.query({
                    rootType: 'method'
                }).$promise
            ]).then(([type, schemaFields, methodFields]) => {
                methodSchemaTableController.includeTypes = EntityType.getIncludeTypes(type);
                methodSchemaTableController.fields = schemaFields;

                const columnFields = _.concat(schemaFields, methodFields);
                methodSchemaTableController.columns_ = Column.fromFields(columnFields, methodSchemaTableController.columns, MAX_COLUMNS);
            }).finally(() => {
                methodSchemaTableController.loading = false;
            });
        }

        methodSchemaTableController.toggle = function (schema) {
            methodSchemaTableController.onToggle({ schema });
        };

        methodSchemaTableController.restore = function (schema, message) {
            return MethodSchema.save({
                content: buildModel(schema),
                comment: {
                    message
                }
            }).$promise.then(() => {
                Message.onSaved();
                return onChange();
            });
        };

        methodSchemaTableController.create = function () {
            const moduleId = getModuleId();

            $uibModal.open({
                size: 'lg',
                template: `
                    <method-schema-create
                        schema="modalController.schema"
                        category="modalController.category"
                        parent="modalController.parent"
                        entity="modalController.entity"
                        module="modalController.module"
                        fields="modalController.fields"
                        include-types="modalController.includeTypes"
                        operations="modalController.operations"
                        operations-to-edit="modalController.operationsToEdit"
                        on-save="modalController.onSave(content, result)"
                        on-close="modalController.close()">
                    </method-schema-create>
                `,
                resolve: {
                    module: function (Module) {
                        return Module.find(moduleId).$promise;
                    }
                },
                controllerAs: 'modalController',
                controller: function ($uibModalInstance, module, feedbackObserver) {
                    const modalController = this;

                    modalController.$onInit = function () {
                        modalController.schema = {
                            academicYearId: sessionStorage.academicYear,
                            entity: methodSchemaTableController.entity.self,
                            method: {
                                moduleId
                            }
                        };

                        modalController.category = methodSchemaTableController.category;
                        modalController.parent = methodSchemaTableController.parent;
                        modalController.entity = methodSchemaTableController.entity;
                        modalController.module = module;
                        modalController.fields = methodSchemaTableController.fields;
                        modalController.includeTypes = methodSchemaTableController.includeTypes;
                        modalController.operations = methodSchemaTableController.operations;
                        modalController.operationsToEdit = methodSchemaTableController.operationsToEdit;
                    };

                    modalController.onSave = function (content, result) {
                        feedbackObserver.dataChanged();
                        Message.onSaved();
                        onCreate(content, result);
                        modalController.close();
                    };

                    modalController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };

        function getModuleId() {
            const entityType = EntityService.getType(methodSchemaTableController.entity);
            return entityType === 'module' ? methodSchemaTableController.entity.id : methodSchemaTableController.entity.moduleId;
        }

        function onCreate(content, result) {
            if (angular.isUndefined(content.methodId)) {
                const created = _(result.entities).map('entity').map('method').head();
                CreateManager.onCreate(created, onChange);
            }

            return onChange();
        }

        function onChange() {
            return methodSchemaTableController.onChange({
                parent: methodSchemaTableController.parent
            });
        }

        function buildModel(schema) {
            const model = angular.copy(schema);
            model.methodId = _.result(schema, 'method.id');
            model.choiceTypeId = _.result(schema, 'choiceType.id');
            return model;
        }

        methodSchemaTableController.edit = function (schema) {
            $uibModal.open({
                size: 'lg',
                template: `
                    <method-schema-edit
                        schema="modalController.schema"
                        fields="modalController.fields"
                        operations="modalController.operations"
                        operations-to-edit="modalController.operationsToEdit"
                        on-save="modalController.onSave(content, result)"
                        on-close="modalController.close()">
                    </method-schema-edit>
                `,
                controllerAs: 'modalController',
                controller: function ($uibModalInstance, feedbackObserver) {
                    const modalController = this;

                    modalController.$onInit = function () {
                        modalController.schema = buildModel(schema);
                        modalController.fields = methodSchemaTableController.fields;
                        modalController.operations = methodSchemaTableController.operations;
                        modalController.operationsToEdit = methodSchemaTableController.operationsToEdit;
                    };

                    modalController.onSave = function () {
                        feedbackObserver.dataChanged();
                        Message.onSaved();
                        onChange();
                        modalController.close();
                    };

                    modalController.close = function () {
                        $uibModalInstance.dismiss();
                    };
                }
            });
        };

        methodSchemaTableController.editMethod = function (schema) {
            WorkflowModal.open({
                entityType: 'method',
                entity: angular.copy(schema.method),
                period: methodSchemaTableController.period,
                onCancel: methodSchemaTableController.onChange,
                onSave: function (content, comment) {
                    return Method.save({ comment, content }).$promise.then(() => {
                        Message.onSaved();
                        return onChange();
                    });
                },
                scope: {
                    root: methodSchemaTableController.root,
                    category: methodSchemaTableController.category,
                    type: schema.type,
                    operationsToEdit: methodSchemaTableController.workflowMode ? ['EDIT_METHODS_WORKFLOW'] : ['EDIT_METHODS']
                },
                template: `
                    <method-edit
                        method="workflowModalStateController.entity"
                        root="workflowModalStateController.root"
                        category="workflowModalStateController.category"
                        type="workflowModalStateController.type"
                        operations="workflowModalStateController.operations"
                        operations-to-edit="workflowModalStateController.operationsToEdit"
                        on-cancel="workflowModalStateController.onCancel()"
                        on-submit="workflowModalStateController.onSave(method, comment)">
                    </method-edit>
                `
            });
        };

        methodSchemaTableController.delete = function (schema, message) {
            return MethodSchema.remove({
                id: schema.id,
                comment: {
                    message
                }
            }).$promise.then(() => {
                Message.onRemoved();
                methodSchemaTableController.onChange();
            });
        };

        methodSchemaTableController.onActivity = function (schema) {
            onChange(schema);
        };
    }
});
