'use strict';

angular.module('uasApp').component('assignmentListPage', {
    bindings: {
        entity: '<',
        page: '<'
    },
    templateUrl: 'es6/staffing/assignment/assignment.list.page.html',
    controllerAs: 'assignmentListPageController',
    controller: function (AuthService, Organisation, Page) {
        const assignmentListPageController = this;

        assignmentListPageController.$onInit = function () {
            assignmentListPageController.showInactive = Page.getParameterAsBoolean(assignmentListPageController.page, 'showInactive', false);
            loadData();
        };

        assignmentListPageController.$onChanges = function () {
            assignmentListPageController.organisationId = _.result(assignmentListPageController, 'entity.organisationId');
            assignmentListPageController.load();
        };

        assignmentListPageController.load = function () {
            delete assignmentListPageController.operations;
            assignmentListPageController.organisation = _.find(assignmentListPageController.organisations, {
                id: assignmentListPageController.organisationId
            });

            if (assignmentListPageController.organisation) {
                AuthService.operations('organisation', assignmentListPageController.organisation.id).then((operations) =>
                    assignmentListPageController.operations = operations
                );
            }
        };

        function loadData() {
            assignmentListPageController.loading = true;
            Organisation.query({
                facultyId: assignmentListPageController.entity.facultyId
            }).$promise.then((organisations) => {
                assignmentListPageController.organisations = organisations;
            }).finally(() => {
                assignmentListPageController.loading = false;
            });
        }
    }
});
