'use strict';

angular.module('uasApp').factory('maintenanceState', function() {

    let instance = {
        state: 'AVAILABLE'
    };

    function setState(state) {
        instance.state = state;
    }

    function getState() {
        return instance.state;
    }

    return { setState, getState };

});
