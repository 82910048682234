'use strict';

angular.module('uasApp').component('assignmentTypeahead', {
    templateUrl: 'es6/staffing/assignment/assignment.typeahead.html',
    bindings: {
        inputId: '@?',
        organisationId: '<',
        inModal: '<?'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controllerAs: 'assignmentTypeaheadController',
    controller: function(Assignment) {
        const assignmentTypeaheadController = this;

        assignmentTypeaheadController.$onInit = function() {
            assignmentTypeaheadController.ngModelCtrl.$render = () => {
                assignmentTypeaheadController.assignmentId = assignmentTypeaheadController.ngModelCtrl.$viewValue;
            };
        };

        assignmentTypeaheadController.search = function(term) {
            return Assignment.search({
                organisationId: assignmentTypeaheadController.organisationId,
                academicYearId: sessionStorage.academicYear,
                active: true,
                term,
                size: 5
            }).$promise.then((assignments) => {
                assignmentTypeaheadController.totalElements = assignments.totalElements;

                if (assignmentTypeaheadController.totalElements === 0) {
                    assignmentTypeaheadController.clear();
                }

                return _.map(assignments.content, (assignment) => {
                    const label = Assignment.getLabel(assignment);
                    return _.extend(assignment, { label });
                });
            });
        };

        assignmentTypeaheadController.onChange = function(assignment) {
            select(assignment.id);
        };

        assignmentTypeaheadController.onType = function(text) {
            if (_.isEmpty(text)) {
                assignmentTypeaheadController.clear();
            }
        };

        assignmentTypeaheadController.clear = function() {
            delete assignmentTypeaheadController.selected;
            select();
        };

        function select(assignmentId) {
            delete assignmentTypeaheadController.totalElements;

            assignmentTypeaheadController.assignmentId = assignmentId;
            assignmentTypeaheadController.ngModelCtrl.$setViewValue(assignmentTypeaheadController.assignmentId);
        }
    }
});
