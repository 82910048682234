'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingPage
 * @description
 * The meetingPage component.
 */
angular.module('uasApp').component('meetingPage', {
    templateUrl: 'es6/schedule/meeting/meeting.page.html',
    bindings: {
        entity: '<',
        page: '<',
        process: '<',
        workflow: '<?',
        flow: '<',
        isReadOnly: '<?',
        operations: '<'
    },
    controllerAs: 'meetingPageController',
    controller: function(Offering, Page) {
        const meetingPageController = this;

        meetingPageController.$onInit = function() {
            const type = _.result(meetingPageController.entity, 'self.type');
            if (type === 'module') {
                loadPeriods(meetingPageController.entity.id);
            }

            meetingPageController.readOnly = meetingPageController.isReadOnly || Page.getParameterAsBoolean(meetingPageController.page, 'readOnly', false);
            meetingPageController.operationsToEdit = angular.isDefined(meetingPageController.workflow) ? ['EDIT_SCHEDULE_WORKFLOW'] : ['EDIT_SCHEDULE'];
        };

        function loadPeriods(moduleId) {
            meetingPageController.loading = true;
            Offering.periods({
                entityType: 'module',
                entityId: moduleId,
                periodId: _.result(meetingPageController.process, 'period.id')
            }).$promise.then((periods) => {
                meetingPageController.periods = _.sortBy(periods, ['start', 'end', 'code']);
            }).finally(() => {
                meetingPageController.loading = false;
            });
        }
    }
});
