'use strict';

angular.module('uasApp').component('tabsList', {
    bindings: {
        rootType: '<?'
    },
    templateUrl: 'es6/app/tabs/tabs.list.html',
    controllerAs: 'tabsListController',
    controller: function(Tab, Message, Pageable, $state, $uibModal) {
        const tabsListController = this;

        tabsListController.$onInit = function() {
            tabsListController.pageable = Pageable.of({
                order: 'sequence'
            });

            loadData();
        };

        tabsListController.search = function() {
            $state.go('tabs', {
                rootType: tabsListController.rootType
            });
        };

        function loadData() {
            if (tabsListController.rootType) {
                tabsListController.loading = true;
                Tab.search({
                    rootType: tabsListController.rootType
                }).$promise.then((tabs) => {
                    tabsListController.tabs = _.map(tabs, (tab) => {
                        if (tab.route) {
                            const state = $state.get(tab.route);
                            tab.valid = _.isObject(state);
                        }

                        return tab;
                    });
                }).finally(() => {
                    tabsListController.loading = false;
                });
            } else {
                tabsListController.tabs = [];
            }
        }

        tabsListController.create = function() {
            open({
                rootType: tabsListController.rootType,
                visible: true
            });
        };

        tabsListController.edit = function(tab) {
            const model = _.extend(tab, {
                groupId: _.result(tab, 'group.id'),
                pageId: _.result(tab, 'page.id')
            });

            open(model);
        };

        function open(tab) {
            $uibModal.open({
                templateUrl: 'es6/app/tabs/tabs.modal.html',
                controllerAs: 'tabController',
                controller: function ($uibModalInstance) {
                    const tabController = this;
                    tabController.tab = angular.copy(tab);

                    tabController.onPage = function() {
                        delete tabController.tab.route;
                    };

                    tabController.onRoute = function() {
                        delete tabController.tab.pageId;
                    };

                    tabController.save = function() {
                        Tab.store(tabController.tab).$promise.then(() => {
                            loadData();
                            Message.onSaved();
                        }).finally(() => {
                            $uibModalInstance.dismiss();
                        });
                    };
                }
            });
        }

        tabsListController.remove = function(tab) {
            Tab.remove(tab).$promise.then(() => {
                loadData();
                Message.onRemoved();
            });
        };
    }
});
