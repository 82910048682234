'use strict';

angular.module('uasApp').component('methodsCompact', {
  bindings: {
    moduleId: '<'
  },
  templateUrl: 'es6/methods/methods.compact.html',
  controllerAs: 'methodsCompactController',
  controller: function (MethodSchema) {
    const methodsCompactController = this;

    methodsCompactController.$onInit = () => {
      MethodSchema.getMethods(methodsCompactController.moduleId).then((categories) => {
        methodsCompactController.categories = categories;
      });
    };
  }
});
