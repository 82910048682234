'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('assignment', {
    url: '/assignment/{id:[0-9]{1,8}}',
    resolve: {
      assignment: function(Assignment, $stateParams) {
        const instance = this;
        return Assignment.get({
            id: $stateParams.id
        }).$promise.then((assignment) => {
            instance.self.data = assignment;
            return assignment;
        });
      },
      operations: function (AuthService, $stateParams) {
        return AuthService.operations('assignment', $stateParams.id);
      }
    },
    controller: 'AssignmentCtrl',
    controllerAs: 'assignmentController',
    template: `
      <entity-tabs 
        view="assignment"
        entity="assignmentController.assignment"
        tabs="assignmentController.tabs"
        operations="assignmentController.operations"
      />
    `,
    abstract: true,
    entityType: 'assignment',
    redirectTo: 'assignment.general',
    breadcrumb: {}
  });

  $stateProvider.state('assignment.general', {
    url: '/general',
    views: {
      'assignment': {
        controllerAs: 'generalStateController',
        controller: function (assignment, operations) {
          this.assignment = assignment;
          this.operations = operations;
        },
        template: `
          <assignment-general
            assignment="generalStateController.assignment"
            operations="generalStateController.operations">
          </assignment-general>
        `
      }
    },
    breadcrumb: {},
    data: {
      title: 'Static.Tab.Assignment.General',
      label: 'Static.Help.Assignment.General'
    }
  });

  $stateProvider.state('assignment.availability', {
    url: '/availability',
    views: {
      'assignment': {
        controllerAs: 'availabilityStateController',
        controller: function (assignment, operations) {
          this.assignment = assignment;
          this.operations = operations;
        },
        template: `
          <availability-details
            assignment="availabilityStateController.assignment"
            operations="availabilityStateController.operations">
          </availability-details>
        `
      }
    },
    breadcrumb: {}
  });

  $stateProvider.state('assignment.relations', {
    url: '/relations',
    views: {
      'assignment': {
        controllerAs: 'relationsStateController',
        controller: function (assignment) {
          this.assignment = assignment;
        },
        template: `
          <relations-report
            entity="relationsStateController.assignment"
            is-target="true">
          </relations-report>`
      }
    },
    breadcrumb: {},
    data: {
      secured: ['VIEW_RELATIONS']
    }
  });

  $stateProvider.state('assignment.notes', {
    url: '/notes',
    views: {
      'assignment': {
        controllerAs: 'notesStateController',
        controller: function (assignment, operations) {
          this.assignment = assignment;
          this.operations = operations;
        },
        template: `
          <notes
            entity="notesStateController.assignment"
            operations="notesStateController.operations">
          </notes>
        `
      }
    },
    breadcrumb: {},
    data: {
      secured: ['VIEW_NOTES']
    }
  });

  $stateProvider.state('assignment.admin', {
    url: '/admin',
    views: {
      'assignment': {
        controllerAs: 'adminStateController',
        controller: function (assignment, operations) {
          this.assignment = assignment;
          this.operations = operations;
        },
        template: `
          <assignment-admin
            initial-values="adminStateController.assignment"
            operations="adminStateController.operations">
          </assignment-admin>
        `
      }
    },
    breadcrumb: {},
    data: {
      secured: ['ADMIN_OBJECT'],
      title: 'Static.Tab.Assignment.Admin',
      label: 'Static.Help.Assignment.Admin'
    }
  });

  $stateProvider.state('assignment.page', {
    url: '/page/{pageId:[0-9]{1,8}}',
    views: {
      'assignment': {
        controllerAs: 'adminStateController',
        controller: function (assignment, operations, $stateParams) {
          this.assignment = assignment;
          this.operations = operations;
          this.pageId = $stateParams.pageId;
        },
        template: `
          <page-loader 
            page-id="adminStateController.pageId" 
            entity="adminStateController.assignment" 
            operations="adminStateController.operations">
          </page-loader>
        `
      }
    },
    breadcrumb: {}
  });
});
