'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ModuleResource
 * @description
 * The ModuleResource service.
 */
angular.module('uasApp')
    .factory('ModuleResource', function(UasResource) {
        return UasResource('/module-resources');
    });
