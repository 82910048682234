'use strict';

angular.module('uasApp').filter('referenceType', function (i18nFilter, ReferenceType) {
  return function (identifier) {
    if (angular.isUndefined(identifier)) {
      return '';
    }

    const type = ReferenceType.getCached({ id: parseInt(identifier) });
    if (angular.isDefined(type)) {
      const name = i18nFilter(type.names);
      return (name || type.code) || identifier;
    } else {
      return identifier;
    }
  };
});
