'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityCardPage
 * @description
 * The activityCardPage component.
 */
angular.module('uasApp').component('activityCardPage', {
    templateUrl: 'es6/schedule/activity/card/activity.card.page.html',
    bindings: {
        entity: '<',
        process: '<',
        page: '<',
        workflow: '<?',
        operations: '<',
        isReadOnly: '<?'
    },
    controllerAs: 'activityCardPageController',
    controller: function(Offering, EntityService, Page, WorkflowValidator) {
        const activityCardPageController = this;

        activityCardPageController.$onInit = function() {
            activityCardPageController.readOnly = activityCardPageController.isReadOnly || Page.getParameterAsBoolean(activityCardPageController.page, 'readOnly', false);
            activityCardPageController.categoryId = Page.getParameter(activityCardPageController.page, 'category');
            activityCardPageController.entityType = EntityService.getType(activityCardPageController.entity);

            if (activityCardPageController.entityType === 'module') {
                loadPeriods(activityCardPageController.entity.id);
            }

            if (!activityCardPageController.readOnly) {
                WorkflowValidator.setValidity(() => activityCardPageController.valid);
            }
        };

        activityCardPageController.$onDestroy = function () {
            WorkflowValidator.reset();
        };

        function loadPeriods(moduleId) {
            activityCardPageController.loading = true;
            Offering.periods({
                entityType: 'module',
                entityId: moduleId,
                periodId: _.result(activityCardPageController.process, 'period.id')
            }).$promise.then((periods) => {
                activityCardPageController.periods = _.sortBy(periods, ['startDate', 'endDate', 'code']);
            }).finally(() => {
                activityCardPageController.loading = false;
            });
        }

        activityCardPageController.setValidity = function(period, valid) {
            period.valid = valid;
            validate();
        };

        function validate() {
            activityCardPageController.valid = _.every(activityCardPageController.periods, { valid: true });
        }
    }
});
