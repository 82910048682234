'use strict';

angular.module('uasApp').component('searchInput', {
  templateUrl: 'es6/app/search.input.html',
  bindings: {
    placeholder: '@?',
    inputSize: '@?',
    inputClasses: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'searchInputController',
  controller: function () {
    const searchInputController = this;

    searchInputController.$onInit = function () {
      searchInputController.ngModelCtrl.$render = function () {
        searchInputController.term = searchInputController.ngModelCtrl.$modelValue;
      };
    };

    searchInputController.clear = function () {
      delete searchInputController.term;
      searchInputController.onChange();
    };

    searchInputController.onChange = function() {
      searchInputController.ngModelCtrl.$setViewValue(searchInputController.term);
    };
  }
});
