'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:GroupCtrl
 * @description
 * # GroupCtrl
 * Group controller, used to switch between tabs.
 */
angular.module('uasApp').controller('GroupCtrl', function ($scope, $state, group, Tabs, GroupTabs, operations) {

    // Bind the id for the feedback directive
    $scope.groupId = $state.params.id;
    $scope.group = group;
    $scope.operations = operations;

    Tabs.build(GroupTabs.get(), operations, 'group', 'module-group', $scope.groupId).then((builtTabs) => {
        $scope.tabs = builtTabs;
    });
});
