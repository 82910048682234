'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:BackendService
 * @description
 * The BackendService service.
 */
angular.module('uasApp')
    .factory('BackendService', function($cookies) {
        var backendService = {};
        var baseUrl;

        backendService.getBaseUrl = function() {
            if (baseUrl === undefined) {
                baseUrl = $cookies['uas-backend'] ? $cookies['uas-backend'] : '/api';
            }
            return baseUrl;
        };

        return backendService;
    });
