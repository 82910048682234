'use strict';

angular.module('uasApp').component('stateSelect', {
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/navigation/state.select.html',
  controllerAs: 'stateSelectController',
  controller: function ($state) {
    const stateSelectController = this;

    stateSelectController.$onInit = function() {
      stateSelectController.states = getStates();
      stateSelectController.ngModelCtrl.$render = function () {
        stateSelectController.state = stateSelectController.ngModelCtrl.$modelValue;
      };
    };

    function getStates() {
        const states = $state.get();
        return _(states).map((state) => {
            return _.pick(state, 'name');
        }).filter((state) => !!state.name).sortBy('name').value();
    }

    stateSelectController.onSelect = function() {
      stateSelectController.ngModelCtrl.$setViewValue(stateSelectController.state);
    };
  }
});
