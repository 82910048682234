'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:expandButton
 * @description Shows chevron icon state based on a state.
 * @param {Boolean} isExpandable - If false, hides button but reserves space. Defaults true.
 * @param {Boolean} isHorizontal - If true, points up and down. Defaults false.
 * @param {Boolean} isOpen - Indicates if expanded.
 * @param {String} iconSize - Chevron size, 'xs' (no margin), 'sm' (default) or 'lg'.
 */
angular.module('uasApp').component('expandButton', {
  templateUrl: 'es6/app/buttons/expand.button.html',
  bindings: {
    isExpandable: '<?',
    isHorizontal: '<?',
    isOpen: '<',
    iconSize: '@'
  },
  controllerAs: 'expandButtonController',
  controller: function(Changes) {
    const expandButtonController = this;

    expandButtonController.$onInit = function() {
      setIconSize();
      setIsExpandable();
    };

    expandButtonController.$onChanges = function(changes) {
      if (Changes.hasChanged(changes, 'isExpandable')) {
        setIsExpandable();
      }
    };

    function setIconSize() {
      expandButtonController.iconSize_ = _.defaultTo(expandButtonController.iconSize, 'sm');
    }

    function setIsExpandable() {
      expandButtonController.isExpandable_ = _.defaultTo(expandButtonController.isExpandable, true);
    }
  }
});
