'use strict';

angular.module('uasApp').component('collapseHeader', {
  templateUrl: 'es6/app/collapse.header.html',
  bindings: {
    displayHeader: '<?',
    collapsible: '<?',
    label: '@?',
    classes: '@?',
    onChange: '&?',
    defaultOpen: '<?'
  },
  transclude: true,
  controllerAs: 'collapseHeaderController',
  controller: function () {
    const collapseHeaderController = this;

    collapseHeaderController.$onInit = function () {
      const open = collapseHeaderController.defaultOpen !== false;
      setOpen(open);
    };

    collapseHeaderController.toggle = function () {
      setOpen(!collapseHeaderController.open);
    };

    function setOpen(open) {
      collapseHeaderController.open = open;
      if (_.isFunction(collapseHeaderController.onChange)) {
        collapseHeaderController.onChange({ open: collapseHeaderController.open });
      }
    }
  }
});
