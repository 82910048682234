'use strict';

angular.module('uasApp').component('labelValues', {
    templateUrl: 'es6/page/label.values.html',
    bindings: {
        entity: '<',
        page: '<'
    },
    controllerAs: 'labelValuesController',
    controller: function (Page, prettyPrintFilter) {
        const labelValuesController = this;

        labelValuesController.$onInit = function() {
            loadData();
        };

        function loadData() {
            labelValuesController.loading = true;
            Page.labels({
                id: labelValuesController.page.id,
                entityId: labelValuesController.entity.self.id,
                entityType: labelValuesController.entity.self.type
            }).$promise.then((labels) => {
                labelValuesController.items = _(labelValuesController.page.items).sortBy('index').map((item) => {
                    if (angular.isDefined(item.field)) {
                        item.value = _(labels)
                            .filter((label) => label.field.id === item.field.id)
                            .map('value')
                            .map(prettyPrintFilter)
                            .first();
                    }

                    return item;
                }).value();
            }).finally(() => {
                labelValuesController.loading = false;
            });
        }
    }
});
