'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('activity', {
        url: '/activity/{id:[0-9]{1,8}}',
        resolve: {
            activity: function (Activity, AcademicYearHolder, $stateParams) {
                const instance = this;
                return Activity.get({
                    id: $stateParams.id
                }).$promise.then((activity) => {
                    instance.self.data = activity;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(activity.period.academicYearId).then(() => {
                        return activity;
                    });
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('activity', $stateParams.id);
            }
        },
        controllerAs: 'activityController',
        controller: function (activity, operations) {
            this.activity = activity;
            this.operations = operations;
            this.tabs = [{
                view: 'general',
                route: 'activity.general',
                readOnly: true
            }];
        },
        template: `
            <entity-tabs
                view="activity"
                entity="activityController.activity"
                tabs="activityController.tabs"
                retrieve-feedback-from-root="false"
                operations="activityController.operations">
            </entity-tabs>
        `,
        abstract: true,
        entityType: 'activity',
        redirectTo: 'activity.general',
        breadcrumb: {}
    });

    $stateProvider.state('activity.general', {
        url: '/general',
        views: {
            'activity': {
                controllerAs: 'activityGeneralStateController',
                controller: function (activity, operations) {
                    this.activity = activity;
                    this.operations = operations;
                },
                template: `
                    <page-loader 
                        route="general" 
                        entity="activityGeneralStateController.activity" 
                        operations="activityGeneralStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Activity.General',
            label: 'Static.Help.Activity.General'
        }
    });

    $stateProvider.state('activity.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'activity': {
                controllerAs: 'activityPageStateController',
                controller: function (activity, operations, $stateParams) {
                    this.activity = activity;
                    this.operations = operations;
                    this.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="activityPageStateController.pageId" 
                        entity="activityPageStateController.activity" 
                        operations="activityPageStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});
