'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowExpiration
 * @description
 * uasWorkflowExpiration Allows the user to jump to the last workflow page for expired courses
 */
angular.module('uasApp')
    .component('uasWorkflowExpiration', {
        bindings: {
            entity: '<',
            page: '<'
        },
        templateUrl: 'es6/workflow/workflow.expiration.html',
        controllerAs: 'expirationController',
        controller: function (Module) {
            const expirationController = this;

            expirationController.onChange = function() {
                const body = {
                    content: expirationController.entity,
                    comment: ''
                };

                Module.update(body);
            };
        }
    });
