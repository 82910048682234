'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Member
 * @description
 * The Member service.
 */
angular.module('uasApp')
    .factory('Member', function(UasResource) {
        return UasResource('/members');
    });
