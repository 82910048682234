'use strict';

angular.module('uasApp').component('objectiveList', {
  bindings: {
    entity: '<',
    operations: '<',
    operationsToEdit: '<?',
    category: '<?',
    columns: '<?',
    showAssessmentCode: '<',
    showSubjectCode: '<',
    selectAllSubjects: '<?',
    isReadOnly: '<?',
    inactive: '<?',
    hideAssessment: '<?',
    hideSubject: '<?'
  },
  templateUrl: 'es6/objective/objective.list.html',
  controllerAs: 'objectiveListController',
  controller: function ($uibModal, AuthService, EntityService, CustomField, Objective, Language, Pageable, Message, SecurityService, feedbackObserver, i18nFilter, entityTranslateFilter) {
    const objectiveListController = this;

    objectiveListController.$onInit = function () {
      objectiveListController.operationsToEdit_ = AuthService.buildOperationsToEdit(objectiveListController.operationsToEdit, 'EDIT_OBJECTIVES');
      const allowed = SecurityService.isAllowed(objectiveListController.operationsToEdit_, objectiveListController.operations);

      objectiveListController.editable = objectiveListController.isReadOnly !== true && allowed;
      objectiveListController.active = objectiveListController.inactive !== true;

      objectiveListController.pageable = Pageable.of({
        order: ['sequence', Language.getPropertyName()]
      });

      objectiveListController.onLanguage = Language.onChange(setDisplayName);

      if (_.isEmpty(objectiveListController.columns)) {
        objectiveListController.columns = ['name', 'subjects', 'assessments'];
      }

      loadData();
    };

    objectiveListController.$onDestroy = function () {
      objectiveListController.onLanguage();
    };

    function loadData() {
      objectiveListController.loading = true;
      CustomField.query({
        rootType: 'objective'
      }).$promise.then((fields) => {
        objectiveListController.fields = fields;
        objectiveListController.fields_ = _.keyBy(fields, 'name');
        
        return objectiveListController.search();
      });
    }

    objectiveListController.search = function () {
      const parameters = objectiveListController.pageable.build({
        entityType: EntityService.getType(objectiveListController.entity),
        entityId: objectiveListController.entity.id
      });

      objectiveListController.loading = true;
      return Objective.page(parameters).$promise.then((objectives) => {
        objectiveListController.objectives = objectives;
        setDisplayName();
        setFiltered();
      }).finally(() => {
        objectiveListController.loading = false;
      });
    };

    function setDisplayName() {
      _.forEach(objectiveListController.objectives.content, (objective) => {
        _.forEach(objective.subjects, (subject) => {
          const code = _.get(subject.type, 'code');
          let name = i18nFilter(subject.type.names) || '';

          if (angular.isDefined(subject.value)) {
            name = `${name} (${i18nFilter(subject.value.names) || subject.value.value})`;
          }

          subject.displayName = getDisplayName(code, name, objectiveListController.showSubjectCode);
        });

        _.forEach(objective.appraisals, (appraisal) => {
          const code = _.get(appraisal.assessment, 'code');
          const name = entityTranslateFilter(appraisal.assessment) || '';
          appraisal.displayName = getDisplayName(code, name, objectiveListController.showAssessmentCode);
        });

        objective.subjects = _.sortBy(objective.subjects, 'displayName');
        objective.appraisals = _.sortBy(objective.appraisals, 'displayName');
      });
    }

    function getDisplayName(prefix, name, showPrefix) {
      if (_.isEmpty(name)) {
        return prefix;
      } else if (_.isEmpty(prefix) || prefix === name || showPrefix === false) {
        return name;
      } else {
        return `${prefix} - ${name}`;
      }
    }

    objectiveListController.onMode = function () {
      setFiltered();
    };

    function setFiltered() {
      objectiveListController.filtered = _.filter(objectiveListController.objectives.content, (objective) =>
        objectiveListController.active !== true || objective.changeType !== 'REMOVE'
      );
    }

    objectiveListController.create = function () {
      open({
        owner: EntityService.toReference(objectiveListController.entity),
        academicYearId: sessionStorage.academicYear,
        subjects: [],
        appraisals: []
      });
    };

    objectiveListController.edit = function (objective) {
      const model = angular.copy(objective);
      open(model);
    };

    function open(objective) {
      $uibModal.open({
        size: 'lg',
        template: `
          <objective-edit
            objective="modalController.objective"
            category="modalController.category"
            entity="modalController.entity"
            fields="modalController.fields"
            operations="modalController.operations"
            operations-to-edit="modalController.operationsToEdit"
            show-assessment-code="modalController.showAssessmentCode"
            show-subject-code="modalController.showSubjectCode"
            select-all-subjects="modalController.selectAllSubjects"
            hide-assessment="modalController.hideAssessment"
            hide-subject="modalController.hideSubject"
            on-change="modalController.onChange()"
            on-cancel="modalController.close()">
          </objective-edit>`,
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.modal = $uibModalInstance;
            modalController.objective = objective;
            modalController.category = objectiveListController.category;
            modalController.entity = objectiveListController.entity;
            modalController.fields = objectiveListController.fields;
            modalController.operations = objectiveListController.operations;
            modalController.operationsToEdit = objectiveListController.operationsToEdit;
            modalController.showAssessmentCode = objectiveListController.showAssessmentCode;
            modalController.showSubjectCode = objectiveListController.showSubjectCode;
            modalController.selectAllSubjects = objectiveListController.selectAllSubjects;
            modalController.hideAssessment = objectiveListController.hideAssessment;
            modalController.hideSubject = objectiveListController.hideSubject;
          };

          modalController.onChange = function () {
            modalController.close();
            onSave();
          };

          modalController.close = function () {
            modalController.modal.dismiss();
          };
        }
      });
    }

    objectiveListController.restore = function (objective) {
      Objective.store({
        content: objective,
        comment: ''
      }).$promise.then(() => {
        onSave();
      });
    };

    objectiveListController.delete = function (objective, comment) {
      Objective.remove({
        comment: comment,
        id: objective.id
      }).$promise.then(() => {
        onRemove();
      });
    };

    function onSave() {
      feedbackObserver.dataChanged();
      Message.onSaved();
      objectiveListController.search();
    }

    function onRemove() {
      Message.onRemoved();
      feedbackObserver.dataChanged();
      objectiveListController.search();
    }
  }
});
