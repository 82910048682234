'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Type
 * @description
 * The Type service.
 */
angular.module('uasApp')
    .factory('Type', function (UasResource, EntityType) {
        const instance = UasResource('/types');

        instance.getAllowed = function(types, includeTypes, excludeTypeIds) {
          return _(types)
            .filter((type) => EntityType.isIncluded(includeTypes, type))
            .filter((type) => !_.includes(excludeTypeIds, type.id))
            .value();
        };

        return instance;
    });