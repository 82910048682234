'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:entityHistoric
 * @description
 * Wraps HTML with a historical label.
 */
angular.module('uasApp').component('entityHistoric', {
    bindings: {
        active: '<',
        entity: '<',
        changeType: '<',
        navigate: '<?'
    },
    controllerAs: 'entityHistoricController',
    templateUrl: 'es6/changes/entity.historic.html'
});