'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowWelcome
 * @description
 * uasWorkflowWelcome Welcomes to the workflow
 */
angular.module('uasApp')
  .component('uasWorkflowWelcome', {
    bindings: {
      entity: '<',
      page: '<',
      workflow: '<',
      process: '<'
    },
    templateUrl: 'es6/workflow/workflow.welcome.html',
    controllerAs: 'welcomeWorkflowController',
    controller: function(Language, Page) {
        const welcomeWorkflowController = this;

        welcomeWorkflowController.$onInit = function() {
            welcomeWorkflowController.showStatus = Page.getParameterAsBoolean(welcomeWorkflowController.page, 'showStatus', false);

            const video = _.result(welcomeWorkflowController.workflow, 'video');
            if (angular.isDefined(video)) {
                welcomeWorkflowController.video = _.find(welcomeWorkflowController.workflow.video.languages, {
                    language: Language.get()
                });
            }
        };
    }
});
