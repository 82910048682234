'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamAdminPage
 * @description teamAdminPage Admin page for managing teams
 */
angular.module('uasApp').component('teamsAdmin', {
  templateUrl: 'es6/team/teams.admin.html',
  bindings: {
    operations: '<'
  },
  controllerAs: 'teamsAdminController',
  controller: function () {
    const teamsAdminController = this;

    teamsAdminController.$onInit = function () {
      teamsAdminController.active = 'true';

      teamsAdminController.searchParams = {
        active: teamsAdminController.active
      };
    };

    teamsAdminController.search = function () {
      teamsAdminController.searchParams = {
        active: teamsAdminController.active,
        text: teamsAdminController.text
      };
    };
  }
});
