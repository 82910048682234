'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:calendarPeriodForm
 * @description
 * calendarPeriodForm edit a calendar period
 */
angular.module('uasApp').component('calendarPeriodForm', {
    bindings: {
        period: '<'
    },
    templateUrl: 'es6/calendar/period/calendar.period.form.html',
    controllerAs: 'calendarPeriodFormController'
});