'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ModulePreviewCtrl
 * @description
 * # ModulePreviewCtrl
 * Module preview controller used to display module information
 */
angular.module('uasApp').component('modulePreview', {
    bindings: {
        moduleId: '<'
    },
    templateUrl: 'es6/module/module.preview.html',
    controllerAs: 'modulePreviewController',
    controller: function ($q, Description, Module, Parameter) {
        const modulePreviewController = this;

        modulePreviewController.$onInit = () => {
            modulePreviewController.isLoading = true;
            $q.all([
                Description.getTypes('module').then((elements) => {
                    return _.filter(elements, { ignore: false });
                }),
                Module.get({
                    id: modulePreviewController.moduleId
                }).$promise,
                Parameter.load()
            ]).then(([elements, module]) => {
                modulePreviewController.module = module;
                modulePreviewController.htmlView = Parameter.getParameterValue('description.editor') === 'html';
                modulePreviewController.elements = elements;
            }).finally(() => {
                modulePreviewController.isLoading = false;
            });
        };
    }
});
