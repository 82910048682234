'use strict';

angular.module('uasApp').component('uasTimeleft', {
  templateUrl: 'es6/app/timeleft.html',
  bindings: {
    startDate: '<',
    endDate: '<'
  },
  controllerAs: 'timeleftController',
  controller: function () {
    const timeleftController = this;
    timeleftController.$onInit = loadData;

    function loadData() {
      timeleftController.result = calculate(timeleftController.startDate, timeleftController.endDate);
    }

    function calculate(startTime, endTime) {
      let result = {};

      if (angular.isDefined(startTime) && angular.isDefined(endTime)) {
        const duration = Math.max(endTime - startTime, 0);

        const currentTime = new Date().getTime();
        const progress = Math.max(currentTime - startTime, 0);
        const remaining = Math.max(endTime - currentTime, 0);

        const progressPercentage = (progress / duration) * 100;
        const remainingPercentage = Math.max(100 - progressPercentage, 0);

        const progressDays = toDays(progress); 
        const remainingDays = toDays(remaining); 

        result = _.extend(result, { progressPercentage, progressDays, remainingDays, remainingPercentage });
      }

      return result;
    }

    function toDays(millis) {
      return Math.ceil(millis / 86400000);
    }
  }
});
