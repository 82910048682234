'use strict';

angular.module('uasApp').component('activeSelect', {
  bindings: {
    classes: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/filter/active.select.html',
  controllerAs: 'activeSelectController',
  controller: function () {
    const activeSelectController = this;

    activeSelectController.$onInit = function () {
      activeSelectController.ngModelCtrl.$render = function () {
        activeSelectController.active = activeSelectController.ngModelCtrl.$modelValue;
      };
    };

    activeSelectController.onSelect = function () {
      activeSelectController.ngModelCtrl.$setViewValue(activeSelectController.active);
    };
  }
});
