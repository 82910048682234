'use strict';

angular.module('uasApp').component('sectionList', {
  bindings: {
    customType: '<'
  },
  templateUrl: 'es6/custom/sections/section.list.html',
  controllerAs: 'sectionListController',
  controller: function($uibModal, filterFilter, Message, Section) {
    const sectionListController = this;

    sectionListController.$onInit = function () {
      loadData();
    };

    function loadData() {
      sectionListController.loading = true;
      return Section.query({
        typeId: sectionListController.customType.id
      }).$promise.then((sections) => {
        sectionListController.sections = _.sortBy(sections, 'sequence');
        sectionListController.search();
      }).finally(() => {
        sectionListController.loading = false;
      });
    }

    sectionListController.search = () => {
      sectionListController.filteredSections = filterFilter(sectionListController.sections, sectionListController.searchText);
    };

    sectionListController.create = function () {
      openModal({
        typeId: sectionListController.customType.id
      });
    };

    sectionListController.edit = function (section) {
      openModal(section);
    };

    function openModal(section) {
      $uibModal.open({
        templateUrl: 'es6/custom/sections/section.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.section = angular.copy(section);

          modalController.save = function () {
            Section.save(modalController.section).$promise.then(() => {
              loadData();
              Message.addSuccessLabel('Static.Message.DataAdjusted');
            });
            $uibModalInstance.dismiss();
          };
        }
      });
    }

    sectionListController.remove = function (section) {
      Section.remove(section).$promise.then(() => {
        loadData();
        Message.addSuccessLabel('Static.Message.DataRemoved');
      });
    };
  }
});