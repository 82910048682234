'use strict';

/**
 * Select a subject to assign to a study or module. Also specify at which level it is achieved.
 */
angular.module('uasApp').component('subjectEdit', {
  templateUrl: 'es6/subjects/subject.edit.html',
  bindings: {
    subject: '<',
    rootEntity: '<?',
    categoryIds: '<?',
    operations: '<',
    operationsToEdit: '<',
    onSaved: '&',
    onCancel: '&'
  },
  controllerAs: 'subjectEditController',
  controller: function (Subject) {
    const subjectEditController = this;

    subjectEditController.$onInit = function () {
      subjectEditController.typeId = _.get(subjectEditController.subject, 'type.id');
    };


    subjectEditController.onCategory = function () {
      delete subjectEditController.subject.typeId;
    };

    subjectEditController.performSave = function (subject, comment) {
      let content = angular.copy(subject);
      delete content.type;
      if (!content.typeId) {
        content.typeId = subjectEditController.typeId;
      }

      Subject.store({
        content,
        comment
      }).$promise.then(() => {
        subjectEditController.onSaved();
      });
    };

    subjectEditController.cancel = function () {
      subjectEditController.onCancel();
    };
  }
});
