'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Nodes
 * @description
 * The Nodes factory.
 */
angular.module('uasApp')
    .factory('Nodes', function($q, ModuleGroup, EntityService) {

        const SORT = ['sequence', 'typeSequence', 'code', 'abbreviation', 'id'];

        function getOpen(items) {
            return _(items).filter({ open: true }).map((item) => {
                return _.concat([item.entity], getOpen(item.children));
            }).flatten().value();
        }

        function getNodes(items, value) {
            return _(items)
                .map((item) => {
                    const result = [];
                    if (_.find([item], value)) {
                        result.push(item);
                    }
                    return _.concat(result, getNodes(item.children, value));
                })
                .flatten()
                .value();
        }

        function build(node, parent) {
            return _.extend(node, {
                level: angular.isDefined(parent) ? _.get(parent, 'level', 0) + 1 : 0,
                open: false,
                parentId: _.get(parent, 'id'),
                typeSequence: _.get(node, 'groupType.sequence', -1),
                self: { type: 'module-group', id: node.id }
            });
        }

        function manager(params) {
            const instance = {};

            instance.build = _.get(params, 'build', build);

            instance.init = function(values) {
                instance.active = _.get(values, 'active', true);
                instance.groupColumns = values.groupColumns;
                instance.loadAll = _.get(values, 'loadAll', false);

                return getRoots(values).then((roots) => {
                    const promises = _.map(roots, instance.prepare);
                    return $q.all(promises).then(() => {
                        return roots;
                    });
                });
            };

            function getRoots(values) {
                const delegate = values.getRoots;
                if (_.isFunction(delegate)) {
                    return delegate();
                }

                const entity = _.result(values, 'entity');
                const entityType = EntityService.getType(entity);
                if (entityType === 'study') {
                    return ModuleGroup.tree({
                        studyId: _.result(entity, 'id'),
                        active: instance.active,
                        fields: _.map(instance.groupColumns, 'id')
                    }).$promise.then((nodes) =>
                        _(nodes).map((node) => instance.build(node)).sortBy(SORT).value()
                    );
                } else if (entityType === 'module-group') {
                    return $q.resolve([
                        instance.build(entity)
                    ]);
                } else {
                    return $q.resolve([]);
                }
            }

            instance.toggle = function(node, open) {
                node.open = angular.isDefined(open) ? open : node.open !== true;
                return instance.prepare(node);
            };

            instance.expand = function(nodes, open, predicate, callback) {
                if (open === true || (instance.loadAll && open !== false)) {
                    return expand(nodes, predicate, callback);
                } else {
                    _.forEach(nodes, close);
                    return $q.resolve();
                }
            };

            function expand(nodes, predicate, callback) {
                const promises = _(nodes)
                    .filter((node) => matches(node, predicate))
                    .map((node) => setOpen(node, predicate, callback))
                    .value();

                return $q.all(promises);
            }

            function setOpen(node, predicate, callback) {
                node.open = true;
                return instance.prepare(node).then((data) => {
                    if (_.isFunction(callback)) {
                        return callback(data);
                    }

                    return data;
                }).then(() => {
                    return expand(node.children, predicate, callback);
                });
            }

            function matches(node, predicate) {
                if (_.isFunction(predicate)) {
                    return predicate(node) === true;
                }

                return true;
            }

            function close(node) {
                node.open = false;
                _.forEach(node.children, close);
            }

            instance.prepare = function(node) {
                const needsLoading = node.open === true || instance.loadAll;
                const isNotLoaded = node.loaded !== true;

                if (needsLoading && isNotLoaded) {
                    return setChildren(node);
                } else {
                    return $q.resolve();
                }
            };

            function setChildren(node) {
                node.loading = true;
                return ModuleGroup.tree({
                    parentId: node.id,
                    active: instance.active,
                    fields: _.map(instance.groupColumns, 'id')
                }).$promise.then((children) => {
                    node.children = _(children).map((child) => instance.build(child, node)).sortBy(SORT).value();

                    const promises = _.map(node.children, instance.prepare);
                    return $q.all(promises).then(() => {
                        const loader = _.get(params, 'load');
                        return loader ? loader(node) : node;
                    });
                }).finally(() => {
                    node.loading = false;
                    node.loaded = true;
                });
            }

            instance.reload = function(node) {
                setChildren(node);
            };

            instance.traverse = function(nodes, callback) {
                _.forEach(nodes, (node) => {
                    callback(node);
                    instance.traverse(node.children, callback);
                });
            };

            return instance;
        }

        return { getOpen, getNodes, build, manager };
    });
