'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:refreshButton
 * @description Shows a simple button that shows a rotating refresh icon if refreshing.
 */
angular.module('uasApp').component('refreshButton', {
  bindings: {
    btnDisabled: '<?',
    btnLabel: '@',
    isRefreshing: '<?',
    tooltip: '@?',
    onClick: '&'
  },
  templateUrl: 'es6/app/buttons/refresh.button.html',
  controllerAs: 'refreshButtonController',
  controller: function () {
    const refreshButtonController = this;

    refreshButtonController.$onInit = function () {
      refreshButtonController.btnLabel_ = _.defaultTo(refreshButtonController.btnLabel, 'Static.Button.Refresh');
      refreshButtonController.tooltip_ = _.defaultTo(refreshButtonController.tooltip, refreshButtonController.btnLabel_);
    };

    refreshButtonController.$onChanges = function () {
      refreshButtonController.btnDisabled_ = refreshButtonController.btnDisabled || refreshButtonController.isRefreshing;
    };

    refreshButtonController.refresh = function () {
      refreshButtonController.onClick();
    };
  }
});
