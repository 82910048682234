'use strict';

angular.module('uasApp').factory('WorkflowEvaluator', function(Expression, Page, $q) {

    function getValues(pages, entity) {
        const values = {};
        const promises = _(pages)
            .filter((page) => page.route === 'form')
            .map((page) =>
                Page.items({
                    id: page.id,
                    entityId: entity.self.id,
                    entityType: entity.self.type
                }).$promise.then((items) => {
                    const itemValues = _(items)
                        .map((item) => ({
                            name: item.field.name,
                            values: getValue(item)
                        })).value();
                    _.extend(values, itemValues);
                })
            ).value();
        return $q.all(promises).then(() => values);
    }

    function getValue(item) {
        let values = item.values;
        if (!_.isArray(values)) {
            values = [values];
        }
        values = _.filter(values, (value) => angular.isDefined(value) && value !== null && value !== '');

        if (item.field.multipleValues !== true) {
            return _.head(values);
        }

        return values;
    }

    function evaluateAll(pages, evaluation) {
        const promises = _.map(pages, (page) =>
            evaluate(page, angular.copy(evaluation))
        );

        return $q.all(promises);
    }

    function evaluate(page, evaluation) {
        if (page.condition && !_.isEmpty(evaluation)) {
            evaluation.expression = page.condition;
            evaluation.academicYearId = sessionStorage.academicYear;

            return Expression.evaluate(evaluation).$promise.then((result) => {
                page.visible = result.value === true;
                return page;
            });
        }

        page.visible = true;
        return $q.resolve(page);
    }

    return { getValues, evaluateAll };

});
