'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:specificationGeneral
 * @description
 * Displays general specification information
 */
angular.module('uasApp').component('specificationGeneral', {
    bindings: {
        specification: '<',
        operations: '<'
    },
    templateUrl: 'es6/specification/specification.general.html',
    controllerAs: 'specificationGeneralController',
    controller: function ($state) {
        const specificationGeneralController = this;

        specificationGeneralController.onStatus = function () {
            $state.reload();
        };
    }
});
