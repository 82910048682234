'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Qualification
 * @description
 * The Qualification service.
 */
angular.module('uasApp').factory('Qualification', function (UasResource) {
  return UasResource('/qualification', {
    deleteFull: {
      url: '/qualification/:id/full',
      method: 'DELETE',
      isArray: false
    },
    planned: {
      url: '/planned-qualifications',
      method: 'GET',
      isArray: true
    },
    report: {
      url: '/qualifications-report',
      method: 'GET',
      isArray: false
    },
    search: {
      method: 'POST',
      url: '/qualification/search',
      isArray: false
    }
  });
});
