'use strict';

/**
 * @ngdoc function
 * @description
 * # personGeneral
 * Person general controller
 */

angular.module('uasApp')
  .component('personGeneral', {
    bindings: {
      person: '<',
      operations: '<?'
    },
    templateUrl: 'es6/person/person.general.html',
    controllerAs: 'personGeneralController',
    controller: function(EmailAddress, AuthService, Process) {
      const personGeneralController = this;

      personGeneralController.$onInit = function() {
        EmailAddress.get({ personId: personGeneralController.person.id }).$promise.then((result) => {
          personGeneralController.email = result.email;
        });

        personGeneralController.processes = Process.query({
            academicYearId: sessionStorage.academicYear,
            rootType: 'PERSON'
        });
      };

      personGeneralController.become = function(person) {
        AuthService.loginAs(person);
      };
    }
});
