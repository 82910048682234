'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:ProcessProgress
 * @description
 * # ProcessProgress
 * ProcessProgress resource of the uasApp
 */
angular.module('uasApp')
  .factory('ProcessProgress', function (UasResource) {
    return UasResource('/process-progress', {
      summary: {
        url: '/process-summary/:id',
        method: 'GET',
        isArray: false
      },
      widgets: {
        url: '/process-widgets',
        method: 'GET',
        isArray: true
      }
    });
  });
