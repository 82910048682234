'use strict';

angular.module('uasApp')
  .component('uasEditModuleGroup', {
      bindings: {
          moduleGroup: '<',
          entityType: '<?',
          operations: '<',
          onCancel: '&',
          onSave: '&',
          workflowMode: '<?',
          excludeTypeIds: '<?'
      },
      templateUrl: 'es6/planboard/edit/edit.module.group.html',
      controllerAs: 'editModuleGroupController',
      controller: function($q, ModuleGroup, Message, translateFilter, CustomField, EntityType) {
        const editModuleGroupController = this;

        editModuleGroupController.$onInit = function () {
            editModuleGroupController.saveCallbacks = [];

            editModuleGroupController.loading = true;
            $q.all([
                CustomField.query({
                    rootType: 'module-group',
                    entityType: 'module-group'
                }).$promise,
                getIncludeTypes(editModuleGroupController.entityType)
            ]).then(([fields, includeTypes]) => {
                editModuleGroupController.fields = fields;
                editModuleGroupController.includeTypes = includeTypes;
            }).finally(() => {
                editModuleGroupController.loading = false;
            });
        };

        function getIncludeTypes(entityType) {
            if (!entityType) {
                return $q.resolve([]);
            }

            const rootType = entityType.replace('-module-group', '');
            return EntityType.get({
                rootType,
                entityType
            }).$promise.then((type) => {
                return EntityType.getIncludeTypes(type);
            });
        }

        editModuleGroupController.save = function() {
            updateModuleGroup()
                .then(() => $q.all(executeSaveFunctions()))
                .then(() => {
                    editModuleGroupController.onSave();
                    Message.addSuccess(translateFilter('Static.Message.DataSaved'));
                });
        };

        function updateModuleGroup() {
            return ModuleGroup.update({
                comment: editModuleGroupController.comment,
                content: editModuleGroupController.moduleGroup
            }).$promise;
        }

        function executeSaveFunctions() {
            return _(editModuleGroupController.saveCallbacks)
                .filter((saveFn) => _.isFunction(saveFn))
                .map((saveFn) => $q.resolve(saveFn(editModuleGroupController.moduleGroup, editModuleGroupController.comment)))
                .value();
        }

        editModuleGroupController.cancel = function() {
            editModuleGroupController.onCancel();
        };
      }
    });
