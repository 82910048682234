'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Mailing
 * @description
 * The Mailing factory.
 */
angular.module('uasApp').factory('Mailing', function() {

    function generate(assets) {
        const deliveries = flatten(assets);
        const bundles = bundle(deliveries);
        return combine(bundles);
    }

    function flatten(assets) {
        return _(assets).map((asset) => {
            return _(asset.deliveries).filter((delivery) => delivery.amount > 0).map((delivery) => {
                return _.extend(delivery, {
                    version: asset.version,
                    week: asset.scheduledWeek
                });
            }).value();
        }).flatten().value();
    }

    function bundle(deliveries) {
        const grouped = _.groupBy(deliveries, (delivery) => `${delivery.recipientId}-${delivery.week}`);
        const bundles = _.values(grouped);

        return _(bundles).map((values) => {
            const value = _.head(values);
            return {
                recipientId: value.recipientId,
                week: value.week,
                deliveries: _.map(values, (delivery) => _.pick(delivery, ['amount', 'version']))
            };
        }).sortBy('week').value();
    }

    function combine(mailings) {
        return _.reduce(mailings, (accumulator, mailing) => {
            const existing = _.find(accumulator, (it) => {
                return it.week === mailing.week && _.isEqual(it.deliveries, mailing.deliveries);
            });
    
            if (angular.isDefined(existing)) {
                existing.recipientIds.push(mailing.recipientId);
            } else {
                const container = angular.copy(mailing);
                container.recipientIds = [container.recipientId];
                delete container.recipientId;

                accumulator.push(container);
            }
    
            return accumulator;
          }, []);
    }

    return { generate };
});