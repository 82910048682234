'use strict';

angular.module('uasApp')
    .directive('helpIcon', function() {
        return {
            restrict: 'E',
            templateUrl: 'es6/app/instruction/help.icon.html',
            scope: {
                label: '=',
                icon: '@?',
                helpTextSide: '@?'
            },
            replace: true,
            link: function($scope) {
                $scope.icon = $scope.icon || 'info-circle';
                $scope.helpTextSide = $scope.helpTextSide || 'left';
            }
        };
    });
