'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:validationMessages
 * @description
 */
angular.module('uasApp').component('validationMessages', {
  bindings: {
    validation: '<',
    editable: '<',
    period: '<',
    onChange: '&'
  },
  templateUrl: 'es6/fields/validation/validation.messages.html',
  controllerAs: 'validationMessagesController',
  controller: function(ValidationOverride, EntityService) {
    const validationMessagesController = this;

    validationMessagesController.$onChanges = function() {
      setMessages(validationMessagesController.validation);
      validationMessagesController.valid = _.result(validationMessagesController.validation, 'valid', true);
    };

    function setMessages(validation) {
      if (angular.isDefined(validation)) {
        validationMessagesController.messages = _(validation.entities)
          .map((entity) => {
            return _.map(entity.messages, (message) => {
              return _.extend(message, { root: entity.entity });
            });
          })
          .flatten()
          .uniqBy((message) => `${message.fieldName}-${message.message}-${message.value}`)
          .value();
      } else {
        validationMessagesController.messages = [];
      }
    }
    
    validationMessagesController.override = function(message) {
      const override = build(message);
      ValidationOverride.save(override).$promise.then(() => {
        validationMessagesController.onChange();
      });
    };

    validationMessagesController.reset = function(message) {
      const override = build(message);
      ValidationOverride.remove(override).$promise.then(() => {
        validationMessagesController.onChange();
      });
    };

    function build(message) {
      return {
        root: EntityService.toReference(message.root),
        entity: EntityService.toReference(message.entity),
        periodId: _.result(validationMessagesController.period, 'id'),
        message: message.message
      };
    }
  }
});