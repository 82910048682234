'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamSelect
 * @description Search for a single team in a paginated result list.
 */
angular.module('uasApp').component('teamSelect', {
  bindings: {
    onSelect: '&'
  },
  templateUrl: 'es6/security/team.select.html',
  controllerAs: 'teamSelectController',
  controller: function (Pageable, Team) {
    const teamSelectController = this;

    teamSelectController.$onInit = function () {
      teamSelectController.pageable = Pageable.of({
        order: 'code'
      });
      teamSelectController.search();
    };

    teamSelectController.search = function () {
      const params = teamSelectController.pageable.build({
        text: teamSelectController.searchText || '',
        active: true
      });

      Team.page(params).$promise.then((teams) => {
        delete teamSelectController.selected;
        teamSelectController.page = teams;
      });
    };

    teamSelectController.select = function (team) {
      teamSelectController.selected = team;
      teamSelectController.onSelect({ team });
    };

    teamSelectController.isNotSelected = function (team) {
      return angular.isUndefined(teamSelectController.selected) || team.id !== teamSelectController.selected.id;
    };
  }
});