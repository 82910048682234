'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('study', {
        url: '/study/{id:[0-9]{1,8}}',
        templateUrl: 'es6/study/study.html',
        controller: 'StudyCtrl',
        abstract: true,
        redirectTo: 'study.general',
        entityType: 'study',
        breadcrumb: {},
        data: {},
        resolve: {
            studyId: function ($stateParams) {
                return $stateParams.id;
            },
            study: function (AcademicYearHolder, Study, studyId) {
                const instance = this;
                return Study.get({
                    id: studyId,
                    academicYearId: sessionStorage.academicYear
                }).$promise.then(function (study) {
                    instance.self.data = study;

                    // Ensure the correct year is selected
                    return AcademicYearHolder.setCurrent(study.academicYearId).then(() => {
                        return study;
                    });
                });
            },
            historyMode: function ($stateParams) {
                return $stateParams.historyMode === 'true';
            },
            operations: function (AuthService, study) {
                return AuthService.operations('study', study.id);
            }
        },
        params: {
          historyMode: {
            value: 'false'
          }
        }
    });

    $stateProvider.state('study.general', {
        url: '/general?historyMode',
        views: {
            'study': {
                template: `
                    <uas-study-general
                        study="study"
                        operations="operations">
                    </uas-study-general>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Study.General',
            label: 'Static.Help.Study.General'
        }
    });

    $stateProvider.state('study.description', {
        url: '/description',
        views: {
            'study': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('study');
                    }
                },
                controller: function($scope, types) {
                    $scope.types = types;
                },
                template: `
                    <description
                        data-types="types"
                        data-entity-type="study"
                        data-entity-id="studyId"
                        data-status="study.status"
                        data-operations="operations">
                    </description>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Study.Description',
            label: 'Static.Help.Study.Description'
        }
    });

    $stateProvider.state('study.additional', {
        url: '/additional',
        views: {
            'study': {
                resolve: {
                    studyId: function ($stateParams) {
                        return $stateParams.id;
                    }
                },
                template: `
                    <uas-additional
                        entity="study"
                        operations="operations">
                    </uas-additional>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_ADDITIONAL'],
            title: 'Static.Tab.Study.Extra',
            label: 'Static.Help.Study.Extra'
        }
    });

    $stateProvider.state('study.capacity', {
        url: '/capacity',
        views: {
            'study': {
                template: `
                    <uas-capacity
                        entity="study"
                        operations="operations">
                    </uas-capacity>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_CAPACITY'],
            title: 'Static.Tab.Study.Capacity',
            label: 'Static.Help.Study.Capacity'
        }
    });

    $stateProvider.state('study.credits', {
        url: '/credits',
        views: {
            'study': {
                template: `
                    <uas-credits
                        entity="study"
                        operations="operations">
                    </uas-credits>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_CREDITS'],
            title: 'Static.Tab.Study.Credits',
            label: 'Static.Help.Study.Credits'
        }
    });

    $stateProvider.state('study.relations', {
        url: '/relations',
        views: {
            'study': {
                template: `
                    <relations
                        data-entity-type="study"
                        data-entity-id="studyId"
                        data-operations="operations">
                    </relations>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Study.Relations',
            label: 'Static.Help.Study.Relations'
        }
    });

    $stateProvider.state('study.studyable', {
        url: '/studyable',
        views: {
            'study': {
                template: `
                    <uas-studyable
                        study-id="study.id">
                    </uas-studyable>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDYABLE'],
            title: 'Static.Tab.Study.Studyable',
            label: 'Static.Help.Study.Studyable'
        }
    });

    $stateProvider.state('study.cost', {
        url: '/cost',
        views: {
            'study': {
                template: `
                    <uas-study-cost
                        study="study"
                        operations="operations">
                    </uas-study-cost>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST'],
            title: 'Static.Tab.Study.Cost',
            label: 'Static.Help.Study.Cost'
        }
    });

    $stateProvider.state('study.qualifications', {
        url: '/qualifications',
        views: {
            'study': {
                template: `
                    <qualifications-report
                        entity="study"
                        operations="operations">
                    </qualifications-report>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Study.Qualifications',
            label: 'Static.Help.Study.Qualifications'
        }
    });

    $stateProvider.state('study.admin', {
        url: '/admin',
        views: {
            'study': {
                template: `
                    <uas-study-admin
                        study="study"
                        operations="operations">
                    </uas-study-admin>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Study.Admin',
            label: 'Static.Help.Study.Admin'
        }
    });

    $stateProvider.state('study.changes', {
        url: '/changes',
        views: {
            'study': {
                resolve: {
                    studyId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                controllerAs: 'studyChangesController',
                controller: function(studyId) {
                    this.entity = { type: 'study', id: studyId };
                },
                template: `
                    <change-report
                        entity="studyChangesController.entity">
                    </change-report>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_CHANGE'],
            title: 'Static.Tab.Study.ReportChange',
            label: 'Static.Help.Study.ReportChange'
        }
    });

    $stateProvider.state('study.modules', {
        url: '/modules',
        views: {
            'study': {
                template: `
                    <modules-report
                        entity="study"
                        operations="operations">
                    </modules-report>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Study.ReportModule',
            label: 'Static.Help.Study.ReportModule'
        }
    });

    $stateProvider.state('study.module-group-modules', {
        url: '/module-group-modules',
        views: {
            'study': {
                template: `
                    <study-modules-report
                        study="study">
                    </study-modules-report>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_MODULES'],
            title: 'Static.Tab.Study.ReportModule',
            label: 'Static.Help.Study.ReportModule'
        }
    });

    $stateProvider.state('study.groups', {
        url: '/groups',
        views: {
            'study': {
                template: `
                    <groups-report
                        entity="study"
                        operations="operations">
                    </groups-report>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_GROUPS'],
            title: 'Static.Tab.Study.Groups',
            label: 'Static.Help.Study.Groups'
        }
    });

    $stateProvider.state('study.structure', {
        url: '/structure',
        views: {
            'study': {
                template: `
                    <structure
                        entity="study"
                        operations="operations"
                        show-redirect="true">
                    </structure>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_GROUPS', 'VIEW_STRUCTURE'],
            title: 'Static.Tab.Study.Structure',
            label: 'Static.Help.Study.Structure'
        }
    });

    $stateProvider.state('study.planner', {
        url: '/planner',
        views: {
            'study': {
                template: `
                    <uas-planner
                        entity="study"
                        operations="operations">
                    </uas-planner>
                `
            }
        },
        parent: 'study',
        breadcrumb: {},
        data: {
            secured: ['VIEW_GROUPS', 'VIEW_STRUCTURE'],
            title: 'Static.Tab.Study.Planner',
            label: 'Static.Help.Study.Planner'
        }
    });

    $stateProvider.state('study.hours', {
        url: '/hours',
        views: {
            'study': {
                resolve: {
                    studyId: function($stateParams) {
                        return $stateParams.id;
                    }
                },
                controllerAs: 'studyHoursController',
                controller: function(studyId, AuthService, Worklog) {
                    this.studyId = studyId;
                    this.report = Worklog.report({ studyId }).$promise;
                    this.operations = AuthService.operations('study', studyId);
                    this.generate = function() {
                        return Worklog.generate({ studyId }, {}).$promise;
                    };
                },
                template: `
                    <uas-hour-report
                        report="studyHoursController.report"
                        operations="studyHoursController.operations"
                        generate="studyHoursController.generate()"
                        entity-id="{{ studyHoursController.studyId }}">
                    </uas-hour-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_HOURS_MODULE_REPORT'],
            title: 'Static.Tab.Study.Hours',
            label: 'Static.Help.Study.Hours'
        }
    });

    $stateProvider.state('study.cost-divisions', {
        url: '/cost-divisions',
        views: {
            'study': {
                template: `
                    <uas-cost-division-report
                        entity="costDivisionsController.study"
                        operations="costDivisionsController.operations">
                    </uas-cost-division-report>
                `,
                controllerAs: 'costDivisionsController',
                controller: function(study, operations) {
                    this.study = study;
                    this.operations = operations;
                }
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_COST_DIVISION'],
            title: 'Static.Tab.Study.CostDivisions',
            label: 'Static.Help.Study.CostDivisions'
        }
    });

    $stateProvider.state('study.export', {
        url: '/export',
        views: {
            'study': {
                template: `
                    <export-list
                        faculty-id="study.facultyId"
                        study-id="study.id">
                    </export-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['EXPORT'],
            title: 'Static.Tab.Study.Export',
            label: 'Static.Help.Study.Export'
        }
    });

    $stateProvider.state('study.planboard', {
        url: '/planboard',
        views: {
            'study': {
                template: `
                    <page-loader
                        route="study-planboard"
                        entity="study"
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STRUCTURE'],
            title: 'Static.Tab.Study.Planboard',
            label: 'Static.Help.Study.Planboard'
        }
    });

    $stateProvider.state('study.links', {
        url: '/links',
        views: {
            'study': {
                template: `
                    <link-list
                        entity="study"
                        operations="operations">
                    </link-list>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_LINKS'],
            title: 'Static.Tab.Study.Links',
            label: 'Static.Help.Study.Links'
        }
    });

    $stateProvider.state('study.deliveries', {
        url: '/deliveries',
        views: {
            'study': {
                template: `
                    <study-deliveries
                        study="study"
                        operations="operations">
                    </study-deliveries>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ASSETS'],
            title: 'Static.Tab.Study.Deliveries',
            label: 'Static.Help.Study.Deliveries'
        }
    });

    $stateProvider.state('study.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'study': {
                controller: function($scope, $stateParams) {
                    $scope.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader
                        page-id="pageId"
                        entity="study"
                        operations="operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});
