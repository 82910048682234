'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:LinkType
 * @description
 * The LinkType service.
 */
angular.module('uasApp').factory('LinkType', function (UasResource) {
  return UasResource('/link-types');
});
