'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:assessmentsReportPageController
 * @description
 * Shows the assessments in a configurable page.
 */
angular.module('uasApp')
  .component('assessmentsReportPage', {
    bindings: {
      entity: '<',
      process: '<',
      page: '<',
      operations: '<',
      workflow: '<?'
    },
    templateUrl: 'es6/assessment/report/assessments.report.page.html',
    controllerAs: 'assessmentsReportPageController',
    controller: function (Page) {
      const assessmentsReportPageController = this;

      assessmentsReportPageController.$onInit = function () {
        assessmentsReportPageController.refresh = { loadData: _.noop };
        assessmentsReportPageController.readOnly = Page.getParameterAsBoolean(assessmentsReportPageController.page, 'readOnly', false);

        assessmentsReportPageController.operationsToEdit = angular.isDefined(assessmentsReportPageController.workflow) ? 'EDIT_ASSESSMENTS_WORKFLOW' : 'EDIT_ASSESSMENTS';
        assessmentsReportPageController.showCreate = Page.getParameterAsBoolean(assessmentsReportPageController.page, 'showCreate', false);
        assessmentsReportPageController.showModal = Page.getParameterAsBoolean(assessmentsReportPageController.page, 'modal', false);

        assessmentsReportPageController.reportTemplate = Page.getParameter(assessmentsReportPageController.page, 'reportTemplate');
        assessmentsReportPageController.hideReportTemplates = Page.getParameterAsBoolean(assessmentsReportPageController.page, 'hideReportTemplates', false);
      };

    }
  });
