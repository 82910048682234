'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasHourInput
 * @description
 * uasHourInput component for formatted HH:mm input
 */
angular.module('uasApp')
  .component('uasHourInput', {
    bindings: {
        ngDisabled: '<?',
        ngModel: '='
    },
    templateUrl: 'es6/staffing/hour.input.html',
    controllerAs: 'hourInputController',
    controller: function(minutesAsHoursFilter) {
        const hourInputController = this;

        hourInputController.$onChanges = function() {
          const value = hourInputController.ngModel;
          if (value) {
            setValue(value);
          }
        };

        function setValue(value) {
            hourInputController.value = minutesAsHoursFilter(value);
        }

        hourInputController.onChange = function(value) {
            if (_.isEmpty(value)) {
                hourInputController.ngModel = undefined;
            } else {
                const minutes = parse(value);
                if (minutes) {
                    hourInputController.ngModel = minutes;
                }
            }
        };

        function parse(hours) {
            if (_.isEmpty(hours)) {
              return null;
            }
      
            // test if hours contains anything other than 0-9 : . or ,
            if (/[^:,.\d]/.test(hours)) {
              return null;
            }
      
            let h;
            let m;
      
            let delimiter;
      
            // find the delimiter
            if (_.includes(hours, ':')) {
              delimiter = ':';
            } else if (_.includes(hours, ',')) {
              delimiter = ',';
            } else if (_.includes(hours, '.')) {
              delimiter = '.';
            }
      
            // numeric input without delimiter is seen as hours
            if (angular.isUndefined(delimiter)) {
              if (_.isNumber(parseInt(hours))) {
                return parseInt(hours) * 60;
              } else {
                return null;
              }
            }
      
            h = hours.split(delimiter)[0];
            m = (hours.split(delimiter)[1] || '').substring(0, 2);
      
            return (parseInt(h) * 60) + parseInt(m);
        }
    }
});