'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:WeekLabel
 * @description Shows the week number as label.
 */
angular.module('uasApp')
  .component('weekLabel', {
    bindings: {
      week: '<',
      elements: '<?',
      label: '@?',
      color: '<?'
    },
    templateUrl: 'es6/calendar/week/week.label.html',
    controllerAs: 'weekController',
    controller: function($q, Element) {
      const weekController = this;

      weekController.$onInit = function() {
        weekController.element = getElement(weekController.week).then((element) => {
          weekController.tooltip = Element.getLabel(element);
          weekController.color = _.get(element, 'color', {});
        });
      };

      weekController.$onChanges = function() {
        weekController.label_ = _.defaultTo(weekController.label, weekController.week.week);
      };

      function getElement(week) {
        const typeId = _.get(week, 'typeId');

        if (!_.isEmpty(weekController.elements)) {
          const found = _.find(weekController.elements, { id: typeId });

          if (found) {
            return $q.resolve(found);
          }
        }

        if (angular.isDefined(typeId)) {
          return Element.get({ id: typeId }).$promise;
        }

        return $q.resolve({});
      }
    }
  });
