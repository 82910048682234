'use strict';

angular.module('uasApp').component('taskList', {
    templateUrl: 'es6/staffing/task/task.list.html',
    bindings: {
        assignment: '<',
        operations: '<',
        isReadOnly: '<?',
        hidePersonal: '<?',
        workflowMode: '<',
        onChange: '&',
        onClose: '&'
    },
    controllerAs: 'taskListController',
    controller: function ($q, Task, Message, SecurityService) {
        const taskListController = this;

        taskListController.$onInit = function () {
            const operationsToEdit = taskListController.workflowMode === true ? 'EDIT_TASKS_WORKFLOW' : 'EDIT_TASKS';
            taskListController.editable = taskListController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, taskListController.operations);
            taskListController.personal = taskListController.hidePersonal !== true && SecurityService.isAllowed(['VIEW_TASKS_PERSONAL'], taskListController.operations);
            taskListController.allowed = SecurityService.isAllowed(['VIEW_TASKS'], taskListController.operations);

            if (taskListController.allowed) {
                loadData();
            }
        };

        function loadData() {
            taskListController.loading = true;
            return Task.query({
                assignmentId: taskListController.assignment.id,
                academicYearId: sessionStorage.academicYear,
                personal: taskListController.personal
            }).$promise.then((tasks) => {
                taskListController.tasks = _.filter(tasks, (task) => task.changeType !== 'REMOVE');
            }).finally(() => {
                taskListController.loading = false;
            });
        }

        taskListController.save = function(task) {
            const form = build(task);
            Task.store(form).$promise.then(() => {
                Message.onSaved();
            });
        };

        function build(task) {
            const form = angular.copy(task);
            form.templateId = task.template.id;
            form.assignmentId = taskListController.assignment.id;
            return form;
        }
    }
});
