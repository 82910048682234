'use strict';

angular.module('uasApp').component('changeTypeLabel', {
  bindings: {
    changeType: '<',
    entity: '<?',
    root: '<?',
    viewClasses: '@?',
    isIconOnly: '<?'
  },
  templateUrl: 'es6/changes/change.type.label.html',
  controllerAs: 'labelController',
  controller: function (ChangeModal) {
    const labelController = this;

    labelController.$onChanges = function () {
      labelController.iconOnly = labelController.isIconOnly === true;

      if (labelController.changeType) {
        const classes = getClasses(labelController.changeType);
        labelController.labelClass = _.result(classes, 'label');
        labelController.iconClass = _.result(classes, 'icon');
      }
    };

    function getClasses(changeType) {
      switch (changeType) {
        case 'REMOVE':
          return { label: 'label-danger', icon: 'trash' };
        case 'MODIFY':
          return { label: 'label-warning', icon: 'pencil' };
        case 'CREATE':
          return { label: 'label-success', icon: 'plus' };
        case 'RESET':
          return { label: 'label-info', icon: 'undo' };
        default:
          return { label: 'label-primary', icon: 'comment-o' };
      }
    }

    labelController.onClick = function($event) {
      if (!labelController.entity) {
        return;
      }

      $event.preventDefault();
      $event.stopPropagation();

      ChangeModal.openChanges(labelController.entity, labelController.root);
    };
  }
});
