'use strict';

angular.module('uasApp').component('academicYearForm', {
    templateUrl: 'es6/admin/year/year.form.html',
    controllerAs: 'academicYearFormController',
    bindings: {
        year: '<',
        onSubmit: '&'
    },
    controller: function (AcademicYear) {
        const academicYearFormController = this;

        academicYearFormController.$onInit = function() {
            academicYearFormController.isNew = angular.isUndefined(academicYearFormController.year.id);

            AcademicYear.query().$promise.then((origins) => {
                academicYearFormController.origins = origins;
            });
        };

        academicYearFormController.submit = function() {
            const year = angular.copy(academicYearFormController.year);
            _.forEach(year.persons, (person) => {
                person.person = person.person.id;
            });

            academicYearFormController.onSubmit({ year });
        };
    }
});
