'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:facultiesRules
 * @description
 * Displays rules for a faculty.
 */
angular.module('uasApp').component('facultiesRules', {
  templateUrl: 'es6/faculties/faculties.rules.html',
  bindings: {
    faculty: '<?',
    operations: '<'
  },
  controllerAs: 'rulesController'
});
