'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:documentReport
 * @description
 * Shows documents in a report.
 */
angular.module('uasApp').component('documentReport', {
  bindings: {
    entity: '<',
    operations: '<?',
    operationsToExport: '<',
    reportTemplate: '@?',
    hideReportTemplates: '<?'
  },
  templateUrl: 'es6/document/report/document.report.html',
  controllerAs: 'documentReportController',
  controller: function (Document, Download, EntityService, Language, SecurityService) {
    const documentReportController = this;

    documentReportController.$onInit = function () {
      documentReportController.entityType = EntityService.getType(documentReportController.entity);
      documentReportController.selectable = SecurityService.isAllowed(documentReportController.operationsToExport, documentReportController.operations);

      documentReportController.name = 'documents';
      documentReportController.context = 'documents';

      if (angular.isDefined(documentReportController.entity)) {
        documentReportController.context = `${documentReportController.entityType}.documents`;

        if (documentReportController.entity.code) {
          documentReportController.name = `${documentReportController.entity.code}-documents`;
        }
      }

      loadData();
      Language.onChange(loadData);
    };

    function loadData() {
      documentReportController.loading = true;
      Document.report({
        definition: true
      }).$promise.then((report) => {
        documentReportController.columns = report.headers;
        documentReportController.groups = report.groups;
      }).finally(() => {
        documentReportController.loading = false;
      });
    }

    documentReportController.onChange = function () {
      loadData();
    };

    documentReportController.getRows = function (pageable) {
      const parameters = _.extend({
        entityType: EntityService.getType(documentReportController.entity),
        entityId: _.get(documentReportController.entity, 'id'),
        startDate: documentReportController.startDate,
        language: Language.get()
      }, pageable);
      
      return Document.report(parameters).$promise;

    };

    documentReportController.export = function () {
      const documentIds = _.map(documentReportController.selectedRows, 'id');
      const reference = EntityService.toReference(documentReportController.entity);

      Document.export(documentIds, reference).then((response) => {
        Download.downloadResponse(response.data, response.headers());
      });
    };

    documentReportController.search = function () {
      documentReportController.reload = {
        reset: true
      };
    };
  }
});
