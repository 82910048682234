'use strict';

angular.module('uasApp').factory('Resource', function (UasResource) {
  return UasResource('/resources', {
    search: {
      method: 'GET',
      url: '/resources/search',
      isArray: false
    }
  });
});
