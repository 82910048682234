'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:CustomType
 * @description
 * The custom type service.
 */
angular.module('uasApp')
    .factory('CustomType', function(UasResource, i18nFilter, typeFilter) {
        const resource = UasResource('/custom-type');

        function getLabel(type) {
            if (angular.isUndefined(type)) {
                return '';
            }

            const name = i18nFilter(type.labels) || typeFilter(type.entityType);
            if (type.entityType) {
                return `${name} (${type.entityType})`;
            }
            return name;
        }

        return _.extend(resource, { getLabel });
    });
