'use strict';

angular.module('uasApp').component('questionsWidget', {
  templateUrl: 'es6/dashboard/questions.widget.html',
  controllerAs: 'questionsWidgetController',
  controller: function (Comment) {
    const questionsWidgetController = this;

    questionsWidgetController.$onInit = function () {
      questionsWidgetController.loading = true;

      Comment.asked({
        academicYearId: sessionStorage.academicYear,
        sort: 'creationTime,desc',
        size: 3
      }).$promise.then((questions) => {
        questionsWidgetController.questions = questions.content;
      }).finally(() => {
        questionsWidgetController.loading = false;
      });
    };
  }
});
