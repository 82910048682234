'use strict';

angular.module('uasApp').factory('QualificationType', function (UasResource) {
  return UasResource('/qualification-types', {
    search: {
      method: 'GET',
      url: '/qualification-types/search'
    }
  });
});
