'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:btnState
 * @description
 * # btnState
 * Defines the state of a button
 */
angular.module('uasApp')
    .directive('stateLabel', function() {
        return {
            restrict: 'E',
            scope: {
                status: '=',
                addTooltip: '@'
            },
            template: '<status-label class="state-label" tooltip-append-to-body="true" status="status" uib-tooltip="{{addTooltip | enum}}" mapping="resolveMapping" no-text="true"></status-label>',
            controller: function($scope, $filter) {
                $scope.addTooltip = $scope.addTooltip ? $filter('translate')('Enum.' + $scope.addTooltip) : null;

                var labels = {
                    'PREFERRED': 'label-success',
                    'POSSIBLE': 'label-info',
                    'UNAVAILABLE': 'label-danger',
                    'UNKNOWN': 'label-warning'
                };

                var icons = {
                    'PREFERRED': 'check',
                    'POSSIBLE': 'minus',
                    'UNAVAILABLE': 'times',
                    'UNKNOWN': 'question'
                };

                $scope.resolveMapping = function() {
                    return {
                        label: labels[$scope.status],
                        icon: icons[$scope.status]
                    };
                };

            }
        };
    });
