'use strict';

angular.module('uasApp')
.directive('onTab', function() {
    return function(scope, element, attr) {
        return element.bind('keydown keypress', function(e) {
            if (e.which === 9 && !event.shiftKey) {
                return scope.$apply(attr.onTab);
            } else if (e.which === 9 && event.shiftKey) {
                return scope.$apply(attr.onTab);
            }
        });
    };
});