'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Enums
 * @description
 * The Enums service.
 */
angular.module('uasApp')
    .factory('Enums', function(UasResource) {
        const resource = UasResource('/enums').withCache('enums');

        return {
            constants: function(type) {
                return resource.query({ type });
            }
        };
    });
