'use strict';

/**
 * Sortable table headers with icons, font for icons:FontAwesome.
 */
angular.module('uasApp')
    .directive('sortableTable', function($timeout) {
        return {
            restrict: 'A',
            transclude: true,
            replace: true,
            templateUrl: 'es6/app/pagination/sortable.table.html',
            scope: {
                order: '<',
                pageable: '<',
                colspan: '@?',
                class: '@?',
                onChange: '&?'
            },
            link: function(scope, element) {
                if (scope.colspan) {
                    element.attr('colspan', scope.colspan);
                }
                if (scope.class) {
                    element.addClass(scope.class);
                }

                const pageable = scope.pageable || {};
                if (angular.isUndefined(pageable.reverse)) {
                    pageable.reverse = false;
                }

                scope.isActive = function () {
                    if (_.isArray(pageable.order)) {
                        return _.includes(pageable.order, scope.order);
                    }

                    return scope.order === pageable.order;
                };

                scope.onClick = function() {
                    if (scope.isActive()) {
                        pageable.reverse = pageable.reverse !== true;
                    } else {
                        pageable.order = scope.order;
                        pageable.reverse = false;
                    }

                    if (scope.onChange) {
                        $timeout(() => {
                            scope.onChange(pageable);
                        });
                    }
                };
            }
        };
    });
