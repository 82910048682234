'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:SpecificationCtrl
 * @description
 * # SpecificationCtrl
 */
angular.module('uasApp').controller('SpecificationCtrl', function (specification, operations) {
  const specificationController = this;

  specificationController.specification = specification;
  specificationController.operations = operations;

  specificationController.tabs = [{
    view: 'general',
    route: 'specification.general',
    readOnly: true
  }, {
    view: 'relations',
    route: 'specification.relations'
  }, {
    view: 'additional',
    route: 'specification.additional'
  }, {
    view: 'description',
    route: 'specification.description'
  }, {
    view: 'capacity',
    route: 'specification.capacity'
  }, {
    view: 'credits',
    route: 'specification.credits'
  }, {
    view: 'studies',
    route: 'specification.studies'
  }, {
    view: 'admin',
    route: 'specification.admin'
  }];
});
