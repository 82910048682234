'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:structureCompare
 * @description Loads and compares the complete structure of a target entity to an entity configured with scope.
 * This enables an overview of used groups and modules, without having to toggle them manually.
 */
angular.module('uasApp')
  .component('structureCompare', {
    bindings: {
      target: '<',
      isVisible: '<',
      onSelect: '&'
    },
    templateUrl: 'es6/planboard/compare/structure.compare.html',
    transclude: true,
    controllerAs: 'structureCompareController',
    controller: function (Study, Nodes, Compare) {
        const structureCompareController = this;

        const manager = Nodes.manager({
          build,
          load
        });

        function build(node, parent) {
          const result = Nodes.build(node, parent);

          return _.extend(result, {
            open: _.some(structureCompareController.open, node.entity)
          });
        }

        function load(node) {
          return Compare.modules({
              parentId: node.id,
              targetId: structureCompareController.target.id
          }).$promise.then((modules) => {
              node.modules = _.orderBy(modules, ['used', 'module.code'], ['desc', 'asc']);
              setUsed(node);
              return node;
          });
        }

        function setUsed(group) {
          if (group.children) {
            _.forEach(group.children, setUsed);
          }

          group.used = _.some(group.modules, { used: true });
        }

        structureCompareController.$onInit = function() {
          if (structureCompareController.isVisible === true) {
            const entityId = _.result(structureCompareController.target, 'scopeId');

            Study.find(entityId).$promise.then((entity) => {
              structureCompareController.entity = entity;
              loadData();
            });
          }
        };

        function loadData() {
          if (angular.isDefined(structureCompareController.entity)) {
            structureCompareController.loading = true;
            manager.init({
              entity: structureCompareController.entity,
              active: true,
              loadAll: true
            }).then((groups) => {
              structureCompareController.groups = groups;
            }).finally(() => {
              structureCompareController.loading = false;
            });
          }
        }

        structureCompareController.expandAll = function(open) {
          return manager.expand(structureCompareController.groups, open, () => true);
        };

        structureCompareController.toggle = function (group) {
          return manager.toggle(group);
        };

        structureCompareController.select = function(row) {
          structureCompareController.selected = row.module;

          structureCompareController.onSelect({
            module: row.module,
            onChange: () => {
              row.used = true;
              _.forEach(structureCompareController.groups, setUsed);
            }
          });
        };

        structureCompareController.refresh = function() {
          loadData();
        };
    }
});
