'use strict';

/**
 * The redirect strategies for the main module 'uasApp'.
 */
angular.module('uasApp').factory('ProcessModal', function($uibModal, $q, ProcessTakeover, EntityService) {
      const instance = {};

      // input: entity, statuses, periods, period, process, callback
      instance.open = function(args) {
          $uibModal.open({
            templateUrl: 'es6/process/progress/process.modal.html',
            size: 'lg',
            resolve: {
              operations: function(AuthService) {
                const readOnly = _.get(args, 'readOnly', false);
                if (readOnly) {
                  return $q.resolve([]);
                }

                return AuthService.operations(args.entity.self.type, args.entity.id);
              },
              pages: function(CustomPage) {
                return CustomPage.query({
                  basePath: args.entity.self.type + '.general'
                }).$promise;
              }
            },
            controllerAs: 'progressModalController',
            controller: function($uibModalInstance, operations, pages) {
              const progressModalController = this;

              progressModalController.$onInit = function() {
                progressModalController.operations = operations;
                progressModalController.pages = pages;
                progressModalController.entity = args.entity;
                progressModalController.process = args.process;
                progressModalController.status = args.status;
                progressModalController.active = angular.copy(args.status);
                progressModalController.statuses = angular.copy(args.statuses);

                setActive();
              };

              progressModalController.goToEntity = function(entity) {
                EntityService.redirect(entity.self);
                $uibModalInstance.dismiss();
              };

              progressModalController.onStatus = function(status) {
                progressModalController.status = status;
                setActive();
              };

              progressModalController.takeover = function() {
                return ProcessTakeover.open({
                  entityType: progressModalController.entity.self.type,
                  entityIds: [progressModalController.entity.id],
                  process: progressModalController.process,
                  statuses: progressModalController.statuses,
                  callback: () => progressModalController.onChange()
                });
              };

              progressModalController.onChange = function() {
                $uibModalInstance.dismiss();
                args.callback();
              };

              function setActive() {
                _.forEach(progressModalController.statuses, (status) => {
                  status.active = status.id === _.result(progressModalController.status, 'id');
                });
              }
            }
          });
      };

      return instance;
    });