'use strict';

angular.module('uasApp').component('assignmentEdit', {
    templateUrl: 'es6/staffing/assignment/assignment.edit.html',
    bindings: {
        assignment: '<',
        onSave: '&',
        onCancel: '&'
    },
    controllerAs: 'assignmentEditController',
    controller: function(Assignment) {
        const assignmentEditController = this;

        assignmentEditController.save = function() {
            Assignment.save({
                comment: {},
                content: assignmentEditController.assignment
            }).$promise.then((assignment) => {
                assignmentEditController.onSave({ assignment });
            });
        };

        assignmentEditController.cancel = function() {
            assignmentEditController.onCancel();
        };
    }
});