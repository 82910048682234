'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:statusTimeline
 * @description
 * Displays the status timeline.
 */
angular.module('uasApp').component('statusTimeline', {
  bindings: {
    statuses: '<',
    isCompact: '<?',
    onSelect: '&?'
  },
  templateUrl: 'es6/status/status.timeline.html',
  controllerAs: 'timelineController',
  controller: function () {
    const timelineController = this;

    timelineController.getStyle = (index) => {
      const width = 100 / timelineController.statuses.length;

      return {
        width: width + '%',
        left: width * index + '%'
      };
    };
  }
});
