'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:Faculties
 * @description Factory to handle common /faculties functionality for getting tabs and facultyId.
 */
angular.module('uasApp').factory('Faculties', function($q, Tabs, $stateParams) {
  let loadedTabs = [];

  const tabs = [{
    title: 'Static.Page.Organisations',
    view: 'organisations',
    route: 'faculties.faculty.organisation'
  }, {
    title: 'Static.Page.Specifications',
    view: 'specifications',
    route: 'faculties.faculty.specification'
  }, {
    title: 'Static.Page.Qualifications',
    view: 'qualifications',
    route: 'faculties.faculty.qualification'
  }, {
    title: 'Static.Page.Rules',
    view: 'rules',
    route: 'faculties.faculty.rule'
  }, {
    title: 'Static.Page.Studies',
    view: 'studies',
    route: 'faculties.faculty.study'
  }, {
    title: 'Static.Page.Groups',
    view: 'groups',
    route: 'faculties.faculty.group'
  }, {
    title: 'Static.Page.Modules',
    view: 'modules',
    route: 'faculties.faculty.module'
  }, {
    title: 'Static.Page.Assessments',
    view: 'assessments',
    route: 'faculties.faculty.assessment'
  }, {
    title: 'Static.Page.Methods',
    view: 'methods',
    route: 'faculties.faculty.method'
  }, {
    title: 'Static.Page.Persons',
    view: 'persons',
    route: 'faculties.faculty.person'
  }];

  /**
   * @ngdoc method
   * @description Returns cached or fetched faculty tabs.
   * @returns {Promise} Promise containing a list of faculty tabs, with `default` set to `true` for the first visible tab.
   */
  function getTabs() {
    if (!_.isEmpty(loadedTabs)) {
      return $q.resolve(loadedTabs);
    }

    return Tabs.extend(tabs, 'faculties').then((facultyTabs) => {
      const visibleTabs = _(facultyTabs).filter((tab) => tab.visible).value();

      if (!_.isEmpty(visibleTabs)) {
        const firstTab = _.first(visibleTabs);
        _.assign(firstTab, { default: true });
      }

      loadedTabs = visibleTabs;

      return visibleTabs;
    });
  }

  /**
   * @ngdoc method
   * @description Returns tabs saved in memory if available, otherwise returns an empty array.
   * @returns {Array} An array of cached faculty tabs, or an empty array if none are cached.
   */
  function getCachedTabs() {
    return loadedTabs;
  }

  /**
   * @ngdoc method
   * @description Returns faculty ID from query param or session storage, or `undefined` if none is found
   * @returns {number|undefined}
   */
  function getFacultyId() {
    let facultyId = $stateParams.facultyId;

    if (!isDefinedAndNotNull(facultyId)) {
      facultyId = sessionStorage['faculties.facultyId'];
    }

    return isDefinedAndNotNull(facultyId) ? _.parseInt(facultyId) : undefined;
  }

  function isDefinedAndNotNull(value) {
    return angular.isDefined(value) && !_.isNull(value);
  }

  return { getTabs, getCachedTabs, getFacultyId };
});
