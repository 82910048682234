'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:qualificationsListPage
 * @description Page to manage (award-)qualifications for an entity.
 */
angular.module('uasApp').component('qualificationsListPage', {
  templateUrl: 'es6/qualification/list/qualifications.list.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<'
  },
  controllerAs: 'qualificationsListPageController',
  controller: function (Page) {
    const qualificationsListPageController = this;

    qualificationsListPageController.$onInit = function () {
      qualificationsListPageController.readOnly = Page.getParameterAsBoolean(qualificationsListPageController.page, 'readOnly', false);
      qualificationsListPageController.columns = Page.getParameterAsArray(qualificationsListPageController.page, 'columns');
    };
  }
});
