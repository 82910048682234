'use strict';

angular.module('uasApp').factory('ItemModal', function ($uibModal, feedbackObserver, Message) {

  const instance = {};

  instance.open = function (args) {
    $uibModal.open({
      controllerAs: 'itemModalController',
      controller: function ($uibModalInstance) {
        const itemModalController = this;

        itemModalController.$onInit = function () {
          itemModalController.item = angular.copy(args.item);
          itemModalController.type = args.type;
          itemModalController.entity = args.entity;
          itemModalController.instance = $uibModalInstance;
          itemModalController.operations = args.operations;
          itemModalController.operationsToEdit = args.operationsToEdit;
          itemModalController.isReadOnly = args.isReadOnly;
          itemModalController.showDates = args.showDates;
          itemModalController.commentType = args.commentType || args.type.commentType;
          itemModalController.labelWidth = args.labelWidth || 3;
        };

        itemModalController.onSave = function () {
          feedbackObserver.dataChanged();
          Message.addSuccessLabel('Static.Message.DataAdjusted');
          if (_.isFunction(args.onEdit)) {
            args.onEdit();
          }
        };
      },
      size: 'lg',
      template: `
        <item-edit
          item="itemModalController.item"
          type="itemModalController.type"
          entity="itemModalController.entity"
          comment-type="itemModalController.commentType"
          instance="itemModalController.instance"
          operations="itemModalController.operations"
          operations-to-edit="itemModalController.operationsToEdit"
          on-save="itemModalController.onSave()"
          is-read-only="itemModalController.isReadOnly"
          show-dates="itemModalController.showDates"
          label-width="itemModalController.labelWidth">
        </item-edit>`
    });
  };

  return instance;
});
