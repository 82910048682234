'use strict';

/**
 * @ngdoc function
 * @name uasApp.directive:entityText
 * @description
 * # EntityRedirect
 * Redirects to the entity.
 */
angular.module('uasApp')
    .directive('entityRedirect', function(EntityService) {
        return {
            restrict: 'A',
            scope: {
                entity: '='
            },
            link: function(scope, element) {
                element.bind('click', function() {
                    EntityService.redirect(scope.entity, scope.entity);
                });
            }
        };
    });
