'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personAdmin
 * @description Person admin controller
 */
angular.module('uasApp')
  .component('personAdmin', {
    bindings: {
      personId: '<',
      operations: '<?'
    },
    templateUrl: 'es6/person/person.admin.html',
    controllerAs: 'personAdminController',
    controller: function ($q, $state, FeedbackService, Message, Person) {
      const personAdminController = this;

      personAdminController.$onInit = function () {
        loadData();
      };

      function loadData() {
        personAdminController.loading = true;

        return Person.secure({
          id: personAdminController.personId
        }).$promise.then((person) => {
          personAdminController.person = person;
        }).finally(() => {
          personAdminController.loading = false;
        });
      }

      personAdminController.save = function (person, comment) {
        person.comment = comment;

        return Person.store(personAdminController.person).$promise.then(() =>
          updatePassword(personAdminController.person.password)
        ).then(() => {
          personAdminController.adminForm.$setPristine();
          FeedbackService.onUpdate(personAdminController.person);
        });
      };

      function updatePassword(password) {
        if (!_.isEmpty(password)) {
          return Person.password({
            id: personAdminController.person.id
          }, { password }).$promise;
        }
        return $q.resolve();
      }

      personAdminController.removePassword = function () {
        Person.password({
          id: personAdminController.person.id
        }, { password: '' }).$promise.then(() => {
          personAdminController.adminForm.$setPristine();

          Message.onSaved();
          $state.reload();
        });
      };

      personAdminController.delete = function (person, message) {
        const body = {
          id: person.id,
          comment: { message }
        };

        Person.remove(body).$promise.then(() => {
          personAdminController.adminForm.$setPristine();

          Message.onRemoved();
          $state.reload();
        });
      };
    }
  });
