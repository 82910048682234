'use strict';

angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('organisation-types', {
    url: '/organisation-types',
    template: `
      <type-list
        validator='organisation-type'
        header-label='Static.Page.OrganisationTypes'
        help-label='Static.Help.OrganisationTypes'
        icon='fa-university'
        resource='organisationTypeController.resource'>
      </type-list>
    `,
    controllerAs: 'organisationTypeController',
    controller: function (OrganisationType) {
      const organisationTypeController = this;
      organisationTypeController.resource = OrganisationType;
    },
    breadcrumb: {
      label: 'Static.Page.OrganisationTypes',
      translate: true,
      root: true
    },
    admin: true
  });
  
});
