'use strict';

angular.module('uasApp').component('taskTemplateEdit', {
    templateUrl: 'es6/staffing/task/template/task.template.edit.html',
    bindings: {
        template: '<',
        onSubmit: '&',
        onCancel: '&'
    },
    controllerAs: 'taskTemplateEditController',
    controller: function() {
        const taskTemplateEditController = this;

        taskTemplateEditController.save = function() {
            taskTemplateEditController.onSubmit({
                template: taskTemplateEditController.template
            });
        };

        taskTemplateEditController.close = function() {
            taskTemplateEditController.onCancel();
        };
    }
});
