'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:DateService
 * @description
 * The UAS Date service.
 */
angular.module('uasApp').factory('uasDateService', function() {

  const dateTimeFormat = /^(\d{4}-\d{2}-\d{2})(T\d{2}:\d{2}:\d{2}(\.\d{3})?Z)?/;

  function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  return {
    convert: function(value) {
      if (angular.isDefined(value) && value !== null) {
        if (angular.isString(value) && dateTimeFormat.test(value)) {
          value = dateTimeFormat.exec(value)[1];
        }
        const date = new Date(value);
        if (isValidDate(date)) {
          return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        }
      }
      return null;
    }
  };
});
