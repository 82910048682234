'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Export
 * @description
 * The Export service.
 */
angular.module('uasApp')
    .factory('Export', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/export/:id', {}, {
            'submit': {
                method: 'POST',
                url: BackendService.getBaseUrl() + '/export',
                isArray: true
            },
            'faculties': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/export/faculties',
                isArray: true
            },
            'studies': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/export/studies',
                isArray: true
            },
            'roots': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/export/roots',
                isArray: true
            },
            'changes': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/export/changes',
                isArray: false
            },
            'handlers': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/export-handler',
                isArray: true
            }
        });
    });
