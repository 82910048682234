'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCustomTypeList
 * @description
 * uasCustomTypeList Manages the custom types
 */
angular.module('uasApp')
    .component('uasCustomTypeList', {
        templateUrl: 'es6/custom/types/custom.type.list.html',
        controllerAs: 'customTypeListController',
        controller: function ($uibModal, $q, CustomType, EntityType, ItemType, Language,  filterFilter, i18nFilter, typeFilter) {
            const customTypeListController = this;

            customTypeListController.$onInit = function() {
                customTypeListController.active = 'true';
                customTypeListController.onLanguage = Language.onChange(loadData);
                loadData();
            };

            customTypeListController.$onDestroy = function() {
                customTypeListController.onLanguage();
            };

            function loadData() {
                customTypeListController.loading = true;
                $q.all([
                    EntityType.query().$promise,
                    ItemType.query().$promise
                ]).then(([entityTypes, itemTypes]) => {
                    customTypeListController.rootTypes = _(entityTypes).map('rootType').filter((rootType) => !_.isEmpty(rootType)).uniq().map((rootType) => {
                        const mappedEntityTypes = _(entityTypes).filter({ rootType }).map(mapEntityType).value();
                        const mappedItemTypes = _(itemTypes).filter({ rootType }).map(mapItemType).value();
                        const subTypes = _.orderBy(_.concat(mappedEntityTypes, mappedItemTypes), ['isRoot', 'name', 'sequence'], ['desc', 'asc', 'asc']);

                        return {
                            type: rootType,
                            name: typeFilter(rootType),
                            subTypes
                        };
                    }).sortBy('name').value();

                    customTypeListController.search();
                }).finally(() => {
                    customTypeListController.loading = false;
                });
            }

            customTypeListController.search = function() {
                customTypeListController.visibleTypes = _(customTypeListController.rootTypes).map((rootType) => {
                    let subTypes = filterFilter(rootType.subTypes, customTypeListController.searchTerm);
                    if (customTypeListController.active) {
                        subTypes = _.filter(subTypes, {
                            invalid: customTypeListController.active !== 'true'
                        });
                    }

                    return _.extend(angular.copy(rootType), { subTypes });
                }).filter((rootType) => rootType.subTypes.length > 0).value();
            };

            function mapEntityType(entityType) {
                return {
                    id: entityType.id,
                    code: entityType.code,
                    name: i18nFilter(entityType.labels) || typeFilter(entityType.entityType),
                    entityType: entityType.entityType,
                    rootType: entityType.rootType,
                    sequence: entityType.sequence,
                    invalid: entityType.invalid,
                    isRoot: entityType.rootType === entityType.entityType
                };
            }

            function mapItemType(itemType) {
                return {
                    id: itemType.id,
                    code: itemType.code,
                    name: i18nFilter(itemType.labels) || itemType.code,
                    entityType: 'item',
                    rootType: itemType.rootType,
                    sequence: itemType.sequence,
                    invalid: false,
                    isRoot: false
                };
            }

            customTypeListController.create = function(rootType) {
                $uibModal.open({
                    template: `
                        <div class="modal-header">
                            <h2 class="modal-title">
                                <span translate>Static.Button.Add</span>
                                <button type="button" class="close" ng-click="customTypeCreateController.close()"></button>
                            </h2>
                        </div>
                        <div class="modal-body">
                            <uas-custom-type-general 
                                custom-type="customTypeCreateController.itemType"
                                on-save="customTypeCreateController.onSave(result)">
                            </uas-custom-type-general>
                        </div>
                    `,
                    size: 'lg',
                    controllerAs: 'customTypeCreateController',
                    controller: function($uibModalInstance) {
                        const customTypeCreateController = this;

                        customTypeCreateController.$onInit = function() {
                            customTypeCreateController.itemType = {
                                rootType: rootType,
                                commentType: 'REQUIRED',
                                create: 'ALWAYS'
                            };
                        };

                        customTypeCreateController.onSave = function() {
                            customTypeCreateController.close();
                            loadData();
                        };

                        customTypeCreateController.close = function() {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            };

            customTypeListController.delete = function(customType) {
                CustomType.delete({
                    id: customType.id
                }).$promise.then(loadData);
            };
        }
    });
