'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:HourReport
 * @description
 * The HourReport service.
 */
angular.module('uasApp')
    .factory('HourReport', function(UasResource) {
        return UasResource('/hour-report', {});
    });
