'use strict';

angular.module('uasApp').component('teamMembers', {
  bindings: {
    team: '<'
  },
  templateUrl: 'es6/team/members/team.members.html',
  controllerAs: 'teamMembersController',
  controller: function(Events, Member, Message, $uibModal) {
    const teamMembersController = this;

    teamMembersController.$onInit = function() {
        loadData();
    };

    function loadData() {
        teamMembersController.loading = true;
        return Member.query({
            teamId: teamMembersController.team.id
        }).$promise.then((members) => {
            teamMembersController.members = _.sortBy(members, ['person.fullName', 'id']);
        }).finally(() => {
            teamMembersController.loading = false;
        });
    }

    teamMembersController.create = function() {
        open({
            teamId: teamMembersController.team.id
        });
    };

    teamMembersController.edit = function(member, $event) {
      if (!Events.isClickableTarget($event)) {
        const model = _.omit(member, ['team', 'person']);
        model.teamId = _.get(member, 'team.id');
        model.personId = _.get(member, 'person.id');

        open(model);
      }
    };

    function open(member) {
        $uibModal.open({
          templateUrl: 'es6/team/members/team.member.edit.modal.html',
          controllerAs: 'memberEditController',
          controller: function ($uibModalInstance) {
            const memberEditController = this;
            memberEditController.member = angular.copy(member);

            memberEditController.submit = function () {
                Member.store(memberEditController.member).$promise.then(() => {
                    Message.onSaved();
                    loadData();
                });

                memberEditController.close();
            };

            memberEditController.close = function() {
                $uibModalInstance.dismiss();
            };
          }
        });
    }

    teamMembersController.remove = function (member) {
        Member.remove(member).$promise.then(() => {
            Message.onRemoved();
            loadData();
        });
    };
  }
});
