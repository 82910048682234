'use strict';

angular.module('uasApp').component('referenceModal', {
  templateUrl: 'es6/reference/reference.modal.html',
  bindings: {
    reference: '<',
    addLabel: '@',
    editLabel: '@',
    modalInstance: '<',
    onSave: '&'
  },
  controllerAs: 'modalController',
  controller: function () {
    const modalController = this;

    modalController.save = function (reference) {
      modalController.onSave({ reference });
      modalController.close();
    };

    modalController.close = function () {
      modalController.modalInstance.dismiss();
    };
  }
});
