'use strict';

angular.module('uasApp').component('uasDateTimeInput', {
  templateUrl: 'es6/app/date.time.input.html',
  bindings: {
    inputId: '@?',
    isReadOnly: '<?',
    isRequired: '<?',
    viewClasses: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'dateTimeInputController',
  controller: function (dateFilter) {
    const dateTimeInputController = this;

    const PATTERN = /^(\d{2})-(\d{2})-(\d{4})( (\d{2}):(\d{2})(:(\d{2}))?)?$/;
    const ISO_PATTERN = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2})(:(\d{2}))?)?(.*)$/;

    dateTimeInputController.$onInit = function() {
      dateTimeInputController.ngModelCtrl.$render = function () {
        dateTimeInputController.value = format(dateTimeInputController.ngModelCtrl.$modelValue);
      };
    };

    dateTimeInputController.onChange = function() {
      const value = dateTimeInputController.value;
      if (_.isEmpty(value)) {
        dateTimeInputController.ngModelCtrl.$setViewValue(undefined);
        dateTimeInputController.ngModelCtrl.$setValidity('pattern', true);
      } else if (_.isString(value)) {
        const parsed = parse(value);
        dateTimeInputController.ngModelCtrl.$setViewValue(parsed);
        dateTimeInputController.ngModelCtrl.$setValidity('pattern', !_.isEmpty(parsed));
      }
    };

    function parse(value) {
      const matches = value.match(PATTERN);
      if (matches !== null) {
        const day = matches[1];
        const month = matches[2];
        const year = matches[3];
        const hour = matches[5] || '00';
        const minute = matches[6] || '00';
        const second = matches[8] || '00';

        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
      }
    }

    function format(value) {
      if (value instanceof Date) {
        return dateFilter(value, 'dd-MM-yyyy HH:mm:ss');
      } else if (value) {
        const matches = value.match(ISO_PATTERN);
        const year = matches[1];
        const month = matches[2];
        const day = matches[3];
        const hour = matches[5] || '00';
        const minute = matches[6] || '00';
        const second = matches[8] || '00';

        return `${day}-${month}-${year} ${hour}:${minute}:${second}`;
      }
    }

    dateTimeInputController.clear = function() {
      dateTimeInputController.value = '';
      dateTimeInputController.onChange();
    };

    dateTimeInputController.setNow = function() {
      dateTimeInputController.value = format(new Date());
      dateTimeInputController.onChange();
    };
  }
});
