'use strict';

angular.module('uasApp').component('periodBar', {
    bindings: {
        entity: '<',
        onLoaded: '&'
    },
    templateUrl: 'es6/offerings/period.bar.html',
    controllerAs: 'periodBarController',
    controller: function(entityTranslateFilter, EntityService, Offering, Parameter) {

        const periodBarController = this;

        periodBarController.$onInit = function() {
            Parameter.load().then(() => loadData());
        };

        function loadData() {
            periodBarController.entityType = EntityService.getType(periodBarController.entity);

            const periodView = Parameter.getParameterValue('period.view');
            if (periodView === 'block') {
                loadCalendars();
            } else {
                loadOfferings();
            }
        }

        function loadCalendars() {
            Offering.calendars({
                entityType: periodBarController.entityType,
                entityId: periodBarController.entity.id
            }).$promise.then((calendars) => {
                periodBarController.calendars = calendars;

                const periods = _(calendars).map('periods').flatten().filter({ active: true }).value();
                periodBarController.onLoaded({ data: periods });
            });
        }

        function loadOfferings() {
            Offering.scheduled({ 
                entityType: periodBarController.entityType,
                entityId: periodBarController.entity.id
            }).$promise.then((offerings) => {
                periodBarController.offerings = _(offerings).map((offering) => {
                    return {
                        id: offering.id,
                        name: getName(offering),
                        startDate: _.result(offering.period, 'startDate')
                    };
                }).orderBy(['startDate', 'name']).value();

                periodBarController.onLoaded({ data: offerings });
            });
        }

        function getName(offering) {
            if (_.isEmpty(offering.blocks)) {
                return getPeriodName(offering.period);
            }

            return _(offering.blocks)
                .orderBy('sequence')
                .map(getPeriodName)
                .sort()
                .uniq()
                .join('+');
        }

        function getPeriodName(period) {
            return entityTranslateFilter(period) || period.code;
        }
    }
});
