'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:meetingReport
 * @description
 */
angular.module('uasApp').component('meetingReport', {
  bindings: {
    module: '<',
    period: '<'
  },
  templateUrl: 'es6/schedule/meeting/meeting.report.html',
  controllerAs: 'meetingReportController',
  controller: function ($q, Activity, Meeting, Relation, Offering, Parameter) {
    const meetingReportController = this;

    meetingReportController.$onInit = function() {
        loadData();
    };

    function loadData() {
        meetingReportController.loading = true;
        $q.all([
            Activity.query({
                entityType: 'module',
                entityId: meetingReportController.module.id,
                periodId: meetingReportController.period.id
            }).$promise,
            Meeting.query({
                entityType: 'module',
                entityId: meetingReportController.module.id,
                periodId: meetingReportController.period.id
            }).$promise,
            Offering.weeks({
                entityType: 'module',
                entityId: meetingReportController.module.id,
                periodId: meetingReportController.period.id
            }).$promise,
            Relation.teachers({
                entityType: 'module',
                entityId: meetingReportController.module.id,
                periodId: meetingReportController.period.id
            }).$promise,
            Parameter.load()
        ]).then(([activities, meetings, weeks, persons]) => {
            const types = _(activities).map('type').uniqBy('id').sortBy('sequence').value();

            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            _.forEach(weeks, (week) => {
                week.label = calendarWeeks ? week.yearWeek : week.week;
            });

            meetingReportController.types = _.map(types, (type) => {
                type.activities = _(activities).filter((activity) => activity.type.id === type.id).sortBy('name').map((activity) => {
                    const extended = _(activity.plannings).map((planning) => {
                        const week = _.find(weeks, { week: planning.week });
                        return _(meetings).filter({ planning: planning.id }).map((meeting) => {
                            return _.extend(meeting, { 
                                persons: _(meeting.personIds).map((id) => _.find(persons, { id: id })).filter(angular.isDefined).value(),
                                week: week 
                            });
                        }).value();
                    }).flatten().value();

                    activity.meetings = Meeting.reduce(extended);
                    return activity;
                }).value();

                return type;
            });
        }).finally(() => {
            meetingReportController.loading = false;
        });
    }
  }
});