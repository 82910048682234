'use strict';

angular.module('uasApp').component('teamsPage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?',
    operations: '<'
  },
  templateUrl: 'es6/team/teams.page.html',
  controllerAs: 'teamsPageController',
  controller: function (EntityService, Page) {
    const teamsPageController = this;

    teamsPageController.$onInit = function () {
      teamsPageController.readOnly = Page.getParameterAsBoolean(teamsPageController.page, 'readOnly', false);
      teamsPageController.searchParams = {
        active: 'true'
      };
    };

    teamsPageController.search = function () {
      teamsPageController.searchParams = angular.copy(teamsPageController.searchParams);
    };
  }
});
