'use strict';

import timeeditLogo from '../../images/timeedit.svg';

/**
 * @ngdoc function
 * @name uasApp.controller:FooterCtrl
 * @description
 * # FooterCtrl
 * Footer controller of the uasApp
 */
angular.module('uasApp')
    .controller('FooterCtrl', function($scope, Session, translateFilter, Language) {
        $scope.timeeditLogoUrl = timeeditLogo;

        $scope.Session = Session;

        const FAQ_URL_LABEL = 'Static.Label.Faq.Url';
        const MANUAL_URL_LABEL = 'Static.Label.Manual.Url';

        function setFaqAndManual() {
            $scope.faqUrl = translateFilter(FAQ_URL_LABEL);
            $scope.faqAvailable = $scope.faqUrl !== FAQ_URL_LABEL && urlIsAvailable($scope.faqUrl);
            $scope.manualUrl = translateFilter(MANUAL_URL_LABEL);
            $scope.manualAvailable = $scope.manualUrl !== MANUAL_URL_LABEL && urlIsAvailable($scope.manualUrl);
            $scope.statusLabel = getStatusLabel();
        }

        function urlIsAvailable(url) {
            return url !== '' && url !== '#';
        }

        function getStatusLabel() {
            const status = _.get($scope.criticalHealth, 'status', 'UNKNOWN');
            return `Static.Footer.About.Api.Status.${status}`;
        }

        setFaqAndManual();

        Language.onChange(setFaqAndManual);
    });
