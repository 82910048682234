'use strict';

angular.module('uasApp')
    .directive('confirmClick', function($uibModal) {
        return {
            restrict: 'A',
            scope: {
                confirmClick: '&',
                text: '@',
                buttonClasses: '@?',
                buttonText: '@'
            },
            link: function(scope, element) {
                element.bind('click', function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    $uibModal.open({
                        templateUrl: 'views/modals/confirm.html',
                        resolve: {
                            action: function() {
                                return scope.confirmClick;
                            },
                            text: function() {
                                return scope.text;
                            }
                        },
                        controller: function($scope, $uibModalInstance, action, text) {
                            $scope.text = text;

                            $scope.buttonClasses = scope.buttonClasses;
                            $scope.buttonText = scope.buttonText;

                            $scope.perform = function() {
                                $scope.$dismiss();
                                action();
                            };

                            $scope.$dismiss = function() {
                                $uibModalInstance.dismiss();
                            };
                        }
                    });
                });
            }
        };
    });
