'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:buttonOption
 * @description
 * The buttonOption directive. Displays an accessible button which is styled and
 * accompanied by an icon, depending on the active state.
 * You should only use buttonOption as a child of the buttonOptions directive.
 */
angular.module('uasApp')
  .directive('buttonOption', function () {
    return {
      replace: true,
      require: '^buttonOptions',
      restrict: 'E',
      transclude: true,
      scope: {
        activeBackgroundColor: '@?',
        activeColor: '@?',
        activeIcon: '@?',
        icon: '@?',
        label: '@',
        isActive: '<',
        isDisabled: '<?'
      },
      templateUrl: 'es6/app/forms/buttons/button.option.html'
    };
  });
