'use strict';

angular.module('uasApp').component('appraisalSelect', {
  templateUrl: 'es6/assessment/appraisal/appraisal.select.html',
  bindings: {
    category: '<?',
    entity: '<',
    inputId: '@',
    showCode: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  controllerAs: 'appraisalSelectController',
  controller: function (Pageable, EntityService, Appraisal) {
    const appraisalSelectController = this;

    appraisalSelectController.$onInit = function () {
        appraisalSelectController.ngModelCtrl.$render = function () {
            appraisalSelectController.selectedIds = appraisalSelectController.ngModelCtrl.$modelValue;
        };

        loadData();
    };

    function loadData() {
        appraisalSelectController.loading = true;
        return Appraisal.query({
            categoryId: _.get(appraisalSelectController.category, 'id'),
            entityType: EntityService.getType(appraisalSelectController.entity),
            entityId: appraisalSelectController.entity.id,
            active: true
        }).$promise.then((appraisals) => {
            appraisalSelectController.assessments = _(appraisals)
              .filter((appraisal) => angular.isDefined(appraisal.assessment))
              .map('assessment')
              .value();
        }).finally(() => {
            appraisalSelectController.loading = false;
        });
    }

    appraisalSelectController.onSelect = function () {
        appraisalSelectController.ngModelCtrl.$setViewValue(appraisalSelectController.selectedIds);
    };

  }
});