'use strict';

angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('tasks', {
        url: '/tasks',
        template: '<task-template-list></task-template-list>',
        breadcrumb: {
            label: 'Static.Page.TaskTemplates',
            root: true
        },
        admin: true
    });
    
});
