'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:objectivePage
 * @description
 * objectivePage Manage objectives on a workflow page
 */
angular.module('uasApp').component('objectivePage', {
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?',
    operations: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/objective/objective.page.html',
  controllerAs: 'objectivePageController',
  controller: function (Page, Category, EntityService) {
    const objectivePageController = this;

    objectivePageController.$onInit = function () {
      objectivePageController.reference = EntityService.toReference(objectivePageController.entity);
      objectivePageController.readOnly = objectivePageController.isReadOnly || Page.getParameterAsBoolean(objectivePageController.page, 'readOnly', false);
      objectivePageController.showAssessmentCode = Page.getParameterAsBoolean(objectivePageController.page, 'showAssessmentCode', false);
      objectivePageController.selectAllSubjects = Page.getParameterAsBoolean(objectivePageController.page, 'selectAllSubjects', false);
      objectivePageController.showSubjectCode = Page.getParameter(objectivePageController.page, 'showSubjectCode', false);
      objectivePageController.showInactive = Page.getParameter(objectivePageController.page, 'showInactive', false);
      objectivePageController.operationsToEdit = angular.isDefined(objectivePageController.workflow) ? ['EDIT_OBJECTIVES_WORKFLOW'] : ['EDIT_OBJECTIVES'];
      objectivePageController.columns = Page.getParameterAsArray(objectivePageController.page, 'columns');

      objectivePageController.hideAssessment = Page.getParameterAsBoolean(objectivePageController.page, 'hideAssessment', false);
      objectivePageController.hideSubject = Page.getParameterAsBoolean(objectivePageController.page, 'hideSubject', false);

      const categoryId = Page.getParameterAsInt(objectivePageController.page, 'category');
      objectivePageController.loading = true;
      Category.find(categoryId).$promise.then((category) => {
        objectivePageController.category = category;
      }).finally(() => {
        objectivePageController.loading = false;
      });
    };
  }
});
