'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:timeBlockForm
 * @description
 * timeBlockForm edit a time block
 */
angular.module('uasApp').component('timeBlockForm', {
    bindings: {
        block: '<'
    },
    controllerAs: 'timeBlockFormController',
    templateUrl: 'es6/calendar/time/time.block.form.html'
});