'use strict';

angular.module('uasApp').factory('TaskModal', function($q, $uibModal, i18nFilter, Message, TaskTemplate, EntityService) {
    
    function open(args) {
        $uibModal.open({
            resolve: {
                templates: function() {
                    const template = _.result(args.task, 'template');
                    if (angular.isDefined(template)) {
                        const selected = buildTemplate(template);
                        return $q.resolve([selected]);
                    }

                    return getTemplates();
                }
            },
            template: `
                <task-edit 
                    task="taskModalController.task" 
                    templates="taskModalController.templates" 
                    correctable="taskModalController.correctable" 
                    on-save="taskModalController.onSaved()"
                    on-close="taskModalController.close()">
                </task-edit>
            `,
            controllerAs: 'taskModalController',
            controller: function($uibModalInstance, templates) {
                const taskModalController = this;
                taskModalController.task = args.task;
                taskModalController.templates = templates;
                taskModalController.correctable = args.correctable !== false;

                taskModalController.onSaved = function() {
                    taskModalController.close();
                    Message.onSaved();
                    return args.onChange();
                };

                taskModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    }

    function getTemplates() {
        return TaskTemplate.query({
            academicYearId: sessionStorage.academicYear
        }).$promise.then((templates) => {
            return _(templates)
                .map(buildTemplate)
                .filter((template) => template.entityType !== 'assignment')
                .sortBy(['name', 'id'])
                .value();
        });
    }

    function buildTemplate(template) {
        const rootType = _.result(template, 'role.entityType');

        return _.extend(template, {
            name: i18nFilter(template.names) || template.code,
            entityType: EntityService.getEntityType(rootType)
        });
    }

    return { open };

});
