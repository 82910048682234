'use strict';

/**
 * @ngdoc directive
 * @name uasApp.directive:tooltipIfTruncated
 * @restrict A
 * @description Provides a tooltip for elements containing text that gets truncated.
 * - Automatically attaches the `truncate-ellipsis` class (`display: block !important;`) for styling needs.
 * - Note: Don't pair this with (children with) any form of 'ng-repeat' or 'ng-transclude' due to underlying $compile issues.
 * - Note: Manually set tooltip properties if there's an 'ng-click' on the directive element or its children.
 */
angular.module('uasApp').directive('tooltipIfTruncated', function ($compile) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      // Throw an error if used with directives that can cause recompilation bugs and console errors.
      const incompatibleDirectives = ['ng-repeat', 'ng-repeat-start', 'ng-repeat-end', 'ng-transclude'];
      const hasIncompatibleDirectives = _.some(incompatibleDirectives, (attr) => angular.isDefined(element.attr(attr)));

      if (hasIncompatibleDirectives) {
        const joinedDirectives = incompatibleDirectives.join(', ');
        throw new Error(`The 'tooltip-if-truncated' directive cannot be used on the same element with any of the following directives: ${joinedDirectives}.`);
      }

      /**
       * On first digest, add `uibTooltip` if it's not yet there.
       * Avoid `ng-click` on the directive; it won't be registered by `$compile`. Instead, wrap content in a child container for `ng-click`.
       * Note: `ng-click` on a child while using this directive can trigger the click function twice.
       * Add tooltip attributes as shown below to mitigate these issues.
       */
      if (angular.isUndefined(attrs.uibTooltip) && attrs.tooltipIfTruncated !== 'false') {
        element.attr('tooltip-enable', 'true');
        element.attr('tooltip-placement', 'auto');
        element.attr('uib-tooltip', '');
        element.attr('tooltip-append-to-body', 'true');
        element.addClass('truncate-ellipsis');

        $compile(element)(scope);
      }

      // If `scrollWidth` or `offsetWidth` changes, check if a tooltip is needed.
      scope.$watch(function () {
        return [element[0].scrollWidth, element[0].offsetWidth];
      }, function () {
        const el = element[0];

        if (el.offsetWidth < el.scrollWidth) {
          attrs.tooltipEnable = 'true';
          attrs.uibTooltip = el.innerText;
        } else {
          attrs.tooltipEnable = 'false';
        }
      }, true);
    }
  };
});
