'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:workflowValidator
 * @description
 * The workflow validator service.
 */
angular.module('uasApp').factory('WorkflowValidator', function ($q, $state, CommentModal, EntityType, UnsavedChangesModal) {

  let forms = {};
  let saveCallback;
  let commentParams;

  return { setValidity, isValid, isDirty, checkForUnsavedChanges, onSave, reset, save };

  function setValidity(form) {
    const key = getKey();
    forms[key] = form;
  }

  function isValid() {
    const form = getForm();
    return form === true || angular.isUndefined(form) || _.get(form, '$valid', false) === true;
  }

  function isDirty() {
    const form = getForm();
    return _.get(form, '$dirty', false) === true;
  }

  function isInvalid() {
    const form = getForm();
    return _.get(form, '$invalid', false);
  }

  function getForm() {
    const key = getKey();

    let form = forms[key];
    if (_.isFunction(form)) {
      form = form();
    }
    return form;
  }

  function getKey() {
    return $state.current.name;
  }

  function reset() {
    setValidity();

    saveCallback = undefined;
    commentParams = undefined;
  }

  function onSave(callback, entity, commentType) {
    saveCallback = callback;
    commentParams = {
      saveWithComment: angular.isDefined(entity),
      rootType: _.get(entity, 'rootType'),
      entityType: _.get(entity, 'entityType'),
      commentType
    };
  }

  function checkForUnsavedChanges(event, onDiscard) {
    if (isDirty() && _.isFunction(saveCallback)) {
      if (event) {
        event.preventDefault();
      }
      const params = _.extend({
        invalid: isInvalid(),
        onSave: saveCallback,
        onDiscard
      }, commentParams);

      UnsavedChangesModal.open(params);
    } else {
      onDiscard();
    }
  }

  function save(onSuccess) {
    if (isDirty() && _.isFunction(saveCallback)) {
      const onSaveCallback = (content, comment) => {
        saveCallback(comment).then(onSuccess);
      };

      getCommentType().then((commentType) => {
        if (commentType !== 'NEVER') {
          CommentModal.openModal(
            null,
            onSaveCallback,
            commentType
          );
        } else {
          onSaveCallback();
        }
      });
    } else {
      onSuccess();
    }
  }

  function getCommentType() {
    const commentType = _.get(commentParams, 'commentType');
    if (commentType) {
      return $q.resolve(commentType);
    }

    const entityType = _.get(commentParams, 'entityType');
    const rootType = _.get(commentParams, 'rootType', entityType);
    if (rootType || entityType) {
      return EntityType.get({
        rootType,
        entityType
      }).$promise.then((config) => {
        return _.get(config, 'commentType', 'NEVER');
      });
    }

    return $q.resolve('NEVER');
  }
});
