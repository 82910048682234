'use strict';

angular.module('uasApp').component('expressionInput', {
  bindings: {
    inputId: '@',
    inputClasses: '@?',
    placeholder: '@?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/expression/expression.input.html',
  controllerAs: 'expressionInputController',
  controller: function ($q, $uibModal, Expression) {
    const expressionInputController = this;

    expressionInputController.$onInit = function() {
      expressionInputController.ngModelCtrl.$render = function () {
        expressionInputController.expression = expressionInputController.ngModelCtrl.$modelValue;
      };

      expressionInputController.ngModelCtrl.$asyncValidators.valid = function (modelValue, viewValue) {
        delete expressionInputController.error;

        const value = modelValue || viewValue;
        if (!_.isEmpty(value)) {
          return validate(value);
        } else {
          return $q.resolve(true);
        }
      };
    };

    function validate(expression) {
      return Expression.validate({
        expression
      }).$promise.then((result) => {
        if (result.valid === true) {
          return true;
        } else {
          expressionInputController.error = result.message;
          return $q.reject(false);
        }
      });
    }

    expressionInputController.onChange = function() {
      expressionInputController.ngModelCtrl.$setViewValue(expressionInputController.expression);
    };

    expressionInputController.evaluate = function() {
      $uibModal.open({
        template: `
          <expression-evaluate 
            expression="{{ expressionModalController.expression }}"
            on-close="expressionModalController.close()">
          </expression-evaluate>
        `,
        controllerAs: 'expressionModalController',
        controller: function($uibModalInstance) {
          this.expression = expressionInputController.expression;
          this.close = function() {
            $uibModalInstance.dismiss();
          };
        }
      });
    };
  }
});
