'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:adviceForm
 * @description
 * adviceForm Manages the advice.
 */
angular.module('uasApp')
  .component('adviceForm', {
    bindings: {
      entity: '<',
      roleId: '<?',
      forceNewAdvice: '<?'
    },
    templateUrl: 'es6/advice/advice.form.html',
    controllerAs: 'adviceFormController',
    controller: function (Advice, Message, WorkflowValidator) {
      const adviceFormController = this;

      adviceFormController.$onInit = function () {
        WorkflowValidator.setValidity(() => adviceFormController.adviceForm);
        WorkflowValidator.onSave((comment) => {
          return adviceFormController.save(comment);
        }, {
          rootType: _.get(adviceFormController.entity, 'root.type'),
          entityType: 'advice'
        });

        loadData();
      };

      adviceFormController.$onDestroy = function () {
        WorkflowValidator.reset();
      };

      function loadData() {
        adviceFormController.loading = true;

        getAdvices().then((advices) => {
          adviceFormController.advices = advices;

          adviceFormController.edit();
          if (!adviceFormController.advice) {
            adviceFormController.create();
          }
        }).finally(() => {
          adviceFormController.adviceForm.$setPristine();
          adviceFormController.loading = false;
        });
      }

      function getAdvices() {
        return Advice.query({
          entityType: adviceFormController.entity.self.type,
          entityId: adviceFormController.entity.id,
          roleId: adviceFormController.roleId
        }).$promise.then((advices) =>
          _(advices)
            .map((advice) => _.extend(advice, { creationTime: advice.date }))
            .sort((a, b) => b.creationTime - a.creationTime)
            .value()
        );
      }

      adviceFormController.create = function () {
        const advice = {
          entity: {
            type: adviceFormController.entity.self.type,
            id: adviceFormController.entity.id
          }
        };

        setAdvice(advice);
      };

      adviceFormController.edit = function () {
        const advice = _(adviceFormController.advices).filter({ ready: false }).head();
        setAdvice(advice);
      };

      function setAdvice(advice) {
        adviceFormController.advice = advice;
        adviceFormController.isNew = angular.isUndefined(_.get(advice, 'id'));
      }

      adviceFormController.save = function (comment) {
        adviceFormController.advice.roleId = adviceFormController.roleId;
        delete adviceFormController.advice.role;

        return Advice.save({
          content: adviceFormController.advice,
          comment
        }).$promise.then(() => {
          adviceFormController.adviceForm.$setSubmitted(true);
          Message.onSaved();
          return loadData();
        });
      };

      adviceFormController.clear = function () {
        setAdvice();
      };
    }
  });
