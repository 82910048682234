'use strict';

angular.module('uasApp')
    .component('uasPlanboardMoveModule', {
        bindings: {
            modal: '<',
            moduleGroupCount: '<',
            origin: '<',
            groups: '<',
            onMove: '&',
            onCopy: '&'
        },
        templateUrl: 'es6/planboard/move/planboard.move.module.html',
        controllerAs: 'planboardMoveController',
        controller: function ($filter) {
            const planboardMoveController = this;

            planboardMoveController.$onInit = function () {
                const moduleName = $filter('entityTranslate')(planboardMoveController.origin.offering, 'moduleLocalName', 'moduleEnglishName');

                planboardMoveController.moduleName = {
                    moduleName: moduleName
                };

                planboardMoveController.offeringElsewhere = {
                    moduleName: moduleName,
                    offeringCount: planboardMoveController.moduleGroupCount
                };
            };

            planboardMoveController.copy = function () {
                planboardMoveController.close();
                planboardMoveController.onCopy();
            };

            planboardMoveController.move = function () {
                planboardMoveController.close();
                planboardMoveController.onMove();
            };

            planboardMoveController.close = function () {
                planboardMoveController.modal.close();
            };
        }
    });

