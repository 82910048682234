'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Version
 * @description
 * The Version service.
 */
angular.module('uasApp').factory('Version', function(UasResource) {
    return UasResource('/versions');
});
