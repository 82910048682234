'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasProcessNotifications
 * @description
 * uasProcessNotifications Process notifications
 */
angular.module('uasApp').component('uasProcessNotifications', {
  bindings: {
    statusType: '<',
    onCancel: '&?'
  },
  templateUrl: 'es6/process/process.notifications.html',
  controllerAs: 'notificationsController',
  controller: function (ProcessNotification, NotificationTemplate, RoleType, i18nFilter) {
    const notificationsController = this;

    notificationsController.$onInit = function () {
      notificationsController.loading = true;
      ProcessNotification.query({
        statusType: notificationsController.statusType.id
      }).$promise.then((notifications) => {
        notificationsController.notifications = notifications;
      }).finally(() => {
        notificationsController.loading = false;
      });

      notificationsController.templates = [];
      NotificationTemplate.query({
        eventType: 'WORKFLOW_NOTIFICATIONS'
      }).$promise.then((templates) => {
        notificationsController.templates = templates;
      });

      notificationsController.roleTypes = [];
      RoleType.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((roleTypes) => {
        notificationsController.roleTypes = roleTypes;
      });
    };

    notificationsController.getTemplateName = function (notification) {
      const template = _.find(notificationsController.templates, {
        id: notification.notificationTemplateId
      });

      return _.result(template, 'templateName');
    };

    notificationsController.getRoleTypeName = function (notification) {
      const roleType = _.find(notificationsController.roleTypes, {
        id: notification.roleTypeId
      });

      return i18nFilter(_.result(roleType, 'names') || []);
    };

    notificationsController.edit = function (notification) {
      notificationsController.notification = notification;
    };

    notificationsController.create = function () {
      notificationsController.edit({
        statusTypeId: notificationsController.statusType.id,
        daysOffset: 0
      });
    };

    notificationsController.save = function (data) {
      ProcessNotification.save(data).$promise.then(function (notification) {
        notificationsController.notifications = notificationsController.notifications || [];
        _.remove(notificationsController.notifications, { id: notification.id });
        notificationsController.notifications.push(notification);
        delete notificationsController.notification;
      });
    };

    notificationsController.remove = function (notification) {
      const params = { id: notification.id };
      ProcessNotification.remove(params).$promise.then(function () {
        _.remove(notificationsController.notifications, params);
      });
    };
  }
});
