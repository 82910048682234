'use strict';

angular.module('uasApp').component('restoreButton', {
  bindings: {
    btnSize: '@?',
    btnIconOnly: '<?',
    callback: '&',
    confirm: '<?',
    rootType: '@?',
    entityType: '@?'
  },
  require: '?^secured',
  templateUrl: 'es6/app/forms/buttons/restore.button.html',
  controllerAs: 'restoreButtonController',
  controller: function ($element, $uibModal) {
    const restoreButtonController = this;

    restoreButtonController.$onInit = function () {
      if (restoreButtonController.securedCtrl) {
        restoreButtonController.securedCtrl.resecure($element);
      }
    };

    restoreButtonController.onClick = function ($event) {
      if (restoreButtonController.confirm === true) {
        open($event);
      } else {
        restoreButtonController.callback({ comment: '' });
      }
    };

    function open($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $uibModal.open({
        templateUrl: 'es6/app/forms/buttons/restore.button.confirm.modal.html',
        controllerAs: 'modalController',
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.$onInit = function () {
            modalController.rootType = restoreButtonController.rootType;
            modalController.entityType = restoreButtonController.entityType;
          };

          modalController.restore = function () {
            restoreButtonController.callback({ comment: modalController.comment });
            $uibModalInstance.close();
          };
        }
      });
    }
  }
});
