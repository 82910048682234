'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Planning
 * @description
 * The Planning service.
 */
angular.module('uasApp')
    .factory('Planning', function($resource, BackendService) {
        return $resource(BackendService.getBaseUrl() + '/planning/:studyId', {}, {
            'children': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/planning/:studyId/:moduleGroupId',
                isArray: false
            },
            'calendar': {
                method: 'GET',
                url: BackendService.getBaseUrl() + '/planning/:studyId/calendar',
                isArray: false
            },
            'save': {
                method: 'POST'
            }
        });
    });
