'use strict';

angular.module('uasApp').component('scaleValueEdit', {
  templateUrl: 'es6/subjects/scale/scale.value.edit.html',
  bindings: {
    value: '<',
    onSave: '&',
    onCancel: '&'
  },
  controllerAs: 'scaleValueEditController'
});
