'use strict';

angular.module('uasApp')
    .directive('toggleButton', function() {
        return {
            restrict: 'E',
            template: '<i class="fa fa-fw {{ isOpen === true ? \'fa-angle-double-down\' : \'fa-angle-double-right\' }}"></i>',
            scope: {
                isOpen: '='
            }
        };
    });
