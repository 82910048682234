'use strict';

angular.module('uasApp').component('referenceListsItems', {
  templateUrl: 'es6/reference/reference.lists.items.html',
  bindings: {
    referenceType: '<?', // Reference type or element type should be present
    elementType: '<?'
  },
  controllerAs: 'listController',
  controller: function (Pageable, Element, Reference, entityTranslateFilter) {
    const listController = this;

    listController.$onInit = function () {
      listController.pageable = Pageable.of({
        order: 'displayName'
      });

      listController.loading = true;
      loadData().then((references) => {
        listController.references = references;
      }).finally(() => {
        listController.loading = false;
      });
    };

    function loadData() {
      if (angular.isDefined(listController.referenceType)) {
        return Reference.load();
      }

      return Element.load();
    }

    listController.select = function (list) {
      listController.list = list;

      const items = _.get(list, 'items', []);
      _.forEach(items, (item) =>
        item.name = getName(item)
      );
    };

    function getName(item) {
      const reference = _.find(listController.references, { id: item.referenceId });
      return entityTranslateFilter(reference) || item.referenceId;
    }
  }
});
