'use strict';

angular.module('uasApp')
    .component('bulkResult', {
        bindings: {
            result: '<',
            onClose: '&'
        },
        templateUrl: 'es6/report/bulk/bulk.result.html',
        controllerAs: 'bulkResultController',
        controller: function() {
            const bulkResultController = this;

            bulkResultController.close = function() {
                bulkResultController.onClose();
            };
        }
    });