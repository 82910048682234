'use strict';

angular.module('uasApp').component('effortList', {
    bindings: {
        templates: '<',
        entity: '<',
        periodId: '<',
        operations: '<',
        isReadOnly: '<',
        workflowMode: '<?',
        showHistory: '<?',
        showUnassigned: '<?',
        onChange: '&',
        onSwitch: '&',
        onPeriod: '&?'
    },
    templateUrl: 'es6/staffing/effort/effort.list.html',
    controllerAs: 'effortListController',
    controller: function($q, Effort, EntityService, Relation, CustomPage, CustomField, Capacity, Credits) {
        const effortListController = this;

        effortListController.$onInit = function() {
            effortListController.active = true;
            effortListController.entityType = EntityService.getType(effortListController.entity);

            effortListController.loadData();
        };

        effortListController.loadData = function() {
            effortListController.loading = true;
            $q.all([
                Relation.query({
                    entityType: effortListController.entityType,
                    entityId: effortListController.entity.id,
                    active: false
                }).$promise,
                CustomPage.query({
                    basePath: 'relations'
                }).$promise,
                CustomField.query({
                    rootType: effortListController.entityType,
                    entityType: 'relation'
                }).$promise,
                Capacity.get({
                    entityType: effortListController.entityType,
                    entityId: effortListController.entity.id
                }).$promise,
                Credits.get({
                    entityType: effortListController.entityType,
                    entityId: effortListController.entity.id
                }).$promise
            ]).then(([relations, pages, fields, capacity, credits]) => {
                effortListController.pages = pages;
                effortListController.fields = fields;
                effortListController.capacity = capacity;
                effortListController.credits = credits;

                _.forEach(effortListController.templates, (template) => {
                    if (template.role) {
                        template.relations = _(relations).filter({ roleId: template.role.id }).filter(isVisible).value();
                    }
                });
            }).finally(() => {
                effortListController.loading = false;
            });
        };

        function isVisible(relation) {
            return !effortListController.active || relation.changeType !== 'REMOVE';
        }

        effortListController.onEffort = function(refresh) {
            effortListController.onChange();

            if (refresh !== false) {
                effortListController.loadData();
            }
        };

        effortListController.onSelectPeriod = function () {
            if (_.isFunction(effortListController.onPeriod)) {
                effortListController.onPeriod({
                    periodId: effortListController.periodId
                });
            }

            effortListController.loadData();
        };

        effortListController.calculate = function () {
            effortListController.loading = true;
            Effort.calculate({
                entityType: effortListController.entityType,
                entityId: effortListController.entity.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.then(() => {
                effortListController.loadData();
            });
        };
    }
});