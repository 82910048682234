'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalList
 * @description
 * Displays a list of module appraisals.
 */
angular.module('uasApp').component('appraisalList', {
  bindings: {
    module: '<',
    period: '<?',
    operations: '<',
    isReadOnly: '<?',
    inactive: '<?',
    navigate: '<?',
    workflowMode: '<?',
    category: '<?',
    childPage: '<?',
    columns: '<?'
  },
  templateUrl: 'es6/assessment/appraisal/appraisal.list.html',
  controllerAs: 'appraisalListController',
  controller: function ($q, AcademicPeriod, Appraisal, Assessment, Element, MethodType, SecurityService, WorkflowValidator, activeFilter, entityTranslateFilter) {
    const appraisalListController = this;

    appraisalListController.$onInit = function () {
      appraisalListController.operationsToEdit = appraisalListController.workflowMode === true ? 'EDIT_APPRAISALS_WORKFLOW' : 'EDIT_APPRAISALS';
      const allowed = SecurityService.isAllowed(appraisalListController.operationsToEdit, appraisalListController.operations);

      appraisalListController.periodId = ''; // Force trigger ng-change on undefined
      appraisalListController.editable = appraisalListController.isReadOnly !== true && allowed;
      appraisalListController.active = appraisalListController.inactive !== true;
      appraisalListController.appraisals = [];

      WorkflowValidator.setValidity(() => {
        return _.result(appraisalListController.validation, 'valid', true) === true;
      });
    };

    appraisalListController.$onDestroy = function () {
      WorkflowValidator.reset();
    };

    function loadData() {
      setOpened();

      appraisalListController.loading = true;
      $q.all([
        Appraisal.query({
          categoryId: _.result(appraisalListController.category, 'id'),
          entityType: appraisalListController.module.self.type,
          entityId: appraisalListController.module.id
        }).$promise,
        MethodType.query({
          categoryId: _.result(appraisalListController.category, 'id'),
          academicYearId: sessionStorage.academicYear
        }).$promise,
        Element.getActive('EXAM_CRITERIA'),
        validate()
      ]).then(([appraisals, types, criteria, validation]) => {
        appraisalListController.types = types;
        appraisalListController.criteria = criteria;
        appraisalListController.validation = validation;
        appraisalListController.appraisals = buildAll(appraisals, 0, true);
      }).finally(() => {
        appraisalListController.loading = false;
      });
    }

    function setOpened() {
      appraisalListController.opened = getOpened(appraisalListController.appraisals);
    }

    function getOpened(appraisals) {
      return _(appraisals)
        .filter((appraisal) => appraisal.open)
        .transform((result, appraisal) => result.push(appraisal, ...getOpened(appraisal.children)), [])
        .value();
    }

    function buildAll(appraisals, level, editable) {
      return _(appraisals)
        .map((appraisal) => build(appraisal, level, editable))
        .sortBy(['sequence', 'type.sequence', 'weighting', 'id'])
        .value();
    }

    function build(appraisal, level, editable) {
      const assessment = appraisal.assessment;

      const result = angular.copy(appraisal);
      result.level = level;
      result.owned = _.result(assessment, 'moduleId') === appraisalListController.module.id;
      result.editable = editable && appraisal.changeType !== 'REMOVE';

      if (angular.isDefined(assessment)) {
        const type = _.find(appraisalListController.types, { id: assessment.typeId });
        result.container = _.result(type, 'container', false);
        result.type = type;

        result.assessment.displayName = _.defaultTo(entityTranslateFilter(appraisal.assessment), appraisal.assessment.abbreviation);
      } else {
        result.container = false;
      }

      result.open = isOpen(result);
      result.children = [];
      result.validations = getValidations(appraisal);
      return result;
    }

    function getValidations(appraisal) {
      const validations = [];
      validations.push(_.find(appraisalListController.validation.entities, { entity: appraisal.self }));
      if (angular.isDefined(appraisal.assessment)) {
        validations.push(_.find(appraisalListController.validation.entities, { entity: appraisal.assessment.self }));
      }
      return validations;
    }

    function isOpen(appraisal) {
      const found = _.find(appraisalListController.opened, { id: appraisal.id, level: appraisal.level });
      if (found) {
        appraisalListController.toggle(appraisal);
      }
      return angular.isDefined(found);
    }

    appraisalListController.toggle = function (appraisal) {
      if (angular.isDefined(appraisal)) {
        appraisal.open = appraisal.open !== true;

        if (!appraisal.loaded) {
          return loadChildren(appraisal).then(() => {
            setOpened();
          });
        }
      }
      return $q.resolve(appraisal);
    };

    function loadChildren(appraisal) {
      if (appraisal.container) {
        appraisal.loading = true;

        return Appraisal.query({
          categoryId: _.result(appraisalListController.category, 'id'),
          entityType: 'assessment',
          entityId: appraisal.assessment.id
        }).$promise.then((children) => {
          appraisal.children = buildAll(children, appraisal.level + 1, appraisal.owned);
          return appraisal;
        }).finally(() => {
          appraisal.loading = false;
          appraisal.loaded = true;
        });
      } else {
        return $q.resolve(appraisal);
      }
    }

    appraisalListController.generate = function () {
      return Appraisal.generate({
        moduleId: appraisalListController.module.id
      }, {}).$promise.then(() => loadData());
    };

    appraisalListController.onChange = function (parent) {
      if (angular.isUndefined(parent)) {
        loadData();
      } else {
        validate().then(() => {
          parent.validations = getValidations(parent);
          loadChildren(parent);
        });
      }
    };

    function validate() {
      return Assessment.validate({
        entityType: 'module',
        entityId: appraisalListController.module.id,
        categoryId: _.result(appraisalListController.category, 'id'),
        periodId: _.result(appraisalListController.selectedPeriod, 'id')
      }).$promise.then((validation) => {
        appraisalListController.validation = validation;
        return validation;
      });
    }

    appraisalListController.validate = function () {
      loadData();
    };

    appraisalListController.onPeriod = function () {
      AcademicPeriod.find(appraisalListController.periodId).$promise.then((period) => {
        appraisalListController.selectedPeriod = period;
        loadData();
      });
    };

    appraisalListController.expandAll = function (expanded) {
      _.forEach(appraisalListController.appraisals, (appraisal) => {
        expand(appraisal, expanded);
      });
    };

    function expand(appraisal, expanded) {
      appraisal.open = expanded;
      setOpened();

      let promise = $q.resolve();
      if (expanded) {
        promise = load(appraisal);
      }

      promise.then(() => {
        _.forEach(appraisal.children, (child) => expand(child, expanded));
      });
    }

    appraisalListController.getAssessments = function () {
      const promises = _.map(appraisalListController.appraisals, load);
      return $q.all(promises).then(() => {
        const appraisals = _.flatMap(appraisalListController.appraisals, flatten);
        return _(activeFilter(appraisals)).filter({ owned: true }).map('assessment').filter(angular.isDefined).uniqBy('id').value();
      });
    };

    function load(appraisal) {
      let promise = $q.resolve();
      if (!appraisal.loaded) {
        promise = loadChildren(appraisal);
      }

      return promise.then(() => {
        const promises = _.map(appraisal.children, load);
        return $q.all(promises);
      });
    }

    function flatten(appraisal) {
      if (angular.isUndefined(appraisal.children)) {
        return [appraisal];
      }

      return _(appraisal.children).flatMap(flatten).concat([appraisal]).value();
    }
  }
});
