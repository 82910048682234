'use strict';

angular.module('uasApp').component('offeringAdhoc', {
    bindings: {
        offering: '<',
        calendar: '<?',
        fields: '<',
        operations: '<',
        operationsToEdit: '<',
        onClose: '&',
        onSave: '&'
    },
    templateUrl: 'es6/offerings/offering.adhoc.html',
    controllerAs: 'offeringAdhocController',
    controller: function(CustomField) {
        const offeringAdhocController = this;

        offeringAdhocController.$onInit = function() {
            setEvaluation();
        };

        offeringAdhocController.save = function() {            
            offeringAdhocController.onSave({
                offering: offeringAdhocController.offering,
                message: offeringAdhocController.message
            });
        };

        offeringAdhocController.close = function() {
            offeringAdhocController.onClose();
        };

        offeringAdhocController.onChange = function() {
            setEvaluation();
        };

        function setEvaluation() {
            offeringAdhocController.evaluation = CustomField.getEvaluation(
                offeringAdhocController.offering.entity, 
                offeringAdhocController.offering, 
                offeringAdhocController.fields
            );
        }
    }
});