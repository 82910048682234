'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:RequestLogDetails
 * @description
 * The RequestLogDetails factory.
 */
angular.module('uasApp').factory('RequestLogDetails', function ($uibModal, Clipboard, prettyJsonFilter, prettyXmlFilter) {

  function open(requestLog) {
    $uibModal.open({
      templateUrl: 'es6/admin/request-log/request.log.details.html',
      controllerAs: 'modalController',
      controller: function () {
        const modalController = this;
        modalController.requestLog = requestLog;

        modalController.openJson = function (data, $event) {
          openClipboard(prettyJsonFilter(data), 'json', $event);
        };

        modalController.openXml = function (data, $event) {
          openClipboard(prettyXmlFilter(data), 'xml', $event);
        };

        modalController.open = function (data, $event) {
          openClipboard(data, 'text', $event);
        };

        function openClipboard(data, language, $event) {
          $event.preventDefault();
          $event.stopPropagation();

          Clipboard.copy({
            value: data,
            styles: language
          });
        }
      }
    });
  }

  return { open };

});
