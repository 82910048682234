'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:facultyStudyableModules
 * @description
 * # studyableModules
 * Displays the studyability of modules.
 */
angular.module('uasApp')
  .component('facultyStudyableModules', {
    bindings: {
      facultyId: '<'
    },
    templateUrl: 'es6/study/studyable/faculty.studyable.modules.html',
    controllerAs: 'facultyStudyableModulesController',
    controller: function (Pageable, Studyable, AuthService, Report, Language) {
      const facultyStudyableModulesController = this;

      facultyStudyableModulesController.$onInit = function () {
        facultyStudyableModulesController.loading = false;
        facultyStudyableModulesController.all = false;
        facultyStudyableModulesController.searchColumns = false;

        facultyStudyableModulesController.pageable = Pageable.of({
          order: 'code'
        });

        facultyStudyableModulesController.loadData();
      };

      facultyStudyableModulesController.toggle = function (all) {
        facultyStudyableModulesController.all = all;
        facultyStudyableModulesController.loadData();
      };

      facultyStudyableModulesController.loadData = function () {
        const params = facultyStudyableModulesController.pageable.build({
          facultyId: facultyStudyableModulesController.facultyId,
          incorrectOnly: !facultyStudyableModulesController.all
        });

        _.assignWith(params, facultyStudyableModulesController.searchCriteria);

        facultyStudyableModulesController.loading = true;
        Studyable.get(params).$promise.then((page) => {
          facultyStudyableModulesController.page = page;
        }).finally(() => {
          facultyStudyableModulesController.loading = false;
        });

        AuthService.operations('faculty', facultyStudyableModulesController.facultyId).then((operations) => {
          facultyStudyableModulesController.operations = operations;
        });
      };

      facultyStudyableModulesController.enableSearchColumns = function () {
        facultyStudyableModulesController.searchColumns = !facultyStudyableModulesController.searchColumns;
      };

      facultyStudyableModulesController.onFilter = function (search) {
        facultyStudyableModulesController.searchCriteria = search;
        facultyStudyableModulesController.loadData();
      };

      facultyStudyableModulesController.getReport = function() {
        const query = {
          incorrectOnly: !facultyStudyableModulesController.all,
          language: Language.get()
        };

        query.facultyId = facultyStudyableModulesController.facultyId;
        return Report.studyableModules(query).$promise;
      };

    }
  });
