'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:EntityType
 * @description
 * The entity type service.
 */
angular.module('uasApp')
    .factory('EntityType', function (UasResource, AcademicYearHolder, AuthService) {
        const resource = UasResource('/entity-type', {
            searchable: {
                url: '/entity-type/searchable',
                method: 'GET',
                isArray: true
            }
        });

        function getIncludeTypes(type) {
            const value = _.result(type, 'includeTypes');
            const types = _.split(value, ',');
            return _(types).map(_.trim).filter((t) => !_.isEmpty(t)).sort().value();
        }

        function isIncluded(includeTypes, type) {
            if (_.isEmpty(includeTypes)) {
                return true;
            }

            return _.includes(includeTypes, type.code);
        }

        function canCreate(entityType, rootType) {
            return resource.get({
                rootType: rootType || entityType,
                entityType: entityType
            }).$promise.then((type) => {
                const value = _.get(type, 'create', 'ALWAYS');

                if (value === 'ALWAYS') {
                    const allowed = _.get(AcademicYearHolder, 'current.creation');
                    return allowed === true || AuthService.isAdmin();
                } else if (value === 'SIMULATION') {
                    return AcademicYearHolder.isSimulation() === true;
                }

                return false;
            });
        }

        return _.extend(resource, { getIncludeTypes, isIncluded, canCreate });
    });
