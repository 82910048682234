'use strict';

angular.module('uasApp').factory('Document', function ($http, UasResource) {
  const resource = UasResource('/documents', {
    report: {
      url: '/document-report',
      method: 'GET',
      isArray: false
    }
  });

  resource.export = function (documentIds, entity) {
    return $http({
      url: 'api/documents/export',
      method: 'POST',
      responseType: 'arraybuffer',
      data: {
        documentIds,
        entity
      }
    });
  };

  return resource;
});
