'use strict';

angular.module('uasApp').component('assignmentWidget', {
  templateUrl: 'es6/dashboard/assignment.widget.html',
  controllerAs: 'assignmentWidgetController',
  controller: function (Assignment, Availability) {
    const assignmentWidgetController = this;

    assignmentWidgetController.$onInit = function () {
      loadData();
    };

    function loadData() {
      assignmentWidgetController.loading = true;
      Availability.mine({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((availabilities) => {
        assignmentWidgetController.assignments = _(availabilities)
          .map(build)
          .orderBy(['fte', 'allocatedPercentage', 'organisation.code', 'id'], ['desc', 'desc', 'asc', 'asc'])
          .value();
      }).finally(() =>
        assignmentWidgetController.loading = false
      );
    }

    function build(availability) {
      return _.extend(availability.assignment, {
        allocatedPercentage: availability.allocatedPercentage
      });
    }
  }
});
