'use strict';

angular.module('uasApp').component('auditLog', {
  templateUrl: 'es6/admin/audit-log/audit.log.html',
  controllerAs: 'auditLogController',
  controller: function (Pageable, AuditLog) {
    const auditLogController = this;

    auditLogController.$onInit = function () {
      auditLogController.pageable = Pageable.of({
        order: 'timestamp',
        reverse: true
      });

      auditLogController.search();
    };

    auditLogController.search = function () {
      const params = auditLogController.pageable.build(auditLogController.parameters);

      auditLogController.loading = true;
      AuditLog.get(params).$promise.then((results) => {
        auditLogController.auditLogs = results;
      }).finally(() => {
        auditLogController.loading = false;
      });
    };
  }
});