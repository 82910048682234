'use strict';

angular.module('uasApp')
    .directive('shrinkNav', function($window) {
        // Runs during compile
        return {
            restrict: 'A',
            scope: {
                shrinkNav: '@?',
                shrinkDisabled: '<?'
            },
            link: function(scope, elm) {
                if (scope.shrinkDisabled !== true) {
                    const window = angular.element($window);
                    window.bind('scroll', updateClass);
                    scope.$on('$destroy', () => {
                        window.unbind('scroll', updateClass);
                    });
                }

                function updateClass() {
                    const id = _.get(scope, 'shrinkNav', 'fixed-header-limit');
                    const limit = document.getElementById(id);
                    if (limit) {
                        var d = limit.getBoundingClientRect();
                        var offset = d.top;
                        if (offset <= 0) {
                            elm.addClass('active');
                        } else {
                            elm.removeClass('active');
                        }
                    }
                }
            }
        };
    });
