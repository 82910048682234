'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:hookList
 * @description
 * hookList Shows the hooks
 */
angular.module('uasApp').component('hookList', {
    templateUrl: 'es6/process/hook/hook.list.html',
    controllerAs: 'hookListController',
    controller: function (Hook, Pageable, Message, $uibModal) {
        const hookListController = this;

        hookListController.$onInit = function() {
            hookListController.pageable = Pageable.of({
                order: 'id'
            });

            Hook.describe().$promise.then((descriptions) => {
                hookListController.descriptions = _.sortBy(descriptions, 'name');
                hookListController.search();
            });
        };

        hookListController.search = function() {
            const params = hookListController.pageable.build();
    
            hookListController.loading = true;
            Hook.get(params).$promise.then((hooks) => {
                hookListController.hooks = hooks;
            }).finally(() => {
                hookListController.loading = false;
            });
        };

        hookListController.create = function() {
            open();
        };

        hookListController.edit = function(hook) {
            const data = angular.copy(hook);
            open(data);
        };

        function open(hook) {
            $uibModal.open({
                controllerAs: 'hookModalController',
                controller: function($uibModalInstance) {
                    const hookModalController = this;
                    hookModalController.hook = hook;
                    hookModalController.descriptions = hookListController.descriptions;

                    hookModalController.onSave = function() {
                        Message.onSaved();
                        hookModalController.close();
                        hookListController.search();
                    };

                    hookModalController.close = function() {
                        $uibModalInstance.dismiss();
                    };
                },
                template: `
                    <hook-modal
                        hook="hookModalController.hook"
                        descriptions="hookModalController.descriptions"
                        on-cancel="hookModalController.close()"
                        on-save="hookModalController.onSave()">
                    </hook-modal>
                `
            });
        }

        hookListController.remove = function(hook) {
            Hook.remove(hook).$promise.then(() => {
                Message.onRemoved();
                hookListController.search();
            });
        };
    }
});
