'use strict';

angular.module('uasApp').component('uasReportModal', {
    templateUrl: 'es6/report/report.modal.html',
    bindings: {
        requestedReport: '<',
        modal: '<'
    },
    controllerAs: 'reportModalController',
    controller: function ($http) {
        const reportModalController = this;
        reportModalController.isLoading = true;

        reportModalController.$onInit = function () {
            reportModalController.getReport();
        };

        reportModalController.getReport = function() {
            reportModalController.isLoading = true;
            $http.get(reportModalController.requestedReport.url).then((result) => {
                reportModalController.report = result.data;
            }).finally(() => {
                reportModalController.isLoading = false;
            });
        };

        reportModalController.getName = function(reportId) {
            reportId = reportId || '';

            // Some reports have a "/" in their name due to their mapping (e.g. an URL of person/overview).
            // A "/" is not allowed in file names (which can be created by making a CSV export), so replace them with a "-".
            return reportId.replace('/', '-');
        };


        reportModalController.close = function () {
            reportModalController.modal.dismiss();
        };
    }
});
