'use strict';
/**
 * @ngdoc directive
 * @name uasApp.directive:search
 * @description Set search on an element
 * @usage `<input type="text" data-search>`
 * @deprecated Work on modularizing and implementing `<search-typeahead>` instead
 */
angular.module('uasApp').directive('search', function() {
  return {
    restrict: 'AE',
    scope: {
      selected: '@?',
      onSelect: '=?',
      inModal: '<?',    // Indicates if search directive is used in modal
      entityTypes: '<?', // Limits the search in specified entity types
      excludeIds: '<?'
    },
    templateUrl: 'es6/search/search.html',
    controller: function($element, $scope, $state, EntityService, ENTITY_TYPES, Language, Search, entityTranslateFilter, translateFilter) {

      $scope.inputId = _.uniqueId('entitySearch');

      // Loads the items we can search through, this method is used whenever the academic year is changed and on first load
      function loadSearchItems(term, limit) {
        return Search.get({
          academicYearId: sessionStorage.academicYear,
          search: term,
          entityType: $scope.entityTypes,
          excludeIds: $scope.excludeIds,
          size: limit,
          sort: ['sequence,ASC', 'code,ASC'],
          language: Language.get()
        });
      }

      $scope.getSearchItems = function(term, limit) {
        return loadSearchItems(term, limit).$promise.then(function(page) {
          $scope.totalElements = page.totalElements;
          return buildItems(page.content);
        });
      };

      function buildItems(items) {
        let processedItems  = _(items)
          .map(buildItem)
          .groupBy('type')
          .map(buildGroup)
          .flatten()
          .value();

        return _.isEmpty(processedItems) ? [{
          name: translateFilter('Static.Message.NoResultsFound')
        }] : processedItems;
      }

      function buildItem(item) {
        return _.assign(item, {
          type: item.type.trim(),
          route: EntityService.getHref(item),
          name: entityTranslateFilter(item)
        });
      }

      function buildGroup(type) {
        const firstInType = _.head(type);

        if (firstInType) {
          _.assign(firstInType, {
            typeFirst: true,
            typeDisplayName: getTypeDisplayName(firstInType.type),
            typeIcon: EntityService.getIcon(firstInType)
          });
        }

        return type;
      }

      function getTypeDisplayName(type) {
        const nameKey = _.upperFirst(type.replace('-', ''));
        return translateFilter(nameKey);
      }

      $scope.openSelectedItem = function(item) {
        if (item.id) {
          goTo(item);
        } else {
          $scope.clear();
        }
      };

      function goTo(item) {
        if ($scope.onSelect) {
          $scope.onSelect(item);
        } else if (item && item.route && item.id) {
          $scope.selected = '';
          $state.go(item.route, {
            id: item.id
          });
        }
      }

      $scope.clear = function() {
        $scope.selected = '';
        $element.find(`#${$scope.inputId}`)[0].focus();
        goTo();
      };
     }
    };
});
