'use strict';

angular.module('uasApp').component('workflowWidget', {
  bindings: {
    item: '<'
  },
  templateUrl: 'es6/dashboard/workflow.widget.dashboard.html',
  controllerAs: 'workflowWidgetController',
  controller: function ($state, AcademicYear, AcademicYearHolder, entityRedirect) {
    const workflowWidgetController = this;

    workflowWidgetController.$onInit = function () {
      workflowWidgetController.entities = workflowWidgetController.item.entities;
      workflowWidgetController.showCompleted = false;

      AcademicYear.query().$promise.then((years) => {
        workflowWidgetController.years = years;
        setAcademicYear(workflowWidgetController.item.entities);
        setAcademicYear(workflowWidgetController.item.completedEntities);
      });
    };

    function setAcademicYear(entities) {
      _.forEach(entities, (entity) => {
        const yearId = _.get(entity, 'root.academicYearId');
        if (!entity.year && yearId) {
          entity.year = _.find(workflowWidgetController.years, { id: yearId });
        }
      });
    }

    workflowWidgetController.displayCompleted = function (completed) {
      workflowWidgetController.showCompleted = completed;
      if (workflowWidgetController.showCompleted) {
        workflowWidgetController.entities = workflowWidgetController.item.completedEntities;
      } else {
        workflowWidgetController.entities = workflowWidgetController.item.entities;
      }
    };

    workflowWidgetController.onClickItem = function (item) {
      if (item.groupable === true) {
        toggleGroup(item);
      } else {
        workflowWidgetController.goToPage(item);
      }
    };

    function toggleGroup(item) {
      item.collapsed = item.collapsed !== true;
      _.forEach(item.children, (child) => {
        child.visible = item.collapsed;
      });
    }

    workflowWidgetController.goToPage = function (item) {
      if (workflowWidgetController.showCompleted) {
        goToCompletedPage(item);
      } else {
        goToWorkflow(item);
      }
    };

    function goToCompletedPage(item) {
      const entity = item.root;
      if (workflowWidgetController.item.workflow.completedPage) {
        $state.go(`${entity.self.type}.page`, {
          id: entity.id,
          pageId: workflowWidgetController.item.workflow.completedPage.id
        });
      } else {
        entityRedirect.redirect(entity.self.type, { id: entity.id });
      }
    }

    function goToWorkflow(item) {
      const entity = item.root;
      const year = _.find(workflowWidgetController.years, { id: entity.academicYearId });

      return AcademicYearHolder.setCurrent(year).then(() => {
        $state.go('start-workflow', {
          entityType: entity.self.type,
          entityId: entity.id,
          id: item.participant.id
        });
      });
    }
  }
});
