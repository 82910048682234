'use strict';

angular.module('uasApp')
    .component('reportTemplateEdit', {
        bindings: {
            createNew: '<',
            template: '<',
            instance: '<',
            onSubmit: '&'
        },
        templateUrl: 'es6/report/template/report.template.edit.html',
        controllerAs: 'reportTemplateEditController',
        controller: function(AuthService) {
            const reportTemplateEditController = this;

            reportTemplateEditController.$onInit = function() {
              reportTemplateEditController.new = reportTemplateEditController.createNew ||
                _.isEmpty(reportTemplateEditController.template) || reportTemplateEditController.template.editable === false;

              reportTemplateEditController.isAdmin = AuthService.isAdmin();

              setTitle();

              if (isNew()) {
                  reportTemplateEditController.template.visible = false;
                  reportTemplateEditController.template.originalName = reportTemplateEditController.template.name;
                  reportTemplateEditController.template.name = '';
              }
            };

            reportTemplateEditController.save = function() {
                let template = angular.copy(reportTemplateEditController.template);
                if (isNew()) {
                    template = _.omit(template, 'id');
                }

                return reportTemplateEditController.onSubmit({
                    template: template
                });
            };

            function isNew() {
                return reportTemplateEditController.new === true;
            }

            function setTitle() {
                reportTemplateEditController.title = reportTemplateEditController.new
                  ? 'Static.Page.ReportTemplates.Action.Create'
                  : `Static.Page.ReportTemplates.Action.Edit.${reportTemplateEditController.isAdmin ? 'AsAdmin' : 'AsUser'}.Title`;
            }
        }
    });
