'use strict';

/**
 * @ngdoc function
 * @name uasApp.directive:entityIcon
 * @description
 * # EntityIcon
 * Icon for entity.
 */
angular.module('uasApp')
    .directive('entityIcon', function(EntityService) {
        return {
            restrict: 'E',
            scope: {
                entityType: '=',
                tooltipPlacement: '@?'
            },
            transclude: true,
            template: `
                <i
                    class="{{ getIcon() }} fa-fw"
                    uib-tooltip="{{ entityType | type }}"
                    tooltip-placement="{{ tooltipPlacement || \'auto top\' }}"
                    tooltip-append-to-body="true">
                        <span ng-transclude></span>
                </i>
            `,
            controller: function($scope) {
                $scope.getIcon = function() {
                  return EntityService.getIcon($scope.entityType);
                };
            }
        };
    });
