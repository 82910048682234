'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:argumentInput
 * @description
 * argumentInput Input field for script/hook arguments
 */
angular.module('uasApp').component('argumentInput', {
    bindings: {
        argument: '<'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/process/hook/argument.input.html',
    controllerAs: 'argumentInputController',
    controller: function () {
        const argumentInputController = this;

        argumentInputController.$onInit = function() {
            argumentInputController.ngModelCtrl.$render = function () {
                const value = argumentInputController.ngModelCtrl.$modelValue;
                const values = (argumentInputController.argument.multiple && value) ? _.split(value, ', ') : [];

                argumentInputController.value = value;
                argumentInputController.values = values;
            };
        };

        argumentInputController.formatValue = function(value) {
            const option = _.find(argumentInputController.argument.options, { value });
            return _.get(option, 'label', value);
        };

        argumentInputController.addValue = function(value) {
            argumentInputController.values = _(argumentInputController.values).concat(value).uniq().value();
            delete argumentInputController.newValue;
            setValues();
        };

        argumentInputController.removeValue = function(index) {
            argumentInputController.values.splice(index, 1);
            setValues();
        };

        function setValues() {
            argumentInputController.value = _.join(argumentInputController.values, ', ');
            argumentInputController.onChange();
        }

        argumentInputController.onChange = function() {
            argumentInputController.ngModelCtrl.$setViewValue(argumentInputController.value);
        };
    }
});
