'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('specification', {
        url: '/specification/{id:[0-9]{1,8}}',
        resolve: {
            specification: function(Specification, $stateParams) {
                const instance = this;
                return Specification.get({
                    id: $stateParams.id
                }).$promise.then((specification) => {
                    instance.self.data = specification;
                    return specification;
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('specification', $stateParams.id);
            }
        },
        controller: 'SpecificationCtrl',
        controllerAs: 'specificationController',
        template: `
            <entity-tabs 
                view="specification"
                entity="specificationController.specification"
                tabs="specificationController.tabs"
                operations="specificationController.operations"
            />
        `,
        abstract: true,
        entityType: 'specification',
        redirectTo: 'specification.general',
        breadcrumb: {}
    });

    $stateProvider.state('specification.general', {
        url: '/general',
        views: {
            'specification': {
                controllerAs: 'generalStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <specification-general 
                        specification="generalStateController.specification"
                        operations="generalStateController.operations">
                    </specification-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Specification.General',
            label: 'Static.Help.Specification.General'
        }
    });

    $stateProvider.state('specification.relations', {
        url: '/relations',
        views: {
            'specification': {
                controllerAs: 'relationsStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <relations
                        entity-type="specification"
                        entity-id="relationsStateController.specification.id"
                        operations="relationsStateController.operations">
                    </relations>`
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_RELATIONS'],
            title: 'Static.Tab.Specification.Relations',
            label: 'Static.Help.Specification.Relations'
        }
    });

    $stateProvider.state('specification.additional', {
        url: '/additional',
        views: {
            'specification': {
                controllerAs: 'additionalStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <uas-additional
                        entity="additionalStateController.specification"
                        operations="additionalStateController.operations">
                    </uas-additional>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_ADDITIONAL'],
            title: 'Static.Tab.Specification.Additional',
            label: 'Static.Help.Specification.Additional'
        }
    });

    $stateProvider.state('specification.description', {
        url: '/descriptions',
        views: {
            'specification': {
                resolve: {
                    types: function(Description) {
                        return Description.getTypes('specification');
                    }
                },
                controllerAs: 'descriptionStateController',
                controller: function(specification, operations, types) {
                    this.specification = specification;
                    this.operations = operations;
                    this.types = types;
                },
                template: `
                    <description
                        types="descriptionStateController.types"
                        entity-type="specification"
                        entity-id="descriptionStateController.specification.id"
                        operations="descriptionStateController.operations">
                    </description>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_DESCRIPTIONS'],
            title: 'Static.Tab.Specification.Description',
            label: 'Static.Help.Specification.Description'
        }
    });

    $stateProvider.state('specification.capacity', {
        url: '/capacity',
        views: {
            'specification': {
                controllerAs: 'capacityStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <uas-capacity
                        entity="capacityStateController.specification"
                        operations="capacityStateController.operations">
                    </uas-capacity>`
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CAPACITY'],
            title: 'Static.Tab.Specification.Capacity',
            label: 'Static.Help.Specification.Capacity'
        }
    });

    $stateProvider.state('specification.credits', {
        url: '/credits',
        views: {
            'specification': {
                controllerAs: 'creditsStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <uas-credits
                        entity="creditsStateController.specification"
                        operations="creditsStateController.operations">
                    </uas-credits>`
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_CREDITS'],
            title: 'Static.Tab.Specification.Credits',
            label: 'Static.Help.Specification.Credits'
        }
    });

    $stateProvider.state('specification.studies', {
        url: '/studies',
        views: {
            'specification': {
                controllerAs: 'specificationStudiesController',
                controller: function (specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <studies-report 
                        entity="specificationStudiesController.specification"
                        operations="specificationStudiesController.operations">
                    </studies-report>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['VIEW_STUDIES'],
            title: 'Static.Tab.Specification.Studies',
            label: 'Static.Help.Specification.Studies'
        }
    });

    $stateProvider.state('specification.admin', {
        url: '/admin',
        views: {
            'specification': {
                controllerAs: 'adminStateController',
                controller: function(specification, operations) {
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <specification-admin
                        specification="adminStateController.specification"
                        operations="adminStateController.operations">
                    </specification-admin>`
            }
        },
        breadcrumb: {},
        data: {
            secured: ['ADMIN_OBJECT'],
            title: 'Static.Tab.Specification.Admin',
            label: 'Static.Help.Specification.Admin'
        }
    });

    $stateProvider.state('specification.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'specification': {
                controllerAs: 'pageController',
                controller: function($stateParams, operations, specification) {
                    this.pageId = $stateParams.pageId;
                    this.specification = specification;
                    this.operations = operations;
                },
                template: `
                    <page-loader
                        page-id="pageController.pageId"
                        entity="pageController.specification"
                        operations="pageController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });
});