'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processExpander
 * @description
 * processExpander Explores the processes for an entity
 */
angular.module('uasApp').component('processExpander', {
  bindings: {
    entity: '<',
    process: '<',
    operations: '<',
    isReadOnly: '<?',
    isOpen: '<?'
  },
  templateUrl: 'es6/process/progress/process.expander.html',
  controllerAs: 'processExpanderController',
  controller: function($q, Process, ProcessModal) {
    const processExpanderController = this;

    processExpanderController.$onChanges = function() {
      loadProcess(processExpanderController.process).then((process) => {
        process.open = true;

        Process.children({
          parentId: processExpanderController.process.type.id,
          academicYearId: processExpanderController.process.year.id
        }).$promise.then((children) => {
          processExpanderController.children = children;
        });
      });
    };

    function loadProcess(process) {
      process.loading = true;
      return Process.timeline({
        id: process.id,
        entityId: processExpanderController.entity.self.id,
        entityType: processExpanderController.entity.self.type
      }).$promise.then((statuses) => {
        process.statuses = _(statuses).filter((status) => angular.isDefined(status.type)).sortBy(['type.index', 'type.id']).value();
        process.status = _(statuses).filter({ active: true }).map('type').find();
        process.active = _.some(statuses, { active: true });
        setVisible(process);
        return process;
      }).finally(() => {
        process.loading = false;
      });
    }

    processExpanderController.onStatus = function (process, status) {
      status.open = status.open !== true;
      if (status.open) {
        loadStatus(process, status);
      }
    };

    function loadStatus(process, status) {
      if (angular.isDefined(status.loading)) {
        return $q.resolve();
      }

      status.loading = true;
      return Process.propagations({
        id: process.id,
        statusTypeId: status.type.id
      }).$promise.then((propagations) => {
        status.propagations = _.sortBy(propagations, ['type.sequence', 'period.startDate', 'period.endDate', 'id']);
        return $q.all(_.map(status.propagations, loadProcess));
      }).finally(() => {
        status.loading = false;
      });
    }

    processExpanderController.onExpand = function() {
      const expanded = processExpanderController.expanded !== true;
      setExpanded(processExpanderController.process, expanded);
    };

    function setExpanded(process, expanded) {
      processExpanderController.expanded = expanded;
      setOpen(process, expanded);
    }

    function setOpen(process, open) {      
      if (!process.visible) {
        return $q.resolve();
      }

      process.open = open;
      const statuses = _.map(process.statuses, (status) => {
        status.open = open;

        return loadStatus(process, status).then(() => {          
          const propagations = _.map(status.propagations, (propagation) => setOpen(propagation, open));
          return $q.all(propagations);
        });
      });

      return $q.all(statuses);
    }

    processExpanderController.onFilter = function() {
      processExpanderController.filtered = processExpanderController.filtered !== true;
      setVisible(processExpanderController.process);
    };

    function setVisible(object) {
      object.visible = 
      processExpanderController.filtered !== true || 
        object.active === true || 
        _.some(object.propagations, { active: true });

      _.forEach(object.statuses, setVisible);
      _.forEach(object.propagations, setVisible);  
    }

    processExpanderController.show = function (process, status) {
      ProcessModal.open({
        entity: processExpanderController.entity,
        process: process,
        statuses: _.map(process.statuses, 'type'),
        status: status,
        callback: () => loadProcess(process)
      });
    };
  }
});