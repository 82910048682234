'use strict';

angular.module('uasApp').component('uasTabs', {
    bindings: {
        tabs: '<',
        operations: '<',
        entityType: '@?',
        onActiveTab: '&'
    },
    templateUrl: 'es6/app/tabs/tabs.html',
    controllerAs: 'tabsController',
    controller: function($state, $scope, i18nFilter, AuthService, EntityService, Parameter, Tabs, Debug, Language) {
        const tabsController = this;

        tabsController.$onInit = function() {
            tabsController.admin = AuthService.isAdmin();
            tabsController.debug = Debug.isDebug();

            setGroups();

            Parameter.load().then(() => {
                tabsController.grouped = Parameter.getParameterAsBoolean('group.tabs', false);
                tabsController.limit = Parameter.getParameterAsInt('tab.limit', 25);
                updateTabs();
            });

            Language.onChange(updateGroups);

            // This is used when navigating to different tab via unsaved changes modal
            $scope.$on('$stateChangeSuccess', () => updateTabs());
        };

        tabsController.$onChanges = function() {
            tabsController.rootType = EntityService.getRootType(tabsController.entityType);

            setGroups();
            updateTabs();
        };

        tabsController.onGroup = function(group) {
            tabsController.group = group;

            filterTabs();

            const tab = _.head(tabsController.filteredTabs);
            if (angular.isDefined(tab)) {
                tabsController.navigate(tab);
            }
        };

        function filterTabs() {
            tabsController.showGroups = tabsController.grouped && _.size(tabsController.groups) > 1;
            tabsController.filteredTabs = _(tabsController.tabs).filter(isVisible).filter((tab) => {
                if (tabsController.showGroups) {
                    return hasGroup(tab);
                } else {
                    return true;
                }
            }).value();
        }

        function isVisible(tab) {
            return tab.visible !== false && (!tab.disabled || tabsController.debug === true);
        }

        function hasGroup(tab) {
            return _.result(tab.group, 'id') === _.result(tabsController.group, 'id');
        }

        function updateTabs() {
            _.each(tabsController.tabs, (tab) => {
                tab.active = Tabs.isActive(tab);
                if (!tab.disabled) {
                    tab.href = $state.href(tab.route, tab.params);
                }
            });

            updateActive();
            filterTabs();
        }

        function updateActive() {
            const tab = _.find(tabsController.tabs, { active: true });
            if (tab) {
                tabsController.onActiveTab({ tab });
            }

            tabsController.group = findGroup(tab);
        }

        function findGroup(tab) {
            const groupId = _.result(tab, 'group.id');
            const found = _.find(tabsController.groups, (group) => {
                return group.id === groupId;
            });

            if (angular.isUndefined(found)) {
                return _.head(tabsController.groups);
            }

            return found;
        }

        tabsController.show = function () {
            delete tabsController.limit;
        };

        // Due to the bug in angular-ui ui-sref will navigate if ng-disabled is true
        // So we create a onclick functionality which prevents that.
        tabsController.navigate = (tab, $event) => {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }

            if (!tab.disabled) {
                $state.go(tab.route, tab.params).then(() => updateTabs());
            }
        };

        function setGroups() {
            if (tabsController.tabs) {
                tabsController.groups = _(tabsController.tabs)
                  .filter(isVisible)
                  .map('group')
                  .uniqBy('id')
                  .map(buildGroup)
                  .sortBy(['sequence', 'id'])
                  .value();
            }
        }

        function buildGroup(group) {
            const names = _.result(group, 'names');
            if (angular.isUndefined(names)) {
                return { 
                    name: 'Static.Label.Other'
                };
            }

            group.name = i18nFilter(names);
            return group;
        }

        function updateGroups() {
            _.forEach(tabsController.groups, (group) => {
                group.name = buildGroup(group).name;
            });
        }
    }
});
