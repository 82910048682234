'use strict';

angular.module('uasApp')
    .directive('checkForUnsavedChanges', function() {
        return {
            restrict: 'A',
            bindToController: {
                checkForUnsavedChanges: '<',
                onSave: '&',
                operations: '<?',
                secured: '@?'
            },
            controllerAs: 'checkForUnsavedChangesController',
            controller: function ($scope, SecurityService, $state, UnsavedChangesModal) {
                const checkForUnsavedChangesController = this;

                const operations = angular.isUndefined(checkForUnsavedChangesController.operations) ? [] : checkForUnsavedChangesController.operations;
                const secured = angular.isUndefined(checkForUnsavedChangesController.secured) ? '' : checkForUnsavedChangesController.secured;
                checkForUnsavedChangesController.isAllowed = _.isEmpty(secured) ? true : SecurityService.isAllowed(secured, operations);

                checkForUnsavedChangesController.$onInit = function() {
                    if (checkForUnsavedChangesController.isAllowed) {
                        const state = $scope.$on('$stateChangeStart', function (event, toState, toParams) {
                            if (toState.ignoreUnsavedChangesWarning === true) {
                                return;
                            }
    
                            if (toState.ignore === true) {
                                toState.ignore = false;
                                return;
                            }
    
                            // Changes in workflow are automatically saved
                            if (toState.name !== 'workflow') {
                                open(event, () => {
                                    toState.ignore = true;
                                    $state.go(toState, toParams).then(toParams.afterStateChange);
                                });
                            }
                        });
    
                        const workflow = $scope.$on('beforeCompleteWorkflow', function (event, onSuccess) {
                            open(event, onSuccess);
                        });

                        checkForUnsavedChangesController.listeners = [state, workflow];
                    } else {
                        checkForUnsavedChangesController.listeners = [];
                    }
                };

                checkForUnsavedChangesController.$onDestroy = function() {
                    _.forEach(checkForUnsavedChangesController.listeners);
                };

                function open(event, onSuccess) {
                    const dirty = _.result(checkForUnsavedChangesController.checkForUnsavedChanges, '$dirty');
                    if (checkForUnsavedChangesController.checkForUnsavedChanges === true || dirty) {
                        event.preventDefault();

                        UnsavedChangesModal.open({
                            saveWithComment: false,
                            onSave: checkForUnsavedChangesController.onSave,
                            invalid: _.result(checkForUnsavedChangesController.checkForUnsavedChanges, '$invalid'),
                            onDiscard: () => {
                                if (angular.isDefined(checkForUnsavedChangesController.checkForUnsavedChanges)) {
                                    _.invoke(checkForUnsavedChangesController.checkForUnsavedChanges, '$setPristine');
                                }
                                onSuccess();
                            }
                        });
                    }
                }
            }
        };
    });
