'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:weeksSelect
 * @description Shows a list of weeks with a select all option
 */
angular.module('uasApp').component('weeksSelect', {
  bindings: {
    weeks: '<',
    showAll: '<?',
    labelKey: '@',
    isDisabled: '<?',
    onChange: '&'
  },
  templateUrl: 'es6/calendar/week/weeks.select.html',
  controllerAs: 'weeksSelectController',
  controller: function (Changes, Element, Parameter) {
    const weeksSelectController = this;

    weeksSelectController.$onInit = function () {
      Element.getActive('CALENDAR_WEEK_TYPE').then((weekTypes) => {
        weeksSelectController.weekTypes = weekTypes;
      });
    };

    weeksSelectController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'weeks') && weeksSelectController.weeks) {
        Parameter.load().then(() => {
          const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
          const property = calendarWeeks === true ? 'yearWeek' : 'week';
          _.forEach(weeksSelectController.weeks, (week) => week.label = week[property]);
        });
      }
    };

    weeksSelectController.toggle = function (week) {
      week.active = !week.active;
      onChange();
    };

    weeksSelectController.selectAll = function () {
      _.forEach(weeksSelectController.weeks, (week) => {
        if (isTeachingWeek(week)) {
          week.active = true;
        }
      });

      onChange();
    };

    function isTeachingWeek(week) {
      const type = _.find(weeksSelectController.weekTypes, { id: week.typeId });
      return _.get(type, 'defaultSelected', false);
    }

    function onChange() {
      weeksSelectController.onChange({
        weeks: weeksSelectController.weeks
      });
    }
  }
});
