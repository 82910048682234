'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:errorsIcon
 * @description
 */
angular.module('uasApp').component('errorsIcon', {
  bindings: {
    validation: '<',
    message: '<?',
    viewClasses: '@?'
  },
  templateUrl: 'es6/fields/validation/errors.icon.html',
  controllerAs: 'errorsIconController',
  controller: function(Language, translateFilter) {
    const errorsIconController = this;

    errorsIconController.$onInit = function() {
      errorsIconController.onLanguage = Language.onChange(setError);
    };

    errorsIconController.$onDestroy = function() {
      errorsIconController.onLanguage();
    };

    errorsIconController.$onChanges = function() {
      setError();
    };

    function setError() {
      const validations = getValidations();
      errorsIconController.valid = !_.some(validations, { valid: false });

      if (errorsIconController.message) {
        errorsIconController.error = translateFilter(errorsIconController.message);
      } else {
        errorsIconController.error = _(validations).map('messages').filter(_.isArray).flatten().filter({ valid: false }).map(translateError).uniq().sort().join(', ');
      }
    }

    function getValidations() {
      if (_.isArray(errorsIconController.validation)) {
        return errorsIconController.validation;
      }

      return [errorsIconController.validation];
    }

    function translateError(error) {
      return translateFilter(error.message, {
        displayName: error.displayName,
        value: error.value
      });
    }
  }
});
