'use strict';

angular.module('uasApp').config(function($provide) {
    $provide.decorator('$exceptionHandler', function($delegate, $injector) {

      function report(error) {
          const exceptionReporter = $injector.get('exceptionReporter');
          exceptionReporter.report(error);
      }

      var debouncedReport = _.debounce(report, 1000);

        return function(exception, cause) {
          const error = { cause };

          if (_.isString(exception)) {
            error.message = exception;
        } else {
          error.message = exception.message;
          error.stack = exception.stack;
        }

        var $location = $injector.get('$location');
          error.url = $location.absUrl();
        debouncedReport(error);

        /*
            For some reason it is not possible to properly unit test this
            call to $delegate. The reason for this is that $exceptionHandler
            is mocked by ngMock by default, and for some reason this prevents
            us from using our normal mocking strategies.

            I've already sunken 3 hours of my life into the hellhole which
            is unit testing $exceptionHandler decorators. Since I failed
            miserably, I can only ask you not to remove the next line of
            code, so the default behavior of $exceptionHandler is still
            executed.

                                                    -- Signed Maarten Hus
        */
            $delegate(exception, cause);
        };
    });
});
