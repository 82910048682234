'use strict';

angular.module('uasApp').component('modeButton', {
    bindings: {
        active: '<',
        icon: '@',
        label: '@'
    },
    templateUrl: 'es6/app/buttons/mode.button.html',
    controllerAs: 'modeButtonController'
});