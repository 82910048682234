'use strict';

angular.module('uasApp')
    .directive('largeTooltip', function() {
        return {
            restrict: 'E',
            templateUrl: 'views/directives/large-tooltip.html',
            scope: {
              text: '@',
              position: '@',
              icon: '@'
            }
        };
    });