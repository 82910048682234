'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:AdhocOffering
 * @description
 * The AdhocOffering service.
 */
angular.module('uasApp').factory('AdhocOffering', function (UasResource) {
    return UasResource('/adhoc-offerings');
});