'use strict';

angular.module('uasApp').component('availabilityManager', {
    templateUrl: 'es6/staffing/availability/availability.manager.html',
    bindings: {
        assignment: '<',
        operations: '<',
        isReadOnly: '<?',
        workflowMode: '<',
        onChange: '&',
        onClose: '&'
    },
    controllerAs: 'availabilityManagerController',
    controller: function ($q, Availability, AvailabilityModal, Task, Period, SecurityService) {
        const availabilityManagerController = this;

        availabilityManagerController.$onInit = function () {
            availabilityManagerController.operationsToEdit = availabilityManagerController.workflowMode === true ? 'EDIT_TASKS_WORKFLOW' : 'EDIT_TASKS';
            availabilityManagerController.editable = 
                availabilityManagerController.isReadOnly !== true && 
                SecurityService.isAllowed(availabilityManagerController.operationsToEdit, availabilityManagerController.operations);
            availabilityManagerController.personal = SecurityService.isAllowed(['VIEW_TASKS_PERSONAL'], availabilityManagerController.operations);

            Period.query({
                academicYearId: sessionStorage.academicYear,
                planning: true
            }).$promise.then((periods) => {
                availabilityManagerController.periods = periods;
            });

            loadData();
        };

        function loadData() {
            availabilityManagerController.loading = true;
            calculate().finally(() => {
                availabilityManagerController.loading = false;
            });
        }

        function calculate() {
            return Availability.calculate({
                assignmentId: availabilityManagerController.assignment.id,
                academicYearId: sessionStorage.academicYear
            }, {}).$promise.then((availability) => {
                // Execute after calculation because tasks may not exist yet
                return Task.query({
                    assignmentId: availabilityManagerController.assignment.id,
                    academicYearId: sessionStorage.academicYear,
                    personal: availabilityManagerController.personal
                }).$promise.then((tasks) => {
                    return _.filter(tasks, (task) => task.changeType !== 'REMOVE');
                }).then((tasks) => {
                    return [availability, tasks];
                });
            }).then(([availability, tasks]) => {
                setDetails(availability, tasks);
            });
        }

        function overlaps(task, period) {
            if (angular.isUndefined(task.period)) {
                return true;
            }

            return !(task.period.startDate > period.endDate || task.period.endDate < period.startDate);
        }

        function setDetails(availability, tasks) {
            const factor = _.result(availabilityManagerController.period, 'factor', 1);

            if (angular.isUndefined(availabilityManagerController.period)) {
                availabilityManagerController.tasks = tasks;
            } else {
                availabilityManagerController.tasks = _(tasks).map((task) => {
                    const periods = _.filter(availabilityManagerController.periods, (period) => overlaps(task, period));
                    if (_.some(periods, { id: availabilityManagerController.period.id })) {
                        if (periods.length > 1) {
                            const formula = task.formula || task.hours;

                            return _.extend(task, {
                                formula: formula ? `${formula} * ${factor} (${availabilityManagerController.period.code})` : '',
                                hours: task.hours * factor,
                                correction: task.correction * factor,
                                correctedHours: task.correctedHours * factor
                            });
                        }
                        
                        return task;
                    }
                }).filter(angular.isDefined).value();
            }

            const availableHours = availability.availableHours * factor;
            const allocatedHours = _.sumBy(availabilityManagerController.tasks, 'correctedHours');
            const allocatedPercentage = Math.round((allocatedHours / availableHours) * 100);
            const remainingHours = availableHours - allocatedHours;

            availabilityManagerController.availability = _.extend(availability, {
                availableHours,
                allocatedHours,
                allocatedPercentage,
                remainingHours
            });
        }

        availabilityManagerController.onPeriod = function(period) {
            availabilityManagerController.period = period;
            calculate();
        };

        availabilityManagerController.calculate = function () {
            return calculate().then(() => {
                availabilityManagerController.onChange();
            });
        };

        availabilityManagerController.save = function(task) {
            const form = build(task);
            Task.store(form).$promise.then(() => {
                availabilityManagerController.calculate();
            });
        };

        function build(task) {
            const form = angular.copy(task);
            form.templateId = task.template.id;
            form.assignmentId = availabilityManagerController.availability.assignment.id;
            return form;
        }

        availabilityManagerController.reopen = function() {
            AvailabilityModal.open({
                assignmentId: _.result(availabilityManagerController.assignment, 'id'),
                operationsToEdit: availabilityManagerController.operationsToEdit,
                onChange: availabilityManagerController.onChange
            });
        };
    }
});
