'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Entity
 * @description
 * The Entity service.
 */
angular.module('uasApp').factory('Entity', function ($injector, UasResource) {
    const resource = UasResource('/entities', {
        types: {
            method: 'GET',
            url: '/entities/types',
            isArray: true
        }
    });

    function find(reference) {
        if (_.isEmpty(reference)) {
            return resolve();
        }

        const handler = getResource(reference.type);
        if (angular.isUndefined(handler)) {
            return resolve();
        }

        return handler.find(reference.id);
    }

    function getResource(type) {
        if (_.isEmpty(type)) {
            return;
        }

        const name = _.upperFirst(_.camelCase(type));
        return $injector.get(name);
    }

    function resolve() {
        return resource.resolve();
    }

    return _.extend(resource, { find });
});