'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ModuleGroupModuleGroup
 * @description
 * The ModuleGroupModuleGroup service.
 */
angular.module('uasApp')
    .factory('ModuleGroupModuleGroup', function(UasResource) {
        return UasResource('/module-group-module-group', {
            'restore': {
                method: 'PUT',
                url: '/module-group-module-group/:id/restore',
                isArray: false
            }
        });
    });
