'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:notePage
 * @description
 * Page that manages and displays notes.
 */
angular.module('uasApp').component('notePage', {
  templateUrl: 'es6/note/note.page.html',
  bindings: {
    entity: '<',
    page: '<',
    workflow: '<?',
    operations: '<',
    isReadOnly: '<?'
  },
  controllerAs: 'notePageController',
  controller: function (Page) {
    const notePageController = this;

    notePageController.$onInit = function () {
      notePageController.readOnly = notePageController.isReadOnly || Page.getParameterAsBoolean(notePageController.page, 'readOnly', false);
      notePageController.showEmpty = Page.getParameterAsBoolean(notePageController.page, 'showEmpty', false);
      notePageController.operationsToEdit = angular.isDefined(notePageController.workflow) ? ['EDIT_NOTES_WORKFLOW'] : ['EDIT_NOTES'];
    };
  }
});
