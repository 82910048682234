'use strict';

angular.module('uasApp').component('linkEdit', {
  bindings: {
    link: '<',
    entity: '<',
    fields: '<',
    operations: '<',
    operationsToEdit: '<',
    onCancel: '&',
    onSave: '&'
  },
  templateUrl: 'es6/link/link.edit.html',
  controllerAs: 'linkEditController',
  controller: function (Link) {
      const linkEditController = this;

      linkEditController.close = function() {
        linkEditController.onCancel();
      };

      linkEditController.save = function(link, comment) {
        Link.store({
            content: link,
            comment: comment
        }).$promise.then(() => {
          linkEditController.onSave();
        });
      };
  }
});