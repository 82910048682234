'use strict';

angular.module('uasApp').component('customForm', {
    templateUrl: 'es6/custom/fields/custom.form.html',
    bindings: {
        entityType: '@?',
        entity: '<',
        root: '<',
        items: '<',
        excludeIds: '<?',
        commentType: '<?',
        operations: '<',
        operationsToEdit: '<',
        isReadOnly: '<?',
        onEvaluate: '&',
        onParentSave: '<?',  // save callback should have form callback(entity, comment)
        onSave: '&'
    },
    controllerAs: 'customFormController',
    controller: function ($q, AuthService, EntityService, CustomField, SecurityService, WorkflowValidator) {
        const customFormController = this;

        customFormController.$onInit = function() {
            customFormController.readOnly = customFormController.isReadOnly === true;
            customFormController.operationsToEdit_ = AuthService.buildOperationsToEdit(customFormController.operationsToEdit, ['EDIT_WORKFLOW']);
            
            const isAllowed = SecurityService.isAllowed(customFormController.operationsToEdit_, customFormController.operations);
            if (isAllowed && customFormController.onParentSave) {
                customFormController.onParentSave.push(saveCallback);
            }

            _.forEach(customFormController.items, prepare);

            if (!customFormController.readOnly) {
                WorkflowValidator.setValidity(() => customFormController.customForm);
                WorkflowValidator.onSave((comment) => {
                    return customFormController.save(customFormController.items, comment);
                }, {
                    rootType: _.get(customFormController.entity, 'root.type'),
                    entityType: customFormController.entityType || _.get(customFormController.entity, 'self.type')
                }, customFormController.commentType);
            }

            setEvaluation();
        };

        customFormController.$onDestroy = function () {
            WorkflowValidator.reset();
        };

        function prepare(item) {
            if (item.field) {
                item.disabled = isDisabled(item);
                item.field.values = item.values;
            }
        }

        function isDisabled(item) {
            if (customFormController.readOnly) {
                return true;
            }
            
            return !CustomField.isEditable(item.field, customFormController.entity, customFormController.root);
        }

        customFormController.onChange = function() {
            if (!customFormController.readOnly) {
                setEvaluation();
            }
        };

        function setEvaluation() {
            customFormController.evaluation = {
                entity: EntityService.toReference(customFormController.entity),
                values: getValues()
            };

            customFormController.onEvaluate({
                evaluation: customFormController.evaluation
            });
        }

        function getValues() {
            let model = {};
            _.forEach(customFormController.items, (item) => {
                if (item.field) {
                    const values = getValueArray(item.values);
                    model[item.field.name] = CustomField.parseValues(values, item.field);
                }
            });
            return model;
        }

        function getValueArray(value) {
            let values = value;
            if (!_.isArray(values)) {
                values = [values];
            }
            return _.filter(values, hasValue);
        }

        function hasValue(value) {
            return angular.isDefined(value) && value !== null && value !== '';
        }

        /**
         * Function to be called from onParentSave callbacks
         */
        function saveCallback(entity, comment) {
            return save(customFormController.items, entity, comment);
        }

        function save(items, entity, comment) {
            if (!customFormController.isReadOnly) {
                const model = _.map(items, (item) => {
                    if (item.field) {
                        const values = getValueArray(item.values);
                        return {
                            field: item.field,
                            values: values,
                            value: CustomField.parseValues(values, item.field)
                        };
                    }
                });

                return customFormController.onSave({ 
                    items: model,
                    entity: entity,
                    comment: comment || ''
                });
            }
            
            return $q.resolve();
        }

        customFormController.save = function (items, comment) {
            return save(items, customFormController.entity, comment);
        };
    }
});
