'use strict';

/**
 * @ngdoc service
 * @name uasServices.factory:CalendarPeriod
 * @description
 * The CalendarPeriod service.
 */
angular.module('uasApp').factory('CalendarPeriod', function (UasResource, AcademicYearHolder) {
    const resource = UasResource('/calendar-periods');

    resource.getReferenceDate = function (period) {
        if (period) {
            return period.startDate;
        }
        return AcademicYearHolder.current.start;
    };

    return resource;
});
