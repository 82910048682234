'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Person
 * @description
 * The Person service.
 */
angular.module('uasApp').factory('LoginAsPermission', function(UasResource) {
    return UasResource('/login-as-permission', {
        allowed: {
            method: 'GET',
            url: '/login-as-permission/allowed',
            isArray: true
        },
        login: {
            method: 'POST',
            url: '/login-as-permission/:id/login',
            isArray: false
        }
    });
});
