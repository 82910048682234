'use strict';

angular.module('uasApp').component('effortReport', {
    bindings: {
        entity: '<',
        operations: '<',
        workflowMode: '<?'
    },
    templateUrl: 'es6/staffing/effort/report/effort.report.html',
    controllerAs: 'effortReportController',
    controller: function (Effort, EffortModal, Language) {
        const effortReportController = this;

        effortReportController.$onInit = function () {
            effortReportController.defaultSort = Language.getPropertyName();

            const entityType = _.result(effortReportController.entity, 'self.type');
            if (entityType === 'study') {
                effortReportController.studyId = effortReportController.entity.id;
            }
            effortReportController.name = 'effort';
            if (effortReportController.entity) {
                effortReportController.name = `${effortReportController.entity.code}-effort`;
            }

            loadData();
            Language.onChange(() => {
                effortReportController.defaultSort = Language.getPropertyName();
                loadData();
            });
        };

        function loadData() {
            effortReportController.loading = true;
            Effort.report({
                definition: true
            }).$promise.then((report) => {
                effortReportController.columns = report.headers;
                effortReportController.groups = report.groups;
            }).finally(() => {
                effortReportController.loading = false;
            });
        }

        effortReportController.onSearch = function (params) {
            if (effortReportController.params) {
                effortReportController.reload = {};
            }
            effortReportController.params = angular.copy(params) || {};
        };

        effortReportController.getData = function (pageable) {
            effortReportController.pageable = pageable;

            const params = getParameters(pageable);
            return Effort.report(params).$promise;
        };

        function getParameters(pageable) {
            const params = _.extend(pageable, {
                studyId: effortReportController.params.studyId,
                moduleGroupId: effortReportController.params.moduleGroupId,
                periodId: effortReportController.params.periodId,
                term: effortReportController.params.term,
                attention: effortReportController.params.attention,
                complete: effortReportController.params.complete
            });

            const entityType = _.result(effortReportController.entity, 'self.type');
            if (entityType !== 'study') {
                params.entityType = entityType;
                params.entityId = effortReportController.entity.id;
            }
            return params;
        }

        effortReportController.calculate = function () {
            const entityType = _.result(effortReportController.entity, 'self.type');

            effortReportController.loading = true;
            Effort.calculate({
                entityType,
                entityId: effortReportController.entity.id,
                academicYearId: sessionStorage.academicYear
            }).$promise.finally(() =>
                effortReportController.loading = false
            );
        };

        effortReportController.open = function (row) {
            Effort.get({ id: row.entity.id }).$promise.then((effort) => {
                EffortModal.open({
                    effort,
                    workflowMode: effortReportController.workflowMode,
                    onChange: () => effortReportController.onSearch(effortReportController.params)
                });
            });
        };
    }
});