'use strict';

angular.module('uasApp').filter('boolean', function (translateFilter) {
    return function (value) {
        if (angular.isUndefined(value)) {
            return '';
        }

        if (value !== true && value !== false) {
            return value;
        }

        const key = 'Boolean.' + (value ? 'TRUE' : 'FALSE');
        return translateFilter(key);
    };
});
