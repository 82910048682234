'use strict';

angular.module('uasApp').component('editModuleOffering', {
  bindings: {
    module: '<',
    operations: '<',
    onCancel: '&',
    hideEdit: '<?',
    workflowMode: '<?'
  },
  templateUrl: 'es6/planboard/edit/edit.module.offering.html',
  controllerAs: 'editModuleOfferingController',
  controller: function () {
    const editModuleOfferingController = this;

    editModuleOfferingController.cancel = function () {
      editModuleOfferingController.onCancel();
    };
  }
});
