'use strict';

angular.module('uasApp')
    .service('EntityHolder', function() {
        var instance = {};
        var holder = null;

        instance.set = function(entityHolder) {
            holder = entityHolder;
        };
        instance.get = function() {
            return holder;
        };

        return instance;
    });
