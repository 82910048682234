'use strict';

angular.module('uasApp')
  .component('uasStudyCost', {
    bindings: {
      study: '<',
      operations: '<',
      workflowMode: '<?'
    },
    templateUrl: 'es6/cost/study.cost.html',
    controllerAs: 'studyCostController',
    controller: function ($uibModal, $q, Language, StudyCost, StudyModuleGroup,
      ModuleGroupModuleGroup, ModuleGroupModule, Element, Parameter, Period, AuthService, $timeout,
      Structures, AcademicYearHolder, EntityService, Method, MethodType, MethodModals,
      Relation, SecurityService, Category, Message, ModuleGroup,
      entityTranslateFilter, translateFilter, i18nFilter, ENTITY_STATES) {

      const studyCostController = this;
      let groupCache = {};

      studyCostController.setMode = function(mode) {
        studyCostController.mode = mode;
      };

      studyCostController.$onInit = function () {
        studyCostController.lines = [];
        studyCostController.studyCostLines = [];
        studyCostController.descriptions = [];
        studyCostController.cache = {};
        studyCostController.previousLines = [];

        studyCostController.language = Language.get();

        studyCostController.entityStates = ENTITY_STATES;
        studyCostController.studyId = _.result(studyCostController.study, 'id');

        loadData();
      };

      studyCostController.isSimulation = function () {
        return AcademicYearHolder.isSimulation() === true;
      };

      function loadData() {
        studyCostController.loading = true;

        loadReferenceData().then(() =>
          loadStudyCost()
        ).finally(() => {
          studyCostController.loading = false;
        });
      }

      function loadReferenceData() {
        return $q.all([
          Period.query({
            academicYearId: sessionStorage.academicYear
          }).$promise,
          Element.getActive('PHASE'),
          Category.query({
            rootType: 'METHOD'
          }).$promise,
          MethodType.query({
            academicYearId: sessionStorage.academicYear
          }).$promise,
          StudyCost.properties().$promise,
          Parameter.load()
        ]).then(([periods, phases, categories, methods, properties]) => {
          studyCostController.periods = periods;
          studyCostController.phases = phases;
          studyCostController.methodCategories = categories;

          const budgetCategories = _.filter(studyCostController.methodCategories, { budget: true });
          studyCostController.methodTypes = methods.filter((type) => {
            return _.some(budgetCategories, { id: type.categoryId });
          });

          studyCostController.properties = properties;
          studyCostController.isOrderAllowed = Parameter.getParameterAsBoolean('curriculum.order');
          studyCostController.maxGroupDepth = Parameter.getParameterValue('curriculum.max_depth');

          studyCostController.permissions = studyCostController.workflowMode === true ? {
            editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_WORKFLOW', studyCostController.operations),
            editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP_WORKFLOW', studyCostController.operations),
            editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP_WORKFLOW', studyCostController.operations)
          } : {
            editModuleGroupModule: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE', studyCostController.operations),
            editModuleGroupModuleGroup: SecurityService.isAllowed('EDIT_MODULE_GROUP_MODULE_GROUP', studyCostController.operations),
            editStudyModuleGroup: SecurityService.isAllowed('EDIT_STUDY_MODULE_GROUP', studyCostController.operations)
          };

          studyCostController.isEditingStructureAllowed = _.some(studyCostController.permissions);
          studyCostController.operationsToView = ['VIEW_COST'];
          studyCostController.operationsToEdit = studyCostController.workflowMode === true ? ['EDIT_COST_WORKFLOW'] : ['EDIT_COST'];

          setModes();
        });
      }

      function setModes() {
        studyCostController.MODES = {
          CHILDREN: {
            property: 'children',
            disabled: false,
            icon: 'fa fa-sitemap'
          },
          FLATTENED: {
            property: 'flattened',
            disabled: true,
            icon: 'fa fa-table'
          }
        };

        studyCostController.setMode(studyCostController.MODES.CHILDREN);

        if (!Parameter.getParameterAsBoolean('curriculum.show_flattened', false)) {
          delete studyCostController.MODES.FLATTENED;
        }

        studyCostController.modeLength = _.size(studyCostController.MODES);
      }

      function loadStudyCost() {
        StudyCost.get({
          studyId: studyCostController.studyId
        }).$promise.then((cost) => {
          setStudyCost(cost);
        });
      }

      function setStudyCost(result) {
        if (angular.isDefined(result.studyCost)) {
          studyCostController.cost = result.studyCost;
          _.each(result.studyCostLines, (line) => {
            line.children = [];
            if (angular.isUndefined(line.target)) {
              line.target = {};
            }
            line.type = _.result(line.target, 'type');
            line.entityType = _.result(line.entity, 'type');
            line.phase = getElementById(studyCostController.phases, line.phaseId);
          });

          const grouped = _.groupBy(result.studyCostLines, 'parentId');
          studyCostController.lines = grouped[undefined] || [];

          // Attach each cost line to the structure
          const parentIds = _(Object.keys(grouped)).filter((v) => v !== 'undefined').map((id) => parseInt(id)).value();
          _.each(parentIds, function (parentId) {
            const parent = _.find(result.studyCostLines, { id: parentId });
            parent.children = grouped['' + parentId] || [];
            _.each(parent.children, (c) => c.parent = parent);
          });

          // Calculate the desired indenting levels
          _.each(result.studyCostLines, (line) => {
            line.level = calculateLineLevel(line);
            line.isLeaf = !_.some(line.children, (child) => child.type === 'module-group');
          });

          _.each(studyCostController.lines, (line) => {
            line.flattened = flattenStructure(line, line);
          });

          setGroupCache({
            studyId: result.study.id,
            active: true
          }).then(() => {
            _.each(studyCostController.lines, enrich);
            reopen(result.studyCostLines);
          });
        } else {
          studyCostController.cost = {
            studyId: studyCostController.studyId
          };

          studyCostController.lines = [];
        }
      }

      function reopen(lines) {
        const promises = _(studyCostController.previousLines).filter({
          open: true
        }).map((line) => {
          return _.find(lines, { id: line.id });
        }).filter(angular.isDefined).map((line) => {
          line.open = true;
          return ensure(line);
        }).value();

        $q.all(promises).finally(() => {
          _.forEach(lines, calculate);
        });
      }

      function getElementById(elements, id) {
        return _.find(elements, { id: id });
      }

      function flattenStructure(root, parent) {
        const modules = getModules(root, parent);
        return groupByCluster(root, modules);
      }

      function getModules(root, parent) {
        let modules = [];
        _.each(parent.children, function (item) {
          if (item.type === 'module') {
            const module = angular.copy(item);
            modules.push(module);
          } else if (item.children) {
            const result = getModules(root, item);
            modules = modules.concat(result);
          }
        });
        return modules;
      }

      function groupByCluster(root, modules) {
        const structure = [];

        const clusters = _.groupBy(modules, 'cluster');
        _.forEach(clusters, (modulesInCluster, cluster) => {
          const item = {
            parent: root,
            open: root.open === true,
            id: root.id + '-' + cluster,
            code: cluster,
            sequence: 0,
            flattened: modulesInCluster,
            temporary: true
          };

          item.level = calculateLineLevel(item);
          item.totalCost = _.sumBy(modulesInCluster, 'totalCost');
          item.freechoiceCost = _.sumBy(modulesInCluster, 'freechoiceCost');
          item.calculatedCredits = _.sumBy(modulesInCluster, 'calculatedCredits');

          _.forEach(modulesInCluster, (module) => {
            module.parent = item;
            module.level = calculateLineLevel(module);

            module.flattened = module.children;
            _.forEach(module.flattened, (child) => {
              child.level = calculateLineLevel(child);
            });
          });

          structure.push(item);
        });

        return structure;
      }

      function calculateLineLevel(line) {
        let level = 1;
        let parent = line.parent;
        while (angular.isDefined(parent)) {
          level++;
          parent = parent.parent;
        }
        return level;
      }

      function cleanLines(lines) {
        lines.forEach((line) => {
          line.numberOfPeriods = Number(line.numberOfPeriods);
          if (angular.isDefined(line.children)) {
            cleanLines(line.children);
          }
        });
      }

      /**
       * Cleans up invalid input from the frontend
       */
      function cleanInput() {
        if (studyCostController.cost.studentFlowYear1 > 100) {
          studyCostController.cost.studentFlowYear1 = 100;
        }
        if (studyCostController.cost.studentFlowYear2 > 100) {
          studyCostController.cost.studentFlowYear2 = 100;
        }
        if (studyCostController.cost.studentFlowYear3 > 100) {
          studyCostController.cost.studentFlowYear3 = 100;
        }
        cleanLines(studyCostController.lines);
      }

      studyCostController.save = function (form) {
        studyCostController.loading = true;

        cleanInput();

        const lines = flatten(studyCostController.lines);
        studyCostController.previousLines = lines;

        return StudyCost.update(buildSaveRequest(lines)).$promise.then(() => {
          form.$setDirty();
          loadStudyCost();
        }).finally(() => {
          studyCostController.loading = false;
        });
      };

      studyCostController.saveAndCalculate = function (form) {
        studyCostController.loading = true;

        cleanInput();
        groupCache = {};

        const lines = flatten(studyCostController.lines);
        studyCostController.previousLines = lines;

        StudyCost.save(buildSaveRequest(lines)).$promise.then(() => {
          if (form) {
            form.$setPristine();
          }
          loadData();
        }).finally(() => {
          studyCostController.loading = false;
        });
      };

      function buildSaveRequest(lines) {
        let request = {
          studyCost: studyCostController.cost
        };

        request.studyCostLines = _.map(lines, function (line) {
          return {
            id: line.id,
            parentId: line.parentId,
            code: line.code,
            flowPercentage: line.flowPercentage,
            freechoiceMultiplier: line.freechoiceMultiplier,
            numberOfPeriods: line.numberOfPeriods,
            credits: line.credits,
            sequence: line.ordered === true ? line.sequence : undefined
          };
        });

        return request;
      }

      function flatten(data) {
        var list = [];
        _.each(data, function (item) {
          list.push(item);
          if (item.children) {
            var result = flatten(item.children);
            list = list.concat(result);
          }
        });
        return list;
      }

      studyCostController.expandAllToModule = function () {
        studyCostController.loading = true;
        $timeout(() => {
          studyCostController.lines.forEach((line) => {
            studyCostController.expandToModule(line);
          });
          studyCostController.loading = false;
        }, 0);
      };

      studyCostController.expandToModule = function (line) {
        if (_.result(line.target, 'type') !== 'module') {
          studyCostController.open(line);

          _.each(line.children, (c) => studyCostController.expandToModule(c));
          _.each(line.flattened, (f) => studyCostController.expandToModule(f));
        }
      };

      studyCostController.expandAll = function (line) {
        studyCostController.open(line);
        studyCostController.expandToModule(line);
      };

      studyCostController.open = function (line, expandChildren) {
        return ensure(line).then(() => {
          if (expandChildren === true) {
            const promises = _.map(line.children, (child) => studyCostController.open(child, expandChildren));
            return $q.all(promises);
          }
        }).finally(() => {
          calculate(line);
          line.open = true;
        });
      };

      function calculate(line) {
        line.totalCredits = _.sumBy(line.children, (child) => {
          if (_.result(child.target, 'type') === 'module-group') {
            return child.minimumCredits || 0;
          } else {
            return child.credits || 0;
          }
        });

        return line;
      }

      function ensure(line) {
        return getGroupData(line).then(() => {
          _.each(line.children, enrich);
          return line;
        });
      }

      function getGroupData(line) {
        const isCached = _(line.children)
          .filter((child) => _.result(child.target, 'type') === 'module-group')
          .every((child) => angular.isDefined(groupCache[child.target.id]));

        if (!isCached) {
          return setGroupCache({
            parentId: line.target.id,
            active: true
          });
        }

        return $q.resolve();
      }

      function setGroupCache(params) {
        return ModuleGroup.tree(params).$promise.then((groups) => {
          _.each(groups, (group) => groupCache[group.id] = group);
        });
      }

      function enrich(line) {
        if (_.result(line.target, 'type') === 'module-group') {
          const moduleGroup = groupCache[line.target.id];
          if (moduleGroup) {
            line.groupType = moduleGroup.groupType;
            line.choiceType = moduleGroup.choiceType;
            line.minimumCredits = _.result(moduleGroup.credits, 'minimum');
          }
        }

        return calculate(line);
      }

      studyCostController.groupTooltipText = function (line) {
        let text = entityTranslateFilter(line);
        if (line.groupType) {
          text += ' - ' + i18nFilter(line.groupType.names);
        }
        if (line.choiceType) {
          text += ' - ' + entityTranslateFilter(line.choiceType);
        }
        return text;
      };

      studyCostController.close = function (line) {
        line.open = false;
      };

      studyCostController.hasChildren = function(line, type) {
        return _.some(line.children, (child) => child.type === type);
      };

      studyCostController.toggle = function (line) {
        if (line.open) {
          studyCostController.close(line);
        } else {
          studyCostController.open(line);
        }
      };

      studyCostController.redirect = function (line, newTab) {
        if (newTab) {
          EntityService.redirectPlain(line.target, {
            target: '_blank'
          });
        } else {
          EntityService.redirectPlain(line.target);
        }
      };

      //
      // Modifications
      //

      studyCostController.onNumberOfPeriodsChange = function (line) {
        studyCostController.cache[line.id] = {
          numberOfPeriods: line.numberOfPeriods
        };

        var lines = _.filter(flatten(studyCostController.lines), {
          id: line.id,
          type: line.type
        });

        _.each(lines, function (x) {
          x.numberOfPeriods = line.numberOfPeriods;
        });
      };

      // Add

      studyCostController.addGroupToStudy = function (form) {
        Structures.addGroupToStudy({
          study: studyCostController.study,
          operations: studyCostController.operations,
          operationsToEdit: studyCostController.operationsToEdit,
          workflowMode: studyCostController.workflowMode,
          onAdd: function (group, study, message) {
            return StudyModuleGroup.save({
              comment: {
                message: message
              },

              content: {
                studyId: study.id,
                moduleGroupId: group.id,
                required: group.required,
                description: group.description,
                values: group.values
  }
            }).$promise.then(function (result) {
              StudyModuleGroup.get({
                id: result.entities[0].entity.id
              }).$promise.then(function () {
                studyCostController.save(form);
              });
            });
          }
        });
      };

      studyCostController.addModuleGroupToParent = function (parentGroup, form) {
        studyCostController.open(parentGroup);
        Structures.addGroupToParent({
          studyId: studyCostController.study.id,
          parent: parentGroup.target.id,
          operations: studyCostController.operations,
          operationsToEdit: studyCostController.operationsToEdit,
          workflowMode: studyCostController.workflowMode,
          onAdd: function (group, parent, message) {
            return ModuleGroupModuleGroup.save({
              comment: {
                message: message
              },
              content: {
                parentId: parent.id,
                moduleGroupId: group.id,
                required: group.required,
                description: group.description,
                values: group.values
              }
            }).$promise.then(function (result) {
              ModuleGroupModuleGroup.get({
                id: result.entities[0].entity.id
              }).$promise.then(function () {
                studyCostController.save(form);
              });
            });
          }
        });
      };

      studyCostController.addModule = function (group, form) {
        studyCostController.open(group);
        Structures.addModuleToGroup({
          studyId: studyCostController.study.id,
          facultyId: studyCostController.study.facultyId,
          academicYearId: studyCostController.study.academicYearId,
          groupId: group.target.id,
          showGroupAlert: true,
          operations: studyCostController.operations,
          operationsToEdit: studyCostController.operationsToEdit,
          onAddNewModule: function (module, message) {
            onAddModule(module, group, form, message);
          },
          onAddExistingModule: function (module, message) {
            onAddModule(module, group, form, message);
          },
          onDelete: function() {
            studyCostController.save(form);
          }
        });
      };

      function onAddModule(module, group, form, message) {
        return ModuleGroupModule.save({
          comment: {
            message: message
          },
          content: {
            moduleGroupId: group.target.id,
            moduleId: module.id,
            cluster: module.cluster,
            required: module.required,
            alternative: module.alternative,
            values: module.values
          }
        }).$promise.then((result) => {
          ModuleGroupModule.get({
            id: result.entities[0].entity.id
          }).$promise.then(() => {
            studyCostController.save(form);
          });
        });
      }

      studyCostController.addMethod = function (line, form) {
        ModuleGroupModule.get({ id: line.entity.id }).$promise.then((moduleGroupModule) => {
          const usedMethodTypeIdPromises = _.map(line.children, (child) => {
            return Method.get({ id: child.entity.id })
              .$promise.then((method) => {
                return method.target.id;
              });
          });

          $q.all(usedMethodTypeIdPromises).then((usedMethodTypeIds) => {
            var remaining = studyCostController.methodTypes.filter((type) => {
              return !_.some(usedMethodTypeIds, { typeId: type.id });
            });

            studyCostController.open(moduleGroupModule);
            MethodModals.addMethod({
              module: moduleGroupModule,
              methods: remaining,
              askComments: true,
              onAdd: function (method, comment) {
                Method.save({
                  comment: comment,
                  content: {
                    typeId: method.type.id,
                    academicYearId: sessionStorage.academicYear,
                    moduleId: moduleGroupModule.moduleId,
                    required: moduleGroupModule.required,
                    enabled: true
                  }
                }).$promise.then(function (result) {
                  Method.get({
                    id: result.entities[0].entity.id
                  }).$promise.then(function () {
                    studyCostController.save(form);
                  });
                });
              }
            });
          });
        });
      };

      studyCostController.remove = function (line, form) {
        const rootTypes = {
          'study-module-group': 'study',
          'module-group-module-group': 'module-group',
          'module-group-module': 'module-group',
          'method': 'module'
        };

        $uibModal.open({
          templateUrl: 'es6/cost/study.cost.remove.modal.html',
          controller: function ($scope, $uibModalInstance) {
            $scope.line = line;

            $scope.entityType = line.entity.type;
            $scope.rootType = rootTypes[$scope.entityType];

            $scope.remove = function (message) {
              const comment = {
                message
              };

              let promise;
              if (line.entity.type === 'study-module-group') {
                promise = removeStudyModuleGroup(line, comment);
              } else if (line.entity.type === 'module-group-module-group') {
                promise = removeModuleGroupModuleGroup(line, comment);
              } else if (line.entity.type === 'module-group-module') {
                promise = removeModuleGroupModule(line, comment);
              } else if (line.entity.type === 'method') {
                promise = removeMethod(line, comment);
              } else if (line.entity.type === 'person') {
                promise = removeRelation(line, comment);
              }

              promise.then(function () {
                let children = studyCostController.lines;
                if (line.parent) {
                  children = line.parent.children;
                }
                _.remove(children, {
                  reference: line.entity
                });

                studyCostController.save(form);
                $uibModalInstance.close();
              }).catch(function () {
                Message.addError(translateFilter('Status.500'));
                $uibModalInstance.close();
              });
            };
          }
        });
      };

      function removeStudyModuleGroup(line, comment) {
        return StudyModuleGroup.remove({
          id: line.entity.id,
          comment: comment
        }).$promise;
      }

      function removeModuleGroupModuleGroup(line, comment) {
        return ModuleGroupModuleGroup.remove({
          id: line.entity.id,
          comment: comment
        }).$promise;
      }

      function removeModuleGroupModule(line, comment) {
        return ModuleGroupModule.remove({
          id: line.entity.id,
          comment: comment
        }).$promise;
      }

      function removeMethod(line, comment) {
        return Method.remove({
          id: line.entity.id,
          comment: comment
        }).$promise;
      }

      function removeRelation(line, comment) {
        return Relation.remove({
          id: line.entity.id,
          comment: comment
        }).$promise;
      }

      // Move

      studyCostController.order = function (line) {
        $uibModal.open({
          template: `
            <uas-planboard-order
              modal="modal"
              contents="contents"
              content="content"
              btn-save-label="StudyCost.Order.Button.Save"
              btn-cancel-label="StudyCost.Order.Button.Cancel"
              on-save="save(contents)">
            </uas-planboard-order>
          `,
          controller: function ($scope, $uibModalInstance) {
            const siblings = getSiblings(line);

            $scope.modal = $uibModalInstance;
            $scope.contents = _(siblings).sortBy('sequence').filter((s) => s.target.type === 'module-group').map(getPlain).value();
            $scope.content = getPlain(line);
            $scope.save = function(contents) {
              _.forEach(contents, (item, index) => {
                const content = _.find(siblings, { id: item.id });
                if (angular.isDefined(content)) {
                  content.sequence = index;
                  content.ordered = true;
                }
              });
              studyCostController.saveAndCalculate(studyCostController.costForm);
            };
          }
        });
      };

      function getSiblings(line) {
        return angular.isDefined(line.parent) ? line.parent.children : studyCostController.lines;
      }

      function getPlain(item) {
        return _.pick(item, ['id', 'code', 'localName', 'englishName']);
      }

      studyCostController.move = function (line, form) {
        $uibModal.open({
          templateUrl: 'es6/cost/study.cost.move.modal.html',
          size: 'lg',
          controller: function ($scope, $uibModalInstance, CustomField) {
            $scope.line = line;
            $scope.targets = [];

            if (_.get(line, 'parent.target.type') === 'module-group') {
              $scope.parentId = line.parent.target.id;
            }
            $scope.originalId = $scope.parentId;

            $scope.comment = {
              message: ''
            };
            $scope.cluster = line.cluster;

            CustomField.query({
              rootType: 'module-group',
              entityType: 'module-group-module'
            }).$promise.then((fields) => {
              $scope.fields = fields;
            });

            // Start building all possible targets
            _(studyCostController.lines).sortBy('sequence').forEach((x) => {
              addTarget(x, $scope.targets);
            });

            $scope.current = _.find($scope.targets, { id: $scope.parentId });

            function addTarget(element, container) {
              if (element.type === 'module-group' && (element.isLeaf || element.target.id === $scope.parentId)) {
                container.push({
                  id: element.target.id,
                  code: element.code,
                  localName: element.localName,
                  englishName: element.englishName
                });
              }

              _.forEach(_.sortBy(element.children, 'sequence'), (child) => addTarget(child, container));
            }

            $scope.move = function (comment) {
              ModuleGroupModule.move({}, {
                id: line.entity.id,
                targetId: $scope.parentId,
                studyId: studyCostController.studyId,
                cluster: $scope.cluster,
                message: comment.message
              }).$promise.then(function (result) {
                _.remove(line.parent.children, {
                  id: line.id
                });

                ModuleGroupModule.get({
                  id: result.entities[0].entity.id
                }).$promise.then(function () {
                  studyCostController.save(form);
                  $uibModalInstance.close();
                });
              });
            };
          }
        });
      };

      studyCostController.editModuleGroup = function(line) {
        delete groupCache[line.target.id];

        Structures.editGroup({
            id: line.target.id,
            entityType: line.entityType,
            operations: studyCostController.operations,
            workflowMode: studyCostController.workflowMode,
            onSuccess: refresh,
            onCancel: refresh
        });
      };

      studyCostController.editModuleGroupModule = function(line) {
        Structures.editModuleInStructure({
          entity: line.entity,
          module: {
            id: line.target.id,
            code: line.code,
            localName: line.localName,
            englishName: line.englishName
          },
          operations: studyCostController.operations,
          operationsToEdit: studyCostController.operationsToEdit,
          onSave: refresh
        });
      };

      function refresh() {
        studyCostController.save(studyCostController.costForm);
      }
    }
  });
