'use strict';

angular.module('uasApp').factory('Objective', function (UasResource) {
  return UasResource('/objectives', {
    report: {
      url: '/objectives-report',
      method: 'GET',
      isArray: false
    },
    nodes: {
      url: '/objectives-report/nodes',
      method: 'GET',
      isArray: true
    }
  });
});
