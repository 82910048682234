'use strict';

angular.module('uasApp').directive('maxDate', (uasDateService) => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {
      ctrl.$validators.maxDate = (modelValue) => {
        const maxDate = uasDateService.convert(scope.$eval(attrs.maxDate));
        return !maxDate || !modelValue || modelValue <= maxDate;
      };

      scope.$watch(() => {
        return scope.$eval(attrs.maxDate);
      }, () => {
        ctrl.$validate();
      });
    }
  };
});
