'use strict';

/**
 * @ngdoc service
 * @name uasApp.LanguageLoaderFactory
 * @description
 * # LanguageLoader
 * LanguageLoader service.
 */
angular.module('uasApp')
    .factory('languageLoader', function($q, Label) {

        return function(options) {
            // $q.defer is needed for language angular-translate directive
            var deferred = $q.defer();

            const language = options.key;
            if (language && language !== 'undefined') {
                Label.load({ language }).$promise.then(function(data) {
                    const result = _.omit(_.extend({ language }, data), ['$promise', '$resolved']);
                    deferred.resolve(result);
                }, function() {
                    deferred.reject(options.key);
                });
            } else {
                deferred.resolve({ language });
            }

            // Returns the deferred promise
            return deferred.promise;
        };

    });
