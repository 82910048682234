'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:offeringPicker
 * @description
 */
angular.module('uasApp').component('offeringPicker', {
  bindings: {
    entity: '<',
    period: '<?',
    autoSelect: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/schedule/offering/offering.picker.html',
  controllerAs: 'offeringPickerController',
  controller: function (EntityService, Offering) {
    const offeringPickerController = this;

    offeringPickerController.$onInit = function() {
      offeringPickerController.ngModelCtrl.$render = onRender;
      loadData();
    };

    function onRender() {
      offeringPickerController.periodId = offeringPickerController.ngModelCtrl.$modelValue;
      loadPeriod();
    }

    function loadData() {
      offeringPickerController.loading = true;
      getPeriods().then((periods) => {
        offeringPickerController.periods = periods;

        if (!_.isNumber(offeringPickerController.periodId) && offeringPickerController.autoSelect === true) {
          offeringPickerController.periodId = _(periods).sortBy('startDate').map('id').find();
          offeringPickerController.onChange();
        }

        loadPeriod();
      }).finally(() => {
        offeringPickerController.loading = false;
      });
    }

    function getPeriods() {
      return Offering.periods({
        entityType: EntityService.getType(offeringPickerController.entity),
        entityId: offeringPickerController.entity.id,
        periodId: _.result(offeringPickerController.period, 'id')
      }).$promise;
    }

    function loadPeriod() {
      offeringPickerController.selected = _.find(offeringPickerController.periods, {
        id: offeringPickerController.periodId
      });
    }

    offeringPickerController.onChange = function() {
      offeringPickerController.ngModelCtrl.$setViewValue(offeringPickerController.periodId);
      if (_.isNumber(offeringPickerController.periodId)) {
        loadPeriod();
      }
    };
  }
});