'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:UasResource
 * @description A factory for loading resources with frontend caching.
 */
angular.module('uasApp')
  .factory('UasResource', function ($resource, $http, $injector, $q, BackendService, UasCache) {
    let EntityTextHolder; // Workaround for circular dependency

    function wrapUrl(url) {
      if (_.startsWith(url, '/')) {
        url = BackendService.getBaseUrl() + url;
      }
      return url;
    }

    return function (url, extensions) {
      let transformed = {};

      _.each(extensions, (config, name) => {
        let extension = angular.copy(config);
        extension.url = wrapUrl(extension.url);
        transformed[name] = extension;
      });

      // Include convention methods
      if (!transformed.update) {
        transformed.update = {
          method: 'PUT'
        };
      }

      const baseUrl = wrapUrl(url);
      const resource = $resource(baseUrl + '/:id', {}, transformed);

      const result = _.extend({}, resource, {
        find: function (id) {
          if (angular.isUndefined(id) || id === '') {
            return resolve();
          }
          return resource.get({ id });
        },
        findAll: function (ids) {
          if (_.isEmpty(ids)) {
            return resolve([]);
          }
          return resource.query({ ids });
        },
        page: function (data) {
          return resource.get(data);
        },
        save: function (...args) {
          const promise = resource.save(...args).$promise.then(handleSuccess).catch(handleError);
          return resolve(promise);
        },
        store: function (data, identifier) {
          const id = _.get(data, 'id', identifier);
          if (angular.isDefined(id)) {
            return resource.update({
              id
            }, _.omit(data, 'id'));
          } else {
            return resource.save(data);
          }
        },
        remove: function (data) {
          let response = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
          };

          if (angular.isDefined(data.id) && angular.isUndefined(data.comment)) {
            _.extend(response, {
              url: baseUrl + '/' + data.id,
              data: _.omit(data, 'id')
            });
          } else {
            _.extend(response, {
              url: baseUrl,
              data
            });
          }
          return {
            $promise: $http(response)
          };
        },
        resolve,
        withCache: function(cacheName) {
          return withCache(result, cacheName);
        }
      });

      return result;
    };

    // Conforms data to the standard promise structure { $promise }
    function resolve(data) {
      if (data && data.$promise) {
        return data;
      }

      return {
        $promise: $q.resolve(data)
      };
    }

    // Creates a resource with frontend caching on get/query, cache is cleared during modifications
    function withCache(resource, cacheName) {
      const cache = UasCache(cacheName);

      const cached = _.extend({}, resource, {
        query: function(args) {
          const key = getCacheKey('query', args);
          const data = cache.fetch(key, () => resource.query(args));
          return resolve(data);
        },
        queryWithoutCache: function(args) {
          return resource.query(args);
        },
        get: function(args) {
          const key = getCacheKey('get', args);
          const data = cache.fetch(key, () => resource.get(args));
          return resolve(data);
        },
        getCached: function(args) {
          const values = cache.get('query');
          return _.find(values, args);
        },
        load: function() {
          const data = cache.fetch('query', resource.query);
          return resolve(data).$promise;
        },
        reload: function() {
          cache.clear();
          return cached.load();
        },
        save: function(...args) {
          const promise = resource.save(...args);
          return withClear(promise);
        },
        store: function(data) {
          const promise = resource.store(data);
          return withClear(promise);
        },
        remove: function(data) {
          const promise = resource.remove(data);
          return withClear(promise);
        }
      });

      function getCacheKey(name, args) {
        if (_.isEmpty(args)) {
          return name;
        }

        return `${name}(${angular.toJson(args)})`;
      }

      function withClear(promise) {
        const done = promise.$promise.then((data) => {
          cache.clear();
          updateEntityText(data);
          return data;
        });

        return resolve(done);
      }

      return cached;
    }

    // We could do Message.onSaved here to simplify components
    function handleSuccess(response) {
      updateEntityText(response);
      return response;
    }

    function updateEntityText(updatedEntity) {
      if (!EntityTextHolder) {
        EntityTextHolder = $injector.get('EntityTextHolder');
      }
      EntityTextHolder.forceUpdate(updatedEntity);
    }

    function handleError(error) {
      return $q.reject(error);
    }
  });
