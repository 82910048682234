'use strict';

angular.module('uasApp').component('personLoginPermissions', {
    templateUrl: 'es6/person/permissions/person.login.permissions.html',
    controllerAs: 'personLoginPermissionsController',
    controller: function(LoginAsPermission, $uibModal, Message, AuthService, $state) {
        const personLoginPermissionsController = this;

        personLoginPermissionsController.$onInit = function() {
            personLoginPermissionsController.loadData();
        };

        personLoginPermissionsController.loadData = function() {
            LoginAsPermission.query().$promise.then((permissions) => {
                personLoginPermissionsController.permissions = permissions;
                personLoginPermissionsController.permissions.forEach((permission) => {
                    permission.displayPersons = permission.persons.map((person) => person.externalId).join(', ');
                });
            });
            
            LoginAsPermission.allowed().$promise.then((allowed) => {
                personLoginPermissionsController.allowedPermissions = allowed;
            });
        };

        personLoginPermissionsController.openAddModal = function() {
            $uibModal.open({
                controllerAs: 'modalController',
                controller: function ($uibModalInstance, onUpdate) {
                    const modalController = this;
                    modalController.modal = $uibModalInstance;
                    modalController.onUpdate = onUpdate;
                },
                resolve: {
                    onUpdate: function() {
                        return function() {
                            personLoginPermissionsController.loadData();
                        };
                    }
                },
                template: '<person-login-permission modal="modalController.modal" on-update="modalController.onUpdate()"></person-login-permission>'
            });
        };

        personLoginPermissionsController.openEditModal = function(permission) {
            $uibModal.open({
                controllerAs: 'modalController',
                controller: function ($uibModalInstance, editPermission, onUpdate) {
                    const modalController = this;
                    modalController.modal = $uibModalInstance;
                    modalController.permission = editPermission;
                    modalController.onUpdate = onUpdate;
                },
                size: 'lg',
                resolve: {
                    editPermission: function() {
                        return angular.copy(permission);
                    },
                    onUpdate: function() {
                        return function() {
                            personLoginPermissionsController.loadData();
                        };
                    }
                },
                template: '<person-login-permission permission="modalController.permission" on-update="modalController.onUpdate()" modal="modalController.modal"></person-login-permission>'
            });
        };

        personLoginPermissionsController.delete = function(permission) {
            LoginAsPermission.delete(permission).$promise.then(() => {
                Message.addSuccessLabel('Static.Message.DataRemoved');
                personLoginPermissionsController.loadData();
            });
        };

        personLoginPermissionsController.login = function(permission) {
            LoginAsPermission.login({
                id: permission.id
            }, {}).$promise.then((auth) => {
                AuthService.setAuthentication(auth);
                $state.go('dashboard', {}, {
                    reload: true
                });
            });
        };
    }
});
