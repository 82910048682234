'use strict';

angular.module('uasApp').component('referenceListItems', {
  templateUrl: 'es6/reference/reference.list.items.html',
  bindings: {
    list: '<'
  },
  controllerAs: 'itemController',
  controller: function (Pageable, filterFilter) {
    const itemController = this;

    itemController.$onInit = function () {
      itemController.pageable = Pageable.of({
        order: 'name'
      });
    };

    itemController.$onChanges = function () {
      itemController.search();
    };

    itemController.search = function () {
      const items = filterFilter(_.get(itemController.list, 'items', []), itemController.term);
      itemController.items = items;
      _.set(itemController.pageable, 'currentPage', 1);
    };
  }
});
