'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:methodSchemaEdit
 * @description
 * methodSchemaEdit Edits an method schema.
 */
angular.module('uasApp').component('methodSchemaEdit', {
    bindings: {
        schema: '<',
        fields: '<',
        operations: '<',
        operationsToEdit: '<',
        onSave: '&',
        onClose: '&'
    },
    templateUrl: 'es6/methods/schema/method.schema.edit.html',
    controllerAs: 'methodSchemaEditController',
    controller: function (MethodSchema) {
        const methodSchemaEditController = this;

        methodSchemaEditController.save = function (content, comment) {
            MethodSchema.save({
                content,
                comment
            }).$promise.then((result) => {
                methodSchemaEditController.onSave({ content, result });
            });
        };

        methodSchemaEditController.close = function() {
            methodSchemaEditController.onClose();
        };
    }
});