'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Status
 * @description
 * The Status service.
 */
angular.module('uasApp')
    .factory('Status', function(UasResource) {
        return UasResource('/status', {
            current: {
                method: 'GET',
                url: '/status/current',
                isArray: true
            },
            report: {
                method: 'GET',
                url: '/status-report'
            }
        });
    });
