'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:appraisalListPage
 * @description
 * appraisalListPage Manages the appraisals.
 */
angular.module('uasApp')
  .component('appraisalListPage', {
    bindings: {
      module: '<',
      page: '<',
      period: '<?',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/assessment/appraisal/appraisal.list.page.html',
    controllerAs: 'appraisalListPageController',
    controller: function (Page, Category, $q) {
      const appraisalListPageController = this;

      appraisalListPageController.$onInit = function () {
        appraisalListPageController.readOnly = appraisalListPageController.isReadOnly || Page.getParameterAsBoolean(appraisalListPageController.page, 'readOnly', false);
        appraisalListPageController.showInactive = Page.getParameterAsBoolean(appraisalListPageController.page, 'showInactive', false);
        appraisalListPageController.navigate = !Page.getParameterAsBoolean(appraisalListPageController.page, 'disableNavigate', false);
        appraisalListPageController.columns = Page.getParameterAsArray(appraisalListPageController.page, 'columns');

        loadParameters();
      };

      function loadParameters() {
        const categoryId = Page.getParameterAsInt(appraisalListPageController.page, 'category');
        const childPageId = Page.getParameterAsInt(appraisalListPageController.page, 'childPage');

        appraisalListPageController.loading = true;
        $q.all([
          Category.find(categoryId).$promise,
          Page.find(childPageId).$promise
        ]).then(([category, page]) => {
          appraisalListPageController.category = category;
          appraisalListPageController.childPage = page || Page.build('activity-list');
        }).finally(() => {
          appraisalListPageController.loading = false;
        });
      }
    }
  });
