'use strict';

/**
 * @ngdoc function
 * @name uasApp.factory:SecuredOperation
 * @description
 * # SecuredOperation
 * SecuredOperation resource of the uasApp
 */
angular.module('uasApp').factory('SecuredOperation', function (Enums) {

    const GROUPS = [
        { name: 'VIEW', pattern: /VIEW.*/, open: true },
        { name: 'EDIT_WORKFLOW', pattern: /EDIT.*WORKFLOW/ },
        { name: 'EDIT', pattern: /EDIT.*/ },
        { name: 'CUSTOM', pattern: /OPERATION.*/ },
        { name: 'OTHER', pattern: /.*/ }
    ];

    function getOperations() {
        return Enums.constants('SecuredOperation').$promise;
    }

    function getGroups() {
        return getOperations().then((operations) => {
            const groups = angular.copy(GROUPS);
            _.forEach(operations, (operation) => {
                const group = _.find(groups, (it) => operation.match(it.pattern) !== null);
                if (angular.isDefined(group)) {
                    const container = group.operations || [];
                    container.push({
                        name: operation
                    });
                    group.operations = container;
                }
            });
            return groups;
        });
    }

    return { getGroups };

});
