'use strict';

/**
 * List based report of changes in the faculty.
 */
angular.module('uasApp')
    .component('uasDeadline', {
        bindings: {
            deadline: '<'
        },
        template: `
          <small ng-if="deadlineController.remainder !== undefined && deadlineController.remainder <= 14"
                 class="badge {{ deadlineController.remainder <= 0 ? 'badge-danger' : 'badge-warning' }}">
            {{ deadlineController.deadline | configDate }}
          </small>
        `,
        controllerAs: 'deadlineController',
        controller: function() {
          const deadlineController = this;

          deadlineController.$onInit = function() {
            deadlineController.current = new Date().getTime();
            calculateRemainder();
          };

          function calculateRemainder() {
            if (angular.isDefined(deadlineController.deadline)) {
              const remainder = deadlineController.deadline - deadlineController.current;
              deadlineController.remainder = Math.ceil(remainder / 86400000);
            } else {
              delete deadlineController.remainder;
            }
          }
        }
    });
