'use strict';

/**
 * @ngdoc function
 * @name uasApp.direcitve:changeStatusBlock
 * @description
 * # changeStatusBlock
 * Template for change status.
 */
angular.module('uasApp')
    .directive('changeStatusBlock', function() {
        return {
            restrict: 'E',
            scope: {
                change: '='
            },
            templateUrl: 'es6/changes/change.status.block.html'
        };
    });
