'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Messages
 * @description
 * The Messages service.
 */
angular.module('uasApp')
    .factory('Messages', function(UasResource) {
        return UasResource('/messages', {
            entities: {
                method: 'GET',
                url: '/messages/entities',
                isArray: false
            },
            grouped: {
                method: 'GET',
                url: '/messages/grouped',
                isArray: true
            }
        });
    });
