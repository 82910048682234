'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:subjectPage
 * @description
 * subjectPage Manage entity subjects on a workflow page
 */
angular.module('uasApp')
  .component('subjectPage', {
    bindings: {
      entity: '<',
      page: '<',
      workflow: '<?',
      operations: '<',
      isReadOnly: '<?'
    },
    templateUrl: 'es6/subjects/subject.page.html',
    controllerAs: 'subjectPageController',
    controller: function (Page) {
      const subjectPageController = this;

      subjectPageController.$onInit = function () {
        subjectPageController.readOnly = subjectPageController.isReadOnly || Page.getParameterAsBoolean(subjectPageController.page, 'readOnly', false);
        subjectPageController.categoryId = Page.getParameterAsInt(subjectPageController.page, 'category');
        subjectPageController.showInactive = Page.getParameterAsBoolean(subjectPageController.page, 'showInactive', false);
        subjectPageController.typeId = Page.getParameterAsInt(subjectPageController.page, 'type');

        subjectPageController.operationsToEdit = angular.isDefined(subjectPageController.workflow) ? ['EDIT_SUBJECTS_WORKFLOW'] : ['EDIT_SUBJECTS'];
      };
    }
  });
