'use strict';

angular.module('uasApp').filter('i18n', function(Language) {
    function collect(value, property) {
      if (_.isArray(value)) {
        return _(value).map((current) => {
          let result = current;
          if (!_.isEmpty(property)) {
            result = _.result(current, property);
          }
          return result;
        }).flatten().value();
      } else if (_.isObject(value)) {
        return _.map(value, (val, key) => {
          return {
            language: key,
            value: val
          };
        });
      } else {
        return [];
      }
    }

    function translate(values, language) {
      const languages = _.groupBy(values, 'language');

      let results = languages[language];
      if (_.isEmpty(results)) {
        results = languages[_.get(values[0], 'language')];
      }

      return results;
    }

    function print(values) {
      return _(values).map('value').sort().value().join(', ');
    }

    return function(value, language, property) {
        if (_.isEmpty(value)) {
            return '';
        }
        if (_.isEmpty(language)) {
          language = Language.get();
        }

        const values = collect(value, property);
        const translated = translate(values, language);
        return print(translated);
    };
});
