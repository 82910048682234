'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasCost
 * @description
 * uasCost wrapper for costs.
 */
angular.module('uasApp')
    .component('uasCost', {
        bindings: {
            entity: '<',
            operations: '<',
            operationsToEdit: '<',
            isReadOnly: '<?'
        },
        templateUrl: 'es6/cost/cost.html',
        controllerAs: 'costController',
        controller: function (StudyCost, Message, EntityService, SecurityService, Pageable, Report, Language, entityTranslateFilter, $filter, $q) {
            const costController = this;

            costController.$onInit = function() {
                costController.language = Language.get();
                costController.pageable = Pageable.of({
                    order: 'study.code'
                });

                const operationsToEdit = costController.operationsToEdit || ['EDIT_COST'];
                costController.editable = SecurityService.isAllowed(operationsToEdit, costController.operations);

                loadData();
            };

            function loadData() {
                costController.loading = true;
                return getStudyCosts().then((costs) => {
                    _.each(costs, (cost) => {
                        cost.original = angular.copy(cost.studyCost);
                    });

                    sumCosts(costs);
                    sumBudget(costs);

                    costController.costs = costs;
                }).finally(() => {
                    costController.loading = false;
                });
            }

            function getStudyCosts() {
                const entityType = EntityService.getType(costController.entity);
                if (entityType === 'faculty') {
                    return StudyCost.query({
                        facultyId: costController.entity.id
                    }).$promise;
                } else if (entityType === 'organisation') {
                    return StudyCost.query({
                        organisationId: costController.entity.id
                    }).$promise;
                } else if (entityType === 'study') {
                    return StudyCost.get({
                        studyId: costController.entity.id
                    }).$promise.then((cost) => {
                        return [cost || {}];
                    });
                }

                return $q.resolve([]);
            }

            costController.headers = [
                { group: 'general', code: 'code', name: 'Code', valueType: 'STRING' },
                { group: 'general', code: 'name', name: 'Name', valueType: 'STRING' },
                { group: 'general', code: 'organisation', name: 'Organisation', valueType: 'STRING' },
                { group: 'general', code: 'capacity', name: 'Number of students', valueType: 'NUMBER' },
                { group: 'general', code: 'studentFlowYear1', name: 'Year 1', valueType: 'NUMBER' },
                { group: 'general', code: 'studentFlowYear2', name: 'Year 2', valueType: 'NUMBER' },
                { group: 'general', code: 'studentFlowYear3', name: 'Year 3', valueType: 'NUMBER' },
                { group: 'general', code: 'budget', name: 'Budget', valueType: 'NUMBER' },
                { group: 'general', code: 'totalCost', name: 'Costs', valueType: 'NUMBER' }
            ];

            costController.getRows = function() {                     
                const orderedRows = $filter('orderBy')(costController.costs, costController.pageable.order, costController.pageable.reverse);
                let rows = _.map(orderedRows, mapToReportRow);
                rows.push({
                    budget: costController.totalBudget,
                    totalCost: costController.totalCosts
                });

                return $q.resolve(rows);
            };

            function mapToReportRow(row) {
                return {
                    code: row.study.code,
                    name: entityTranslateFilter(row.study),
                    organisation: entityTranslateFilter(row, 'organisationLocalName', 'organisationEnglishName'),
                    capacity: row.studyCost.capacity,
                    studentFlowYear1: row.studyCost.studentFlowYear1,
                    studentFlowYear2: row.studyCost.studentFlowYear2,
                    studentFlowYear3: row.studyCost.studentFlowYear3,
                    budget: row.studyCost.budget,
                    totalCost: row.studyCost.totalCost
                };
            }

            costController.isDirty = function(cost) {
                if (!cost.original) {
                    return true;
                }

                return cost.studyCost.studentFlowYear1 !== cost.original.studentFlowYear1 ||
                    cost.studyCost.studentFlowYear2 !== cost.original.studentFlowYear2 ||
                    cost.studyCost.studentFlowYear3 !== cost.original.studentFlowYear3 ||
                    cost.studyCost.capacity !== cost.original.capacity ||
                    cost.studyCost.budget !== cost.original.budget;
            };

            function saveStudyCost(cost) {
                // Never more than 100
                cost.studyCost.studentFlowYear1 = Math.min(cost.studyCost.studentFlowYear1, 100);
                cost.studyCost.studentFlowYear2 = Math.min(cost.studyCost.studentFlowYear2, 100);
                cost.studyCost.studentFlowYear3 = Math.min(cost.studyCost.studentFlowYear3, 100);

                return StudyCost.save({
                    studyCost: cost.studyCost,
                    studyCostLines: []
                }).$promise.then((result) => {
                    cost = result.studyCost;
                });
            }

            costController.save = function(form) {
                const promises = _(costController.costs)
                    .filter(costController.isDirty)
                    .map(saveStudyCost)
                    .value();

                costController.saving = true;
                $q.all(promises)
                    .then(() => {
                        form.$setPristine();
                        Message.onSaved();
                    })
                    .then(() => loadData())
                    .finally(() => costController.saving = false);

                return form;
            };

            function sumCosts(costs) {
                costController.totalCosts = _.sumBy(costs, (cost) => {
                    return (cost.studyCost) ? cost.studyCost.totalCost : 0;
                });
            }

            function sumBudget(costs) {
                costController.totalBudget = _.sumBy(costs, (cost) => {
                    return (cost.studyCost) ? cost.studyCost.budget : 0;
                });
            }
        }
    });
