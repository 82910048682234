'use strict';

angular.module('uasApp')
  .component('facultyStatistics', {
    bindings: {
      academicYearId: '<'
    },
    templateUrl: 'es6/report/statistics/faculty.statistics.html',
    controllerAs: 'statisticsController',
    controller: function (Pageable, FacultyStatistics) {
      const statisticsController = this;

      statisticsController.$onInit = function () {
        statisticsController.pageable = Pageable.of({
          order: 'faculty.code'
        });

        statisticsController.loading = true;
        FacultyStatistics.query({
          academicYearId: statisticsController.academicYearId
        }).$promise.then((faculties) => {
          statisticsController.faculties = faculties;
          statisticsController.total = {
            studyCount: _.sumBy(faculties, 'studyCount'),
            moduleGroupCount: _.sumBy(faculties, 'moduleGroupCount'),
            moduleCount: _.sumBy(faculties, 'moduleCount')
          };
        }).finally(() => {
          statisticsController.loading = false;
        });
      };
    }
  });
